import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[numbersOnly]',
})
export class InputNumberOnlyDirective {
    @HostListener('input', ['$event'])
    onInputChange(event: InputEvent): void {
        const element = <HTMLInputElement>event.target;
        const initialValue = element.value;

        element.value = initialValue.replace(/[^0-9]*/g, '');

        if (initialValue !== element.value) {
            event.stopPropagation();
        }
    }
}
