import moment from 'moment';

import { Resource } from '../index';
import { MarketplaceFormStatus } from './marketplace-order-summary';

export class MarketplaceOrder implements Resource {
    id: string;
    name: string;
    cloud_account_type: string;
    customer_id: string;
    form_status: MarketplaceFormStatus;
    product_id: string;
    timestamp: moment.Moment;
    partner_email: string;
    promotion_code: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    salesforce_account_id: string;
    salesforce_contact_id: string;
    org_id: number | null;
    company_name: string;
    company_website: string;
    billing_street_address: string;
    billing_city: string;
    billing_state: string;
    billing_postal_code: string;
    billing_country: string;
    entitlement: {
        dimension: string;
        expiration_date: moment.Moment;
    };
    azure_marketplace_subscription_detail: {
        term: string;
        id: string;
        name: string;
        status: string;
        planId: string;
        offerId: string;
    };
    entitlement_last_updated: moment.Moment;
    aws_registration_last_updated: moment.Moment;

    // IBM marketplace specific fields
    ibm_crn: string;

    // PX-specific fields
    // e.g. {
    // "px_cluster_info": {
    // 	"crn": "crn:v1:bluemix:public:portworx-test:us-south:a/f3201f735578f72f3494142373fc1a51:ad159810-f919-4a91-b795-0bc8c085fca5::",
    // 	"clusterID": "246fce23-0302-4554-a70e-db860199a13e",
    // 	"status": "INSTALLED",
    // 	"pxVersion": "3.0.0.0-038005f",
    // 	"installedOn": "tp-nextpx-iks-catalog-pl-148",
    // 	"planID": "c14f87c4-ef15-491b-8e2d-aaf1dd6f606d",
    // 	"lastSuccess": "2023-07-19T13:41:01Z",
    // 	"creationDate": "2023-07-19T12:55:12Z"
    // }
    px_cluster_info: {
        crn: string;
        clusterID: string;
        status: string;
        pxVersion: string;
        installedOn: string;
        planID: string;
        // These two should come from the backend as timestamps (number) and not
        // strings; we'll add these when we need them
        // lastSuccess: string;
        // creationDate: string;
    };

    program_type: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = null;
        this.cloud_account_type = json.cloud_account_type;
        this.form_status = json.form_status;
        this.customer_id = json.customer_id;
        this.product_id = json.product_id;
        this.timestamp = json.timestamp && moment(json.timestamp);
        this.partner_email = json.partner_email;
        this.promotion_code = json.promotion_code;
        this.first_name = json.first_name;
        this.last_name = json.last_name;
        this.email = json.email;
        this.phone_number = json.phone_number;
        this.salesforce_account_id = json.salesforce_account_id;
        this.salesforce_contact_id = json.salesforce_contact_id;
        this.org_id = json.org_id;
        this.company_name = json.company_name;
        this.company_website = json.company_website;
        this.billing_street_address = json.billing_street_address;
        this.billing_city = json.billing_city;
        this.billing_state = json.billing_state;
        this.billing_postal_code = json.billing_postal_code;
        this.billing_country = json.billing_country;
        this.entitlement = json.entitlement && {
            dimension: json.entitlement.dimension,
            expiration_date: json.entitlement.expiration_date && moment(json.entitlement.expiration_date),
        };
        this.entitlement_last_updated = json.entitlement_last_updated && moment(json.entitlement_last_updated);
        this.aws_registration_last_updated =
            json.aws_registration_last_updated && moment(json.aws_registration_last_updated);
        this.program_type = json.program_type;
        this.azure_marketplace_subscription_detail = json.azure_marketplace_subscription_detail && {
            term: json.azure_marketplace_subscription_detail.term,
            id: json.azure_marketplace_subscription_detail.id,
            name: json.azure_marketplace_subscription_detail.name,
            status: json.azure_marketplace_subscription_detail.status,
            planId: json.azure_marketplace_subscription_detail.planId,
            offerId: json.azure_marketplace_subscription_detail.offerId,
        };

        this.ibm_crn = json.ibm_crn;
        this.px_cluster_info = json.px_cluster_info && {
            crn: json.px_cluster_info.crn,
            clusterID: json.px_cluster_info.clusterID,
            status: json.px_cluster_info.status,
            pxVersion: json.px_cluster_info.pxVersion,
            installedOn: json.px_cluster_info.installedOn,
            planID: json.px_cluster_info.planID,
            // lastSuccess: json.px_cluster_info.lastSuccess,
            // creationDate: json.px_cluster_info.creationDate,
        };
    }
}
