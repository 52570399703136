import { CaseService } from './case.service';
import { CaseManager } from './case-data.service';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { SupportCase } from '../support.interface';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SupportCaseService {
    constructor(
        private caseService: CaseService,
        private caseManager: CaseManager,
        private router: Router,
    ) {}

    createInitialCase = (draft: Partial<SupportCase>): Partial<SupportCase> => {
        return {
            origin: 'web',
            preferredContactMethod: 'email',
            ...draft,
        };
    };

    createSubmitCase = (supportCase: Partial<SupportCase>): Observable<SupportCase> => {
        return this.caseService.saveCase(supportCase).pipe(
            tap((newSupportCase): void => {
                if (newSupportCase) {
                    this.caseManager.addCase(newSupportCase);

                    this.router.navigate(['/support/cases'], { queryParams: { caseId: newSupportCase.id } });
                }
            }),
        );
    };

    editInitialCase = (caseId: string): Observable<SupportCase> => {
        return this.caseManager.getCase(caseId);
    };

    editSubmitCase = (supportCase: Partial<SupportCase>): Observable<SupportCase> => {
        // supportCase is bound to the view. Since we need to send to the REST API only a subset of the fields of
        // supportCase we need to modify it and this causes flickering. To avoid it, we work on a duplicate object
        const diffCase = cloneDeep(supportCase);

        // Properties that can be deleted by customer. If this is the case, we must set them to empty, because
        // undefined is not serialized in JSON, and the back-end treats null as 'not modified'
        const deletableProperties = ['arrayId', 'purityVersion'];
        const len = deletableProperties.length;
        for (let i = 0; i < len; i++) {
            const prop = deletableProperties[i];
            if (!diffCase[prop]) {
                diffCase[prop] = '';
            }
        }
        if (!diffCase.alternateContact) {
            diffCase.alternateContact = { id: '' };
        }

        // When origin prop is present on update request then it results in error:
        // Cannot update case's origin
        // It doesn't matter if the value really changed
        delete diffCase.origin;

        return this.caseService.updateCase(diffCase).pipe(
            map(updatedCase => {
                if (!updatedCase.arrayId) {
                    updatedCase.array = undefined;
                }
                this.caseManager.updateCase(updatedCase);
                this.router.navigate(['/support/cases'], { queryParams: { caseId: updatedCase.id } });
                return updatedCase;
            }),
        );
    };
}
