import { DraasApiRecoveryPlanExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryJobExecution } from './disaster-recovery-job-execution';

export class DisasterRecoveryRecoveryPlanExecution extends DisasterRecoveryJobExecution {
    constructor(json: DraasApiRecoveryPlanExecutionInfo) {
        super(json);
        this.clusterId = json.recovery_plan.cluster_id;
        this.name = json.recovery_plan.name;
        this.type = json.recovery_plan.plan_type;
        this.groupNames = new Map(json.recovery_plan.group_ids.map(groupId => [groupId, json.group_names[groupId]]));
    }
}
