import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericSnapshotsAggregationService } from './generic-snapshots-aggregation.service';

@Injectable({ providedIn: 'root' })
export class FileSystemSnapshotsAggregationService extends GenericSnapshotsAggregationService {
    constructor(protected http: HttpClient) {
        super('/rest/v4/arrays/file-system-snapshots-aggregation');
    }
}
