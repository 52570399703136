import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { LicenseLevelAggregation } from '../models/license-level-aggregation';

@Injectable({ providedIn: 'root' })
export class LicenseLevelAggregationService extends GenericService<LicenseLevelAggregation> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: LicenseLevelAggregation,
            endpoint: '/rest/v1/usage-aggregation/detail/license',
        });
    }
}
