<div class="address-street-view street-view-not-found" *ngIf="!panorama || !streetViewFound">
    <img class="placeholder-image" src="/images/StreetViewPlaceholder.png" />
</div>
<div class="street-view-container" #streetView></div>
<div class="street-address">
    <ng-container *ngIf="currentPlace; else initialAddress">{{ currentPlace.formatted_address }}</ng-container>
    <ng-template #initialAddress>
        <ng-container *ngIf="multipleAddresses; else singleAddress"> Multiple Addresses Selected </ng-container>
        <ng-template #singleAddress>
            {{ selectedArraysList[0].address.street_address || 'Street address not found' }}
        </ng-template>
    </ng-template>
</div>
<div class="address-information">
    <!-- When mapLoadingStatus is set to 'err', errorOccurred needs to also be set to an error message-->
    <div class="current-address-header" *ngIf="mapLoadingStatus !== 'err'">
        <ng-container *ngIf="currentPlace; else initialHeader"> Updated physical address </ng-container>
        <ng-template #initialHeader> Current physical {{ multipleAddresses ? 'addresses' : 'address' }} </ng-template>
    </div>
    <div class="current-address-list" *ngIf="multipleAddresses && mapLoadingStatus !== 'err'">
        <div class="single-address" *ngFor="let array of selectedArraysList; trackBy: 'id' | trackByProperty">
            {{ array.address.street_address }}
        </div>
    </div>
    <div id="map-view-search-bar" *ngIf="mapLoadingStatus !== 'err'">
        <input id="map-view-search" (blur)="deepSearch()" #searchElement />
        <div
            class="deep-search-button"
            (click)="deepSearch()"
            [angulartics2On]="'click'"
            [angularticsCategory]="'Action'"
            [angularticsAction]="analyticsPrefix + analyticsPreference"
            [angularticsLabel]="'Deep search performed with mouseclick'"
        >
            <div class="icon-container">
                <pureui-svg class="search-icon" [svg]="'magnifying-glass-icon.svg'" [height]="18"></pureui-svg>
            </div>
        </div>
    </div>
    <div class="err-message" *ngIf="errorOccurred">
        <div class="error-header">
            {{ errorOccurred.header }}
        </div>
        <div class="error-body" *ngIf="errorOccurred.body">
            {{ errorOccurred.body }}
        </div>
    </div>
</div>
<div class="action-buttons">
    <button
        class="confirm-address-change"
        (click)="updateClicked()"
        [disabled]="
            ('PURE1:write:array' | isNotAllowed) || (errorOccurred && errorOccurred.errorCode === errorEnum.DENY_LIST)
        "
    >
        <ng-container *ngIf="errorOccurred && errorOccurred.errorCode === errorEnum.SEARCH; else regularText"
            >Submit</ng-container
        >
        <ng-template #regularText>Update</ng-template>
    </button>
    <button
        class="cancel-address-change"
        (click)="cancelClicked()"
        [angulartics2On]="'click'"
        [angularticsCategory]="'Action'"
        [angularticsAction]="analyticsPrefix + analyticsStep"
        [angularticsLabel]="'Cancel button clicked'"
        >Cancel</button
    >
</div>
<div class="p-loading">
    <pureui-spinner [pureuiShow]="updating"></pureui-spinner>
</div>
