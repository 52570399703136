import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import { NpsSurvey } from '../models/NpsSurvey';
import { INpsSurveyFeedback, NpsSurveyService } from '../services/nps-survey.service';
import { WINDOW } from '../../app/injection-tokens';

const SCORE_OPTIONS = Array.from(Array(11).keys());

@Component({
    selector: 'feedback-form',
    templateUrl: './feedback-form.component.html',
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [style({ height: 0 }), animate('100ms', style({ height: '*' }))]),
        ]),
    ],
})
export class FeedbackFormComponent implements OnInit {
    scores = SCORE_OPTIONS;

    survey: NpsSurvey;
    selectedScore: number;
    textFeedback: string;
    submitStarted: boolean; // after submit button is clicked, it will be disabled
    submitCompleted: boolean; // after submission completed, switch to thank you message

    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private npsSurveyService: NpsSurveyService,
    ) {}

    ngOnInit(): void {
        this.resetInputs();

        this.npsSurveyService.currentSurvey$.pipe(takeUntil(this.destroy$)).subscribe(survey => {
            this.survey = survey;
            this.resetInputs();
        });

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.closeForm();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    onSelectScore(score: number): void {
        this.selectedScore = score;
    }

    submitFeedback(): void {
        if (this.submitStarted) {
            return;
        }

        this.submitStarted = true;

        const feedback: INpsSurveyFeedback = {
            survey_id: this.survey.surveyId,
            score: this.selectedScore,
            feedback_text: this.textFeedback,
            page_target: this.window.location.pathname,
        };

        this.npsSurveyService.submitFeedback(feedback).subscribe(() => {
            this.submitCompleted = true;
            this.npsSurveyService.refreshSurvey();
        });
    }

    resetInputs(): void {
        this.selectedScore = null;
        this.textFeedback = null;
        this.submitStarted = false;
        this.submitCompleted = false;
    }

    closeForm(): void {
        this.survey = null;
    }
}
