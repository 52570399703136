import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_DECIMAL = 2;
const MB_PER_SEC_PER_TIB = ' MB/s/TiB';

@Pipe({ name: 'formatBandwidthPerCapacity' })
export class FormatBandwidthPerCapacityPipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');
    transform(value: number, decimal = DEFAULT_DECIMAL): string {
        if (typeof value !== 'number' || isNaN(value) || value < 0) {
            return null;
        }

        return (
            FormatBandwidthPerCapacityPipe.decimalPipe.transform(value, `1.${decimal}-${decimal}`) + MB_PER_SEC_PER_TIB
        );
    }
}
