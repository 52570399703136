import { Component, Input, OnChanges } from '@angular/core';
import { ArraySafeModeStatus } from '@pure1/data';
import { SafeModeStatusString, getSafeModeStatusStringFromStatus } from '../../../safemode/util';

@Component({
    selector: 'safemode-shield-icon',
    templateUrl: 'safemode-shield-icon.component.html',
})
export class SafemodeShieldIconComponent implements OnChanges {
    @Input() readonly arraySafeModeStatus: ArraySafeModeStatus;
    @Input() readonly iconHeight = 18;

    safeModeClass: string;
    safeModeDisplayString: SafeModeStatusString;
    safeModeTooltipString: string;

    ngOnChanges(): void {
        this.safeModeDisplayString = getSafeModeStatusStringFromStatus(this.arraySafeModeStatus);
        this.safeModeTooltipString = this.safeModeDisplayString;
        switch (this.safeModeDisplayString) {
            case 'Enabled':
                this.safeModeClass = 'safemode-enabled';
                break;
            case 'Granular Enabled':
                this.safeModeClass = 'safemode-enabled';
                break;
            case 'Partial':
                this.safeModeClass = 'safemode-partial';
                break;
            case 'Upgrade Required':
                this.safeModeClass = 'safemode-upgrade-required';
                break;
            default:
                this.safeModeClass = 'safemode-disabled';
        }
    }
}
