import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { FileSystemReplicaLink } from '../models/file-system-replica-link';

@Injectable({ providedIn: 'root' })
export class FileSystemReplicaLinksService extends GenericService<FileSystemReplicaLink> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: FileSystemReplicaLink,
            endpoint: '/rest/v3/file-system-replica-links',
        });
    }
}
