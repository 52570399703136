import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { DirectoryTreeNode } from '../models/directory-tree-node';

@Injectable({ providedIn: 'root' })
export class DirectoryTreeNodeService extends GenericService<DirectoryTreeNode> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: DirectoryTreeNode,
            endpoint: '/rest/v3/directory-tree',
            defaultParams: {
                pageStart: null,
                pageSize: null,
                sort: {
                    key: 'name',
                    order: 'asc',
                },
            },
        });
    }
}
