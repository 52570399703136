import { Subject } from 'rxjs';
import { distinctUntilChanged, sampleTime, takeUntil } from 'rxjs/operators';
import { Chart, Point, charts as highchartsInstances, PointerEventObject } from 'highcharts/highstock';
import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy, NgZone } from '@angular/core';

import { getChartsUnderElement } from '../../utils/highcharts';

/**
 * Place this directive on an element containing multiple Highcharts instances to have it synchronize
 * the tooltip between each of them like magic.
 * NOTE: This requires that you do NOT use shared tooltips (chartOptions.tooltip.shared = false). You can
 * still achieve a "shared" tooltip without this setting by using the input point as a reference point,
 * and manually searching for the point for each other series. See VMTimelineChartComponent for an example.
 */
@Directive({
    selector: '[synchronizedChartTooltips]',
})
export class SynchronizedChartTooltipsDirective implements AfterViewInit, OnDestroy {
    private unregisterListeners: Function[];

    private readonly update$ = new Subject<MouseEvent>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private ngZone: NgZone,
    ) {}

    ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular(() => {
            const eventNames = ['mousemove', 'touchstart', 'touchmove'];
            this.unregisterListeners = eventNames.map(eventName => {
                return this.renderer.listen(this.el.nativeElement, eventName, e => this.update$.next(e));
            });

            this.update$
                .pipe(
                    distinctUntilChanged(null, event => event.x), // Don't make multiple updates for the same x-axis value
                    sampleTime(Math.floor(1000 / 60)), // Limit update frequency to 60 FPS
                    takeUntil(this.destroy$),
                )
                .subscribe(event => {
                    try {
                        this.mouseMoveHandler(event);
                    } catch (err) {
                        console.error('Failed to update tooltips', err);
                    }
                });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();

        if (this.unregisterListeners) {
            this.unregisterListeners.forEach(f => f());
        }
    }

    private mouseMoveHandler(e: MouseEvent): void {
        NgZone.assertNotInAngularZone();

        if (!highchartsInstances) {
            return;
        }

        getChartsUnderElement(this.el.nativeElement).forEach(chart => {
            // Get the point for each series
            const normEvent = chart.pointer.normalize(e as PointerEvent);
            const points = this.getSeriesPoints(chart, normEvent);

            if (points.length === 0) {
                return;
            }

            // Show the tooltip on the single point closest to the cursor
            const chartExtremes = chart.xAxis[0].getExtremes();
            const duration = chartExtremes.max - chartExtremes.min;
            const closestCloseFlag = this.getClosestCloseFlag(chart, normEvent, duration * 0.01);
            if (closestCloseFlag) {
                // if we update crosshair position when the flag is not exactly under the cursor it jumps around
                this.showTooltip(closestCloseFlag, normEvent, false);
            } else {
                const closestPoint = this.getClosestPoint(points, normEvent);
                this.showTooltip(closestPoint, normEvent);
            }
        });

        e.stopPropagation?.();
    }

    /**
     * Gets the point for each series that is closest to the cursor
     */
    private getSeriesPoints(chart: Chart, normEvent: PointerEventObject): Point[] {
        return chart.series
            .filter(series => series && series.visible)
            .map(series => (<any>series).searchPoint(normEvent, true) as Point)
            .filter(point => point != null);
    }

    private distanceFromCursor(point: Point, cursorXValue: number): number {
        return Math.abs(point.x - cursorXValue);
    }

    /**
     * For a set of points, gets the single point closest to the origin of the event
     */
    private getClosestPoint(points: Point[], normEvent: PointerEventObject): Point {
        const referenceSeries = points[0].series;
        const cursorXValue = referenceSeries.xAxis.toValue(normEvent.chartX, false);

        return points.sort(
            (a, b) => this.distanceFromCursor(a, cursorXValue) - this.distanceFromCursor(b, cursorXValue),
        )[0];
    }

    /**
     * Find the flag series in a given chart, and return the closest flag to the origin of the event within a certain distance threshold
     */
    private getClosestCloseFlag(chart: Chart, normEvent: PointerEventObject, flagClosenessThreshold: number): Point {
        const cursorXValue = chart.series[0].xAxis.toValue(normEvent.chartX, false);
        const closeFlags: Point[] = chart.series
            .filter(series => series.type === 'flags')
            .map(series =>
                series.data.find(point => this.distanceFromCursor(point, cursorXValue) < flagClosenessThreshold),
            )
            .filter(point => point != null);

        if (closeFlags.length === 0) {
            return null;
        }

        return closeFlags.sort(
            (a, b) => this.distanceFromCursor(a, cursorXValue) - this.distanceFromCursor(b, cursorXValue),
        )[0];
    }

    /**
     * Activates the tooltip for the given point
     */
    private showTooltip(point: Point, normEvent: PointerEventObject, drawCrosshair = true): void {
        const chart = point.series.chart;
        if (chart?.tooltip) {
            chart.tooltip.refresh(point);
            if (drawCrosshair) {
                chart.xAxis[0].drawCrosshair(normEvent, point);
            }
        }
    }
}
