import moment from 'moment';

export interface UserNotificationLink {
    displayName: string;
    url: string;
    openInNewTab: boolean;
}

export class UserNotification implements UserNotification {
    id: string;
    read: boolean;
    title: string;
    body: string;
    image: string;
    path: string | null;
    kbArticleLink: string;
    startDate: moment.Moment;
    hideReleaseNotes: boolean;
    isComingSoon: boolean;
    customLinks: UserNotificationLink[];
    // temporary flag to show update notification [CLOUD-107594]
    isUpdate: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.title = json.title;
        this.body = json.body;
        this.image = json.image;
        this.path = json.path;
        this.kbArticleLink = json.kbArticleLink;
        this.startDate = json.startDate ? moment(json.startDate) : undefined;
        this.read = typeof json.read === 'boolean' ? json.read : false;
        this.hideReleaseNotes = json.hideReleaseNotes;
        this.isComingSoon = !!json.comingSoon;
        // parse object to list with name and url
        this.customLinks = json.customLinks || [];
        this.isUpdate = json.isUpdate || false;
    }
}
