/**
 * @license: Taken from the ngx-moment: https://github.com/urish/ngx-moment/
 * time-ago.pipe.ts
 * ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence
 */

import { Pipe, ChangeDetectorRef, PipeTransform, OnDestroy, NgZone, Inject } from '@angular/core';
import moment from 'moment';
import { WINDOW } from '../../app/injection-tokens';

@Pipe({ name: 'fromNow', pure: false })
export class FromNowPipe implements PipeTransform, OnDestroy {
    private currentTimer: number;
    private lastTime: Number;
    private lastValue: moment.Moment;
    private lastOmitSuffix: boolean;
    private lastLocale?: string;
    private lastText: string;

    constructor(
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone,
        @Inject(WINDOW) private window: Window,
    ) {}

    transform(value: Date | moment.Moment | number, omitSuffix?: boolean): string {
        if (!value) {
            return null;
        }
        const momentValue = value instanceof Date || typeof value === 'number' ? moment(value) : value;
        if (this.hasChanged(momentValue, omitSuffix)) {
            this.lastValue = momentValue;
            this.lastTime = momentValue.valueOf();
            this.lastOmitSuffix = omitSuffix;
            this.lastLocale = this.lastValue.locale();
            this.removeTimer();
            this.createTimer();
            this.lastText = this.lastValue.from(moment(), omitSuffix);
        } else {
            this.createTimer();
        }

        return this.lastText;
    }

    ngOnDestroy(): void {
        this.removeTimer();
    }

    private createTimer(): void {
        if (this.currentTimer) {
            return;
        }
        const timeToUpdate = this.getMillisecondsUntilUpdate(this.lastValue);
        this.currentTimer = this.ngZone.runOutsideAngular(() => {
            return this.window.setTimeout(() => {
                this.lastText = this.lastValue.from(moment(), this.lastOmitSuffix);
                this.currentTimer = null;
                this.ngZone.run(() => this.cdRef.markForCheck());
            }, timeToUpdate);
        });
    }

    private removeTimer(): void {
        if (this.currentTimer) {
            this.window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }

    private getMillisecondsUntilUpdate(momentInstance: moment.Moment): number {
        const howOld = Math.abs(moment().diff(momentInstance, 'minute'));
        if (howOld < 1) {
            return moment.duration(1, 'second').asMilliseconds();
        } else if (howOld < 60) {
            return moment.duration(30, 'second').asMilliseconds();
        } else if (howOld < 180) {
            return moment.duration(5, 'minute').asMilliseconds();
        } else {
            return moment.duration(1, 'hour').asMilliseconds();
        }
    }

    private hasChanged(value: moment.Moment, omitSuffix?: boolean): boolean {
        return (
            value.valueOf() !== this.lastTime ||
            value.locale() !== this.lastLocale ||
            omitSuffix !== this.lastOmitSuffix
        );
    }
}
