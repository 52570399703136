import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is for use case when want to show data in html templates and we want to use function for that
 * For now the pipe allow only one function parameter, but feel free to extend this pipe
 * If you want to use in the function "this" it needs to be an arrow function(lambda) or use bind()
 */
@Pipe({
    name: 'memoizeFnc',
    pure: true,
})
export class MemoizeFncPipe implements PipeTransform {
    transform(value: any, fnc: (value: any) => any): any {
        return fnc(value);
    }
}
