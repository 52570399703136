import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';

import { CaseComment, CommentComposite, ItemType } from '../support.interface';
import { catchError, first, map } from 'rxjs/operators';
import { SupportErrorService } from './error.service';

@Injectable()
export class CaseCommentService {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 29000; // 29 seconds
    private endpoint = '/rest/v1/support/cases';

    constructor(
        private http: HttpClient,
        private errorService: SupportErrorService,
    ) {}

    @Cacheable({
        maxAge: CaseCommentService.cacheTTL,
        cacheBusterObserver: CaseCommentService.cacheBuster$,
    })
    getCaseComments(caseId: string): Observable<CaseComment[]> {
        return this.http.get<CommentComposite[]>(`${this.endpoint}/${caseId}/comments`).pipe(
            first(),
            map((comments: CommentComposite[]) => comments.map(c => this.compositeHelper(c))),
        );
    }

    @Cacheable({
        maxAge: CaseCommentService.cacheTTL,
        cacheBusterObserver: CaseCommentService.cacheBuster$,
    })
    getCaseCommentById(caseId: string, commentId: string): Observable<CaseComment> {
        return this.http.get<CaseComment>(`${this.endpoint}/${caseId}/comments/${commentId}`);
    }

    @CacheBuster({
        cacheBusterNotifier: CaseCommentService.cacheBuster$,
    })
    addCaseComment(caseId: string, comment: Partial<CaseComment>): Observable<CaseComment> {
        return this.http
            .post<CaseComment>(`${this.endpoint}/${caseId}/comments`, comment)
            .pipe(catchError(this.errorService.errorInterceptor));
    }

    private compositeHelper = (compositeData: CommentComposite): CaseComment => {
        if (!compositeData) {
            return;
        }

        return {
            ...compositeData.comment,
            createdByUser: compositeData.createdByUser,
            itemType: ItemType.Comment,
        };
    };
}
