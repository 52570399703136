import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pluralize' })
export class PluralizePipe implements PipeTransform {
    transform(count: number, singularStr: string, pluralStr?: string): string {
        if (count === 1) {
            return singularStr;
        } else {
            if (!isFinite(count)) {
                console.warn(`pluralize pipe count: ${count} is not finite!`);
            }
            return pluralStr || singularStr + 's';
        }
    }
}
