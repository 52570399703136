import { Pipe, PipeTransform } from '@angular/core';

// Despite the name, this has been updated to work with numbers too
@Pipe({ name: 'defaultString' })
export class DefaultStringPipe implements PipeTransform {
    transform(value: any): string {
        if (typeof value === 'string' && value.length > 0) {
            return value;
        } else if (typeof value === 'number' && isFinite(value)) {
            return value.toString();
        } else if (
            value === null ||
            value === undefined ||
            (typeof value === 'string' && value.length === 0) ||
            (typeof value === 'number' && !isFinite(value))
        ) {
            return '–';
        } else {
            console.warn('Expected a string, number, null, or undefined, but got:', value);
            return value.toString();
        }
    }
}
