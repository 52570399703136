import {
    Directive,
    Input,
    OnDestroy,
    ViewContainerRef,
    TemplateRef,
    OnInit,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { AuthzAuthorizerService } from '@pure1/data';
import { forkJoin, of, Subject, switchMap } from 'rxjs';
import { defaultIfEmpty, take, takeUntil } from 'rxjs/operators';

/**
 * @deprecated use {@link AuthzDirective}, {@link AuthzPipe} or {@link AuthorizationServiceResolver} instead
 */
@Directive({
    selector: '[authzAnd], [authzOr]',
})
export class AuthzPermissionsDirective implements OnInit, OnDestroy, OnChanges {
    @Input() readonly authzAnd?: string[];
    @Input() readonly authzOr?: string[];

    private readonly destroy$ = new Subject<void>();
    private isDisplayed = false;
    private requiredPermissions$ = new Subject<string[]>();

    constructor(
        private authorizerService: AuthzAuthorizerService,
        private view: ViewContainerRef,
        private template: TemplateRef<any>,
    ) {}

    ngOnInit(): void {
        this.requiredPermissions$
            .pipe(
                switchMap(requiredPermissions =>
                    forkJoin(
                        requiredPermissions.map(permission =>
                            this.authorizerService.hasPermission(permission).pipe(take(1), takeUntil(this.destroy$)),
                        ),
                    ).pipe(
                        switchMap(results => of(this.shouldBeDisplayed(results))),
                        defaultIfEmpty(true),
                    ),
                ),
            )
            .subscribe(shouldDisplay => {
                if (shouldDisplay && !this.isDisplayed) {
                    this.view.createEmbeddedView(this.template);
                } else {
                    this.view.clear();
                }
                this.isDisplayed = shouldDisplay;
            });
        this.refresh();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.authzIf) {
            this.refresh();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    refresh(): void {
        this.requiredPermissions$.next(this.authzAnd || this.authzOr || []);
    }

    shouldBeDisplayed(permissionsAllowed: boolean[]): boolean {
        if (this.authzOr) {
            return permissionsAllowed.some(Boolean);
        } else {
            return permissionsAllowed.every(Boolean);
        }
    }
}
