import { DraasApiExecutionPlanReport } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryReport } from './disaster-recovery-report';
import moment from 'moment/moment';
import { Moment } from 'moment';

export class DisasterRecoveryExecutionPlanReport extends DisasterRecoveryReport {
    date: Moment;
    initiatorId: string;

    constructor(json: DraasApiExecutionPlanReport) {
        super(json);
        this.initiatorId = json.initiator_id;
        this.date = moment.utc(json.aggregated_execution_info.started);
    }
}
