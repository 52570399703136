import { NgModule } from '@angular/core';
import {
    NotificationCenterPageComponent as NcNotificationCenterPageComponent,
    NotificationPreferencesComponent as NcNotificationPreferencesComponent,
} from '@pure/notification-center';
import { NotificationCenterPageComponent } from './notification-center-page/notification-center-page.component';
import { NotificationCenterRoutingModule } from './notification-center-routing.module';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';

@NgModule({
    imports: [NotificationCenterRoutingModule, NcNotificationCenterPageComponent, NcNotificationPreferencesComponent],
    declarations: [NotificationCenterPageComponent, NotificationPreferencesComponent],
})
export class NotificationCenterModule {}
