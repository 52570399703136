import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

/**
 * Sets the Content-Type HTTP header on non-GET requests that do not explicitly have a Content-Type already specified.
 */
@Injectable()
export class HttpContentTypeInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Note: If Content-Type is not specified, Angular ends up using "text/plain"

        if (
            request.method !== 'GET' &&
            request.body != null &&
            !request.headers?.get('Content-Type') &&
            !(request.body instanceof FormData)
        ) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
            });
        }

        return next.handle(request);
    }
}
