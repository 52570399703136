import { formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    hasCapacityChange,
    hasControllerChange,
    Incident,
    isEOLDetails,
    isEvergreenOneDetails,
    isLeanSas2NvmeDetails,
} from '@pure1/data';
import moment from 'moment';

import { PerformanceAnimations } from '../../../performance/animations/performance-animations';

@Component({
    selector: 'proactive-recommendation-card',
    templateUrl: 'proactive-recommendation-card.component.html',
    animations: [PerformanceAnimations.collapseTrigger],
})
export class ProactiveRecommendationCardComponent {
    @Input() readonly recommendation: Incident;
    @Input() readonly isDismissEnabled: boolean = false;
    @Output() readonly openDismiss = new EventEmitter<void>();

    clickDismiss(event: Event): void {
        event.stopPropagation();
        this.openDismiss.emit();
    }

    getCurrentPercent(): string {
        return this.recommendation.getCapacitySparklineDetails()?.getCurrentPercentCapacity();
    }

    getProjectedPercent(): string {
        return this.recommendation.getCapacitySparklineDetails()?.getProjectedPercentCapacity();
    }

    getTimerangeText(): string {
        return `In ${this.recommendation.getCapacitySparklineDetails()?.timerangeDays} days`;
    }

    getSparkline(): [number, number][] {
        return this.recommendation.getCapacitySparklineDetails()?.sparkline.timeseries;
    }

    getEntityName(): string {
        return this.recommendation.getEntityName();
    }

    getControllerMessage(): string {
        if (this.recommendation.isSeRec()) {
            return 'Your Pure Systems Engineer recommended a controller upgrade to improve appliance performance.';
        } else {
            return 'This recommendation is generated using multi-factor indicators such as latency, load & I/O size.';
        }
    }

    getLeanSas2NvmeMessage(): string {
        return 'Upgrade to NVMe to achieve better performance with the latest in storage technology.';
    }

    getCapacityProblem(): string {
        return `is projected to reach ${this.recommendation.getCapacityDetails()?.getProjectedPercentCapacity()} capacity in ${this.recommendation.getCapacityDetails()?.timerangeDays} days`;
    }

    getEOneProblem(): string {
        return `is projected to exceed reserve in ${this.recommendation.getEvergreenOneDetails()?.currentDaysToOnDemand} days.`;
    }

    shouldHaveCapacityRecommendationMessage(): boolean {
        const details = this.recommendation.additionalInformation;
        if (isEvergreenOneDetails(details)) {
            return details.recommendedReserveTiB != null;
        } else if (hasCapacityChange(details) && details.newInstalledTB) {
            // check for truthyness on newInstalledTB:
            //  A recent change to include an empty hardware_config for controller only
            //  recommendations means that a zero value for newInstalledTB indicates there
            //  is no capacity change for this recommendation
            return true;
        } else {
            return false;
        }
    }

    getRecBlackText(): string {
        const details = this.recommendation.additionalInformation;
        if (isEvergreenOneDetails(details)) {
            return `Expand from ${this.format(details.currentReserveTiB)} TiB to`;
        } else if (!hasCapacityChange(details)) {
            return null;
        } else if (isEOLDetails(details) || isLeanSas2NvmeDetails(details)) {
            return `Upgrade from SAS ${this.format(details.currentInstalledTB)} TB to`;
        } else {
            return `Expand from ${this.format(details.currentInstalledTB)} TB to`;
        }
    }

    getRecGreenText(): string {
        const details = this.recommendation.additionalInformation;
        if (isEvergreenOneDetails(details)) {
            return `${this.format(details.recommendedReserveTiB)} TiB Reserved`;
        } else if (!hasCapacityChange(details)) {
            return null;
        } else if (isEOLDetails(details) || isLeanSas2NvmeDetails(details)) {
            return `NVMe ${this.format(details.newInstalledTB)} TB`;
        } else {
            return `${this.format(details.newInstalledTB)} TB`;
        }
    }

    getControllerRecBlackText(): string {
        const details = this.recommendation.additionalInformation;
        if (!hasControllerChange(details)) {
            return null;
        }
        return `Upgrade from ${details.currentModel} to`;
    }

    getControllerRecGreenText(): string {
        const details = this.recommendation.additionalInformation;
        if (!hasControllerChange(details)) {
            return null;
        }
        return details.recommendedModel;
    }

    getEOLMessage(): string {
        if (this.recommendation.isM2XRecommendation() || this.recommendation.isEoL20Recommendation()) {
            return `An appliance in your Evergreen//Foundation subscription is approaching the end of life`;
        } else if (this.recommendation.is6GRecommendation() || this.recommendation.isEoL11Recommendation()) {
            return `A component in your Evergreen//Foundation subscription is approaching the end of life`;
        } else {
            return null;
        }
    }

    getEOLNumDays(): string {
        const details = this.recommendation.additionalInformation;
        if (!isEOLDetails(details)) {
            return null;
        }
        const expire = details.eolDate;
        if (expire == null) {
            return '--';
        }
        const numDays = moment.duration(expire.diff(moment())).asDays();
        return Math.max(numDays, 0).toFixed();
    }

    format(num: number): string {
        return formatNumber(num, 'en-US', '1.0-1');
    }

    getCurrOffset(): number {
        return this.getOffset(this.recommendation.getCapacitySparklineDetails()?.getSparklineCurrentCapacity());
    }

    getProjectedOffset(): number {
        return this.getOffset(this.recommendation.getCapacitySparklineDetails()?.getSparklineProjectedCapacity());
    }

    getDisplayRecModel(): string {
        const details = this.recommendation.additionalInformation;
        if (!hasControllerChange(details)) {
            return null;
        }
        return details.recommendedModel;
    }

    getOffset(val: number): number {
        if (val == null) {
            return 0;
        }
        const max = this.recommendation.getCapacitySparklineDetails().getSparklineMaxCapacity();
        // Calculate what percent down on the chart the value is
        const ratio = (max - val) / max;
        // Pill at max value aligns at-3px down and at 0 lines up at 62px down
        const offset = 65 * ratio - 3;
        // Don't offset more than 39 to avoid covering "Now" or "In X days" text
        return Math.min(offset, 39);
    }

    getSourceIcon(): string {
        if (this.recommendation.isSeRec()) {
            return 'icon-recommendation-human.svg';
        } else {
            return 'icon-recommendation-bulb.svg';
        }
    }

    getRecSourceTooltip(): string {
        if (this.recommendation.isEOLRecommendation()) {
            return 'The system suggests this improvement.';
        } else if (this.recommendation.isSeRec() && this.recommendation.getSeName() != null) {
            return `This improvement is recommended by your Systems Engineer ${this.recommendation.getSeName()}.`;
        } else if (this.recommendation.isSeRec()) {
            // Gracefully handle missing SE name
            return `This improvement is recommended by your Pure Systems Engineer.`;
        } else {
            return `This improvement is recommended by our machine learning model.`;
        }
    }
}
