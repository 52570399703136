import { Angulartics2Module } from 'angulartics2';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StepUpModule } from '../step-up/step-up.module';

import { UIModule } from '../ui/ui.module';
import { UMEditUserComponent } from './um-edit-user/um-edit-user.component';
import { UMUserFormComponent } from './um-user-form/um-user-form.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { RegularUserViewComponent } from './regular-user-view/regular-user-view.component';
import { AdminUserViewComponent } from './admin-user-view/admin-user-view.component';
import { SwitchUserModalComponent } from './switch-user-modal/switch-user-modal.component';
import { TitleWithSwitchComponent } from './title-with-switch/title-with-switch.component';
import { GfbModule } from '../gfb/gfb.module';
import { UMSsoUserFormComponent } from './um-sso-user-form/um-sso-user-form.component';
import { UserManagementSharedModule } from '../user-management-shared/user-management-shared.module';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        Angulartics2Module,
        CommonModule,
        FormsModule,
        GfbModule,
        NgbModule,
        ReactiveFormsModule,
        UIModule,
        RouterModule,
        StepUpModule,
        UserManagementSharedModule,
        AuthorizerModule,
    ],
    declarations: [
        AdminUserViewComponent,
        UMEditUserComponent,
        RegularUserViewComponent,
        UMSsoUserFormComponent,
        SwitchUserModalComponent,
        TitleWithSwitchComponent,
        UMUserFormComponent,
        UserModalComponent,
    ],
    exports: [UMEditUserComponent],
})
export class UserManagementModule {}
