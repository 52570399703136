import { Resource } from '../interfaces/resource';
import { LicenseType } from './license';
import { ProgramType } from './subscription';
import { ServiceCatalogQuotingOptions } from './service-catalog-quoting-options';
import { ServiceCatalogStructuredPricing } from './service-catalog-structured-pricing';
import { ServiceCatalogCTA } from './service-catalog-cta';

export const OPEN_CATALOG_NEW_HW_IN_PLANNER_KEY = 'open-catalog-new-hw-in-planning';

export interface SectionJson {
    title: string;
    details: string;
    link: string;
    icon: string;
}

interface FormattedPriceTable {
    headers: string[];
    rows: string[][];
    name: string[];
}

interface Availability {
    name: string;
    region: string;
    code: string;
}

export enum SectionName {
    keyFeatures = 'keyFeatures',
    useCases = 'useCases',
    documentations = 'documentations',
    prices = 'prices',
    promotions = 'promotions',
    availability = 'availability',
}

export class ServiceCatalogOffering implements Resource {
    id: string;
    name: string;
    serviceName: string;
    productName: string;
    groupName: string;
    description: string;
    detailDescription: string;
    highlights: string[];
    allUseCases: string[];
    useCasesDescription: string; // this is for the "Use Cases" section, not each use case
    canRequestQuote: boolean;
    canDoTrial: boolean;
    keyFeatures: SectionJson[];
    keyFeaturesDescription: string; // this is for the "Key Features" section, not each feature
    keyFeaturesIcon: string;
    documentations: SectionJson[];
    documentationsIcon: string;
    prices: FormattedPriceTable[];
    pricesDescription: string;
    promotions: any[]; // type TBD
    promotionsDescription: string;
    ordinal: number;
    shouldSendEmail: boolean;
    ctas: ServiceCatalogCTA[];
    keyFeaturesTitle: string;
    useCasesTitle: string;
    documentationsTitle: string;
    pricesTitle: string;
    promotionsTitle: string;
    immutableName: string;
    preAllowed: boolean;

    // Optional properties
    availabilities?: Availability[];
    availabilitiesTitle?: string;
    availabilitiesDescription?: string;
    colorTheme?: string;
    licenseType?: LicenseType;
    licenseTypes?: LicenseType[];
    programType?: ProgramType;
    icon?: string;
    priceFormat?: string;
    structuredPricings: ServiceCatalogStructuredPricing[];
    quotingOptions?: ServiceCatalogQuotingOptions[];
    summaryIcon: string;
    summaryProductName: string;

    // Derived field
    compoundedName: string;

    constructor(json: any, group_name: string, service_name: string) {
        this.id = json.id;
        this.name = json.id;
        this.productName = json.product_name;
        this.groupName = group_name;
        this.serviceName = service_name;
        this.description = json.description;
        this.highlights = json.highlights?.slice(0, 4); // we only take 4 at most
        this.allUseCases = json.all_use_cases;
        this.useCasesDescription = json.use_cases_description;
        this.canRequestQuote = json.can_request_quote;
        this.canDoTrial = json.can_do_trial;
        this.keyFeatures = json.key_features;
        this.keyFeaturesDescription = json.key_features_description;
        this.keyFeaturesIcon = json.key_features_icon;
        this.documentations = json.documentations;
        this.documentationsIcon = json.documentations_icon;
        this.prices = json.prices;
        this.pricesDescription = json.prices_description;
        this.promotions = json.promotions;
        this.promotionsDescription = json.promotion_description;
        this.ordinal = json.ordinal;
        this.shouldSendEmail = json.should_send_email;
        this.ctas = this.parseAllCtas(json.ctas);
        this.keyFeaturesTitle = json.key_features_title;
        this.documentationsTitle = json.documentations_title;
        this.useCasesTitle = json.use_cases_title;
        this.pricesTitle = json.prices_title;
        this.promotionsTitle = json.promotions_title;
        this.immutableName = json.immutable_name;
        this.preAllowed = json.pre_allowed;

        // Optional values
        this.availabilities = json.availabilities;
        this.availabilitiesTitle = json.availabilities_title;
        this.availabilitiesDescription = json.availabilities_description;
        this.detailDescription = json.detail_description;
        this.colorTheme = json.color_theme;
        this.licenseType = json.license_type;
        this.licenseTypes = json.license_types;
        this.programType = json.program_type;
        this.icon = json.icon || 'catalog-offering-default-icon.svg';
        this.priceFormat = json.price_format;

        if (this.priceFormat === 'structured_pricing' && json.structured_pricings?.length > -1) {
            this.structuredPricings = json.structured_pricings.map(
                strPrice => new ServiceCatalogStructuredPricing(strPrice),
            );
            this.prices = this.structuredPricings.map(strPrice => strPrice.generatePriceTable());
        }

        this.quotingOptions = this.parseAllQuotingOptions(json.quoting_options);
        this.summaryIcon = json.summary?.icon;
        this.summaryProductName = json.summary?.product_name;

        this.compoundedName = this.serviceName + this.groupName + this.productName + (this.licenseType ?? '');
    }

    private parseAllQuotingOptions(json: any[]): ServiceCatalogQuotingOptions[] {
        if (!json) {
            return null;
        }
        return json.map(offeringJson => new ServiceCatalogQuotingOptions(offeringJson));
    }

    private parseAllCtas(json: any[]): ServiceCatalogCTA[] {
        if (!json) {
            return null;
        }
        return json.map(ctasJson => new ServiceCatalogCTA(ctasJson));
    }

    isValid(sectionName: String): boolean {
        if (this) {
            switch (sectionName) {
                case SectionName.keyFeatures:
                    return this.keyFeatures?.length > 0 || this.keyFeaturesDescription?.length > 0;
                case SectionName.useCases:
                    return this.allUseCases?.length > 0 || this.useCasesDescription?.length > 0;
                case SectionName.documentations:
                    return this.documentations?.length > 0;
                case SectionName.prices:
                    return this.prices?.length > 0 || this.pricesDescription?.length > 0;
                case SectionName.promotions:
                    return this.promotions?.length > 0 || this.promotionsDescription?.length > 0;
                case SectionName.availability:
                    return this.availabilities?.length > 0 || this.availabilitiesDescription?.length > 0;
                default:
                    return false;
            }
        }
    }

    isCapexOffering(): boolean {
        return this.groupName === 'FA' || this.groupName === 'FB';
    }
}
