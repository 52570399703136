<ng-template #confirmModal let-close="close">
    <div class="modal-body" style="display: flex; flex-direction: column; align-items: center">
        <ng-container *ngIf="createCaseDoneFlag; else confirmModalLoading">
            <ng-container *ngIf="!createCaseErrorFlag; else confirmModalError">
                <div>
                    <pureui-svg class="pstg-action-icon" svg="checkmark-circle.svg" height="64"></pureui-svg>
                </div>
                <br />
                <h5
                    ><b>Case ID: #{{ caseId }}</b></h5
                >
                <br />
            </ng-container>
            <hive-button emphasis="primary" size="medium" label="Close" (click)="close(caseId)"></hive-button>
        </ng-container>
        <ng-template #confirmModalLoading>
            <br />
            <pureui-spinner class="p-loading" [pureuiShow]="true"></pureui-spinner>
            <br />
        </ng-template>
        <ng-template #confirmModalError>
            <pureui-svg style="margin: 20px" class="pstg-action-icon" svg="warning-icon.svg" height="64"></pureui-svg>
            <h4>Oops! Something went wrong.</h4>
            <div>Please try again. If the issue persists, contact Pure1 Technical Services.</div>
            <div style="height: 40px"></div>
        </ng-template>
    </div>
</ng-template>

<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
        <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Dispute Invoice </h3>
</div>

<form class="modal-body" [formGroup]="disputeInvoiceForm">
    <h1 class="section-header">Information</h1>
    <div class="row">
        <div class="col-xs-4">
            <label class="dispute-invoice-label required"> Reason </label>
        </div>
        <div class="col-xs-8">
            <hive-multiselect [(selection)]="selectedReasons" placeholder="Select a reason">
                <ng-container *ngFor="let reason of possibleReasons">
                    <hive-selection-menu-item [value]="reason" [label]="reason"> </hive-selection-menu-item>
                </ng-container>
            </hive-multiselect>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4">
            <label class="dispute-invoice-label"> Category </label>
        </div>
        <div class="col-xs-8 fixed-value">
            {{ categoryDisplayName }}
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4">
            <label class="dispute-invoice-label"> Product Name </label>
        </div>
        <div class="col-xs-8 fixed-value">
            {{ invoice.contractNumber | defaultString }}
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4">
            <label class="dispute-invoice-label"> Subject </label>
        </div>
        <div class="col-xs-8 fixed-value">
            {{ invoice.id }}
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4">
            <label class="dispute-invoice-label required"> Description </label>
        </div>
        <div class="col-xs-8">
            <textarea
                class="form-control dispute-invoice-description"
                label="Description"
                formControlName="description"
            ></textarea>
        </div>
    </div>
    <contact-section formControlName="contacts"></contact-section>
</form>

<div class="modal-footer">
    <hive-button emphasis="secondary" label="Cancel" (click)="modal.dismiss()"></hive-button>
    <hive-button
        class="dispute-invoice-submit"
        emphasis="primary"
        label="Submit"
        (click)="submitDisputeInvoice()"
        [disabled]="isDisputeButtonDisabled()"
    ></hive-button>
</div>
