import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriptionUsageAggregations } from '../models/subscription-usage-aggregations';
import { GenericService } from './generic.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionAggreggationService extends GenericService<SubscriptionUsageAggregations> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SubscriptionUsageAggregations,
            endpoint: '/rest/v1/usage-aggregation/summary',
            defaultParams: {},
        });
    }
}
