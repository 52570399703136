<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<!-- The try-button class is styled in feature-banner -->
<ng-template #xToXlUpgradeTemplate>
    <a
        [href]="notification.kbArticleLink"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Read more"
        [angularticsLabel]="notification.title"
    >
        Read More
    </a>
    |
    <a
        href="https://support.purestorage.com/Pure1/Pure1_Manage/001_Overview/000:_Pure1_Manage_Release_Notes"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Release notes"
        [angularticsLabel]="notification.title"
    >
        Release Notes
    </a>
    |
    <a
        href="https://youtu.be/l0BZb3RUrH8"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - X to XL Upgrade Video"
        class="bold-link"
        [angularticsLabel]="notification.title"
    >
        Watch a Video
    </a>
</ng-template>
