import { Resource } from '../interfaces/resource';

export class ServiceLevelAggregation implements Resource {
    readonly id: string;
    readonly name: string;

    readonly location: string;
    readonly licenseTypeStr: string;
    readonly numberOfLicenses: number;
    readonly percentageUsage: number;
    readonly usedUsage: number;
    readonly latestReservedAmount: number;
    readonly onDemandUsage: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.location = json.location;
        this.licenseTypeStr = json.license_type;
        this.numberOfLicenses = json.number_of_licenses;
        this.percentageUsage = json.percentage_usage;
        this.usedUsage = json.used_usage;
        this.latestReservedAmount = json.latest_reserved_amount;
        this.onDemandUsage = json.on_demand_usage;
    }
}
