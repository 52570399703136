import { Angulartics2Module } from 'angulartics2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '../ui/ui.module';

import { ExportButtonComponent } from './export-button/export-button.component';
import { ExportDetailsModalComponent } from './export-details-modal/export-details-modal.component';
import { ExportOverlayComponent } from './export-overlay/export-overlay.component';

@NgModule({
    imports: [Angulartics2Module, CommonModule, NgbModule, UIModule],
    declarations: [ExportButtonComponent, ExportDetailsModalComponent, ExportOverlayComponent],
    exports: [ExportButtonComponent, ExportOverlayComponent],
})
export class ExportModule {}
