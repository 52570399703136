import _ from 'lodash';
import { Observable, merge } from 'rxjs';

import { Injectable } from '@angular/core';
import { ArrayTagService, Tag } from '@pure1/data';

import { MultiResourceTagValue } from '../multi-resource-tag-value';

const MAX_RESOURCES_PER_CHUNK = 20;

@Injectable({ providedIn: 'root' })
export class ArrayTagUpdateService {
    constructor(private arrayTagService: ArrayTagService) {}

    update(tagsToUpsert: MultiResourceTagValue[], tagsToDelete: MultiResourceTagValue[]): Promise<void> {
        // Block until all backend tag changes complete
        return new Promise((resolve, reject) => {
            const deleteObservables = _.flatten(
                tagsToDelete.map(tag =>
                    this.inChunks(
                        tag.resourceIds,
                        (ids, namespace, key) => this.arrayTagService.delete(ids, namespace, key),
                        tag.namespace,
                        tag.key,
                    ),
                ),
            );

            const upsertObservables = _.flatten(
                tagsToUpsert.map(tag =>
                    this.inChunks(tag.resourceIds, (ids, tag) => this.arrayTagService.upsert(ids, tag), new Tag(tag)),
                ),
            );

            merge(...deleteObservables, ...upsertObservables).subscribe(
                () => {},
                () => {
                    reject('failed to update tags');
                },
                () => {
                    resolve();
                },
            );
        });
    }

    private inChunks<T, V>(
        tags: string[],
        serviceCall: (ids: string[], ...paramms: V[]) => Observable<T>,
        ...params: V[]
    ): Observable<T>[] {
        const result: Observable<T>[] = [];
        for (let i = 0; i < tags.length; i += MAX_RESOURCES_PER_CHUNK) {
            const chunk = tags.slice(i, i + MAX_RESOURCES_PER_CHUNK);
            result.push(serviceCall(chunk, ...params));
        }
        return result;
    }
}
