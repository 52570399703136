import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagDxpService } from '@pure1/data';
import { Observable } from 'rxjs';

// Will be removed after final switching of AWS accounts.
@Injectable()
export class CfwdHeaderInterceptor implements HttpInterceptor {
    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Do nothing for non-cfwd requests
        if (!request.url.includes('/rest/cfwd/api/')) {
            return next.handle(request);
        }

        return this.requestWithCfwdHeader(request, next);
    }

    requestWithCfwdHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedRequest = request.clone({
            //headers: request.headers.append('CFWD-Cdu-Account', 'test'),
        });

        return next.handle(clonedRequest);
    }
}
