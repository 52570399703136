import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isSubscriptionActive, SubscriptionAsset } from '../../data';
import moment from 'moment';
import { SubscriptionStatus } from '@pure1/data';
import { AssetManagementSummaryInfo } from '../asset-management-summary-card/asset-management-summary-card.component';
import { partition, uniqBy } from 'lodash';

export const ChartDetails = {
    expired: {
        color: '#AF93CA',
        name: 'Expired',
    },
    expiresIn30Days: {
        color: '#E186B2',
        name: 'Expires in 30 days',
    },
    expiresIn120Days: {
        color: '#FBD87D',
        name: 'Expires in 120 days',
    },
    current: {
        color: '#7cd8cd',
        name: 'Current',
    },
};

@Component({
    selector: 'asset-management-summary',
    templateUrl: './asset-management-summary.component.html',
})
export class AssetManagementSummaryComponent implements OnChanges {
    @Input() readonly assets: SubscriptionAsset[];
    @Input() readonly loading: boolean;

    count: number;
    size = 210;
    innerSize = 95;
    centerLabel: string;

    dialSegments: IRadialDialSegment[] = [];

    renewalInfo: AssetManagementSummaryInfo;
    licensesInfo: AssetManagementSummaryInfo;

    expiredSubscriptions = 0;
    expiringIn120Subscriptions = 0;
    expiringIn30Subscriptions = 0;
    underContractSubscriptions = 0;

    ChartDetails = ChartDetails;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.assets) {
            this.updateSubscriptionStatus();
        }
    }

    updateSubscriptionStatus(): void {
        this.expiredSubscriptions = 0;
        this.expiringIn120Subscriptions = 0;
        this.expiringIn30Subscriptions = 0;
        this.underContractSubscriptions = 0;

        const [nullIDSubscriptions, nonNullIDSubscriptions] = partition(
            this.assets?.map(asset => asset.subscription),
            subscription => subscription.id == null,
        );
        const subscriptions = [
            ...nullIDSubscriptions,
            ...uniqBy(nonNullIDSubscriptions, subscription => subscription.id),
        ];
        this.centerLabel = subscriptions.length.toString();

        subscriptions.forEach(subscription => {
            const endDate = subscription.endDate;
            if (moment.utc().isAfter(endDate)) {
                this.expiredSubscriptions++;
            } else if (moment.utc().add(30, 'days').isAfter(endDate)) {
                this.expiringIn30Subscriptions++;
            } else if (moment.utc().add(120, 'days').isAfter(endDate)) {
                this.expiringIn120Subscriptions++;
            } else if (isSubscriptionActive(subscription.status)) {
                this.underContractSubscriptions++;
            }
        });

        this.dialSegments = [
            {
                name: ChartDetails.current.name,
                value: this.underContractSubscriptions,
                color: ChartDetails.current.color,
            },
            {
                name: ChartDetails.expired.name,
                value: this.expiredSubscriptions,
                color: ChartDetails.expired.color,
            },
            {
                name: ChartDetails.expiresIn30Days.name,
                value: this.expiringIn30Subscriptions,
                color: ChartDetails.expiresIn30Days.color,
            },
            {
                name: ChartDetails.expiresIn120Days.name,
                value: this.expiringIn120Subscriptions,
                color: ChartDetails.expiresIn120Days.color,
            },
        ];

        const upComingRenewals =
            this.assets == null
                ? null
                : subscriptions.filter(subscription => {
                      const endDate = subscription.endDate;
                      return moment.utc().isBefore(endDate) && moment.utc().add(90, 'days').isAfter(endDate);
                  }).length;

        this.renewalInfo = {
            svg: 'file-signature.svg',
            title: 'Upcoming Renewals',
            value: upComingRenewals,
        };

        const [nullIDLicenses, nonNullIDLicenses] = partition(
            this.assets?.map(asset => asset.license),
            license => license.id == null,
        );
        const licenses = [...nullIDLicenses, ...uniqBy(nonNullIDLicenses, license => license.id)];
        const totalOnDemand =
            this.assets == null ? null : licenses.filter(license => license.usage.totalOnDemand > 0).length;

        this.licensesInfo = {
            svg: 'layer-group.svg',
            title: 'Total On-Demand Licenses',
            value: totalOnDemand,
        };
    }
}
