import { Resource } from '../interfaces/resource';

export class ArraySubscription implements Resource {
    id: string;
    name: string; // subscription id
    org_id: number;
    program_type: string;
    subscription_partner_org: number;
    salesforce_id: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.org_id = json.org_id;
        this.program_type = json.program_type;
        this.subscription_partner_org = json.subscription_partner_org;
        this.salesforce_id = json.salesforce_id;
    }
}
