import { NgModule } from '@angular/core';
import { ShowAllRolesComponent } from './components/show-all-roles/show-all-roles.component';
import { UIModule } from '../ui/ui.module';
import { RoleStatusSuffixPipe } from './pipes/role-status-suffix.pipe';
import { CommonModule } from '@angular/common';
import { RoleErrorsPipe } from './pipes/role-errors.pipe';

const declarations = [ShowAllRolesComponent, RoleStatusSuffixPipe, RoleErrorsPipe];

@NgModule({
    imports: [CommonModule, UIModule],
    exports: [...declarations],
    declarations: [...declarations],
})
export class UserManagementSharedModule {}
