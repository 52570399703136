import { Input, Component } from '@angular/core';
import { Router } from '@angular/router';

import { ISubmenuBarItem, SubmenuBarBaseComponent } from '../submenu-bar/submenu-bar-base.component';

export interface ICategorizedSubmenuBarItem {
    categoryTitle: string;
    submenuItems: ISubmenuBarItem[];
}

@Component({
    selector: 'categorized-submenu-bar',
    templateUrl: 'categorized-submenu-bar.component.html',
})
export class CategorizedSubmenuBarComponent extends SubmenuBarBaseComponent {
    @Input() readonly categorizedSubmenuItems: ICategorizedSubmenuBarItem[];

    constructor(router: Router) {
        super(router);
    }

    isCategoryActive(category: ICategorizedSubmenuBarItem): boolean {
        return category.submenuItems?.some(submenuItem => this.isActive(submenuItem));
    }
}
