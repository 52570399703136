<span class="safemode-shield-icon">
    <pureui-svg
        class="pstg-action-icon"
        svg="safemode-shield.svg"
        [height]="iconHeight"
        [ngClass]="safeModeClass"
        ngbTooltip="{{ safeModeDisplayString === 'Upgrade Required' ? '' : 'SafeMode ' }}{{
            safeModeTooltipString | titlecase
        }}"
        placement="top"
        container="body"
    ></pureui-svg>
</span>
