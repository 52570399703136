import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FeatureFlagDxpService } from '../../data';

// TODO: Replace by the directive from @pure/pure1-ui-platform-angular once available (CLOUD-144422)
@Directive({
    selector: '[ifFeatureEnabled]',
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
    @Input({
        required: true,
        alias: 'ifFeatureEnabled',
    })
    featureName!: string;

    private subscription: Subscription;

    constructor(
        private featureFlagService: FeatureFlagDxpService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this.checkFeatureFlag();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private checkFeatureFlag(): void {
        this.subscription = this.featureFlagService.getFeatureFlag(this.featureName).subscribe(status => {
            if (status.enabled) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }
}
