<div>
    <!-- Container used to offset the page (a separate div to avoid messing with the animations) -->
    <div *ngIf="isVisible()" class="export-status-bar-page-spacer"> </div>

    <!-- The actual status bar -->
    <div *ngIf="isVisible()" class="export-status-bar pure-animated animate-fast slideInOutBottom">
        <ng-template #exportDetailsModal let-modal>
            <export-details-modal [activeModal]="modal" [exportTask]="detailsTask"></export-details-modal>
        </ng-template>

        <!-- Individual tasks -->
        <div
            class="export-task pure-animated animate-fast fadeInOut"
            *ngFor="let task of exportTasks; trackBy: 'id' | trackByProperty"
            [ngClass]="{ 'is-ready': showTaskAsCompleted(task) }"
        >
            <div class="task-titlebar">
                <div class="task-title" (click)="clickDownload(exportDetailsModal, task)">
                    {{ task.fileName }}
                </div>
            </div>

            <div
                class="task-show-details"
                (click)="showDetailsModal(exportDetailsModal, task)"
                angulartics2On="click"
                [angularticsAction]="analyticsPrefix + 'Click details caret'"
                angularticsCategory="Action"
            >
                <i class="fa fa-caret-up"></i>
            </div>

            <div class="task-status" (click)="clickDownload(exportDetailsModal, task)">
                <!-- Export in progress -->
                <span *ngIf="!showTaskAsCompleted(task)">
                    <pureui-spinner [pureuiShow]="true"></pureui-spinner>
                    <span class="status-text">{{ task.isInQueue ? 'In queue…' : 'Processing…' }}</span>
                </span>

                <!-- Completed successfully, ready to download -->
                <span
                    *ngIf="showTaskAsCompleted(task) && !task.isFailed"
                    angulartics2On="click"
                    [angularticsAction]="analyticsPrefix + 'Click download'"
                    angularticsCategory="Action"
                >
                    <i class="status-icon fa fa-cloud-download"></i>
                    <span class="status-text">Click to download</span>
                </span>

                <!-- Export failed to generate -->
                <span
                    *ngIf="showTaskAsCompleted(task) && task.isFailed"
                    angulartics2On="click"
                    [angularticsAction]="analyticsPrefix + 'Click failed download'"
                    angularticsCategory="Action"
                >
                    <pureui-svg
                        class="pstg-secondary-icon pstg-alert-warning-icon"
                        svg="alert_warning.svg"
                        height="10"
                    ></pureui-svg>
                    <span class="status-text">Export failed. Click for details.</span>
                </span>
            </div>
        </div>
    </div>
</div>
