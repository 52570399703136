import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'formatClusterUsage',
})
export class FormatClusterUsagePipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');

    transform(data: number): string {
        if (data === undefined || data === null) {
            return null;
        }

        if (typeof data !== 'number') {
            console.warn(`input ${data} is not of type number`);
            return null;
        }
        // DecimalPipe introduces commas, so we'll need to remove them
        const formattedString = `${FormatClusterUsagePipe.decimalPipe.transform(data, '1.0-1').replace(/,/g, '')} VM`;
        if (data === 1) {
            return formattedString;
        }
        return formattedString + 's';
    }
}
