import moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, map, takeUntil, take, switchMap, catchError } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

import { SimulationsService } from '../simulation-summary/simulations.service';
import { SimulationStep } from '../../services/simulation-step.service';
import { HttpCacheService } from '../../services/http-cache.service';

const CAPACITY_FORECAST_URL = `/rest/v2/forecast/arrays/capacity`;
const CAPACITY_FORECAST_EFFECTIVE_USED_URL = '/rest/v2/forecast/arrays/effective-used';
const CAPACITY_FORECAST_LICENSE_EFFECTIVE_USED_URL = '/rest/v2/forecast/licenses/effective-used';

export interface CapacityProjectionResponse {
    data: ITimestampValuePoint[];
    lowerConfidenceInterval: ITimestampValuePoint[];
    upperConfidenceInterval: ITimestampValuePoint[];
    daysToReach90: number | string;
    daysToReach100: number | string;
}

export interface CapacityFull {
    fullInDays: number | string;
    percentInDays: number | string;
}

@Injectable({ providedIn: 'root' })
export class CapacityProjectionService implements OnDestroy {
    private readonly lastSimulationChanged = new Map<string, moment.Moment>();
    private readonly lastTrainingWindowChanged = new Map<string, moment.Moment>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        private httpCacheService: HttpCacheService,
        private simulationsService: SimulationsService,
    ) {
        simulationsService.capacitySimulation$.pipe(takeUntil(this.destroy$)).subscribe(id => {
            this.lastSimulationChanged.set(id, moment());
        });

        simulationsService.capacityTrainingWindow$.pipe(takeUntil(this.destroy$)).subscribe(id => {
            this.lastTrainingWindowChanged.set(id, moment());
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    getSimulatedCapacityFull(
        arrayId: string,
        simulationId: string,
        withSimulation: boolean,
        trainingTimeStart: number,
        trainingTimeEnd: number,
        days: number,
        licenseId: string = undefined,
    ): Observable<CapacityFull> {
        return this.getCapacityProjectionResponse(
            arrayId,
            { startTime: trainingTimeStart, endTime: trainingTimeEnd },
            simulationId,
            withSimulation,
            licenseId != undefined,
            licenseId,
        ).pipe(
            map(capacityProjectionResponse => {
                if (!capacityProjectionResponse || capacityProjectionResponse.daysToReach100 == null) {
                    return { fullInDays: NaN, percentInDays: NaN };
                } else if (
                    typeof capacityProjectionResponse.daysToReach90 === 'string' &&
                    typeof capacityProjectionResponse.daysToReach100 === 'string'
                ) {
                    return {
                        fullInDays: capacityProjectionResponse.daysToReach90,
                        percentInDays: capacityProjectionResponse.daysToReach100,
                    };
                } else if (!capacityProjectionResponse.data) {
                    console.error('Perfplanner does not return capacity projection data.');
                    return { fullInDays: NaN, percentInDays: NaN };
                } else if (capacityProjectionResponse.data[days - 1]?.[1] == null) {
                    console.error(
                        'Perfplanner does not return enough capacity projection data for the requested ' +
                            days +
                            ' days.',
                        capacityProjectionResponse.data[days - 1],
                    );
                    return { fullInDays: NaN, percentInDays: NaN };
                } else {
                    return {
                        fullInDays: capacityProjectionResponse.daysToReach100,
                        percentInDays: capacityProjectionResponse.data[days - 1][1],
                    };
                }
            }),
        );
    }

    getCapacityProjectionResponse(
        arrayId: string,
        defaultTrainingWindow: ITrainingTimes | null,
        simulationId: string,
        withSimulation = false,
        isEffectiveUsed: boolean = false,
        licenseId: string = undefined,
    ): Observable<CapacityProjectionResponse> {
        const params = new HttpParams({
            fromObject: {
                array_id: arrayId,
                license_id: licenseId,
                default_training_starttime: defaultTrainingWindow
                    ? defaultTrainingWindow.startTime.toString()
                    : undefined,
                default_training_endtime: defaultTrainingWindow ? defaultTrainingWindow.endTime.toString() : undefined,
                simulation_id: simulationId,
                with_simulation: withSimulation.toString(),
            },
        });

        let relevantSteps: Partial<SimulationStep>[];
        if (licenseId) {
            relevantSteps = this.simulationsService.getSpeculativeStepsForLicense(licenseId);
        } else {
            relevantSteps = this.simulationsService.getSpeculativeStepsForArray(arrayId);
        }

        const url = isEffectiveUsed
            ? licenseId
                ? CAPACITY_FORECAST_LICENSE_EFFECTIVE_USED_URL
                : CAPACITY_FORECAST_EFFECTIVE_USED_URL
            : CAPACITY_FORECAST_URL;
        if (relevantSteps.length > 0) {
            // There are some simulation steps in the wizard for this array that are not saved on the back end
            //  yet. Use getwithPost to send the new steps to the back end.
            return this.getWithPost(params, relevantSteps, url);
        } else {
            // No simulation steps in the wizard for this array, use regular get
            return this.getWithCache(arrayId, params, withSimulation, url);
        }
    }

    private getWithCache(
        id: string,
        params: HttpParams,
        withSimulation: boolean,
        url: string,
    ): Observable<CapacityProjectionResponse> {
        return this.httpCacheService.httpGetWithCache<CapacityProjectionResponse>(url, params).pipe(
            mergeMap(capacityProjectionResponse => {
                const simulationCacheExpired =
                    withSimulation &&
                    this.lastSimulationChanged.has(id) &&
                    this.lastSimulationChanged.get(id).isAfter(capacityProjectionResponse.requestTime);
                const trainingWindowCacheExpired =
                    this.lastTrainingWindowChanged.has(id) &&
                    this.lastTrainingWindowChanged.get(id).isAfter(capacityProjectionResponse.requestTime);
                if (simulationCacheExpired || trainingWindowCacheExpired) {
                    // Item still in HTTP cache, but we don't want it. Call again, bypassing cache.
                    // Update the stored states as we make this new call so if we make additional calls before this one expires,
                    // we don't cause those ones to force bypass cache, too, as we wait for the original results.
                    this.lastTrainingWindowChanged.set(id, moment());
                    if (withSimulation) {
                        this.lastSimulationChanged.set(id, moment());
                    }

                    return this.httpCacheService.httpGetWithCache<CapacityProjectionResponse>(url, params, true);
                } else {
                    // Value not cached / cached value is still valid
                    return of(capacityProjectionResponse);
                }
            }),
            map(capacityProjectionResponse => capacityProjectionResponse.response),
        );
    }

    private getWithPost(
        params: HttpParams,
        extraSteps: Partial<SimulationStep>[],
        url: string,
    ): Observable<CapacityProjectionResponse> {
        return this.simulationsService.simulation$.pipe(
            take(1),
            switchMap(simulation => {
                const body = extraSteps.map(step => {
                    return {
                        name: 'simulation-step',
                        simulation: simulation,
                        arrays: step.arrays,
                        licenses: step.licenses,
                        action: step.action,
                        action_details: step.action_details,
                    };
                });
                return this.httpCacheService
                    .httpPostAsGetWithCache<CapacityProjectionResponse>(url, body, params)
                    .pipe(map(capacityProjectionResponse => capacityProjectionResponse.response));
            }),
            catchError(error => {
                return this.responseErrorHandler(error);
            }),
        );
    }

    private responseErrorHandler(error: HttpErrorResponse): Observable<CapacityProjectionResponse> {
        const errorMessage = 'Could not retrieve projection data.';
        console.warn('Error retrieving projection data: ', error.message);
        return of({
            data: [],
            lowerConfidenceInterval: [],
            upperConfidenceInterval: [],
            daysToReach90: errorMessage,
            daysToReach100: errorMessage,
        });
    }
}
