import moment from 'moment';

import { Resource } from '../interfaces/resource';

export abstract class BaseSnapshot implements Resource {
    name: string;
    id: string;
    source_appliance_id?: string;
    target_appliance_id: string;
    source_global_id: string;
    type: string;
    created: moment.Moment;
    snapshot_space: number;
    target_array_name: string;
    pgroup: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.target_appliance_id = json.target_appliance_id;
        this.target_array_name = json.target_array_name;
        this.source_appliance_id = json.source_appliance_id;
        this.source_global_id = json.source_global_id;
        this.type = json.type;
        this.created = moment(json.created);
        this.snapshot_space = json.snapshot_space;
        this.pgroup = json.pgroup;
    }
}
