import { Resource } from '../interfaces/resource';

export class ServiceCatalogQuotingOptions implements Resource {
    id: string;
    productSku: string;
    productType: string;
    description: string;
    name: string;
    terms: number[];
    requestAmounts: number[];
    requestPerformanceAmounts: number[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.productSku = json.product_sku;
        this.productType = json.product_type;
        this.description = json.description;
        this.terms = json.terms;
        this.requestAmounts = json.request_amounts;
        this.requestPerformanceAmounts = json.request_performance_amounts;
    }
}
