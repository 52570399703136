import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataPage } from '../interfaces/data-page';
import { map } from 'rxjs/operators';
import { IRestResponse } from '../interfaces/collection';

export class DirectoryCapacityMetricsRequest {
    array: string;
    directories: string[];
}

export type DirectoryCapacityMetricsName =
    | 'data_reduction'
    | 'unique_space'
    | 'snapshot_space'
    | 'unique_effective_used_space'
    | 'snapshots_effective_used_space';

export class DirectoryCapacityMetricsResponse {
    id: string;
    latest_metric_time: number;
    data: {
        data_reduction: DirectoryCapacityMetricsItem;
        unique_space: DirectoryCapacityMetricsItem;
        snapshot_space: DirectoryCapacityMetricsItem;
        unique_effective_used_space: DirectoryCapacityMetricsItem;
        snapshots_effective_used_space: DirectoryCapacityMetricsItem;
    };
}

export type DirectoryCapacityMetricsType = '1h_avg' | '1h_avg_30days_growth' | '1h_avg_30days_growth_percentage';

export class DirectoryCapacityMetricsItem {
    '1h_avg': number;
    metric_time: number;
    '1h_avg_30days_growth': number;
    '1h_avg_30days_growth_percentage': number;
}

const DIRECTORY_CAPACITY_METRICS_URL = 'rest/v3/directories/capacity-metrics';

@Injectable({ providedIn: 'root' })
export class DirectoryCapacityMetricsService {
    constructor(private http: HttpClient) {}

    getDirectoryMetrics(
        request: DirectoryCapacityMetricsRequest[],
    ): Observable<DataPage<DirectoryCapacityMetricsResponse>> {
        return this.http
            .post<IRestResponse<DirectoryCapacityMetricsResponse>>(DIRECTORY_CAPACITY_METRICS_URL, request)
            .pipe(
                map(response => {
                    if (response.items) {
                        return { response: response.items, total: response.total_item_count };
                    } else {
                        return { response: [], total: 0 };
                    }
                }),
            );
    }
}
