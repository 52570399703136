import { ReplicaLink } from './replica-link';

export class BucketReplicaLink extends ReplicaLink {
    targetType: string;
    visible: boolean;

    constructor(json: any) {
        super(json);
        this.targetType = json.targetType || null;
        this.visible = json.visible || false;
    }
}
