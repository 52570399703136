import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_DECIMAL = 2;
const THOUSAND = 1000;

@Pipe({ name: 'formatIOPS' })
export class FormatIOPSPipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');
    transform(value: number, decimal = DEFAULT_DECIMAL): string {
        if (typeof value !== 'number' || isNaN(value) || value < 0) {
            return null;
        }

        return FormatIOPSPipe.decimalPipe.transform(value / THOUSAND, `1.${decimal}-${decimal}`);
    }
}
