import { map, Observable } from 'rxjs';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormBuilder,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
} from '@angular/forms';
import { SupportPxCluster, SupportPxClusterService } from '@pure1/data';
import { SearchPxClusterComponent } from '../../shared/components/search-px-cluster/search-px-cluster.component';
import { AbstractProductSectionAppliancesComponent } from '../abstract-product-section-appliances';

@Component({
    selector: 'product-section-px-clusters',
    templateUrl: 'product-section-px-clusters.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductSectionPxClustersComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProductSectionPxClustersComponent),
            multi: true,
        },
    ],
})
export class ProductSectionPxClustersComponent extends AbstractProductSectionAppliancesComponent<
    SupportPxCluster,
    SearchPxClusterComponent
> {
    constructor(
        protected pxClusterService: SupportPxClusterService,
        protected cdr: ChangeDetectorRef,
        protected fb: UntypedFormBuilder,
    ) {
        super(cdr, fb);
    }

    getAppliances(): Observable<SupportPxCluster[]> {
        return this.pxClusterService.list().pipe(
            map(clusters => {
                return clusters.response;
            }),
        );
    }

    selectVisibleProduct(initialProduct: SupportPxCluster): void {
        if (!initialProduct) {
            return;
        }
        this.appliances$.subscribe(() => {
            this.writeValue(initialProduct);
        });
    }

    validateAppliance(control: UntypedFormControl): ValidationErrors {
        // Let's not bother with checking when editing
        if (this.isBeingEdited) {
            return null;
        }
        const val = control.value;
        if (!val || !(val instanceof SupportPxCluster || typeof val === 'string')) {
            return { cluster: 'A valid product is required' };
        }
        return null;
    }

    writeValue(val: any): void {
        // need to wait for the clusters, otherwise the draft recovery breaks as we store only arrayId
        this.appliances$.subscribe(() => {
            if (val?.id) {
                let selectedArray = this.appliances.find(cluster => cluster.id === (val as SupportPxCluster).id);
                if (!selectedArray) {
                    selectedArray = val.id;
                }
                selectedArray && this.form.setValue({ product: selectedArray });
            }
        });
    }
}
