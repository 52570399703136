import { Injectable } from '@angular/core';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { CachedCurrentUserService, CurrentUser } from '@pure1/data';

import { CaseService } from './case.service';
import { CaseCommentService } from './case-comment.service';
import { CaseEmailService } from './case-email.service';
import { CaseAttachmentService } from './case-attachment.service';
import { CaseAsyncJobService } from './case-async-job.service';
import { CaseEscalationService } from './case-escalation.service';
import { CaseAttachment, CaseComment, SupportCaseItem } from '../support.interface';
import { forMoment } from '../../utils/comparator';

@Injectable()
export class CaseItemDataService {
    private currentUser: CurrentUser;

    constructor(
        private caseAsyncJobService: CaseAsyncJobService,
        private attachmentService: CaseAttachmentService,
        private caseCommentService: CaseCommentService,
        private caseService: CaseService,
        private emailService: CaseEmailService,
        private escalationManager: CaseEscalationService,
        cachedCurrentUserService: CachedCurrentUserService,
    ) {
        cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(user => {
                this.currentUser = user;
            });
    }

    getItemsForCase: (caseId: string, limit?: number) => Observable<SupportCaseItem[]> = (caseId, limit) => {
        if (limit && limit > 0) {
            return this.caseService.getCaseRecentItems(caseId, limit).pipe(
                first(),
                map(({ attachments, comments, emails, escalations }): SupportCaseItem[] =>
                    [...attachments, ...comments, ...emails, ...escalations]
                        .sort(forMoment((item: SupportCaseItem) => item.createdDate.valueOf()).asc)
                        .slice(-limit),
                ),
                map(this.setCreatedTime),
            );
        } else {
            return forkJoin([
                this.attachmentService.getCaseAttachments(caseId),
                this.caseCommentService.getCaseComments(caseId),
                this.emailService.getCaseEmails(caseId),
                this.escalationManager.getCaseEscalations(caseId),
            ]).pipe(
                map(([attachments, comments, emails, escalations /*, asyncJobs*/]) =>
                    [...attachments, ...comments, ...emails, ...escalations].sort(
                        forMoment((item: SupportCaseItem) => item.createdDate.valueOf()).asc,
                    ),
                ),
                map(this.setCreatedTime),
            );
        }
    };

    postAttachment = (caseId: string, attachment: Partial<CaseAttachment>): Observable<CaseAttachment> => {
        return this.attachmentService.addCaseAttachment(caseId, attachment);
    };

    postComment = (caseId: string, comment: Partial<CaseComment>): Observable<CaseComment> => {
        return this.caseCommentService.addCaseComment(caseId, comment);
    };

    getPlaceholder = (item: any): SupportCaseItem => {
        const now = moment();
        const placeholderItem = {
            createdByUser: this.currentUser,
            id: 'tmp' + String(now.valueOf()),
            createdDate: now.format(),
            lastModifiedDate: now.format(),
            tmp: true,
        };
        const placeholderComment = {
            parent: {},
            published: true,
        };
        if (item.itemType === 'comment') {
            return {
                ...placeholderItem,
                ...placeholderComment,
                ...item,
            };
        } else {
            return {
                ...placeholderItem,
                ...item,
            };
        }
    };

    setCreatedTime = items => {
        return items.map(item => {
            item.createdDate = moment(item.createdDate);
            return item;
        });
    };
}
