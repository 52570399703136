import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'sdfc-update-banner',
    templateUrl: 'sdfc-update-banner.component.html',
})
export class SdfcUpdateBannerComponent extends BaseBanner implements AfterViewInit {
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('sdfcUpdateLinksTemplate', { static: true }) readonly sdfcUpdateLinksTemplate: TemplateRef<any>;
    @ViewChild('sdfcUpdateButtonTemplate', { static: true }) readonly sdfcUpdateButtonTemplate: TemplateRef<any>;

    ngAfterViewInit(): void {
        const templates = {
            linksTemplate: this.sdfcUpdateLinksTemplate,
            buttonTemplate: this.sdfcUpdateButtonTemplate,
        };
        this.populatedNotification = Object.assign({}, this.notification, templates, { isUpdate: true });
    }
}
