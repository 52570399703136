import { Pipe, PipeTransform } from '@angular/core';
import { Invoice } from '@pure1/data';

@Pipe({
    name: 'invoiceAddress',
})
export class InvoiceAddressPipe implements PipeTransform {
    transform(invoice: Invoice): string {
        if (!invoice) {
            return '';
        }

        return [invoice.shipToCity, invoice.shipToState, invoice.shipToCountry].filter(Boolean).join(', ');
    }
}
