import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@pure1/data';

@Pipe({
    name: 'productShortName',
})
export class ProductShortNamePipe implements PipeTransform {
    transform(product: Product): string {
        switch (product) {
            case Product.FA:
                return 'FA';
            case Product.FB:
                return 'FB';
            default:
                console.warn(`Unrecognized product: ${product}`);
                return '';
        }
    }
}
