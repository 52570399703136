import { ArrayContractStatus, ArraySubscriptionLicense, UnifiedArray, Product } from '@pure1/data';
import { PerformanceData } from './PerformanceData';

const NA = '–';

// Used to sort the protocols
enum ProtocolOrdering {
    FC = 1,
    ISCSI = 2,
    NFS = 3,
    SMB = 4,
    HTTP = 5,
    S3 = 6,
}

export function isFArrayProduct(product: string): boolean {
    return product === Product.FA || product === 'flash-array';
}

export function isFBladeProduct(product: string): boolean {
    return product === Product.FB || product === 'flash-blade';
}

export class PureArray extends UnifiedArray implements PureArray {
    arrayId: string;
    capacity_analysis: any;
    hostname: string;
    lastUpdated: string;
    load_analysis: any;
    orgId: number;
    protocol_list: string[];
    protocolString: string;
    performanceData: PerformanceData;
    capacityData: ICapacityData;
    workloadState: any;
    capacityState: any;
    host_iqn_wwn_list: string[];
    target_iqn_wwn_list: string[];
    host_hostname_list: string[];
    subscriptionLicense: ArraySubscriptionLicense;
    has_end_of_life_hardware: boolean;
    end_of_life_hardware?: IPureArrayEndOfLifeHardware;
    capacity_expandable: boolean;

    constructor(json: any) {
        super({
            _as_of: json.last_updated * 1000,
            appliance_id: json.appliance_id,
            array_id: json.array_id,
            contract_expiration_date: json.contract_expiration_date,
            contract_last_renew_requested: json.contract_last_renew_requested,
            fqdn: json.fqdn,
            flashArray: json.flashArray,
            flashBlade: json.flashBlade,
            storReduce: json.storReduce,
            helium: json.helium,
            cbs: json.cbs,
            id: json.id || json.appliance_id || json.array_id,
            install_address: json.install_address,
            install_address_requested: json.install_address_requested,
            is_current: json.is_current,
            name: json.hostname,
            model: json.model,
            os: json.os,
            org_id: json.org_id,
            product: json.product,
            version: json.version,
            active_status: json.active_status,
            contract_status: json.contract_status,
            contract_renewal_status: json.contract_renewal_status,
            safe_mode_array_status: json.safe_mode_array_status,
            safe_mode_global: json.safe_mode_global,
            safe_mode_object_store: json.safe_mode_object_store,
            safe_mode_file_systems: json.safe_mode_file_systems,
            safe_mode_status_weight: json.safe_mode_status_weight,
            number_of_volumes: json.number_of_volumes,
            number_of_protected_volumes: json.number_of_protected_volumes,
            last_updated: json.last_updated,
            end_of_life_hardware: json.end_of_life_hardware,
            has_end_of_life_hardware: json.has_end_of_life_hardware,
            capacity_expandable: json.capacity_expandable,
            support_expiration_date: json.support_expiration_date,
        });
        this.arrayId = json.array_id;
        this.hostname = !json.hostname ? NA : json.hostname;
        this.orgId = json.org_id;
        this.subscriptionLicense = json.subscription_license
            ? new ArraySubscriptionLicense(json.subscription_license)
            : undefined;
        this.end_of_life_hardware = json.end_of_life_hardware;
        this.has_end_of_life_hardware = json.has_end_of_life_hardware;
        this.capacity_expandable = json.capacity_expandable;

        if (this.isCurrent) {
            this.model = String(!this.model ? NA : this.model);
            this.protocol_list = !json.protocol_list ? [NA] : json.protocol_list;
            this.protocolString = this.protocol_list
                .sort((a: string, b: string): number => {
                    return ProtocolOrdering[a] - ProtocolOrdering[b];
                })
                .join(', ');
            this.performanceData = new PerformanceData(json.purearray_monitor);
            this.capacity_analysis = !json.capacity_analysis ? null : json.capacity_analysis;
            this.load_analysis = !json.load_analysis ? null : json.load_analysis;
            this.host_iqn_wwn_list = !json.host_iqn_wwn_list ? [NA] : json.host_iqn_wwn_list;
            this.target_iqn_wwn_list = !json.target_iqn_wwn_list ? [NA] : json.target_iqn_wwn_list;
            this.host_hostname_list = !json.host_hostname_list ? [NA] : json.host_hostname_list;
        }
    }

    isFlashBlade(): boolean {
        return isFBladeProduct(this.product);
    }

    isFlashArray(): boolean {
        return isFArrayProduct(this.product);
    }

    isCBS(): boolean {
        return false;
    }

    isOutOfSupport(): boolean {
        return this.contract_status === ArrayContractStatus.EXPIRED;
    }

    isSupportInGracePeriod(): boolean {
        return this.contract_status === ArrayContractStatus.ACTIVE_WITH_GRACE_PERIOD;
    }

    isSupportActive(): boolean {
        return [ArrayContractStatus.ACTIVE].includes(this.contract_status);
    }
}
