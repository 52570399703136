import { Component, Input } from '@angular/core';
import { CurrentUser } from '@pure1/data';

import { UserModalService } from '../user-modal/user-modal.service';

@Component({
    selector: 'admin-user-view',
    templateUrl: 'admin-user-view.component.html',
})
export class AdminUserViewComponent {
    @Input() readonly currentUser: CurrentUser;

    constructor(public userModalService: UserModalService) {}
}
