<ng-template
    #sortHeader
    let-name="name"
    let-title="title"
    let-headerClass="headerClass"
    let-isFlashArray="isFlashArray"
>
    <th (click)="sortByColumn(name)" [ngClass]="headerClass" *ngIf="!(isFlashArray && title === 'Storage')">
        <span [ngClass]="getSortClass(name)">
            {{ title }}
        </span>
    </th>
</ng-template>

<div class="modal-body">
    <button type="button" class="close modal-x-button safemode-enable-close-button" (click)="onCancel.emit()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" [height]="12"></pureui-svg>
    </button>

    <div class="title">Select Appliances</div>

    <pureui-pagination
        [totalItemCount]="filteredSortedArrays?.length"
        [itemsPerPage]="pageLength"
        [offset]="pageOffset"
        (pageChange)="onPageChange($event)"
    >
    </pureui-pagination>

    <div class="d-flex flex-row">
        <input
            class="p2 form-control search-box"
            [(ngModel)]="filterValue"
            (input)="updateFilter()"
            placeholder="Filter"
        />
        <pureui-svg
            class="p2 pstg-action-icon search-icon"
            [svg]="'funnel.svg'"
            [height]="12"
            style="transform: translateY(2px)"
        ></pureui-svg>
    </div>

    <table class="table table-hover array-selector-table">
        <thead class="thead-default" [class.loading]="loading">
            <tr class="multiline">
                <ng-container
                    *ngTemplateOutlet="
                        sortHeader;
                        context: { name: 'name', title: 'Name', headerClass: 'array-name-header' }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        sortHeader;
                        context: { name: 'model', title: 'Model', headerClass: 'model-header' }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        sortHeader;
                        context: { name: 'version', title: 'Version', headerClass: 'version-header' }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        sortHeader;
                        context: {
                            name: 'storage',
                            title: 'Storage',
                            headerClass: 'storage-header',
                            isFlashArray: isFlashArray(arrays[0])
                        }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        sortHeader;
                        context: { name: 'status', title: 'SafeMode', headerClass: 'status-header' }
                    "
                ></ng-container>
                <ng-container
                    *ngTemplateOutlet="
                        sortHeader;
                        context: {
                            name: 'eradicationTimer',
                            title: 'Eradication Delay',
                            headerClass: 'eradication-header'
                        }
                    "
                ></ng-container>
            </tr>
        </thead>
        <tbody *ngIf="!loading">
            <tr
                *ngFor="let array of pagedArrays"
                (click)="toggleArraySelection(array.id)"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="Assessment - SafeMode - Array Selector - toggle array"
            >
                <td class="hostname-cell array-name-cell">
                    <span>
                        <input
                            type="checkbox"
                            [id]="'array-selector-' + array.id"
                            [checked]="selectedArraysMap[array.id]"
                        />
                    </span>
                    <label [htmlFor]="'array-selector-' + array.id">{{ array.name }}</label>
                </td>
                <td class="model-cell">{{ array.model }}</td>
                <td class="version-cell">{{ array.version }}</td>
                <td class="storage-cell" *ngIf="!isFlashArray(array)">
                    <div class="storage-type" *ngIf="array.safeMode.fileSystems"> File Systems </div>
                    <div class="storage-type" *ngIf="array.safeMode.objectStore"> Object Store </div>
                </td>
                <td class="status-cell">
                    <div *ngIf="isFlashArray(array); else flashBladeStorageStatus">
                        <div class="storage-type-status" *ngIf="array.safeMode.global">
                            {{ array.safeMode.global.status }}
                        </div>
                    </div>
                    <ng-template #flashBladeStorageStatus>
                        <div class="storage-type-status" *ngIf="array.safeMode.fileSystems">
                            {{ array.safeMode.fileSystems.status }}
                        </div>
                        <div class="storage-type-status" *ngIf="array.safeMode.objectStore">
                            {{ array.safeMode.objectStore.status }}
                        </div>
                    </ng-template>
                </td>
                <td class="eradication-cell">
                    <div *ngIf="isFlashArray(array); else flashBladeEradicationTimer">
                        <div class="eradication-timer" *ngIf="array.safeMode.global">
                            {{ getDurationDays(array.safeMode.global.eradicationTimer) }}
                        </div>
                    </div>
                    <ng-template #flashBladeEradicationTimer>
                        <div class="eradication-timer" *ngIf="array.safeMode.fileSystems">
                            {{ getDurationDays(array.safeMode.fileSystems.eradicationTimer) }}
                        </div>
                        <div class="eradication-timer" *ngIf="array.safeMode.objectStore">
                            {{ getDurationDays(array.safeMode.objectStore.eradicationTimer) }}
                        </div>
                    </ng-template>
                </td>
            </tr>
            <tr *ngIf="filteredSortedArrays && filteredSortedArrays.length === 0" class="no-results-row">
                <td> No results found. Please adjust your filters </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="loading" class="col-xs-12 loader">
        <pureui-spinner [pureuiShow]="true"></pureui-spinner>
    </div>

    <div class="btn-toolbar clearfix">
        <pure-entity-selection-dropdown
            entityName="Selections"
            [isSelectable]="false"
            [entities]="selectedArrays"
            (entitiesChange)="updateSelectedArrays($event)"
        >
        </pure-entity-selection-dropdown>
        <span
            *ngIf="{ selectedArrays, selectedPGroups } | memoizeFnc: reachedArrayLimit; else infoMessage"
            class="errorMessage"
        >
            You reached the limit ({{ maxSelectedAppliances }} appliances max)
        </span>
        <ng-template #infoMessage>
            <span class="infoMessage">Select up to {{ maxSelectedAppliances }} appliances</span>
        </ng-template>
        <button
            type="button"
            class="btn btn-primary float-xs-right margin"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Assessment - SafeMode - Array Selector - Continue schedule request"
            [angularticsLabel]="selectedArrays.length + ''"
            (click)="onNext()"
            [disabled]="!({ selectedArrays, selectedPGroups } | memoizeFnc: canSchedule)"
        >
            Select
        </button>
        <button type="button" class="btn btn-secondary float-xs-right margin" (click)="onCancel.emit()">
            Cancel
        </button>
    </div>
</div>
