import { Component, OnInit } from '@angular/core';
import { CurrentUser, FeatureFlagDxpService, XaaSAuthorizationService } from '@pure1/data';
import { combineLatest, forkJoin, map, of, zip } from 'rxjs';
import { FeatureNames } from '../../model/FeatureNames';
import { ISubmenuBarItem } from '../../ui/components/submenu-bar/submenu-bar-base.component';

export const ASSET_MANAGEMENT_TAB_NAME = 'Asset Management';
export const APPLIANCE_GENEALOGY_TAB_NAME = 'Appliance Genealogy';
export const SUBSCRIPTION_GENEALOGY_TAB_NAME = 'Subscription Genealogy';
export const QUOTE_MANAGEMENT_TAB_NAME = 'Quote Management';
export const INVOICE_MANAGEMENT_TAB_NAME = 'Invoice Management';

interface AssetSubmenuBarItem extends ISubmenuBarItem {
    authzPermissions?: string[]; // This uses OR logic. Any of the permissions being true will allow the tab to be shown
}

@Component({
    selector: 'asset-tab',
    templateUrl: 'asset-tab.component.html',
})
export class AssetTabComponent implements OnInit {
    // Items that show up by default
    submenuItems: AssetSubmenuBarItem[] = [
        {
            title: ASSET_MANAGEMENT_TAB_NAME,
            url: '/assets/asset-management',
        },
    ];
    currentUser: CurrentUser;

    // Items that appear if the flag is enabled
    readonly conditionalTabs = new Map<FeatureNames, AssetSubmenuBarItem>([
        [
            FeatureNames.APPLIANCE_GENEALOGY,
            {
                title: APPLIANCE_GENEALOGY_TAB_NAME,
                url: '/assets/appliance-genealogy',
            },
        ],
        [
            FeatureNames.SUBSCRIPTION_GENEALOGY,
            {
                title: SUBSCRIPTION_GENEALOGY_TAB_NAME,
                url: '/assets/subscription-genealogy',
            },
        ],
        [
            FeatureNames.QUOTE_MANAGEMENT,
            {
                title: QUOTE_MANAGEMENT_TAB_NAME,
                url: '/assets/quote-management',
                authzPermissions: ['PURE1:read:partner_quote'],
            },
        ],
        [
            FeatureNames.PARTNER_INVOICE,
            {
                title: INVOICE_MANAGEMENT_TAB_NAME,
                url: '/assets/invoice-management',
                authzPermissions: ['PURE1:read:partner_invoice'],
            },
        ],
    ]);

    constructor(
        private featureFlagDxpService: FeatureFlagDxpService,
        private authzService: XaaSAuthorizationService,
    ) {}

    ngOnInit(): void {
        const featurePolicies$ = forkJoin(
            Array.from(this.conditionalTabs.entries()).map(([featureName, tab]) => {
                const featurePolicy$ = this.featureFlagDxpService.getFeatureFlag(featureName);
                const authzPermission$ = tab.authzPermissions
                    ? combineLatest(
                          tab.authzPermissions.map(authzPermission => this.authzService.isAllowed(authzPermission)),
                      )
                    : of([true]);
                return zip([featurePolicy$, authzPermission$]);
            }),
        ).pipe(
            map(permissions =>
                permissions
                    .filter(([feature, authzPermissions]) => feature !== null && authzPermissions.some(Boolean)) // Check policies against permissions
                    .map(([feature, _]) => feature),
            ),
        );

        featurePolicies$.subscribe(features => {
            features.forEach(feature => {
                if (feature?.enabled) {
                    this.submenuItems.push(this.conditionalTabs.get(feature.name as FeatureNames));
                }
            });
        });
    }
}
