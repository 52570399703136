import { Resource } from '../interfaces/resource';
import { Product, getProduct } from './unified-array';
import { ResourceStatus } from './license';

export class ArraySpace implements Resource {
    id: string;
    name: string;
    fqdn: string;
    capacity: number; // Usable capacity in bytes.
    parity: number; // A representation of data redundancy on the array. Data redundancy is rebuilt automatically by the system whenever parity is less than 1.0.
    space: {
        dataReduction: number;
        shared: number;
        snapshots: number;
        system: number;
        thinProvisioning: number; // Thin provisioning percentage, from 0 to 1.
        totalPhysical: number; // Total physical space, in bytes.
        percentFull: number;
        totalProvisioned: number; // Total provisioned size of child containers, in bytes.
        provisioning: number; //this is the overprovisioning ratio calculated as "total provisioned / used space" (by the backend)
        totalReduction: number; // Total data reduction, with thin provisioning savings.
        volumes: number; // NOTE:    we will attempt to populate any of "volumes", "fileSystemsSpace"
        replication: number;
        fileSystemsSpace: number; //          or "objectStoreSpace" as we will no break them into FA or FB
        objectStoreSpace: number; //          as they will soon be used by both
        unique: number; // Unique physical space occupied by customer data, in bytes. Excludes shared space, snapshots, and metadata, if exists.
        virtual: number; // Virtual space, in bytes.
        journal: number; // This is the sum of journal space consumed by all pods on this array.

        // Destroyed space. Currently only for FB >=4.2.0.
        destroyedSpace?: number;
        fileSystemsDestroyedSpace?: number;
        bucketsDestroyedSpace?: number;
    };
    totalBlades: number;
    notInstalledBlades: number;
    time: number;
    lastUpdated: number;
    isCurrent: boolean;
    product: Product;
    model: string;
    active_status: ResourceStatus;

    // TODO: review if creating typings for the below is needed as part of CLOUD-34853
    capacity_analysis: any;
    utility_usage_summary: any;
    capacity30DayGrowth: any;

    // capacity down licensing
    capacityInstalled: number;
    capacityExpandable: boolean;
    instantCapacity: number;

    constructor(json: any) {
        this.space = {
            dataReduction: 1,
            shared: 0,
            snapshots: 0,
            system: 0,
            thinProvisioning: 0, // Thin provisioning percentage, from 0 to 1.
            totalPhysical: 0, // Total physical space, in bytes.
            percentFull: 0,
            totalProvisioned: 0, // Total provisioned size of child containers, in bytes.
            provisioning: 0,
            totalReduction: 0, // Total data reduction, with thin provisioning savings.
            volumes: 0, // NOTE:    we will attempt to populate any of "volumes", "fileSystemsSpace"
            replication: 0,
            fileSystemsSpace: 0, //          or "objectStoreSpace" as we will no break them into FA or FB
            objectStoreSpace: 0, //          as they will soon be used by both
            unique: 0, // Unique physical space occupied by customer data, in bytes. Excludes shared space, snapshots, and metadata, if exists.
            virtual: 0, // Virtual space, in bytes.
            journal: 0, // This is the sum of journal space consumed by all pods on this array.
        };

        if (json?.purearray_list_space) {
            this.capacity = json.purearray_list_space.capacity;
            this.capacityInstalled = json.purearray_list_space.capacity_installed;
            // [CLOUD-137509] is_current check disabled for playtest
            // this.capacityExpandable = !!json.is_current && json.purearray_list_space.capacity_expandable;
            this.capacityExpandable = json.purearray_list_space.capacity_expandable;
            this.instantCapacity = json.purearray_list_space.instant_capacity;
            this.parity = json.purearray_list_space.parity || -1;
            this.space.dataReduction = json.purearray_list_space.data_reduction;
            this.space.shared = json.purearray_list_space.shared_space;
            this.space.snapshots = json.purearray_list_space.snapshots;
            this.space.system = json.purearray_list_space.system;
            this.space.thinProvisioning = json.purearray_list_space.thin_provisioning;
            this.space.totalPhysical = json.purearray_list_space.total;
            this.space.percentFull = json.purearray_list_space.percent_full;
            this.space.totalProvisioned =
                json.purearray_list_space.size ||
                json.purearray_list_space.total * json.purearray_list_space.total_reduction;
            this.space.provisioning = json.purearray_list_space.provisioning || null;
            this.space.totalReduction = json.purearray_list_space.total_reduction;
            this.space.volumes = json.purearray_list_space.volumes || 0;
            this.space.replication = json.purearray_list_space.replication || 0;
            this.space.fileSystemsSpace = json.purearray_list_space.file_systems_space || 0;
            this.space.objectStoreSpace = json.purearray_list_space.buckets_space || 0;
            this.space.unique = json.purearray_list_space.unique || 0;
            this.space.virtual = json.purearray_list_space.virtual || 0;
            this.space.journal = json.purearray_list_space.journal || 0;

            this.space.destroyedSpace = json.purearray_list_space.destroyed_space;
            this.space.fileSystemsDestroyedSpace = json.purearray_list_space.file_systems_destroyed_space;
            this.space.bucketsDestroyedSpace = json.purearray_list_space.buckets_destroyed_space;

            this.time = -1;
            this.lastUpdated = json.last_updated || 0;
            this.isCurrent = !!json.is_current;
            this.totalBlades = json.total_blades || null; //cannot be 0, if it's 0, we should not show anything, hence null
            this.notInstalledBlades = json.not_installed_blades || 0; // can be 0
        } else {
            this.capacity = 0;
            this.capacityExpandable = false;

            this.space.dataReduction = 1;
            this.space.shared = 0;
            this.space.snapshots = 0;
            this.space.system = 0;
            this.space.thinProvisioning = 0;
            this.space.totalPhysical = 0;
            this.space.percentFull = 0;
            this.space.totalProvisioned = 0;
            this.space.provisioning = 0;
            this.space.totalReduction = 0;
            this.space.volumes = 0;
            this.space.replication = 0;
            this.space.fileSystemsSpace = 0;
            this.space.objectStoreSpace = 0;
            this.space.unique = 0;
            this.space.virtual = 0;
            this.space.journal = 0;

            this.time = -1;
            this.lastUpdated = 0;
            this.isCurrent = false;
        }
        if (json) {
            this.id = json.array_id;
            this.name = json.hostname;
            this.fqdn = json.fqdn || null;
            this.capacity_analysis = json.capacity_analysis || null;
            this.utility_usage_summary = json.utility_usage_summary || null;
            this.product = getProduct(json.product);

            const growth = this.capacity_analysis?.growth;
            if (growth) {
                this.capacity30DayGrowth =
                    typeof growth.value_30days === 'number' && growth.dataset_90days
                        ? growth.dataset_90days.slice(growth.dataset_90days.length - 30)
                        : [];
            }

            this.active_status = json.active_status || ResourceStatus.ACTIVE;
            this.model = json.model;
        }
    }
}
