<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<!-- The try-button class is styled in feature-banner -->
<ng-template #optimizeCapacityButtonTemplate>
    <a
        class="btn btn-primary try-button"
        [routerLink]="populatedNotification.path"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Plan Capacity Expansion"
        [angularticsLabel]="notification.title"
    >
        Plan Capacity Expansion
    </a>
</ng-template>
