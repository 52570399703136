import { formatNumber } from './formatNumber';

const UNIT_LABELS = ['B', 'K', 'M', 'G', 'T', 'P', 'E'];

/**
 * Format size values in bytes into a string with specified number of decimals.
 * @param bytes Size in bytes.
 * @param decimal Number of decimals to have in the value.
 * @param axisLabel If this formatting is for a chart axis label. Axis labels only use 'K' and above as unit, and don't show anything at zero.
 * @param fixUnit Optional fixed unit to use.
 */
export function formatSize(bytes: number, decimal: number, axisLabel?: boolean, fixUnit?: string): IValueUnit {
    if (typeof bytes !== 'number' || isNaN(bytes)) {
        return { value: '-', unit: '' };
    }
    if (typeof decimal !== 'number') {
        decimal = 0;
    }
    if (axisLabel && bytes <= 0) {
        return { value: '', unit: '' };
    }

    const bytesDecimalUnit: ISizeNumberUnit = formatSizeWithUntouchedDecimals(bytes, axisLabel, fixUnit);
    return {
        value: formatNumber(bytesDecimalUnit.sizeNumber, decimal),
        unit: bytesDecimalUnit.unit,
    };
}

interface ISizeNumberUnit {
    sizeNumber: number;
    unit: string;
}

/**
 * Format size values in bytes into a number and a corresponding unit.
 * @param bytes Size in bytes.
 * @param axisLabel If this formatting is for a chart axis label. Axis labels only use 'K' and above as unit, and don't show anything at zero.
 * @param fixUnit Optional fixed unit to use.
 */
function formatSizeWithUntouchedDecimals(bytes: number, axisLabel?: boolean, fixUnit?: string): ISizeNumberUnit {
    const units = axisLabel ? UNIT_LABELS.slice(1) : UNIT_LABELS;
    if (axisLabel) {
        bytes = bytes / 1024;
    }

    let index = 0;
    let unit = '';
    const unitIndex = fixUnit ? units.indexOf(fixUnit) : -1;
    if (unitIndex !== -1) {
        while (index < unitIndex) {
            bytes = bytes / 1024;
            index++;
        }
    } else {
        // NOTE: '>= 1000' is to prevent values between [1000, 1024) from showing up as, say, 1005.6MB
        // and instead show as 1.0 GB (assuming 1 digit rounding). This helps simplify UI layout issues
        while (Math.abs(bytes) >= 1000 && index < units.length - 1) {
            bytes = bytes / 1024;
            index++;
        }
        unit = bytes !== 0 ? units[index] : 'G';
    }

    return {
        sizeNumber: bytes,
        unit: unit,
    };
}

/**
 * Format size values in bytes into a string with specified number of decimals.
 * @param bytes Size in bytes.
 * @param decimal Number of decimals to have in the value.
 * @param axisLabel If this formatting is for a chart axis label. Axis labels only use 'K' and above as unit, and don't show anything at zero.
 * @param fixUnit Optional fixed unit to use.
 */
export function formatSizeStr(bytes: number, decimal: number, axisLabel?: boolean, fixUnit?: string): string {
    const iVal = formatSize(bytes, decimal, axisLabel, fixUnit);
    return `${iVal.value} ${iVal.unit}`.trim();
}

/**
 * Format size values in bytes into a string with specified number of digits.
 * e.g. 1.10E, 1.00P, 123T, 12.3G, 1.23M, 0.12K, 0.01B
 * If 999.x is rounded up to 1000, the unit will be upgraded and the value will be 1.00.
 * e.g. 999.8M -> 1.00G
 * @param bytes Size in bytes.
 * @param totalDigits Total number of digits.
 */
export function formatSizeStrWithTotalDigits(bytes: number, totalDigits: number): string {
    if (typeof bytes !== 'number' || isNaN(bytes) || typeof totalDigits !== 'number') {
        return '-';
    }

    if (bytes === 0) {
        return '0';
    }

    const sizeNumberUnit: ISizeNumberUnit = formatSizeWithUntouchedDecimals(bytes);

    let threshold: number = 10;
    let wholeNumberDigits: number = 1;
    while (sizeNumberUnit.sizeNumber >= threshold) {
        threshold *= 10;
        wholeNumberDigits++;
    }
    let finalBytes = formatNumber(sizeNumberUnit.sizeNumber, totalDigits - wholeNumberDigits);

    // Special case if formatNumber has rounded up 999.x to 1000, upgrade to a bigger unit.
    if (finalBytes === '1000') {
        finalBytes = '1.00';
        sizeNumberUnit.unit = UNIT_LABELS[UNIT_LABELS.indexOf(sizeNumberUnit.unit) + 1];
    }

    return `${finalBytes} ${sizeNumberUnit.unit}`.trim();
}
