import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HiveSortParams, HiveTableFilterEvent } from '@pure/hive';
import { take } from 'rxjs';
import { DynamicTableInterface } from './dynamic-table-interface';
import {
    getStoredTablePagesize,
    loadTableAttributesFromUrl,
    saveTableAttributesToUrl,
    saveTablePagesize,
    TABLE_DEFAULT_PAGE_SIZE,
    TABLE_DEFAULT_PAGESIZE_OPTIONS,
} from './table-utils';

/**
 * Data source for table depending on Hive and with dynamic loading the data
 */
@Injectable({ providedIn: 'root' })
export class TableDynamicDataSource<T, S extends DynamicTableInterface<T>> {
    pageSize: number = TABLE_DEFAULT_PAGE_SIZE;
    pageSizeOptions: number[] = TABLE_DEFAULT_PAGESIZE_OPTIONS;
    offset: number = 0;
    totalItemCount: number;

    loading: boolean;
    hasLoadingError: boolean;

    columnIds: string[];
    data: T[];
    service: S;
    tableId: string;

    sortingParams: HiveSortParams;
    filtering: { [key: string]: string } = {};

    private route: ActivatedRoute = inject(ActivatedRoute);
    private router: Router = inject(Router);
    private shouldStoreTableDataToUrl: boolean = true;
    private latestLoadingData: number;

    initialize(
        service: S,
        tableId: string,
        columnIds: string[],
        defaultSort: HiveSortParams,
        shouldStoreTableDataToUrl?: boolean,
    ): void {
        this.loading = true;
        this.service = service;
        this.tableId = tableId;
        this.columnIds = columnIds;
        this.sortingParams = defaultSort;
        this.shouldStoreTableDataToUrl = shouldStoreTableDataToUrl;
        this.pageSize = getStoredTablePagesize(this.tableId);
        this.route.queryParamMap.pipe(take(1)).subscribe(params => {
            const loadedData = loadTableAttributesFromUrl(params, this.columnIds);
            this.offset = loadedData.offset;
            this.sortingParams = loadedData.sortingParams || defaultSort;
            this.filtering = loadedData.filtering;
        });
        this.loadData();
    }

    loadData(): void {
        this.loading = true;
        this.latestLoadingData = Date.now();
        const thisQueryTime = this.latestLoadingData;

        this.service
            .list({
                filtering: this.filtering,
                offset: this.offset,
                pageSize: this.pageSize,
                order: this.sortingParams?.order,
                orderBy: this.sortingParams?.key,
            })
            .subscribe({
                next: data => {
                    if (this.latestLoadingData <= thisQueryTime) {
                        this.totalItemCount = data.total_item_count;
                        this.data = data.items;
                        this.loading = false;
                    }
                },
                error: err => {
                    this.hasLoadingError = true;
                    this.loading = false;
                    console.log(err);
                },
            });
    }

    pageChange(offset: number): void {
        this.offset = offset;
        this.loadData();
        if (this.offset === 0) {
            this.shouldStoreTableDataToUrl ? saveTableAttributesToUrl(this.router, this.route, { offset: null }) : null;
        } else {
            this.shouldStoreTableDataToUrl
                ? saveTableAttributesToUrl(this.router, this.route, { offset: this.offset.toString() })
                : null;
        }
    }

    pageSizeChange(pageSize: number): void {
        this.pageSize = pageSize;
        saveTablePagesize(this.tableId, this.pageSize);
    }

    filterChanged(filter: HiveTableFilterEvent): void {
        const filteringValue = filter.value === '' ? null : filter.value;
        if (this.filtering[filter.key] != filteringValue) {
            this.filtering[filter.key] = filteringValue;
            this.offset = 0;
            this.loadData();
            this.shouldStoreTableDataToUrl
                ? saveTableAttributesToUrl(this.router, this.route, {
                      offset: null,
                      [filter.key]: filteringValue,
                  })
                : null;
        }
    }

    sortChanged(sort: HiveSortParams): void {
        this.sortingParams = sort;
        this.offset = 0;
        this.loadData();
        const key = this.sortingParams.order === 'desc' ? this.sortingParams.key + '-' : this.sortingParams.key;
        this.shouldStoreTableDataToUrl
            ? saveTableAttributesToUrl(this.router, this.route, { offset: null, sort: key })
            : null;
    }
}
