import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { ampli } from 'core/src/ampli';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'drr-banner',
    templateUrl: 'drr-banner.component.html',
})
export class DrrBannerComponent extends BaseBanner implements AfterViewInit {
    readonly ampli = ampli;
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('drrLinksTemplate', { static: true }) readonly drrLinksTemplate: TemplateRef<any>;
    @ViewChild('drrButtonTemplate', { static: true }) readonly drrButtonTemplate: TemplateRef<any>;

    ngAfterViewInit(): void {
        const templates = { linksTemplate: this.drrLinksTemplate, buttonTemplate: this.drrButtonTemplate };
        this.populatedNotification = Object.assign({}, this.notification, templates);
    }
}
