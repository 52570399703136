import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { RoleAssignment } from '../models/role-assignment';

@Injectable({ providedIn: 'root' })
export class RoleAssignmentService extends GenericService<RoleAssignment> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: RoleAssignment,
            endpoint: '/rest/v1/assignments',
        });
    }
}
