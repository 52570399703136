import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayedPartner, FeatureFlagDxpService, PartnerRequest, QuoteType, ServiceCatalogQuote } from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { FeatureNames } from '../../../../model/FeatureNames';
import { emailFormatValidator, getDuplicateEntryValidator } from '../../../../utils/form-validators';

type PartnerOptions = 'existingPartner' | 'newPartner';

@Component({
    selector: 'modify-partner-modal',
    templateUrl: './modify-partner-modal.component.html',
})
export class ModifyPartnerModalComponent implements OnInit {
    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly activeOrder: ServiceCatalogQuote;
    @Input() readonly partnerLatestOrg: string;
    @Input() readonly partnersMapping: Map<string, string[]>;
    @Input() readonly partnerToUpdate: DisplayedPartner;
    @Output() readonly partnerRequestChanged = new EventEmitter<PartnerRequest>();

    dataQuoteType = QuoteType;
    isLoading = false;
    selectedPartnerOption: PartnerOptions = 'existingPartner';
    allCurrentFieldsFilled = false;
    partnerOrganizationOptions: string[];

    @HostBinding('class.hbff') featureFlagEnabled = false;

    //Choose Existing Partner Variables
    selectedOrg: string;
    preSelectedOrg: string;
    selectedPartner: string;
    //New Partner Variables
    additionalCommentInput: string;
    confirmForm: UntypedFormGroup;

    // HBFF
    partnerForm: UntypedFormGroup;

    readonly TEXT_MAX_LENGTH = 64;
    readonly PHONE_MAX_LENGTH = 10;
    readonly COMMENT_MAX_LENGTH = 1000;
    readonly analyticsPrefix = 'Modify Partner Modal - ';

    constructor(
        private angulartics2: Angulartics2,
        private fb: UntypedFormBuilder,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService.getFeatureFlag(FeatureNames.REQUEST_PARTNER).subscribe(feature => {
            this.featureFlagEnabled = feature?.enabled === true;
        });

        this.partnerOrganizationOptions = Array.from(this.partnersMapping?.keys() || []);

        this.confirmForm = this.fb.group({
            orgInput: new UntypedFormControl('', Validators.maxLength(this.TEXT_MAX_LENGTH)),
            firstNameInput: new UntypedFormControl('', Validators.maxLength(this.TEXT_MAX_LENGTH)),
            lastNameInput: new UntypedFormControl('', Validators.maxLength(this.TEXT_MAX_LENGTH)),
            phoneInput: new UntypedFormControl('', Validators.maxLength(this.PHONE_MAX_LENGTH)),
            emailInput: new UntypedFormControl('', [
                Validators.maxLength(this.TEXT_MAX_LENGTH),
                emailFormatValidator,
                getDuplicateEntryValidator(),
            ]),
            additionalCommentInput: new UntypedFormControl('', Validators.maxLength(this.COMMENT_MAX_LENGTH)),
        });

        // HBFF
        this.partnerForm = this.fb.group({
            orgInput: new UntypedFormControl(
                {
                    value: this.partnerToUpdate?.accountName || '',
                    disabled: this.isRenewalOrder(),
                },
                [Validators.maxLength(this.TEXT_MAX_LENGTH), Validators.required],
            ),
            nameInput: new UntypedFormControl(this.partnerToUpdate?.contactName || '', [
                Validators.maxLength(this.TEXT_MAX_LENGTH),
                Validators.required,
            ]),
            phoneInput: new UntypedFormControl(
                this.partnerToUpdate?.contactPhoneNumber || '',
                Validators.maxLength(this.PHONE_MAX_LENGTH),
            ),
            emailInput: new UntypedFormControl(this.partnerToUpdate?.contactEmail || '', [
                Validators.maxLength(this.TEXT_MAX_LENGTH),
                Validators.required,
                emailFormatValidator,
                getDuplicateEntryValidator(),
            ]),
            additionalCommentInput: new UntypedFormControl(
                this.partnerToUpdate?.comment || '',
                Validators.maxLength(this.COMMENT_MAX_LENGTH),
            ),
        });
    }

    partnerOrgSelectionChanged(newSelection: string): void {
        if (this.preSelectedOrg !== newSelection) {
            this.selectedPartner = '';
        }
        this.selectedOrg = newSelection;
        this.preSelectedOrg = newSelection;
    }

    isRenewalOrder(): boolean {
        return (
            this.activeOrder?.quoteType === this.dataQuoteType.RENEWAL ||
            this.activeOrder?.quoteType === this.dataQuoteType.SUPPORT_CONTRACT_RENEWAL
        );
    }

    generatePartnerNameList(): string[] {
        return this.isRenewalOrder()
            ? this.partnersMapping?.get(this.partnerLatestOrg)
            : this.partnersMapping?.get(this.selectedOrg);
    }

    modifyPartner(): void {
        if (this.featureFlagEnabled) {
            this.partnerRequestChanged.emit(
                new PartnerRequest({
                    account_name: this.partnerForm.controls.orgInput.value,
                    contact_name: this.partnerForm.controls.nameInput.value,
                    contact_email: this.partnerForm.controls.emailInput.value,
                    contact_phone_number: this.partnerForm.controls.phoneInput.value,
                    comment: this.partnerForm.controls.additionalCommentInput.value,
                }),
            );
            this.angulartics2.eventTrack.next({
                action:
                    this.analyticsPrefix +
                    'Modified partner info successfully by choosing ' +
                    this.selectedPartnerOption,
                properties: { category: 'Action' },
            });
            this.activeModal.close();
            return;
        }

        const name =
            this.confirmForm.controls.firstNameInput.value + ' ' + this.confirmForm.controls.lastNameInput.value;
        const latestInputOrgValue = this.isRenewalOrder()
            ? this.partnerLatestOrg
            : this.confirmForm.controls.orgInput.value;
        this.selectedOrg = this.isRenewalOrder() ? this.partnerLatestOrg : this.selectedOrg;
        const params = new PartnerRequest({
            account_name: this.selectedPartnerOption === 'existingPartner' ? this.selectedOrg : latestInputOrgValue,
            contact_name: this.selectedPartnerOption === 'existingPartner' ? this.selectedPartner : name,
            contact_email:
                this.selectedPartnerOption === 'existingPartner' ? '' : this.confirmForm.controls.emailInput.value,
            contact_phone_number:
                this.selectedPartnerOption === 'existingPartner' ? '' : this.confirmForm.controls.phoneInput.value,
            comment:
                this.selectedPartnerOption === 'existingPartner'
                    ? ''
                    : this.confirmForm.controls.additionalCommentInput.value,
        });
        this.partnerRequestChanged.emit(params);
        this.angulartics2.eventTrack.next({
            action:
                this.analyticsPrefix + 'Modified partner info successfully by choosing ' + this.selectedPartnerOption,
            properties: { category: 'Action' },
        });
        this.activeModal.close();
    }

    clickPartnerOption(newSelectedOption: PartnerOptions): void {
        if (newSelectedOption !== this.selectedPartnerOption) {
            this.selectedPartnerOption = newSelectedOption;
            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Choose ' + newSelectedOption + ' selection',
                properties: { category: 'Action' },
            });
        }
    }

    isSaveButtonValidated(): boolean {
        if (this.featureFlagEnabled) {
            return this.partnerForm.valid;
        }

        // 1. nonRenewal Order and all selections or inputs are filled
        // 2. Renewal Order, Only the partner selection or all inputs except Org is needed to be filled
        if (this.isRenewalOrder()) {
            return (
                (this.selectedPartnerOption === 'existingPartner' && this.selectedPartner?.length > 0) ||
                (this.isRenewalOrder() &&
                    this.selectedPartnerOption === 'newPartner' &&
                    this.confirmForm.controls.firstNameInput.value.length > 0 &&
                    this.confirmForm.controls.lastNameInput.value.length > 0 &&
                    this.confirmForm.controls.emailInput.value.length > 0)
            );
        } else {
            return (
                (this.selectedPartnerOption === 'existingPartner' &&
                    this.selectedOrg?.length > 0 &&
                    this.selectedPartner?.length > 0) ||
                (this.selectedPartnerOption === 'newPartner' &&
                    this.confirmForm.controls.orgInput.value.length > 0 &&
                    this.confirmForm.controls.firstNameInput.value.length > 0 &&
                    this.confirmForm.controls.lastNameInput.value.length > 0 &&
                    this.confirmForm.controls.emailInput.value.length > 0)
            );
        }
    }
}
