import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { take, map, tap, catchError } from 'rxjs/operators';
import { OnDestroy, Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TimeSeriesCache, ITimeSeriesCacheKey } from '../utils/time-series-cache';
import { ListParams } from '../interfaces/list-params';
import { PodMetricAnnotationService } from './pod-metric-annotation.service';
import { ApiCallBatcher } from './api-call-batcher';
import { VolumeMetricAnnotation } from '../models/volume-metric-annotation';
import { PodMetricAnnotation } from '../models/pod-metric-annotation';
import { VolumeMetricAnnotationService } from './volume-metric-annotation.service';
import { WINDOW } from '../../app/injection-tokens';

/** Don't serve cache items older than this */
const TIMESERIES_VALUES_CACHE_DURATION = moment.duration(3, 'minutes');

/**
 * How long to have each ApiCallBatcher wait. Longer waits allow more to be batched together, but also introduce
 * longer delays before the requests are made.
 * In the case of the Performance page, the delays between the api calls are caused by the api calls being made
 * by each individual PerformanceChart, and there is a PerformanceChart per metric type (eg IOPs, Bandwidth, Latency).
 *
 * Note: Normally, this is avoided by having the parent of the chart component making the api calls to fetch data, then pass
 * it to the chart to simply render, but that is not how PerformanceChart was designed and it'll be a fair bit of work to change.
 * Other code, like VM Analytics (through VMTimelineChartListComponent) handle this much more cleanly.
 */
const BATCH_WAIT_TIME_MS = 100;

export type Granularity = 'pt1h' | 'p1d';

export type IAggregationMethod = 'avg' | 'AVERAGE' | 'max' | 'MAX' | 'min' | 'last' | 'blended max';

export type IArrayAggregationMethod = 'sum' | 'max' | 'avg';

export const enum MetricsEndpoint {
    aggregations = '/rest/v1/analysis/aggregatedmetrics/metrics',
    arrays = '/rest/v1/metrics/arrays',
    arraysV3 = '/rest/v3/metrics/arrays',
    buckets = '/rest/v3/metrics/buckets',
    directories = '/rest/v1/metrics/directories',
    directoriesV3 = '/rest/v3/metrics/directories',
    filesystems = '/rest/v1/metrics/file-systems',
    filesystemsV3 = '/rest/v3/metrics/file-systems',
    licenseV3 = '/rest/v3/metrics/license',
    pods = '/rest/v1/metrics/pods',
    podReplicaLinks = '/rest/v1/metrics/pod-replica-link',
    volumes = '/rest/v1/metrics/volumes',
    volumesAggregated = '/rest/v1/metrics/volumes/aggregated',
    volumesV3 = '/rest/v3/metrics/volumes',
    pxVolumes = '/rest/v1/metrics/portworx/time-series',
    pxNodes = '/rest/v1/metrics/portworx/time-series',
    pxPools = '/rest/v1/metrics/portworx/time-series',
}

export type HttpPerformanceHistoryMetricNames =
    | 'http_read_latency'
    | 'http_write_latency'
    | 'http_other_latency'
    | 'http_read_dir_latency'
    | 'http_write_dir_latency'
    | 'http_read_file_latency'
    | 'http_write_file_latency'
    | 'http_specific_other_latency'
    | 'http_read_iops'
    | 'http_write_iops'
    | 'http_other_iops'
    | 'http_read_dir_iops'
    | 'http_write_dir_iops'
    | 'http_read_file_iops'
    | 'http_write_file_iops'
    | 'http_specific_other_iops'
    | 'http_read_bandwidth'
    | 'http_write_bandwidth';

export type SmbPerformanceHistoryMetricNames =
    | 'smb_read_latency'
    | 'smb_write_latency'
    | 'smb_other_latency'
    | 'smb_read_iops'
    | 'smb_write_iops'
    | 'smb_other_iops'
    | 'smb_read_bandwidth'
    | 'smb_write_bandwidth';

export type S3PerformanceHistoryMetricNames =
    | 's3_read_latency'
    | 's3_write_latency'
    | 's3_other_latency'
    | 'read_bucket_latency'
    | 'write_bucket_latency'
    | 'read_object_latency'
    | 'write_object_latency'
    | 's3_specific_other_latency'
    | 's3_read_iops'
    | 's3_write_iops'
    | 's3_other_iops'
    | 'read_bucket_iops'
    | 'write_bucket_iops'
    | 'read_object_iops'
    | 'write_object_iops'
    | 's3_specific_other_iops'
    | 's3_read_bandwidth'
    | 's3_write_bandwidth';

export type FlashBladePerProtocolPerformanceHistoryMetricNames =
    | HttpPerformanceHistoryMetricNames
    | FilesystemPerformanceHistoryMetricNames
    | SmbPerformanceHistoryMetricNames
    | S3PerformanceHistoryMetricNames;

export type ArrayVolumesPerformanceHistoryMetricNames =
    | 'bm_total_busyness'
    | 'total_bandwidth'
    | 'read_bandwidth'
    | 'write_bandwidth'
    | 'mirror_write_bandwidth'
    | 'total_iops'
    | 'read_iops'
    | 'write_iops'
    | 'mirror_write_iops'
    | 'other_iops'
    | 'total_latency'
    | 'read_latency'
    | 'write_latency'
    | 'mirror_write_latency'
    | 'other_latency'
    | 'cpu_usage'
    | 'mem_usage'
    | FlashBladePerProtocolPerformanceHistoryMetricNames;

export type DirectoryPerformanceHistoryMetricNames =
    | 'all_read_latency'
    | 'all_write_latency'
    | 'all_read_bandwidth'
    | 'all_write_bandwidth'
    | 'all_read_iops'
    | 'all_write_iops'
    | 'all_others_latency_per_op'
    | 'all_others_ops_per_sec';

export type FilesystemPerformanceHistoryMetricNames =
    | 'nfs_read_latency'
    | 'nfs_write_latency'
    | 'nfs_other_latency'
    | 'nfs_read_bandwidth'
    | 'nfs_write_bandwidth'
    | 'nfs_read_iops'
    | 'nfs_write_iops'
    | 'nfs_other_iops';

export type CapacityHistoryMetricNames =
    | 'unique'
    | 'volumes'
    | 'file_systems'
    | 'object_store'
    | 'replication'
    | 'snapshots'
    | 'shared'
    | 'system'
    | 'used_total'
    | 'total_available'
    | 'utility_usage';

export type GroupCapacityHistoryMetricNames =
    | 'unique_space'
    | 'file_system_space'
    | 's3_bucket_space'
    | 'replication_space'
    | 'physical_shared'
    | 'snapshot_space'
    | 'system_space'
    | 'total_used'
    | 'volume_space'
    | 'total_system_size'
    | 'unique_effective_used_space'
    | 'snapshots_effective_used_space'
    | 'shared_effective_used_space'
    | 'total_effective_used_space';

export type VolumeCapacityHistoryMetricNames =
    | 'volume_space'
    | 'snapshot_space'
    | 'total_physical'
    | 'virtual_space'
    | 'snapshot_virtual_space'
    | 'total_virtual'
    | 'unique_effective_used_space'
    | 'snapshots_effective_used_space'
    | 'total_effective_used_space';

export type PodReplicaLinkHistoryMetricNames = 'avg_lag' | 'max_lag' | 'direction' | 'status' | 'recovery_point';

export type MetricsHistoryMetricNames =
    | ArrayVolumesPerformanceHistoryMetricNames
    | DirectoryPerformanceHistoryMetricNames
    | FilesystemPerformanceHistoryMetricNames
    | CapacityHistoryMetricNames
    | GroupCapacityHistoryMetricNames
    | VolumeCapacityHistoryMetricNames
    | PodReplicaLinkHistoryMetricNames;

export type GroupMetricsHistoryMetricNames =
    | ArrayVolumesPerformanceHistoryMetricNames
    | GroupCapacityHistoryMetricNames;

export const arrayCapacityMetricsToGroupMetrics: Map<CapacityHistoryMetricNames, GroupCapacityHistoryMetricNames> =
    new Map([
        ['unique', 'unique_space'],
        ['volumes', 'volume_space'],
        ['file_systems', 'file_system_space'],
        ['object_store', 's3_bucket_space'],
        ['snapshots', 'snapshot_space'],
        ['replication', 'replication_space'],
        ['shared', 'physical_shared'],
        ['system', 'system_space'],
        ['used_total', 'total_used'],
        ['total_available', 'total_system_size'],
    ]);

export type ITimeseriesValues = [number, number][];

type ReplicaLinkMetricValues = {
    avg_lag: number;
    max_lag: number;
    direction: number;
    status: number;
    recovery_point: number;
};

export type ConsolidatedReplicaLinkValues = [number, ReplicaLinkMetricValues][];

export type BatchMetricsHistoryResult = Record<string, MetricsHistoryResult>;

export type MetricsHistoryResult = {
    [prop in MetricsHistoryMetricNames]?: ITimeseriesValues;
};

export type EucMetricsHistory = {
    total: ITimeseriesValues;
    unique: ITimeseriesValues;
    snapshot: ITimeseriesValues;
    shared: ITimeseriesValues;
    system: ITimeseriesValues;
};

export interface IMetricsHistoryOptions {
    startTime: moment.Moment;
    endTime: moment.Moment;
    sourceGranularity?: Granularity;
    aggregationMethod?: IAggregationMethod;
    arrayAggregationMethod?: IArrayAggregationMethod;
    requestedGranularitySecs?: number;
    maxPoints?: number;
    metricName?: MetricsHistoryMetricNames;
}

export interface IMetricsGroupCapacityHistoryOptions extends IMetricsHistoryOptions {
    metricName: GroupMetricsHistoryMetricNames;
}

export interface ApiCallBatcherInputs extends IMetricsHistoryOptions {
    id?: string;
    arrayId?: string;
    applianceId?: string;
    bucketId?: string;
    directoryId?: string;
    fileSystemId?: string;
    licenseId?: string;
    podId?: string;
    podReplicaLinkId?: string;
    volumeId?: string;
    volumeIds?: string[];
}

export interface ApiAnnotationCallBatcherInputs extends IMetricsHistoryOptions {
    arrayId?: string;
    podId?: string;
    types: MetricAnnotationType[];
    volumeId?: string;
}

export interface IGetMetricsHistoryResult {
    values: MetricsHistoryResult;
}

// TODO: This code is filthy and repetitive and I feel dirty for having touched it.
//      Update 5/23/2021: Ok, its slightly better now... I guess...
@Injectable({ providedIn: 'root' })
export class MetricsHistoryService implements OnDestroy {
    private timeseriesValuesCache: TimeSeriesCache<IGetMetricsHistoryResult>;
    private replicaLinkValuesCache: TimeSeriesCache<ConsolidatedReplicaLinkValues>;

    private aggregatedBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherAggregatedGroupKey(request),
        requests => this.apiBatcherAggregatedExecute(requests),
        this.window,
    );

    private arrayBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherArrayGroupKey(request),
        requests => this.apiBatcherArrayExecute(requests),
        this.window,
    );

    private directoryBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherDirectoryGroupKey(request),
        requests => this.apiBatcherDirectoryExecute(requests),
        this.window,
    );

    private fileSystemBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherFileSystemGroupKey(request),
        requests => this.apiBatcherFileSystemExecute(requests),
        this.window,
    );

    private licenseBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherLicenseGroupKey(request),
        requests => this.apiBatcherLicenseExecute(requests),
        this.window,
    );

    private podBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherPodGroupKey(request),
        requests => this.apiBatcherPodExecute(requests),
        this.window,
    );

    private podReplicaLinkBatcher = new ApiCallBatcher<ApiCallBatcherInputs, ConsolidatedReplicaLinkValues>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherPodReplicaLinkGroupKey(request),
        requests => this.apiBatcherPodReplicaLinkExecute(requests),
        this.window,
    );

    private volumeBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherVolumeGroupKey(request),
        requests => this.apiBatcherVolumeExecute(requests),
        this.window,
    );

    private volumeAggregatedBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherVolumeAggregatedGroupKey(request),
        requests => this.apiBatcherVolumeAggregatedExecute(requests),
        this.window,
    );

    private podAnnotationBatcher = new ApiCallBatcher<ApiAnnotationCallBatcherInputs, PodMetricAnnotation[]>(
        BATCH_WAIT_TIME_MS,
        request => this.apiAnnotationBatcherPodGroupKey(request),
        requests => this.apiAnnotationBatcherPodExecute(requests),
        this.window,
    );

    private volumeAnnotationBatcher = new ApiCallBatcher<ApiAnnotationCallBatcherInputs, VolumeMetricAnnotation[]>(
        BATCH_WAIT_TIME_MS,
        request => this.apiAnnotationBatcherVolumeGroupKey(request),
        requests => this.apiAnnotationBatcherVolumeExecute(requests),
        this.window,
    );

    constructor(
        private http: HttpClient,
        private podMetricAnnotationService: PodMetricAnnotationService,
        private volumeMetricAnnotationService: VolumeMetricAnnotationService,
        @Inject(WINDOW) private window: Window,
    ) {
        this.timeseriesValuesCache = new TimeSeriesCache<IGetMetricsHistoryResult>(TIMESERIES_VALUES_CACHE_DURATION);
        this.replicaLinkValuesCache = new TimeSeriesCache<ConsolidatedReplicaLinkValues>(
            TIMESERIES_VALUES_CACHE_DURATION,
        );
    }

    ngOnDestroy(): void {
        this.timeseriesValuesCache.destroy();
        this.replicaLinkValuesCache.destroy();
    }

    /**
     * Clears all cached items in this service.
     * This is required only in the rare cases when the underlying timeseries data of an object changes, without the object's id changing.
     * Eg, modifying the membership of an aggregated metrics group.
     */
    clearCache(): void {
        this.timeseriesValuesCache.clear();
        this.replicaLinkValuesCache.clear();
    }

    getAggregatedMetricsHistoryTimeseries(
        groupId: string,
        options: IMetricsGroupCapacityHistoryOptions,
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            id: groupId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.aggregatedBatcher.enqueue(apiBatcherInput);
    }

    getArrayMetricsHistoryTimeseries(
        arrayId: string,
        options: IMetricsHistoryOptions,
        annotationTypes?: MetricAnnotationType[],
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            arrayId: arrayId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.arrayBatcher.enqueue(apiBatcherInput);
    }

    getLicenseMetricsHistoryTimeseries(
        licenseId: string,
        options: IMetricsHistoryOptions,
        annotationTypes?: MetricAnnotationType[],
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            licenseId: licenseId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            arrayAggregationMethod: options.arrayAggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.licenseBatcher.enqueue(apiBatcherInput);
    }

    getDirectoryMetricsHistoryTimeseries(
        applianceId: string,
        directoryId: string,
        options: IMetricsHistoryOptions,
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            applianceId: applianceId,
            directoryId: directoryId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.directoryBatcher.enqueue(apiBatcherInput);
    }

    getFilesystemMetricsHistoryTimeseries(
        arrayId: string,
        filesystemId: string,
        rootDirectoryId: string,
        options: IMetricsHistoryOptions,
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            arrayId: arrayId,
            fileSystemId: filesystemId,
            directoryId: rootDirectoryId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.fileSystemBatcher.enqueue(apiBatcherInput);
    }

    getPodMetricsHistoryTimeseries(
        getTimeseries: (result: IGetPodPerformanceMetricsResult) => ITimeseriesValues,
        isPod: boolean,
        podId: string,
        options: IMetricsHistoryOptions,
        annotationTypes?: MetricAnnotationType[],
    ): Observable<ITimeseriesValuesInfo> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            podId: podId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        const apiAnnotationBatcherInput: ApiAnnotationCallBatcherInputs = {
            podId: podId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            types: annotationTypes || [],
        };

        if (!isPod) {
            return this.podBatcher.enqueue(apiBatcherInput).pipe(
                map((timeSeriesResult: IGetMetricsHistoryResult) => {
                    const trueTimeSeriesResult = (timeSeriesResult as any).values.items[0];
                    return {
                        values: cloneDeep(getTimeseries(trueTimeSeriesResult)) || [],
                        granularity: null,
                        zones: null,
                    };
                }),
                take(1),
            );
        }

        return zip(
            this.podBatcher.enqueue(apiBatcherInput),
            this.podAnnotationBatcher.enqueue(apiAnnotationBatcherInput),
            (timeSeriesResult: IGetMetricsHistoryResult, annotations: PodMetricAnnotation[]) => {
                const trueTimeSeriesResult = (timeSeriesResult as any).values.items[0];
                const zones = annotationTypes && annotationTypes.length ? this.getPodChartZones(annotations) : null;

                return {
                    values: cloneDeep(getTimeseries(trueTimeSeriesResult)) || [],
                    granularity: null,
                    zones: zones,
                };
            },
        ).pipe(take(1));
    }

    getPodReplicaLinkMetricsHistoryTimeseries(
        arrayId: string,
        podReplicaLinkId: string,
        options: IMetricsHistoryOptions,
    ): Observable<ConsolidatedReplicaLinkValues> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            arrayId: arrayId,
            podReplicaLinkId: podReplicaLinkId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
        };

        return this.podReplicaLinkBatcher.enqueue(apiBatcherInput);
    }

    getVolumeMetricsHistoryTimeseries(
        arrayId: string,
        volumeId: string,
        options: IMetricsHistoryOptions,
        annotationTypes?: MetricAnnotationType[],
    ): Observable<ITimeseriesValuesInfo> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            arrayId: arrayId,
            volumeId: volumeId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        const apiAnnotationBatcherInput: ApiAnnotationCallBatcherInputs = {
            arrayId: arrayId,
            // might need to figure out a better place to change this (or add a second parameter?)
            // The annotations require the full volume id with ':' separator
            volumeId: arrayId + ':' + volumeId,
            startTime: options.startTime,
            endTime: options.endTime,
            maxPoints: options.maxPoints,
            types: annotationTypes || [],
        };

        //We don't need to deal with annotations if none exist
        if (!annotationTypes || !annotationTypes.length) {
            return this.volumeBatcher.enqueue(apiBatcherInput).pipe(
                map(item => {
                    return {
                        values: cloneDeep(item.values[options.metricName]) || [],
                        granularity: null,
                        zones: null,
                    };
                }),
                take(1),
            );
        }

        return zip(
            this.volumeBatcher.enqueue(apiBatcherInput),
            this.volumeAnnotationBatcher.enqueue(apiAnnotationBatcherInput),
            (timeSeriesResult: IGetMetricsHistoryResult, annotations: VolumeMetricAnnotation[]) => {
                const zones = this.getVolumeChartZones(annotations);

                return {
                    values: cloneDeep(timeSeriesResult.values[options.metricName]) || [],
                    granularity: null,
                    zones: zones,
                };
            },
        ).pipe(take(1));
    }

    getVolumeMetricsHistoryTimeseriesAggregated(
        arrayId: string,
        volumeId: string,
        options: IMetricsHistoryOptions,
    ): Observable<ITimeseriesValuesInfo> {
        let sourceGranularity = 'pt1h' as Granularity;
        let requestedGranularitySecs = 3600; // 1 hour
        // Time window is either 1 month (or less) or 3 month (or more), nothing in between.
        // Therefore, using 40 days as a safe threshold to determine what granularity to use.
        // Using 40 days also helps to avoid potential issues with timezones, daylight savings,
        // and whether to use 30 days for a month or exact number of days of the month, etc.
        if (options.endTime.diff(options.startTime, 'day') > 40) {
            sourceGranularity = 'p1d' as Granularity;
            requestedGranularitySecs = 86400; // 1 day
        }

        const apiBatcherInput: ApiCallBatcherInputs = {
            arrayId: arrayId,
            volumeIds: [volumeId],
            startTime: options.startTime,
            endTime: options.endTime,
            sourceGranularity: sourceGranularity,
            aggregationMethod: options.aggregationMethod,
            requestedGranularitySecs: requestedGranularitySecs,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.volumeAggregatedBatcher.enqueue(apiBatcherInput).pipe(
            map(item => {
                const timeseries = cloneDeep(item.values[volumeId][options.metricName]) || [];
                if (requestedGranularitySecs === 3600) {
                    timeseries.forEach(pt => {
                        // Normalize all timestamps to the start of the hour in local time
                        pt[0] = moment(pt[0]).startOf('hour').valueOf();
                    });
                } else {
                    timeseries.forEach(pt => {
                        // Normalize all timestamps to the start of the day in local time
                        pt[0] = moment(pt[0]).startOf('day').valueOf();
                    });
                }
                return {
                    values: timeseries,
                    granularity: null,
                    zones: null,
                };
            }),
            take(1),
            catchError(e => {
                console.warn('getVolumeMetricsHistoryTimeseriesAggregated error', e);
                return of(<ITimeseriesValuesInfo>{
                    values: [],
                    granularity: null,
                    zones: null,
                });
            }),
        );
    }

    private getPodChartZones(annotations: PodMetricAnnotation[]): IPerformanceChartZone[] {
        const zones: IPerformanceChartZone[] = [];
        annotations.forEach(annotation => {
            switch (annotation.type) {
                /** Currently the only annotation type treated as a chart zone for pods is 'not_mirrored' */
                case 'not_mirrored':
                    zones.push({
                        value: annotation._state_started.valueOf(),
                        type: 'mirrored_write',
                        dashStyle: 'Solid',
                    });
                    zones.push({ value: annotation._state_ended.valueOf(), type: 'local_write', dashStyle: 'Dot' });
                    break;
                default:
                    console.warn('Unexpected annotation type for pods: ' + annotation.type);
            }
        });
        return zones;
    }

    private getVolumeChartZones(annotations: VolumeMetricAnnotation[]): IPerformanceChartZone[] {
        const zones: IPerformanceChartZone[] = [];
        annotations.forEach(annotation => {
            switch (annotation.type) {
                /** Currently the only annotation type treated as a chart zone is 'mirrored_write' */
                case 'mirrored_write':
                    zones.push({ value: annotation._state_started.valueOf(), type: 'local_write' }); // end of a local_write zone
                    zones.push({ value: annotation._state_ended.valueOf(), type: 'mirrored_write' }); // end of a mirrored_write zone
                    break;
                default:
                    console.warn('Unexpected annotation type for volumes: ' + annotation.type);
                    break;
            }
        });
        return zones;
    }

    private createCacheKey(
        metricNames: MetricsHistoryMetricNames[],
        id: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregationMethod: IAggregationMethod,
    ): ITimeSeriesCacheKey {
        return {
            metricNames: metricNames,
            entityId: id,
            startTime: startTime,
            endTime: endTime,
            aggregationMethod: aggregationMethod,
        };
    }

    private apiAnnotationBatcherPodGroupKey(request: ApiAnnotationCallBatcherInputs): string {
        const keyValues = [
            request.podId,
            request.types,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiAnnotationBatcherPodExecute(
        requests: ApiAnnotationCallBatcherInputs[],
    ): Observable<PodMetricAnnotation[]> {
        const { types, podId, startTime, endTime, maxPoints } = requests[0];
        const typesStr = types.join(',');

        const params: ListParams<PodMetricAnnotation> = {
            extra:
                `pod_ids=${podId}&types=${typesStr}` +
                `&start_time=${startTime.valueOf()}&end_time=${endTime.valueOf()}` +
                `&max_points=${maxPoints}`,
        };
        return this.podMetricAnnotationService.list(params).pipe(map(item => item.response));
    }

    private apiAnnotationBatcherVolumeGroupKey(request: ApiAnnotationCallBatcherInputs): string {
        const keyValues = [
            request.volumeId,
            request.arrayId,
            request.types,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiAnnotationBatcherVolumeExecute(
        requests: ApiAnnotationCallBatcherInputs[],
    ): Observable<VolumeMetricAnnotation[]> {
        const { types, volumeId, startTime, endTime, maxPoints } = requests[0];
        const typesStr = types.join(',');

        const params: ListParams<VolumeMetricAnnotation> = {
            extra:
                `volume_ids=${volumeId}&types=${typesStr}` +
                `&start_time=${startTime.valueOf()}&end_time=${endTime.valueOf()}` +
                `&max_points=${maxPoints}`,
        };
        return this.volumeMetricAnnotationService.list(params).pipe(map(item => item.response));
    }

    private apiBatcherAggregatedGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [request.id, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    private apiBatcherAggregatedExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { id, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            group_id: id,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(uniqueMetricList, id, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(MetricsEndpoint.aggregations, cacheKey, params);
    }

    /**
     * Gets the batch bucket key to use for the ApiCallBatcher.
     */
    private apiBatcherArrayGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [request.arrayId, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    private apiBatcherArrayExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { arrayId, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            array_id: arrayId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(uniqueMetricList, arrayId, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(MetricsEndpoint.arrays, cacheKey, params);
    }

    private apiBatcherDirectoryGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.applianceId,
            request.directoryId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiBatcherDirectoryExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { startTime, endTime, maxPoints, aggregationMethod, directoryId, applianceId } = requests[0];

        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            appliance_id: applianceId,
            directory_id: directoryId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(uniqueMetricList, directoryId, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(MetricsEndpoint.directories, cacheKey, params);
    }

    private apiBatcherFileSystemGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.fileSystemId,
            request.arrayId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
            request.directoryId || '',
        ];
        return keyValues.join('_');
    }

    private apiBatcherFileSystemExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { fileSystemId, directoryId, arrayId, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const rootDirectoryId = directoryId || '';
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            file_system_guid: fileSystemId,
            root_directory_id: rootDirectoryId,
            array_id: arrayId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(
            uniqueMetricList,
            fileSystemId + rootDirectoryId,
            startTime,
            endTime,
            aggregationMethod,
        );

        return this.fetchTimeseriesData(MetricsEndpoint.filesystems, cacheKey, params);
    }

    private apiBatcherLicenseGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.licenseId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
            request.aggregationMethod,
            request.arrayAggregationMethod,
        ];
        return keyValues.join('_');
    }

    private apiBatcherLicenseExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { licenseId, startTime, endTime, maxPoints, aggregationMethod, arrayAggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            license_id: licenseId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            timeAggregation: aggregationMethod || undefined,
            arrayAggregation: arrayAggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(uniqueMetricList, licenseId, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(MetricsEndpoint.licenseV3, cacheKey, params);
    }

    private apiBatcherPodGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [request.podId, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    private apiBatcherPodExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { podId, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            ids: podId,
            start_time: String(startTime.valueOf()),
            end_time: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(uniqueMetricList, podId, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(MetricsEndpoint.pods, cacheKey, params);
    }

    private apiBatcherPodReplicaLinkGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.podReplicaLinkId,
            request.arrayId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiBatcherPodReplicaLinkExecute(
        requests: ApiCallBatcherInputs[],
    ): Observable<ConsolidatedReplicaLinkValues> {
        const { podReplicaLinkId, arrayId, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const uniqueMetricList: PodReplicaLinkHistoryMetricNames[] = [
            'avg_lag',
            'max_lag',
            'direction',
            'status',
            'recovery_point',
        ];

        const cacheKey = this.createCacheKey(uniqueMetricList, podReplicaLinkId, startTime, endTime, aggregationMethod);
        const cacheValue = this.replicaLinkValuesCache.getValue(cacheKey);
        if (cacheValue) {
            // When we return from cache, add a timeout so we can break up the processing of the results a bit more instead
            // of locking up the thread until all charts get fully processed (which can be pretty slow).

            return Observable.create(observer => {
                setImmediate(() => {
                    observer.next(cacheValue);
                    observer.complete();
                });
            });
        }

        const params = {
            metrics: uniqueMetricList.join(','),
            replica_link_id: podReplicaLinkId,
            appliance_id: arrayId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        return this.http.get(MetricsEndpoint.podReplicaLinks, { params }).pipe(
            map(response => {
                const metricsResultMap = new Map<number, ReplicaLinkMetricValues>();
                uniqueMetricList.forEach(metricName => {
                    response[metricName].forEach(dataPoint => {
                        const currentMapVal: ReplicaLinkMetricValues = metricsResultMap.has(dataPoint[0])
                            ? metricsResultMap.get(dataPoint[0])
                            : {
                                  avg_lag: undefined,
                                  max_lag: undefined,
                                  direction: undefined,
                                  status: undefined,
                                  recovery_point: undefined,
                              };

                        currentMapVal[metricName] = dataPoint[1];
                        metricsResultMap.set(dataPoint[0], currentMapVal);
                    });
                });

                return Array.from(metricsResultMap);
            }),
            tap(result => {
                this.replicaLinkValuesCache.put(cacheKey, result); // Add it to the cache for next time
            }),
        );
    }

    private apiBatcherVolumeGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.volumeId,
            request.arrayId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiBatcherVolumeExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { volumeId, arrayId, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            volume_id: volumeId,
            array_id: arrayId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        const cacheKey = this.createCacheKey(uniqueMetricList, volumeId, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(MetricsEndpoint.volumes, cacheKey, params);
    }

    private apiBatcherVolumeAggregatedGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [
            request.volumeIds,
            request.arrayId,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiBatcherVolumeAggregatedExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const {
            volumeIds,
            arrayId,
            startTime,
            endTime,
            maxPoints,
            sourceGranularity,
            aggregationMethod,
            requestedGranularitySecs,
        } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();
        const commaSeparatedUniqueVolumeIdList = volumeIds.sort().join(',');

        const params = {
            metrics: uniqueMetricList.join(','),
            volume_ids: commaSeparatedUniqueVolumeIdList,
            array_id: arrayId,
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            source_granularity: sourceGranularity || undefined,
            aggregation: aggregationMethod || undefined,
            requested_granularity: requestedGranularitySecs?.toString(),
        };

        const cacheKey = this.createCacheKey(
            uniqueMetricList,
            arrayId + '|' + commaSeparatedUniqueVolumeIdList,
            startTime,
            endTime,
            aggregationMethod,
        );

        return this.fetchTimeseriesData(MetricsEndpoint.volumesAggregated, cacheKey, params);
    }

    /**
     * Fetches the timeseries data for endspoints that use the standard MetricsHistoryResult format.
     */
    private fetchTimeseriesData(
        endpoint: MetricsEndpoint,
        cacheKey: ITimeSeriesCacheKey,
        requestParams: { [key: string]: string },
    ): Observable<IGetMetricsHistoryResult> {
        const cacheValue = this.timeseriesValuesCache.getValue(cacheKey);
        if (cacheValue) {
            return of(cacheValue);
        }

        return this.http.get<MetricsHistoryResult>(endpoint, { params: requestParams }).pipe(
            map(response => {
                return <IGetMetricsHistoryResult>{
                    values: response,
                };
            }),
            tap(result => {
                this.timeseriesValuesCache.put(cacheKey, result); // Add it to the cache for next time
            }),
        );
    }
}
