import _ from 'lodash';
import moment from 'moment';
import { Chart, Options, Point, SeriesColumnOptions, SeriesLineOptions } from 'highcharts/highstock';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';

import { FBlade, PureArray } from '../../../../model/model';
import {
    IHealthData,
    ArraysManager,
    HealthDataMetric,
    IArrayCapacityData,
} from '../../../../services/arrays-manager.service';
import PureUtils from '../../../../utils/pure_utils';
import { formatRatio } from '../../../../utils/formatRatio';
import { formatSize } from '../../../../utils/formatSize';
import {
    buildPerfChartTooltipHtml,
    clearSelectedPoints,
    getPointsByXValue,
    selectPoints,
} from '../../../../utils/highcharts';
import { ICapacityConfig } from '../array-expanded-card-capacity.component';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { smartTimer } from '@pstg/smart-timer';

const CHART_WIDTH = 538;
const CHART_HEIGHT = 330;
const CAPACITY_REFRESH_INTERVAL = moment.duration(60, 'minutes').asMilliseconds();
const REDRAW = true;
const ANIMATE = true;

const X_AXIS_DATE_FORMAT = 'MMM YYYY';

@Component({
    selector: 'array-expanded-card-capacity-chart',
    templateUrl: 'array-expanded-card-capacity-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayExpandedCardCapacityChartComponent implements OnInit, OnDestroy {
    @Input() readonly array: PureArray;
    @Input() readonly capacityMetadata: ICapacityConfig[];
    @ViewChild('chartElem', { static: true }) readonly chartElem: ElementRef;

    hasNoData = false;
    chart: Chart;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private arraysManager: ArraysManager,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.chart = this.buildChart();
        smartTimer(0, CAPACITY_REFRESH_INTERVAL)
            .pipe(
                takeUntil(this.destroy$),
                switchMap(() => this.arraysManager.getCapacityData(this.array.id, this.array instanceof FBlade)),
            )
            .subscribe(data => this.updateChartData(data));
    }

    ngOnDestroy(): void {
        this.destroy$.next();

        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    onChartMouseLeave(): void {
        // Because we're using the selected state to do the hover style, we need to remove that state
        // when the mouse leaves the chart, otherwise the series will get stuck in the hover state
        clearSelectedPoints(this.chart);
    }

    private updateChart(newData: IHealthData | null): void {
        if (!this.chart) {
            return;
        }
        const orderedMetadata = this.capacityMetadata.slice().reverse();
        orderedMetadata.forEach(s => {
            if (newData[s.rawKey]) {
                // find chart series with matching name
                const matchingSeries = this.chart.series.find(individualSeries => individualSeries.name === s.label);
                if (matchingSeries) {
                    const seriesData = newData && newData[s.rawKey];
                    matchingSeries.setData(seriesData, !REDRAW);
                } else {
                    console.warn(
                        `No matching series found for "${s.label}" on expanded card capacity chart of OE: ${this.array.hostname}`,
                    );
                }
            }
        });

        this.chart.redraw();

        this.hasNoData = newData === null;
    }

    private updateChartData(data: IArrayCapacityData): void {
        const normalizedData = data && this.normalizeAllCapacityData(data);
        this.updateChart(normalizedData);
        this.cdr.markForCheck();
    }

    private buildChart(): Chart {
        const tooltipFormatter = (p: Point) => this.buildTooltipHTML(p);
        const closestMonth = (d: number) => this.closestMonth(d);

        // eslint-disable-next-line prefer-const
        let chart: Chart;

        const chartOptions: Options = {
            tooltip: {
                shared: false,
                backgroundColor: 'rgba(240, 240, 240, 0.9)',
                borderWidth: 1,
                borderColor: '#CCCCCC',
                useHTML: true,
                style: {
                    padding: '5px',
                    margin: 0,
                },
                positioner: (labelWidth, _labelHeight, point) => {
                    let x: number;
                    if (point.plotX + labelWidth > chart.plotWidth - 20) {
                        x = point.plotX + chart.plotLeft - labelWidth - 15;
                    } else {
                        x = point.plotX + chart.plotLeft + 10;
                    }
                    return { x: x, y: chart.plotTop };
                },
                formatter: function (): string {
                    return tooltipFormatter(this.point);
                },
            },
            chart: {
                renderTo: this.chartElem.nativeElement,
                type: 'column',
                animation: !ANIMATE,
                width: CHART_WIDTH,
                height: CHART_HEIGHT,
            },
            title: {
                text: '13 Month Average',
                margin: 5,
                style: { fontSize: '14px' },
            },
            xAxis: {
                type: 'datetime',
                crosshair: false,
                tickInterval: moment.duration(1, 'month').asMilliseconds(),
                labels: {
                    formatter: function (): string {
                        const date = closestMonth(this.value as number)
                            .format(X_AXIS_DATE_FORMAT)
                            .split(' ');
                        return `<div style="text-align: center;">${date[0]}<br />${date[1]}</div>`;
                    },
                    useHTML: true,
                },
            },
            yAxis: [
                {
                    gridLineDashStyle: 'Dash',
                    labels: { enabled: false },
                    title: { text: null },
                },
            ],
            plotOptions: {
                series: {
                    animation: !ANIMATE,
                    allowPointSelect: true,

                    // NOTE: There appears to be an issue with Highcharts in that it isn't taking the configuration
                    // when specified in the 'line' plotOptions. So we have to stick these under 'series'...
                    // Seems to be related to the fact that we specify default marker options at the series level as well (in highcharts.config.ts).
                    marker: {
                        enabled: true,
                        lineWidth: 0,
                        radius: 3,
                        symbol: 'circle',
                        fillColor: null,
                        states: {
                            select: {
                                lineWidth: 2,
                                lineColor: 'rgba(255,255,255,0.8)',
                                radius: 3,
                            },
                        },
                    },
                },
                column: {
                    grouping: false,
                    stacking: 'normal',
                    borderColor: '#FFFFFF',
                    pointWidth: 18,
                },
            },
            legend: {
                enabled: false,
            },
        };

        // If we have a secondary series, add another y axis
        if (this.capacityMetadata.some(s => s.secondarySeries)) {
            if (chartOptions.yAxis instanceof Array) {
                chartOptions.yAxis.push({
                    gridLineDashStyle: 'Dash',
                    labels: { enabled: false },
                    title: { text: null },
                    opposite: true,
                    min: 0,
                });
            }
        }

        // Create instance
        chart = new Chart(chartOptions);

        // TODO: We're not using s.outline. Plus, we can make these charts look better...
        const orderedMetadata = this.capacityMetadata.slice().reverse();
        orderedMetadata.forEach((s, idx) => {
            const seriesToAdd = {
                type: s.secondarySeries ? 'line' : 'column',
                yAxis: s.secondarySeries ? 1 : 0,
                zIndex: this.capacityMetadata.length - idx, // Make them show up in the order they are defined in the config
                name: s.label,
                color: s.fill,
                states: {
                    select: {
                        color: PureUtils.colorStringToRgba(s.fill).lighten(0.1).toRGBAString(),
                        borderColor: '#cccccc',
                    },
                },
            } as SeriesLineOptions | SeriesColumnOptions;

            chart.addSeries(seriesToAdd, !REDRAW, !ANIMATE);
        });

        return chart;
    }

    /**
     * Computes the closest start of month (UTC) to given time
     */
    private closestMonth(time: number): moment.Moment {
        const fullDate = moment.utc(time);
        let year = fullDate.year();
        let month = fullDate.month();
        const day = fullDate.date();
        if (day > 1) {
            month++;
            if (month > 11) {
                month = 0;
                year++;
            }
        }
        return moment.utc([year, month, 1, 0, 0, 0]);
    }

    private normalizeAllCapacityData(capacityData: IHealthData): IHealthData {
        const normalizedAllCapacityData: IHealthData = {};

        this.capacityMetadata.forEach(s => {
            const seriesData = this.normalize((capacityData && capacityData[s.rawKey]) || [], s.rawKey, this.array);
            normalizedAllCapacityData[s.rawKey] = seriesData;
        });

        return normalizedAllCapacityData;
    }

    /**
     * Fills in gaps in data. Gaps can be anywhere (start, middle, end).
     * This algorithm assumes that data is sorted by time (increasing).
     */
    private normalize(
        data: ITimeseriesValues,
        rawKey: HealthDataMetric | string,
        currentArray: PureArray,
    ): ITimeseriesValues {
        let tempData = data.slice(0);
        let dataLength = tempData.length;
        const newData: ITimeseriesValues = [];
        const now = new Date();

        // Get current month in prior year in user's locale.
        let year = now.getFullYear() - 1;
        let month = now.getMonth();

        // Iterate over UTC months, copying existing data and filling gaps.
        for (let i = 0; i < 13; i++) {
            const time = Date.UTC(year, month, 1, 0, 0, 0);
            for (let j = 0; j < dataLength; j++) {
                if (tempData[j][0] > time) {
                    break;
                }

                if (tempData[j][0] === time) {
                    // Found matching data: use it and slice the array so that these points
                    // are not compared again.
                    if (tempData[j][1] < 0) {
                        newData.push([time, null]);
                    } else {
                        newData.push([time, tempData[j][1]]);
                    }
                    tempData = tempData.slice(j + 1);
                    dataLength = tempData.length;
                    break;
                }
            }

            if (newData.length < i + 1) {
                // Did not find matching data: insert a null.
                newData.push([time, null]);
            }

            // Increment month.
            month = month + 1;
            if (month > 11) {
                month = 0;
                year = year + 1;
            }
        }

        if (newData[12][1] === null && currentArray.isCurrent === true) {
            // If the current month's data is missing (typically on the 1st day of the month),
            // use the current space data for this array (if available).
            newData[12][1] = currentArray[rawKey];
        }

        return newData;
    }

    private buildTooltipHTML(referencePoint: Point): string {
        clearSelectedPoints(this.chart);

        // Using the reference point, find the point on each series
        const points = getPointsByXValue(this.chart, referencePoint.x);

        if (points.length === 0) {
            return '';
        }

        // Select points to "highlight" them in the UI
        selectPoints(points);

        // Build tooltip from points

        // Series rows
        const rowHtmls: string[] = [];
        points.forEach(pt => {
            let valueStr: string;
            if (pt.series.type === 'line') {
                valueStr = `${formatRatio(pt.y)} to 1`;
            } else {
                const size = formatSize(pt.y, 2);
                valueStr = `${size.value} ${size.unit}`;
            }
            rowHtmls.push(`
            <tr>
                <td>
                    <span class="entityColor" style="background: ${(<SeriesLineOptions | SeriesColumnOptions>pt.series.options).color}"></span>
                    <span class="metricName metricNameExtraWide">${pt.series.name}</span>
                    <span class="metricValue metricValueRightAlign">${valueStr}</span>
                </td>
             </tr>`);
        });

        // Wrap the rows up into the containing html
        return buildPerfChartTooltipHtml(
            this.closestMonth(referencePoint.x).format(X_AXIS_DATE_FORMAT),
            '',
            rowHtmls.join(' '),
        );
    }
}
