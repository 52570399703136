import moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { Resource, UnifiedArray } from '@pure1/data';
import { getDurationDays, isFlashArray, isOutOfSupport } from '../../util';

interface ArrayError {
    id: string;
    errorMessage: string;
}

interface TableFilter {
    name: string;
    model: string;
    version: string;
    storage: string;
    status: string;
    eradication: string;
}

type SortableColumns = 'name' | 'model' | 'version';

const OUT_OF_SUPPORT = 'Support expired';

const MAX_SELECTED_ARRAYS = 25;

@Component({
    selector: 'safemode-array-selector',
    templateUrl: 'safemode-array-selector.component.html',
})
export class SafeModeArraySelectorComponent implements OnChanges, OnInit {
    @Input() readonly arrays: UnifiedArray[] = [];
    @Input() readonly loading: boolean;
    @Input() readonly initialSelection: string[];
    @Input() readonly isEnablement: boolean | null;
    @Input() readonly hasSNOWContact: boolean;
    @Input() readonly isSNOWInitialized: boolean;
    @Output() readonly onCancel = new EventEmitter();
    @Output() readonly onForward = new EventEmitter<UnifiedArray[]>();

    sortColumnName: SortableColumns = 'name';
    isSortingAscending = true;

    nameFilter = '';
    modelFilter = '';
    currentVersionFilter = '';
    storageFilter = '';
    statusFilter = '';
    eradicationFilter = '';
    filter$ = new BehaviorSubject<TableFilter>({
        name: '',
        version: '',
        model: '',
        storage: '',
        status: '',
        eradication: '',
    });

    filteredSortedArrays: UnifiedArray[] = [];

    // used for a quick array search by id
    arraysMap: Map<string, UnifiedArray> = new Map<string, UnifiedArray>();

    // selected arrays map tracks which arrays's checkboxes are checked
    // do not manipulate this directly, use selectArray and unselectArray instead
    selectedArraysMap: { [id: string]: boolean } = {};

    // array of selected arrays
    // do not manipulate this directly, use selectArray and unselectArray instead
    selectedArrays: UnifiedArray[] = [];

    // Tracker any loadings going on on certain arrays
    arrayLoadingCounter: { [id: string]: number } = {};

    // disabled arrays map tells you which arrays can't be selected for upgrade
    // each array is mapped to a text containing the reason for disability
    disabledArrays: { [id: string]: string } = {};

    // we might not need this anymore
    arrayErrors: ArrayError[] = [];

    // map of version errors for quick access from template
    arrayErrorsMap: { [id: string]: ArrayError } = {};

    constructor() {}

    ngOnInit(): void {
        this.filter$.pipe(debounceTime(250)).subscribe((value: TableFilter) => {
            this.updateFilteredArrays(value);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        // disable arrays out of support
        if (changes.arrays) {
            this.arraysMap.clear();
            this.arrayLoadingCounter = {};
            this.arrayErrors = [];
            this.arrayErrorsMap = {};
            this.filteredSortedArrays = [...changes.arrays.currentValue];
            this.nameFilter = '';
            this.currentVersionFilter = '';
            this.modelFilter = '';
            this.updateFilteredArrays({
                name: '',
                model: '',
                version: '',
                storage: '',
                status: '',
                eradication: '',
            });
            this.arrays.forEach(arr => {
                this.arraysMap.set(arr.id, arr);
                if (isOutOfSupport(arr)) {
                    this.disabledArrays[arr.id] =
                        OUT_OF_SUPPORT + ' on ' + arr.contract_expiration?.format('YYYY-MM-DD');
                    this.unselectArray(arr.id);
                }
                this.arrayLoadingCounter[arr.id] = 0;
            });

            if (this.initialSelection && this.initialSelection.length > 0) {
                this.selectedArrays = [];
                this.selectedArraysMap = {};
                this.initialSelection.forEach(id => {
                    this.selectArray(id);
                });
            }
        }
    }

    getSortClass(columnName: SortableColumns): string {
        if (!this.canSortByColumn(columnName)) {
            return '';
        }
        if (columnName === this.sortColumnName) {
            const sortClass = 'st-sort-' + (this.isSortingAscending ? 'ascent' : 'descent');
            return `manual-sort ${sortClass}`;
        }

        return 'manual-sort';
    }

    sortByColumn(columnName: SortableColumns): void {
        if (!this.canSortByColumn(columnName)) {
            return;
        }
        if (this.sortColumnName === columnName) {
            this.isSortingAscending = !this.isSortingAscending;
        } else {
            this.sortColumnName = columnName;
            this.isSortingAscending = true;
        }

        this.sortArrays();
    }

    private canSortByColumn(columnName: string): boolean {
        return !['storage', 'status', 'eradicationTimer'].includes(columnName);
    }

    sortArrays(): void {
        const sortDirection = this.isSortingAscending ? 1 : -1;
        this.filteredSortedArrays = this.filteredSortedArrays.sort(
            (a, b) => sortDirection * a[this.sortColumnName].localeCompare(b[this.sortColumnName]),
        );
    }

    updateFilter(): void {
        this.filter$.next({
            name: this.nameFilter,
            model: this.modelFilter,
            version: this.currentVersionFilter,
            storage: this.storageFilter,
            status: this.statusFilter,
            eradication: this.eradicationFilter,
        });
    }

    updateFilteredArrays(filter: TableFilter): void {
        this.filteredSortedArrays = this.arrays
            .filter(arr => !filter.name || arr.name.toLowerCase().indexOf(filter.name.toLocaleLowerCase()) > -1)
            .filter(
                arr =>
                    !filter.version || arr.version.toLocaleLowerCase().indexOf(filter.version.toLocaleLowerCase()) > -1,
            )
            .filter(
                arr => !filter.model || arr.model.toLocaleLowerCase().indexOf(filter.model.toLocaleLowerCase()) > -1,
            )
            .filter(arr => this.filterSafeModeField(filter.storage, arr, ['All'], ['File Systems', 'Object Store']))
            .filter(arr =>
                this.filterSafeModeField(
                    filter.status,
                    arr,
                    [arr.safeMode.global?.status],
                    [arr.safeMode.fileSystems?.status, arr.safeMode.objectStore?.status],
                ),
            )
            .filter(arr =>
                this.filterSafeModeField(
                    filter.eradication,
                    arr,
                    [getDurationDays(arr.safeMode.global?.eradicationTimer)],
                    [
                        getDurationDays(arr.safeMode.fileSystems?.eradicationTimer),
                        getDurationDays(arr.safeMode.objectStore?.eradicationTimer),
                    ],
                ),
            );

        this.sortArrays();
    }

    private filterSafeModeField(
        filterValue: string,
        array: UnifiedArray,
        flashArrayValues: string[],
        flashBladeValues: string[],
    ): boolean {
        if (!filterValue) {
            return true;
        }
        const filterValueLowerCase = filterValue.toLocaleLowerCase();
        if (isFlashArray(array)) {
            return flashArrayValues.some(value => value.toLocaleLowerCase().includes(filterValueLowerCase));
        } else {
            return flashBladeValues.some(value => value.toLocaleLowerCase().includes(filterValueLowerCase));
        }
    }

    // builds output data structure and emits onForward event
    onNext(): void {
        this.onForward.emit(this.selectedArrays);
    }

    isFlashArray(array: UnifiedArray): boolean {
        return isFlashArray(array);
    }

    selectArray(id: string): void {
        if (!this.selectedArrays.find(resource => resource.id === id)) {
            const array = this.arrays.find(arr => arr.id === id);

            if (array) {
                this.selectedArraysMap[id] = true;
                this.selectedArrays = [...this.selectedArrays, array];
            }
        }

        this.removeArrayError(id);
    }

    unselectArray(id: string): void {
        this.selectedArraysMap[id] = false;
        this.selectedArrays = this.selectedArrays.filter(arr => arr.id !== id);

        this.removeArrayError(id);
    }

    updateSelectedArrays(updatedResources: Resource[]): void {
        const itemsToUnselect: Resource[] = [];
        this.selectedArrays.forEach(resource => {
            if (!updatedResources.find(updatedResource => updatedResource.id === resource.id)) {
                itemsToUnselect.push(resource);
            }
        });

        itemsToUnselect.forEach(resource => {
            this.unselectArray(resource.id);
        });
    }

    toggleArraySelection(id: string): void {
        if (this.selectedArraysMap[id]) {
            this.unselectArray(id);
        } else {
            this.selectArray(id);
        }
    }

    removeArrayError(id: string): void {
        const error = this.arrayErrorsMap[id];
        if (error) {
            const { [id]: _, ...restOfErrors } = this.arrayErrorsMap;
            this.arrayErrorsMap = restOfErrors;
            this.arrayErrors = this.arrayErrors.filter(e => e !== error);
        }
    }

    canSchedule(): boolean {
        if (!this.hasSNOWContact) {
            return false;
        }
        return (
            this.selectedArrays && this.selectedArrays.length > 0 && this.selectedArrays.length <= MAX_SELECTED_ARRAYS
        );
    }

    trackByArrayId(index: number, item: ArrayError): string {
        return item.id;
    }

    getDurationDays(duration: moment.Duration): string {
        return getDurationDays(duration);
    }
}
