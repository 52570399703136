import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';

import { WINDOW } from './injection-tokens';

@Injectable()
export class UserChangedDetectorInterceptor implements HttpInterceptor {
    constructor(@Inject(WINDOW) private window: Window) {}

    mostRecentUserEmail: string = null;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(event => {
                if (event.type === HttpEventType.Response) {
                    const userEmail = event.headers.get('X-User-Email');
                    if (userEmail) {
                        if (this.mostRecentUserEmail === null) {
                            // before the email is set, we need to store it on the first request
                            this.mostRecentUserEmail = userEmail;
                        } else if (this.mostRecentUserEmail !== userEmail) {
                            // once the email header changes, we need to reload the page
                            this.window.location.reload();
                        }
                    }
                    // in the case where no user email is set, or it matches the latest one, then
                    // nothing has to change, so the event stream continues for other interceptors
                    // (tap is only used for the side effects)
                }
            }),
        );
    }
}
