import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactSectionComponent } from './contact-section.component';
import { SearchContactComponent } from '../search-contact/search-contact.component';
import { UIModule } from '../../ui/ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, UIModule, NgbModule],
    declarations: [ContactSectionComponent, SearchContactComponent],
    exports: [ContactSectionComponent],
})
export class ContactSectionModule {}
