export class StepUpVerifyResponse {
    accessToken: string;
    expiresIn: number;
    scope?: string;
    authorizationDetails?: any;

    constructor(json: any) {
        this.accessToken = json.access_token;
        this.expiresIn = json.expires_in;
        this.scope = json.scope;
        this.authorizationDetails = json.authorization_details;
    }
}
