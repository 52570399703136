import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProgressBarPercentagePosition, ProgressBarState } from '@pure/hive';

@Component({
    selector: 'capacity-progress-bar',
    templateUrl: 'capacity-progress-bar.component.html',
})
export class CapacityProgressBarComponent implements OnChanges {
    @Input() readonly percent: string | number;
    @Input() readonly notAvailable: boolean;

    @Input() readonly percentagePosition: ProgressBarPercentagePosition = 'lower-left';
    @Input() readonly label: string;

    effectivePercent: number;
    state: ProgressBarState;

    ngOnChanges(changes: SimpleChanges): void {
        const numericalPercent = Number(this.percent);

        if (this.notAvailable) {
            this.state = 'info';
            this.effectivePercent = 100;
        } else {
            if (numericalPercent < 90) {
                this.state = 'success';
            } else if (numericalPercent < 100) {
                this.state = 'warning';
            } else {
                this.state = 'error';
            }
            this.effectivePercent = Math.round(numericalPercent);
        }
    }
}
