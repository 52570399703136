import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Chart, Options, SeriesAreaOptions } from 'highcharts/highstock';
import {
    OnInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { AppService } from '../../../../app/app.service';
import { WINDOW } from '../../../../app/injection-tokens';

const DEFAULT_HEIGHT = 70;

@Component({
    selector: 'recommendation-sparkline',
    templateUrl: 'recommendation-sparkline.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationSparklineComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly data: [number, number][];
    @ViewChild('sparklineElem', { static: true }) readonly sparklineElem: ElementRef<HTMLDivElement>;

    hasData: boolean;
    chart: Chart;

    readonly chartHeight: number = DEFAULT_HEIGHT;
    private componentDestroyed = false;
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private appService: AppService,
        private ngZone: NgZone,
    ) {}

    ngOnInit(): void {
        this.appService.navSideBarToggled.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.chart?.reflow();
        });

        this.ngZone.runOutsideAngular(() => {
            if (!this.componentDestroyed) {
                // Make sure we don't try creating the chart after the controller gets destroyed
                this.chart = new Chart(this.getChartOptions());
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.hasData = this.data && this.data.length > 0;
        }

        // Update the chart if it is already created
        if (this.chart) {
            if (changes.data) {
                this.ngZone.runOutsideAngular(() => {
                    this.chart.series[0].setData(this.data || []);
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.componentDestroyed = true;
        this.chart?.destroy();
        this.chart = null;
    }

    private getChartOptions(): Options {
        const seriesOptions: SeriesAreaOptions = {
            data: this.data,
            color: '#E575B1',
            type: 'area',
        };

        const chartOptions: Options = {
            series: [seriesOptions],
            chart: {
                renderTo: this.sparklineElem.nativeElement,
                backgroundColor: null,
                borderWidth: 0,
                type: 'area',
                margin: [1, 0, 1, 0],
                spacingBottom: 0,
                height: this.chartHeight,
            },
            title: {
                text: '',
            },
            xAxis: {
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                crosshair: false,
                startOnTick: false,
                endOnTick: false,
                tickPositions: [],
            },
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                min: 0,
                minRange: 0.02, // y-axis must be at least 2%
                tickPositions: [0],
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                area: {
                    softThreshold: true,
                    fillOpacity: 0.1,
                },
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false,
                            },
                            select: {
                                enabled: false,
                            },
                        },
                    },
                },
            },
        };

        return chartOptions;
    }
}
