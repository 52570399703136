import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil, tap, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, TemplateRef } from '@angular/core';

import { ToastService, Toast, ToastType, KeepHtml } from '../../../services/toast.service';
import { isString } from 'lodash';
import { smartTimer } from '@pstg/smart-timer';

export const AUTO_DISMISS_TIMEOUT = moment.duration(6, 'seconds').asMilliseconds();
export const CLOSE_ANIMATION_DURATION = 300; // Should match the opacity transition duration of .toast-item in the scss

enum ToastItemState {
    initialized,
    opened,
    close,
}

/**
 * A Toast instance displayed by this component. Wraps around the Toast to add some UI-specific state.
 */
class ToastItem {
    state: ToastItemState = ToastItemState.initialized;

    readonly cssClass: string;
    readonly iconClass: string;

    get id(): number {
        return this.toast.id;
    }

    get text(): string | TemplateRef<any> | KeepHtml {
        return this.toast.text;
    }

    get stateCssClass(): string {
        switch (this.state) {
            case ToastItemState.opened:
                return 'item-show';
            case ToastItemState.close:
                return 'item-hide';
            default:
                return '';
        }
    }

    constructor(public readonly toast: Toast) {
        switch (toast.type) {
            case ToastType.success:
                this.cssClass = 'type-success';
                this.iconClass = 'fa fa-check';
                break;
            case ToastType.info:
                this.cssClass = 'type-info';
                this.iconClass = 'fa fa-info';
                break;
            case ToastType.error:
                this.cssClass = 'type-error';
                this.iconClass = 'fa fa-exclamation';
                break;
            default:
                this.cssClass = '';
                this.iconClass = '';
                console.warn('Unknown toast type', toast.type, toast);
                break;
        }
    }
}

/**
 * Default component to render the toasts. Also auto-dismisses them after time.
 */
@Component({
    selector: 'pure-toast',
    templateUrl: 'pure-toast.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PureToastComponent implements OnInit, OnDestroy {
    items: ToastItem[] = [];

    private readonly destroy$ = new Subject<void>();

    constructor(
        private toastService: ToastService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // TODO: These observables should be rewritten.
        // * Remove the need for timer() by using Angular animations
        // * The logic in the tap()s should just be in the subscribe()
        // * Don't use nested subscribe()s...

        this.toastService.add$
            .pipe(
                tap(toast => {
                    try {
                        const item = new ToastItem(toast);
                        this.items.push(item);
                        this.cdr.markForCheck();

                        smartTimer(5) // To make the animation work correctly, we need to render first with the initialized state before moving to opened
                            .pipe(take(1), takeUntil(this.destroy$))
                            .subscribe(() => {
                                if (item.state === ToastItemState.initialized) {
                                    item.state = ToastItemState.opened;
                                    this.cdr.markForCheck();
                                }
                            });

                        smartTimer(AUTO_DISMISS_TIMEOUT) // Auto-dismiss timer
                            .pipe(take(1), takeUntil(this.destroy$))
                            .subscribe(() => {
                                this.toastService.remove(toast);
                            });
                    } catch (err) {
                        console.error('Failed to add toast item', err);
                    }
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();

        this.toastService.remove$
            .pipe(
                tap(toast => {
                    try {
                        const item = this.items.find(i => i.toast.id === toast.id);
                        if (!item || item.state === ToastItemState.close) {
                            return; // Skip if the item does not exist, or is already closing
                        }

                        // Set to close state, and create a timer to actually remove it from the collection after animation completes
                        item.state = ToastItemState.close;
                        this.cdr.markForCheck();

                        smartTimer(CLOSE_ANIMATION_DURATION)
                            .pipe(take(1), takeUntil(this.destroy$))
                            .subscribe(() => {
                                const itemIndex = this.items.findIndex(i => i.toast.id === toast.id);
                                if (itemIndex >= 0) {
                                    this.items.splice(itemIndex, 1);
                                    this.cdr.markForCheck();
                                }
                            });
                    } catch (err) {
                        console.error('Failed to remove toast item', err);
                    }
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();

        this.toastService.clear$
            .pipe(
                tap(() => {
                    try {
                        if (this.items.length > 0) {
                            this.items = [];
                            this.cdr.markForCheck();
                        }
                    } catch (err) {
                        console.error('Failed to clear toast items', err);
                    }
                }),
                takeUntil(this.destroy$),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    dismissItem(item: ToastItem): void {
        this.toastService.remove(item.toast);
    }

    isTemplateRef(value: string | TemplateRef<any> | KeepHtml): value is TemplateRef<any> {
        return value instanceof TemplateRef;
    }

    isKeepHtml(value: string | TemplateRef<any> | KeepHtml): value is KeepHtml {
        return value instanceof KeepHtml;
    }

    protected readonly isString = isString;
}
