import moment from 'moment';
import { PodPerformanceAggregation } from './pod-performance-aggregation';
import { Pod } from './pod';

export class PodAggregation extends Pod {
    readonly _as_of: moment.Moment;
    readonly stretched_pod: boolean;
    readonly aggregation: PodPerformanceAggregation;

    constructor(json: any) {
        super(json);
        this._as_of = moment(json._as_of);

        if (json.aggregation) {
            this.aggregation = new PodPerformanceAggregation(json.aggregation);
        }
    }
}
