import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'formatLoad' })
export class FormatLoadPipe implements PipeTransform {
    /**
     * Formats a load value for display
     * @param value The original load value, on scale of 0 to 1 (eg: 0.15 = 15).
     * @param clipValue If true, values less than 10 will be shown as "<10" instead.
     */
    transform(value: number | string, clipValue = false): string {
        if (typeof value !== 'number') {
            return value;
        }

        if (Number.isNaN(value)) {
            return null;
        }

        if (clipValue && value < 0.1) {
            return '<10%';
        }

        value = Math.min(value * 100, 100);
        return Math.round(value).toFixed(0) + '%';
    }
}
