import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPlannerActionTooltip' })
export class FormatPlannerActionTooltipPipe implements PipeTransform {
    transform(message: string, prefix: string = '', divider: string = ' | '): string {
        if (!message) {
            message = '';
        }

        if (!prefix || !message) {
            divider = '';
        }

        return prefix + divider + message;
    }
}
