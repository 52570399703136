export function toRoundedFixed(num: number, decimals: number): string {
    if (typeof num !== 'number' || isNaN(num)) {
        return null;
    }
    if (typeof decimals !== 'number' || isNaN(decimals) || decimals <= 0) {
        return String(Math.round(num));
    } else {
        const p = Math.pow(10.0, decimals);
        return (Math.round(num * p) / p).toFixed(decimals);
    }
}
