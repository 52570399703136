import { DraasApiDatastore } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryDatastore {
    assetId: string;
    clusterId: string;
    homeProviderId: string;
    name: string;
    capacity: number;
    available: number;

    constructor(json: DraasApiDatastore) {
        this.assetId = json.asset_id;
        this.clusterId = json.cluster_id;
        this.homeProviderId = json.home_provider_id;
        this.name = json.name;
        this.capacity = json.capacity;
        this.available = json.available;
    }
}
