import moment from 'moment';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import {
    StepUpService,
    StepUpError,
    StepUpVerifyResponse,
    StepUpFactor,
    StepUpChallengeResponse,
    STEP_UP_ERROR_RESPONSES,
    StepUpErrorType,
} from '@pure1/data';

import { Angulartics2 } from 'angulartics2';
import { StepUpSupportService } from '../services/step-up-support.service';

const ANGULARTICS_EVENT_SUFFIX_FOR_ACTIVATION = ' for activation';
const SMS_PASSCODE_EXPIRATION_TIME = moment.duration(5, 'minutes');
const EMAIL_PASSCODE_EXPIRATION_TIME = moment.duration(15, 'minutes');
const AUTH0_EMAIL_PASSCODE_EXPIRATION_TIME = moment.duration(5, 'minutes');
const DEFAULT_TTL_SECONDS = 300;

/**
 * This component is used for both regular step up challenge-verify flow and the enroll-activate flow.
 * In both cases, it is important for whoever opens this component to supply a non-null/non-undefined StepUpFactor.
 * Because we have to supply a StepUpFactor, we must call enroll before opening this component.
 *    (factor exists)
 *         ||                StepUpChallengeComponent                ||
 * Enroll  || ---          |   can retry Enroll        |   Activate  ||
 * ---     || Challenge    |   can retry Challenge     |   Verify    ||
 * ----------------------------------------------------------------------> Timeline
 * Because we want to be able to retry Enroll, we must supply the required auth token (authToResendEnroll).
 */
@Component({
    selector: 'step-up-challenge',
    templateUrl: 'step-up-challenge.component.html',
})
export class StepUpChallengeComponent implements OnInit, OnChanges {
    @Input() readonly factor: StepUpFactor;
    @Input() readonly audience: string;
    @Input() readonly authorizationDetails = [];
    @Input() readonly singleUse: boolean;
    // TTL for step-up tokens. Different from the countdown time.
    // Cannot rely on default input, because we assign the input variables manually (potentially undefined/null) in step-up-modal.service.ts, which overrides defaults.
    @Input() readonly ttl: number;
    // Is this verify-challenge for activation of a factor in PENDING_ACTIVATION status?
    @Input() readonly isActivate: boolean;
    // Required if isActivate is true, for resending activation code in case user didn't successfully receive one.
    @Input() readonly authToResendEnroll: string;
    // If present, shows a red text in the modal subtitle.
    @Input() readonly additionalWarning: string;

    @Output() readonly verifySuccess = new EventEmitter<StepUpVerifyResponse>();
    @Output() readonly verifyFailure = new EventEmitter<StepUpError>();
    @Output() readonly verifyCancel = new EventEmitter<void>();

    readonly MAX_ATTEMPTS = 5;
    readonly MAX_RETRIES = 3;

    countdownDuration: moment.Duration;
    countdownActive = false;
    lockedOut = false;
    passCodeForm: UntypedFormGroup;
    attemptsRemaining = this.MAX_ATTEMPTS;
    retriesRemaining = this.MAX_RETRIES;
    errorMessage: string = null;
    obfuscatedPhoneNumber: string = null;
    challengeResponse: StepUpChallengeResponse = null;
    verificationInProgress = false;
    passCode: string;
    passCodeIconStr = 'eye-visibility-off.svg';
    passCodeTypeStr = 'password';
    passCodeField: HTMLInputElement;

    constructor(
        @Inject(DOCUMENT) private document: HTMLDocument,
        private angulartics: Angulartics2,
        private stepUpService: StepUpService,
        private stepUpSupport: StepUpSupportService,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        const formConfig = {
            phoneNumber: [{ value: this.obfuscatedPhoneNumber, disabled: true }],
            passCode: [this.passCode, Validators.compose([Validators.required, Validators.pattern(/^[\d]{6}$/)])],
        };
        this.passCodeForm = this.fb.group(formConfig);
        this.passCodeField = this.document.getElementById('passcode-field') as HTMLInputElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.factor && this.factor) {
            if (this.passCodeField) {
                this.passCodeField.value = '';
            }
            if (this.factor.profile.phoneNumber) {
                this.obfuscatedPhoneNumber = this.obfuscateNumber(this.factor.profile.phoneNumber);
            }
            if (this.isActivate) {
                this.resetCountDown();
            } else {
                this.challenge();
            }
        }
    }

    resendEnrollForActivation(): void {
        this.angulartics.eventTrack.next({
            action: 'Step Up - Challenge Modal - activation passcode issued',
            properties: { category: 'Action', label: this.factor.factorType },
        });
        this.stepUpService
            .stepUpEnrollFactorV2(this.factor.factorType, this.factor.profile, this.authToResendEnroll)
            .subscribe({
                next: _ => {
                    this.resetCountDown();
                },
                error: (error: StepUpError) => {
                    console.warn(error);
                    this.errorMessage = error.errorDescription;
                },
            });
    }

    challenge(): void {
        // issue the challenge
        this.angulartics.eventTrack.next({
            action: 'Step Up - Challenge Modal - passcode challenge issued',
            properties: { category: 'Action', label: this.factor.factorType },
        });

        this.stepUpService
            .stepUpChallenge(
                this.factor.id,
                this.audience,
                this.authorizationDetails,
                this.singleUse,
                this.ttl != null ? this.ttl : DEFAULT_TTL_SECONDS,
            )
            .subscribe(
                response => {
                    this.resetCountDown();
                    this.challengeResponse = response;
                },
                (error: StepUpError) => {
                    console.warn(`error issuing challenge: ${error.errorDescription}`);
                    this.errorMessage = error.errorDescription;
                },
            );
    }

    submitEnabled(): boolean {
        return (
            this.passCodeForm.valid &&
            !this.verificationInProgress &&
            (this.challengeResponse != null || this.isActivate)
        );
    }

    submit(): void {
        // verify passcode
        const passCode = this.passCodeForm.controls.passCode.value;
        this.verificationInProgress = true;
        if (this.isActivate) {
            this.stepUpService.stepUpActivateV2(passCode, this.factor.id).subscribe({
                next: _ => {
                    this.angulartics.eventTrack.next({
                        action:
                            'Step Up - Challenge Modal - passcode verified successfully' +
                            ANGULARTICS_EVENT_SUFFIX_FOR_ACTIVATION,
                        properties: { category: 'Action' },
                    });
                    this.verifySuccess.emit(new StepUpVerifyResponse({})); // Returning an empty response to indicate success;
                },
                error: (error: StepUpError) => this.handleStepUpError(error),
            });
        } else {
            this.stepUpService.stepUpVerify(passCode, this.challengeResponse.stateToken, this.factor.id).subscribe({
                next: (response: StepUpVerifyResponse) => {
                    this.angulartics.eventTrack.next({
                        action: 'Step Up - Challenge Modal - passcode verified successfully',
                        properties: { category: 'Action' },
                    });
                    this.verificationInProgress = false;
                    this.verifySuccess.emit(response);
                },
                error: (error: StepUpError) => this.handleStepUpError(error),
            });
        }
    }

    showAttemptsMessage(): boolean {
        return this.errorMessage && !this.lockedOut && this.attemptsRemaining < this.MAX_ATTEMPTS;
    }

    goToSupport(): void {
        this.stepUpSupport.openStepUpUnlockRequestCase();
        this.verifyFailure.emit(STEP_UP_ERROR_RESPONSES.locked_out);
    }

    close(): void {
        this.verifyCancel.emit();
    }

    obfuscateNumber(phoneNumber: string): string {
        return `${phoneNumber.substring(0, phoneNumber.length - 4).replace(/[\d]/g, '*')}${phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length)}`;
    }

    togglePasswordVisibility(): void {
        this.passCodeTypeStr = this.passCodeTypeStr === 'password' ? 'text' : 'password';
        this.passCodeIconStr =
            this.passCodeIconStr === 'eye-visibility-off.svg' ? 'eye-visibility-on.svg' : 'eye-visibility-off.svg';
    }

    passCodeExpired(): void {
        this.retriesRemaining--;
        this.countdownActive = false;
        if (this.retriesRemaining > 0) {
            this.angulartics.eventTrack.next({
                action:
                    'Step Up - Challenge Modal - timer elapsed retry' + this.isActivate
                        ? ANGULARTICS_EVENT_SUFFIX_FOR_ACTIVATION
                        : '',
                properties: { category: 'Action' },
            });
            this.errorMessage = 'Expired verification code, another code will be sent.';
            if (this.isActivate) {
                this.resendEnrollForActivation();
            } else {
                this.challenge();
            }
        } else {
            this.angulartics.eventTrack.next({
                action:
                    'Step Up - Challenge Modal - timer elapsed, retry limit reached' + this.isActivate
                        ? ANGULARTICS_EVENT_SUFFIX_FOR_ACTIVATION
                        : '',
                properties: { category: 'Action' },
            });
            this.errorMessage = 'Expired verification code, please try again.';
        }
    }

    isEmailFactor(): boolean {
        return this.factor?.factorType === 'email';
    }

    isAuth0EmailFactor(): boolean {
        return this.factor?.id.startsWith('email|');
    }

    private resetCountDown(): void {
        if (this.isEmailFactor()) {
            if (this.isAuth0EmailFactor()) {
                this.countdownDuration = AUTH0_EMAIL_PASSCODE_EXPIRATION_TIME;
            } else {
                this.countdownDuration = EMAIL_PASSCODE_EXPIRATION_TIME;
            }
        } else {
            this.countdownDuration = SMS_PASSCODE_EXPIRATION_TIME;
        }
        this.countdownActive = true;
    }

    private handleStepUpError(error: StepUpError) {
        this.verificationInProgress = false;
        if (error.error === 'locked_out') {
            this.attemptsRemaining = 0;
        } else if (error.error === 'bad_passcode') {
            this.attemptsRemaining--;
            this.errorMessage = error.errorDescription;
        } else if (error.error === 'challenge_expired') {
            this.passCodeExpired();
        } else {
            this.errorMessage = error.errorDescription;
        }

        if (this.attemptsRemaining === 0) {
            this.errorMessage = 'Too many failed verification attempts.';
            this.lockedOut = true;

            this.angulartics.eventTrack.next({
                action:
                    'Step Up - Challenge Modal - passcode lockout' + this.isActivate
                        ? ANGULARTICS_EVENT_SUFFIX_FOR_ACTIVATION
                        : '',
                properties: { category: 'Action' },
            });
        }
        this.trackPassCodeError(error.error);
    }

    private trackPassCodeError(stepUpError: StepUpErrorType): void {
        this.angulartics.eventTrack.next({
            action:
                'Step Up - Challenge Modal - challenge error' + this.isActivate
                    ? ANGULARTICS_EVENT_SUFFIX_FOR_ACTIVATION
                    : '',
            properties: { category: 'Action', label: stepUpError },
        });
    }
}
