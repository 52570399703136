<ng-container *ngIf="columnOptionGroups">
    <div class="column" *ngFor="let columnGroup of columnOptionGroups | keyvalue: sortOrder">
        <div class="column-heading">
            {{ columnGroup.key | uppercase }}
        </div>
        <div class="column-options">
            <div
                class="select-all column-option"
                *ngIf="selectAllStatuses.get(columnGroup.key) as status"
                (click)="onSelectAllClick(columnGroup)"
            >
                <input
                    type="checkbox"
                    [checked]="status === 'checked' || status === 'indeterminate'"
                    [indeterminate]="status === 'indeterminate'"
                    #selectAllCheckbox
                />
                Select All
            </div>
            <div
                class="column-option"
                *ngFor="let option of columnGroup.value"
                [ngClass]="{ disabled: option.readonly }"
                (click)="onOptionClick(option, columnGroup.key)"
            >
                <input type="checkbox" [checked]="option.selected" [disabled]="option.readonly" />
                {{ option.name }}
                <ng-container *ngIf="option.column?.subColumns">
                    <div *ngFor="let subColumn of option.column.subColumns" class="sub-columns-option">
                        {{ subColumn.name }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
