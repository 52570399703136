import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UnifiedArrayService } from '@pure1/data';

@Injectable({ providedIn: 'root' })
export class ArrayNameTooltipService {
    private readonly idToTooltipName: Promise<Map<string, string>>;
    private readonly idToFqdn = new Map<string, string>();

    constructor(private unifiedArrayService: UnifiedArrayService) {
        this.idToTooltipName = new Promise((resolve, reject) => {
            this.unifiedArrayService
                .list()
                .pipe(take(1))
                .subscribe(
                    arraysResponse => {
                        arraysResponse.response.forEach(array => {
                            if (array.cloud_array_id) {
                                this.idToFqdn.set(array.cloud_array_id, array.fqdn || array.name);
                            }
                            if (array.appliance_id) {
                                this.idToFqdn.set(array.appliance_id, array.fqdn || array.name);
                            }
                        });
                        resolve(this.idToFqdn);
                    },
                    err => reject(err),
                );
        });
    }

    get(id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.idToTooltipName.then(map => resolve(map.get(id))).catch(err => reject(err));
        });
    }

    // We cannot use 'get()' directly in a template because it returns a promise, so this will use the
    // idToFqdn map to return the correct string (or a default) when needed
    getFQDNFromId(id: string, defaultName: string): string {
        const fqdn = this.idToFqdn.get(id);
        if (!this.idToFqdn.has(id)) {
            this.get(id)
                .then(name => {
                    this.idToFqdn.set(id, name || defaultName || '');
                })
                .catch(err => {
                    this.idToFqdn.set(id, defaultName || '');
                });
        }
        // fqdn should only be undefined when we get our first return before the promise has resolved.
        return fqdn || defaultName;
    }
}
