import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PureAuditEvent } from '../models/PureAuditEvent';

@Injectable({ providedIn: 'root' })
export class AuditEventService extends GenericService<PureAuditEvent> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PureAuditEvent,
            endpoint: '/rest/v3/audits',
        });
    }
}
