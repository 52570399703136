<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<ng-template #drrLinksTemplate>
    <a
        href="https://support.purestorage.com/Pure1/Pure1_Manage/003_Dashboards_and_Appliances/01_Pure1_Manage_-_Assessment"
        target="_blank"
        class="bold-link"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - DRR - KB article"
    >
        Learn More
    </a>
    |
    <a
        href="https://youtu.be/Qsja2axydGY"
        target="_blank"
        class="bold-link"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - DRR - Watch a video"
    >
        Watch a video
    </a>
</ng-template>

<ng-template #drrButtonTemplate>
    <a
        *ngIf="notification.path"
        [routerLink]="notification.path"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - DRR - Get Started"
        class="btn btn-primary try-button"
    >
        Try it Out
    </a>
</ng-template>
