import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

@Pipe({
    name: 'messagesDisplayDatetime',
})
export class MessagesDisplayDatetimePipe implements PipeTransform {
    transform(timestamp: number): string {
        if (timestamp === null || timestamp === undefined || timestamp <= 0) {
            return '–';
        } else {
            return moment.unix(timestamp).format(DATETIME_FORMAT);
        }
    }
}
