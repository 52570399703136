import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2, Optional } from '@angular/core';
import { NgModel, FormControlDirective } from '@angular/forms';

const PADDING = 20;
const MIN_WIDTH = 45;

@Directive({
    selector: 'input[inputautoresize]',
})
export class InputAutoresizeDirective implements AfterViewInit, OnDestroy {
    private deregister: () => void;
    private input: HTMLInputElement;
    private span: HTMLElement;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private el: ElementRef,
        @Optional() private ngModel: NgModel,
        @Optional() private formControl: FormControlDirective,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        this.input = this.el.nativeElement;
        this.span = this.renderer.createElement('span');
        this.renderer.setStyle(this.span, 'display', 'none');
        this.renderer.setStyle(this.span, 'visibility', 'hidden');
        this.renderer.setStyle(this.span, 'width', 'auto');
        this.renderer.setStyle(this.span, 'white-space', 'pre');
        this.renderer.setStyle(this.span, 'padding', '0');
        this.renderer.setStyle(this.span, 'border', 'none');
        this.renderer.setStyle(this.span, 'box-sizing', 'border-box');

        const inputParent: HTMLInputElement = this.renderer.parentNode(this.input);
        this.renderer.appendChild(inputParent, this.span);

        if (this.ngModel || this.formControl) {
            (this.ngModel || this.formControl).valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.resizeInput());
        } else {
            this.deregister = this.renderer.listen(this.input, 'input', () => this.resizeInput());
        }
        this.resizeInput();
    }

    ngOnDestroy(): void {
        if (this.deregister) {
            this.deregister();
        }
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    resizeInput(): void {
        this.renderer.setStyle(this.span, 'display', '');
        this.span.innerText = this.input.value;
        const width = this.span.getBoundingClientRect().width;
        this.renderer.setStyle(this.span, 'display', 'none');
        this.renderer.setStyle(this.el.nativeElement, 'width', `${Math.max(width + PADDING, MIN_WIDTH)}px`);
    }
}
