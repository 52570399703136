import { htmlEntities } from './string.helpers';
import { cloneDeep, get, set } from 'lodash';

export interface IMarkOptions {
    classAttr?: string;
    encodeHtmlEntities?: boolean;
    filter?: boolean;
}

/**
 * <mark> the needle in the haystack
 * options:
 *     - classAttr: string the classes to add the <mark> element
 *     - encodeHtmlEntities: boolean
 *     - filter: boolean. Return an empty haystack if the needle is not found
 */
export function markText(haystack: string, needle: string, options?: IMarkOptions): string {
    if (typeof haystack !== 'string' || typeof needle !== 'string') {
        return haystack;
    }

    const defaultOptions = {
        classAttr: 'needle',
        filter: false,
        encodeHtmlEntities: false,
    };
    options = { ...defaultOptions, ...options };

    if (options.encodeHtmlEntities) {
        haystack = htmlEntities(haystack);
        needle = htmlEntities(needle);
    }

    if (needle.length === 0) {
        return options.filter ? '' : haystack;
    }

    const escapedRegex = needle.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const markedHaystack = haystack.replace(
        new RegExp(escapedRegex, 'gi'),
        `<mark class="${options.classAttr}">$&</mark>`,
    );
    if (options.filter === true && haystack === markedHaystack) {
        return '';
    }
    return markedHaystack;
}

/**
 * Returns a deep copy of object with <mark>'d fields
 * @param fields object {field: <bool> filter} where
 *   field: property of the object to look for needle
 *   filter: the filter parameter passed to markString
 * @param needle: string
 * @param options: options object passed to markString
 */
export function markObject<T>(
    object: T,
    fields: { [key: string]: boolean },
    needle: string,
    options?: IMarkOptions,
): T {
    let result = cloneDeep(object);
    for (const field in fields) {
        const value = get(result, field);
        if (typeof value === 'string') {
            const opts = { filter: fields[field], ...options };
            result = set(result as any, field, markText(value, needle, opts));
        }
    }
    return result;
}

export function markArray<T>(
    array: T[],
    fields: { [key: string]: boolean },
    needle: string,
    options?: IMarkOptions,
): T[] {
    return array.map((object: T): T => markObject(object, fields, needle, options));
}
