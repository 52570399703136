import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, OnChanges } from '@angular/core';
import intlTelInput from 'intl-tel-input';

const UTILS = '/js/utils.js';
const DIRTY_EVENTS = ['change', 'keydown', 'keyup', 'cut', 'paste', 'countrychange'];
const PRISTINE_EVENTS = ['focus', 'blur'];
const EVENTS = [...DIRTY_EVENTS, ...PRISTINE_EVENTS];

type CssClassName =
    | 'ng-valid'
    | 'ng-invalid'
    | 'ng-pristine'
    | 'ng-dirty'
    | 'ng-touched'
    | 'ng-untouched'
    | 'ng-empty'
    | 'ng-not-empty'
    | 'initializing';
type ClassMap = { [className in CssClassName]: boolean };

export class PhoneResult {
    phoneNumber: string;
    isValid: boolean;
    isEmpty: boolean;
}

@Component({
    selector: 'input-phone',
    templateUrl: 'input-phone.component.html',
})
export class InputPhoneComponent implements OnChanges, OnDestroy {
    @Input() readonly prefill: string;
    @Input() readonly prefillCountry: string;
    @Input() readonly disabled = false;

    @Output() readonly onPhoneChange: EventEmitter<PhoneResult> = new EventEmitter();
    @ViewChild('telInput', { static: true }) readonly telInput: ElementRef;

    classes: ClassMap = {
        'ng-valid': true,
        'ng-invalid': true,
        'ng-pristine': true,
        'ng-dirty': false,
        'ng-touched': false,
        'ng-untouched': true,
        'ng-empty': true,
        'ng-not-empty': false,
        initializing: true,
    };

    private isValid = false;
    private phoneInput: intlTelInput.Plugin;
    private phoneNumber = '';
    private abortController = new AbortController();

    ngOnChanges(): void {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.phoneInput = intlTelInput(this.telInput.nativeElement, {
            nationalMode: true,
            preferredCountries: ['us'],
            separateDialCode: true,
            utilsScript: UTILS,
        });
        this.phoneInput.promise.then(() => {
            setImmediate(() => {
                if (this.prefill) {
                    this.phoneInput.setNumber(this.prefill);
                    this.classes['ng-empty'] = false;
                    // only country code could be provided, in which case we don't want to mark field as invalid.
                    this.classes['ng-not-empty'] = !!this.phoneInput.getNumber();
                } else if (this.prefillCountry) {
                    this.phoneInput.setNumber(this.prefillCountry);
                }

                this.setValidity(this.phoneInput.isValidNumber());
                this.classes['initializing'] = false;
                this.onPhoneChange.emit({
                    phoneNumber: this.phoneInput.getNumber(),
                    isValid: this.isValid,
                    isEmpty: this.phoneInput.getNumber() === '',
                });
            });
        });

        EVENTS.forEach(eventName => {
            this.telInput.nativeElement.addEventListener(
                eventName,
                () => {
                    const newPhoneNumber = this.phoneInput.getNumber();
                    if (newPhoneNumber !== this.phoneNumber) {
                        this.phoneNumber = newPhoneNumber;
                        this.setValidity(this.phoneInput.isValidNumber());
                        this.classes['ng-empty'] = this.phoneInput.getNumber() === '';
                        this.classes['ng-not-empty'] = this.phoneInput.getNumber() !== '';
                        this.onPhoneChange.emit({
                            phoneNumber: newPhoneNumber,
                            isValid: this.isValid,
                            isEmpty: this.phoneInput.getNumber() === '',
                        });
                    }
                },
                {
                    signal: this.abortController.signal,
                },
            );
        });

        this.telInput.nativeElement.addEventListener(
            'blur',
            () => {
                this.classes['ng-touched'] = true;
                this.classes['ng-untouched'] = false;
            },
            {
                once: true,
                signal: this.abortController.signal,
            },
        );

        const dirtyListener = () => {
            this.classes['ng-dirty'] = true;
            this.classes['ng-pristine'] = false;
            DIRTY_EVENTS.forEach(cancelledEventName => {
                this.telInput.nativeElement.removeEventListener(cancelledEventName, dirtyListener);
            });
        };

        DIRTY_EVENTS.forEach(eventName => {
            this.telInput.nativeElement.addEventListener(eventName, dirtyListener, {
                signal: this.abortController.signal,
            });
        });
    }

    ngOnDestroy(): void {
        this.phoneInput.destroy();
        this.abortController.abort();
    }

    private setValidity(isValid: boolean): void {
        this.isValid = isValid;
        this.classes['ng-valid'] = isValid;
        this.classes['ng-invalid'] = !isValid;
    }
}
