import { Resource } from '../interfaces/resource';

export enum StatusType {
    edgeServiceNotEnabled = 'edgeServiceNotEnabled',
    healthy = 'healthy',
    unhealthy = 'unhealthy',
    purityUpgradeRequired = 'purityUpgradeRequired',
    offline = 'offline',
    na = 'na',
    eligible = 'eligible',
}

export enum StatusTypeText {
    edgeServiceNotEnabled = 'Edge service not enabled',
    healthy = 'Healthy',
    unhealthy = 'Unhealthy',
    purityUpgradeRequired = 'Purity upgrade required',
    na = 'N/A',
    offline = 'Offline',
    eligible = 'Eligible to install',
}

export class EdgeServiceAgent implements Resource {
    id: string;
    name: string;
    displayName: string;
    description?: string;
    deprecated = false;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.displayName = json.display_name;
        this.description = json.description;
    }
}

export class EdgeServiceAgentArrayAggregationSummary implements Resource {
    // ID and name of agent for summary
    id: string;
    name: string;
    // counts of arrays for agent
    healthyArraysCount: number;
    unhealthyArraysCount: number;
    naArraysCount: number;
    eligibleArraysCount: number;
    totalArraysCount: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.healthyArraysCount = json.healthy_arrays_count;
        this.unhealthyArraysCount = json.unhealthy_arrays_count;
        this.naArraysCount = json.na_arrays_count;
        this.eligibleArraysCount = json.eligible_arrays_count;
        this.totalArraysCount = json.total_arrays_count;
    }
}
