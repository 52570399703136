import { Pipe, PipeTransform } from '@angular/core';
import { LicenseType } from '@pure1/data';
import { FormatTiBPipe } from './format-tib.pipe';
import { FormatClusterUsagePipe } from './format-cluster-usage.pipe';
import { FormatSizePipe } from './format-size.pipe';

@Pipe({
    name: 'formatSubscriptionData',
})
export class FormatSubscriptionDataPipe implements PipeTransform {
    static readonly formatTiBPipe = new FormatTiBPipe();
    static readonly formatClusterUsagePipe = new FormatClusterUsagePipe();
    static readonly formatSizePipe = new FormatSizePipe();

    transform(data: number, type: LicenseType, decimal = 0, useFormatTiBPipe = true, fixUnit?: string): string {
        switch (type) {
            case LicenseType.VMA:
                return FormatSubscriptionDataPipe.formatClusterUsagePipe.transform(data);
            default:
                return useFormatTiBPipe
                    ? FormatSubscriptionDataPipe.formatTiBPipe.transform(data, decimal)
                    : FormatSubscriptionDataPipe.formatSizePipe.transform(data, decimal, false, fixUnit);
        }
    }
}
