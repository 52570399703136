import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { VCenter } from '../models/vcenter';

@Injectable({ providedIn: 'root' })
export class VCenterService extends GenericService<VCenter> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: VCenter,
            endpoint: '/rest/v1/vmanalytics/vcenters',
        });
    }
}
