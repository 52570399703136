<div class="modal-header">
    <button (click)="activeModal.dismiss()" class="close modal-x-button pull-right" type="button">
        <pureui-svg [height]="12" [svg]="'x-action.svg'" class="pstg-action-icon"></pureui-svg>
    </button>

    <h3 class="modal-title">
        <pure-alert-indicator
            [fixedSeverity]="message.current_severity || message.currentSeverity"
            [iconHeight]="16"
            [iconWidth]="16"
            class="severity-icon"
        >
        </pure-alert-indicator>
        {{ message.current_severity || message.currentSeverity | titlecase }}
    </h3>
</div>

<div class="modal-body">
    <div class="container-fluid">
        <div [ngSwitch]="message.origin" class="row">
            <ng-container *ngSwitchCase="alertOrigin.DRAAS">
                <div class="col-xs-2">Cluster</div>
                <div class="col-xs-10">
                    {{ message.array_id | defaultString }}
                </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="col-xs-2">Source</div>
                <div class="col-xs-10">
                    <ng-container
                        *ngIf="
                            isDataProtectionAlert;
                            then dataProtectionAlertSourceTemplate;
                            else notDataProtectionAlertSourceTemplate
                        "
                    />
                </div>
            </ng-container>
        </div>
        <div class="row">
            <div class="col-xs-2">Summary</div>
            <div class="col-xs-10">
                <a [href]="knowledgeBaseLink" hive-link target="_blank">
                    {{ message.summary }}
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-2">Description</div>
            <div class="col-xs-10 wrap-text">
                <span *ngIf="!isDataProtectionAlert; else dpaDescription" class="white-space-pre-wrap">{{
                    message.description | defaultString
                }}</span>
                <ng-template #dpaDescription>
                    <div class="updated-fields-wrapper">
                        <ng-container *ngIf="dataProtectionAlert.applianceType === 'FA'; else flashBladeSettingsChange">
                            <!-- FA SafeMode change -->
                            <ng-container
                                *ngTemplateOutlet="
                                    dpaSafeModeChange;
                                    context: {
                                        label: 'SafeMode Status',
                                        oldValue: dataProtectionAlert.faSafeModeEnabledOld,
                                        newValue: dataProtectionAlert.faSafeModeEnabledNew
                                    }
                                "
                            ></ng-container>
                            <!-- FA Eradication Delay change -->
                            <ng-container
                                *ngTemplateOutlet="
                                    dpaEradicationDelayChange;
                                    context: {
                                        label: 'Eradication Delay',
                                        oldValue: dataProtectionAlert.faEradicationTimerOld,
                                        newValue: dataProtectionAlert.faEradicationTimerNew
                                    }
                                "
                            ></ng-container>
                        </ng-container>
                        <ng-template #flashBladeSettingsChange>
                            <!-- FB File SafeMode change -->
                            <ng-container
                                *ngTemplateOutlet="
                                    dpaSafeModeChange;
                                    context: {
                                        label: 'File System SafeMode Status',
                                        oldValue: dataProtectionAlert.fileSafeModeEnabledOld,
                                        newValue: dataProtectionAlert.fileSafeModeEnabledNew
                                    }
                                "
                            ></ng-container>
                            <!-- FB File Eradication delay change -->
                            <ng-container
                                *ngTemplateOutlet="
                                    dpaSafeModeChange;
                                    context: {
                                        label: 'File System Eradication Delay',
                                        oldValue: dataProtectionAlert.fileEradicationTimerInDaysOld,
                                        newValue: dataProtectionAlert.fileEradicationTimerInDaysNew
                                    }
                                "
                            ></ng-container>
                            <!-- FB Object SafeMode change -->
                            <ng-container
                                *ngTemplateOutlet="
                                    dpaSafeModeChange;
                                    context: {
                                        label: 'Object Store SafeMode Status',
                                        oldValue: dataProtectionAlert.objectSafeModeEnabledOld,
                                        newValue: dataProtectionAlert.objectSafeModeEnabledNew
                                    }
                                "
                            ></ng-container>
                            <!-- FB Object Eradication delay change -->
                            <ng-container
                                *ngTemplateOutlet="
                                    dpaSafeModeChange;
                                    context: {
                                        label: 'Object Store Eradication Delay',
                                        oldValue: dataProtectionAlert.objectEradicationTimerInDaysOld,
                                        newValue: dataProtectionAlert.objectEradicationTimerInDaysNew
                                    }
                                "
                            ></ng-container>
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="isDataProtectionAlert && dataProtectionAlert.storageTypeAssessmentExists" class="row">
            <div class="col-xs-2">Assessment (Storage&nbsp;Type)</div>
            <div class="col-xs-10">
                <div class="updated-fields-wrapper">
                    <ng-container *ngIf="dataProtectionAlert.applianceType === 'FA'; else flashBladeAssessmentChange">
                        <ng-container
                            *ngTemplateOutlet="
                                dpaAssessmentChange;
                                context: {
                                    label: 'Block',
                                    oldValue: dataProtectionAlert.blockAssessmentLevelOld,
                                    newValue: dataProtectionAlert.blockAssessmentLevelNew
                                }
                            "
                        ></ng-container>
                        <ng-container
                            *ngTemplateOutlet="
                                dpaAssessmentChange;
                                context: {
                                    label: 'File System',
                                    oldValue: dataProtectionAlert.fileAssessmentLevelOld,
                                    newValue: dataProtectionAlert.fileAssessmentLevelNew
                                }
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-template #flashBladeAssessmentChange>
                        <ng-container
                            *ngTemplateOutlet="
                                dpaAssessmentChange;
                                context: {
                                    label: 'File System',
                                    oldValue: dataProtectionAlert.fileAssessmentLevelOld,
                                    newValue: dataProtectionAlert.fileAssessmentLevelNew
                                }
                            "
                        ></ng-container>
                        <ng-container
                            *ngTemplateOutlet="
                                dpaAssessmentChange;
                                context: {
                                    label: 'Object Store',
                                    oldValue: dataProtectionAlert.objectAssessmentLevelOld,
                                    newValue: dataProtectionAlert.objectAssessmentLevelNew
                                }
                            "
                        ></ng-container>
                    </ng-template>
                </div>
            </div>
        </div>
        <div *ngIf="isDataProtectionAlert" class="row">
            <div class="col-xs-2">Overall Assessment</div>
            <div class="col-xs-10">
                <div class="updated-fields-wrapper">
                    <ng-container
                        *ngTemplateOutlet="
                            dpaAssessmentChange;
                            context: {
                                label: 'Appliance',
                                oldValue: dataProtectionAlert.overallAssessmentLevelOld,
                                newValue: dataProtectionAlert.overallAssessmentLevelNew
                            }
                        "
                    ></ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-2">ID</div>
            <div class="col-xs-10">{{ message.id }}</div>
        </div>
        <div class="row">
            <div class="col-xs-2">Code</div>
            <div class="col-xs-10">{{ message.code }}</div>
        </div>
        <div *ngIf="message.opened" class="row">
            <div class="col-xs-2">Created</div>
            <div class="col-xs-10">{{ message.openedString || ($any(message).opened | messagesDisplayDatetime) }}</div>
        </div>
        <div class="row">
            <div class="col-xs-2">Updated</div>
            <div class="col-xs-10">{{ message.updatedString || (message.updated | messagesDisplayDatetime) }}</div>
        </div>
        <div class="row">
            <div class="col-xs-2">Case</div>
            <div class="col-xs-10">
                <a
                    hive-link
                    (click)="activeModal.close()"
                    *ngIf="message.servicenow_case_id; else noCase"
                    [supportCasesRedirect]="message.servicenow_case_id"
                >
                    {{ message.servicenow_case_number | defaultString }}
                </a>
                <ng-template #noCase>
                    {{ message.servicenow_case_number | defaultString }}
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-2">Closed</div>
            <div class="col-xs-10">
                <ng-container *ngIf="!isDataProtectionAlert || message.state === 'closed'; else dataProtectionClosing">
                    {{ message.closedString || (message.closed | messagesDisplayDatetime) }}
                </ng-container>
                <ng-template #dataProtectionClosing>
                    <span class="dpa-closing-label">
                        The alert will be closed automatically 7 days from creation date.
                    </span>
                </ng-template>
            </div>
        </div>
        <div *ngIf="message.expected || message.actual" class="row">
            <div class="col-xs-2">Expected</div>
            <div class="col-xs-10">{{ message.expected | defaultString }}</div>
        </div>
        <div *ngIf="message.expected || message.actual" class="row">
            <div class="col-xs-2">Actual</div>
            <div class="col-xs-10">{{ message.actual | defaultString }}</div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <hive-button
        (click)="closeDpaAlert()"
        *ngIf="
            isDataProtectionAlert &&
            message.state !== 'closed' &&
            ('PURE1:write:assessment_alert_close'
                | isAllowed
                    : (dataProtectionAlert.applianceType === 'FA' ? 'FLASH_ARRAY_ARRAY_ID' : 'FLASH_BLADE_APPLIANCE_ID')
                    : dataProtectionAlert.arrayId)
        "
        [isLoading]="closeInProgress"
        label="Close Alert"
        emphasis="tertiary"
        class="close-dpa-alerts"
    ></hive-button>
    <hive-button (click)="activeModal.dismiss()" label="OK"></hive-button>
</div>

<ng-template #dpaSafeModeChange let-label="label" let-newValue="newValue" let-oldValue="oldValue">
    <div *ngIf="oldValue !== newValue" class="updated-field">
        <div class="updated-field-title">
            {{ label }}
        </div>
        <div class="updated-field-body">
            {{ dataProtectionAlert.getSafeModeText(oldValue) }} &rarr;
            <span [ngClass]="dataProtectionAlert.getCssClass(oldValue, newValue)" class="updated-field-value">
                {{ dataProtectionAlert.getSafeModeText(newValue) }}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #dpaEradicationDelayChange let-label="label" let-newValue="newValue" let-oldValue="oldValue">
    <div *ngIf="oldValue !== newValue" class="updated-field">
        <div class="updated-field-title">
            {{ label }}
        </div>
        <div class="updated-field-body">
            <ng-container [ngPlural]="oldValue">
                <ng-template ngPluralCase="=1">1 Day</ng-template>
                <ng-template ngPluralCase="other">{{ oldValue }} Days</ng-template>
            </ng-container>
            &rarr;
            <span
                [ngClass]="
                    dataProtectionAlert.getCssClass(
                        dataProtectionAlert.faEradicationTimerOld,
                        dataProtectionAlert.faEradicationTimerNew
                    )
                "
                class="updated-field-value"
            >
                <ng-container [ngPlural]="newValue">
                    <ng-template ngPluralCase="=1">1 Day</ng-template>
                    <ng-template ngPluralCase="other">{{ newValue }} Days</ng-template>
                </ng-container>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #dpaAssessmentChange let-label="label" let-newValue="newValue" let-oldValue="oldValue">
    <ng-container *ngIf="newValue !== null">
        <div class="updated-field">
            <div class="updated-field-title">
                {{ label }}
            </div>
            <div class="updated-field-body">
                <ng-container>
                    <ng-container *ngTemplateOutlet="assessmentHexagon; context: { value: oldValue }"></ng-container>
                    <span class="change-arrow">&rarr;</span>
                    <ng-container *ngTemplateOutlet="assessmentHexagon; context: { value: newValue }"></ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #assessmentHexagon let-value="value">
    <ng-container *ngIf="value !== null">
        <pureui-svg class="{{ value | lowercase }}" height="12" svg="hexagon.svg" width="12"></pureui-svg>
        {{ value | titlecase }}
    </ng-container>
</ng-template>

<ng-template #linkArray let-value="value">
    <a
        hive-link
        [arrayHref]="message.array_id || message.arrayId"
        [arrayNameTooltip]="{ id: message.array_id || message.arrayId, name: message.hostname }"
        [ngbTooltip]
        target="_blank"
    >
        {{ value }}
    </a>
</ng-template>

<ng-template #dataProtectionAlertSourceTemplate>
    Pure1
    <a
        hive-link
        (click)="activeModal.dismiss()"
        [queryParams]="{ filter: ([{ entity: 'arrays', key: 'array_name', value: message.hostname }] | json) }"
        class="dpa-link"
        routerLink="/dashboard/assessment/data-protection-v2"
    >
        View Assessment
    </a>
    <div class="link-divider"></div>
    <ng-container [ngTemplateOutletContext]="{ value: 'Visit Array' }" [ngTemplateOutlet]="linkArray" />
</ng-template>

<ng-template #notDataProtectionAlertSourceTemplate>
    <ng-container [ngSwitch]="message.origin">
        <ng-container *ngSwitchCase="alertOrigin.PURE1">
            <a hive-link (click)="activeModal.dismiss()" routerLink="/fleet/subscriptions/active">Pure1</a>
        </ng-container>
        <ng-container *ngSwitchCase="alertOrigin.DRAAS">
            <span> {{ message.array_id | defaultString }} </span>
        </ng-container>
        <ng-container
            *ngSwitchDefault
            [ngTemplateOutletContext]="{ value: message.hostname }"
            [ngTemplateOutlet]="linkArray"
        />
    </ng-container>
</ng-template>
