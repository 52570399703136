import { Pipe, PipeTransform } from '@angular/core';

// parses FQDN and returns domain
@Pipe({ name: 'domain' })
export class DomainPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const firstDot = value.indexOf('.');
            return firstDot > 0 ? value.substring(firstDot + 1) : '-';
        }
        return '-';
    }
}
