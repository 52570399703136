<table
    class="table subscription-page-table main-table"
    [class.table-fixed-header]="!assetsTable"
    [class.assets-table]="assetsTable"
>
    <thead>
        <tr *ngIf="colGroups.length > 0" class="column-groups">
            <th *ngFor="let colGroup of colGroups" [colSpan]="colGroup.size">
                {{ colGroup.title }}
                <pureui-svg
                    *ngIf="colGroup.tooltip"
                    class="info-icon pstg-action-icon"
                    svg="info-circle.svg"
                    width="13"
                    [ngbTooltip]="colGroup.tooltip"
                    placement="top"
                    container="body"
                ></pureui-svg>
            </th>
        </tr>
        <tr>
            <th *ngIf="isRecommendationsEnabled" class="sticky-columns recommendation">
                <div class="recommendation-header">
                    <pureui-svg
                        svg="light/lightbulb.svg"
                        height="14"
                        [ngbTooltip]="recommendationTooltip"
                        placement="top"
                        container="body"
                    >
                    </pureui-svg>
                </div>
            </th>
            <th
                *ngIf="multiSelect"
                [ngClass]="{ 'checkbox-with-recommendation': isRecommendationsEnabled }"
                class="checkbox select-all"
                (click)="clickSelectAllCheckbox($event)"
            >
                <input type="checkbox" #selectAllCheckbox />
            </th>
            <ng-container *ngIf="!hasStickyCol; else withStickyCol">
                <th *ngFor="let column of shownColumns" (click)="toggleSort(column)" [class]="getWeightClass(column)">
                    <ng-container *ngIf="column.subColumns != null; else columnHeaderNoSubColumns">
                        <div>
                            {{ column.title }}
                        </div>
                        <div>
                            <div
                                *ngFor="let subColumn of column.subColumns"
                                class="sub-column-header"
                                [class]="getWeightClass(subColumn.column)"
                                (click)="toggleSort(subColumn.column)"
                            >
                                <div
                                    [class.manual-sort]="isSortable(subColumn.column)"
                                    [ngClass]="getSortByClass(subColumn.column)"
                                >
                                    <ng-container [ngTemplateOutlet]="subColumn.column.titleTemplate"></ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #columnHeaderNoSubColumns>
                        <div [class.manual-sort]="isSortable(column)" [ngClass]="getSortByClass(column)">
                            <ng-container
                                *ngIf="column.titleTemplate"
                                [ngTemplateOutlet]="column.titleTemplate"
                            ></ng-container>
                            <ng-container *ngIf="!column.titleTemplate">{{ column.title }}</ng-container>
                        </div>
                    </ng-template>
                </th>
            </ng-container>
            <ng-template #withStickyCol>
                <th
                    *ngFor="let column of stickyColumns"
                    (click)="toggleSort(column)"
                    class="weight-5 sticky-columns"
                    [ngClass]="{
                        'sticky-columns-with-multiselect': multiSelect,
                        'with-recommendations': isRecommendationsEnabled
                    }"
                >
                    <div [class.manual-sort]="isSortable(column)" [ngClass]="getSortByClass(column)">
                        <ng-container
                            *ngIf="column.titleTemplate"
                            [ngTemplateOutlet]="column.titleTemplate"
                        ></ng-container>
                        <ng-container *ngIf="!column.titleTemplate">{{ column.title }}</ng-container>
                    </div>
                </th>
                <th
                    *ngFor="let column of nonStickyColumns"
                    (click)="toggleSort(column)"
                    [class]="getWeightClass(column)"
                >
                    <ng-container *ngIf="column.subColumns != null; else columnHeaderNoSubColumns">
                        <div>
                            {{ column.title }}
                        </div>
                        <div>
                            <div
                                *ngFor="let subColumn of column.subColumns"
                                class="sub-column-header"
                                [class]="getWeightClass(subColumn.column)"
                                (click)="toggleSort(subColumn.column)"
                            >
                                <div
                                    [class.manual-sort]="isSortable(subColumn.column)"
                                    [ngClass]="getSortByClass(subColumn.column)"
                                >
                                    <ng-container [ngTemplateOutlet]="subColumn.column.titleTemplate"></ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #columnHeaderNoSubColumns>
                        <div [class.manual-sort]="isSortable(column)" [ngClass]="getSortByClass(column)">
                            <ng-container
                                *ngIf="column.titleTemplate"
                                [ngTemplateOutlet]="column.titleTemplate"
                            ></ng-container>
                            <ng-container *ngIf="!column.titleTemplate">{{ column.title }}</ng-container>
                        </div>
                    </ng-template>
                </th>
            </ng-template>

            <!-- For styling purposes -->
            <div class="row-end-template" *ngIf="rowEndTemplate"></div>
        </tr>
    </thead>
    <!-- loading -->
    <tbody *ngIf="loading && !data">
        <tr>
            <td [colSpan]="shownColumns.length + 1" class="subscription-page-table-message">Loading...</td>
        </tr>
    </tbody>
    <!-- no data -->
    <tbody *ngIf="!loading && dataSorted.length === 0">
        <tr>
            <td [colSpan]="shownColumns.length + 1" class="subscription-page-table-message">{{ noDataMsg }}</td>
        </tr>
    </tbody>
    <!-- data rows -->
    <tbody *ngIf="dataSorted.length > 0">
        <tr
            *ngFor="let row of dataSorted"
            [class.disabled]="multiSelect?.shouldDisableRow(row)"
            [class.selectable-row]="!assetsTable"
            [class.selected]="isItemSelected(row)"
            (click)="onRowClicked(row)"
        >
            <td *ngIf="isRecommendationsEnabled" class="sticky-columns recommendation">
                <div class="recommendation-cell" *ngIf="doesAssetHaveIncident(row)" (click)="goToCatalogPage(row)">
                    <pureui-svg
                        svg="icon-recommendation-bulb-small.svg"
                        height="14"
                        [ngbTooltip]="getApplianceRecommendationTooltip(row.id)"
                        placement="top"
                        container="body"
                    ></pureui-svg>
                </div>
            </td>
            <td
                *ngIf="multiSelect"
                [ngClass]="{ 'checkbox-with-recommendation': isRecommendationsEnabled }"
                class="checkbox"
            >
                <input type="checkbox" [checked]="isItemSelected(row)" [disabled]="multiSelect.shouldDisableRow(row)" />
            </td>
            <ng-container *ngIf="!hasStickyCol; else withStickyCol">
                <td *ngFor="let column of shownColumns" [class]="getCellClasses(column, row)">
                    <ng-container *ngIf="column.subColumns != null; else cellNoSubColumns">
                        <div
                            *ngFor="let subColumn of column.subColumns"
                            class="sub-column-cell"
                            [class]="getWeightClass(subColumn.column)"
                            [ngbTooltip]="
                                subColumn.column.getValue(row)?.length > SHOW_TOOLTIP_LENGTH &&
                                subColumn.column.getValue(row)
                            "
                        >
                            {{ subColumn.column.getValue(row) | defaultString }}
                        </div>
                    </ng-container>
                    <ng-template #cellNoSubColumns>
                        <ng-container
                            *ngIf="column.valueTemplate"
                            [ngTemplateOutlet]="column.valueTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row }"
                        >
                        </ng-container>
                        <span
                            *ngIf="!column.valueTemplate"
                            [ngbTooltip]="column.getValue(row)?.length > SHOW_TOOLTIP_LENGTH && column.getValue(row)"
                        >
                            {{ column.getValue(row) | defaultString }}
                        </span>
                    </ng-template>
                </td>
            </ng-container>
            <ng-template #withStickyCol>
                <td
                    *ngFor="let column of stickyColumns"
                    class="weight-5 sticky-columns"
                    [ngClass]="{
                        'sticky-columns-with-multiselect': multiSelect,
                        'with-recommendations': isRecommendationsEnabled
                    }"
                >
                    <ng-container
                        *ngIf="column.valueTemplate"
                        [ngTemplateOutlet]="column.valueTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row }"
                    >
                    </ng-container>
                    <span
                        *ngIf="!column.valueTemplate"
                        [ngbTooltip]="column.getValue(row)?.length > SHOW_TOOLTIP_LENGTH && column.getValue(row)"
                    >
                        {{ column.getValue(row) | defaultString }}
                    </span>
                </td>
                <td *ngFor="let column of nonStickyColumns" [class]="getCellClasses(column, row)">
                    <ng-container *ngIf="column.subColumns != null; else cellNoSubColumns">
                        <div
                            *ngFor="let subColumn of column.subColumns"
                            class="sub-column-cell"
                            [class]="getWeightClass(subColumn.column)"
                            [ngbTooltip]="
                                subColumn.column.getValue(row)?.length > SHOW_TOOLTIP_LENGTH &&
                                subColumn.column.getValue(row)
                            "
                        >
                            {{ subColumn.column.getValue(row) | defaultString }}
                        </div>
                    </ng-container>
                    <ng-template #cellNoSubColumns>
                        <ng-container
                            *ngIf="column.valueTemplate"
                            [ngTemplateOutlet]="column.valueTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row }"
                        >
                        </ng-container>
                        <span
                            *ngIf="!column.valueTemplate"
                            [ngbTooltip]="column.getValue(row)?.length > SHOW_TOOLTIP_LENGTH && column.getValue(row)"
                        >
                            {{ column.getValue(row) | defaultString }}
                        </span>
                    </ng-template>
                </td>
            </ng-template>
            <div *ngIf="rowEndTemplate" class="row-end-template">
                <ng-container [ngTemplateOutlet]="rowEndTemplate" [ngTemplateOutletContext]="{ $implicit: row }">
                </ng-container>
            </div>
        </tr>
    </tbody>
</table>
