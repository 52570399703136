import { PipeTransform, Pipe } from '@angular/core';
import moment from 'moment';

import { FormatApproxDurationPipe } from '../../ui/pipes/format-approx-duration.pipe';
import { DefaultStringPipe } from './default-string.pipe';

@Pipe({ name: 'formatFullInDays' })
export class FormatFullInDaysPipe implements PipeTransform {
    private formatApproxDurationPipe = new FormatApproxDurationPipe();
    private defaultStringFormat = new DefaultStringPipe();
    transform(value: any): string {
        let response = null;
        if (typeof value === 'number' && !Number.isNaN(value)) {
            if (value === 0) {
                return 'Now';
            }
            if (value >= 0 && value < 8) {
                return '< 1 week';
            }
            if (value < 0 || value > 365) {
                return '> 1 year';
            }
            response = this.formatApproxDurationPipe.transform(moment.duration(value, 'days'));
        }
        return this.defaultStringFormat.transform(response);
    }
}
