import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { UserNotification } from '../model/user-notification';

// New feature announcement notification Id,
// this is currently defined in the UserController in the spog server.
// search for New_FEATURE_ANNOUNCEMENT_NOTIFICATION to locate it.
export const BANNER_NOTIFICATION_ID = 'A000024';

const ENDPOINT = '/rest/v1/user/notifications';

@Injectable({ providedIn: 'root' })
export class UserNotificationService {
    constructor(private http: HttpClient) {}

    getAllNotifications(): Observable<UserNotification[]> {
        return this.http.get(ENDPOINT).pipe(
            take(1),
            map((response: any[]) => {
                return response.map(notification => new UserNotification(notification));
            }),
        );
    }

    ackNotification(notificationId: string, surveyId: string = null): Observable<{}> {
        const body = surveyId == null ? null : { survey_id: surveyId };
        return this.http.put(`${ENDPOINT}/${notificationId}`, body);
    }

    getNotification(notificationId: string): Observable<UserNotification> {
        return this.http.get(`${ENDPOINT}/${notificationId}`).pipe(
            take(1),
            map((notification: UserNotification) => new UserNotification(notification)),
        );
    }
}
