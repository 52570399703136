import { DraasApiProtectionStatistics } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryProtectionStatistics {
    groupsCount: number;
    policiesCount: number;
    plansCount: number;

    constructor(json: DraasApiProtectionStatistics) {
        this.groupsCount = json.protection_groups_count;
        this.policiesCount = json.service_level_policies_count;
        this.plansCount = json.recovery_plans_count;
    }
}
