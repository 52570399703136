import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    IGetMetricsHistoryResult,
    ApiCallBatcherInputs,
    BatchMetricsHistoryResult,
    MetricsHistoryMetricNames,
} from './metrics-history.service';
import { ITimeSeriesCacheKey, TimeSeriesCache } from '../utils/time-series-cache';
import { ApiCallBatcher } from './api-call-batcher';

/** Don't serve cache items older than this */
const TIMESERIES_VALUES_CACHE_DURATION = moment.duration(3, 'minutes');

/**
 * How long to have each ApiCallBatcher wait. Longer waits allow more to be batched together, but also introduce
 * longer delays before the requests are made.
 */
const BATCH_WAIT_TIME_MS = 100;

export type IGetBatchMetricsHistoryResult = Map<string, IGetMetricsHistoryResult>;

export type IGetBatchMetricsHistoryParameters = { [paramName: string]: string };

export function getUniqueKeysFromInputs<T extends ApiCallBatcherInputs, K extends keyof T>(
    inputs: T[],
    key: K,
): T[K][] {
    const itemSet = new Set<T[K]>(inputs.map(r => r[key]));
    return Array.from(itemSet).sort();
}

export function addIdsToRequest(idKey: string, idValues: string[], params: IGetBatchMetricsHistoryParameters): void {
    params[idKey] = idValues.join(',');
}

export abstract class BaseBatchMetricsHistoryService {
    private timeseriesValuesCache: TimeSeriesCache<IGetMetricsHistoryResult> =
        new TimeSeriesCache<IGetMetricsHistoryResult>(TIMESERIES_VALUES_CACHE_DURATION);
    private apiBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetBatchMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherGroupKey(request),
        requests => this.apiBatcherExecute(requests),
        this.window,
    );
    protected apiEndpoint: string;

    constructor(
        private http: HttpClient,
        private window: Window,
    ) {}

    // getMetricsHistoryTimeseries is not specified here so that inheriting services can specify with whatever parameters
    // necessary to make their requests

    /**
     * first argument to api call batcher, this is to create a grouping key to match requests together. only requests with
     * the same key will be batched together
     * @param request
     * @protected
     */
    protected abstract apiBatcherGroupKey(request: ApiCallBatcherInputs): string;

    /**
     * second argument to api call batcher, this is to execute a batch of requests. most likely this will need to construct
     * parameters and do any setup based on the requests, and then call fetchTimeSeriesData to execute the batch request
     * and populate the cache
     * @param requests
     * @protected
     */
    protected abstract apiBatcherExecute(requests: ApiCallBatcherInputs[]): Observable<IGetBatchMetricsHistoryResult>;

    protected enqueueBatch(
        entityId: string,
        apiBatcherInput: ApiCallBatcherInputs,
    ): Observable<IGetMetricsHistoryResult> {
        return this.apiBatcher.enqueue(apiBatcherInput).pipe(map(result => result.get(entityId)));
    }

    protected createCacheKey(
        metricNames: MetricsHistoryMetricNames[],
        id: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregationMethod: IAggregationMethod,
    ): ITimeSeriesCacheKey {
        return {
            metricNames: metricNames,
            entityId: id,
            startTime: startTime,
            endTime: endTime,
            aggregationMethod: aggregationMethod,
        };
    }

    protected fetchTimeSeriesData(
        uniqueIdList: string[],
        uniqueMetricList: MetricsHistoryMetricNames[],
        params: IGetBatchMetricsHistoryParameters,
        addIdsToParameters: (idList: string[], params: IGetBatchMetricsHistoryParameters) => void,
        createCacheKey: (id: string) => ITimeSeriesCacheKey,
    ): Observable<IGetBatchMetricsHistoryResult> {
        const unCachedIdList: string[] = [];
        const cachedResults = new Map<string, IGetMetricsHistoryResult>();
        uniqueIdList.forEach(id => {
            const cacheKey = createCacheKey(id);
            const cacheValue = this.timeseriesValuesCache.getValue(cacheKey);

            if (cacheValue) {
                cachedResults.set(id, cacheValue);
            } else {
                unCachedIdList.push(id);
            }
        });

        if (unCachedIdList.length === 0) {
            // no uncached results, no API call necessary
            return of(cachedResults);
        }
        // add ids we do not have cached timeseries for to the request
        addIdsToParameters(unCachedIdList, params);

        return this.http.get<BatchMetricsHistoryResult>(this.apiEndpoint, { params: params }).pipe(
            map(response => {
                const responseMap = new Map<string, IGetMetricsHistoryResult>();

                Object.keys(response).forEach(entityId => {
                    const metricsResult = { values: response[entityId] };
                    responseMap.set(entityId, metricsResult);
                    const cacheKey = createCacheKey(entityId);
                    this.timeseriesValuesCache.put(cacheKey, metricsResult); // Add it to the cache for next time
                });

                return responseMap;
            }),
            map(response => {
                // fill in cached timeseries
                cachedResults.forEach((value: IGetMetricsHistoryResult, key: string) => {
                    response.set(key, value);
                });
                return response;
            }),
        );
    }
}
