<div class="d-inline" [formGroup]="arrayForm">
    <ng-template #rt let-result="result" let-term="term">
        <div class="search-array-item">
            <!-- We ideally want to use result.name, but that's not available
                 from phonebook at the time of writing.  -->
            <ngb-highlight [result]="result.id" [term]="term"></ngb-highlight>
            <div class="search-array-item-info">
                <ngb-highlight
                    class="secondary-info search-array-item-model"
                    [result]="result.pxVersion"
                    [term]="term"
                ></ngb-highlight>
            </div>
        </div>
    </ng-template>

    <input
        class="form-control full-width"
        type="text"
        [ngbTypeahead]="search"
        [value]="searchString"
        (selectItem)="onItemSelected($event)"
        (click)="click$.next('')"
        (keydown)="typeaheadKeydown($event)"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        #typeInstance="ngbTypeahead"
        #typeaheadInput
        formControlName="array"
    />
</div>
