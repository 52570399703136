import { DraasApiSnapshotSetSummary } from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';

export class DisasterRecoverySnapshotSummary {
    id: string;
    createdAt: moment.Moment;
    protectedVms: number;
    totalVms: number;

    constructor(json: DraasApiSnapshotSetSummary) {
        this.id = json.id;
        this.createdAt = moment.utc(json.created_at);
        this.protectedVms = json.protected_vms;
        this.totalVms = json.total_vms;
    }
}
