import Highstock from 'highcharts/highstock';
import draggable from 'highcharts/modules/draggable-points';
import gantt from 'highcharts/modules/gantt';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';

export function configureModules(): void {
    configureHighCharts();
    configureMoment();
}

/**
 * Sets global default values for moment
 */
function configureMoment(): void {
    moment.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'seconds',
            ss: '%ds',
            m: '1m',
            mm: '%dm',
            h: '1hr',
            hh: '%dhr',
            d: '1d',
            dd: '%dd',
            M: '1mo',
            MM: '%dmo',
            y: '1yr',
            yy: '%dyr',
        },
    });
    // Need 60 minutes before counting in hours,
    moment.relativeTimeThreshold('m', 60);
    // 24 hours before counting in days,
    moment.relativeTimeThreshold('h', 24);
    // 30 days before counting in months,
    moment.relativeTimeThreshold('d', 30);
    // and 12 months before counting in years.
    moment.relativeTimeThreshold('M', 12);
}

/**
 * Sets global default values for HighCharts
 */
function configureHighCharts(): void {
    const CHART_CROSSHAIR_COLOR = '#1ba0e9';
    const CHART_GRIDLINE_COLOR = '#e6e6e6';
    const SERIES_DEFAULT_LINE_WIDTH = 1.5;

    // Modify the SVGRenderer's URL during the init process to not include URL parameters. This fixes
    // issues with rewriting the url dynamically, and having the absolute URL in the SVG no longer match the page's url.
    // This appears to work fine and cause no issues, at least in the current version of Highcharts.
    // There doesn't seem to be any documentation on why Highcharts uses an absolute path instead of just anchor links,
    // but it is probably due to making it iframe/embedding-friendly.
    const origInit = (<any>Highstock).SVGRenderer.prototype.init;
    (<any>Highstock).SVGRenderer.prototype.init = function (): void {
        // eslint-disable-next-line prefer-rest-params
        origInit.apply(this, arguments); // Perform the original init
        this.url = '';
    };

    // Explicitly allow some additional html attributes. Mostly stuff used for showing SVGs in the charts (eg in the Assessment honeycomb).
    // For more info, see: https://www.highcharts.com/docs/chart-concepts/security
    [
        'version',
        'xmlns',
        'viewBox',
        'ng-reflect-ng-style',
        'stroke-dasharray',
        'svg',
        'ng-reflect-svg',
        'ng-reflect-height',
        'ng-reflect-width',
    ].forEach(attr => {
        Highstock.AST.allowedAttributes.push(attr);
    });
    Highstock.AST.allowedTags.push('line', 'pureui-svg');

    // Set default options
    Highstock.setOptions({
        time: {
            useUTC: false,
        },
        chart: {
            style: {
                fontFamily: 'Proxima Nova, Helvetica, Arial, sans-serif',
            },
        },
        tooltip: {
            style: {
                fontFamily: 'Proxima Nova, Helvetica, Arial, sans-serif',
            },
        },
        credits: {
            enabled: false,
        },
        accessibility: {
            enabled: false, // Also disables the console warning about accessibility being disabled
        },
        xAxis: {
            dateTimeLabelFormats: {
                millisecond: {
                    main: '%H:%M:%S.%L',
                },
                second: {
                    main: '%H:%M:%S',
                },
                minute: {
                    main: '%H:%M',
                },
                hour: {
                    main: '%H:%M',
                },
                day: {
                    main: '%b. %e',
                },
                week: {
                    main: '%b. %e',
                },
                month: {
                    main: "%b '%y",
                },
                year: {
                    main: '%Y',
                },
            },
            lineColor: CHART_GRIDLINE_COLOR,
            gridLineColor: CHART_GRIDLINE_COLOR,
            minorGridLineColor: CHART_GRIDLINE_COLOR,
            gridLineDashStyle: 'Solid',
            crosshair: {
                color: CHART_CROSSHAIR_COLOR,
            },
        },
        yAxis: {
            lineColor: CHART_GRIDLINE_COLOR,
            gridLineColor: CHART_GRIDLINE_COLOR,
            minorGridLineColor: CHART_GRIDLINE_COLOR,
            gridLineDashStyle: 'Solid',
        },
        plotOptions: {
            series: {
                lineWidth: SERIES_DEFAULT_LINE_WIDTH,
                states: {
                    hover: {
                        lineWidth: SERIES_DEFAULT_LINE_WIDTH,
                        halo: {
                            size: 0,
                        },
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    lineColor: null, // Setting this null lets the select state's lineColor inherit the series color
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: null, // Use series color
                            lineWidth: 2,
                            radius: 2,
                        },
                        select: {
                            enabled: true,
                            lineColor: null, // Use series color
                            fillColor: null, // Use series color
                            lineWidth: 2,
                            radius: 2,
                        },
                    },
                },
            },
        },
    });
    draggable(Highstock);
    gantt(Highstock);
    highchartsMore(Highstock);
}
