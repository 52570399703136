import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'podPromotionSvg' })
export class PodPromotionSvgPipe implements PipeTransform {
    transform(status: string): string {
        switch (status) {
            case 'demoted':
                return 'demoted.svg';
            case 'demoting':
                return 'demoting.svg';
            case 'promoted':
                return 'promoted.svg';
            case 'promoting':
                return 'promoting.svg';
            case 'unknown':
                return 'promotion_status_unknown.svg';
            default:
                console.warn('unknown podPromotionStatus: ' + status);
                return 'promotion_status_unknown.svg';
        }
    }
}
