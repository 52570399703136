import { Resource } from '../interfaces/resource';
import { CapacityConfig } from './capacity-config';
export class HardwareCapacityConfiguration implements Resource {
    id: string;
    name: string;
    raw: number;
    usable: number;
    capacityConfig: CapacityConfig;
    discontinued: boolean;
    licensedRawTB: number;

    constructor(json: any) {
        // TODO: change id and name to be more meaningful once we have the config data
        this.id = 'placehold-id';
        this.name = 'placeholder-name';
        this.raw = json.raw;
        this.usable = json.usable;
        this.discontinued = json.discontinued;
        this.capacityConfig = json.capacityConfig;
        this.licensedRawTB = json.licensedRawTB;
    }
}
