<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<banners></banners>

<subscription-genealogy-totals [data]="unfilteredData"></subscription-genealogy-totals>

<section class="action-bar">
    <gfb
        class="btn-group flex-btn-group flex-btn-group-first"
        [barId]="barId"
        [entities]="GFB_ENTITIES"
        [defaultKey]="DEFAULT_KEY"
        [defaultEntity]="DEFAULT_ENTITY"
    >
    </gfb>
    <div class="table-top-actions">
        <div class="actions-left-section">
            <div class="customize-options">
                <ng-select
                    class="subscription-name-dropdown"
                    [clearable]="false"
                    [ngModel]="selectedSubscriptionName"
                    [searchable]="false"
                    [items]="subscriptionNameOptions"
                    (change)="onSubscriptionNamesChanges($event)"
                >
                    <ng-template ng-option-tmp let-item="item">
                        <div class="name-options">
                            <span class="name-label">{{ item }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        View: <span class="name-label">{{ item }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <genealogy-sort-dropdown
                [sortOptions]="sortOptions"
                [sortState]="sortState"
                (sortChange)="onSort($event)"
            ></genealogy-sort-dropdown>
            <div class="export-action" ngbTooltip="Export" container="body">
                <ng-template #exportModal let-modal>
                    <subscription-genealogy-export-modal
                        [modal]="modal"
                        [selectedOption]="selectedExportOption"
                        [entityFilter]="subscriptionFilter"
                        [totalFiltered]="data?.length"
                        [totalUnfiltered]="totalUnfiltered"
                    >
                    </subscription-genealogy-export-modal>
                </ng-template>
                <export-button
                    class="export-btn"
                    [showNewIcon]="true"
                    [showText]="false"
                    [useSpinner]="true"
                    (updateCounts)="updateExportOptionCounts($event.optionsMap)"
                    [exportOptions]="listViewExportOptions"
                    (onClickExport)="onClickExport($event.option)"
                >
                </export-button>
            </div>
        </div>
        <div class="actions-right-section">
            <div class="indicators">
                <div class="option-info" *ngFor="let event of events">
                    <pureui-svg class="legend-icon" height="12" [svg]="event | genealogyEventIcon"></pureui-svg>
                    <div class="option-text">{{ event | genealogyEventDisplayName }}</div>
                </div>
            </div>
            <genealogy-display-options
                [selectedEvents]="eventTypeFilters"
                [eventOptions]="eventOptions"
                (applyOptions)="onApplyDisplayOptions($event)"
            >
            </genealogy-display-options>
        </div>
    </div>
</section>
<subscription-genealogy-chart
    [genealogies]="!loading && filteredData"
    [selectedSubscriptionName]="selectedSubscriptionName"
></subscription-genealogy-chart>
