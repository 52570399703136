import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'pure-support-case-wrapper',
    template: '<pure-support-case *ngIf="caseId" [standalone]="true" [caseId]="caseId"></pure-support-case>',
})
export class SupportCaseComponentWrapper implements OnInit, OnDestroy {
    caseId: string;

    private readonly destroy$ = new Subject<void>();

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.caseId = params.caseId;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
