import moment from 'moment';
import { PxResource } from './px-resource';
import { PxVolumeAggregation } from './px-volume-aggregation';

export type PxStatus = 'online' | 'offline' | 'warning';

export type PxVolumeState =
    | 'none'
    | 'pending'
    | 'available'
    | 'attached'
    | 'detached'
    | 'detaching'
    | 'error'
    | 'deleted'
    | 'try_detaching'
    | 'restore'
    | 'in_trashcan';

export type PxVolumeLabel = { key: string; value: string };

export class PxVolume extends PxResource {
    volumeName: string;
    pvcName: string;
    pvcNamespace: string;
    clusterName: string;
    state: PxVolumeState;
    status: PxStatus;
    haLevel: number;
    format: number;
    attachTime: moment.Moment;
    detachTime: moment.Moment;
    labels: PxVolumeLabel[];
    aggregation: PxVolumeAggregation;

    constructor(json: any) {
        super(json);
        this.name = json.volume_display_name;
        this.volumeName = json.volume_name;
        this.id = json.volume_id;
        this.pvcName = json.pvc_name;
        this.pvcNamespace = json.pvc_namespace;
        this.clusterName = json.name;
        this.state = json.state;
        this.status = json.status;
        this.haLevel = json.ha_level;
        this.format = json.format;
        this.attachTime = moment(json.attach_time);
        this.detachTime = moment(json.detach_time);

        this.labels = [];
        if (json.labels) {
            const keys = Object.keys(json.labels);
            keys.forEach(key => this.labels.push({ key, value: json.labels[key] }));
        }

        if (json.aggregation) {
            this.aggregation = new PxVolumeAggregation(json.aggregation);
        }
    }
}
