import { filter, Subject, takeUntil } from 'rxjs';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { BadgeEmphasis } from '@pure/hive';

export interface ISubmenuBarItem {
    title: string;
    url?: string;
    pillText?: string;
    pillTooltip?: string;
    pillEmphasis?: BadgeEmphasis;
    testId?: string;
}

@Directive()
export class SubmenuBarBaseComponent implements OnInit, OnDestroy {
    selectedTab: string;
    deregisterHook: Function;

    protected readonly destroy$ = new Subject<boolean>();

    constructor(protected router: Router) {}

    ngOnInit(): void {
        this.selectedTab = this.router.url;
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd || e instanceof NavigationSkipped),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                // For some reason, NavigationSkipped can occur when you 1) reload page, 2) go to submenu, 3) immediately
                // navigate back. Not sure why this happens. But because we just use this as a trigger for updating the url,
                // it shouldn't matter if it was actually skipped or not.
                this.selectedTab = this.router.url;
            });
    }

    ngOnDestroy(): void {
        if (this.deregisterHook) {
            this.deregisterHook();
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    /**
     * Gets if the given menu item is the active route
     */
    isActive(menuItem: ISubmenuBarItem): boolean {
        return this.router.isActive(menuItem.url, {
            matrixParams: 'ignored',
            paths: 'subset',
            queryParams: 'ignored',
            fragment: 'ignored',
        });
    }
}
