<div class="safemode-intro-wrapper">
    <div class="container-fluid mt-5 mb-5">
        <div class="row">
            <div class="col-md-5 image-column">
                <img src="/images/authentication-required.svg" alt="authentication-required" />
            </div>
            <div class="col-md-7">
                <h3 class="font-mildly-bold mb-2">SafeMode Configuration Wizard</h3>

                <div *ngIf="loading; else loaded" class="p-loading">
                    <pureui-spinner [pureuiShow]="true"></pureui-spinner>
                </div>

                <ng-template #loaded>
                    <div *ngIf="!hasSNOWContact; else haveSNOWContact">
                        You have read-only access. First time access to the Pure1 Manage Support Cases page requires a
                        verification step. This process requires a minimum of 45 minutes to resolve. You can retry
                        accessing the page again later. If this is not your first time, work with your Administrator to
                        validate your access.
                    </div>
                    <ng-template #haveSNOWContact>
                        <div *ngIf="isOrgCompliant; else nonCompliantState">
                            <p class="font-mildly-bold">This requires 2 steps:</p>
                            <p class="font-mildly-bold left-gap"
                                ><span class="color-orange">Step 1.</span> Select your appliances and change
                                configuration</p
                            >
                            <p class="font-mildly-bold left-gap"
                                ><span class="color-orange">Step 2.</span> Schedule an appointment with Pure Technical
                                Service</p
                            >

                            <p
                                >Please read
                                <a
                                    target="_blank"
                                    href="https://support.purestorage.com/Pure1/Pure1_Manage/006a_Pure1_Manage_-_Fleet/Pure_1_Manage_-_SafeMode"
                                    >this article</a
                                >
                                for more details.</p
                            >
                            <button (click)="proceed.emit()" class="btn btn-primary">Continue</button>
                        </div>

                        <ng-template #nonCompliantState>
                            <p>
                                <span class="font-mildly-bold"
                                    >Your organization is <span class="color-orange">non-compliant</span>.</span
                                >
                                You should have a minimum of two SafeMode approvers with the step-up authentication
                                enabled for the organization to be in compliant mode.
                            </p>
                            <p>
                                Find more info in our
                                <a
                                    target="_blank"
                                    href="https://support.purestorage.com/Pure1/Pure1_Manage/006a_Pure1_Manage_-_Fleet/Pure_1_Manage_-_SafeMode"
                                    >documentation</a
                                >.
                            </p>
                        </ng-template>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
</div>
