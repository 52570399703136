export function formatNumber(value: number, decimal: number): string {
    if (typeof value !== 'number' || typeof decimal !== 'number') {
        return String(value);
    }
    const p = Math.pow(10.0, decimal);
    let r = String(Math.round(value * p) / p);
    let i = r.indexOf('.');
    let count = 0;
    if (decimal > 0) {
        if (i < 0) {
            count = decimal;
            r = r + '.';
        } else {
            count = decimal - (r.length - i - 1);
        }
    }
    for (i = 0; i < count; i++) {
        r = r + '0';
    }

    return r;
}
