import {
    Directive,
    ComponentRef,
    ComponentFactoryResolver,
    Input,
    Injector,
    ApplicationRef,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    AfterViewInit,
    Renderer2,
    ElementRef,
    Inject,
} from '@angular/core';
import { WINDOW } from '../../app/injection-tokens';

export interface PureTooltipComponent {
    resolve: any;
    event: MouseEvent;
}

@Directive({
    selector: '[pureTooltip]',
    host: {
        '(mouseenter)': 'createTooltip($event)',
        '(mouseleave)': 'destroyTooltip()',
    },
})
export class TooltipDirective<T extends PureTooltipComponent> implements AfterViewInit, OnChanges, OnDestroy {
    @Input('pureTooltip') ComponentClass: { new (): T };
    @Input() resolve: any;

    componentRef: ComponentRef<T>;

    private deregisterMouseMove: Function;

    static computeTooltipOffset(
        tooltip: { height: number; width: number },
        client: { x: number; y: number },
    ): { top: string; left: string } {
        const marginFromBottom = 5;
        const marginFromRight = 5;
        const leftOffset = 10;
        const topOffset = 20;

        // Try to put the tooltip to the bottom right
        let left = client.x + leftOffset;
        let top = client.y + topOffset;

        const overflowX = left + tooltip.width > window.innerWidth - marginFromRight;
        const overflowY = top + tooltip.height > window.innerHeight - marginFromBottom;

        if (!overflowY) {
            left = Math.min(client.x - leftOffset, window.innerWidth - marginFromRight - tooltip.width);
        } else {
            if (!overflowX) {
                top = window.innerHeight - marginFromBottom - tooltip.height;
            } else {
                top = client.y - topOffset - tooltip.height;
                left = Math.min(client.x - leftOffset, window.innerWidth - marginFromRight - tooltip.width);
            }
        }

        return {
            top: top + 'px',
            left: left + 'px',
        };
    }

    constructor(
        @Inject(WINDOW) private window: Window,
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private ngZone: NgZone,
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        // For performance reasons, run mousemove outside of the Angular zone. detectChanges() will be called
        // on the tooltip component directly so that bindings in the tooltip's ComponentClass instance will still update.
        this.ngZone.runOutsideAngular(() => {
            this.deregisterMouseMove = this.renderer.listen(this.el.nativeElement, 'mousemove', $event => {
                this.moveTooltip($event);
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resolve && this.componentRef) {
            this.componentRef.instance.resolve = this.resolve;
            this.componentRef.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.destroyTooltip();
        this.deregisterMouseMove?.();
    }

    createTooltip($event: MouseEvent): void {
        if (!this.componentRef) {
            this.componentRef = this.componentFactoryResolver
                .resolveComponentFactory(this.ComponentClass)
                .create(this.injector);
            this.componentRef.instance.resolve = this.resolve;
            this.applicationRef.attachView(this.componentRef.hostView);
            document.body.appendChild(this.componentRef.location.nativeElement);
        }
        this.moveTooltip($event);
    }

    moveTooltip($event: MouseEvent): void {
        if (!this.componentRef) {
            return;
        }
        this.componentRef.instance.event = $event;

        this.ngZone.runOutsideAngular(() => {
            this.window.requestAnimationFrame(() => {
                // Check if the component has been destroyed
                if (!this.componentRef) {
                    return;
                }
                this.componentRef.instance.event = $event;
                this.componentRef.changeDetectorRef.detectChanges();

                const tooltip = this.componentRef.location.nativeElement as HTMLElement;
                const tooltipSize = {
                    height: tooltip.offsetHeight,
                    width: tooltip.offsetWidth,
                };
                const clientPosition = {
                    x: $event.clientX,
                    y: $event.clientY,
                };
                const tooltipOffset = TooltipDirective.computeTooltipOffset(tooltipSize, clientPosition);
                Object.assign(this.componentRef.location.nativeElement.style, tooltipOffset);
            });
        });
    }

    destroyTooltip(): void {
        if (this.componentRef) {
            this.applicationRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
