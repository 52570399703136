import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArrayGenealogy } from '../models/array-genealogy';
import { GenericService } from './generic.service';

@Injectable({ providedIn: 'root' })
export class ArrayGenealogyService extends GenericService<ArrayGenealogy> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArrayGenealogy,
            endpoint: '/rest/v1/asset-management/appliance-genealogies',
        });
    }
}
