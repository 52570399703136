import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    FeatureFlagDxpService,
    GFBEntity,
    isSubscriptionGenealogyEvent,
    SubscriptionGenealogy,
    SubscriptionGenealogyEventType,
    SubscriptionGenealogyService,
} from '@pure1/data';
import { PagedDataComponent2 } from 'core/src/gui/paged-data2.component';
import moment from 'moment';
import { Action } from 'redux';
import { take } from 'rxjs';
import { ampli } from '../../ampli';
import { ExportOptionIds } from '../../export/export-button/export-button.component';
import { FeatureNames } from '../../model/FeatureNames';
import {
    addFilter,
    addSort,
    ASSET_APPLIANCE_ENTITY,
    ASSET_LICENSE_ENTITY,
    ASSET_SUBSCRIPTION_ENTITY,
    DEFAULT_NAMESPACE,
    GLOBAL_ENTITY,
    LOCAL_BAR_ASSET_MANAGEMENT,
    LOCAL_BAR_SUBSCRIPTION_GENEALOGY_HIDDEN,
    NAME_KEY,
    removeAllSorts,
    removeFiltersByKey,
    SUBSCRIPTION_ASSET_ENTITY,
    SUBSCRIPTION_GENEALOGY_EVENT_TYPE_KEY,
} from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { UrlService } from '../../redux/url.service';
import { createFilter, getActiveFilters, getCombinedFilterValues } from '../../redux/utils';
import { ToastService, ToastType } from '../../services/toast.service';
import { SortOption } from '../genealogy-sort-dropdown/genealogy-sort-dropdown.component';

export enum SubscriptionNameOption {
    INITIAL_SUBSCRIPTION = 'Initial Subscription',
    CURRENT_SUBSCRIPTION = 'Current Subscription',
}

enum SortType {
    NAME = 'name',
    START = 'start',
}

const SORT_OPTIONS: SortOption[] = [
    {
        sortKey: 'start',
        label: 'Chronologically',
    },
    {
        sortKey: 'name',
        label: 'Alphabetically',
    },
];

const SELECTABLE_EVENT_TYPES: SubscriptionGenealogyEventType[] = ['plannedRamp', 'updateRequest', 'renewal'];
const LEGEND_EVENTS: SubscriptionGenealogyEventType[] = [
    'plannedRamp',
    'updateRequest',
    'renewal',
    'expiration',
    'termination',
];

@Component({
    selector: 'subscription-genealogy-view',
    templateUrl: './subscription-genealogy-view.component.html',
})
export class SubscriptionGenealogyViewComponent extends PagedDataComponent2<SubscriptionGenealogy> implements OnInit {
    readonly GFB_ENTITIES: GFBEntity[] = [
        ASSET_SUBSCRIPTION_ENTITY,
        ASSET_LICENSE_ENTITY,
        ASSET_APPLIANCE_ENTITY,
        GLOBAL_ENTITY,
    ];
    readonly DEFAULT_KEY = NAME_KEY;
    readonly DEFAULT_ENTITY = ASSET_SUBSCRIPTION_ENTITY;
    readonly listViewExportOptions: IExportButtonOption[] = [
        { id: ExportOptionIds.filtered, text: 'Export filtered assets', count: null },
        { id: ExportOptionIds.all, text: 'Export all assets', count: null },
    ];
    readonly hiddenBarId = LOCAL_BAR_SUBSCRIPTION_GENEALOGY_HIDDEN;
    readonly eventTypeToField = new Map<SubscriptionGenealogyEventType, string>([
        ['plannedRamp', 'plannedRampEvents'],
        ['updateRequest', 'updateRequestEvents'],
        ['renewal', 'renewalEvents'],
        ['upcomingRenewal', 'upcomingRenewalEvent'],
    ]);

    @ViewChild('exportModal', { static: true }) readonly exportModal: TemplateRef<any>;

    events: SubscriptionGenealogyEventType[] = LEGEND_EVENTS;
    selectedExportOption: IExportButtonOption;
    subscriptionFilter: string;
    totalUnfiltered: number = null;
    unfilteredData: SubscriptionGenealogy[];
    eventTypeFilters: SubscriptionGenealogyEventType[] = SELECTABLE_EVENT_TYPES.slice();
    eventOptions: SubscriptionGenealogyEventType[] = SELECTABLE_EVENT_TYPES;

    subscriptionNameOptions: SubscriptionNameOption[] = [
        SubscriptionNameOption.CURRENT_SUBSCRIPTION,
        SubscriptionNameOption.INITIAL_SUBSCRIPTION,
    ];
    selectedSubscriptionName: string = SubscriptionNameOption.CURRENT_SUBSCRIPTION;
    sortOptions = SORT_OPTIONS;
    sortState: string = SortType.NAME;
    filteredData: SubscriptionGenealogy[] = [];
    isFollowupEnabled = false;

    constructor(
        private featureFlagDxpService: FeatureFlagDxpService,
        protected subscriptionGenealogyService: SubscriptionGenealogyService,
        protected ngRedux: NgRedux<IState>,
        private ngbModal: NgbModal,
        private toastService: ToastService,
        url: UrlService,
    ) {
        super(
            subscriptionGenealogyService,
            ngRedux,
            url,
            new SubscriptionGenealogyReaderWriter(),
            LOCAL_BAR_ASSET_MANAGEMENT,
            SUBSCRIPTION_ASSET_ENTITY,
            DEFAULT_NAMESPACE,
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscriptionGenealogyService
            .list({ pageStart: 0, pageSize: 1 })
            .pipe(take(1))
            .subscribe(({ response, total }) => {
                this.unfilteredData = response;
                this.totalUnfiltered = total;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_FOLLOWUP)
            .pipe(take(1))
            .subscribe((feature: FeatureFlagStatus) => {
                this.isFollowupEnabled = feature?.enabled === true;
                if (this.isFollowupEnabled) {
                    this.eventOptions = [...this.eventOptions, 'upcomingRenewal'];
                    if (!this.eventTypeFilters.includes('upcomingRenewal')) {
                        this.eventTypeFilters = [...this.eventTypeFilters, 'upcomingRenewal'];
                    }
                }
            });
    }

    protected getAutoRefreshInterval(): moment.Duration {
        // Arbitrary but it's not necessary to update every 30s. Data will get populated at most every quarter
        return moment.duration(1, 'hour');
    }

    // Gets called when the export menu pops up
    updateExportOptionCounts(optionsMap: Map<number, IExportButtonOption>): void {
        const updateExportOption = (option: IExportButtonOption, count: number) => {
            option.disabled = count === 0 || count == null;
            // Don't want to show 0, just disable it
            option.count = count ? count : null;
        };

        const filteredOpt = optionsMap.get(ExportOptionIds.filtered);
        updateExportOption(filteredOpt, this.data?.length);

        if (this.totalUnfiltered !== null) {
            const allOpt = optionsMap.get(ExportOptionIds.all);
            updateExportOption(allOpt, this.totalUnfiltered);
        }
    }

    onClickExport(option: IExportButtonOption): void {
        this.selectedExportOption = option;
        this.ngbModal.open(this.exportModal);
    }

    onSubscriptionNamesChanges(subscriptionType: string): void {
        this.selectedSubscriptionName = subscriptionType;
        if (subscriptionType === SubscriptionNameOption.INITIAL_SUBSCRIPTION) {
            ampli.subscriptionGenealogySubscriptionNamesSelectionApplied({ 'subscription name': 'initial name' });
        } else {
            ampli.subscriptionGenealogySubscriptionNamesSelectionApplied({ 'subscription name': 'current name' });
        }
    }

    onSort(sortKey: string): void {
        if (sortKey === 'start' || sortKey === 'start-') {
            ampli.subscriptionGenealogySortingApplied({ 'Subscription Genealogy Sorting Category': 'Chronologically' });
        } else if (sortKey === 'name' || sortKey === 'name-') {
            ampli.subscriptionGenealogySortingApplied({ 'Subscription Genealogy Sorting Category': 'Alphabetically' });
        }
        this.ngRedux.dispatch([removeAllSorts(this.hiddenBarId), addSort(this.hiddenBarId, sortKey)]);
    }

    onDataChange(_: SubscriptionGenealogy[]): void {
        this.filteredData = this.applyEventTypeFilter(this.data || []);
        this.sortData();
    }

    onBeforeUpdateParams(): void {
        const state = this.ngRedux.getState();

        const filters = state.filters[this.hiddenBarId];
        if (filters) {
            const activeFilters = getActiveFilters(null, state.filters[this.hiddenBarId]);
            const filterValues = getCombinedFilterValues(activeFilters, SUBSCRIPTION_GENEALOGY_EVENT_TYPE_KEY);
            if (filterValues === '') {
                this.eventTypeFilters = [];
            } else {
                const filterStrings = filterValues.split(',');
                this.eventTypeFilters = filterStrings.filter(isSubscriptionGenealogyEvent);
            }
        }

        this.sortState = (state.sorts[this.hiddenBarId] || [])[0] || this.sortState;
        this.filteredData = this.applyEventTypeFilter(this.data || []);
        this.sortData();
    }

    onAfterFetchFailed(_: Error): void {
        this.loading = false;
        this.toastService.add(ToastType.error, 'Failed to retrieve subscription genealogy data.');
        this.onDataChange([]);
    }

    onApplyDisplayOptions(events: SubscriptionGenealogyEventType[]): void {
        const actions: Action[] = [
            removeFiltersByKey(this.hiddenBarId, SUBSCRIPTION_GENEALOGY_EVENT_TYPE_KEY, DEFAULT_NAMESPACE),
        ];
        events.forEach(event => {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(null, DEFAULT_NAMESPACE, SUBSCRIPTION_GENEALOGY_EVENT_TYPE_KEY, event),
                ),
            );
            if (event === 'plannedRamp') {
                ampli.subscriptionGenealogyEventsDisplayOptionsApplied({
                    'subscription genealogy event types': 'planned ramp',
                });
            } else if (event === 'updateRequest') {
                ampli.subscriptionGenealogyEventsDisplayOptionsApplied({
                    'subscription genealogy event types': 'update request',
                });
            } else if (event === 'renewal') {
                ampli.subscriptionGenealogyEventsDisplayOptionsApplied({
                    'subscription genealogy event types': 'renewal',
                });
            } else if (event === 'expiration') {
                ampli.subscriptionGenealogyEventsDisplayOptionsApplied({
                    'subscription genealogy event types': 'expiration',
                });
            } else if (event === 'upcomingRenewal') {
                ampli.subscriptionGenealogyEventsDisplayOptionsApplied({
                    'subscription genealogy event types': 'upcoming renewal',
                });
            }
        });

        this.ngRedux.dispatch(actions);
    }

    private applyEventTypeFilter(data: SubscriptionGenealogy[]): SubscriptionGenealogy[] {
        return data.map(genealogy => {
            const filteredGenealogy: SubscriptionGenealogy = {
                ...genealogy,
                plannedRampEvents: [],
                updateRequestEvents: [],
                renewalEvents: [],
                upcomingRenewalEvent: null,
            };
            if (this.eventTypeFilters && this.eventTypeFilters[0]?.length > 0) {
                this.eventTypeFilters.forEach(filter => {
                    const field = this.eventTypeToField.get(filter);
                    filteredGenealogy[field] = genealogy[field];
                });
            }
            return filteredGenealogy;
        });
    }

    private sortData() {
        // Strip "-" from sortKey to denote desc or asc
        const sortKey = this.sortState.endsWith('-')
            ? this.sortState.slice(0, this.sortState.length - 1)
            : this.sortState;

        const sortDesc = this.sortState.endsWith('-');

        this.filteredData = this.filteredData.slice().sort((subscriptionA, subscriptionB) => {
            // TODO: Change this to take into account Initial vs Current Subscription
            if (sortKey === SortType.NAME) {
                return subscriptionA.name?.localeCompare(subscriptionB.name);
            } else if (sortKey === SortType.START) {
                return subscriptionA.startEvent.date.diff(subscriptionB.startEvent.date);
            } else {
                return 0;
            }
        });

        if (sortDesc) {
            this.filteredData = this.filteredData.slice().reverse();
        }
    }
}

class SubscriptionGenealogyReaderWriter extends UrlReaderWriter {
    path = /^\/assets\/subscription-genealogies/;
    localBarId = LOCAL_BAR_ASSET_MANAGEMENT;

    constructor() {
        super();
    }
}
