import {
    Component,
    Input,
    EventEmitter,
    Output,
    Renderer2,
    ElementRef,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Inject,
} from '@angular/core';
import { Resource } from '@pure1/data';

import { WINDOW } from '../../app/injection-tokens';

@Component({
    selector: 'pure-entity-selection-dropdown',
    templateUrl: 'entity-selection-dropdown.component.html',
})
export class EntitySelectionDropdownComponent implements OnDestroy, OnChanges {
    @Input() readonly entityName: string;
    @Input() entities: Resource[]; // TODO: Fix this to be readonly
    @Input() selectedEntity: Resource = null; // TODO: Fix this to be readonly
    @Input() readonly isEditable: boolean = true;
    @Input() readonly isSelectable: boolean = true;
    @Input() readonly entityColorMap: Map<string, string>;
    @Input() readonly displayTextOverride: string | null;

    @Output() readonly entitiesChange: EventEmitter<Resource[]> = new EventEmitter<Resource[]>();
    @Output() readonly selectedEntityChange: EventEmitter<Resource> = new EventEmitter<Resource>();

    /** Max number of individual items to be displayed */
    readonly ITEM_DISPLAY_LIMIT = 100;

    isSelectionsExpanded = false;

    private endSelectionExpandFn = null;

    constructor(
        @Inject(WINDOW) private window: Window,
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.entities) {
            if (this.entities.length === 0) {
                if (this.isSelectionsExpanded) {
                    this.toggleSelections();
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.endSelectionExpandFn) {
            this.endSelectionExpandFn();
        }
    }

    entityHasColor(entity: Resource): boolean {
        return this.entityColorMap && this.entityColorMap.has(entity.id);
    }

    getEntityColor(entity: Resource): string {
        return this.entityColorMap.get(entity.id);
    }

    toggleSelections(): void {
        this.isSelectionsExpanded = !this.isSelectionsExpanded;
        if (this.isSelectionsExpanded) {
            this.endSelectionExpandFn = this.renderer.listen('window', 'click', (event: MouseEvent) => {
                if (!this.el.nativeElement.contains(event.target)) {
                    this.isSelectionsExpanded = false;
                    this.endSelectionExpandFn();
                    this.endSelectionExpandFn = null;
                }
            });
        } else {
            if (this.endSelectionExpandFn) {
                this.endSelectionExpandFn();
                this.endSelectionExpandFn = null;
            }
        }
    }

    isSelected(entity: Resource): boolean {
        return this.selectedEntity && entity.id === this.selectedEntity.id;
    }

    clickClearAll(): void {
        this.entities = [];
        this.entitiesChange.emit(this.entities);
    }

    deselectEntity($event: MouseEvent, entity: Resource): void {
        $event.stopPropagation();

        this.window.setImmediate(() => {
            // so element will not be removed before click event is handled, to keep dropdown visible.
            this.entities = this.entities.filter(selectedItem => selectedItem.id !== entity.id);
            this.entitiesChange.emit(this.entities);
            if (this.isSelected(entity)) {
                this.selectedEntity = null;
                this.selectedEntityChange.emit(null);
            }
        });
    }

    clickEntity(entity: Resource): void {
        if (this.isSelectable) {
            this.selectedEntity = entity;
            this.selectedEntityChange.emit(this.selectedEntity);
        }
    }
}
