import { Component, Output, EventEmitter } from '@angular/core';
import { BaseBanner } from '../base-banner';

/*
    This banner is mostly copied from FeatureBannerComponent, tweaked slightly and the extra bits stripped out.
    Doing that felt more appropriate than adding more special-cases to the FeatureBannerComponent directly.
*/
@Component({
    selector: 'eol-warning-carousel-banner',
    templateUrl: 'eol-warning-carousel-banner.component.html',
})
export class EolWarningCarouselBannerComponent extends BaseBanner {
    @Output() readonly dismissNotification = new EventEmitter<void>();

    getHwEolFilters(): string {
        return JSON.stringify([{ entity: 'arrays', key: 'has_end_of_life_hardware', value: 'true' }]);
    }
}
