import { LazyLoadingScriptService } from './lazy-load-scripts.service';

export const googleMapsPromiseFactory = (lazyLoadScript: LazyLoadingScriptService): Promise<typeof google.maps> => {
    return new Promise((accept, reject) => {
        lazyLoadScript
            .loadScript('https://maps.googleapis.com/maps/api/js?libraries=places,geometry&client=gme-purestorage')
            .subscribe(
                (progress: number) => {
                    if (progress === 1) {
                        accept(google.maps);
                    }
                },
                (err: string) => reject(err),
            );
    });
};
