import { Pipe, PipeTransform } from '@angular/core';
import { AssignmentStatus } from '@pure1/data';

@Pipe({
    name: 'roleStatusSuffix',
})
export class RoleStatusSuffixPipe implements PipeTransform {
    transform(status: AssignmentStatus): string | null {
        if (status === 'ASSIGNMENT_PENDING') {
            return 'pending approval';
        } else if (status === 'REMOVAL_PENDING') {
            return 'pending removal';
        }

        return null;
    }
}
