enum AlertSeverityEnum {
    CRITICAL = 100,
    WARNING = 200,
    INFO = 300,
    UNKNOWN = 400,
}

export class AlertSeverity {
    private alertSeverityNumber: AlertSeverityEnum;

    constructor(value: number) {
        if (Number.isNaN(value)) {
            this.alertSeverityNumber = AlertSeverityEnum.UNKNOWN;
            return;
        }

        switch (value) {
            case AlertSeverityEnum.CRITICAL:
                this.alertSeverityNumber = AlertSeverityEnum.CRITICAL;
                break;
            case AlertSeverityEnum.WARNING:
                this.alertSeverityNumber = AlertSeverityEnum.WARNING;
                break;
            case AlertSeverityEnum.INFO:
                this.alertSeverityNumber = AlertSeverityEnum.INFO;
                break;
            default:
                this.alertSeverityNumber = AlertSeverityEnum.UNKNOWN;
                break;
        }
    }

    toString(): string {
        switch (this.alertSeverityNumber) {
            case AlertSeverityEnum.CRITICAL:
                return 'CRITICAL';
            case AlertSeverityEnum.WARNING:
                return 'WARNING';
            case AlertSeverityEnum.INFO:
                return 'INFO';
            default:
                return 'UNKNOWN';
        }
    }
}
