import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { WorkloadStats } from '../models/workload-stats';

@Injectable({ providedIn: 'root' })
export class WorkloadStatsService extends GenericService<WorkloadStats> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: WorkloadStats,
            endpoint: '/rest/v1/forecast/workload-stats',
        });
    }
}
