import { DraasApiGroupProtectionSummary } from '@pure/paas-api-gateway-client-ts';
import moment from 'moment/moment';

export class DisasterRecoveryGroupProtectionSummary {
    groupId: string;
    groupName: string;
    isConsistencyGroup: boolean;
    numberOfVms: number;
    numberOfSuccessfulVms: number;
    lastProtectionStatus: ProtectionStatusEnum;
    numberOfJobsExecuted: number;
    totalExecutionTime: moment.Duration;

    constructor(json: DraasApiGroupProtectionSummary) {
        this.groupId = json.group_id;
        this.groupName = json.group_name;
        this.isConsistencyGroup = json.is_consistency_group;
        this.totalExecutionTime = moment.duration(json.total_execution_time);
        this.numberOfVms = json.number_of_vms;
        this.numberOfSuccessfulVms = json.number_of_successful_vms;
        this.lastProtectionStatus = ProtectionStatusEnum[json.last_protection_status];
        this.numberOfJobsExecuted = json.number_of_jobs_executed;
    }
}

export enum ProtectionStatusEnum {
    SUCCESS = 'SUCCESS',
    PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
    FAILED = 'FAILED',
}
