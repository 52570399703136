import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { ApiCallBatcher } from './api-call-batcher';
import { VolumeMetricAnnotation } from '../models/volume-metric-annotation';
import { ApiAnnotationCallBatcherInputs, IMetricsHistoryOptions } from './metrics-history.service';
import { ListParams } from '../interfaces/list-params';
import { VolumeMetricAnnotationService } from './volume-metric-annotation.service';
import { getUniqueKeysFromInputs } from './base-batch-metrics-history.service';
import { WINDOW } from '../../app/injection-tokens';

const BATCH_WAIT_TIME_MS = 100;

@Injectable({ providedIn: 'root' })
export class BatchVolumeAnnotationMetricsHistoryService {
    private volumeAnnotationBatcher = new ApiCallBatcher<ApiAnnotationCallBatcherInputs, VolumeMetricAnnotation[]>(
        BATCH_WAIT_TIME_MS,
        request => this.apiAnnotationBatcherVolumeGroupKey(request),
        requests => this.apiAnnotationBatcherVolumeExecute(requests),
        this.window,
    );

    constructor(
        private volumeMetricAnnotationService: VolumeMetricAnnotationService,
        @Inject(WINDOW) private window: Window,
    ) {}

    getAnnotationMetricsHistory(
        arrayId: string,
        volumeId: string,
        options: IMetricsHistoryOptions,
        annotationTypes: MetricAnnotationType[],
    ): Observable<VolumeMetricAnnotation[]> {
        if (!annotationTypes || annotationTypes.length === 0) {
            return of([]);
        }

        const apiAnnotationBatcherInput: ApiAnnotationCallBatcherInputs = {
            arrayId: arrayId,
            volumeId: arrayId + ':' + volumeId,
            startTime: options.startTime,
            endTime: options.endTime,
            maxPoints: options.maxPoints,
            types: annotationTypes || [],
        };

        return this.volumeAnnotationBatcher.enqueue(apiAnnotationBatcherInput).pipe(
            map((annotations: VolumeMetricAnnotation[]) => {
                return annotations.filter(annotation =>
                    annotation.volumes?.some(volume => volume.arrayId === arrayId && volume.volumeId === volumeId),
                );
            }),
        );
    }

    private apiAnnotationBatcherVolumeGroupKey(request: ApiAnnotationCallBatcherInputs): string {
        const keyValues = [
            request.arrayId,
            request.types,
            request.startTime.valueOf(),
            request.endTime.valueOf(),
            request.maxPoints,
        ];
        return keyValues.join('_');
    }

    private apiAnnotationBatcherVolumeExecute(
        requests: ApiAnnotationCallBatcherInputs[],
    ): Observable<VolumeMetricAnnotation[]> {
        const { types, startTime, endTime, maxPoints } = requests[0];
        const volumeIds = getUniqueKeysFromInputs<ApiAnnotationCallBatcherInputs, 'volumeId'>(requests, 'volumeId');
        const typesStr = types.join(',');
        const volumeStr = volumeIds.join(',');

        const params: ListParams<VolumeMetricAnnotation> = {
            extra:
                `volume_ids=${volumeStr}&types=${typesStr}` +
                `&start_time=${startTime.valueOf()}&end_time=${endTime.valueOf()}` +
                `&max_points=${maxPoints}`,
        };
        return this.volumeMetricAnnotationService.list(params).pipe(map(item => item.response));
    }
}
