<div class="step-up-modal-header">
    <pureui-svg class="header-icon" svg="step-up-locked.svg" height="50"></pureui-svg>
</div>
<div class="step-up-modal-body">
    <div class="title">{{ errorBody.title }}</div>
    <div class="text" [innerHTML]="errorBody.message"> </div>
</div>
<div class="step-up-modal-footer">
    <button
        *ngIf="!showSupportButton; else supportButton"
        class="btn btn-primary"
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Step Up - Error Modal - close modal"
        angularticsCategory="Action"
    >
        {{ errorBody.closeLabel }}
    </button>
    <ng-template #supportButton>
        <button
            class="btn btn-primary contact-support"
            (click)="goToSupport()"
            angulartics2On="click"
            angularticsAction="Step Up - Error Modal - contact support"
            angularticsCategory="Action"
        >
            Contact Pure Technical Services
        </button>
        <div class="wait-text">
            <a
                class="close-link"
                (click)="close()"
                angulartics2On="click"
                angularticsAction="Step Up - Error Modal - close modal"
                angularticsCategory="Action"
            >
                {{ errorBody.closeLabel }}
            </a>
        </div>
    </ng-template>
</div>
