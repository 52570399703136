import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { VolumeSpace } from '../models/VolumeSpace';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class VolumeSpaceService extends GenericService<VolumeSpace> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: VolumeSpace,
            endpoint: '/rest/v3/volumes/capacity-metrics',
            secondarySort: {
                key: 'array_id,core_id',
                order: 'asc',
            },
        });
    }
}
