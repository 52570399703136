import { Injectable } from '@angular/core';
import { DisasterRecoveryLicense } from '../models/disaster-recovery-license';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DraasApiLicense } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryLicensesService extends DisasterRecoveryBaseService<
    DisasterRecoveryLicense,
    DraasApiLicense
> {
    protected resourceClass = DisasterRecoveryLicense;
    protected pathParams = [];

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {
        super();
    }

    protected getEndpoint(): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/1.0/licenses`;
    }
}
