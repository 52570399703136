import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { ampli } from 'core/src/ampli';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'dpa-banner',
    templateUrl: 'dpa-banner.component.html',
})
export class DpaBannerComponent extends BaseBanner implements AfterViewInit {
    readonly ampli = ampli;
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('dpaLinksTemplate', { static: true }) readonly dpaLinksTemplate: TemplateRef<any>;
    @ViewChild('dpaButtonTemplate', { static: true }) readonly dpaButtonTemplate: TemplateRef<any>;

    ngAfterViewInit(): void {
        const templates = { linksTemplate: this.dpaLinksTemplate, buttonTemplate: this.dpaButtonTemplate };
        this.populatedNotification = Object.assign({}, this.notification, templates);
    }
}
