import { Injectable } from '@angular/core';
import { KeepHtml, ToastService, ToastType } from '../../services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class SupportErrorService {
    constructor(private toastService: ToastService) {}

    private showErrorToast(message: string): void {
        this.toastService.add(ToastType.error, new KeepHtml(message));
    }

    errorInterceptor = (error: HttpErrorResponse) => {
        if (error.status) {
            switch (error.status) {
                case 400:
                    this.showErrorToast('Invalid action or invalid or missing data.');
                    break;
                case 401:
                    this.showErrorToast('Session expired. Please log in again.');
                    break;
                case 403:
                    this.showErrorToast(
                        'You are not authorized to perform this action. Please email <a href="mailto:support@purestorage.com">support@purestorage.com</a> for assistance.',
                    );
                    break;
                case 404:
                    this.showErrorToast('Action is currently not available. Try again.');
                    break;
                case 429:
                    this.showErrorToast('You have created too many support cases in a given amount of time.');
                    break;
                case 500:
                    this.showErrorToast(
                        'Action generated an internal error. Please email <a href="mailto:support@purestorage.com">support@purestorage.com</a> for assistance.',
                    );
                    break;
                default:
                    if (error.status < 500 && error.status >= 400) {
                        this.showErrorToast('Client Error');
                    } else {
                        this.showErrorToast('Server Error');
                    }
            }
            return throwError(error);
        }
    };
}
