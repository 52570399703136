import { Resource } from '../interfaces/resource';
import { AdditionalRoleAssignment } from './additional-role';
import { Assignment } from './assignment';
import { RoleType } from './user';

export class Group extends Assignment implements Resource {
    id: string;
    name: string;

    /** @deprecated since its type ({@link RoleType}) is deprecated */
    role: RoleType;
    additionalRoles: AdditionalRoleAssignment[];

    constructor(group: any) {
        super(group);
        this.id = group.name;
        this.name = group.name;
        this.role = group.role;
        this.additionalRoles = (group.additionalRoles || [])
            .map(role => new AdditionalRoleAssignment(role))
            .sort(AdditionalRoleAssignment.compareByNameAlphabetically);
    }
}
