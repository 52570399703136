import { Resource } from '../interfaces/resource';

/** String to use to separate components of the uniqueId */
const UNIQUE_ID_DELIMITER = '::';

export class VSphereFolder implements Resource {
    readonly id: string;
    readonly name: string;

    constructor(json: { id: string; name: string }) {
        this.id = json.id;
        this.name = json.name;
    }
}

export class VCenter implements Resource {
    readonly id: string;
    readonly name: string;
    readonly datacenters: Datacenter[];
    readonly folder?: string; // NOTE: Folder isn't available yet for vcenters
    readonly uniqueId: string;

    constructor(json: any) {
        this.id = json.id;
        this.uniqueId = this.id; // The uniqueId is the same as the id since the vcenter id itself is already unique
        this.name = json.name;
        this.folder = json.folder;

        this.datacenters = (json.datacenters || []).map(dc => new Datacenter(dc, this.uniqueId));
    }
}

export class Datacenter implements Resource {
    /** Unique datacenter id within the VCenter, but NOT a guid! */
    readonly id: string;
    readonly name: string;
    readonly clusters: Cluster[];
    readonly folder?: string;
    readonly uniqueId: string;

    constructor(json: any, vcenterUniqueId: string) {
        this.id = json.id;
        this.uniqueId = vcenterUniqueId + UNIQUE_ID_DELIMITER + this.id;
        this.name = json.name;
        this.folder = json.folder;

        this.clusters = (json.clusters || []).map(cl => new Cluster(cl, this.uniqueId));
    }
}

export class Cluster implements Resource {
    /** Unique cluster id within the Datacenter, but NOT a guid! */
    readonly id: string;
    readonly name: string;
    readonly folder?: string;
    readonly uniqueId: string;
    readonly uuid: string;

    constructor(json: any, datacenterUniqueId: string) {
        this.id = json.id;
        this.uniqueId = datacenterUniqueId + UNIQUE_ID_DELIMITER + this.id;
        this.uuid = json.uuid;
        this.name = json.name;
        this.folder = json.folder;
    }
}
