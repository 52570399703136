import { Pipe, PipeTransform } from '@angular/core';
import { PGroupSafemode } from '@pure1/data';

@Pipe({
    name: 'getPgroupName',
    pure: true,
})
export class GetPgroupNamePipe implements PipeTransform {
    transform(pgroup: PGroupSafemode): string {
        let pgroupName = '';
        pgroupName += `${pgroup.arrays.map(value => value.name).toString()} / `;
        const array = pgroup.name.split('::');
        let pgroupNamePosition = 0;
        if (array.length > 1) {
            pgroupName += `${array[0]} / `;
            pgroupNamePosition = 1;
        }
        pgroupName += `${array[pgroupNamePosition]}`;
        return pgroupName;
    }
}
