import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { AllowListedAccount } from '../models/allow-listed-account';

@Injectable({ providedIn: 'root' })
export class AllowListAccountService extends GenericService<AllowListedAccount> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: AllowListedAccount,
            endpoint: '/rest/v3/subscription-licenses/cloud_accounts',
            create: true,
            delete: true,
        });
    }
}
