<div class="feedback-form-wrapper" *ngIf="survey" @inOutAnimation>
    <div class="feedback-form-header">
        <pureui-svg
            (click)="closeForm()"
            class="pstg-action-icon close-icon"
            svg="x-action.svg"
            alt="close button"
            height="12"
            role="button"
            tabindex="0"
            aria-label="close"
            angulartics2On="click"
            angularticsAction="NPS - Close Survey Form"
        ></pureui-svg>
    </div>

    <div class="feedback-form-content" *ngIf="!submitCompleted; else submissionAck">
        <!-- score question -->
        <div class="question-1">
            <div class="question-1-text">
                {{ survey.questionOne }}
            </div>
            <div class="question-1-response" [class.has-selection]="selectedScore != null">
                <button
                    *ngFor="let i of scores"
                    [ngClass]="['score-button', 'score-' + i]"
                    [class.selected]="selectedScore != null && i === selectedScore"
                    (click)="onSelectScore(i)"
                    >{{ i }}</button
                >
            </div>
            <div class="question-1-footer">
                <span>0 = {{ survey.questionOneLowExplanation }}</span>
                <span>10 = {{ survey.questionOneHighExplanation }}</span>
            </div>
        </div>

        <!-- text question -->
        <div class="question-2" *ngIf="selectedScore != null">
            <ng-container *ngIf="selectedScore < 9; else highScoreQuestion">
                <div class="question-2-text">
                    {{ survey.questionTwoLow }}
                </div>
            </ng-container>
            <ng-template #highScoreQuestion>
                <div class="question-2-text">
                    {{ survey.questionTwoHigh }}
                </div>
            </ng-template>
            <textarea class="form-control" [(ngModel)]="textFeedback" rows="8" maxlength="4000"></textarea>
            <span class="character-counter">{{ textFeedback == null ? 0 : textFeedback.length }}/4000 characters</span>
        </div>

        <!-- submit button -->
        <div class="submit-button-container" *ngIf="selectedScore != null">
            <button class="btn btn-primary" (click)="submitFeedback()" [disabled]="submitStarted" pure-read-only>
                {{ submitStarted ? 'Submitting' : 'Submit' }}
            </button>
        </div>
    </div>

    <ng-template #submissionAck>
        <ng-container *ngIf="survey.externalSurveyUrl; else noExternalSurvey">
            <div class="after-submission">
                <img src="/images/puzzle.gif" alt="putting together puzzles" class="animation-canvas" />
                <div class="thank-you-msg">
                    <h3>Thank you for your feedback!</h3>
                    <p
                        >Want to share more feedback with us? Click "Yes, I'm interested" below to take our 5-minute
                        external survey.</p
                    >
                </div>
                <div class="button-container-with-external">
                    <button class="btn btn-light" (click)="closeForm()">No, thanks</button>
                    <a
                        class="btn btn-primary"
                        href="{{ survey.externalSurveyUrl }}"
                        target="_blank"
                        (click)="closeForm()"
                        angulartics2On="click"
                        angularticsAction="NPS - External Survey Click"
                        [angularticsLabel]="survey.surveyId"
                        >Yes, I'm interested</a
                    >
                </div>
            </div>
        </ng-container>
        <ng-template #noExternalSurvey>
            <div class="after-submission">
                <img src="/images/letter.gif" alt="letter sent out" class="animation-canvas" />
                <div class="thank-you-msg">
                    <h3>Thank you for your feedback!</h3>
                </div>
                <div class="button-container-no-external">
                    <button class="btn btn-primary" (click)="closeForm()">Close</button>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
