import { Subject } from 'rxjs';
import { Component, Input, OnChanges, OnInit, OnDestroy, Inject, SimpleChanges } from '@angular/core';
import { CachedCurrentUserService, Organization, OrganizationService } from '@pure1/data';

import { addFilter, DEFAULT_NAMESPACE, removeFilters, ORG_ID_KEY } from '../../../redux/actions';
import { IStateFilter, IState } from '../../../redux/pure-redux.service';
import { createFilter, getFilterIdsByKey } from '../../../redux/utils';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { NgRedux } from '../../../redux/ng-redux.service';
import { Action } from 'redux';

/**
 * Displays a list of Views. Keys off of redux filter state for the given barId, so the only input is the barId.
 * Get/change the value through Redux.
 */
@Component({
    selector: 'org-filter-selector',
    templateUrl: 'org-filter-selector.component.html',
})
export class OrgFilterSelectorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly barId: string;

    orgList: Organization[] = null;
    selectedOrg: Organization = null;
    userOrgId: number = null;

    private unsubscribeFromRedux: Function;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private ngRedux: NgRedux<IState>,
        private organizationService: OrganizationService,
        private cachedCurrentUserService: CachedCurrentUserService,
    ) {}

    ngOnInit(): void {
        this.unsubscribeFromRedux = this.ngRedux.subscribe(() => this.handleRedux());
        this.cachedCurrentUserService
            .get()
            .pipe(
                switchMap(user => {
                    this.userOrgId = user.organization_id;
                    return this.organizationService.list();
                }),
                take(1),
                takeUntil(this.destroy$),
            )
            .subscribe(dataPage => {
                if (dataPage) {
                    this.orgList = dataPage.response;
                    this.selectedOrgChanged(this.orgList.find(org => org.id === this.userOrgId.toString()));
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.barId) {
            this.handleRedux();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.unsubscribeFromRedux?.();
    }

    selectedOrgChanged(newOrg: Organization): void {
        if (this.selectedOrg === newOrg) {
            return; // No change
        }

        this.selectedOrg = newOrg;

        // Update redux filter
        const barFilters = this.ngRedux.getState().filters[this.barId];
        const viewFilterIds = getFilterIdsByKey(barFilters, null, ORG_ID_KEY, DEFAULT_NAMESPACE);
        const actions: Action[] = [];
        if (viewFilterIds.length > 0) {
            // Remove old value
            actions.push(removeFilters(this.barId, viewFilterIds));
        }
        if (newOrg) {
            // Add new value (if there is one)
            actions.push(
                addFilter(this.barId, createFilter(null, DEFAULT_NAMESPACE, ORG_ID_KEY, newOrg.id.toString())),
            );
        }
        this.ngRedux.dispatch(actions);
    }

    private handleRedux(): void {
        this.updateSelectedOrg();
    }

    private updateSelectedOrg(): void {
        const filters: IStateFilter[] = this.ngRedux.getState().filters[this.barId] || [];
        const viewFilter = filters.find(sf => sf.key === ORG_ID_KEY);
        this.selectedOrg = this.orgList?.find(org => org.id.toString() === viewFilter?.value) || null;
    }
}
