export interface SeverityData {
    svg: string;
    class: string;
}

export const SEVERITY_DATA: { [severity: string]: SeverityData } = Object.freeze({
    none: { svg: 'alert_info.svg', class: 'pstg-alert-none-svg-color' },
    info: { svg: 'alert_info.svg', class: 'pstg-alert-info-svg-color' },
    warning: { svg: 'alert_warning.svg', class: 'pstg-alert-warning-icon pstg-alert-warning-svg-color' },
    critical: { svg: 'alert_critical.svg', class: 'pstg-alert-critical-svg-color' },
    eol: { svg: 'eol-icon-small.svg', class: 'pstg-eol-icon' },
});
