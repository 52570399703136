import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact, CurrentUser, Invoice } from '@pure1/data';
import {
    CaseCategory,
    CaseSeverity,
    CaseSubCategory,
    ProductLine,
    SupportCase,
} from '../../../../support/support.interface';
import { getSubCategoryOption } from '../../../../support/support.utils';
import { Validators, FormBuilder } from '@angular/forms';
import { SupportCaseService } from '../../../../support/services/support-create-edit.service';
import moment from 'moment';
import { CachedCurrentUserService } from '../../../../data/services/cached-current-user.service';
import { take } from 'rxjs';
import { ampli } from 'core/src/ampli';

const EVERGREEN_USAGE_BILLING_OPTION = getSubCategoryOption(ProductLine.None, CaseSubCategory.USAGE_AND_BILLING);

const REASONS = ['Rate', 'Quantity', 'Amount', 'Credit', 'SKU', 'PO#', 'Shipping Address', 'Billing Address', 'Other'];

@Component({
    selector: 'dispute-invoice-modal',
    templateUrl: './dispute-invoice-modal.component.html',
})
export class DisputeInvoiceModalComponent {
    @Input() readonly invoice: Invoice;
    @Input() readonly modal: NgbActiveModal;

    @ViewChild('confirmModal') readonly confirmModal: TemplateRef<any>;

    readonly ampli = ampli;

    disputeInvoiceForm = this.formBuilder.group({
        description: ['', Validators.required],
        contacts: {
            primary: null,
            alternate: null,
        },
    });
    categoryDisplayName = EVERGREEN_USAGE_BILLING_OPTION.group + ' - ' + EVERGREEN_USAGE_BILLING_OPTION.displayName;
    selectedReasons: string[];
    possibleReasons = REASONS;
    currentUser: CurrentUser;
    caseId: string;
    createCaseDoneFlag = false;
    createCaseErrorFlag = false;

    constructor(
        private formBuilder: FormBuilder,
        private supportCaseService: SupportCaseService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private ngbModal: NgbModal,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(user => {
                this.currentUser = user;
            });
    }

    isDisputeButtonDisabled(): boolean {
        return !this.disputeInvoiceForm.valid || this.currentUser.readOnly;
    }

    submitDisputeInvoice(): void {
        if (this.disputeInvoiceForm.invalid) {
            return;
        }
        this.modal.close();

        let reasonsSubject: string;
        if (this.selectedReasons.length === 1) {
            reasonsSubject = ` - ${this.selectedReasons[0]}`;
        } else if (this.selectedReasons.length > 1) {
            reasonsSubject = ` - ${this.selectedReasons[0]} and ${this.selectedReasons.length - 1} more`;
        }
        const subject = this.invoice.id + reasonsSubject;

        const reasonDescription = this.selectedReasons?.length > 0 ? `Reasons: ${this.selectedReasons.join(', ')}` : '';
        const description = reasonDescription + '\n' + this.disputeInvoiceForm.value.description;

        let severity: CaseSeverity;
        if (moment().isAfter(this.invoice.dueDate)) {
            severity = CaseSeverity.MAJOR;
        } else {
            severity = CaseSeverity.MINOR;
        }

        const supportCase: Partial<SupportCase> = {
            description,
            severity,
            category: CaseCategory.EVERGREENONEADMINISTRATION,
            subCategory: CaseSubCategory.USAGE_AND_BILLING,
            subject,
            contact: this.disputeInvoiceForm.value.contacts.primary,
            alternateContact: this.disputeInvoiceForm.value.contacts.alternate,
        };

        this.supportCaseService.createSubmitCase(supportCase).subscribe({
            next: response => {
                this.createCaseDoneFlag = true;
                this.caseId = response.caseNumber;
                this.ampli.invoiceManagementDisputeInvoice({ 'submit status': 'success' });
            },
            error: () => {
                this.createCaseDoneFlag = true;
                this.createCaseErrorFlag = true;
                this.ampli.invoiceManagementDisputeInvoice({ 'submit status': 'failed' });
            },
        });

        this.ngbModal.open(this.confirmModal);
    }
}
