import { DraasApiOvaUrl } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryOvaUrl {
    url: string;
    expiration: Date;

    constructor(json: DraasApiOvaUrl) {
        this.url = json.url;
        this.expiration = new Date(json.expiration);
    }
}
