import { ValidationErrors, UntypedFormControl } from '@angular/forms';
import { MultiResourceTag } from '../multi-resource-tag';

export const valueRequiredValidator = (control: UntypedFormControl): ValidationErrors | null => {
    if (control.parent) {
        const mrt = control.parent.value as MultiResourceTag;
        if (control.value === '' && !mrt.originMultiple) {
            return { required: true };
        }
    }
    return null;
};
