import moment from 'moment';

export type PxLicenseType = 'trial' | 'enterprise' | 'backup';

export class PxLicense {
    licenseType: PxLicenseType;
    expiration: moment.Moment;

    constructor(json: any) {
        this.licenseType = json.type;
        this.expiration = moment(json.exp);
    }
}
