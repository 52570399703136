import moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    BulkSchedule,
    Case,
    CaseCategory,
    CaseComposite,
    CaseSubCategory,
    Contact,
    FreeTime,
    FreeTimeType,
    ProductLine,
} from '../../support.interface';
import { Schedule } from '../types';
import { CaseManager } from '../../services/case-data.service';
import { CaseService } from '../../services/case.service';
import { Cacheable } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class SupportUpgradeService {
    private static cacheTTL = 300000; // 300 seconds

    readonly ORIGIN = 'web';
    readonly CATEGORY = CaseCategory.UPGRADE;
    readonly SUB_CATEGORY = CaseSubCategory.UPGRADE_SOFTWARE_UPGRADE;
    readonly PATH_PREFIX = '/rest/v1/support';

    constructor(
        private http: HttpClient,
        private caseManager: CaseManager,
        private caseService: CaseService,
    ) {}

    /**
     * Returns time windows between startTime and endTime where there are enough
     * TSEs to perform an upgrade.
     *
     * startTime and endTime *must* have a timezone attached.
     */
    @Cacheable({
        maxAge: SupportUpgradeService.cacheTTL,
        maxCacheCount: 4,
    })
    getFreeTimes(startTime: moment.Moment, endTime: moment.Moment, productLine: ProductLine): Observable<FreeTime[]> {
        return (
            this.http
                .get<{ records: { startTime: number; endTime: number; capacity: number }[] }>(
                    `${this.PATH_PREFIX}/schedulertimeslots`,
                    {
                        params: {
                            startTime: startTime.valueOf(),
                            endTime: endTime.valueOf(),
                            timeSlotType: FreeTimeType.UPGRADE,
                            productLine,
                        },
                    },
                )
                // Response -> FreeTime[]
                .pipe(
                    map(response =>
                        response.records.map(item => ({
                            start: moment.tz(item.startTime, 'UTC'),
                            end: moment.tz(item.endTime, 'UTC'),
                            capacity: item.capacity || 1,
                        })),
                    ),
                )
        );
    }

    getUpgradeVersions(arrayId: string): Observable<string[]> {
        return this.http
            .get<{ items: string[] }>(`${this.PATH_PREFIX}/upgradeversions`, {
                params: { arrayId: arrayId },
            })
            .pipe(
                catchError(() => of({ items: [] })),
                map(resp => resp.items),
            );
    }

    createUpgradeCase(
        arrayId: string,
        userId: string,
        email: string,
        startTime: number,
        endTime: number,
        numOfHops: number,
        targetPurityVersion: string,
        description: string,
        timeZone: string,
        productLine: ProductLine,
    ): Observable<CaseComposite> {
        const contact: Contact = {
            id: userId,
            email: email,
        };
        const newCase: Partial<Case> = {
            caseNumber: undefined,
            origin: this.ORIGIN,
            arrayId: arrayId,
            productLine,
            category: this.CATEGORY,
            subCategory: this.SUB_CATEGORY,
            nextActionDueDate: startTime,
            upgradeEndDateTime: endTime,
            numOfHops: numOfHops,
            targetPurityVersion: targetPurityVersion,
            isUpgrade: true,
            contact: contact,
            description: description,
            maintenanceExecutionTimezoneLocal: timeZone,
        };

        return this.http
            .post<CaseComposite>(`${this.PATH_PREFIX}/cases`, newCase)
            .pipe(tap((newCaseComposite: CaseComposite): void => this.addCaseCompositeToCaseManager(newCaseComposite)));
    }

    // this creates one parent case and a bunch of child cases for everything from the schedule
    createBulkUpgradeSchedule(schedule: Schedule, userId: string, description: string): Observable<CaseComposite> {
        const contact: Contact = {
            id: userId,
        };

        const scheduleItems = schedule.schedule.map(versionedArray => {
            return {
                arrayId: versionedArray.array.applianceId,
                productLine: versionedArray.productLine,
                nextActionDueDate: versionedArray.timeslot ? versionedArray.timeslot.startTime.valueOf() : null,
                upgradeEndDateTime: versionedArray.timeslot
                    ? versionedArray.timeslot.startTime.clone().add(versionedArray.timeslot.duration).valueOf()
                    : null,
                targetPurityVersion: versionedArray.targetVersion,
                maintenanceExecutionTimezoneLocal: schedule.timezone,
                numOfHops: versionedArray.numberOfHops,
            };
        });

        const newUpgradeSchedule: BulkSchedule = {
            origin: this.ORIGIN,
            category: this.CATEGORY,
            subCategory: this.SUB_CATEGORY,
            timeFrameType: FreeTimeType.UPGRADE,
            description: description,
            contact: contact,
            schedule: scheduleItems,
            nextActionDueDate: schedule.fromDate ? schedule.fromDate.valueOf() : null,
            upgradeEndDateTime: schedule.toDate ? schedule.toDate.valueOf() : null,
            maintenanceExecutionTimezoneLocal: schedule.timezone,
        };
        return this.http
            .post<CaseComposite>(`${this.PATH_PREFIX}/bulkschedule`, newUpgradeSchedule)
            .pipe(tap((newCaseComposite: CaseComposite): void => this.addCaseCompositeToCaseManager(newCaseComposite)));
    }

    private addCaseCompositeToCaseManager(newCaseComposite: CaseComposite): void {
        const newCase = this.caseService.caseCompositeHelper(newCaseComposite);
        this.caseManager.addCase(newCase);
    }

    getExistingUpgradeCases(arrayId: string): Observable<string> {
        return this.getExistingUpgradeParentCase(arrayId).pipe(map(supportCase => supportCase?.caseNumber || ''));
    }

    getExistingUpgradeParentCase(arrayId: string): Observable<Case> {
        return this.http.get<Case[]>(`${this.PATH_PREFIX}/cases/existing-upgrades`).pipe(
            map((cases: Case[]) => {
                const found = cases.find(upgradeCase => upgradeCase.arrayId === arrayId);
                if (found) {
                    if (found.parentId) {
                        const foundParent = cases.find(upgradeCase => upgradeCase.id === found.parentId);
                        if (foundParent) {
                            return foundParent;
                        }
                    }
                    return found;
                } else {
                    return null;
                }
            }),
        );
    }

    getExistingUpgradeParentCases(arrayIds: string[]): Observable<{ [key: string]: Case }> {
        return this.http.get<Case[]>(`${this.PATH_PREFIX}/cases/existing-upgrades`).pipe(
            map(cases => {
                return arrayIds.reduce((prevValue, currentValue) => {
                    const found = cases.find(upgradeCase => upgradeCase.arrayId === currentValue);
                    let parent = null;
                    if (found) {
                        if (found.parentId) {
                            const foundParent = cases.find(upgradeCase => upgradeCase.id === found.parentId);
                            if (foundParent) {
                                parent = foundParent;
                            }
                        }
                        parent = found;
                    }

                    return {
                        ...prevValue,
                        [currentValue]: parent,
                    };
                }, {});
            }),
        );
    }

    getUpgradeHops(arrayId: string, targetVersion: string, currentVersion: string): Observable<number> {
        return this.http
            .get<{ items: string[] }>(`${this.PATH_PREFIX}/upgradepath`, {
                params: {
                    arrayId: arrayId,
                    targetVersion: targetVersion,
                    currentVersion: currentVersion,
                },
            })
            .pipe(
                catchError(() => of({ items: [] })),
                map(resp => Math.max(resp.items.length - 1, 0)),
            );
    }
}
