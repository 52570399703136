import { ValidationErrors, UntypedFormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export const uniqueKeyValidator = (formArray: UntypedFormArray): ValidationErrors | null => {
    let validationError: ValidationErrors = null;

    const keyTagIndexes = new Map<string, number[]>();
    formArray.controls.forEach((fg: UntypedFormGroup, i: number) => {
        const keyLc = fg.value.key.toLowerCase();
        if (keyLc !== '') {
            if (keyTagIndexes.has(keyLc)) {
                keyTagIndexes.get(keyLc).push(i);
            } else {
                keyTagIndexes.set(keyLc, [i]);
            }
        }
    });
    keyTagIndexes.forEach((indexes: number[]) => {
        if (indexes.length > 1) {
            indexes.forEach(i => {
                ((formArray.controls[i] as UntypedFormGroup).controls.key as UntypedFormControl).setErrors({
                    uniqueKey: {},
                });
                ((formArray.controls[i] as UntypedFormGroup).controls.key as UntypedFormControl).markAsDirty();
            });
            validationError = { uniqueKey: {} };
        } else {
            const formGroup = formArray.controls[indexes[0]] as UntypedFormGroup;
            if (formGroup.controls.key.hasError('uniqueKey')) {
                // Unfortunately, there is no Control.removeError() method. The best we can do is to call
                // Control.updateValueAndValidity() that will also call (again) all the validators on the control
                // and we cannot let it bubble up the tree because this would trigger this validator again and
                // lead us to an infinite loop. So we update manually the path between the control and this
                // formArrayControl
                formGroup.controls.key.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            }
        }
    });
    return validationError;
};
