import {
    ChangeDetectorRef,
    Directive,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { CachedCurrentUserService, CurrentUser } from '@pure1/data';

/**
 * Super-small directive that pre-fills a Google Form with the current user's name and email address.
 * Current user is fetched from the `CachedCurrentUserService`.
 * End user have to specify 3 inputs:
 * - `prefillGoogleForm` - the URL of the Google Form to prefill
 * - `contactEmailField` - should be found from the Google Form via html inspection and should match the pattern `entry.<number>`
 * - `contactNameField` - should be found from the Google Form via html inspection and should match the pattern `entry.<number>`
 * This directive is standalone and does not require any other components to be present in the template.
 * Also, it is expected that this directive is final and does not have to be extended.
 *
 * @example
 * ```html
 *  <a
 *      prefillGoogleForm="https://docs.google.com/forms/d/e/my-form-id/viewform"
 *      contactEmailField="entry.000000"
 *      contactNameField="entry.000000"
 *  >
 *      Open Google Form
 *  </a>
 * ```
 */
@Directive({
    selector: '[prefillGoogleForm]',
    standalone: true,
})
export class PrefillGoogleFormDirective implements OnChanges, OnInit, OnDestroy {
    /**
     * The URL of the Google Form to prefill.
     */
    @Input({ required: true }) prefillGoogleForm!: string;
    /**
     * The name of the field in the Google Form that corresponds to the contact's email address.
     * Usually this is a hidden field that matches the pattern `entry.<number>`.
     */
    @Input({ required: true }) contactEmailField!: string;
    /**
     * The name of the field in the Google Form that corresponds to the contact's name.
     * Usually this is a hidden field that matches the pattern `entry.<number>`.
     */
    @Input({ required: true }) contactNameField!: string;

    @HostBinding('href')
    protected prefillGoogleFormLink?: string;

    private readonly currentUser$: Observable<CurrentUser> = this.cachedCurrentUserService.get();
    private readonly contactEmailField$: BehaviorSubject<string> = new BehaviorSubject(this.contactEmailField);
    private readonly contactNameField$: BehaviorSubject<string> = new BehaviorSubject(this.contactNameField);
    private readonly googleFormLink$: BehaviorSubject<string> = new BehaviorSubject(this.prefillGoogleForm);
    private readonly prefillGoogleFormLink$: Observable<string> = combineLatest({
        currentUser: this.currentUser$,
        contactEmailField: this.contactEmailField$,
        contactNameField: this.contactNameField$,
        googleFormLink: this.googleFormLink$,
    }).pipe(
        map(({ currentUser, contactEmailField, contactNameField, googleFormLink }): string => {
            const params = new HttpParams({
                fromObject: {
                    [contactNameField]: currentUser.name,
                    [contactEmailField]: currentUser.email,
                },
            });
            return `${googleFormLink}?${params.toString()}`;
        }),
        distinctUntilChanged(),
    );
    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly cachedCurrentUserService: CachedCurrentUserService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.prefillGoogleFormLink$.pipe(takeUntil(this.destroy$)).subscribe(prefillGoogleFormLink => {
            this.prefillGoogleFormLink = prefillGoogleFormLink;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['contactEmailField']?.currentValue) {
            this.contactEmailField$.next(changes['contactEmailField']?.currentValue);
        }
        if (changes['contactNameField']?.currentValue) {
            this.contactNameField$.next(changes['contactNameField']?.currentValue);
        }
        if (changes['prefillGoogleForm']?.currentValue) {
            this.googleFormLink$.next(changes['prefillGoogleForm']?.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
