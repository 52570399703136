import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { HistoricalPod } from '../models/pod-history';

@Injectable({ providedIn: 'root' })
export class PodHistoryService extends GenericService<HistoricalPod> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: HistoricalPod,
            endpoint: '/rest/v3/pods/history',
        });
    }
}
