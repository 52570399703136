import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Directive, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
    IGetMetricsHistoryResult,
    MetricsHistoryMetricNames,
    MetricsHistoryResult,
    ApiCallBatcherInputs,
    IAggregationMethod,
    IMetricsHistoryOptions,
    MetricsEndpoint,
} from './metrics-history.service';
import { TimeSeriesCache, ITimeSeriesCacheKey } from '../utils/time-series-cache';
import { ApiCallBatcher } from './api-call-batcher';

/** Don't serve cache items older than this */
const TIMESERIES_VALUES_CACHE_DURATION = moment.duration(3, 'minutes');

/**
 * How long to have each ApiCallBatcher wait. Longer waits allow more to be batched together, but also introduce
 * longer delays before the requests are made.
 * In the case of the Performance page, the delays between the api calls are caused by the api calls being made
 * by each individual PerformanceChart, and there is a PerformanceChart per metric type (eg IOPs, Bandwidth, Latency).
 *
 * Note: Normally, this is avoided by having the parent of the chart component making the api calls to fetch data, then pass
 * it to the chart to simply render, but that is not how PerformanceChart was designed and it'll be a fair bit of work to change.
 * Other code, like VM Analytics (through VMTimelineChartListComponent) handle this much more cleanly.
 */
const BATCH_WAIT_TIME_MS = 100;

export type MetricsRequestParams = { [key: string]: string };

@Directive()
export abstract class BaseMetricsHistoryService implements OnDestroy {
    protected timeseriesValuesCache: TimeSeriesCache<IGetMetricsHistoryResult>;
    protected apiBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherGroupKey(request),
        requests => this.apiBatcherExecute(requests),
        this.window,
    );

    // we can remove this once all metrics history calls are migrated to the new endpoint
    protected abstract apiEndpoint: MetricsEndpoint;

    constructor(
        protected http: HttpClient,
        private window: Window,
    ) {
        this.timeseriesValuesCache = new TimeSeriesCache<IGetMetricsHistoryResult>(TIMESERIES_VALUES_CACHE_DURATION);
    }

    ngOnDestroy(): void {
        this.timeseriesValuesCache.destroy();
    }

    getMetricsHistoryTimeseries(id: string, options: IMetricsHistoryOptions): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            id,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.apiBatcher.enqueue(apiBatcherInput);
    }

    private createCacheKey(
        metricNames: MetricsHistoryMetricNames[],
        id: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregationMethod: IAggregationMethod,
    ): ITimeSeriesCacheKey {
        return {
            metricNames: metricNames,
            entityId: id,
            startTime: startTime,
            endTime: endTime,
            aggregationMethod: aggregationMethod,
        };
    }

    private apiBatcherGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [request.id, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    // override this to specify a different key for the id
    protected addIdToParams(id: string, requestParams: MetricsRequestParams): void {
        requestParams.id = id;
    }

    protected apiBatcherExecute(requests: ApiCallBatcherInputs[]): Observable<IGetMetricsHistoryResult> {
        const { id, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();

        const params = {
            metrics: uniqueMetricList.join(','),
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };
        this.addIdToParams(id, params);

        const cacheKey = this.createCacheKey(uniqueMetricList, id, startTime, endTime, aggregationMethod);

        return this.fetchTimeseriesData(this.apiEndpoint, cacheKey, params);
    }

    /**
     * Fetches the timeseries data for endspoints that use the standard MetricsHistoryResult format.
     */
    private fetchTimeseriesData(
        endpoint: MetricsEndpoint,
        cacheKey: ITimeSeriesCacheKey,
        requestParams: MetricsRequestParams,
    ): Observable<IGetMetricsHistoryResult> {
        const cacheValue = this.timeseriesValuesCache.getValue(cacheKey);
        if (cacheValue) {
            return of(cacheValue);
        }

        return this.http.get<MetricsHistoryResult>(endpoint, { params: requestParams }).pipe(
            map(response => {
                return <IGetMetricsHistoryResult>{
                    values: response,
                };
            }),
            tap(result => {
                this.timeseriesValuesCache.put(cacheKey, result); // Add it to the cache for next time
            }),
        );
    }
}
