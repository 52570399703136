<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Sign Up for Access </h3>
</div>

<div class="support-only-modal-body modal-body clearfix">
    <div class="pull-left">
        <img width="200" height="160" src="/images/pure1_ipad_with_iphone.png" />
    </div>
    <div class="pull-right col-sm-7 col-xs-12">
        {{ message }}
        <a href="https://www.purestorage.com/products/pure-1.html">Learn more about Pure1 here.</a>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-primary" (click)="activeModal.close()"> Ok, got it. </button>
</div>
