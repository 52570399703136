import { formatNumber } from './formatNumber';

export function formatLatency(value: number, decimal: number, axisLabel?: boolean): IValueUnit {
    if (typeof value !== 'number' || isNaN(value)) {
        return { value: '-', unit: '' };
    } else {
        if (value === 0) {
            return axisLabel === true ? { value: '', unit: '' } : { value: '0', unit: 'ms' };
        } else {
            if (value < 1e6) {
                return {
                    // Drop one digit after the decimal point when latency exceeds 100 ms
                    value: formatNumber(value / 1000, value < 1e5 ? decimal : decimal - 1),
                    unit: 'ms',
                };
            } else {
                // if over 1000 ms, show '>1k', '>2k', etc, up to '>99k'
                if (axisLabel === true) {
                    return {
                        value: formatNumber(value / 1e6, decimal),
                        unit: 's',
                    };
                } else {
                    return {
                        value: '>' + Math.min(99, Math.floor(value / 1e6)) + 'k',
                        unit: 'ms',
                    };
                }
            }
        }
    }
}
