<div class="modal-header">
    <button
        type="button"
        class="close modal-x-button pull-right"
        aria-label="Close"
        (click)="activeModal.dismiss()"
        angulartics2On="click"
        angularticsAction="Tagging - Dismiss edit modal"
        angularticsCategory="Action"
    >
        <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
    </button>
    <h3 *ngIf="arrays.length === 1; else multiArrays" class="modal-title">Manage Tags &mdash; {{ arrays[0].name }} </h3>
    <ng-template #multiArrays>
        <h3 class="modal-title">Manage Tags</h3>
    </ng-template>
</div>
<form [formGroup]="tagsForm" (ngSubmit)="save()">
    <div class="modal-body" [pureshadowscroll]="{ scrollElem: '.tag-list-wrapper', shadowElem: '#shadow-elem' }">
        <div class="array-names" *ngIf="arrays.length > 1">
            <span class="array-names-title">Selected:</span> {{ displayArrays }}
        </div>

        <!-- this is only used to "anchor" the scroll shadow -->
        <div style="position: relative" id="shadow-elem"></div>

        <div class="tag-list-wrapper" formArrayName="tags" #listWrapper>
            <div class="tl-header">
                <div class="tl-header-title">Key</div>
                <div class="tl-header-title">Value</div>
            </div>
            <div *ngFor="let tagControl of tagsFormArray.controls; index as i" class="tag-control">
                <div [ngClass]="activeIndex === i ? 'edit-row' : 'read-row'" class="tag-list-item" [@deleteTag]="'in'">
                    <div class="key-value-wrapper form-inline" [formGroupName]="i">
                        <input
                            class="form-control edit-key"
                            placeholder="Key"
                            formControlName="key"
                            (focus)="edit(i)"
                            (focusout)="trim(i, 'key')"
                            [pureAutocomplete]="autocompleteKeysOptions"
                            (autocompleteSelect)="onAutocompleteKeySelect($any(tagControl), $event)"
                        />

                        <input
                            class="form-control edit-value"
                            (focus)="edit(i)"
                            (focusout)="trim(i, 'value')"
                            [ngClass]="{ multiple: tagControl.value.originMultiple }"
                            [placeholder]="tagControl.value.originMultiple ? 'Multiple Values' : 'Value'"
                            formControlName="value"
                            [pureAutocomplete]="autocompleteValuesOptions"
                            (autocompleteSelect)="onAutocompleteValueSelect($any(tagControl), $event)"
                        />
                    </div>
                    <button type="button" class="action-icons" (click)="delete(i)">
                        <pureui-svg class="pstg-action-icon" svg="trash_erase.svg" [height]="16"></pureui-svg>
                    </button>
                </div>
                <div
                    class="error-messages clearfix"
                    *ngIf="tagControl.invalid && (tagControl.dirty || tagControl.touched)"
                    [innerHTML]="getErrorMsg($any(tagControl), activeIndex === i)"
                ></div>
            </div>
        </div>
        <button
            type="button"
            class="btn btn-primary add-tag-button"
            (click)="newTag()"
            [disabled]="isAddButtonDisabled()"
        >
            <pureui-svg class="pstg-action-icon" svg="create_add.svg" [height]="10"></pureui-svg>
            <span>Add</span>
        </button>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-info"
            (click)="activeModal.dismiss()"
            angulartics2On="click"
            angularticsAction="Tagging - Dismiss edit modal"
            angularticsCategory="Action"
            >Cancel</button
        >
        <button
            type="submit"
            class="btn btn-primary"
            [disabled]="('PURE1:write:tags' | isNotAllowed) || tagsForm.invalid"
            (keydown)="tabCircleBack($event)"
            angulartics2On="click"
            angularticsAction="Tagging - Apply tag changes"
            angularticsCategory="Action"
            >Apply</button
        >
    </div>
</form>
