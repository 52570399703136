import moment from 'moment';
import { Inject, Injectable, NgZone } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { WINDOW } from '../app/injection-tokens';

export const REDUX_LOCAL_STORAGE_KEY = 'redux_state';

@Injectable({ providedIn: 'root' })
export class ReduxMiddleWares {
    constructor(
        private storageService: StorageService,
        @Inject(WINDOW) private window: Window,
        private zone: NgZone,
    ) {}

    consoleLogger =
        ({ dispatch, getState }: any) =>
        (next: any) =>
        (action: any): void => {
            const prevState = getState();
            const result = next(action);
            const nextState = getState();

            console.group('Dispatch', moment().format());
            console.log('Previous state', prevState);
            console.log('Action', action);
            console.log('Next state', nextState);
            console.groupEnd();

            return result;
        };

    localStorage =
        ({ dispatch, getState }: any) =>
        (next: any) =>
        (action: any): void => {
            const result = next(action);
            const nextState = getState();

            this.zone.runOutsideAngular(() =>
                this.window.setTimeout(() => this.storageService.set(REDUX_LOCAL_STORAGE_KEY, nextState), 50),
            );

            return result;
        };
}
