import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { GenericService } from './generic.service';
import { ServiceCatalogQuote } from '../models/service-catalog-quote';
import { SortParams } from '../interfaces/list-params';
import { QuoteRequestDetails } from '../models/quote-request-details';
import { IRestResponse } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';

const QUOTE_ENDPOINT = '/rest/v3/sales-flows';
const EVERGREEN_QUOTE_ENDPOINT = '/rest/v3/sales-flows/renewal';

@Injectable({ providedIn: 'root' })
export class ServiceCatalogQuoteServiceV3 extends GenericService<ServiceCatalogQuote> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ServiceCatalogQuote,
            endpoint: QUOTE_ENDPOINT,
            create: true,
            update: true,
            defaultParams: {
                sort: <SortParams>{
                    key: 'requested_at',
                    order: 'desc',
                },
            },
        });
    }

    /**
     * @param properties Required fields: name
     * @param params If specified, the url query params to include
     */
    create(properties: Partial<ServiceCatalogQuote>): Observable<ServiceCatalogQuote> {
        const requestBody = this.convertQuoteToRequest(properties);

        return this.http
            .post<IRestResponse<ServiceCatalogQuote>>(QUOTE_ENDPOINT, requestBody, { observe: 'response' })
            .pipe(map(response => response.body?.items?.[0] && ServiceCatalogQuote.fromJson(response.body.items[0])));
    }

    update(properties: Partial<ServiceCatalogQuote>): Observable<DataPage<ServiceCatalogQuote>> {
        const baseUrl = `${QUOTE_ENDPOINT}?`;
        let params: string;

        if (properties?.id) {
            params = `ids=${properties.id}`;
        } else {
            throw new Error('No update ids supplied');
        }

        const requestBody = this.convertQuoteToRequest(properties);

        return this.http
            .patch<IRestResponse<ServiceCatalogQuote>>(baseUrl + params, requestBody, { observe: 'response' })
            .pipe(
                map(response => {
                    const mappedResponse: IRestResponse<ServiceCatalogQuote> = response?.body;
                    return this.makeDataPage(mappedResponse, response);
                }),
            );
    }

    updateEvergreen(properties: Partial<ServiceCatalogQuote>): Observable<DataPage<ServiceCatalogQuote>> {
        const baseUrl = `${EVERGREEN_QUOTE_ENDPOINT}?`;
        let params: string;

        if (properties?.id) {
            params = `ids=${properties.id}`;
        } else {
            throw new Error('No update ids supplied');
        }

        const requestBody = this.convertQuoteToRequest(properties, true);

        return this.http
            .patch<IRestResponse<ServiceCatalogQuote>>(baseUrl + params, requestBody, { observe: 'response' })
            .pipe(
                map(response => {
                    const mappedResponse: IRestResponse<ServiceCatalogQuote> = response?.body;
                    return this.makeDataPage(mappedResponse, response);
                }),
            );
    }

    private convertQuoteToRequest(quote: Partial<ServiceCatalogQuote>, selfServeRenewal?: boolean): string {
        return JSON.stringify({
            id: quote.id,
            quote_type: quote.quoteType,
            program_type: quote.programType,
            license_type: quote.licenseType,
            license_updates: quote.licenseUpdates?.map(update => {
                return {
                    license_id: update.id,
                    site_name: update.siteName,
                    new_site: update.newSite,
                    license_type: update.licenseType,
                    additional_amount: update.additionalAmount,
                    start_date: update.startDate,
                    product_sku: update.productSku,
                    license_array_capacities: update.licenseArrayCapacities,
                    workload: update.workload, // May need to change this if we add more fields to Workload
                    original_amount: 0.0,
                    is_pre: update.isPre,
                    subscription_product_id: update.subscriptionProductId,
                    product_ids: update.productIds,
                    performance: update.performance,
                };
            }),
            product_sku: quote.productSku,
            product_type: quote.productType,
            service_name: quote.serviceName,
            group_name: quote.groupName,
            product_name: quote.productName,
            quoting_option_name: quote.quotingOptionName,
            products: quote.products?.map(product => {
                return {
                    product_sku: product.productSku,
                    service_name: product.serviceName,
                    group_name: product.groupName,
                    product_name: product.productName,
                    full_product_name: product.fullProductName,
                    quoting_option_name: product.quotingOptionName,
                };
            }),
            comment: quote.comment,
            reserved_amount: quote.reservedAmount,
            reserved_unit: quote.reservedUnit,
            quote_customer_emails: quote.quoteCustomerEmails,
            subscription_id: quote.subscriptionId,
            pure1_acknowledged: quote.pure1Acknowledged,
            request_type: quote.requestType,
            order_category: quote.orderCategory,
            term_in_month: quote.termInMonth,
            on_demand: quote.onDemand,
            self_serve_renewal: selfServeRenewal,
            partner_request: quote.partnerRequest,
            pure1_lead_source: quote.pure1LeadSource,
        });
    }
}

@Injectable({ providedIn: 'root' })
export class ServiceCatalogQuoteServiceV4 extends GenericService<ServiceCatalogQuote> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ServiceCatalogQuote,
            endpoint: '/rest/v4/sales-flows',
            defaultParams: {
                sort: <SortParams>{
                    key: 'requested_at',
                    order: 'desc',
                },
            },
        });
    }
}

@Injectable({ providedIn: 'root' })
export class RequestDetailsService extends GenericService<QuoteRequestDetails> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: QuoteRequestDetails,
            endpoint: '/rest/v4/sales-flows/request-details',
            defaultParams: {
                sort: <SortParams>{
                    key: 'requested_at',
                    order: 'desc',
                },
            },
        });
    }
}
