import { Resource } from '../interfaces/resource';

export class ViewReference implements Resource {
    name: string;
    id: string;

    constructor(viewReference: any) {
        this.name = viewReference.name;
        this.id = viewReference.id;
    }
}
