import { Resource } from '@pure1/data';
import { DraasApiTagCategory } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryTagCategory implements Resource {
    readonly id: string;
    readonly platformId: string;
    readonly name: string;
    readonly homeProviderId: string;
    readonly description: string;
    readonly deleted: boolean;

    constructor(json: DraasApiTagCategory) {
        this.id = json.id;
        this.platformId = json.platform_id;
        this.name = json.name;
        this.homeProviderId = json.home_provider_id;
        this.description = json.description;
        this.deleted = json.deleted;
    }
}
