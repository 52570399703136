import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { TabGroup } from '../../ui/directives/tab-group.directive';
import { SupportCase } from '../support.interface';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'support-tab-nav',
    templateUrl: 'tab-nav.component.html',
})
export class SupportTabNavComponent {
    readonly ampli = ampli;

    @Input() readonly cases: SupportCase[];
    @Input() readonly closedCases: SupportCase[];
    @Input() readonly filteredOpenCases: SupportCase[];
    @Input() readonly filteredClosedCases: SupportCase[];
    @Input() readonly searchText: string;
    @Input() selectedTab: string;
    @Output() readonly selectedTabChange = new EventEmitter<string>();

    @ViewChild(TabGroup, { static: true }) readonly tabGroup: TabGroup<string>;

    selectTab(tab: string): void {
        this.selectedTab = tab;
        this.ampli.supportTabChanged({
            label: tab,
        });
        this.selectedTabChange.emit(tab);
    }
}
