import { Component, ElementRef, Renderer2, Inject, NgZone } from '@angular/core';

import { AppService } from '../../../app/app.service';
import { BaseResizeComponent } from './base-resize-component';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState } from '../../../redux/pure-redux.service';

/**
 * Adds a drag bar to the bottom of the element this directive is on.
 */
@Component({
    selector: 'pureui-horizontal-resize',
    templateUrl: 'horizontal-resize.component.html',
    styles: [':host { display: block; }'],
})
export class HorizontalResizeComponent extends BaseResizeComponent {
    constructor(el: ElementRef, renderer: Renderer2, appService: AppService, ngZone: NgZone, ngRedux: NgRedux<IState>) {
        super(el, renderer, appService, ngZone, ngRedux, 'height');
    }

    getSizeFromMouseEvent(event: MouseEvent): number {
        return event.pageY;
    }

    getSizeFromTouchEvent(event: Touch): number {
        return event.pageY;
    }

    getStartingOffset(rootElem: HTMLElement): number {
        return rootElem.offsetHeight;
    }
}
