import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { SubscriptionGenealogy } from '../models/subscription-genealogy';

@Injectable({ providedIn: 'root' })
export class SubscriptionGenealogyService extends GenericService<SubscriptionGenealogy> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SubscriptionGenealogy,
            endpoint: '/rest/v1/asset-management/subscription-genealogies',
        });
    }
}
