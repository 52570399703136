import { Resource } from '../interfaces/resource';

export enum MarketplaceFormStatus {
    PENDING = 'pending',
    SUBMITTED = 'submitted',
}

export class MarketplaceOrderSummary implements Resource {
    id: string;
    name: string;
    formStatus: MarketplaceFormStatus;
    programTypeName: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = null;
        this.formStatus = json.form_status;
        this.programTypeName = json.program_type_name;
    }
}
