import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FactorProfile, FactorType, StepUpFactor } from '../models/step-up-factor';
import { StepUpChallengeResponse } from '../models/step-up-challenge-response';
import { StepUpVerifyResponse } from '../models/step-up-verify-response';

export type StepUpErrorType =
    | 'invalid_request'
    | 'enrollment_required'
    | 'bad_passcode'
    | 'challenge_expired'
    | 'locked_out'
    | 'unknown_error'
    | 'cancelled'
    | 'impersonate_not_allowed'
    | 'too_many_requests';

export const STEP_UP_ERROR_RESPONSES: { [index: string]: StepUpError } = {
    invalid_request: {
        error: 'invalid_request',
        errorDescription: 'Invalid request.',
    },
    enrollment_required: {
        error: 'enrollment_required',
        errorDescription: 'Prior enrollment for Step-up Authentication required.',
    },
    bad_passcode: {
        error: 'bad_passcode',
        errorDescription: 'Passcode incorrect.',
    },
    challenge_expired: {
        error: 'challenge_expired',
        errorDescription: 'Authentication challenge expired.',
    },
    locked_out: {
        error: 'locked_out',
        errorDescription: 'Too many failed authentication requests.',
    },
    impersonate_not_allowed: {
        error: 'impersonate_not_allowed',
        errorDescription: 'Step Up is not permitted while switched to another user.',
    },
};

const STEP_UP_ENDPOINT_V2 = '/rest/v2/oob';

export type StepUpError = {
    error: StepUpErrorType;
    errorDescription: string;
};

@Injectable({ providedIn: 'root' })
export class StepUpService {
    constructor(private http: HttpClient) {}

    getFactorsV2(): Observable<StepUpFactor[]> {
        return this.http.get(`${STEP_UP_ENDPOINT_V2}/factors`).pipe(
            catchError((error: any) => this.transformToStepUpError(error)),
            map((response: any[]) => (response ? response.map(json => new StepUpFactor(json)) : [])),
        );
    }

    stepUpChallenge(
        factorId: string,
        audience: string,
        details: any[],
        singleUse: boolean,
        ttl?: number,
    ): Observable<StepUpChallengeResponse> {
        return this.http
            .post(`${STEP_UP_ENDPOINT_V2}/challenge`, {
                factor_id: factorId,
                audience,
                single_use: singleUse,
                authorization_details: details,
                ttl,
            })
            .pipe(
                catchError((error: any) => this.transformToStepUpError(error)),
                map((response: any) => new StepUpChallengeResponse(response)),
            );
    }

    stepUpVerify(passCode: string, stateToken: string, factorId: string): Observable<StepUpVerifyResponse> {
        return this.http
            .post(`${STEP_UP_ENDPOINT_V2}/verify`, {
                pass_code: passCode,
                state_token: stateToken,
                factor_id: factorId,
            })
            .pipe(
                catchError((error: any) => this.transformToStepUpError(error)),
                map((response: any) => new StepUpVerifyResponse(response)),
            );
    }

    stepUpEnrollFactorV2(
        factorType: FactorType,
        profile: FactorProfile,
        auth: string,
        provider?: string,
    ): Observable<StepUpFactor> {
        return this.http
            .post(`${STEP_UP_ENDPOINT_V2}/factors`, {
                factor_type: factorType,
                profile: { email: profile?.email, phone_number: profile?.phoneNumber },
                auth: auth,
                provider: provider,
            })
            .pipe(
                catchError((error: any) => this.transformToStepUpError(error)),
                map(response => (response ? new StepUpFactor(response) : undefined)),
            );
    }

    stepUpActivateV2(passCode: string, factorId: string): Observable<StepUpFactor> {
        return this.http.post(`${STEP_UP_ENDPOINT_V2}/factors/${factorId}/activate`, { pass_code: passCode }).pipe(
            catchError((error: any) => this.transformToStepUpError(error)),
            map(response => (response ? new StepUpFactor(response) : undefined)),
        );
    }

    stepUpResetV2(factorId: string, auth: string): Observable<void> {
        return this.http.post(`${STEP_UP_ENDPOINT_V2}/factors/${factorId}/reset`, { auth: auth }).pipe(
            catchError((error: any) => this.transformToStepUpError(error)),
            map(_ => undefined),
        );
    }

    stepUpEnabled(email: string): Observable<boolean | StepUpError> {
        return this.http.get<boolean>(`${STEP_UP_ENDPOINT_V2}/status`, {
            params: {
                email,
            },
        });
    }

    private transformToStepUpError(error: any): Observable<StepUpError> {
        if (error.error) {
            return throwError({ error: error.error.error, errorDescription: error.error.error_description });
        } else {
            return throwError({ error: 'unknown_error', errorDescription: 'Unknown error occurred' });
        }
    }
}
