import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GenericService } from './generic.service';
import { SortParams } from '../interfaces/list-params';
import { LicenseBillingInfo } from '../models/license-billing-info';

@Injectable({ providedIn: 'root' })
export class SubscriptionBillingService extends GenericService<LicenseBillingInfo> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: LicenseBillingInfo,
            endpoint: '/rest/v2/metrics/billing',
            defaultParams: {
                sort: <SortParams>{
                    key: 'name',
                    order: 'desc',
                },
            },
        });
    }
}
