import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface QuoteContactRequest {
    id: string;
    comment: string;
}

const CONTACT_US_URL = '/rest/v3/sales-flows/contact-us';

@Injectable({ providedIn: 'root' })
export class OrdersUpdateService implements OnDestroy {
    readonly refreshQuotes$ = new BehaviorSubject<void>(null);

    constructor(private http: HttpClient) {}

    ngOnDestroy(): void {
        this.refreshQuotes$.complete();
    }

    sendContactUsRequest(request: QuoteContactRequest): Observable<QuoteContactRequest> {
        return this.http.post<QuoteContactRequest>(CONTACT_US_URL, request);
    }
}
