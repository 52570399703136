import { CollectionList, DataPage, DisasterRecoveryPlan, FilterParams, IRestResponse, ListParams } from '@pure1/data';
import { map, Observable, Subject, tap } from 'rxjs';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

export abstract class DisasterRecoveryBaseService<T, DraasApiType> implements CollectionList<T> {
    protected abstract http: DisasterRecoveryThrottlingHttpClient;
    protected abstract resourceClass: { new (json: DraasApiType): T };
    protected abstract pathParams: string[];

    protected abstract getEndpoint(params: FilterParams<DisasterRecoveryPlan>, extra: string): string;

    list(params?: ListParams<T>): Observable<DataPage<T>> {
        const url: string = this.getListRequest(params);
        return this.http.get<DraasApiType[] | IRestResponse<DraasApiType>>(url).pipe(
            map(response => {
                return this.makeDataPage(response);
            }),
        );
    }

    protected doCreate(url: string, body: any, notify$?: Subject<T>): Observable<T> {
        return this.http.post<DraasApiType>(url, body).pipe(
            map(response => new this.resourceClass(response)),
            tap(resource => notify$?.next(resource)),
        );
    }

    protected doUpdate(url: string, body: any, notify$?: Subject<T>): Observable<T> {
        return this.http.patch<DraasApiType>(url, body).pipe(
            map(response => new this.resourceClass(response)),
            tap(resource => notify$?.next(resource)),
        );
    }

    protected doDelete<S>(url: string, resourceId: S, notify$?: Subject<S>): Observable<void> {
        return this.http.delete<void>(url).pipe(tap(() => notify$?.next(resourceId)));
    }

    private getListRequest(params: ListParams<T>): string {
        const queryParams: string[] = [];

        if (params.pageStart !== undefined && params.pageSize !== undefined) {
            queryParams.push(`page_number=${Math.floor(params.pageStart / params.pageSize)}`);
            queryParams.push(`page_size=${params.pageSize}`);
        }

        const endpoint = this.getEndpoint(params.filter, params.extra);

        if (params.filter || params.defaultFilter) {
            const filter = { ...params.filter, ...params.defaultFilter };
            Object.keys(filter)
                .filter(k => k != null)
                .filter(k => k != null && !this.pathParams.includes(k))
                .forEach(k => queryParams.push(`${k}=${encodeURIComponent(filter[k] as string)}`));
        }

        if (params.sort) {
            if (Array.isArray(params.sort)) {
                params.sort.forEach(s => queryParams.push(`sort=${s.key},${s.order}`));
            } else {
                queryParams.push(`sort=${params.sort.key},${params.sort.order}`);
            }
        }

        return endpoint + (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');
    }

    private makeDataPage(response: DraasApiType[] | IRestResponse<DraasApiType>): DataPage<T> {
        if (this.isPaged(response)) {
            return {
                total: response.total_item_count,
                response: response.items.map(resource => new this.resourceClass(resource)),
            };
        } else {
            return {
                total: response.length,
                response: response.map(resource => new this.resourceClass(resource)),
            };
        }
    }

    private isPaged(response: DraasApiType[] | IRestResponse<DraasApiType>): response is IRestResponse<DraasApiType> {
        return !Array.isArray(response);
    }
}
