import { animate, state, style, transition, trigger } from '@angular/animations';

export const PerformanceAnimations = {
    collapseTrigger: trigger('smoothCollapse', [
        state(
            'collapsed',
            style({
                height: '0',
                opacity: '0',
            }),
        ),
        state(
            'expanded',
            style({
                opacity: '1',
            }),
        ),
        transition('collapsed<=>expanded', animate('100ms')),
    ]),
};
