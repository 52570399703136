import moment from 'moment';
import { HourlyAggregation } from './hourly-aggregation';

export class PxVolumeAggregation {
    data: any;
    read_bandwidth: HourlyAggregation;
    write_bandwidth: HourlyAggregation;
    read_iops: HourlyAggregation;
    write_iops: HourlyAggregation;
    read_latency: HourlyAggregation;
    write_latency: HourlyAggregation;
    latest_metric_time: moment.Moment;
    aggregation_time: moment.Moment;

    constructor(json: any) {
        this.data = json.data;
        this.latest_metric_time = moment(json.latest_metric_time);
        this.aggregation_time = moment(json.aggregation_time);

        this.read_bandwidth = new HourlyAggregation(json.data.px_volume_readthroughput);
        this.write_bandwidth = new HourlyAggregation(json.data.px_volume_writethroughput);
        this.read_iops = new HourlyAggregation(json.data.px_volume_read_iops);
        this.write_iops = new HourlyAggregation(json.data.px_volume_write_iops);
        this.read_latency = new HourlyAggregation(json.data.px_volume_read_latency);
        this.write_latency = new HourlyAggregation(json.data.px_volume_write_latency);
    }
}
