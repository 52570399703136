import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { WINDOW } from '../../app/injection-tokens';
import { NpsSurvey } from '../../nps-survey/models/NpsSurvey';
import { NpsSurveyService } from '../../nps-survey/services/nps-survey.service';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'nps-survey-banner',
    templateUrl: 'nps-survey-banner.component.html',
})
export class NpsSurveyBannerComponent extends BaseBanner implements OnInit {
    populatedNotification: UserNotification = new UserNotification({});
    survey: NpsSurvey;

    @ViewChild('npsButtonTemplate', { static: true }) readonly npsButtonTemplate: TemplateRef<any>;

    private destroy$ = new Subject<void>();

    constructor(
        private npsSurveyService: NpsSurveyService,
        @Inject(WINDOW) private window: Window,
    ) {
        super();
    }

    ngOnInit(): void {
        this.npsSurveyService.refreshSurvey$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.dismissNotification.emit(this.survey.surveyId));

        this.npsSurveyService.getSurveys(this.window.location.pathname, true).subscribe(surveys => {
            this.survey = surveys?.[0];
            this.populateNPSNotificationContent();
        });
    }

    openFeedbackForm(): void {
        this.npsSurveyService.announceSurvey(this.survey);
    }

    private populateNPSNotificationContent(): void {
        if (!this.notification) {
            return;
        }

        const populatedNotification = Object.assign({}, this.notification, {
            hideReleaseNotes: true,
        });

        if (this.survey) {
            Object.assign(populatedNotification, {
                title: this.survey.surveyTitle,
                body: this.survey.surveyTagline,
                buttonTemplate: this.npsButtonTemplate, // Template uses openFeedbackForm which won't work without this.survey
            });
        }

        this.populatedNotification = populatedNotification;
    }
}
