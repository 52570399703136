import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LicenseSlaIndicator, LicenseSlaIndicatorSummary, SlaIndicatorStatus } from '@pure1/data';

export const STATUS_TEXT_MAP: Readonly<Map<SlaIndicatorStatus, string>> = Object.freeze(
    new Map([
        ['OK' as SlaIndicatorStatus, 'On target'],
        ['AT_RISK' as SlaIndicatorStatus, 'Nearing limit'],
        ['UNKNOWN' as SlaIndicatorStatus, 'Unknown'],
        ['INELIGIBLE' as SlaIndicatorStatus, '–'],
    ]),
);

@Component({
    selector: 'sla-status',
    templateUrl: 'sla-status.component.html',
})
export class SlaStatusComponent implements OnChanges {
    @Input() readonly slaIndicator: LicenseSlaIndicator;
    @Input() readonly summary: LicenseSlaIndicatorSummary;
    statusText: string;
    ineligibleMessage: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.slaIndicator) {
            if (this.slaIndicator) {
                this.statusText = STATUS_TEXT_MAP.get(this.slaIndicator.status);
                if (this.slaIndicator.status === 'INELIGIBLE') {
                    // Prefer to always show the actual message. But just in case that isn't available (eg backwards compatibility),
                    // show the ineligiblity code. If it is marked ineligible but for some reason no reason why, give a generic message.
                    this.ineligibleMessage =
                        this.slaIndicator.ineligibleMessage ||
                        this.slaIndicator.ineligibleReason ||
                        'Ineligible due to unspecified reason';
                }
            } else {
                this.statusText = null;
            }
        }
    }
}
