import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService } from '@pure/pure1-ui-platform-angular';
import moment from 'moment';
import { FeatureNames } from '../../model/FeatureNames';
import { ToastService, ToastType } from '../../services/toast.service';
import { CaseManager } from '../services/case-data.service';
import { Subject, Subscription } from 'rxjs';
import { exhaustMap, take, takeUntil } from 'rxjs/operators';
import { CachedCurrentUserService } from '@pure1/data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NoSupportModalComponent } from '../no-support-modal/no-support-modal.component';
import { SupportCenterRedirectService } from '../services/support-center-redirect.service';
import { SupportLoaderService } from '../services/support-loader.service';
import { SupportCase } from '../support.interface';
import { smartTimer } from '@pstg/smart-timer';
import { ActivatedRoute, Router } from '@angular/router';
import { ampli } from 'core/src/ampli';

const refreshSubscriptionInterval = moment.duration(5, 'minutes').asMilliseconds();

@Component({
    selector: 'pure-support-tab',
    templateUrl: './support-tab.component.html',
})
export class SupportTabComponent implements OnInit, OnDestroy {
    readonly ampli = ampli;

    secondaryNavCollapsed = false;
    mainViewOpenCases: SupportCase[] = [];
    caseId: string;
    caOnly: boolean;
    supportCenterEnabled = false;
    private refreshSubscription: Subscription;
    private stopPolling$ = new Subject<void>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        public caseManager: CaseManager,
        private ngbModal: NgbModal,
        public supportLoader: SupportLoaderService,
        private toastService: ToastService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private router: Router,
        private route: ActivatedRoute,
        private featureService: FeatureFlagService,
        private supportCenterRedirectService: SupportCenterRedirectService,
    ) {}

    ngOnInit(): void {
        this.featureService.getFeatureFlags(FeatureNames.SUPPORT_CENTER).subscribe(feature => {
            this.supportCenterEnabled = feature[FeatureNames.SUPPORT_CENTER].enabled;
        });
        // Check if we need to display the "caOnly" modal
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.caOnly = currentUser.caOnly;
                if (currentUser.caOnly) {
                    if (!currentUser.isAlternateLogin && !this.ngbModal.hasOpenModals()) {
                        const modal = this.ngbModal.open(NoSupportModalComponent);
                    }
                } else {
                    // Otherwise, load normally
                    this.startTimer();
                }
            });

        this.onCaseChange(this.caseManager.forNav());

        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.caseId = params.caseId;
            if (this.caseId) {
                this.ampli.supportCaseSelected({
                    caseId: this.caseId,
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.supportLoader.clear();

        this.stopTimer();

        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    startTimer = () => {
        this.refreshSubscription = smartTimer(0, refreshSubscriptionInterval)
            .pipe(
                exhaustMap(() => this.caseManager.refresh()),
                takeUntil(this.stopPolling$),
            )
            .subscribe(newCases => {
                this.onCaseChange(newCases);
            });
    };

    toggleSecondaryNavCollapsed(): void {
        this.secondaryNavCollapsed = !this.secondaryNavCollapsed;
    }

    stopTimer = () => {
        if (this.refreshSubscription && !this.refreshSubscription.closed) {
            this.refreshSubscription.unsubscribe();
        }

        this.stopPolling$.next();
    };

    newCaseCreated = (newCaseId: string) => {
        this.refreshCases();
        this.router.navigate(['/support/cases'], { queryParams: { caseId: newCaseId } });
    };

    refreshCases = () => {
        this.stopTimer();
        this.startTimer();
    };

    showNoCaseSelectedMessage(): boolean {
        const cases = this.caseManager.forNav();
        return cases && cases.length > 0;
    }

    private onCaseChange(newCases: SupportCase[]): void {
        if (typeof newCases === 'undefined') {
            return;
        }

        if (this.mainViewOpenCases.length === 0) {
            this.mainViewOpenCases = newCases.slice();
        } else {
            const newestCases = this.caseManager.getNewestCases();
            const newestCasesCount = newestCases.length;
            if (newestCasesCount > 0) {
                newestCases.forEach(newCase => {
                    if (!this.mainViewOpenCases.some(openCase => openCase.id === newCase.id)) {
                        this.mainViewOpenCases.push(newCase);
                    }
                });
            }

            const message = newestCasesCount === 1 ? '1 new case' : `${newestCasesCount} new cases`;
            this.toastService.add(ToastType.info, message);
        }

        if (this.caseManager.getRecentlyClosedCases().length > 0 || this.caseManager.getNewestCases().length > 0) {
            this.caseManager.fetchClosedCases().subscribe();
        }
    }

    openSupportCenter(): void {
        this.supportCenterRedirectService.openSupportCenter();
    }

    openCreateCasePage(): void {
        this.supportCenterRedirectService.openCreateCase();
    }
}
