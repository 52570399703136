import moment from 'moment';
import { Injectable } from '@angular/core';
import {
    License,
    LicenseType,
    QuoteType,
    ServiceCatalogGroup,
    ServiceCatalogOffering,
    ServiceCatalogQuote,
    Subscription,
} from '@pure1/data';

export type QuoteProductParam = {
    full_product_name: string;
    group_name: string;
    product_name: string;
    product_sku: string;
    quoting_option_name: string;
    service_name: string;
};

const MAX_COMMENT_CHARS_LENGTH = 3000;
const BYTE_MULTIPLIER = 1024;

@Injectable({ providedIn: 'root' })
export class QuoteManagementService {
    private reserveUnit: string;
    private isLoading: boolean;
    private hasError: boolean;

    constructor() {}

    generateParamsForQuote(
        increaseReserved: number[],
        existingLicenses: License[],
        newLicenses: License[],
        subscription: Subscription,
        offeringsInGroups: ServiceCatalogGroup[],
        additionalInstructionsText: string,
        additionalEmails: string,
        quoteBeingEdited: ServiceCatalogQuote,
        reserveUnit = 'TiB',
    ): ServiceCatalogQuote {
        this.reserveUnit = reserveUnit;
        // prepare license_udpates
        const updateLicenses =
            increaseReserved
                .map((increaseAmount, index) => {
                    return {
                        license_id: existingLicenses[index].id,
                        site_name: existingLicenses[index].name,
                        license_type: existingLicenses[index].licenseType,
                        product_sku: null as string,
                        additional_amount: this.convertAmountToTiB(
                            increaseAmount && !Number.isNaN(increaseAmount) ? increaseAmount : 0,
                            'B',
                        ),
                        original_amount: this.convertAmountToTiB(existingLicenses[index].reservedAmount, 'B'),
                        new_site: false,
                        start_date: existingLicenses[index]?.startDate
                            ? existingLicenses[index].startDate.valueOf()
                            : moment().valueOf(),
                    };
                })
                .filter(license => license) || [];
        const newLicensesForUpdate =
            newLicenses.map(license => {
                return {
                    license_id: null,
                    site_name: license.name,
                    license_type: license.licenseType,
                    product_sku: null as string,
                    additional_amount: this.convertAmountToTiB(license.reservedAmount, 'B'),
                    original_amount: 0.0,
                    new_site: true,
                    start_date: license.startDate.valueOf(),
                };
            }) || [];
        const allLicenseUpdates = updateLicenses.concat(newLicensesForUpdate);

        // prepare products
        // first cycle through all licenses to build up a unique list of license types
        const uniqueLicenseTypes: LicenseType[] = [];
        allLicenseUpdates.forEach(licenseUpdate => {
            if (!uniqueLicenseTypes.includes(licenseUpdate.license_type)) {
                uniqueLicenseTypes.push(licenseUpdate.license_type);
            }
        });

        // then, add each license's product info in the productsCollection using the offering catalog
        const productsCollection: QuoteProductParam[] = [];
        uniqueLicenseTypes.forEach(licenseType => {
            let matchingOffering: ServiceCatalogOffering = null;
            offeringsInGroups?.forEach(group => {
                group.offerings?.forEach(offering => {
                    if (offering.licenseType === licenseType) {
                        matchingOffering = offering;
                    }
                });
            });

            if (matchingOffering) {
                const matchingProduct = {
                    full_product_name: matchingOffering.licenseType,
                    group_name: matchingOffering.groupName,
                    product_name: matchingOffering.productName,
                    product_sku: matchingOffering.quotingOptions[0].productSku,
                    quoting_option_name: null,
                    service_name: matchingOffering.serviceName,
                };
                productsCollection.push(matchingProduct);
            }
        });

        // finally, populate each licenseUpdate item with its matching productSku
        allLicenseUpdates.forEach(licenseUpdate => {
            productsCollection.forEach(product => {
                if (product.full_product_name === licenseUpdate.license_type) {
                    licenseUpdate.product_sku = product.product_sku;
                }
            });
        });

        this.isLoading = true;
        this.hasError = false;
        const params = new ServiceCatalogQuote({
            quote_type:
                !subscription || subscription.isPoc
                    ? QuoteType.REGULAR_QUOTE
                    : !quoteBeingEdited
                      ? QuoteType.EXPANSION
                      : quoteBeingEdited.quoteType,
            program_type: subscription?.programType,
            reserved_unit: 'TiB',
            comment:
                !additionalInstructionsText || additionalInstructionsText === ''
                    ? null
                    : additionalInstructionsText.slice(0, MAX_COMMENT_CHARS_LENGTH), // technically empty string also is truthy when !someString
            on_demand: null, //Null for now since we might support on-demand in the future
            request_type: 'Quote',
            order_category:
                !subscription || subscription.isPoc
                    ? 'New Subscription'
                    : !quoteBeingEdited
                      ? 'Subscription Expansion'
                      : quoteBeingEdited.orderCategory,
            quote_customer_emails: !additionalEmails
                ? null
                : additionalEmails.split(',').map(emailEntry => emailEntry.trim()),
            subscription_id: subscription.id,
            license_updates: allLicenseUpdates?.length > 0 ? allLicenseUpdates : null,
            products: productsCollection?.length > 0 ? productsCollection : null,
        });

        return params;
    }

    private convertAmountToTiB(value: number, currentUnitOfValue = 'TiB'): number {
        if (!value || Number.isNaN(value)) {
            return value;
        }
        switch (currentUnitOfValue) {
            case 'TiB':
                return value;
            case 'GiB':
                return value / BYTE_MULTIPLIER;
            case 'MiB':
                return value / (BYTE_MULTIPLIER * BYTE_MULTIPLIER);
            case 'KiB':
                return value / (BYTE_MULTIPLIER * BYTE_MULTIPLIER * BYTE_MULTIPLIER);
            case 'B':
                return value / (BYTE_MULTIPLIER * BYTE_MULTIPLIER * BYTE_MULTIPLIER * BYTE_MULTIPLIER);
            default:
                console.warn(
                    'Unknown unit: ' +
                        currentUnitOfValue +
                        ' used to convert value: ' +
                        value +
                        '. Returning original value as it was.',
                );
                return value; // unknown unit, do nothing
        }
    }
}
