<ng-container>
    <div *ngIf="loading">
        <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
    </div>
    <ng-container *ngIf="!loading">
        <div *ngIf="isBeingEdited && form.controls.product">
            <input
                class="form-control full-width"
                type="text"
                [value]="form.controls.product.value?.subscription.name + '-' + form.controls.product.value?.name"
                disabled="true"
            />
        </div>
        <div *ngIf="!isBeingEdited" [formGroup]="form">
            <search-license
                #searchApplianceInput
                formControlName="product"
                [checkSupportContract]="checkSupportContract"
                [licenses]="appliances"
            ></search-license>
        </div>
    </ng-container>
</ng-container>
