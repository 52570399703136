import { Injectable, Inject } from '@angular/core';
import { GOOGLE_MAPS, WINDOW } from '../app/injection-tokens';
import { DOCUMENT } from '@angular/common';
import { Angulartics2 } from 'angulartics2';

export interface Panorama {
    view: google.maps.StreetViewPanorama;
    div: HTMLDivElement;
}

type GoogleMaps = typeof google.maps;

// The amount of objects we think we can safely load at once
// Until we finish the work to recycle instances that aren't in the view,
// this will just be used to show a warning as opposed to putting a hard-cap on the
// total number of instances created
const POOL_LIMIT = 10;
// Default center provided by creating a new LatLngBounds
const DEFAULT_CENTER = {
    lat: 0,
    lng: -180,
};
const DEFAULT_MAX_ZOOM = 20;

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
    // Analytics
    readonly analyticsPrefix = 'Map View - ';
    readonly analyticsGoogleCalls = 'Google API calls';

    // Maps
    availableMapPool: google.maps.Map[] = [];
    inUseMapPool: google.maps.Map[] = [];

    // Panoramas
    availablePanoramaPool: Panorama[] = [];
    inUsePanoramaPool: Panorama[] = [];

    private googleMaps: GoogleMaps;

    // These options contain some basic default initialization. If more specialized fields are used,
    // we will need to reset those to the default here as well.
    private defaultMapOptions: google.maps.MapOptions;

    // Should be of type StreetViewPanoramaOptions, but @types/googlemaps library hasn't been updated with the
    // showRoadLabels field. The field is documented here:
    // https://developers.google.com/maps/documentation/javascript/reference/street-view#StreetViewPanoramaOptions
    private defaultPanoramaOptions: any;

    constructor(
        private angulartics2: Angulartics2,
        @Inject(DOCUMENT) private doc: Document,
        @Inject(GOOGLE_MAPS) googleMapsPromise: Promise<GoogleMaps>,
        @Inject(WINDOW) private window: Window,
    ) {
        googleMapsPromise
            .then(map => {
                this.googleMaps = map;
                this.initializeDefault();
            })
            .catch(error => {
                console.error('Google Maps not loaded', error);
            });
    }

    getMap(options?: google.maps.MapOptions): google.maps.Map {
        const mapOptions = { ...this.defaultMapOptions, ...options };
        let newMap: google.maps.Map;

        if (this.availableMapPool.length > 0) {
            newMap = this.availableMapPool.pop();
        } else {
            const newDiv = this.doc.createElement('div');
            newMap = new this.googleMaps.Map(newDiv);
            this.angulartics2.eventTrack.next({
                // When more pages use this service, we will need to update this analytic tracking
                // to be more sophisticated. For now, we will assume the Map View page is the only one calling this
                action: this.analyticsPrefix + this.analyticsGoogleCalls,
                properties: {
                    category: 'Action',
                    label: 'map created',
                },
            });
        }

        newMap.setOptions(mapOptions);
        this.inUseMapPool.push(newMap);

        // We don't ever expect this to happen, since most pages will have at most 1 map. There is likely a terrible
        // error if this occurs.
        if (this.inUseMapPool.length >= POOL_LIMIT) {
            console.warn(`Map pool limit of ${POOL_LIMIT} reached. Further Map objects may be impacted.`);
        }
        return newMap;
    }

    getPanorama(options?: google.maps.StreetViewPanoramaOptions): Panorama {
        const panoOptions = { ...this.defaultPanoramaOptions, options };
        let newPano: Panorama;

        if (this.availablePanoramaPool.length > 0) {
            newPano = this.availablePanoramaPool.pop();
        } else if (this.inUsePanoramaPool.length < POOL_LIMIT) {
            const newDiv = this.doc.createElement('div');
            newPano = {
                div: newDiv,
                view: new this.googleMaps.StreetViewPanorama(newDiv),
            };

            this.angulartics2.eventTrack.next({
                // When more pages use this service, we will need to update this analytic tracking
                // to be more sophisticated. For now, we will assume the Map View page is the only one calling this
                action: this.analyticsPrefix + this.analyticsGoogleCalls,
                properties: {
                    category: 'Action',
                    label: 'streetview created',
                },
            });
        } else {
            const viewportHeight = this.window.innerHeight;
            let panoIndex = 0;
            // Map to distance from the viewport and find the one furthest away
            this.inUsePanoramaPool
                .map(panorama => {
                    const rect = panorama.div.getBoundingClientRect();
                    if (rect.bottom < 0) {
                        return Math.abs(rect.bottom);
                    } else {
                        return rect.top - viewportHeight;
                    }
                })
                .forEach((distFromView, index, distances) => {
                    if (distFromView > distances[panoIndex]) {
                        panoIndex = index;
                    }
                });
            newPano = this.inUsePanoramaPool[panoIndex];
            newPano.view.setOptions(panoOptions);
            return newPano;
        }

        newPano.view.setOptions(panoOptions);
        this.inUsePanoramaPool.push(newPano);

        return newPano;
    }

    releaseMap(map: google.maps.Map): void {
        this.inUseMapPool = this.inUseMapPool.filter(inUseMap => inUseMap !== map);
        this.availableMapPool.push(map);
    }

    releasePanorama(panorama: Panorama): void {
        this.inUsePanoramaPool = this.inUsePanoramaPool.filter(inUsePanorama => inUsePanorama !== panorama);
        this.availablePanoramaPool.push(panorama);
    }

    private initializeDefault(): void {
        this.defaultMapOptions = <any>{
            center: DEFAULT_CENTER,
            clickableIcons: true,
            disableDefaultUI: false,
            disableDoubleClickZoom: false,
            draggable: true,
            fullscreenControl: true,
            mapTypeControl: true,
            mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
            maxZoom: DEFAULT_MAX_ZOOM,
            minZoom: 0,
            noClear: false,
            overviewMapControl: true,
            rotateControl: true,
            scaleControl: true,
            streetViewControl: true,
            styles: [],
            zoomControl: true,
        };

        this.defaultPanoramaOptions = {
            addressControl: true,
            clickToGo: true,
            disableDefaultUI: false,
            panControl: true,
            scrollwheel: true,
            showRoadLabels: true,
        };
    }
}
