import moment from 'moment';
import { Resource } from '../interfaces/resource';

interface IMetricsAggregation1Hr24Hr {
    '1h_avg': number;
    '1h_max': number;
    '24hours_avg': number;
    '24hours_avg_1day_ago': number;
    '24hours_avg_growth': number;
    '24hours_max': number;
    '24hours_max_1day_ago': number;
    '24hours_max_growth': number;
}

interface IMetricsAggregation {
    aggregation_time?: moment.Moment | number;
    latest_metric_time?: moment.Moment | number;
    data?: {
        estimated_space?: IMetricsAggregation1Hr24Hr;
        estimated_space_lower?: IMetricsAggregation1Hr24Hr;
        provisioned?: IMetricsAggregation1Hr24Hr;
        snapshot_space?: IMetricsAggregation1Hr24Hr;
        data_reduction?: IMetricsAggregation1Hr24Hr;
        /** Unit: iops */
        write_iops?: IMetricsAggregation1Hr24Hr;
        mirror_write_iops?: IMetricsAggregation1Hr24Hr;
        read_iops?: IMetricsAggregation1Hr24Hr;
        other_iops?: IMetricsAggregation1Hr24Hr;
        system_space?: IMetricsAggregation1Hr24Hr;
        /** Unit: bytes */
        read_bandwidth?: IMetricsAggregation1Hr24Hr;
        write_bandwidth?: IMetricsAggregation1Hr24Hr;
        mirror_write_bandwidth?: IMetricsAggregation1Hr24Hr;
        queue_depth?: IMetricsAggregation1Hr24Hr;
        physical_unique?: IMetricsAggregation1Hr24Hr;
        total_system_size?: IMetricsAggregation1Hr24Hr;
        physical_shared?: IMetricsAggregation1Hr24Hr;
        /** Unit: microseconds (not milliseconds!) */
        read_latency?: IMetricsAggregation1Hr24Hr;
        write_latency?: IMetricsAggregation1Hr24Hr;
        mirror_write_latency?: IMetricsAggregation1Hr24Hr;
        other_latency?: IMetricsAggregation1Hr24Hr;
    };
}

export type VolumePerformanceAggregationMetricKey =
    | 'read_bandwidth'
    | 'write_bandwidth'
    | 'read_iops'
    | 'write_iops'
    | 'read_latency'
    | 'write_latency';
export type VolumePerformanceAggregationTimerangeKey = '1_month' | '3_months' | '6_months' | '12_months';

interface IVolumePerformanceAggregation {
    data: {
        [metric in VolumePerformanceAggregationMetricKey]: {
            [key in VolumePerformanceAggregationTimerangeKey]: IVolumePerformanceAggregationValues;
        };
    };
}

interface IVolumePerformanceAggregationValues {
    average: number;
    max: number;
    sum: number;
}

export class SimulatedVolume implements Resource {
    readonly id: string;
    readonly coreId: string;
    readonly name: string;
    readonly aggregation: IMetricsAggregation;
    readonly performance_aggregation: IVolumePerformanceAggregation;
    readonly serial: string;
    readonly array: Resource;

    get isPhantomWorkload(): boolean {
        return this.array && this.array.id === 'PHANTOM_WORKLOAD';
    }

    constructor(json: any) {
        // For regular volumes, the id is arrayId + ':' + coreId. For phantom volumes, the guid is in the array_name.
        this.id = json.array_id === 'PHANTOM_WORKLOAD' ? json.array_name : json.array_id + ':' + json.core_id;
        this.coreId = String(json.core_id);
        this.name = json.name;
        this.aggregation = json.aggregation;
        this.performance_aggregation = json.performance_aggregation;
        this.serial = json.serial;
        this.array = {
            id: json.array_id,
            name: json.array_name,
        };

        // For each volume, the backend returns the steps that affect it. While this is very useful, using it requires
        // us to update the volumes every time a step changes. So for something like the Change Workload modal, where we
        // show a table of volumes and allow modifying steps, it means that we have to make sure the volumes updates every
        // time a step changes as well.
        // So we have similar logic in the SimulationStep class, and ignore the value from the backend.
        //      this.simulation_steps = (json.simulation_steps || []).map((id: string) => ({ id: id }));
    }
}
