import { Resource } from '../interfaces/resource';

export class MarketplaceOrderContact implements Resource {
    id: string;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    company_name: string;
    billing_street_address: string;
    billing_city: string;
    billing_state: string;
    billing_postal_code: string;
    billing_country: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = null;
        this.first_name = json.first_name;
        this.last_name = json.last_name;
        this.email = json.email;
        this.phone_number = json.phone_number;
        this.company_name = json.company_name;
        this.billing_street_address = json.billing_street_address;
        this.billing_city = json.billing_city;
        this.billing_state = json.billing_state;
        this.billing_postal_code = json.billing_postal_code;
        this.billing_country = json.billing_country;
    }
}
