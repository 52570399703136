<div class="card-perf-chart-container" [ngClass]="{ expanded: isExpanded }">
    <div class="performance-chart" #chartElem></div>

    <div class="expand-contract">
        <span
            [ngClass]="isExpanded ? 'fa fa-compress' : 'fa fa-expand'"
            (click)="toggleExpanded()"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Arrays - Chart toggled"
            [angularticsLabel]="metricConfig.label"
        ></span>
    </div>
</div>
