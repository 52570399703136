import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { FlexHardwareModel } from '../models/flex-hardware-model';

export type FlexProductType = 'FA' | 'FB';

@Injectable({ providedIn: 'root' })
export class FlexHardwareModelsService extends GenericService<FlexHardwareModel> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: FlexHardwareModel,
            endpoint: '/rest/v1/hardware-configurations/capacities/flex/hardware',
        });
    }
}
