import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PxCluster } from '../models/px-cluster';

@Injectable({ providedIn: 'root' })
export class PxClusterService extends GenericService<PxCluster> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PxCluster,
            endpoint: '/rest/v1/portworx/clusters',
        });
    }
}
