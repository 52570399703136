export type ChassisDatapackType = 'SAS' | 'directFlash' | 'DFMc' | 'DFMD' | 'DFMe' | 'EMPTY';
export type ShelfType = '6G_SAS' | '12G_SAS' | 'directFlash' | 'DFMc' | 'DFMe' | 'EMPTY';
export const ONE_TB_IN_BYTES = 1e12;

export class DatapackCapacityConfig {
    static createDPCC({
        drive_count,
        raw_bytes,
        raw_TB,
        licensed_label_bytes,
        licensed_raw_TB,
        installed_drive_count,
    }: {
        drive_count: number;
        raw_bytes?: number;
        raw_TB?: number;
        licensed_label_bytes?: number;
        licensed_raw_TB?: number;
        installed_drive_count?: number;
    }): DatapackCapacityConfig {
        return new DatapackCapacityConfig(
            drive_count,
            raw_bytes ?? null,
            raw_TB ?? null,
            licensed_label_bytes ?? null,
            licensed_raw_TB ?? null,
            installed_drive_count ?? null,
        );
    }

    constructor(
        public drive_count: number,
        /**  currently existing datapack size */
        public raw_bytes?: number,
        /** simulated datapack size */
        public raw_TB?: number,
        /** licensed datapack size */
        public licensed_label_bytes?: number,
        /** licensed datapack size */
        public licensed_raw_TB?: number,
        public installed_drive_count?: number,
    ) {}
}

export class ChassisDatapackCapacityConfig extends DatapackCapacityConfig {
    static createCDPCC({
        type,
        drive_count,
        raw_bytes,
        raw_TB,
        licensed_label_bytes,
        licensed_raw_TB,
        installed_drive_count,
    }: {
        type: ChassisDatapackType;
        drive_count: number;
        raw_bytes?: number;
        raw_TB?: number;
        licensed_label_bytes?: number;
        licensed_raw_TB?: number;
        installed_drive_count?: number;
    }): ChassisDatapackCapacityConfig {
        return new ChassisDatapackCapacityConfig(
            type,
            drive_count,
            raw_bytes ?? null,
            raw_TB ?? null,
            licensed_label_bytes ?? null,
            licensed_raw_TB ?? null,
            installed_drive_count ?? null,
        );
    }

    static createEmptyCDPCC(isForXLChassis: boolean): ChassisDatapackCapacityConfig {
        return new ChassisDatapackCapacityConfig(isForXLChassis ? 'DFMD' : null, 10, 0);
    }

    constructor(
        public type: ChassisDatapackType,
        public drive_count: number,
        /**  currently existing datapack size */
        public raw_bytes?: number,
        /** simulated datapack size */
        public raw_TB?: number,
        /** licensed datapack size */
        public licensed_label_bytes?: number,
        public licensed_raw_TB?: number,
        public installed_drive_count?: number,
    ) {
        super(drive_count, raw_bytes, raw_TB, licensed_label_bytes, licensed_raw_TB, installed_drive_count);
    }
}

export class ChassisCapacityConfig {
    datapacks: ChassisDatapackCapacityConfig[];

    constructor(json: any) {
        this.datapacks = json.datapacks.map(dp => ChassisDatapackCapacityConfig.createCDPCC(dp));
    }
}

export class ShelfCapacityConfig {
    type: ShelfType;
    datapacks: DatapackCapacityConfig[];
    deleted?: boolean;

    constructor(json: any) {
        this.type = json.type;
        this.datapacks = json.datapacks.map(dp => DatapackCapacityConfig.createDPCC(dp));
        this.deleted = json.deleted;
    }
}

export class CapacityConfig {
    chassis: ChassisCapacityConfig;
    shelves: ShelfCapacityConfig[];

    constructor(json: any) {
        if (!json) {
            return;
        }
        this.chassis = new ChassisCapacityConfig(json.chassis);
        this.shelves = json.shelves.map(shelf => new ShelfCapacityConfig(shelf));
    }
}
