import { Component, Input } from '@angular/core';
import { isFormattedFAC, isFormattedFAXL, isXCR4 } from '../../../../utils/marketing';

@Component({
    selector: 'model-bezel',
    templateUrl: './model-bezel.component.html',
})
export class ModelBezelComponent {
    @Input() readonly displayModel: string;
    @Input() readonly width: number = 245;

    getBezelModelImage(): string {
        if (isFormattedFAC(this.displayModel)) {
            return isXCR4(this.displayModel) ? '/images/fa-x-c-e-min.png' : '/images/fa-c-min.png';
        } else if (isFormattedFAXL(this.displayModel)) {
            return '/images/fa-xl-min.png';
        } else {
            return isXCR4(this.displayModel) ? '/images/fa-x-c-e-min.png' : '/images/fa-x-min.png';
        }
    }
}
