import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityQuote, OpportunityQuoteService, QuoteStatus } from '@pure1/data';

enum State {
    DEFAULT = 'DEFAULT',
    COMPLETED = 'COMPLETED',
    ERROR = 'ERROR',
}

@Component({
    selector: 'quote-request-changes-modal',
    templateUrl: './quote-request-changes-modal.component.html',
})
export class QuoteRequestChangesModalComponent implements OnChanges {
    @Input() readonly opportunityQuote: OpportunityQuote;
    @Input() readonly modal: NgbActiveModal;
    @Input() readonly requestChangeAllowed: boolean;

    @Output() readonly submitChangesComplete = new EventEmitter<void>();

    readonly State = State;
    state: State = State.DEFAULT;

    readonly CHAR_LIMIT = 125;

    requestChanges = '';
    isReadonly = true;
    isLoading = false;

    constructor(private opportunityQuoteService: OpportunityQuoteService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.opportunityQuote) {
            this.isReadonly = !(
                this.opportunityQuote?.status === QuoteStatus.OPEN ||
                this.opportunityQuote?.status === QuoteStatus.ACTION_REQUIRED
            );
            this.requestChanges = this.opportunityQuote?.partnerComment || '';
        }
    }

    isSubmitDisabled(): boolean {
        return (
            this.isLoading ||
            this.isReadonly ||
            this.requestChanges.length === 0 ||
            this.requestChanges.length > this.CHAR_LIMIT ||
            !this.requestChangeAllowed
        );
    }

    onSubmit(): void {
        if (this.isSubmitDisabled()) {
            return;
        }

        this.submitChangesComplete.emit();
        this.isLoading = true;
        this.opportunityQuoteService.requestChanges(this.opportunityQuote, this.requestChanges).subscribe({
            next: () => {
                this.state = State.COMPLETED;
                this.isLoading = false;
            },
            error: () => {
                this.state = State.ERROR;
                this.isLoading = false;
            },
        });
    }
}
