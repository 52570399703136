import { Resource } from '../interfaces/resource';

export class HardwareModels implements Resource {
    id: string;
    name: string;
    faXmodels: string[];
    faCmodels: string[];
    faEmodels: string[];

    constructor(json: any) {
        this.id = 'hardware-models';
        this.name = 'hardware-models';
        this.faCmodels = json.fa_c;
        this.faXmodels = json.fa_x;
        this.faEmodels = json.fa_e;
    }
}
