import moment from 'moment';
import { HourlyAggregation } from './hourly-aggregation';

export class PodPerformanceAggregation {
    data: any;
    read_bandwidth: HourlyAggregation;
    write_bandwidth: HourlyAggregation;
    read_iops: HourlyAggregation;
    write_iops: HourlyAggregation;
    read_latency: HourlyAggregation;
    write_latency: HourlyAggregation;
    aggregation_time: moment.Moment;
    latest_metric_time: moment.Moment;

    constructor(json: any) {
        this.aggregation_time = moment(json.aggregation_time);
        this.latest_metric_time = moment(json.latest_metric_time);
        this.read_bandwidth = new HourlyAggregation(json.data.read_bandwidth);
        this.write_bandwidth = new HourlyAggregation(json.data.write_bandwidth);
        this.read_iops = new HourlyAggregation(json.data.read_iops);
        this.write_iops = new HourlyAggregation(json.data.write_iops);
        this.read_latency = new HourlyAggregation(json.data.read_latency);
        this.write_latency = new HourlyAggregation(json.data.write_latency);
        this.data = json.data;
    }
}
