<button type="button" class="close modal-x-button safemode-enable-close-button" (click)="cancel()">
    <pureui-svg class="pstg-action-icon" svg="x-action.svg" [height]="12"></pureui-svg>
</button>

<safemode-intro
    *ngIf="introDisplayed"
    [isOrgCompliant]="isOrgCompliant"
    [loading]="loading"
    [hasSNOWContact]="hasSNOWContact"
    (proceed)="introDisplayed = false"
></safemode-intro>

<div *ngIf="!introDisplayed" class="upgrade-scheduler-wrapper">
    <aw-wizard
        *ngIf="!submitting && !submitted"
        #wizard
        navBarLayout="small"
        [defaultStepIndex]="timeslotsConflictFlag ? 1 : 0"
        class="pure-common-wizard"
    >
        <aw-wizard-step stepTitle="Select your appliances">
            <div class="select-appliances-step">
                <div class="container selected-appliances">
                    <div class="row h-100">
                        <div class="selected-arrays col-md-6">
                            <div class="selection-label">Selected Appliances</div>
                            <div *ngIf="selectedArrays.length > 0; else emptySelection">
                                <div class="array-list">
                                    <div *ngFor="let array of selectedArrays">
                                        <button
                                            type="button"
                                            class="remove-button"
                                            (click)="removeAppliance(array.appliance_id)"
                                        >
                                            <pureui-svg
                                                class="pstg-action-icon"
                                                svg="x-action.svg"
                                                [height]="12"
                                            ></pureui-svg>
                                        </button>
                                        {{ array.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="btn btn-secondary" (click)="selectAppliancesModalShow()">
                                <pureui-svg
                                    class="pstg-action-icon add-user-icon"
                                    svg="create_add.svg"
                                    height="12"
                                ></pureui-svg>
                                <span>Add Appliances</span>
                            </div>
                        </div>
                        <div *ngIf="selectedProductLine === productLine.FlashArray" class="selected-pgroups col-md-6">
                            <div class="selection-label">Selected Protection Groups</div>
                            <div *ngIf="selectedPGroups.length > 0; else emptySelection">
                                <div class="array-list">
                                    <div *ngFor="let pGroup of selectedPGroups">
                                        <button type="button" class="remove-button" (click)="removePgroup(pGroup.id)">
                                            <pureui-svg
                                                class="pstg-action-icon"
                                                svg="x-action.svg"
                                                [height]="12"
                                            ></pureui-svg>
                                        </button>
                                        <safemode-pgroup
                                            [pgroupId]="pGroup.id"
                                            [applianceName]="pGroup.arrays[0].name"
                                        ></safemode-pgroup>
                                    </div>
                                </div>
                            </div>
                            <div class="btn btn-secondary" (click)="selectPGroupsModalShow()">
                                <pureui-svg
                                    class="pstg-action-icon add-user-icon"
                                    svg="create_add.svg"
                                    height="12"
                                ></pureui-svg>
                                <span>Add Protection Groups</span>
                            </div>
                        </div>

                        <div *ngIf="selectedProductLine === productLine.FlashBlade" class="selected-pgroups col-md-6">
                            <div class="selection-label">Selected Storage type</div>
                            <hive-checkbox
                                [checked]="fileStorageTypeSelected"
                                (checkedChange)="fileStorageTypeSelected = $event"
                            >
                                File system
                            </hive-checkbox>

                            <hive-checkbox
                                [checked]="objectStorageTypeSelected"
                                (checkedChange)="objectStorageTypeSelected = $event"
                            >
                                Object store
                            </hive-checkbox>
                        </div>
                        <ng-template #emptySelection>
                            <span class="empty-selection">- None -</span>
                        </ng-template>
                    </div>
                </div>

                <div class="container select-operation">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="section-label">SafeMode Status</div>
                            <div [hidden]="statusEditMode" class="value">
                                <span>{{ currentStatus }}</span>
                                <div class="editIcon" (click)="statusEditMode = true">
                                    <pureui-svg
                                        svg="edit-pen.svg"
                                        (click)="statusEditMode = true"
                                        height="12"
                                    ></pureui-svg>
                                </div>
                            </div>

                            <select
                                [hidden]="!statusEditMode"
                                class="input-sm custom-select select-time-range"
                                [(ngModel)]="safemodeEnablement"
                                container="body"
                            >
                                <option [ngValue]="true">Enable</option>
                                <option [ngValue]="false">Disable</option>
                            </select>
                            <div class="note"
                                >SafeMode prevents early or unwanted eradication of data. Enabling SafeMode and
                                increasing the eradication timer may require more capacity than previously planned.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="section-label">Eradication Timer</div>
                            <div [hidden]="eradicationEditMode" class="value">
                                <span>{{ currentEradication }}</span>
                                <div class="editIcon" (click)="eradicationEditMode = true">
                                    <pureui-svg
                                        svg="edit-pen.svg"
                                        (click)="eradicationEditMode = true"
                                        height="12"
                                    ></pureui-svg>
                                </div>
                            </div>
                            <select
                                [hidden]="!eradicationEditMode"
                                class="input-sm custom-select select-time-range"
                                [(ngModel)]="eradicationDays"
                                container="body"
                            >
                                <option *ngFor="let day of eradicationOptions" [ngValue]="day">{{
                                    daysString(day)
                                }}</option>
                            </select>
                            <div class="note">Eradication timer helps protect your data under ransomware attack.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-buttons btn-toolbar clearfix">
                <button
                    type="button"
                    class="btn btn-primary float-xs-right margin"
                    [disabled]="
                        (!eradicationEditMode && !statusEditMode) ||
                        (selectedArrays.length === 0 && selectedPGroups.length === 0) ||
                        (selectedProductLine === productLine.FlashBlade &&
                            !(objectStorageTypeSelected || fileStorageTypeSelected))
                    "
                    (click)="showSchedule()"
                >
                    Schedule &gt;
                </button>
                <button type="button" class="btn btn-info float-xs-right margin" (click)="cancel()"> Cancel </button>
            </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Schedule an appointment">
            <safemode-planner
                [productLine]="selectedProductLine"
                [timeSlotConflict]="timeslotsConflictFlag"
                [encourageCustomerToCallSupport]="encourageCustomerToCallSupport"
                (onBack)="showStep(0)"
                (onCancel)="cancel()"
                (onForward)="doSchedule($event)"
            ></safemode-planner>
        </aw-wizard-step>
    </aw-wizard>
    <div *ngIf="submitting || submitted">
        <div *ngIf="submitting" class="col-xs-12 waiting">
            <h4>Please wait while we process your request.</h4>
            <br />
            <div class="progress-wrapper">
                <ngb-progressbar
                    [value]="100"
                    [max]="100"
                    type="info"
                    [striped]="true"
                    [animated]="true"
                    height="24px"
                    class="bar"
                ></ngb-progressbar>
            </div>
        </div>
        <safemode-enable-scheduled-confirmation-multiparty-auth
            *ngIf="submitted"
            [newRequest]="newRequest"
            (onDismiss)="cancel()"
            [createCaseDoneFlag]="createCaseDoneFlag"
            [createCaseErrorFlag]="createCaseErrorFlag"
        ></safemode-enable-scheduled-confirmation-multiparty-auth>
    </div>
</div>
