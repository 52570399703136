import {
    Component,
    Input,
    Inject,
    ViewChild,
    ElementRef,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    Renderer2,
} from '@angular/core';
import { UnifiedArray, UnifiedArrayService, IArrayRequestedAddress, IGeolocation } from '@pure1/data';
import { GOOGLE_MAPS } from '../../../app/injection-tokens';
import { PlottedArray } from '../arrays-map-view/arrays-map-view.component';
import { DOCUMENT } from '@angular/common';
import { GoogleMapsService, Panorama } from '../../../services/google-maps.service';
import { Angulartics2 } from 'angulartics2';
import { HttpErrorResponse } from '@angular/common/http';

type GoogleMaps = typeof google.maps;

enum ERROR {
    DENY_LIST,
    REQUEST,
    SEARCH,
    NO_CHANGE,
    GOOGLE_MAPS,
    NON_ROOFTOP_ADDRESS,
}

const STREETVIEW_CLASS = 'address-street-view';
// When modifying this list, also make sure to change the deny-list in the backend:
// spog/spog/src/main/java/com/purestorage/dao/rest/GeocodingDaoImpl.java
const COUNTRY_DENY_LIST = ['Cuba', 'CU', 'Iran', 'IR', 'North Korea', 'KP', 'Sudan', 'SD', 'Syria', 'SY'];
const COORDINATE_SIG_FIG = 4;
const ERROR_MSG: Error[] = [
    {
        header: `This location is under a trade embargo. This move is prohibited. Please change your entry.`,
        errorCode: ERROR.DENY_LIST,
    },
    {
        header: `There was an error submitting the request. Please try again later.`,
        errorCode: ERROR.REQUEST,
    },
    {
        header: `We weren't able to locate the address you entered.`,
        body: `Make sure your search is spelled and formatted properly.\n
            If your address is correct and we were unable to locate it, click submit to update the address.`,
        errorCode: ERROR.SEARCH,
    },
    {
        header: `No address change detected.`,
        errorCode: ERROR.NO_CHANGE,
    },
    {
        header: `Cannot load Google Maps`,
        errorCode: ERROR.GOOGLE_MAPS,
    },
    {
        header: 'This address cannot be validated automatically.',
        body: 'Verify your entry is correct. If the entry is correct and still not validating, contact Pure Technical Services.',
        errorCode: ERROR.NON_ROOFTOP_ADDRESS,
    },
];

interface Error {
    header: string;
    body?: string;
    errorCode: ERROR;
}

@Component({
    selector: 'update-address',
    templateUrl: './update-address.component.html',
})
export class UpdateAddressComponent implements OnInit, OnDestroy {
    @Input() readonly selectedArraysList: PlottedArray[] = [];
    @Output() readonly closeUpdateForm = new EventEmitter<void>();
    @Output() readonly confirmAddressChange = new EventEmitter<Partial<IArrayRequestedAddress>>();
    @ViewChild('searchElement') readonly searchElement: ElementRef;
    @ViewChild('streetView') readonly streetView: ElementRef;

    currentPlace: google.maps.places.PlaceResult;
    googleMaps: GoogleMaps;
    mapLoadingStatus: 'loading' | 'loaded' | 'err' = 'loading';
    multipleAddresses: boolean;
    panorama: Panorama;
    placesService: google.maps.places.PlacesService;
    streetViewFound = false;
    streetViewService: google.maps.StreetViewService;
    search: google.maps.places.Autocomplete;
    updating = false;

    // Manage state
    errorEnum = ERROR;
    errorOccurred: Error;
    initialSearchPerformed = false;

    // Analytics
    readonly analyticsPrefix = 'Map View - ';
    readonly analyticsStep = 'Workflow Step';
    readonly analyticsPreference = 'Address search preference';

    constructor(
        private angulartics2: Angulartics2,
        private googleMapsService: GoogleMapsService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document,
        @Inject(GOOGLE_MAPS) private googleMapsPromise: Promise<GoogleMaps>,
        private unifiedArrayService: UnifiedArrayService,
    ) {}

    ngOnInit(): void {
        this.googleMapsPromise
            .then(map => {
                this.mapLoadingStatus = 'loaded';
                this.googleMaps = map;
                this.initializeStreetView();
                this.initializeSearch();
                this.multipleAddresses = this.areMultipleAddressesSelected();
            })
            .catch(() => {
                // Setting mapLoadingStatus makes sure we hide UI elements that no longer make sense
                // Setting errorOccurred gives the UI the actual error message to display
                this.mapLoadingStatus = 'err';
                this.errorOccurred = ERROR_MSG[ERROR.GOOGLE_MAPS];
            });
    }

    ngOnDestroy(): void {
        this.googleMaps.event.clearInstanceListeners(this.search);
        // We'll also see the pac-container from the Map. We only want to pop off the one we added
        const dropdowns = Array.from(this.doc.body.querySelectorAll('.pac-container'));
        if (dropdowns.length > 0) {
            dropdowns.pop().remove();
        }
        this.renderer.removeChild(this.streetView.nativeElement, this.panorama.div);
        this.renderer.removeClass(this.panorama.div, STREETVIEW_CLASS);
        this.googleMapsService.releasePanorama(this.panorama);
    }

    initializeSearch(): void {
        const searchBox = this.searchElement.nativeElement as HTMLInputElement;
        this.search = new this.googleMaps.places.Autocomplete(this.searchElement.nativeElement);
        searchBox.value = this.selectedArraysList[0].address.street_address;
        this.search.addListener('place_changed', () => {
            this.initialSearchPerformed = true;
            const submittedPlace = this.search.getPlace();
            if (submittedPlace.geometry) {
                this.errorOccurred = null;
                this.checkDenyList(submittedPlace);
                this.currentPlace = submittedPlace;
                this.getStreetview(this.currentPlace.geometry.location);
                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + this.analyticsPreference,
                    properties: {
                        category: 'Action',
                        label: 'User selected address from autocomplete dropdown',
                    },
                });
                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + 'Google API calls',
                    properties: {
                        category: 'Action',
                        label: 'place searched',
                    },
                });
            } else {
                this.deepSearch();
                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + this.analyticsPreference,
                    properties: {
                        category: 'Action',
                        label: 'Deep search performed with enter key',
                    },
                });
            }
        });
        this.placesService = new this.googleMaps.places.PlacesService(this.searchElement.nativeElement);
    }

    initializeStreetView(): void {
        this.streetViewService = new this.googleMaps.StreetViewService();
        // Should be of type StreetViewPanoramaOptions, but @types/googlemaps library hasn't been updated with the
        // showRoadLabels field. The field is documented here:
        // https://developers.google.com/maps/documentation/javascript/reference/street-view#StreetViewPanoramaOptions
        const options = {
            addressControl: false,
            clickToGo: false,
            disableDefaultUI: true,
            panControl: false,
            scrollwheel: false,
            showRoadLabels: false,
        };
        this.panorama = this.googleMapsService.getPanorama(options);
        if (this.panorama) {
            this.renderer.appendChild(this.streetView.nativeElement, this.panorama.div);
            this.renderer.addClass(this.panorama.div, STREETVIEW_CLASS);
        }

        const geolocation = this.selectedArraysList[0].address.geolocation;
        const location = new this.googleMaps.LatLng(geolocation.latitude, geolocation.longitude);
        this.getStreetview(location);
    }

    checkDenyList(place: google.maps.places.PlaceResult): void {
        if (place.address_components) {
            const countryInfo = place.address_components.find(component => {
                return component.types.includes('country');
            });
            if (
                countryInfo &&
                (COUNTRY_DENY_LIST.includes(countryInfo.long_name) ||
                    COUNTRY_DENY_LIST.includes(countryInfo.short_name))
            ) {
                this.errorOccurred = ERROR_MSG[ERROR.DENY_LIST];
            }
        }
    }

    deepSearch(): void {
        this.updating = true;
        this.initialSearchPerformed = true;
        this.placesService.findPlaceFromQuery(
            {
                query: this.searchElement.nativeElement.value,
                fields: ['place_id'],
                locationBias: {
                    lat: this.selectedArraysList[0].address.geolocation.latitude,
                    lng: this.selectedArraysList[0].address.geolocation.longitude,
                },
            },
            (results, status) => {
                if (status === this.googleMaps.places.PlacesServiceStatus.OK && results.length > 0) {
                    const result = results[0];
                    // We need to do a second search to get the Country information to check if it's deny-listed
                    this.placesService.getDetails(
                        {
                            fields: ['address_components', 'geometry.location', 'formatted_address'],
                            placeId: result.place_id,
                        },
                        (details, status) => {
                            if (status === this.googleMaps.places.PlacesServiceStatus.OK) {
                                this.errorOccurred = null;
                                this.checkDenyList(details);
                                (this.searchElement.nativeElement as HTMLInputElement).value =
                                    details.formatted_address;
                                this.currentPlace = details;
                                this.getStreetview(details.geometry.location);
                            } else {
                                this.errorOccurred = ERROR_MSG[ERROR.SEARCH];
                            }
                            this.updating = false;

                            this.angulartics2.eventTrack.next({
                                action: this.analyticsPrefix + 'Google API calls',
                                properties: {
                                    category: 'Action',
                                    label: 'place searched',
                                },
                            });
                        },
                    );
                } else {
                    this.errorOccurred = ERROR_MSG[ERROR.SEARCH];
                    this.updating = false;
                }

                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + 'Google API calls',
                    properties: {
                        category: 'Action',
                        label: 'place searched',
                    },
                });
            },
        );
    }

    areMultipleAddressesSelected(): boolean {
        return !this.selectedArraysList.every(array => {
            return this.areGeolocationsSame(array.address.geolocation, this.selectedArraysList[0].address.geolocation);
        });
    }

    areGeolocationsSame(geo1: IGeolocation, geo2: IGeolocation): boolean {
        return (
            geo1.latitude.toFixed(COORDINATE_SIG_FIG) === geo2.latitude.toFixed(COORDINATE_SIG_FIG) &&
            geo1.longitude.toFixed(COORDINATE_SIG_FIG) === geo2.longitude.toFixed(COORDINATE_SIG_FIG)
        );
    }

    cancelClicked(): void {
        this.closeUpdateForm.emit();
    }

    updateClicked(): void {
        if (!this.initialSearchPerformed) {
            this.errorOccurred = ERROR_MSG[ERROR.NO_CHANGE];
            return;
        }
        if (!this.currentPlace && !this.errorOccurred) {
            this.errorOccurred = ERROR_MSG[ERROR.SEARCH];
            return;
        }

        // Get address and geolocation (if possible) for the request
        // Backend only looks at address. Geolocation is for moving the array on the map
        let address: string;
        let geolocation: IGeolocation;
        if (this.currentPlace && (!this.errorOccurred || this.errorOccurred.errorCode === ERROR.NO_CHANGE)) {
            address = this.currentPlace.formatted_address;
            geolocation = {
                latitude: this.currentPlace.geometry.location.lat(),
                longitude: this.currentPlace.geometry.location.lng(),
            };

            if (this.areGeolocationsSame(geolocation, this.selectedArraysList[0].address.geolocation)) {
                this.errorOccurred = ERROR_MSG[ERROR.NO_CHANGE];
                return;
            }

            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Address change submitted',
                properties: {
                    category: 'Action',
                    label: 'Google-verified address',
                },
            });
        } else {
            address = this.searchElement.nativeElement.value;
            geolocation = null;

            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Address change submitted',
                properties: {
                    category: 'Action',
                    label: 'Unverified address',
                },
            });
        }
        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + this.analyticsStep,
            properties: {
                category: 'Action',
                label: 'Submitted address',
            },
        });

        const ids = this.selectedArraysList.map(array => array.id);
        const request = {
            install_address_requested: {
                street_address: address,
                geolocation: geolocation,
            },
        } as Partial<UnifiedArray>;

        this.updating = true;
        this.unifiedArrayService.update(request, ids).subscribe(
            () => {
                this.updating = false;
                this.errorOccurred = null;
                this.confirmAddressChange.emit(request.install_address_requested);
            },
            (err: HttpErrorResponse) => {
                console.error('Failed to update array address', err);
                this.updating = false;

                const responseMessage = err.error?.message;
                if (err.status === 400 && responseMessage === 'The requested location is not a rooftop address.') {
                    this.errorOccurred = ERROR_MSG[ERROR.NON_ROOFTOP_ADDRESS];
                } else {
                    this.errorOccurred = ERROR_MSG[ERROR.REQUEST];
                }
            },
        );
    }

    getStreetview(location: google.maps.LatLng): void {
        if (!this.panorama) {
            return;
        }
        const locationRequest: google.maps.StreetViewLocationRequest = {
            location: location,
            source: this.googleMaps.StreetViewSource.OUTDOOR,
        };
        this.streetViewService.getPanorama(locationRequest, panoData => {
            if (panoData) {
                // Compute which direction to look
                const heading = this.googleMaps.geometry.spherical.computeHeading(panoData.location.latLng, location);
                const pov = this.panorama.view.getPov();
                pov.heading = heading;
                this.panorama.view.setPov(pov);
                this.panorama.view.setPosition(location);
                this.panorama.view.setVisible(true);
                this.streetViewFound = true;
            } else {
                this.panorama.view.setVisible(false);
                this.streetViewFound = false;
            }

            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Google API calls',
                properties: {
                    category: 'Action',
                    label: 'streetview changed',
                },
            });
        });
    }
}
