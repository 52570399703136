import moment from 'moment';

export function formatApproxDuration(from: number, to: number): IValueUnit {
    if (!(typeof from === 'number' && typeof to === 'number')) {
        return { value: '', unit: '' };
    }

    const duration = to - from;
    if (duration <= 0) {
        return { value: 'Now', unit: '' };
    } else {
        const weekInMs = moment.duration(1, 'w').asMilliseconds();
        const monthInMs = moment.duration(1, 'M').asMilliseconds();
        const yearsInMs = moment.duration(1, 'year').asMilliseconds();

        const weeks = duration / weekInMs;
        if (weeks < 1) {
            return { value: '< 1 week', unit: '' };
        } else if (weeks < 15) {
            const weeksRounded = Math.round(weeks);
            return { value: String(weeksRounded), unit: weeksRounded === 1 ? 'week' : 'weeks' };
        } else if (weeks < 52) {
            const months = Math.round(duration / monthInMs);
            return { value: String(months), unit: months === 1 ? 'month' : 'months' };
        } else {
            const years = Math.round(duration / yearsInMs);
            return { value: String(years), unit: years === 1 ? 'year' : 'years' };
        }
    }
}
