import { of, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { WINDOW } from '../../app/injection-tokens';

@Injectable() // Only works as a sandboxed service due to injecting Renderer2
export class FileDownloaderService {
    private static currentLinkId = 0;

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private http: HttpClient,
        private renderer: Renderer2,
    ) {}

    downloadUrl(url: string, filename: string): void {
        this.createPhantomLink(filename, url);
    }

    downloadBlob(blob: Blob, filename: string): void {
        this.createPhantomLink(filename, URL.createObjectURL(blob));
    }

    downloadCaseAttachment(caseId: string, attachment: CaseAttachment): Observable<void> {
        // we don't know if the file is actually uploaded to sfdc or only s3
        // try old approach first, if empty url then try thru Tesseract (secure download)

        const url = `/rest/v1/support/cases/${caseId}/attachments/${attachment.id}/contents3`;
        const filename = attachment.name;

        return this.http.get<any>(url, { params: { filename: filename } }).pipe(
            switchMap(response => {
                const s3Uri = response?.s3Uri;
                if (!s3Uri || s3Uri === '') {
                    return this.secureDownload(caseId, filename);
                } else {
                    this.downloadUrl(s3Uri, filename);
                    return of<void>(void 0);
                }
            }),
        );
    }

    private createPhantomLink(filename: string, url: string): void {
        const linkId = 'file-link-' + FileDownloaderService.currentLinkId++;
        const phantomLink: HTMLElement = this.renderer.createElement('a');
        this.renderer.setStyle(phantomLink, 'display', 'none');
        this.renderer.setAttribute(phantomLink, 'id', linkId);
        this.renderer.setAttribute(phantomLink, 'href', url);
        this.renderer.setAttribute(phantomLink, 'target', '_self');
        this.renderer.setAttribute(phantomLink, 'download', filename);
        this.document.body.append(phantomLink);

        const event = this.document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, this.window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        phantomLink.dispatchEvent(event);
        phantomLink.remove();
    }

    private secureDownload(caseId: string, filename: string): Observable<void> {
        const secureUrl = `/rest/v1/support/secure-uploads/attachments/download-url`;

        const secureRequestConfig = {
            params: {
                caseId,
                filename,
            },
        };

        return this.http.get<any>(secureUrl, secureRequestConfig).pipe(
            tap(response => {
                const url = response?.url;
                this.downloadUrl(url, filename);
            }),
        );
    }
}
