import { Pipe, PipeTransform } from '@angular/core';
import { SEVERITY_DATA, SeverityData } from '../components/alert-indicator/alert-indicator.data';

/*
 * Returns icon data according to severity level
 * Usage:
 *   value | alertIndicatorIconData
 * Example:
 *   'warning' | alertIndicatorIconData as icon
 *   <pureui-svg [svg]="icon.svg" [ngClass]="icon.class"></pureui-svg>
 *   Displays warning icon
 */
@Pipe({
    name: 'alertIndicatorIconData',
})
export class AlertIndicatorIconDataPipe implements PipeTransform {
    transform(severity: string): SeverityData {
        return SEVERITY_DATA[severity] || SEVERITY_DATA['none'];
    }
}
