import moment from 'moment';
import { Alert } from './Alert';
import { capitalize } from '../utils/capitalize';

export class FArrayAlert extends Alert implements FArrayAlert {
    category: string;
    categoryCapitalized: string;
    componentName: string;
    componentType: string;
    event: string;
    opened: moment.Moment;
    openedString: string;

    constructor(json: any) {
        super(json);

        this.category = String(json.category);
        this.categoryCapitalized = capitalize(this.category);
        this.arrayId = json.array_id ? String(json.array_id) : null;
        this.componentType = String(json.component_type);
        this.componentName = String(json.component_name);
        this.event = String(json.event);
        this.opened = moment(json.opened * 1000);
        this.openedString = this.opened.format('YYYY-MM-DD HH:mm:ss');
    }
}
