import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { View } from '../models/view';

@Injectable({ providedIn: 'root' })
export class ViewsService extends GenericService<View> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: View,
            defaultParams: {},
            endpoint: '/rest/v1/views',
            create: true,
            update: true,
            delete: true,
        });
    }
}
