import { Tag } from '@pure1/data';

export class TagChange {
    static insertedTag(tag: Tag, resourceId: string): TagChange {
        return new TagChange(null, tag.key, tag.namespace, null, tag.value, resourceId, tag.tag_organization_id);
    }

    static deletedTag(tag: Tag, resourceId: string): TagChange {
        return new TagChange(tag.key, null, tag.namespace, tag.value, null, resourceId, tag.tag_organization_id);
    }

    static updatedTag(tag: Tag, resourceId: string, key: string, value: string): TagChange {
        return new TagChange(tag.key, key, tag.namespace, tag.value, value, resourceId, tag.tag_organization_id);
    }

    constructor(
        readonly oldKey: string,
        readonly newKey: string,
        readonly namespace: string,
        readonly oldValue: string | null,
        readonly newValue: string | null,
        readonly resourceId: string,
        readonly tag_organization_id: number,
    ) {
        console.assert(
            oldKey === null || newKey === null || oldKey.toLowerCase() === newKey.toLowerCase(),
            'Cannot update tag with different key other than difffernt cases',
        );
    }

    toNewTag(): Tag {
        if (this.newValue) {
            return new Tag({
                namespace: this.namespace,
                resource_id: this.resourceId,
                tag_organization_id: this.tag_organization_id,
                key: this.newKey,
                value: this.newValue,
            });
        } else {
            return null;
        }
    }

    toOldTag(): Tag {
        if (this.oldValue) {
            return new Tag({
                namespace: this.namespace,
                resource_id: this.resourceId,
                tag_organization_id: this.tag_organization_id,
                key: this.oldKey,
                value: this.oldValue,
            });
        } else {
            return null;
        }
    }
}
