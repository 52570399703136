<title-with-switch [currentUser]="currentUser"></title-with-switch>
<stateless-submenu-bar [submenuItems]="submenuItems" (selectedItemChanged)="selectedSubmenuItemChanged($event)">
</stateless-submenu-bar>
<ng-container *ngIf="selectedSubmenuItem.title === 'User Details'">
    <div id="form" class="edit-form" *ngIf="status === 'form'">
        <div class="um-body">
            <um-user-form
                *ngIf="!currentUser.adUser; else ssoUser"
                [currentUser]="currentUser"
                (userSaved)="onUserSaved($event)"
                (cancelled)="onCancelled()"
                (error)="onError($event)"
            >
            </um-user-form>

            <ng-template #ssoUser>
                <um-sso-user-form [currentUser]="currentUser" (cancelled)="onCancelled()"></um-sso-user-form>
            </ng-template>
        </div>
        <div class="um-footer">
            <log-off-link></log-off-link>
            <a id="reset-password-link" (click)="resetPassword()" *ngIf="!currentUser.adUser">Reset Password</a>
        </div>
    </div>
    <div id="error" class="um-body result-message error" *ngIf="status === 'error'">
        <pureui-svg class="pstg-secondary-icon result-icon" [svg]="'escalation.svg'" [height]="74"></pureui-svg><br />
        <h2>Permissions error, please contact support.</h2>
    </div>
    <div
        id="success-reset-password"
        class="um-body result-message success-reset-password"
        *ngIf="status === 'successResetPassword'"
    >
        <div class="result-reset-password-name">{{ currentUser.name }}</div>
        <div class="result-reset-password-question"
            >An email was sent to<br /><span class="result-reset-password-email">{{ currentUser.email }}</span
            ><br />with instructions on how<br />to reset the password.</div
        >
        <button ng-click="goBack()" class="btn btn-info result-delete-reject">OK</button>
    </div>
</ng-container>
<account-security-section *ngIf="selectedSubmenuItem.title === 'Account Security'" [currentUser]="currentUser">
</account-security-section>
