import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { CurrentUser } from '../models/current-user';
import { IRestResponse } from '../interfaces/collection';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class CurrentUserService extends GenericService<CurrentUser> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: CurrentUser,
            endpoint: '/rest/v1/user',
        });
    }
}
