import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';

import { GfbComponent } from './gfb/gfb.component';
import { GfbEditorComponent } from './gfb-editor/gfb-editor.component';
import { GfbPillComponent } from './gfb-pill/gfb-pill.component';
import { UIModule } from '../ui/ui.module';

@NgModule({
    imports: [Angulartics2Module, CommonModule, UIModule],
    declarations: [GfbComponent, GfbEditorComponent, GfbPillComponent],
    exports: [GfbComponent, GfbPillComponent],
})
export class GfbModule {}
