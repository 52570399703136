import moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ImmutableTimeRange } from '../../../model/ImmutableTimeRange';

export type TimeRangeListOption = {
    text: string;
    duration: moment.Duration;
};

export interface CustomTimeRange {
    start: moment.Moment;
    end: moment.Moment;
}

interface CustomNgbDateRange {
    start?: NgbDate;
    end?: NgbDate;
    hover?: NgbDate;
}

export type TimeRangeSelectChoice = TimeRangeListOption | CustomTimeRange;

export function isCustomTimeRange(option: TimeRangeSelectChoice): option is CustomTimeRange {
    return (<TimeRangeListOption>option).text === null || (<TimeRangeListOption>option).text === undefined;
}

export function timeRangeSelectChoiceToString(option: TimeRangeSelectChoice, displayPrefix: string): string {
    if (option) {
        if (isCustomTimeRange(option)) {
            const duration = moment.duration(option.end.diff(option.start));
            return `${displayPrefix}${option.start.format('YYYY-MM-DD')} to ${option.end.format('YYYY-MM-DD')} (${duration.humanize()})`;
        } else {
            return option.text;
        }
    }
    return '';
}

@Component({
    selector: 'calendar-time-range-select',
    templateUrl: 'calendar-time-range-select.component.html',
})
export class CalendarTimeRangeSelectComponent implements OnInit, OnChanges {
    @Input() readonly options: TimeRangeListOption[];
    @Input() readonly selected: TimeRangeSelectChoice;
    @Input() readonly extremeTimeRange: ImmutableTimeRange;
    @Input() readonly maxTimeRangeDuration: moment.Duration;
    @Input() readonly inclusiveEndDate = true;
    @Input() readonly showReset: boolean = false;
    @Input() readonly isUTCTime: boolean = false;
    @Input() readonly displayPrefix: string = '';
    @Input() readonly timeRangeSelectDisabled: boolean = false;

    /**
     * If set to true, will enable a "custom" calendar datepicker, useful for
     * longer time range selection.
     */
    @Input() readonly enableCustomChoice: boolean = true;

    @Input() readonly customLabel: string = null;

    @Output() readonly selectionChange = new EventEmitter<TimeRangeSelectChoice>();

    @ViewChild('dropDown', { static: true }) readonly dropDown: NgbDropdown;

    customDisplayedMonth: NgbDate;
    ngbDateRange: CustomNgbDateRange = { start: undefined, end: undefined };
    isCustom: boolean;
    isReset = false;

    private selectedTimeRange: TimeRangeListOption;
    private customRange: CustomTimeRange;

    // Years to display
    jumpYears: number[] = [];

    constructor(private ngbCalendar: NgbCalendar) {}

    ngOnInit(): void {
        this.updateSelection();
        this.initializeNgbDateRange();
        // Because we show 2 months, we should start with (currentMonth - 1)
        this.navigateCalendar(this.ngbCalendar.getPrev(this.ngbCalendar.getToday(), 'm', 1));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selected) {
            this.updateSelection();
        }
        if (changes.extremeTimeRange) {
            const startYear = this.extremeTimeRange?.getStartTime()?.year();
            const endYear = this.extremeTimeRange?.getEndTime()?.year();

            if (startYear && endYear) {
                const jumpYears: number[] = [];
                for (let y = startYear; y <= endYear; y++) {
                    jumpYears.push(y);
                }

                // If there's less than 2 years, don't show the jumper
                this.jumpYears = jumpYears.length > 2 ? jumpYears : [];
            } else {
                this.jumpYears = [];
            }
        }
    }

    navigateCalendar(newDate: NgbDate): void {
        this.customDisplayedMonth = newDate;
    }

    isSelected(option: TimeRangeListOption): boolean {
        return !this.isCustom && this.selectedTimeRange && option.text === this.selectedTimeRange.text;
    }

    getSelectionName(): string {
        if (this.isCustom && this.customRange) {
            return timeRangeSelectChoiceToString(this.customRange, this.displayPrefix);
        }
        if (this.enableCustomChoice && this.customLabel) {
            this.isCustom = true;
            return this.customLabel;
        }
        return timeRangeSelectChoiceToString(this.selectedTimeRange, this.displayPrefix);
    }

    clickSelection(option: TimeRangeListOption): void {
        this.isReset = false;
        this.isCustom = false;
        this.selectedTimeRange = option;
        this.selectionChange.emit(option);
        this.dropDown.close();
    }

    resetCustomPicker(): void {
        this.isReset = true;
        this.ngbDateRange = { start: null, end: null };
        this.customRange = null;
        this.selectionChange.emit(this.customRange);
    }

    showCustomPicker(): void {
        this.isCustom = true;
    }

    dropdownOpenChange(open: boolean): void {
        if (!open) {
            if (this.isCustom && !this.ngbDateRange.end) {
                // if we were in the middle of selecting a custom range and then closed the dropdown, then abandon
                // the custom selection
                this.customRange = null;
                this.isCustom = false;
            }
        }
    }

    doneClicked(): void {
        if (this.isReset) {
            this.customRange = null;
        } else {
            this.customRange = {
                start: this.ngbDateToMoment(this.ngbDateRange.start),
                end: this.ngbDateToMoment(this.ngbDateRange.end, this.inclusiveEndDate),
            };
        }
        this.selectionChange.emit(this.customRange);
        this.dropDown.close();
    }

    doneDisabled(): boolean {
        return !this.ngbDateRange.end;
    }

    shiftMonths(amount: number): void {
        this.navigateCalendar(this.ngbCalendar.getNext(this.customDisplayedMonth, 'm', amount));
    }

    shiftToToday(): void {
        this.navigateCalendar(this.ngbCalendar.getToday());
    }

    momentToNgbDateStruct(date: moment.Moment): NgbDateStruct {
        return { day: date.date(), month: date.month() + 1, year: date.year() };
    }

    momentToNgbDate(date: moment.Moment): NgbDate {
        return new NgbDate(date.year(), date.month() + 1, date.date());
    }

    ngbDateToMoment(date: NgbDate, inclusive?: boolean): moment.Moment {
        // because of how we convert the date to a moment, 1/1 becomes 1/1 12:00 AM, which means if we query with that
        // range it will exclude that day, so optionally add more time to make it inclusive
        const dateObject = { month: date.month - 1, day: date.day, year: date.year };
        if (inclusive) {
            const hours = { hours: 23, minutes: 59, seconds: 59 };
            Object.assign(dateObject, hours);
        }
        return this.isUTCTime ? moment.utc(dateObject) : moment(dateObject);
    }

    selectCustomDate(date: NgbDate): void {
        this.isReset = false;
        const range = this.ngbDateRange;
        // Set start date if it isn't set, if both are set
        if (!range.start || range.end) {
            this.ngbDateRange = { start: date, end: null };
        } else {
            if (date.after(range.start)) {
                if (!this.isOutOfRange(date)) {
                    this.ngbDateRange.end = date;
                }
                // If the user chooses a out-of-range date, don't do anything.
            } else {
                // If the selected date comes before the end date, use it as the
                // start instead
                this.ngbDateRange.start = date;
            }
        }
    }

    isInsideRange(date: NgbDate, range: CustomNgbDateRange): boolean {
        // Nothing is selected
        if (!range.start) {
            return false;
        }
        // Both start and end are selected
        if (range.end) {
            return date.after(range.start) && date.before(range.end);
        }
        // Start is selected, and the user is hovering over a date
        if (range.hover) {
            return date.after(range.start) && date.before(range.hover);
        }
        return false;
    }

    isOutOfRange(date: NgbDate): boolean {
        // When a maximum range is set, and we are in the middle of a selection,
        // only show those dates as selectable.
        if (!this.maxTimeRangeDuration || this.ngbDateRange.end || !this.ngbDateRange.start) {
            return false;
        }

        const curDate = this.ngbDateToMoment(date);
        const maxDate = this.ngbDateToMoment(this.ngbDateRange.start).add(this.maxTimeRangeDuration);

        return curDate.isAfter(maxDate);
    }

    shiftMonthRightDisabled(): boolean {
        const rightMonth = this.ngbDateToMoment(this.ngbCalendar.getNext(this.customDisplayedMonth, 'm', 1)).startOf(
            'month',
        );
        const endMonth = this.extremeTimeRange.getEndTime().startOf('month');
        return rightMonth.isSameOrAfter(endMonth);
    }

    shiftMonthLeftDisabled(): boolean {
        const leftMonth = this.ngbDateToMoment(this.customDisplayedMonth).startOf('month');
        const startMonth = this.extremeTimeRange.getStartTime().startOf('month');
        return leftMonth.isSameOrBefore(startMonth);
    }

    getMaxNgbDate(): NgbDateStruct {
        return this.momentToNgbDateStruct(this.extremeTimeRange.getEndTime());
    }

    getMinNgbDate(): NgbDateStruct {
        return this.momentToNgbDateStruct(this.extremeTimeRange.getStartTime());
    }

    private updateSelection() {
        if (this.selected) {
            if (isCustomTimeRange(this.selected)) {
                this.customRange = this.selected;
                this.selectedTimeRange = null;
                this.isCustom = true;
            } else {
                this.selectedTimeRange = this.selected;
                this.customRange = null;
                this.isCustom = false;
            }
        }
    }

    private initializeNgbDateRange(): void {
        // make sure to call updateSelection first
        if (this.isCustom) {
            this.ngbDateRange = {
                start: this.momentToNgbDate(this.customRange.start),
                end: this.momentToNgbDate(this.customRange.end),
            };
        }
    }

    trackByValue(_: number, item: any): any {
        return item;
    }

    jumpToYear(year: number): void {
        // 0 = January cause moment
        let jumpTo = moment([year, 0, 1]);
        const minDate = this.extremeTimeRange?.getStartTime();
        const maxDate = this.extremeTimeRange?.getEndTime();

        // Check that we don't jump past the extreme dates
        if (minDate) {
            jumpTo = moment.max(jumpTo, minDate);
        }
        if (maxDate) {
            jumpTo = moment.min(jumpTo, maxDate);
        }

        this.navigateCalendar(this.momentToNgbDate(jumpTo));
    }
}
