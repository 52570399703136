import { Component, Input } from '@angular/core';
import moment from 'moment-timezone';
import {
    ApplianceStorageType,
    SafeModeRequestDetails,
    SafeModeResource,
    SafeModeStatusEnablement,
} from '../../services/safemode-multiparty-authorization.service';

@Component({
    selector: 'safemode-change-request-details',
    templateUrl: './safemode-change-request-details.component.html',
})
export class SafemodeChangeRequestDetailsComponent {
    @Input() readonly resources: SafeModeResource[];
    @Input() readonly changeInParameters: SafeModeRequestDetails;
    @Input() readonly executionTime: moment.Moment;
    @Input() readonly flexDirection: string = 'row';
    border = '1px solid #E7E7E7';

    getSafeModeChange(): string {
        if (this.changeInParameters.newSafeModeStatus === SafeModeStatusEnablement.UNCHANGED) {
            return '(do not change SafeMode settings)';
        } else if (this.changeInParameters.newSafeModeStatus === SafeModeStatusEnablement.ENABLED) {
            return 'Enable SafeMode';
        } else if (this.changeInParameters.newSafeModeStatus === SafeModeStatusEnablement.DISABLED) {
            return 'Disable SafeMode';
        }
        return '';
    }

    getChangeInEradicationTime(): string {
        if (this.changeInParameters.newExpirationInDays) {
            return `Set eradication to: ${this.changeInParameters.newExpirationInDays} days`;
        } else {
            return '(do not change eradication timer)';
        }
    }

    getStorageTypes(storageTypes: ApplianceStorageType[]): string {
        if (!storageTypes?.length) {
            return '';
        }
        const result: string[] = [];
        if (storageTypes.includes(ApplianceStorageType.FILE_SYSTEMS)) {
            result.push('File system');
        }
        if (storageTypes.includes(ApplianceStorageType.OBJECT_STORAGE)) {
            result.push('Object store');
        }

        return `(${result.join(', ')})`;
    }
}
