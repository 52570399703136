import { Resource } from '../interfaces/resource';

export class PGroupLocationReference {
    flashArrayId: string;
    fqdn: string;
    id: string;
    name: string;
    resourceType: string;

    constructor(json: any) {
        this.flashArrayId = json.flashArrayId || json.flash_array_id;
        this.fqdn = json.fqdn;
        this.id = json.id;
        this.name = json.name;
        this.resourceType = json.resourceType || json.resource_type;
    }
}

export class PGroupSafemode implements Resource {
    name: string;
    id: string;
    arrays: PGroupLocationReference[];
    retentionLock: string;
    volumeCount: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.arrays = json.arrays.map((array: any) => new PGroupLocationReference(array));
        this.retentionLock = json.retentionLock || json.retention_lock;
        this.volumeCount = json.volumeCount || json.volume_count;
    }
}
