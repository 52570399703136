<div class="draas-banners-container">
    <banners></banners>
</div>
<div class="job-execution-monitor">
    <pureui-spinner [pureuiShow]="loadingCluster" class="draas-spinner"></pureui-spinner>
    <draas-landing *ngIf="!loadingCluster && !clusterBootstrapped"></draas-landing>

    <ng-container *ngIf="!loadingCluster && clusterBootstrapped">
        <collapsible-panel
            *ngIf="runningJobExecutions.length > 0"
            [groupId]="type"
            itemId="panel-inflight"
            header="Inflight Executions"
        >
            <job-execution-list-item
                *ngFor="let jobExecution of runningJobExecutions; trackBy: 'id' | trackByProperty"
                [jobExecution]="jobExecution"
                [type]="type"
                [selectedVmJobExecution]="selectedVmJobExecutionOrNull"
                (selectJobExecution)="selectJobExecution($event)"
                [isV2Cluster]="isV2Cluster"
            ></job-execution-list-item>
        </collapsible-panel>

        <collapsible-panel [groupId]="type" itemId="panel-history" header="Execution History">
            <div class="action-buttons">
                <pureui-pagination
                    [totalItemCount]="finishedJobExecutionsTotal"
                    [itemsPerPage]="finishedJobExecutionsPageSize"
                    [offset]="finishedJobExecutionsOffset"
                    (pageChange)="onPageChange($event)"
                >
                </pureui-pagination>

                <div class="select-time-interval">
                    <calendar-time-range-select
                        [options]="timeRangeOptions"
                        [extremeTimeRange]="extremeTimeRange"
                        [selected]="selectedTimeRangeOption"
                        (selectionChange)="selectTimeRange($event)"
                    ></calendar-time-range-select>
                </div>
            </div>

            <pureui-spinner [pureuiShow]="loadingFinishedJobExecutions" class="draas-spinner"></pureui-spinner>

            <ng-container *ngIf="finishedJobExecutions.length > 0">
                <job-execution-list-item
                    *ngFor="let jobExecution of finishedJobExecutions; trackBy: 'id' | trackByProperty"
                    [jobExecution]="jobExecution"
                    [type]="type"
                    [selectedVmJobExecution]="selectedVmJobExecutionOrNull"
                    (selectJobExecution)="selectJobExecution($event)"
                    (deselectJobExecution)="closeExecutionStepsPanel(false)"
                    [isV2Cluster]="isV2Cluster"
                ></job-execution-list-item>
            </ng-container>

            <div *ngIf="finishedJobExecutions.length === 0 && !loadingFinishedJobExecutions" class="no-items-message">
                <ng-container>No job executions in the selected time range.</ng-container>
            </div>
        </collapsible-panel>

        <job-execution-steps-panel
            [vmJobExecution]="selectedVmJobExecutionOrNull"
            [parentJobExecution]="selectedParentJobExecutionOrNull"
            [isOpen]="isExecutionStepsPanelOpen"
            [type]="type"
            [isV2Cluster]="isV2Cluster"
            (close)="closeExecutionStepsPanel(true)"
        ></job-execution-steps-panel>
    </ng-container>
</div>
