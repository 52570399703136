import moment from 'moment';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, map, take } from 'rxjs';
import {
    convertToAmpliSubscriptionProgramTypeConfig,
    isSubscriptionActive,
    License,
    LicenseService,
    ProgramType,
    QuoteStage,
    QuoteType,
    ServiceCatalogQuote,
    ServiceCatalogQuoteServiceV4,
    SortParams,
    Subscription,
    SubscriptionService,
} from '@pure1/data';
import { SelectionEvent } from '@pure/hive';

import { TableColumn } from '../subscription-page-table/subscription-page-table.component';
import {
    addFilter,
    DEFAULT_NAMESPACE,
    LOCAL_BAR_SUBSCRIPTIONS,
    NAME_KEY,
    ORDERS_ENTITY,
    removeFiltersByKey,
    SUBSCRIPTION_ENTITY,
} from '../../redux/actions';
import { IState, IStateFilter } from '../../redux/pure-redux.service';
import { createFilter, getFiltersUrlParams } from '../../redux/utils';
import { PENDING_ORDER_STAGES } from '../base-evergreen-view/base-evergreen-view.component';
import { NgRedux } from '../../redux/ng-redux.service';
import { ampli } from 'core/src/ampli';
import { Router } from '@angular/router';
import { FeatureNames } from '../../model/FeatureNames';

export const ChartDetails = {
    expired: {
        color: '#AF93CA',
        name: 'Expired',
        styling: 'expired',
    },
    expiresIn30Days: {
        color: '#E186B2',
        name: 'Expires in 30 days',
        styling: 'expiresIn30Days',
    },
    expiresIn120Days: {
        color: '#FBD87D',
        name: 'Expires in 120 days',
        styling: 'expiresIn120Days',
    },
    underContract: {
        color: '#7cd8cd',
        name: 'Under Contract',
        styling: 'underContract',
    },
};

export enum TableDropdownSelections {
    NEEDS_ATTENTION = 'Needs Attention',
    ALL_SUBSCRIPTIONS = 'All Subscriptions',
}

export type SubscriptionSummary = Subscription & {
    numLicenses: number;
    detailedStatus: string;
    actionLabel: string;
};

export const CYBER_RECOVERY_TOOLTIP_SUBSCRIPTIONS = 'Cyber Recovery and Resilience SLA was added to this subscription';

@Component({
    selector: 'evergreen-summary',
    templateUrl: './evergreen-summary.component.html',
})
export class EvergreenSummaryComponent implements OnChanges {
    @Input() readonly evergreenProgramTypes: ProgramType[];
    @ViewChild('renewSubscriptionModal') readonly renewSubscriptionModal: TemplateRef<any>;

    size = 210;
    innerSize = 95;
    dialSegments: IRadialDialSegment[] = [];
    pendingOrders = new Map<string, ServiceCatalogQuote[]>();
    loading = true;

    centerLabel: string;
    pendingOrdersBySubscription: ServiceCatalogQuote[];
    editableRenewalOrder: ServiceCatalogQuote;
    selectedSubscription: Subscription;

    subscriptions: SubscriptionSummary[];
    actionableSubscriptions: SubscriptionSummary[];
    licenses: License[];
    quotes: ServiceCatalogQuote[];
    columns: TableColumn<Subscription>[];

    expiredSubscriptions: number;
    expiringIn120Subscriptions: number;
    expiringIn30Subscriptions: number;
    underContractSubscriptions: number;
    cyberRecoverySubscriptions: number;
    selectedFilter: string;
    preSelectedFilter: string;
    filters: { label: string; value: string }[];

    sort: SortParams | null = null;
    TableDropdownSelections = TableDropdownSelections;
    FeatureNames = FeatureNames;

    readonly CYBER_RECOVERY_TOOLTIP = CYBER_RECOVERY_TOOLTIP_SUBSCRIPTIONS;

    constructor(
        private licenseService: LicenseService,
        private ngRedux: NgRedux<IState>,
        private modalService: NgbModal,
        private router: Router,
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV4,
        private subscriptionService: SubscriptionService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.evergreenProgramTypes && this.evergreenProgramTypes?.length > 0) {
            combineLatest([
                this.subscriptionService.list().pipe(map(result => result.response)),
                this.licenseService.list().pipe(map(result => result.response)),
                this.serviceCatalogQuoteService.list().pipe(map(result => result.response)),
            ])
                .pipe(take(1))
                .subscribe(([subscriptions, licenses, orders]) => {
                    this.loading = false;
                    this.licenses = licenses;

                    orders.forEach(order => {
                        // Find last stage with an update (or default to first stage if no updates)
                        let currentQuoteStage: QuoteStage = order.stages?.[0];
                        order.stages?.forEach(stage => {
                            if (stage.lastUpdated) {
                                currentQuoteStage = stage;
                            }
                        });
                        // Check if the current stage is in the list of pending order stages, if so add this order to list
                        // of pending orders for this subscriptionId
                        if (PENDING_ORDER_STAGES.includes(currentQuoteStage?.name)) {
                            if (!this.pendingOrders.get(order.subscriptionId)) {
                                this.pendingOrders.set(order.subscriptionId, []);
                            }
                            this.pendingOrders.get(order.subscriptionId).push(order);
                        }
                    });

                    // Update subscriptions with relevant fields after pending orders have been processed
                    this.subscriptions = subscriptions
                        .filter(subscription => this.evergreenProgramTypes.includes(subscription.programType))
                        .map(
                            sub =>
                                ({
                                    ...sub,
                                    numLicenses: this.getLicensesBySubscription(sub).length,
                                    detailedStatus: this.getSubscriptionStatus(sub),
                                    actionLabel: this.getSubscriptionActions(sub),
                                }) as SubscriptionSummary,
                        );

                    this.updateTableDropdown();
                    this.updateSubscriptionStatus();
                    this.centerLabel = this.subscriptions?.length.toString();
                });
        }
    }

    updateTableDropdown(): void {
        //Only showing expired/renewable subscriptions OR subscriptions with pending orders on the top 'Need Attention' table
        this.actionableSubscriptions = this.subscriptions
            ?.filter(
                subscription =>
                    moment.utc().add(120, 'days').isAfter(subscription.endDate) ||
                    this.pendingOrders?.get(subscription.id)?.length > 0,
            )
            ?.sort((a, b) => a.endDate.diff(b.endDate));
        this.selectedFilter = this.actionableSubscriptions?.length
            ? TableDropdownSelections.NEEDS_ATTENTION
            : TableDropdownSelections.ALL_SUBSCRIPTIONS;
        const needs_attention =
            TableDropdownSelections.NEEDS_ATTENTION + ' (' + this.actionableSubscriptions?.length + ')';
        const all_subscriptions = TableDropdownSelections.ALL_SUBSCRIPTIONS + ' (' + this.subscriptions?.length + ')';
        this.filters = [
            { label: needs_attention, value: TableDropdownSelections.NEEDS_ATTENTION },
            { label: all_subscriptions, value: TableDropdownSelections.ALL_SUBSCRIPTIONS },
        ];
        if (this.preSelectedFilter !== this.selectedFilter) {
            this.selectedFilter =
                this.actionableSubscriptions?.length > 0
                    ? (this.filters[0].value as string)
                    : (this.filters[1].value as string);
        }
    }

    updateSubscriptionStatus(): void {
        this.expiredSubscriptions = 0;
        this.expiringIn120Subscriptions = 0;
        this.expiringIn30Subscriptions = 0;
        this.underContractSubscriptions = 0;

        this.subscriptions?.forEach(subscription => {
            if (subscription.endDate.isBefore(moment.utc())) {
                this.expiredSubscriptions++;
            } else if (moment.utc().add(30, 'days').isAfter(subscription.endDate)) {
                this.expiringIn30Subscriptions++;
            } else if (moment.utc().add(120, 'days').isAfter(subscription.endDate)) {
                this.expiringIn120Subscriptions++;
            } else if (isSubscriptionActive(subscription.status)) {
                this.underContractSubscriptions++;
            }
        });

        this.dialSegments = [
            {
                name: ChartDetails.underContract.name,
                value: this.underContractSubscriptions,
                color: ChartDetails.underContract.color,
            },
            {
                name: ChartDetails.expired.name,
                value: this.expiredSubscriptions,
                color: ChartDetails.expired.color,
            },
            {
                name: ChartDetails.expiresIn30Days.name,
                value: this.expiringIn30Subscriptions,
                color: ChartDetails.expiresIn30Days.color,
            },
            {
                name: ChartDetails.expiresIn120Days.name,
                value: this.expiringIn120Subscriptions,
                color: ChartDetails.expiresIn120Days.color,
            },
        ];

        this.cyberRecoverySubscriptions =
            this.subscriptions?.filter(subscription => subscription.isRansomwareProtectionEnabled)?.length || 0;
    }

    onTableSelectChange(option: string): void {
        ampli.needsAttentionDropdownSelected({
            'subscription program type': convertToAmpliSubscriptionProgramTypeConfig(this.evergreenProgramTypes[0]),
        });
        this.selectedFilter = option;
        this.preSelectedFilter = this.selectedFilter;
    }

    onSelectedRowChange(subscriptionEvent: SelectionEvent<Subscription>): void {
        const subscriptions = subscriptionEvent.data;
        const actions = subscriptions.map(subscription =>
            addFilter(
                LOCAL_BAR_SUBSCRIPTIONS,
                createFilter(SUBSCRIPTION_ENTITY, DEFAULT_NAMESPACE, NAME_KEY, subscription.name),
            ),
        );
        this.ngRedux.dispatch([removeFiltersByKey(LOCAL_BAR_SUBSCRIPTIONS, NAME_KEY, DEFAULT_NAMESPACE), ...actions]);
    }

    onSortChange(newSort: SortParams): void {
        this.sort = newSort;
        this.sortSubscriptions(this.sort);
    }

    getSubscriptionActions(subscription: Subscription): string {
        //1. contact sales => expired already
        //2. review renewal => expires in 120day
        //3. pending order => get pending orders
        this.pendingOrdersBySubscription = this.pendingOrders?.get(subscription.id);
        this.pendingOrdersBySubscription?.forEach(order => {
            if (order.quoteType === QuoteType.RENEWAL && order.editable) {
                this.editableRenewalOrder = order;
            }
        });
        let subscriptionActionLabel = null;
        if (subscription.endDate.isBefore(moment.utc())) {
            subscriptionActionLabel = 'Contact CSM for assistance';
        } else if (moment.utc().add(120, 'days').isAfter(subscription.endDate)) {
            subscriptionActionLabel = !!this.editableRenewalOrder ? 'Review Renewal' : 'Contact CSM for assistance';
        } else if (this.pendingOrdersBySubscription?.length > 0) {
            subscriptionActionLabel = 'Review Orders';
        }
        return subscriptionActionLabel;
    }

    manageSubscription(subscription: Subscription): void {
        this.pendingOrdersBySubscription = this.pendingOrders?.get(subscription.id);
        this.selectedSubscription = subscription;
        this.editableRenewalOrder = null;
        this.pendingOrdersBySubscription.forEach(order => {
            if (order.quoteType === QuoteType.RENEWAL && order.editable) {
                this.editableRenewalOrder = order;
            }
        });
        if (this.editableRenewalOrder && !subscription.isPoc) {
            //If the Renewal Order is still under editable status (First time request renewal/ Edit existing renewal order )
            if (subscription.isPoc) {
                return; // user should not be seeing the button that calls this method if subscription.isPoc. This is a failsafe.
            }
            this.modalService.open(this.renewSubscriptionModal, { windowClass: 'renew-summary-modal' });
            return;
        } else if (this.pendingOrdersBySubscription.length > 0) {
            //If the Renewal Order is not editable anymore, redirect to orders page
            const referenceIdsFilter: IStateFilter[] = [];
            this.pendingOrdersBySubscription.forEach(order => {
                referenceIdsFilter.push(
                    createFilter(ORDERS_ENTITY, DEFAULT_NAMESPACE, 'order_request_id', order.orderRequestId),
                );
            });
            const referenceIdsParams = {
                filter: getFiltersUrlParams(referenceIdsFilter),
            };
            this.router.navigate(['/services/servicecatalog/orders'], { queryParams: referenceIdsParams });
        }
    }

    getSubscriptionStatus(subscription: Subscription): string {
        this.pendingOrdersBySubscription = this.pendingOrders?.get(subscription.id);
        if (subscription?.endDate.isBefore(moment.utc())) {
            return 'Expired ' + moment.utc().diff(subscription?.endDate, 'days') + ' days ago.';
        } else if (moment.utc().add(120, 'days').isAfter(subscription?.endDate)) {
            return 'Expires in ' + subscription?.endDate.diff(moment.utc(), 'days') + ' days.';
        } else if (this.pendingOrdersBySubscription?.length > 0) {
            return this.pendingOrdersBySubscription?.length === 1
                ? '1 pending order'
                : this.pendingOrdersBySubscription?.length + ' pending orders';
        } else {
            return 'Active';
        }
    }

    getLicensesBySubscription(subscription: Subscription): License[] {
        return this.licenses.filter(license => license.subscription.id === subscription.id);
    }

    private compareFields(a: SubscriptionSummary, b: SubscriptionSummary, sort: SortParams): number {
        let compare;
        const fieldA = a[sort.key];
        const fieldB = b[sort.key];
        if (typeof fieldA === 'string') {
            compare = fieldA.localeCompare(fieldB as string);
        } else {
            compare = fieldA - (fieldB as number);
        }
        return compare * (sort.order === 'asc' ? 1 : -1);
    }

    private sortSubscriptions(sortBy: SortParams): void {
        if (sortBy) {
            // Since we don't reset sorting on switch between "Needs Attention" and
            // "All Subscriptions", we should go ahead and sort both
            this.subscriptions.sort((a, b) => this.compareFields(a, b, sortBy));
            this.actionableSubscriptions.sort((a, b) => this.compareFields(a, b, sortBy));
        }
    }
}
