import { formatNumber } from './formatNumber';

export function formatNetworkSpeed(value: number, decimal: number, axisLabel?: boolean): IValueUnit {
    if (typeof value !== 'number' || isNaN(value)) {
        return { value: '-', unit: '' };
    }
    if (typeof decimal !== 'number') {
        decimal = 0;
    }
    if (axisLabel && value <= 0) {
        return { value: '', unit: '' };
    }
    const units = axisLabel === true ? ['KB/s', 'MB/s'] : ['B/s', 'KB/s', 'MB/s', 'GB/s'];
    if (axisLabel === true) {
        value = value / 1024;
    }

    let index = 0;
    const len = units.length - 1;
    // NOTE: '>=1000' is to prevent values between [1000, 1024) from showing up as, say, 1005.6MB/s
    // and instead show as 1.0 GB/s (assuming 1 digit rounding). This helps simplify UI layout issues
    while (value >= 1000 && index < len) {
        value = value / 1024;
        index++;
    }

    return {
        value: formatNumber(value, decimal),
        unit: units[index],
    };
}

export function formatNetworkSpeedStr(value: number, decimal: number, axisLabel?: boolean): string {
    const iVal = formatNetworkSpeed(value, decimal, axisLabel);
    return `${iVal.value} ${iVal.unit}`.trim();
}
