import moment from 'moment';

import { Resource } from '../interfaces/resource';

export abstract class StorageEntity implements Resource {
    name: string;
    id: string;
    array_name: string;
    appliance_id: string;
    count: number;
    total_snapshot_space: number;
    eradicated: boolean;
    last_recovery_point: moment.Moment;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.array_name = json.array_name;
        this.appliance_id = json.appliance_id;
        this.count = json.count;
        this.total_snapshot_space = json.total_snapshot_space ? json.total_snapshot_space : 0;
        this.eradicated = json.eradicated;
        this.last_recovery_point = json.last_recovery_point ? moment(json.last_recovery_point) : null;
    }
}
