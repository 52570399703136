<div
    class="dismiss-button"
    (click)="cancel()"
    angulartics2On="click"
    angularticsAction="Step Up - Disable Modal - close modal"
>
    &#x2715;
    <!-- "✕" -->
</div>
<pureui-svg svg="info-bubble-icon.svg" height="50"></pureui-svg>
<div class="title">Disable Two Factor Authentication?</div>
<div class="text">
    Disabling two factor authentication will make your account less secure. You also won't be able to perform Pure1
    operations that have higher security sensitivity and require authentication.
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="cancel()"> Cancel </button>
    <button type="button" class="btn btn-primary" (click)="disable()"> Yes, Disable </button>
</div>
