<ng-container>
    <div *ngIf="loading">
        <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
    </div>
    <ng-container *ngIf="!loading">
        <div *ngIf="isBeingEdited && form.controls.product">
            <input
                class="form-control full-width"
                type="text"
                [value]="form.controls.product.value?.name || form.controls.product.value"
                disabled="true"
            />
        </div>
        <div *ngIf="!isBeingEdited" [formGroup]="form">
            <search-px-cluster
                #searchApplianceInput
                formControlName="product"
                [clusters]="appliances"
            ></search-px-cluster>
        </div>
    </ng-container>
</ng-container>
