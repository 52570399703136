import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

interface ApproxConfig {
    tresholdFn: (d: moment.Duration) => boolean;
    valueFn: (d: moment.Duration) => number;
    name: string;
}
const config: ApproxConfig[] = [
    {
        tresholdFn: d => d.asMonths() >= 11.5,
        valueFn: d => Math.round(d.asYears()),
        name: 'year',
    },
    {
        tresholdFn: d => d.asWeeks() > 4,
        valueFn: d => Math.round(d.asMonths()),
        name: 'month',
    },
    {
        tresholdFn: d => d.asDays() >= 6.5,
        valueFn: d => Math.round(d.asWeeks()),
        name: 'week',
    },
    {
        tresholdFn: d => d.asHours() >= 23.5,
        valueFn: d => Math.round(d.asDays()),
        name: 'day',
    },
];

@Pipe({ name: 'formatApproxDuration' })
export class FormatApproxDurationPipe implements PipeTransform {
    transform(value: moment.Duration): string | null {
        if (moment.isDuration(value)) {
            for (const { tresholdFn, valueFn, name } of config) {
                if (tresholdFn(value)) {
                    const v = valueFn(value);
                    return `${v} ${name}${v > 1 ? 's' : ''}`;
                }
            }
            const v = Math.round(value.asHours());
            return `${v} hour${v > 1 ? 's' : ''}`;
        }
        return null;
    }
}
