import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'humanizeGranularity' })
export class HumanizeGranularityPipe implements PipeTransform {
    transform(duration: moment.Duration): string {
        return duration ? getPreciseHumanizedDuration(duration) : null;
    }
}

/**
 * An alternative to moment.duration.humanize(). Returns using a bit of a different format, and with a much more precise resolution.
 * @returns The humanized duration, or null if an invalid / zero duration.
 */
function getPreciseHumanizedDuration(duration: moment.Duration): string {
    if (!duration) {
        return null;
    }

    const buildString = (value: number, singular: string, plural: string): string => {
        // Round to 2 decimal places. This helps avoid getting numbers that are overly-precise, and also helps avoid cases
        // where a small rounding error would give us not the unit we expect. For example, if we didn't do this, and you
        // ended up with 0.9991 days, we'd end up showing as "23.9x hours" (roughly) instead of "1 day".
        value = Math.round(value * 100) / 100;

        if (value < 1) {
            // If we don't have at least 1 for this unit, use the next smallest unit
            return null; // Returning null allows us to null coalesce
        }

        return value + ' ' + (value > 1 ? plural : singular);
    };

    // Check each unit from largest to smallest
    return (
        buildString(duration.asMonths(), 'month', 'months') ||
        buildString(duration.asDays(), 'day', 'days') ||
        buildString(duration.asHours(), 'hour', 'hours') ||
        buildString(duration.asMinutes(), 'minute', 'minutes') ||
        buildString(duration.asSeconds(), 'second', 'seconds') ||
        buildString(duration.asMilliseconds(), 'millisecond', 'milliseconds')
    );
}
