import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface StatelessSubmenuBarItem {
    title: string;
    warning?: boolean;
}

@Component({
    selector: 'stateless-submenu-bar',
    templateUrl: 'stateless-submenu-bar.component.html',
})
export class StatelessSubmenuBarComponent implements OnInit {
    @Input() readonly submenuItems: StatelessSubmenuBarItem[];
    @Output() readonly selectedItemChanged = new EventEmitter<StatelessSubmenuBarItem>();

    selectedItem: StatelessSubmenuBarItem;

    ngOnInit(): void {
        this.changeTabSelection(this.submenuItems[0]);
    }

    changeTabSelection(tab: StatelessSubmenuBarItem): void {
        this.selectedItem = tab;
        this.selectedItemChanged.next(tab);
    }
}
