<div class="step-up-modal-header">
    <pureui-svg class="header-icon" svg="Pure1.svg" height="40"></pureui-svg>
</div>
<div class="step-up-modal-body">
    <div class="title"> Two Factor Authentication </div>
    <div>Enter the code generated by your authenticator app.</div>
    <div class="passcode-area">
        <div class="totp-error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
        <totp-passcode-input
            [hasError]="errorMessage != null"
            (valueChanged)="inputValueChanged($event)"
        ></totp-passcode-input>
    </div>

    <button
        class="btn btn-primary submit-button"
        (click)="verify()"
        [disabled]="submitDisabled()"
        angulartics2On="click"
        angularticsAction="Step Up - Challenge Modal - submit passcode"
        angularticsCategory="Action"
    >
        Verify
    </button>

    <div class="text">
        Need help?
        <a
            (click)="goToSupport()"
            angulartics2On="click"
            angularticsAction="Step Up - Challenge Modal - contact support"
            angularticsCategory="Action"
        >
            Contact Pure Technical Services
        </a>
    </div>
</div>
<div class="step-up-modal-footer">
    <a
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Step Up - Challenge Modal - cancel"
        angularticsCategory="Action"
        >Cancel</a
    >
</div>
