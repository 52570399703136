import { Resource } from '../interfaces/resource';
import { DirectoryMember } from './directory-member';
import { DirectoryPerformanceAggregation } from './directory-performance-aggregation';
import moment from 'moment';

export class Directory implements Resource {
    name: string;
    id: string;
    _as_of: moment.Moment;

    array: DirectoryMember;
    array_name: string; // For sorting and filtering, we directly include this as a property of Directory
    created: moment.Moment | null;
    destroyed: boolean;
    file_system: DirectoryMember;
    lastUpdated: moment.Moment | null;
    path: string;
    last_recovery_point: moment.Moment;
    total_usage: number;
    dynamic_limit: number;
    quota_limit: number;

    aggregation: DirectoryPerformanceAggregation;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;

        this._as_of = json._as_of ? moment(json._as_of) : moment(json.as_of);

        this.array = json.array ? new DirectoryMember(json.array) : null;
        this.array_name = this.array ? this.array.name : null;
        this.created = moment(json.created || null);
        this.destroyed = json.destroyed || null;
        this.file_system = new DirectoryMember(json.file_system);
        this.lastUpdated = moment(json.lastUpdated || null);
        this.path = json.path;
        this.last_recovery_point = json.last_recovery_point ? moment(json.last_recovery_point) : null;
        this.total_usage = json.total_usage;
        this.dynamic_limit = json.dynamic_limit;
        this.quota_limit = json.quota_limit;

        if (json.performance_aggregation) {
            this.aggregation = new DirectoryPerformanceAggregation(json.performance_aggregation.aggregation);
        }
    }
}
