import moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject, of, concat } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    Inject,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UnifiedArray } from '@pure1/data';
import { WINDOW } from '../../../app/injection-tokens';
import { TimeSlot } from '../types';

@Component({
    selector: 'timeslot-picker',
    templateUrl: './timeslot-picker.component.html',
})
export class TimeSlotPickerComponent implements OnChanges, OnInit {
    @Input() day: NgbDateStruct; // TODO: Make "readonly". Inputs should not be assigned to.
    @Input() readonly control: UntypedFormControl;
    @Input() readonly timezone: string;
    @Input() readonly scrollToTimeSlot: TimeSlot;
    /** These need to be sorted by startTime */
    @Input() readonly sortedTimeSlots: TimeSlot[];
    @Input() readonly selectedTimeSlots: { [id: string]: TimeSlot };
    @Input() readonly selectedArray: UnifiedArray;
    @Input() readonly arrays: UnifiedArray[];
    @Input() readonly oneScheduleForMultipleAppliances: boolean = true;

    @Output() readonly dayChange = new EventEmitter<NgbDateStruct>();

    timeSlots$: Observable<TimeSlot[]>;

    private refreshTimeSlots$: Subject<moment.Moment>;

    constructor(@Inject(WINDOW) private window: Window) {}

    ngOnInit(): void {
        this.refreshTimeSlots$ = new BehaviorSubject<moment.Moment>(this.getDayAsMoment(this.timezone));

        this.timeSlots$ = this.refreshTimeSlots$.pipe(
            switchMap(day => {
                // Send "null" first to indicate that this is loading
                return concat(of(null), this.getTimeSlotsForDay(day, this.timezone));
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.refreshTimeSlots$) {
            if (changes.day || changes.timezone || changes.sortedTimeSlots) {
                this.refreshTimeSlots$.next(this.getDayAsMoment(this.timezone));
            }
        }

        if (changes.scrollToTimeSlot && changes.scrollToTimeSlot.currentValue) {
            const element = this.window.document.getElementById(
                changes.scrollToTimeSlot.currentValue.startTime.valueOf(),
            );

            if (element) {
                element.parentElement.scrollIntoView();
            }
        }
    }

    printDate(): string {
        return this.getDayAsMoment(this.timezone).format('LL');
    }

    addDays(days: number): void {
        const next = this.getDayAsMoment(this.timezone).add(days, 'days');
        this.day = {
            year: next.year(),
            month: next.month() + 1,
            day: next.date(),
        };
        this.dayChange.emit(this.day);
    }

    trackByIndex(index: number): number {
        return index;
    }

    printPrettyTime(time: moment.Moment): string {
        // Reason of using moment instead of DatePipe is because
        // DatePipe only accept number offset for the timezone, it cannot auto-adjust for daylight saving time
        return time.tz(this.timezone).format('HH:mm');
    }

    getSelectedArrayIndex(): number {
        if (!this.selectedArray) {
            return 0;
        }
        return this.arrays.findIndex(arr => arr.id === this.selectedArray.id);
    }

    timeSlotHasRemainingCapacity(timeslot: TimeSlot): boolean {
        if (this.arrays.length > 0 && !this.oneScheduleForMultipleAppliances) {
            const arrayCountForTimeslot = this.arrays.reduce(
                (accumulator, current) => {
                    if (current.id === this.selectedArray.id) {
                        return accumulator;
                    } else {
                        return this.isArrayInTimeSlot(current, timeslot) ? accumulator + 1 : accumulator;
                    }
                },

                0,
            );
            return arrayCountForTimeslot < timeslot.capacity;
        }

        // this is for single array upgrade creation or when there is one schedule for multiple appliances
        return true;
    }

    isArrayInTimeSlot(array: UnifiedArray, timeslot: TimeSlot): boolean {
        const arrayTimeSlot = this.selectedTimeSlots[array.id];
        return arrayTimeSlot && this.checkTimeSlotsStartEqual(arrayTimeSlot, timeslot);
    }

    isCurrentArrayInTimeslot(timeslot: TimeSlot): boolean {
        const arrayTimeSlot = this.selectedTimeSlots[this.selectedArray.id];
        if (arrayTimeSlot) {
            return arrayTimeSlot.startTime.isSame(timeslot.startTime);
        }
        return false;
    }

    checkTimeSlotsStartEqual(a: TimeSlot, b: TimeSlot): boolean {
        return a.startTime.isSame(b.startTime);
    }

    private getDayAsMoment(timezone: string): moment.Moment {
        return moment.tz(
            {
                year: this.day.year,
                month: this.day.month - 1,
                day: this.day.day,
            },
            timezone,
        );
    }

    private getTimeSlotsForDay(day: moment.Moment, timezone: string): Observable<TimeSlot[]> {
        const startTime = moment.tz(day, this.timezone);
        const endTime = startTime.clone().add(1, 'days');

        if (!this.sortedTimeSlots) {
            return of([]);
        }
        return Observable.create(obs => {
            const todaysTimeSlots: TimeSlot[] = [];
            for (const timeSlot of this.sortedTimeSlots) {
                if (timeSlot.startTime.isSameOrAfter(startTime) && timeSlot.startTime.isBefore(endTime)) {
                    todaysTimeSlots.push(timeSlot);
                }
            }
            obs.next(todaysTimeSlots);
        });
    }
}
