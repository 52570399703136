import { DraasApiVmWareDatastore } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryVmWareDatastore {
    assetId: string;
    clusterId: string;
    providerId: string;
    name: string;
    capacity: number;
    available: number;
    moref: string;

    constructor(json: DraasApiVmWareDatastore) {
        this.assetId = json.id;
        this.clusterId = json.cluster_id;
        this.providerId = json.provider_id;
        this.name = json.name;
        this.moref = json.moref;
        this.capacity = json.capacity_in_bytes;
        this.available = json.available_in_bytes;
    }
}
