import {
    Component,
    ContentChildren,
    HostBinding,
    QueryList,
    Input,
    SimpleChanges,
    OnChanges,
    Output,
    EventEmitter,
    AfterContentInit,
} from '@angular/core';
import { SidePanelTabDirective } from './side-panel-tab/side-panel-tab.directive';
import { PanelTabName } from '../../../redux/pure-redux.service';
import { take } from 'rxjs/operators';
import { smartTimer } from '@pstg/smart-timer';

const TEN_SECONDS_IN_MS = 10000;

@Component({
    selector: 'side-panel',
    templateUrl: 'side-panel.component.html',
})
export class SidePanelComponent implements AfterContentInit, OnChanges {
    @Input() readonly selectedTabName: string;
    @Input() readonly showSelector = true;
    @Output() readonly panelOpen = new EventEmitter<PanelTabName>(); // Should not emit when opened by input
    @Output() readonly panelClose = new EventEmitter<void>();

    @ContentChildren(SidePanelTabDirective) readonly tabs: QueryList<SidePanelTabDirective>;

    @Input() @HostBinding('class.opened') opened = false;

    selectedTab: SidePanelTabDirective;
    showAttentionBubbleText = true;

    // AfterContent so that the SidePanelTabDirectives are loaded by the time we select
    ngAfterContentInit(): void {
        if (this.selectedTabName && !this.selectedTab) {
            this.selectTabFromName(this.selectedTabName);
        }

        // wait 10secs before hiding the megaphone speech bubble
        smartTimer(TEN_SECONDS_IN_MS)
            .pipe(take(1))
            .subscribe((): void => {
                this.showAttentionBubbleText = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTabName) {
            this.selectTabFromName(this.selectedTabName);
        }
    }

    onTabClick(tab: SidePanelTabDirective): void {
        if (this.selectedTab === tab && this.opened === true) {
            this.closeSidebar();
            return;
        }

        this.selectTab(tab);
        this.panelOpen.emit(tab.name);
    }

    closeSidebar(): void {
        this.opened = false;
        this.panelClose.emit();
    }

    private selectTab(tab: SidePanelTabDirective): void {
        this.selectedTab = tab;
        this.opened = true;
    }

    private selectTabFromName(tabName: string): void {
        this.tabs?.forEach(tab => {
            if (tab.name === tabName) {
                this.selectTab(tab);
            }
        });
    }
}
