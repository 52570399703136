import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UnifiedArray } from '@pure1/data';
import { orderBy } from 'lodash';
import { MessagesModalService } from '../../../messages/alerts/messages-modal/messages-modal.service';
import { severity } from '../../../utils/severity';
import { Theme } from '../../styles/theme';

@Component({
    selector: 'array-alerts-popover',
    templateUrl: 'array-alerts-popover.component.html',
})
export class ArrayAlertsPopoverComponent implements OnChanges {
    @Input() readonly array: UnifiedArray;
    @Input() readonly alerts: IAlert[];
    @Input() readonly showArrayLink: boolean = true;

    readonly Theme = Theme;
    sortedAlerts: IAlert[];

    constructor(private messagesModal: MessagesModalService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.alerts) {
            this.updateSortedAlerts();
        }
    }

    alertCount(): number {
        return (this.alerts || []).length + (this.hasEolComponents() ? 1 : 0);
    }

    clickAlert(alert: IAlert): void {
        this.messagesModal.open(alert, alert.getKBLink());
    }

    hasEolComponents(): boolean {
        return !!this.array?.has_end_of_life_hardware;
    }

    private updateSortedAlerts(): void {
        this.sortedAlerts = orderBy(this.alerts || [], [
            alert => severity(alert.currentSeverity).value,
            alert => -alert.updated,
        ]);
    }
}
