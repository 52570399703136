import { Directive, ElementRef, Renderer2, Input, AfterViewInit, OnDestroy, NgZone } from '@angular/core';

const SHOW_SHADOW_CLASS = 'show-shadow';
const FORCE_POSITION_RELATIVE_CLASS = 'pureui-force-position-relative';
const SHOW_TOP_SHADOW_CLASS = 'pureui-top-shadow';
const SHOW_BOTTOM_SHADOW_CLASS = 'pureui-bottom-shadow';

interface IShadowScrollOptions {
    /** Child selector for the element to append the shadow element onto. If null, uses the element this directive is defined on. */
    shadowElem?: string;

    /** Child selector for the element to watch for scroll events. If null, uses the element this directive is defined on. */
    scrollElem?: string;
}

@Directive({
    selector: '[pureshadowscroll]',
})
export class ShadowScrollDirective implements AfterViewInit, OnDestroy {
    @Input('pureshadowscroll') options: IShadowScrollOptions;

    private scrollEl: HTMLElement;
    private shadowEl: HTMLDivElement;

    private unregisterShadowListener: () => void;
    private animationFrameRequested = false;
    private rafId: number;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private ngZone: NgZone,
    ) {}

    ngAfterViewInit(): void {
        // Watch the element to scroll on for scroll events
        this.scrollEl = this.options.scrollElem
            ? this.el.nativeElement.querySelector(this.options.scrollElem)
            : this.el.nativeElement;
        if (!this.scrollEl) {
            console.warn(`Expected scrollElem to be not null. Source elem: `, this.el.nativeElement);
        }

        // Create and append shadow element
        this.shadowEl = this.renderer.createElement('div');
        this.renderer.addClass(this.shadowEl, 'pureui-shadow-scroll');

        const shadowElemParent = this.options.shadowElem
            ? this.el.nativeElement.querySelector(this.options.shadowElem)
            : this.el.nativeElement;
        if (!shadowElemParent) {
            console.warn(`Expected shadowElemParent to be not null. Source elem: `, this.el.nativeElement);
        }
        this.renderer.appendChild(shadowElemParent, this.shadowEl);

        // Because .pureui-shadow-scroll uses absolute positioning, its parent element must not be static positioned
        // for the shadow to position correctly
        if (getComputedStyle(shadowElemParent).getPropertyValue('position') === 'static') {
            this.renderer.addClass(shadowElemParent, FORCE_POSITION_RELATIVE_CLASS);
            console.warn('Changed the from static to relative for element', shadowElemParent);
        }

        this.ngZone.runOutsideAngular(() => {
            this.unregisterShadowListener = this.renderer.listen(this.scrollEl, 'scroll', () => this.onScroll());
            // Update initial state
            this.onScroll();
        });
    }

    ngOnDestroy(): void {
        if (this.scrollEl) {
            this.unregisterShadowListener();
        }
        if (this.shadowEl) {
            const shadowElemParent = this.renderer.parentNode(this.shadowEl);
            this.renderer.removeChild(shadowElemParent, this.shadowEl);
        }
        if (this.rafId) {
            window.cancelAnimationFrame(this.rafId);
        }
    }

    private onScroll(): void {
        NgZone.assertNotInAngularZone();
        if (!this.animationFrameRequested) {
            this.animationFrameRequested = true;
            this.rafId = window.requestAnimationFrame(() => {
                this.animationFrameRequested = false;
                this.rafId = null;
                const scrollTop = this.scrollEl.scrollTop;
                const scrollBottom = this.scrollEl.scrollHeight - this.scrollEl.offsetHeight - scrollTop;
                if (scrollTop === 0) {
                    this.renderer.removeClass(this.shadowEl, SHOW_SHADOW_CLASS);
                    this.renderer.removeClass(this.el.nativeElement, SHOW_TOP_SHADOW_CLASS);
                } else {
                    this.renderer.addClass(this.shadowEl, SHOW_SHADOW_CLASS);
                    this.renderer.addClass(this.el.nativeElement, SHOW_TOP_SHADOW_CLASS);
                }
                if (scrollBottom <= 0) {
                    this.renderer.removeClass(this.el.nativeElement, SHOW_BOTTOM_SHADOW_CLASS);
                } else {
                    this.renderer.addClass(this.el.nativeElement, SHOW_BOTTOM_SHADOW_CLASS);
                }
            });
        }
    }
}
