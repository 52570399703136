import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'list' })
export class ListPipe implements PipeTransform {
    transform(list: any[]): string {
        if (list && list.length > 0) {
            return list.join(', ');
        }
        return '';
    }
}
