import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { takeUntil, tap } from 'rxjs/operators';
import { smartTimer } from '@pstg/smart-timer';

const CACHE_CONFIGS: CacheConfig[] = [
    {
        endpoint: '/rest/v1/vmanalytics/metrics/vms',
        durationMs: moment.duration(2, 'minutes').asMilliseconds(),
    },
    {
        endpoint: '/rest/v1/vmanalytics/collector/installers/script-url',
        durationMs: moment.duration(5, 'minutes').asMilliseconds(),
    },
];

/** Default size of the cache if no size explicitly specified */
const DEFAULT_CACHE_SIZE = 50;

/**
 * How frequently to run background cache cleanup. This is only to help avoid using a needless amount of memory - it
 * does not affect the cache's effectiveness to ability to store items.
 */
const BACKGROUND_CLEANUP_FREQ_MS = moment.duration(30, 'seconds').asMilliseconds();

interface CacheConfig {
    /**
     * Api endpoint to match. This must match the whole request url, minus params and domain. Eg: /rest/v1/vmanalytics/metrics/vms.
     */
    endpoint: string;

    /**
     * How long items in this cache will live
     */
    durationMs: number;

    /**
     * Gets the maximum number of items allowed in this cache at once. If exceeded, older items will be flushed.
     * Must be at least 10.
     */
    maxSize?: number;
}

/**
 * Caches HTTP calls made to specified endpoints. Only GET calls are cached, and any non-GET
 * call clears all entries the cache for that specific endpoint.
 */
@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor, OnDestroy {
    // FUTURE: Find a way to let concurrent requests be handled by the same single http call, while still allowing
    // http calls to be aborted if every caller of that observable aborts.

    private readonly caches = new Map<string, HttpCache>();
    private readonly destroy$ = new Subject<void>();

    static getCacheConfigs(): CacheConfig[] {
        return CACHE_CONFIGS;
    }

    constructor(ngZone: NgZone) {
        HttpCacheInterceptor.getCacheConfigs().forEach(config => {
            this.caches.set(config.endpoint, new HttpCache(config));
        });

        // Create a background cleanup to remove expired items from the caches
        ngZone.runOutsideAngular(() => {
            smartTimer(BACKGROUND_CLEANUP_FREQ_MS, BACKGROUND_CLEANUP_FREQ_MS, BACKGROUND_CLEANUP_FREQ_MS)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.caches.forEach(cache => {
                        cache.clearExpired();
                    });
                });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cache = this.caches.get(request.url);

        if (!cache) {
            // No caching specified on for this endpoint
            return next.handle(request);
        }

        const cachedResponse = cache.get(request);
        if (cachedResponse) {
            // Serve cached response
            return of(cachedResponse);
        }

        // Make request
        return next.handle(request).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    // If this is not a GET, clear the cache completely
                    if (request.method && request.method.toUpperCase() !== 'GET') {
                        cache.clear();
                        return;
                    }

                    // Only cache a successful call with no body
                    if (response.status !== 200 || (request.body || '').length > 0) {
                        return;
                    }

                    cache.add(request, response);
                }
            }),
        );
    }
}

interface CacheItem {
    expireAt: number;
    response: HttpResponse<any>;
}

class HttpCache {
    private readonly cacheItems = new Map<string, CacheItem>();

    constructor(private readonly config: CacheConfig) {
        if (config.maxSize != null && config.maxSize < 10) {
            throw new Error('cache maxSize must be >= 10');
        }
    }

    /**
     * Gets the cached response, or null if no live cached result is available.
     */
    get(request: HttpRequest<any>): HttpResponse<any> | null {
        const cacheItem = this.cacheItems.get(this.key(request));

        if (!cacheItem) {
            return null; // Not in cache
        }

        if (this.isExpired(cacheItem)) {
            this.cacheItems.delete(this.key(request));
            return null; // In cache, but expired
        }

        return this.cloneResponse(cacheItem.response);
    }

    /**
     * Adds a response to the cache.
     */
    add(request: HttpRequest<any>, response: HttpResponse<any>): void {
        // Make sure our cache is within bounds
        // If we're going to overfill the cache, delete some items
        if (this.cacheItems.size > this.maxSize()) {
            this.clearOldest(5);
        }

        // Add to cache
        this.cacheItems.set(this.key(request), {
            expireAt: Date.now() + this.config.durationMs,
            response: this.cloneResponse(response),
        });
    }

    /**
     * Flushes all items from the cache
     */
    clear(): void {
        this.cacheItems.clear();
    }

    /**
     * Clears all expired items from the cache
     */
    clearExpired(): void {
        Array.from(this.cacheItems)
            .filter(item => this.isExpired(item[1]))
            .map(item => item[0])
            .forEach(key => {
                this.cacheItems.delete(key);
            });
    }

    /**
     * Clears the least recently used items from the cache
     * @param removeCount The target number of items to remove
     */
    private clearOldest(removeCount: number): void {
        Array.from(this.cacheItems)
            .sort((a, b) => a[1].expireAt - b[1].expireAt)
            .slice(0, removeCount)
            .map(item => item[0])
            .forEach(key => {
                this.cacheItems.delete(key);
            });
    }

    /**
     * Gets the cache key to use for a request
     */
    private key(request: HttpRequest<any>): string {
        return request.urlWithParams;
    }

    /**
     * Checks if the cache item is expired
     */
    private isExpired(cacheItem: CacheItem): boolean {
        const now = Date.now();
        return cacheItem.expireAt <= now;
    }

    private maxSize(): number {
        return this.config.maxSize || DEFAULT_CACHE_SIZE;
    }

    /**
     * Creates a deep copy of a HttpResponse
     */
    private cloneResponse(response: HttpResponse<any>): HttpResponse<any> {
        return response.clone({
            body: cloneDeep(response.body),
            headers: cloneDeep(response.headers),
        });
    }
}
