import { Observable, of, switchMap } from 'rxjs';
import { map, tap, take, switchAll } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EntityEndpointMap } from './entity-endpointmap';
import { IRestResponse } from '../interfaces/collection';
import { quoteAndEscape } from '../utils';
import { ProgramType } from '../models/subscription';

export const TAG_KEY_ENDPOINT = '{entityEndpoint}/tag-key-summaries';
export const TAG_VALUE_ENDPOINT = '{entityEndpoint}/tag-summaries?filter=';

export interface IAutocompleteKey {
    namespace: string;
    key: string;
    display_key: string;
}

export interface AutocompleteKeyParams {
    entity: string;
    match: string;
    orgId?: number;
    programType?: ProgramType;
}

export interface AutocompleteKeyResponse {
    entity: string;
    match: string;
    keys: IAutocompleteKey[];
}

export interface AutocompleteValueParams extends AutocompleteKeyParams {
    namespace: string;
    key: string;
}

export interface AutocompleteValueResponse extends IAutocompleteKey {
    value: string;
    display_value: string;
}

@Injectable({ providedIn: 'root' })
export class AutocompleteService {
    protected tagKeyEndpoint$: Observable<string>;
    protected tagValueEndpoint$: Observable<string>;

    constructor(protected http: HttpClient) {
        this.tagKeyEndpoint$ = of(TAG_KEY_ENDPOINT);
        this.tagValueEndpoint$ = of(TAG_VALUE_ENDPOINT);
    }

    protected formatKeys(
        entity: string,
        match: string,
        orgId: number,
        programType: ProgramType,
    ): Observable<{ entity: string; match: string; keys: IAutocompleteKey[] }> {
        return this.tagKeyEndpoint$.pipe(
            switchMap((tagKeyEndpoint: string) => {
                let requestString = `${tagKeyEndpoint.replace('{entityEndpoint}', EntityEndpointMap[entity])}`;
                const params: string[] = [];
                if (match) {
                    params.push(encodeURIComponent(`contains(display_key,${quoteAndEscape(match)})`));
                }
                if (orgId) {
                    params.push(`org_id='${orgId}'`);
                }
                if (programType) {
                    params.push(encodeURIComponent(`contains(program_type,${quoteAndEscape(programType)})`));
                }
                if (params.length > 0) {
                    requestString += `?filter=${params.join(' and ')}`;
                }

                return this.http.get<IRestResponse<IAutocompleteKey>>(requestString).pipe(
                    map(response => {
                        return {
                            entity: entity,
                            match: match,
                            keys: this.sanitizeKeys(response.items),
                        };
                    }),
                );
            }),
        );
    }

    getKeys(params$: Observable<AutocompleteKeyParams>): Observable<AutocompleteKeyResponse> {
        let cacheMatch: string = null;
        let cacheEntity: string = null;
        let cacheOrgId: number = null;
        let cacheProgramType: ProgramType = null;
        let cache: IAutocompleteKey[];

        const isInCache = (params: AutocompleteKeyParams): boolean => {
            return (
                cacheMatch !== null &&
                cacheEntity === params.entity &&
                params.match.toLowerCase().includes(cacheMatch.toLowerCase()) &&
                params.orgId === cacheOrgId &&
                params.programType === cacheProgramType
            );
        };

        const getValuesFromCache = (params: AutocompleteKeyParams): IAutocompleteKey[] => {
            const matchLc = params.match.toLowerCase();
            return cache
                .filter(autocompleteKey => autocompleteKey.display_key.toLowerCase().includes(matchLc))
                .map(autocompleteKey => Object.assign({}, autocompleteKey));
        };

        return params$.pipe(
            map(params => {
                if (isInCache(params)) {
                    return of({ entity: params.entity, match: params.match, keys: getValuesFromCache(params) });
                } else {
                    return this.formatKeys(params.entity, params.match, params.orgId, params.programType).pipe(
                        take(1),
                        tap(response => {
                            cacheMatch = params.match;
                            cacheEntity = params.entity;
                            cacheOrgId = params.orgId;
                            cacheProgramType = params.programType;
                            cache = response.keys;
                        }),
                    );
                }
            }),
            switchAll(),
        );
    }

    protected formatValues(
        entity: string,
        key: string,
        namespace: string,
        match: string,
        orgId: number,
        programType: ProgramType,
        supportStatusFilterOption: SupportStatusFilterOption,
    ): Observable<AutocompleteValueResponse[]> {
        // TODO: add support for namespace
        return this.tagValueEndpoint$.pipe(
            switchMap((tagValueEndpoint: string) => {
                let requestString = tagValueEndpoint.replace('{entityEndpoint}', EntityEndpointMap[entity]);
                const params = [encodeURIComponent(`key=${quoteAndEscape(key)}`)];
                if (match) {
                    params.push(encodeURIComponent(`contains(display_value,${quoteAndEscape(match)})`));
                }
                if (orgId) {
                    params.push(`org_id='${orgId}'`);
                }
                if (programType) {
                    params.push(encodeURIComponent(`contains(program_type,${quoteAndEscape(programType)})`));
                }
                requestString += params.join(' and ');
                // supportStatusFilterOption is only applicable to arrays.
                // If supportStatusFilterOption is not specified, we do not pass it to the endpoint,
                // but instead use the default behavior determined by the backend.
                if (entity === 'arrays' && supportStatusFilterOption) {
                    requestString += `&support_status_filter_option=${supportStatusFilterOption}`;
                }
                return this.http
                    .get<IRestResponse<AutocompleteValueResponse>>(requestString)
                    .pipe(
                        map(response =>
                            this.sanitizeValues(response.items.filter(item => item.namespace === namespace)),
                        ),
                    );
            }),
        );
    }

    getValues(
        params$: Observable<AutocompleteValueParams>,
        supportStatusFilterOption: SupportStatusFilterOption,
    ): Observable<AutocompleteValueResponse[]> {
        let cacheMatch: string;
        let cacheEntity: string;
        let cacheOrgId: number;
        let cacheProgramType: ProgramType;
        let cacheNamespace: string;
        let cacheKey: string;
        let cache: AutocompleteValueResponse[];

        const isInCache = (params: AutocompleteValueParams): boolean => {
            return (
                cacheMatch !== null &&
                cacheEntity === params.entity &&
                cacheNamespace === params.namespace &&
                cacheKey === params.key.toLowerCase() &&
                params.match.toLowerCase().includes(cacheMatch.toLowerCase()) &&
                params.orgId === cacheOrgId &&
                params.programType === cacheProgramType
            );
        };

        const getValuesFromCache = (params: AutocompleteValueParams): AutocompleteValueResponse[] => {
            const matchLc = params.match.toLowerCase();
            return cache
                .filter(autocompleteValue => autocompleteValue.display_value.toLowerCase().includes(matchLc))
                .map(autocompleteValue => Object.assign({}, autocompleteValue));
        };

        return params$.pipe(
            map(params => {
                if (isInCache(params)) {
                    return of(getValuesFromCache(params));
                } else {
                    return this.formatValues(
                        params.entity,
                        params.key,
                        params.namespace,
                        params.match,
                        params.orgId,
                        params.programType,
                        supportStatusFilterOption,
                    ).pipe(
                        take(1),
                        tap(response => {
                            cacheMatch = params.match;
                            cacheEntity = params.entity;
                            cacheOrgId = params.orgId;
                            cacheProgramType = params.programType;
                            cacheNamespace = params.namespace;
                            cacheKey = params.key;
                            cache = response;
                        }),
                    );
                }
            }),
            switchAll(),
        );
    }

    protected sanitizeKeys(keys: IAutocompleteKey[]): IAutocompleteKey[] {
        const keySet = new Set<string>();
        const result: IAutocompleteKey[] = [];
        keys.sort((a, b) =>
            `${a.namespace}:${a.display_key.toLowerCase()}`.localeCompare(
                `${b.namespace}:${b.display_key.toLowerCase()}`,
            ),
        ).forEach(key => {
            const keyLCWithNamespace = `${key.namespace}:${key.display_key.toLowerCase()}`;
            if (!keySet.has(keyLCWithNamespace)) {
                result.push(key);
                keySet.add(keyLCWithNamespace);
            }
        });
        return result;
    }

    protected sanitizeValues(values: AutocompleteValueResponse[]): AutocompleteValueResponse[] {
        const valueSet = new Set<string>();
        const result: AutocompleteValueResponse[] = [];
        values
            .sort((a, b) => a.display_value.localeCompare(b.display_value))
            .forEach(value => {
                const valueLC = value.display_value.toLowerCase();
                if (!valueSet.has(valueLC)) {
                    result.push(value);
                    valueSet.add(valueLC);
                }
            });
        return result;
    }
}
