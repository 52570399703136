<div class="d-inline" [formGroup]="licenseForm">
    <ng-template #rt let-result="result" let-term="term">
        <div class="search-license-item">
            <ngb-highlight [result]="result.subscription.name" [term]="term"></ngb-highlight> -
            <ngb-highlight [result]="result.name" [term]="term"></ngb-highlight>
            <div class="search-license-item-info">
                <ngb-highlight class="secondary-info" [result]="result.licenseType" [term]="term"></ngb-highlight>
                <span class="search-license-info-separator" *ngIf="result.licenseType && result.reservedAmount">|</span>
                <span class="secondary-info">
                    {{ result.reservedAmount | formatSubscriptionData: result.licenseType : 0 }}
                </span>
            </div>
        </div>
    </ng-template>

    <input
        class="form-control full-width"
        type="text"
        [ngbTypeahead]="search"
        [value]="searchString"
        (selectItem)="onItemSelected($event)"
        (click)="click$.next('')"
        (keydown)="typeaheadKeydown($event)"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        #typeInstance="ngbTypeahead"
        #typeaheadInput
        formControlName="license"
    />
</div>
