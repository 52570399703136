import { Pipe, PipeTransform } from '@angular/core';

export const POSSIBLE_FLASH_TYPES_LIST = ['6G_SAS', '12G_SAS', 'SAS', 'directFlash', 'DFMc', 'DFMD', 'DFMe'];
type possibleFlashTypes = (typeof POSSIBLE_FLASH_TYPES_LIST)[number];
/**
 * Takes in one of the possible datapack/shelf capacity types and converts to a more readable string
 * If input is not one of the types, then the input is returned
 */
@Pipe({ name: 'flashType' })
export class FlashTypePipe implements PipeTransform {
    transform(flashType: possibleFlashTypes | string): string {
        switch (flashType) {
            case '6G_SAS':
            case '12G_SAS':
            case 'SAS':
                return 'SAS';
            case 'directFlash':
                return 'NVMe (DFM)';
            case 'DFMc':
                return 'NVMe (DFMc)';
            case 'DFMD':
                return 'NVMe (DFMD)';
            case 'DFMe':
                return 'NVMe (DFMe)';
            default:
                console.warn('Input is not one of the possible capacity types: ', flashType);
                return flashType;
        }
    }
}
