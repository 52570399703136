import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { CachedCurrentUserService, FeatureFlagDxpService } from '@pure1/data';
import { ampli } from 'core/src/ampli';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { Observable, Subject, Subscription, combineLatest, of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { WINDOW } from '../../app/injection-tokens';
import { FeatureNames } from '../../model/model';
import { authorized } from '../../utils/form-validators';
import { SupportCaseDraftService } from '../services/support-case-draft.service';
import { SupportCaseService } from '../services/support-create-edit.service';
import { ProductLine, SubCategoryOption, SupportCase, SupportCaseFormValue } from '../support.interface';
import { SUB_CATEGORY_GROUP, getCategoryForSubcategory, subCategoryOptionsFactory } from '../support.utils';

@Component({
    selector: 'create-edit-support-case',
    templateUrl: 'create-edit-support-case.component.html',
})
export class CreateEditSupportCaseComponent implements OnInit, OnDestroy {
    @ViewChild('confirmModal') readonly confirmModal: TemplateRef<any>;

    readonly ampli = ampli;

    @Input() readonly activeModal: NgbActiveModal;
    @Input() caseId: string;
    @Input() searchArrayName: string;
    @Input() caseTemplate: Partial<SupportCase>;

    checkSupportContract = true; // By default, it is impossible to create cases for arrays out of support

    initialCase: Partial<SupportCase>;
    loading: boolean;
    error: string;
    supportCaseForm: UntypedFormGroup;
    createCaseDoneFlag = false;
    createCaseErrorFlag = false;
    createCaseForAspFlag = false;
    submitted = false;
    startTime: number;
    oldFormValue: Partial<SupportCaseFormValue>;
    valueChanges$: Subscription;
    subCategoryOptions$: Observable<SubCategoryOption[]> = combineLatest([
        this.hasPermission('PURE1:view:support_evergreen'),
        this.hasPermission('PURE1:view:support_flash_array'),
        this.hasPermission('PURE1:view:support_flash_blade'),
        this.hasPermission('PURE1:view:support_other'),
        this.hasPermission('PURE1:view:support_portworx'),
        this.hasPermission('PURE1:view:support_pure1'),
        this.hasPermission('PURE1:view:support_draas'),
    ]).pipe(
        map(([evergreen, flashArray, flashBlade, other, portworx, pure1, draas]) =>
            subCategoryOptionsFactory({ evergreen, flashArray, flashBlade, other, portworx, pure1, draas }),
        ),
    );

    private readonly destroy$ = new Subject<boolean>();

    constructor(
        private ngbModal: NgbModal,
        private fb: UntypedFormBuilder,
        @Inject(WINDOW) private window: Window,
        private supportCreateEditService: SupportCaseService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private draftService: SupportCaseDraftService,
        private featureFlagDxpService: FeatureFlagDxpService,
        private authzServiceResolver: AuthorizationServiceResolver,
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.startTime = moment().valueOf();

        this.supportCaseForm = this.fb.group(
            {
                product: [null],
                subject: ['', Validators.required],
                description: ['', Validators.required],
                contacts: [null],
            },
            {
                asyncValidators: authorized(this.hasPermission('PURE1:write:support_cases')),
            },
        );

        const currentUser$ = this.cachedCurrentUserService.get().pipe(take(1));
        let initialCase$: Observable<Partial<SupportCase>>;

        if (Boolean(this.caseId)) {
            initialCase$ = this.supportCreateEditService.editInitialCase(this.caseId);
        } else if (this.caseTemplate) {
            initialCase$ = of(this.supportCreateEditService.createInitialCase(this.caseTemplate));
        } else {
            initialCase$ = of(this.supportCreateEditService.createInitialCase(this.draftService.getDraft()));
        }

        // We don't allow edit and severity in UI - furthermore, some cases might have it empty
        if (!this.caseId) {
            this.supportCaseForm.addControl('severity', new UntypedFormControl('', Validators.required));
        }

        zip(initialCase$, currentUser$)
            .pipe(take(1))
            .subscribe(([initialCase, currentUser]) => {
                this.initialCase = initialCase;

                // Normalize product line
                if (
                    initialCase.productLine &&
                    initialCase.productLine !== ProductLine.FlashArray &&
                    initialCase.productLine !== ProductLine.Portworx &&
                    initialCase.productLine !== ProductLine.Others &&
                    initialCase.productLine !== ProductLine.FlashBlade &&
                    initialCase.productLine !== ProductLine.DisasterRecovery
                ) {
                    this.initialCase.productLine = ProductLine.Others;
                }
                this.supportCaseForm.controls.subject.setValue(this.initialCase.subject);
                this.supportCaseForm.controls.description.setValue(this.initialCase.description);
                if (this.supportCaseForm.controls.severity) {
                    this.supportCaseForm.controls.severity.setValue(this.initialCase.severity);
                }

                this.oldFormValue = this.supportCaseForm.value;
                this.valueChanges$ = this.supportCaseForm.valueChanges.subscribe(this.saveDraft);
                this.loading = false;
            });

        // Determine visibility of warning card for out of support appliances
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.VIEW_SUPPORT_EXPIRED_APPLIANCE_WARNING_CARD)
            .subscribe(feature => {
                this.checkSupportContract = feature?.enabled;
            });

        this.ampli.supportCaseModalOpened();
    }

    ngOnDestroy(): void {
        if (!this.submitted) {
            this.ampli.supportCaseModalClosed();
        }
        if (this.valueChanges$ && !this.valueChanges$.closed) {
            this.valueChanges$.unsubscribe();
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    saveDraft = (newValue: Partial<SupportCaseFormValue>) => {
        // no drafts when editing a case
        if (this.caseId) {
            return;
        }
        const changedFields = Object.keys(this.supportCaseForm.value).filter(
            key => this.oldFormValue[key] !== newValue[key],
        );

        if (changedFields.length === 1) {
            const fieldName = changedFields[0];
            const fieldValue = newValue[fieldName];
            if (fieldName === 'contacts') {
                this.draftService.setDraftField('contact', fieldValue.primary);
                this.draftService.setDraftField('alternateContact', fieldValue.alternate);
            } else if (fieldName === 'product') {
                this.draftService.setDraftField('subCategory', fieldValue.subCategory.subCategory);
                this.draftService.setDraftField('productLine', fieldValue.subCategory.productLine);
                // cleanup product on productline change
                if (
                    fieldValue.subCategory.productLine != this.oldFormValue.product?.subCategory.productLine ||
                    !fieldValue.selectedProduct?.product
                ) {
                    this.draftService.setDraftField('array', undefined);
                } else if (fieldValue.selectedProduct?.product) {
                    switch (fieldValue.subCategory.group) {
                        case SUB_CATEGORY_GROUP.FlashArray:
                        case SUB_CATEGORY_GROUP.FlashBlade:
                            this.draftService.setDraftField('array', {
                                arrayId: fieldValue.selectedProduct.product.arrayId,
                            });
                            break;
                        case SUB_CATEGORY_GROUP.Portworx:
                        case SUB_CATEGORY_GROUP.EvergreenOne:
                        case SUB_CATEGORY_GROUP.DisasterRecovery:
                            this.draftService.setDraftField(
                                'array',
                                fieldValue.selectedProduct.product.id
                                    ? { id: fieldValue.selectedProduct.product.id }
                                    : { id: fieldValue.selectedProduct.product },
                            );
                            break;
                        default:
                            this.draftService.setDraftField('array', { id: fieldValue.selectedProduct.product });
                    }
                }
            } else {
                this.draftService.setDraftField(fieldName, fieldValue);
            }
        }
        this.oldFormValue = this.supportCaseForm.value;
    };

    submitCase(): void {
        ampli.supportCaseModalSubmitted();
        this.submitted = true;
        this.activeModal.close();
        const supportCase = cloneDeep(this.initialCase);

        // subject, description, severity are simple, just assign
        supportCase['subject'] = this.supportCaseForm.value.subject;
        supportCase['description'] = this.supportCaseForm.value.description;
        supportCase['severity'] = this.supportCaseForm.value.severity;

        // normalize contacts
        const contacts = this.supportCaseForm.value.contacts;
        supportCase.contact = contacts.primary;
        supportCase.alternateContact = contacts.alternate;

        // normalize product - this may be actually coming in as null on update
        const product = this.supportCaseForm.value.product;
        if (product) {
            supportCase.subCategory = product.subCategory.subCategory;
            supportCase.productLine = product.subCategory.productLine;
            if (product.selectedProduct?.product) {
                supportCase.array = product.selectedProduct.product;
                switch (product.subCategory.group) {
                    case SUB_CATEGORY_GROUP.Portworx:
                        // In case of cluster that is not in the cluster list, there will be just string id
                        if (typeof product.selectedProduct.product === 'string') {
                            supportCase.array = undefined;
                            supportCase.description +=
                                '\n\nCluster UUID (freetext): ' + product.selectedProduct.product;
                            supportCase.arrayId = product.selectedProduct.product;
                        } else {
                            supportCase.description += '\n\nCluster UUID: ' + product.selectedProduct.product.id;
                            supportCase.arrayId = product.selectedProduct.product.id;
                        }
                        break;
                    case SUB_CATEGORY_GROUP.EvergreenOne:
                        supportCase.description += `\n\nLicense: ${product.selectedProduct.product.id} (${product.selectedProduct.product.name})`;
                        supportCase.arrayId = product.selectedProduct.product.id;
                        break;
                    default:
                        supportCase.arrayId = product.selectedProduct.product.arrayId;
                        supportCase.purityVersion = product.selectedProduct.product.version;
                }
            } else {
                // this cleanup might be necessary as draft can contain outdated value of product
                // this is caused by no cleanup in initialCase in case the productLine changes
                supportCase.array = undefined;
            }
        }

        // Valid product lines for backend are FlashArray, FlashBlade, Portworx, DRAAS and Others
        if (
            ![
                ProductLine.FlashArray,
                ProductLine.FlashBlade,
                ProductLine.Portworx,
                ProductLine.DisasterRecovery,
            ].includes(supportCase.productLine)
        ) {
            supportCase.productLine = ProductLine.Others;
        }

        // set proper category based on subcategory
        supportCase.category = getCategoryForSubcategory(supportCase.subCategory);

        this.createCaseDoneFlag = false;

        let submitCase$: Observable<SupportCase>;
        if (Boolean(this.caseId)) {
            submitCase$ = this.supportCreateEditService.editSubmitCase(supportCase);
        } else {
            submitCase$ = this.supportCreateEditService.createSubmitCase(supportCase);
        }
        submitCase$.subscribe(
            response => {
                if (response?.caseNumber) {
                    this.caseId = response.caseNumber;
                }
                this.createCaseDoneFlag = true;
                this.createCaseErrorFlag = false;
                this.draftService.cancelDraft();
                if (response?.supportProvider) {
                    this.createCaseForAspFlag = true;
                }
            },
            _err => {
                this.createCaseDoneFlag = true;
                this.createCaseErrorFlag = true;
            },
        );
        this.ngbModal.open(this.confirmModal);
    }

    cancelCase(): void {
        this.draftService.cancelDraft();
        this.activeModal.dismiss();
    }

    hasPermission(permission: string): Observable<boolean> {
        return this.authzServiceResolver
            .getDefaultService()
            .pipe(switchMap(service => service.hasPermission(permission)));
    }
}
