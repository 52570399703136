import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { Invoice, InvoiceStatus } from '../../../data';
import { AssetManagementSummaryInfo } from '../../asset-management-summary-card/asset-management-summary-card.component';

@Component({
    selector: 'invoice-management-summary-totals',
    templateUrl: './invoice-management-summary-totals.component.html',
})
export class InvoiceManagementSummaryTotalsComponent implements OnChanges {
    @Input() readonly invoices: Invoice[];

    summaries: AssetManagementSummaryInfo[];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.invoices) {
            const totalInvoices = this.invoices?.length;
            const totalPaidInvoices = this.invoices?.filter(invoice => invoice.status === InvoiceStatus.OPEN).length;
            const totalOverdueInvoices = this.invoices?.filter(
                invoice => invoice.dueDate.isBefore(moment()) && invoice.status !== InvoiceStatus.PAID_IN_FULL,
            ).length;
            const closedInvoices = this.invoices?.filter(
                invoice => invoice.status === InvoiceStatus.PAID_IN_FULL,
            ).length;

            this.summaries = [
                {
                    title: 'Total Invoices',
                    svg: 'file-grey.svg',
                    value: totalInvoices,
                },
                {
                    title: 'Open Invoices',
                    svg: 'file-blue.svg',
                    value: totalPaidInvoices,
                },
                {
                    title: 'Past Due Invoices',
                    svg: 'file-red-warn.svg',
                    value: totalOverdueInvoices,
                },
                {
                    title: 'Closed Invoices',
                    svg: 'file-teal-check.svg',
                    value: closedInvoices,
                },
            ];
        }
    }
}
