import { Component, ViewChild } from '@angular/core';
import { ContentDialogComponent } from '@pure/hive';
import { StorageService } from '../../services/storage.service';

export const DO_NOT_SHOW_RASPBERRY_THEMING_MODAL = 'do-not-show-introducing-raspberry-theming-modal';

@Component({
    selector: 'introducing-raspberry-theming',
    templateUrl: `./introducing-raspberry-theming.component.html`,
})
export class IntroducingRaspberryThemingComponent {
    @ViewChild(ContentDialogComponent) contentDialogComponent?: ContentDialogComponent;

    constructor(private storageService: StorageService) {}

    protected close(): void {
        this.contentDialogComponent.hide();
    }

    updateDoNotShowAgain(value: boolean): void {
        if (value) {
            this.storageService.set(DO_NOT_SHOW_RASPBERRY_THEMING_MODAL, true);
        } else {
            this.storageService.remove(DO_NOT_SHOW_RASPBERRY_THEMING_MODAL);
        }
    }

    show(): void {
        if (this.storageService.get(DO_NOT_SHOW_RASPBERRY_THEMING_MODAL)) {
            return;
        }

        this.contentDialogComponent?.show();
    }
}
