<div class="upload-modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="dismiss()">
            <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
        </button>
        <h3 class="modal-title"
            >Submit attachment
            <span *ngIf="multiple">- {{ current }} of {{ total }}</span>
        </h3>
    </div>

    <div class="modal-body">
        <div *ngIf="currentUrl" id="file-upload-preview">
            <img id="preview-img" [src]="currentUrl" />
        </div>

        <div class="form-group row">
            <label class="col-xs-3 col-form-label text-xs-right required" for="file-name">Title</label>
            <div class="col-xs-9">
                <input type="text" class="form-control" id="file-name" maxlength="50" [(ngModel)]="fileName" required />
                <div class="text-danger" [hidden]="fileName">File title cannot be blank.</div>
            </div>
        </div>
    </div>

    <div class="modal-footer btn-toolbar">
        <button class="btn btn-primary float-xs-right" [disabled]="!fileName" pure-read-only (click)="submit()"
            >Submit</button
        >
        <button class="btn btn-info float-xs-right" (click)="dismiss()">Cancel</button>
        <button class="btn btn-info float-xs-right" *ngIf="multiple" (click)="dismissAll('Cancel all.')"
            >Cancel All</button
        >
    </div>
</div>
