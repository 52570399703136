<div *ngIf="cases === undefined" class="p-loading"><pureui-spinner [pureuiShow]="!anyCases"></pureui-spinner></div>

<ul class="support-nav-cases list-group">
    <li
        *ngFor="let case of cases"
        class="support-nav-case list-group-item list-group-item-action"
        [class.active]="selectedCaseId === case.id"
        [class.support-nav-case-unread]="
            !case.isClosed && case.lastCaseActivity.valueOf() > case.lastUserView.valueOf()
        "
    >
        <a routerLink="/support/cases" [queryParams]="{ caseId: case.id }" class="support-nav-case-link">
            <div class="support-nav-case-escalated" *ngIf="$any(case).isEscalated">
                <pureui-svg class="pstg-secondary-icon" [svg]="'escalation.svg'" [height]="10"></pureui-svg>
            </div>
            <div>
                <span class="support-nav-case-number pull-left" [innerHTML]="'#' + $any(case).caseNumber"></span>
                <span class="support-nav-case-lastCaseActivity pull-right">{{
                    case.isClosed
                        ? (case.closedDate | moment2date | date: 'YY/MM/dd h:mm a')
                        : (case.lastCaseActivity | moment2date | date: 'YY/MM/dd h:mm a')
                }}</span
                ><br />
                <span class="support-nav-case-subject clearfix" [innerHTML]="case.subject"></span>
            </div>
            <div *ngIf="$any(case).array">
                <span class="support-nav-case-hostname" [innerHTML]="$any(case).array.hostname"></span>
                <span *ngIf="$any(case).array.model" class="support-nav-case-extra">
                    | Model: <span class="support-nav-case-model" [innerHTML]="$any(case).array.model"></span>
                </span>
                <span *ngIf="$any(case).array.version" class="support-nav-case-extra">
                    | Version: <span class="support-nav-case-version" [innerHTML]="$any(case).array.version"></span>
                </span>
            </div>
            <div *ngIf="$any(case).description" class="support-nav-case-extra">
                <span class="support-nav-case-description" [innerHTML]="$any(case).description"></span>
            </div>
        </a>
    </li>
</ul>
