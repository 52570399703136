import { Renderer2, ElementRef, NgZone } from '@angular/core';

/**
 * Wait until elFn returns something truthy. Typically an ElementRef when used with @ViewChild decorator
 * @param elFn Function returning something that might not be truthy just right now.
 * @returns A promise that is resolved if elFn() becomes truthy, or rejected othterwise
 */
export function waitUntilDefined<T>(elFn: () => T): Promise<T> {
    const maxRetry = 10;
    return new Promise((resolve, reject) => {
        const f = (retryCount: number) =>
            window.setTimeout(() => {
                try {
                    const el = elFn();
                    if (el) {
                        resolve(el);
                    } else if (retryCount >= maxRetry) {
                        reject('waitUntilDefined exceeded maxRetry count');
                    } else {
                        f(retryCount + 1);
                    }
                } catch (ex) {
                    console.error('waitUntilDefined error', ex);
                    reject(ex);
                }
            }, 10);
        f(0);
    });
}

/**
 * Helper for adding an event listener that only triggers on child elements that match the given selector.
 * @param elem Root element to add the listener to
 * @param eventName Event to listen for
 * @param selector Only execute on child elements that match this selector
 * @param callback Callback to run when the event triggers for a child that matches the selector. Contains the original event.
 * @returns A function to call to unbind the event.
 */
export function addDelegateEventListener(
    ngZone: NgZone,
    renderer: Renderer2,
    elem: ElementRef,
    eventName: string,
    selector: string,
    callback: (e: UIEvent) => void,
): () => void {
    return renderer.listen(elem.nativeElement, eventName, (e: UIEvent) => {
        const target = e.target as HTMLElement;
        if (target?.closest?.(selector)) {
            callback(e);
        }
    });
}

/**
 * Checks if the given element has a vertical scrollbar. Or, if elem not specified, checks the document body.
 */
function hasVerticalScroll(elem?: HTMLElement): boolean {
    if (elem) {
        return elem.scrollHeight > elem.offsetHeight;
    } else {
        if (window.innerHeight) {
            return document.body && document.body.offsetHeight > window.innerHeight;
        } else {
            return (
                (document.documentElement &&
                    document.documentElement.scrollHeight > document.documentElement.offsetHeight) ||
                (document.body && document.body.scrollHeight > document.body.offsetHeight)
            );
        }
    }
}

/**
 * Continually executes the infinite scroll's addMore function until the target element (usually window) has a scrollbar, or we run out of items.
 * @param hasMore Function that returns true if we have more items we can add (that is, if addMore() will even do anything)
 * @param addMore Function to trigger adding more items to the infinite scroll
 * @param scrollElem The infinite scroll target element, or null for the window. By default, infinite scroll uses the window.
 */
export function infiniteScrollFillContainer(
    hasMore: () => boolean,
    addMore: () => void,
    scrollElem?: HTMLElement,
): void {
    const internalLoop = (iteration: number): void => {
        // Safety net: never expect to make this many iterations
        // Don't take it personally. Its the DOM I don't trust, not you.
        if (iteration > 30) {
            console.error(`Aborting infiniteScrollFillElement after ${iteration} iterations`);
            return;
        }

        if (hasMore() && !hasVerticalScroll(scrollElem)) {
            addMore();

            // The timeout gives time for the elements to display on the UI
            window.setTimeout(() => {
                internalLoop(iteration + 1);
            }, 100);
        }
    };

    internalLoop(1);
}

export function copyElemTextToClipboard(elem: ElementRef, event: Event, doc?: Document): void {
    // TODO: This does not work in Safari. CLOUD-33577
    elem.nativeElement.select();
    if (doc) {
        doc.execCommand('copy');
    } else {
        document.execCommand('copy');
    }
    event.stopPropagation();
}

/**
 * HACK: Get a reference to the base page content element (the main body). Eg, if you need to set the page scroll position.
 * Hack because this should probably be done in a more proper Angular way (eg using DI), and more strictly bound instead
 * of just querying the DOM.
 *
 * Note: If you're using this in a test, add a wrapper <div id="page-content-wrapper"> in your HostComponent to avoid
 * null references. Eg, see: service-catalog-details.component.spec.ts
 */
export function getPageBaseContentElem(): HTMLElement {
    return document.getElementById('page-content-wrapper')?.parentElement;
}
