import { Angulartics2 } from 'angulartics2';
import { Injectable } from '@angular/core';
import { track } from '@amplitude/analytics-browser';

/**
 * Angulartics2 provider for Amplitude.
 * Do NOT use the Amplitude plugin provided by Angulartics2. That relies on using the window.amplitude object, which is different
 * than the @amplitude/analytics-browser package used here.
 */
@Injectable({ providedIn: 'root' })
export class Angulartics2AmplitudePSTG {
    constructor(private angulartics2: Angulartics2) {}

    startTracking(): void {
        this.angulartics2.pageTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe((x: any) => this.pageTrack(x.path));

        this.angulartics2.eventTrack
            .pipe(this.angulartics2.filterDeveloperMode())
            .subscribe((x: any) => this.eventTrack(x.action, x.properties));
    }

    pageTrack(path: string, urlParams: string = ''): void {
        try {
            this.eventTrack('Pageview', {
                url: path,
                urlParams: urlParams,
            });
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                // Ignore errors due to Amplitude not being available/loaded yet (just in case...)
                throw e;
            }
        }
    }

    eventTrack(action: string, properties: any): void {
        try {
            track(action, properties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }
}
