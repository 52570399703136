import { Pipe, PipeTransform } from '@angular/core';
import { SubscriptionReportType } from '../../export/services/export-request-builder.service';

export const SUBSCRIPTION_REPORT_TYPE_MAP = new Map<SubscriptionReportType, string>([
    [SubscriptionReportType.RAW, 'Raw Usage (.csv)'],
    [SubscriptionReportType.CONSOLIDATED, 'Consolidated Usage (.xlsx)'],
]);

@Pipe({
    name: 'subscriptionReportType',
})
export class SubscriptionReportTypePipe implements PipeTransform {
    transform(type: SubscriptionReportType): string {
        return SUBSCRIPTION_REPORT_TYPE_MAP.get(type) || '';
    }
}
