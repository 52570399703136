import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagService, Pure1NavigationService } from '@pure/pure1-ui-platform-angular';
import { of, switchMap } from 'rxjs';
import { WINDOW } from '../../app/injection-tokens';
import { FeatureNames } from '../../model/FeatureNames';
import { CreateEditSupportCaseComponent } from '../create-edit-support-case/create-edit-support-case.component';
import { SupportCase } from '../support.interface';

@Injectable({
    providedIn: 'root',
})
export class SupportCenterRedirectService {
    constructor(
        private featureFlagService: FeatureFlagService,
        private navigationService: Pure1NavigationService,
        private ngbModal: NgbModal,
        private router: Router,
        @Inject(WINDOW) private window: Window,
    ) {}

    openSupportCenter(caseId?: string): void {
        this.featureFlagService
            .getFeatureFlags(FeatureNames.SUPPORT_CENTER)
            .pipe(
                switchMap(feature => {
                    if (feature[FeatureNames.SUPPORT_CENTER].enabled) {
                        if (caseId) {
                            return this.navigationService.link('support-center', `cases/${caseId}`);
                        } else {
                            return this.navigationService.link('support-center');
                        }
                    } else {
                        let options = {};
                        if (caseId) {
                            options = { queryParams: { caseId: caseId } };
                        }
                        this.router.navigate(['/support/cases'], options);
                        return of();
                    }
                }),
            )
            .subscribe(url => {
                if (url) {
                    this.window.open(url, '_blank');
                }
            });
    }

    openCreateCase(
        modal?: TemplateRef<NgbActiveModal> | typeof CreateEditSupportCaseComponent,
        supportCase?: Partial<SupportCase>,
        searchArray?: string,
    ): void {
        this.featureFlagService
            .getFeatureFlags(FeatureNames.SUPPORT_CENTER)
            .pipe(
                switchMap(feature => {
                    if (feature[FeatureNames.SUPPORT_CENTER].enabled) {
                        let params = new HttpParams();
                        if (supportCase) {
                            if (supportCase.category) {
                                params = params.append('category', supportCase.category);
                            }
                            if (supportCase.subCategory) {
                                params = params.append('subCategory', supportCase.subCategory);
                            }
                            if (supportCase.severity) {
                                params = params.append('severity', supportCase.severity);
                            }
                            if (supportCase.subject) {
                                params = params.append('subject', supportCase.subject);
                            }
                            if (supportCase.description) {
                                params = params.append('description', supportCase.description);
                            }
                            if (supportCase.productLine) {
                                params = params.append('productLine', supportCase.productLine);
                            }
                        }
                        if (searchArray) {
                            params = params.append('searchArray', searchArray);
                        }
                        return this.navigationService.link('support-center', `createCase?${params.toString()}`);
                    } else {
                        const modalRef = this.ngbModal.open(modal);
                        modalRef.componentInstance.activeModal = modalRef;
                        modalRef.componentInstance.caseTemplate = supportCase;
                        modalRef.componentInstance.searchArrayName = searchArray;
                        return of();
                    }
                }),
            )
            .subscribe(url => {
                if (url) {
                    this.window.open(url, '_blank');
                }
            });
    }
}
