import { Resource } from '../interfaces/resource';
import { BaseSnapshot } from './base-snapshot';

export class DirectorySnapshot extends BaseSnapshot {
    uuid: string;
    policy: Resource;

    constructor(json: any) {
        super(json);

        this.uuid = json.uuid;
        this.type = json.target_type;
        if (json.directory) {
            this.source_global_id = json.directory.id; // directory snapshot does not have the source_global_id field
        }
        if (json.policy) {
            this.policy = {
                name: json.policy.name,
                id: json.policy.uuid,
            };
        }
    }
}
