import moment from 'moment';

import { Historical } from '../interfaces/history';
import { Pod } from './pod';

export class HistoricalPod extends Pod implements Historical<Pod> {
    readonly _as_of: moment.Moment;
    readonly _state_started: moment.Moment;
    readonly _state_ended: moment.Moment | null;

    constructor(json: any) {
        super(json);
        this._as_of = moment(json._as_of);
        this._state_started = moment(json._state_started);
        this._state_ended = json._state_ended ? moment(json._state_ended) : null;
    }
}
