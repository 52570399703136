import moment, { Duration } from 'moment';
import {
    DraasApiReplicationStrategySourceProvider,
    DraasApiReplicationStrategyTargetProvider,
    DraasApiReplicationStrategyProviderTemplate,
} from '@pure/paas-api-gateway-client-ts';

export type ProviderType = 'VSPHERE' | 'AWS';

type DraasApiReplicationStrategySite =
    | DraasApiReplicationStrategySourceProvider
    | DraasApiReplicationStrategyTargetProvider;

export class DisasterRecoveryReplicationStrategySiteBase<T extends DisasterRecoveryReplicationStrategySiteBase<T>> {
    ordinal: number;
    providerType: ProviderType;
    replicationTargets: T[];

    constructor(
        json: DraasApiReplicationStrategySite | DraasApiReplicationStrategyProviderTemplate,
        targetClass: { new (json: any): T },
    ) {
        this.ordinal = json.ordinal;
        this.providerType = json.provider_type;
        this.replicationTargets = json.replication_targets.map(
            replicationTargetJson => new targetClass(replicationTargetJson),
        );
    }

    get flattenedTargets(): T[] {
        return this.replicationTargets.reduce(
            (flattenedTargets, site) => [...flattenedTargets, ...site.flattenedTargets],
            this.replicationTargets,
        );
    }
}

export class DisasterRecoveryReplicationStrategySiteTemplate extends DisasterRecoveryReplicationStrategySiteBase<DisasterRecoveryReplicationStrategySiteTemplate> {
    constructor(json: DraasApiReplicationStrategyProviderTemplate) {
        super(json, DisasterRecoveryReplicationStrategySiteTemplate);
    }
}

export class DisasterRecoveryReplicationStrategySite extends DisasterRecoveryReplicationStrategySiteBase<DisasterRecoveryReplicationStrategyTargetSite> {
    providerId?: string;
    name?: string;
    retention: Duration;

    constructor(json: DraasApiReplicationStrategySourceProvider | DraasApiReplicationStrategyTargetProvider) {
        super(json, DisasterRecoveryReplicationStrategyTargetSite);
        this.providerId = json.provider_id;
        this.name = json.name;
        this.retention = moment.duration(json.retention);
    }
}

export class DisasterRecoveryReplicationStrategySourceSite extends DisasterRecoveryReplicationStrategySite {}

export class DisasterRecoveryReplicationStrategyTargetSite extends DisasterRecoveryReplicationStrategySite {
    estimatedRto: Duration;

    constructor(json: DraasApiReplicationStrategyTargetProvider) {
        super(json);
        this.estimatedRto = moment.duration(json.estimated_rto);
    }
}
