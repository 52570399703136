<div class="read-only">
    <span *ngIf="product.model"> Model: {{ product.model }} </span>

    <span class="support-create-array-version" *ngIf="product.version"> OS: Purity {{ product.version }} </span>

    <span
        class="support-create-array-contract-expiration"
        *ngIf="checkSupportContract && !!product.contract_expiration"
    >
        <ng-container *ngIf="product.isOutOfSupport()">
            Support expired&nbsp;<span class="text-danger">{{
                normalizeMoment(product.contract_expiration) | date: 'MM-dd-yyyy'
            }}</span>
        </ng-container>

        <ng-container *ngIf="product.isSupportInGracePeriod()">
            Support expired&nbsp;<span class="text-warning">{{
                normalizeMoment(product.contract_expiration) | date: 'MM-dd-yyyy'
            }}</span>
        </ng-container>

        <ng-container *ngIf="product.isSupportActive()">
            Support expires {{ normalizeMoment(product.contract_expiration) | date: 'MM-dd-yyyy' }}
        </ng-container>
    </span>
</div>
