import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LicenseType, ProgramType, IRestResponse } from '@pure1/data';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

type TypeMapping = { [key in ProgramType]: LicenseType[] };

const BASE_URL = '/rest/v3/subscription-licenses';
const LICENSE_TYPE_URL = BASE_URL + '/types';

@Injectable({ providedIn: 'root' })
export class LicenseTypeMappingService {
    constructor(private http: HttpClient) {}

    getTypeMapping(): Observable<Map<ProgramType, LicenseType[]>> {
        return this.http.get(LICENSE_TYPE_URL).pipe(
            map((response: IRestResponse<TypeMapping>) => {
                const mapping = new Map<ProgramType, LicenseType[]>();
                // sample of payload:
                // {
                //    items": [{
                //         "FlashStack as a Service": [
                //             "Block - Ultra Performance Storage Service",
                //             "Block - High Performance Storage Service",
                //             "Block - Performance Storage Service",
                //             "Cloud Block Storage",
                //             "Consolidated - Capacity Storage Service",
                //             "FileObject - Performance Storage Service"
                //         ]
                //      }, ...
                //    ],
                // }
                response.items.forEach(item => {
                    const key = Object.keys(item)[0] as ProgramType;
                    mapping.set(key, item[key]);
                });
                return mapping;
            }),
            catchError((err: HttpErrorResponse) => {
                console.warn(`License type mapping endpoint returned ${err.status}`);
                return of(new Map<ProgramType, LicenseType[]>());
            }),
        );
    }
}
