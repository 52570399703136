import { Pipe, type PipeTransform } from '@angular/core';
import { OpportunityQuote, QuoteStatus } from '@pure1/data';

export interface QuoteAction {
    label: string;
    acceptedStates: QuoteStatus[];
    action: (quote: OpportunityQuote) => void;
}

@Pipe({
    name: 'filterQuoteActions',
})
export class FilterQuoteActionsPipe implements PipeTransform {
    transform(quoteActions: QuoteAction[], quote: OpportunityQuote): QuoteAction[] {
        return quoteActions.filter((quoteAction: QuoteAction) => quoteAction.acceptedStates.includes(quote.status));
    }
}
