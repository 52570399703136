import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataPage } from '../interfaces/data-page';
import { ListParams } from '../interfaces/list-params';
import { ArrayData, DataProtectionV2EntityType } from '../models/assessment-array';
import { DataProtectionV2EntityDetailData } from '../models/data-protection-v2-entity-detail-data';
import { GenericService } from './generic.service';

@Injectable({
    providedIn: 'root',
})
export class DataProtectionV2AssessmentDetailsService extends GenericService<DataProtectionV2EntityDetailData> {
    constructor(protected readonly http: HttpClient) {
        super({
            resourceClass: DataProtectionV2EntityDetailData,
            endpoint: '',
        });
        this.setEntityType();
    }

    setEntityType(entityType: DataProtectionV2EntityType = DataProtectionV2EntityType.volume): void {
        switch (entityType) {
            case DataProtectionV2EntityType.directory:
                this.serviceParams.endpoint = `/rest/v1/dpa/fa-directories`;
                break;
            case DataProtectionV2EntityType.volume:
                this.serviceParams.endpoint = `/rest/v1/dpa/fa-volumes`;
                break;
            case DataProtectionV2EntityType.fileSystem:
                this.serviceParams.endpoint = `/rest/v1/dpa/fb-filesystems`;
                break;
            case DataProtectionV2EntityType.bucket:
                this.serviceParams.endpoint = `/rest/v1/dpa/fb-buckets`;
                break;
            default:
                throw new Error('Unexciting entity type');
        }
    }
}
