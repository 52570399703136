import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Output, EventEmitter, Input, TemplateRef, HostBinding } from '@angular/core';

@Component({
    selector: 'drawer',
    templateUrl: './drawer.component.html',
    animations: [
        trigger('openCloseAnimation', [
            state(
                '*',
                style({
                    // Closed (default) state
                    'margin-left': '{{ width }}', // Must equal $drawer-width in drawer.component.scss
                    opacity: 0,
                }),
                { params: { width: '400px' } },
            ),
            state(
                'open',
                style({
                    'margin-left': 0,
                    opacity: 1,
                }),
            ),
            transition('* <=> open', animate('500ms ease')),
        ]),
    ],
})
export class DrawerComponent {
    @Input() readonly width: number = 400;
    @Input() readonly visibleSection: number = 0;
    @Input() readonly alwaysVisible: boolean;
    @Input() readonly drawerClosed: boolean = true;
    @Input() readonly contentTemplate: TemplateRef<any>;
    @Output() readonly closeDrawerClick = new EventEmitter<void>();

    get calculatedAnimationWidth(): number {
        return this.width - this.visibleSection;
    }
    @HostBinding('style.width.px')
    get calculatedWidth(): number {
        // 15 is the $drawer-shadow-padding in the scss
        return this.width + 15;
    }

    closeDrawer(): void {
        this.closeDrawerClick.emit();
    }
}
