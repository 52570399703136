<div class="upgrade-scheduler-wrapper">
    <aw-wizard *ngIf="!submitting && !submitted" #wizard navBarLayout="small" class="pure-common-wizard">
        <aw-wizard-step stepTitle="Select appliances">
            <p>
                <span class="bolded-text">Prerequisite -</span>
                Enabling/Utilizing SafeMode requires an evaluation by your Account Team. You may open a support case to
                facilitate that request or reach out to your Account Team directly.
            </p>
            <div class="mb-1 row">
                <div class="col-sm-9">
                    <ng-container *ngIf="isEnablement; else requestChangesText">
                        Select the appliances you want to enable SafeMode for:
                    </ng-container>
                    <ng-template #requestChangesText>
                        <div class="mb-1">Select the appliances you want to request SafeMode changes for:</div>
                    </ng-template>
                </div>
                <div class="col-sm-3 product-line-container">
                    <label class="form-check-inline" (click)="selectProductLine(ProductLine.FlashArray)">
                        <input
                            type="radio"
                            class="form-check-input"
                            name="productline-flasharray"
                            [value]="'FlashArray'"
                            [checked]="selectedProductLine === ProductLine.FlashArray"
                        />
                        FlashArray
                    </label>
                    <label class="form-check-inline" (click)="selectProductLine(ProductLine.FlashBlade)">
                        <input
                            type="radio"
                            class="form-check-input"
                            name="productline-flashblade"
                            [value]="'FlashBlade'"
                            [checked]="selectedProductLine === ProductLine.FlashBlade"
                        />
                        FlashBlade
                    </label>
                </div>
            </div>

            <safemode-array-selector
                [arrays]="arraysFilteredByProduct"
                [loading]="loading"
                [initialSelection]="initialArrays"
                [isEnablement]="isEnablement"
                [hasSNOWContact]="hasSNOWContact"
                [isSNOWInitialized]="isSNOWInitialized"
                (onCancel)="cancel()"
                (onForward)="setArrays($event)"
            ></safemode-array-selector>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Schedule">
            <safemode-enable-planner
                *ngIf="shouldLoadTimeslots"
                [arrays]="arraysToEnable"
                [isEnablement]="isEnablement"
                [productLine]="selectedProductLine"
                [freeTimeUpdates]="$any(freeTimeUpdates)"
                (onBack)="showStep(0)"
                (onCancel)="cancel()"
                (onForward)="setSchedule($event)"
            ></safemode-enable-planner>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Confirm request">
            <safemode-enable-confirmation
                [schedule]="schedule"
                [timeslotsChecking]="timeslotsChecking"
                [timeslotsConflictFlag]="timeslotsConflictFlag"
                (onBack)="showStep(1)"
                (onCancel)="cancel()"
                (onForward)="doSchedule($event)"
            ></safemode-enable-confirmation>
        </aw-wizard-step>
    </aw-wizard>
    <div *ngIf="submitting || submitted">
        <div *ngIf="submitting" class="col-xs-12 waiting">
            <h4>Please wait while we process your request.</h4>
            <br />
            <div class="progress-wrapper">
                <ngb-progressbar
                    [value]="100"
                    [max]="100"
                    type="info"
                    [striped]="true"
                    [animated]="true"
                    height="24px"
                    class="bar"
                ></ngb-progressbar>
            </div>
        </div>
        <safemode-enable-scheduled-confirmation
            *ngIf="submitted"
            [caseId]="caseId"
            (onDismiss)="dismiss()"
            [createCaseDoneFlag]="createCaseDoneFlag"
            [createCaseErrorFlag]="createCaseErrorFlag"
            [schedule]="schedule"
            [email]="userEmail"
        ></safemode-enable-scheduled-confirmation>
    </div>
</div>
