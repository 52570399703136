<ng-template #sortHeader let-name="name" let-title="title" let-headerClass="headerClass">
    <th (click)="sortByColumn(name)" [ngClass]="headerClass">
        <span [ngClass]="getSortClass(name)">
            {{ title }}
        </span>
    </th>
</ng-template>

<table class="table table-hover array-selector-table">
    <thead class="thead-default" [class.loading]="loading">
        <tr class="multiline">
            <ng-container
                *ngTemplateOutlet="sortHeader; context: { name: 'name', title: 'Name', headerClass: 'name-header' }"
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="sortHeader; context: { name: 'model', title: 'Model', headerClass: 'model-header' }"
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    sortHeader;
                    context: { name: 'version', title: 'Version', headerClass: 'version-header' }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    sortHeader;
                    context: { name: 'storage', title: 'Storage', headerClass: 'storage-header' }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    sortHeader;
                    context: { name: 'status', title: 'Status', headerClass: 'status-header' }
                "
            ></ng-container>
            <ng-container
                *ngTemplateOutlet="
                    sortHeader;
                    context: { name: 'eradicationTimer', title: 'Eradication Delay', headerClass: 'eradication-header' }
                "
            ></ng-container>
        </tr>
        <tr class="multiline">
            <th>
                <input class="form-control" [(ngModel)]="nameFilter" (input)="updateFilter()" />
            </th>
            <th>
                <input class="form-control" [(ngModel)]="modelFilter" (input)="updateFilter()" />
            </th>
            <th>
                <input class="form-control" [(ngModel)]="currentVersionFilter" (input)="updateFilter()" />
            </th>
            <th>
                <input class="form-control" [(ngModel)]="storageFilter" (input)="updateFilter()" />
            </th>
            <th>
                <input class="form-control" [(ngModel)]="statusFilter" (input)="updateFilter()" />
            </th>
            <th>
                <input class="form-control" [(ngModel)]="eradicationFilter" (input)="updateFilter()" />
            </th>
        </tr>
    </thead>
    <tbody *ngIf="!loading && hasSNOWContact">
        <tr
            *ngFor="let array of filteredSortedArrays"
            (click)="toggleArraySelection(array.id)"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Assessment - SafeMode - Array Selector - toggle array"
        >
            <td class="hostname-cell array-name-cell">
                <span>
                    <input
                        type="checkbox"
                        [id]="'array-selector-' + array.id"
                        [checked]="selectedArraysMap[array.id]"
                    />
                </span>
                <label [htmlFor]="'array-selector-' + array.id">{{ array.name }}</label>
            </td>
            <td class="model-cell">{{ array.model }}</td>
            <td class="version-cell">{{ array.version }}</td>
            <td class="storage-cell">
                <div *ngIf="isFlashArray(array); else flashBladeStorage">
                    <div class="storage-type" *ngIf="array.safeMode.global"> All </div>
                </div>
                <ng-template #flashBladeStorage>
                    <div class="storage-type" *ngIf="array.safeMode.fileSystems"> File Systems </div>
                    <div class="storage-type" *ngIf="array.safeMode.objectStore"> Object Store </div>
                </ng-template>
            </td>
            <td class="status-cell">
                <div *ngIf="isFlashArray(array); else flashBladeStorageStatus">
                    <div class="storage-type-status" *ngIf="array.safeMode.global">
                        {{ array.safeMode.global.status }}
                    </div>
                </div>
                <ng-template #flashBladeStorageStatus>
                    <div class="storage-type-status" *ngIf="array.safeMode.fileSystems">
                        {{ array.safeMode.fileSystems.status }}
                    </div>
                    <div class="storage-type-status" *ngIf="array.safeMode.objectStore">
                        {{ array.safeMode.objectStore.status }}
                    </div>
                </ng-template>
            </td>
            <td class="eradication-cell">
                <div *ngIf="isFlashArray(array); else flashBladeEradicationTimer">
                    <div class="eradication-timer" *ngIf="array.safeMode.global">
                        {{ getDurationDays(array.safeMode.global.eradicationTimer) }}
                    </div>
                </div>
                <ng-template #flashBladeEradicationTimer>
                    <div class="eradication-timer" *ngIf="array.safeMode.fileSystems">
                        {{ getDurationDays(array.safeMode.fileSystems.eradicationTimer) }}
                    </div>
                    <div class="eradication-timer" *ngIf="array.safeMode.objectStore">
                        {{ getDurationDays(array.safeMode.objectStore.eradicationTimer) }}
                    </div>
                </ng-template>
            </td>
        </tr>
        <tr *ngIf="filteredSortedArrays && filteredSortedArrays.length === 0" class="no-results-row">
            <td> No results found. Please adjust your filters </td>
        </tr>
    </tbody>
</table>
<div *ngIf="loading || !isSNOWInitialized" class="col-xs-12 loader">
    <pureui-spinner [pureuiShow]="true"></pureui-spinner>
</div>

<div *ngIf="!loading && !hasSNOWContact && isSNOWInitialized" class="col-xs-12 loader">
    <div
        >You have read-only access. First time access to the Pure1 Manage Support Cases page requires a verification
        step. This process requires a minimum of 45 minutes to resolve. You can retry accessing the page again later. If
        this is not your first time, work with your Administrator to validate your access.</div
    >
</div>

<div class="btn-toolbar clearfix">
    <pure-entity-selection-dropdown
        entityName="Selections"
        [isSelectable]="false"
        [entities]="selectedArrays"
        (entitiesChange)="updateSelectedArrays($event)"
    >
    </pure-entity-selection-dropdown>
    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode - Array Selector - Continue schedule request"
        [angularticsLabel]="selectedArrays.length + ''"
        (click)="onNext()"
        [disabled]="!canSchedule()"
    >
        Schedule &gt;
    </button>
    <button
        type="button"
        class="btn btn-info float-xs-right margin"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode - Array Selector - Cancel schedule request"
        (click)="onCancel.emit()"
    >
        Cancel
    </button>
</div>
