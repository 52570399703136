// ScaleRaphael is an (incredibly outdated) Raphael plugin. Rewritten as a ts file so
// it can easily be imported only where it is needed, instead of attached to window like normal.

/* eslint-disable */
import Raphael from 'raphael';

export function ScaleRaphael(container, width, height) {
    var wrapper = document.getElementById(container);
    if (!wrapper.style.position) wrapper.style.position = 'relative';
    wrapper.style.width = width + 'px';
    wrapper.style.height = height + 'px';
    wrapper.style.overflow = 'hidden';

    var nestedWrapper;

    if (Raphael.type == 'VML') {
        wrapper.innerHTML =
            "<rvml:group style='position : absolute; width: 1000px; height: 1000px; top: 0px; left: 0px' coordsize='1000,1000' class='rvml' id='vmlgroup'></rvml:group>";
        nestedWrapper = document.getElementById('vmlgroup');
    } else {
        var id = 'svggroup' + container;
        wrapper.innerHTML = '<div id=' + id + '></div>';
        nestedWrapper = document.getElementById(id);
    }

    var paper = new (Raphael as any)(nestedWrapper, width, height);
    var vmlDiv;

    if (Raphael.type == 'SVG') {
        paper.canvas.setAttribute('viewBox', '0 0 ' + width + ' ' + height);
    } else {
        vmlDiv = wrapper.getElementsByTagName('div')[0];
    }

    paper.changeSize = function (w, h, center, clipping) {
        clipping = !clipping;

        var ratioW = w / width;
        var ratioH = h / height;
        var scale = ratioW < ratioH ? ratioW : ratioH;

        var newHeight = parseInt((height * scale) as any);
        var newWidth = parseInt((width * scale) as any);

        if (Raphael.type == 'VML') {
            // scale the textpaths
            var txt = document.getElementsByTagName('textpath');
            for (var i in txt) {
                var curr = txt[i] as any;
                if (curr.style) {
                    if (!curr._fontSize) {
                        var mod = curr.style.font.split('px');
                        curr._fontSize = parseInt(mod[0]);
                        curr._font = mod[1];
                    }
                    curr.style.font = curr._fontSize * scale + 'px' + curr._font;
                }
            }
            var newSize;
            if (newWidth < newHeight) {
                newSize = (newWidth * 1000) / width;
            } else {
                newSize = (newHeight * 1000) / height;
            }
            newSize = parseInt(newSize);
            nestedWrapper.style.width = newSize + 'px';
            nestedWrapper.style.height = newSize + 'px';
            if (clipping) {
                nestedWrapper.style.left = parseInt(((w - newWidth) / 2) as any) + 'px';
                nestedWrapper.style.top = parseInt(((h - newHeight) / 2) as any) + 'px';
            }
            vmlDiv.style.overflow = 'visible';
        }

        if (clipping) {
            newWidth = w;
            newHeight = h;
        }

        wrapper.style.width = newWidth + 'px';
        wrapper.style.height = newHeight + 'px';
        paper.setSize(newWidth, newHeight);

        if (center) {
            wrapper.style.position = 'absolute';
            wrapper.style.left = parseInt(((w - newWidth) / 2) as any) + 'px';
            wrapper.style.top = parseInt(((h - newHeight) / 2) as any) + 'px';
        }
    };

    paper.scaleAll = function (amount) {
        paper.changeSize(width * amount, height * amount);
    };

    paper.changeSize(width, height);

    paper.w = width;
    paper.h = height;

    return paper;
}
