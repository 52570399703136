import { Injectable } from '@angular/core';
import { FilterParams } from '../interfaces/list-params';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryTagCategory } from '../models/disaster-recovery-tag-category';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DraasApiTagCategory } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryTagCategoriesService extends DisasterRecoveryBaseService<
    DisasterRecoveryTagCategory,
    DraasApiTagCategory
> {
    protected pathParams = ['clusterId'];
    protected resourceClass = DisasterRecoveryTagCategory;

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryTagCategory>): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/tag-categories`;
    }
}
