import { Alert } from './Alert';
import { ApplianceType, AssessmentLevel, toAssessmentLevel } from '@pure1/data';
import { isBoolean, isNumber } from 'lodash';

export enum DpaAlertSafeModeStatus {
    Disabled = 'DISABLED',
    Granular = 'GRANULAR',
    Enabled = 'ENABLED',
}

export class DpaAlert extends Alert {
    blockSafeModeEnabledOld: boolean;
    blockSafeModeEnabledNew: boolean;
    blockEradicationTimerInDaysOld: number;
    blockEradicationTimerInDaysNew: number;
    fileSafeModeEnabledOld: boolean;
    fileSafeModeEnabledNew: boolean;
    fileEradicationTimerInDaysOld: number;
    fileEradicationTimerInDaysNew: number;
    objectSafeModeEnabledOld: boolean;
    objectSafeModeEnabledNew: boolean;
    objectEradicationTimerInDaysOld: number;
    objectEradicationTimerInDaysNew: number;
    blockAssessmentLevelOld: AssessmentLevel;
    blockAssessmentLevelNew: AssessmentLevel;
    fileAssessmentLevelOld: AssessmentLevel;
    fileAssessmentLevelNew: AssessmentLevel;
    objectAssessmentLevelOld: AssessmentLevel;
    objectAssessmentLevelNew: AssessmentLevel;
    granularSafeModeEnabledOld: boolean;
    granularSafeModeEnabledNew: boolean;
    applianceType: ApplianceType;
    overallAssessmentLevelOld: AssessmentLevel;
    overallAssessmentLevelNew: AssessmentLevel;
    arrayId: string;

    //calculated fields
    faSafeModeEnabledOld: DpaAlertSafeModeStatus;
    faSafeModeEnabledNew: DpaAlertSafeModeStatus;
    faEradicationTimerOld: number;
    faEradicationTimerNew: number;
    storageTypeAssessmentExists: boolean;

    constructor(json: any) {
        super(json);

        this.blockSafeModeEnabledOld = json.block_safemode_enabled_old;
        this.blockSafeModeEnabledNew = json.block_safemode_enabled_new;
        this.blockEradicationTimerInDaysOld = json.block_eradication_timer_in_days_old;
        this.blockEradicationTimerInDaysNew = json.block_eradication_timer_in_days_new;
        this.fileSafeModeEnabledOld = json.file_safemode_enabled_old;
        this.fileSafeModeEnabledNew = json.file_safemode_enabled_new;
        this.fileEradicationTimerInDaysOld = json.file_eradication_timer_in_days_old;
        this.fileEradicationTimerInDaysNew = json.file_eradication_timer_in_days_new;
        this.objectSafeModeEnabledOld = json.object_safemode_enabled_old;
        this.objectSafeModeEnabledNew = json.object_safemode_enabled_new;
        this.objectEradicationTimerInDaysOld = json.object_eradication_timer_in_days_old;
        this.objectEradicationTimerInDaysNew = json.object_eradication_timer_in_days_new;
        this.blockAssessmentLevelOld = toAssessmentLevel(json.block_assessment_level_old);
        this.blockAssessmentLevelNew = toAssessmentLevel(json.block_assessment_level_new);
        this.fileAssessmentLevelOld = toAssessmentLevel(json.file_assessment_level_old);
        this.fileAssessmentLevelNew = toAssessmentLevel(json.file_assessment_level_new);
        this.objectAssessmentLevelOld = toAssessmentLevel(json.object_assessment_level_old);
        this.objectAssessmentLevelNew = toAssessmentLevel(json.object_assessment_level_new);
        this.granularSafeModeEnabledOld = json.granular_safemode_enabled_old;
        this.granularSafeModeEnabledNew = json.granular_safemode_enabled_new;
        this.applianceType = json.appliance_type;
        this.overallAssessmentLevelOld = toAssessmentLevel(json.overall_assessment_level_old);
        this.overallAssessmentLevelNew = toAssessmentLevel(json.overall_assessment_level_new);
        this.arrayId = json.array_id;

        if (this.blockSafeModeEnabledOld !== this.blockSafeModeEnabledNew) {
            this.faSafeModeEnabledOld = this.getSafeModeStatus(
                this.blockSafeModeEnabledOld,
                this.granularSafeModeEnabledOld,
            );
            this.faSafeModeEnabledNew = this.getSafeModeStatus(
                this.blockSafeModeEnabledNew,
                this.granularSafeModeEnabledNew,
            );
        } else {
            this.faSafeModeEnabledOld = this.getSafeModeStatus(
                this.fileSafeModeEnabledOld,
                this.granularSafeModeEnabledOld,
            );
            this.faSafeModeEnabledNew = this.getSafeModeStatus(
                this.fileSafeModeEnabledNew,
                this.granularSafeModeEnabledNew,
            );
        }

        if (this.blockEradicationTimerInDaysOld !== this.blockEradicationTimerInDaysNew) {
            this.faEradicationTimerOld = this.blockEradicationTimerInDaysOld;
            this.faEradicationTimerNew = this.blockEradicationTimerInDaysNew;
        } else {
            this.faEradicationTimerOld = this.fileEradicationTimerInDaysOld;
            this.faEradicationTimerNew = this.fileEradicationTimerInDaysNew;
        }

        this.storageTypeAssessmentExists =
            this.blockAssessmentLevelNew !== null ||
            this.fileAssessmentLevelNew !== null ||
            this.objectAssessmentLevelNew !== null;
    }

    getSafeModeText(safeMode: boolean | DpaAlertSafeModeStatus): string {
        if (isBoolean(safeMode)) {
            return safeMode ? 'Enabled' : 'Disabled';
        } else {
            switch (safeMode) {
                case DpaAlertSafeModeStatus.Disabled:
                    return 'Disabled';
                case DpaAlertSafeModeStatus.Granular:
                    return 'Object Level';
                case DpaAlertSafeModeStatus.Enabled:
                    return 'Enabled';
                default:
                    break;
            }
        }

        // Should never happen
        return '';
    }

    getCssClass(
        oldValue: DpaAlertSafeModeStatus | number,
        newValue: DpaAlertSafeModeStatus | number,
    ): { [cssClass: string]: boolean } {
        let isBetter = false;
        let isWorse = false;

        if (isNumber(newValue)) {
            isBetter = newValue > <number>oldValue;
            isWorse = newValue < <number>oldValue;
        } else {
            if (oldValue === DpaAlertSafeModeStatus.Disabled && newValue !== DpaAlertSafeModeStatus.Disabled) {
                isBetter = true;
                isWorse = false;
            } else if (oldValue !== DpaAlertSafeModeStatus.Disabled && newValue === DpaAlertSafeModeStatus.Disabled) {
                isBetter = false;
                isWorse = true;
            }
        }

        return {
            better: isBetter,
            worse: isWorse,
        };
    }

    private getSafeModeStatus(safeModeStatus: boolean, granularSafeModeStatus: boolean): DpaAlertSafeModeStatus {
        if (safeModeStatus) {
            return DpaAlertSafeModeStatus.Enabled;
        } else if (granularSafeModeStatus) {
            return DpaAlertSafeModeStatus.Granular;
        } else {
            return DpaAlertSafeModeStatus.Disabled;
        }
    }
}
