import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Timeslot } from '../../support/support-upgrade/types';

@Pipe({
    name: 'getPrettyTime',
})
export class GetPrettyTimePipe implements PipeTransform {
    transform(timeSlot: Timeslot, timezone: string): string {
        return timeSlot.startTime.tz(timezone).format('HH:mm');
    }
}
