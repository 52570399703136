import { DraasApiAWSElasticIpGenerationRequest } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryAWSElasticIpGenerationRequest {
    readonly id: string;
    readonly providerId: string;
    readonly clusterId: string;
    readonly status: 'PENDING' | 'COMPLETED';
    readonly createdAt: Date;
    readonly lastUpdatedAt: Date;
    readonly awsElasticIpStatus: 'PENDING' | 'SYNCED' | 'ERROR';

    constructor(json: DraasApiAWSElasticIpGenerationRequest) {
        this.id = json.id;
        this.providerId = json.provider_id;
        this.clusterId = json.cluster_id;
        this.status = json.status;
        this.createdAt = new Date(json.created_at_utc);
        this.lastUpdatedAt = new Date(json.last_updated_at_utc);
        this.awsElasticIpStatus = json.aws_elastic_ip_status;
    }
}
