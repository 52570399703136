<div
    class="card-container"
    infiniteScroll
    [infiniteScrollContainer]="baseContentElem"
    [immediateCheck]="true"
    (scrolled)="infiniteScrollAddMore()"
>
    <div
        *ngFor="let item of displayedItems; trackBy: 'id' | trackByProperty"
        class="expanded-card-div"
        [ngClass]="{
            'expanded-array-disconnected': !item.isCurrent,
            'expanded-card': isCardExpanded(item),
            'contracted-card': !isCardExpanded(item)
        }"
        [class.flip]="isCardFlipped(item)"
        [class.animated]="isCardAnimated(item)"
    >
        <array-expanded-card
            [array]="$any(item)"
            [alerts]="getAlerts(item)"
            [arrayHealth]="getHealth(item)"
            [allOrders]="allOrders"
            [isCardFlipped]="isCardFlipped(item)"
            [showWarningCardForOutOfSupportAppliance]="showWarningCardForOutOfSupportAppliance"
            (onFlipCard)="flipCard(item, $event.event)"
            (onChangeExpanded)="setExpandedState(item, $event.isExpanded)"
        >
        </array-expanded-card>
    </div>
</div>
