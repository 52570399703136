import { DpaSafeMode, Product, SubscriptionAsset, UnifiedArray } from '@pure1/data';
import PureUtils from './pure_utils';
import { isFlashArray } from '../safemode/util';
import moment from 'moment';
import { cloneDeep } from 'lodash';

// Since DPA Service information is updating once per hour, it is pointless to refresh too often
export const SAFEMODE_REFRESH_INTERVAL = moment.duration(30, 'minutes').asMilliseconds();

const valueMap = {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    UPGRADE_REQUIRED: 'Upgrade Required',
    GRANULAR_ENABLED: 'Granular Enabled',
};

const defaultVal = '-';

export class DpaSafeModeUtils {
    // Only rewrites the data about SafeMode. If they do not exist in DPA SafeMode response, fallback to Mongo data is used
    static getArraysWithDpaSafeMode(arrays: UnifiedArray[], safeModeResult: DpaSafeMode[] = []): UnifiedArray[] {
        const safeModeMap = PureUtils.createMapGroupByProperty(safeModeResult, 'applianceID');
        const result = cloneDeep(arrays);

        for (const array of result) {
            const safeMode = safeModeMap.get(array.cloud_array_id);
            if (safeMode) {
                DpaSafeModeUtils.updateArraySafeModeInfo(array, safeMode[0]);
            } else {
                array.safeModeStatus = defaultVal;
            }
        }

        return result;
    }

    static getAssetsWithDpaSafeMode(
        assets: SubscriptionAsset[],
        safeModeResult: DpaSafeMode[] = [],
    ): SubscriptionAsset[] {
        const safeModeMap = PureUtils.createMapGroupByProperty(safeModeResult, 'applianceID');
        const result = cloneDeep(assets);

        for (const asset of result) {
            const safeMode = safeModeMap.get(asset.id);
            if (safeMode) {
                DpaSafeModeUtils.updateAssetSafeModeInfo(asset, safeMode[0]);
            } else {
                asset.arraySafeModeStatus = defaultVal;
            }
        }

        return result;
    }

    private static updateArraySafeModeInfo(array: UnifiedArray, safeMode: DpaSafeMode): void {
        if (isFlashArray(array)) {
            array.safeModeStatus = valueMap[safeMode.blockSafeModeStatus] || defaultVal;
            array.safeMode.global.status = array.safeModeStatus;
            array.safeMode.global.eradicationTimer = DpaSafeModeUtils.getUpdatedEradicationTimer(
                safeMode.blockEradicationTimerInDays,
            );

            if (array.safeModeStatus === 'Granular Enabled') {
                array.numberOfVolumes = safeMode.volumes;
                array.numberOfProtectedVolumes = safeMode.volumesProtected;
            }
        } else {
            if (safeMode.fileSafeModeStatus === safeMode.objectSafeModeStatus) {
                array.safeModeStatus = valueMap[safeMode.fileSafeModeStatus];
            } else if (safeMode.fileSafeModeStatus === 'ENABLED' || safeMode.objectSafeModeStatus === 'ENABLED') {
                array.safeModeStatus = 'Partial';
            } else {
                array.safeModeStatus = defaultVal;
            }

            array.safeMode.fileSystems.status = valueMap[safeMode.fileSafeModeStatus] || defaultVal;
            array.safeMode.fileSystems.eradicationTimer = DpaSafeModeUtils.getUpdatedEradicationTimer(
                safeMode.fileEradicationTimerInDays,
            );

            array.safeMode.objectStore.status = valueMap[safeMode.objectSafeModeStatus] || defaultVal;
            array.safeMode.objectStore.eradicationTimer = DpaSafeModeUtils.getUpdatedEradicationTimer(
                safeMode.objectEradicationTimerInDays,
            );
        }
    }

    private static updateAssetSafeModeInfo(asset: SubscriptionAsset, safeMode: DpaSafeMode): void {
        if (safeMode.product === Product.FA) {
            asset.arraySafeModeStatus = valueMap[safeMode.blockSafeModeStatus] || defaultVal;
        } else {
            if (safeMode.fileSafeModeStatus === safeMode.objectSafeModeStatus) {
                asset.arraySafeModeStatus = valueMap[safeMode.fileSafeModeStatus] || defaultVal;
            } else if (safeMode.fileSafeModeStatus === 'ENABLED' || safeMode.objectSafeModeStatus === 'ENABLED') {
                asset.arraySafeModeStatus = 'Partial';
            } else {
                asset.arraySafeModeStatus = defaultVal;
            }
        }
    }

    private static getUpdatedEradicationTimer(eradicationTimerinDays: number): moment.Duration {
        if (eradicationTimerinDays > -1) {
            return moment.duration(eradicationTimerinDays, 'days');
        }

        return null;
    }
}
