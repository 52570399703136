<div class="btn btn-secondary btn-group-select btn-no-hover">
    <span class="time-range-select-prefix">{{ prefix }}</span>
    <select
        class="custom-select time-range-select input-sm"
        [ngModel]="timeRange"
        (ngModelChange)="selectTimeRange($event)"
    >
        <option *ngFor="let choice of allChoices;" [ngValue]="choice">{{ choice | timeRange }}</option>
    </select>
</div>

<button
    type="button"
    class="btn btn-secondary with-action-icon time-window-backward"
    blurOnClick
    *ngIf="showMoveWindowButtons"
    [disabled]="isLeftShiftDisabled"
    (click)="moveWindowBackward()"
>
    <span class="pstg-action-icon">
        <pureui-svg svg="chevron-left.svg" [height]="10"></pureui-svg>
    </span>
</button>

<button
    type="button"
    class="btn btn-secondary with-action-icon time-window-forward"
    blurOnClick
    *ngIf="showMoveWindowButtons"
    [disabled]="isRightShiftDisabled"
    (click)="moveWindowForward()"
>
    <span class="pstg-action-icon">
        <pureui-svg svg="chevron-right.svg" [height]="10"></pureui-svg>
    </span>
</button>
