import { Subject, takeUntil } from 'rxjs';
import { Injectable, Inject, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { smartTimer } from '@pstg/smart-timer';

import { WINDOW } from '../app/injection-tokens';

/*
    This service is used to update the "url()" values in the CSS to point to the current url.
    The CSS classes and SVG filter definitions are defined in page.jsp.

THE PROBLEM:
    We use url() quite a bit with SVGs, since that is how you reference a SVG filter. Eg:
        fill: url(#my-gradient-filter)

    The url() needs to point to the current page. Normally, the above would work since the url is
    (by default) based off the current url. However, we also use the html <base> tag:
        <base href="/">

    As a result, the url "#my-gradient-filter" now points to "/#my-gradient-filter", which is only going to
    work if the user is actually at the root path with no url parameters. Basically, it won't work.

    Being a SPA, and persisting state to url, we also doing a lot of url rewriting. So we can't just capture
    the url on page load, set it as a prefix, and call it good.

THE SOLUTION / HOW IT WORKS:
    To solve this, we first define the filters we want to use in a hidden SVG element (see page.jsp) so we can
    reference them effectively as globals (since they end up on every page).

    To apply the filter, you must define a CSS class in the head of page.jsp. It must be under the style block
    with id #cssUrlStyleManager-styles. For example:

        <style id="cssUrlStyleManager-styles" type="text/css">
            rect.use-my-gradient-filter { fill: url(_BASE_#my-gradiant-filter); }
        </style>

    The "_BASE_" prefix is where the current url gets replaced. It must be at the start of the url(), like shown
    above. This placeholder & formatting is done this way to make it valid CSS, but point to an invalid path if
    the url replacement goes wrong (since some browsers, like Chrome, will still work if it is just url(#base-gradiant-filter)).

    Whenever the url changes, this style block is modified to use the new url.

    See also:
        https://jira.purestorage.com/browse/CLOUD-21251

POTENTIAL ISSUES WITH THIS:
    1. There is no guarantee that, when you change a CSS class definition, the browser will re-render the SVG. It should,
        and all testing shows that it does, but I have seen some weird issues with deferred SVG rendering
        (presumably for performance reasons).
    2. This results in a SVG's "complete" definition now no longer being under a single <svg> block. While this does
        help us avoid having duplicate filter definitions (like we used to on the FB health cards), it can
        cause complications if we ever want to support exporting of the SVG images.
    3. Our "reference" SVG that contains all the filter definitions needs to be hidden completely from view. We currently
        use absolute positioning & set width/height to 0 to "hide" it. This seems to work for now, but it was tricky
        finding a solution that worked in every browser. Firefox didn't work when using <symbol>, and at least one
        browser (FF again?) didn't support "display: none". There is no guarantee this is future-proof.
*/

const WATCHER_FREQ_MS = 1000;

@Injectable({ providedIn: 'root' })
export class CssUrlStyleManager implements OnDestroy {
    private htmlTemplate: string;
    private lastUrl: string;

    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private ngZone: NgZone,
    ) {}

    run(): void {
        // Get the style definitions
        this.htmlTemplate = this.window.document.querySelector('head #cssUrlStyleManager-styles')?.innerHTML;
        if (!this.htmlTemplate || this.htmlTemplate.length === 0) {
            console.error('CssUrlStyleManager: no style definition found.');
            return; // If we can't find it, there is nothing for us to do...
        }

        // Perform initial update
        this.checkIfUrlChanged();

        this.ngZone.runOutsideAngular(() => {
            // Update whenever the url changes. While this probably should be filtering to only listen
            // for NavigationEnd events, it is safer and easier to just look at every event since
            // the callback is very cheap (and it runs outside Angular). So it's not even worth the
            // extra testing to add the filter.
            this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.checkIfUrlChanged();
            });

            // Create background watcher to check for url changes since it may not always be picked up by events.
            // This will make sure we eventually self-correct.
            smartTimer(WATCHER_FREQ_MS, WATCHER_FREQ_MS)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.checkIfUrlChanged();
                });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private checkIfUrlChanged(): void {
        const newUrl = this.window.location.pathname + (this.window.location.search || '');
        if (this.lastUrl !== newUrl) {
            this.lastUrl = newUrl;
            this.updateCss(newUrl);
        }
    }

    private updateCss(basePath: string): void {
        const html = this.htmlTemplate.replace(/url\(_BASE_([^\)]+)\)/g, (_substr, m1) => {
            const url = basePath + m1;
            return `url('${url}')`;
        });

        this.window.document.querySelector('head #cssUrlStyleManager-styles').innerHTML = html;
    }
}
