import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'auto-on-safe-mode-banner',
    templateUrl: 'auto-on-safe-mode-banner.component.html',
})
export class AutoOnSafeModeBannerComponent extends BaseBanner implements AfterViewInit {
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('autoOnSMLinksTemplate', { static: true }) readonly autoSMLinksTemplate: TemplateRef<any>;
    @ViewChild('autoOnSMButtonTemplate', { static: true }) readonly autoOnSMButtonTemplate: TemplateRef<any>;

    ngAfterViewInit(): void {
        const templates = { linksTemplate: this.autoSMLinksTemplate, buttonTemplate: this.autoOnSMButtonTemplate };
        this.populatedNotification = Object.assign({}, this.notification, templates);
    }
}
