import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HiveThemedDirective } from '@pure/hive';
import { STEP_UP_ERROR_RESPONSES } from '@pure1/data';

@Component({
    selector: 'enrollment-via-identity-center-required-modal',
    templateUrl: 'enrollment-via-identity-center-required-modal.component.html',
    hostDirectives: [HiveThemedDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollmentViaIdentityCenterRequiredModalComponent {
    protected readonly STEP_UP_SUPPORT_LINK = `https://support.purestorage.com/Pure1/Pure1_Manage/002_Getting_Support/Identity_Management_with_Step-Up_Authentication`;

    constructor(public readonly activeModal: NgbActiveModal) {}

    protected close(): void {
        this.activeModal.dismiss(STEP_UP_ERROR_RESPONSES['enrollment_required']);
    }
}
