import moment from 'moment';
import { Product } from './unified-array';

export type ArraySafeModeStatus = 'Enabled' | 'Disabled' | 'Partial' | 'Upgrade Required' | '-' | 'Granular Enabled';

export enum SafeModeStatusEnum {
    ENABLED = 'Enabled',
    DISABLED = 'Disabled',
    UPGRADE_REQUIRED = 'Upgrade Required',
    GRANULAR_ENABLED = 'Granular Enabled',
    PARTIAL = 'Partial',
    NOT_AVAILABLE = '-',
}

export interface ISafeModeConfig {
    status: ArraySafeModeStatus;
    connected: boolean;
    eradicationTimer: moment.Duration | null;
}

interface IArraySafeModeContstructorJson {
    safe_mode_global: { status: string; eradication_timer: number };
    safe_mode_object_store: { status: string; eradication_timer: number };
    safe_mode_file_systems: { status: string; eradication_timer: number };
}

export function toSafeModeStatus(status: string): ArraySafeModeStatus {
    switch (status) {
        case 'enabled':
            return 'Enabled';
        case 'disabled':
            return 'Disabled';
        case 'partial':
            return 'Partial';
        case 'upgrade_required':
            return 'Upgrade Required';
        case 'disconnected-disabled':
            return 'Disabled';
        case 'disconnected-upgrade':
            return 'Upgrade Required';
        case 'granular_enabled':
            return 'Granular Enabled';
        default:
            return '-';
    }
}

export class ArraySafeMode {
    global?: ISafeModeConfig;
    fileSystems?: ISafeModeConfig;
    objectStore?: ISafeModeConfig;

    constructor(product: Product, json: IArraySafeModeContstructorJson) {
        if (product === Product.FA) {
            if (json.safe_mode_global) {
                this.global = {
                    status: toSafeModeStatus(json.safe_mode_global.status),
                    connected: !json.safe_mode_global.status.startsWith('disconnected'),
                    eradicationTimer: json.safe_mode_global.eradication_timer
                        ? moment.duration(json.safe_mode_global.eradication_timer)
                        : null,
                };
            } else {
                this.global = { status: '-', connected: false, eradicationTimer: null };
            }
        } else if (product === Product.FB) {
            if (json.safe_mode_file_systems) {
                this.fileSystems = {
                    status: toSafeModeStatus(json.safe_mode_file_systems.status),
                    connected: !json.safe_mode_file_systems.status.startsWith('disconnected'),
                    eradicationTimer: json.safe_mode_file_systems.eradication_timer
                        ? moment.duration(json.safe_mode_file_systems.eradication_timer)
                        : null,
                };
            } else {
                this.fileSystems = { status: '-', connected: false, eradicationTimer: null };
            }
            if (json.safe_mode_object_store) {
                this.objectStore = {
                    status: toSafeModeStatus(json.safe_mode_object_store.status),
                    connected: !json.safe_mode_object_store.status.startsWith('disconnected'),
                    eradicationTimer: json.safe_mode_object_store.eradication_timer
                        ? moment.duration(json.safe_mode_object_store.eradication_timer)
                        : null,
                };
            } else {
                this.objectStore = { status: '-', connected: false, eradicationTimer: null };
            }
        } else {
            this.global = { status: '-', connected: false, eradicationTimer: null };
        }
    }
}
