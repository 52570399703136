import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface IAggregationOption {
    text: string;
    method: IAggregationMethod;
}

@Component({
    selector: 'aggregation-option',
    templateUrl: 'aggregation-option.html',
})
export class AggregationOptionComponent {
    @Input() readonly aggregationOptions: IAggregationOption[];
    @Input() readonly aggregationOption: IAggregationOption;
    @Input() readonly prefixText: string = '';
    @Input() readonly titleText: string = '';
    @Output() readonly aggregationOptionChange = new EventEmitter<IAggregationOption>();

    clickAggregationMethod(aggregation: IAggregationOption): void {
        this.aggregationOptionChange.emit(aggregation);
    }
}
