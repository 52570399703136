import moment from 'moment';
import { AlertOrigin } from '@pure1/data';

/**
 * Ideally, we'd just use Alert.getKBLink(). However, we can't guarantee right now that all alerts are constructed
 * through the Alert object.
 * Eventually, remove this exported function.
 */
export function getAlertKBLink(code: number): string {
    const value = String(code).padStart(4, '0');
    return `https://support.purestorage.com/?cid=Alert_${value}`;
}

export class Alert implements IAlert {
    static SEVERITY = {
        CRITICAL: 'critical',
        WARNING: 'warning',
        INFO: 'info',
    };

    currentSeverity: AlertSeverityName;
    id: string;
    uuid: string;
    code: number;
    expected: string;
    actual: string;
    hostname: string;
    closed: moment.Moment;
    closedString: string;
    isClosed: boolean;
    arrayId: string;
    status: AlertStatusName;
    summary: string;
    updated: moment.Moment;
    updatedString: string;
    itemType: string;
    orgId: number;
    origin: AlertOrigin;
    source: string;
    category: string;

    // for compatibility with AlertResourceV2 in MessagedModalComponent
    opened: number | moment.Moment;
    component: string;
    component_id: string;
    sub_component: string;
    sub_component_id: string;

    constructor(json: any) {
        this.id = String(json.id);
        this.uuid = String(json.uuid);
        this.expected = json.expected ? String(json.expected) : null;
        this.actual = json.actual ? String(json.actual) : null;
        this.closed = typeof json.closed === 'number' ? (json.closed === 0 ? null : moment(json.closed * 1000)) : null;
        this.isClosed = String(json.state) === 'closed';
        this.closedString = this.closed ? this.closed.format('YYYY-MM-DD HH:mm:ss') : '-';
        this.status = this.isClosed ? 'closed' : 'open';
        this.summary = String(json.summary);
        this.updated = moment(json.updated * 1000);
        this.updatedString = this.updated.format('YYYY-MM-DD HH:mm:ss');
        this.itemType = 'alert';
        this.origin = json.origin as AlertOrigin;
        this.source = json.source;
        this.category = json.category;
        this.code = Number(json.code);
        this.currentSeverity = <AlertSeverityName>String(json.current_severity);
        this.hostname = json.hostname ? json.hostname : null;

        this.opened = json.opened;
        this.component = json.component;
        this.component_id = json.component_id;
        this.sub_component = json.sub_component;
        this.sub_component_id = json.sub_component_id;

        // Use parseInt because sometimes org_id is a number, but sometimes it's a string
        this.orgId = parseInt(json.org_id, 10);
    }

    getKBLink(): string {
        return getAlertKBLink(this.code);
    }
}
