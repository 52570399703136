import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ArrayEolComponentInfo, UnifiedArray } from '@pure1/data';
import { OpportunityService, Pure1LeadSource } from '../../../services/opportunity.service';
import { ToastService, ToastType } from '../../../services/toast.service';
import { EolHardwareService, EolOpportunityStatus } from '../../../services/eol-hardware.service';

@Component({
    selector: 'eol-hw-popover-card',
    templateUrl: 'eol-hw-popover-card.component.html',
    providers: [OpportunityService],
})
export class EolHwPopoverCardComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly array: UnifiedArray;
    @Input() readonly showArrayLink: boolean = true;
    @Input() readonly leadSource: Pure1LeadSource;

    labels: string[] = [];
    loading = true;
    opportunityStatus: EolOpportunityStatus = 'unknown';
    incidentOpened = false;
    hwEolInfo: ArrayEolComponentInfo;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private eolHardwareService: EolHardwareService,
        private toast: ToastService,
    ) {}

    ngOnInit(): void {
        this.eolHardwareService.opportunitiesUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.opportunityStatus = this.eolHardwareService.isEolOpportunityOpened(this.array.cloud_array_id);
        });

        this.eolHardwareService.incidentsUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.incidentOpened = this.eolHardwareService.isEolIncidentOpened(this.array.cloud_array_id);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array) {
            this.eolHardwareService.getEolComponentInfo(this.array.cloud_array_id).subscribe({
                next: (info: ArrayEolComponentInfo) => {
                    this.hwEolInfo = info;
                    if (this.hwEolInfo) {
                        this.labels = this.eolHardwareService.getComponentLabels(this.hwEolInfo.components);
                    }
                    this.loading = false;
                },
                error: error => {
                    console.warn('failed to get hardware eol info' + error);
                    this.loading = false;
                },
            });
        }
    }

    warningCount(): number {
        return this.array.end_of_life_hardware?.components.length || 0;
    }

    clickContactAccountTeam(): void {
        this.loading = true;
        this.eolHardwareService
            .createEolOpportunity(
                this.array.cloud_array_id,
                this.array.name,
                this.leadSource,
                this.hwEolInfo.recommendationAction.capacityConfig,
            )
            .subscribe({
                next: () => {
                    this.opportunityStatus = 'opened';
                    this.loading = false;
                },
                error: err => {
                    this.toast.add(ToastType.error, 'Failed to submit request, please try again.');
                    console.log(err);
                    this.loading = false;
                },
            });
    }

    hasPermissionToViewRecommendation(): boolean {
        return this.eolHardwareService.hasPermissionToViewRecommendation();
    }

    clickViewRecommendation(): void {
        this.eolHardwareService.viewRecommendation(this.array.cloud_array_id);
    }
}
