import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HiveSortParams } from '@pure/hive';

export const TABLE_DEFAULT_PAGE_SIZE = 10;
export const TABLE_DEFAULT_PAGESIZE_OPTIONS = [10, 25, 50, 100];

export function getStoredTablePagesize(tableId: string): number {
    return Number(localStorage.getItem('table-' + tableId + '-pagesize')) || TABLE_DEFAULT_PAGE_SIZE;
}

export function saveTablePagesize(tableId: string, pageSize: number): void {
    localStorage.setItem('table-' + tableId + '-pagesize', pageSize.toString());
}

export function loadTableAttributesFromUrl(
    params: ParamMap,
    columnIds: string[],
): { sortingParams: HiveSortParams; offset: number; filtering: { [key: string]: string } } {
    const returnValue = { sortingParams: null, offset: 0, filtering: {} };
    for (const param of params.keys) {
        switch (param) {
            case 'sort':
                const value = String(params.get(param));
                if (value) {
                    let sortingParams: HiveSortParams;
                    if (value.endsWith('-')) {
                        sortingParams = {
                            key: value.slice(0, -1),
                            order: 'desc',
                        };
                    } else {
                        sortingParams = {
                            key: value,
                            order: 'asc',
                        };
                    }
                    returnValue.sortingParams = sortingParams;
                }
                break;
            case 'offset':
                returnValue.offset = Number(params.get(param));
                break;
            default:
                if (columnIds.indexOf(param) !== -1) {
                    returnValue.filtering[param] = String(params.get(param));
                }
                break;
        }
    }
    return returnValue;
}

export function saveTableAttributesToUrl(
    router: Router,
    route: ActivatedRoute,
    keyObject: { [key: string]: string },
): void {
    router.navigate([], {
        relativeTo: route,
        queryParams: keyObject,
        queryParamsHandling: 'merge',
    });
}
