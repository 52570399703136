import { Pipe, PipeTransform } from '@angular/core';
import { RoleType } from '@pure1/data';

export const ROLE_TYPE_MAP = new Map<RoleType, string>([
    [RoleType.DASHBOARD_VIEWER, 'Dashboard Viewer'],
    [RoleType.VM_VIEWER, 'VM Viewer'],
    [RoleType.PURE1_VIEWER, 'Pure1 Viewer'],
    [RoleType.PURE1_ADMIN, 'Pure1 Admin'],
    [RoleType.PURE1_SUPPORT, 'Pure1 Support'],
    [RoleType.PORTWORX_ADMIN, 'Portworx Admin'],
]);

@Pipe({
    name: 'roleType',
})
export class RoleTypePipe implements PipeTransform {
    transform(roleId: RoleType): string {
        return ROLE_TYPE_MAP.get(roleId as RoleType) || 'None';
    }
}
