<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<ng-template #sdfcUpdateLinksTemplate>
    <a
        href="https://support.purestorage.com/Pure1/Pure1_Manage/012_Working_with_Single_Sign-On_(SSO)/Enhanced_Pure_Storage_SFDC_Login_Experience"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - SDFC login update - KB article"
        class="bold-link"
    >
        Learn More
    </a>
    or contact
    <a
        href="https://support.purestorage.com/?cid=ContactUs"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - SDFC login update - Pure Technical Services"
        class="bold-link"
    >
        Pure Technical Services
    </a>
    for assistance.
</ng-template>

<ng-template #sdfcUpdateButtonTemplate>
    <!-- NO BTN IS NEEDED -->
</ng-template>
