import { ValidatorFn, AbstractControl, UntypedFormGroup, AsyncValidatorFn } from '@angular/forms';

import { emailRegex } from './strings';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * Checks if the entered email string is valid
 * We accept a comma-separated list of emails, so each email must be properly formatted.
 * Emails ending in .local and .p3 are also not allowed
 */
export const emailFormatValidator: ValidatorFn = (control: AbstractControl) => {
    const inputString: string = control.value;
    if (!inputString || inputString.length === 0) {
        // Empty string and null values are valid.
        // It's up to the user to use Validators.required if that's not the case.
        return null;
    }

    const error = inputString
        .split(',')
        .map(email => email.trim())
        .some(email => !emailRegex.test(email) || email.endsWith('.local') || email.endsWith('.p3'));
    return error ? { email: { value: control.value } } : null;
};

/**
 * Checks against the existing input list as well as the passed in parameter for duplicate entries.
 */
export const getDuplicateEntryValidator: Function = (existingEntries?: string[]): ValidatorFn => {
    return (control: AbstractControl) => {
        let error = false;
        const entryCount = new Map<string, number>();
        (control.value as string)
            .split(',')
            .map(email => email.trim())
            .concat(existingEntries || [])
            .forEach(email => {
                const existingCount = entryCount.get(email) || 0;
                if (existingCount === 1) {
                    error = true;
                }
                entryCount.set(email, existingCount + 1);
            });
        return error ? { duplicate: { value: control.value } } : null;
    };
};

/**
 * Checks that one or more of the checkboxes within a FormGroup have been checked.
 */
export function requireFormGroupCheckboxesValidator(): ValidatorFn {
    return (group: UntypedFormGroup) => {
        const anyChecked = Object.keys(group.controls).some(key => {
            const control = group.controls[key];
            return control.value === true;
        });

        return !anyChecked ? { requireFormGroupCheckboxes: true } : null;
    };
}

/**
 * Checks that the form control does not equal any of the given values. Uses strict equality comparison (===).
 */
export function notEqualToValidator(...values: any[]): ValidatorFn {
    return (control: AbstractControl) => {
        if (values.some(v => control.value === v)) {
            return { notEqualTo: { value: control.value } };
        } else {
            return null;
        }
    };
}

/**
 * Generates a `ValidatorFn` that checks if the control's value excludes an item
 * that satisfies the provided predicate.
 *
 * @param predicate function to identify unwanted values
 * @returns `ValidatorFn`
 */
export function exclude<T>(predicate: (value: T) => boolean, errorKey = 'invalidValue'): ValidatorFn {
    return (control: AbstractControl<T[]>) => {
        for (const item of control.value) {
            if (predicate(item)) {
                return { [errorKey]: item };
            }
        }

        return null;
    };
}

/** Conditionally applies validators */
export function when(predicate: (control: AbstractControl) => boolean) {
    return {
        applyValidator:
            (validator: ValidatorFn): ValidatorFn =>
            (control: AbstractControl) =>
                predicate(control) ? validator(control) : null,
        applyAsycValidator:
            (validator: AsyncValidatorFn): AsyncValidatorFn =>
            (control: AbstractControl) =>
                predicate(control) ? validator(control) : of(null),
    };
}

export function authorized(obs$: Observable<boolean>): AsyncValidatorFn {
    return () =>
        obs$.pipe(
            map((isAuthorized: boolean) => (isAuthorized ? null : { unauthorized: true })),
            take(1),
        );
}
