import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';

import { CaseEscalation, ItemType } from '../support.interface';
import { first, map } from 'rxjs/operators';

@Injectable()
export class CaseEscalationService {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 29000; // 29 seconds
    private endpoint = '/rest/v1/support/cases';

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: CaseEscalationService.cacheTTL,
        cacheBusterObserver: CaseEscalationService.cacheBuster$,
    })
    getCaseEscalations(caseId: string): Observable<CaseEscalation[]> {
        return this.http.get<CaseEscalation[]>(`${this.endpoint}/${caseId}/escalations`).pipe(
            first(),
            map((escalations: CaseEscalation[]) => escalations.map(e => this.itemTypeHelper(e))),
        );
    }

    @CacheBuster({
        cacheBusterNotifier: CaseEscalationService.cacheBuster$,
    })
    addCaseEscalation(caseId: string, escalation: Partial<CaseEscalation>): Observable<CaseEscalation> {
        return this.http.post<CaseEscalation>(`${this.endpoint}/${caseId}/escalations`, escalation).pipe(
            map(
                (newEscalation: Partial<CaseEscalation>) =>
                    ({
                        ...escalation,
                        ...newEscalation,
                    }) as CaseEscalation,
            ),
        );
    }

    private itemTypeHelper = (data: CaseEscalation): CaseEscalation => {
        return {
            ...data,
            itemType: ItemType.Escalation,
        };
    };
}
