<div
    class="card-back card"
    [ngClass]="{
        'array-disconnected': !array.isCurrent || array.active_status === ResourceStatus.SUSPENDED,
        'eol-hw': hasEolComponents()
    }"
>
    <ng-container *ngIf="!removeInternalsFromDom">
        <!-- Note: we set 'showWarningCardForOutOfSupportAppliance' to 'false' here as user should never reach the back of the card (i.e. this template) if it is 'true'.
            As we do not want them stuck at the back of the card if they somehow reach here, we set the value to 'false' to let them flip the card to front -->
        <array-card-header
            class="card-header"
            [array]="$any(array)"
            [alerts]="alerts"
            [showWarningCardForOutOfSupportAppliance]="false"
            (onFlipCard)="flipCard($event.event)"
        ></array-card-header>

        <div class="card-array-info" *ngIf="!array.isCurrent || array.active_status === ResourceStatus.SUSPENDED">
            <ng-container *ngIf="array.active_status === ResourceStatus.SUSPENDED; else disconnectedCloud">
                <pureui-svg svg="suspended.svg" height="11"></pureui-svg>
                Suspended
            </ng-container>
            <ng-template #disconnectedCloud>
                <pureui-reporting-icon></pureui-reporting-icon>
                Last updated {{ $any(array).lastUpdated }}
            </ng-template>
        </div>

        <div class="card-alert-summary">
            {{ alertCount() }} {{ alertCount() === 1 ? 'open alert' : 'open alerts' }}
        </div>

        <div class="array-alerts inverted-scrollbar">
            <eol-hw-popover-card [array]="array" [leadSource]="'Pure1 Eol Appliance Card'" *ngIf="hasEolComponents()" />

            <div
                class="array-alert"
                *ngFor="let alert of sortedAlerts; trackBy: 'uuid' | trackByProperty"
                (click)="clickAlert(alert)"
            >
                <pure-alert-indicator
                    class="alert-indicator card-alert-indicator card-flip-b"
                    [iconHeight]="14"
                    [alerts]="alert"
                >
                </pure-alert-indicator>

                <div class="alert-event">{{ alert.summary }}</div>
                <div class="alert-opened">{{ alert.updatedString }}</div>
            </div>
        </div>
    </ng-container>
</div>
