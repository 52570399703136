/**
 * Helps batch api calls together that are normally made separately.
 * Works by debouncing api calls to create a window for the batch to be built up, then
 * lets all the grouped requests be handled at once.
 */
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

interface IBatch<TIn, TOut> {
    values: TIn[];
    subject: Subject<TOut>;
}

export class ApiCallBatcher<TIn, TOut> {
    private readonly batches = new Map<string, IBatch<TIn, TOut>>();

    constructor(
        /**
         * How long to delay the execution of a request so additional requests can join the batch.
         * The greater the delay, the greater the chance that requests will get batched together, but also
         * the longer every request takes. If you expect most batching to occur in the same digest cycle,
         * you can keep this value quite low (eg <50ms).
         */
        private batchWaitTimeMs: number,

        /**
         * Generates a batch group key for a request. Only requests with the same keys end up batched together.
         */
        private getGroupingKey: (request: TIn) => string,

        /**
         * Executes a batch of requests.
         */
        private batchExecutor: (requests: TIn[]) => Observable<TOut>,
        private window: Window,
    ) {}

    /**
     * Enqueues a batchable request.
     */
    enqueue(request: TIn): Observable<TOut> {
        const key = this.getGroupingKey(request);

        // Get batch
        let batch = this.batches.get(key);
        const timeoutCreated = batch != null;

        // Create if it does not yet exist
        if (!batch) {
            batch = {
                values: [],
                subject: new Subject<TOut>(),
            };
            this.batches.set(key, batch);
        }

        // Enqueue item
        batch.values.push(request);

        if (!timeoutCreated) {
            this.window.setTimeout(() => this.executeBatch(key), this.batchWaitTimeMs);
        }

        return batch.subject;
    }

    /**
     * Executes a batch of requests.
     */
    private executeBatch(key: string): void {
        // Get the batch to execute
        const batch = this.batches.get(key);

        // Remove the batch from the lookup so new requests can come in while we execute
        this.batches.delete(key);

        // Execute
        this.batchExecutor(batch.values)
            .pipe(take(1))
            .subscribe(
                result => {
                    batch.subject.next(result);
                },
                err => {
                    console.error('Failed to execute api batch', batch, err);
                    batch.subject.error(err);
                },
                () => {
                    batch.subject.complete();
                },
            );
    }
}
