import { Directive, ElementRef, NgZone, Input, Inject } from '@angular/core';
import { WINDOW } from '../../app/injection-tokens';

/**
 * Directive that automatically adds/removes fade-outs at the top/bottom
 * of the scrolling container.
 *
 * Fade-outs are added only when container is scrolled in that direction.
 *
 * For CSS positioning purposes, the directive has to be applied on wrapping
 * element. Not on the scrolling container itself.
 *
 * @input {HTMLElement} pureFadeOutScroll - the scrolling container element
 * @input {string} backgroundColor - (optional) the color of surrounding background in CSS notation
 * @input {string} fadeHeight - (optional) the height of the fade out effects in CSS notation
 */

@Directive({
    selector: '[pureFadeOutScroll]',
})
export class FadeOutScrollDirective {
    @Input() readonly pureFadeOutScroll: HTMLElement;
    @Input() readonly backgroundColor: string = '#fff';
    @Input() readonly fadeHeight: string = '10px';

    private changeInProgress = false;
    private resizeObserver = new ResizeObserver(this.setFadeouts.bind(this));

    constructor(
        private el: ElementRef,
        private ngZone: NgZone,
        @Inject(WINDOW) private window: Window,
    ) {}

    ngAfterViewInit(): void {
        this.el.nativeElement.style.setProperty('--fade-out-scroll-background-color', this.backgroundColor);
        this.el.nativeElement.style.setProperty('--fade-out-scroll-fade-height', this.fadeHeight);

        this.setFadeouts();
        this.ngZone.runOutsideAngular(() => {
            this.pureFadeOutScroll.addEventListener('scroll', this.setFadeouts.bind(this));
            this.resizeObserver.observe(this.pureFadeOutScroll);
        });
    }

    private async setFadeouts(): Promise<undefined> {
        if (this.changeInProgress) {
            return;
        }

        // state changes spaced at 20ms
        this.changeInProgress = true;
        await new Promise(resolve => void this.window.setTimeout(resolve, 20));
        this.changeInProgress = false;

        const scrollContainer = this.pureFadeOutScroll;

        // top fade
        if (scrollContainer.scrollTop > 0) {
            this.el.nativeElement.classList.add('fade-out-scroll-top-fade');
        } else {
            this.el.nativeElement.classList.remove('fade-out-scroll-top-fade');
        }

        // bottom fade
        if (scrollContainer.scrollTop + scrollContainer.clientHeight < scrollContainer.scrollHeight) {
            this.el.nativeElement.classList.add('fade-out-scroll-bottom-fade');
        } else {
            this.el.nativeElement.classList.remove('fade-out-scroll-bottom-fade');
        }
    }
}
