import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '../ui/ui.module';
import { CopyLicenseKeyComponent } from './copy-license-key/copy-license-key.component';
import { SubscriptionPageTableComponent } from './subscription-page-table/subscription-page-table.component';
import { SubscriptionColumnSelectorComponent } from './subscription/subscription-column-selector/subscription-column-selector.component';
import { TableModule } from '@pure/hive';

@NgModule({
    imports: [CommonModule, NgbModule, UIModule, TableModule],
    declarations: [CopyLicenseKeyComponent, SubscriptionColumnSelectorComponent, SubscriptionPageTableComponent],
    exports: [CopyLicenseKeyComponent, SubscriptionColumnSelectorComponent, SubscriptionPageTableComponent],
})
export class SubscriptionSharedModule {}
