import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceReportType } from '../../export/services/export-request-builder.service';

export const INVOICE_REPORT_TYPE_MAP = new Map<InvoiceReportType, string>([
    [InvoiceReportType.INVOICE_SUMMARY_XLSX, 'Invoice Summary (.xlsx)'],
    [InvoiceReportType.INVOICE_SUMMARY_PDF, 'Invoice Summary (.pdf)'],
    [InvoiceReportType.INVOICE_DETAIL_PDF, 'Invoice Detail (.pdf)'],
    [InvoiceReportType.INVOICE_SUMMARY_AND_DETAIL_PDF, 'Summary & Detail (.pdf)'],
]);

@Pipe({
    name: 'invoiceReportType',
})
export class InvoiceReportTypePipe implements PipeTransform {
    transform(type: InvoiceReportType): string {
        return INVOICE_REPORT_TYPE_MAP.get(type) || '';
    }
}
