import _ from 'lodash';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, catchError, share, multicast, publishLast, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRestResponse } from '@pure1/data';

import { NpsSurvey } from '../models/NpsSurvey';

export interface INpsSurveyFeedback {
    survey_id: string;
    score: number;
    feedback_text: string;
    page_target: string;
}

@Injectable({ providedIn: 'root' })
export class NpsSurveyService implements OnDestroy {
    private readonly surveyAnnouncedSource$ = new Subject<NpsSurvey>();
    private readonly surveyRefreshSource$ = new Subject<void>();

    readonly currentSurvey$ = this.surveyAnnouncedSource$.asObservable();
    readonly refreshSurvey$ = this.surveyRefreshSource$.asObservable();

    private readonly surveyCache$ = new Map<string, ReplaySubject<string[]>>();
    private readonly destroy$ = new Subject<void>();

    constructor(private http: HttpClient) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    announceSurvey(survey: NpsSurvey): void {
        // send survey to the feedback form component that will then pop up
        this.surveyAnnouncedSource$.next(survey);
    }

    refreshSurvey(): void {
        // notify components that they need to refresh the survey associated with them
        this.surveyRefreshSource$.next();
    }

    getSurveys(pageTarget: string, npsRequired = false): Observable<NpsSurvey[]> {
        const requestParams = {
            page_target: pageTarget,
            nps_required: String(npsRequired),
        };

        let req$ = this.surveyCache$.get(pageTarget);

        if (!req$) {
            // No existing request for this page, so create one and store it in the cache
            req$ = new ReplaySubject<string[]>(1);
            this.surveyCache$.set(pageTarget, req$);

            this.http
                .get<IRestResponse<string>>('/rest/v1/nps-surveys', { params: requestParams })
                .pipe(
                    map(response => response.items),
                    catchError(err => {
                        console.error(`Failed to fetch nps survey for page "${pageTarget}"`, err);
                        return of(<string[]>[]);
                    }),
                    takeUntil(this.destroy$),
                )
                .subscribe(req$);
        }

        return req$.pipe(map(itemJsons => itemJsons.map(json => new NpsSurvey(json)))); // Construct the objects every call, that way there is no risk of mutated state being shared around
    }

    submitFeedback(feedback: INpsSurveyFeedback): Observable<any> {
        this.surveyCache$.clear(); // Keep it safe and easy and just clear the whole cache (rather than just the cache for the specific page)
        return this.http.post<any>('/rest/v1/nps-surveys/feedbacks', feedback);
    }
}
