import { DraasApiClusterHealth, DraasApiProviderHealth } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryClusterHealth {
    clusterId: string;
    status:
        | 'NOT_STARTED'
        | 'PENDING_DEPLOYMENT'
        | 'DEPLOYED'
        | 'PENDING_SEED_NODE_CONNECTION'
        | 'PENDING_TARGET_NODE_CONNECTION'
        | 'PENDING_VPN'
        | 'READY'
        | 'HEALTHY'
        | 'UNHEALTHY'
        | 'UNKNOWN';
    providerHealths: DisasterRecoveryProviderHealth[];

    constructor(json: DraasApiClusterHealth) {
        this.clusterId = json.cluster_id;
        this.status = json.status;
        this.providerHealths = json.provider_healths.map(
            providerHealth => new DisasterRecoveryProviderHealth(providerHealth),
        );
    }
}

export class DisasterRecoveryProviderHealth {
    providerId: string;
    status: 'READY' | 'HEALTHY' | 'UNHEALTHY' | 'PENDING_DEPLOYMENT' | 'CREDENTIALS_INVALID' | 'UNKNOWN';

    constructor(providerHealth: DraasApiProviderHealth) {
        this.providerId = providerHealth.provider_id;
        this.status = providerHealth.status;
    }
}
