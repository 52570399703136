import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DataPage } from '../interfaces/data-page';
import { AlertsCount } from '../models/alerts-count';

@Injectable({ providedIn: 'root' })
export class AlertsCountService {
    constructor(protected http: HttpClient) {}

    getOpenAlerts(): Observable<DataPage<AlertsCount>> {
        return this.http.get<any>('/rest/v4/alerts-count').pipe(
            map(res => {
                return {
                    total: res.total_item_count,
                    response: (res.items || []).map(item => new AlertsCount(item)),
                };
            }),
        );
    }
}
