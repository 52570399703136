import moment from 'moment-timezone';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Schedule } from '../../safemode-scheduler-common/types';

@Component({
    selector: 'safemode-enable-scheduled-confirmation',
    templateUrl: 'safemode-enable-scheduled-confirmation.component.html',
})
export class SafeModeEnableScheduledConfirmationComponent {
    @Input() readonly caseId: string;
    @Input() readonly schedule: Schedule;
    @Input() readonly createCaseDoneFlag: boolean;
    @Input() readonly createCaseErrorFlag: boolean;
    @Input() readonly email: string;
    @Output() readonly onDismiss: EventEmitter<any> = new EventEmitter();

    printPrettyTime(time: moment.Moment): string {
        if (time) {
            return time.format('ddd, MMM D, HH:mm z');
        }
        return '';
    }
}
