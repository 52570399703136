import moment from 'moment';

export class ImmutableTimeRange implements ITimeRange {
    private _startTime: moment.Moment;
    private _endTime: moment.Moment;

    constructor(startTime: number | moment.Moment, endTime: number | moment.Moment) {
        this._startTime = moment(startTime);
        this._endTime = moment(endTime);
    }

    getStartTime(): moment.Moment {
        return this._startTime.clone();
    }

    getEndTime(): moment.Moment {
        return this._endTime.clone();
    }

    getDuration(): moment.Duration {
        return moment.duration(this._endTime.valueOf() - this._startTime.valueOf());
    }

    /**
     * Returns whether `this` is a subset of the ITimeRange `tr`, i.e. `this.startTime >= tr.startTime && this.endTime <= tr.endTime`
     * @param tr {ITimeRange}
     * @returns {boolean}
     */
    isSubsetOf(tr: ITimeRange): boolean {
        return this._startTime.isSameOrAfter(tr.getStartTime()) && this._endTime.isSameOrBefore(tr.getEndTime());
    }

    clone(): ITimeRange {
        return new ImmutableTimeRange(this._startTime.valueOf(), this._endTime.valueOf());
    }

    equals(tr: ITimeRange): boolean {
        return this._startTime.isSame(tr.getStartTime()) && this._endTime.isSame(tr.getEndTime());
    }
}
