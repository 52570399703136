import moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { UnifiedArray, CachedCurrentUserService } from '@pure1/data';
import { timezoneList } from '../../../utils/supportedTimezones';
import { Schedule } from '../types';

/**
 * Holds functionality that is common to both upgrade planning and safemode planning, at some point these two should
 * be consolidated
 */
@Directive()
export abstract class AbstractUpgradePlannerComponent implements OnInit {
    @Input() readonly arrays: UnifiedArray[];
    @Output() readonly onBack: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onForward: EventEmitter<Schedule> = new EventEmitter<Schedule>();

    // the timezone of the user according to SFDC
    userTimezone: string;

    // list of available timezones (populates dropdown)
    tzList = timezoneList;

    // the date currently mousehovered in the calendar widget
    hoveredDate: NgbDate | null = null;

    // the earliest date the user is allowed to select in the calendar
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;

    upgradeForm: UntypedFormGroup;
    currentUserLoading = true;

    protected constructor(
        protected fb: UntypedFormBuilder,
        protected cachedCurrentUserService: CachedCurrentUserService,
        protected calendar: NgbCalendar,
    ) {}

    /**
     * Defines minDate, initializes upgradeForm with a timezone control and sets up an initial value for it.
     */
    ngOnInit(): void {
        // pre-select a timezone
        const local = moment.tz.guess();
        const found = this.tzList.find(zone => zone === local);
        const tzNow: string = found ? found : 'GMT';
        this.upgradeForm = this.fb.group({
            timezone: [tzNow],
        });

        // get user timezone, and use if only if the user hasn't selected one yet an its a valid timezone
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.currentUserLoading = false;
                if (
                    !this.upgradeForm.controls.timezone.dirty &&
                    currentUser.timezone &&
                    this.tzList.some(zone => zone === currentUser.timezone)
                ) {
                    this.userTimezone = currentUser.timezone;
                    this.upgradeForm.controls.timezone.setValue(currentUser.timezone);
                }
            });

        this.minDate = this.addBusinessDays(this.calendar.getToday(), 0);
        // maximum date is 6 months form now
        this.maxDate = this.calendar.getNext(this.calendar.getToday(), 'm', 6);
    }

    getTimezonePrettyName(timezone: string): string {
        if (!timezone) {
            return '';
        }
        const raw = timezone.split('/');
        const city = raw[raw.length - 1].replace('_', ' ');
        return city + ' (UTC' + moment.tz(timezone).format('Z') + ')';
    }

    printPrettyDate(date: NgbDate): string {
        const dateWithTz = this.ngbDateToMoment(date);
        return dateWithTz.format('MMMM D, YYYY');
    }

    ngbDateToMoment(date: NgbDateStruct): moment.Moment {
        return moment.tz(
            `${date.year}-${date.month}-${date.day}`,
            'YYYY-M-D',
            true,
            this.upgradeForm.controls.timezone.value,
        );
    }

    addBusinessDays(originalNgbDate: NgbDate, numDaysToAdd: number): NgbDate {
        const saturday = 6;
        const sunday = 7;
        let daysRemaining = numDaysToAdd + 1; // +1 becasue we are not counting original date in

        let newDate = NgbDate.from(originalNgbDate);

        while (daysRemaining > 0) {
            newDate = this.calendar.getNext(newDate, 'd', 1);
            if (this.calendar.getWeekday(newDate) !== saturday && this.calendar.getWeekday(newDate) !== sunday) {
                daysRemaining--;
            }
        }

        return newDate;
    }
}
