// Same enum as java/com/purestorage/controller/model/ErrorCode.java
export enum ErrorCode {
    SF_DOWN = 0,
    AUTH0_DOWN = 1,
    ALTERNATIVE_LOGIN_LINK_EXPIRED = 2,
    INTERNAL_ERROR = 3,
    NO_ALTERNATE_LOGIN_FOR_USER = 4,
    AUTH_PORTAL_DOWN = 5,
    IDP_NOT_AVAILABLE = 6,
    IDP_INTERNAL_ERROR = 7,
    UNAUTHORIZED = 8,
    SWITCH_TO_INVALID_USER = 9,
    NEED_VPN = 10,
    MARKETPLACE_INVALID_CALLBACK = 11,
    FORBIDDEN = 12,
    CANNOT_IMPERSONATE_USER = 13,
}
