import { Resource } from '../interfaces/resource';

// This class is used by file-system-replica-link
export class Policy implements Resource {
    id: string;
    name: string;
    resourceType: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.resourceType = json.resourceType || null;
    }
}
