import moment from 'moment';
import {
    Component,
    Input,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    NgZone,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { UnifiedArray } from '@pure1/data';

import { getChartsUnderElement, clearSelectedPoints, clearHoverState } from '../../../utils/highcharts';
import { formatLatency } from '../../../utils/formatLatency';
import { formatIOPs } from '../../../utils/formatIOPs';
import { formatNetworkSpeed } from '../../../utils/formatNetworkSpeed';
import { formatSize } from '../../../utils/formatSize';
import { FBlade, FArray, HistoricalPerformanceData } from '../../../model/model';
import { Theme } from '../../../ui/styles/theme';
import { ArraysManager } from '../../../services/arrays-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { smartTimer } from '@pstg/smart-timer';

const REFRESH_INTERVAL = moment.duration(1, 'minutes');
const MAX_DURATION = moment.duration(6, 'hours');

export type MetricConfigSeriesRawKey =
    | 'readLatency'
    | 'writeLatency'
    | 'mirroredWriteLatency'
    | 'otherLatency' // Latency
    | 'readIops'
    | 'writeIops'
    | 'mirroredWriteIops'
    | 'otherIops'
    | 'averageIoSize' // IOPs
    | 'readBandwidth'
    | 'writeBandwidth'
    | 'mirroredWriteBandwidth'; // Bandwidth

export interface IMetricConfig {
    label: string;
    minRange: number;
    minTickInterval: number;
    binaryTickPositioner?: boolean;
    series: {
        label: string;
        shortLabel: string;
        key: string;
        rawKey: MetricConfigSeriesRawKey;
        filter: (value: number, decimal: number, axisLabel?: boolean) => IValueUnit;
        color?: string;
        hidden?: boolean;
    }[];
}

@Component({
    selector: 'array-expanded-card-performance',
    templateUrl: 'array-expanded-card-performance.component.html',
})
export class ArrayExpandedCardPerformanceComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly isExpanded: boolean;
    @Input() readonly array: UnifiedArray;

    metricConfigs: IMetricConfig[];
    performanceData: HistoricalPerformanceData;

    private fetchFromPerformanceData: boolean;
    private readonly stopTimer$ = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        private arraysManager: ArraysManager,
    ) {}

    ngOnInit(): void {
        if (this.array instanceof FBlade) {
            this.metricConfigs = [
                this.createConfigLatency('readLatency', 'writeLatency', 'otherLatency'),
                this.createConfigIOPs('readIops', 'writeIops', 'otherIops', 'averageIoSize'),
                this.createConfigBandwidth('readBandwidth', 'writeBandwidth'),
            ];
            this.fetchFromPerformanceData = true;
        } else if (this.array instanceof FArray) {
            const mw = this.array.hasMirroredWrites;
            this.metricConfigs = [
                this.createConfigLatency('readLatency', 'writeLatency', mw ? 'mirroredWriteLatency' : null),
                this.createConfigIOPs('readIops', 'writeIops', mw ? 'mirroredWriteIops' : null, 'averageIoSize'),
                this.createConfigBandwidth('readBandwidth', 'writeBandwidth', mw ? 'mirroredWriteBandwidth' : null),
            ];
            this.fetchFromPerformanceData = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isExpanded) {
            this.stopTimer$.next();

            // If we change to expanded, fetch the new data, and start up the automatic refresh
            if (this.isExpanded) {
                this.ngZone.runOutsideAngular(() => {
                    smartTimer(0, REFRESH_INTERVAL.asMilliseconds())
                        .pipe(takeUntil(this.stopTimer$))
                        .subscribe(() => this.fetchData());
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.stopTimer$.next();
    }

    onChartContainerMouseLeave($event: MouseEvent): void {
        // Deselect the points on each chart under this container
        getChartsUnderElement($event.srcElement as any).forEach(chart => {
            clearSelectedPoints(chart);
            clearHoverState(chart);
        });
    }

    /**
     * Creates the metric config. Each appliance uses the same config per metric, but differ in the series included.
     * @param includeSeries Which series to include, and in what order
     */
    private createConfigLatency(...includeSeries: MetricConfigSeriesRawKey[]): IMetricConfig {
        // Build the config with all the series
        const config: IMetricConfig = {
            label: 'Latency',
            minRange: 200,
            minTickInterval: 50,
            series: [
                {
                    label: 'Read Latency',
                    shortLabel: 'R',
                    key: 'readLatencyStr',
                    rawKey: 'readLatency',
                    color: Theme.performance.read,
                    filter: formatLatency,
                },
                {
                    label: 'Write Latency',
                    shortLabel: 'W',
                    key: 'writeLatencyStr',
                    rawKey: 'writeLatency',
                    color: Theme.performance.write,
                    filter: formatLatency,
                },
                {
                    label: 'Mirrored Write Latency',
                    shortLabel: 'MW',
                    key: 'mirroredWriteLatencyStr',
                    rawKey: 'mirroredWriteLatency',
                    color: Theme.performance.mirrored_write,
                    filter: formatLatency,
                },
                {
                    label: 'Other Latency',
                    shortLabel: 'O',
                    key: 'otherLatencyStr',
                    rawKey: 'otherLatency',
                    color: Theme.performance.other,
                    filter: formatLatency,
                },
            ],
        };
        return this.filterSeries(config, includeSeries);
    }

    private createConfigIOPs(...includeSeries: MetricConfigSeriesRawKey[]): IMetricConfig {
        const config: IMetricConfig = {
            label: 'IOPS',
            minRange: 1000,
            minTickInterval: 250,
            series: [
                {
                    label: 'Read IOPS',
                    shortLabel: 'R',
                    key: 'readIopsStr',
                    rawKey: 'readIops',
                    color: Theme.performance.read,
                    filter: formatIOPs,
                },
                {
                    label: 'Write IOPS',
                    shortLabel: 'W',
                    key: 'writeIopsStr',
                    rawKey: 'writeIops',
                    color: Theme.performance.write,
                    filter: formatIOPs,
                },
                {
                    label: 'Mirrored Write IOPS',
                    shortLabel: 'MW',
                    key: 'mirroredWriteIopsStr',
                    rawKey: 'mirroredWriteIops',
                    color: Theme.performance.mirrored_write,
                    filter: formatIOPs,
                },
                {
                    label: 'Other IOPS',
                    shortLabel: 'O',
                    key: 'otherIopsStr',
                    rawKey: 'otherIops',
                    color: Theme.performance.other,
                    filter: formatIOPs,
                },
                {
                    label: 'Average IO Size',
                    shortLabel: 'Average IO Size',
                    key: 'averageIOSizeStr',
                    rawKey: 'averageIoSize',
                    hidden: true,
                    filter: formatSize,
                },
            ],
        };
        return this.filterSeries(config, includeSeries);
    }

    private createConfigBandwidth(...includeSeries: MetricConfigSeriesRawKey[]): IMetricConfig {
        const config: IMetricConfig = {
            label: 'Bandwidth',
            minRange: 1024,
            minTickInterval: 512,
            binaryTickPositioner: true,
            series: [
                {
                    label: 'Read Bandwidth',
                    shortLabel: 'R',
                    key: 'readBandwidthStr',
                    rawKey: 'readBandwidth',
                    color: Theme.performance.read,
                    filter: formatNetworkSpeed,
                },
                {
                    label: 'Write Bandwidth',
                    shortLabel: 'W',
                    key: 'writeBandwidthStr',
                    rawKey: 'writeBandwidth',
                    color: Theme.performance.write,
                    filter: formatNetworkSpeed,
                },
                {
                    label: 'Mirrored Write Bandwidth',
                    shortLabel: 'MW',
                    key: 'mirroredWriteBandwidthStr',
                    rawKey: 'mirroredWriteBandwidth',
                    color: Theme.performance.mirrored_write,
                    filter: formatNetworkSpeed,
                },
            ],
        };
        return this.filterSeries(config, includeSeries);
    }

    /**
     * Filters the series array to include only the ones specified, and in the order specified
     */
    private filterSeries(config: IMetricConfig, includeSeries: MetricConfigSeriesRawKey[]): IMetricConfig {
        const allSeries = config.series.slice();
        config.series = includeSeries
            .filter(rawKey => rawKey)
            .map(rawKey => allSeries.find(serie => serie.rawKey === rawKey));
        return config;
    }

    /**
     * Fetches and updates the performance data
     * NOTE: Run outside of angular zone
     */
    private fetchData(): void {
        if (!this.array || !this.metricConfigs) {
            this.performanceData = null;
            return;
        }

        let getDataPromise: Promise<HistoricalPerformanceData>;

        if (this.fetchFromPerformanceData) {
            // Old approach - gets from performance_history from /arrays endpoint
            const timestamp = this.getArrayIncrementalPerformanceTimestamp(this.performanceData);
            getDataPromise = this.arraysManager.getPerformanceData(this.array.id, timestamp).then(data => {
                // Typically the server returns 24hr of points but we show only MAX_DURATION, so filter out the older points
                this.metricConfigs.forEach(mc => {
                    mc.series.forEach(serie => {
                        const seriePoints = data && data[serie.rawKey];
                        if (seriePoints && seriePoints.length > 0) {
                            const lastPointTime = moment(seriePoints[seriePoints.length - 1].x);
                            const minTime = lastPointTime.clone().subtract(MAX_DURATION).valueOf();
                            data[serie.rawKey] = seriePoints.filter(p => moment(p.x).isAfter(minTime));
                        }
                    });
                });

                return data;
            });
        } else {
            // Modern approach - gets from /metrics endpoint
            const metrics: PerformanceMetricsApiRequestMetricNames[] = [];
            this.metricConfigs.forEach(mc => {
                metrics.push(...mc.series.map(serie => this.toApiRequestMetricName(serie.rawKey)));
            });

            const endTime = moment().startOf('minute');
            const startTime = endTime.clone().subtract(MAX_DURATION);

            getDataPromise = this.arraysManager
                .getPerformanceMetrics(this.array.id, metrics, startTime, endTime, 'avg', 200) // 2 minute resolution
                .then(data => {
                    return new HistoricalPerformanceData({
                        output_per_sec: data.values.read_bandwidth,
                        input_per_sec: data.values.write_bandwidth,
                    });
                });
        }

        // Apply new data
        getDataPromise.then(data => {
            this.performanceData = data;
            this.cdr.markForCheck();
        });
    }

    private getArrayIncrementalPerformanceTimestamp(seriesData: HistoricalPerformanceData): number {
        if (!seriesData || Object.keys(seriesData).length === 0) {
            return null;
        }

        // Get the max x-axis value of all the points
        return Object.keys(seriesData)
            .map(key => <HistoricalPerformancePoint[]>seriesData[key])
            .filter(points => points && points.length > 0)
            .map(points => points[points.length - 1].x / 1000 + 1) // TODO: REST talks seconds, fix when converting to millis. Adding 1 second because REST returns points == to supplied value.
            .reduce((a, b) => Math.max(a, b), 0);
    }

    private toApiRequestMetricName(rawKey: MetricConfigSeriesRawKey): PerformanceMetricsApiRequestMetricNames {
        switch (rawKey) {
            case 'readBandwidth':
                return 'read_bandwidth';
            case 'writeBandwidth':
                return 'write_bandwidth';
            default:
                throw new Error('Unsupported rawKey: ' + rawKey);
        }
    }
}
