import { BaseSnapshot } from './base-snapshot';
import { Resource } from '../interfaces/resource';

export class FileSystemSnapshot extends BaseSnapshot {
    policy: Resource;

    constructor(json: any) {
        super(json);
        if (json.policy) {
            this.policy = {
                name: json.policy.name,
                id: json.policy.guid,
            };
        }
    }
}
