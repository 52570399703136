import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DisasterRecoveryOvaInfo } from '../models/disaster-recovery-ova-info';
import { DraasApiOvaInfo, DraasApiOvaUrl } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryOvaUrl } from '../models/disaster-recovery-ova-url';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryOvaService {
    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {}

    private getEndpoint(): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/1.0/ovas`;
    }

    getOvas(): Observable<DisasterRecoveryOvaInfo[]> {
        const url = this.getEndpoint();
        return this.http
            .get<DraasApiOvaInfo[]>(url)
            .pipe(map(response => response.map(info => new DisasterRecoveryOvaInfo(info))));
    }

    getLatestOva(): Observable<DisasterRecoveryOvaInfo> {
        const url = `${this.getEndpoint()}/latest`;
        return this.http.get<DraasApiOvaInfo>(url).pipe(map(response => new DisasterRecoveryOvaInfo(response)));
    }

    createDownloadUrl(id: string): Observable<DisasterRecoveryOvaUrl> {
        const url = `${this.getEndpoint()}/${id}`;
        return this.http.post<DraasApiOvaUrl>(url, null).pipe(map(response => new DisasterRecoveryOvaUrl(response)));
    }
}
