import { Resource } from '../interfaces/resource';

export class FlexHardwareCapacity implements Resource {
    id: string;
    name: string;

    rawTB: number;
    usableTiB: number;
    minimumEUCTiB: number;
    maximumEUCTiB: number;

    constructor(json: any) {
        this.id = json.product_id;
        this.name = json.product_name;
        this.rawTB = json.raw;
        this.usableTiB = json.usable;
        this.minimumEUCTiB = json.minimum;
        this.maximumEUCTiB = json.maximum;
    }
}
