<div class="line-container" [style.width.px]="width">
    <div style="position: relative">
        <!-- Prefetch images -->
        <img rel="prefetch" src="/images/fa-c-min.png" hidden />
        <img rel="prefetch" src="/images/fa-x-min.png" hidden />
        <img rel="prefetch" src="/images/fa-xl-min.png" hidden />
        <img rel="prefetch" src="/images/fa-x-c-r4-min.png" hidden />

        <img
            class="chassis-image"
            [ngClass]="{ 'fa-xl-chassis-image': displayModel?.toLowerCase().startsWith('//xl') }"
            [src]="getBezelModelImage()"
        />
        <div class="model-text">
            <div class="parallelogram">
                <div class="model-name">{{ displayModel }}</div>
            </div>
        </div>
    </div>
</div>
