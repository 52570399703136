import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource } from '@pure/authz-authorizer';
import { CachedCurrentUserService } from './cached-current-user.service';
import {
    Observable,
    ReplaySubject,
    Subject,
    Subscription,
    distinctUntilChanged,
    map,
    shareReplay,
    switchMap,
} from 'rxjs';

const XAAS_URL = '/rest/xaas/v1/authorization/list-authorized-permissions-scoped';

export type XaaSPermission =
    | 'PURE1:read:partner_invoice'
    | 'PURE1:write:partner_invoice'
    | 'PURE1:read:partner_quote'
    | 'PURE1:write:partner_quote'
    | 'PURE1:read:partner_opportunity';

type PermissionsScopedResponse = {
    readonly permissions: AuthorizedPermission[];
};

type AuthorizedPermission = {
    readonly domain: string;
    readonly name: string;
};

class XaaSCacheRecord {
    readonly permissions$: Subject<string[]> = new ReplaySubject<string[]>(1);

    constructor(
        public readonly resource: Resource,
        public readonly domain: string,
        public sourceSubscription: Subscription = Subscription.EMPTY,
    ) {}
}

@Injectable({ providedIn: 'root' })
class XaaSNgService {
    constructor(private readonly http: HttpClient) {}

    listAuthorizedPermissionsScoped(): Observable<string[]> {
        return this.http
            .get<PermissionsScopedResponse>(XAAS_URL)
            .pipe(map(({ permissions }) => permissions.map(({ domain, name }) => this.scopePermission(domain, name))));
    }

    private scopePermission(domain: string, permission: string): string {
        return `${domain}:${permission}`;
    }
}

@Injectable({ providedIn: 'root' })
export class XaaSCachedPermissionService {
    private cache: Map<string, XaaSCacheRecord> = new Map();

    constructor(protected readonly xaasNgService: XaaSNgService) {}

    /**
     * Get permissions for the given domain and resource.
     */
    getPermissions(resource: Resource, domain = 'PURE1'): Observable<string[]> {
        let record = this.cache.get(this.getKey(resource, domain));
        if (!record) {
            record = new XaaSCacheRecord(resource, domain);
            this.subscribeToPermissions(record);
            this.cache.set(this.getKey(resource, domain), record);
        }

        return record.permissions$.asObservable();
    }

    getKeyResource(resource: Resource): string {
        return `${resource.type}:${resource.id}`;
    }

    getKeyDomain(domain: string): string {
        return `(${domain})`;
    }

    getKey(resource: Resource, domain: string): string {
        return `${this.getKeyResource(resource)}${this.getKeyDomain(domain)}`;
    }

    /**
     * Subscribes the cache record to the permissions observable and requests the list of permissions.
     */
    private subscribeToPermissions(cacheRecord: XaaSCacheRecord) {
        cacheRecord.sourceSubscription = this.xaasNgService
            .listAuthorizedPermissionsScoped()
            .subscribe(cacheRecord.permissions$);

        return cacheRecord;
    }
}

/**
 * Note:
 * This is the P1M XaaS team's alternative to partner related resources that cannot be
 * captured by AuthZ at this time. Hopefully we can combine the two, but this is necessary
 * for the near future.
 *
 * Implementation is a Frankenstein's monster of code in pure-pure1/authz-client-angular
 */
@Injectable({ providedIn: 'root' })
export class XaaSAuthorizationService {
    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private xaaSCachedPermissionService: XaaSCachedPermissionService,
    ) {}

    isAllowed(permission: string): Observable<boolean> {
        const resource$ = this.cachedCurrentUserService.getDefaultResource();

        return resource$.pipe(
            switchMap(resource => this.xaaSCachedPermissionService.getPermissions(resource)),
            map(permissions => permissions.includes(permission)),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }
}
