import moment from 'moment';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, exhaustMap, take, takeUntil } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { BannerService, Banner, DataPage } from '@pure1/data';
import { smartTimer } from '@pstg/smart-timer';

@Injectable({ providedIn: 'root' })
export class BannerCacheService implements OnDestroy {
    private readonly listCache$ = new ReplaySubject<DataPage<Banner>>(1);
    private readonly destroy$ = new Subject<void>();

    constructor(private bannerService: BannerService) {
        smartTimer(0, moment.duration(30, 'seconds').asMilliseconds())
            .pipe(
                exhaustMap(() =>
                    this.bannerService.list().pipe(
                        take(1),
                        catchError(err => {
                            console.warn('Failed to update Banners', err);
                            return of<DataPage<Banner>>(null);
                        }),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(result => {
                if (result != null) {
                    // Don't bubble up errors
                    this.listCache$.next(result);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    list(): Observable<DataPage<Banner>> {
        return this.listCache$;
    }
}
