export interface AuthzPermissionsScopedResponse {
    readonly permissions: AuthzAuthorizedPermission[];
}

export interface AuthzPermissionsScopedRequest {
    domain: string;
    resourceIdentifier: {
        type: string;
        value: number;
    };
}

export interface AuthzAuthorizedPermission {
    readonly domain: string;
    readonly name: string;
}

export const AuthzDomain: Record<string, string> = {
    DRAAS: 'DRAAS',
} as const;

export const DraasAuthzPermission = {
    POLICY_CREATE: AuthzDomain.DRAAS + ':policy:create',
    POLICY_DELETE: AuthzDomain.DRAAS + ':policy:delete',
    POLICY_READ: AuthzDomain.DRAAS + ':policy:read',
    POLICY_UPDATE: AuthzDomain.DRAAS + ':policy:update',
    PROTECTION_GROUP_CREATE: AuthzDomain.DRAAS + ':protection_group:create',
    PROTECTION_GROUP_DELETE: AuthzDomain.DRAAS + ':protection_group:delete',
    PROTECTION_GROUP_DISABLE: AuthzDomain.DRAAS + ':protection_group:disable',
    PROTECTION_GROUP_EXECUTE: AuthzDomain.DRAAS + ':protection_group:execute',
    PROTECTION_GROUP_READ: AuthzDomain.DRAAS + ':protection_group:read',
    PROTECTION_GROUP_UPDATE: AuthzDomain.DRAAS + ':protection_group:update',
    PROVIDER_CREATE: AuthzDomain.DRAAS + ':provider:create',
    PROVIDER_READ: AuthzDomain.DRAAS + ':provider:read',
    PROVIDER_UPDATE: AuthzDomain.DRAAS + ':provider:update',
    RECOVERY_PLAN_CREATE: AuthzDomain.DRAAS + ':recovery_plan:create',
    RECOVERY_PLAN_DELETE: AuthzDomain.DRAAS + ':recovery_plan:delete',
    RECOVERY_PLAN_EXECUTE: AuthzDomain.DRAAS + ':recovery_plan:execute',
    RECOVERY_PLAN_READ: AuthzDomain.DRAAS + ':recovery_plan:read',
    RECOVERY_PLAN_UPDATE: AuthzDomain.DRAAS + ':recovery_plan:update',
    REPORT_READ: AuthzDomain.DRAAS + ':report:read',
    SUBSCRIPTION_CREATE: AuthzDomain.DRAAS + ':subscription:create',
    SUBSCRIPTION_READ: AuthzDomain.DRAAS + ':subscription:read',
    SUBSCRIPTION_UPDATE: AuthzDomain.DRAAS + ':subscription:update',
    UIPAGE_OVERVIEW_READ: AuthzDomain.DRAAS + ':uipage_overview:read',
    UIPAGE_SETUP_SYSTEM_READ: AuthzDomain.DRAAS + ':uipage_setup_system:read',
    UIPAGE_SETUP_POLICIES_READ: AuthzDomain.DRAAS + ':uipage_setup_policies:read',
    UIPAGE_SETUP_GROUPS_READ: AuthzDomain.DRAAS + ':uipage_setup_groups:read',
    UIPAGE_SETUP_PLANS_READ: AuthzDomain.DRAAS + ':uipage_setup_plans:read',
    UIPAGE_SETUP_WIZARD_READ: AuthzDomain.DRAAS + ':uipage_setup_wizard:read',
    UIPAGE_MONITOR_PROTECTION_READ: AuthzDomain.DRAAS + ':uipage_monitor_protection:read',
    UIPAGE_MONITOR_RECOVERY_READ: AuthzDomain.DRAAS + ':uipage_monitor_recovery:read',
    UIPAGE_MONITOR_REPORTS_READ: AuthzDomain.DRAAS + ':uipage_monitor_reports:read',
} as const;
