<form [formGroup]="upgradeForm">
    <div *ngIf="timeSlotConflict" class="alert alert-warning"
        >The time slot you selected is no longer available. Please select a different time slot.</div
    >
    <div class="row" *ngIf="arrays && arrays.length > 0">
        <div class="col-xs-1" *ngIf="oneScheduleForMultipleAppliances"></div>
        <div class="col-xs-3" *ngIf="!oneScheduleForMultipleAppliances">
            <div class="row selected-appliances">
                <div class="col-xs-12">
                    <p>Selected appliances ({{ arrays.length }})</p>
                    <ul class="selected-appliances-list">
                        <li
                            *ngFor="let array of arrays; index as i; trackBy: trackByArrayId"
                            [class.selected-array]="array === selectedArray"
                            (click)="setSelectedArray(array)"
                        >
                            <div class="array-name">
                                <span class="dot" [ngClass]="'dot-' + (i + 1)"></span>
                                <span>{{ array.name }}</span>
                                <pureui-svg
                                    *ngIf="selectedTimeSlots[array.id]"
                                    class="pstg-action-icon tick-icon"
                                    svg="tick.svg"
                                    height="12"
                                ></pureui-svg>
                            </div>
                            <span class="version">{{ array.version }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ng-template #loader>
            <div class="col-xs-9 loader-column">
                <div class="row loader">
                    <pureui-spinner [pureuiShow]="true"></pureui-spinner>
                </div>
            </div>
        </ng-template>
        <div *ngIf="!timeslotsLoading && !currentUserLoading && firstTimeLoaded; else loader" class="col-xs-9 px-2">
            <ng-container>
                <div class="row" *ngIf="!oneScheduleForMultipleAppliances">
                    <p class="col-xs-12"
                        >Select the desired date and time for
                        {{ isEnablement ? 'enabling SafeMode for' : 'SafeMode changes for' }}
                        <span class="dot" [ngClass]="'dot-' + (getSelectedArrayIndex() + 1)"></span>
                        <strong>{{ selectedArray.name }}</strong>
                    </p>
                </div>
                <div class="row">
                    <div class="col-xs-5 calendar-wrapper">
                        <select
                            class="btn btn-default-focus btn-sm"
                            formControlName="timezone"
                            angulartics2On="change"
                            analyticsCategory="Action"
                            angularticsAction="Assessment - Planner - SafeMode - Timezone changed"
                        >
                            <option [value]="timezone" *ngFor="let timezone of tzList; trackBy: trackByIndex">
                                {{ getTimezonePrettyName(timezone) }}
                            </option>
                        </select>
                        <ngb-datepicker
                            #dp
                            navigation="arrows"
                            [(ngModel)]="selectedDate"
                            [ngModelOptions]="{ standalone: true }"
                            (dateSelect)="onDateSelection($event)"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [markDisabled]="makeMarkDayDisabled"
                            [dayTemplate]="t"
                        ></ngb-datepicker>
                        <ng-template
                            #t
                            let-date
                            let-currentMonth="currentMonth"
                            let-disabled="disabled"
                            let-focused="focused"
                        >
                            <div
                                class="custom-day"
                                [class.disabled]="disabled"
                                [class.focused]="focused"
                                [class.outside]="date.month !== currentMonth"
                                [class.selected]="date.equals(selectedDate)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null"
                            >
                                <div class="dot-wrapper" *ngIf="!oneScheduleForMultipleAppliances">
                                    <div class="bar" *ngIf="numberOfArraysInDate(date) > 3; else dotsTpl"></div>
                                    <ng-template #dotsTpl>
                                        <ng-container *ngFor="let array of arrays; index as i">
                                            <span
                                                *ngIf="isArrayInDay(array, date)"
                                                class="dot"
                                                [ngClass]="'dot-' + (i + 1)"
                                            ></span>
                                        </ng-container>
                                    </ng-template>
                                </div>
                                {{ date.day }}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-xs-7" *ngIf="timeslots && selectedDate">
                        <timeslot-picker
                            [(day)]="selectedDate"
                            [control]="$any(upgradeForm.controls.timeslot)"
                            [timezone]="upgradeForm.controls.timezone.value"
                            [scrollToTimeSlot]="scrollToTimeSlot"
                            [sortedTimeSlots]="timeslots"
                            [selectedTimeSlots]="selectedTimeSlots"
                            [arrays]="arrays"
                            [selectedArray]="selectedArray"
                            [oneScheduleForMultipleAppliances]="oneScheduleForMultipleAppliances"
                            (dayChange)="dp.navigateTo($event)"
                        >
                        </timeslot-picker>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>

<div class="btn-toolbar clearfix">
    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        (click)="continue()"
        [disabled]="!canContinue()"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode - Planner - Continue schedule requeset"
    >
        Continue &gt;
    </button>

    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        (click)="onBack.emit()"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode - Planner - Go back during schedule request"
    >
        &lt; Back
    </button>
    <button
        type="button"
        class="btn btn-info float-xs-right margin"
        (click)="onCancel.emit()"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode - Planner - Cancel schedule request"
    >
        Cancel
    </button>
</div>
