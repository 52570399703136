import moment from 'moment';

import { Historical } from '../interfaces/history';

export class MetricAnnotation implements Historical<MetricAnnotation> {
    /* Resource properties */
    readonly name: string;
    readonly id: string;

    /* Historical properties */
    readonly _as_of: moment.Moment | null;
    readonly _state_started: moment.Moment | null;
    readonly _state_ended: moment.Moment | null;

    /* MetricAnnotation properties */
    readonly type: MetricAnnotationType;
    readonly metrics: string[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this._state_started = json.start_time !== null ? moment(json.start_time) : null;
        this._state_ended = json.end_time !== null ? moment(json.end_time) : null;
        this._as_of = json._as_of !== null ? moment(json._as_of) : null;
        this.type = json.type;
        this.metrics = json.metrics !== null ? json.metrics : [];
    }
}
