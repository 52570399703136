import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
    BaseBatchMetricsHistoryService,
    getUniqueKeysFromInputs,
    IGetBatchMetricsHistoryParameters,
    IGetBatchMetricsHistoryResult,
} from './base-batch-metrics-history.service';
import {
    MetricsEndpoint,
    ApiCallBatcherInputs,
    IMetricsHistoryOptions,
    IGetMetricsHistoryResult,
} from './metrics-history.service';
import { WINDOW } from '../../app/injection-tokens';

@Injectable({ providedIn: 'root' })
export class BatchFilesystemMetricsHistoryService extends BaseBatchMetricsHistoryService {
    protected apiEndpoint = MetricsEndpoint.filesystemsV3;

    constructor(http: HttpClient, @Inject(WINDOW) window: Window) {
        super(http, window);
    }

    getMetricsHistoryTimeseries(
        arrayId: string,
        filesystemId: string,
        rootDirectoryId: string,
        options: IMetricsHistoryOptions,
    ): Observable<IGetMetricsHistoryResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            arrayId: arrayId,
            fileSystemId: filesystemId,
            directoryId: rootDirectoryId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.enqueueBatch(filesystemId, apiBatcherInput);
    }

    protected apiBatcherGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [request.arrayId, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    protected apiBatcherExecute(requests: ApiCallBatcherInputs[]): Observable<IGetBatchMetricsHistoryResult> {
        const { startTime, endTime, arrayId, maxPoints, aggregationMethod } = requests[0];
        const uniqueMetricList = getUniqueKeysFromInputs<ApiCallBatcherInputs, 'metricName'>(requests, 'metricName');
        const uniqueIdList = getUniqueKeysFromInputs<ApiCallBatcherInputs, 'fileSystemId'>(requests, 'fileSystemId');

        // for requests with directory ids, we need to make sure they are passed in with the matching file system guid
        const directoryToIdMapping: Map<string, string> = new Map<string, string>();
        const isFAFilesystem = !!requests[0].directoryId && requests[0].directoryId !== '';
        if (isFAFilesystem) {
            requests.forEach(request => directoryToIdMapping.set(request.fileSystemId, request.directoryId));
        }
        const addIdsToParameters = (idList: string[], params: IGetBatchMetricsHistoryParameters) => {
            params.file_system_guids = idList.join(',');
            params.root_directory_ids = isFAFilesystem
                ? idList.map(id => directoryToIdMapping.get(id)).join(',')
                : null;
        };

        const createCacheKeyFromId = (id: string) =>
            this.createCacheKey(uniqueMetricList, id, startTime, endTime, aggregationMethod);

        const params: IGetBatchMetricsHistoryParameters = {
            array_id: arrayId,
            metrics: uniqueMetricList.join(','),
            starttime: String(startTime.valueOf()),
            endtime: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        return this.fetchTimeSeriesData(
            uniqueIdList,
            uniqueMetricList,
            params,
            addIdsToParameters,
            createCacheKeyFromId,
        );
    }
}
