export class HourlyAggregation {
    avg: number;
    max: number;

    constructor(json: any) {
        if (json) {
            this.avg = json['1h_avg'];
            this.max = json['1h_max'];
        }
    }
}
