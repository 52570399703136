import moment from 'moment-timezone';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Schedule, ScheduledArray } from '../../safemode-scheduler-common/types';

@Component({
    selector: 'safemode-enable-confirmation',
    templateUrl: 'safemode-enable-confirmation.component.html',
})
export class SafeModeEnableConfirmationComponent implements OnInit {
    @Input() readonly schedule: Schedule;
    @Input() readonly timeslotsChecking: boolean;
    @Input() readonly timeslotsConflictFlag: boolean;
    @Output() readonly onBack: EventEmitter<any> = new EventEmitter();
    @Output() readonly onCancel: EventEmitter<any> = new EventEmitter();
    @Output() readonly onForward: EventEmitter<any> = new EventEmitter();

    confirmForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.confirmForm = this.fb.group({
            comment: '',
            bestPracticesCheck: [false, Validators.requiredTrue],
        });
    }

    continue(): void {
        const { bestPracticesCheck, comment } = this.confirmForm.value;
        if (bestPracticesCheck) {
            this.onForward.emit(comment);
        }
    }

    getTimezonePrettyName(timezone: string): string {
        if (!timezone) {
            return '';
        }
        const raw = timezone.split('/');
        const city = raw[raw.length - 1].replace('_', ' ');
        return city + ' (UTC' + moment.tz(timezone).format('Z') + ')';
    }

    getDateFromMoment(start: moment.Moment): string {
        if (start) {
            return start.format('MMMM D, YYYY');
        } else {
            return '';
        }
    }

    getTimeAndDurationFromMoments(start: moment.Moment, duration: moment.Duration): string {
        let output = '';
        if (start) {
            output += start.format('HH:mm');
        }
        if (duration) {
            const hrs = duration.as('hours');
            const hrsuffix = hrs === 1 ? 'hr' : 'hrs';
            output += ' (' + hrs + ' ' + hrsuffix + ')';
        }
        return output;
    }

    trackByArrayId(index: number, item: ScheduledArray): string {
        return item.array.id;
    }
}
