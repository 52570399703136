import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { VolumeMetricAnnotation } from '../models/volume-metric-annotation';

@Injectable({ providedIn: 'root' })
export class VolumeMetricAnnotationService extends GenericService<VolumeMetricAnnotation> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: VolumeMetricAnnotation,
            endpoint: '/rest/v1/volume-metric-annotations',
        });
    }
}
