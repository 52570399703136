import { Pipe, PipeTransform } from '@angular/core';
import { Member } from '@pure1/data';

const NOT_AVAILABLE = '-';

@Pipe({
    name: 'memberItemDisplay',
})
export class MemberItemDisplayPipe implements PipeTransform {
    transform(member: Member): string {
        if (!member) {
            return NOT_AVAILABLE;
        }
        return member.name || NOT_AVAILABLE;
    }
}
