import { Component, Output, EventEmitter, Input } from '@angular/core';
import { WarningBannerNotification } from '../base-banner';

@Component({
    selector: 'warning-banner',
    templateUrl: 'warning-banner.component.html',
})
export class WarningBannerComponent {
    @Input() readonly warningNotification: WarningBannerNotification;
    @Output() readonly dismissNotification = new EventEmitter<void>();
}
