import { Pipe, PipeTransform } from '@angular/core';
import { ReplicaLink } from '@pure1/data';

@Pipe({
    name: 'alertIndicatorString',
})
export class AlertIndicatorStringPipe implements PipeTransform {
    transform(replicaLink: ReplicaLink): string {
        if (
            !replicaLink.alertSeverity ||
            (replicaLink.alertSeverity.toString().toLowerCase() === 'unknown' && replicaLink.alertId === null)
        ) {
            return 'none';
        } else {
            return replicaLink.alertSeverity.toString().toLowerCase();
        }
    }
}
