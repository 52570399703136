import { ObservableInput, MonoTypeOperatorFunction, from } from 'rxjs';
import { switchMap, mapTo, take } from 'rxjs/operators';

/**
 * Like a take(1) switchMap(), but emits the original input value, instead of the mapped-to value.
 * Alternatively, you can think of it as like a tap() that waits for the resulting observable to emit before continuing.
 *
 * More specifically, this will wait for the specified observable to emit only only after the outer observable has emitted,
 * and will only emit (pass through) a single event for each input event.
 */
export function switchTap<T>(project: (value: T, index: number) => ObservableInput<any>): MonoTypeOperatorFunction<T> {
    return switchMap((value, index) => {
        return from(project(value, index)).pipe(mapTo(value), take(1));
    });
}
