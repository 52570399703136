import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

import { User } from '../support.interface';

/**
 * Currently this service works against SNOW contact database
 */
@Injectable()
export class SupportContactService {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 29000; // 29 seconds
    private endpoint = '/rest/v1/support/contacts';

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: SupportContactService.cacheTTL,
        cacheBusterObserver: SupportContactService.cacheBuster$,
    })
    getContacts(): Observable<User[]> {
        return this.http.get<User[]>(this.endpoint);
    }

    @Cacheable({
        maxAge: SupportContactService.cacheTTL,
        cacheBusterObserver: SupportContactService.cacheBuster$,
    })
    getContactById(id: string): Observable<User> {
        return this.http.get<User>(`${this.endpoint}/id/${id}`);
    }
}
