<div class="um-title">
    <div class="um-title-wrapper">
        <div *ngIf="'PURE1:view:users' | isAllowed; then editable; else inert"></div>
        <ng-template #editable>
            <div
                class="um-title-email-text"
                (click)="clickUserName()"
                angulartics2On="click"
                angularticsAction="UM - Edit current user from title"
                angularticsCategory="Action"
                >{{ currentUser.email }}</div
            >
        </ng-template>
        <ng-template #inert>
            <div class="um-title-email-text">{{ currentUser.email }}</div>
        </ng-template>
        <div class="um-title-profile-icon">
            <pureui-svg svg="glyph-user.svg" [height]="32" class="um-title-icon user-icon"></pureui-svg>
        </div>
    </div>
    <a
        *ngIf="currentUser.oktaConnection"
        class="um-title-switch"
        (click)="switchUser()"
        angulartics2On="click"
        angularticsAction="UM - Switch user"
        angularticsCategory="Action"
    >
        <pureui-svg svg="glyph-switch-user.svg" [height]="32" class="um-title-icon switch-icon"></pureui-svg>
    </a>
</div>
