import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Directory } from '../models/directory';

@Injectable({ providedIn: 'root' })
export class DirectoryServiceV3 extends GenericService<Directory> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Directory,
            endpoint: '/rest/v3/directories',
        });
    }
}
