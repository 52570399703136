<div class="array-alerts-popover">
    <div class="array-header">
        <span *ngIf="array.cbs" class="paws-overlay">
            <span class="paws-background"></span>
            <pureui-svg class="paws-background-icon" [svg]="'cloud.svg'" [height]="13"></pureui-svg>
        </span>

        <span *ngIf="hasEolComponents()" class="eol-hw-indicator pull-right">
            <pure-alert-indicator class="alert-indicator list-alert-indicator" [iconHeight]="24" [array]="array" />
        </span>

        <div class="card-array-title" [class.fqdn]="array.fqdn" [class.eol-hw]="hasEolComponents()">
            <ng-container *ngIf="array.fqdn; then fqdn; else noFqdn" class="pull-left" />
        </div>
        <ng-template #fqdn>
            <a
                *ngIf="showArrayLink; else noLink"
                [arrayHref]="array.cloud_array_id"
                [ngbTooltip]="array.fqdn"
                placement="top"
                container="body"
                angulartics2On="click"
                angularticsCateogry="Action"
                angularticsAction="Arrays - Go to array"
            >
                <div class="array-fqdn">
                    <div class="array-name-multiline array-name" [class.eol-hw]="hasEolComponents()">{{
                        array.name
                    }}</div>
                    <div class="array-name-multiline domain"> {{ array.fqdn | domain }}</div>
                </div>
            </a>
            <ng-template #noLink>
                <div class="array-fqdn">
                    <div class="array-name-multiline array-name" [class.eol-hw]="hasEolComponents()">{{
                        array.name
                    }}</div>
                    <div class="array-name-multiline domain"> {{ array.fqdn | domain }}</div>
                </div>
            </ng-template>
        </ng-template>
        <ng-template #noFqdn>
            <a
                *ngIf="showArrayLink; else noLinkAndNoFqdn"
                [arrayHref]="array.cloud_array_id"
                [ngbTooltip]="array.name"
                placement="top"
                container="body"
                angulartics2On="click"
                angularticsCateogry="Action"
                angularticsAction="Arrays - Go to array"
            >
                <div
                    class="array-name-multiline array-name"
                    [ngClass]="{ 'eol-hw eol-hw-no-domain': hasEolComponents() }"
                >
                    {{ array.name }}
                </div>
            </a>
            <ng-template #noLinkAndNoFqdn>
                <div
                    class="array-name-multiline array-name"
                    [class.eol-hw]="hasEolComponents()"
                    [class.eol-hw-no-domain]="hasEolComponents()"
                >
                    {{ array.name }}
                </div>
            </ng-template>
        </ng-template>
    </div>

    <div class="card-alert-summary"> {{ alertCount() }} {{ alertCount() === 1 ? 'open alert' : 'open alerts' }} </div>

    <div class="array-alerts inverted-scrollbar">
        <eol-hw-popover-card [array]="array" [leadSource]="'Pure1 Eol Appliance List'" *ngIf="hasEolComponents()" />
        <div
            class="array-alert"
            *ngFor="let alert of sortedAlerts; trackBy: 'uuid' | trackByProperty"
            (click)="clickAlert(alert)"
        >
            <pure-alert-indicator
                class="alert-indicator card-alert-indicator card-flip-b"
                [iconHeight]="14"
                [alerts]="alert"
            >
            </pure-alert-indicator>

            <div class="alert-event">{{ alert.summary }}</div>
            <div class="alert-opened">{{ alert.updatedString }}</div>
        </div>
    </div>
</div>
