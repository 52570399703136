import { Resource } from '../interfaces/resource';

export class CustomThreshold {
    severity: string;
    threshold: number;

    constructor(json: any) {
        this.severity = json.severity;
        this.threshold = json.threshold;
    }
}

export class LicenseThreshold implements Resource {
    id: string;
    name: string; // Unused. Set as id

    orgName: string;
    orgId: number;
    customThresholds: CustomThreshold[];

    constructor(json: any) {
        this.id = json.entity_id;
        this.name = json.entity_id;
        this.orgName = json.organization_name;
        this.orgId = json.organization_id;
        this.customThresholds = json.value_custom_thresholds?.map(threshold => new CustomThreshold(threshold));
    }

    toRequestBody(): string {
        return JSON.stringify({
            entity_id: this.id,
            organization_name: this.orgName,
            organization_id: this.orgId,
            value_custom_thresholds: this.customThresholds,
        });
    }
}
