import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment-timezone';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { CachedCurrentUserService, UnifiedArray } from '@pure1/data';

import { TimeSlot } from '../types';
import { AbstractUpgradePlannerComponent } from '../abstract-upgrade-planner/abstract-upgrade-planner.component';
import { SafeModeSchedulerService } from '../../services/safemode-scheduler.service';
import { FreeTime, FreeTimeUpdates, ProductLine } from '../../../support/support.interface';
import { splitUpSlots } from '../../../support/support.utils';

function getDayAsMoment(day: NgbDateStruct, timezone: string): moment.Moment {
    return moment.tz(
        {
            year: day.year,
            month: day.month - 1,
            day: day.day,
        },
        timezone,
    );
}

// Interval between timeslot starttimes
const TIMESLOT_INTERVAL = moment.duration(60, 'minutes');

type MarkDisabledFunction = (date: NgbDateStruct, currentMonth: { year: number; month: number }) => boolean;

@Component({
    selector: 'safemode-enable-planner',
    templateUrl: 'safemode-enable-planner.component.html',
})
export class SafeModeEnablePlannerComponent
    extends AbstractUpgradePlannerComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() readonly arrays: UnifiedArray[];
    @Input() readonly isEnablement: boolean | null;
    @Input() readonly productLine: ProductLine;
    @Input() readonly freeTimeUpdates: FreeTimeUpdates;
    @Input() readonly oneScheduleForMultipleAppliances: boolean = false;
    @Input() readonly timeSlotConflict: boolean = false;

    selectedDate: NgbDateStruct | null = null;
    selectedArray: UnifiedArray;
    selectedTimeSlots: { [id: string]: TimeSlot } = {};
    timeslots: TimeSlot[];
    timeslotsLoading = true;
    firstTimeLoaded = false;
    scrollToTimeSlot: TimeSlot = null;
    /**
     * Passed into NgbDatePicker; returns whether or not the specified date
     * should be disabled.
     */
    markDayDisabledFn$: Observable<MarkDisabledFunction>;
    markDayDisabledFnSubscription: Subscription;

    private startTime: moment.Moment;
    private endTime: moment.Moment;
    private freeTimes$ = new BehaviorSubject<FreeTime[]>([]);

    private readonly destroy$ = new Subject<void>();

    constructor(
        protected fb: UntypedFormBuilder,
        protected cachedCurrentUserService: CachedCurrentUserService,
        protected calendar: NgbCalendar,
        private scheduleService: SafeModeSchedulerService,
    ) {
        super(fb, cachedCurrentUserService, calendar);
    }

    /**
     * Extends existing initialization with timeslots logic
     */
    ngOnInit(): void {
        super.ngOnInit();

        this.selectedTimeSlots = {};
        this.upgradeForm.registerControl('timeslot', this.fb.control(null, Validators.required));

        this.upgradeForm.controls.timeslot.valueChanges.subscribe(this.onTimeSlotSelected.bind(this));

        const tzNow = this.upgradeForm.controls.timezone.value;
        this.startTime = getDayAsMoment(this.calendar.getToday(), tzNow);
        this.endTime = getDayAsMoment(this.calendar.getToday(), tzNow).add(6, 'months');

        this.scheduleService
            .getFreeTimes(this.startTime, this.endTime, this.productLine)
            .pipe(takeUntil(this.destroy$))
            .subscribe(freetimes => {
                this.firstTimeLoaded = true;
                this.timeslotsLoading = false;
                this.freeTimes$.next(freetimes);
            });

        this.markDayDisabledFn$ = combineLatest([
            this.freeTimes$,
            this.upgradeForm.controls.timezone.valueChanges.pipe(startWith(tzNow)),
        ]).pipe(
            map(([freeTimes, timezone]) => [
                splitUpSlots(freeTimes, moment.duration(1, 'hours'), TIMESLOT_INTERVAL),
                timezone,
            ]),
            tap(([timeslots]) => {
                this.timeslots = timeslots;
                this.selectedTimeSlots = {};
                this.upgradeForm.controls.timeslot.setValue(null);
            }),
            map(([freeTimes, timezone]) => this.makeMarkDayDisabledFn(freeTimes, timezone)),
        );

        this.markDayDisabledFn$.pipe(takeUntil(this.destroy$)).subscribe(makeMarkDayDisabled => {
            this.timeslotsLoading = false;
            this.makeMarkDayDisabled = makeMarkDayDisabled;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.arrays) {
            const newArrays = changes.arrays.currentValue;
            if (!this.selectedArray || !newArrays.find(arr => arr === this.selectedArray)) {
                if (newArrays.length > 0) {
                    this.setSelectedArray(newArrays[0]);
                }
            }
        }

        if (changes.productLine && changes.productLine.currentValue) {
            if (this.startTime && this.endTime) {
                this.timeslotsLoading = true;
                this.scheduleService
                    .getFreeTimes(this.startTime, this.endTime, this.productLine)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(freetimes => {
                        this.timeslotsLoading = false;
                        this.freeTimes$.next(freetimes);
                    });
            }
        }

        if (changes.freeTimeUpdates && changes.freeTimeUpdates.currentValue) {
            const newFreeTimes = changes.freeTimeUpdates.currentValue.freeTimes;
            const arraysWithConflicts = changes.freeTimeUpdates.currentValue.conflictingArrays;

            if (this.markDayDisabledFnSubscription && !this.markDayDisabledFnSubscription.closed) {
                this.markDayDisabledFnSubscription.unsubscribe();
            }

            const tzNow = this.upgradeForm.controls.timezone.value;

            this.markDayDisabledFn$ = this.upgradeForm.controls.timezone.valueChanges.pipe(
                startWith(tzNow),
                map(timezone => [splitUpSlots(newFreeTimes, moment.duration(1, 'hours'), TIMESLOT_INTERVAL), timezone]),
                tap(([timeslots]) => {
                    this.timeslots = timeslots;
                    const newSelectedTimeslots: { [id: string]: TimeSlot } = {};
                    Object.keys(this.selectedTimeSlots).forEach(arrayId => {
                        if (!arraysWithConflicts.some(arrWithConflict => arrWithConflict.array.id === arrayId)) {
                            newSelectedTimeslots[arrayId] = this.selectedTimeSlots[arrayId];
                        }
                    });
                    this.selectedTimeSlots = newSelectedTimeslots;
                }),
                map(([freeTimes, timezone]) => this.makeMarkDayDisabledFn(freeTimes, timezone)),
            );

            this.setSelectedArray(arraysWithConflicts[0]); // it is guaranteed to have some items

            this.markDayDisabledFnSubscription = this.markDayDisabledFn$.subscribe(makeMarkDayDisabled => {
                this.timeslotsLoading = false;
                this.makeMarkDayDisabled = makeMarkDayDisabled;
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        if (this.markDayDisabledFnSubscription && !this.markDayDisabledFnSubscription.closed) {
            this.markDayDisabledFnSubscription.unsubscribe();
        }
    }

    onTimeSlotSelected(newValue: TimeSlot): void {
        if (this.oneScheduleForMultipleAppliances) {
            this.selectedTimeSlots = {};
            if (newValue) {
                this.arrays.forEach(array => {
                    this.selectedTimeSlots[array.id] = {
                        startTime: newValue.startTime,
                        duration: moment.duration(1, 'hours'),
                        capacity: 0,
                    };
                });
                this.selectedTimeSlots[this.arrays[0].id].capacity = 1;
            }
        } else {
            if (this.selectedArray) {
                if (!newValue) {
                    this.selectedTimeSlots[this.selectedArray.id] = newValue;
                } else {
                    this.selectedTimeSlots[this.selectedArray.id] = {
                        startTime: newValue.startTime,
                        duration: moment.duration(1, 'hours'),
                        capacity: 1,
                    };
                }
            }
        }
    }

    isArrayInDay(array: UnifiedArray, date: NgbDate): boolean {
        const arrayTimeSlot = this.selectedTimeSlots[array.id];
        return arrayTimeSlot && arrayTimeSlot.startTime.isSame(this.ngbDateToMoment(date), 'day');
    }

    numberOfArraysInDate(date: NgbDate): number {
        return this.arrays.reduce((count, arr) => (this.isArrayInDay(arr, date) ? count + 1 : count), 0);
    }

    isArrayInTimeSlot(array: UnifiedArray, timeslot: TimeSlot): boolean {
        const arrayTimeSlot = this.selectedTimeSlots[array.id];
        return arrayTimeSlot && this.checkTimeSlotsStartEqual(arrayTimeSlot, timeslot);
    }

    printPrettyTime(time: moment.Moment): string {
        // Reason of using moment instead of DatePipe is because
        // DatePipe only accept number offset for the timezone, it cannot auto-adjust for daylight saving time
        return time && time.tz(this.upgradeForm.controls.timezone.value).format('HH:mm');
    }

    setSelectedArray(array: UnifiedArray): void {
        this.selectedArray = array;
        const timeslot = this.selectedTimeSlots[array.id];
        if (timeslot) {
            this.selectedDate = new NgbDate(
                timeslot.startTime.year(),
                timeslot.startTime.month() + 1,
                timeslot.startTime.date(),
            );
            this.scrollToTimeSlot = timeslot;
        } else {
            this.scrollToTimeSlot = null;
        }
        if (this.upgradeForm) {
            this.upgradeForm.controls.timeslot.setValue(this.selectedTimeSlots[array.id], { emitEvent: false });
        }
    }

    onDateSelection(date: NgbDateStruct): void {
        this.selectedDate = date;
    }

    continue(): void {
        this.onForward.emit({
            timezone: this.upgradeForm.controls.timezone.value,
            arrays: this.arrays.map(array => ({
                array,
                timeslot: this.selectedTimeSlots[array.id],
            })),
        });
    }

    // Creates a markDayDisabled function for use in the ngbDatePicker, so that
    // it can react to timezone changes
    makeMarkDayDisabledFn(timeslots: TimeSlot[], timezone: string): MarkDisabledFunction {
        return (currentDay: NgbDateStruct) => {
            const curDay = getDayAsMoment(currentDay, timezone);
            const nextDay = curDay.clone().add(1, 'day');
            return !timeslots.find(cur => cur.startTime.isSameOrAfter(curDay) && cur.startTime.isBefore(nextDay));
        };
    }

    // this function will be overwritten
    makeMarkDayDisabled: MarkDisabledFunction = (_date, _currentMonth) => {
        return true;
    };

    checkTimeSlotsStartEqual(a: TimeSlot, b: TimeSlot): boolean {
        return a.startTime.isSame(b.startTime);
    }

    canContinue(): boolean {
        return (
            this.arrays &&
            !this.arrays.some(array => {
                return !this.selectedTimeSlots[array.id];
            })
        );
    }

    getSelectedArrayIndex(): number {
        if (!this.selectedArray) {
            return 0;
        }
        return this.arrays.findIndex(array => array.id === this.selectedArray.id);
    }

    trackByIndex(index: number): number {
        return index;
    }

    trackByArrayId(index: number, item: UnifiedArray): string {
        return item.id;
    }
}
