import { DraasApiSystemStatus } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoverySystemStatus {
    connectivityStatus: 'ONLINE' | 'WARNING';
    sourceCount: number;
    targetCount: number;
    discoveredVms: number;
    protectedVms: number;
    protectedBytes: number;

    constructor(json: DraasApiSystemStatus) {
        this.connectivityStatus = json.connectivity_status;
        this.sourceCount = json.source_count;
        this.targetCount = json.target_count;
        this.discoveredVms = json.discovered_vms;
        this.protectedVms = json.protected_vms;
        this.protectedBytes = json.protected_bytes;
    }
}
