<div class="table-top-actions">
    <h4> Invoice Details - {{ invoice.id }} </h4>
    <div class="table-right-actions">
        <hive-button
            emphasis="secondary"
            label="Dispute Invoice"
            (click)="openModal()"
            [disabled]="!hasSNOWContact"
            hiveTooltip
            [hiveTooltipDescription]="!hasSNOWContact ? NO_SNOW_CONTACT_MSG : null"
        ></hive-button>
    </div>
</div>
<hive-table [value]="invoice.lines" [scrollable]="true" scrollHeight="300px">
    <ng-template hiveTemplate="header">
        <tr>
            <th hive-header id="item" primaryText="Item"></th>
            <th hive-header id="license" primaryText="Site License Name"></th>
            <th hive-header id="description" primaryText="Description"></th>
            <th hive-header id="quantity" primaryText="Quantity"></th>
            <th hive-header id="unitPrice" primaryText="Unit Price"></th>
            <th hive-header id="amount" primaryText="Amount"></th>
            <th hive-header id="tax" primaryText="Tax Percentage"></th>
        </tr>
    </ng-template>
    <ng-template hiveTemplate="body" let-line>
        <tr>
            <td hive-cell [primaryText]="line.item" [primaryTextTooltip]="line.item"></td>
            <td
                hive-cell
                [primaryText]="line.groupName | defaultString"
                [primaryTextTooltip]="line.groupName | defaultString"
            ></td>
            <td hive-cell [primaryText]="line.description" [primaryTextTooltip]="line.description"></td>
            <td hive-cell [primaryText]="line.quantity" [primaryTextTooltip]="line.quantity"></td>
            <td
                hive-cell
                [primaryText]="line.unitPrice | currency"
                [primaryTextTooltip]="line.unitPrice | currency"
            ></td>
            <td hive-cell [primaryText]="line.amount | currency" [primaryTextTooltip]="line.amount | currency"></td>
            <td
                hive-cell
                [primaryText]="line.tax.percentage / 100 | formatPercent"
                [primaryTextTooltip]="line.tax.percentage / 100 | formatPercent"
            ></td>
        </tr>
    </ng-template>
</hive-table>

<ng-template #disputeInvoiceModal let-modal>
    <dispute-invoice-modal [invoice]="invoice" [modal]="modal"></dispute-invoice-modal>
</ng-template>
