import { Observable } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit, Input, ViewChild } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormControl,
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    ValidationErrors,
} from '@angular/forms';

interface SearchApplianceInput {
    searchFor(value: string): void;
    setFocusWhenEmpty(): void;
    emitChanges(): void;
}

@Component({
    selector: 'abstract-product-section-appliances',
    template: '<span>Provide your own template in a child class</span>',
})
export abstract class AbstractProductSectionAppliancesComponent<T, U extends SearchApplianceInput>
    implements OnInit, ControlValueAccessor
{
    @ViewChild('searchApplianceInput') readonly searchApplianceInput: U;
    @Input() readonly searchArrayName: string;
    @Input() readonly initialProduct: T;
    @Input() readonly isBeingEdited: boolean;
    @Input() readonly checkSupportContract: boolean;

    loading = true;
    form: UntypedFormGroup;
    appliances$: Observable<T[]>;
    appliances: T[] = [];

    constructor(
        protected cdr: ChangeDetectorRef,
        protected fb: UntypedFormBuilder,
    ) {}
    onTouched = (_: any) => {};

    ngOnInit(): void {
        this.form = this.fb.group({
            product: [
                null, // not setting here, it gets set after the arrays are loaded
                [Validators.required, this.validateAppliance.bind(this)],
            ],
        });
        this.appliances$ = this.getAppliances();
        this.appliances$.subscribe(appliances => {
            this.appliances = appliances;
            this.loading = false;
            // without this trigger, there's no searchArrayInput rendered
            this.cdr.detectChanges();
            this.selectVisibleProduct(this.initialProduct);
            // the assumption here is that the product was selected and there's no need to work with the array-select anymore
            if (!this.initialProduct) {
                this.searchOrFocus();
            }
        });
    }

    abstract getAppliances(): Observable<T[]>;

    searchOrFocus(): void {
        if (this.searchApplianceInput) {
            if (this.searchArrayName) {
                this.searchApplianceInput.searchFor(this.searchArrayName);
                // emitChanges seems to be the only call that actually validates the input
                this.searchApplianceInput.emitChanges();
            } else {
                this.searchApplianceInput.setFocusWhenEmpty();
            }
        }
    }

    abstract selectVisibleProduct(initialProduct: T): void;

    abstract validateAppliance(control: UntypedFormControl): ValidationErrors;

    abstract writeValue(val: any): void;

    registerOnChange(fn: any): void {
        this.form.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.form.valid ? null : { invalidForm: { valid: false, message: 'Product is invalid' } };
    }
}
