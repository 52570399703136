import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { ReportConfiguration } from '../models/report-configuration';
import { QueryParams } from '../interfaces/list-params';
import { Observable } from 'rxjs';
import { IRestResponse } from '../interfaces/collection';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportConfigurationService extends GenericService<ReportConfiguration> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ReportConfiguration,
            endpoint: '/rest/v1/reports/configurations',
            create: true,
            update: true,
            delete: true,
        });
    }

    /**
     * override create since the POST API currently does not return an ItemCollection
     * @param properties Required fields: name
     * @param params If specified, the url query params to include
     */
    create(properties: Partial<ReportConfiguration>, params?: QueryParams): Observable<ReportConfiguration> {
        const req = this.getCreateRequest(properties);

        return this.http
            .post<IRestResponse<Object>>(req.url, req.body, {
                params: params,
                observe: 'response',
            })
            .pipe(map(response => response.body && new this.serviceParams.resourceClass(response.body)));
    }
}
