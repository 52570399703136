import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PxVolume } from '../models/px-volume';

@Injectable({ providedIn: 'root' })
export class PxVolumeAggregationService extends GenericService<PxVolume> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PxVolume,
            endpoint: '/rest/v1/portworx/volumes/aggregations',
            defaultParams: {
                sort: {
                    key: 'volume_name',
                    order: 'asc',
                },
            },
        });
    }
}
