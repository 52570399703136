import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment/moment';

import { GenericService } from './generic.service';
import { SustainabilityDatacenter } from '../models/sustainability-datacenter';

const defaultParams = {
    pageStart: 0,
    pageSize: 1000,
    extra: 'applianceTypes=FA&applianceTypes=FB',
};

@Injectable({ providedIn: 'root' })
export class SustainabilityDatacenterService extends GenericService<SustainabilityDatacenter> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SustainabilityDatacenter,
            defaultParams: defaultParams,
            endpoint: '/rest/v1/sustainability/data_centers',
            // Increase update frequency to 30 minutes. The data are getting refreshed once per hour in the database.
        });
    }
}
