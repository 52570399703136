import { DEFAULT_NAMESPACE, TAGS_KEY } from './actions';
import { IStateFilter, IStateFilterExport } from './pure-redux.service';
import PureUtils from '../utils/pure_utils';

/* Helper functions */

/**
 * Get the value for a state key, or the default value
 * @param state The current state
 * @param collectionKey The name of the collection (eg filters or times)
 * @param key If specified, assumes the collection contains an array of key-value pairs, and searches for the pair with this key.
 * @param defaultValue The default value for when the key does not exist
 */
export const getStateValue = (
    state: any,
    barId: string,
    collectionKey: string,
    key: string = undefined,
    defaultValue: string = undefined,
): string => {
    const barValues = (state[collectionKey] || [])[barId];
    if (!barValues) {
        return defaultValue;
    }

    if (key) {
        const match = barValues.find(kvp => kvp.key === key);
        return match ? match.value : defaultValue;
    } else {
        return barValues;
    }
};

export const createFilter = (
    entity: string,
    namespace: string,
    key: string,
    value: string,
    displayKey?: string,
    displayValue?: string,
): IStateFilter => {
    const id = PureUtils.getRandomId();
    return {
        id,
        entity,
        key,
        displayKey,
        namespace,
        value,
        displayValue,
    };
};

export const getFilterIdsByKey = (barFilters: any, entity: string, key: string, namespace: string): string[] => {
    const filterCompare = (entity: string, key: string, namespace: string, filter2: IStateFilter): boolean => {
        return (
            namespace === filter2.namespace &&
            key.toLocaleLowerCase() === filter2.key.toLocaleLowerCase() &&
            entity === filter2.entity
        );
    };

    if (barFilters) {
        return barFilters.filter(filter => filterCompare(entity, key, namespace, filter)).map(p => p.id);
    }
    return [];
};

export const splitFilterArguments = (text: string): string[] => {
    return text && text !== '' ? text.split(',') : null;
};

export const getFilterIdsByKeyValue = (
    barFilters: any,
    entity: string,
    key: string,
    value: string,
    namespace: string,
): string[] => {
    const filterCompare = (
        entity: string,
        key: string,
        value: string,
        namespace: string,
        filter2: IStateFilter,
    ): boolean => {
        return (
            namespace === filter2.namespace &&
            key.toLocaleLowerCase() === filter2.key.toLocaleLowerCase() &&
            value.toLocaleLowerCase() === filter2.value.toLocaleLowerCase() &&
            entity === filter2.entity
        );
    };

    if (barFilters) {
        return barFilters.filter(filter => filterCompare(entity, key, value, namespace, filter)).map(p => p.id);
    }
    return [];
};

const toFilterExports = (filters: IStateFilter[]): IStateFilterExport[] => {
    return filters.map(filter => {
        const newFilter: IStateFilterExport = {
            entity: filter.entity,
            key: filter.key,
            value: filter.value,
            namespace: filter.namespace === DEFAULT_NAMESPACE ? undefined : filter.namespace,
        };
        return newFilter;
    });
};

export const getFiltersUrlParams = (filters: IStateFilter[]): string => {
    return filters?.length > 0 ? JSON.stringify(toFilterExports(filters)) : undefined;
};

/*
Get back a map of active filters found in global and local bar.
Map should contain such:
activeFilters['name']['c75-10'] = true;
activeFilters['org_id']['25'] = true
*/
export const getActiveFilters = (globalBar: any, localBar: any): any => {
    let filters = localBar || [];
    filters = filters.concat(globalBar || []);
    const activeFilters = {};
    if (filters) {
        filters.forEach(element => {
            const value = element.value;
            if (!element.namespace) {
                activeFilters[element.key] = activeFilters[element.key] || {};
                activeFilters[element.key][value] = true;
            } else {
                activeFilters[TAGS_KEY] = activeFilters[TAGS_KEY] || new Map<string, string[]>();
                if (activeFilters[TAGS_KEY].has(element.key)) {
                    activeFilters[TAGS_KEY].get(element.key).push(value);
                } else {
                    activeFilters[TAGS_KEY].set(element.key, [value]);
                }
            }
        });
    }
    return activeFilters;
};

export const getCombinedFilters = (globalFilters: any, localFilters: any): any => {
    const filters = localFilters || [];
    return filters.concat(globalFilters || []);
};

export const getCombinedFilterValues = (filters: any, key: string): string => {
    const values = filters[key] ? Object.keys(filters[key]) : undefined;
    return (values || []).join(',');
};
