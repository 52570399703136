import _ from 'lodash';
import {
    Component,
    Input,
    HostBinding,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    Renderer2,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { IArrayRequestedAddress } from '@pure1/data';
import { PlottedArray } from '../arrays-map-view/arrays-map-view.component';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'contacts-drawer',
    templateUrl: './contacts-drawer.component.html',
})
export class ContactsDrawerComponent implements OnChanges {
    @Input() arrayLists: PlottedArray[][] = [];
    @HostBinding('class.show') @Input() drawerOpened: boolean;
    @Output() drawerClose = new EventEmitter<void>();
    @Output() locationChange = new EventEmitter<PlottedArray[]>();

    @ViewChild('mainContent', { static: true }) mainContent: ElementRef;

    selectedArrays = new Set<PlottedArray>();
    selectedArraysList: PlottedArray[] = [];
    showUpdateForm = false;

    // Analytics
    private readonly analyticsPrefix = 'Map View - ';
    private readonly analyticsStep = 'Workflow Step';

    constructor(
        private angulartics2: Angulartics2,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.arrayLists) {
            const flattenedList = _.flatten(this.arrayLists);
            this.selectedArrays.forEach(array => {
                if (!flattenedList.includes(array)) {
                    this.selectedArrays.delete(array);
                }
            });
            this.showUpdateForm = false;
            this.renderer.setProperty(this.mainContent.nativeElement, 'scrollTop', 0);
        }
    }

    onArraySelect(array: PlottedArray): void {
        this.selectedArrays.add(array);

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'Select Array',
            properties: {
                category: 'Action',
                label: 'Single',
            },
        });
    }

    onArrayDeselect(array: PlottedArray): void {
        this.selectedArrays.delete(array);
    }

    onCloseUpdateForm(): void {
        this.selectedArrays.clear();
        this.selectedArraysList = [];
        this.showUpdateForm = false;
    }

    onConfirmAddressChange(requestAddress: IArrayRequestedAddress): void {
        this.selectedArrays.forEach(array => {
            array.install_address_requested = requestAddress;
        });
        this.locationChange.emit(Array.from(this.selectedArrays));
        this.onCloseUpdateForm();
    }

    onEditAll(arrays: PlottedArray[]): void {
        this.selectedArrays = new Set(arrays);
        this.selectedArraysList = arrays;
        this.showUpdateForm = true;

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'Select Array',
            properties: {
                category: 'Action',
                label: 'All',
            },
        });
        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + this.analyticsStep,
            properties: {
                category: 'Action',
                label: 'Continue with address update',
            },
        });
    }

    updateButtonClicked(): void {
        this.selectedArraysList = Array.from(this.selectedArrays);
        this.showUpdateForm = true;

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + this.analyticsStep,
            properties: {
                category: 'Action',
                label: 'Continue with address update',
            },
        });
    }

    trackByArrayIds(index: number, arrays: PlottedArray[]): string {
        return arrays.map(array => array.id).join(',');
    }

    closeIconClick(): void {
        this.drawerClose.emit();
        this.showUpdateForm = false;

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + this.analyticsStep,
            properties: {
                category: 'Action',
                label: 'Close drawer',
            },
        });
    }
}
