<ng-container *ngIf="asset?.everModernUpgrade; else noEligibilityInfo">
    <div
        [ngbTooltip]="everModernTooltip"
        container="body"
        tooltipClass="ever-modern-tooltip"
        class="ever-modern-upgrade"
    >
        <ng-template #everModernTooltip>
            <div [innerHTML]="getEverModernTooltipHtml(asset)"></div>
        </ng-template>
        <span>
            {{ asset.everModernUpgrade.assetAgeTargetDate?.format('yyyy-MM-DD') | defaultString }}
        </span>
        <div *ngIf="doesEmuHaveDate(asset)" class="emu-display-inline">
            <div *ngIf="isEmuBooked(asset); else emuInfoIcon">
                <pureui-svg class="info-icon pstg-action-icon" svg="checkmark-circle.svg" height="13"></pureui-svg>
            </div>
            <ng-template #emuInfoIcon>
                <pureui-svg class="header-icon" svg="info-circle.svg" height="13"></pureui-svg>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #noEligibilityInfo>
    {{ null | defaultString }}
</ng-template>
