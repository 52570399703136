import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Resource } from '../interfaces/resource';
import { AsyncCollection, Collection, IRestResponse } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';
import { SortParams, QueryParams, ListParams, FilterParams } from '../interfaces/list-params';
import { createSortQueryString, customMappingForLegacyResponses } from '../utils';
import { cloneDeep, orderBy } from 'lodash';

export interface IServiceClassFactoryParams<T extends Resource> {
    resourceClass: { new (json: any): T };
    endpoint: string;
    defaultParams?: ListParams<T>;
    /**
     * If specified, always append this to the sort order if it is not already explicitly specified.
     * It is good to at least specify an id field to ensure order is always deterministic.
     */
    secondarySort?: SortParams;

    // operations supported by this service
    // note that if list is not specified, it is assumed to be supported
    list?: boolean;
    create?: boolean;
    update?: boolean;
    delete?: boolean;
}

export interface IAsyncServiceClassFactoryParams<T extends Resource, TOperation extends Resource>
    extends IServiceClassFactoryParams<T> {
    operationResourceClass: { new (json: any): TOperation };
}

/**
 * The generic base of generic services, doing generic things for other generic services.
 */
abstract class GenericServiceBase<T extends Resource> {
    protected abstract http: HttpClient;

    constructor(protected serviceParams: IServiceClassFactoryParams<T>) {}

    protected getDeleteRequest(ids: string, queryParams: QueryParams): { url: string } {
        if (!this.serviceParams.delete) {
            throw new Error('Not Supported');
        }

        let formattedQueryParams = '';
        if (queryParams) {
            formattedQueryParams =
                '?' +
                Object.keys(queryParams)
                    .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
                    .join('&');
        }

        return {
            url: this.serviceParams.endpoint + formattedQueryParams,
        };
    }

    protected getUpdateRequest(
        properties: Partial<T>,
        ids?: string[] | QueryParams,
    ): { url: string; body: string | Object } {
        if (!this.serviceParams.update) {
            throw new Error('Not Supported');
        }
        const baseUrl = `${this.serviceParams.endpoint}?`;
        let params: string;

        if (ids) {
            if (ids instanceof Array) {
                let formattedIds = '';
                if (ids && ids.length > 0) {
                    formattedIds = ids.map(id => `${id}`).join(',');
                } else {
                    throw new Error('No ids supplied in queryParams');
                }
                params = `ids=${formattedIds}`;
            } else {
                params = Object.keys(ids)
                    .map(key => `${key}=${encodeURIComponent(ids[key])}`)
                    .join('&');
            }
        } else if (properties?.id) {
            params = `ids=${properties.id}`;
        } else {
            throw new Error('No update ids supplied');
        }

        return {
            url: baseUrl + params,
            body:
                properties && typeof properties.toRequestBody === 'function' ? properties.toRequestBody() : properties,
        };
    }

    protected getCreateRequest(properties: Partial<T>): { url: string; body: string | Object } {
        if (!this.serviceParams.create) {
            throw new Error('Not Supported');
        }

        return {
            url: this.serviceParams.endpoint,
            body:
                properties && typeof properties.toRequestBody === 'function' ? properties.toRequestBody() : properties,
        };
    }

    protected getListRequest(params: ListParams<T>): { url: string } {
        if (this.serviceParams.hasOwnProperty('list') && !this.serviceParams.list) {
            throw new Error('Not Supported');
        }
        const defaultParams: ListParams<T> = this.serviceParams.defaultParams || {
            pageStart: 0,
            pageSize: 1000,
            sort: {
                key: 'name',
                order: 'asc',
            },
        };

        params = { ...defaultParams, ...params };

        if (defaultParams.filter?.product) {
            params.filter = { ...params.filter, product: defaultParams.filter.product };
        }

        const queryParams: string[] = [];
        if (params.ids) {
            queryParams.push(`ids=${params.ids.join(',')}`);
        }
        if (params.sort) {
            queryParams.push(`sort=${createSortQueryString(params.sort, this.serviceParams.secondarySort)}`);
        }
        if (params.pageSize) {
            queryParams.push(`limit=${params.pageSize}`);
        }
        if (params.pageStart) {
            queryParams.push(`start=${params.pageStart}`);
        }
        if (params.extra) {
            queryParams.push(params.extra);
        }

        if (params.filter || params.defaultFilter) {
            queryParams.push(`filter=${this.makeFilterQueryParam({ ...params.filter, ...params.defaultFilter })}`);
        }

        if (params.fields) {
            queryParams.push(`fields=${params.fields.join(',')}`);
        }

        if (params.supportStatusFilterOption) {
            queryParams.push(`support_status_filter_option=${params.supportStatusFilterOption}`);
        }

        const url = this.serviceParams.endpoint + (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');
        return { url };
    }

    protected makeDataPage(wrapper: IRestResponse<Object>, response: HttpResponse<IRestResponse<Object>>): DataPage<T> {
        if (!wrapper || !wrapper.items || !wrapper.total_item_count) {
            return {
                total: 0,
                response: [],
                originalResponse: response,
            };
        }

        const resources: T[] = wrapper.items.map(resource => new this.serviceParams.resourceClass(resource));
        return {
            asOf: wrapper._as_of,
            total: wrapper.total_item_count,
            response: resources,
            originalResponse: response,
        };
    }

    protected makeFilterQueryParam(filter: FilterParams<T>): string {
        const parts = Object.keys(filter)
            .filter(k => k !== undefined)
            .map(k => `${filter[k]}`);

        return encodeURIComponent(parts.join(' and '));
    }
}

/**
 * Helper for implementing a simple Collection<> for an endpoint that properly follows the REST guidelines.
 */
export abstract class GenericService<T extends Resource> extends GenericServiceBase<T> implements Collection<T> {
    constructor(protected serviceParams: IServiceClassFactoryParams<T>) {
        super(serviceParams);
    }

    list(params?: ListParams<T>): Observable<DataPage<T>> {
        const req = this.getListRequest(params);
        return this.http.get<IRestResponse<T>>(req.url, { observe: 'response' }).pipe(
            map(response => {
                const mappedResponse = customMappingForLegacyResponses(response, this.serviceParams.endpoint);
                return this.makeDataPage(mappedResponse, response);
            }),
        );
    }

    /**
     * @param properties Required fields: name
     * @param params If specified, the url query params to include
     */
    create(properties: Partial<T>, params?: QueryParams): Observable<T> {
        const req = this.getCreateRequest(properties);

        return this.http
            .post<IRestResponse<Object>>(req.url, req.body, {
                params: params,
                observe: 'response',
            })
            .pipe(
                map(
                    response =>
                        response.body?.items?.[0] && new this.serviceParams.resourceClass(response.body.items[0]),
                ),
            );
    }

    update(properties: Partial<T>, ids?: string[] | QueryParams): Observable<DataPage<T>> {
        const req = this.getUpdateRequest(properties, ids);

        return this.http.patch<IRestResponse<Object>>(req.url, req.body, { observe: 'response' }).pipe(
            map(response => {
                const mappedResponse = customMappingForLegacyResponses(response, this.serviceParams.endpoint);
                return this.makeDataPage(mappedResponse, response);
            }),
        );
    }

    delete(ids: string, queryParams?: QueryParams): Observable<void> {
        const req = this.getDeleteRequest(ids, queryParams);

        return this.http
            .delete<unknown>(req.url, {
                params: { ids },
                observe: 'response',
            })
            .pipe(map(_response => void 0)); // Don't bubble-up whatever the server returned
    }
}

/**
 * Same as GenericService, but for implementing an AsyncCollection<> instead of Collection<>.
 * Intentionally excludes some of the junk from GenericService as well (eg refreshInterval and customMappingForLegacyResponses).
 */
export abstract class AsyncGenericService<T extends Resource, TOperation extends Resource>
    extends GenericServiceBase<T>
    implements AsyncCollection<T, TOperation>
{
    constructor(protected serviceParams: IAsyncServiceClassFactoryParams<T, TOperation>) {
        super(serviceParams);
    }

    list(params?: ListParams<T>): Observable<DataPage<T>> {
        const req = this.getListRequest(params);

        return this.http
            .get<IRestResponse<T>>(req.url, { observe: 'response' })
            .pipe(map(response => this.makeDataPage(response.body, response)));
    }

    create(properties: Partial<T>, params?: QueryParams): Observable<TOperation> {
        const req = this.getCreateRequest(properties);

        return this.http
            .post<Object>(req.url, req.body, {
                params: params,
                observe: 'response',
            })
            .pipe(map(response => this.mapToOperation(response)));
    }

    update(properties: Partial<T>, ids?: string[] | QueryParams): Observable<TOperation> {
        const req = this.getUpdateRequest(properties, ids);

        return this.http
            .patch<IRestResponse<Object>>(req.url, req.body, { observe: 'response' })
            .pipe(map(response => this.mapToOperation(response)));
    }

    delete(ids: string, queryParams?: QueryParams): Observable<TOperation> {
        const req = this.getDeleteRequest(ids, queryParams);

        return this.http
            .delete<unknown>(req.url, {
                params: { ids },
                observe: 'response',
            })
            .pipe(map(response => this.mapToOperation(response)));
    }

    /**
     * Gest the Operation object from a response
     */
    private mapToOperation(response: HttpResponse<any>): TOperation {
        return response.body && new this.serviceParams.operationResourceClass(response.body);
    }
}
