import moment from 'moment';
import { Resource } from '../interfaces/resource';

export type podStatus = 'healthy' | 'critical' | 'warning';
export type ArrayPodStatus = 'online' | 'offline' | 'unknown' | 'resyncing';

export interface ArrayInPod extends Resource {
    readonly status: ArrayPodStatus;
    readonly frozen_at: moment.Moment | null;
}

export class Pod implements Resource, IPureAnalyticsSourceEntity {
    readonly _as_of: moment.Moment;
    readonly id;
    readonly name;
    readonly last_in_sync: moment.Moment | null;
    arrays: ArrayInPod[];
    status: podStatus;

    constructor(json: any) {
        this._as_of = moment(json._as_of);
        this.id = json.id;
        this.name = json.name;
        if (json.last_in_sync) {
            this.last_in_sync = moment(json.last_in_sync);
        } else {
            this.last_in_sync = null;
        }
        this.arrays = json.arrays.map(array => {
            if (array.frozen_at) {
                array.frozen_at = moment(array.frozen_at);
            }
            return array;
        });
        this.status = json.status;
    }
}
