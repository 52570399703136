import { Resource } from '../interfaces/resource';

export class Tag {
    key: string;
    namespace: string;
    value: string;
    resource: Resource;
    tag_organization_id: number;

    constructor(tag: any) {
        this.key = tag.key;
        this.namespace = tag.namespace;
        this.value = tag.value;
        this.tag_organization_id = tag.tag_organization_id;
        if (tag.resource) {
            this.resource = tag.resource;
        } else {
            this.resource = {
                id: tag.resource_id,
                name: tag.resource_name,
            };
        }
    }

    get uniqueCombinedKey(): string {
        return `${this.namespace}:${this.tag_organization_id}:${this.key.toLocaleLowerCase()}`;
    }
}
