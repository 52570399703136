import { Alert } from './Alert';
import { AlertBody } from '@pure1/data';

export class CloudAlert extends Alert {
    description: string;

    constructor(alertBody: AlertBody) {
        super(alertBody);

        this.description = alertBody.description;
        this.arrayId = alertBody.array_id;
    }
}
