import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { FeatureFlagDxpService } from '../services/feature-flag-dxp.service';

@Directive({
    selector: '[featurePolicy]',
})
export class FeaturePolicyDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    @Input() set featurePolicy(flag: string) {
        this.featureFlagDxpService
            .getFeatureFlag(flag)
            .pipe(
                take(1),
                map(feature => feature?.enabled === true),
            )
            .subscribe(result => {
                if (result) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
