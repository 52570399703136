export type ReportFilterType = 'FILTERED' | 'SELECTED';

export type ReportFilterVersion = 'v3';

export class ReportFilter {
    type: ReportFilterType;
    value: string;
    filter_version: ReportFilterVersion;

    constructor(json: any) {
        this.type = json.type;
        this.value = json.value;
        this.filter_version = json.filter_version;
    }
}
