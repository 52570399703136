export type PricingType = 'reserved' | 'on_demand' | 'performance' | 'unique' | 'linear';

export interface Dimension {
    display_text: string; // eg: Term (in months)
    labels: [
        {
            display_text: string;
            value?: number;
            min?: number;
            max?: number;
        },
    ];
}

export class ServiceCatalogStructuredPricing {
    name: string;
    pricingType: PricingType;
    terms: Dimension;
    tiers: Dimension;
    data: number[][];
    tiersSizeToDataPriceSizeMultiplier: number; // this is currently fixed at 1024 as we only have 1 TiB = 1024 GiB

    constructor(json: any) {
        this.name = json.name;
        this.pricingType = json.pricing_type;
        this.terms = json.terms;
        this.tiers = json.tiers;
        this.data = json.data;
        this.tiersSizeToDataPriceSizeMultiplier = 1024;
    }

    /** This method currently assumes the following:
     * desiredTerm will match one of the term lengths (terms.labels[x].value)
     * desiredSize are in TiB (as per slider input)
     * tiers sizes are also in TiB (tiers.labels[x].min & .max)
     * pricing $ amount are in GiB (1 TiB = 1024 GiB)
     * pricing data is stored as data[term][tier] (meaning first dimension is the term length, then the size)
     *
     * This method returns -1 on error (such as no term length match)
     */
    getPrice(desiredTerm: number, desiredSize: number): number {
        // simple validations
        if (Number.isNaN(desiredTerm) || Number.isNaN(desiredSize) || desiredTerm < 0 || desiredSize < 0) {
            return -1;
        }

        // find the term index matching the desired term
        const termIndex = this.terms.labels.findIndex(label => label.value === desiredTerm);
        if (termIndex < 0) {
            return -1;
        }

        // find the size index matching the desired storage size
        // (for number such as 800+TiB, assuming backend will use very large number for tier.max)
        const sizeIndex = this.tiers.labels.findIndex(tier => tier.min <= desiredSize && desiredSize <= tier.max);
        if (sizeIndex < 0) {
            return -1;
        }

        // calculate price and return
        return desiredSize * this.tiersSizeToDataPriceSizeMultiplier * this.data[termIndex][sizeIndex];
    }

    getPerformanceBasedPrice(desiredTerm: number, desiredSize: number, desiredPerformance: number): number {
        // simple validations
        if (
            Number.isNaN(desiredTerm) ||
            Number.isNaN(desiredSize) ||
            Number.isNaN(desiredPerformance) ||
            desiredTerm < 0 ||
            desiredSize < 0 ||
            desiredPerformance < 0
        ) {
            return -1;
        }

        // find the term index matching the desired term
        const termIndex = this.terms.labels.findIndex(label => label.value === desiredTerm);
        if (termIndex < 0) {
            return -1;
        }

        // find the size index matching the desired performance
        const performanceIndex = this.tiers.labels.findIndex(
            tier => tier.min <= desiredPerformance && desiredPerformance <= tier.max,
        );
        if (performanceIndex < 0) {
            return -1;
        }

        if (this.pricingType === 'performance') {
            return this.data[termIndex][performanceIndex] * desiredPerformance;
        }

        // calculate price and return
        return desiredSize * this.tiersSizeToDataPriceSizeMultiplier * this.data[termIndex][performanceIndex];
    }

    generatePriceTable(): any {
        return {
            headers: this.generatePriceTableHeaders(),
            name: this.name,
            rows: this.generatePriceTableRows(),
        };
    }

    generatePriceTableHeaders(): string[] {
        if (!this.terms || !this.tiers) {
            return [];
        }

        const headerStringsArray: string[] = [];
        headerStringsArray.push(this.terms.display_text);
        this.tiers.labels.forEach(label => headerStringsArray.push(label.display_text));

        return headerStringsArray;
    }

    generatePriceTableRows(): string[][] {
        if (!this.terms || !this.tiers) {
            return [];
        }

        const contentStringsArray: string[][] = [];

        this.terms.labels.forEach((termLabel, termIndex) => {
            const rowArray = [termLabel.display_text];
            this.data[termIndex].forEach(cellValue => {
                rowArray.push(
                    cellValue.toLocaleString('en-US', {
                        minimumFractionDigits: this.pricingType === 'performance' ? 0 : 3,
                        style: 'currency',
                        currency: 'USD',
                    }),
                );
            });
            contentStringsArray.push(rowArray);
        });

        return contentStringsArray;
    }
}
