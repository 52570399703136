import { Pipe, PipeTransform } from '@angular/core';
import { CaseEscalationReason } from '../../support.interface';

/**
 * Takes a reason number from SNOW representing a day of the month and makes it more human readable e.g. 1 -> "Day 1"
 * also, if the day is greater than 28, convert it to last day of the month
 */
@Pipe({ name: 'escalationReason' })
export class EscalationReasonPipe implements PipeTransform {
    transform(reason: CaseEscalationReason): any {
        switch (reason) {
            case CaseEscalationReason.CLOSED_BUT_NOT_RESOLVED:
                return 'Case closed but issue not resolved';
            case CaseEscalationReason.PROGRESS_BY_EMPLOYEE:
                return "Case not progressing at customer's satisfaction";
            case CaseEscalationReason.PROGRESS_BY_CUSTOMER:
                return 'Case not progressing at your satisfaction';
            case CaseEscalationReason.SEVERITY_INCREASED:
                return 'Case severity has increased';
            case CaseEscalationReason.COMMUNICATION_DELAY:
                return 'Communication delay';
            case CaseEscalationReason.CUSTOMER_REQUEST:
                return 'On behalf of customer';
            case CaseEscalationReason.MISSED_SCHEDULED_COMMITMENT:
                return 'Missed scheduled commitment';
            case CaseEscalationReason.RMA_ISSUE:
                return 'RMA issue';
            case CaseEscalationReason.OTHER:
                return 'Other, please provide additional details';
        }
    }
}
