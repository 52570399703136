<div class="banner-icon">
    <pureui-svg svg="new-big-badge.svg" [height]="83.25"></pureui-svg>
</div>
<button
    class="dismiss-button svg-hover-dropshadow"
    (click)="dismissNotification.emit()"
    pure-read-only
    angulartics2On="click"
    angularticsCategory="Action"
    angularticsAction="SafeMode Banner - Close banner"
    [angularticsLabel]="notification.title"
>
    <pureui-svg svg="close-button.svg" [height]="12.39"></pureui-svg>
</button>
<div class="banner-content">
    <div class="banner-graphic">
        <pureui-svg [svg]="notification.image" width="60"></pureui-svg>
    </div>
    <div class="banner-text">
        <div class="banner-title">
            {{ notification.title }}
        </div>
        <br />
        <div class="banner-body">
            {{ notification.body }}
            <span class="safemode-links">
                <a
                    href="https://support.purestorage.com/Pure_PEAK/Knowledge/Drafting_Area/Pure1_Manage_-_SafeMode"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="SafeMode Banner - Pure1 KB"
                    [angularticsLabel]="notification.title"
                >
                    Pure1
                </a>
                |
                <a
                    href="https://www.purestorage.com/docs.html?item=/type/pdf/subtype/doc/path/content/dam/pdf/en/solution-briefs/sb-ransomware-remediation-for-flasharray.pdf"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="SafeMode Banner - FlashArray Remediation"
                    [angularticsLabel]="notification.title"
                >
                    FlashArray Remediation
                </a>
                |
                <a
                    href="https://www.purestorage.com/docs.html?item=/type/pdf/subtype/doc/path/content/dam/pdf/en/solution-briefs/sb-protect-against-ransomware-with-pure.pdf"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="SafeMode Banner - FlashBlade Remediation"
                    [angularticsLabel]="notification.title"
                >
                    FlashBlade Remediation
                </a>
                |
                <a
                    href="https://pure1.purestorage.com/services/servicecatalog/details?selection=Professional%20ServicesEvaluateSafeMode%20Advisory%20Workshop"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="SafeMode Banner - SafeMode Advisory Workshop"
                    [angularticsLabel]="notification.title"
                >
                    SafeMode Advisory Workshop
                </a>
            </span>
        </div>
    </div>
    <div class="banner-actions">
        <ng-container *ngIf="notification.buttonTemplate; else pageRedirectButton">
            <ng-container *ngTemplateOutlet="notification.buttonTemplate"></ng-container>
        </ng-container>
        <ng-template #pageRedirectButton>
            <a
                *ngIf="notification.path"
                class="btn btn-primary try-button"
                [routerLink]="notification.path"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="SafeMode Banner - Try it out"
                [angularticsLabel]="notification.title"
            >
                Try it out
            </a>
        </ng-template>
    </div>
</div>
