import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

import { CaseEmail, ItemType } from '../support.interface';
import { first, map } from 'rxjs/operators';

@Injectable()
export class CaseEmailService {
    private static cacheTTL = 29000; // 29 seconds
    private endpoint = '/rest/v1/support/cases';

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: CaseEmailService.cacheTTL,
    })
    getCaseEmails(caseId: string): Observable<CaseEmail[]> {
        return this.http.get<CaseEmail[]>(`${this.endpoint}/${caseId}/emails`).pipe(
            first(),
            map((emails: CaseEmail[]) =>
                emails
                    .filter(
                        email => !!email.from && (!!email.htmlBody || !!email.body), // filter out emails coming from SNOW that dont have necessary fields
                    )
                    .map(e => this.itemTypeHelper(e)),
            ),
        );
    }

    @Cacheable({
        maxAge: CaseEmailService.cacheTTL,
    })
    getCaseEmailById(caseId: string, emailId: string): Observable<CaseEmail> {
        return this.http.get<CaseEmail>(`${this.endpoint}/${caseId}/emails?emailId=${emailId}`);
    }

    private itemTypeHelper = (data: CaseEmail): CaseEmail => {
        return {
            ...data,
            itemType: ItemType.Email,
        };
    };
}
