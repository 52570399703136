import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Partner } from '../models/partner';

const PARTNER_ENDPOINT = '/rest/xaas/v1/partners';

@Injectable({ providedIn: 'root' })
export class PartnersService extends GenericService<Partner> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Partner,
            endpoint: PARTNER_ENDPOINT + '/valid',
        });
    }
}
