<h6 class="title hive-header-semibold">Multi-Factor Authentication Required</h6>
<div
    (click)="close()"
    angulartics2On="click"
    angularticsAction="Step Up - Enrollment Modal - close modal"
    class="dismiss-button"
    data-testid="enrollment-via-identity-center-required-modal-close-button"
>
    &#x2715;
    <!-- "✕" -->
</div>

<div class="image">
    <pureui-svg width="150" svg="identity-center-setup-mfa.svg"></pureui-svg>
</div>

<div class="text">
    <p>
        We've added an extra level of security against unauthorized logins and access to sensitive functionality. Once
        you set up step-up multifactor authentication, Pure1 will ask for a temporary one time passcode when
        authentication is required.
        <a
            [external]="true"
            [href]="STEP_UP_SUPPORT_LINK"
            angulartics2On="click"
            angularticsAction="Step Up - Enrollment Modal - learn more"
            angularticsCategory="Action"
            hive-link
            data-testid="enrollment-via-identity-center-required-modal-learn-more-link"
            target="_blank"
        >
            Learn More
        </a>
    </p>
</div>

<div class="actions">
    <hive-button
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Step Up - Enrollment Modal - close modal"
        angularticsCategory="Action"
        emphasis="secondary"
        data-testid="enrollment-via-identity-center-required-modal-cancel-button"
        label="Cancel"
    />
    <a
        (click)="close()"
        [href]="'identity-center' | pure1AppLink: '/profile/account-security?mfaSetupOpen=true'"
        angulartics2On="click"
        angularticsAction="Pure Identity Center - Dialog - Launch"
        angularticsCategory="Action"
        target="_blank"
    >
        <hive-button
            emphasis="primary"
            icon="regular/arrow-up-right-from-square.svg"
            iconPosition="right"
            label="Set Up Multi-Factor Authentication"
            data-testid="enrollment-via-identity-center-required-modal-setup-mfa-button"
        />
    </a>
</div>
