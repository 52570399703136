import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { CurrentUser, ResetPasswordService, StepUpFactor, User } from '@pure1/data';
import { Subject, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CachedStepUpService } from '../../data/services/cached-step-up.service';
import { StatelessSubmenuBarItem } from '../../ui/components/stateless-submenu-bar/stateless-submenu-bar.component';
import { UserModalStateService } from '../user-modal/user-modal-state.service';

type CreateEditUserStatus = 'form' | 'successCreate' | 'error' | 'confirmDelete' | 'successResetPassword';

@Component({
    selector: 'um-edit-user',
    templateUrl: './um-edit-user.component.html',
})
export class UMEditUserComponent implements OnInit, OnDestroy {
    @Input() readonly currentUser: CurrentUser;

    usersEndpointUser: User = null;
    status: CreateEditUserStatus = 'form';
    isProcessing = false;
    submenuItems: StatelessSubmenuBarItem[] = [{ title: 'User Details' }, { title: 'Account Security' }];
    selectedSubmenuItem: StatelessSubmenuBarItem;

    private readonly destroy$ = new Subject<void>();

    constructor(
        public umStateService: UserModalStateService,
        private resetPasswordService: ResetPasswordService,
        private cachedStepUpService: CachedStepUpService,
        private authzServiceResolver: AuthorizationServiceResolver,
    ) {}

    ngOnInit(): void {
        this.authzServiceResolver
            .getDefaultService()
            .pipe(switchMap(service => service.hasPermission('PURE1:read:authentication_details')))
            .pipe(takeUntil(this.destroy$))
            .subscribe(canReadAuthDetails => {
                if (canReadAuthDetails) {
                    this.cachedStepUpService
                        .getFactorsV2()
                        .pipe(takeUntil(this.destroy$))
                        .subscribe({
                            next: (factors: StepUpFactor[]) => {
                                this.submenuItems = this.submenuItems.slice();
                                this.submenuItems[1] = {
                                    ...this.submenuItems[1],
                                    warning: !factors.some(
                                        factor => factor.factorType === 'totp' && factor.status === 'ACTIVE',
                                    ),
                                };
                            },
                        });
                }
            });
    }

    onUserSaved(user: User): void {
        this.returnToList();
    }

    onCancelled(): void {
        this.returnToList();
    }

    returnToList(): void {
        this.umStateService.previousState();
    }

    onError(errorMsg: string): void {
        this.status = 'error';
    }

    resetPassword(): void {
        this.resetPasswordService.delete(null, { email: this.currentUser.email }).subscribe(
            () => {
                this.status = 'successResetPassword';
            },
            () => {
                this.status = 'error';
            },
        );
    }

    returnToForm(): void {
        this.status = 'form';
    }

    isSsoUser(): boolean {
        return this.currentUser && this.currentUser.adUser;
    }

    selectedSubmenuItemChanged(selectedItem: StatelessSubmenuBarItem): void {
        this.selectedSubmenuItem = selectedItem;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
