import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '../ui/ui.module';
import { ManageTagsModalComponent } from './manage-tags/manage-tags-modal.component';
import { PureTagComponent } from './pure-tag/pure-tag.component';
import { AuthorizerModule } from '@pure/authz-authorizer';
@NgModule({
    imports: [AuthorizerModule, CommonModule, ReactiveFormsModule, UIModule],
    declarations: [ManageTagsModalComponent, PureTagComponent],
    exports: [ManageTagsModalComponent, PureTagComponent],
})
export class TagsSharedModule {}
