import moment, { Duration } from 'moment';
import { DraasApiVmLastJobExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryJobExecutionStatus } from '@pure1/data';

export class DisasterRecoveryVmLastJobExecutionInfo {
    id: string;
    name: string;
    status: DisasterRecoveryJobExecutionStatus;
    groupName: string;
    start: moment.Moment;
    end: moment.Moment;
    duration: Duration;
    vmwareToolsVersion: string;
    deleted: boolean;
    diskSize: number;

    constructor(json: DraasApiVmLastJobExecutionInfo) {
        this.id = json.vm.id;
        this.name = json.vm.name;
        this.groupName = json.vm.protection_group_name;
        if (!json.execution_info) {
            this.status = DisasterRecoveryJobExecutionStatus.NOT_PROTECTED;
        } else if (json.execution_info.status === DisasterRecoveryJobExecutionStatus.COMPLETE) {
            this.status = DisasterRecoveryJobExecutionStatus.FINISHED_SUCCESSFULLY;
        } else {
            this.status =
                DisasterRecoveryJobExecutionStatus[json.execution_info.status] ||
                DisasterRecoveryJobExecutionStatus.UNKNOWN;
        }
        this.start = json.execution_info?.start ? moment.utc(json.execution_info.start) : null;
        this.end = json.execution_info?.end ? moment.utc(json.execution_info.end) : null;
        this.duration =
            this.start && this.end && this.start.isSameOrBefore(this.end)
                ? moment.duration(this.end.diff(this.start))
                : null;
        this.vmwareToolsVersion = json.vm.vmware_tools_version;
        this.diskSize = json.vm.disk_total_size;
        this.deleted = json.vm.deleted;
    }
}
