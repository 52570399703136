import { DisasterRecoverySnapshotSummary } from './disaster-recovery-snapshot-summary';
import { DraasApiGroupWithSnapshotSets } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryPlanGroupWithSnapshotSets {
    groupId: string;
    groupName: string;
    snapshotSets: DisasterRecoverySnapshotSummary[];

    constructor(group: DraasApiGroupWithSnapshotSets) {
        this.groupId = group.group_id;
        this.groupName = group.group_name;
        this.snapshotSets = group.snapshot_sets.map(json => new DisasterRecoverySnapshotSummary(json));
    }
}
