<div #resize class="pureui-resizable resizable-leftright" style="position: relative">
    <ng-content></ng-content>
    <div
        class="resize-grabber grabber-leftright"
        (mousedown)="dragStartHandler($event)"
        (touchstart)="dragStartHandler($event)"
        (dragstart)="(false)"
    >
        <span class="grabber-bar"></span>
        <span class="grabber-icon"></span>
    </div>
</div>
