import moment from 'moment';

export class LicensePerformanceAggregationMetrics {
    avgValue?: number;
    avgPercent?: number;
    maxValue?: number;
    maxPercent?: number;
    slaDensity?: number;
    limitDensity?: number;

    constructor(json: any) {
        this.avgValue = json.avg_value || null;
        this.avgPercent = json.avg_percent || null;
        this.maxValue = json.max_value || null;
        this.maxPercent = json.max_percent || null;
        this.slaDensity = json.sla_density || null;
        this.limitDensity = json.limit_density || null;
    }
}

export class LicensePerformanceAggregation {
    aggregationTime?: moment.Moment;
    bw: LicensePerformanceAggregationMetrics;
    io: LicensePerformanceAggregationMetrics;

    constructor(json: any) {
        this.aggregationTime = moment.utc(json.aggregation_time) || null;
        this.bw = new LicensePerformanceAggregationMetrics(json.bw || {});
        this.io = new LicensePerformanceAggregationMetrics(json.io || {});
    }
}
