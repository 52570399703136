import moment from 'moment';
import { isEqual } from 'lodash';
import { Observable, throwError, of, ReplaySubject } from 'rxjs';
import { first, map, concatAll, catchError, flatMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CachedCurrentUserService } from '@pure1/data';

import { SupportCase, SupportCaseWithUrl } from '../support.interface';
import { SupportErrorService } from './error.service';
import { forMoment } from '../../utils/comparator';
import { CaseService } from './case.service';

// for input array [ { name: 'sil', color: 'pink' }, { name: 'ondra', color: 'blue' } ] and property 'color'
// the output will be { 'pink': { name: 'sil', color: 'pink' }, 'blue': { name: 'ondra', color: 'blue' } }
const arrayToMapFilter = <T extends object>(array: T[], property: keyof T): Map<any, T> => {
    const objectMap = new Map<any, T>();
    for (let i = 0; i < array.length; i++) {
        if (array[i][property]) {
            const key = array[i][property];
            const value: T = array[i];
            objectMap.set(key, value);
        }
    }

    return objectMap;
};

@Injectable({
    providedIn: 'root',
})
export class CaseManager {
    allOpenCases$ = new ReplaySubject<SupportCase[]>(1);

    private allOpenCases: SupportCaseWithUrl[];
    private allClosedCases: SupportCaseWithUrl[];
    private initialPromise: Observable<SupportCase[]>;
    private previousCasesMap: any;
    private casesMap: any;
    private closedCasesMap: Map<string, SupportCaseWithUrl>;
    private isRefreshing = false;

    private _counts: ISupportCounts;

    constructor(
        private http: HttpClient,
        private caseService: CaseService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private supportErrorService: SupportErrorService,
    ) {}

    /*
     * Adds a new case WITHOUT re-fetching the raw data.
     */
    addCase(newCase: SupportCase): void {
        newCase.lastCaseActivity = moment();

        // If case is created from outside of support page then this property is undefined
        if (this.allOpenCases) {
            this.allOpenCases.push({
                url: null,
                ...newCase,
            });
            this.allOpenCases = this.buildCasesNav(this.allOpenCases);
            this.allOpenCases$.next(this.allOpenCases);
        }
    }

    counts(): ISupportCounts {
        return this._counts;
    }

    forNav(): SupportCase[] {
        return this.allOpenCases;
    }

    closedCases(): SupportCase[] {
        return this.allClosedCases;
    }

    fetchClosedCases(): Observable<SupportCase[]> {
        return this.caseService.getCasesBrief({ closed: true }).pipe(
            first(),
            map((closedCases: SupportCase[]) => {
                this.allClosedCases = closedCases
                    .map(caseDetails => {
                        return {
                            ...caseDetails,
                            url: `/support/cases?caseId=${caseDetails.id}`,
                        };
                    })
                    .sort(forMoment((supportCase: SupportCase) => supportCase.closedDate?.valueOf()).desc);
                this.closedCasesMap = arrayToMapFilter(this.allClosedCases, 'id');
                return this.allClosedCases;
            }),
        );
    }

    getCase(caseId: string): Observable<SupportCase> {
        if (!this.initialPromise) {
            this.initialPromise = this.refresh();
        }
        if (this.isRefreshing) {
            return this.initialPromise.pipe(
                map(() => {
                    const caseDetails =
                        this.casesMap.get(caseId) || (this.closedCasesMap ? this.closedCasesMap.get(caseId) : null);
                    if (caseDetails) {
                        return of(caseDetails);
                    } else {
                        return this.caseService
                            .getCaseById(caseId)
                            .pipe(catchError(this.supportErrorService.errorInterceptor));
                    }
                }),
                flatMap(value => value),
            );
        }
        const caseDetails = this.casesMap.get(caseId) || (this.closedCasesMap ? this.closedCasesMap.get(caseId) : null);
        if (caseDetails) {
            return of(caseDetails);
        } else {
            return this.caseService.getCaseById(caseId).pipe(catchError(this.supportErrorService.errorInterceptor));
        }
    }

    getFullCase(caseId: string): Observable<SupportCase> {
        return this.caseService.getCaseById(caseId).pipe(catchError(this.supportErrorService.errorInterceptor));
    }

    /*
     * This method fetches arrays and cases and then builds the tree
     */
    refresh(): Observable<SupportCase[]> {
        this.isRefreshing = true;
        return this.caseService.getCasesBrief().pipe(
            first(),
            map(response => {
                const newOpenCases = this.buildCasesNav(response);
                if (!isEqual(this.allOpenCases, newOpenCases)) {
                    this.allOpenCases = newOpenCases;
                    this.allOpenCases$.next(this.allClosedCases);
                }
                this.isRefreshing = false;
                return this.allOpenCases;
            }),
            catchError(err => {
                this.supportErrorService.errorInterceptor(err);
                this.isRefreshing = false;
                throw err;
            }),
        );
    }

    /*
     * Updates case details WITHOUT refetching the raw data
     */
    updateCase(updatedCase: Partial<SupportCase>, lastUserView?: moment.Moment): void {
        const oldCase = this.casesMap.get(updatedCase.id) || this.closedCasesMap.get(updatedCase.id);
        if (oldCase) {
            if (oldCase.isClosed) {
                this.allOpenCases = this.allOpenCases.concat(oldCase);
            }
            updatedCase.lastUserView = lastUserView || moment();
            const mergedCase = Object.assign(oldCase, updatedCase);

            this.allOpenCases = this.buildCasesNav(
                this.allOpenCases.map(c => (c.id === mergedCase.id ? mergedCase : c)),
            );
            this.allOpenCases$.next(this.allOpenCases);
        }
    }

    getNewestCases(): SupportCase[] {
        const newestCases: SupportCase[] = [];
        if (this.casesMap && this.previousCasesMap) {
            this.casesMap.forEach((caseDetails: SupportCase) => {
                if (!this.previousCasesMap.has(caseDetails.id)) {
                    newestCases.push(caseDetails);
                }
            });
        }
        return newestCases;
    }

    getRecentlyClosedCases(): SupportCase[] {
        const closedCases: SupportCase[] = [];
        if (this.casesMap && this.previousCasesMap) {
            this.previousCasesMap.forEach((caseDetails: SupportCase) => {
                if (!this.casesMap.has(caseDetails.id)) {
                    closedCases.push(caseDetails);
                }
            });
        }
        return closedCases;
    }

    markAsRead(supportCase: SupportCase): Observable<number> {
        return this.cachedCurrentUserService.get().pipe(
            take(1),
            map(currentUser => {
                if (currentUser && !currentUser.readOnly) {
                    return this.http.post(`/rest/v1/support/cases/${supportCase.id}/timestamp`, {}).pipe(
                        first(),
                        map((ts: ISupportTimestampResource): number => {
                            this.updateCase({ ...supportCase }, moment(ts.timestamp));
                            return ts.timestamp;
                        }),
                    );
                } else {
                    return throwError('Readonly user cannot mark a case as Read');
                }
            }),
            concatAll(),
        );
    }

    reopen(caseId: string): void {
        this.updateCase({
            id: caseId,
            isClosed: false,
            status: 'Awaiting on Support',
        });
        this.allClosedCases = this.allClosedCases.filter((supportCase: SupportCase) => supportCase.id !== caseId);
        this.closedCasesMap = arrayToMapFilter(this.allClosedCases, 'id');
    }

    getCaseCounts(): Observable<ISupportCounts> {
        return this.http.get<ISupportCounts>(`/rest/v1/support/cases/counts`);
    }

    private buildCasesNav(cases: SupportCase[]): SupportCaseWithUrl[] {
        if (cases) {
            this.previousCasesMap = this.casesMap;
            const newCases = cases
                .map(caseDetails => {
                    return {
                        ...caseDetails,
                        url: '/support/cases?caseId=' + caseDetails.id,
                    };
                })
                .sort(forMoment((supportCase: SupportCase) => supportCase.lastCaseActivity?.valueOf()).desc);
            this.casesMap = arrayToMapFilter(newCases, 'id');
            this.updateCounts(newCases);
            return newCases;
        }
    }

    private updateCounts(supportCases: SupportCase[]): void {
        if (supportCases) {
            this._counts = {
                escalated: supportCases.filter(supportCase => supportCase.isEscalated).length,
                total: supportCases.length,
            };
        }
    }
}
