import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'cdu-banner',
    templateUrl: 'cdu-banner.component.html',
})
export class CduBannerComponent extends BaseBanner implements AfterViewInit {
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('cduButtonTemplate', { static: true }) readonly cduButtonTemplate: TemplateRef<any>;
    @ViewChild('cduLinksTemplate', { static: true }) readonly cduLinksTemplate: TemplateRef<any>;

    ngAfterViewInit(): void {
        this.populatedNotification = Object.assign({}, this.notification, {
            buttonTemplate: this.cduButtonTemplate,
            linksTemplate: this.cduLinksTemplate,
        });
    }
}
