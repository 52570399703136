import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { TimeRange } from './time-range-selector.component';

@Pipe({
    name: 'timeRange',
})
export class TimeRangePipe implements PipeTransform {
    transform(tr: moment.Duration | TimeRange | ITimeRange): string {
        if (moment.isDuration(tr)) {
            const durationFormats = [
                { method: 'asYears', singular: 'year', plural: 'years' },
                { method: 'asMonths', singular: 'month', plural: 'months' },
                { method: 'asWeeks', singular: 'week', plural: 'weeks' },
                { method: 'asDays', singular: 'day', plural: 'days' },
                { method: 'asHours', singular: 'hour', plural: 'hours' },
            ];
            for (const format of durationFormats) {
                const val = tr[format.method]();
                if (Number.isInteger(val)) {
                    if (val === 1) {
                        return `Last ${format.singular}`;
                    } else if (val > 1) {
                        return `Last ${val} ${format.plural}`;
                    }
                }
            }
        } else {
            let start: moment.Moment;
            let end: moment.Moment;

            if ('end' in tr && 'start' in tr) {
                // To handle the TimeRange interface in time-selector
                start = tr.start;
                end = tr.end;
            } else if ('getStartTime' in tr && 'getEndTime' in tr) {
                // To handle ITimeRange
                start = tr.getStartTime();
                end = tr.getEndTime();
            }

            const duration = moment.duration(end.diff(start), 'milliseconds');
            return `[${duration.humanize(false)}] ${start.format('MMM D, LT')} - ${end.format('MMM D, LT')}`;
        }
    }
}
