<div class="nav-pagination btn-group event-input float-xs-right" role="group" aria-label="Pagination">
    <!-- Page number / total pages -->
    <div class="btn btn-secondary btn-no-hover count-range">
        <span *ngIf="totalItemCount != null">
            <span class="current-count">{{ currentCount }}</span>
            <span class="total-count">of {{ totalItemCount }}</span>
        </span>
        <span *ngIf="totalItemCount == null">
            <pureui-spinner [pureuiShow]="true"></pureui-spinner>
        </span>
    </div>

    <!-- Previous page -->
    <button
        type="button"
        class="btn btn-secondary change-page with-action-icon"
        [disabled]="offset <= 0"
        (click)="selectPage(offset - itemsPerPage)"
        blurOnClick
    >
        <span class="pstg-action-icon">
            <pureui-svg svg="chevron-left.svg" height="10"></pureui-svg>
        </span>
    </button>

    <!-- Next page -->
    <button
        type="button"
        class="btn btn-secondary change-page with-action-icon"
        [disabled]="offset + itemsPerPage >= totalItemCount"
        (click)="selectPage(offset + itemsPerPage)"
        blurOnClick
    >
        <span class="pstg-action-icon">
            <pureui-svg svg="chevron-right.svg" height="10"></pureui-svg>
        </span>
    </button>
</div>
