import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CachedCurrentUserService } from '@pure1/data';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { PagedResult } from '../../software-lifecycle/purity-upgrades/purity-upgrades.interface';

export const MAX_SELECTED_APPLIANCES = 4;

export interface OrgApproversDetails {
    hasEnoughApprovers: boolean;
}

export interface PermissionRequest {
    candidate: Candidate;
}

export interface Candidate {
    name: string;
    email: string;
}

export enum SafeModeStatusEnablement {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    UNCHANGED = 'UNCHANGED',
}

export interface SafeModeRequestDetails {
    newExpirationInDays?: number;
    newSafeModeStatus: SafeModeStatusEnablement;
}

export interface SafemodeChangeRequest {
    id: number;
    creatorName: string;
    mpaRequestId?: number;
    executionTime: moment.Moment;
    orgId: number;
    caseId: string;
    caseNumber: string;
    resources: SafeModeResource[];
    changeParameters: SafeModeRequestDetails;
    createTime: moment.Moment;
    lastUpdateTime: moment.Moment;
    cancelled: boolean;
    stepUpRequestDetails: { requestId: number };
}

export enum ApplianceStorageType {
    FILE_SYSTEMS = 'FILE_SYSTEMS',
    OBJECT_STORAGE = 'OBJECT_STORAGE',
}

export interface SafeModeResourceId {
    applianceId: string;
    pgroupId?: String;
    storageTypes?: ApplianceStorageType[];
}

export interface SafeModeResource {
    applianceId: string;
    applianceName: string;
    pgroupId?: string;
    storageTypes?: ApplianceStorageType[];
}

const safemodePath = '/multiparty/safemode/change-requests';

const safemodeOrgCompliantPath = '/multiparty/safemode/org-compliance';

const safemodeCandidatePath = '/multiparty/safemode/permission-requests';

@Injectable({
    providedIn: 'root',
})
export class SafemodeMultipartyAuthorizationService {
    constructor(
        private http: HttpClient,
        private cachedCurrentUserService: CachedCurrentUserService,
    ) {}

    createSafemodeRequest(
        resources: SafeModeResourceId[],
        details: SafeModeRequestDetails,
        executionTime: string,
        timezone: string,
    ): Observable<SafemodeChangeRequest> {
        return this.cachedCurrentUserService.get().pipe(
            take(1),
            switchMap(cu => {
                const request = {
                    orgId: cu.organization_id,
                    resources,
                    changeParameters: details,
                    executionTime,
                    timezone,
                };
                return this.http.post<SafemodeChangeRequest>(safemodePath, request).pipe(take(1));
            }),
        );
    }

    getSafemodeRequests(executionTimeAfter?: moment.Moment): Observable<PagedResult<SafemodeChangeRequest>> {
        let url = safemodePath;
        executionTimeAfter ? (url += '?executionTimeAfter=' + executionTimeAfter.toISOString()) : null;
        return this.http.get<PagedResult<SafemodeChangeRequest>>(url).pipe(
            take(1),
            map(response => ({
                ...response,
                content: response.content.map(this.formatSafemodeChangeRequest),
            })),
        );
    }

    isOrgCompliant(): Observable<OrgApproversDetails> {
        return this.http.get<OrgApproversDetails>(safemodeOrgCompliantPath).pipe(take(1));
    }

    getSafemodeApproverCandidate(mpaRequestId: number): Observable<PermissionRequest> {
        return this.http.get<PermissionRequest>(`${safemodeCandidatePath}/${mpaRequestId}`).pipe(take(1));
    }

    cancelSafemodeChangeRequest(safemodeRequestId: number, accessToken: string): Observable<void> {
        return this.http.delete<void>(`${safemodePath}/${safemodeRequestId}`, {
            headers: { 'Additional-Authorization': `Bearer ${accessToken}` },
        });
    }

    private formatSafemodeChangeRequest = (safemodeRequest: SafemodeChangeRequest): SafemodeChangeRequest => {
        return {
            ...safemodeRequest,
            executionTime: safemodeRequest.executionTime ? moment(safemodeRequest.executionTime) : null,
            createTime: safemodeRequest.createTime ? moment(safemodeRequest.createTime) : null,
            lastUpdateTime: safemodeRequest.lastUpdateTime ? moment(safemodeRequest.lastUpdateTime) : null,
        };
    };
}
