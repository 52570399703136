import stableStringify from 'json-stable-stringify';
import { IStateFilter } from '../redux/pure-redux.service';

export class Pill {
    pinned: boolean;
    pinnable = false;
    private _filters: IStateFilter[];
    private _fullKey: string;
    private _key: string;
    private _namespace: string;
    private keyLc: string;
    private nsLc: string;
    private isTag: boolean;
    private entityLc: string;
    private _entity: string;
    private _pillId: string;

    constructor(f: IStateFilter[], pinned = false) {
        this.pinned = pinned;
        this.keyLc = f[0].key.toLocaleLowerCase();
        this._key = f[0].key;
        this.nsLc = f[0].namespace ? f[0].namespace.toLocaleLowerCase() : null;
        this._namespace = f[0].namespace;
        this.isTag = f.some((filter: IStateFilter) => filter.namespace !== null && filter.namespace.length > 0);
        this.entityLc = f[0].entity?.toLocaleLowerCase();
        this._entity = f[0].entity;
        this._filters = [];
        f.forEach(filter => {
            if (
                this.keyLc === filter.key.toLocaleLowerCase() &&
                this.nsLc === (filter.namespace ? filter.namespace.toLocaleLowerCase() : null) &&
                this.entityLc === filter.entity?.toLocaleLowerCase()
            ) {
                this._filters.push(filter);
            } else {
                throw new Error('Incompatible filters');
            }
        });
    }

    key(): string {
        return this._key;
    }

    entity(): string {
        return this._entity;
    }

    namespace(): string {
        return this._namespace;
    }

    fullKey(): string {
        this._fullKey = this._fullKey || this.computeFullKey();
        return this._fullKey;
    }

    // unique pill id that does not change value between pinned states for
    // track by in the GSB
    pillId(): string {
        if (!this._pillId) {
            const filterContents = this._filters.map(f => f.value).join('-');
            const idObj = {
                k: this.keyLc,
                n: this.nsLc,
                e: this.entityLc,
                f: filterContents,
            };
            this._pillId = stableStringify(idObj);
        }
        return this._pillId;
    }

    filters(): IStateFilter[] {
        return this._filters;
    }

    merge(pill: Pill): this {
        if (this.fullKey() !== pill.fullKey()) {
            throw new Error(`Cannot merge pills with different full keys: ${this.fullKey()} and ${pill.fullKey()}`);
        }

        // Code in O(n^2) Could be optimized to run in O(n)
        // Not sure it's needed because the number of filters should be really small.
        const addedFilters = pill
            .filters()
            .filter(pf => !this._filters.find(f => f.value.toLocaleLowerCase() === pf.value.toLocaleLowerCase()));
        this._filters = this._filters.concat(addedFilters).sort((a, b) => a.value.localeCompare(b.value));
        return this;
    }

    private computeFullKey(): string {
        const keyObj = {
            k: this.keyLc,
            t: this.isTag, // TODO: Try-out merge w/ fullKey once multi-namespace tags are working
            e: this.entityLc,
            p: this.pinned,
        };
        return stableStringify(keyObj);
    }
}
