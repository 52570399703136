import { CachedCurrentUserService } from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnInit,
    Inject,
    Renderer2,
    OnDestroy,
} from '@angular/core';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { GOOGLE_MAPS } from '../../../app/injection-tokens';
import { PlottedArray } from '../arrays-map-view/arrays-map-view.component';
import { GoogleMapsService, Panorama } from '../../../services/google-maps.service';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { Subject } from 'rxjs';

type GoogleMaps = typeof google.maps;

const STREETVIEW_CLASS = 'address-street-view';

@Component({
    selector: 'contacts-array-list',
    templateUrl: './contacts-array-list.component.html',
})
export class ContactsArrayListComponent implements OnInit, OnDestroy {
    private static observer: IntersectionObserver;

    @Input() readonly arrayList: PlottedArray[] = [];
    @Input() readonly disableEditAll: boolean;
    @Output() readonly arraySelect = new EventEmitter<PlottedArray>();
    @Output() readonly arrayDeselect = new EventEmitter<PlottedArray>();
    @Output() readonly editAll = new EventEmitter<PlottedArray[]>();

    @ViewChild('streetView', { static: true }) streetView: ElementRef;
    areAllArraysInOrg: boolean;
    observer: IntersectionObserver;
    panorama: Panorama;
    selectedArrays = new Set<PlottedArray>();
    streetViewFound = false;
    streetViewService: google.maps.StreetViewService;
    userOrgId: string;
    canViewArray = false;

    // Analytics
    readonly analyticsPrefix = 'Map View - ';

    readonly destroy$ = new Subject<void>();

    private googleMaps: GoogleMaps;

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private angulartics2: Angulartics2,
        private googleMapsService: GoogleMapsService,
        private renderer: Renderer2,
        private authzServiceResolver: AuthorizationServiceResolver,
        @Inject(GOOGLE_MAPS) private googleMapsPromise: Promise<GoogleMaps>,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(cu => {
                this.userOrgId = String(cu.organization_id);
                this.areAllArraysInOrg = this.arrayList.every(array => array.organization.id === this.userOrgId);
            });
        this.authzServiceResolver
            .getDefaultService()
            .pipe(
                switchMap(service => service.hasPermission('PURE1:view:array')),
                takeUntil(this.destroy$),
            )
            .subscribe(authorized => {
                this.canViewArray = authorized;
            });
        this.googleMapsPromise.then(map => {
            this.googleMaps = map;
            this.streetViewService = new this.googleMaps.StreetViewService();

            this.observer = new IntersectionObserver(entries => {
                this.checkViewportIntersection(entries);
            });
            this.observer.observe(this.streetView.nativeElement);
        });
    }

    ngOnDestroy(): void {
        if (this.panorama && !this.hasPanoramaBeenClaimed()) {
            this.renderer.removeChild(this.streetView.nativeElement, this.panorama.div);
            this.renderer.removeClass(this.panorama.div, STREETVIEW_CLASS);
            this.googleMapsService.releasePanorama(this.panorama);
        }
        if (this.observer) {
            this.observer.unobserve(this.streetView.nativeElement);
        }

        this.destroy$.next();
        this.destroy$.complete();
    }

    checkViewportIntersection(entries: IntersectionObserverEntry[]): void {
        entries.forEach(entry => {
            if (entry.isIntersecting && entry.target === this.streetView.nativeElement) {
                if (!this.panorama || this.hasPanoramaBeenClaimed()) {
                    // Should be of type StreetViewPanoramaOptions, but @types/googlemaps library hasn't been updated with the
                    // showRoadLabels field. The field is documented here:
                    // https://developers.google.com/maps/documentation/javascript/reference/street-view#StreetViewPanoramaOptions
                    const options = {
                        addressControl: false,
                        clickToGo: false,
                        disableDefaultUI: true,
                        panControl: false,
                        scrollwheel: false,
                        showRoadLabels: false,
                    };
                    this.panorama = this.googleMapsService.getPanorama(options);
                    if (this.panorama) {
                        this.renderer.appendChild(this.streetView.nativeElement, this.panorama.div);
                        this.renderer.addClass(this.panorama.div, STREETVIEW_CLASS);
                        this.updatePanorama();
                    }
                }
            }
        });
    }

    updatePanorama(): void {
        if (this.arrayList && this.arrayList.length > 0) {
            const geolocation = this.arrayList[0].address.geolocation;
            const location = new this.googleMaps.LatLng(geolocation.latitude, geolocation.longitude);
            const locationRequest: google.maps.StreetViewLocationRequest = {
                location: location,
                source: this.googleMaps.StreetViewSource.OUTDOOR,
            };
            this.streetViewService.getPanorama(locationRequest, panoData => {
                if (panoData) {
                    // Compute which direction to look
                    const heading = this.googleMaps.geometry.spherical.computeHeading(
                        panoData.location.latLng,
                        location,
                    );
                    const pov = this.panorama.view.getPov();
                    pov.heading = heading;
                    this.panorama.view.setPov(pov);
                    this.panorama.view.setPosition(location);
                    this.panorama.view.setVisible(true);
                    this.streetViewFound = true;
                } else {
                    this.panorama.view.setVisible(false);
                    this.streetViewFound = false;
                }

                this.angulartics2.eventTrack.next({
                    action: this.analyticsPrefix + 'Google API calls',
                    properties: {
                        category: 'Action',
                        label: 'streetview changed',
                    },
                });
            });
        } else {
            this.panorama.view.setVisible(false);
        }
    }

    arrayClicked(array: PlottedArray): void {
        if (!this.canViewArray || array.organization.id !== this.userOrgId) {
            return;
        }

        if (this.selectedArrays.has(array)) {
            this.selectedArrays.delete(array);
            this.arrayDeselect.emit(array);
        } else {
            this.selectedArrays.add(array);
            this.arraySelect.emit(array);
        }
    }

    editAllClicked(): void {
        if (!this.canViewArray || !this.areAllArraysInOrg || this.disableEditAll) {
            return;
        }
        this.editAll.emit(Array.from(this.arrayList));
    }

    hasPanoramaBeenClaimed(): boolean {
        return this.streetView.nativeElement !== this.panorama.div.parentElement;
    }

    openArrayTooltip(tooltip: NgbTooltip, array: PlottedArray): void {
        let tooltipMessage: string;

        if (!this.canViewArray) {
            tooltipMessage = 'Only administrators can edit the address of an array';
        } else if (array.organization.id !== this.userOrgId) {
            tooltipMessage = 'This array is not a part of your organization';
        }

        if (tooltipMessage) {
            tooltip.open({
                message: tooltipMessage,
            });
        }
    }

    openEditAllTooltip(tooltip: NgbTooltip): void {
        let tooltipMessage: string;

        if (!this.canViewArray) {
            tooltipMessage = 'Only administrators can edit this address';
        } else if (!this.areAllArraysInOrg) {
            tooltipMessage = 'Some arrays are not a part of your organization';
        } else if (this.disableEditAll) {
            tooltipMessage = 'Cannot edit data center address while individual arrays are selected';
        } else {
            tooltipMessage = 'Edit data center address';
        }

        tooltip.open({
            message: tooltipMessage,
        });
    }
}
