import moment, { DurationInputArg2 } from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

const LONG_LABELS: DurationInputArg2[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];
const SHORT_LABELS = ['yr', 'mo', 'w', 'd', 'hr', 'min', 's'];

/**
 * Formats a duration of time represented in ms to a human readable format
 * at the precision specified.
 * eg: 267023000 | formatDuration => "3 days"
 *     267023000 | formatDuration: 3 => "3 days 2 hours 10 minutes"
 *  also, it can be configured to return a more compact version.
 * eg: 267023000 | formatDuration: 3: true => "3d 2hr 10min"
 */
@Pipe({ name: 'formatDuration' })
export class FormatDurationPipe implements PipeTransform {
    transform(value: number | moment.Duration, precision = 1, shortForm = false, startingPeriodIndex = 0): string {
        if (value === null || isNaN(value as any) || precision === 0) {
            return '–';
        }
        if (value === 0) {
            return shortForm ? '0min' : '0 minutes';
        }
        if (<number>value < moment.duration(1, 'second').asMilliseconds()) {
            return shortForm ? '1s' : '1 second';
        }

        const duration = moment.duration(value);
        const valueFuncs: ((duration: moment.Duration) => number)[] = [
            duration => duration.asYears(),
            duration => duration.asMonths(),
            duration => duration.asWeeks(),
            duration => duration.asDays(),
            duration => duration.asHours(),
            duration => duration.asMinutes(),
            duration => duration.asSeconds(),
        ];
        const labels = shortForm ? SHORT_LABELS : LONG_LABELS;

        let dateString = '';
        let currentPrecision = 0;
        const validatedStartingPeriodIndex =
            !isNaN(startingPeriodIndex) && 0 <= startingPeriodIndex && startingPeriodIndex < valueFuncs.length
                ? startingPeriodIndex
                : 0;
        for (let i = validatedStartingPeriodIndex; i < labels.length; i++) {
            const amount = Math.floor(valueFuncs[i](duration));
            duration.subtract(moment.duration(amount, LONG_LABELS[i]).asMilliseconds());
            if (amount > 0) {
                dateString += `${amount}${shortForm ? '' : ' '}${labels[i]}${!shortForm && amount > 1 ? 's' : ''} `;
                currentPrecision++;
            }
            if (currentPrecision === precision) {
                return dateString.trim();
            }
        }

        return dateString.trim();
    }
}
