import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CurrentUser } from '@pure1/data';

@Component({
    selector: 'um-sso-user-form',
    templateUrl: './um-sso-user-form.component.html',
})
export class UMSsoUserFormComponent implements OnInit {
    @Input() readonly currentUser: CurrentUser;

    @Output() readonly cancelled = new EventEmitter<void>();

    ssoUserForm: UntypedFormGroup;

    readonly hiddenField = '----';

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.ssoUserForm = this.fb.group({
            email: [{ value: this.currentUser.email, disabled: true }],
            firstName: [{ value: this.hideWhileImpersonating(this.currentUser.firstName), disabled: true }],
            lastName: [{ value: this.hideWhileImpersonating(this.currentUser.lastName), disabled: true }],
        });
    }

    cancel(): void {
        this.cancelled.emit();
    }

    hideWhileImpersonating(displayValue: string): string {
        if (this.currentUser.impersonating) {
            return this.hiddenField;
        }
        return displayValue;
    }
}
