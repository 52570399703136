import { Observable, ReplaySubject } from 'rxjs';
import { Injectable, TemplateRef } from '@angular/core';

export enum ToastType {
    success,
    info,
    error,
}

export class Toast {
    constructor(
        public readonly id: number,
        public readonly text: string | TemplateRef<any> | KeepHtml,
        public readonly type: ToastType,
    ) {}
}

export class KeepHtml {
    constructor(public readonly html: string) {}
}

/**
 * Service used to add/remove toasts. This itself does not track toasts, but instead raises events
 * for toasts to be handled on other components (like the pure-toast component).
 */
@Injectable({ providedIn: 'root' })
export class ToastService {
    private _nextId = 1;

    private readonly _add$ = new ReplaySubject<Toast>(1);

    private readonly _remove$ = new ReplaySubject<Toast>(1);

    private readonly _clear$ = new ReplaySubject<void>(1);

    get add$(): Observable<Toast> {
        return this._add$;
    }

    get remove$(): Observable<Toast> {
        return this._remove$;
    }

    get clear$(): Observable<void> {
        return this._clear$;
    }

    add(type: ToastType, text: string | TemplateRef<any> | KeepHtml): void {
        const toast = new Toast(this._nextId++, text, type);
        this._add$.next(toast);
    }

    remove(toast: Toast): void {
        this._remove$.next(toast);
    }

    /**
     * Note: Currently no plans to use this. May use it later, eg to clear toasts between page changes.
     */
    clear(): void {
        this._clear$.next();
    }
}
