import { PureArray } from './PureArray';
import { FBladeCapacityData } from './FBladeCapacityData';

const NA = '–';

export class FBlade extends PureArray {
    capacityData: FBladeCapacityData;

    constructor(json: any) {
        super(json);

        if (this.isCurrent) {
            this.version = !json.version ? NA : json.version;
            this.capacityData = new FBladeCapacityData(json.purearray_list_space);
        }
    }
}
