import { Input, Component } from '@angular/core';
import { Router } from '@angular/router';

import { ISubmenuBarItem, SubmenuBarBaseComponent } from './submenu-bar-base.component';

@Component({
    selector: 'submenu-bar',
    templateUrl: 'submenu-bar.component.html',
})
export class SubmenuBarComponent extends SubmenuBarBaseComponent {
    @Input() readonly submenuItems: ISubmenuBarItem[];

    constructor(router: Router) {
        super(router);
    }
}
