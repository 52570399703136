import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Tag } from '../models/tag';
import { DataPage } from '../interfaces/data-page';
import { ListParams, FilterParams } from '../interfaces/list-params';
import { buildUrlFilterStringForV3Endpoint, quoteAndEscape } from '../utils/index';

const ENDPOINT = '/rest/v3/arrays/tags';
const ARRAY_ID_FILTER_SEPARATOR = `','`;

@Injectable({ providedIn: 'root' })
export class ArrayTagService {
    constructor(private http: HttpClient) {}

    list(params: ListParams<Tag>): Observable<DataPage<Tag>> {
        // ids param is not yet implemented in backend API, must rely on filtering for now
        // const idsQueryParam = params && params.ids && params.ids.length > 0
        //     ? '?ids=' + params.ids.join(',')
        //     : '';
        let filterQueryParam = this.buildArraysFilterParamForPureArrays(params.filter);
        if (filterQueryParam) {
            filterQueryParam = '?filter=' + encodeURIComponent(filterQueryParam);
        }

        const url = ENDPOINT + filterQueryParam;

        return this.http.get(url, { observe: 'response' }).pipe(map(response => this.makeDataPage(response.body)));
    }

    upsert(ids: string[], tag: Tag): Observable<Tag> {
        const tags = {
            add_tags: [tag],
        };

        const params = `array_id=('${ids.join(ARRAY_ID_FILTER_SEPARATOR)}')`;
        const url = ENDPOINT + `?filter=${encodeURIComponent(params)}`;

        return this.http.patch(url, tags).pipe(map(() => void 0)); // PATCH returns empty response from backend currently
    }

    delete(ids: string[], namespace: string, key: string): Observable<void> {
        const params = `array_id=('${ids.join(ARRAY_ID_FILTER_SEPARATOR)}') and namespace='${namespace}' and key=${quoteAndEscape(key)}`;
        const url = ENDPOINT + `?filter=${encodeURIComponent(params)}`;

        return this.http.delete(url).pipe(map(() => void 0)); // Discard result
    }

    private makeDataPage(wrapper: any): DataPage<Tag> {
        const resources = wrapper.items.map(resource => new Tag(resource));
        return {
            total: wrapper.total_item_count,
            response: resources,
        };
    }

    // (copied from Arrays Manager)
    private buildArraysFilterParamForPureArrays(filter: FilterParams<Tag>): string {
        const paramsWrapWildcard: string[] = [
            'array_name',
            'host_iqn_wwn_list',
            'target_iqn_wwn_list',
            'host_hostname_list',
        ];
        const paramsTags: string[] = ['tags'];

        return buildUrlFilterStringForV3Endpoint(filter, {
            paramsWrapWildcard: paramsWrapWildcard,
            paramsTags: paramsTags,
        });
    }
}
