<ng-container *ngIf="slideTemplates?.length > 1; else singleBanner">
    <div class="carousel-container">
        <pureui-svg
            *ngIf="showPureNavigationArrows"
            class="carousel-control-icon carousel-left svg-hover-dropshadow"
            svg="chevron-left.svg"
            height="20"
            (click)="carousel.prev()"
        ></pureui-svg>
        <pureui-svg
            *ngIf="showPureNavigationArrows"
            class="carousel-control-icon carousel-right svg-hover-dropshadow"
            svg="chevron-right.svg"
            height="20"
            (click)="carousel.next()"
        ></pureui-svg>
        <ngb-carousel #carousel (slide)="onCarouselSlideChange($event)">
            <ng-template *ngFor="let slideTemplate of slideTemplates" ngbSlide>
                <ng-container *ngTemplateOutlet="slideTemplate"></ng-container>
            </ng-template>
        </ngb-carousel>
    </div>
</ng-container>

<ng-template #singleBanner>
    <ng-container *ngIf="slideTemplates?.length === 1">
        <div class="single-template-container">
            <ng-container *ngTemplateOutlet="slideTemplates.first"></ng-container>
        </div>
    </ng-container>
</ng-template>
