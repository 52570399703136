<div *ngIf="slaIndicator; else noSlaIndicator">
    <sla-status-icon
        *ngIf="statusText"
        [status]="slaIndicator.status"
        [height]="13"
        [startDate]="summary?.startDate"
        [endDate]="summary?.endDate"
    >
    </sla-status-icon>
    <span class="status-label">{{ statusText }}</span>
    <svg
        *ngIf="ineligibleMessage"
        [height]="13"
        hiveTooltip
        [hiveTooltipDescription]="ineligibleMessage"
        hive-svg="info-circle.svg"
    ></svg>
</div>
<ng-template #noSlaIndicator>–</ng-template>
