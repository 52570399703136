<pureui-spinner class="p-loading" [pureuiShow]="mapLoadingStatus === 'loading'"></pureui-spinner>
<div class="map-unavailable" *ngIf="mapLoadingStatus === 'err'">Cannot load Google Maps</div>
<div #mapElement class="map-element"></div>
<div id="top-section" #topSection></div>
<contacts-drawer
    [arrayLists]="selectedLocation.arrayLists"
    [drawerOpened]="drawerOpened"
    (drawerClose)="closeDrawer()"
    (locationChange)="onLocationChange($event)"
    #drawer
></contacts-drawer>
