import { Input, Output, Component, HostListener, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

/**
 * Describes the drop-down options for the export button.
 */
export interface IExportButtonOption {
    /** Unique option id for this button */
    id: number;

    /** Text to display */
    text: string;

    /** If not null, displays the number of entities that this option will export (affects display only) */
    count: number | null;

    /** If true, this option will be disabled */
    disabled?: boolean;
}

export enum ExportOptionIds {
    selected = 0,
    filtered = 1,
    all = 2,
}

@Component({
    selector: 'export-button',
    templateUrl: 'export-button.component.html',
})
export class ExportButtonComponent {
    @Input() readonly exportOptions?: IExportButtonOption[];
    @Input() readonly analyticsPrefix: string;
    @Input() readonly useSpinner: boolean = false; // indicate if we use the spinner when fetching export option counts
    @Input() readonly showText: boolean = true; // indicate if we show the "Export" text or only the icon
    @Input() readonly useRightPosition: boolean = false; // Indicate whether the export button is positioned at the right edge of the screen
    @Input() readonly showNewIcon: boolean = false; //indicate whether we show the new export icon (chevron-down-icon.svg) or old export icon (export.svg). The default icon will be export.svg

    @Output() readonly onClickExport: EventEmitter<{ option: IExportButtonOption }> = new EventEmitter();
    @Output() readonly updateCounts: EventEmitter<{ optionsMap: Map<number, IExportButtonOption> }> =
        new EventEmitter();

    @ViewChild('exportButton') readonly exportButton: ElementRef;

    isDropdownOpen = false;

    constructor(private angulartics2: Angulartics2) {}

    useDropdown(): boolean {
        return this.exportOptions && this.exportOptions.length > 0;
    }

    clickButton(event: MouseEvent): void {
        if (this.useDropdown()) {
            this.isDropdownOpen = !this.isDropdownOpen;

            if (this.isDropdownOpen) {
                this.updateCounts.emit({ optionsMap: this.createOptionsMap() });
            }
        } else {
            this.onClickExport.emit({ option: null });
        }

        this.angulartics2.eventTrack.next({
            action: this.analyticsPrefix + 'Export Button - Click',
            properties: { category: 'Action' },
        });

        // Don't bubble event to prevent it going to the documentClickHandler and immediately closing
        event.stopPropagation();
    }

    clickOption(option: IExportButtonOption, event: MouseEvent): void {
        if (!option.disabled) {
            this.onClickExport.emit({ option: option });
            this.isDropdownOpen = false;
            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Export Button - Click option',
                properties: { category: 'Action', label: option.text },
            });
        }

        // Don't bubble to documentClickHandler. We'll close the dropdown ourselves.
        event.stopPropagation();
    }

    @HostListener('document:click', ['$event'])
    documentClickHandler(event: MouseEvent): void {
        if (event.target && this.isDropdownOpen && !this.exportButton.nativeElement.contains(event.target)) {
            this.isDropdownOpen = false;
        }
    }

    private createOptionsMap(): Map<number, IExportButtonOption> {
        const map = new Map<number, IExportButtonOption>();
        this.exportOptions.forEach(option => {
            map.set(option.id, option);
        });
        return map;
    }
}
