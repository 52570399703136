import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse, Resource, Collection, DataPage } from '@pure1/data';

export const SIMULATIONS_URL = '/rest/v1/forecast/simulations';

const QUERY_PARAM_IDS = 'ids';

export class ForecastSimulation implements Resource {
    id: string;
    name: string;
    training_time_period: number;

    static fromJson(json: any): ForecastSimulation {
        return new ForecastSimulation(json.id, json.name, json.training_time_period);
    }

    constructor(id: string, name: string, training_time_period: number) {
        this.id = id;
        this.name = name;
        this.training_time_period = training_time_period;
    }
}

@Injectable({ providedIn: 'root' })
export class SimulationService implements Collection<ForecastSimulation> {
    constructor(private http: HttpClient) {}

    list(): Observable<DataPage<ForecastSimulation>> {
        return this.http.get<IRestResponse<any>>(SIMULATIONS_URL).pipe(
            map(response => {
                return {
                    total: response.total_item_count,
                    response: response.items.map(item => {
                        return ForecastSimulation.fromJson(item);
                    }),
                };
            }),
        );
    }

    create(simulation: Partial<ForecastSimulation>): Observable<ForecastSimulation> {
        return this.http.post<IRestResponse<any>>(SIMULATIONS_URL, simulation).pipe(
            map(response => {
                if (response.total_item_count === 1) {
                    return ForecastSimulation.fromJson(response.items[0]);
                }
            }),
        );
    }

    update(simulation: Partial<ForecastSimulation>): Observable<DataPage<ForecastSimulation>> {
        return this.http.patch<IRestResponse<any>>(SIMULATIONS_URL, simulation).pipe(
            map(() => {
                return undefined;
            }),
        );
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(SIMULATIONS_URL, { params: new HttpParams().set(QUERY_PARAM_IDS, id) });
    }
}
