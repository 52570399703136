import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { createSortQueryString, formUrlEncodedRequestTransformer } from '../utils';
import { Collection } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';
import { ListParams, FilterParams } from '../interfaces/list-params';
import { PGroupSnapshot } from '../models/pgroup-snapshot';

const ENDPOINT = '/rest/v3/protection-group-snapshots';

const EVENT_BATCH_LIMIT = 1000;

@Injectable({ providedIn: 'root' })
export class PGroupSnapshotService implements Collection<PGroupSnapshot> {
    constructor(private http: HttpClient) {}

    // refresh of pgroup snapshots relies on refresh of pgroups...
    list(params: ListParams<PGroupSnapshot>): Observable<DataPage<PGroupSnapshot>> {
        const queryParams: string[] = [];
        if (params) {
            if (params.extra) {
                queryParams.push(params.extra);
            }
            if (params.sort) {
                queryParams.push(`sort=${createSortQueryString(params.sort)}`);
            }
        }

        const filterParam = params && params.filter && this.makeFilterQueryParam(params.filter);
        const pageSizeRemain = params && params.pageSize ? params.pageSize : EVENT_BATCH_LIMIT;
        const pageStart = params && params.pageStart ? params.pageStart : 0;

        return Observable.create(observer =>
            this.listAll(observer, queryParams, filterParam, pageSizeRemain, pageStart, []),
        );
    }

    create(properties: Partial<PGroupSnapshot>): Observable<PGroupSnapshot> {
        throw new Error('Not Supported');
    }

    update(properties: Partial<PGroupSnapshot>): Observable<DataPage<PGroupSnapshot>> {
        throw new Error('Not Supported');
    }

    delete(id: string): Observable<void> {
        throw new Error('Not Supported');
    }

    // list all the snapshots, here the pageSizeRemain may be greater than the EVENT_BATCH_LIMIT.
    // In that case, the result will be combining multiple http calls
    private listAll(
        observer: Observer<DataPage<PGroupSnapshot>>,
        queryParams: string[],
        filterParam: string,
        pageSizeRemain: number,
        pageStart: number,
        data: PGroupSnapshot[],
    ): void {
        const params = queryParams.slice(0);
        const pageSize = Math.min(pageSizeRemain, EVENT_BATCH_LIMIT);
        params.push(`limit=${pageSize}`);
        params.push(`start=${pageStart}`);

        // NOTE: We have to make a POST because the filter parameter can get very long
        const url = ENDPOINT + (params.length > 0 ? `?${params.join('&')}` : '');

        const body = formUrlEncodedRequestTransformer({ filter: filterParam });

        this.http
            .post(url, body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, observe: 'response' })
            .pipe(
                map(response =>
                    this.makeDataPage(
                        observer,
                        queryParams,
                        filterParam,
                        pageSizeRemain - pageSize,
                        pageStart + pageSize,
                        response.body,
                        data,
                    ),
                ),
            )
            .subscribe();
    }

    private makeDataPage(
        observer: Observer<DataPage<PGroupSnapshot>>,
        queryParams: string[],
        filterParam: string,
        pageSizeRemain: number,
        pageStart: number,
        wrapper: any,
        data: PGroupSnapshot[],
    ): void {
        const snapshots = wrapper.items.map(jsonSnapshot => PGroupSnapshot.fromJson(jsonSnapshot));
        data = data.concat(snapshots);
        // return the result, either no more page is needed or reach the end of it.
        if (pageSizeRemain === 0 || wrapper.total <= pageStart) {
            observer.next({
                total: wrapper.total,
                response: data,
            });
        } else {
            // not done yet, keep getting the rest...
            this.listAll(observer, queryParams, filterParam, pageSizeRemain, pageStart, data);
        }
    }

    private makeFilterQueryParam(filter: FilterParams<PGroupSnapshot>): string {
        return Object.keys(filter)
            .map(k => `${k}=(${filter[k]})`)
            .join(' and ');
    }
}
