<div class="message-banner" *ngIf="infoMessage">
    {{ infoMessage }}
</div>
<div class="license-header-content">
    <section class="description-section">
        <h4 class="section-title"> License Description </h4>
        <div class="section-value">
            {{ license.name | defaultString }}
        </div>
    </section>
    <section class="tier-section">
        <h4 class="section-title"> Service Level </h4>
        <!-- this feature is temporarily cut, but will likely be added in a PI or two -->
        <ng-container *ngIf="isQuoteRenewal && false; else notRenewalQuote">
            <span class="license-type">
                <ng-select
                    class="left-actions"
                    bindLabel="label"
                    bindValue="licenseType"
                    groupBy="group"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="licenseTypes"
                    [ngModel]="license.licenseType"
                    (ngModelChange)="onLicenseTypeChange($event)"
                    [disabled]="!licenseTypes || licenseTypes.length <= 1"
                    angulartics2On="change"
                    angularticsCategory="Action"
                    angularticsAction="Subscription Viewer - Update License Service Level Selection"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <pureui-svg
                            class="pstg-secondary-icon license-type-icon"
                            [svg]="item.icon"
                            height="14"
                        ></pureui-svg>
                        {{ item.licenseType }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <pureui-svg
                            class="pstg-secondary-icon license-type-icon"
                            [svg]="item.icon"
                            height="14"
                        ></pureui-svg>
                        {{ item.label }}
                    </ng-template>
                </ng-select>
            </span>
        </ng-container>
        <ng-template #notRenewalQuote>
            <div class="section-value">
                <ng-container *ngIf="isServiceTierSupported(); else noServiceTier">
                    <span>{{ license.licenseType | defaultString }}</span>
                </ng-container>
                <ng-template #noServiceTier>
                    <span>{{ migrateToServiceTier(license.licenseType) | defaultString }}</span>
                </ng-template>
            </div>
        </ng-template>
    </section>
    <section class="usage-section">
        <h4 class="section-title"> Current Usage </h4>
        <div class="section-value">
            <license-usage
                [reservedAmount]="license.reservedAmount"
                [currentAmount]="license.licenseUsage.current"
                [licenseType]="license.licenseType"
            ></license-usage>
        </div>
    </section>
</div>
<ng-container *ngIf="subscription?.isPoc; else notPocSubscription">
    <h4 class="reserved-amount-title">Set Reserved</h4>
    <div class="license-content">
        <section class="reserved-section">
            <license-reserved-amount
                [licenseType]="license.licenseType"
                [increaseAmount]="license.reservedAmount"
                [subscription]="subscription"
                (increaseAmountChange)="amountChanged($event)"
            >
            </license-reserved-amount>
        </section>
    </div>
</ng-container>
<div class="existing-license-expansion-datepicker-container" *ngIf="!isQuoteRenewal">
    <span class="existing-license-expansion-datepicker-label">Requested Activation Date</span>
    <pureui-svg
        svg="info-circle.svg"
        [height]="13"
        class="pstg-action-icon header-info-icon"
        ngbTooltip="Select the date you would like the capacity available for consumption."
        placement="right"
        container="body"
    >
    </pureui-svg>
    <input
        class="form-control"
        placeholder="Choose a date"
        readonly="readonly"
        name="dp"
        [ngModel]="existingLicenseChangeDateModel"
        ngbDatepicker
        #existingLicensesExpansionsDatePicker="ngbDatepicker"
        (click)="existingLicensesExpansionsDatePicker.toggle()"
        (ngModelChange)="onLicenseStartDateChange($event)"
    />
</div>
<ng-template #notPocSubscription>
    <h4 class="reserved-amount-title">Increase Reserved</h4>
    <div class="license-content">
        <section class="reserved-section">
            <license-reserved-amount
                [licenseType]="license.licenseType"
                [currentAmount]="license.reservedAmount"
                [increaseAmount]="increaseAmount"
                [subscription]="subscription"
                (increaseAmountChange)="amountChanged($event)"
            >
            </license-reserved-amount>
        </section>
        <section class="expected-usage-section usage-section">
            <h4 class="section-title"> Expected Usage </h4>
            <div class="section-value">
                <license-usage
                    [reservedAmount]="license.reservedAmount + increaseAmount"
                    [currentAmount]="license.licenseUsage.current"
                    [licenseType]="license.licenseType"
                ></license-usage>
            </div>
        </section>
    </div>
</ng-template>
