import { Observable, ConnectableObservable } from 'rxjs';
import { take, publishLast } from 'rxjs/operators';
import {
    Component,
    ElementRef,
    Renderer2,
    Input,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    ViewChild,
    HostBinding,
    ChangeDetectionStrategy,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

/** Cache of the aspect ratios, keyed by svg name. */
const aspectRatioCache = new Map<string, number>();

const svgCache = new Map<string, Observable<string>>();

/** Regex used to parse the width and height from the viewbox attribute. Matches the last two numbers from the string. */
const svgRegex = /viewBox="(.*?)"/;
const viewBoxRegex = /\s+([0-9\.]+)\s+([0-9\.]+)\s*$/;

export const getSvgUrl = (svgName: string): string => `/images/${svgName}`;

export const getSvg = (svgUrl: string, http: HttpClient): Observable<string> => {
    if (!svgCache.has(svgUrl)) {
        const connectable = http
            .get(svgUrl, { responseType: 'text' })
            .pipe(publishLast()) as ConnectableObservable<string>;
        svgCache.set(svgUrl, connectable);
        connectable.connect();
    }

    return svgCache.get(svgUrl).pipe(take(1));
};

/**
 * Use this in place of ng-include to include an svg. Pass in the dimension to base the size off of (width or height), and
 * this directive will set the other dimension accordingly onto the element's style so IE will render at the correct size.
 */
@Component({
    selector: 'pureui-svg',
    template: '<span style="display: inline-block;" #pureSvg></span>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PureSvgComponent implements AfterViewInit, OnChanges {
    @Input() readonly svg: string;
    @Input() @HostBinding('style.width.px') readonly width: number | string; // Will be string if not using [] binding when passing in a number, which is fine
    @Input() @HostBinding('style.height.px') readonly height: number | string;
    @ViewChild('pureSvg', { static: true }) readonly pureSvg: ElementRef<HTMLSpanElement>;

    private span: HTMLSpanElement;

    constructor(
        private renderer: Renderer2,
        private http: HttpClient,
    ) {}

    ngAfterViewInit(): void {
        this.span = this.pureSvg.nativeElement;
        this.toSvg();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.span && changes.svg.currentValue) {
            this.toSvg();
        }
    }

    /**
     * create svg element, remove old one if it already exists
     */
    private toSvg(): void {
        // Validate arguments
        if (!(<number>this.width > 0 || <number>this.height > 0)) {
            this.raiseError(`Must specify either a width or height`);
            return;
        }

        // Create SVG element. For simplicity, we just grab from http directly instead of trying to
        // simulate the behavior of ng-include.
        if (!this.svg) {
            this.span.innerHTML = '';
            return;
        }

        const svgUrl = getSvgUrl(this.svg);

        getSvg(svgUrl, this.http).subscribe({
            next: (svgTemplateHtml: string) => {
                if (!svgTemplateHtml) {
                    this.raiseError(`Could not find svg url: ${svgUrl}`);
                    return;
                }

                if (!this.span) {
                    console.warn('pure-svg: span got destroyed', this.svg);
                    return;
                }
                // Compute missing dimension
                const aspectRatio = this.getAspectRatio(this.svg, svgTemplateHtml);
                const computedWidth = <number>this.width > 0 ? this.width : Number(this.height) * aspectRatio;
                const computedHeight = <number>this.height > 0 ? this.height : Number(this.width) / aspectRatio;

                // Set dimensions on the root element and append the SVG
                this.span.innerHTML = '';
                this.renderer.setStyle(this.span, 'width', computedWidth + 'px');
                this.renderer.setStyle(this.span, 'height', computedHeight + 'px');
                this.span.insertAdjacentHTML('beforeend', svgTemplateHtml);
            },
            error: (error: any) => {
                this.raiseError(`Failed to load svg ${svgUrl}`, error);
            },
        });
    }

    private raiseError(errorMessage: string, error?: any): void {
        // Errors are currently just logged to the console. It is very unlikely a dev is going to miss an error since
        // the svg won't show and they should be looking at the console. If we were to throw an error, then it will
        // break the page in much worse ways than not showing the element.
        console.error('pure-svg: ' + errorMessage, this.pureSvg, error);
    }

    /**
     * Gets the aspect ratio (width / height) for an SVG.
     */
    private getAspectRatio(svgName: string, svgElem: string): number {
        // Get from cache
        let aspectRatio = aspectRatioCache.get(svgName);

        if (aspectRatio === undefined) {
            // Not in cache, so compute it from the SVG's viewBox
            const viewBoxResult = svgRegex.exec(svgElem);
            const viewBoxStr = String(viewBoxResult?.[1]);
            const regexResult = viewBoxRegex.exec(viewBoxStr);
            const width = Number(regexResult?.[1]);
            const height = Number(regexResult?.[2]);
            if (Number.isNaN(width) || Number.isNaN(height) || width <= 0 || height <= 0) {
                this.raiseError(
                    `Invalid viewBox attribute "${viewBoxStr}" (casing matters!). Width (${width}) and height (${height}) must be > 0.`,
                );
                return 1;
            }

            aspectRatio = width / height;
            aspectRatioCache.set(svgName, aspectRatio);
        }

        return aspectRatio;
    }
}
