import { Component, Input, OnChanges } from '@angular/core';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import { take } from 'rxjs';
import { FeatureFlagDxpService } from '../../../data';
import { SubscriptionGenealogy } from '../../../data/models/subscription-genealogy';
import { FeatureNames } from '../../../model/FeatureNames';
import { AssetManagementSummaryInfo } from '../../asset-management-summary-card/asset-management-summary-card.component';

export interface ApplianceGenealogyTotals {
    totalAppliancesInstalled: number;
    totalHardwareUpgrades: number;
    totalSoftwareUpgrades: number;
}

@Component({
    selector: 'subscription-genealogy-totals',
    templateUrl: './subscription-genealogy-totals.component.html',
})
export class SubscriptionGenealogyTotalsComponent implements OnChanges {
    @Input() readonly data: SubscriptionGenealogy[];

    summaries: AssetManagementSummaryInfo[] = [];
    isFollowupEnabled = false;

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnInit(): void {
        this.calculateSummaries();
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_FOLLOWUP)
            .pipe(take(1))
            .subscribe((feature: FeatureFlagStatus) => {
                this.isFollowupEnabled = feature?.enabled === true;
                if (this.isFollowupEnabled) {
                    this.calculateSummaries();
                }
            });
    }

    ngOnChanges(): void {
        this.calculateSummaries();
    }

    calculateSummaries(): void {
        const totalSubscriptions = this.data?.filter(subscription => subscription.startEvent != null).length;
        const totalRenewals = this.data?.reduce((total, subscription) => subscription.renewalEvents.length + total, 0);
        const totalExpired = this.data?.filter(subscription => subscription.expirationEvent != null).length;
        const totalTerminated = this.data?.filter(subscription => subscription.terminationEvent != null).length;
        const totalUpcomingRenewals = this.data?.filter(
            subscription => subscription.upcomingRenewalEvent != null,
        ).length;

        this.summaries = [
            {
                title: 'Total Subscriptions',
                svg: 'database.svg',
                value: totalSubscriptions,
            },
            {
                title: 'Total Renewals',
                svg: 'file-signature-yellow.svg',
                value: totalRenewals,
            },
            {
                title: 'Expired',
                svg: 'caution-orange.svg',
                value: totalExpired,
            },
            {
                title: 'Terminated',
                svg: 'cross-in-hexagon.svg',
                value: totalTerminated,
            },
            ...(this.isFollowupEnabled
                ? [
                      {
                          title: 'Upcoming Renewals',
                          svg: 'file-signature-yellow.svg',
                          value: totalUpcomingRenewals,
                      },
                  ]
                : []),
        ];
    }
}
