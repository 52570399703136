// All sorts of junk for working with the old Alerts interfaces/etc from old code
// Ideally, we'd be able to remove all of this in the future and use only the proper Resources (eg AlertResourceV2)

import { AlertResourceV2, AlertOrigin, AlertBody } from '@pure1/data';

import { FBladeAlert, FArrayAlert, CloudAlert, Alert } from '../model/model';
import { DpaAlert } from '../model/DpaAlert';

export interface IDisplayedAlertInfo {
    // check associated array to ensure alert is current
    isCurrent: boolean;
    isSuspended: boolean;
}

export interface IAlertBodyResource extends AlertBody, IDisplayedAlertInfo {
    // Keep track of the name and id for the alert (not normally in body)
    // to satisfy Resource constraint
    name: string;
    id: string;
}

/**
 * Caution: This method modifies the alertResourceV2.body properties as a side-effect! Use _.cloneDeep() for anything you pass into here!
 */
export function createAlertFromResourceV2(alertResourceV2: AlertResourceV2): IAlert | null {
    // AlertBody is kind of a mess since it has fields from all 4 alert types
    const alertBody = <any>alertResourceV2.body;
    alertBody.org_id = alertBody.org_id || alertResourceV2.tags.orgId;
    alertBody.current_severity = alertBody.current_severity || alertResourceV2.tags.current_severity;
    alertBody.state = alertBody.state || alertResourceV2.tags.status;
    alertBody.id = alertResourceV2.body.id || alertResourceV2.id;

    if (alertBody.origin === AlertOrigin.PURE1) {
        if (alertBody.product === 'dpa') {
            return new DpaAlert(alertBody);
        }
        return new CloudAlert(alertBody);
    }

    switch (alertBody.product) {
        case 'ir':
            return new FBladeAlert(alertBody);
        case 'os':
            return new FArrayAlert(alertBody);
        default:
            console.warn(
                `Unexpected alert type. Product: ${alertBody.product}. Raw alert (from api): ${JSON.stringify(alertResourceV2)}`,
            );
            return null;
    }
}

export function createAlertBodyResourceFromAlertResourceV2(alertResourceV2: AlertResourceV2): IAlertBodyResource {
    const alertBody = <IAlertBodyResource>alertResourceV2.body;
    alertBody.name = alertResourceV2.name || alertResourceV2.id;
    alertBody.id = alertResourceV2.body.id || alertResourceV2.id;

    return alertBody;
}
