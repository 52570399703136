import { DraasApiReport } from '@pure/paas-api-gateway-client-ts';

export type DisasterRecoveryReportType = 'ONE_FAILOVER_EXECUTION';

export class DisasterRecoveryReport {
    id: string;

    constructor(json: DraasApiReport) {
        this.id = json.id;
    }
}
