import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[blurOnClick]',
})
export class BlurOnClickDirective {
    constructor(private el: ElementRef) {}

    @HostListener('click', ['$event'])
    onClick(_$event: MouseEvent): void {
        this.el.nativeElement.blur();
    }
}
