import { DraasApiUpgradeExecutionStep } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryUpgradeExecutionStep {
    clusterId: string;
    upgradeExecutionId: string;
    order: number;
    status: 'NOT_STARTED' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED';
    description: string;

    constructor(json: DraasApiUpgradeExecutionStep) {
        this.clusterId = json.cluster_id;
        this.upgradeExecutionId = json.upgrade_execution_id;
        this.order = json.order;
        this.status = json.status;
        this.description = json.description;
    }
}
