import { Component, Input } from '@angular/core';
import moment from 'moment';
import { PureArray } from '../../../model/PureArray';

@Component({
    selector: 'product-section-array-contract-expiration',
    templateUrl: 'product-section-array-contract-expiration.component.html',
})
export class ProductSectionArrayContractExpirationComponent {
    @Input() product: PureArray;
    @Input() checkSupportContract: boolean;

    // TODO unify with upgrade-prechecks/pipes/moment-date-normalize pipe
    normalizeMoment(value: moment.Moment | Date | string | number | null | undefined): Date | string | number | null {
        if (moment.isMoment(value)) {
            return value.toDate();
        }
        return value;
    }
}
