<div class="reserve-amount">
    <span class="reserve-unit">
        {{ increaseAmountInUnit | number: '1.0' : 'en-US' }} {{ licenseType | licenseReservedUnit }}
    </span>
    <span class="pct-increased" *ngIf="!subscription?.isPoc">
        <ng-container>
            <span class="arrow-icon"> <i class="fa fa-arrow-up"></i> </span>
            <span>
                {{ increaseAmountRatio | formatPercent }}
            </span>
        </ng-container>
    </span>
</div>
