import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { DisplayedPartner, Partner, PartnersService, QuoteType, ServiceCatalogQuote, Subscription } from '@pure1/data';

@Injectable({ providedIn: 'root' })
export class PartnerInfoService {
    partnersInfos$: Subject<Partner[]> = new ReplaySubject(1);
    partnersInfos: Partner[];

    constructor(private partnersInfoService: PartnersService) {
        this.generatePartnerInfo();
    }

    generatePartnerInfo(): void {
        //list the valid partners info
        this.partnersInfoService
            .list()
            .pipe(take(1))
            .subscribe(dataPage => {
                this.partnersInfos = dataPage.response;
                this.partnersInfos$.next(this.partnersInfos);
            });
    }

    generateDisplayedPartnerInfo(pendingQuote: ServiceCatalogQuote, subscription: Subscription): DisplayedPartner {
        const quotePartner = this.getPartnerInfoFromQuote(pendingQuote);
        const subscriptionPartner = this.getPartnerInfoFromSubscription(subscription);

        // Need to generate partner info by priority
        if (quotePartner) {
            return quotePartner;
        } else if (subscriptionPartner) {
            return subscriptionPartner;
        } else if (this.partnersInfos) {
            // Get info from the list of the latest validated partners
            return new DisplayedPartner({
                account_name: this.partnersInfos[0].accountName,
                contact_name: this.partnersInfos[0].contactName,
                contact_email: this.partnersInfos[0].contactEmail,
            });
        }

        return new DisplayedPartner({});
    }

    getPartnerInfoFromQuote(pendingQuote: ServiceCatalogQuote): DisplayedPartner {
        //Generate the latest Partner info
        const partnerNameFromOrder = pendingQuote?.partnerAe?.name;
        const partnerOrgFromOrder = pendingQuote?.partnerOrgName;
        const partnerEmailFromOrder = pendingQuote?.partnerAe?.email;

        let displayedPartnerName: string;
        let displayedPartnerOrg: string;
        let displayedPartnerContact: string;

        if (this.isPartnerUpdated(pendingQuote)) {
            //If the partner is finalized already after Stage3 for Evergreen one and foundation and Stage 4 for Capex
            displayedPartnerName = partnerNameFromOrder;
            displayedPartnerOrg = partnerOrgFromOrder;
            displayedPartnerContact = partnerEmailFromOrder;
        } else if (pendingQuote?.partnerRequest?.contact_name || pendingQuote?.partnerRequest?.account_name) {
            //If customer already sent the partner change request
            displayedPartnerName = pendingQuote?.partnerRequest?.contact_name;
            displayedPartnerOrg = pendingQuote?.partnerRequest?.account_name;
            displayedPartnerContact = pendingQuote?.partnerRequest?.contact_email;
        } else if (partnerNameFromOrder?.length > 0 || partnerOrgFromOrder?.length > 0) {
            //If renewal salesflow is ready and has partner info
            displayedPartnerName = partnerNameFromOrder;
            displayedPartnerOrg = partnerOrgFromOrder;
            displayedPartnerContact = partnerEmailFromOrder;
        } else {
            return null;
        }

        return new DisplayedPartner({
            account_name: displayedPartnerOrg,
            contact_name: displayedPartnerName,
            contact_email: displayedPartnerContact,
        });
    }

    getPartnerInfoFromSubscription(subscription: Subscription): DisplayedPartner {
        if (subscription?.subscriptionPartnerOrg || subscription?.subscriptionBillingPartnerContactName) {
            // If current subscription got partner info value
            return new DisplayedPartner({
                account_name: subscription?.partnerAccountName,
                contact_name: subscription?.subscriptionBillingPartnerContactName,
                contact_email: subscription?.subscriptionBillingPartnerContactEmail,
            });
        }

        return null;
    }

    private isPartnerUpdated(pendingQuote: ServiceCatalogQuote): boolean {
        if (!pendingQuote) {
            return false;
        }
        const partnerAssignedStage = pendingQuote.quoteType === QuoteType.CAPEX ? 4 : 3;
        return pendingQuote.sfdcStage != null && pendingQuote.sfdcStageNumber >= partnerAssignedStage;
    }
}
