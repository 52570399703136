import { ReplaySubject, Subject } from 'rxjs';
import { take, filter, switchMap, debounceTime, takeUntil } from 'rxjs/operators';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CachedCurrentUserService } from '@pure1/data';
import { init } from '@amplitude/analytics-browser';

import { getEmailDomain } from '../utils/strings';
import { WINDOW } from './injection-tokens';
import { AuthenticationService } from '../services/authentication.service';
import { Angulartics2AmplitudePSTG } from './angulartics-amplitude-pstg.service';
import { isPure1Production } from '../utils/url';
import { ampli, ApiKey, IdentifyProperties } from '../ampli';

@Injectable({ providedIn: 'root' })
export class AngularticsConfigService implements OnDestroy {
    readonly userIdentified$ = new ReplaySubject<{
        originalEmailDomain: string;
        impersonatedEmailDomain: string;
        originalContactId: string;
        impersonatedContactId: string;
    }>(1);
    private readonly pageTrack$ = new Subject<void>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private cachedCurrentUserService: CachedCurrentUserService,
        private angulartics2AmplitudePSTG: Angulartics2AmplitudePSTG,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    /**
     * Hooks up Angulartics2 for Amplitude.
     */
    run(): void {
        this.initAmplitude(isPure1Production(this.window.location)).then(() => {
            this.angulartics2AmplitudePSTG.startTracking();
        });

        // Manually track pageviews by watching for when the router state changes.
        // Do this manually instead of letting Angulartics manage it for us since (as of Angulartics 8.x) rewriting the url params without
        // changing the path causes a pageview event, which isn't desired since we rewrite the url params frequently
        // as part of all that reduxy stuff we do.
        let prevPath: string = null;

        this.pageTrack$
            .pipe(
                debounceTime(500), // wait for the URL query params to be set.
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                const currPath = this.window.location.pathname;
                let urlParams = this.window.location.search;
                if (urlParams?.startsWith('?')) {
                    // Trim the trailing ?
                    urlParams = urlParams.substring(1);
                }
                // Only log event if page view changed and userid is recognized.
                // Hitting the login page while not logged in would create an unidentified "unique" user which we do
                // not want to track. Once a user logs in, their device id will been associated with a user id and
                // amplitude will use that user id when it sees that device in the future.
                if (currPath && prevPath !== currPath && ampli.client.getUserId()) {
                    prevPath = currPath;
                    this.angulartics2AmplitudePSTG.pageTrack(currPath, urlParams);
                }
            });

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(e => this.pageTrack$.next());

        // Update Amplitude with the user's domain if they are logged in
        this.authenticationService
            .isLoggedIn()
            .pipe(
                filter(Boolean),
                switchMap(_ => this.cachedCurrentUserService.get()),
                take(1),
            )
            .subscribe(currentUser => {
                // Assign the Amplitude user id with the user original email, which is a good username to use and doesn't change when impersonating. Progress!
                const userEmailDomain = getEmailDomain(currentUser.email);

                const origEmail = currentUser.originalUserEmail;

                if (currentUser.impersonating) {
                    // Impersonating
                    const origEmailDomain = getEmailDomain(origEmail) || 'Unknown';
                    this.userIdentified$.next({
                        originalEmailDomain: origEmailDomain,
                        impersonatedEmailDomain: userEmailDomain,
                        originalContactId: currentUser.originalContactId,
                        impersonatedContactId: currentUser.id,
                    });
                } else {
                    // Not impersonating
                    this.userIdentified$.next({
                        originalEmailDomain: userEmailDomain,
                        impersonatedEmailDomain: null,
                        originalContactId: currentUser.id,
                        impersonatedContactId: null,
                    });
                }

                // Amplitude user properties
                const origEmailDomain = getEmailDomain(currentUser.originalUserEmail);
                const ampliIdentify: IdentifyProperties = {
                    'impersonating user': currentUser.impersonating ? currentUser.email : undefined,
                    'is impersonating user': Boolean(currentUser.impersonating),
                    'pure1 org id': String(currentUser.organization_id),
                    'pure1 org': currentUser.organizationName,
                    'pure1 user role': currentUser.role,
                    'user domain': origEmailDomain ? '@' + origEmailDomain : '',
                    'user email': currentUser.originalUserEmail,
                };
                ampli.identify(currentUser.originalUserEmail, ampliIdentify);
            });
    }

    /**
     * Wrapper for init(), mostly so we can spy/stub init() in unit tests
     */
    initAmplitude(isProd: boolean): Promise<void> {
        const useBatch = isProd; // Don't use batching outside prod since it lets events show up quicker, which makes debugging less annoying

        const initAngulartics = init(isProd ? ApiKey.production : ApiKey.development, null, {
            useBatch: useBatch,
        }).promise;

        const initAmpli = ampli.load({
            environment: isProd ? 'production' : 'development',
            client: {
                configuration: {
                    useBatch: useBatch,
                },
            },
        }).promise;

        return Promise.all([initAngulartics, initAmpli]).then(() => {});
    }
}
