<div class="modal-body">
    <ng-container *ngIf="createCaseDoneFlag; else confirmModalLoading">
        <ng-container *ngIf="!createCaseErrorFlag; else confirmModalError">
            <div>
                <pureui-svg class="pstg-action-icon" svg="checkmark-circle.svg" height="64"> </pureui-svg>
            </div>
            <br />
            <h4>Your request has been created for the following arrays:</h4>
            <ul class="appliances-list">
                <li *ngFor="let array of schedule.arrays" class="h5">
                    {{ array.array.name }} on {{ printPrettyTime(array.timeslot.startTime) }} ({{
                        array.timeslot.duration.asMilliseconds() | formatDuration: 3
                    }})
                </li>
            </ul>
            <br />
            <h5
                >A confirmation email will be sent to <b class="email">{{ email }}</b></h5
            >
            <h5
                ><b *ngIf="caseId">Case ID: #{{ caseId }}</b></h5
            >
            <br />
        </ng-container>
        <button class="btn btn-secondary" (click)="onDismiss.emit()">Done</button>
    </ng-container>
    <ng-template #confirmModalLoading>
        <br />
        <pureui-spinner class="p-loading" [pureuiShow]="true"></pureui-spinner>
        <br />
    </ng-template>
    <ng-template #confirmModalError>
        <div class="error-content">
            <h4>Oops... Something went wrong...</h4>
            <h4>Please contact administrator or try again later.</h4>
        </div>
    </ng-template>
</div>
