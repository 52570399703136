import { Component, EventEmitter, Output } from '@angular/core';
import { CachedCurrentUserService } from '@pure1/data';
import { take } from 'rxjs/operators';
import { UserModalService } from '../../user-management/user-modal/user-modal.service';

import { BaseBanner } from '../base-banner';

@Component({
    selector: 'feature-banner',
    templateUrl: 'feature-banner.component.html',
})
export class FeatureBannerComponent extends BaseBanner {
    @Output() readonly dismissNotification = new EventEmitter<void>();

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private userModalService: UserModalService,
    ) {
        super();
    }

    // For TOTP-based step-up authentication, id: A000089
    // copied from step-up-banner.component.ts
    openStepUpSetup(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.userModalService.openStepUpSetup(currentUser);
            });
    }
}
