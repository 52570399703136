import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse, PGroupSafemode } from '@pure1/data';
import { map, Observable } from 'rxjs';
import { DynamicTableInterface } from '../table-utils/dynamic-table-interface';
import { TableList } from '../table-utils/table-list';

@Injectable({
    providedIn: 'root',
})
export class SafemodePgroupsService implements DynamicTableInterface<PGroupSafemode> {
    constructor(private http: HttpClient) {}

    list(tableInput: TableList): Observable<IRestResponse<PGroupSafemode>> {
        let params = new HttpParams();
        if (tableInput.filtering['name']) {
            params = params.append('filter', `contains(name, '${tableInput.filtering['name']}')`);
        }
        if (tableInput.filtering['arrays.name']) {
            if (params.has('filter')) {
                params = params.set(
                    'filter',
                    params.get('filter') + ` and contains(arrays.name, '${tableInput.filtering['arrays.name']}')`,
                );
            } else {
                params = params.append('filter', `contains(arrays.name, '${tableInput.filtering['arrays.name']}')`);
            }
        }
        if (tableInput.offset) {
            params = params.append('offset', tableInput.offset.toString());
        }
        if (tableInput.pageSize) {
            params = params.append('limit', tableInput.pageSize.toString());
        }
        return this.http
            .get<IRestResponse<PGroupSafemode>>('/rest/v3/protection-groups', { params: params })
            .pipe(map(response => ({ ...response, items: response.items.map(item => new PGroupSafemode(item)) })));
    }
}
