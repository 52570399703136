import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ElementRef,
    Renderer2,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { License, LicenseType, Subscription } from '@pure1/data';
import { LicenseTypeMappingService } from '../../services/license-type-mapping.service';
import {
    convertToDate,
    convertToNgbDateStruct,
    isServiceTierSupported,
    getLicenseTypeDetail,
    LicenseTypeDetail,
    migrateToServiceTier,
} from '../../subscription-utils/subscription-utils';

@Component({
    selector: 'new-license',
    templateUrl: './new-license.component.html',
})
export class NewLicenseComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() readonly license: License;
    @Input() readonly subscription: Subscription;
    @Input() readonly firstExistingLicenseType: LicenseType;
    @Input() readonly isQuoteRenewal: boolean = false;
    @Output() readonly licenseChange = new EventEmitter<License>();
    @Output() readonly delete = new EventEmitter<void>();
    @ViewChild('newLicensesExpansionsDatePicker') newLicensesExpansionsDatePicker: NgbInputDatepicker;

    allLicenseTypes: LicenseTypeDetail[];
    licenseTypes: LicenseTypeDetail[];
    infoMessage: string = null;
    newLicenseChangeDateModel: NgbDateStruct;

    // This character limitation is from Salesforce
    readonly maxLength = 80;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private licenseTypeMappingService: LicenseTypeMappingService,
        private hostElement: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        if (!this.isQuoteRenewal) {
            const todayPlusAWeek = moment().add(7, 'day');
            const oneYearFromEndDate = this.subscription.endDate.add(1, 'year');
            this.newLicensesExpansionsDatePicker.firstDayOfWeek = 7;
            this.newLicensesExpansionsDatePicker.minDate = convertToNgbDateStruct(todayPlusAWeek);
            this.newLicensesExpansionsDatePicker.maxDate = todayPlusAWeek.isAfter(this.subscription.endDate)
                ? convertToNgbDateStruct(oneYearFromEndDate)
                : convertToNgbDateStruct(this.subscription.endDate);
            // also disabling weekends. 6 = Saturday, 7 = Sunday
            this.newLicensesExpansionsDatePicker.markDisabled = (date: NgbDateStruct) => {
                const dayOfWeek = moment(convertToDate(date)).isoWeekday();
                return dayOfWeek === 6 || dayOfWeek === 7;
            };
            this.newLicensesExpansionsDatePicker.container = 'body';
        }
    }

    ngOnInit(): void {
        if (this.license?.startDate) {
            this.newLicenseChangeDateModel = convertToNgbDateStruct(this.license.startDate);
        }

        if (this.isServiceTierSupported()) {
            this.licenseTypeMappingService
                .getTypeMapping()
                .pipe(takeUntil(this.destroy$))
                .subscribe(typeMap => {
                    this.allLicenseTypes = typeMap
                        .get(this.subscription.programType)
                        .map(licenseType => getLicenseTypeDetail(licenseType))
                        .filter(f => f != null);
                    if (this.isQuoteRenewal) {
                        this.licenseTypes = this.allLicenseTypes;
                    } else {
                        this.licenseTypes = this.allLicenseTypes.filter(f => {
                            return this.firstExistingLicenseType
                                ? (this.firstExistingLicenseType as string).startsWith(f.group)
                                : true;
                        });
                    }
                });
        }

        this.renderer
            .selectRootElement(this.hostElement.nativeElement.querySelector('.license-description input'), true)
            .focus();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    deleteThis(): void {
        this.delete.emit();
    }

    isServiceTierSupported(): boolean {
        return isServiceTierSupported(this.subscription.programType);
    }

    migrateToServiceTier(licenseType: LicenseType): LicenseType {
        return migrateToServiceTier(licenseType);
    }

    onLicenseTypeChange(licenseType: LicenseType): void {
        const newLicense = _.clone(this.license);
        newLicense.licenseType = licenseType;
        this.licenseChange.emit(newLicense);

        if (
            this.firstExistingLicenseType &&
            (this.firstExistingLicenseType as string).startsWith((licenseType as string).substr(0, 6))
        ) {
            this.infoMessage = null;
        } else {
            this.infoMessage =
                'You have selected a different service level from your current license. A new subscription will be created for this.';
        }
    }

    onReservedAmountChange(reservedAmount: number): void {
        const newLicense = _.clone(this.license);
        newLicense.reservedAmount = reservedAmount;
        this.licenseChange.emit(newLicense);
    }

    onLicenseNameChange(name: string): void {
        const newLicense = _.clone(this.license);
        newLicense.name = name;
        this.licenseChange.emit(newLicense);
    }

    onLicenseStartDateChange(date: NgbDateStruct): void {
        const newLicense = _.clone(this.license);
        newLicense.startDate = moment(convertToDate(date));
        this.licenseChange.emit(newLicense);
    }
}
