<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" [height]="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Feature disabled </h3>
</div>

<div class="modal-body">
    <div class="modal-picture">
        <img width="200" height="160" src="/images/pure1_ipad_with_iphone_map_view.png" />
    </div>
    <div class="pull-right col-sm-7 col-xs-12">
        <p>{{ message }}</p>
        <a target="_blank" href="http://www.purestorage.com/products/pure-1.html">Learn more about Pure1 here.</a>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()"> Ok, got it. </button>
</div>
