import { RouterModule, Routes } from '@angular/router';
import { hasPermissionGuard } from '../app/guards/permission-guard';
import { PureTeam } from '../utils/teamOwnership';
import { NgModule } from '@angular/core';
import { SupportTabComponent } from './support-tab/support-tab.component';
import { SupportCaseComponentWrapper } from '../upgrade-directives/support-case.component';

const routes: Routes = [
    {
        path: '',
        component: SupportTabComponent,
        title: 'Cases',
        canActivate: [hasPermissionGuard('PURE1:read:support_cases')],
        data: {
            ownedBy: PureTeam.TRITIUM,
            pageHelpUrl:
                'https://support.purestorage.com/Pure1/Pure1_Manage/002_Getting_Support/00_Pure1_Manage_-_Support_Cases',
        },
        children: [
            {
                path: 'cases',
                component: SupportCaseComponentWrapper,
                canActivate: [hasPermissionGuard('PURE1:read:support_cases')],
                data: {
                    ownedBy: PureTeam.TRITIUM,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SupportRoutingModule {}
