import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';
import { DataProtectionV2DrrVolume, FeedbackData } from '../models/data-protection-v2-drr-volume';

@Injectable({
    providedIn: 'root',
})
export class DataProtectionV2DrrDetailsService extends GenericService<DataProtectionV2DrrVolume> {
    constructor(protected readonly http: HttpClient) {
        super({
            resourceClass: DataProtectionV2DrrVolume,
            endpoint: '/rest/v1/dpa/drr-anomalies',
        });
    }

    setApplianceDrrFeedback(applianceID: string, feedback: FeedbackData): Observable<string> {
        const url = `/rest/v1/dpa/drr-feedback/${applianceID}`;
        return this.http.put(url, feedback, { responseType: 'text' });
    }
}
