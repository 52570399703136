import moment from 'moment';
import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

export interface TimeRange {
    start: moment.Moment;
    end: moment.Moment;
}

export type TimeRangeSelectorChoice = moment.Duration | TimeRange;

export function isTimeRange(obj: any): obj is TimeRange {
    const o = obj as TimeRange;
    return o != null && moment.isMoment(o.start) && moment.isMoment(o.end);
}

@Component({
    selector: 'time-range-selector',
    templateUrl: 'time-range-selector.html',
    host: {
        class: 'btn-group flex-btn-group',
    },
})
export class TimeRangeSelectorComponent implements OnChanges {
    @Input() readonly extremeTimeRange: TimeRange;
    @Input() readonly choices: TimeRangeSelectorChoice[];
    @Input() readonly timeRange: TimeRangeSelectorChoice;
    @Input() readonly showMoveWindowButtons: boolean = true;
    @Input() readonly prefix: string = '';
    @Output() readonly timeRangeChange = new EventEmitter<TimeRangeSelectorChoice>();

    allChoices: TimeRangeSelectorChoice[];
    isRightShiftDisabled = true;
    isLeftShiftDisabled = true;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.timeRange) {
            this.updateShiftButtons();
            this.updateAllChoices();
        }
    }

    selectTimeRange(tr: TimeRangeSelectorChoice): void {
        this.timeRangeChange.emit(tr);
    }

    moveWindowBackward(): void {
        if (!this.showMoveWindowButtons) {
            return;
        }
        let start: moment.Moment;
        let end: moment.Moment;
        if (moment.isDuration(this.timeRange)) {
            start = this.extremeTimeRange.end.clone().subtract(this.timeRange).subtract(this.timeRange);
            end = this.extremeTimeRange.end.clone().subtract(this.timeRange);
        } else {
            const duration = moment.duration(this.timeRange.end.diff(this.timeRange.start), 'milliseconds');
            start = this.timeRange.start.clone().subtract(duration);
            end = this.timeRange.end.clone().subtract(duration);
        }
        const offset = this.extremeTimeRange.start.diff(start);
        if (offset > 0) {
            start.add(offset, 'milliseconds');
            end.add(offset, 'milliseconds');
        }
        this.selectTimeRange({ start, end });
    }

    moveWindowForward(): void {
        if (!this.showMoveWindowButtons) {
            return;
        }
        if (moment.isDuration(this.timeRange)) {
            return;
        }
        const duration = moment.duration(this.timeRange.end.diff(this.timeRange.start), 'milliseconds');
        const start = this.timeRange.start.clone().add(duration);
        const end = this.timeRange.end.clone().add(duration);
        const offset = end.diff(this.extremeTimeRange.end);
        if (offset > 0) {
            start.subtract(offset, 'milliseconds');
            end.subtract(offset, 'milliseconds');
        }
        let tr: TimeRangeSelectorChoice = { start, end };
        if (end.isSame(this.extremeTimeRange.end)) {
            const index = this.choices.findIndex(choice => {
                if (moment.isDuration(choice)) {
                    return moment.isDuration(choice) && choice.asMilliseconds() === duration.asMilliseconds();
                }
            });
            if (index > -1) {
                tr = this.choices[index];
            }
        }
        this.selectTimeRange(tr);
    }

    private updateAllChoices(): void {
        if (moment.isDuration(this.timeRange)) {
            this.allChoices = this.choices;
        } else {
            this.allChoices = this.choices.concat(this.timeRange);
        }
    }

    private updateShiftButtons(): void {
        if (!this.showMoveWindowButtons) {
            return;
        }
        if (moment.isDuration(this.timeRange)) {
            this.isLeftShiftDisabled =
                this.timeRange.asMilliseconds() >= this.extremeTimeRange.end.diff(this.extremeTimeRange.start);
            this.isRightShiftDisabled = true;
        } else {
            this.isLeftShiftDisabled = this.timeRange.start.isSameOrBefore(this.extremeTimeRange.start);
            this.isRightShiftDisabled = this.timeRange.end.isSameOrAfter(this.extremeTimeRange.end);
        }
    }
}
