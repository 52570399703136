import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { ReportSchedule } from './report-schedule';
import { ReportDeliveryMethod } from './report-delivery-method';
import { ReportFilter } from './report-filter';
import { ReportGranularity } from './report-granularity';
import { ReportTimeRange } from '../../export/services/export-request-builder.service';

export type ReportGenerationStatus = 'INITIAL' | 'FAILED' | 'SUCCEEDED' | 'TRIGGERED';

export type ReportAggregationMethod = 'AVERAGE' | 'MAX';

export type DataGranularityOption = 'low' | 'medium' | 'high';

// Should match with CalendarTimeRange.java in report common
export enum LastCalendarTimeRange {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
}

export type TimePeriod = {
    start_time: number;
    end_time: number;
};

export class ReportConfiguration implements Resource {
    aggregation_method: ReportAggregationMethod;
    created_by: string;
    data_granularity: ReportGranularity;
    delivery_method: ReportDeliveryMethod;
    format: string;
    id: string;
    last_generated_at: moment.Moment | null;
    last_generation_status: ReportGenerationStatus;
    name: string;
    next_generation_at: moment.Moment | null;
    organization_id: number | null;
    operational_company: string;
    owned_by: string;
    report_filter: ReportFilter;
    schedule: ReportSchedule | null;
    simulation_id: string;
    template_id: string;
    time_range: number;
    last_calendar_time_range: LastCalendarTimeRange;

    // DPA report specific
    report_details: dpaReportDetails;
    metric: dpaReportMetric;
    value_type: dpaReportValueType;
    include_appliances_details: boolean;
    report_view: dpaReportView;

    // multi time range specific
    multi_time_selections: ReportTimeRange[] | null;

    // Invoice report specific
    invoice_report_type: invoiceReportType;
    custom_time_range: TimePeriod | null;

    // Security Assessment report specific
    zone_id: string;

    constructor(json) {
        this.aggregation_method = json.aggregation_method;
        this.created_by = json.created_by;
        this.data_granularity = json.data_granularity;
        this.delivery_method = new ReportDeliveryMethod(json.delivery_method || {});
        this.format = json.format;
        this.id = json.id;
        this.last_generated_at = json.last_generated_at ? moment(json.last_generated_at) : null;
        this.last_generation_status = json.last_generation_status;
        this.name = json.name;
        this.next_generation_at = json.next_generation_at ? moment(json.next_generation_at) : null;
        this.organization_id = json.target_oid;
        this.operational_company = json.operational_company;
        this.owned_by = json.owned_by;
        this.report_filter = json.report_filter ? new ReportFilter(json.report_filter) : null;
        this.schedule = json.schedule ? new ReportSchedule(json.schedule) : null;
        this.simulation_id = json.simulation_id;
        this.template_id = json.template_id;
        this.time_range = json.time_range;
        this.last_calendar_time_range = json.last_calendar_time_range;
        this.report_details = json.report_details;
        this.metric = json.metric;
        this.value_type = json.value_type;
        this.report_view = json.report_view;
        this.include_appliances_details = json.include_appliances_details;
        this.multi_time_selections = json.multi_time_selections ? json.multi_time_selections : null;
        this.invoice_report_type = json.invoice_report_type ? json.invoice_report_type : null;
        this.custom_time_range = json.custom_time_range ? json.custom_time_range : null;
        this.zone_id = json.zone_id ? json.zone_id : moment.tz.guess();
    }
}
