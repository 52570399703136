import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '../../utils/formatNumber';

const UNIT_LABELS = ['B', 'K', 'M', 'G', 'T', 'P', 'E'];

interface ISizeNumberUnit {
    sizeNumber: number;
    unit: string;
}

/**
 * Format size values in bytes into a string with specified number of digits.
 * e.g. 1.10E, 1.00P, 123T, 12.3G, 1.23M, 0.12K, 0.01B
 * If 999.x is rounded up to 1000, the unit will be upgraded and the value will be 1.00.
 * e.g. 999.8M -> 1.00G
 * @param bytes Size in bytes.
 * @param totalDigits Total number of digits.
 */
@Pipe({ name: 'formatSizeTotalDigits' })
export class FormatSizeTotalDigitsPipe implements PipeTransform {
    transform(bytes: number, totalDigits = 3): string {
        if (typeof bytes !== 'number' || isNaN(bytes) || typeof totalDigits !== 'number') {
            return '-';
        }

        if (bytes === 0) {
            return '0';
        }

        const sizeNumberUnit: ISizeNumberUnit = FormatSizeTotalDigitsPipe.formatSizeWithUntouchedDecimals(bytes);

        let threshold: number = 10;
        let wholeNumberDigits: number = 1;
        while (sizeNumberUnit.sizeNumber >= threshold) {
            threshold *= 10;
            wholeNumberDigits++;
        }
        let finalBytes = formatNumber(sizeNumberUnit.sizeNumber, totalDigits - wholeNumberDigits);

        // Special case if formatNumber has rounded up 999.x to 1000, upgrade to a bigger unit.
        if (finalBytes === '1000') {
            finalBytes = '1.00';
            sizeNumberUnit.unit = UNIT_LABELS[UNIT_LABELS.indexOf(sizeNumberUnit.unit) + 1];
        }

        return `${finalBytes} ${sizeNumberUnit.unit}`.trim();
    }

    private static formatSizeWithUntouchedDecimals(
        bytes: number,
        axisLabel?: boolean,
        fixUnit?: string,
    ): ISizeNumberUnit {
        const units = axisLabel ? UNIT_LABELS.slice(1) : UNIT_LABELS;
        if (axisLabel) {
            bytes = bytes / 1024;
        }

        let index = 0;
        let unit = '';
        const unitIndex = fixUnit ? units.indexOf(fixUnit) : -1;
        if (unitIndex !== -1) {
            while (index < unitIndex) {
                bytes = bytes / 1024;
                index++;
            }
        } else {
            // NOTE: '>= 1000' is to prevent values between [1000, 1024) from showing up as, say, 1005.6MB
            // and instead show as 1.0 GB (assuming 1 digit rounding). This helps simplify UI layout issues
            while (Math.abs(bytes) >= 1000 && index < units.length - 1) {
                bytes = bytes / 1024;
                index++;
            }
            unit = bytes !== 0 ? units[index] : 'G';
        }

        return {
            sizeNumber: bytes,
            unit: unit,
        };
    }
}
