import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CachedCurrentUserService } from '@pure1/data';
import moment from 'moment-timezone';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Timeslot } from '../../../support/support-upgrade/types';
import { ProductLine } from '../../../support/support.interface';
import { splitUpSlots } from '../../../support/support.utils';
import { timezoneList } from '../../../utils/supportedTimezones';
import { SafeModeSchedulerService } from '../../services/safemode-scheduler.service';
import { getDayAsMoment } from '../../util';
import { Schedule } from '../types';

@Component({
    selector: 'safemode-planner',
    templateUrl: './safemode-planner.component.html',
})
export class SafemodePlannerComponent implements OnInit, OnChanges {
    @Input() readonly productLine: ProductLine;
    @Input() readonly timeSlotConflict: boolean = false;
    @Input() readonly encourageCustomerToCallSupport: boolean = false;
    @Output() readonly onBack: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly onForward: EventEmitter<Schedule> = new EventEmitter<Schedule>();

    selectedTimeZone: string;
    timezones: { label: string; value: string }[] = [];
    // the earliest and latest date the user is allowed to select in the calendar
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    selectedDate: NgbDateStruct | null = null;
    selectedTime: moment.Moment;
    loading = false;
    allTimeSlots: Timeslot[] = [];
    timeslotsForSelectedDay: Timeslot[] = [];
    heightOfScrollBar = 400;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private calendar: NgbCalendar,
        private cachedCurrentUserService: CachedCurrentUserService,
        private scheduleService: SafeModeSchedulerService,
    ) {}

    ngOnInit(): void {
        this.loading = true;

        //the earliest date to schedule is tomorrow
        this.minDate = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
        // maximum date is 6 months form now
        this.maxDate = this.calendar.getNext(this.calendar.getToday(), 'm', 6);

        const preSelectTimeZone$ = this.preSelectTimezone();
        const getFreeTimes$ = this.scheduleService.getFreeTimes(moment(), moment().add(6, 'months'), this.productLine);
        forkJoin([preSelectTimeZone$, getFreeTimes$])
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: ([_, freeTimes]) => {
                    this.allTimeSlots = splitUpSlots(
                        freeTimes,
                        moment.duration(1, 'hours'),
                        moment.duration(60, 'minutes'),
                    );
                    this.selectedDate = this.minDate;
                    this.getTimeslotsForSelectedDate();
                    this.loading = false;
                },
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.timeSlotConflict || changes.encourageCustomerToCallSupport) {
            this.heightOfScrollBar = 400;
            if (this.timeSlotConflict) {
                this.heightOfScrollBar = this.heightOfScrollBar - 40;
            }
            if (this.encourageCustomerToCallSupport) {
                this.heightOfScrollBar = this.heightOfScrollBar - 60;
            }
        }
    }

    continue(): void {
        this.onForward.emit({
            timezone: this.selectedTimeZone,
            selectedTime: this.selectedTime,
        });
    }

    preSelectTimezone(): Observable<void> {
        //get all timezones
        timezoneList.forEach(timezone => {
            this.timezones.push({ label: this.getTimezonePrettyName(timezone), value: timezone });
        });

        // pre-select a timezone
        this.selectedTimeZone = this.timezones.find(zone => zone.value === moment.tz.guess())?.value || 'GMT';

        // get user timezone, and use if only if the user hasn't selected one yet an its a valid timezone
        return this.cachedCurrentUserService.get().pipe(
            take(1),
            map(currentUser => {
                if (currentUser.timezone && this.timezones.some(zone => zone.value === currentUser.timezone)) {
                    this.selectedTimeZone = currentUser.timezone;
                }
            }),
        );
    }

    getTimezonePrettyName(timezone: string): string {
        if (!timezone) {
            return '';
        }
        const raw = timezone.split('/');
        const city = raw[raw.length - 1].replace('_', ' ');
        return city + ' (UTC' + moment.tz(timezone).format('Z') + ')';
    }

    getTimeslotsForSelectedDate(): void {
        this.timeslotsForSelectedDay = [];
        const startTime = getDayAsMoment(this.selectedDate, this.selectedTimeZone);
        const endTime = startTime.clone().add(1, 'days');

        for (const timeSlot of this.allTimeSlots) {
            if (timeSlot.startTime.isSameOrAfter(startTime) && timeSlot.startTime.isBefore(endTime)) {
                this.timeslotsForSelectedDay.push(timeSlot);
            }
        }
    }

    changeSelectedDate(days: number): void {
        const next = getDayAsMoment(this.selectedDate, this.selectedTimeZone).add(days, 'days');
        this.selectedDate = {
            year: next.year(),
            month: next.month() + 1,
            day: next.date(),
        };
        this.getTimeslotsForSelectedDate();
    }

    printDate(): string {
        return getDayAsMoment(this.selectedDate, this.selectedTimeZone).format('LL');
    }

    changeSelectedTimeZone(newZone: string): void {
        this.selectedTimeZone = newZone;
        this.getTimeslotsForSelectedDate();
    }

    selectTime(timeslot: Timeslot): void {
        this.selectedTime = timeslot.startTime;
    }
}
