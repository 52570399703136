import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

export interface SortOption {
    sortKey: string;
    label: string;
    faIcon?: string;
}

@Component({
    selector: 'genealogy-sort-dropdown',
    templateUrl: './genealogy-sort-dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenealogySortDropdownComponent implements OnChanges {
    @Input() readonly sortOptions: SortOption[];
    @Input() readonly sortState: string;

    @Output() readonly sortChange = new EventEmitter<string>();

    sortKey: string;
    sortDesc: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sortState) {
            this.sortKey = this.sortState.endsWith('-')
                ? this.sortState.slice(0, this.sortState.length - 1)
                : this.sortState;
            this.sortDesc = this.sortState.endsWith('-');
        }
    }

    onSortKeyChange(key: string): void {
        const orderSuffix = this.sortDesc ? '-' : '';
        this.sortChange.emit(key + orderSuffix);
    }

    onClickSortOrder(): void {
        const orderSuffix = !this.sortDesc ? '-' : '';
        this.sortChange.emit(this.sortKey + orderSuffix);
    }
}
