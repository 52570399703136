import moment from 'moment';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { IMetricsHistoryOptions, ApiAnnotationCallBatcherInputs } from './metrics-history.service';
import { getUniqueKeysFromInputs } from './base-batch-metrics-history.service';
import { PodMetricAnnotationService } from './pod-metric-annotation.service';
import { PodMetricAnnotation } from '../models/pod-metric-annotation';
import { ApiCallBatcher } from './api-call-batcher';
import { ListParams } from '../interfaces/list-params';
import { WINDOW } from '../../app/injection-tokens';

const BATCH_WAIT_TIME_MS = 100;

@Injectable({ providedIn: 'root' })
export class BatchPodAnnotationMetricsHistoryService {
    private podAnnotationBatcher = new ApiCallBatcher<ApiAnnotationCallBatcherInputs, PodMetricAnnotation[]>(
        BATCH_WAIT_TIME_MS,
        request => this.apiAnnotationBatcherPodGroupKey(request),
        requests => this.apiAnnotationBatcherPodExecute(requests),
        this.window,
    );

    constructor(
        private podMetricAnnotationService: PodMetricAnnotationService,
        @Inject(WINDOW) private window: Window,
    ) {}

    getAnnotationMetricsHistory(
        podId: string,
        options: IMetricsHistoryOptions,
        annotationTypes: MetricAnnotationType[],
    ): Observable<PodMetricAnnotation[]> {
        const apiAnnotationBatcherInput: ApiAnnotationCallBatcherInputs = {
            podId: podId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            types: annotationTypes || [],
        };

        return this.podAnnotationBatcher.enqueue(apiAnnotationBatcherInput).pipe(
            map(annotations => {
                return annotations.filter(annotation => annotation.pods.map(pod => pod.id).includes(podId));
            }),
        );
    }

    private apiAnnotationBatcherPodGroupKey(request: ApiAnnotationCallBatcherInputs): string {
        const keyValues = [request.types, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    private apiAnnotationBatcherPodExecute(
        requests: ApiAnnotationCallBatcherInputs[],
    ): Observable<PodMetricAnnotation[]> {
        const { types, startTime, endTime, maxPoints } = requests[0];
        const uniqueIds = getUniqueKeysFromInputs<ApiAnnotationCallBatcherInputs, 'podId'>(requests, 'podId');

        const typesStr = types.join(',');
        const idStr = uniqueIds.join(',');
        const params: ListParams<PodMetricAnnotation> = {
            extra:
                `pod_ids=${idStr}&types=${typesStr}` +
                `&start_time=${startTime.valueOf()}&end_time=${endTime.valueOf()}` +
                `&max_points=${maxPoints}`,
        };
        return this.podMetricAnnotationService.list(params).pipe(
            take(1),
            map(item => item.response),
        );
    }
}
