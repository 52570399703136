import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes a number representing a day of the month and makes it more human readable e.g. 1 -> "Day 1"
 * also, if the day is greater than 28, convert it to last day of the month
 */
@Pipe({ name: 'dayOfMonth' })
export class DayOfMonthPipe implements PipeTransform {
    transform(value: number): any {
        if (value > 28) {
            return 'Last Day';
        }
        return `Day ${value}`;
    }
}
