import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { DraasApiConfig } from './data/services/disaster-recovery-constants';
import { isPure1Production } from './utils/url';

if (environment.production) {
    enableProdMode();
}

if (!isPure1Production()) {
    window['draasApi'] = DraasApiConfig.initialize();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: true })
    .catch(err => {
        console.log('Failed to bootstrap Angular', err);
    });
