import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TouModalComponent } from './tou-modal/tou-modal.component';

const TOU_ENDPOINT = '/rest/v1/user/tou';

@Injectable({ providedIn: 'root' })
export class TouService {
    constructor(
        private httpClient: HttpClient,
        private ngbModal: NgbModal,
    ) {}

    /**
     * Determine if the user needs to accept the ToU, and show them if needed
     * @returns `true` if showing the TOU.
     */
    run(): Observable<boolean> {
        return this.touAccepted().pipe(
            tap(accepted => {
                if (!accepted) {
                    const modal = this.ngbModal.open(TouModalComponent, { size: 'lg' });
                    modal.result.then(() => this.accept());
                }
            }),
            map(accepted => !accepted),
        );
    }

    touAccepted(): Observable<boolean> {
        return this.httpClient.get<{ accepted: boolean }>(TOU_ENDPOINT).pipe(map(x => x.accepted));
    }

    accept(): void {
        this.httpClient.put(TOU_ENDPOINT, {}).subscribe();
    }
}
