import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrentUser, RoleType, User, UsersService } from '@pure1/data';
import _ from 'lodash';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PhoneResult } from '../../ui/components/input-phone.component';

import { timezoneList } from '../../utils/supportedTimezones';
import { UserModalStateService } from '../user-modal/user-modal-state.service';

type Timezone = { value: string; timeOffset: string };

@Component({
    selector: 'um-user-form',
    templateUrl: './um-user-form.component.html',
})
export class UMUserFormComponent implements OnInit, OnDestroy {
    readonly roleTypes: RoleType[] = Object.values(RoleType);
    @Input() readonly currentUser: CurrentUser;

    @Output() readonly userSaved = new EventEmitter<User>();
    @Output() readonly error = new EventEmitter<string>();
    @Output() readonly cancelled = new EventEmitter<void>();

    userForm: UntypedFormGroup;
    currentUserInfo: User;
    loading = false;
    timezones: Timezone[];

    private validPhoneNumber = false;
    private emptyPhoneNumber = true;
    private phoneNumber: string = null;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private usersService: UsersService,
        private umStateService: UserModalStateService,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.timezones = this.getTimezoneData();

        const controlsConfig = {
            role: [{ value: this.currentUser.role, disabled: true }, Validators.required],
            email: [
                { value: this.currentUser.email, disabled: true },
                Validators.compose([Validators.required, Validators.email]),
            ],
            firstName: [this.currentUser.firstName, Validators.required],
            lastName: [this.currentUser.lastName, Validators.required],
            phone: [this.currentUser.phone],
            timezone: [this.currentUser.timezone, Validators.required],
        } as { [key: string]: any };

        this.userForm = this.fb.group(controlsConfig);

        if (!this.userForm.value.timezone) {
            this.userForm.controls.timezone.setValue(this.currentUser.timezone, { onlySelf: true });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    edit(): void {
        if (this.userForm.valid) {
            const userPatchDto = this.createUserUpdatePayload(_.omit(this.userForm.value, 'view'));

            this.loading = true;
            this.umStateService
                .updateWithCache<User>(this.usersService, userPatchDto, { email: this.currentUser.email })
                .pipe(
                    map(result => result.response[0]),
                    take(1),
                )
                .subscribe({
                    next: (user: User) => {
                        this.loading = false;
                        this.emit(user);
                    },
                    error: error => this.handleError(error),
                });
        }
    }

    cancel(): void {
        this.cancelled.emit();
    }

    isValidForm(): boolean {
        return this.userForm.valid && (this.validPhoneNumber || this.emptyPhoneNumber);
    }

    onPhoneChange(result: PhoneResult): void {
        this.validPhoneNumber = result.isValid;
        this.emptyPhoneNumber = result.isEmpty;
        this.phoneNumber = result.phoneNumber;
    }

    createUserUpdatePayload(formValue: Partial<User>): Partial<User> {
        const payload: Partial<User> = {
            ...formValue,
            phone: this.phoneNumber,
            // these fields shall not change
            id: this.currentUser.id,
            name: this.currentUser.name,
            email: this.currentUser.email,
        };

        return payload;
    }

    emit(user: User): void {
        this.userSaved.emit(user);
    }

    private getTimezoneData(): Timezone[] {
        return timezoneList.map(zone => {
            return { value: zone, timeOffset: 'GMT' + moment.tz(zone).format('Z') };
        });
    }

    private handleError(error: any): void {
        this.loading = false;
        const errorMsg = (error.data && error.data.errors && error.data.errors[0].message) || '';
        this.error.emit(errorMsg);
    }
}
