import moment from 'moment';
import { Resource } from '../interfaces/resource';

export const COMMENT_FOR_EDITING_PARTNER = ' ** Additional Comment For Editing Partner: ';

export class PartnerRequest {
    account_name: string;
    contact_name: string;
    contact_email: string;
    contact_phone_number: string;
    comment: string;

    constructor(json: any) {
        this.account_name = json.account_name;
        this.contact_name = json.contact_name;
        this.contact_email = json.contact_email;
        this.contact_phone_number = json.contact_phone_number;
        this.comment = json.comment;
    }
}

export class DisplayedPartner {
    accountName: string;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
    comment: string;

    constructor(json: any) {
        this.accountName = json.account_name;
        this.contactName = json.contact_name;
        this.contactEmail = json.contact_email;
        this.contactPhoneNumber = json.contact_phone_number;
        this.comment = json.comment;
    }

    static fromPartner(partner: Partner): DisplayedPartner {
        return new DisplayedPartner({
            account_name: partner.accountName,
            contact_name: partner.contactName,
            contact_email: partner.contactEmail,
        });
    }

    static fromPartnerRequest(request: PartnerRequest): DisplayedPartner {
        return new DisplayedPartner({
            account_name: request.account_name,
            contact_name: request.contact_name,
            contact_email: request.contact_email,
            contact_phone_number: request.contact_phone_number,
            comment: request.comment,
        });
    }

    toPartnerRequest(): PartnerRequest {
        return new PartnerRequest({
            account_name: this.accountName,
            contact_name: this.contactName,
            contact_email: this.contactEmail,
            contact_phone_number: this.contactPhoneNumber,
            comment: this.comment,
        });
    }
}

export class Partner implements Resource {
    id: string;
    name: string;
    accountName: string;
    contactName: string;
    contactEmail: string;
    opportunityCloseDate: moment.Moment;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.accountName = json.account_name;
        this.contactName = json.contact_name;
        this.contactEmail = json.contact_email;
        this.opportunityCloseDate = moment(json.opportunity_close_date);
    }
}
