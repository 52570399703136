import moment from 'moment';
import { Resource } from '../interfaces/resource';

export enum EracleStatus {
    Offline = 'Offline',
    Degraded = 'Degraded',
    Online = 'Online',
}

export class ServiceStatus implements Resource {
    id: string;
    name: string;
    status: EracleStatus;
    _as_of: moment.Moment | null;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        if (json.status === 'critical') {
            this.status = EracleStatus.Offline;
        } else if (json.status === 'warning') {
            this.status = EracleStatus.Degraded;
        } else if (json.status === 'ok') {
            this.status = EracleStatus.Online;
        }
        this._as_of = json._as_of;
    }
}
