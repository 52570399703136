import { Tag } from '@pure1/data';

export class MultiResourceTagValue extends Tag {
    readonly resourceIds: string[] = [];

    constructor(tag: Tag, value?: string) {
        super(tag);
        this.resource = null;
        if (value) {
            this.value = value;
        }
    }

    add(resourceId: string): this {
        this.resourceIds.push(resourceId);
        if (!this.resource) {
            this.resource = { id: resourceId, name: undefined };
        }
        return this;
    }
}
