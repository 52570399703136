import { Resource } from '../interfaces/resource';
import moment from 'moment/moment';

export class InvoiceWithReportSize {
    readonly fileSizeInBytes: number;
    readonly invoices: Invoice[];

    constructor(json: any) {
        this.fileSizeInBytes = json.file_size_in_bytes;
        this.invoices = json.invoices?.map((invoice: any) => new Invoice(invoice)) || [];
    }
}

export class Invoice implements Resource {
    readonly id: string;
    readonly name: string;
    readonly status: InvoiceStatus;
    readonly date: moment.Moment;
    readonly dueDate: moment.Moment;
    readonly customerName: string;
    readonly contractNumber: string;
    readonly partnerPo: string[];
    readonly shipToCountry: string;
    readonly shipToState: string;
    readonly shipToCity: string;
    readonly transactionType: string;
    readonly paymentFrequency: string;
    readonly lines: InvoiceLine[];

    constructor(json: any) {
        // the id is the name of the invoice
        // both have to be defined to satisfy Resource
        this.id = json.id;
        this.name = json.id;
        this.status = json.status;
        this.date = json.date ? moment.utc(json.date) : null;
        this.dueDate = json.due_date ? moment.utc(json.due_date) : null;
        this.customerName = json.end_user_name;
        this.contractNumber = json.subscription?.name ? json.subscription.name : null;
        this.partnerPo = json.split_partner_pos || [];
        this.shipToCountry = json.ship_to_country;
        this.shipToState = json.ship_to_state;
        this.shipToCity = json.ship_to_city;
        this.transactionType = json.transaction_type;
        this.paymentFrequency = json.payment_frequency;
        this.lines = json.lines?.map((line: any) => new InvoiceLine(line)) || [];
    }
}

export enum InvoiceStatus {
    OPEN = 'Open',
    PAID_IN_FULL = 'Paid In Full',
}

export class InvoiceLine {
    readonly item: string;
    readonly quantity: number;
    readonly description: string;
    readonly startDate: moment.Moment;
    readonly endDate: moment.Moment;
    readonly unitPrice: number;
    readonly amount: number;
    readonly billingPeriodMonths: number;
    readonly capacity: number;
    readonly groupName: string;
    readonly tax: Tax;

    constructor(json: any) {
        this.item = json.item;
        this.quantity = json.quantity;
        this.description = json.description;
        this.startDate = json.start_date ? moment.utc(json.start_date) : null;
        this.endDate = json.end_date ? moment.utc(json.end_date) : null;
        this.unitPrice = json.unit_price;
        this.amount = json.amount;
        this.billingPeriodMonths = json.billing_period_months;
        this.capacity = json.capacity;
        this.groupName = json.group_name;
        this.tax = new Tax(json.tax);
    }
}

export class Tax {
    readonly percentage: number;
    readonly amount: number;
    readonly exemptionStatement: string;

    constructor(json: any) {
        this.percentage = json.percentage;
        this.amount = json.amount;
        this.exemptionStatement = json.exemption_statement;
    }
}
