import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayContractStatus, UnifiedArray } from '@pure1/data';
import { FArray, FBlade } from '../../model/model';

@Component({
    selector: 'array-card-header',
    templateUrl: 'array-card-header.component.html',
})
export class ArrayCardHeaderComponent {
    @Input() readonly array: UnifiedArray & FArray & FBlade;
    @Input() readonly alerts: IAlert[];
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Output() readonly onFlipCard = new EventEmitter<{ event: Event }>();

    readonly ArrayContractStatusExpired = ArrayContractStatus.EXPIRED;

    flipCard(event: Event): void {
        if (
            this.showWarningCardForOutOfSupportAppliance &&
            this.array.contract_status === this.ArrayContractStatusExpired
        ) {
            return;
        }
        this.onFlipCard.emit({ event: event });
    }

    hasEolComponents(): boolean {
        return this.array.has_end_of_life_hardware;
    }

    hasLongHostname(): boolean {
        // Array name in card view with 15px font-size starts to truncate on the 24th character
        return this.array.hostname.length > 23;
    }
}
