import { Subject } from 'rxjs';
import { Directive, AfterViewInit, OnDestroy, Input, ElementRef, Renderer2 } from '@angular/core';

export interface Tab<T> {
    name: T;
    el: HTMLElement;
}

@Directive({
    selector: '[pureTabName]',
})
export class TabName<T> implements AfterViewInit, OnDestroy {
    @Input('pureTabName') readonly name: T;

    readonly enter$ = new Subject<Tab<T>>();
    readonly select$ = new Subject<Tab<T>>();
    readonly leave$ = new Subject<Tab<T>>();

    private deregister: Function[] = [];

    constructor(
        public element: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        this.deregister.push(
            this.renderer.listen(this.element.nativeElement, 'mouseover', () => {
                this.enter$.next({ name: this.name, el: this.element.nativeElement });
            }),
        );
        this.deregister.push(
            this.renderer.listen(this.element.nativeElement, 'mouseout', () => {
                this.leave$.next({ name: this.name, el: this.element.nativeElement });
            }),
        );
        this.deregister.push(
            this.renderer.listen(this.element.nativeElement, 'click', () => {
                this.select$.next({ name: this.name, el: this.element.nativeElement });
            }),
        );
    }

    ngOnDestroy(): void {
        this.deregister.forEach(f => f());
    }
}
