import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { LicenseThreshold } from '../models/license-threshold';

@Injectable({ providedIn: 'root' })
export class LicenseThresholdService extends GenericService<LicenseThreshold> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: LicenseThreshold,
            endpoint: '/rest/v1/license-thresholds',
            list: false, // Use V2 instead
            create: true,
            update: true,
        });
    }
}

@Injectable({ providedIn: 'root' })
export class LicenseThresholdServiceV2 extends GenericService<LicenseThreshold> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: LicenseThreshold,
            endpoint: '/rest/v2/license-thresholds',
        });
    }
}
