import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '../ui/ui.module';

import { FeedbackButtonComponent } from './feedback-button/feedback-button.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';

const components = [FeedbackButtonComponent, FeedbackFormComponent];

@NgModule({
    imports: [Angulartics2Module, CommonModule, FormsModule, NgbModule, UIModule],
    declarations: components,
    exports: components,
})
export class NpsSurveyModule {}
