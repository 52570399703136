import { of, zip } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    ValidationErrors,
} from '@angular/forms';
import { CachedCurrentUserService, ContactsService } from '@pure1/data';
import { Contact } from '../support.interface';
import { forString } from '../../utils/comparator';

@Component({
    selector: 'contact-section',
    templateUrl: 'contact-section.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactSectionComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ContactSectionComponent),
            multi: true,
        },
    ],
})
export class ContactSectionComponent implements OnInit, ControlValueAccessor {
    @Input() readonly parentLoading: boolean;
    @Input() readonly initialPrimaryContact: Contact;
    @Input() readonly initialAlternateContact: Contact;

    contactForm: UntypedFormGroup;
    loading = true;
    contacts: Contact[];

    constructor(
        private contactsService: ContactsService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private fb: UntypedFormBuilder,
    ) {}

    onTouched = (_: any) => {};

    ngOnInit() {
        this.contactForm = this.fb.group({
            primary: [null, Validators.required],
            alternate: [null],
        });
        const allUsers$ = this.contactsService.list().pipe(
            take(1),
            map(r => r.response),
            catchError(_err => of([])), // If access denied, then swallow error and work with empty list
        );
        const currentUser$ = this.cachedCurrentUserService.get().pipe(take(1));

        // Initialize primary and secondary contacts
        zip(allUsers$, currentUser$)
            .pipe(take(1))
            .subscribe(([allUsers, currentUser]) => {
                this.contacts = allUsers.sort(forString<Contact>(contact => contact.name).asc);
                // If primary is empty, we default to the current user
                const contactsMap = new Map<string, Contact>(this.contacts.map(contact => [contact.id, contact]));
                this.contactForm.controls.primary.setValue(
                    contactsMap.get(this.initialPrimaryContact ? this.initialPrimaryContact.id : currentUser.id),
                );
                if (this.initialAlternateContact) {
                    this.contactForm.controls.alternate.setValue(contactsMap.get(this.initialAlternateContact.id));
                }
                this.loading = false;
            });
    }

    writeValue(val: any): void {
        val && this.contactForm.setValue(val, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.contactForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.contactForm.disable() : this.contactForm.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.contactForm.valid ? null : { invalidForm: { valid: false, message: 'contact-section is invalid' } };
    }
}
