import { EventEmitter, Input, Output, TemplateRef, Directive } from '@angular/core';
import { UserNotification } from '../model/user-notification';

export interface BannerNotification extends UserNotification {
    buttonTemplate?: TemplateRef<any>;
    linksTemplate?: TemplateRef<any>;
}

export interface WarningBannerNotification {
    body: string;
    url: string;
    hideAction?: boolean;
    title?: string;
    color?: string;
    buttonLabel?: string;
    kbArticleLink?: string;
    icon?: string;
}

@Directive()
export abstract class BaseBanner {
    @Input() readonly notification: BannerNotification;
    // Type any in case the extender wants to send something to banner-carousel (parent component)
    @Output() readonly dismissNotification = new EventEmitter<any>();
}
