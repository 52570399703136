import moment from 'moment';
import _ from 'lodash';

import { Resource } from '../interfaces/resource';
import { IOrganization } from './unified-array';
import { ProgramType } from './subscription';
import { LicenseSlaIndicators } from './license-sla-indicators';
import { LicensePerformanceAggregation } from './license-performance-aggregation';

export enum LicenseType {
    BLOCK_ULTRA = '//Block - Ultra',
    BLOCK_PREMIUM = '//Block - Premium',
    BLOCK_PERFORMANCE = '//Block - Performance',
    BLOCK_ULTRA_PRE = '//Block - Ultra (PRE)',
    BLOCK_PREMIUM_PRE = '//Block - Premium (PRE)',
    BLOCK_PERFORMANCE_PRE = '//Block - Performance (PRE)',
    BLOCK_CAPACITY = '//Block - Capacity',
    UBF_ULTRA = '//Unified Block and File - Ultra',
    UBF_ULTRA_PRE = '//Unified Block and File - Ultra(PRE)',
    UBF_PREMIUM = '//Unified Block and File - Premium',
    UBF_PREMIUM_PRE = '//Unified Block and File - Premium(PRE)',
    UBF_PERFORMANCE = '//Unified Block and File - Performance',
    UBF_PERFORMANCE_PRE = '//Unified Block and File - Performance(PRE)',
    UBF_CAPACITY = '//Unified Block and File - Capacity',
    UFFO_ULTRA = '//UFFO - Ultra',
    UFFO_PREMIUM = '//UFFO - Premium',
    UFFO_PERFORMANCE = '//UFFO - Performance',
    UFFO_STANDARD = '//UFFO - Standard',
    FLEX = 'Evergreen//Flex',
    UDR_FILE_OBJECT = '//UDR - F&O',
    UDR_BLOCK_FILE = '//UDR - Block&File',
    FLEX_FLASHARRAY_X50 = '//Flex FlashArray X50',
    FLEX_FLASHARRAY_X70 = '//Flex FlashArray X70',
    FLEX_FLASHARRAY_X90 = '//Flex FlashArray X90',
    FLEX_FLASHARRAY_XL130 = '//Flex FlashArray XL130',
    FLEX_FLASHARRAY_XL170 = '//Flex FlashArray XL170',
    FLEX_FLASHARRAY_C40 = '//Flex FlashArray C40',
    FLEX_FLASHARRAY_C60 = '//Flex FlashArray C60',
    FLEX_FLASHBLADE_S200_D = '//Flex FlashBlade S200-D',
    FLEX_FLASHBLADE_S200_P = '//Flex FlashBlade S200-P',
    FLEX_FLASHBLADE_S500_D = '//Flex FlashBlade S500-D',
    FLEX_FLASHBLADE_S500_P = '//Flex FlashBlade S500-P',
    DRAAS_PREMIUM = '//DRaaS Premium',
    FOR_AI = 'for AI',
    UNIVERSAL_CREDITS = 'Universal Credits',
    CBS = 'CBS', // deprecated
    FA_SERVICE = 'FA Service', // deprecated
    FA_C_SERVICE = 'FA-C Service', // deprecated
    FB_SERVICE = 'FB Service', // deprecated
    UNKNOWN = 'UNKNOWN', // deprecated
    VMA = 'VMA', // deprecated
}

export enum AssetType {
    ARRAY = 'ARRAY',
    VM_CLUSTER = 'VM_CLUSTER',
}

export enum CloudAccountType {
    AWS = 'AWS',
    AZURE = 'Azure',
}

export enum ResourceStatus {
    ACTIVE = 'active',
    DEACTIVATED = 'deactivated',
    SUSPENDED = 'suspended',
}

export class ActivationPeriod {
    startDate: moment.Moment;
    endDate: moment.Moment;
    active: boolean;

    constructor(json: any) {
        this.startDate = json.start_date ? moment.utc(json.start_date) : null;
        this.endDate = json.end_date ? moment.utc(json.end_date) : null;
        this.active = json.active;
    }
}

export class LicenseUsage {
    // Previous day's daily usage
    current: number;
    totalOnDemand: number;
    averageOnDemand: number;

    constructor(json: any) {
        this.current = json.current;
        this.totalOnDemand = json.total_on_demand;
        this.averageOnDemand = json.average_on_demand;
    }
}

export class Address {
    name: string;
    street: string;
    city: string;
    stateProvince: string;
    country: string;

    constructor(json: any) {
        this.name = json.name;
        this.street = json.street;
        this.city = json.city;
        this.stateProvince = json.state_province;
        this.country = json.country;
    }
}

export interface Revision {
    startDate: moment.Moment;
    endDate: moment.Moment;
    reservedAmount: number;
    reservedUnit: string;
}

export interface LicenseEsg {
    powerUsage: number;
    powerEfficiency: number;
    lastUpdated: moment.Moment;
}

export interface LicenseSubscriptionResource extends Resource {
    org_id: number;
    program_type: ProgramType;
    subscription_partner_org: number;
}

export class SubscriptionResource implements Resource {
    id: string;
    name: string;
    type: AssetType;
    cloud_account_id: string;
    cloud_account_type: CloudAccountType;
    cloud_region: string;
    fqdn: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.type = json.type;
        this.cloud_account_id = json.cloud_account_id;
        this.cloud_account_type = json.cloud_account_type;
        this.cloud_region = json.cloud_region;
        this.fqdn = json.fqdn;
    }
}

export class ClusterActivation extends SubscriptionResource {
    v_center: string;
    data_center: string;

    constructor(json: any) {
        super(json);
        this.v_center = json.v_center;
        this.data_center = json.data_center;
    }
}

const BANDWIDTH_PER_CAPACITY_MULTIPLIER = Math.pow(1024, 4) / Math.pow(1000, 2);

export class LicenseAsset extends SubscriptionResource {
    capacity: number;
    currentUsage: number;
    activations: ActivationPeriod[];
    model: string;
    dataReduction: number;
    status: ResourceStatus;
    percentFull: number;
    purityVersion: string;
    averageLatency: number;
    bandwidthPerCapacity: number;
    vmProtected: number;
    logicalManagedCapacity: number;
    totalIops: number;
    totalBandwidth: number;

    constructor(json: any) {
        super(json);
        this.capacity = json.physical_capacity || json.capacity;
        this.currentUsage = json.effective_total_used || json.current_usage;
        this.activations = (json.activations || []).map(activation => new ActivationPeriod(activation));
        this.model = json.model;
        this.dataReduction = json.data_reduction;
        this.status = json.status;
        this.percentFull = json.percent_full;
        this.purityVersion = json.purity_version;
        this.vmProtected = Number(json?.vm_protected);
        this.logicalManagedCapacity = Number(json?.logical_managed_capacity);
        if (json.array_monitor_data) {
            const { read_latency, write_latency, mirrored_write_latency, other_latency } = json.array_monitor_data;
            const nonZeroLatencies = [read_latency, write_latency, mirrored_write_latency, other_latency].filter(
                value => value > 0,
            );
            if (nonZeroLatencies.length > 0) {
                this.averageLatency = _.sum(nonZeroLatencies) / nonZeroLatencies.length;
            }

            const { read_bandwidth, write_bandwidth, mirrored_write_bandwidth } = json.array_monitor_data;
            if (this.capacity > 0) {
                this.bandwidthPerCapacity =
                    ((read_bandwidth + write_bandwidth + mirrored_write_bandwidth) / this.capacity) *
                    BANDWIDTH_PER_CAPACITY_MULTIPLIER;
            }
            const nonZeroBandwidth = [read_bandwidth, write_bandwidth, mirrored_write_bandwidth].filter(
                value => value > 0,
            );
            this.totalBandwidth = _.sum(nonZeroBandwidth);

            const { read_iops, write_iops, mirrored_write_iops } = json.array_monitor_data;
            const nonZeroIops = [read_iops, write_iops, mirrored_write_iops].filter(value => value > 0);
            this.totalIops = _.sum(nonZeroIops);
        }
    }
}

export const VMA_LICENSE_MIN_RESERVE = 100;
export const VMA_LICENSE_MAX_RESERVE = 1000000;

export const CBS_LICENSE_MIN_RESERVE_IN_TIB = 0;
export const CBS_LICENSE_MAX_RESERVE_IN_TIB = 10240;

export class License implements Resource {
    id: string;
    name: string;
    organization: IOrganization;
    licenseType: LicenseType;
    skuId: string;
    key: string;
    region: string;
    licenseAssets: LicenseAsset[];
    startDate: moment.Moment;
    endDate: moment.Moment;
    subscription: LicenseSubscriptionResource;
    reservedAmount: number;
    licenseUsage: LicenseUsage;
    revisions: Revision[];
    unauthorizedResourceCount: number;
    reservedUnit: string;
    partnerSubscriptionId?: string;
    siteAddress: Address;
    preRatio: number;
    fullInDays: string;
    averageLatency: number;
    bandwidthPerCapacity: number;
    licenseEsg: LicenseEsg;
    slaIndicators?: LicenseSlaIndicators;
    isRansomwareProtectionEnabled: boolean;
    lastUpdated: moment.Moment;

    // [CLOUD-129438] EOPM aggregation
    licensePerformanceAggregation?: LicensePerformanceAggregation;

    // Derived
    programTypeAndLicenseTypeString: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.organization = {
            id: String(json.org_id),
        };
        this.licenseType = json.license_type;
        this.skuId = json.sku_id;
        this.key = json.key;
        this.region = json.region;
        this.licenseAssets = (json.resources || []).map(resource => new LicenseAsset(resource));
        this.startDate = moment.utc(json.start_date);
        this.endDate = moment.utc(json.end_date);
        this.subscription = json.subscription;
        this.reservedAmount = json.reserved_amount;
        this.licenseUsage = new LicenseUsage(json.usage || {});
        this.revisions = (json.revisions || []).map(revision => {
            return {
                startDate: moment.utc(revision.start_date),
                endDate: moment.utc(revision.end_date),
                reservedAmount: revision.reserved_amount,
                reservedUnit: revision.reserved_unit,
            };
        });
        this.unauthorizedResourceCount = json.unauthorized_resource_count;
        this.reservedUnit = json.reserved_unit;
        this.partnerSubscriptionId = json.partner_subscription_id;
        this.siteAddress = new Address(json.site_address || {});
        this.preRatio = json.pre_ratio;
        this.averageLatency = json.average_latency;
        this.bandwidthPerCapacity = json.bandwidth_per_capacity;
        this.licenseEsg = {
            lastUpdated: moment.utc(json.license_esg?.last_updated),
            powerEfficiency: this.esgFormatter(json.license_esg?.power_efficiency),
            powerUsage: this.esgFormatter(json.license_esg?.power_usage),
        };
        this.slaIndicators = json.sla_indicators ? new LicenseSlaIndicators(json.sla_indicators) : null;
        // [CLOUD-130965] TODO: using this for eopm license summary last updated, in the future confirm if we want a different timestamp source for that purpose
        this.lastUpdated = moment.utc(json.last_updated);
        this.programTypeAndLicenseTypeString = this.subscription.program_type + this.licenseType;
        this.isRansomwareProtectionEnabled = json.ransomware || false;

        this.licensePerformanceAggregation = new LicensePerformanceAggregation(
            json.license_performance_aggregation || {},
        );
    }

    esgFormatter(value: number): number {
        return Number(value?.toFixed(1)) || null;
    }
}

export const LICENSE_TYPE_LATENCY_SLO_MS = {
    [LicenseType.BLOCK_ULTRA]: 1,
    [LicenseType.BLOCK_PREMIUM]: 1,
    [LicenseType.BLOCK_PERFORMANCE]: 1,
    [LicenseType.BLOCK_CAPACITY]: 5,
    [LicenseType.UFFO_ULTRA]: 10,
    [LicenseType.UFFO_PREMIUM]: 10,
} as const;

export const LICENSE_TYPE_BANDWIDTH_SLO_MB = {
    [LicenseType.BLOCK_ULTRA]: 32,
    [LicenseType.BLOCK_PREMIUM]: 16,
    [LicenseType.BLOCK_PERFORMANCE]: 8,
    [LicenseType.BLOCK_CAPACITY]: 1.8,
    [LicenseType.UFFO_ULTRA]: 37,
    [LicenseType.UFFO_PREMIUM]: 16,
} as const;
