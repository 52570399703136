export class DraasApiConfig {
    private static DRAAS_API_URL_PREFIX_KEY = 'draasApiUrlPrefix';

    private static DRAAS_API_URL_PREFIX = '/draas-api';

    static initialize(): DraasApiConfig {
        this.DRAAS_API_URL_PREFIX = window.sessionStorage.getItem(this.DRAAS_API_URL_PREFIX_KEY) ?? '/draas-api';
        return this;
    }

    // This method can be used from browser console to switch DRaaS to DEV API gateway in runtime
    static setDevPrefix(): string {
        this.DRAAS_API_URL_PREFIX = '/draas-api-dev';
        window.sessionStorage.setItem(this.DRAAS_API_URL_PREFIX_KEY, this.DRAAS_API_URL_PREFIX);
        return 'DRaaS API prefix changed, please reload your page';
    }

    // This method can be used from browser console to switch DRaaS to STG API gateway in runtime
    static setStgPrefix(): string {
        this.DRAAS_API_URL_PREFIX = '/draas-api';
        window.sessionStorage.setItem(this.DRAAS_API_URL_PREFIX_KEY, this.DRAAS_API_URL_PREFIX);
        return 'DRaaS API prefix changed, please reload your page';
    }

    static getUrlPrefix(): string {
        return this.DRAAS_API_URL_PREFIX;
    }
}
