<ng-container *ngIf="stepUpVerifyResponse; else stepUpChallenge">
    <div class="step-up-modal-header">
        <pureui-svg svg="edge-service-check.svg" height="60"></pureui-svg>
    </div>
    <div class="step-up-modal-body">
        <div class="step-up-challenge-title">
            <div class="title">
                <ng-container *ngIf="stepUpFactor.factorType === 'email'; else phoneVerified"
                    >Email Verified!</ng-container
                >
                <ng-template #phoneVerified>Verified Your Current Mobile Number!</ng-template>
            </div>
            <div class="subtitle">
                <span class="email">{{ stepUpFactor.profile.email }}</span>
                <br /><br />
                Thanks for helping us keep your account secure!
                <ng-container *ngIf="stepUpFactor.factorType === 'email'; else phoneVerifiedContinue"
                    >Click Continue to receive a text message to verify your new mobile number.</ng-container
                >
                <ng-template #phoneVerifiedContinue>Click Continue to verify your updated mobile number.</ng-template>
                <br /><br />
            </div>
        </div>
        <div class="step-up-challenge-inputs">
            <button
                class="btn btn-primary continue-button"
                (click)="continue()"
                [disabled]="continueButtonDisabled"
                angulartics2On="click"
                angularticsAction="Step Up - Challenge Modal - continue after email verification"
                angularticsCategory="Action"
            >
                Continue
            </button>
        </div>
    </div>
    <div class="step-up-modal-footer">
        <a
            (click)="cancel()"
            angulartics2On="click"
            angularticsAction="Step Up - Challenge Modal - cancel"
            angularticsCategory="Action"
            >Cancel</a
        >
    </div>
</ng-container>

<ng-template #stepUpChallenge>
    <totp-step-up-challenge
        *ngIf="stepUpFactor?.factorType === 'totp'; else legacyStepUpChallenge"
        [factor]="stepUpFactor"
        [audience]="audience"
        [authorizationDetails]="authorizationDetails"
        [singleUse]="singleUse"
        [ttl]="ttl"
        (verifySuccess)="verifySuccess($event)"
        (verifyCancel)="cancel()"
    >
    </totp-step-up-challenge>

    <ng-template #legacyStepUpChallenge>
        <step-up-challenge
            [factor]="stepUpFactor"
            [audience]="audience"
            [authorizationDetails]="authorizationDetails"
            [singleUse]="singleUse"
            [isActivate]="isActivate"
            [authToResendEnroll]="authToResendEnroll"
            [additionalWarning]="additionalWarning"
            [ttl]="ttl"
            (verifySuccess)="verifySuccess($event)"
            (verifyFailure)="verifyFail($event)"
            (verifyCancel)="cancel()"
        >
        </step-up-challenge>
    </ng-template>
</ng-template>
