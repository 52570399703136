import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

const NOT_AVAILABLE = '-';

/**
 * 2015 chosen since this is FB specific and lag dated before then doesn't make sense,
 * but actual replication point values should in practice be much more recent
 */

@Pipe({
    name: 'formatReplicationRecoveryPoint',
})
export class FormatReplicationRecoveryPointPipe implements PipeTransform {
    transform(value: moment.Moment): string {
        if (value && value.isValid() && value.isAfter(moment('2015-01-01'))) {
            return value.format('YYYY-MM-DD HH:mm:ss');
        }
        return NOT_AVAILABLE;
    }
}
