import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { MetricGroup, OrganizationService } from '@pure1/data';

import { DEFAULT_WILDCARD_FIELDS } from '../../gui/paged-data2.component';
import { GLOBAL_ENTITY, ORGNAME_KEY } from '../../redux/actions';

// Don't want to call organizationService each time pipe rebuilt
let orgNameCache$: Observable<Map<string, string>> = null;
/**
 * Constructs a StateParams object to set the selected metric group, and change the filters to only include the tags that
 * group is constructed from.
 */
@Pipe({
    name: 'getMetricGroupDrilldownParams',
})
export class GetMetricGroupDrilldownParamsPipe implements PipeTransform {
    constructor(private organizationService: OrganizationService) {
        if (orgNameCache$) {
            return;
        }
        this.rebuildCache();
    }

    rebuildCache(): void {
        orgNameCache$ = this.organizationService.list().pipe(
            take(1),
            map(orgData => orgData && new Map(orgData.response.map(org => [org.id, org.name]))),
            shareReplay(1),
        );
    }

    transform(group: MetricGroup): Observable<object> {
        // StateParams are not currently typed beyond 'object' (see: interface IEntitySref)
        return orgNameCache$.pipe(
            map(cache => {
                const filters = (group.tags || []).map(({ value, key, namespace }) => {
                    if (DEFAULT_WILDCARD_FIELDS.includes(key)) {
                        value = '^' + value + '$';
                    }
                    return { entity: GLOBAL_ENTITY, key, value, namespace };
                });
                if (cache.size > 1) {
                    filters.push({
                        entity: GLOBAL_ENTITY,
                        key: ORGNAME_KEY,
                        value: cache.get(group.org_id.toString()) ?? group.org_id.toString(),
                        namespace: null,
                    });
                }
                return {
                    group_selection: group.id,
                    filter: JSON.stringify(filters),
                };
            }),
        );
    }
}
