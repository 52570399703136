import { Component, ElementRef, Renderer2, NgZone } from '@angular/core';

import { AppService } from '../../../app/app.service';
import { BaseResizeComponent } from './base-resize-component';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState } from '../../../redux/pure-redux.service';

/**
 * Adds a drag bar to the right of the element this directive is on.
 */
@Component({
    selector: 'pureui-vertical-resize',
    templateUrl: 'vertical-resize.component.html',
})
export class VerticalResizeComponent extends BaseResizeComponent {
    constructor(el: ElementRef, renderer: Renderer2, appService: AppService, ngZone: NgZone, ngRedux: NgRedux<IState>) {
        super(el, renderer, appService, ngZone, ngRedux, 'width');
    }

    getSizeFromMouseEvent(event: MouseEvent): number {
        return event.pageX;
    }

    getSizeFromTouchEvent(event: Touch): number {
        return event.pageX;
    }

    getStartingOffset(rootElem: HTMLElement): number {
        return rootElem.offsetWidth;
    }
}
