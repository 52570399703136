import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatJobExecutionDuration' })
export class FormatJobExecutionDurationPipe implements PipeTransform {
    transform(value: moment.Duration): string {
        if (!moment.isDuration(value)) {
            return '–';
        }
        const duration = value.clone();
        const hours = Math.floor(duration.asHours());
        duration.subtract(hours, 'hours');
        const minutes = Math.floor(duration.asMinutes());
        duration.subtract(minutes, 'minutes');
        const seconds = Math.floor(duration.asSeconds());
        return this.formatNumber(hours) + ':' + this.formatNumber(minutes) + ':' + this.formatNumber(seconds);
    }

    private formatNumber(number: number): string {
        return number.toString().padStart(2, '0');
    }
}
