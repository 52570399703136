import moment from 'moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { OnDestroy, Injectable } from '@angular/core';
import { HttpClient, HttpParamsOptions, HttpResponse } from '@angular/common/http';
import { IRestResponse, CapacityConfig, isPhantomArray, PartnerRequest } from '@pure1/data';
import {
    SimulationStep,
    UpgradeFlashBladeHardwareCapacityActionDetails,
    UpgradeHardwareCapacityActionDetails,
    getStepDescription,
} from './simulation-step.service';
import { DecimalPipe } from '@angular/common';
import { smartTimer } from '@pstg/smart-timer';

const decimalPipe = new DecimalPipe('en-US');
/**
 * Opportunity source is stored in SFDC as "Lead Source" and can only be one of the below values.
 * As of writting, adding a new value requires an update from the subscriptions team and IT team.
 */
export type OpportunitySource =
    | 'Pure1 SE Curated Recommendation' // Recommendation which was originally created by an SE
    | 'Proactive Recommendation' // Recommendation which was created by the automated generator
    | 'Catalog' // New hardware requested from the catalog page
    | 'Planning Tool' // Quote requested from the modify hardware modal
    | 'Pure1'; // Catchall for other types (XaaS defaults to this value if no source_param is provided)

/**
 * Pure1LeadSource indicates where in Pure1 Manage an opportunity is created, and values are managed by the subscription team
 * GUI will be the gatekeeper for the values as subscription service would not do a second check.
 * You can add a new value here if a new usecase does not fit under these categories
 */
export type Pure1LeadSource =
    | 'Pure1 Eol Appliance Card'
    | 'Pure1 Eol Appliance List'
    | 'Pure1 Eol Appliance Table'
    | 'Pure1 Eol Proactive Recommendation Card'
    | 'Pure1 Lean Trade-Up Proactive Recommendation Card'
    | 'Pure1 Eol Planning Tool Recommendation';

export interface Opportunity {
    description: string;
    array_id: string;
    controller: string;
    usable_increase: string;
    /** This should only be set when the Opportunity is for net-new array, not expansion of existing hardware */
    increased_raw_TB: number | null;
    increased_usable_TiB: number;
    pure1_lead_source?: Pure1LeadSource;
    product_sku?: string;
}

export interface NewArrayOpportunity extends Opportunity {
    simulation_id: string;
    source_param: OpportunitySource;
    controller_step: Partial<SimulationStep>;
    capacity_step: Partial<SimulationStep>;
    comment: string;
    quote_customer_emails: string[];
    evergreen_tier: string;
    partner_request: PartnerRequest;
}

export interface FlashBladeSOpportunity extends Opportunity {
    /** the "label" of the FB//S configuration, from Sizer/PerfCalc and meant to remain consistent with SFDC */
    fbs_configuration?: string;
    raw_increase: string;
    comment?: string;
}

export interface OpportunityRequest extends Opportunity {
    simulation_id: string;
    /**
     * The lead source that is saved in SFDC.
     * If value does not match in an enum found in pure1-subscription SalesFlowService.java it is defaulted to 'Pure1'
     */
    source_param: OpportunitySource;
    controller_step?: Partial<SimulationStep>;
    capacity_step?: Partial<SimulationStep>;
    /**
     * Lead source which is consumed by XaaS team. Work to move over all `source_param` values here was tracked in
     * CLOUD-94430 but was deprioritized. Will remain optional until more work is done to by XaaS team.
     */
    pure1_lead_source?: Pure1LeadSource;
}

export interface OpportunityStatus {
    opportunities: Opportunity[];
    isError: boolean;
    error?: string;
}

export type CreateOrUpdateResult = { isUpdate: boolean };

// See the following for how to validate created opportunities
// https://wiki.purestorage.com/display/psw/Opportunity+Validation
export const OPPORTUNITY_URL = '/rest/v1/opportunity';

@Injectable()
export class OpportunityService implements OnDestroy {
    // Check the array id, controller, and increased usable to dedupe on old quote requests.
    static oppEqual(opportunity1: Opportunity, opportunity2: Opportunity): boolean {
        return (
            opportunity1.array_id === opportunity2.array_id &&
            opportunity1.controller == opportunity2.controller &&
            decimalPipe.transform(opportunity1.increased_usable_TiB, '1.1-1') ===
                decimalPipe.transform(opportunity2.increased_usable_TiB, '1.1-1')
        );
    }

    readonly opportunities$ = new ReplaySubject<Map<string, OpportunityStatus>>(1);

    // A map of array id to a list of opportunities(simulations) that have been
    //  requested by the user. If an attempt to quote a simulation has returned an
    //  error then set the error status and error from the back end
    private readonly opportunityStatusMap = new Map<string, OpportunityStatus>();
    private readonly destroy$ = new Subject<void>();

    constructor(private http: HttpClient) {
        // Call the get every two minutes
        smartTimer(0, moment.duration(2, 'minutes').asMilliseconds())
            .pipe(
                takeUntil(this.destroy$),
                tap(() => this.refreshOpportunityStatuses()),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    buildOpportunityObject(
        controllerStep: SimulationStep,
        capacityStep: SimulationStep,
        arrayId: string,
        model: string,
        increasedRawTB: number,
        increasedUsableTiB: number,
        fbConfiguration?: string,
        comment?: string,
        leadSource?: Pure1LeadSource,
        recommendedSku?: string,
    ): Opportunity {
        const roundedRaw = decimalPipe.transform(increasedRawTB, '1.1-1');

        let roundedUsable: string;

        // If increasedUsableTiB was not supplied grab it from the simulation step, this means we are on the simulation summary
        //  instead of the hardware modal
        if (!increasedUsableTiB && capacityStep) {
            const usableFromSimulationStep = (<
                UpgradeHardwareCapacityActionDetails | UpgradeFlashBladeHardwareCapacityActionDetails
            >capacityStep.action_details).increased_usable_TiB;
            roundedUsable = decimalPipe.transform(usableFromSimulationStep, '1.1-1');
        } else {
            roundedUsable = decimalPipe.transform(increasedUsableTiB, '1.1-1');
        }

        const isPhantom = isPhantomArray({ id: arrayId });
        let description: string;
        if (isPhantom) {
            description = this.formatDescription(controllerStep, capacityStep, arrayId);
        } else if (leadSource) {
            description = OpportunityService.buildEOLDescription(recommendedSku, false);
        } else {
            description =
                'Raw capacity is changed by ' +
                roundedRaw +
                ' TB and usable capacity is changed by ' +
                roundedUsable +
                ' TiB';
        }

        const opportunity: Opportunity = {
            description: description,
            array_id: arrayId,
            controller: model,
            usable_increase: roundedUsable,
            increased_raw_TB: isPhantom ? increasedRawTB : null,
            increased_usable_TiB: increasedUsableTiB,
            pure1_lead_source: leadSource,
            product_sku: recommendedSku,
        };
        if (fbConfiguration) {
            // some additional properties are required by the backend when the product is FlashBlade
            // for now we use fbs_configuration for both FB//S and FB//E. TODO: API change if necessary if backend chagned in XAAS-4599.
            Object.assign(opportunity, { fbs_configuration: fbConfiguration, raw_increase: roundedRaw });
        }
        if (comment) {
            Object.assign(opportunity, {
                comment: comment,
                increased_raw_TB: increasedRawTB,
                raw_increase: roundedRaw,
            });
        }
        return opportunity;
    }

    // this function should only be used for creating proactive recommendation opportunities
    createCapacityRequest(
        arrayId: string,
        arrayName: string,
        opportunitySource: OpportunitySource,
        rawIncreaseNumber: number,
        usableIncreaseNumber: number,
        usableChangePct: number,
        capacityConfig: CapacityConfig,
    ): Observable<CreateOrUpdateResult> {
        const capacityStep = OpportunityService.buildCapacitySimulationStep(
            arrayId,
            arrayName,
            usableIncreaseNumber,
            usableChangePct,
            capacityConfig,
        );
        const roundedUsable = decimalPipe.transform(usableIncreaseNumber, '1.1-1');
        const description = OpportunityService.buildDescription(null, rawIncreaseNumber, roundedUsable);
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            source_param: opportunitySource,
            capacity_step: capacityStep,
            array_id: arrayId,
            controller: null,
            usable_increase: roundedUsable,
            increased_raw_TB: null, // omitting raw TB because Proactive Recommendation opportunities are only on existing hardware, which cannot use Raw TB in the quote request
            increased_usable_TiB: usableIncreaseNumber,
        };
        return this.postOpportunity(opportunityRequest);
    }

    // this function should only be used for creating proactive recommendation opportunities
    createControllerRequest(
        arrayId: string,
        arrayName: string,
        opportunitySource: OpportunitySource,
        controllerModel: string,
    ): Observable<CreateOrUpdateResult> {
        const controllerStep = OpportunityService.buildControllerSimulationStep(arrayId, arrayName, controllerModel);
        const description = OpportunityService.buildDescription(controllerModel, null, null);
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            source_param: opportunitySource,
            controller_step: controllerStep,
            array_id: arrayId,
            controller: controllerModel,
            usable_increase: null,
            increased_raw_TB: null,
            increased_usable_TiB: null,
        };
        return this.postOpportunity(opportunityRequest);
    }

    // this function should only be used for creating proactive recommendation opportunities
    createCapconRequest(
        arrayId: string,
        arrayName: string,
        opportunitySource: OpportunitySource,
        rawIncreaseNumber: number,
        usableIncreaseNumber: number,
        usableChangePct: number,
        capacityConfig: CapacityConfig,
        controllerModel: string,
    ): Observable<CreateOrUpdateResult> {
        const capacityStep = OpportunityService.buildCapacitySimulationStep(
            arrayId,
            arrayName,
            usableIncreaseNumber,
            usableChangePct,
            capacityConfig,
        );
        const controllerStep = OpportunityService.buildControllerSimulationStep(arrayId, arrayName, controllerModel);
        const roundedUsable = decimalPipe.transform(usableIncreaseNumber, '1.1-1');
        const description = OpportunityService.buildDescription(controllerModel, rawIncreaseNumber, roundedUsable);
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            source_param: opportunitySource,
            capacity_step: capacityStep,
            controller_step: controllerStep,
            array_id: arrayId,
            controller: controllerModel,
            usable_increase: roundedUsable,
            increased_raw_TB: null, // omitting the raw TB because Proactive Recommendation opportunities are only for existing hardware, which cannot use Raw TB in the quote request
            increased_usable_TiB: usableIncreaseNumber,
        };
        return this.postOpportunity(opportunityRequest);
    }

    createEolRequest(
        arrayId: string,
        arrayName: string,
        leadSource: Pure1LeadSource,
        capacityConfig: CapacityConfig,
    ): Observable<CreateOrUpdateResult> {
        const capacityStep = OpportunityService.buildCapacitySimulationStep(
            arrayId,
            arrayName,
            null,
            null,
            capacityConfig,
        );
        const description = 'End of Life contact account team request';
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            pure1_lead_source: leadSource,
            source_param: 'Pure1',
            capacity_step: capacityStep,
            array_id: arrayId,
            controller: null,
            usable_increase: null,
            increased_raw_TB: null, // omitting the raw TB because EOL opportunities are only for existing hardware, which cannot use Raw TB in the quote request
            increased_usable_TiB: null,
        };
        return this.postOpportunity(opportunityRequest);
    }

    createM2XRequest(
        arrayId: string,
        arrayName: string,
        usableIncreaseNumber: number,
        usableChangePct: number,
        capacityConfig: CapacityConfig,
        controllerModel: string,
        recommendedSku: string,
        shouldAccountTeamBeContacted: boolean,
    ): Observable<CreateOrUpdateResult> {
        const description = OpportunityService.buildEOLDescription(recommendedSku, shouldAccountTeamBeContacted);
        const roundedUsable = decimalPipe.transform(usableIncreaseNumber, '1.1-1');
        const controllerStep = OpportunityService.buildControllerSimulationStep(arrayId, arrayName, controllerModel);
        const capacityStep = OpportunityService.buildCapacitySimulationStep(
            arrayId,
            arrayName,
            usableIncreaseNumber,
            usableChangePct,
            capacityConfig,
        );
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            pure1_lead_source: 'Pure1 Eol Proactive Recommendation Card',
            source_param: 'Proactive Recommendation',
            capacity_step: capacityStep,
            controller_step: controllerStep,
            array_id: arrayId,
            controller: controllerModel,
            usable_increase: roundedUsable,
            increased_raw_TB: null, // omitting the raw TB because Proactive Recommendation opportunities are only for existing hardware, which cannot use Raw TB in the quote request
            increased_usable_TiB: usableIncreaseNumber,
            product_sku: recommendedSku,
        };
        return this.postOpportunity(opportunityRequest);
    }

    createCombinedSas2NvmeEolRequest(
        arrayId: string,
        arrayName: string,
        usableIncreaseNumber: number,
        usableChangePct: number,
        capacityConfig: CapacityConfig,
        controllerModel: string,
        recommendedSku: string,
        shouldAccountTeamBeContacted: boolean,
    ): Observable<CreateOrUpdateResult> {
        const description = OpportunityService.buildEOLDescription(recommendedSku, shouldAccountTeamBeContacted);
        const roundedUsable =
            usableIncreaseNumber == null ? null : decimalPipe.transform(usableIncreaseNumber, '1.1-1');
        const controllerStep =
            controllerModel == null
                ? null
                : OpportunityService.buildControllerSimulationStep(arrayId, arrayName, controllerModel);
        const capacityStep =
            usableIncreaseNumber == null
                ? null
                : OpportunityService.buildCapacitySimulationStep(
                      arrayId,
                      arrayName,
                      usableIncreaseNumber,
                      usableChangePct,
                      capacityConfig,
                  );
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            pure1_lead_source: 'Pure1 Eol Proactive Recommendation Card',
            source_param: 'Proactive Recommendation',
            capacity_step: capacityStep,
            controller_step: controllerStep,
            array_id: arrayId,
            controller: controllerModel,
            usable_increase: roundedUsable,
            increased_raw_TB: null, // omitting the raw TB because Proactive Recommendation opportunities are only for existing hardware, which cannot use Raw TB in the quote request
            increased_usable_TiB: usableIncreaseNumber,
            product_sku: recommendedSku,
        };
        return this.postOpportunity(opportunityRequest);
    }

    createLeanSas2NVMeRequest(
        arrayId: string,
        arrayName: string,
        usableIncreaseNumber: number,
        usableChangePct: number,
        capacityConfig: CapacityConfig,
        shouldAccountTeamBeContacted: boolean,
    ): Observable<CreateOrUpdateResult> {
        const description = OpportunityService.buildLeanTradeUpDescription(shouldAccountTeamBeContacted);
        const roundedUsable = decimalPipe.transform(usableIncreaseNumber, '1.1-1');
        const capacityStep = OpportunityService.buildCapacitySimulationStep(
            arrayId,
            arrayName,
            usableIncreaseNumber,
            usableChangePct,
            capacityConfig,
        );
        const opportunityRequest: OpportunityRequest = {
            description,
            simulation_id: null,
            pure1_lead_source: 'Pure1 Lean Trade-Up Proactive Recommendation Card',
            source_param: 'Proactive Recommendation',
            capacity_step: capacityStep,
            array_id: arrayId,
            controller: null,
            usable_increase: roundedUsable,
            increased_raw_TB: null, // omitting the raw TB because Proactive Recommendation opportunities are only for existing hardware, which cannot use Raw TB in the quote request
            increased_usable_TiB: usableIncreaseNumber,
        };
        return this.postOpportunity(opportunityRequest);
    }

    createBannerNewApplianceQuoteRequest(
        phantomArrayId: string,
        controller: string,
        comment: string,
        description: string,
    ): Observable<HttpResponse<CreateOrUpdateResult>> {
        const newApplianceOpportunity: NewArrayOpportunity = {
            description: description,
            array_id: phantomArrayId,
            controller: controller,
            simulation_id: '',
            source_param: 'Pure1',
            increased_usable_TiB: 1,
            increased_raw_TB: 1,
            usable_increase: '1',
            controller_step: null,
            capacity_step: null,
            comment: comment,
            quote_customer_emails: [],
            evergreen_tier: null,
            partner_request: null,
        };
        return this.postOpportunityAndReturnHttpResponse(newApplianceOpportunity);
    }

    createCatalogNewFBQuoteRequest(
        phantomArrayId: string,
        controller: string,
        comment: string,
        emails: string[],
        selectedEvergreenOption: string,
        partnerRequest: PartnerRequest,
    ): Observable<CreateOrUpdateResult> {
        const newApplianceOpportunity: NewArrayOpportunity = {
            description: 'New FlashBlade Quote Request from Pure1 Service Catalog',
            array_id: phantomArrayId,
            controller: controller,
            simulation_id: '',
            source_param: 'Catalog',
            increased_usable_TiB: 1,
            increased_raw_TB: 1,
            usable_increase: '1',
            controller_step: null,
            capacity_step: null,
            comment: comment,
            quote_customer_emails: emails,
            evergreen_tier: selectedEvergreenOption ? `Evergreen${selectedEvergreenOption}` : null,
            partner_request: partnerRequest,
        };
        return this.postOpportunity(newApplianceOpportunity);
    }

    createCatalogNewFARequest(
        phantomArrayId: string,
        controller: string,
        comment: string,
        emails: string[],
        selectedEvergreenOption: string,
        partnerRequest: PartnerRequest,
        usableTiB: number,
        rawTB: number,
    ): Observable<CreateOrUpdateResult> {
        // construct controller step
        const controllerStep: Partial<SimulationStep> = {
            name: 'simulation step',
            simulation: {
                id: 'not-a-real-simulation-id',
                name: 'default_simulation',
            },
            arrays: [
                {
                    id: phantomArrayId,
                    name: 'New-FlashArray',
                },
            ],
            action: 'upgrade_controller_model',
            action_details: {
                controller_model: controller,
                is_recommended: false,
            },
        };

        const newApplianceOpportunity: NewArrayOpportunity = {
            description: 'New Array Quote Request from Pure1 Service Catalog',
            array_id: phantomArrayId,
            controller: controller,
            simulation_id: '',
            source_param: 'Catalog',
            increased_usable_TiB: usableTiB,
            increased_raw_TB: rawTB,
            usable_increase: String(usableTiB),
            controller_step: controllerStep,
            capacity_step: null,
            comment: comment,
            quote_customer_emails: emails,
            evergreen_tier: selectedEvergreenOption ? `Evergreen${selectedEvergreenOption}` : null,
            partner_request: partnerRequest,
        };

        return this.postOpportunity(newApplianceOpportunity);
    }

    private static buildCapacitySimulationStep(
        arrayId: string,
        arrayName: string,
        usableIncreaseNumber: number,
        usableChangePct: number,
        capacityConfig: CapacityConfig,
    ): Partial<SimulationStep> {
        const capacityStep = SimulationStep.createUpgradeHardwareCapacityStep(
            { id: arrayId, name: arrayName },
            capacityConfig,
            usableChangePct,
            usableIncreaseNumber,
            true,
        );
        capacityStep.simulation = { id: null, name: null };
        return capacityStep;
    }

    private static buildControllerSimulationStep(arrayId: string, arrayName: string, controllerModel: string) {
        const controllerStep = SimulationStep.createUpgradeControllerModelStep(
            { id: arrayId, name: arrayName },
            controllerModel,
            true,
        );
        controllerStep.simulation = { id: null, name: null };
        return controllerStep;
    }

    private static buildDescription(controllerModel: string, rawIncreaseNumber: number, roundedUsable: string): string {
        let controllerDescription = '';
        if (controllerModel != null) {
            controllerDescription = 'Controller is upgraded to ' + controllerModel + '. ';
        }
        let capacityDescription = '';
        if (rawIncreaseNumber != null && roundedUsable != null) {
            const roundedRaw = decimalPipe.transform(rawIncreaseNumber, '1.1-1');
            capacityDescription =
                'Raw capacity is changed by ' +
                roundedRaw +
                ' TB and usable capacity is changed by ' +
                roundedUsable +
                ' TiB. ';
        }
        return controllerDescription + capacityDescription;
    }

    private static buildEOLDescription(sku: string, shouldAccountTeamBeContacted: boolean): string {
        if (!sku) {
            return 'Note - Pure1 cloud not fetch SKU for this upgrade and recommends account team to check it manually.';
        }
        const desc = `Note - Pure1 recommends account team to quote E//Forever ${sku} with this upgrade.`;
        if (shouldAccountTeamBeContacted) {
            return `The customer would like to speak with the account team about the recommendation. ` + desc;
        }
        return desc;
    }

    private static buildLeanTradeUpDescription(shouldAccountTeamBeContacted: boolean): string {
        const desc = `Note - Pure1 recommends account team to quote lean trade-up with this upgrade.`;
        if (shouldAccountTeamBeContacted) {
            return `The customer would like to speak with the account team about the recommendation. ` + desc;
        }
        return desc;
    }

    create(
        newOpportunity: Opportunity,
        simulationId: string,
        controllerStep: Partial<SimulationStep>,
        capacityStep: Partial<SimulationStep>,
        sourceParam?: OpportunitySource,
    ): Observable<CreateOrUpdateResult> {
        const newOpportunityRequest: OpportunityRequest = {
            ...newOpportunity,
            simulation_id: simulationId,
            controller_step: controllerStep,
            capacity_step: capacityStep,
            source_param: sourceParam,
        };
        return this.postOpportunity(newOpportunityRequest);
    }

    private postOpportunity(opportunityRequest: OpportunityRequest): Observable<CreateOrUpdateResult> {
        return this.handlePostOpportunity(
            opportunityRequest,
            this.http.post<CreateOrUpdateResult>(OPPORTUNITY_URL, opportunityRequest, { observe: 'body' }),
        );
    }

    private postOpportunityAndReturnHttpResponse(
        opportunityRequest: OpportunityRequest,
    ): Observable<HttpResponse<CreateOrUpdateResult>> {
        return this.handlePostOpportunity(
            opportunityRequest,
            this.http.post<CreateOrUpdateResult>(OPPORTUNITY_URL, opportunityRequest, { observe: 'response' }),
        );
    }

    private handlePostOpportunity<T>(
        opportunityRequest: OpportunityRequest,
        postRequest: Observable<T>,
    ): Observable<T> {
        return postRequest.pipe(
            tap(
                () => {
                    this.refreshOpportunityStatuses();
                },
                err => {
                    // We keep track of requests that ended with errors so that the front
                    //  end can keep a button disabled if we know a request doesn't work
                    //  These errors can happen because the Asset record in Salesforce may
                    //  belong to a separate account than the user.
                    //  If a front end click-to-quote component is created it needs to know
                    //  the history of errors so it can disable the button if the current
                    //  simulation will cause an error
                    console.error(
                        'Failed to create opportunity: err.error: ',
                        err.error,
                        'array_id: ',
                        opportunityRequest.array_id,
                    );
                    // get the map object and change the isError and errorString value
                    this.fillOpportunities(opportunityRequest);
                    const opp = this.opportunityStatusMap.get(opportunityRequest.array_id);
                    opp.isError = true;
                    opp.error = err.error ? err.error.message : 'Unknown error';
                    // Send the status map to click-to-quote components with the new error
                    this.opportunities$.next(this.opportunityStatusMap);
                    return err;
                },
            ),
        );
    }

    private formatDescription(controllerStep: SimulationStep, capacityStep: SimulationStep, arrayId): string {
        return [controllerStep, capacityStep]
            .filter(step => step != null)
            .map(step => getStepDescription(step, arrayId))
            .join('\n');
    }

    /**
     * This method is private because the service keeps track of actual quotes as
     *  well as requests that ended in errors.
     */
    private refreshOpportunityStatuses(): void {
        this.http.get<IRestResponse<Opportunity>>(OPPORTUNITY_URL + '/opportunityHistory').subscribe(response => {
            // Clear out the opportunities so we can rebuild it
            this.opportunityStatusMap.forEach(opp => {
                opp.opportunities = [];
            });

            // Rebuild the opportunity list for each array id
            response.items.forEach(opp => {
                this.fillOpportunities(opp);
                this.opportunityStatusMap.get(opp.array_id).opportunities.push(opp);
            });
            // Send the status map to click-to-quote component listening on the ReplaySubject
            this.opportunities$.next(this.opportunityStatusMap);
        });
    }

    private fillOpportunities(opportunity: Opportunity): void {
        if (!this.opportunityStatusMap.has(opportunity.array_id)) {
            // Add the array_id to the map if it isn't there yet
            this.opportunityStatusMap.set(opportunity.array_id, { opportunities: [], isError: false });
        }
    }
}
