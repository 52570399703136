import { Resource } from '../interfaces/resource';
import { PxPoolAggregation } from './px-pool-aggregation';

export class PxPool implements Resource {
    id: string;
    name: string;
    nodeName: string;
    aggregation: PxPoolAggregation;

    constructor(json: any) {
        this.id = json.pool_id;
        this.name = json.pool_name;
        this.nodeName = json.node_name;
        if (json.aggregation) {
            this.aggregation = new PxPoolAggregation(json.aggregation);
        }
    }
}
