import { Pipe, PipeTransform } from '@angular/core';

export const KEY_MASK = '****…';
const DEFAULT_CHARS = 4;

/**
 * Show only the trailing X number of characters from the value passed into the pipe
 */
@Pipe({ name: 'hideKey' })
export class HideKeyPipe implements PipeTransform {
    transform(key: string, trailingChars?: number): string {
        if (key === undefined || key === null) {
            return key;
        }
        if (typeof key !== 'string') {
            console.warn(`key ${key} is not of type string`);
            return key;
        }
        trailingChars = trailingChars || DEFAULT_CHARS;
        if (key.length <= trailingChars) {
            return key;
        }
        return KEY_MASK + key.substr(key.length - trailingChars);
    }
}
