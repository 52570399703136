import { Action } from 'redux';
import { EdgeServiceAgentCardsCollapseState, IStateFilter, IStateTime, PanelTabName } from './pure-redux.service';
import moment from 'moment';
import { DisasterRecoveryReportType } from '../disaster-recovery/monitor/reports/reports/reports.component';
import {
    DisasterRecoveryJobExecutionStatus,
    DisasterRecoveryPlan,
    DisasterRecoveryTag,
    DisasterRecoveryTagCategory,
} from '@pure1/data';

// bar ids
export const GLOBAL_FILTER = 'global_bar';
export const LOCAL_BAR_ALERTS = 'alerts';
export const LOCAL_BAR_ARRAYS = 'arrays';
export const LOCAL_BAR_ARRAY_CAPACITY_ARRAY = 'array-capacity-array';
export const LOCAL_BAR_ARRAY_CAPACITY_GROUP = 'array-capacity-group';
export const LOCAL_BAR_ARRAY_CAPACITY_EDIT_GROUP = 'array-capacity-edit-group';
export const LOCAL_BAR_ASSET_MANAGEMENT = 'asset-management';
export const LOCAL_BAR_ASSET_MANAGEMENT_HIDDEN = 'asset-management_hidden';
export const LOCAL_BAR_APPLIANCE_GENEALOGY_HIDDEN = 'appliance-genealogy-hidden';
export const LOCAL_BAR_SUBSCRIPTION_GENEALOGY_HIDDEN = 'subscription-genealogy-hidden';
export const LOCAL_BAR_QUOTE_MANAGEMENT_HIDDEN = 'quote-management-hidden';
export const LOCAL_BAR_INVOICE_MANAGEMENT_HIDDEN = 'invoice-management-hidden';
export const LOCAL_BAR_VOLUME_CAPACITY = 'volume-capacity';
export const LOCAL_BAR_USERS = 'users';
export const LOCAL_BAR_AUDITS = 'audits';
export const LOCAL_BAR_MAP_VIEW = 'map-view';
export const LOCAL_BAR_DASHBOARD = 'dashboard';
export const LOCAL_BAR_CLUSTERS_LIST = 'clusters-list';
export const LOCAL_BAR_FILESYSTEM_SNAPSHOTS = 'filesystem-snapshots';
export const LOCAL_BAR_FORECAST = 'forecast';
export const LOCAL_BAR_FORECAST_HIDDEN = 'forecast_hidden';
export const LOCAL_BAR_PGROUPS = 'pgroups';
export const LOCAL_BAR_PODS = 'pods';
export const LOCAL_BAR_PURITY_OPTIMIZATIONS = 'purity-optimizations';
export const LOCAL_BAR_QUOTE_MANAGEMENT = 'quote-management';
export const LOCAL_BAR_INVOICE_MANAGEMENT = 'invoice-management';
export const LOCAL_BAR_REPORT_TEMPLATES = 'report-templates';
export const LOCAL_BAR_REPORT_CONFIGURATIONS = 'report-configurations';
export const LOCAL_BAR_SAFEMODE = 'safe-mode';
export const LOCAL_BAR_DATA_PROTECTION_V2_DRR = 'data-protection-v2-drr';
export const LOCAL_BAR_DATA_PROTECTION_V2_EXCLUSIONS = 'data-protection-v2-exclusions';
export const LOCAL_BAR_DATA_PROTECTION_V2 = 'data-protection-v2';
export const LOCAL_BAR_SUSTAINABILITY = 'sustainability';
export const LOCAL_BAR_SECURITY_ASSESSMENT = 'security-assessment';
export const LOCAL_BAR_SECURITY_ASSESSMENT_TABLE = 'security-assessment-table';
export const LOCAL_BAR_SECURITY_ASSESSMENT_HISTORICAL_TABLE = 'security-assessment-historical-table';
export const LOCAL_BAR_SECURITY_ASSESSMENT_CURRENT_ANOMALIES_TABLE = 'security-assessment-current-anomalies-table';
export const LOCAL_BAR_SESSIONS = 'sessions';
export const LOCAL_BAR_SNAPSHOTS_DETAILS = 'snapshots-details';
export const LOCAL_BAR_SUBSCRIPTIONS = 'subscriptions';
export const LOCAL_BAR_SERVICE_CATALOG = 'service-catalog';
export const LOCAL_BAR_RECOMMENDATIONS_VIEW = 'recommendations-view';
export const LOCAL_BAR_SUPPORT_CONTRACTS = 'support-contracts';
export const LOCAL_BAR_PURITY_UPGRADES = 'purity-upgrades';
export const LOCAL_BAR_VM_ANALYTICS = 'vmanalytics';
export const LOCAL_BAR_VOLUME_SNAPSHOTS = 'volume-snapshots';
export const LOCAL_BAR_ACTIVEDR_REPLICATION = 'activedr-replication';
export const LOCAL_BAR_CONTINUOUS_REPLICATION = 'continuous-replication';
export const LOCAL_BAR_PERIODIC_REPLICATION = 'periodic-replication';
export const LOCAL_BAR_DISASTER_RECOVERY_CLUSTERS = 'draas-clusters';
export const LOCAL_BAR_DISASTER_RECOVERY_LICENSES = 'draas-licenses';
export const LOCAL_BAR_DISASTER_RECOVERY_PLANS = 'draas-recovery-plans';
export const LOCAL_BAR_DISASTER_RECOVERY_POLICIES = 'draas-policies';
export const LOCAL_BAR_DISASTER_RECOVERY_PROTECTION_GROUPS = 'draas-protection-groups';
export const LOCAL_BAR_DISASTER_RECOVERY_PROTECTION_SUMMARY_REPORT = 'draas-protection-summary-report';
export const LOCAL_BAR_DISASTER_RECOVERY_VIRTUAL_MACHINES = 'draas-virtual-machines';
export const LOCAL_BAR_DISASTER_RECOVERY_VIRTUAL_MACHINES_NEW_GROUP = 'draas-virtual-machines-new-group';
export const LOCAL_BAR_ARRAY_PERFORMANCE_ARRAY = 'array-performance-array';
export const LOCAL_BAR_ARRAY_PERFORMANCE_GROUP = 'array-performance-group';
export const LOCAL_BAR_ARRAY_PERFORMANCE_EDIT_GROUP = 'array-performance-edit-group';
export const LOCAL_BAR_FILESYSTEM_PERFORMANCE = 'filesystem-performance';
export const LOCAL_BAR_OBJECT_STORE_PERFORMANCE = 'object-store-performance';
export const LOCAL_BAR_POD_PERFORMANCE = 'pod-performance';
export const LOCAL_BAR_PX_VOLUME_PERFORMANCE = 'portworx-performance-volumes';
export const LOCAL_BAR_PX_NODE_PERFORMANCE = 'portworx-performance-nodes';
export const LOCAL_BAR_PX_POOL_PERFORMANCE = 'portworx-performance-pools';
export const LOCAL_BAR_VOLUME_PERFORMANCE = 'volume-performance';
export const LOCAL_BAR_DIRECTORY_PERFORMANCE = 'directory-performance';
export const LOCAL_BAR_ORDERS = 'orders';
export const LOCAL_BAR_EDGE_SERVICE = 'edge-service'; // this is shared by both edge-gateway and agents page for edge service
export const LOCAL_BAR_EDGE_SERVICE_INSTALL_AGENT = 'edge-service-install-agent';
export const LOCAL_BAR_NOTIFICATION_CENTER = 'notification-center';
export const LOCAL_BAR_DIRECTORY_CAPACITY = 'directory-capacity';
export const LOCAL_BAR_DIRECTORY_SNAPSHOT = 'directory-snapshot';

// entities
export const GLOBAL_ENTITY = 'arrays';
export const VOLUMES_ENTITY = 'volumes';
export const PGROUPS_ENTITY = 'protection groups';
export const PODS_ENTITY = 'pods';
export const DIRECTORIES_ENTITY = 'directories';
export const ALERTS_ENTITY = 'alerts';
export const AUDIT_ENTITY = 'audits';
export const ASSET_SUBSCRIPTION_ENTITY = 'asset subscription';
export const ASSET_LICENSE_ENTITY = 'asset license';
export const ASSET_APPLIANCE_ENTITY = 'asset appliance';
export const BUCKET_ENTITY = 'buckets';
export const SESSIONS_ENTITY = 'sessions';
export const FILESYSTEM_ENTITY = 'file systems';
export const SNAPSHOT_ENTITY = 'snapshots';
export const OBJECT_REPLICA_LINK_ENTITY = 'object replica links';
export const POD_REPLICA_LINK_ENTITY = 'pod replica links';
export const FILESYSTEM_REPLICA_LINK_ENTITY = 'file system replica links';
export const REPORT_TEMPLATE_ENTITY = 'report templates';
export const FILESYSTEM_SNAPSHOTS_ENTITY = 'file system snapshots';
export const SUBSCRIPTION_ENTITY = 'subscriptions';
export const SUBSCRIPTION_ASSET_ENTITY = 'subscription assets';
export const SUBSCRIPTION_LICENSE_GLOBAL_ENTITY = 'subscription license';
export const LICENSE_ENTITY = 'licenses';
export const USERS_ENTITY = 'users';
export const VOLUMES_SNAPSHOTS_ENTITY = 'volume snapshots';
export const VOLUMES_PERFORMANCE_ENTITY = 'volumes performance';
export const OPPORTUNITY_QUOTES_ENTITY = 'opportunity quotes';
export const INVOICE_ENTITY = 'invoice';
export const ORDERS_ENTITY = 'quotes';
export const CLUSTERS_ENTITY = 'clusters';
export const OPTIMIZABLE_ARRAYS_ENTITY = 'optimizable arrays';
export const PX_CLUSTERS_ENTITY = 'portworx clusters';
export const PX_VOLUMES_ENTITY = 'portworx volumes';
export const PX_NODES_ENTITY = 'portworx nodes';
export const PX_POOLS_ENTITY = 'portworx pools';
export const ASSESSMENT_ARRAYS = 'assessment arrays';
export const SUSTAINABILITY_ARRAYS = 'sustainability arrays';
export const SECURITY_ASSESSMENT_ARRAYS = 'security assessment arrays';
export const EDGE_SERVICE_ENTITY = 'edge service';
export const DISASTER_RECOVERY_CLUSTER_ENTITY = 'draas cluster';
export const DISASTER_RECOVERY_LICENSE = 'draas license';
export const DISASTER_RECOVERY_PLAN_ENTITY = 'draas recovery plan';
export const DISASTER_RECOVERY_POLICY_ENTITY = 'draas policy';
export const DISASTER_RECOVERY_PROTECTION_GROUP_ENTITY = 'draas protection group';
export const DISASTER_RECOVERY_VIRTUAL_MACHINE_ENTITY = 'draas virtual machine';
export const DISASTER_RECOVERY_ENROLLED_VIRTUAL_MACHINE_ENTITY = 'draas enrolled virtual machine';
export const DISASTER_RECOVERY_FAILBACK_OVA_ENTITY = 'draas failback ova';
export const DIRECTORY_SNAPSHOTS_ENTITY = 'directory snapshots';

// namespace
export const DEFAULT_NAMESPACE = null;

// time keys
export const TIME_INDEX_KEY = 'timeIdx';
export const TIME_START_KEY = 'startTime';
export const TIME_END_KEY = 'endTime';
export const TIME_CUSTOM_DURATION = 'timeCustomDuration';

//time key for the invoice date
export const DATE = 'date';
export const DUE_DATE = 'due_date';

//time key for quote management date
export const CREATED_DATE = 'created_date';
export const EXPIRATION_DATE = 'expiration_date';

//time keys for subscription start date and subscription renewal date
export const SUBSCRIPTION_START_DATE = 'subscription.start_date';
export const SUBSCRIPTION_END_DATE = 'subscription.end_date';

//event type keys for appliance genealogy
export const APPLIANCE_GENEALOGY_EVENT_TYPE_KEY = 'event_type';
export const SUBSCRIPTION_GENEALOGY_EVENT_TYPE_KEY = 'event_type';

/* keys */
// do not change to anything else unless getArrays() is returning something different
export const SUMMARY_KEY = 'summary';
export const NAME_KEY = 'name';
export const ARRAY_NAME_KEY = 'array_name';
export const ARRAYS_NAME_KEY = 'arrays.name';
export const CITY_KEY = 'city';
export const COUNTRY_KEY = 'country';
export const CONTRACT_STATUS_CODE_KEY = 'contract_status_code';
export const FORMAT_KEY = 'format';
export const ORGNAME_KEY = 'org_name';
export const SEVERITY_KEY = 'current_severity';
export const STATUS_KEY = 'status';
export const VERSION_KEY = 'version';
export const RECOMMENDED_VERSION_KEY = 'policyRecommendedVersion';
export const STATUS_TIME_KEY = 'statusTime';
export const MODEL_KEY = 'model';
export const PROTOCOL_KEY = 'protocol_list';
export const TIME_KEY = 'absoluteTime';
export const USER_KEY = 'user';
export const COMMAND_KEY = 'command';
export const SUBCOMMAND_KEY = 'subcommand';
export const DETAILS_KEY = 'details';
export const ARGUMENTS_KEY = 'arguments';
export const HOST_HOSTNAME_KEY = 'host_hostname_list';
export const HOST_IQN_WWN_KEY = 'host_iqn_wwn_list';
export const IS_PAAS_ARRAY = 'is_paas_array';
export const HAS_END_OF_LIFE_HARDWARE = 'has_end_of_life_hardware';
export const AVAILABLE_PREINSTALLED_CAPACITY = 'capacity_expandable';
export const CHASSIS_SERIAL_NUMBER = 'chassis_serial_number';
export const SUBSCRIPTION_LICENSE_NAME = 'subscription_license.name';
export const SUBSCRIPTION_LICENSE_LICENSE_TYPE = 'subscription_license.license_type';
export const SUBSCRIPTION_LICENSE_SUBSCRIPTION_NAME = 'subscription_license.subscription.name';
export const TARGET_IQN_WWN_KEY = 'target_iqn_wwn_list';
export const SOURCE_NAME_KEY = 'source.name';
export const TARGET_KEY = 'target';
export const TARGET_NAME_KEY = 'target.name';
export const TARGET_TYPE_KEY = 'target_type';
export const POLICY_KEY = 'snapshot_policy_names';
export const DIRECTORY_SNAPSHOT_POLICY_KEY = 'policy.name';
export const POLICIES_NAME_KEY = 'policies.name';
export const CLUSTER_KEY = 'cluster';
export const TAGS_KEY = 'tags';
export const SUFFIX_KEY = 'suffix';
export const TARGET_ARRAY_NAME_KEY = 'target_array_name';
export const TYPE_KEY = 'type';
export const PGROUP_KEY = 'pgroup';
export const FQDN = 'fqdn';
export const VIEW_ID_KEY = 'view_id';
export const ORG_ID_KEY = 'org_id';
export const LICENSE_TYPE_KEY = 'licenses.license_type';
export const LICENSE_COUNTRY_KEY = 'licenses.site_address.country';
export const LICENSE_NAME_KEY = 'licenses.name';
export const LICENSE_RESERVED_USAGE_KEY = 'licenses.license_statistics.latest_reserved_amount';
export const LICENSE_USAGE_PERCENTAGE_KEY = 'licenses.license_statistics.usage_percentage';
export const LICENSE_USAGE_AMOUNT_KEY = 'licenses.license_statistics.average_used_amount';
export const LICENSE_USAGE_ON_DEMAND_KEY = 'licenses.license_statistics.total_on_demand';
export const LICENSE_ON_DEMAND_DAYS_KEY = 'licenses.license_statistics.number_of_days_exceed';
export const SERVICE_NAME_KEY = 'service_name';
export const PROGRAM_TYPE_KEY = 'program_type';
export const BULK_OPERATIONS_KEY = 'bulkOperationIds';
export const FILE_SYSTEM_NAME_KEY = 'file_system.name';
export const IS_FIELD_LED_KEY = 'is_field_led';
export const CURRENT_STAGE_PROGRESS_KEY = 'current_stage_progress';
export const REQUESTED_AT_KEY = 'requested_at';

/* Actions types */
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTERS = 'REMOVE_FILTERS';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const REMOVE_FILTERS_BY_KEY = 'REMOVE_FILTERS_BY_KEY';
export const SWAP_ALL_FILTERS = 'SWAP_ALL_FILTERS';
export const COPY_ALL_FILTERS = 'COPY_ALL_FILTERS';
export const ADD_SORT = 'ADD_SORT';
export const REMOVE_SORTS = 'REMOVE_SORTS';
export const REMOVE_ALL_SORTS = 'REMOVE_ALL_SORTS';
export const ADD_PAGINATION = 'ADD_PAGINATION';
export const REMOVE_PAGINATION = 'REMOVE_PAGINATION';
export const ADD_TIME = 'ADD_TIME';
export const REMOVE_TIMES = 'REMOVE_TIMES';
export const REMOVE_ALL_TIMES = 'REMOVE_ALL_TIMES';
export const ADD_FLEET_TYPE = 'ADD_FLEET_TYPE';
export const REMOVE_FLEET_TYPE = 'REMOVE_FLEET_TYPE';
export const ADD_ITEM_TYPE = 'ADD_ITEM_TYPE';
export const REMOVE_ITEM_TYPE = 'REMOVE_ITEM_TYPE';
export const SWAP_ITEM_TYPE = 'SWAP_ITEM_TYPE';
export const ADD_AGGREGATION_METHOD = 'ADD_AGGREGATION_METHOD';
export const REMOVE_AGGREGATION_METHOD = 'REMOVE_AGGREGATION_METHOD';
export const ADD_CAPACITY_FULL_METHOD = 'ADD_CAPACITY_FULL_METHOD';
export const REMOVE_CAPACITY_FULL_METHOD = 'REMOVE_CAPACITY_FULL_METHOD';
export const ADD_METRIC_SERIES = 'ADD_METRIC_SERIES';
export const REMOVE_METRIC_SERIES = 'REMOVE_METRIC_SERIES';
export const REMOVE_ALL_METRIC_SERIES = 'REMOVE_ALL_METRIC_SERIES';
export const ADD_SELECTION = 'ADD_SELECTION';
export const REMOVE_SELECTION = 'REMOVE_SELECTION';
export const REMOVE_ALL_SELECTION = 'REMOVE_ALL_SELECTION';
export const SWAP_ALL_SELECTION = 'SWAP_ALL_SELECTION';
export const ADD_GROUP_SELECTION = 'ADD_GROUP_SELECTION';
export const REMOVE_GROUP_SELECTION = 'REMOVE_GROUP_SELECTION';
export const ADD_CLUSTER = 'ADD_CLUSTER';
export const REMOVE_CLUSTER = 'REMOVE_CLUSTER';
export const ADD_METRIC_TYPE = 'ADD_METRIC_TYPE';
export const REMOVE_METRIC_TYPE = 'REMOVE_METRIC_TYPE';
export const ADD_FOCUS = 'ADD_FOCUS';
export const REMOVE_FOCUS = 'REMOVE_FOCUS';
export const ADD_TAB = 'ADD_TAB';
export const REMOVE_TAB = 'REMOVE_TAB';
export const ADD_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE = 'ADD_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE';
export const REMOVE_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE = 'REMOVE_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE';
export const ADD_PANEL_SELECTION = 'ADD_PANEL_SELECTION';
export const REMOVE_PANEL_SELECTION = 'REMOVE_PANEL_SELECTION';
export const ADD_STEPUP_TOKEN = 'ADD_STEPUP_TOKEN';
export const REMOVE_EXPIRED_STEPUP_TOKENS = 'REMOVE_EXPIRED_STEPUP_TOKENS';
export const REMOVE_ALL_STEPUP_TOKENS = 'REMOVE_ALL_STEPUP_TOKENS';
export const DRAAS_OPEN_GROUP = 'DRAAS_OPEN_GROUP';
export const DRAAS_CLOSE_GROUP = 'DRAAS_CLOSE_GROUP';
export const DRAAS_TOGGLE_ITEM = 'DRAAS_TOGGLE_ITEM';
export const DRAAS_SELECT_POLICY = 'DRAAS_SELECT_POLICY';
export const DRAAS_DESELECT_POLICY = 'DRAAS_DESELECT_POLICY';
export const DRAAS_SELECT_PLAN = 'DRAAS_SELECT_PLAN';
export const DRAAS_DESELECT_PLAN = 'DRAAS_DESELECT_PLAN';
export const DRAAS_SELECT_GROUPS_VIEW = 'DRAAS_SELECT_GROUPS_VIEW';
export const DRAAS_SELECT_INVENTORY_VIEW = 'DRAAS_SELECT_INVENTORY_VIEW';
export const DRAAS_FILTER_VMS = 'DRAAS_FILTER_VMS';
export const DRAAS_FILTER_VM_JOB_EXECUTIONS = 'DRAAS_FILTER_VM_JOB_EXECUTIONS';
export const DRAAS_SELECT_CLUSTER = 'DRAAS_SELECT_CLUSTER';
export const DRAAS_DESELECT_CLUSTER = 'DRAAS_DESELECT_CLUSTER';
export const DRAAS_SELECT_REPORT_TYPE = 'DRAAS_SELECT_REPORT_TYPE';
export const DRAAS_SELECT_REPORT = 'DRAAS_SELECT_REPORT';
export const TOGGLE_DO_NOT_SHOW_MODAL = 'TOGGLE_DO_NOT_SHOW_MODAL';

export const SET_RESIZER_SIZE = 'SET_RESIZER_SIZE';

export const REDUX_INIT = '@@redux/INIT';

export interface IActionReduxInit extends Action {
    type: '@@redux/INIT';
}

/* Action Creators */
interface IActionAddFilter extends Action {
    type: 'ADD_FILTER';
    barId: string;
    filter: IStateFilter;
}
export const addFilter = (barId: string, filter: IStateFilter): IActionAddFilter => {
    return {
        type: ADD_FILTER,
        barId,
        filter,
    };
};

export interface IActionRemoveFilters extends Action {
    type: 'REMOVE_FILTERS';
    barId: string;
    filterIds: string[];
}
export const removeFilters = (barId: string, ids: string | string[]): IActionRemoveFilters => {
    const filterIds = ids instanceof Array ? ids : [ids];
    return {
        type: REMOVE_FILTERS,
        barId,
        filterIds,
    };
};

interface IActionRemoveAllFilters extends Action {
    type: 'REMOVE_ALL_FILTERS';
    barId: string;
}
export const removeAllFilters = (barId: string): IActionRemoveAllFilters => {
    return {
        type: REMOVE_ALL_FILTERS,
        barId,
    };
};

interface IActionRemoveFiltersByKey extends Action {
    type: 'REMOVE_FILTERS_BY_KEY';
    barId: string;
    key: string;
    namespace: string;
}
export const removeFiltersByKey = (barId: string, key: string, namespace: string): IActionRemoveFiltersByKey => {
    return {
        type: REMOVE_FILTERS_BY_KEY,
        barId,
        key,
        namespace,
    };
};

interface IActionSwapAllFilters extends Action {
    type: 'SWAP_ALL_FILTERS';
    barId1: string;
    barId2: string;
}
export const swapAllFilters = (barId1: string, barId2: string): IActionSwapAllFilters => {
    return {
        type: SWAP_ALL_FILTERS,
        barId1,
        barId2,
    };
};

interface IActionCopyAllFilters extends Action {
    type: 'COPY_ALL_FILTERS';
    barId1: string;
    barId2: string;
}
export const copyAllFilters = (barId1: string, barId2: string): IActionCopyAllFilters => {
    return {
        type: COPY_ALL_FILTERS,
        barId1,
        barId2,
    };
};

export type IActionFilter =
    | IActionReduxInit
    | IActionAddFilter
    | IActionRemoveFilters
    | IActionRemoveAllFilters
    | IActionSwapAllFilters
    | IActionCopyAllFilters
    | IActionRemoveFiltersByKey;

interface IActionAddSort extends Action {
    type: 'ADD_SORT';
    barId: string;
    sort: string;
}
export const addSort = (barId: string, sort: string): IActionAddSort => {
    return {
        type: ADD_SORT,
        barId,
        sort,
    };
};

interface IActionRemoveSorts extends Action {
    type: 'REMOVE_SORTS';
    barId: string;
    names: string[];
}
export const removeSort = (barId: string, names: string | string[]): IActionRemoveSorts => {
    const values = names instanceof Array ? names : [names];
    return {
        type: REMOVE_SORTS,
        barId,
        names: values,
    };
};

interface IActionRemoveAllSorts extends Action {
    type: 'REMOVE_ALL_SORTS';
    barId: string;
}
export const removeAllSorts = (barId: string): IActionRemoveAllSorts => {
    return {
        type: REMOVE_ALL_SORTS,
        barId,
    };
};

export type IActionSort = IActionReduxInit | IActionAddSort | IActionRemoveSorts | IActionRemoveAllSorts;

interface IActionAddPagination extends Action {
    type: 'ADD_PAGINATION';
    barId: string;
    page: number;
}
export const addPagination = (barId: string, page: number): IActionAddPagination => {
    return {
        type: ADD_PAGINATION,
        barId,
        page,
    };
};

interface IActionRemovePagination extends Action {
    type: 'REMOVE_PAGINATION';
    barId: string;
}
export const removePagination = (barId: string): IActionRemovePagination => {
    return {
        type: REMOVE_PAGINATION,
        barId,
    };
};

export type IActionPagination = IActionReduxInit | IActionAddPagination | IActionRemovePagination;

interface IActionAddTime extends Action {
    type: 'ADD_TIME';
    barId: string;
    time: IStateTime;
}
export const addTime = (barId: string, key: string, value: string): IActionAddTime => {
    const time: IStateTime = {
        key,
        value,
    };
    return {
        type: ADD_TIME,
        barId,
        time,
    };
};

interface IActionRemoveTime extends Action {
    type: 'REMOVE_TIMES';
    barId: string;
    keys: string[];
}
export const removeTime = (barId: string, keys: string | string[]): IActionRemoveTime => {
    const times = keys instanceof Array ? keys : [keys];
    return {
        type: REMOVE_TIMES,
        barId,
        keys: times,
    };
};

interface IActionRemoveAllTimes extends Action {
    type: 'REMOVE_ALL_TIMES';
    barId: string;
}
export const removeAllTimes = (barId: string): IActionRemoveAllTimes => {
    return {
        type: REMOVE_ALL_TIMES,
        barId,
    };
};

export type IActionTime = IActionReduxInit | IActionAddTime | IActionRemoveTime | IActionRemoveAllTimes;

interface IActionAddFleetType extends Action {
    type: 'ADD_FLEET_TYPE';
    barId: string;
    fleetType: string;
}
export const addFleetType = (barId: string, fleetType: string): IActionAddFleetType => {
    return {
        type: ADD_FLEET_TYPE,
        barId,
        fleetType,
    };
};

interface IActionRemoveFleetType extends Action {
    type: 'REMOVE_FLEET_TYPE';
    barId: string;
}
export const removeFleetType = (barId: string): IActionRemoveFleetType => {
    return {
        type: REMOVE_FLEET_TYPE,
        barId,
    };
};

export type IActionFleetType = IActionAddFleetType | IActionRemoveFleetType;

interface IActionAddItemType extends Action {
    type: 'ADD_ITEM_TYPE';
    barId: string;
    itemType: string;
}
export const addItemType = (barId: string, itemType: string): IActionAddItemType => {
    return {
        type: ADD_ITEM_TYPE,
        barId,
        itemType: itemType,
    };
};

interface IActionRemoveItemType extends Action {
    type: 'REMOVE_ITEM_TYPE';
    barId: string;
}
export const removeItemType = (barId: string): IActionRemoveItemType => {
    return {
        type: REMOVE_ITEM_TYPE,
        barId,
    };
};

interface IActionSwapItemType extends Action {
    type: 'SWAP_ITEM_TYPE';
    barId1: string;
    barId2: string;
}
export const swapItemType = (barId1: string, barId2: string): IActionSwapItemType => {
    return {
        type: SWAP_ITEM_TYPE,
        barId1,
        barId2,
    };
};

export type IActionItemType = IActionAddItemType | IActionRemoveItemType | IActionSwapItemType;

interface IActionAddMetricSeries extends Action {
    type: 'ADD_METRIC_SERIES';
    barId: string;
    metricSeries: string[];
}
export const addMetricSeries = (barId: string, _metricSeries: string | string[]): IActionAddMetricSeries => {
    const metricSeries = _metricSeries instanceof Array ? _metricSeries : [_metricSeries];
    return {
        type: ADD_METRIC_SERIES,
        barId,
        metricSeries,
    };
};

interface IActionRemoveMetricSeries extends Action {
    type: 'REMOVE_METRIC_SERIES';
    barId: string;
    metricSeries: string[];
}
export const removeMetricSeries = (barId: string, _metricSeries: string | string[]): IActionRemoveMetricSeries => {
    const metricSeries = _metricSeries instanceof Array ? _metricSeries : [_metricSeries];
    return {
        type: REMOVE_METRIC_SERIES,
        barId,
        metricSeries,
    };
};

interface IActionRemoveAllMetricSeries extends Action {
    type: 'REMOVE_ALL_METRIC_SERIES';
    barId: string;
}
export const removeAllMetricSeries = (barId: string): IActionRemoveAllMetricSeries => {
    return {
        type: REMOVE_ALL_METRIC_SERIES,
        barId,
    };
};

export type IActionMetricSeries =
    | IActionReduxInit
    | IActionAddMetricSeries
    | IActionRemoveMetricSeries
    | IActionRemoveAllMetricSeries;

interface IActionAddAggregatonMethod extends Action {
    type: 'ADD_AGGREGATION_METHOD';
    barId: string;
    aggregationMethod: string;
}
export const addAggregationMethod = (barId: string, aggregationMethod: string): IActionAddAggregatonMethod => {
    return {
        type: ADD_AGGREGATION_METHOD,
        barId,
        aggregationMethod,
    };
};

interface IActionRemoveAggregationMethod extends Action {
    type: 'REMOVE_AGGREGATION_METHOD';
    barId: string;
}
export const removeAggregationMethod = (barId: string): IActionRemoveAggregationMethod => {
    return {
        type: REMOVE_AGGREGATION_METHOD,
        barId,
    };
};

export const addCapacityFullMethod = (barId: string, capacityFullMethod: string): IActionAddCapacityFullMethod => {
    return {
        type: ADD_CAPACITY_FULL_METHOD,
        barId,
        capacityFullMethod,
    };
};

export type IActionAggregationMethod = IActionReduxInit | IActionAddAggregatonMethod | IActionRemoveAggregationMethod;

interface IActionAddCapacityFullMethod extends Action {
    type: 'ADD_CAPACITY_FULL_METHOD';
    barId: string;
    capacityFullMethod: string;
}

interface IActionRemoveCapacityFullMethod extends Action {
    type: 'REMOVE_CAPACITY_FULL_METHOD';
    barId: string;
}

export const removeCapacityFullMethod = (barId: string): IActionRemoveCapacityFullMethod => {
    return {
        type: REMOVE_CAPACITY_FULL_METHOD,
        barId,
    };
};

export type IActionCapacityFullMethod =
    | IActionReduxInit
    | IActionAddCapacityFullMethod
    | IActionRemoveCapacityFullMethod;

interface IActionAddCluster extends Action {
    type: 'ADD_CLUSTER';
    barId: string;
    cluster: string;
}
export const addCluster = (barId: string, cluster: string): IActionAddCluster => {
    return {
        type: ADD_CLUSTER,
        barId,
        cluster,
    };
};

interface IActionRemoveCluster extends Action {
    type: 'REMOVE_CLUSTER';
    barId: string;
}
export const removeCluster = (barId: string): IActionRemoveCluster => {
    return {
        type: REMOVE_CLUSTER,
        barId,
    };
};

export type IActionCluster = IActionAddCluster | IActionRemoveCluster;

interface IActionAddMetricType extends Action {
    type: 'ADD_METRIC_TYPE';
    barId: string;
    metricType: string;
}
export const addMetricType = (barId: string, metricType: string): IActionAddMetricType => {
    return {
        type: ADD_METRIC_TYPE,
        barId,
        metricType,
    };
};

interface IActionRemoveMetricType extends Action {
    type: 'REMOVE_METRIC_TYPE';
    barId: string;
}
export const removeMetricType = (barId: string): IActionRemoveMetricType => {
    return {
        type: REMOVE_METRIC_TYPE,
        barId,
    };
};

export type IActionMetricType = IActionAddMetricType | IActionRemoveMetricType;

interface IActionAddSelection extends Action {
    type: 'ADD_SELECTION';
    barId: string;
    selection: string[];
}
export const addSelection = (barId: string, selection: string | string[]): IActionAddSelection => {
    const selections = selection instanceof Array ? selection : [selection];
    return {
        type: ADD_SELECTION,
        barId,
        selection: selections,
    };
};

interface IActionRemoveSelection extends Action {
    type: 'REMOVE_SELECTION';
    barId: string;
    selection: string[];
}
export const removeSelection = (barId: string, selection: string | string[]): IActionRemoveSelection => {
    const selections = selection instanceof Array ? selection : [selection];
    return {
        type: REMOVE_SELECTION,
        barId,
        selection: selections,
    };
};

interface IActionRemoveAllSelection extends Action {
    type: 'REMOVE_ALL_SELECTION';
    barId: string;
}
export const removeAllSelection = (barId: string): IActionRemoveAllSelection => {
    return {
        type: REMOVE_ALL_SELECTION,
        barId,
    };
};

interface IActionSwapAllSelection extends Action {
    type: 'SWAP_ALL_SELECTION';
    barId1: string;
    barId2: string;
}
export const swapAllSelection = (barId1: string, barId2: string): IActionSwapAllSelection => {
    return {
        type: SWAP_ALL_SELECTION,
        barId1,
        barId2,
    };
};

export type IActionSelection =
    | IActionReduxInit
    | IActionAddSelection
    | IActionRemoveSelection
    | IActionRemoveAllSelection
    | IActionSwapAllSelection;

interface IActionAddGroupSelection extends Action {
    type: 'ADD_GROUP_SELECTION';
    barId: string;
    groupSelection: string;
}
export const addGroupSelection = (barId: string, groupSelection: string): IActionAddGroupSelection => {
    return {
        type: ADD_GROUP_SELECTION,
        barId,
        groupSelection,
    };
};

interface IActionRemoveGroupSelection extends Action {
    type: 'REMOVE_GROUP_SELECTION';
    barId: string;
}
export const removeGroupSelection = (barId: string): IActionRemoveGroupSelection => {
    return {
        type: REMOVE_GROUP_SELECTION,
        barId,
    };
};

export type IActionGroupSelection = IActionReduxInit | IActionAddGroupSelection | IActionRemoveGroupSelection;

interface IActionSetResizerSize extends Action {
    type: 'SET_RESIZER_SIZE';
    resizerId: string;
    size: number;
}
export const setResizerSize = (resizerId: string, size: number): IActionSetResizerSize => {
    return {
        type: SET_RESIZER_SIZE,
        resizerId,
        size,
    };
};

export type IActionResizer = IActionSetResizerSize;

interface IActionAddTab extends Action {
    type: 'ADD_TAB';
    barId: string;
    tab: string;
}
export const addTab = (barId: string, tab: string): IActionAddTab => {
    return {
        type: ADD_TAB,
        barId,
        tab,
    };
};

interface IActionRemoveTab extends Action {
    type: 'REMOVE_TAB';
    barId: string;
}
export const removeTab = (barId: string): IActionRemoveTab => {
    return {
        type: REMOVE_TAB,
        barId,
    };
};

export type IActionTab = IActionAddTab | IActionRemoveTab;

interface IActionEntityFocusRemove extends Action {
    type: 'REMOVE_FOCUS';
    barId: string;
}

interface IActionEntityFocusAdd extends Action {
    type: 'ADD_FOCUS';
    barId: string;
    entityId: string;
}

export const removeEntityFocus = (barId: string): IActionEntityFocusRemove => {
    return {
        type: REMOVE_FOCUS,
        barId: barId,
    };
};

export const addEntityFocus = (entityId: string, barId: string): IActionEntityFocusAdd => {
    return {
        type: ADD_FOCUS,
        barId: barId,
        entityId,
    };
};

export type IActionEntityFocus = IActionEntityFocusAdd | IActionEntityFocusRemove;

interface IActionAddEdgeServiceAgentCardsCollapseState extends Action {
    type: 'ADD_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE';
    barId: string;
    status: EdgeServiceAgentCardsCollapseState;
}
export const addEdgeServiceAgentCardsCollapseState = (
    barId: string,
    status: EdgeServiceAgentCardsCollapseState,
): IActionAddEdgeServiceAgentCardsCollapseState => {
    return {
        type: ADD_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE,
        barId,
        status,
    };
};

interface IActionRemoveEdgeServiceAgentCardsCollapseState extends Action {
    type: 'REMOVE_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE';
    barId: string;
}
export const removeEdgeServiceAgentCardsCollapseState = (
    barId: string,
): IActionRemoveEdgeServiceAgentCardsCollapseState => {
    return {
        type: REMOVE_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE,
        barId,
    };
};

export type IActionEdgeServiceAgentCardsCollapseState =
    | IActionAddEdgeServiceAgentCardsCollapseState
    | IActionRemoveEdgeServiceAgentCardsCollapseState;

interface IActionPanelTabAdd extends Action {
    type: 'ADD_PANEL_SELECTION';
    selection: PanelTabName;
}

interface IActionPanelTabRemove extends Action {
    type: 'REMOVE_PANEL_SELECTION';
}

export const addPanelSelection = (panelTabName: PanelTabName): IActionPanelTabAdd => {
    return {
        type: ADD_PANEL_SELECTION,
        selection: panelTabName,
    };
};

export const removePanelSelection = (): IActionPanelTabRemove => {
    return {
        type: REMOVE_PANEL_SELECTION,
    };
};

export type IActionPanelTab = IActionPanelTabAdd | IActionPanelTabRemove;

interface IActionAddStepUpToken extends Action {
    type: 'ADD_STEPUP_TOKEN';
    arrayId: string;
    accessToken: string;
    expiresAt: moment.Moment;
}

export function addStepUpToken(arrayId: string, accessToken: string, expiresAt: moment.Moment): IActionAddStepUpToken {
    return {
        type: ADD_STEPUP_TOKEN,
        arrayId,
        accessToken,
        expiresAt,
    };
}

interface IActionRemoveExpiredStepUpTokens {
    type: 'REMOVE_EXPIRED_STEPUP_TOKENS';
    currentTime: moment.Moment;
}

export function removeExpiredStepUpTokens(currentTime: moment.Moment): IActionRemoveExpiredStepUpTokens {
    return {
        type: REMOVE_EXPIRED_STEPUP_TOKENS,
        currentTime,
    };
}

interface IActionRemoveAllStepUpTokens {
    type: 'REMOVE_ALL_STEPUP_TOKENS';
}

export function removeAllStepUpTokens(): IActionRemoveAllStepUpTokens {
    return {
        type: REMOVE_ALL_STEPUP_TOKENS,
    };
}

export type IActionStepUpToken =
    | IActionAddStepUpToken
    | IActionRemoveExpiredStepUpTokens
    | IActionRemoveAllStepUpTokens;

export interface IActionToggleDoNotShowModal extends Action {
    type: 'TOGGLE_DO_NOT_SHOW_MODAL';
    value: boolean;
}

export function toggleDoNotShowModal(value: boolean): IActionToggleDoNotShowModal {
    return {
        type: TOGGLE_DO_NOT_SHOW_MODAL,
        value,
    };
}

export interface IActionDisasterRecoveryToggleItem extends Action {
    type: 'DRAAS_TOGGLE_ITEM';
    groupId: string;
    itemId: string;
}

export function toggleItem(groupId: string, itemId: string): IActionDisasterRecoveryToggleItem {
    return {
        type: DRAAS_TOGGLE_ITEM,
        groupId: groupId,
        itemId,
    };
}

export type IActionDisasterRecoverySelectPolicy =
    | IActionSelectDisasterRecoveryPolicy
    | IActionDeselectDisasterRecoveryPolicy;

interface IActionSelectDisasterRecoveryPolicy extends Action {
    type: 'DRAAS_SELECT_POLICY';
    barId: string;
    policyId: string;
}

interface IActionDeselectDisasterRecoveryPolicy extends Action {
    type: 'DRAAS_DESELECT_POLICY';
    barId: string;
}

export function selectDisasterRecoveryPolicy(barId: string, policyId: string): IActionSelectDisasterRecoveryPolicy {
    return {
        type: DRAAS_SELECT_POLICY,
        barId,
        policyId,
    };
}

export function deselectDisasterRecoveryPolicy(barId: string): IActionDeselectDisasterRecoveryPolicy {
    return {
        type: DRAAS_DESELECT_POLICY,
        barId,
    };
}

export type IActionDisasterRecoverySelectPlan = IActionSelectDisasterRecoveryPlan | IActionDeselectDisasterRecoveryPlan;

interface IActionSelectDisasterRecoveryPlan extends Action {
    type: 'DRAAS_SELECT_PLAN';
    barId: string;
    plan: DisasterRecoveryPlan;
}

interface IActionDeselectDisasterRecoveryPlan extends Action {
    type: 'DRAAS_DESELECT_PLAN';
    barId: string;
}

export function selectDisasterRecoveryPlan(
    barId: string,
    plan: DisasterRecoveryPlan,
): IActionSelectDisasterRecoveryPlan {
    return {
        type: DRAAS_SELECT_PLAN,
        barId,
        plan,
    };
}

export function deselectDisasterRecoveryPlan(barId: string): IActionDeselectDisasterRecoveryPlan {
    return {
        type: DRAAS_DESELECT_PLAN,
        barId,
    };
}

export type IActionDisasterRecoverySelectGroupsView = IActionSelectDisasterRecoveryGroupsView;

interface IActionSelectDisasterRecoveryGroupsView extends Action {
    type: 'DRAAS_SELECT_GROUPS_VIEW';
    barId: string;
    viewId: 'card-view' | 'list-view';
}

export function selectDisasterRecoveryGroupsView(
    barId: string,
    viewId: 'card-view' | 'list-view',
): IActionSelectDisasterRecoveryGroupsView {
    return {
        type: DRAAS_SELECT_GROUPS_VIEW,
        barId,
        viewId,
    };
}

export type IActionDisasterRecoverySelectInventoryView = IActionSelectDisasterRecoveryInventoryView;

interface IActionSelectDisasterRecoveryInventoryView extends Action {
    type: 'DRAAS_SELECT_INVENTORY_VIEW';
    barId: string;
    viewId: 'vms-view' | 'tags-view';
}

export function selectDisasterRecoveryInventoryView(
    barId: string,
    viewId: 'vms-view' | 'tags-view',
): IActionSelectDisasterRecoveryInventoryView {
    return {
        type: DRAAS_SELECT_INVENTORY_VIEW,
        barId,
        viewId,
    };
}

export interface IActionDisasterRecoveryFilter extends Action {
    type: 'DRAAS_FILTER_VMS' | 'DRAAS_FILTER_VM_JOB_EXECUTIONS';
    barId: string;
    key: string;
    value: string | DisasterRecoveryTagCategory | DisasterRecoveryTag[];
}

interface IActionDisasterRecoveryAddVmsFilter extends IActionDisasterRecoveryFilter {
    type: 'DRAAS_FILTER_VMS';
    key: 'search' | 'tagCategory' | 'tag';
}

export function addDisasterRecoveryVmSearchFilter(barId: string, value: string): IActionDisasterRecoveryAddVmsFilter {
    return {
        type: DRAAS_FILTER_VMS,
        barId,
        key: 'search',
        value,
    };
}

export function addDisasterRecoveryVmTagCategoryFilter(
    barId: string,
    value: DisasterRecoveryTagCategory,
): IActionDisasterRecoveryAddVmsFilter {
    return {
        type: DRAAS_FILTER_VMS,
        barId,
        key: 'tagCategory',
        value,
    };
}

export function addDisasterRecoveryVmTagFilter(
    barId: string,
    value: DisasterRecoveryTag[],
): IActionDisasterRecoveryAddVmsFilter {
    return {
        type: DRAAS_FILTER_VMS,
        barId,
        key: 'tag',
        value,
    };
}

interface IActionDisasterRecoveryVmJobExecutionsFilter extends IActionDisasterRecoveryFilter {
    type: 'DRAAS_FILTER_VM_JOB_EXECUTIONS';
    key: 'group' | 'status';
}

export function addDisasterRecoveryVmJobExecutionGroupFilter(
    barId: string,
    value: string,
): IActionDisasterRecoveryVmJobExecutionsFilter {
    return {
        type: DRAAS_FILTER_VM_JOB_EXECUTIONS,
        barId,
        key: 'group',
        value,
    };
}

export function addDisasterRecoveryVmJobExecutionStatusFilter(
    barId: string,
    value: DisasterRecoveryJobExecutionStatus,
): IActionDisasterRecoveryVmJobExecutionsFilter {
    return {
        type: DRAAS_FILTER_VM_JOB_EXECUTIONS,
        barId,
        key: 'status',
        value,
    };
}

export type IActionDisasterRecoveryCluster =
    | IActionSelectDisasterRecoveryCluster
    | IActionDeselectDisasterRecoveryCluster;

interface IActionSelectDisasterRecoveryCluster extends Action {
    type: 'DRAAS_SELECT_CLUSTER';
    clusterId: string;
}

export function selectDisasterRecoveryCluster(clusterId: string): IActionSelectDisasterRecoveryCluster {
    return {
        type: DRAAS_SELECT_CLUSTER,
        clusterId,
    };
}

interface IActionDeselectDisasterRecoveryCluster extends Action {
    type: 'DRAAS_DESELECT_CLUSTER';
}

export function deselectDisasterRecoveryCluster(): IActionDeselectDisasterRecoveryCluster {
    return {
        type: DRAAS_DESELECT_CLUSTER,
    };
}

export interface IActionDisasterRecoverySelectReportType extends Action {
    type: 'DRAAS_SELECT_REPORT_TYPE';
    reportType: DisasterRecoveryReportType;
}

export function selectDisasterRecoveryReportType(
    reportType: DisasterRecoveryReportType,
): IActionDisasterRecoverySelectReportType {
    return {
        type: DRAAS_SELECT_REPORT_TYPE,
        reportType,
    };
}

export interface IActionDisasterRecoverySelectReport extends Action {
    type: 'DRAAS_SELECT_REPORT';
    reportId: string;
}

export function selectDisasterRecoveryReport(reportId: string): IActionDisasterRecoverySelectReport {
    return {
        type: DRAAS_SELECT_REPORT,
        reportId,
    };
}

interface IActionSetPerformanceAlert extends Action {
    type: 'SET_PERFORMANCE_ALERT';
    barId: string;
    alertId: string;
}

export function setPerformanceAlert(barId: string, alertId: string): IActionSetPerformanceAlert {
    return {
        type: 'SET_PERFORMANCE_ALERT',
        barId,
        alertId,
    };
}

interface IActionRemovePerformanceAlert extends Action {
    type: 'REMOVE_PERFORMANCE_ALERT';
    barId: string;
}

export function removePerformanceAlert(barId: string): IActionRemovePerformanceAlert {
    return {
        type: 'REMOVE_PERFORMANCE_ALERT',
        barId,
    };
}

export type IActionPerformanceAlert = IActionSetPerformanceAlert | IActionRemovePerformanceAlert;
