import { Resource } from '../interfaces/resource';

export class EdgeServiceAgentInstance implements Resource {
    id: string;
    name: string;
    displayName: string;
    status: string;
    update_status: string;
    running_version: string;
    available_version: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.displayName = json.display_name;
        this.status = json.health;
        this.update_status = json.update_status;
        this.running_version = json.running_version;
        this.available_version = json.available_version;
    }
}
