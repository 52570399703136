import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { UmStates, UserModalStateService } from './user-modal-state.service';
import { CachedCurrentUserService, CurrentUser } from '@pure1/data';
import { Subject, takeUntil } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';

const REGULAR_USER_MODAL = 'regular-user-modal';

@Component({
    selector: 'user-modal',
    templateUrl: 'user-modal.component.html',
    providers: [UserModalStateService],
})
export class UserModalComponent implements OnInit {
    private readonly destroy$ = new Subject<void>();

    currentUser: CurrentUser;
    canSeeUserManagement: boolean;

    constructor(
        public userModalStateService: UserModalStateService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private authzServiceResolver: AuthorizationServiceResolver,
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.authzServiceResolver
            .getDefaultService()
            .pipe(
                switchMap(service => service.hasPermission('PURE1:view:users')),
                takeUntil(this.destroy$),
            )
            .subscribe(canSeeUserManagement => {
                this.canSeeUserManagement = canSeeUserManagement;
                this.initializeModalState();
            });

        this.cachedCurrentUserService
            .get()
            .pipe(takeUntil(this.destroy$))
            .subscribe(user => (this.currentUser = user));
    }

    private initializeModalState() {
        if (this.userModalStateService.state !== UmStates.UM) {
            // If the state is already set to something other than the default dummy, leave it as-is.
        } else if (this.canSeeUserManagement) {
            this.userModalStateService.state = UmStates.Admin;
        } else {
            this.renderer.addClass(this.el.nativeElement, REGULAR_USER_MODAL);
            this.userModalStateService.state = UmStates.Regular;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
