import { Resource } from '../interfaces/resource';
import { DraasApiServiceLevelPolicy, DraasApiReplicationTarget } from '@pure/paas-api-gateway-client-ts';
import moment, { Duration } from 'moment';

export class DisasterRecoveryPolicy implements Resource {
    id: string;
    name: string;
    description: string;
    replicationTarget: DisasterRecoveryReplicationTarget;
    rpo: Duration;
    estimatedRto: Duration;
    localRetention: Duration;
    drRetention: Duration;
    groupCount: number;
    version: number;

    constructor(json: DraasApiServiceLevelPolicy) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.replicationTarget = mapReplicationTarget(json.replication_target);
        this.rpo = moment.duration(json.rpo);
        this.estimatedRto = moment.duration(json.estimated_rto);
        this.localRetention = moment.duration(json.local_retention);
        this.drRetention = moment.duration(json.dr_retention);
        this.groupCount = json.group_count;
        this.version = json.version;
    }
}

export interface DisasterRecoveryReplicationTarget {
    clusterId: string;
    providerId: string;
    name: string;
}

const mapReplicationTarget = (json: DraasApiReplicationTarget): DisasterRecoveryReplicationTarget => ({
    clusterId: json.cluster_id,
    providerId: json.provider_id,
    name: json.name,
});
