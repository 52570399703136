import {
    Coordinates,
    SustainabilityArray,
    SustainabilityArrayAssessmentLevel,
    SustainabilityInsightSeverity,
    SustainabilitySubscription,
} from './sustainability-array';
import { Resource } from '../interfaces/resource';

export type SustainabilityDatacenterInsight = { appliances: SustainabilityArray[] } & Record<
    SustainabilityInsightSeverity,
    number
>;
export const defaultInsights = {
    [SustainabilityArrayAssessmentLevel.GOOD]: undefined,
    [SustainabilityArrayAssessmentLevel.RECOMMENDATION]: undefined,
    [SustainabilityArrayAssessmentLevel.ACTION_REQUIRED]: undefined,
};

export class SustainabilityDatacenter implements Resource {
    name: string;
    id: string;
    coordinates: Coordinates;
    address: string;
    totalPeakPower: number;
    totalPeakHeat: number;
    totalActualPower: number;
    totalActualHeat: number;
    totalRackUnits: number;
    totalShelves: number;
    totalEffectiveTiBAverage: number;
    totalUsableSpaceTibAverage: number;
    totalCountedAppliances: number;
    notPhoningHomeAppliances: number;
    notEnoughDataAppliances: number;
    unsupportedPurityAppliances: number;
    appliances: SustainabilityArray[] = [];
    subscriptions: SustainabilitySubscription[] = [];
    insights: Record<
        | SustainabilityArrayAssessmentLevel.GOOD
        | SustainabilityArrayAssessmentLevel.RECOMMENDATION
        | SustainabilityArrayAssessmentLevel.ACTION_REQUIRED,
        SustainabilityDatacenterInsight
    > = { ...defaultInsights };

    constructor(response: any) {
        this.id = response.data_center_id;
        this.coordinates = new Coordinates(response.coordinates);
        this.address = response.address;
        this.totalPeakPower = response.total_peak_power;
        this.totalPeakHeat = response.total_peak_heat;
        this.totalActualPower = response.total_actual_power;
        this.totalActualHeat = response.total_actual_heat;
        this.totalRackUnits = response.total_rack_units;
        this.totalShelves = response.total_shelves;
        this.totalEffectiveTiBAverage = response.total_effective_tib_average;
        this.totalUsableSpaceTibAverage = response.total_usable_space_tib_average;
        this.subscriptions = response.subscriptions.map(sub => new SustainabilitySubscription(sub));
        this.totalCountedAppliances = 0;
        this.notPhoningHomeAppliances = 0;
        this.notEnoughDataAppliances = 0;
        this.unsupportedPurityAppliances = 0;

        // appliances and insights are filled later

        //Just for satisfying the Resource interface
        this.name = response.data_center_id;
    }
}
