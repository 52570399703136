import moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { smartTimer } from '@pstg/smart-timer';

@Component({
    selector: 'countdown-timer',
    templateUrl: 'countdown-timer.component.html',
})
export class CountdownTimerComponent implements OnChanges, OnDestroy {
    @Input() readonly duration: moment.Duration;
    @Input() readonly countdown: boolean;

    @Output() readonly timerElapsed: EventEmitter<void> = new EventEmitter();

    secondsDisplay = 0;
    minutesDisplay = 0;

    private timerSubscription: Subscription;
    private previousTime: number;
    private secondsRemaining: number;

    constructor() {}

    ngOnDestroy(): void {
        this.stopTimer();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.duration && this.duration) {
            this.secondsRemaining = this.duration.asSeconds();
            this.secondsDisplay = this.duration.seconds();
            this.minutesDisplay = this.duration.minutes();
        }

        if (changes.countdown && this.countdown) {
            this.stopTimer();
            this.secondsRemaining = this.duration.asSeconds();

            this.previousTime = moment().valueOf();
            this.timerSubscription = smartTimer(1000, 1000).subscribe(() => {
                const currentTime = moment().valueOf();
                const delta = (currentTime - this.previousTime) / 1000;
                this.previousTime = currentTime;

                this.secondsRemaining -= delta;
                const currentDuration = moment.duration(this.secondsRemaining, 'seconds');
                this.secondsDisplay = currentDuration.seconds();
                this.minutesDisplay = currentDuration.minutes();
                if (this.secondsRemaining <= 0) {
                    this.timerSubscription.unsubscribe();
                    this.timerElapsed.emit();
                }
            });
        } else if (changes.countdown && !this.countdown) {
            this.stopTimer();
        }
    }

    displayValue(val: number): string {
        return val < 10 ? `0${val}` : `${val}`;
    }

    private stopTimer(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }
}
