<div class="entity-selection-button open">
    <button
        type="button"
        class="btn btn-secondary selection-button with-caret"
        [ngClass]="{ up: isSelectionsExpanded }"
        (click)="toggleSelections()"
        [disabled]="entities.length === 0"
    >
        <div class="pull-left">
            <ng-container *ngIf="displayTextOverride == null; else customDisplayText">
                {{ entityName }}
                <span class="count">({{ entities.length }})</span>
            </ng-container>
            <ng-template #customDisplayText>
                {{ displayTextOverride }}
            </ng-template>
        </div>
    </button>

    <ul *ngIf="isSelectionsExpanded" class="selection-list" [ngClass]="{ readonly: !isEditable && !isSelectable }">
        <li *ngIf="isEditable">
            <span class="clear-all" (click)="clickClearAll()"> Clear all ({{ entities.length }}) </span>
        </li>

        <li
            *ngFor="let entity of entities | slice: 0 : ITEM_DISPLAY_LIMIT; trackBy: 'id' | trackByProperty"
            class="selection-list-item"
            [ngClass]="{ 'non-editable-item': !isEditable && !isSelectable }"
        >
            <div>
                <div
                    *ngIf="isEditable || isSelectable; else nonEditableListItem"
                    (click)="clickEntity(entity)"
                    class="btn btn-secondary btn-block text-xs-left row-button"
                    [ngClass]="{ 'btn-no-hover': !isSelectable, active: isSelected(entity) }"
                >
                    <span
                        class="entity-color"
                        *ngIf="entityHasColor(entity)"
                        [ngStyle]="{ background: getEntityColor(entity) }"
                    ></span>
                    <span class="entity-name">{{ $any(entity).fqdn || entity.name }}</span>
                    <span class="entity-remove pstg-action-icon" (click)="deselectEntity($event, entity)">
                        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="9"></pureui-svg>
                    </span>
                </div>
                <ng-template #nonEditableListItem>
                    <span class="entity-name">{{ entity.name }}</span>
                </ng-template>
            </div>
        </li>

        <li *ngIf="entities.length > ITEM_DISPLAY_LIMIT" class="more-items">
            …{{ entities.length - ITEM_DISPLAY_LIMIT }} more not shown
        </li>
    </ul>
</div>
