import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortParams } from '../interfaces/list-params';
import { UnifiedArray } from '../models/unified-array';

import { GenericService } from './generic.service';

const unifiedArrayFields: getArraysFields[] = [
    'appliance_id',
    'array_id',
    'is_current',
    'last_updated',
    'hostname',
    'os',
    'version',
    'install_address',
    'install_address_requested',
    'org_id',
    'model',
    'product',
    'fqdn',
    'contract_expiration_date',
    'contract_renewal_status',
    'contract_start_date',
    'contract_status',
    'contract_last_renew_requested',
    'safe_mode_global',
    'safe_mode_object_store',
    'safe_mode_file_systems',
    'safe_mode_storage_weight',
    'safe_mode_status_weight',
    'safe_mode_timer_weight',
    'contract_status_code',
    'evergreen_tier',
    'support_tier',
    'chassis_serial_number',
    'shelf_serial_number',
    'capacity_analysis',
    'load_analysis',
    'city',
    'country',
    'purearray_list_space',
    'end_of_life_hardware',
    'has_end_of_life_hardware',
    'capacity_expandable',
    'support_expiration_date',
];
export const unifiedArrayDefaultParams = {
    pageStart: 0,
    pageSize: 1000,
    sort: <SortParams>{
        key: 'hostname',
        order: 'asc',
    },
    fields: unifiedArrayFields,
};

@Injectable({ providedIn: 'root' })
export class UnifiedArrayService extends GenericService<UnifiedArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: UnifiedArray,
            defaultParams: unifiedArrayDefaultParams,
            endpoint: '/rest/v3/arrays',
            update: true,
        });
    }
}
