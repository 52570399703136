import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'formatDataReduction' })
export class FormatDataReductionPipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');

    transform(dataReduction: number): string {
        if (dataReduction && dataReduction > 0) {
            return `${FormatDataReductionPipe.decimalPipe.transform(dataReduction, '1.1-1')} to 1`;
        }
        return null;
    }
}
