import { DraasApiRecoveryPlan, DraasApiRecoveryPlanWithLastExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { Resource } from '../interfaces/resource';
import moment from 'moment/moment';

export type DisasterRecoveryPlanState =
    | 'NOT_IN_FAILOVER_MODE'
    | 'TRANSITIONING_TO_FAILOVER_MODE'
    | 'SUCCESSFUL_FAILOVER_MODE'
    | 'TEARING_DOWN_FAILOVER_MODE'
    | 'TRANSITIONING_TO_FAILBACK_MODE'
    | 'SUCCESSFUL_FAILBACK_MODE'
    | 'CRITICAL_FAILURE';

export class DisasterRecoveryPlan implements Resource {
    id: string;
    clusterId: string;
    name: string;
    description: string;
    planType: 'PRODUCTION' | 'TEST';
    scaleNumber: number;
    planState: DisasterRecoveryPlanState;
    groupIds: string[];
    version: number;
    lastExecutionTime?: moment.Moment;
    lastExecutionStatus?: string;

    constructor(json: DraasApiRecoveryPlan | DraasApiRecoveryPlanWithLastExecutionInfo) {
        if (hasRecoveryPlan(json)) {
            this.lastExecutionTime = json.execution_info ? moment.utc(json.execution_info.start) : null;
            this.lastExecutionStatus = json.execution_info?.status;
            json = json.recovery_plan;
        }
        this.id = json.id;
        this.clusterId = json.cluster_id;
        this.name = json.name;
        this.description = json.description;
        this.planType = json.plan_type;
        this.scaleNumber = json.scale_number;
        this.planState = json.plan_state;
        this.groupIds = json.group_ids;
        this.version = json.version;
    }
}

function hasRecoveryPlan(
    json: DraasApiRecoveryPlan | DraasApiRecoveryPlanWithLastExecutionInfo,
): json is DraasApiRecoveryPlanWithLastExecutionInfo {
    return (json as DraasApiRecoveryPlanWithLastExecutionInfo).recovery_plan !== undefined;
}
