import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ArrayContractStatus, ArraySafeModeStatus, PGroupSafemode, Product, UnifiedArray } from '@pure1/data';
import { IStateFilter } from '../redux/pure-redux.service';
import { getFiltersUrlParams } from '../redux/utils';

export const SAFEMODE_PREFIX_URL = '/overview/assessment/safemode';
export const SAFEMODE_ENABLED_FILTERS = [
    { entity: 'safeMode', key: 'safe_mode_array_status', value: 'enabled', id: null, namespace: null },
];
export const SAFEMODE_PARTIAL_FILTERS = [
    { entity: 'safeMode', key: 'safe_mode_array_status', value: 'partial', id: null, namespace: null },
];
export const SAFEMODE_UPGRADE_REQ_FILTERS = [
    { entity: 'safeMode', key: 'safe_mode_array_status', value: 'upgrade_required', id: null, namespace: null },
];
export const SAFEMODE_DISABLED_FILTERS = [
    { entity: 'safeMode', key: 'safe_mode_array_status', value: 'disabled', id: null, namespace: null },
];
const SAFEMODE_NULL_FILTERS = [{ entity: 'safeMode', key: null, value: null, id: null, namespace: null }];

export type SafeModeStatusString = 'Enabled' | 'Partial' | 'Upgrade Required' | 'Disabled' | 'Granular Enabled';

export function isUpgradeRequired(array: UnifiedArray): boolean {
    if (!array) {
        return false;
    }
    if (isFlashArray(array)) {
        return array.safeMode.global.status === 'Upgrade Required';
    } else if (array.product === Product.FB) {
        return (
            array.safeMode.fileSystems.status === 'Upgrade Required' ||
            array.safeMode.objectStore.status === 'Upgrade Required'
        );
    } else {
        return true;
    }
}

export function isArraySafeModeFullyEnabled(array: UnifiedArray): boolean {
    return array?.safeModeStatus === 'Enabled';
}

export function isArrayConnected(array: UnifiedArray): boolean {
    if (array?.safeModeStatus === 'Enabled' || array?.safeModeStatus === 'Partial') {
        return true;
    } else if (isFlashArray(array)) {
        return array?.safeMode?.global?.connected;
    } else {
        return array?.safeMode?.fileSystems?.connected && array?.safeMode?.objectStore?.connected;
    }
}

export function getSafeModeStatusString(array: UnifiedArray): SafeModeStatusString {
    return getSafeModeStatusStringFromStatus(array?.safeModeStatus);
}

export function getSafeModeStatusStringFromStatus(arraySafeModeStatus: ArraySafeModeStatus): SafeModeStatusString {
    if (!arraySafeModeStatus || arraySafeModeStatus === '-') {
        return 'Disabled';
    } else {
        return arraySafeModeStatus;
    }
}

/**
 * Returns the value between 0 and 100 which represents the percentage
 * @param total - the total number of items
 * @param portion - the number of items that are part of the total
 * @param significantDigits - the number of significant digits to round to, 0 means round to the nearest integer
 */
export function getPercentage(total: number, portion: number, significantDigits = 0): number {
    let result = (portion * 100) / total;
    result = isFinite(result) ? result : 0;
    if (significantDigits === 0) {
        return Math.round(result);
    } else {
        // use rounding to significant digits
        return Number(result.toPrecision(significantDigits));
    }
}

export function getSafeModeApplianceFilter(applianceName: string): IStateFilter[] {
    return [{ entity: 'arrays', key: 'array_name', value: applianceName, id: null, namespace: null }];
}

export function getSafeModeStateFilter(status: SafeModeStatusString): IStateFilter[] {
    let filter = SAFEMODE_NULL_FILTERS; // shouldn't actually get these filters for now, default to 'Disabled' on unknown status
    switch (status) {
        case 'Enabled':
            filter = SAFEMODE_ENABLED_FILTERS;
            break;
        case 'Partial':
            filter = SAFEMODE_PARTIAL_FILTERS;
            break;
        case 'Upgrade Required':
            filter = SAFEMODE_UPGRADE_REQ_FILTERS;
            break;
        case 'Disabled':
            filter = SAFEMODE_DISABLED_FILTERS;
            break;
        default:
    }
    return filter;
}

export function getSafeModeLink(filter: IStateFilter[]): string {
    //TODO: When deprecating SafeMode tab completely, change this to Data Protection link
    return `${SAFEMODE_PREFIX_URL}?filter=${encodeURIComponent(getFiltersUrlParams(filter))}`;
}

export function isFlashArray(array: UnifiedArray): boolean {
    return array.product === Product.FA;
}

export function isOutOfSupport(array: UnifiedArray): boolean {
    return array.contract_status === ArrayContractStatus.EXPIRED;
}

export function getDurationDays(duration: moment.Duration): string {
    if (!duration) {
        return '-';
    }
    const days = Math.floor(duration.asDays());
    return `${days} day${days > 1 ? 's' : ''}`;
}

export function getUniqueArrayIdsOfArraysAndPGroups(arrays: UnifiedArray[], pgroups: PGroupSafemode[]): Set<string> {
    const appliancesIds = pgroups.map(pgroup => pgroup.arrays[0].id).concat(arrays.map(array => array.id));
    return new Set(appliancesIds);
}

export function getDayAsMoment(day: NgbDateStruct, timezone: string): moment.Moment {
    return moment.tz(
        {
            year: day.year,
            month: day.month - 1,
            day: day.day,
        },
        timezone,
    );
}
