import { Resource } from '../interfaces/resource';

export class PxResource implements Resource {
    name: string;
    id: string;

    capacityUsed: number;
    diskUsage: number;
    diskUsed: number;
    diskTotal: number;

    constructor(json: any) {
        this.capacityUsed = json.capacity_used;
        this.diskUsage = json.disk_usage;
        this.diskUsed = json.disk_used;
        this.diskTotal = json.disk_total;
    }
}
