import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { ServiceCatalogOffering } from './service-catalog-offering';

export class ServiceCatalogGroup implements Resource {
    id: string;
    name: string;
    groupName: string;
    serviceName: string;
    labels: string[];
    modelVersion: number;
    ordinal: number;
    lastUpdated: moment.Moment;
    offerings: ServiceCatalogOffering[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.group_name;
        this.groupName = json.group_name;
        this.serviceName = json.service_name;
        this.labels = json.labels;
        this.modelVersion = json.model_version;
        this.lastUpdated = moment(json.last_updated);
        this.offerings = this.parseAllOfferings(json.offerings, json.group_name, json.service_name);
    }

    private parseAllOfferings(json: any[], group_name: string, service_name: string): ServiceCatalogOffering[] {
        if (!json || !group_name || !service_name) {
            return null;
        }
        return json.map(offeringJson => new ServiceCatalogOffering(offeringJson, group_name, service_name));
    }
}
