<div class="banner-icon">
    <pureui-svg svg="eol-carousel-badge.svg" [height]="83.25"></pureui-svg>
</div>
<div class="banner-content">
    <div class="banner-graphic">
        <pureui-svg [svg]="notification.image" width="60"></pureui-svg>
    </div>
    <div class="banner-text">
        <div class="banner-title">
            {{ notification.title }}
        </div>
        <br />
        <div class="banner-body">
            {{ notification.body }}
        </div>
    </div>
    <div class="banner-actions">
        <ng-container *ngIf="notification.buttonTemplate; else pageRedirectButton">
            <ng-container *ngTemplateOutlet="notification.buttonTemplate"></ng-container>
        </ng-container>
        <ng-template #pageRedirectButton>
            <a
                *ngIf="notification.path"
                class="btn btn-primary try-button"
                [routerLink]="notification.path"
                [queryParams]="{ filter: getHwEolFilters() }"
                angulartics2On="click"
                angularticsCategory="Action"
                angularticsAction="EOL Banner - Try it out"
                [angularticsLabel]="notification.title"
            >
                Try it out
            </a>
        </ng-template>
    </div>
</div>
