import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SubscriptionAsset } from '@pure1/data';

@Injectable()
export class AssetManagementService {
    readonly openTagsModal$ = new Subject<SubscriptionAsset>();
    readonly updateAssets$ = new Subject<void>();
    readonly updateTags$ = new BehaviorSubject<void>(null);

    getOpenTagsModal(): Observable<SubscriptionAsset> {
        return this.openTagsModal$.asObservable();
    }

    openTagsModal(selectedAsset: SubscriptionAsset): void {
        this.openTagsModal$.next(selectedAsset);
    }

    getUpdateAssets(): Observable<void> {
        return this.updateAssets$.asObservable();
    }

    updateAssets(): void {
        this.updateAssets$.next();
    }

    getUpdateTags(): Observable<void> {
        return this.updateTags$.asObservable();
    }

    updateTags(): void {
        this.updateTags$.next();
    }
}
