import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAlertBodyResource } from '../../../utils/legacy-alerts';
import { FArrayAlert } from '../../../model/FArrayAlert';
import { DpaAlert } from '../../../model/DpaAlert';
import { AlertOrigin, DataProtectionV2Service } from '@pure1/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService, ToastType } from '../../../services/toast.service';

@Component({
    selector: 'messages-modal-component',
    templateUrl: './messages-modal.component.html',
})
export class MessagesModalComponent implements OnDestroy {
    _message: IAlert & IAlertBodyResource & FArrayAlert;
    get message(): IAlert & IAlertBodyResource & FArrayAlert {
        return this._message;
    }
    set message(value: IAlert & IAlertBodyResource & FArrayAlert) {
        this._message = value;
        this.isDataProtectionAlert = value.code === 2024 || value.code === 2025;
        if (this.isDataProtectionAlert) {
            this.dataProtectionAlert = this.message instanceof DpaAlert ? this.message : new DpaAlert(value);
        }
    }
    @Input() knowledgeBaseLink: string;

    isDataProtectionAlert = false;
    dataProtectionAlert: DpaAlert;
    closeInProgress = false;
    readonly alertOrigin = AlertOrigin;

    private destroy$ = new Subject<void>();

    constructor(
        public activeModal: NgbActiveModal,
        private dataProtectionV2Service: DataProtectionV2Service,
        private toast: ToastService,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    closeDpaAlert(): void {
        this.closeInProgress = true;
        this.dataProtectionV2Service
            .closeDpaAlert(
                this.message.id,
                this.dataProtectionAlert.arrayId,
                this.dataProtectionAlert.applianceType,
                this.message.code,
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this.toast.add(
                        ToastType.success,
                        'Alert ' + this.message.id + ' has been successfully submitted to be closed',
                    );
                    this.closeInProgress = false;
                    this.activeModal.dismiss();
                },
                () => {
                    this.toast.add(ToastType.error, 'Alert ' + this.message.id + ' failed to be closed');
                    this.closeInProgress = false;
                },
            );
    }
}
