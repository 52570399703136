import moment from 'moment';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SlaIndicatorStatus } from '@pure1/data';

export const STATUS_SVGS_PREFIX: Readonly<Map<SlaIndicatorStatus, string>> = Object.freeze(
    new Map([
        ['OK' as SlaIndicatorStatus, 'sla-indicator-on-target'],
        ['AT_RISK' as SlaIndicatorStatus, 'sla-indicator-at-risk'],
        ['UNKNOWN' as SlaIndicatorStatus, 'sla-indicator-unknown'],
        ['INELIGIBLE' as SlaIndicatorStatus, 'sla-indicator-ineligible'],
    ]),
);

@Component({
    selector: 'sla-status-icon',
    templateUrl: 'sla-status-icon.component.html',
})
export class SlaStatusIconComponent implements OnChanges {
    @Input() readonly status: SlaIndicatorStatus;
    @Input() readonly height: number;
    @Input() readonly startDate: moment.Moment;
    @Input() readonly endDate: moment.Moment;

    svg: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.status || changes.startDate || changes.endDate) {
            if (this.status) {
                if (this.status === 'INELIGIBLE') {
                    this.svg = `${STATUS_SVGS_PREFIX.get(this.status)}.svg`;
                } else if (this.startDate && this.endDate) {
                    const endMonthDate = this.startDate.clone().add(1, 'month');
                    const progressValue = this.endDate.diff(this.startDate) / endMonthDate.diff(this.startDate);

                    let svgSuffix = '';
                    if (progressValue <= 0.25) {
                        svgSuffix = '-25';
                    } else if (progressValue <= 0.5) {
                        svgSuffix = '-50';
                    } else if (progressValue < 100) {
                        svgSuffix = '-75';
                    }
                    this.svg = `${STATUS_SVGS_PREFIX.get(this.status)}${svgSuffix}.svg`;
                } else {
                    // default to full
                    this.svg = `${STATUS_SVGS_PREFIX.get(this.status)}.svg`;
                }
            }
        }
    }
}
