import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemType } from '../support.interface';

export interface Attachment {
    file: File;
    name: string;
    contentType: string;
    itemType: ItemType.Attachment;
}

@Component({
    selector: 'upload-modal',
    templateUrl: './upload-modal.component.html',
})
export class UploadModalComponent implements OnInit {
    @Input() files: File[];
    @Output() onFileSubmitted = new EventEmitter<Attachment>();
    fileName: string;
    multiple: boolean;
    current: File;
    currentUrl: string;
    currentIndex = 0;
    total: number;

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.multiple = this.files.length > 1;
        this.total = this.files.length;
        this.setCurrentFile();
    }

    setCurrentFile() {
        const file = this.files[this.currentIndex];
        this.current = file;
        this.fileName = this.shortenMiddle(file.name, 50);
        this.currentUrl = '';

        if (file.type.includes('image')) {
            const reader = new FileReader();
            reader.onload = () => {
                this.currentUrl = reader.result as string;
            };
            reader.readAsDataURL(file);
        }
    }

    submit(): void {
        this.onFileSubmitted.emit({
            file: this.current,
            name: this.fileName.replace(/\s/g, '_'),
            itemType: ItemType.Attachment,
            contentType: this.current.type !== '' ? this.current.type : 'application/octet-stream',
        });
        this.currentIndex++;
        if (this.currentIndex < this.files.length) {
            this.setCurrentFile();
        } else {
            this.activeModal.close();
        }
    }

    dismiss(): void {
        this.currentIndex++;
        if (this.currentIndex < this.files.length) {
            this.setCurrentFile();
        } else {
            this.activeModal.close();
        }
    }

    dismissAll(reason): void {
        this.activeModal.dismiss(reason);
    }

    /**
     * Shortening the given string to fit into the maximum length.
     * Beginning and ending parts of the string remain, while the middle part is removed.
     * Example:
     *      shortenMiddle("short", 10) => "short"
     *      shortenMiddle("someLongInput", 10) => "som...nput"
     *
     * @param str
     * @param maxLen
     */
    shortenMiddle(str, maxLen): string {
        if (str.length <= maxLen) {
            return str;
        }
        const middleSubstitute = '...';
        const leftLength = (maxLen - middleSubstitute.length) / 2;
        const rightLength = maxLen - middleSubstitute.length - leftLength;
        return str.substr(0, leftLength) + middleSubstitute + str.substr(str.length - rightLength);
    }
}
