import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class LazyLoadingScriptService {
    private scripts = new Map<string, BehaviorSubject<number>>();

    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     *
     * @param url
     *
     * @returns the progress done for loading the script
     * 0 is script has not started to download or is downloading
     * 1 when script is finished downloading
     * throw an error if the script cannot be downloaded
     */
    loadScript(url: string): Observable<number> {
        if (this.scripts.has(url)) {
            return this.scripts.get(url).asObservable();
        }

        const scriptLoaded$ = new BehaviorSubject<number>(0);
        this.scripts.set(url, scriptLoaded$);

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onload = () => {
            scriptLoaded$.next(1);
        };
        script.onerror = () => {
            scriptLoaded$.error('cannot load script');
        };
        script.onabort = () => {
            scriptLoaded$.error('Loading script aborted');
        };

        this.document.body.appendChild(script);
        return scriptLoaded$.asObservable();
    }
}
