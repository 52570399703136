<div class="scheduler-container">
    <div *ngIf="timeSlotConflict" class="alert alert-warning"
        >The time slot you selected is no longer available. Please select a different time slot.</div
    >
    <div *ngIf="encourageCustomerToCallSupport" class="alert alert-warning">
        A change request for an appliance with high utilization detected. In case of no available slots to mitigate an
        emergency, please contact Pure Storage Technical Services by phone for assistance -
        <a href="https://support.purestorage.com/Pure1/Support" target="_blank"> Pure Storage phone number reference</a
        >.
    </div>
    <ng-template #loader>
        <div class="loader-column">
            <div class="row loader">
                <pureui-spinner [pureuiShow]="true"></pureui-spinner>
            </div>
        </div>
    </ng-template>
    <div *ngIf="!loading; else loader" class="pick-date">
        <div class="available-dates">
            <hive-dropdown
                (selectionChange)="changeSelectedTimeZone($event)"
                [ngModel]="selectedTimeZone"
                size="small"
                class="mb-1"
            >
                <hive-selection-menu-item
                    *ngFor="let timezone of timezones"
                    [label]="timezone.label"
                    [value]="timezone.value"
                ></hive-selection-menu-item>
            </hive-dropdown>
            <ngb-datepicker
                navigation="arrows"
                [(ngModel)]="selectedDate"
                [ngModelOptions]="{ standalone: true }"
                (dateSelect)="getTimeslotsForSelectedDate()"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [dayTemplate]="t"
            ></ngb-datepicker>
            <ng-template #t let-date let-currentMonth="currentMonth" let-disabled="disabled" let-focused="focused">
                <div
                    class="custom-day"
                    [class.disabled]="disabled"
                    [class.focused]="focused"
                    [class.outside]="date.month !== currentMonth"
                    [class.selected]="date.equals(selectedDate)"
                >
                    {{ date.day }}
                </div>
            </ng-template>
        </div>
        <div class="available-timeslots" *ngIf="timeslotsForSelectedDay && selectedDate">
            <div class="selected-day">
                <button type="button" class="btn previous-day btn-link" (click)="changeSelectedDate(-1)">
                    <pureui-svg class="pstg-action-icon" [svg]="'chevron-left.svg'" [height]="10"></pureui-svg>
                </button>
                <div class="d-inline-block printed-date">
                    {{ printDate() }}
                </div>
                <button type="button" class="btn next-day btn-link" (click)="changeSelectedDate(1)">
                    <pureui-svg class="pstg-action-icon" [svg]="'chevron-right.svg'" [height]="10"></pureui-svg>
                </button>
            </div>
            <div *ngIf="timeslotsForSelectedDay.length === 0" class="no-timeslot">
                No timeslots available for the current date.
            </div>
            <div
                role="radiogroup"
                [ngStyle]="{ height: heightOfScrollBar.toString() + 'px' }"
                class="btn-group btn-group-toggle timeslot-button"
            >
                <div *ngFor="let timeSlot of timeslotsForSelectedDay">
                    <div class="m-1">
                        <label
                            class="btn btn-outline-primary btn-lg btn-block"
                            [class.active]="timeSlot.startTime === selectedTime"
                            (click)="selectTime(timeSlot)"
                        >
                            <input type="radio" />
                            <div class="custom-timeslot">
                                <span>
                                    {{ timeSlot | getPrettyTime: selectedTimeZone }}
                                    (1 hrs)
                                </span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="btn-toolbar clearfix">
    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        (click)="continue()"
        [disabled]="selectedTime == null"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode self service - Planner - Continue schedule requeset"
    >
        Continue &gt;
    </button>

    <button
        type="button"
        class="btn btn-primary float-xs-right margin"
        (click)="onBack.emit()"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode self service - Planner - Go back during schedule request"
    >
        &lt; Back
    </button>
    <button
        type="button"
        class="btn btn-info float-xs-right margin"
        (click)="onCancel.emit()"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Assessment - SafeMode self service - Planner - Cancel schedule request"
    >
        Cancel
    </button>
</div>
