import moment from 'moment';
import { CapacityConfig } from './capacity-config';
import { Resource } from '../interfaces/resource';

export type RecommendationAction = { controllerModel: string; capacityConfig: CapacityConfig };

export type ArrayEolComponentType = 'ct' | 'sh' | 'ch';

export class ArrayEolComponent {
    capacity: number;
    endOfSupportDate: moment.Moment;
    flag: string;
    protocol: string;
    serialNumber: string;
    type: ArrayEolComponentType;

    constructor(json: any) {
        this.capacity = json.capacity;
        this.endOfSupportDate = moment(json.end_of_support_date);
        this.flag = json.flag;
        this.protocol = json.protocol;
        this.serialNumber = json.serial_number;
        this.type = json.type;
    }
}

export class ArrayEolComponentInfo implements Resource {
    id: string;
    name: string;
    arrayId: string;
    components: ArrayEolComponent[];
    currentModel: string;
    earliestEolDate: moment.Moment;
    recommendationAction: RecommendationAction;
    recommendationSKU: string;
    salesforceAccountId: string;

    constructor(json: any) {
        this.id = json.array_id;
        this.name = undefined; // no name for the array is included in the response
        this.arrayId = json.array_id;
        this.components =
            json.components && json.components.length > 0
                ? json.components.map(component => new ArrayEolComponent(component))
                : [];
        this.currentModel = json.current_model;
        this.earliestEolDate = moment(json.earliest_eol_date);
        if (json.recommendation_action) {
            this.recommendationAction = {
                controllerModel: json.recommendation_action.controller_model,
                capacityConfig: new CapacityConfig(json.recommendation_action.capacity_config),
            };
        } else {
            this.recommendationAction = null;
        }
        this.recommendationSKU = json.recommendation_sku;
        this.salesforceAccountId = json.salesforce_account_id;
    }
}
