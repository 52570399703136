import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ResourceStatus, UnifiedArray } from '@pure1/data';
import { orderBy } from 'lodash';
import { MessagesModalService } from '../../messages/alerts/messages-modal/messages-modal.service';

import { severity } from '../../utils/severity';

@Component({
    selector: 'array-card-back',
    templateUrl: 'array-card-back.component.html',
})
export class ArrayCardBackComponent implements OnChanges {
    @Input() readonly array: UnifiedArray;
    @Input() readonly alerts: IAlert[];
    /**
     * [CLOUD-112131] Provides a way to unload the internal elements of the component while leaving the component itself in-place.
     * This is a hack to let the view be lazy-loaded while still retaining the outer container so that it retains
     * the same spacing/layout whether or not the content is shown.
     * This is purely an optimization. If you don't know what it is for or care about it, you can safely leave it unset.
     */
    @Input() readonly removeInternalsFromDom: boolean = false;
    @Output() readonly onFlipCard = new EventEmitter<{ event: Event }>();

    readonly ResourceStatus = ResourceStatus;

    sortedAlerts: IAlert[];

    constructor(private messagesModal: MessagesModalService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.alerts) {
            this.updateSortedAlerts();
        }
    }

    flipCard(event: Event): void {
        this.onFlipCard.emit({ event: event });
    }

    alertCount(): number {
        return (this.alerts || []).length + (this.hasEolComponents() ? 1 : 0);
    }

    clickAlert(alert: IAlert): void {
        this.messagesModal.open(alert, alert.getKBLink());
    }

    hasEolComponents(): boolean {
        return this.array.has_end_of_life_hardware;
    }

    private updateSortedAlerts(): void {
        this.sortedAlerts = orderBy(this.alerts || [], [
            alert => severity(alert.currentSeverity).value,
            alert => -alert.updated,
        ]);
    }
}
