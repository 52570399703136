import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * By default, HttpClient will translate values set to null or undefined into the respective string "null" or
 * "undefined", making it annoying to support conditional params.
 * This interceptor modifies http requests to instead not include null/undefined values as a url param.
 */
@Injectable()
export class HttpUndefinedParamsInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.params) {
            let newParams = request.params;

            // Delete each key with an undefined value
            newParams.keys().forEach(key => {
                const value = newParams.get(key);
                // Unfortunately, starting with Angular 15, a null/undefined value will show up as the corresponding string,
                // which means that, in the unlikely event someone wants to pass an actual string literal of "null" or "undefined"
                // as a parameter, this will end up stripping it out.
                if (value == null || value === 'null' || value === 'undefined') {
                    newParams = newParams.delete(key);
                }
            });

            // If the params have changed, we need to generate a new request with the new params
            if (request.params !== newParams) {
                request = request.clone({
                    params: newParams,
                });
            }
        }

        return next.handle(request);
    }
}
