<div class="time-range-filters">
    <label>Quote Creation Date:</label>
    <calendar-time-range-select
        class="form-control start-range"
        [extremeTimeRange]="extremeTimeRange"
        [selected]="selectedRangeFilters?.startRange"
        [enableCustomChoice]="true"
        customLabel="All time"
        [showReset]="true"
        [isUTCTime]="true"
        (selectionChange)="selectDateRange($event, 'creation')"
    ></calendar-time-range-select>
    <!-- <label>Quote Expiration Date:</label>
    <calendar-time-range-select
        class="form-control end-range"
        [extremeTimeRange]="extremeTimeRange"
        [selected]="selectedRangeFilters?.endRange"
        [enableCustomChoice]="true"
        customLabel="All time"
        [showReset]="true"
        [isUTCTime]="true"
        (selectionChange)="selectDateRange($event, 'expiration')"
    ></calendar-time-range-select> -->
</div>
