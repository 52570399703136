<svg viewBox="0 0 98.75 66.75" class="svg">
    <path
        style="fill: #e6e6e6"
        d="M83.5820007,27.1430016c0.0159988-0.3689995,0.0279999-0.7400017,0.0279999-1.1139984
        c0-13.616003-11.0469971-24.6540031-24.6749992-24.6540031c-9.8689995,0-18.3810005,5.7910004-22.3269997,14.1549988
        c-2.1920013-1.5629997-4.8999996-2.4890003-7.8320007-2.4890003c-7.2950001,0-13.2080002,5.717001-13.2080002,12.7679996
        c0,1.0470009,0.1330004,2.0629997,0.3789997,3.0359993C7.6259999,30.6170006,1.375,38.1430016,1.375,47.1609993
        C1.375,57.4950027,9.5830002,65.375,19.7080002,65.375h60.1230011C89.5199966,65.375,97.375,57.1009979,97.375,46.2809982
        C97.375,36.8989983,91.4680023,29.0620003,83.5820007,27.1430016z"
    />
    <path
        style="fill: #c5c5c5"
        d="M84.984375,26.1069336l0.0009766-0.078125C84.9853516,11.6762695,73.2988281,0,58.9345703,0
        c-9.5585938,0-18.2836914,5.1855469-22.8662109,13.5605469c-2.2070312-1.2407227-4.7192383-1.8945312-7.2924805-1.8945312
        c-8.0410156,0-14.5830078,6.3447266-14.5830078,14.1430664c0,0.671875,0.050293,1.3496094,0.1503906,2.0205078
        C5.8808594,30.2651367,0,38.1650391,0,47.1611328C0,58.1455078,8.6567383,66.75,19.7080078,66.75h60.1230469
        C90.4394531,66.75,98.75,57.7587891,98.75,46.2807617C98.75,36.9023438,93.1035156,28.6513672,84.984375,26.1069336z M79.8310547,64
        H19.7080078C10.1987305,64,2.75,56.6035156,2.75,47.1611328c0-8.1699219,5.6704102-15.3071289,13.4833984-16.9711914
        l1.3959961-0.2973633l-0.3491211-1.3837891c-0.2241211-0.8862305-0.3374023-1.7944336-0.3374023-2.699707
        c0-6.2822266,5.3081055-11.3930664,11.8330078-11.3930664c2.5527344,0,4.9848633,0.7719727,7.0336914,2.2329102l1.3393555,0.9555664
        l0.7026367-1.487793C41.6826172,7.996582,49.9584961,2.75,58.9345703,2.75
        c12.8476562,0,23.3007812,10.4428711,23.3007812,23.2788086c0,0.3544922-0.0126953,0.706543-0.0273438,1.0556641
        l-0.0478516,1.1279297l1.0966797,0.2666016C90.640625,30.2753906,96,37.762207,96,46.2807617
        C96,56.2167969,88.8974609,64,79.8310547,64z"
    />
    <path
        style="fill: #f33b2e"
        d="M63.4713173,46.3886909l-7.848175-7.8481712l7.848175-7.848175
        c1.1832237-1.1832218,1.2048378-3.069355,0.0497055-4.3881893l-0.0497055-0.0534897
        c-1.1670151-1.1686344-3.2773666-1.1664734-4.4411392,0l-7.8492546,7.8481731l-7.7368736-7.7374134
        c-1.1670151-1.1670151-3.2752037-1.1680965-4.4422188,0c-1.2037544,1.2037525-1.2037544,3.2373848,0,4.4411373l7.7379532,7.737957
        l-7.7379532,7.7379532c-1.2032127,1.2032127-1.2037544,3.2373848,0,4.4411392
        c0.7774696,0.7780113,1.595459,0.8774223,2.2211075,0.8774223c0.9579277,0,1.6586761-0.4419518,2.1017075-0.7747688
        l7.8562775-7.8400688l7.8492546,7.8487129c0.7780113,0.7780113,1.5949211,0.8774223,2.2205696,0.8774223
        c0.9573822,0,1.6575928-0.4419518,2.1017075-0.7736855l0.1188622-0.1037369
        C64.6750717,49.6271591,64.6750717,47.5924454,63.4713173,46.3886909z"
    />
</svg>
