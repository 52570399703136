import moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { SubscriptionAsset } from '../models/subscription-asset';

@Injectable({ providedIn: 'root' })
export class SubscriptionAssetService extends GenericService<SubscriptionAsset> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SubscriptionAsset,
            endpoint: '/rest/v1/asset-management',
            defaultParams: {
                // Don't specify page limit
                pageStart: 0,
                sort: {
                    key: 'name',
                    order: 'asc',
                },
            },
        });
    }
}
