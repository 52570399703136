import moment from 'moment';
import { Resource } from '../interfaces/resource';

interface VMCollectorJSON {
    id: string;
    name: string;
    type: 'array' | 'vm' | 'ova';
    created_by: string;
    created_at: number;
    last_phonehome_at?: number;
    version?: string;
    versionStatus?: 'green' | 'red';
    org_id: number;
}

export enum VersionStatus {
    green,
    red,
}

const UNKNOWN_VERSION = 'Unknown';

export class VMCollector implements Resource {
    id: string;
    name: string;
    type: string;
    createdBy: string;
    createdAt: moment.Moment;
    lastPhonehome?: moment.Moment;
    version: string;
    fullVersion: string;
    versionStatus: VersionStatus;
    orgId: number;

    constructor(json: VMCollectorJSON) {
        this.id = json.id;
        this.name = json.name;
        this.type = json.type;
        this.createdBy = json.created_by;
        this.createdAt = moment(json.created_at);
        this.lastPhonehome = json.last_phonehome_at ? moment(json.last_phonehome_at) : null;
        this.version = (json.version || UNKNOWN_VERSION).split('-')[0];
        this.fullVersion = json.version || UNKNOWN_VERSION;
        this.versionStatus = VersionStatus[json.versionStatus];
        this.orgId = json.org_id;
    }
}
