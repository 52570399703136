<hive-content-dialog
    #copilotOnboardingModalDialog
    size="medium"
    [showCloseButton]="true"
    (contentDialogClose)="dismissModalDialog()"
    heading="Welcome to AI CoPilot™"
>
    <!-- use a specific class selector since this will be created in the DOM outside of the component -->
    <div class="copilot-onboarding-modal-dialog-content">
        <svg hive-svg="ai-onboarding-background.svg" class="dialog-background-img" [width]="644" [height]="330"></svg>

        <div class="dialog-description-container">
            <div class="dialog-description-icon">
                <svg hive-svg="ai-sparkles.svg" [width]="24" [height]="24"></svg>
            </div>
            <div class="dialog-description-text">
                <span class="hive-paragraph-small hive-paragraph-regular">
                    Thank you for your interest in using the beta version of AI CoPilot™, your cutting-edge
                    intelligence that helps with performance management, optimizing storage, streamlining your
                    workflows, and more.
                </span>
                <span class="hive-paragraph-small hive-paragraph-regular">
                    Before you proceed, please read the following important information:
                </span>
                <ul class="dialog-description-list">
                    <li class="dialog-description-list-item hive-paragraph-small hive-paragraph-regular">
                        <strong>Beta Version Limitations:</strong> This product is a beta version and may contain bugs,
                        errors, or other issues. It is not intended for use in a production environment and should be
                        used for testing and evaluation purposes only.
                    </li>
                    <li class="dialog-description-list-item hive-paragraph-small hive-paragraph-regular">
                        <strong>Feedback Ownership:</strong> By using this beta version, you agree that all feedback,
                        suggestions, and ideas you provide to us regarding the product shall be the sole property of
                        Pure Storage, Inc. ("Pure"). Pure reserves the right to use this feedback for any purpose
                        without any obligation to you.
                    </li>
                    <li class="dialog-description-list-item hive-paragraph-small hive-paragraph-regular">
                        <strong>No Warranty:</strong> The beta version is provided "as is," and Pure makes no
                        warranties, express or implied, regarding its performance or reliability.
                    </li>
                </ul>
                <span class="hive-paragraph-small hive-paragraph-regular">
                    By proceeding with the beta version, you acknowledge and accept these terms. Thank you for your
                    participation!
                </span>
            </div>
        </div>
    </div>

    <!-- use a specific class selector since this will be created in the DOM outside of the component -->
    <div class="copilot-onboarding-modal-dialog-actions-container" slot="actions-right">
        <hive-button emphasis="secondary" label="Maybe Later" (click)="dismissModalDialog()"> </hive-button>
        <hive-button emphasis="primary" label="Try AI CoPilot™" (click)="ackOnboardingModal()"> </hive-button>
    </div>
</hive-content-dialog>
