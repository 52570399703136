import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserNotification } from '../../model/user-notification';
import { BaseBanner } from '../base-banner';

@Component({
    selector: 'enhanced-safemode-banner',
    templateUrl: './enhanced-safemode-banner.component.html',
})
export class EnhancedSafemodeBannerComponent extends BaseBanner implements OnInit {
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('enhancedSafemodeButtonTemplate', { static: true })
    readonly enhancedSafemodeButtonTemplate: TemplateRef<any>;

    ngOnInit(): void {
        const templates = { buttonTemplate: this.enhancedSafemodeButtonTemplate };
        this.populatedNotification = { ...this.notification, ...templates };
    }
}
