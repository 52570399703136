import { Injectable } from '@angular/core';
import { CaseEscalation, SupportCase } from '../support.interface';
import { CaseManager } from './case-data.service';
import { CaseEscalationService } from './case-escalation.service';

@Injectable()
export class SupportCaseOrchestratorService {
    numOfOpenCases: number;

    constructor(
        private escalationManager: CaseEscalationService,
        private caseManager: CaseManager,
    ) {
        this.numOfOpenCases = 0;
        //use the casesData.getCaseCounts here to directly fetch the case count from server
        //instead of relying on the support_nav.component to be instantiated.
        this.caseManager.getCaseCounts().subscribe(countData => {
            this.numOfOpenCases = countData.total;
        });
    }

    createEscalation(escalation: CaseEscalation, supportCase: SupportCase): Promise<CaseEscalation> {
        return this.escalationManager
            .addCaseEscalation(supportCase.id, escalation)
            .toPromise()
            .then((escalation: CaseEscalation) => {
                // The backend marks the case as escalated when creating an escalation
                // We duplicate this behavior here so that we don't have to fetch the case.
                supportCase.isEscalated = true;
                this.caseManager.updateCase(supportCase);
                return escalation;
            });
    }
}
