import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { ListParams, FilterParams } from '../interfaces/list-params';
import { SnapshotsAggregation } from '../models/snapshots-aggregation';
import { DataPage } from '../interfaces/data-page';
import { Collection, IRestResponse } from '../interfaces/collection';
import { formUrlEncodedRequestTransformer } from '../utils';
import { PGroupSnapshot } from '../models/pgroup-snapshot';

export abstract class GenericSnapshotsAggregationService implements Collection<SnapshotsAggregation> {
    protected abstract http: HttpClient;

    constructor(protected url: string) {}

    list(params: ListParams<SnapshotsAggregation>): Observable<DataPage<SnapshotsAggregation>> {
        const filterParam = params && params.filter && this.makeFilterQueryParam(params.filter);

        const body = formUrlEncodedRequestTransformer({ filter: filterParam });

        return this.http
            .post<
                IRestResponse<any>
            >(this.url + '?limit=1000&sort=name', body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .pipe(
                map(response => ({
                    total: 1,
                    response: response.items,
                })),
            );
    }

    create(_properties: Partial<SnapshotsAggregation>): Observable<SnapshotsAggregation> {
        throw new Error('Not Supported');
    }

    update(_properties: Partial<SnapshotsAggregation>): Observable<DataPage<SnapshotsAggregation>> {
        throw new Error('Not Supported');
    }

    delete(_id: string): Observable<void> {
        throw new Error('Not Supported');
    }

    private makeFilterQueryParam(filter: FilterParams<PGroupSnapshot>): string {
        return Object.keys(filter)
            .map(k => `${k}=(${filter[k]})`)
            .join(' and ');
    }
}
