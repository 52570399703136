import { Resource } from '../interfaces/resource';

export class View implements Resource {
    name: string;
    selector: string;
    id: string;

    constructor(view: any) {
        this.name = view.name || '';
        this.selector = view.selector || view.filter || ''; // TODO: remove view.filter once VistaPoint deployment is complete
        this.id = view.id;
    }
}
