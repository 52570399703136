import { Component, OnInit } from '@angular/core';
import { UnifiedArrayService } from '@pure1/data';
import { take } from 'rxjs/operators';

@Component({
    selector: 'eol-warning-banner',
    templateUrl: 'eol-warning-banner.component.html',
})
export class EOLWarningBannerComponent implements OnInit {
    hasEOL = false;

    constructor(private unifiedArrayService: UnifiedArrayService) {}

    ngOnInit(): void {
        this.unifiedArrayService
            .list({ fields: ['has_end_of_life_hardware'] })
            .pipe(take(1))
            .subscribe(data => {
                this.hasEOL = data?.response.some(array => array.has_end_of_life_hardware);
            });
    }

    getHwEolFilters(): string {
        return JSON.stringify([{ entity: 'arrays', key: 'has_end_of_life_hardware', value: 'true' }]);
    }
}
