import moment from 'moment';
import { Injectable } from '@angular/core';

import {
    Bucket,
    Directory,
    FileSystem,
    getSplitIdsFromFullId,
    Pod,
    UnifiedArray,
    VolumePerformance,
    VolumeSpace,
} from '@pure1/data';
import { ArraysManager } from '../../services/arrays-manager.service';

export enum SubscriptionReportType {
    RAW = 'SUBSCRIPTION',
    CONSOLIDATED = 'SUBSCRIPTION_CONSOLIDATED',
}

export enum InvoiceReportType {
    INVOICE_SUMMARY_XLSX = 'INVOICE_SUMMARY_XLSX',
    INVOICE_SUMMARY_PDF = 'INVOICE_SUMMARY_PDF',
    INVOICE_DETAIL_PDF = 'INVOICE_DETAIL_PDF',
    INVOICE_SUMMARY_AND_DETAIL_PDF = 'INVOICE_SUMMARY_AND_DETAIL_PDF',
}

export type ReportTimeRange = {
    period?: string;
    start_time?: moment.Moment;
    end_time?: moment.Moment;
};

function getArrayId(array: IPureArray | UnifiedArray): string {
    return (
        (array as IPureArray).array_id ||
        (array as UnifiedArray).cloud_array_id || // need to ensure we get array_id and not appliance_id
        array.id
    ); // fallback for un-upgraded array types (capacity export request, for example)
}

@Injectable({ providedIn: 'root' })
export class ExportRequestBuilderService {
    constructor(private arraysManager: ArraysManager) {}

    selectedArraysPerformance(
        arrays: UnifiedArray[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
        loadGranularity: moment.Duration,
    ): IPerformanceExportRequest {
        arrays = arrays || [];
        return new PerformanceExportRequest(
            this,
            arrays,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
        );
    }

    filteredArraysPerformance(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
        loadGranularity: moment.Duration,
        isGlobal = false,
    ): IPerformanceExportRequest {
        const filterString = filter || '';
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            filterString,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
            null,
            null,
            null,
            null,
            isGlobal,
        );
    }

    filteredBucketsPerformance(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGraunularity: moment.Duration,
        isGlobal = false,
    ): PerformanceExportRequest {
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGraunularity,
            null,
            null,
            null,
            null,
            filter || '',
            isGlobal,
        );
    }

    selectedBucketsPerformance(
        buckets: Bucket[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGraunularity: moment.Duration,
    ): PerformanceExportRequest {
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGraunularity,
            null,
            null,
            null,
            buckets,
        );
    }

    selectedDirectoriesPerformance(
        directories: Directory[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
    ): IPerformanceExportRequest {
        directories = directories || [];
        return new PerformanceExportRequest(
            this,
            null,
            directories,
            null,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
        );
    }

    filteredDirectoriesPerformance(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
        isGlobal = false,
    ): IPerformanceExportRequest {
        const filterString = filter || '';
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            filterString,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
            null,
            null,
            null,
            null,
            isGlobal,
        );
    }

    selectedFileSystemsPerformance(
        fileSystems: FileSystem[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
    ): IPerformanceExportRequest {
        fileSystems = fileSystems || [];
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
            fileSystems,
        );
    }

    filteredFileSystemsPerformance(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
        isGlobal = false,
    ): IPerformanceExportRequest {
        filter = filter || '';
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
            null,
            filter,
            null,
            null,
            isGlobal,
        );
    }

    filteredFileSystemsCapacity(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        isGlobal = false,
    ): ICapacityExportRequest {
        filter = filter || '';
        return new CapacityExportRequest(
            this,
            null, // arrays
            null, // directories
            null, // file systems
            null, // volumes
            null, // array filter
            null, // directory filter
            filter, // file system filter
            null, // volume filter
            startTime,
            endTime,
            null,
            null,
            isGlobal,
        );
    }

    selectedFileSystemsCapacity(
        directories: Directory[],
        startTime: moment.Moment,
        endTime: moment.Moment,
    ): ICapacityExportRequest {
        directories = directories || [];
        return new CapacityExportRequest(
            this,
            null, // arrays
            directories,
            null, // file systems
            null, // volumes
            null, // array filter
            null, // directory filter
            null, // file system filter
            null, // volume filter
            startTime,
            endTime,
            null,
            null,
        );
    }

    selectedVolumesPerformance(
        volumes: VolumePerformance[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
    ): IPerformanceExportRequest {
        volumes = volumes || [];
        return new PerformanceExportRequest(
            this,
            null,
            null,
            volumes,
            null,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
        );
    }

    filteredVolumesPerformance(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
        isGlobal = false,
    ): IPerformanceExportRequest {
        filter = filter || '';
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            null,
            filter,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
            null,
            null,
            null,
            null,
            isGlobal,
        );
    }

    selectedVolumesCapacity(
        volumes: VolumeSpace[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        forecastType: exportRequestForecastType = null,
        trainingPeriods: IExportRequestPayloadTrainingPeriods = null,
    ): ICapacityExportRequest {
        volumes = volumes || [];
        return new CapacityExportRequest(
            this,
            null, // arrays
            null, // directories
            null, // file systems
            volumes, // volumes
            null, // array filter
            null, // directory filter
            null, // file system filter
            null, // volume filter
            startTime,
            endTime,
            forecastType,
            trainingPeriods,
        );
    }

    filteredVolumesCapacity(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        forecastType: exportRequestForecastType = null,
        trainingPeriods: IExportRequestPayloadTrainingPeriods = null,
        isGlobal = false,
    ): ICapacityExportRequest {
        filter = filter || '';
        return new CapacityExportRequest(
            this,
            null, // arrays
            null, // directories
            null, // file systems
            null, //  volumes
            null, // array filter
            null, // directory filter
            null, // file system filter
            filter, // volume filter
            startTime,
            endTime,
            forecastType,
            trainingPeriods,
            isGlobal,
        );
    }

    selectedPodsPerformance(
        pods: Pod[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
    ): IPerformanceExportRequest {
        pods = pods || [];
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            pods,
            null,
            null,
            null,
            null,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
        );
    }

    filteredPodsPerformance(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregation: IAggregationMethod,
        perfGranularity: moment.Duration,
        isGlobal = false,
    ): IPerformanceExportRequest {
        filter = filter || '';
        return new PerformanceExportRequest(
            this,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            filter,
            startTime,
            endTime,
            aggregation,
            perfGranularity,
            null,
            null,
            null,
            null,
            null,
            isGlobal,
        );
    }

    selectedArraysCapacity(
        arrays: IPureArray[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        forecastType: exportRequestForecastType = null,
        trainingPeriods: IExportRequestPayloadTrainingPeriods = null,
    ): ICapacityExportRequest {
        arrays = arrays || [];
        return new CapacityExportRequest(
            this,
            arrays, // arrays
            null, // directories
            null, // file systems
            null, // volumes
            null, // arrays filter
            null, // directories filter
            null, // file systems filter
            null, // volumes filter
            startTime,
            endTime,
            forecastType,
            trainingPeriods,
        );
    }

    filteredArraysCapacity(
        filter: IGlobalFilters,
        startTime: moment.Moment,
        endTime: moment.Moment,
        forecastType: exportRequestForecastType = null,
        trainingPeriods: IExportRequestPayloadTrainingPeriods = null,
    ): ICapacityExportRequest {
        const filterString = this.arraysManager.buildArraysFilterParamForPureArrays(filter || {});
        return new CapacityExportRequest(
            this,
            null, // arrays
            null, // directories
            null, // file systems
            null, // volumes
            filterString, // array filter
            null, // directory filter
            null, // file system filter
            null, // volume filter
            startTime,
            endTime,
            forecastType,
            trainingPeriods,
        );
    }

    filteredArraysCapacityFilterString(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        isGlobal = false,
    ): ICapacityExportRequest {
        filter = filter || '';
        return new CapacityExportRequest(
            this,
            null,
            null,
            null,
            null,
            filter,
            null,
            null,
            null,
            startTime,
            endTime,
            null,
            null,
            isGlobal,
        );
    }

    selectedArraysForecast(
        arrays: PureArray[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        simulationId: string,
    ): IForecastExportRequest {
        arrays = arrays || [];
        return new ForecastExportRequest(this, arrays, null, null, null, startTime, endTime, simulationId);
    }

    filteredArraysForecast(
        filter: IGlobalFilters,
        startTime: moment.Moment,
        endTime: moment.Moment,
        simulationId: string,
    ): IForecastExportRequest {
        const filterString = this.arraysManager.buildArraysFilterParamForPureArrays(filter || {});
        return new ForecastExportRequest(this, null, null, filterString, null, startTime, endTime, simulationId);
    }

    filteredArraysForecastFilterString(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        simulationId: string,
        isGlobal = false,
    ): IForecastExportRequest {
        filter = filter || '';
        return new ForecastExportRequest(this, null, null, filter, null, startTime, endTime, simulationId, isGlobal);
    }

    filteredArraysAudits(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        isGlobal = false,
    ): IExportRequest {
        return new AuditsExportRequest(this, filter, startTime, endTime, isGlobal);
    }

    filteredArraysDashboard(
        filter: IGlobalFilters,
        startTime: moment.Moment,
        endTime: moment.Moment,
    ): IEmailExportRequest {
        const filterString = this.arraysManager.buildArraysFilterParamForPureArrays(filter || {});
        return new DashboardExportRequest(this, null, null, filterString, null, startTime, endTime);
    }

    filteredArraysDashboardFilterString(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        isGlobal = false,
    ): IEmailExportRequest {
        filter = filter || '';
        return new DashboardExportRequest(this, null, null, filter, null, startTime, endTime, isGlobal);
    }

    filteredSubscriptions(
        filter: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        reportType: SubscriptionReportType,
        isGlobal = false,
    ): ISubscriptionExportRequest {
        filter = filter || '';
        if (reportType === SubscriptionReportType.CONSOLIDATED) {
            return new SubscriptionConsolidatedExportRequest(this, filter, startTime, endTime, isGlobal);
        }
        return new SubscriptionExportRequest(this, filter, startTime, endTime, isGlobal);
    }

    filteredSubscriptionDashboard(
        filter: string,
        multiTimeSelections: ReportTimeRange[],
        isGlobal = false,
    ): ISubscriptionDashboardExportRequest {
        return new SubscriptionDashboardExportRequest(this, filter || '', multiTimeSelections, isGlobal);
    }

    filteredEuc(filter: string, isGlobal = false): IEucExportRequest {
        return new EucExportRequest(this, filter || '', isGlobal);
    }

    filteredSupportContracts(filter: string, isGlobal = false): ISupportContractExportRequest {
        filter = filter || '';
        return new SupportContractExportRequest(this, filter, isGlobal);
    }

    filteredAssets(filter: string, isGlobal = false): IAssetExportRequest {
        return new AssetExportRequest(this, filter, isGlobal);
    }

    filteredApplianceGenealogy(filter: string, isGlobal = false): IApplianceGenealogyExportRequest {
        return new ApplianceGenealogyExportRequest(this, filter, isGlobal);
    }

    filteredSubscriptionGenealogy(filter: string, isGlobal = false): ISubscriptionGenealogyExportRequest {
        return new SubscriptionGenealogyExportRequest(this, filter, isGlobal);
    }

    filteredDpaArrays(
        filter: string,
        report_details: dpaReportDetails,
        metric: dpaReportMetric,
        value_type: dpaReportValueType,
        include_appliances_details: boolean,
        report_view: dpaReportView,
        isGlobal = false,
    ): IDpaExportRequest {
        filter = filter || '';
        return new DpaExportRequest(
            this,
            filter,
            report_details,
            metric,
            value_type,
            include_appliances_details,
            report_view,
            isGlobal,
        );
    }

    filteredSecurityAssessmentArrays(
        filter: string,
        isGlobal = false,
        zoneId?: string,
    ): ISecurityAssessmentExportRequest {
        filter = filter || '';
        // set zone_id before request
        const zone_id = zoneId || moment.tz.guess();
        return new SecurityAssessmentExportRequest(this, filter, zone_id, isGlobal);
    }

    filteredEOneResilienceArrays(filter: string, isGlobal = false): IEsgExportRequest {
        return new EOneResilienceExportRequest(this, filter || '', isGlobal);
    }

    filteredEsgArrays(filter: string, isGlobal = false): IEsgExportRequest {
        filter = filter || '';
        return new EsgExportRequest(this, filter, isGlobal);
    }

    filteredEsgExecutiveSummaryArrays(filter: string, isGlobal = false): IEsgExportRequest {
        return new EsgExecutiveSummaryExportRequest(this, filter || '', isGlobal);
    }

    filteredArrayEol(filter: string, isGlobal = false): ArrayEolExportRequest {
        filter = filter || '';
        return new ArrayEolExportRequest(this, filter, isGlobal);
    }

    filteredInvoice(
        filter: string,
        invoiceReportType: InvoiceReportType,
        customTimeRange: ReportTimeRange,
        isGlobal = false,
    ): IInvoiceExportRequest {
        return new InvoiceExportRequest(this, filter || '', invoiceReportType, customTimeRange, isGlobal);
    }

    filteredQuotes(filter: string, isGlobal = false): IQuoteExportRequest {
        return new QuoteExportRequest(this, filter, isGlobal);
    }
}

abstract class ExportRequestBase implements IExportRequest {
    fileName: string;
    recipientEmails: IExportEmailRecipientParameters;
    schedule: IExportEmailScheduleParameters;
    body: string;
    subject: string;

    constructor(
        reportType: exportRequestReportType,
        public exportRequestBuilder: ExportRequestBuilderService,
        public arrays: (IPureArray | PureArray | UnifiedArray)[],
        public directories: Directory[],
        public volumes: (VolumePerformance | VolumeSpace)[] = null,
        public pods: Pod[],
        public arraysFilter: string,
        public directoriesFilter: string,
        public volumesFilter: string,
        public podsFilter: string,
        public auditsFilter?: string,
        public fileSystems?: FileSystem[],
        public fileSystemFilter?: string,
        public buckets?: Bucket[],
        public bucketsFilter?: string,
        public subscriptionsFilter?: string,
        public eucFilter?: string,
        public assetFilter?: string,
        public applianceGenealogyFilter?: string,
        public subscriptionGenealogyFilter?: string,
        public invoiceFilter?: string,
        public quoteFilter?: string,
    ) {
        this.fileName = [
            this.getEntityType().toLowerCase(),
            this.getReportTypeFileString(reportType),
            moment().format('YYYY-MM-DD'),
        ].join('_');
    }

    abstract getRequestPayload(): IExportRequestPayload;

    getEntityType(): exportRequestReportEntityType {
        if (this.arrays || this.arraysFilter != null) {
            return 'ARRAY';
        } else if (this.directories || this.directoriesFilter != null) {
            return 'DIRECTORY';
        } else if (this.volumes || this.volumesFilter != null) {
            return 'VOLUME';
        } else if (this.pods || this.podsFilter != null) {
            return 'POD';
        } else if (this.auditsFilter != null) {
            return 'AUDIT';
        } else if (this.fileSystems || this.fileSystemFilter != null) {
            return 'FILE_SYSTEM';
        } else if (this.buckets || this.bucketsFilter != null) {
            return 'BUCKET';
        } else if (this.subscriptionsFilter != null) {
            return 'SUBSCRIPTION';
        } else if (this.eucFilter != null) {
            return 'EUC';
        } else if (this.assetFilter != null) {
            return 'SUBSCRIBED_ASSET';
        } else if (this.applianceGenealogyFilter != null) {
            return 'SUBSCRIBED_ASSET';
        } else if (this.subscriptionGenealogyFilter != null) {
            return 'SUBSCRIBED_ASSET';
        } else if (this.invoiceFilter != null) {
            return 'INVOICE';
        } else if (this.quoteFilter != null) {
            return 'QUOTE';
        } else {
            throw new Error('Unable to determine entityType');
        }
    }

    getRequestPayloadFilter(): IExportRequestPayloadEntityFilter {
        if (this.arrays) {
            return {
                type: 'SELECTED',
                selected: this.arrays.map(array => {
                    return { array_id: getArrayId(array as UnifiedArray) };
                }),
            };
        }

        if (this.directories) {
            return {
                type: 'SELECTED',
                selected: this.directories.map(directory => {
                    return { directory_id: directory.id, array_id: directory.array.flashArrayId };
                }),
            };
        }

        if (this.volumes) {
            return {
                type: 'SELECTED',
                selected: this.volumes.map(volume => {
                    return {
                        array_id: volume instanceof VolumeSpace ? volume.arrayId : volume.array_id,
                        volume_id:
                            volume instanceof VolumeSpace ? getSplitIdsFromFullId(volume.id).coreId : volume.core_id,
                    };
                }),
            };
        }

        if (this.pods) {
            return {
                type: 'SELECTED',
                selected: this.pods.map(pod => {
                    return { pod_id: pod.id };
                }),
            };
        }

        if (this.fileSystems) {
            return {
                type: 'SELECTED',
                selected: this.fileSystems.map(fileSystem => {
                    // NOTE: metricsController takes array_id, so we use that first, FB's array_id === appliance_id, so we try that second in case FB didn't send array_id
                    return {
                        array_id: fileSystem.array_id || fileSystem.appliance_id,
                        file_system_guid: fileSystem.file_system_guid,
                    };
                }),
            };
        }

        if (this.buckets) {
            return {
                type: 'SELECTED',
                selected: this.buckets.map(bucket => {
                    return { array_id: bucket.appliance_id, bucket_id: bucket.id };
                }),
            };
        }

        if (this.arraysFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.arraysFilter,
            };
        }

        if (this.directoriesFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.directoriesFilter,
            };
        }

        if (this.volumesFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.volumesFilter,
            };
        }

        if (this.podsFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.podsFilter,
            };
        }

        if (this.auditsFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.auditsFilter,
            };
        }

        if (this.fileSystemFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.fileSystemFilter,
            };
        }

        if (this.bucketsFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.bucketsFilter,
            };
        }

        if (this.subscriptionsFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.subscriptionsFilter,
            };
        }

        if (this.eucFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.eucFilter,
            };
        }

        if (this.assetFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.assetFilter,
            };
        }

        if (this.applianceGenealogyFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.applianceGenealogyFilter,
            };
        }

        if (this.subscriptionGenealogyFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.subscriptionGenealogyFilter,
            };
        }

        if (this.invoiceFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.invoiceFilter,
            };
        }

        if (this.quoteFilter != null) {
            return {
                type: 'FILTERED',
                filter_version: 'v3',
                value: this.quoteFilter,
            };
        }

        return null;
    }

    getEntityCountMessage(entityCount: number): string {
        const payload = this.getRequestPayload();

        // Get the entity name
        let entityName: string;
        switch (payload.entity_type) {
            case 'ARRAY':
                entityName = 'arrays';
                break;
            case 'DIRECTORY':
                entityName = 'directories';
                break;
            case 'VOLUME':
                entityName = 'volumes';
                break;
            case 'POD':
                entityName = 'pods';
                break;
            case 'AUDIT':
                entityName = 'audits';
                break;
            case 'FILE_SYSTEM':
                entityName = payload.type === 'CAPACITY' && this.directories ? 'items' : 'file systems';
                break;
            case 'SUBSCRIPTION':
                entityName = 'subscriptions';
                break;
            case 'EUC':
                entityName = 'licenses';
                break;
            case 'INVOICE':
                entityName = 'invoices';
                break;
            case 'BUCKET':
                entityName = 'buckets';
                break;
            default:
                entityName = 'na';
                break;
        }

        // Get the filter prefix
        const filter = payload.filter;
        let prefix: string;
        if (filter.type === 'FILTERED') {
            // Product is not a user-controlled filter. If that is the only thing we're filtering on, still show "All".
            if (filter.value && filter.value.length > 0 && !this.isProductFilter(filter.value)) {
                prefix = 'Filtered';
            } else {
                prefix = 'All';
            }
        } else if (filter.type === 'SELECTED') {
            prefix = 'Selected';
        }

        //Add limit warning to export request if applicable
        const MAX_QUERY_LIMIT = 1000; //From event-service\src\main\java\com\purestorage\event\config\EventConfig.java
        const MAX_PODS_PER_REQUEST = 50; //From spog\src\main\java\com\purestorage\controller\MetricsController.java
        switch (payload.entity_type) {
            case 'ARRAY':
                if (entityCount > MAX_QUERY_LIMIT) {
                    prefix = 'Truncated to the first 1000 of ' + prefix;
                }
                break;
            case 'POD':
                if (entityCount > MAX_PODS_PER_REQUEST) {
                    prefix = 'Truncated to the first 50 of ' + prefix;
                }
                break;
            default: //Couldn't find any limits set for volume
                break;
        }

        // Put it all together
        return `${prefix} ${entityCount} ${entityName}`;
    }

    private getReportTypeFileString(reportType: exportRequestReportType): string {
        switch (reportType) {
            case 'PERFORMANCE':
                return 'perf';
            case 'CAPACITY':
                return 'capacity';
            case 'PLANNING':
                return 'forecast';
            case 'AUDIT':
                return 'audit';
            case 'DASHBOARD_SUMMARY':
                return 'dashboard_summary';
            case 'EUC':
                return 'euc';
            case 'SUBSCRIPTION':
                return 'subscription';
            case 'SUBSCRIPTION_DASHBOARD':
                return 'subscription_dashboard';
            case 'SUBSCRIPTION_CONSOLIDATED':
                return 'subscription_consolidated';
            case 'SUPPORT_CONTRACT':
                return 'support_contract';
            case 'DATA_PROTECTION':
                return 'data_protection';
            case 'SECURITY_ASSESSMENT':
                return 'security_assessment';
            case 'EVERGREEN_ONE_RESILIENCE':
                return 'evergreen_one_resilience';
            case 'SUSTAINABILITY':
                return 'sustainability';
            case 'SUSTAINABILITY_EXECUTIVE':
                return 'sustainability_executive';
            case 'ASSET_MANAGEMENT':
                return 'asset_management';
            case 'ARRAY_EOL':
                return 'array_eol';
            case 'APPLIANCE_GENEALOGY':
                return 'appliance_genealogy';
            case 'SUBSCRIPTION_GENEALOGY':
                return 'subscription_genealogy';
            case 'INVOICE':
                return 'invoice';
            case 'QUOTE_MANAGEMENT':
                return 'quote_management';
            default:
                throw new Error('Unknown reportType: ' + reportType);
        }
    }

    /**
     * Checks if a filter string is a filter only on the product, and nothing else
     */
    private isProductFilter(filter: string): boolean {
        const regex = /^product\!?='\w+'$/;
        return filter && regex.test(filter.trim());
    }
}

abstract class EmailExportRequestBase extends ExportRequestBase implements IEmailExportRequest {
    emailRecipients: IExportEmailRecipientParameters;
    emailSender: string;
    subject: string;
    body: string;
    sendEmail: boolean;

    constructor(
        reportType: exportRequestReportType,
        public exportRequestBuilder: ExportRequestBuilderService,
        public arrays: IPureArray[],
        public directories: Directory[],
        public volumes: VolumePerformance[],
        public pods: Pod[],
        public arraysFilter: string,
        public directoriesFilter: string,
        public volumesFilter: string,
        public podsFilter: string,
        public auditsFilter?: string,
    ) {
        super(
            reportType,
            exportRequestBuilder,
            arrays,
            directories,
            volumes,
            null,
            arraysFilter,
            directoriesFilter,
            volumesFilter,
            null,
        );
        this.emailRecipients = {
            to: [],
            cc: [],
            bcc: [],
        };
    }

    withEmailParameters(payload: IExportRequestPayload): IExportRequestPayload {
        if (this.sendEmail) {
            payload = Object.assign(payload, {
                email: {
                    recipients: this.emailRecipients,
                    sender: this.emailSender,
                    subject: this.subject,
                    body: this.body,
                },
            });
        }

        return payload;
    }
}

class PerformanceExportRequest extends ExportRequestBase implements IPerformanceExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arrays: UnifiedArray[],
        public directories: Directory[],
        public volumes: VolumePerformance[] = null,
        public pods: Pod[],
        public arraysFilter: string,
        public directoriesFilter: string,
        public volumesFilter: string = null,
        public podsFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public aggregation: IAggregationMethod,
        public performanceGranularity: moment.Duration,
        public loadGranularity: moment.Duration,
        public fileSystems: FileSystem[] = null,
        public fileSystemFilter: string = null,
        public buckets: Bucket[] = null,
        public bucketsFilter: string = null,
        public isGlobal = false,
    ) {
        super(
            'PERFORMANCE',
            exportRequestBuilder,
            arrays,
            directories,
            volumes,
            pods,
            arraysFilter,
            directoriesFilter,
            volumesFilter,
            podsFilter,
            undefined,
            fileSystems,
            fileSystemFilter,
            buckets,
            bucketsFilter,
        );
    }

    getRequestPayload(): IExportRequestPayload {
        return <IExportRequestPayload>{
            type: 'PERFORMANCE',
            zip_name: this.fileName + '.zip',
            entity_type: this.getEntityType(),
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            filter: this.getRequestPayloadFilter(),
            aggregation_method: this.getAggregationMethod(),
            granularities: {
                PERFORMANCE: this.performanceGranularity?.asSeconds() || undefined,
                LOAD: this.loadGranularity?.asSeconds() || undefined,
            },
            is_global: this.isGlobal,
        };
    }

    private getAggregationMethod(): exportRequestAggregationType {
        switch (this.aggregation) {
            case 'AVERAGE':
            case 'avg':
                return 'AVERAGE';
            case 'MAX':
            case 'max':
                return 'MAX';
            default:
                throw new Error('Unknown aggregationMethod: ' + this.aggregation);
        }
    }
}

class AuditsExportRequest extends ExportRequestBase {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public auditsFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public isGlobal = false,
    ) {
        super('AUDIT', exportRequestBuilder, null, null, null, null, null, null, null, null, auditsFilter);
        this.fileName = [this.getEntityType().toLowerCase(), moment().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'AUDIT',
            zip_name: this.fileName + '.zip',
            entity_type: this.getEntityType(),
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            filter: this.getRequestPayloadFilter(),
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class CapacityExportRequest extends ExportRequestBase implements ICapacityExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arrays: IPureArray[],
        public directories: Directory[],
        public fileSystems: FileSystem[],
        public volumes: VolumeSpace[],
        public arraysFilter: string,
        public directoryFilter: string,
        public fileSystemFilter: string,
        public volumesFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public forecastType: exportRequestForecastType,
        public trainingPeriods: IExportRequestPayloadTrainingPeriods,
        public isGlobal = false,
    ) {
        super(
            'CAPACITY',
            exportRequestBuilder,
            arrays,
            directories,
            volumes,
            null,
            arraysFilter,
            directoryFilter,
            volumesFilter,
            null,
            null,
            fileSystems,
            fileSystemFilter,
        );
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: ICapacityPlanningExportRequestPayload = {
            type: 'CAPACITY',
            zip_name: this.fileName + '.zip',
            entity_type: this.getEntityType(),
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            filter: this.getRequestPayloadFilter(),
            capacity_type: this.forecastType ? 'FORECAST' : 'ACTUAL',
            is_global: this.isGlobal,
        };

        // Forecast-specific params
        if (payload.capacity_type === 'FORECAST') {
            const timespan = moment.duration(this.endTime.valueOf() - this.startTime.valueOf());
            payload.forecast_type = this.forecastType;
            payload.forecast_start_time = this.endTime.valueOf();
            payload.forecast_end_time = payload.forecast_start_time + timespan.asMilliseconds();
            payload.training_periods = this.trainingPeriods || undefined;
        }

        return payload;
    }

    getEntityType(): exportRequestReportEntityType {
        if (this.arrays || this.arraysFilter != null) {
            return 'ARRAY';
        } else if (this.volumes || this.volumesFilter != null) {
            return 'VOLUME';
        } else if (this.directories || this.fileSystemFilter != null) {
            // even though we may pass in a list of directories, this still needs to be a FILE SYSTEM report
            return 'FILE_SYSTEM';
        } else {
            throw new Error('Unable to determine entityType');
        }
    }
}

class ForecastExportRequest extends ExportRequestBase implements IForecastExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arrays: (IPureArray | PureArray)[],
        public volumes: VolumePerformance[],
        public arraysFilter: string,
        public volumesFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public simulationId?: string,
        public isGlobal = false,
    ) {
        super('PLANNING', exportRequestBuilder, arrays, null, volumes, null, arraysFilter, null, volumesFilter, null);
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: ICapacityPlanningExportRequestPayload = {
            type: 'PLANNING',
            zip_name: this.fileName + '.zip',
            entity_type: this.getEntityType(),
            // start_time and end_time define the historical data reporting period (i.e. report time range)
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            // forecast_start_time begins whenever the historical reporting period ends (i.e. projection after the end
            // of the historical data)
            forecast_start_time: this.endTime.valueOf(),
            // the forecast duration (time diff between forecast_start_time and forecast_end_time) is assumed to equal
            // the reporting duration (time diff between start_time and end_time)
            forecast_end_time: this.endTime
                .clone()
                .add(this.endTime.valueOf() - this.startTime.valueOf(), 'milliseconds')
                .valueOf(),
            filter: this.getRequestPayloadFilter(),
            capacity_type: 'FORECAST',
            simulation_id: this.simulationId,
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class SubscriptionExportRequest extends ExportRequestBase implements ISubscriptionExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public subscriptionsFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public isGlobal = false,
    ) {
        super(
            'SUBSCRIPTION',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            subscriptionsFilter,
        );
        this.fileName = [this.getEntityType().toLowerCase(), moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'SUBSCRIPTION',
            zip_name: this.fileName + '.zip',
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class SubscriptionDashboardExportRequest extends ExportRequestBase implements ISubscriptionExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public subscriptionsFilter: string,
        public multiTimeSelections: ReportTimeRange[],
        public isGlobal = false,
    ) {
        super(
            'SUBSCRIPTION_DASHBOARD',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            subscriptionsFilter,
        );
        this.fileName = [this.getEntityType().toLowerCase(), 'xlsx', moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IDashboardExportRequestPayload {
        const payload: IDashboardExportRequestPayload = {
            type: 'SUBSCRIPTION_DASHBOARD',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            multi_time_selections: this.multiTimeSelections,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class InvoiceExportRequest extends ExportRequestBase implements IInvoiceExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public invoiceFilter: string,
        public invoiceReportType: invoiceReportType,
        public customTimeRange: ReportTimeRange,
        public isGlobal = false,
    ) {
        super(
            'INVOICE',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            invoiceFilter,
        );
        this.fileName = [this.getEntityType().toLowerCase(), moment.utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IInvoiceExportRequestPayload = {
            type: 'INVOICE',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            invoice_report_type: this.invoiceReportType,
            custom_time_range: !this.customTimeRange
                ? null
                : {
                      start_time: this.customTimeRange.start_time.valueOf(),
                      end_time: this.customTimeRange.end_time.valueOf(),
                  },
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class QuoteExportRequest extends ExportRequestBase implements IQuoteExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public quoteFilter: string,
        public isGlobal = false,
    ) {
        super(
            'QUOTE_MANAGEMENT',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            quoteFilter,
        );
        this.fileName = [this.getEntityType().toLowerCase(), moment.utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'QUOTE_MANAGEMENT',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class EucExportRequest extends ExportRequestBase implements IEucExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public eucFilter: string,
        public isGlobal = false,
    ) {
        super(
            'EUC',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            null,
            eucFilter,
        );
        this.fileName = [this.getEntityType().toLowerCase(), moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'EUC',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class SubscriptionConsolidatedExportRequest extends ExportRequestBase implements ISubscriptionExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public subscriptionsFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public isGlobal = false,
    ) {
        super(
            'SUBSCRIPTION_CONSOLIDATED',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            subscriptionsFilter,
        );
        this.fileName = [this.getEntityType().toLowerCase(), moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'SUBSCRIPTION_CONSOLIDATED',
            zip_name: this.fileName + '.zip',
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class AssetExportRequest extends ExportRequestBase implements IAssetExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public assetFilter: string,
        public isGlobal = false,
    ) {
        super(
            'ASSET_MANAGEMENT',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            null,
            null,
            assetFilter,
        );
        this.fileName = ['asset-inventory', moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'ASSET_MANAGEMENT',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class ApplianceGenealogyExportRequest extends ExportRequestBase implements IAssetExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public applianceGenealogyFilter: string,
        public isGlobal = false,
    ) {
        super(
            'APPLIANCE_GENEALOGY',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            applianceGenealogyFilter,
        );
        this.fileName = ['appliance-genealogy', moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'APPLIANCE_GENEALOGY',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class SubscriptionGenealogyExportRequest extends ExportRequestBase implements ISubscriptionGenealogyExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public subscriptionGenealogyFilter: string,
        public isGlobal = false,
    ) {
        super(
            'SUBSCRIPTION_GENEALOGY',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            subscriptionGenealogyFilter,
        );
        this.fileName = ['subscription-genealogy', moment.utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'SUBSCRIPTION_GENEALOGY',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class SupportContractExportRequest extends ExportRequestBase implements ISupportContractExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public isGlobal = false,
    ) {
        super(
            'SUPPORT_CONTRACT',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            null,
            null,
            null,
            null,
            arraysFilter,
        );
        this.fileName = ['support_contract', moment().utc().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'SUPPORT_CONTRACT',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(), // Used just for UI. Backend doesn't process this
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class DpaExportRequest extends ExportRequestBase implements IDpaExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public report_details: dpaReportDetails,
        public metric: dpaReportMetric,
        public value_type: dpaReportValueType,
        public include_appliances_details: boolean,
        public report_view: dpaReportView,
        public isGlobal = false,
    ) {
        super(
            'DATA_PROTECTION',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            arraysFilter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        );

        this.fileName = ['data_protection_assessment', moment().local().format('YYYY-MM-DD_hh-mm-A')].join('_');
    }

    getRequestPayload(): IDpaExportRequestPayload {
        const payload: IDpaExportRequestPayload = {
            type: 'DATA_PROTECTION',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            report_details: this.report_details,
            value_type: this.value_type,
            include_appliances_details: this.include_appliances_details,
            report_view: this.report_view,
            metric: this.metric,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(),
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class SecurityAssessmentExportRequest extends ExportRequestBase implements ISecurityAssessmentExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public zone_id: string,
        public isGlobal = false,
    ) {
        super(
            'SECURITY_ASSESSMENT',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            arraysFilter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        );
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: ISecurityAssessmentExportRequestPayload = {
            type: 'SECURITY_ASSESSMENT',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(),
            zone_id: this.zone_id,
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class EOneResilienceExportRequest extends ExportRequestBase implements IExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public isGlobal = false,
    ) {
        super(
            'EVERGREEN_ONE_RESILIENCE',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            arraysFilter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        );
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'EVERGREEN_ONE_RESILIENCE',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(),
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class EsgExportRequest extends ExportRequestBase implements IEsgExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public isGlobal = false,
    ) {
        super(
            'SUSTAINABILITY',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            arraysFilter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        );
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'SUSTAINABILITY',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(),
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class EsgExecutiveSummaryExportRequest extends ExportRequestBase implements IEsgExportRequest {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public isGlobal = false,
    ) {
        super(
            'SUSTAINABILITY_EXECUTIVE',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            arraysFilter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        );
        this.fileName = ['sustainability_executive_summary', moment().local().format('YYYY-MM-DD')].join('_');
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'SUSTAINABILITY_EXECUTIVE',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(),
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class ArrayEolExportRequest extends ExportRequestBase {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arraysFilter: string,
        public isGlobal = false,
    ) {
        super(
            'ARRAY_EOL',
            exportRequestBuilder,
            null,
            null,
            null,
            null,
            arraysFilter,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        );
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'ARRAY_EOL',
            zip_name: this.fileName + '.zip',
            start_time: null,
            end_time: null,
            filter: this.getRequestPayloadFilter(),
            entity_type: this.getEntityType(),
            is_global: this.isGlobal,
        };
        return payload;
    }
}

class DashboardExportRequest extends EmailExportRequestBase {
    constructor(
        public exportRequestBuilder: ExportRequestBuilderService,
        public arrays: IPureArray[],
        public volumes: VolumePerformance[],
        public arraysFilter: string,
        public volumesFilter: string,
        public startTime: moment.Moment,
        public endTime: moment.Moment,
        public isGlobal = false,
    ) {
        super(
            'DASHBOARD_SUMMARY',
            exportRequestBuilder,
            arrays,
            null,
            volumes,
            null,
            arraysFilter,
            null,
            volumesFilter,
            null,
        );
        this.fileName = `pure1_overview_dashboard_${moment().format('YYYY-MM-DD')}`;
    }

    getRequestPayload(): IExportRequestPayload {
        const payload: IExportRequestPayload = {
            type: 'DASHBOARD_SUMMARY',
            zip_name: this.fileName + '.zip',
            entity_type: this.getEntityType(),
            start_time: this.startTime.valueOf(),
            end_time: this.endTime.valueOf(),
            filter: this.getRequestPayloadFilter(),
            is_global: this.isGlobal,
        };
        return this.withEmailParameters(payload);
    }
}
