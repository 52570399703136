<div class="change-request-detail" [ngStyle]="{ 'flex-direction': flexDirection }">
    <div
        class="details-info"
        [ngStyle]="flexDirection === 'row' ? { 'border-right': border } : { 'border-bottom': border }"
    >
        <div
            ><span class="item-subtitle">Requested Services:</span>
            <span class="bolder-text">{{ getSafeModeChange() }}, {{ getChangeInEradicationTime() }}</span></div
        >
        <div
            ><span class="item-subtitle">Scheduled Appointment:</span>
            <span class="bolder-text">{{ $any(executionTime) | date: 'medium' }}</span></div
        >
    </div>
    <div>
        <div class="item-subtitle">Selected Appliances and Protection Groups:</div>

        <div *ngFor="let resource of resources" class="margin-top">
            <span *ngIf="!resource.pgroupId; else pgroupContent" class="bolder-text"
                >{{ resource.applianceName }} {{ resource.storageTypes | memoizeFnc: getStorageTypes }}</span
            >
            <ng-template #pgroupContent>
                <safemode-pgroup
                    [applianceName]="resource.applianceName"
                    [pgroupId]="resource.pgroupId"
                ></safemode-pgroup>
            </ng-template>
        </div>
    </div>
</div>
