import { formatNumber } from './formatNumber';

export function formatIOPs(value: number, decimal: number, axisLabel?: boolean): IValueUnit {
    if (typeof value !== 'number' || isNaN(value)) {
        return {
            value: '-',
            unit: '',
        };
    }

    if (value === 0) {
        return {
            value: axisLabel === true ? '' : '0',
            unit: '',
        };
    }

    if (value > 0 && value < 100) {
        // Fractional IOPs doesn't make sense and shouldn't occur with real data. When in this range, round
        // instead of using the decimal precision.
        return {
            value: formatNumber(value, 0),
            unit: '',
        };
    }

    return {
        value: formatNumber(value / 1000, decimal),
        unit: 'K',
    };
}

export function formatIOPsStr(value: number, decimal: number, axisLabel?: boolean): string {
    const iVal = formatIOPs(value, decimal, axisLabel);
    return `${iVal.value} ${iVal.unit}`.trim();
}
