import { Resource } from '../interfaces/resource';
import { RoleType } from './user';
import { AdditionalRoleAssignment } from './additional-role';

export enum AssigneeType {
    USER = 'USER',
    GROUP = 'GROUP',
    EXTERNAL = 'EXTERNAL',
    SSO_USER = 'SSO_USER',
    PUBLIC_API = 'PUBLIC_API',
}

export class RoleAssignment implements Resource {
    assigneeType: AssigneeType;
    id: string;
    name: string;
    /** @deprecated since its type ({@link RoleType}) is deprecated */
    role: RoleType;
    additionalRoles: AdditionalRoleAssignment[] = [];
    viewId: string;
    viewName: string;

    constructor(json: any) {
        this.additionalRoles = (json.additionalRoles || [])
            .map(role => new AdditionalRoleAssignment(role))
            .sort(AdditionalRoleAssignment.compareByNameAlphabetically);
        this.assigneeType = json.assignee_type;
        this.id = json.entity_id;
        this.name = json.name;
        this.role = json.role;
        this.viewId = json.view_id;
        this.viewName = json.view_name;
    }
}
