import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'aggregation-view-nav',
    templateUrl: 'aggregation-view-nav.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregationViewNavComponent {
    @Input() readonly title: string;
    @Input() readonly singlePath: string;
    @Input() readonly groupPath: string;

    constructor(public router: Router) {}
}
