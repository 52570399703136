import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { QRCodeModule } from 'angularx-qrcode';
import { UIModule } from '../ui/ui.module';
import { AccountSecuritySectionComponent } from './account-security-section/account-security-section.component';
import { AuthenticationDetailsSectionComponent } from './authentication-details-section/authentication-details-section.component';
import { DisableStepUpWarningModalComponent } from './modals/disable-step-up-warning-modal/disable-step-up-warning-modal.component';
import { EnrollmentRequiredModalComponent } from './modals/enrollment-required-modal/enrollment-required-modal.component';
import { PreEnrollPromptModalComponent } from './modals/pre-enroll-prompt-modal/pre-enroll-prompt-modal.component';
import { StepUpErrorMessageModalComponent } from './modals/step-up-error-message-modal/step-up-error-message-modal.component';
import { StepUpRegistrationModalComponent } from './modals/step-up-registration-modal/step-up-registration-modal.component';
import { VerifyChallengeModalComponent } from './modals/verify-challenge-modal/verify-challenge-modal.component';
import { StepUpSupportService } from './services/step-up-support.service';
import { StepUpChallengeComponent } from './step-up-challenge/step-up-challenge.component';
import { TotpActivationComponent } from './totp-activation/totp-activation.component';
import { TotpPasscodeInputComponent } from './totp-passcode-input/totp-passcode-input.component';
import { TotpStepUpChallengeComponent } from './totp-step-up-challenge/totp-step-up-challenge.component';
import { EnrollmentViaIdentityCenterRequiredModalComponent } from './modals/enrollment-via-identity-center-required-modal/enrollment-via-identity-center-required-modal.component';
import { ButtonComponent, LinkComponent } from '@pure/hive';
import { Pure1AppLinkPipe } from '@pure/pure1-ui-platform-angular';

@NgModule({
    imports: [
        Angulartics2Module,
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        UIModule,
        QRCodeModule,
        LinkComponent,
        ButtonComponent,
        Pure1AppLinkPipe,
    ],
    declarations: [
        EnrollmentRequiredModalComponent,
        EnrollmentViaIdentityCenterRequiredModalComponent,
        StepUpChallengeComponent,
        StepUpErrorMessageModalComponent,
        VerifyChallengeModalComponent,
        AuthenticationDetailsSectionComponent,
        AccountSecuritySectionComponent,
        StepUpRegistrationModalComponent,
        TotpActivationComponent,
        TotpStepUpChallengeComponent,
        DisableStepUpWarningModalComponent,
        PreEnrollPromptModalComponent,
        TotpPasscodeInputComponent,
    ],
    providers: [StepUpSupportService],
    exports: [AuthenticationDetailsSectionComponent, StepUpChallengeComponent, AccountSecuritySectionComponent],
})
export class StepUpModule {}
