import { Injectable } from '@angular/core';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import { FeatureFlagDxpService } from '@pure1/data';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FeatureNames } from '../../../model/FeatureNames';
import { MpaRequest, MpaRequestsService } from './mpa-requests.service';

@Injectable({
    providedIn: 'root',
})
export class MpaRequestsBadgeService {
    mpaPendingRequests$ = new ReplaySubject<MpaRequest[]>(1);

    constructor(
        private mpaRequestsService: MpaRequestsService,
        private featureFlagDxpService: FeatureFlagDxpService,
    ) {}

    loadFreshMpaRequests(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.SAFE_MODE_MULTIPARTY_AUTH)
            .pipe(
                take(1),
                switchMap(feature => this.getMpaRequests(feature)),
            )
            .subscribe(mpaRequests => {
                this.mpaPendingRequests$.next(mpaRequests);
            });
    }

    private getMpaRequests(featureFlag: FeatureFlagStatus): Observable<MpaRequest[]> {
        if (featureFlag?.enabled) {
            return this.mpaRequestsService.listMpaRequests(true).pipe(
                map(pendingMpaRequests => pendingMpaRequests.content.filter(request => request.userCanApprove)),
                take(1),
            );
        } else {
            return of([]);
        }
    }
}
