import { Resource } from '../interfaces/resource';
import { Assignment } from './assignment';
import { RoleType } from './user';

export class ExternalUser extends Assignment implements Resource {
    id: string;
    name: string;
    email: string;

    /** @deprecated since its type ({@link RoleType}) is deprecated */
    role: RoleType;

    constructor(user: any) {
        super(user);
        this.id = user.name;
        this.name = user.name;
        this.email = user.name;
        this.role = user.role;
    }
}
