import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DisasterRecoveryEnrolledVm } from '../models/disaster-recovery-enrolled-vm';
import { DisasterRecoveryVirtualMachine } from '../models/disaster-recovery-virtual-machine';
import { map, Observable, Subject, tap } from 'rxjs';
import { DraasApiEnrollVms, DraasApiEnrolledVm, DraasApiMoveVms } from '@pure/paas-api-gateway-client-ts';
import { GenericService } from './generic.service';
import { ListParams } from '../interfaces/list-params';
import { isArray } from 'lodash';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';
import { DataPage } from '../interfaces/data-page';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryProtectionGroupVirtualMachinesService extends GenericService<DisasterRecoveryVirtualMachine> {
    groupVmsChanged$: Subject<string> = new Subject();

    constructor(
        protected http: HttpClient,
        private throttlingHttpClient: DisasterRecoveryThrottlingHttpClient,
    ) {
        super({
            resourceClass: DisasterRecoveryVirtualMachine,
            endpoint: `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/{clusterId}/protection-groups/{groupId}/vms`,
            list: true,
            create: true,
            update: true,
            delete: true,
        });
    }

    private getEndpoint(clusterId: string, groupId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/protection-groups/${groupId}/vms`;
    }

    private getMoveEndpoint(clusterId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/protection-groups/vms/move`;
    }

    override list(
        params?: ListParams<DisasterRecoveryVirtualMachine>,
    ): Observable<DataPage<DisasterRecoveryVirtualMachine>> {
        return this.throttlingHttpClient.queueRequest(super.list(params));
    }

    override getListRequest(params: ListParams<DisasterRecoveryVirtualMachine>): { url: string; clusterId: string } {
        const queryParams: string[] = [];
        const clusterId: string = params.filter.clusterId as string;
        const groupId: string = params.filter['groupId'] as string;

        queryParams.push(`page_number=${Math.floor(params.pageStart / params.pageSize)}`);
        queryParams.push(`page_size=${params.pageSize}`);

        if (params.filter || params.defaultFilter) {
            const filter = { ...params.filter, ...params.defaultFilter };
            Object.keys(filter)
                .filter(k => k != null && k !== 'clusterId' && k !== 'groupId')
                .forEach(k => queryParams.push(`${k}=${encodeURIComponent(filter[k] as string)}`));
        }

        if (params.sort) {
            if (isArray(params.sort)) {
                params.sort.forEach(s => queryParams.push(`sort=${s.key},${s.order}`));
            } else {
                queryParams.push(`sort=${params.sort.key},${params.sort.order}`);
            }
        }
        if (!queryParams.find(p => p.indexOf('sort=name') >= 0)) {
            queryParams.push(`sort=name,ASC`); // Append name sort if not already contained to stabilize sorts by host and datacenter
        }

        const url = this.getEndpoint(clusterId, groupId) + (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');
        return { url, clusterId };
    }

    enrollVms(
        clusterId: string,
        groupId: string,
        groupVersion: number,
        vmIds: string[],
    ): Observable<DisasterRecoveryEnrolledVm[]> {
        const url: string = this.getEndpoint(clusterId, groupId);
        const body: DraasApiEnrollVms = {
            vm_ids: vmIds,
            protection_group_version: groupVersion,
        };
        return this.throttlingHttpClient.post<DraasApiEnrolledVm[]>(url, body).pipe(
            map(response => response.map(vm => new DisasterRecoveryEnrolledVm(vm))),
            tap(_ => this.groupVmsChanged$.next(groupId)),
        );
    }

    unenrollVms(
        clusterId: string,
        groupId: string,
        groupVersion: number,
        vmIds: string[],
    ): Observable<DisasterRecoveryEnrolledVm[]> {
        const url: string = this.getEndpoint(clusterId, groupId);
        const body: DraasApiEnrollVms = {
            vm_ids: vmIds,
            protection_group_version: groupVersion,
        };
        return this.http.delete<DraasApiEnrolledVm[]>(url, { body }).pipe(
            map(response => response.map(vm => new DisasterRecoveryEnrolledVm(vm))),
            tap(_ => this.groupVmsChanged$.next(groupId)),
        );
    }

    moveVms(
        clusterId: string,
        sourceGroupId: string,
        sourceGroupVersion: number,
        targetGroupId: string,
        targetGroupVersion: number,
        vmIds: string[],
    ): Observable<DisasterRecoveryEnrolledVm[]> {
        const url: string = this.getMoveEndpoint(clusterId);

        const body: DraasApiMoveVms = {
            source_protection_group_id: sourceGroupId,
            source_protection_group_version: sourceGroupVersion,
            target_protection_group_id: targetGroupId,
            target_protection_group_version: targetGroupVersion,
            vm_ids: vmIds,
        };

        return this.throttlingHttpClient.post<DraasApiEnrolledVm[]>(url, body).pipe(
            map(response => response.map(vm => new DisasterRecoveryEnrolledVm(vm))),
            tap(_ => this.groupVmsChanged$.next(sourceGroupId)),
            tap(_ => this.groupVmsChanged$.next(targetGroupId)),
        );
    }
}
