import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    FeatureFlagDxpService,
    getApplianceRecommendationTooltip,
    Incident,
    Resource,
    SortParams,
    SubscriptionAsset,
} from '@pure1/data';
import { FeatureNames } from '../../model/FeatureNames';
import { Router } from '@angular/router';
import { SelectableColumn } from '../subscription/subscription-column-selector/subscription-column-selector.component';

export interface TableColumn<T> {
    title?: string;
    titleTemplate?: TemplateRef<any>;
    subColumns?: SelectableColumn<T>[];
    getValue?: (row: T) => string;
    valueTemplate?: TemplateRef<any>;
    getSortByValue?: (row: T) => string | number;
    getClass?: (row: T) => string;
    defaultSortAsc?: boolean;
    sortable?: boolean;
    weight?: number;
    colGroup?: string;
    colGroupTooltip?: string;
    isShown?: () => boolean;
    // Backend sort fields
    getSortKey?: () => string;
    sticky?: boolean;
}

export interface MultiSelectOptions<T> {
    shouldDisableRow: (row: T) => boolean;
}

export interface SortBy<T> {
    column: TableColumn<T>;
    sortDesc: boolean;
}

interface ColGroup {
    title: string;
    size: number;
    tooltip?: string;
}

const CLASS_NAME_SORT_DESCENT = 'st-sort-descent';
const CLASS_NAME_SORT_ASCENT = 'st-sort-ascent';

@Component({
    selector: 'subscription-page-table',
    templateUrl: 'subscription-page-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPageTableComponent<T extends Resource> implements OnChanges {
    @Input() readonly columns: TableColumn<T>[];
    @Input() readonly data: T[];
    @Input() readonly selections: T[] = [];
    @Input() readonly multiSelect: MultiSelectOptions<T>;
    @Input() readonly incidentMap: Map<string, Incident>;
    @Input() readonly loading: boolean;
    @Input() readonly noDataMsg: string;
    @Input() readonly assetsTable: boolean;
    @Input() readonly rowEndTemplate: TemplateRef<any>;
    @Input() readonly hasStickyCol: boolean = false;

    @Input() readonly sort: SortParams;
    @Input() readonly defaultSort: SortParams;

    @Output() readonly selectionsChange = new EventEmitter<T[]>();
    @Output() readonly sortChange = new EventEmitter<string>();

    @ViewChild('selectAllCheckbox') readonly selectAllCheckbox: ElementRef<HTMLInputElement>;

    readonly SHOW_TOOLTIP_LENGTH = 18; // Arbitrary length requirement for tooltip on any non-templated cell

    shownColumns: TableColumn<T>[];
    stickyColumns: TableColumn<T>[];
    nonStickyColumns: TableColumn<T>[];
    sortBy: SortBy<T>;
    dataSorted: T[] = [];
    colGroups: ColGroup[] = [];
    isRecommendationsEnabled = false;
    recommendationTooltip = '';

    constructor(
        private cd: ChangeDetectorRef,
        private featureFlagDxpService: FeatureFlagDxpService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService.getFeatureFlag(FeatureNames.RECOMMENDATIONS).subscribe(feature => {
            this.isRecommendationsEnabled = feature?.enabled;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.incidentMap && this.incidentMap?.size >= 0) {
            this.recommendationTooltip =
                this.incidentMap.size > 1
                    ? this.incidentMap.size + ' available recommendations'
                    : this.incidentMap.size + ' available recommendation';
        }

        if (changes.columns) {
            this.shownColumns = this.columns.filter(column => !column.isShown || column.isShown());
            this.initColGroups();
            this.initStickyCol();
            this.cd.detectChanges();
        }

        if (changes.data) {
            if (!this.sort) {
                this.sortData();
            } else {
                this.dataSorted = this.data ? [...this.data] : [];
            }
            this.cd.detectChanges();
            this.updateSelectAllCheckboxStatus();
        }

        if (changes.selections) {
            this.updateSelectAllCheckboxStatus();
        }

        if (changes.sort) {
            this.sortBy = {
                column: this.sortByColumn(),
                sortDesc: this.sort.order === 'desc',
            };
        }
    }

    getCellClasses(column: TableColumn<T>, row: T): string[] {
        const classes = [this.getWeightClass(column)];
        if (column.getClass) {
            classes.push(column.getClass(row));
        }
        return classes;
    }

    getWeightClass(column: TableColumn<T>): string {
        return 'weight-' + column?.weight;
    }

    toggleSort(column: TableColumn<T>): void {
        if (!this.isSortable(column)) {
            return;
        }

        if (column.getSortKey) {
            // Use backend sorting
            this.sortChange.emit(column.getSortKey());
            return;
        }

        if (!this.sortBy || this.sortBy.column.title !== column.title) {
            this.sortBy = { column, sortDesc: !column.defaultSortAsc };
        } else {
            this.sortBy.sortDesc = !this.sortBy.sortDesc;
        }

        this.sortData();
    }

    getSortByClass(column: TableColumn<T>): string {
        if (this.sortBy?.column === column) {
            return this.sortBy.sortDesc ? CLASS_NAME_SORT_DESCENT : CLASS_NAME_SORT_ASCENT;
        }
        return null;
    }

    isSortable(column: TableColumn<T>): boolean {
        return !!(column.getSortByValue || (column.sortable && column.getValue) || column.getSortKey);
    }

    onRowClicked(row: T): void {
        if (!this.multiSelect) {
            // Only emit if the row wasn't already selected for single select
            if (!this.isItemSelected(row)) {
                this.selectionsChange.emit([row]);
            }

            return;
        }

        if (this.multiSelect.shouldDisableRow(row)) {
            return;
        }

        const newSelections = this.selections.slice();

        if (this.isItemSelected(row)) {
            const rowIndex = newSelections.findIndex(selection => selection.id === row.id);
            newSelections.splice(rowIndex, 1);
        } else {
            newSelections.push(row);
        }

        this.selectionsChange.emit(newSelections);
    }

    clickSelectAllCheckbox(event: MouseEvent | TouchEvent): void {
        if (!this.data || this.data.length === 0) {
            return;
        }

        const selectedItems = this.getSelectedItems();

        if (selectedItems.length === 0) {
            this.selectionsChange.emit(
                this.selections.concat(this.data.filter(asset => !this.multiSelect.shouldDisableRow(asset))),
            );
        } else {
            const newSelections = this.selections.filter(
                selection => !this.data.some(data => selection.id === data.id),
            );
            this.selectionsChange.emit(newSelections);
        }
    }

    isItemSelected(item: T): boolean {
        return this.selections?.some(selectedItem => selectedItem.id === item.id);
    }

    getStickyCurrentSubscriptionClass(multiSelect: MultiSelectOptions<T>): string {
        if (multiSelect && this.isRecommendationsEnabled) {
            return 'sticky-columns-with-multiselect-recommendation';
        } else if (multiSelect && !this.isRecommendationsEnabled) {
            return 'sticky-columns-with-multiselect';
        } else {
            return '';
        }
    }

    goToCatalogPage(row: T): void {
        const incident = this.incidentMap.get(row.id);
        if (incident) {
            this.router.navigate(['/services/servicecatalog/recommendationsview'], {
                queryParams: { selection: `incident-${incident.id}` },
            });
        }
    }

    doesAssetHaveIncident(row: T): boolean {
        return this.incidentMap?.has(row.id);
    }

    private updateSelectAllCheckboxStatus(): void {
        // The "indeterminate" property is not available as an html attribute, which is why it gets set programatically here
        const checkedCount = this.getSelectedItems().length;
        if (this.multiSelect && this.selectAllCheckbox) {
            const checkbox = this.selectAllCheckbox.nativeElement;

            if (checkedCount === 0) {
                checkbox.checked = false;
                checkbox.indeterminate = false;
            } else if (checkedCount === this.data.length) {
                checkbox.checked = true;
                checkbox.indeterminate = false;
            } else {
                checkbox.checked = false;
                checkbox.indeterminate = true;
            }
        }
    }

    private getSelectedItems(): T[] {
        return (this.data || []).filter(item => this.isItemSelected(item));
    }

    private sortData(): void {
        this.dataSorted = this.data ? [...this.data] : [];
        if (this.sortBy) {
            this.dataSorted.sort((a, b) => this.compareFields(a, b, this.sortBy));
        }
    }

    private compareFields(a: T, b: T, sortBy: SortBy<T>): number {
        let compare;
        const fieldA = this.getField(a, sortBy);
        const fieldB = this.getField(b, sortBy);
        if (typeof fieldA === 'string') {
            compare = fieldA.localeCompare(fieldB as string);
        } else {
            compare = fieldA - (fieldB as number);
        }
        return compare * (sortBy.sortDesc ? -1 : 1);
    }

    private getField(row: T, sortBy: SortBy<T>): string | number {
        const field = sortBy.column.getSortByValue ? sortBy.column.getSortByValue(row) : sortBy.column.getValue(row);
        if (typeof field !== 'string') {
            return field ?? -1; // push nulls before zeros
        } else {
            return field;
        }
    }

    private initStickyCol(): void {
        this.stickyColumns = this.columns?.filter(col => col.sticky);
        this.nonStickyColumns = this.columns?.filter(col => !col.sticky);
    }

    private initColGroups(): void {
        this.colGroups = [];

        if (this.shownColumns.length === 0) {
            return;
        }

        let currentColGroup: ColGroup = {
            title: this.shownColumns[0].colGroup,
            size: 1,
            tooltip: this.shownColumns[0].colGroupTooltip,
        };
        const colGroups: ColGroup[] = [currentColGroup];
        for (let idx = 1; idx < this.shownColumns.length; idx++) {
            const { colGroup, colGroupTooltip } = this.shownColumns[idx];
            if (colGroup === currentColGroup.title) {
                currentColGroup.size++;
            } else {
                currentColGroup = { title: colGroup, size: 1, tooltip: colGroupTooltip };
                colGroups.push(currentColGroup);
            }
        }

        if (colGroups.some(colGroup => colGroup.title)) {
            this.colGroups = colGroups;
        }
    }

    // Find the column (or subColumn) that the table is currently sorted by.
    private sortByColumn() {
        for (const column of this.columns) {
            if (column.getSortKey?.() === this.sort.key) {
                return column;
            }
            if (column.subColumns) {
                for (const subColumn of column.subColumns) {
                    if (subColumn.column.getSortKey?.() === this.sort.key) {
                        return subColumn.column;
                    }
                }
            }
        }
        return null;
    }

    protected readonly SubscriptionAsset = SubscriptionAsset;
    protected readonly getApplianceRecommendationTooltip = getApplianceRecommendationTooltip;
}
