import _ from 'lodash';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'autocomplete-match',
    template: '<span [innerHTML]="matchString"></span>',
})
export class AutocompleteMatchComponent implements OnInit, OnChanges {
    @Input() readonly value: string;
    @Input() readonly match: string;

    // non-bindings
    matchString = '';

    ngOnInit(): void {
        this.createMatchString();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value || changes.match) {
            this.createMatchString();
        }
    }

    createMatchString(): void {
        const value = _.escape(this.value || '');
        this.matchString = value;
        if (value && this.match) {
            const match = _.escape(this.match);
            const regex = new RegExp('(' + _.escapeRegExp(match) + ')', 'gi');
            this.matchString = value.replace(regex, '<span class="match-region">$1</span>');
        }
    }
}
