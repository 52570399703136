<hive-content-dialog heading="A new look for Pure 1" showCloseButton="true" (contentDialogClose)="close()">
    <div class="introducing-raspberry-theming-dialog-content">
        <div class="introducing-raspberry-theming-dialog-content-image-container">
            <img
                alt="Raspberry theming"
                class="introducing-raspberry-theming-dialog-content-image"
                src="/images/introducing-raspberry-theming-dialog.png"
            />
        </div>

        <div class="introducing-raspberry-theming-dialog-content-text-container">
            <p class="hive-paragraph-semibold hive-paragraph-large"> A first step in building a better UI </p>
            <p class="hive-paragraph-regular hive-paragraph-large">
                At Pure Storage, we strive to create user experiences that are intuitive, seamless, and empowering. Our
                latest Pure1 update brings a refreshed theme that enhances the platform’s design and visual appeal. It’s
                a bold step forward to pave the way for even more exciting innovations.
                <span class="hive-paragraph-bold">Dive in and experience the new Pure1 today!</span>
            </p>
        </div>
    </div>
    <div slot="actions-left">
        <hive-checkbox [ngModel]="false" (ngModelChange)="updateDoNotShowAgain($event)" class="checkbox">
            Don't show this message any more
        </hive-checkbox>
    </div>
    <div slot="actions-right">
        <hive-button (click)="close()" label="Continue" />
    </div>
</hive-content-dialog>
