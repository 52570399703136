import moment from 'moment';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { PGroupSafemode, Resource, UnifiedArray } from '@pure1/data';
import { MAX_SELECTED_APPLIANCES } from '../../services/safemode-multiparty-authorization.service';
import { getDurationDays, getUniqueArrayIdsOfArraysAndPGroups, isFlashArray, isOutOfSupport } from '../../util';

interface ArrayError {
    id: string;
    errorMessage: string;
}

type SortableColumns = 'name' | 'model' | 'version';

const OUT_OF_SUPPORT = 'Support expired';

@Component({
    selector: 'safemode-array-selector-multiparty-auth',
    templateUrl: 'safemode-array-selector-multiparty-auth.component.html',
})
export class SafeModeArraySelectorMultipartyAuthComponent implements OnChanges, OnInit, OnDestroy {
    @Input() arrays: UnifiedArray[] = [];
    @Input() loading: boolean;
    @Input() initialSelection: UnifiedArray[];
    @Input() selectedPGroups: PGroupSafemode[];
    @Output() readonly onCancel = new EventEmitter();
    @Output() readonly onForward = new EventEmitter<UnifiedArray[]>();

    sortColumnName: SortableColumns = 'name';
    isSortingAscending = true;

    filterValue = '';
    filter$ = new BehaviorSubject<string>('');

    pageOffset = 0;
    pageLength = 10;

    filteredSortedArrays: UnifiedArray[] = [];
    pagedArrays: UnifiedArray[] = [];

    // used for a quick array search by id
    arraysMap: Map<string, UnifiedArray> = new Map<string, UnifiedArray>();

    // selected arrays map tracks which arrays's checkboxes are checked
    // do not manipulate this directly, use selectArray and unselectArray instead
    selectedArraysMap: { [id: string]: boolean } = {};

    // array of selected arrays
    // do not manipulate this directly, use selectArray and unselectArray instead
    selectedArrays: UnifiedArray[] = [];

    // Tracker any loadings going on on certain arrays
    arrayLoadingCounter: { [id: string]: number } = {};

    // disabled arrays map tells you which arrays can't be selected for upgrade
    // each array is mapped to a text containing the reason for disability
    disabledArrays: { [id: string]: string } = {};

    // map of version errors for quick access from template
    arrayErrorsMap: { [id: string]: ArrayError } = {};

    private readonly destroy$ = new Subject<void>();

    readonly maxSelectedAppliances = MAX_SELECTED_APPLIANCES;

    constructor() {}

    ngOnInit(): void {
        this.filter$.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe((value: string) => {
            this.updateFilteredArrays(value);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // disable arrays out of support
        if (changes.arrays) {
            this.arraysMap.clear();
            this.arrayLoadingCounter = {};
            this.arrayErrorsMap = {};
            this.filteredSortedArrays = [...changes.arrays.currentValue];

            this.updateFilteredArrays('');
            this.arrays.forEach(arr => {
                this.arraysMap.set(arr.id, arr);
                if (isOutOfSupport(arr)) {
                    this.disabledArrays[arr.id] =
                        OUT_OF_SUPPORT + ' on ' + arr.contract_expiration?.format('YYYY-MM-DD');
                    this.unselectArray(arr.id);
                }
                this.arrayLoadingCounter[arr.id] = 0;
            });

            this.initialize();
        }
    }

    initialize(): void {
        if (this.initialSelection && this.initialSelection.length > 0) {
            this.selectedArrays = [];
            this.selectedArraysMap = {};
            this.initialSelection.forEach(arr => {
                this.selectArray(arr.id);
            });
        }
    }

    getSortClass(columnName: SortableColumns): string {
        if (!this.canSortByColumn(columnName)) {
            return '';
        }
        if (columnName === this.sortColumnName) {
            const sortClass = 'st-sort-' + (this.isSortingAscending ? 'ascent' : 'descent');
            return `manual-sort ${sortClass}`;
        }

        return 'manual-sort';
    }

    sortByColumn(columnName: SortableColumns): void {
        if (!this.canSortByColumn(columnName)) {
            return;
        }
        if (this.sortColumnName === columnName) {
            this.isSortingAscending = !this.isSortingAscending;
        } else {
            this.sortColumnName = columnName;
            this.isSortingAscending = true;
        }

        this.sortArrays();
    }

    private canSortByColumn(columnName: string): boolean {
        return !['storage', 'status', 'eradicationTimer'].includes(columnName);
    }

    sortArrays(): void {
        const sortDirection = this.isSortingAscending ? 1 : -1;
        this.filteredSortedArrays = this.filteredSortedArrays.sort(
            (a, b) => sortDirection * a[this.sortColumnName].localeCompare(b[this.sortColumnName]),
        );
        this.pageArrays();
    }

    pageArrays(): void {
        this.pagedArrays = this.filteredSortedArrays.slice(this.pageOffset, this.pageOffset + this.pageLength);
    }

    updateFilter(): void {
        this.filter$.next(this.filterValue);
    }

    updateFilteredArrays(filter: string): void {
        this.filteredSortedArrays = this.arrays.filter(
            arr =>
                !filter ||
                arr.name.toLowerCase().includes(filter.toLocaleLowerCase()) ||
                arr.version.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                arr.model.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
        );

        this.sortArrays();
    }

    private filterSafeModeField(
        filterValue: string,
        array: UnifiedArray,
        flashArrayValues: string[],
        flashBladeValues: string[],
    ): boolean {
        if (!filterValue) {
            return true;
        }
        const filterValueLowerCase = filterValue.toLocaleLowerCase();
        if (isFlashArray(array)) {
            return flashArrayValues.some(value => value.toLocaleLowerCase().includes(filterValueLowerCase));
        } else {
            return flashBladeValues.some(value => value.toLocaleLowerCase().includes(filterValueLowerCase));
        }
    }

    // builds output data structure and emits onForward event
    onNext(): void {
        this.onForward.emit(this.selectedArrays);
    }

    isFlashArray(array: UnifiedArray): boolean {
        return isFlashArray(array);
    }

    selectArray(id: string): void {
        if (!this.selectedArrays.find(resource => resource.id === id)) {
            const array = this.arrays.find(arr => arr.id === id);

            if (array) {
                this.selectedArraysMap[id] = true;
                this.selectedArrays = [...this.selectedArrays, array];
            }
        }

        this.removeArrayError(id);
    }

    unselectArray(id: string): void {
        this.selectedArraysMap[id] = false;
        this.selectedArrays = this.selectedArrays.filter(arr => arr.id !== id);

        this.removeArrayError(id);
    }

    updateSelectedArrays(updatedResources: Resource[]): void {
        const itemsToUnselect: Resource[] = [];
        this.selectedArrays.forEach(resource => {
            if (!updatedResources.find(updatedResource => updatedResource.id === resource.id)) {
                itemsToUnselect.push(resource);
            }
        });

        itemsToUnselect.forEach(resource => {
            this.unselectArray(resource.id);
        });
    }

    toggleArraySelection(id: string): void {
        if (this.selectedArraysMap[id]) {
            this.unselectArray(id);
        } else {
            this.selectArray(id);
        }
    }

    removeArrayError(id: string): void {
        const error = this.arrayErrorsMap[id];
        if (error) {
            const { [id]: _, ...restOfErrors } = this.arrayErrorsMap;
            this.arrayErrorsMap = restOfErrors;
        }
    }

    canSchedule = ({
        selectedArrays,
        selectedPGroups,
    }: {
        selectedArrays: UnifiedArray[];
        selectedPGroups: PGroupSafemode[];
    }): boolean => {
        if (!selectedArrays) {
            return false;
        }
        return (
            selectedArrays.length > 0 &&
            getUniqueArrayIdsOfArraysAndPGroups(selectedArrays, selectedPGroups).size <= MAX_SELECTED_APPLIANCES
        );
    };

    reachedArrayLimit({
        selectedArrays,
        selectedPGroups,
    }: {
        selectedArrays: UnifiedArray[];
        selectedPGroups: PGroupSafemode[];
    }): boolean {
        if (!selectedArrays) {
            return false;
        }
        return getUniqueArrayIdsOfArraysAndPGroups(selectedArrays, selectedPGroups).size > MAX_SELECTED_APPLIANCES;
    }

    trackByArrayId(index: number, item: ArrayError): string {
        return item.id;
    }

    getDurationDays(duration: moment.Duration): string {
        return getDurationDays(duration);
    }

    onPageChange(pageOffset: number): void {
        this.pageOffset = pageOffset;
        this.pageArrays();
    }
}
