import { Input, TemplateRef, Directive } from '@angular/core';
import { PanelTabName } from '../../../../redux/pure-redux.service';

@Directive({
    selector: '[side-panel-tab]',
})
export class SidePanelTabDirective {
    @Input() readonly name: PanelTabName;
    @Input() readonly icon: string;
    @Input() readonly notificationText: string;
    @Input() readonly attentionBubbleText: string;
    @Input() readonly tooltipText: string;

    constructor(public templateRef: TemplateRef<any>) {}

    toString(): string {
        return this.name;
    }
}
