import { EdgeServiceAgentInstance } from './edge-service-agent-instance';

export class EdgeServiceAgentVersionWithPermissions {
    arrayIds: string[];
    agent: EdgeServiceAgentInstance;
    version: string;
    permissions: string[];

    constructor(json: any) {
        this.arrayIds = json.array_ids;
        this.agent = json.agent ? new EdgeServiceAgentInstance(json.agent) : null;
        this.version = json.version;
        this.permissions = json.permissions;
    }
}
