import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { MarketplaceOrderContact } from '../models/marketplace-order-contact';

@Injectable({ providedIn: 'root' })
export class MarketplaceOrderContactService extends GenericService<MarketplaceOrderContact> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: MarketplaceOrderContact,
            endpoint: '/rest/v1/marketplace/order-contacts',
        });
    }
}
