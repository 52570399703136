import { Resource } from '../interfaces/resource';

export class ArraySafeModeEligibilityInfo implements Resource {
    readonly name: string;
    readonly id: string;
    arrayId: string;
    eligible: boolean;
    errorCode: SafeModeEligibilityErrorCode;

    constructor(json: any) {
        this.id = json.array_id;
        this.arrayId = json.array_id;
        this.eligible = json.eligible;
        this.errorCode = json.error_code;
    }
}

export enum SafeModeEligibilityErrorCode {
    UNKNOWN = 'UNKNOWN',
    NO_DATA = 'NO_DATA',
    UNSUPPORTED_ARRAY_OR_VERSION = 'UNSUPPORTED_ARRAY_OR_VERSION',
    SNAPSHOTS_PRESENT = 'SNAPSHOTS_PRESENT',
}
