import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { VMCollector } from '../models/vm-collector';

@Injectable({ providedIn: 'root' })
export class VMCollectorService extends GenericService<VMCollector> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: VMCollector,
            endpoint: '/rest/v1/vmanalytics/collectors',
            list: true,
            create: true,
            update: false,
            delete: true,
        });
    }
}
