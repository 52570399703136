import { Resource } from '../interfaces/resource';
import { AllowListedAccountLabel } from './AllowListedAccountLabel';

export interface SubscriptionResourceType {
    id: string;
    type: string;
}

export class AllowListedAccount implements Resource {
    readonly id: string;
    readonly name: string;
    readonly resource: SubscriptionResourceType;
    readonly account: SubscriptionResourceType;
    labels: AllowListedAccountLabel[];

    constructor(json: any) {
        this.id = json.account && json.account.id;
        this.name = json.name;
        this.resource = json.resource;
        this.account = json.account;
    }
}
