import moment from 'moment';

import { Resource } from '../interfaces/resource';
import { EdgeServiceAgentInstance } from './edge-service-agent-instance';

export class ArrayWithAgents implements Resource {
    org_id: number;
    id: string;
    name: string;
    gateway_update_status: string;
    gateway_status: string;
    gateway_version_current: string;
    gateway_version_recommended: string;
    last_updated: moment.Moment;
    purity_version: string;
    agents: EdgeServiceAgentInstance[];

    constructor(json: any) {
        this.org_id = json.org_id;
        this.id = json.id;
        this.name = json.name;
        this.gateway_update_status = json.gateway_update_status;
        this.gateway_status = json.gateway_status;
        this.gateway_version_current = json.gateway_version_current;
        this.gateway_version_recommended = json.gateway_version_recommended;
        this.last_updated = json.last_updated ? moment.unix(json.last_updated) : null;
        this.purity_version = json.purity_version;
        this.agents = (json.agents || []).map(agent => new EdgeServiceAgentInstance(agent));
    }
}

export enum GatewayHealthStatus { // used for summary panel honeycomb in gateway page
    HEALTHY = 'healthy',
    UNHEALTHY = 'unhealthy',
    NA = 'na',
    OFFLINE = 'offline',
    EDGE_SERVICE_NOT_ENABLED = 'edgeServiceNotEnabled',
    PURITY_UPGRADE_REQUIRED = 'purityUpgradeRequired',
}

export enum AgentUpdateStatus { // used for summary panel radial chart in gateway and agent page
    GATEWAY_INACTIVE = 'inactive', // gateway only
    ELIGIBLE_FOR_UPDATE = 'eligibleForUpdate',
    ELIGIBLE_BUT_UPDATE_DISALLOWED = 'eligibleButDisallowed',
    ELIGIBLE_BUT_PURITY_UPDATE_REQUIRED = 'eligibleButPurityUpdateRequired',
    UP_TO_DATE = 'upToDate',
    INSTALLING = 'installing',
    UPDATING = 'updating',
    UNINSTALLING = 'uninstalling',
    INSTALL_FAILED = 'installFailed',
    UPDATE_FAILED = 'updateFailed',
    UNINSTALL_FAILED = 'uninstallFailed',
}

export enum AgentUpdateStatusText {
    inactive = 'Inactive',
    eligibleForUpdate = 'Eligible for updates',
    eligibleButDisallowed = 'Eligible (updates disallowed)',
    eligibleButPurityUpdateRequired = 'Eligible (Purity update required)',
    upToDate = 'Up-to-date',
    installing = 'Installing',
    updating = 'Updating',
    uninstalling = 'Uninstalling',
    installFailed = 'Install failed',
    updateFailed = 'Update failed',
    uninstallFailed = 'Uninstall failed',
}

export enum ApplianceAgentInfo { // used for summary panel honeycomb in agent page
    APPLIANCE_WITH_AGENT = 'applianceWithAgent',
    NO_AGENT_INSTALLED = 'noAgentInstalled',
    EDGE_SERVICE_NOT_ENABLED = 'edgeServiceNotEnabled',
    PURITY_UPGRADE_REQUIRED = 'purityUpgradeRequired',
    OFFLINE = 'offline',
}
