import { Observable, forkJoin, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    Component,
    OnInit,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnChanges,
    SimpleChanges,
    QueryList,
    ViewChildren,
    AfterViewInit,
    Renderer2,
    Inject,
} from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { IAutocompleteKey, Resource, EntityDisplayNameMap, GFBEntity } from '@pure1/data';

import {
    AutocompleteDirective,
    IPureAutocompleteOptions,
    NamedValue,
    Category,
} from '../../ui/directives/autocomplete.directive';
import { createFilter } from '../../redux/utils';
import { DisplayKeyValueLookupService } from '../../services/display-key-value-lookup.service';
import { KEY } from '../key.enum';
import { Pill } from '../pill';
import { GfbAutocompleteService } from '../../services/gfb-autocomplete.service';
import { IStateFilter } from '../../redux/pure-redux.service';
import { WINDOW } from '../../app/injection-tokens';

// Note: if we update the padding on the gfb bar this will need to be updated
const GFB_PADDING = 95;

export type FilterChanges = {
    filters: IStateFilter[];
    refocusing: boolean;
};

@Component({
    selector: 'gfb-editor',
    templateUrl: 'gfb-editor.component.html',
})
export class GfbEditorComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() readonly entities: GFBEntity[];
    @Input() readonly editFilters: IStateFilter[] = [];
    // We only need this for the count of pills for now.
    // Later, we might aggressively merge as soon as the entity key is selected, and knowing all the pills could let us do that
    @Input() readonly pills: Pill[] = [];
    @Input() readonly disabled: boolean = false;
    @Input() readonly supportStatusFilterOption: SupportStatusFilterOption;

    @Output() readonly selectionChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly filtersChange: EventEmitter<FilterChanges> = new EventEmitter();
    @Output() readonly defaultFilter: EventEmitter<string> = new EventEmitter();
    @Output() readonly viewSelection: EventEmitter<Resource> = new EventEmitter();

    @ViewChildren(AutocompleteDirective) readonly autocompleteDirectives: QueryList<AutocompleteDirective<string>>;
    @ViewChild('keyInput', { static: true }) readonly keyInput: ElementRef;
    @ViewChild('valueInput', { static: true }) readonly valueInput: ElementRef;

    selectedValues: NamedValue<string>[] = [];
    currentKey: IAutocompleteKey;

    autocompleteKeysOptions: IPureAutocompleteOptions<string> = {
        list: match$ => this.listKeys(match$),
        multiSelect: false,
        cleanInputOnEmpty: true,
    };
    autocompleteValuesOptions: IPureAutocompleteOptions<string> = {
        list: match$ => this.listValues(match$),
        multiSelect: true,
        cleanInputOnEmpty: true,
    };

    currentEntity: string;

    private keyAutocomplete: AutocompleteDirective<string>;
    private valueAutocomplete: AutocompleteDirective<string>;
    private completionState: 'values' | 'keys' = 'keys';
    private keyMap: Map<string, IAutocompleteKey>;
    private gfbElement: HTMLElement;
    private commitWithEnter = false;

    constructor(
        private el: ElementRef<HTMLElement>,
        private autocomplete: GfbAutocompleteService,
        private renderer: Renderer2,
        private angulartics2: Angulartics2,
        private displayKeyValueLookupService: DisplayKeyValueLookupService,
        @Inject(WINDOW) private window: Window,
    ) {}

    ngOnInit(): void {
        this.keyMap = new Map<string, IAutocompleteKey>();
        this.initializeAutocompleteComponents();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.editFilters) {
            if (this.editFilters && this.editFilters.length > 0) {
                this.startEdit();
            }
        }
    }

    ngAfterViewInit(): void {
        this.autocompleteDirectives.forEach(directive => {
            if (directive.input.classList.contains('value')) {
                this.valueAutocomplete = directive;
            } else {
                this.keyAutocomplete = directive;
            }
        });
    }

    getPlaceholder(): string {
        // only show the 'Filter' placeholder when no pills are in the GFB
        return this.pills.length > 0 ? '' : 'Filter';
    }

    onFocus(refocusing = false): void {
        this.window.setTimeout(() => {
            // for some reason, setImmediate() does not work here
            this.computeMaxSize();
            if (this.completionState === 'keys') {
                this.keyInput.nativeElement.focus();
            } else {
                if (refocusing) {
                    try {
                        const evt = new Event('focus');
                        (evt as any).refocusing = true;
                        this.valueInput.nativeElement.dispatchEvent(evt);
                    } catch (err) {
                        if (err && (err.number === -2146827843 || err.name === 'TypeError')) {
                            console.log('Ignoring IE11-specific error', err); // Ignore IE11 "Object doesn't support this action"
                        } else {
                            throw err;
                        }
                    }
                }
                this.valueInput.nativeElement.focus();
            }
        }, 0);
    }

    onSelectKey($event: NamedValue<string>[]): void {
        if ($event.length > 0 && $event[0].value && this.keyMap.has($event[0].value)) {
            this.currentEntity = $event[0].category.name;
            this.currentKey = this.keyMap.get($event[0].value);
            this.completionState = 'values';
            this.keyInput.nativeElement.value = '';
            this.onFocus();
        } else {
            // in this case, the key autocomplete directive has emitted an empty list of named values
            // We interpret this as selecting a default filter (i.e. typed a value into the GFB and pressed Enter)
            if (this.keyInput.nativeElement.value !== '') {
                this.defaultFilter.emit(this.keyInput.nativeElement.value);
                this.keyInput.nativeElement.value = '';
            }
        }
    }

    onSelectValues($event: NamedValue<string>[]): void {
        const values: string = this.valueInput.nativeElement.value;
        // we should pick up either the whole text field or the remaining text after the last comma as filter values as well
        const lastCommaIndex = values.lastIndexOf(','); // returns -1 if no comma is found
        const extraFilterValue = values.substring(lastCommaIndex + 1).trim(); // will be the whole string if there's no comma
        if (extraFilterValue.length > 0) {
            $event.push({ name: extraFilterValue, value: extraFilterValue });
        }

        let filters: IStateFilter[] = [];
        if ($event.length > 0) {
            filters = $event.map((value: NamedValue<string>) => {
                return createFilter(
                    this.currentEntity,
                    this.currentKey.namespace,
                    this.currentKey.key,
                    value.value || value.name, // if we have an undefined value, use the name as the value
                    this.currentKey.display_key,
                    value.name,
                );
            });
        } else {
            if (values.trim() === '') {
                // we pressed enter without any text in the value field, or any item selected from the autocomplete menu
                // ignore the event
                return;
            }
        }
        this.filtersChange.emit({ filters, refocusing: this.commitWithEnter });
        this.commitWithEnter = false;
        this.currentKey = null;
        this.completionState = 'keys';
        this.selectedValues = [];

        this.valueInput.nativeElement.value = '';
        // dispatch input
        this.sendInputEvent(this.valueInput);
        this.valueAutocomplete.destroyMenu();
    }

    onValueKeyDown($event: KeyboardEvent): void {
        switch ($event.key) {
            case KEY.backspace:
            case KEY.left:
                $event.stopImmediatePropagation();
                if (this.valueInput.nativeElement.value === '' && this.completionState === 'values') {
                    const keyValue = this.currentKey.display_key;
                    this.currentKey = null;
                    this.currentEntity = null;
                    this.completionState = 'keys';

                    this.angulartics2.eventTrack.next({
                        action: `GFB ${$event.key} cancel value editing`,
                        properties: { category: 'Action' },
                    });

                    this.window.setImmediate(() => {
                        this.keyInput.nativeElement.focus();
                        this.valueAutocomplete.destroyMenu();
                        this.valueInput.nativeElement.value = null;
                        if ($event.key === KEY.left) {
                            // if they hit left arrow, repopulate the input with the display key
                            // and open the dropdown
                            this.keyInput.nativeElement.value = keyValue;
                            try {
                                this.keyInput.nativeElement.dispatchEvent(new Event('input'));
                            } catch (err) {
                                if (err && (err.number === -2146827843 || err.name === 'TypeError')) {
                                    console.log('Ignoring IE11-specific error', err); // Ignore IE11 "Object doesn't support this action"
                                } else {
                                    throw err;
                                }
                            }
                        } else {
                            this.keyInput.nativeElement.value = null;
                        }
                    });
                } else if (this.keyInput.nativeElement.value === '' && this.completionState === 'keys') {
                    this.angulartics2.eventTrack.next({
                        action: `GFB ${$event.key} pill select`,
                        properties: { category: 'Action' },
                    });
                    this.selectionChange.emit();
                }
                break;
            case KEY.delete:
            case KEY.right:
                $event.stopImmediatePropagation();
                break;
            case KEY.enter:
                $event.stopImmediatePropagation();
                this.commitWithEnter = true;
                break;
            default:
        }
    }

    listKeys(match$: Observable<string>): Observable<NamedValue<string>[]> {
        const observables = this.entities.map(entity => {
            return this.autocomplete.getKeys(match$.pipe(map(match => ({ entity, match }))));
        });

        return zip(...observables, (...results) => {
            const hasSubArrayEntity = results.some(kr =>
                ['volumes', 'directories', 'file systems', 'pods'].includes(kr.entity),
            );

            const namedValueResults: NamedValue<string>[] = [];
            this.keyMap.clear();
            results.forEach(keyResponse => {
                keyResponse.keys
                    .filter(key => {
                        return !this.shouldSkipKey(key, keyResponse.entity, hasSubArrayEntity);
                    })
                    .forEach(key => {
                        const namedValue = this.formatNamedValue(key, keyResponse.entity);
                        namedValueResults.push(namedValue);
                        this.keyMap.set(namedValue.value, key);
                    });
            });
            return namedValueResults;
        });
    }

    listValues(match$: Observable<string>): Observable<NamedValue<string>[]> {
        return this.autocomplete
            .getValues(
                match$.pipe(
                    map(match => ({
                        entity: this.currentEntity,
                        namespace: this.currentKey.namespace,
                        key: this.currentKey.key,
                        match,
                    })),
                ),
                this.supportStatusFilterOption,
            )
            .pipe(
                map(autocompleteValues =>
                    autocompleteValues.map(autocompleteValue => ({
                        name: autocompleteValue.display_value,
                        value: autocompleteValue.value,
                    })),
                ),
            );
    }

    completingKeys(): boolean {
        return this.completionState === 'keys';
    }

    startEdit(): void {
        if (
            this.selectedValues.length > 0 ||
            (this.valueInput.nativeElement.value && this.valueInput.nativeElement.value.length !== 0)
        ) {
            // if we have a selection (or part of one), before beginning our new edit commit those values
            this.onSelectValues(this.selectedValues);
        }
        const filter = this.editFilters[0];
        this.currentEntity = filter.entity;
        this.displayKeyValueLookupService
            .getDisplayKey(filter.entity, filter.namespace, filter.key)
            .pipe(take(1))
            .subscribe((displayKey: string) => {
                this.currentKey = {
                    namespace: filter.namespace,
                    key: filter.key,
                    display_key: displayKey,
                };
            });
        this.completionState = 'values';

        forkJoin(
            this.editFilters.map(filter =>
                this.displayKeyValueLookupService.getDisplayValue(
                    filter.entity,
                    filter.namespace,
                    filter.key,
                    filter.value,
                    this.supportStatusFilterOption,
                ),
            ),
        )
            .pipe(take(1))
            .subscribe(displayValues => {
                this.selectedValues = this.editFilters.map((filter, index) => {
                    return {
                        name: displayValues[index],
                        value: filter.value,
                    };
                });
            });

        this.onFocus();
        this.keyInput.nativeElement.value = '';
        this.keyAutocomplete.destroyMenu();
    }

    stopEdit(): void {
        if (this.completionState === 'values') {
            this.currentKey = null;
            this.currentEntity = null;
            this.completionState = 'keys';
            this.selectedValues = [];

            this.angulartics2.eventTrack.next({
                action: `GFB x button cancel value editing`,
                properties: { category: 'Action' },
            });

            this.valueInput.nativeElement.value = '';
            this.sendInputEvent(this.valueInput);
            this.valueAutocomplete.destroyMenu();
            this.keyInput.nativeElement.value = '';
            this.onFocus();
        }
    }

    private formatNamedValue(key: IAutocompleteKey, entity: string): NamedValue<string> {
        return {
            name: key.display_key,
            // Key is not guaranteed to be unique per entity, so making entity+key pair
            value: `${entity}+${key.key}`,
            category: this.getCategory(key, entity),
        };
    }

    private getCategory(key: IAutocompleteKey, entity: string): Category {
        const isArrayEntity = entity === 'arrays';
        const isTagKey = isArrayEntity && !!key.namespace;

        // we use the arrays name for tag keys so that value autocomplete endpoint is correct
        const name = isTagKey ? 'arrays' : entity;
        const mapDisplayName = EntityDisplayNameMap[entity];
        const displayName = isTagKey ? 'tags' : isArrayEntity ? 'appliances' : mapDisplayName || entity;
        return {
            name,
            displayName,
            icon: null,
        };
    }

    private shouldSkipKey(key: IAutocompleteKey, entity: string, hasSubArrayEntity: boolean): boolean {
        // CLOUD-53868: Don't show some Appliance keys when also showing Volumes to avoid confusion (see jira for details)
        const keysToSkip = ['host_hostname_list', 'host_iqn_wwn_list', 'has_end_of_life_hardware'];
        const shouldSkipKeyWhenHasSubArrayEntity =
            entity === 'arrays' && // Only apply on the array keys
            hasSubArrayEntity && // Only filter out when also showing a "sub-array" entity
            keysToSkip.includes(key.key) &&
            !this.pills.some(pill => pill.key() === key.key); // Don't skip if the key is used in an existing filter (eg from a sticky filter from another page)

        return shouldSkipKeyWhenHasSubArrayEntity;
    }

    private initializeAutocompleteComponents(): void {
        const menuAppendToKeys = this.el.nativeElement.querySelector('.autocomplete-menu-holder.keys');
        const menuAppendToValues = this.el.nativeElement.querySelector('.autocomplete-menu-holder.values');
        const editorWrapper = this.el.nativeElement.querySelector('.gfb-editor-wrapper');
        this.autocompleteKeysOptions.appendTo = menuAppendToKeys;
        this.autocompleteValuesOptions.appendTo = menuAppendToValues;
        this.autocompleteKeysOptions.scrollEl = editorWrapper;
        this.autocompleteValuesOptions.scrollEl = editorWrapper;
    }

    private computeMaxSize(): void {
        this.gfbElement = this.gfbElement || this.el.nativeElement.closest('.gfb');
        this.renderer.setStyle(this.el.nativeElement, 'max-width', `${this.gfbElement.clientWidth - GFB_PADDING}px`);
    }

    // send an input event to an input event that will be ignored by the autocomplete directive
    private sendInputEvent(inputElement: ElementRef): void {
        try {
            const inputEvent = new Event('input');
            (inputEvent as any).$pure1 = true;
            inputElement.nativeElement.dispatchEvent(inputEvent);
        } catch (err) {
            if (err && (err.number === -2146827843 || err.name === 'TypeError')) {
                console.log('Ignoring IE11-specific error', err); // Ignore IE11 "Object doesn't support this action"
            } else {
                throw err;
            }
        }
    }
}
