import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Group } from '../models/group';
import { DataPage } from '../interfaces/data-page';
import { QueryParams } from '../interfaces/list-params';
import { IRestResponse, Collection } from '../interfaces/collection';

const ENDPOINT = '/rest/v2/idp/groups';

@Injectable({ providedIn: 'root' })
export class GroupsService implements Collection<Group> {
    constructor(private http: HttpClient) {}

    create(properties: Partial<Group>): Observable<Group> {
        const name = properties.name;
        if (!name) {
            throw new Error('name required to create group');
        }
        const url = `${ENDPOINT}`;
        const params = new HttpParams().set('names', name);

        return this.http.post<IRestResponse<unknown>>(url, properties, { params: params }).pipe(
            map(response => {
                const group = new Group(response.items[0]);
                group.name = name;
                return group;
            }),
        );
    }

    delete(names: string): Observable<void> {
        if (!names) {
            throw new Error('No names supplied');
        }

        const url = `${ENDPOINT}`;
        const params = new HttpParams().set('names', names);
        return this.http.delete<void>(url, { params: params }).pipe(map(() => void 0)); // Discard response
    }

    list(): Observable<DataPage<Group>> {
        return this.http.get<IRestResponse<unknown>>(ENDPOINT).pipe(map(response => this.makeDataPage(response)));
    }

    update(properties: Partial<Group>, names?: string[] | QueryParams): Observable<DataPage<Group>> {
        if (!(names instanceof Array)) {
            throw new Error('Cannot be called with queryParams');
        }

        let formattedNames = '';
        if (names && names.length > 0) {
            formattedNames = names.join(',');
        } else if (properties && properties.name) {
            formattedNames = properties.name;
        } else {
            throw new Error('No names supplied');
        }

        const url = `${ENDPOINT}`;

        const params = new HttpParams().set('names', formattedNames);

        return this.http
            .patch<IRestResponse<unknown>>(url, properties, { params: params })
            .pipe(map(response => this.makeDataPage(response)));
    }

    private makeDataPage(data: IRestResponse<unknown>): DataPage<Group> {
        return {
            total: data.total_item_count,
            response: data.items.map(resource => new Group(resource)),
        };
    }
}
