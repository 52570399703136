import { DraasApiProtectionStatus } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryProtectionStatus {
    success: number;
    failed: number;
    inProgress: number;

    constructor(json: DraasApiProtectionStatus) {
        this.success = json.success;
        this.failed = json.failed;
        this.inProgress = json.in_progress;
    }
}
