import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const MICRO_IN_MS = 1000;
const ONE_HUNDRED_MS = 1e5;
const ONE_SEC = 1e6;
const DEFAULT_DECIMAL = 2;

@Pipe({ name: 'formatLatency' })
export class FormatLatencyPipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');
    transform(value: number, decimal = DEFAULT_DECIMAL): string {
        if (typeof value !== 'number' || isNaN(value) || value < 0) {
            return null;
        } else {
            if (value < ONE_SEC) {
                // Drop one digit after the decimal point when latency exceeds 100 ms
                if (value > ONE_HUNDRED_MS) {
                    decimal--;
                }
                return FormatLatencyPipe.decimalPipe.transform(value / MICRO_IN_MS, `1.${decimal}-${decimal}`);
            } else {
                // if over 1000 ms, show '>1k', '>2k', etc, up to '>99k'
                return `>${Math.min(99, Math.floor(value / ONE_SEC))}k`;
            }
        }
    }
}
