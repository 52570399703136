import { Injectable } from '@angular/core';
import { EdgeServiceAgent, EdgeServiceAgentArrayAggregationSummary } from '../models/edge-service-agent';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { ListParams, SortParams } from '../interfaces/list-params';
import { map, Observable } from 'rxjs';
import { DataPage } from '../interfaces/data-page';

@Injectable({ providedIn: 'root' })
export class EdgeServiceAgentsService extends GenericService<EdgeServiceAgent> {
    //Currently BE doesn't return if the agent is deprecated or not, so we are hardcoding it here.
    deprecatedAgents = ['harbormaster', 'atom-agent'];

    constructor(protected http: HttpClient) {
        super({
            resourceClass: EdgeServiceAgent,
            endpoint: '/rest/v1/ems/agents',
            // create, update, delete are assumed to be non-supported
        });
    }

    list(params?: ListParams<EdgeServiceAgent>): Observable<DataPage<EdgeServiceAgent>> {
        return super.list(params).pipe(
            map((page: DataPage<EdgeServiceAgent>) => {
                page.response = page.response.map((item: EdgeServiceAgent) => {
                    if (this.deprecatedAgents.includes(item.id)) {
                        item.deprecated = true;
                    }
                    return item;
                });
                return page;
            }),
        );
    }
}

@Injectable({ providedIn: 'root' })
export class EdgeServiceAgentArrayAggregationSummariesService extends GenericService<EdgeServiceAgentArrayAggregationSummary> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: EdgeServiceAgentArrayAggregationSummary,
            endpoint: '/rest/v1/ems/agents/aggregation-summaries',
            defaultParams: {
                sort: <SortParams>{
                    key: 'hostname',
                    order: 'asc',
                },
            },
            // create, update, delete are assumed to be non-supported
        });
    }
}
