import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { unifiedArrayDefaultParams } from './unified-array.service';
import { AlohaArray } from '../models/aloha-array';

@Injectable({ providedIn: 'root' })
export class SecurityPatchFb20240115Service extends GenericService<AlohaArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: AlohaArray,
            defaultParams: unifiedArrayDefaultParams,
            endpoint: '/rest/v1/security-patch-fb-2024-01-15',
            create: true,
        });
    }
}
