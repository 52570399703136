export function formatPoint(pointArray: [number, number]): HistoricalPerformancePoint | null {
    if (pointArray.length > 1) {
        return {
            x: pointArray[0],
            y: pointArray[1],
        };
    } else {
        return null;
    }
}
