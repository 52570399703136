import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    Chassis,
    ExternalFabricModule,
    ArrayHardware,
    getConfig,
    MwHardware,
    MwBlade,
    MwDrive,
    buildBoxes,
    rpcToPureHw,
    rpcToPureBlade,
    rpcToPureDrive,
} from 'fb-gui-hw';

import { FBlade, IArrayHealth } from '../../../../model/model';

export function buildBoxesFromArrayHealth(health: IArrayHealth, dashboard: boolean): ArrayHardware {
    const hardware: MwHardware[] = health.purehw_list.map(hwEntry => rpcToPureHw(hwEntry));
    const blades: MwBlade[] = health.pureblade_list.map(bladeEntry => rpcToPureBlade(bladeEntry));
    const drives: MwDrive[] = health.puredrive_list
        ? health.puredrive_list.map(driveEntry => rpcToPureDrive(driveEntry))
        : [];

    const hw = buildBoxes(hardware, blades, drives);
    hw.chassis.forEach(chassis => {
        const idEnabled = false; // Setting this to false shows the light indicator as "readonly" (since you can't toggle the indicator via Pure1)
        chassis.setConfig(getConfig(dashboard, chassis.generation, idEnabled));
    });

    const efmConfig = getConfig(dashboard);
    hw.efms.forEach(efm => {
        efm.setConfig(efmConfig);
    });

    return hw;
}

@Component({
    selector: 'array-expanded-card-health-fblade',
    templateUrl: 'array-expanded-card-health-fblade.component.html',
})
export class ArrayExpandedCardHealthFBladeComponent implements OnChanges {
    @Input() readonly array: FBlade;
    @Input() readonly arrayHealth: IArrayHealth;
    @Input() readonly isExpanded: boolean;

    hardware: ArrayHardware;
    dashboardHardware: ArrayHardware;
    selectedEfm: ExternalFabricModule;
    selectedChassis: Chassis;
    efmVisible = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.arrayHealth) {
            return; // Don't update until arrayHealth binding is set
        }

        if (changes.arrayHealth) {
            this.hardware = buildBoxesFromArrayHealth(this.arrayHealth, false);
            this.dashboardHardware = buildBoxesFromArrayHealth(this.arrayHealth, true);

            if (this.efmVisible && this.selectedEfm) {
                this.selectedEfm = this.hardware.efms.find(e => e.index === this.selectedEfm.index);
            } else if (this.selectedChassis) {
                this.selectedChassis = this.hardware.chassis.find(c => c.index === this.selectedChassis.index);
            } else if (this.hardware.efms.length > 0) {
                // select the first efm if it exists and nothing is selected
                this.efmVisible = true;
                this.selectedEfm = this.hardware.efms[0];
            } else {
                // otherwise select the first chassis
                this.efmVisible = false;
                this.selectedChassis = this.hardware.chassis[0];
            }
        }
    }

    showSelectedBox(chassis: Chassis): boolean {
        return !this.isExpanded || (!this.efmVisible && this.selectedChassis.index === chassis.index);
    }

    showSelectedEfm(efm: ExternalFabricModule): boolean {
        return !this.isExpanded || (this.efmVisible && this.selectedEfm.index === efm.index);
    }

    onClickChassis(chassis: Chassis): void {
        if (this.isExpanded) {
            this.selectedChassis = this.hardware.chassis.find(c => c.index === chassis.index);
            this.efmVisible = false;
        }
    }

    onClickEfm(efm: ExternalFabricModule): void {
        if (this.isExpanded) {
            this.selectedEfm = this.hardware.efms.find(e => e.index === efm.index);
            this.efmVisible = true;
        }
    }

    isGeneration2(chassis: Chassis): boolean {
        return chassis?.generation === 2;
    }
}
