export enum PureTeam {
    ARIES_CONTROLPLANE = 'Aries ControlPlane',
    ARIES_DATAPLANE = 'Aries DataPlane',
    CLOUD_FORWARD = 'CloudForward',
    DATA_SCIENCE = 'Data Science',
    DX_ASSESSMENTS = 'DX Assessments',
    TRITIUM = 'Tritium',
    DX_ENGINEERING_PRODUCTIVITY = 'DX Engineering Productivity',
    DX_QUALITY_ENGINEERING = 'DX Quality Engineering,',
    DX_UI_SERVICES = 'DX UI Services',
    EDGE = 'Edge',
    OCTILLION = 'Octillion',
    OPTIMUS = 'Optimus',
    P1M_XAAS = 'P1M XaaS',
    PURE1_APPLICATION_SERVICES = 'Pure1 Application Services',
    PURE1_META = 'Pure1 Meta',
    PURE1_METRICS_SERVICE = 'Pure1 Metrics Service',
    PURE1_SECURITY_VIRTUAL = 'Pure1 Security (Virtual)',
    PURE1_SECURITY_AUTHZ = 'Pure1 Security AuthZ',
    PURE1_SECURITY_NA = 'Pure1 Security NA',
    PURE1_SECURITY_PRAGUE = 'Pure1 Security Prague',
    UI_NEXT = 'UI Next',
    UPSTACK = 'Upstack',
    UNKNOWN = 'Unknown, to be found!',
    NONE = "This is known as a route without any need of maintenance. This won't have any owner in the future",
}

export const teamOwnershipMap = new Map<PureTeam, string[]>();

export function OwnedByTeam(team: PureTeam) {
    return function (_target: Object, propertyKey: string): void {
        if (!teamOwnershipMap.get(team)) {
            teamOwnershipMap.set(team, []);
        }

        teamOwnershipMap.get(team).push(propertyKey);
    };
}
