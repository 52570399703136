import { formatPoint } from '../utils/formatPoint';

type HistoricalPerformanceDataJsonProperties =
    | 'usec_per_write_op'
    | 'usec_per_mirrored_write_op'
    | 'usec_per_read_op'
    | 'usec_per_other_op'
    | 'writes_per_sec'
    | 'mirrored_writes_per_sec'
    | 'reads_per_sec'
    | 'others_per_sec'
    | 'input_per_sec'
    | 'mirrored_input_per_sec'
    | 'output_per_sec';

type HistoricalPerformanceDataJson = {
    [prop in HistoricalPerformanceDataJsonProperties]?: ITimeseriesValues;
};

export class HistoricalPerformanceData {
    readLatency: HistoricalPerformancePoint[];
    writeLatency: HistoricalPerformancePoint[];
    mirroredWriteLatency: HistoricalPerformancePoint[];
    otherLatency: HistoricalPerformancePoint[];
    readIops: HistoricalPerformancePoint[];
    writeIops: HistoricalPerformancePoint[];
    mirroredWriteIops: HistoricalPerformancePoint[];
    otherIops: HistoricalPerformancePoint[];
    readBandwidth: HistoricalPerformancePoint[];
    writeBandwidth: HistoricalPerformancePoint[];
    mirroredWriteBandwidth: HistoricalPerformancePoint[];

    // derived perf metrics
    averageIoSize?: HistoricalPerformancePoint[];
    totalBandwidth?: HistoricalPerformancePoint[];

    constructor(json: HistoricalPerformanceDataJson) {
        this.writeLatency = json.usec_per_write_op ? json.usec_per_write_op.map(formatPoint) : [];
        this.mirroredWriteLatency = json.usec_per_mirrored_write_op
            ? json.usec_per_mirrored_write_op.map(formatPoint)
            : [];
        this.readLatency = json.usec_per_read_op ? json.usec_per_read_op.map(formatPoint) : [];
        this.otherLatency = json.usec_per_other_op ? json.usec_per_other_op.map(formatPoint) : [];
        this.writeIops = json.writes_per_sec ? json.writes_per_sec.map(formatPoint) : [];
        this.mirroredWriteIops = json.mirrored_writes_per_sec ? json.mirrored_writes_per_sec.map(formatPoint) : [];
        this.readIops = json.reads_per_sec ? json.reads_per_sec.map(formatPoint) : [];
        this.otherIops = json.others_per_sec ? json.others_per_sec.map(formatPoint) : [];
        this.writeBandwidth = json.input_per_sec ? json.input_per_sec.map(formatPoint) : [];
        this.mirroredWriteBandwidth = json.mirrored_input_per_sec ? json.mirrored_input_per_sec.map(formatPoint) : [];
        this.readBandwidth = json.output_per_sec ? json.output_per_sec.map(formatPoint) : [];

        if (this.readBandwidth.length !== this.writeBandwidth.length) {
            console.error(
                'Mismatched read/write bandwith lengths detected! ' +
                    this.readBandwidth.length +
                    ' and ' +
                    this.writeBandwidth.length,
            );
        }

        // Compute the derived perf metrics, if we have valid values for the base metrics
        if (this.readBandwidth.length === this.writeBandwidth.length) {
            this.totalBandwidth = this.readBandwidth.map((rBandwidth, idx) => {
                const totalBandwidth = rBandwidth.y + this.writeBandwidth[idx].y;
                return formatPoint([rBandwidth.x, totalBandwidth]);
            });
        }

        if (
            this.totalBandwidth &&
            this.totalBandwidth.length === this.readIops.length &&
            this.totalBandwidth.length === this.writeIops.length
        ) {
            this.averageIoSize = this.totalBandwidth.map((totalBandwidth, idx) => {
                const totalIO = this.readIops[idx].y + this.writeIops[idx].y;
                const avgIO = totalIO > 0 ? totalBandwidth.y / totalIO : 0;
                return formatPoint([totalBandwidth.x, avgIO]);
            });
        }
    }
}
