import { NotificationPopupComponent } from '@pure/notification-center';
import { Angulartics2Module } from 'angulartics2';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
    AppSwitcherContentsComponent,
    UserProfileButtonComponent,
    OrgSwitcherComponent,
} from '@pure/pure1-ui-platform-angular';
import { NotificationCenterModule } from '../notification-center/notification-center.module';

import { UIModule } from '../ui/ui.module';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';
import { ExportModule } from '../export/export.module';
import { NpsSurveyModule } from '../nps-survey/nps-survey.module';
import { SupportModule } from '../support/support.module';
import { TouModule } from '../tou/tou.module';
import {
    AppHeaderModule,
    AppNavigationModule,
    AvatarModule,
    BadgeModule,
    ButtonComponent,
    HiveTemplateDirective,
    LeftNavigationModule,
    MenuModule,
    PageBaseLayoutModule,
    RootDirective,
    SeparatorComponent,
    SvgDirective,
    TooltipModule,
} from '@pure/hive';
import { EmptyPageComponent } from './empty-page/empty-page.component';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { PageRoutingModule } from './page-routing.module';
import { NavMenuIdPipe } from './pipes/nav-menu-id.pipe';
import { CopilotOnboardingModalComponent } from '../copilot/copilot-onboarding-modal/copilot-onboarding-modal.component';

@NgModule({
    imports: [
        Angulartics2Module,
        AppSwitcherContentsComponent,
        AuthorizerModule,
        CommonModule,
        FormsModule,
        NgbModule,
        UIModule,
        ExportModule,
        NpsSurveyModule,
        SupportModule,
        TouModule,
        TooltipModule,
        BadgeModule,
        NotificationCenterModule,
        SvgDirective,
        PageBaseLayoutModule,
        PageRoutingModule,
        RootDirective,
        UserProfileButtonComponent,
        NotificationPopupComponent,
        CopilotOnboardingModalComponent,
        SeparatorComponent,
        OrgSwitcherComponent,
        LeftNavigationModule,
        AppHeaderModule,
        MenuModule,
        HiveTemplateDirective,
        AvatarModule,
        ButtonComponent,
    ],
    declarations: [NavMenuIdPipe, PageWrapperComponent, EmptyPageComponent],
})
export class PageModule {}
