import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CachedCurrentUserService, Invoice } from '@pure1/data';
import { switchMap } from 'rxjs';
import { SupportContactService } from '../../../../support/services/support-contact.service';

@Component({
    selector: 'invoice-details',
    templateUrl: './invoice-details.component.html',
})
export class InvoiceDetailsComponent implements OnInit {
    @Input() readonly invoice: Invoice;

    @ViewChild('disputeInvoiceModal', { static: true }) disputeInvoiceModal: TemplateRef<NgbActiveModal>;

    readonly NO_SNOW_CONTACT_MSG = 'Could not find a support contact to request a dispute.';
    hasSNOWContact: boolean;

    constructor(
        private modalService: NgbModal,
        private cachedCurrentUserService: CachedCurrentUserService,
        private supportContactService: SupportContactService,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(switchMap(user => this.supportContactService.getContactById(user.id)))
            .subscribe({
                next: () => (this.hasSNOWContact = true),
                error: () => (this.hasSNOWContact = false),
            });
    }

    openModal(): void {
        this.modalService.open(this.disputeInvoiceModal);
    }
}
