import { Pipe, PipeTransform } from '@angular/core';
import { ComplianceStatus } from '@pure1/data';

@Pipe({ name: 'snapshotStatus' })
export class SnapshotStatusPipe implements PipeTransform {
    transform(status: ComplianceStatus): string {
        switch (status) {
            case 'completed':
                return 'Completed';
            case 'inProgress':
                return 'In Progress';
            case 'delayed':
                return 'Delayed';
            case 'pausedDisallowed':
                return 'Paused';
            case 'unknown':
            default:
                console.warn('unrecognized snapshot status: ' + status);
                return 'Unknown';
        }
    }
}
