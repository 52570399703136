<div class="step-up-modal-header">
    <countdown-timer
        [duration]="countdownDuration"
        [countdown]="countdownActive"
        (timerElapsed)="passCodeExpired()"
    ></countdown-timer>
</div>
<div class="step-up-modal-body">
    <div class="step-up-challenge-title">
        <div class="title">
            <ng-container *ngIf="isEmailFactor(); else phoneFactorTitle">Verify It's You</ng-container>
            <ng-template #phoneFactorTitle>
                <ng-container *ngIf="isActivate; else phoneStepUpTitle">Verify Your Phone</ng-container>
                <ng-template #phoneStepUpTitle>Step-Up Authentication</ng-template>
            </ng-template>
        </div>
        <div class="subtitle">
            <ng-container *ngIf="additionalWarning">
                <span class="warning-text">{{ additionalWarning }}</span>
                <br /><br />
            </ng-container>
            <ng-container *ngIf="isEmailFactor(); else phoneFactorSubtitle">
                We've sent an email with a verification code to
                <span class="email">{{ factor.profile.email }}</span>
                <br /><br />
                Check your inbox and enter the code.
            </ng-container>
            <ng-template #phoneFactorSubtitle
                >We've sent a text message with a 6-digit verification code to the following number:</ng-template
            >
        </div>
    </div>
    <div class="step-up-challenge-inputs">
        <form id="step-up-form" [formGroup]="passCodeForm">
            <input
                *ngIf="!isEmailFactor()"
                type="text"
                id="phone-field"
                class="form-control phone-input"
                formControlName="phoneNumber"
            />

            <div class="passcode-area">
                <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
                <div class="password-input">
                    <input
                        id="passcode-field"
                        [type]="passCodeTypeStr"
                        class="form-control passcode-input"
                        [ngClass]="{ error: errorMessage }"
                        formControlName="passCode"
                        placeholder="Enter the code"
                    />
                    <pureui-svg
                        id="passcode-icon"
                        [ngClass]="{ enabled: passCodeField?.value }"
                        [svg]="passCodeIconStr"
                        height="12"
                        (click)="togglePasswordVisibility()"
                    ></pureui-svg>
                </div>
                <div class="attempts-message below-input-text" *ngIf="showAttemptsMessage()"
                    >{{ attemptsRemaining }} {{ attemptsRemaining | pluralize: 'attempt' }} remaining</div
                >
                <div class="locked-out-message below-input-text" *ngIf="lockedOut"
                    >0 attempts remaining. Try again in 1 hour.</div
                >
            </div>

            <button
                class="btn btn-primary continue-button"
                *ngIf="!lockedOut; else supportButton"
                (click)="submit()"
                [disabled]="!submitEnabled()"
                angulartics2On="click"
                angularticsAction="Step Up - Challenge Modal - submit passcode"
                angularticsCategory="Action"
            >
                <ng-container *ngIf="isActivate; else stepUpSubmitPasscode">Complete Verification</ng-container>
                <ng-template #stepUpSubmitPasscode>Continue</ng-template>
            </button>
            <ng-template #supportButton>
                <button
                    class="btn btn-primary support-button"
                    (click)="goToSupport()"
                    angulartics2On="click"
                    angularticsAction="Step Up - Challenge Modal - contact support"
                    angularticsCategory="Action"
                >
                    Contact Pure Technical Services
                </button>
            </ng-template>
        </form>
    </div>
</div>
<div class="step-up-modal-footer">
    <div *ngIf="!lockedOut" class="text"
        >Didn't receive a code?
        <a
            class="resend-link"
            (click)="isActivate ? resendEnrollForActivation() : challenge()"
            angulartics2On="click"
            angularticsAction="Step Up - Challenge Modal - try again"
            angularticsCategory="Action"
        >
            <ng-container *ngIf="isEmailFactor(); else phoneFactorResendLink">Resend code</ng-container>
            <ng-template #phoneFactorResendLink>Send a text</ng-template>
        </a>
        <ng-container *ngIf="!isEmailFactor()">again.</ng-container>
    </div>
    <div *ngIf="!lockedOut" class="text">
        Lost access to your
        <ng-container *ngIf="isEmailFactor(); else lostPhone">email</ng-container>
        <ng-template #lostPhone>mobile phone</ng-template>
        and need to reset your account?
        <a
            (click)="goToSupport()"
            angulartics2On="click"
            angularticsAction="Step Up - Challenge Modal - contact support"
            angularticsCategory="Action"
        >
            Contact Pure Technical Services
        </a>
    </div>
    <a
        (click)="close()"
        angulartics2On="click"
        angularticsAction="Step Up - Challenge Modal - cancel"
        angularticsCategory="Action"
        >Cancel</a
    >
</div>
