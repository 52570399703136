import { Component, Input } from '@angular/core';

@Component({
    selector: 'progress-spinner',
    templateUrl: 'progress-spinner.component.html',
})
export class ProgressSpinnerComponent {
    @Input() readonly size: number = 16;
    @Input() readonly thickness: number = 2;
}
