import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SupportCase, CaseEscalation, User, CaseEscalationReason } from '../support.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PhoneResult } from '../../ui/components/input-phone.component';
import { SupportCaseOrchestratorService } from '../services/support-case-orchestrator.service';
import { when } from 'core/src/utils/form-validators';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'escalate-modal',
    templateUrl: 'escalate-modal.component.html',
})
export class EscalateModalComponent implements OnInit {
    supportCase: SupportCase;
    user: User;
    reason: string;
    contactName: string;
    contactPhone: string;

    readonly escalationReasons = CaseEscalationReason;
    readonly ampli = ampli;

    escalationForm: UntypedFormGroup;
    isRequestSubmitted = false;

    @ViewChild('escalationComment', { static: false }) commentElement: ElementRef;

    isRequestSubmitting = false;
    private createdEscalation: CaseEscalation;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private supportOrchestrator: SupportCaseOrchestratorService,
    ) {}

    ngOnInit(): void {
        this.escalationForm = this.fb.group({
            reason: [this.reason, Validators.required],
            contactName: [this.contactName, Validators.required],
            contactPhone: [this.contactPhone, Validators.required],
            comment: [
                '',
                when(() => this.escalationForm?.controls.reason.value === CaseEscalationReason.OTHER).applyValidator(
                    Validators.required,
                ),
            ],
        });
        this.ampli.supportEscalateModalOpened();
    }

    createEscalation(): void {
        const escalation = {
            ...this.escalationForm.value,
            caseId: this.supportCase.id,
        };
        this.isRequestSubmitting = true;
        this.ampli.supportEscalateModalSubmitted();
        this.supportOrchestrator
            .createEscalation(escalation, this.supportCase)
            .then((createdEscalation): void => {
                this.isRequestSubmitted = true;
                this.createdEscalation = createdEscalation;
            })
            .catch(() => {
                this.activeModal.dismiss();
            })
            .finally(() => {
                this.isRequestSubmitting = false;
            });
    }

    onSelectChange(): void {
        this.ampli.supportEscalateModalEscalationReasonSelected();
        this.escalationForm.controls.comment.updateValueAndValidity();

        if (this.escalationForm.controls.reason.value === CaseEscalationReason.OTHER) {
            this.commentElement.nativeElement.focus();
        }
    }

    onPhoneChange(result: PhoneResult): void {
        this.escalationForm.controls.contactPhone.setValue(result.isValid ? result.phoneNumber : '');
    }

    stopPasteTextEvent(event: ClipboardEvent): void {
        const { clipboardData } = event;
        if (clipboardData && Array.isArray(clipboardData.types)) {
            if (clipboardData.types.indexOf('Files') === -1) {
                event.stopPropagation();
            }
        }
    }

    closeModal(): void {
        this.ampli.supportEscalateModalClosed();
        this.activeModal.close(this.createdEscalation);
    }
}
