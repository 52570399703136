export enum KEY {
    enter = 'Enter',
    escape = 'Escape',
    up = 'ArrowUp',
    down = 'ArrowDown',
    left = 'ArrowLeft',
    right = 'ArrowRight',
    backspace = 'Backspace',
    delete = 'Delete',
    tab = 'Tab',
    space = ' ',
}
