<ng-select
    [items]="sortOptions"
    bindValue="sortKey"
    [searchable]="false"
    [clearable]="false"
    [ngModel]="sortKey"
    (ngModelChange)="onSortKeyChange($event)"
>
    <ng-template ng-option-tmp let-item="item">
        <div class="sort-option">
            <span class="sort-label">{{ item.label }}</span>
        </div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
        Sort: <span class="sort-label">{{ item.label }}</span>
    </ng-template>
</ng-select>
<span class="input-group-btn">
    <button class="btn btn-primary with-action-icon active" (click)="onClickSortOrder()" blurOnClick>
        <pureui-svg
            class="pstg-action-icon"
            *ngIf="sortDesc; else sortAsc"
            svg="sort-desc.svg"
            height="16"
        ></pureui-svg>
        <ng-template #sortAsc>
            <pureui-svg #sortAsc class="pstg-action-icon" *ngIf="!sortDesc" svg="sort-asc.svg" height="16"></pureui-svg>
        </ng-template>
    </button>
</span>
