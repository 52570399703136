import { NotificationCenterModule } from '@pure/notification-center';
import { Angulartics2RouterlessModule } from 'angulartics2';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import MarkerClusterer from '@google/markerclustererplus';
import { NgbCarouselConfig, NgbConfig, NgbModalConfig, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizerModule } from '@pure/authz-authorizer';
import { HIVE_THEMING, SVG_ROOT, Themes } from '@pure/hive';
import { Pure1UIPlatformModule } from '@pure/pure1-ui-platform-angular';
import { CachedCurrentUserService } from '@pure1/data';
import { BehaviorSubject, take } from 'rxjs';
import { ampli } from '../ampli';

import { ArraysModule } from '../arrays/arrays.module';
import { ExportModule } from '../export/export.module';
import { GfbModule } from '../gfb/gfb.module';
import { PageModule } from '../page/page.module';
import { SafeModeModule } from '../safemode/safemode.module';
import { CssUrlStyleManager } from '../services/css-url-style-manager.service';
import { googleMapsPromiseFactory } from '../services/google-maps-promise.factory';
import { LazyLoadingScriptService } from '../services/lazy-load-scripts.service';
import { ModalHeightFixerService } from '../services/modal-height-fixer.service';
import { SupportModule } from '../support/support.module';
import { TagsModule } from '../tags/tags.module';
import { UIModule } from '../ui/ui.module';
import { UserManagementModule } from '../user-management/user-management.module';
import { AngularticsConfigService } from './angulartics-config.service';
import { configureModules } from './app-config';
import { DatadogLoggingConfigService } from './datadog-logging-config.service';
import { HttpCacheInterceptor } from './http-cache.interceptor';
import { HttpContentTypeInterceptor } from './http-content-type.interceptor';
import { HttpRetryInterceptor } from './http-retry.interceptor';
import { HttpUndefinedParamsInterceptor } from './http-undefined-params.interceptor';
import { GOOGLE_MAPS, MARKER_CLUSTERER, WINDOW } from './injection-tokens';
import { RootAppRoutesModule } from './root-app-routes.module';
import { UserChangedDetectorService } from './user-changed-detector.service';

import { AssetManagementModule } from '../asset-management/asset-management.module';
import { PureRedux } from '../redux/pure-redux.service';
import { AppRootComponent } from './app-root.component';
import { CfwdHeaderInterceptor } from './cfwd-header.interceptor';
import { UserChangedDetectorInterceptor } from './user-changed-detector.interceptor';
import { DatadogRumConfigService } from './datadog-rum-config.service';
import { GlobalErrorHandler } from './global-error-handler';

@NgModule({
    imports: [
        AuthorizerModule.forRoot({
            defaultResourceProvider: CachedCurrentUserService,
        }),
        Angulartics2RouterlessModule.forRoot(),
        RootAppRoutesModule,

        // Dependencies
        BrowserModule,
        BrowserAnimationsModule,
        PageModule,
        ExportModule,
        GfbModule,
        HttpClientModule, // NOTE: HttpClientModule should only ever be imported by the app module. Otherwise, lazy-loaded modules won't pick up the interceptors. See: https://github.com/angular/angular/issues/20575
        NgbModule,

        // Non-lazy loaded feature modules (lazy loaded features loaded by the router, see routes.config.ts)
        ArraysModule,
        AssetManagementModule,
        SupportModule,
        TagsModule,
        UIModule,
        UserManagementModule,
        SafeModeModule,
        Pure1UIPlatformModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpUndefinedParamsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpContentTypeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpRetryInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CfwdHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UserChangedDetectorInterceptor, multi: true },
        { provide: WINDOW, useValue: window },
        { provide: GOOGLE_MAPS, useFactory: googleMapsPromiseFactory, deps: [LazyLoadingScriptService] },
        { provide: MARKER_CLUSTERER, useValue: MarkerClusterer },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [], multi: true },
        { provide: APP_INITIALIZER, useFactory: initializePureRedux, deps: [PureRedux], multi: true },
        { provide: SVG_ROOT, useValue: 'images/' },
        { provide: HIVE_THEMING, useValue: new BehaviorSubject<Themes>(Themes.UINext) },
        importProvidersFrom(
            Pure1UIPlatformModule.forRoot({
                amplitudeTrackCallback: (event, options) => {
                    ampli.track(event, options);
                },
                roleService: {
                    useExisting: CachedCurrentUserService,
                },
            }),
        ),
        importProvidersFrom(
            NotificationCenterModule.forRoot({
                amplitudeTrackCallback: (event, options) => {
                    ampli.track(event, options);
                },
            }),
        ),
    ],
    declarations: [AppRootComponent],
    bootstrap: [AppRootComponent],
})
export class AppModule {
    constructor(
        private modalHeightFixer: ModalHeightFixerService,
        private injector: Injector,
        private userChangedDetectorService: UserChangedDetectorService,
        private ngbConfig: NgbConfig,
        private ngbTooltipConfig: NgbTooltipConfig,
        private ngbModalConfig: NgbModalConfig,
        private ngbCarouselConfig: NgbCarouselConfig,
    ) {
        this.modalHeightFixer.run();

        // Configure only after bootstrapping angularjs
        this.injector.get(AngularticsConfigService).run();
        this.injector.get(DatadogLoggingConfigService).run();
        this.injector.get(DatadogRumConfigService).run();
        this.injector.get(CssUrlStyleManager).run();

        this.configureNgBootstrap();
        configureModules();

        this.userChangedDetectorService.run();
    }

    /**
     * Sets global default configuration for NgBootstrap components.
     */
    private configureNgBootstrap(): void {
        this.ngbTooltipConfig.openDelay = 200;

        // Selectively enable animations. Not all animations will work due to our combination of incorrect Bootstrap version
        // and the hacky styles we've thrown on top of them. Ideally we'd fix these all at some point. Realistically... we'll just not animate most stuff. :)
        this.ngbConfig.animation = false; // Disable by default
        this.ngbModalConfig.animation = true; // Modals won't show correctly without animations
        this.ngbCarouselConfig.animation = false; // Carousel animations do not work - it just adds a delay without visually animating, which makes it look broken.
    }
}

export function initializeApp(): () => Promise<unknown> {
    return () => {
        return (window as any).dxp.ready.then(() => {});
    };
}

function initializePureRedux(pureRedux: PureRedux): Function {
    // Ensures we initialize PureRedux before we do any routing since pretty much every page
    // relies on redux already being initialized
    return () =>
        pureRedux
            .init()
            .pipe(take(1))
            .toPromise()
            .catch(err => {
                console.warn('pureRedux.init() returned error', err);
            });
}
