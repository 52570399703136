<div class="collapse-header">
    <span (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" class="collapse-control">
        <span class="expand-icon" [class.collapsed]="isCollapsed"
            ><pureui-svg svg="chevron-up.svg" height="10"></pureui-svg
        ></span>
        {{ headerText }}
    </span>
    <ng-content select="header"></ng-content>
</div>
<div class="collapse-body" [class.collapsed]="isCollapsed">
    <ng-content></ng-content>
</div>
