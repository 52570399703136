<span
    class="pill-wrapper"
    [ngClass]="{ pinnable: pill.pinnable, pinned: pill.pinned, disabled: disabled }"
    (click)="stopPropagation()"
>
    <span class="pill-key" (click)="togglePinUnpin()">
        <pureui-svg
            *ngIf="pill.pinnable"
            class="pstg-action-icon pill-pin-icon"
            [ngClass]="{ pinned: pill.pinned }"
            svg="pin.svg"
            [height]="12"
        ></pureui-svg>
        {{ displayKey }}
    </span>
    <span *ngIf="!shortenValues(); else truncateName" class="pill-values" (click)="editPill()">
        <span class="pill-value" *ngFor="let displayValue of displayValues">{{ displayValue }}</span>
    </span>
    <ng-template #truncateName>
        <span class="pill-values" (click)="editPill()">
            <span class="pill-value">{{ displayValues.length }} appliances...</span>
        </span>
    </ng-template>
    <span class="pill-remove" (click)="removePill()">
        <span class="pstg-action-icon pill-remove-icon">
            <pureui-svg svg="x-action.svg" [height]="8"></pureui-svg>
        </span>
    </span>
</span>
