import { Resource } from '@pure1/data';
import { DraasApiTag } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryTag implements Resource {
    readonly id: string;
    readonly platformId: string;
    readonly name: string;
    readonly homeProviderId: string;
    readonly categoryId: string;
    readonly description: string;

    constructor(json: DraasApiTag) {
        this.id = json.id;
        this.platformId = json.platform_id;
        this.name = json.name;
        this.homeProviderId = json.home_provider_id;
        this.categoryId = json.category_id;
        this.description = json.description;
    }
}
