<div class="subscription-summary-section">
    <div class="subscription-summary-chart">
        <div class="chart-title">All {{ evergreenProgramTypes[0] }} Subscriptions by Status</div>
        <div class="subscription-summary-pie">
            <pure-radial-dial
                *ngIf="!loading"
                [size]="size"
                [innerSize]="innerSize"
                [arcs]="dialSegments"
                [enableTooltip]="false"
                [centerLabel]="centerLabel"
                [centerLabelOffset]="-10"
                subtitle="Subscriptions"
                [enableAnimation]="false"
            ></pure-radial-dial>
            <div *ngIf="!loading" class="health-chart-legend">
                <!-- show emptyMsg if there is only hidden segment in the dialSegments -->
                <div *ngIf="dialSegments && dialSegments.length > 0; else emptyMsg">
                    <div *ngFor="let segment of dialSegments">
                        <div class="health-chart-legend-item">
                            <div class="health-chart-legend-item-circle">
                                <ng-container
                                    *ngTemplateOutlet="circle; context: { color: segment.color }"
                                ></ng-container>
                            </div>
                            <div class="health-chart-legend-item-text">
                                {{ segment.name }}
                                <span class="health-chart-legend-item-count">{{ segment.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #emptyMsg>No subscriptions available</ng-template>
            </div>
        </div>
    </div>
    <div class="subscription-summary-table-section">
        <div class="subscription-selection-dropdown">
            <hive-dropdown
                [placeholder]="selectedFilter ? '' : TableDropdownSelections.NEEDS_ATTENTION"
                [isLoading]="loading"
                [ngModel]="selectedFilter"
                (ngModelChange)="onTableSelectChange($event)"
            >
                >
                <hive-selection-menu-item
                    *ngFor="let filter of filters"
                    [label]="filter.label"
                    [value]="filter.value"
                ></hive-selection-menu-item>
            </hive-dropdown>
        </div>
        <div class="summary-table">
            <hive-table
                [isLoading]="loading"
                [value]="
                    selectedFilter === TableDropdownSelections.NEEDS_ATTENTION ? actionableSubscriptions : subscriptions
                "
                dataKey="id"
                scrollHeight="100%"
                [scrollable]="true"
                [sort]="sort"
                selectionMode="single"
                (selectionChange)="onSelectedRowChange($event)"
                (sortChange)="onSortChange($event)"
            >
                <ng-template hiveTemplate="header">
                    <tr>
                        <th hive-header [colWeight]="2" primaryText="Subscription" id="name" sortKey="name"> </th>
                        <th
                            hive-header
                            [colWeight]="2"
                            primaryText="Licenses"
                            id="numberLicenses"
                            sortKey="numberLicenses"
                        >
                        </th>
                        <th hive-header [colWeight]="2" primaryText="Start Date" id="startDate" sortKey="startDate">
                        </th>
                        <th hive-header [colWeight]="2" primaryText="Expiration Date" id="endDate" sortKey="endDate">
                        </th>
                        <ng-container *featurePolicy="FeatureNames.RANSOMWARE_SAFEMODE">
                            <th
                                hive-header
                                [colWeight]="2"
                                *ngIf="cyberRecoverySubscriptions > 0"
                                primaryText="Cyber Recovery and Resilience SLA"
                                id="cyberRecoverySLA"
                                [helpInfoMessage]="CYBER_RECOVERY_TOOLTIP"
                            ></th>
                        </ng-container>
                        <th hive-header [colWeight]="2" primaryText="Status" id="status" sortKey="detailedStatus"> </th>
                        <th hive-header [colWeight]="2" primaryText="Action" id="action" sortKey="actionLabel"> </th>
                    </tr>
                </ng-template>
                <ng-template hiveTemplate="body" let-subscription>
                    <tr [hiveTableRow]="subscription" selectable>
                        <td hive-cell [primaryText]="subscription.name" textOverflow="tooltip"> </td>
                        <td hive-cell [primaryText]="subscription.numLicenses"> </td>
                        <td hive-cell [primaryText]="subscription.startDate | date: 'yyyy-MM-dd' : 'UTC'"> </td>
                        <td hive-cell [primaryText]="subscription.endDate | date: 'yyyy-MM-dd' : 'UTC'"> </td>
                        <ng-container *featurePolicy="FeatureNames.RANSOMWARE_SAFEMODE">
                            <td hive-template-cell *ngIf="cyberRecoverySubscriptions > 0" class="center-template-text">
                                <span *ngIf="subscription.isRansomwareProtectionEnabled" class="safemode-shield-icon">
                                    <svg class="pstg-action-icon" hive-svg="safemode-shield.svg" [height]="16"></svg>
                                </span>
                            </td>
                        </ng-container>
                        <td hive-template-cell class="center-template-text">
                            <div [ngClass]="{ expired: subscription.detailedStatus.includes('Expired') }">
                                {{ subscription.detailedStatus }}
                            </div>
                        </td>
                        <td hive-template-cell class="center-template-text">
                            <hive-button
                                *ngIf="
                                    !!subscription.actionLabel &&
                                        subscription.actionLabel != 'Contact CSM for assistance';
                                    else contactCSM
                                "
                                (click)="manageSubscription(subscription)"
                                [label]="subscription.actionLabel"
                            >
                            </hive-button>
                            <ng-template class="action-buttons" #contactCSM>
                                <div
                                    hiveTooltip
                                    [hiveTooltipDescription]="subscription.actionLabel"
                                    hiveTooltipPosition="topLeft"
                                    class="contact-csm"
                                    >{{ subscription.actionLabel }}</div
                                >
                            </ng-template>
                        </td>
                    </tr>
                </ng-template>
            </hive-table>
        </div>
    </div>
</div>

<ng-template #circle let-color="color">
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" [attr.fill]="color" />
    </svg>
</ng-template>
<ng-template #renewSubscriptionModal let-modal>
    <renew-summary-modal
        [activeModal]="modal"
        [subscription]="selectedSubscription"
        [licenses]="getLicensesBySubscription(selectedSubscription)"
        [pendingQuote]="editableRenewalOrder"
    >
    </renew-summary-modal>
</ng-template>
