import { Injectable } from '@angular/core';
import { FactorProfile, FactorType, StepUpFactor, StepUpService } from '@pure1/data';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { switchTap } from '../../utils/rxjs-operators';

/**
 * Like its base class StepUpService, but maintains a locally cached copy of factors.
 * - StepUpChallenge and stepUpVerify: use base class, because they don't manipulate factors.
 * - Other methods listed here:
 *   - Get results from corresponding methods in base class.
 *   - Update the local cache before emitting the latest state of the cache to factors$ for all subscribers of
 *     getFactors method.
 */
@Injectable({ providedIn: 'root' })
export class CachedStepUpService extends StepUpService {
    // Latest copy of step-up factors according to our client's knowledge.
    private factors: Map<string, StepUpFactor>;
    // Stores and emits the latest copy of step-up factors during initial get or whenever change happens.
    private factors$ = new ReplaySubject<StepUpFactor[]>(1);
    private sentFirstRequest = false;

    getFactorsV2(): Observable<StepUpFactor[]> {
        if (this.sentFirstRequest) {
            return this.factors$;
        }
        return this.getFactorsForceRefreshV2();
    }

    private getFactorsForceRefreshV2(): Observable<StepUpFactor[]> {
        this.sentFirstRequest = true;
        return super.getFactorsV2().pipe(
            catchError(error => {
                this.factors = null; // Previous results are out-of-date. Force refresh next time.
                // On error, there is no way to get further results in the future.
                // Subscribers will just have to call getFactors or getFactorsForceRefresh again.
                this.factors$.error(error);
                return this.factors$;
            }),
            switchMap(results => {
                this.factors = new Map<string, StepUpFactor>();
                results.forEach(result => {
                    this.factors.set(result.id, result);
                });
                this.factors$.next(Array.from(this.factors.values()));
                // Return factors$ so that subscribers not only get results from this call,
                // but also any updates in the future.
                return this.factors$;
            }),
        );
    }

    stepUpEnrollFactorV2(
        factorType: FactorType,
        profile: FactorProfile,
        auth: string,
        provider?: string,
    ): Observable<StepUpFactor> {
        return super.stepUpEnrollFactorV2(factorType, profile, auth, provider).pipe(
            switchTap(enrolledFactor => {
                if (this.factors) {
                    this.factors.set(enrolledFactor.id, enrolledFactor);
                    this.factors$.next(Array.from(this.factors.values()));
                }
                return of(enrolledFactor); // Doesn't matter in switchTap
            }),
        );
    }

    stepUpActivateV2(passCode: string, factorId: string): Observable<StepUpFactor> {
        return super.stepUpActivateV2(passCode, factorId).pipe(
            switchTap(activatedFactor => {
                if (this.factors) {
                    this.factors.delete(factorId); // In case factorId changes
                    this.factors.set(activatedFactor.id, activatedFactor);
                    this.factors$.next(Array.from(this.factors.values()));
                }
                return of(activatedFactor); // Doesn't matter in switchTap
            }),
        );
    }

    stepUpResetV2(factorId: string, auth: string): Observable<void> {
        return super.stepUpResetV2(factorId, auth).pipe(
            switchTap(_ => {
                if (this.factors) {
                    this.factors.delete(factorId);
                    this.factors$.next(Array.from(this.factors.values()));
                }
                return of(null); // Doesn't matter in switchTap
            }),
        );
    }
}
