import { Resource } from '../interfaces/resource';

// this is used by Member class to specify the appliance on which
// the Member container is located (ex: if a bucket is on an FB, this is the FB's info)
export class MemberLocation implements Resource {
    id: string;
    name: string;

    resourceType: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.resourceType = json.resourceType;
    }
}
