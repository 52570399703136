import { Component, ElementRef, forwardRef, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { License } from '../../../../data';
import { WINDOW } from '../../../..//app/injection-tokens';

const KEYSTROKE_DEBOUNCE_TIME = 200;

@Component({
    selector: 'search-license',
    templateUrl: 'search-license.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchLicenseComponent),
            multi: true,
        },
    ],
})
export class SearchLicenseComponent implements ControlValueAccessor, OnChanges {
    @ViewChild('typeInstance') readonly instance: NgbTypeahead;
    @ViewChild('typeaheadInput') readonly typeaheadInput: ElementRef;

    @Input('licenses') licenses: License[];
    @Input() readonly checkSupportContract: boolean = false;

    click$ = new Subject<string>();
    value: any;
    searchString = '';
    searchStringWasConsumed = true;
    licenseForm: UntypedFormGroup;

    constructor(
        @Inject(WINDOW) private window: Window,
        private fb: UntypedFormBuilder,
    ) {
        // We need to use the form to correctly propagate value changes to the parent component. The ngbTypeahead we
        // use is not propagating the non-matching strings correctly, so the external validation is not triggered
        // correctly.
        this.licenseForm = this.fb.group({
            license: new UntypedFormControl(null, { updateOn: 'blur', validators: [this.validateLicense] }),
        });
    }

    // When the data source changes and there already is selected value
    // Then check if it is also in the new data source and remove it if it is not
    ngOnChanges(changes: SimpleChanges): void {
        const value = this.licenseForm.controls.license.value;
        if (changes.licenses && value) {
            if (!this.licenses.some(license => license.id === value.id)) {
                this.searchString = '';
                this.value = undefined;
                this.licenseForm.controls.license.setValue(undefined);
                this.setFocusWhenEmpty();
            }
        }
    }

    onChange = (_: any): void => {};

    onTouched = (_: any): void => {};

    formatter = (x: License): string => {
        return x.name;
    };

    filterFunction = (license: License, term: string): boolean => {
        // nothing to search in
        if (!license.name && license.subscription.name) {
            return false;
        }
        const termLC = term.toLowerCase();

        // search for term inside name
        if (license.name && license.name.toLowerCase().includes(termLC)) {
            return true;
        }

        // search for term inside subscription name
        if (license.name && license.subscription.name.toLowerCase().includes(termLC)) {
            return true;
        }

        // no match
        return false;
    };

    search: (text$: Observable<string>) => Observable<License[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(KEYSTROKE_DEBOUNCE_TIME), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

        return merge(debouncedText$, clicksWithClosedPopup$).pipe(
            map(term => {
                // This should ensure that the searchString is consumed only when appropriate
                if (!term && !this.searchStringWasConsumed) {
                    term = this.searchString;
                    this.searchStringWasConsumed = true;
                }
                return this.licenses.filter(license => this.filterFunction(license, term));
            }),
        );
    };

    validateLicense = (control: UntypedFormControl): ValidationErrors => {
        const val = control.value;
        // user interaction did not yet happen, so it should not be invalid at all
        if (!control.dirty) {
            return null;
        }

        // casting this to boolean to prevent some unwanted surprises
        const isValid = Boolean(val && val.name);
        if (!isValid) {
            this.value = undefined;
            this.emitChanges();
        }
        return isValid ? null : { license: 'A valid license is required' };
    };

    onItemSelected = $event => {
        this.value = $event.item;
        // for some reason, during this event, the FormControl does not yet have the new value,
        // so we update it here manually, so that the new value gets validated
        const licenseFormControl = this.licenseForm.get('license');
        licenseFormControl.setValue(this.value);
        this.validateLicense(<UntypedFormControl>licenseFormControl);
        this.emitChanges();
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    emitChanges(): void {
        if (this.onChange) {
            this.onChange(this.value);
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.licenseForm.get('license').disable();
        } else {
            this.licenseForm.get('license').enable();
        }
    }

    // I actually don't know the value and I want to search for it
    searchFor(value: string): void {
        this.searchString = value;
        this.searchStringWasConsumed = false;
        // Trigger ngbTypeahead search subscription
        this.typeaheadInput.nativeElement.dispatchEvent(new Event('input'));
    }

    // I want to set the value, the searchString assignment is a backdoor for showing the value in the input
    writeValue(obj: any): void {
        this.value = obj;
        if (this.value && (this.value.fqdn || this.value.name || this.value.hostname)) {
            this.searchString = this.value.fqdn || this.value.name || this.value.hostname;
        }
    }

    setFocusWhenEmpty(): void {
        if (this.typeaheadInput.nativeElement && !this.value) {
            // Without the forced repaint, there is no cursor in the input, it has something
            // to do with Angular's zones and change detection. This is not optimal, but it works.
            this.window.setImmediate(() => {
                // the value check is necessary as it may have been set in the inbetween ticks
                if (!this.value) {
                    this.typeaheadInput.nativeElement.dispatchEvent(new Event('input'));
                    this.typeaheadInput.nativeElement.focus();
                    this.typeaheadInput.nativeElement.select();
                }
            });
        }
    }

    typeaheadKeydown($event: KeyboardEvent): void {
        if (this.instance.isPopupOpen()) {
            this.window.setTimeout(() => {
                const popup = document.getElementById(this.instance.popupId);
                const activeElements = popup.getElementsByClassName('active');
                if (activeElements.length === 1) {
                    this.scrollIntoView(activeElements[0]);
                }
            });
        }
    }

    private scrollIntoView(elem: any): void {
        if (typeof elem.scrollIntoViewIfNeeded === 'function') {
            // non standard function, but works (in chrome)...
            elem.scrollIntoViewIfNeeded();
        } else {
            //do custom scroll calculation or use jQuery Plugin or ...
            this.scrollIntoViewIfNeededPolyfill(elem as HTMLElement);
        }
    }

    private scrollIntoViewIfNeededPolyfill(elem: HTMLElement, centerIfNeeded = true): void {
        const parent = elem.parentElement,
            parentComputedStyle = window.getComputedStyle(parent, null),
            parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'), 10),
            parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'), 10),
            overTop = elem.offsetTop - parent.offsetTop < parent.scrollTop,
            overBottom =
                elem.offsetTop - parent.offsetTop + elem.clientHeight - parentBorderTopWidth >
                parent.scrollTop + parent.clientHeight,
            overLeft = elem.offsetLeft - parent.offsetLeft < parent.scrollLeft,
            overRight =
                elem.offsetLeft - parent.offsetLeft + elem.clientWidth - parentBorderLeftWidth >
                parent.scrollLeft + parent.clientWidth,
            alignWithTop = overTop && !overBottom;

        if ((overTop || overBottom) && centerIfNeeded) {
            parent.scrollTop =
                elem.offsetTop -
                parent.offsetTop -
                parent.clientHeight / 2 -
                parentBorderTopWidth +
                elem.clientHeight / 2;
        }

        if ((overLeft || overRight) && centerIfNeeded) {
            parent.scrollLeft =
                elem.offsetLeft -
                parent.offsetLeft -
                parent.clientWidth / 2 -
                parentBorderLeftWidth +
                elem.clientWidth / 2;
        }

        if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
            elem.scrollIntoView(alignWithTop);
        }
    }
}
