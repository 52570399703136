import moment from 'moment';
import { Chart, Point, Options, Axis, SeriesSplineOptions } from 'highcharts/highstock';
import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    SimpleChanges,
    OnChanges,
    ChangeDetectionStrategy,
} from '@angular/core';

import { HistoricalPerformanceData } from '../../../../model/model';
import {
    clearSelectedPoints,
    selectPoints,
    getPointsByXValue,
    buildPerfChartTooltipHtml,
} from '../../../../utils/highcharts';
import { IMetricConfig } from '../array-expanded-card-performance.component';

const CHART_WIDTH = 553;
const CHART_HEIGHT = 100;
const CHART_WIDTH_MAXIMIZE = 735;
const CHART_HEIGHT_MAXIMIZE = 350;
const ANIMATE = true;
const AXIS_LEFT = 3;

@Component({
    selector: 'array-expanded-card-performance-chart',
    templateUrl: 'array-expanded-card-performance-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayExpandedCardPerformanceChartComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() readonly metricConfig: IMetricConfig;
    @Input() readonly performanceData: HistoricalPerformanceData;
    @ViewChild('chartElem', { static: true }) readonly chartElem: ElementRef;

    isExpanded = false;
    chart: Chart;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.performanceData || changes.metricConfig) {
            this.updateChart();
        }
    }

    ngAfterViewInit(): void {
        this.chart = this.buildChart();
        this.updateChart();
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    toggleExpanded(): void {
        this.isExpanded = !this.isExpanded;
        const width = this.isExpanded ? CHART_WIDTH_MAXIMIZE : CHART_WIDTH;
        const height = this.isExpanded ? CHART_HEIGHT_MAXIMIZE : CHART_HEIGHT;
        this.chart.setSize(width, height, !ANIMATE);
    }

    /**
     * Redraws the chart series using the new data
     */
    private updateChart(): void {
        if (!this.chart) {
            return;
        }

        this.metricConfig.series.forEach((seriesData, i) => {
            const series = this.chart.series[i];
            const key = seriesData.rawKey;

            if (this.performanceData && this.performanceData[key]) {
                const newPoints: HistoricalPerformancePoint[] = this.performanceData[key].filter(pt => pt.y != null);
                if (newPoints.length > 0) {
                    series.setData(newPoints, false);
                }
            }
        });

        this.chart.redraw();
    }

    private buildChart(): Chart {
        const tooltipFormatter = p => this.buildTooltipHTML(p);
        const tickPositioner = axis => this.tickPositioner(axis);
        const getMetricConfig = () => this.metricConfig;

        const chartOptions: Options = {
            tooltip: {
                shared: false,
                backgroundColor: 'rgba(240, 240, 240, 0.9)',
                borderWidth: 1,
                borderColor: '#AAA',
                hideDelay: 0,
                useHTML: true,
                style: {
                    border: 'none',
                    padding: '0',
                    cursor: 'default',
                    fontSize: '12px',
                    pointerEvents: 'none',
                    whiteSpace: 'nowrap',
                },
                positioner: (labelWidth, labelHeight, point) => {
                    let x = 0;
                    if (point.plotX + labelWidth > this.chart.plotWidth - 20) {
                        x = point.plotX + this.chart.plotLeft - labelWidth - 25;
                    } else {
                        x = point.plotX + this.chart.plotLeft + 20;
                    }
                    return { x: x, y: 0 };
                },
                formatter: function (): string {
                    return tooltipFormatter(this.point);
                },
            },
            chart: {
                renderTo: this.chartElem.nativeElement,
                width: CHART_WIDTH,
                height: CHART_HEIGHT,
                type: 'spline',
                spacingTop: 10,
                spacingRight: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                animation: !ANIMATE,
            },
            title: {
                floating: true,
                text: this.metricConfig.label,
                style: { fontSize: '14px' },
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    enabled: true,
                },
                title: {
                    text: null,
                },
                tickColor: '#e6e6e6',
                crosshair: {
                    color: 'black',
                    dashStyle: 'Solid',
                },
            },
            yAxis: [
                {
                    labels: {
                        align: 'left',
                        x: 5,
                        enabled: true,
                        style: {
                            'text-align': 'right',
                            textShadow: '1px 1px #FFF, -1px 1px #FFF, 1px -1px #FFF, -1px -1px #FFF',
                        },
                        formatter: function (): string {
                            if (getMetricConfig()) {
                                const label = getMetricConfig().series[0].filter(this.value as number, 2, true);
                                const v = label.value;
                                if (v.indexOf('.00') > 0) {
                                    label.value = v.substring(0, v.indexOf('.')); // Strip trailing '.00'
                                }
                                return `${label.value} ${label.unit}`;
                            }
                        },
                    },
                    title: {
                        text: null,
                    },
                    min: 0,
                    minRange: this.metricConfig.minRange,
                    minTickInterval: this.metricConfig.minTickInterval,
                    tickPositioner: function (this: Axis): number[] {
                        return tickPositioner(this);
                    },
                },
                {
                    title: {
                        text: null,
                    },
                    labels: {
                        enabled: false,
                    },
                    gridLineWidth: 0,
                    opposite: true,
                },
            ],
            plotOptions: {
                spline: {
                    states: {
                        hover: {
                            enabled: true,
                            lineWidth: 1.5,
                        },
                    },
                },
                series: {
                    animation: false,
                    borderColor: '#888888',
                    lineWidth: 1.5,
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 1.5,
                    },
                },
            },
            legend: {
                enabled: false,
            },
        };

        const newChart: Chart = new Chart(chartOptions);
        // The latest Highcharts versions like to apply overflow: hidden on the chartElem, clipping our tooltips
        (this.chartElem.nativeElement as HTMLElement).style.overflow = 'visible';

        this.metricConfig.series.forEach(serie => {
            const config: SeriesSplineOptions = {
                name: serie.label,
                color: serie.color,
                type: 'spline',
            };
            if (serie.hidden) {
                config.marker = {
                    enabled: false,
                    states: {
                        hover: { enabled: false },
                    },
                };
                config.color = 'rgba(0,0,0,0)';
                config.yAxis = 1;
            }
            newChart.addSeries(config, false, !ANIMATE);
        });

        return newChart;
    }

    private buildTooltipHTML(referencePoint: Point): string {
        clearSelectedPoints(this.chart);

        // Using the reference point, find the point on each series
        const points = getPointsByXValue(this.chart, referencePoint.x);

        if (points.length === 0) {
            return '';
        }

        // Select points to "highlight" them in the UI
        selectPoints(points);

        // Build tooltip from points
        const pointMoment = moment(referencePoint.x);

        const rowHtmls = points.map((pt, i) => {
            const label = this.metricConfig.series[i].filter(pt.y, 2);
            return `<tr>
                        <td>
                            <span class="entityColor" style="background: ${(<SeriesSplineOptions>pt.series.options).color}"></span>
                            <span class="metricName">${this.metricConfig.series[i].shortLabel}</span>
                            <span class="metricValue">${label.value} ${label.unit}</span>
                        </td>
                     </tr>`;
        });

        return buildPerfChartTooltipHtml(
            pointMoment.format('MMM D HH:mm'),
            moment.duration(pointMoment.valueOf() - moment().valueOf()).humanize(true),
            rowHtmls.join(' '),
        );
    }

    private tickPositioner(axis: Axis): number[] {
        const ticks = this.metricConfig.binaryTickPositioner
            ? this.roundedBinaryTickPositioner(axis)
            : this.simpleTickPositioner(axis);

        if (this.isExpanded) {
            // Make sure we have at least 5 ticks
            if (ticks.length >= 2) {
                const adjustedInterval = ticks[1] - ticks[0];
                for (let i = ticks.length; i < 5; i++) {
                    ticks.push(ticks[ticks.length - 1] + adjustedInterval);
                }
            }
            return ticks;
        } else {
            // Two ticks
            return [ticks[0], ticks[ticks.length - 1]];
        }
    }

    private simpleTickPositioner(axis: Axis): number[] {
        const extremes = axis.getExtremes();
        let min,
            max = 0;
        // This used to be .opposite. But now, Highcharts lists sides by number 0-3 (top, right, bottom, left)
        if (axis.side === AXIS_LEFT) {
            min = 0;
            max = Math.max(extremes.dataMax, axis.minRange);
        } else {
            min = extremes.min;
            max = extremes.max;
        }
        return axis.getLinearTickPositions(axis.tickInterval, min, max);
    }

    private roundedBinaryTickPositioner(axis: Axis): number[] {
        // Using the tickInterval as our adjustment point, since the formatter
        // will give all ticks the units of the smallest tick (1 * tickInterval).
        // If we're dealing with KB, then we should work with the max, since KB
        // is our smallest unit.

        // The tick interval depends on the minRange. If the minRange is not set,
        // we set it to 1Kb, and recompute the tickInterval
        const extremes = axis.getExtremes();
        const tickInterval = axis.tickInterval;

        let minRange = axis.minRange;
        if (minRange === undefined || (minRange > 0 && minRange < 1024)) {
            minRange = 1024;
        }

        const min = 0;
        const max = Math.max(extremes.dataMax || 1024, minRange);

        const adjustPoint = tickInterval > 1024 ? tickInterval : max;

        // Highstock by default finds a nice, round tick interval in base 10, so
        // to factor out the right units we need to divide by 1000s instead of 1024s
        const adjustPointUnitPower = Math.floor(Math.log(adjustPoint) / Math.log(1000));
        const adjustedInterval = tickInterval / Math.pow(1000, adjustPointUnitPower);

        // Reduce min and max by unit factor as well. These are in bytes so
        // dividing by the right power of 1024
        const pow1024 = Math.pow(1024, adjustPointUnitPower);
        const adjustedMax = max / pow1024;
        const adjustedMin = min / pow1024;

        // Using highcharts tick positioner now that everything has the units factored out
        const ticks = (axis.getLinearTickPositions(adjustedInterval, adjustedMin, adjustedMax) || []).map(
            tick => tick * Math.pow(1024, adjustPointUnitPower),
        ); // Transforming back up to the correct binary unit

        // Also transforming tickInterval to the correct binary unit. This is used
        // by highstocks as it draws the graph.

        axis.tickInterval = adjustedInterval * pow1024;

        return ticks;
    }
}
