import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

const NOT_AVAILABLE = '-';

/**
 * Last updated should be before the current time
 */

@Pipe({
    name: 'formatReplicationLastUpdated',
})
export class FormatReplicationLastUpdatedPipe implements PipeTransform {
    transform(value: moment.Moment): string {
        if (value && value.isValid() && value.isSameOrBefore(moment())) {
            return value.fromNow();
        }
        if (value) {
            console.warn(`"Last Updated" value is in the future! : ${value.format()}`);
        }
        return NOT_AVAILABLE;
    }
}
