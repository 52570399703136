import moment from 'moment';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImmutableTimeRange } from '../../../model/ImmutableTimeRange';
import { CustomTimeRange } from '../../../ui/components/calendar-time-range-select/calendar-time-range-select.component';

export interface RangeFilters {
    startRange: CustomTimeRange;
    endRange: CustomTimeRange;
}

@Component({
    selector: 'invoice-management-range-filters',
    templateUrl: 'invoice-management-range-filters.component.html',
})
export class InvoiceManagementRangeFiltersComponent {
    @Input() readonly selectedRangeFilters: RangeFilters = { startRange: null, endRange: null };
    @Output() readonly selectedRangeFiltersChange = new EventEmitter<RangeFilters>();

    invoiceDateTimeRange: ImmutableTimeRange = new ImmutableTimeRange(moment.utc().subtract(1, 'year'), moment.utc());
    invoiceDueDateRange: ImmutableTimeRange = new ImmutableTimeRange(
        moment.utc().subtract(1, 'year'),
        moment.utc().add('3', 'year'),
    );

    selectDateRange(timeRangeOption: CustomTimeRange, type: 'creation' | 'due'): void {
        const newFilters: RangeFilters = {
            startRange:
                type === 'creation' ? timeRangeOption : this.cloneTimeRange(this.selectedRangeFilters.startRange),
            endRange: type === 'due' ? timeRangeOption : this.cloneTimeRange(this.selectedRangeFilters.endRange),
        };

        this.selectedRangeFiltersChange.emit(newFilters);
    }

    private cloneTimeRange(timeRange: CustomTimeRange): CustomTimeRange {
        if (timeRange == null) {
            return null;
        }

        return {
            start: timeRange.start ? timeRange.start.clone() : null,
            end: timeRange.end ? timeRange.end.clone() : null,
        };
    }
}
