import moment from 'moment';
import { Observable, from } from 'rxjs';
import { exhaustMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ListParams, UnifiedArray, UnifiedArrayService, Collection, DataPage, QueryParams } from '@pure1/data';

import { ArraysManager } from './arrays-manager.service';
import { smartTimer } from '@pstg/smart-timer';

const REQUEST_ARRAYS_FIELDS = [
    'array_id',
    'hostname',
    'os',
    'version',
    'install_address',
    'install_address_requested',
    'fqdn',
];
const ARRAY_REFRESH_INTERVAL = moment.duration(30, 'seconds').asMilliseconds();

@Injectable({ providedIn: 'root' })
export class ArraysManagerUnifiedArrayService implements Collection<UnifiedArray> {
    constructor(
        private arraysManager: ArraysManager,
        private unifiedArrayService: UnifiedArrayService,
    ) {}

    list(listParams: ListParams<UnifiedArray>): Observable<DataPage<UnifiedArray>> {
        return smartTimer(0, ARRAY_REFRESH_INTERVAL).pipe(
            exhaustMap(() => {
                const promise = this.arraysManager
                    .getPureArrays(
                        REQUEST_ARRAYS_FIELDS.join(','),
                        listParams.filter,
                        listParams.supportStatusFilterOption,
                    )
                    .then(result => {
                        return {
                            total: result.length,
                            response: result,
                        };
                    });
                return from(promise).pipe(take(1));
            }),
        );
    }

    create(properties: Partial<UnifiedArray>): Observable<UnifiedArray> {
        return this.unifiedArrayService.create(properties);
    }

    update(properties: Partial<UnifiedArray>, ids?: string[] | QueryParams): Observable<DataPage<UnifiedArray>> {
        if (!(ids instanceof Array)) {
            throw new Error('Cannot be called with queryParams');
        }

        return this.unifiedArrayService.update(properties, ids);
    }

    delete(id: string): Observable<void> {
        return this.unifiedArrayService.delete(id);
    }
}
