import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetManagementViewComponent } from './asset-management-view/asset-management-view.component';
import { AssetTabComponent } from './asset-tab/asset-tab.component';
import { PureTeam } from '../utils/teamOwnership';
import { ApplianceGenealogyViewComponent } from './appliance-genealogy-view/appliance-genealogy-view.component';
import { SubscriptionGenealogyViewComponent } from './subscription-genealogy-view/subscription-genealogy-view.component';
import { QuoteManagementViewComponent } from './quote-management-view/quote-management-view.component';
import { InvoiceManagementViewComponent } from './invoice-management-view/invoice-management-view.component';
import { FeatureNames } from '../model/FeatureNames';
import { hasPermissionGuard } from '../app/guards/permission-guard';
import { canViewAssetTabGuard } from './asset-tab/can-view-asset-tab';

const routes: Routes = [
    {
        path: '',
        component: AssetTabComponent,
        title: 'Assets',
        canActivate: [hasPermissionGuard('PURE1:read:subscription')],
        data: {
            pageHelpUrl:
                'https://support.purestorage.com/Pure1/Pure1_Manage/005_Pure1_Manage_-_Asset_Management/Asset_Management',
            ownedBy: PureTeam.P1M_XAAS,
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'asset-management',
            },
            {
                path: 'asset-management',
                component: AssetManagementViewComponent,
            },
            {
                path: 'appliance-genealogy',
                component: ApplianceGenealogyViewComponent,
            },
            {
                path: 'subscription-genealogy',
                component: SubscriptionGenealogyViewComponent,
            },
            {
                path: 'quote-management',
                component: QuoteManagementViewComponent,
                canActivate: [
                    canViewAssetTabGuard(
                        ['PURE1:read:partner_quote'],
                        FeatureNames.QUOTE_MANAGEMENT,
                        '/assets/asset-management',
                    ),
                ],
            },
            {
                path: 'invoice-management',
                component: InvoiceManagementViewComponent,
                canActivate: [
                    canViewAssetTabGuard(
                        ['PURE1:read:partner_invoice'],
                        FeatureNames.PARTNER_INVOICE,
                        '/assets/asset-management',
                    ),
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AssetManagementRoutingModule {}
