<div class="d-inline" [formGroup]="contactForm">
    <ng-template #contactSelected let-r="result" let-t="term">
        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
        <ngb-highlight class="highlight-version secondary-info" [result]="r.email" [term]="t"></ngb-highlight>
    </ng-template>
    <div>
        <input
            class="form-control full-width"
            type="text"
            [ngbTypeahead]="search"
            [value]="searchString"
            (selectItem)="onItemSelected($event)"
            (click)="clickContact$.next('')"
            [resultTemplate]="contactSelected"
            [inputFormatter]="formatter"
            #typeContactSearchInstance="ngbTypeahead"
            formControlName="contact"
        />
    </div>
</div>
