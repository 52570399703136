import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PodMetricAnnotation } from '../models/pod-metric-annotation';

@Injectable({ providedIn: 'root' })
export class PodMetricAnnotationService extends GenericService<PodMetricAnnotation> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PodMetricAnnotation,
            endpoint: '/rest/v1/pod-metric-annotations',
        });
    }
}
