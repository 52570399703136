import { toRoundedFixed } from './toRoundedFixed';

export function formatRatio(value: number): string {
    if (typeof value !== 'number' || isNaN(value)) {
        return null;
    }
    let val = '1.0';
    if (value > 1) {
        if (value > 100) {
            val = '>100';
        } else {
            val = toRoundedFixed(value, 1);
        }
    }
    return val;
}
