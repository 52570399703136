import moment from 'moment';
import { Resource } from '../interfaces/resource';

export class PureAuditEvent implements Resource {
    id: string;
    name: string;
    command: string;
    subcommand: string;
    componentName: string;
    details: string;
    arguments: string;
    user: string;
    time: number;
    index: string; // string here to match backend type, which is a string
    hostname: string;
    arrayId: string;
    eventTimeStr: string;

    constructor(json: any) {
        this.id = safeString(json.id);
        this.name = safeString(json.name);
        this.command = safeString(json.command);
        this.subcommand = safeString(json.subcommand);
        this.componentName = safeString(json.componentName);
        this.details = safeString(json.details);
        this.arguments = safeString(json.arguments);
        this.user = safeString(json.user);
        this.time = json.time ? json.time : 0;
        this.index = safeString(json.index);

        this.arrayId = json.arrays && json.arrays.length > 0 ? safeString(json.arrays[0].id) : '';
        this.hostname = json.arrays && json.arrays.length > 0 ? safeString(json.arrays[0].name) : '';

        this.eventTimeStr = formatDate(json.time);
    }
}

function formatDate(value: moment.MomentInput): string {
    if (value) {
        const time = moment(value);
        if (time.isValid()) {
            return time.format('YYYY-MM-DD HH:mm');
        }
    }
    return '-';
}

function safeString(input: any, defaultValue = ''): string {
    return input === null || input === undefined ? defaultValue : String(input);
}
