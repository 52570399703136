import { Observable } from 'rxjs';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormBuilder,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
} from '@angular/forms';
import { DisasterRecoveryClusterConfigurationService, DisasterRecoveryCluster } from '@pure1/data';
import { SearchDisasterRecoveryClusterComponent } from '../../shared/components/search-disaster-recovery-cluster/search-disaster-recovery-cluster.component';
import { AbstractProductSectionAppliancesComponent } from '../abstract-product-section-appliances';
import { forString } from 'core/src/utils/comparator';

@Component({
    selector: 'product-section-disaster-recovery',
    templateUrl: 'product-section-disaster-recovery.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductSectionDisasterRecoveryClustersComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProductSectionDisasterRecoveryClustersComponent),
            multi: true,
        },
    ],
})
export class ProductSectionDisasterRecoveryClustersComponent extends AbstractProductSectionAppliancesComponent<
    DisasterRecoveryCluster,
    SearchDisasterRecoveryClusterComponent
> {
    constructor(
        protected disasterRecoveryConfigurationsService: DisasterRecoveryClusterConfigurationService,
        protected cdr: ChangeDetectorRef,
        protected fb: UntypedFormBuilder,
    ) {
        super(cdr, fb);
    }

    getAppliances(): Observable<DisasterRecoveryCluster[]> {
        return this.disasterRecoveryConfigurationsService.getClusters();
    }

    selectVisibleProduct(initialProduct: DisasterRecoveryCluster): void {
        if (!initialProduct) {
            return;
        }
        this.appliances$.subscribe(() => {
            if (this.isBeingEdited) {
                const selectedCluster = this.appliances.find(cluster => cluster.id === initialProduct.id);
                if (!selectedCluster) {
                    this.appliances = this.appliances
                        .concat(initialProduct)
                        .slice()
                        .sort(forString<DisasterRecoveryCluster>(cluster => cluster.licenseKey).asc);
                }
                this.writeValue(initialProduct);
                return;
            }
            if (this.appliances.length === 0) {
                this.appliances = [initialProduct];
            }
            this.writeValue(initialProduct);
        });
    }

    validateAppliance(control: UntypedFormControl): ValidationErrors {
        // Let's not bother with checking when editing
        if (this.isBeingEdited) {
            return null;
        }
        const val = control.value;
        if (!val || !(val instanceof DisasterRecoveryCluster || typeof val === 'string')) {
            return { cluster: 'A valid product is required' };
        }
        return null;
    }

    writeValue(val: any): void {
        // need to wait for the clusters, otherwise the draft recovery breaks as we store only arrayId
        this.appliances$.subscribe(() => {
            if (val?.id) {
                let selectedArray = this.appliances.find(cluster => cluster.id === (val as DisasterRecoveryCluster).id);
                if (!selectedArray) {
                    selectedArray = val.id;
                }
                selectedArray && this.form.setValue({ product: selectedArray });
            }
        });
    }
}
