import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArraySelectorComponent } from './components/array-selector/array-selector.component';
import { UpgradeConfirmationGenericComponent } from './components/upgrade-confirmation-generic/upgrade-confirmation-generic.component';
import { UpgradeConfirmationComponent } from './components/upgrade-confirmation/upgrade-confirmation.component';
import { UpgradePlannerComponent } from './components/upgrade-planner/upgrade-planner.component';
import { UpgradePlannerGenericComponent } from './components/upgrade-planner-generic/upgrade-planner-generic.component';
import { UpgradeScheduledConfirmationComponent } from './components/upgrade-scheduled-confirmation/upgrade-scheduled-confirmation.component';
import { UpgradeSchedulerComponent } from './components/upgrade-scheduler.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '../../ui/ui.module';
import { UpgradeCaseDraftService } from './services/upgrade-case-draft.service';
import { SupportUpgradeService } from './services/support-upgrade.service';
import { UpgradeTimeslotPickerComponent } from './components/upgrade-timeslot-picker/upgrade-timeslot-picker.component';
import { ArchwizardModule } from '@achimha/angular-archwizard';
import { SharedModule } from '../shared/shared.module';
import { SsuBannerComponent } from './components/ssu-banner/ssu-banner.component';
import { RouterModule } from '@angular/router';
import {
    ButtonComponent,
    CheckboxComponent,
    LinkComponent,
    LoadingSpinnerComponent,
    SvgDirective,
    TabViewModule,
    TableModule,
    HiveTemplateDirective,
} from '@pure/hive';

@NgModule({
    declarations: [
        ArraySelectorComponent,
        UpgradeConfirmationComponent,
        UpgradeConfirmationGenericComponent,
        UpgradePlannerComponent,
        UpgradePlannerGenericComponent,
        UpgradeScheduledConfirmationComponent,
        UpgradeSchedulerComponent,
        UpgradeTimeslotPickerComponent,
        SsuBannerComponent,
    ],
    imports: [
        ArchwizardModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        UIModule,
        SharedModule,
        RouterModule,
        TabViewModule,
        ButtonComponent,
        SvgDirective,
        CheckboxComponent,
        LinkComponent,
        TableModule,
        LoadingSpinnerComponent,
        HiveTemplateDirective,
    ],
    providers: [UpgradeCaseDraftService, SupportUpgradeService],
    exports: [UpgradeSchedulerComponent],
})
export class SupportUpgradeModule {}
