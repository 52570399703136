import { Resource } from '../interfaces/resource';

export class VolumeSpace implements Resource {
    readonly id: string;
    readonly name: string;
    arrayId: string;
    arrayName: string;
    sizeInBytes: number;
    dataReduction: number; // dataReduction to 1
    volumePhysicalInBytes: number;
    volumePhysicalChangeInBytes: number;
    volumePhysicalChangeInPercentage: number; // volumePhysicalChangeInPercentage %
    snapshotPhysicalInBytes: number;
    snapshotPhysicalChangeInBytes: number;
    snapshotPhysicalChangeInPercentage: number; // snapshotPhysicalChangeInPercentage %
    volumeVirtualInBytes: number;
    volumeVirtualChangeInBytes: number;
    volumeVirtualChangeInPercentage: number; // volumeVirtualChangeInPercentage %
    snapshotVirtualInBytes: number;
    snapshotVirtualChangeInBytes: number;
    snapshotVirtualChangeInPercentage: number; // snapshotVirtualChangeInPercentage %
    volumeEffectiveUsedInBytes: number;
    volumeEffectiveUsedChangeInBytes: number;
    volumeEffectiveUsedChangeInPercentage: number; // volumeEffectiveUsedChangeInPercentage %
    snapshotEffectiveUsedInBytes: number;
    snapshotEffectiveUsedChangeInBytes: number;
    snapshotEffectiveUsedChangeInPercentage: number; // snapshotEffectiveUsedChangeInPercentage %
    aggregationTime: number;

    constructor(json: any) {
        if (json) {
            this.id = json.id;
            this.name = json.name;
            this.arrayId = json.array_id;
            this.arrayName = json.array_name;
            if (json.aggregation) {
                this.aggregationTime = json.aggregation.aggregation_time;
                if (json.aggregation.data) {
                    if (json.aggregation.data.provisioned && json.aggregation.data.provisioned['1h_avg'] != null) {
                        this.sizeInBytes = json.aggregation.data.provisioned['1h_avg'];
                    }
                    if (
                        json.aggregation.data.data_reduction &&
                        json.aggregation.data.data_reduction['1h_avg'] != null
                    ) {
                        this.dataReduction = json.aggregation.data.data_reduction['1h_avg'];
                    }
                    if (json.aggregation.data.volume_space) {
                        if (json.aggregation.data.volume_space['1h_avg'] != null) {
                            this.volumePhysicalInBytes = json.aggregation.data.volume_space['1h_avg'];
                        }
                        if (json.aggregation.data.volume_space['1h_avg_30days_growth'] != null) {
                            this.volumePhysicalChangeInBytes =
                                json.aggregation.data.volume_space['1h_avg_30days_growth'];
                        }
                        if (json.aggregation.data.volume_space['1h_avg_30days_growth_percentage'] != null) {
                            this.volumePhysicalChangeInPercentage =
                                json.aggregation.data.volume_space['1h_avg_30days_growth_percentage'] * 100;
                        }
                    }
                    if (json.aggregation.data.snapshot_space) {
                        if (json.aggregation.data.snapshot_space['1h_avg'] != null) {
                            this.snapshotPhysicalInBytes = json.aggregation.data.snapshot_space['1h_avg'];
                        }
                        if (json.aggregation.data.snapshot_space['1h_avg_30days_growth'] != null) {
                            this.snapshotPhysicalChangeInBytes =
                                json.aggregation.data.snapshot_space['1h_avg_30days_growth'];
                        }
                        if (json.aggregation.data.snapshot_space['1h_avg_30days_growth_percentage'] != null) {
                            this.snapshotPhysicalChangeInPercentage =
                                json.aggregation.data.snapshot_space['1h_avg_30days_growth_percentage'] * 100;
                        }
                    }
                    if (json.aggregation.data.virtual_space) {
                        if (json.aggregation.data.virtual_space['1h_avg'] != null) {
                            this.volumeVirtualInBytes = json.aggregation.data.virtual_space['1h_avg'];
                        }
                        if (json.aggregation.data.virtual_space['1h_avg_30days_growth'] != null) {
                            this.volumeVirtualChangeInBytes =
                                json.aggregation.data.virtual_space['1h_avg_30days_growth'];
                        }
                        if (json.aggregation.data.virtual_space['1h_avg_30days_growth_percentage'] != null) {
                            this.volumeVirtualChangeInPercentage =
                                json.aggregation.data.virtual_space['1h_avg_30days_growth_percentage'] * 100;
                        }
                    }
                    if (json.aggregation.data.snapshot_virtual_space) {
                        if (json.aggregation.data.snapshot_virtual_space['1h_avg'] != null) {
                            this.snapshotVirtualInBytes = json.aggregation.data.snapshot_virtual_space['1h_avg'];
                        }
                        if (json.aggregation.data.snapshot_virtual_space['1h_avg_30days_growth'] != null) {
                            this.snapshotVirtualChangeInBytes =
                                json.aggregation.data.snapshot_virtual_space['1h_avg_30days_growth'];
                        }
                        if (json.aggregation.data.snapshot_virtual_space['1h_avg_30days_growth_percentage'] != null) {
                            this.snapshotVirtualChangeInPercentage =
                                json.aggregation.data.snapshot_virtual_space['1h_avg_30days_growth_percentage'] * 100;
                        }
                    }
                    // PaaS volumes will not have Effective Used fields
                    if (json.aggregation.data.unique_effective_used_space) {
                        if (json.aggregation.data.unique_effective_used_space['1h_avg'] != null) {
                            this.volumeEffectiveUsedInBytes =
                                json.aggregation.data.unique_effective_used_space['1h_avg'];
                        }
                        if (json.aggregation.data.unique_effective_used_space['1h_avg_30days_growth'] != null) {
                            this.volumeEffectiveUsedChangeInBytes =
                                json.aggregation.data.unique_effective_used_space['1h_avg_30days_growth'];
                        }
                        if (
                            json.aggregation.data.unique_effective_used_space['1h_avg_30days_growth_percentage'] != null
                        ) {
                            this.volumeEffectiveUsedChangeInPercentage =
                                json.aggregation.data.unique_effective_used_space['1h_avg_30days_growth_percentage'] *
                                100;
                        }
                    }
                    if (json.aggregation.data.snapshots_effective_used_space) {
                        if (json.aggregation.data.snapshots_effective_used_space['1h_avg'] != null) {
                            this.snapshotEffectiveUsedInBytes =
                                json.aggregation.data.snapshots_effective_used_space['1h_avg'];
                        }
                        if (json.aggregation.data.snapshots_effective_used_space['1h_avg_30days_growth'] != null) {
                            this.snapshotEffectiveUsedChangeInBytes =
                                json.aggregation.data.snapshots_effective_used_space['1h_avg_30days_growth'];
                        }
                        if (
                            json.aggregation.data.snapshots_effective_used_space['1h_avg_30days_growth_percentage'] !=
                            null
                        ) {
                            this.snapshotEffectiveUsedChangeInPercentage =
                                json.aggregation.data.snapshots_effective_used_space[
                                    '1h_avg_30days_growth_percentage'
                                ] * 100;
                        }
                    }
                }
            }
        }
    }
}

const ARRAY_VOLUME_SEPARATOR = '|';

export interface VolumeSplitIds {
    arrayId: string;
    coreId: string;
}

export function getSplitIdsFromFullId(volumeId: string): VolumeSplitIds {
    const split = volumeId.split(ARRAY_VOLUME_SEPARATOR);
    return {
        arrayId: split[0],
        coreId: split[1],
    };
}
