import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Pod } from '../models/pod';

@Injectable({ providedIn: 'root' })
export class PodsService extends GenericService<Pod> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Pod,
            endpoint: '/rest/v3/pods',
            secondarySort: {
                key: 'id',
                order: 'asc',
            },
        });
    }
}
