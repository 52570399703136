import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PodReplicaLinkEvent } from '../models/pod-replica-link-event';

@Injectable({ providedIn: 'root' })
export class PodReplicaLinkEventsService extends GenericService<PodReplicaLinkEvent> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PodReplicaLinkEvent,
            endpoint: '/rest/v3/pod-replica-links/events',
        });
    }
}
