import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { LicenseType } from './license';

export class RequestDetailsProduct {
    id: string;
    termInMonth: number;
    licenseType: LicenseType;
    productCategory: string;
    capacity: number;
    productName: string;
    productGeneration: string;
    skuType: string;
    unitOfMeasure: string;
    controller: string;
    siteName: string;

    constructor(json: any) {
        this.id = json.id;
        this.termInMonth = json.term_in_month;
        this.licenseType = json.license_type;
        this.productCategory = json.product_category;
        this.capacity = json.capacity;
        this.productName = json.product_name;
        this.productGeneration = json.product_generation;
        this.skuType = json.sku_type;
        this.unitOfMeasure = json.unit_of_measure;
        this.controller = json.controller ? 'FA-' + json.controller : null;
        this.siteName = json.site_name;
    }
}

export class QuoteRequestDetails implements Resource {
    id: string;
    name: string;

    termInMonth: number;
    reservedAmount: number;
    startDate: moment.Moment;
    products: RequestDetailsProduct[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.id;
        this.termInMonth = json.term_in_month;
        this.reservedAmount = json.reserved_amount;
        this.startDate = json.start_date ? moment.utc(json.start_date) : null;
        this.products = json.products ? json.products.map(product => new RequestDetailsProduct(product)) : [];
    }
}
