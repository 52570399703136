import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import { FeatureFlagDxpService } from '@pure1/data';
import { FeatureNames } from '../../model/model';
import { SafeModeSchedulerMultipartyAuthComponent } from '../safemode-scheduler-multiparty-auth/safemode-scheduler-multiparty-auth/safemode-scheduler-multiparty-auth.component';

import { SafeModeSchedulerComponent } from '../safemode-scheduler/safemode-scheduler/safemode-scheduler.component';

@Injectable({ providedIn: 'root' })
export class SafeModeSchedulerModalService {
    private isOpen = false;
    safeModeMultipartyFeatureFlag = false;

    constructor(
        private ngbModal: NgbModal,
        featureFlagDxpService: FeatureFlagDxpService,
    ) {
        featureFlagDxpService
            .getFeatureFlag(FeatureNames.SAFE_MODE_MULTIPARTY_AUTH)
            .subscribe((safeModeMultipartyAuthPolicy: FeatureFlagStatus) => {
                this.safeModeMultipartyFeatureFlag = safeModeMultipartyAuthPolicy?.enabled === true;
            });
    }

    openSupportScheduler(selectedArrayId: string): void {
        // Ensure only one instance is opened at a time
        if (this.isOpen) {
            return;
        }

        this.isOpen = true;

        const modalInstance = this.ngbModal.open(
            this.safeModeMultipartyFeatureFlag ? SafeModeSchedulerMultipartyAuthComponent : SafeModeSchedulerComponent,
            {
                size: 'lg',
                backdrop: 'static',
            },
        );

        const instance = modalInstance.componentInstance as SafeModeSchedulerComponent;
        instance.initialArrays = [selectedArrayId];
        if (!this.safeModeMultipartyFeatureFlag) {
            instance.isEnablement = null;
        }
        instance.initialize();

        const onModalClose = () => {
            this.isOpen = false;
        };

        modalInstance.result.then(onModalClose, onModalClose);
    }
}
