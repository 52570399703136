import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Banner } from '../models/banner';

@Injectable({ providedIn: 'root' })
export class BannerService extends GenericService<Banner> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Banner,
            endpoint: '/rest/v1/banner',
        });
    }
}
