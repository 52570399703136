export type bannerStatus = 'info' | 'warning' | 'critical';

export class Banner {
    readonly id: string;
    readonly name: string;
    readonly message: string;
    readonly pagePrefix: RegExp;
    readonly status: bannerStatus;
    readonly dismissible?: boolean;

    constructor(banner: any) {
        this.id = banner.id;
        this.name = banner.id;
        this.message = banner.message;
        // convert to javascript compatible regex
        this.pagePrefix = new RegExp(`^${banner.page_prefix.replace('/', '\\/')}`);
        this.status = banner.status;

        // By default, critical banners can't be dismissed
        // But it still can be overridden by `dismissible` option
        const dismissibleDefault = this.status !== 'critical';
        this.dismissible = banner.dismissible ?? dismissibleDefault;
    }
}
