import moment from 'moment';
import { DraasApiFailbackOva } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryJobExecutionStatus, Resource } from '@pure1/data';

export class DisasterRecoveryFailbackOva implements Resource {
    id: string;
    name: string;
    size: number;
    createdAt: moment.Moment;
    expiresAt: moment.Moment;
    status: DisasterRecoveryJobExecutionStatus;
    path: string;
    hasMultipleParts: boolean;

    constructor(json: DraasApiFailbackOva) {
        this.id = json.id;
        this.name = json.file_name;
        this.size = json.size;
        this.createdAt = json.created_at_utc ? moment.utc(json.created_at_utc) : null;
        this.expiresAt = json.expires_at_utc ? moment.utc(json.expires_at_utc) : null;
        this.status = DisasterRecoveryJobExecutionStatus[json.status] || DisasterRecoveryJobExecutionStatus.UNKNOWN;
        this.path = json.path;
        this.hasMultipleParts = json.has_multiple_parts;
    }
}
