import { Resource } from '../interfaces/resource';

export interface VirtualGroup {
    name: string;
    description: string;
}

export interface StatsGroup {
    [type: string]: VirtualGroupStats;
}

export interface VirtualGroupStats {
    med_high: number;
    low_med: number;
    lower_bound: number;
    higher_bound: number;
}

export class WorkloadStats implements Resource {
    readonly id: string;
    readonly name: string;
    readonly virtualGroups: VirtualGroup;
    readonly stats: StatsGroup;

    constructor(json: any) {
        this.id = 'apps';
        this.name = json.name;
        this.virtualGroups = json.virtual_groups;
        this.stats = json.stats;
    }
}
