<div class="alert alert-danger" *ngIf="hasEOL">
    <pureui-svg svg="eol-icon-small.svg" height="24"></pureui-svg>
    <span>You have hardware on your Evergreen//Foundation subscription that is going end of life.</span>
    <a
        href="https://support.purestorage.com/Pure_Storage_Technical_Services/Technical_Services_Information/Pure_Storage_End-of-Product_Life_Cycle_Overview#FlashArray_Hardware_and_End-of-Support"
        target="_blank"
    >
        Learn More
    </a>
    <a routerLink="/fleet/appliances/arrays/card" [queryParams]="{ filter: getHwEolFilters() }"> View Appliances </a>
</div>
