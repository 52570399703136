<div class="alert alert-info" *ngIf="currentUser.impersonating">
    Some fields below cannot be determined at this time since this user is an SSO user.
</div>
<form id="um-sso-user-form" [formGroup]="ssoUserForm">
    <section id="user-access-control" class="container-fluid">
        <h1 class="section-header">Access Control</h1>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label class="no-padding" for="user-role">Role</label>
            </div>
            <div class="col-xs-9">
                <p id="impersonated_role" *ngIf="currentUser.impersonating; else showAllRoles">{{ hiddenField }}</p>
                <ng-template #showAllRoles>
                    <show-all-roles [role]="currentUser.role" [additionalRoles]="currentUser.additionalRoles" />
                </ng-template>
            </div>
        </div>
    </section>
    <section id="user-details" class="container-fluid">
        <h1 class="section-header">User Details</h1>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-email">Email</label>
            </div>
            <div class="col-xs-9">
                <input class="form-control" name="user-email" type="email" formControlName="email" />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-first-name">First Name</label>
            </div>
            <div class="col-xs-9">
                <input class="form-control" name="user-first-name" type="text" formControlName="firstName" />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-last-name">Last Name</label>
            </div>
            <div class="col-xs-9">
                <input class="form-control" name="user-last-name" type="text" formControlName="lastName" />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12"> Please contact your admin to change your user profile. </div>
        </div>
        <div class="row">
            <div class="col-xs-3"></div>
            <div class="col-xs-9">
                <div class="form-buttons btn-toolbar">
                    <button class="btn btn-info float-xs-left cancel-button" (click)="cancel()"> Cancel </button>
                </div>
            </div>
        </div>
    </section>
</form>
