import { Resource } from '../interfaces/resource';

export interface PodReplicaLinkEventBody {
    current_severity?: string;
    new_status?: string;
    pod_name?: string;
    summary?: string;
}

export class PodReplicaLinkEvent implements Resource {
    id: string;
    name: string;
    body: PodReplicaLinkEventBody;
    time: number;
    type: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.body = json.body;
        // We want the time associated with the event to be when it was created, not when it was closed.
        // convert opened time from s to ms
        this.time = json.body.opened * 1000 || json.time;
        this.type = json.type;
    }
}
