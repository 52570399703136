import { take } from 'rxjs/operators';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';

import { Pill } from '../pill';
import { DisplayKeyValueLookupService } from '../../services/display-key-value-lookup.service';
import { IStateFilter } from '../../redux/pure-redux.service';

const MAX_APPLIANCE_KEY_COUNT = 10;

@Component({
    selector: 'gfb-pill',
    templateUrl: 'gfb-pill.component.html',
})
export class GfbPillComponent implements OnInit {
    @Input() readonly pill: Pill;
    @Input() readonly disabled: boolean = false;
    @Input() readonly supportStatusFilterOption: SupportStatusFilterOption;
    @Output() readonly edit = new EventEmitter<void>();
    @Output() readonly togglePin = new EventEmitter<void>();
    @Output() readonly remove = new EventEmitter<void>();

    displayKey: string;
    displayValues: string[] = [];

    constructor(private displayKeyValueLookup: DisplayKeyValueLookupService) {}

    ngOnInit(): void {
        const firstFilter: IStateFilter = this.pill.filters()[0];
        if (firstFilter.displayKey) {
            this.displayKey = firstFilter.displayKey;
        } else {
            this.displayKeyValueLookup
                .getDisplayKey(firstFilter.entity, firstFilter.namespace, firstFilter.key)
                .pipe(take(1))
                .subscribe((result: string) => {
                    this.displayKey = result;
                });
        }
        this.pill.filters().forEach((filter, index) => {
            if (filter.displayValue) {
                this.displayValues[index] = filter.displayValue;
            } else {
                this.displayKeyValueLookup
                    .getDisplayValue(
                        filter.entity,
                        filter.namespace,
                        filter.key,
                        filter.value,
                        this.supportStatusFilterOption,
                    )
                    .pipe(take(1))
                    .subscribe((result: string) => {
                        this.displayValues[index] = result;
                    });
            }
        });
    }

    editPill(): void {
        if (!this.disabled) {
            this.stopPropagation();
            this.edit.emit();
        }
    }

    togglePinUnpin(): void {
        if (!this.disabled) {
            this.stopPropagation();
            this.togglePin.emit();
        }
    }

    removePill(): void {
        if (!this.disabled) {
            this.stopPropagation();
            this.remove.emit();
        }
    }

    stopPropagation(): void {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
    }

    shortenValues(): boolean {
        const firstFilter: IStateFilter = this.pill.filters()[0];
        return (
            firstFilter.key === 'array_name' &&
            !firstFilter.namespace &&
            this.displayValues.length > MAX_APPLIANCE_KEY_COUNT
        );
    }
}
