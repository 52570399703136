import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ArrayWithAgents } from '../models/array-with-agents';
import { GenericService } from './generic.service';
import { SortParams } from '../interfaces/list-params';

@Injectable({ providedIn: 'root' })
export class EdgeServiceArraysService extends GenericService<ArrayWithAgents> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArrayWithAgents,
            endpoint: '/rest/v1/ems/arrays',
            defaultParams: {
                sort: <SortParams>{
                    key: 'hostname',
                    order: 'asc',
                },
            },
            // create, update, delete are assumed to be non-supported
        });
    }
}

@Injectable({ providedIn: 'root' })
export class EdgeServiceArrayProgressService extends GenericService<ArrayWithAgents> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArrayWithAgents,
            endpoint: '/rest/v1/ems/arrays/agent-in-progress',
            defaultParams: {
                sort: <SortParams>{
                    key: 'hostname',
                    order: 'asc',
                },
            },
            // create, update, delete are assumed to be non-supported
        });
    }
}
