<div
    #resize
    class="pureui-resizable resizable-updown"
    style="height: 245px; min-height: 165px; max-height: 795px; position: relative"
>
    <ng-content></ng-content>
    <div
        class="resize-grabber grabber-updown"
        (mousedown)="dragStartHandler($event)"
        (touchstart)="dragStartHandler($event)"
        (dragstart)="(false)"
    >
        <span class="grabber-bar"></span>
        <span class="grabber-icon"></span>
    </div>
</div>
