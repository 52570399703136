import { Directory } from './directory';

export class DirectoryTreeNode extends Directory {
    depth: number;
    pathComponents: string[];
    directoryName: string;
    subDirectories: DirectoryTreeNode[];

    constructor(json: any) {
        super(json);
        this.depth = json.depth;
        this.pathComponents = json.path_components;
        this.directoryName = json.directory_name;
        this.subDirectories = json.subdirectories
            ? json.subdirectories.map((subDir: any) => new DirectoryTreeNode(subDir))
            : [];
    }
}
