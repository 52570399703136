<div class="open">
    <button
        type="button"
        class="btn btn-secondary selection-button with-caret"
        [ngClass]="{ up: isSelectionsExpanded, 'no-selection': !hasSelectedValue, 'no-items': items.length === 0 }"
        (click)="toggleSelections($event)"
        [disabled]="isDisabled"
        [attr.test-id]="testId"
    >
        <div class="pull-left value-container">
            <label *ngIf="label">{{ label }}</label>
            <span class="value">{{ currentDisplayText }}</span>
            <span *ngIf="currentIcon" [ngbTooltip]="currentIconTooltip">
                <pureui-svg [svg]="currentIcon" [height]="15"></pureui-svg>
            </span>
        </div>
    </button>

    <ul *ngIf="isSelectionsExpanded" #selectionList class="selection-list">
        <label *ngIf="visibleItems.length > 0 && displayedItemsText">{{ displayedItemsText }}</label>
        <label *ngIf="visibleItems.length === 0 && noDisplayedItemsText">{{ noDisplayedItemsText }}</label>

        <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ items: visibleItems, shouldHighlight: shouldBoldRecommended }"
        ></ng-container>
        <div class="toggle" *ngIf="!hiddenItemsVisible && hiddenItems.length > 0" (click)="toggleVisibleItems($event)">
            {{ showItemsText }}</div
        >
        <div class="toggle" *ngIf="hiddenItemsVisible && hiddenItems.length > 0" (click)="toggleVisibleItems($event)">
            {{ hideItemsText }}</div
        >
        <ng-container
            *ngIf="hiddenItemsVisible"
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ items: hiddenItems, shouldHighlight: false }"
        ></ng-container>
    </ul>
</div>

<ng-template #itemTemplate let-items="items" let-shouldHighlight="shouldHighlight">
    <div
        *ngFor="let item of items; let first = first; trackBy: 'displayText' | trackByProperty"
        class="selection-list-item"
        [class.selected]="selectedValue === item.value"
        (click)="clickItem(item)"
    >
        <span [ngStyle]="{ 'font-weight': shouldHighlight && first ? 'bold' : 'none' }">
            {{ item.displayText }}{{ shouldHighlight && first ? ' - Recommended' : '' }}</span
        >
        <span *ngIf="item.icon" [ngbTooltip]="item.iconTooltip">
            <pureui-svg [svg]="item.icon" [height]="15"></pureui-svg>
        </span>
    </div>
</ng-template>
