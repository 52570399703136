import { Resource } from '../interfaces/resource';

export class Organization implements Resource {
    id: string;
    name: string;
    orgId: number;

    constructor(json: any) {
        this.id = String(json.orgId);
        this.name = json.name;
        this.orgId = json.orgId;
    }
}
