import _ from 'lodash';
import { Subscription, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { trigger, style, animate, transition } from '@angular/animations';
import { Component, Input, Inject, ElementRef, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tag, Resource, AutocompleteValueResponse, AutocompleteService } from '@pure1/data';

import { KEY } from '../../gfb/key.enum';
import { MultiResourceTag } from '../multi-resource-tag';
import { MultiResourceTagValue } from '../multi-resource-tag-value';
import { ArrayTagUpdateService } from '../services/array-tag-update.service';
import { IPureAutocompleteOptions, NamedValue } from '../../ui/directives/autocomplete.directive';
import { valueRequiredValidator } from './value-required.validator';
import { uniqueKeyValidator } from './unique-key.validator';
import { waitUntilDefined } from '../../utils/dom';
import { WINDOW } from '../../app/injection-tokens';

const CUSTOMER_NS = 'pure1';
const NO_SELECTION = -1;
const ENTITY = 'arrays';

const KEY_VALIDATORS = [Validators.required, Validators.maxLength(64)];
const VALUE_VALIDATORS = [Validators.maxLength(256), valueRequiredValidator];

const KEY_SUGGESTIONS = ['Application', 'Department'];

// export for unit test only
export const NAME_SUGGESTIONS: NamedValue<string>[] = KEY_SUGGESTIONS.map(key => {
    return {
        name: key,
        value: key,
    };
});

@Component({
    selector: 'manage-tags-modal-component',
    templateUrl: './manage-tags-modal.component.html',
    animations: [
        trigger('deleteTag', [
            transition('* => void', [
                style({ height: '*', margin: '*' }),
                animate(150, style({ height: 0, margin: 0 })),
            ]),
        ]),
    ],
})
export class ManageTagsModalComponent implements OnInit, OnDestroy {
    @Input() arrays: Resource[];
    @Input() tags: MultiResourceTag[];
    @Input() tagOrganizationId: number;

    @ViewChild('listWrapper', { static: true }) readonly listWrapper: ElementRef<HTMLDivElement>;

    autocompleteKeysOptions: IPureAutocompleteOptions<string>;
    autocompleteValuesOptions: IPureAutocompleteOptions<string>;
    displayArrays: string;

    tagsForm: UntypedFormGroup;
    get tagsFormArray(): UntypedFormArray {
        return this.tagsForm.get('tags') as UntypedFormArray;
    }

    activeIndex = NO_SELECTION;
    private originalTags: Map<string, MultiResourceTag>;
    private statusChangesSubscription: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private el: ElementRef<HTMLElement>,
        private arrayTagUpdateService: ArrayTagUpdateService,
        @Inject(WINDOW) private window: Window,
        private autocomplete: AutocompleteService,
    ) {}

    @HostListener('click', ['$event'])
    clickOnModal($event: MouseEvent): void {
        if (!($event.target as HTMLElement).closest('input, button')) {
            this.activeIndex = NO_SELECTION;
        }
    }

    ngOnInit(): void {
        this.tags.sort((a: MultiResourceTag, b: MultiResourceTag) => a.key.localeCompare(b.key));
        this.originalTags = new Map(
            this.tags.map((t: MultiResourceTag) => {
                return <[string, MultiResourceTag]>[t.key.toLowerCase(), t];
            }),
        );

        this.tagsForm = this.fb.group({
            tags: this.fb.array(
                this.tags.map(t =>
                    this.fb.group(
                        Object.assign({}, t, {
                            key: [t.key, KEY_VALIDATORS],
                            value: [t.value, VALUE_VALIDATORS],
                        }),
                    ),
                ),
                uniqueKeyValidator,
            ),
        });
        this.statusChangesSubscription = this.tagsForm.statusChanges.subscribe(() => this.emitScrollEvent());

        if (this.tags.length === 0) {
            this.newTag();
        }
        this.autocompleteKeysOptions = {
            list: (match$: Observable<string>): Observable<NamedValue<string>[]> => {
                const existingKeys = new Set<string>();
                this.tagsFormArray.controls.forEach((control, index) => {
                    const tag = control.value as MultiResourceTag;
                    if (index !== this.activeIndex) {
                        existingKeys.add(tag.key.toLowerCase());
                    }
                });
                return this.autocomplete.getKeys(match$.pipe(map(match => ({ entity: ENTITY, match })))).pipe(
                    map(r => {
                        // Transform response from autocomplete to an array of string
                        const tagSuggestions = NAME_SUGGESTIONS.filter(name =>
                            name.name.toLowerCase().includes(r.match.toLowerCase()),
                        );
                        const total = tagSuggestions.concat(
                            r.keys
                                .filter(t => t.namespace != null) // remove properties
                                .map(t => ({ name: t.key, value: t.key })),
                        );
                        const tags = total.filter(t => !existingKeys.has(t.name.toLowerCase())); // Remove existing keys
                        return this.sanitizeKeys(tags);
                    }),
                );
            },
            appendTo: this.el.nativeElement.closest('.modal-content'),
            scrollEl: this.el.nativeElement.querySelector('.tag-list-wrapper'),
            cleanInputOnEmpty: false,
        };

        this.autocompleteValuesOptions = {
            list: (match$: Observable<string>): Observable<NamedValue<string>[]> => {
                const key = (this.tagsFormArray.controls[this.activeIndex].value as MultiResourceTag).key;
                if (key !== '') {
                    return this.autocomplete
                        .getValues(
                            match$.pipe(
                                map(match => ({
                                    entity: ENTITY,
                                    namespace: CUSTOMER_NS,
                                    key,
                                    match,
                                })),
                            ),
                            // No supportStatusFilterOption, use default behavior determined by the backend.
                            null,
                        )
                        .pipe(
                            map((autocompleteValues: AutocompleteValueResponse[]) =>
                                autocompleteValues.map(autocompletValue => ({
                                    name: autocompletValue.value,
                                    value: autocompletValue.value,
                                })),
                            ),
                        );
                } else {
                    return of([]);
                }
            },
            appendTo: this.el.nativeElement.closest('.modal-content'),
            scrollEl: this.el.nativeElement.querySelector('.tag-list-wrapper'),
            cleanInputOnEmpty: false,
        };
        this.getDisplayArrays();
    }

    ngOnDestroy(): void {
        if (this.statusChangesSubscription && !this.statusChangesSubscription.closed) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    newTag(): void {
        const addTag = () => {
            const newTag = {
                namespace: CUSTOMER_NS,
                key: ['', KEY_VALIDATORS],
                value: ['', VALUE_VALIDATORS],
                originKey: '',
                originValues: {},
                resourceCount: this.arrays.length,
                tag_organization_id: this.tagOrganizationId,
            };
            this.tagsFormArray.push(this.fb.group(newTag));
        };

        // Add a tag if the last row is not an empty tag
        if (this.tagsFormArray.controls.length === 0) {
            addTag();
        } else {
            const lastMRT = this.tagsFormArray.controls[this.tagsFormArray.controls.length - 1]
                .value as MultiResourceTag;
            if (lastMRT.key !== '' || lastMRT.value !== '') {
                addTag();
            }
        }
        // Focus on the last row
        this.activeIndex = this.tagsFormArray.length - 1;
        waitUntilDefined(() => this.el.nativeElement.querySelector('.edit-row .edit-key') as HTMLInputElement).then(
            el => el.focus(),
        );
    }

    edit(index: number): void {
        this.activeIndex = index;
    }

    delete(index: number): void {
        this.activeIndex = NO_SELECTION;
        this.tagsFormArray.removeAt(index);
    }

    trim(index: number, controlName: string): void {
        const control = this.tagsFormArray.get([index, controlName]);
        const value = control.value;
        if (value !== value.trim()) {
            control.setValue(value.trim());
        }
    }

    save(): void {
        const tagsToUpsert: MultiResourceTagValue[] = [];
        const tagsToDelete: MultiResourceTagValue[] = [];

        this.tagsFormArray.controls.forEach((control: UntypedFormGroup) => {
            const mrt = control.value as MultiResourceTag;
            const keyLc = mrt.key.toLowerCase();

            if (mrt.value !== '') {
                // value common to all resources
                // We upsert the new values
                const baseTag = new Tag(Object.assign({}, mrt));
                const tagValue = new MultiResourceTagValue(baseTag);

                // Compute the list of arrays to upsert. Start with all arrays, remove the one that currently
                // have this tag.
                const arraysToUpdate = new Set(this.arrays.map(array => array.id));

                if (this.originalTags.has(keyLc)) {
                    const tag = this.originalTags.get(keyLc);
                    if (tag.originKey === mrt.key) {
                        const arraysWithThisValue = this.originalTags.get(keyLc).originValues[mrt.value] || [];
                        arraysWithThisValue.forEach(arrayId => arraysToUpdate.delete(arrayId));
                    }
                }
                if (arraysToUpdate.size > 0) {
                    arraysToUpdate.forEach(arrayId => tagValue.add(arrayId));
                    tagsToUpsert.push(tagValue);
                }
                this.originalTags.delete(keyLc);
            } else if (mrt.originKey !== mrt.key) {
                // Key has changed
                Object.keys(mrt.originValues).forEach(value => {
                    const baseTag = new Tag({
                        namespace: CUSTOMER_NS,
                        key: mrt.key,
                        value: value,
                        tag_organization_id: this.tagOrganizationId,
                    });
                    const tagValue = new MultiResourceTagValue(baseTag);
                    mrt.originValues[value].forEach(arrayId => tagValue.add(arrayId));
                    tagsToUpsert.push(tagValue);
                });
                if (keyLc === mrt.originKey.toLowerCase()) {
                    // Tag key only changed by cases, it is just an update, no need to remove the original one.
                    this.originalTags.delete(keyLc);
                }
            } else {
                // Tag has not been updated
                this.originalTags.delete(keyLc);
            }
        });

        this.originalTags.forEach(tag => tagsToDelete.push(this.toDeleteTagValue(tag)));

        // Block until all backend tag changes complete
        // TODO: show a spinner, or something.
        this.arrayTagUpdateService.update(tagsToUpsert, tagsToDelete).then(() => {
            this.activeModal.close({ upsert: tagsToUpsert, delete: tagsToDelete });
        });
    }

    onAutocompleteKeySelect(fg: UntypedFormGroup, $event: NamedValue<string>[]): void {
        if ($event.length > 0) {
            fg.get('key').setValue($event[0].name);
        }
        this.window.setImmediate(() =>
            this.el.nativeElement.querySelector<HTMLInputElement>('.edit-row .edit-value').focus(),
        );
    }

    onAutocompleteValueSelect(fg: UntypedFormGroup, $event: NamedValue<string>[]): void {
        if ($event.length > 0) {
            fg.get('value').setValue($event[0].name);
        }
    }

    isAddButtonDisabled(): boolean {
        if (this.activeIndex === NO_SELECTION) {
            return false;
        } else {
            const tag = this.tagsFormArray.controls[this.activeIndex].value as MultiResourceTag;
            return tag.key.trim() === '' && tag.value.trim() === '';
        }
    }

    getErrorMsg(fg: UntypedFormGroup, isActive: boolean): string {
        const keyErrors: string[] = [];
        const valueErrors: string[] = [];
        const keyCtrl = fg.get('key');
        const valueCtrl = fg.get('value');

        // We show the error
        const showError = (control: AbstractControl): boolean => {
            return (
                control.invalid &&
                ((isActive && (control.dirty || control.touched)) || (!isActive && (fg.dirty || fg.touched)))
            );
        };

        if (showError(keyCtrl)) {
            if (keyCtrl.errors.required) {
                keyErrors.push('Key is required');
            }
            if (keyCtrl.errors.maxlength) {
                keyErrors.push(
                    `Key is too long: ${keyCtrl.errors.maxlength.actualLength}/${keyCtrl.errors.maxlength.requiredLength}`,
                );
            }
            if (keyCtrl.errors.uniqueKey) {
                keyErrors.push(`Key must be unique`);
            }
        }
        if (showError(valueCtrl)) {
            if (valueCtrl.errors.maxlength) {
                valueErrors.push(
                    `Value is too long: ${valueCtrl.errors.maxlength.actualLength}/${valueCtrl.errors.maxlength.requiredLength}`,
                );
            }
            if (valueCtrl.errors.required) {
                valueErrors.push('Value is required');
            }
        }
        return (
            `<div class="key-errors">` +
            keyErrors.map(txt => `<div class="key-error">${txt}</div>`).join('') +
            `</div>` +
            `<div class="value-errors">` +
            valueErrors.map(txt => `<div class="value-error">${txt}</div>`).join('') +
            `</div>`
        );
    }

    tabCircleBack($event: KeyboardEvent): void {
        if ($event.key === KEY.tab && !$event.shiftKey && !$event.ctrlKey && !$event.metaKey) {
            $event.stopPropagation();
            $event.preventDefault();
            this.el.nativeElement.querySelectorAll<HTMLButtonElement>('input, button.btn').item(0).focus();
        }
    }

    private getDisplayArrays(): void {
        const limit = 3;
        this.displayArrays = this.arrays
            .slice(0, Math.min(this.arrays.length, limit))
            .map(pureArray => pureArray.name)
            .join(', ');
        if (this.arrays.length > limit) {
            this.displayArrays += `, and ${this.arrays.length - limit} more`;
        }
    }

    private toDeleteTagValue(mrt: MultiResourceTag): MultiResourceTagValue {
        const baseTag = new Tag({
            namespace: CUSTOMER_NS,
            key: mrt.key,
            tag_organization_id: this.tagOrganizationId,
        });
        const tagValue = new MultiResourceTagValue(baseTag);
        _.flatten(Object.values(mrt.originValues)).forEach(resourceId => tagValue.add(resourceId));
        return tagValue;
    }

    private sanitizeKeys(keys: NamedValue<string>[]): NamedValue<string>[] {
        const keySet = new Set<string>();
        const result: NamedValue<string>[] = [];
        keys.sort((a, b) => a.name.localeCompare(b.name)).forEach(key => {
            const keyLC = key.name.toLowerCase();
            if (!keySet.has(keyLC)) {
                result.push(key);
                keySet.add(keyLC);
            }
        });
        return result;
    }

    private emitScrollEvent(): void {
        // If the active row has an error message make sure it is visible.
        if (this.activeIndex !== NO_SELECTION) {
            // wait for the DOM to be updated
            this.window.setImmediate(() => {
                const errorMessageDiv = this.el.nativeElement
                    .querySelectorAll('.tag-control')
                    .item(this.activeIndex)
                    .querySelector<HTMLDivElement>('.error-messages');

                if (errorMessageDiv && errorMessageDiv.offsetHeight) {
                    const wrapper = this.listWrapper.nativeElement;
                    const errorMessageBottom = errorMessageDiv.offsetTop + errorMessageDiv.offsetHeight;
                    const wrapperBottom = wrapper.offsetHeight + wrapper.scrollTop;
                    if (errorMessageBottom > wrapperBottom) {
                        wrapper.scrollTop += errorMessageBottom - wrapperBottom;
                    }
                }
            });
        }

        try {
            const scrollEvent = document.createEvent('CustomEvent');
            scrollEvent.initCustomEvent('scroll', false, false, null);
            this.listWrapper.nativeElement.dispatchEvent(scrollEvent);
        } catch (err) {
            if (err && (err.number === -2146827843 || err.name === 'TypeError')) {
                console.log('Ignoring IE11-specific error', err); // Ignore IE11 "Object doesn't support this action"
            } else {
                throw err;
            }
        }
    }
}
