import { Subject, takeUntil } from 'rxjs';
import { Action } from 'redux';
import { cloneDeep } from 'lodash';
import stableStringify from 'json-stable-stringify';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { NgRedux } from './ng-redux.service';
import { IState } from './pure-redux.service';
import { UrlReaderWriter } from './url-reader-writer';

interface RegisteredUrlReaderWriter {
    readerWriter: UrlReaderWriter;
    count: number;
}

@Injectable({ providedIn: 'root' })
export class UrlService {
    // the registed readerWriters, in theory, there should be only one readerWriter, however due to the timing of $onInit and $onDestory being called,
    // it could be multiple readerWriters.
    private readerWriters: Map<string, RegisteredUrlReaderWriter> = new Map<string, RegisteredUrlReaderWriter>();
    // mark the last processed search, the data in last processed search should be equivelant to the current redux state.
    private lastProcessedSearch: string;
    // flag is used to prevent write url with just changed state from the url.
    private loadingStateFromUrl = false;

    private lastProcessedPath: string;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private ngRedux: NgRedux<IState>,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        ngRedux.subscribe(() => this.onStateChange(ngRedux.getState()));

        router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.onUrlChangeSuccess();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    register(urlReaderWriter: UrlReaderWriter, initialRead = false): () => void {
        if (!this.readerWriters.has(String(urlReaderWriter.path))) {
            this.readerWriters.set(String(urlReaderWriter.path), { readerWriter: urlReaderWriter, count: 1 });
            if (initialRead) {
                this.initialRead(urlReaderWriter);
            }
        } else {
            const registeredUrlReaderWriter = this.readerWriters.get(String(urlReaderWriter.path));
            this.readerWriters.set(String(urlReaderWriter.path), {
                readerWriter: urlReaderWriter,
                count: registeredUrlReaderWriter.count + 1,
            });
        }
        return () => {
            const registeredUrlReaderWriter = this.readerWriters.get(String(urlReaderWriter.path));
            if (registeredUrlReaderWriter.count > 1) {
                this.readerWriters.set(String(urlReaderWriter.path), {
                    readerWriter: urlReaderWriter,
                    count: registeredUrlReaderWriter.count - 1,
                });
            } else {
                this.readerWriters.delete(String(urlReaderWriter.path));
            }
        };
    }

    onChangeUrl(): void {
        this.lastProcessedSearch = undefined;
        this.onStateChange(this.ngRedux.getState());
    }

    private initialRead(rw: UrlReaderWriter): void {
        const newPath = this.getUrlPath();
        if (rw.path.test(newPath)) {
            const actions = rw.read(this.getUrlParams(), true);
            if (actions && actions.length > 0) {
                this.lastProcessedPath = newPath;
                this.ngRedux.dispatch(actions);
            } else {
                const state = this.ngRedux.getState();
                const newParams = rw.write(state, {});
                const newSearch = stableStringify(newParams);
                if (newSearch !== this.lastProcessedSearch || newPath !== this.lastProcessedPath) {
                    this.router.navigate([], { relativeTo: this.route, queryParams: newParams, replaceUrl: true });

                    this.lastProcessedSearch = newSearch;
                    this.lastProcessedPath = newPath;
                }
            }
        }
    }

    /**
     * Gets the url search params
     */
    private getUrlParams(): { [key: string]: string } {
        return cloneDeep(this.route.snapshot.queryParams);
    }

    private onUrlChangeSuccess(): void {
        const search = this.getUrlParams();
        // if it is last processed search, it indicates the current state is already updated, nothing need to be done here.
        const stringifiedSearch = stableStringify(search);
        if (this.lastProcessedSearch !== stringifiedSearch) {
            // load the state from url
            const actions: Action[] = [];
            const rws = this.selectReaderWriters(this.getUrlPath());
            rws.forEach(rw => {
                const action = rw.read(search, false, this.getUrlPath(), this.lastProcessedPath);
                if (action) {
                    actions.push(...action);
                }
            });

            if (actions.length > 0) {
                this.loadingStateFromUrl = true;
                try {
                    this.ngRedux.dispatch(actions);
                } finally {
                    this.loadingStateFromUrl = false;
                }
            }

            this.lastProcessedSearch = stringifiedSearch;
        }
        this.lastProcessedPath = this.getUrlPath();
    }

    private onStateChange(state: IState): void {
        if (this.loadingStateFromUrl) {
            return;
        }
        this.updateUrlFromState(state);
    }

    private updateUrlFromState(state: IState, replace = false): void {
        const rws = this.selectReaderWriters(this.getUrlPath());
        const params = this.getUrlParams();
        let newParams = params;
        rws.forEach(rw => (newParams = rw.write(state, newParams)));
        const newSearch = stableStringify(newParams);

        if (this.lastProcessedSearch !== newSearch) {
            this.router.navigate([], { relativeTo: this.route, queryParams: newParams, replaceUrl: replace });
            this.lastProcessedSearch = newSearch;
        }
    }

    private selectReaderWriters(path: string): UrlReaderWriter[] {
        return Array.from(this.readerWriters.values())
            .filter(rw => rw.readerWriter.path.test(path))
            .map(rw => rw.readerWriter);
    }

    private getUrlPath(): string {
        return this.router.url;
    }
}
