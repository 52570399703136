export class NpsSurvey {
    surveyId: string;
    surveyName: string;
    surveyTitle: string;
    surveyTagline: string;
    questionOne: string;
    questionOneLowExplanation: string;
    questionOneHighExplanation: string;
    questionTwoHigh: string;
    questionTwoLow: string;
    externalSurveyUrl: string;

    constructor(json: any) {
        this.surveyId = json.nps_survey_id;
        this.surveyName = json.nps_survey_name;
        this.surveyTitle = json.nps_survey_title;
        this.surveyTagline = json.nps_survey_tagline;
        this.questionOne = json.question_one;
        this.questionOneLowExplanation = json.question_one_low_explanation;
        this.questionOneHighExplanation = json.question_one_high_explanation;
        this.questionTwoHigh = json.question_two_high;
        this.questionTwoLow = json.question_two_low;
        this.externalSurveyUrl = json.external_survey_url;
    }
}
