import { DraasApiDatastoresChangeRequest } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryDatastoresChangeRequest {
    readonly id: string;
    readonly status: 'PENDING' | 'COMPLETED';
    readonly createdAt: Date;
    readonly lastUpdatedAt: Date;
    readonly providerId: string;
    readonly datastoresStatus: 'PENDING' | 'SYNCED' | 'ERROR';

    constructor(json: DraasApiDatastoresChangeRequest) {
        this.id = json.id;
        this.status = json.status;
        this.createdAt = new Date(json.created_at_utc);
        this.lastUpdatedAt = new Date(json.last_updated_at_utc);
        this.providerId = json.provider_id;
        this.datastoresStatus = json.datastores_status;
    }
}
