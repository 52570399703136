import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '../../utils/formatNumber';

@Pipe({ name: 'formatPercent' })
export class FormatPercentPipe implements PipeTransform {
    transform(value: number, decimal = 0): string {
        if (isNaN(value)) {
            return '-';
        }
        return String(formatNumber(100.0 * value, decimal)) + '%';
    }
}
