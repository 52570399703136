import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
    DataPage,
    FilterParams,
    GenericService,
    GFBEntity,
    Incident,
    IncidentService,
    License,
    LicenseService,
    Metric,
    ProgramType,
    QuoteStage,
    Resource,
    ServiceCatalogQuote,
    ServiceCatalogQuoteServiceV4,
    Subscription,
    SubscriptionService,
} from '@pure1/data';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep, isEqual, omit } from 'lodash';
import moment from 'moment';
import { GenericType, MetricTypeMappingService } from '../services/metric-type-mapping.service';
import { OrdersUpdateService } from '../../service-catalog/services/orders-update.service';
import { UpdateResourceService } from '../services/update-resource.service';
import { appendProgramTypeFilters, getTimeRangeChoices } from '../subscription-utils/subscription-utils';
import { TimeRange } from '../../ui/components/time-range-selector/time-range-selector.component';
import { consolidateFilterValues, prepForDataLayer } from '../../gui/paged-data2.component';
import {
    LICENSE_ENTITY,
    LICENSE_NAME_KEY,
    LICENSE_TYPE_KEY,
    NAME_KEY,
    PROGRAM_TYPE_KEY,
    removeFiltersByKey,
    SUBSCRIPTION_ENTITY,
} from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { ExportOptionIds } from '../../export/export-button/export-button.component';
import { smartTimer } from '@pstg/smart-timer';

export interface ServiceRefreshParams<T> {
    serviceName: string;
    filters?: FilterParams<T>;
    tap?: (result: DataPage<T>) => void;
}

export const PENDING_ORDER_STAGES = [
    'Quote Requested',
    'Quote in Progress',
    'Quote w/ Partner',
    'Quote Accepted',
    'Preparing Order',
];
const REFRESH_DELAY = moment.duration(30, 'seconds');
const WILDCARD_FIELDS = [PROGRAM_TYPE_KEY, LICENSE_TYPE_KEY, NAME_KEY];

// These are fields that are different on pretty much every call. We take them out so the objects don't look "new"
// and thus we don't need to update the view
type LicenseObjProps = keyof License;
type SubscriptionObjProps = keyof Subscription;
type EvergreenObjProps = LicenseObjProps | SubscriptionObjProps;

const IGNORED_LICENSE_FIELDS: LicenseObjProps[] = ['licenseEsg', 'licensePerformanceAggregation'];
const IGNORED_SUBSCRIPTION_FIELDS: SubscriptionObjProps[] = ['lastUpdated'];
const IGNORE_FIELDS_WHEN_COMPARING: EvergreenObjProps[] = [...IGNORED_LICENSE_FIELDS, ...IGNORED_SUBSCRIPTION_FIELDS];

@Directive()
export class BaseEvergreenViewComponent implements OnInit, OnDestroy {
    readonly ProgramType = ProgramType;

    loading = true;
    wildcardFields = WILDCARD_FIELDS;

    // Gfb related fields
    NAME_KEY = NAME_KEY;
    GFB_ENTITIES: GFBEntity[] = [SUBSCRIPTION_ENTITY, LICENSE_ENTITY];
    ExportOptionIds = ExportOptionIds;
    listViewExportOptions: IExportButtonOption[] = [
        { id: ExportOptionIds.filtered, text: 'Export filtered subscriptions', count: null },
        { id: ExportOptionIds.all, text: 'Export all subscriptions', count: null },
    ];

    // Redux related fields
    filters: FilterParams<Subscription>;
    licenseFilters: FilterParams<License>;

    allSubscriptionsForSpecifiedProgramTypes: Subscription[];
    subscriptions: Subscription[];
    earliestSubscription: Subscription;
    allLicenses: License[];
    typeMapping: Map<GenericType, Metric>;
    inProgressOrdersBySubscriptionId = new Map<string, ServiceCatalogQuote[]>();

    //Recommendations related fields
    recommendations: Incident[] | null = null;

    //Evergreen One related fields
    evergreenOneSubscriptions: Subscription[];
    evergreenOneEarliestSubscription: Subscription;
    evergreenOneLicenses: License[];

    //Evergreen Flex related fields
    evergreenFlexSubscriptions: Subscription[];
    evergreenFlexEarliestSubscription: Subscription;
    evergreenFlexLicenses: License[];

    //DRaaS Subscription related fields
    draasSubscriptions: Subscription[];
    draasAllLicenses: License[];
    draasGroupedLicenses = new Map<string, License[]>();

    selectedExportOption: IExportButtonOption;
    filterString = '';
    timeRangeChoices: TimeRange[] = [];

    protected allOrders: ServiceCatalogQuote[] = [];

    protected readonly destroy$ = new Subject<void>();

    constructor(
        protected ngRedux: NgRedux<IState>,
        protected licenseService: LicenseService,
        protected incidentService: IncidentService,
        protected metricTypeMappingService: MetricTypeMappingService,
        protected ordersUpdateService: OrdersUpdateService,
        protected serviceCatalogQuoteService: ServiceCatalogQuoteServiceV4,
        protected subscriptionService: SubscriptionService,
        protected updateResourceService: UpdateResourceService,
        public barId: string,
        private programTypeFilters: ProgramType[], // Might want multiple matching program types per tab
    ) {}

    ngOnInit(): void {
        const mappingObservable$ = this.metricTypeMappingService.getTypeMapping();

        const licenses$ = this.updateResourceService.getUpdateLicenses().pipe(
            tap(() => {
                this.loading = true;
            }),
            switchMap(() => {
                return this.generateRefresh(this.licenseService, {
                    serviceName: 'Licenses',
                    filters: this.licenseFilters,
                });
            }),
            takeUntil(this.destroy$),
        );

        this.getAllSubscriptionsForSpecifiedProgramTypes();

        const subscriptions$ = this.updateResourceService.getUpdateSubscriptions().pipe(
            tap(() => {
                this.loading = true;
            }),
            switchMap(() => {
                return this.generateRefresh(this.subscriptionService, {
                    serviceName: 'Subscription',
                    filters: appendProgramTypeFilters(this.filters, this.programTypeFilters),
                });
            }),
            takeUntil(this.destroy$),
        );

        const orderQuotes$ = this.ordersUpdateService.refreshQuotes$.pipe(
            tap(() => {
                this.loading = true;
            }),
            switchMap(() => {
                return this.generateRefresh(this.serviceCatalogQuoteService, {
                    serviceName: 'ServiceCatalogQuote',
                });
            }),
            takeUntil(this.destroy$),
        );

        const recommendations$ = this.incidentService.list().pipe(
            tap(() => {
                this.loading = true;
            }),
            switchMap(() => {
                return this.generateRefresh(this.incidentService, {
                    serviceName: 'Incident',
                });
            }),
            takeUntil(this.destroy$),
        );

        combineLatest([mappingObservable$, licenses$, subscriptions$, orderQuotes$, recommendations$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([typeMap, inputLicenses, subscriptions, orderQuotes, recommendations]) => {
                // These parameters are the same ones used as "prev" in distinctUntilChanged. If we don't deepClone this,
                // we'll be modifying these objects and making change detection comparison more difficult
                // We only modify licenses right now, but we may need to make deep clones if we modify other things
                this.allLicenses = cloneDeep(inputLicenses);
                this.allOrders = cloneDeep(orderQuotes);
                this.recommendations = cloneDeep(recommendations);
                if (Object.keys(this.licenseFilters).length) {
                    // In case licenses are filtered, we want to only include these licenses' parent subscriptions.
                    const subscriptionsFromLicenses = inputLicenses.map(license => license.subscription?.id);
                    this.subscriptions = cloneDeep(
                        subscriptions.filter(subscription => subscriptionsFromLicenses.includes(subscription.id)),
                    );
                } else {
                    this.subscriptions = cloneDeep(subscriptions);
                }
                this.evergreenOneSubscriptions = this.subscriptions?.filter(
                    subscription => subscription.programType === ProgramType.EVERGREEN_ONE,
                );
                this.evergreenOneLicenses = this.allLicenses?.filter(
                    license => license.subscription.program_type === ProgramType.EVERGREEN_ONE,
                );
                this.evergreenFlexSubscriptions = this.subscriptions?.filter(
                    subscription => subscription.programType === ProgramType.EVERGREEN_FLEX,
                );
                this.evergreenFlexLicenses = this.allLicenses?.filter(
                    license => license.subscription.program_type === ProgramType.EVERGREEN_FLEX,
                );

                //DRaaS subscription related fields
                this.draasSubscriptions = this.subscriptions.filter(
                    subscription => subscription.programType === ProgramType.PURE_PROTECT,
                );
                this.draasAllLicenses = this.allLicenses.filter(
                    license => license.subscription.program_type === ProgramType.PURE_PROTECT,
                );
                // Process licenses
                this.draasGroupedLicenses.clear();
                this.draasAllLicenses?.forEach(license => {
                    const group = this.draasGroupedLicenses.get(license.subscription.id) || [];
                    group.push(license);
                    this.draasGroupedLicenses.set(license.subscription.id, group);
                });

                if (Object.keys(this.filters).length) {
                    const subscriptionIds = subscriptions.map(subscription => subscription.id);
                    this.allLicenses = this.allLicenses.filter(license =>
                        subscriptionIds.includes(license.subscription.id),
                    );
                    this.evergreenOneLicenses = this.evergreenOneLicenses.filter(license =>
                        subscriptionIds.includes(license.subscription.id),
                    );
                    this.evergreenFlexLicenses = this.evergreenFlexLicenses.filter(license =>
                        subscriptionIds.includes(license.subscription.id),
                    );
                    this.draasAllLicenses = this.draasAllLicenses.filter(license =>
                        subscriptionIds.includes(license.subscription.id),
                    );
                }

                // Process map. We don't expect more than one value
                if (!this.typeMapping) {
                    this.typeMapping = typeMap;
                }

                // Process orders
                const inProgressOrdersBySubscriptionId = new Map<string, ServiceCatalogQuote[]>();

                this.allOrders.forEach(order => {
                    // Find last stage with an update (or default to first stage if no updates)
                    let currentQuoteStage: QuoteStage = order.stages?.[0];
                    order.stages?.forEach(stage => {
                        if (stage.lastUpdated) {
                            currentQuoteStage = stage;
                        }
                    });
                    // Check if the current stage is in the list of pending order stages, if so add this order to list
                    // of pending orders for this subscriptionId
                    if (PENDING_ORDER_STAGES.includes(currentQuoteStage?.name)) {
                        if (!inProgressOrdersBySubscriptionId.get(order.subscriptionId)) {
                            inProgressOrdersBySubscriptionId.set(order.subscriptionId, []);
                        }
                        inProgressOrdersBySubscriptionId.get(order.subscriptionId).push(order);
                    }
                });

                this.inProgressOrdersBySubscriptionId = inProgressOrdersBySubscriptionId;

                if (this.subscriptions.length > 0) {
                    this.earliestSubscription = this.subscriptions.reduce((prev, curr) => {
                        if (prev?.startDate.isAfter(curr.startDate)) {
                            return curr;
                        }
                        return prev;
                    }, this.subscriptions[0]);
                    this.evergreenOneEarliestSubscription = this.evergreenOneSubscriptions.reduce(
                        (prev, curr) => (prev?.startDate.isAfter(curr.startDate) ? curr : prev),
                        this.evergreenOneSubscriptions[0],
                    );
                    this.evergreenFlexEarliestSubscription = this.evergreenFlexSubscriptions.reduce(
                        (prev, curr) => (prev?.startDate.isAfter(curr.startDate) ? curr : prev),
                        this.evergreenFlexSubscriptions[0],
                    );
                    this.timeRangeChoices = getTimeRangeChoices(this.earliestSubscription.startDate);
                }

                // Process asset fields based on information we've retrieved (active status and visibility)
                this.allLicenses.forEach(license => {
                    license.licenseAssets.forEach(asset => {
                        // If this asset is not currently active, set fields to null (show up as dashes) since
                        // they are no longer relevant to the "current"
                        const lastActivation = asset.activations[asset.activations.length - 1];
                        if (moment().isAfter(lastActivation?.endDate)) {
                            asset.currentUsage = null;
                            asset.model = null;
                            asset.dataReduction = null;
                        }
                    });
                });

                this.draasAllLicenses.forEach(license => {
                    license.licenseAssets.forEach(asset => {
                        const lastActivation = asset.activations[asset.activations.length - 1];
                        if (moment().isAfter(lastActivation?.endDate)) {
                            asset.currentUsage = null;
                            asset.model = null;
                            asset.dataReduction = null;
                        }
                    });
                });

                this.loading = false;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    protected generateRefresh<T extends Resource>(
        service: GenericService<T>,
        params: ServiceRefreshParams<T>,
    ): Observable<T[]> {
        return smartTimer(0, REFRESH_DELAY.asMilliseconds()).pipe(
            switchMap(() =>
                service.list(params.filters ? { filter: params.filters } : {}).pipe(
                    take(1),
                    map(result => result.response.slice().sort()),
                    catchError((err: HttpErrorResponse) => {
                        console.warn(`${params.serviceName} endpoint returned ${err.error}`);
                        return of([]);
                    }),
                ),
            ),
            distinctUntilChanged((prev, curr) => {
                if (prev?.length !== curr?.length) {
                    return false;
                }

                return prev.every((prevItem, index) =>
                    isEqual(
                        omit(prevItem, IGNORE_FIELDS_WHEN_COMPARING),
                        omit(curr[index], IGNORE_FIELDS_WHEN_COMPARING),
                    ),
                );
            }),
            takeUntil(this.destroy$),
        );
    }

    protected handleRedux(): void {
        const state = this.ngRedux.getState();

        // Handle page filters
        const activeFilters = state.filters[this.barId] || [];

        //Clean-up LICENSE_NAME_KEY and PROGRAM_TYPE_KEY from SUBSCRIPTION_ENTITY in local storage, since we deleted them from spog-utils
        if (activeFilters.find(filter => filter.key === LICENSE_NAME_KEY && filter.entity === SUBSCRIPTION_ENTITY)) {
            this.ngRedux.dispatch([removeFiltersByKey(this.barId, LICENSE_NAME_KEY, null)]);
            return;
        }
        if (activeFilters.find(filter => filter.key === PROGRAM_TYPE_KEY && filter.entity === SUBSCRIPTION_ENTITY)) {
            this.ngRedux.dispatch([removeFiltersByKey(this.barId, PROGRAM_TYPE_KEY, null)]);
            return;
        }

        const consolidatedFilters = consolidateFilterValues(activeFilters, this.wildcardFields);

        const newFilters = prepForDataLayer(
            consolidatedFilters.filter(filter => filter.entity === SUBSCRIPTION_ENTITY),
        );
        if (!isEqual(this.filters, newFilters)) {
            this.filters = newFilters;
            this.updateResourceService.getUpdateSubscriptions().next();
        }

        const newLicenseFilters = prepForDataLayer(
            consolidatedFilters.filter(filter => filter.entity === LICENSE_ENTITY),
        );
        if (!isEqual(this.licenseFilters, newLicenseFilters)) {
            this.licenseFilters = newLicenseFilters;
            this.updateResourceService.getUpdateLicenses().next();
        }
    }

    private getAllSubscriptionsForSpecifiedProgramTypes(): void {
        this.subscriptionService
            .list({ filter: appendProgramTypeFilters({}, this.programTypeFilters) })
            .subscribe(result => {
                this.allSubscriptionsForSpecifiedProgramTypes = result.response;
            });
    }
}
