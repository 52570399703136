import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FilterParams, Invoice, InvoiceStatus, SortParams } from '@pure1/data';
import { first } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RangeFilters } from '../invoice-management-range-filters/invoice-management-range-filters.component';
import { ExportOptionIds } from '../../../export/export-button/export-button.component';
import moment from 'moment/moment';
import { ampli } from 'core/src/ampli';

const PAGE_SIZES = [25, 50, 100];

@Component({
    selector: 'invoice-management-table',
    templateUrl: './invoice-management-table.component.html',
})
export class InvoiceManagementTableComponent implements OnChanges {
    @ViewChild('invoiceExportModal', { static: true }) readonly invoiceExportModal: TemplateRef<any>;

    @Input() readonly data: Invoice[] = [];
    @Input() readonly itemsPerPage: number;
    @Input() readonly offset: number;
    @Input() readonly totalItemCount: number;
    @Input() readonly totalUnfiltered: number;
    @Input() readonly selectedRangeFilters: RangeFilters = { startRange: null, endRange: null };
    @Input() readonly filter: FilterParams<Invoice>;
    @Input() readonly sort: SortParams;

    @Output() readonly pageChange = new EventEmitter<number>();
    @Output() readonly itemsPerPageChange = new EventEmitter<number>();
    @Output() readonly timeFilterChange = new EventEmitter<RangeFilters>();
    @Output() readonly sortChange = new EventEmitter<SortParams>();

    readonly pageSizes = PAGE_SIZES;
    readonly listViewExportOptions: IExportButtonOption[] = [
        { id: ExportOptionIds.filtered, text: 'Export filtered invoices', count: null },
        { id: ExportOptionIds.all, text: 'Export all invoice', count: null },
    ];
    readonly ampli = ampli;

    selectedInvoice: Invoice;
    selectedExportOption: IExportButtonOption;
    invoiceFilter: string;

    constructor(private ngbModal: NgbModal) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data?.length > 0 && !this.selectedInvoice) {
            this.selectedInvoice = this.data[0];
        }
    }

    onPageChange(page: number): void {
        this.pageChange.emit(page);
    }

    onItemsPerPageChange(itemsPerPage: number): void {
        this.itemsPerPageChange.emit(itemsPerPage);
    }

    onSelectionChange(invoices: Invoice[]): void {
        this.selectedInvoice = first(invoices);
    }

    onClickExport(option: IExportButtonOption): void {
        this.selectedExportOption = option;
        this.ngbModal.open(this.invoiceExportModal);
    }

    onTimeFilterChanges(timeRangeOption: RangeFilters): void {
        this.ampli.invoiceManagementSelectedTimeRangeFilter();
        this.timeFilterChange.emit(timeRangeOption);
    }

    onSortChange(sort: SortParams): void {
        this.sortChange.emit(sort);
    }

    updateExportOptionCounts(optionsMap: Map<number, IExportButtonOption>): void {
        const filteredOpt = optionsMap.get(ExportOptionIds.filtered);
        this.updateExportOption(filteredOpt, this.totalItemCount);
        const filters = Object.assign({}, this.filter);
        this.invoiceFilter = Object.values(filters).join(' and ');

        if (this.totalUnfiltered !== null) {
            const allOpt = optionsMap.get(ExportOptionIds.all);
            this.updateExportOption(allOpt, this.totalUnfiltered);
        }
    }

    isOpenedInvoiceExpired(dueData: moment.Moment, status: InvoiceStatus): boolean {
        return status !== InvoiceStatus.PAID_IN_FULL && moment.utc().isAfter(dueData);
    }

    private updateExportOption(option: IExportButtonOption, count: number): void {
        option.disabled = count === 0;
        // Don't want to show 0, just disable it
        option.count = count ? count : null;
    }
}
