import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { FileSystemSnapshot } from '../models/file-system-snapshot';

@Injectable({ providedIn: 'root' })
export class FileSystemSnapshotService extends GenericService<FileSystemSnapshot> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: FileSystemSnapshot,
            endpoint: '/rest/v4/file-system-snapshots',
        });
    }
}
