import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { MapModalComponent } from './map-modal.component';

// TODO: Remove this service, and use the more "modern" modal approach that supports angular bindings (eg see VMAnalyticsReportModalComponent in VMAnalyticsViewComponent)
@Injectable() // Cannot be provided in root since it references the component to open in the modal
export class MapModalService {
    constructor(
        private modalService: NgbModal,
        private router: Router,
    ) {}

    open(message: string): void {
        const modalRef = this.modalService.open(MapModalComponent);
        modalRef.componentInstance.message = message;
        modalRef.result
            .then(() => {
                this.router.navigate(['/fleet/appliances']);
            })
            .catch(() => {
                this.router.navigate(['/fleet/appliances']);
            });
    }
}
