import { Pipe, PipeTransform } from '@angular/core';
import { ProgramType, SubscriptionAsset } from '@pure1/data';

@Pipe({
    name: 'assetManagementRouterState',
    pure: true,
})
export class AssetManagementRouterStatePipe implements PipeTransform {
    transform(asset: SubscriptionAsset): string | undefined {
        switch (asset.subscription.programType) {
            case ProgramType.EVERGREEN_ONE:
                return '/fleet/subscriptions/evergreen-one';
            case ProgramType.EVERGREEN_FOREVER:
            case ProgramType.EVERGREEN_FOUNDATION:
                return '/fleet/subscriptions/evergreen-forever-foundation';
            default:
                return undefined;
        }
    }
}
