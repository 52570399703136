import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationServiceResolver } from '@pure/authz-authorizer';
import { CurrentUser, FeatureFlagDxpService, StepUpError, StepUpFactor } from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import moment from 'moment';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CachedStepUpService } from '../../data/services/cached-step-up.service';
import { FeatureNames } from '../../model/FeatureNames';
import { ToastService, ToastType } from '../../services/toast.service';
import { StepUpModalService } from '../services/step-up-modal.service';
import { StepUpSupportService } from '../services/step-up-support.service';

@Component({
    selector: 'account-security-section',
    templateUrl: 'account-security-section.component.html',
})
export class AccountSecuritySectionComponent implements OnInit, OnDestroy {
    @Input() readonly currentUser: CurrentUser;

    loading = true;
    stepUpLockedOut: boolean;
    emailFactor: StepUpFactor;
    smsFactor: StepUpFactor;
    totpFactor: StepUpFactor;
    smsFactorExpirationDays: number;
    isImpersonating = false;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private angulartics: Angulartics2,
        private stepUpSupportService: StepUpSupportService,
        private cachedStepUpService: CachedStepUpService,
        private toast: ToastService,
        private stepUpModalService: StepUpModalService,
        private featureFlagDxpService: FeatureFlagDxpService,
        private authzServiceResolver: AuthorizationServiceResolver,
    ) {}

    ngOnInit() {
        this.smsFactorExpirationDays = 0;
        this.authzServiceResolver
            .getDefaultService()
            .pipe(
                switchMap(service => service.hasPermission('PURE1:read:authentication_details')),
                takeUntil(this.destroy$),
                tap(canReadAuthDetails => {
                    this.isImpersonating = !canReadAuthDetails;
                }),
                switchMap(() => this.cachedStepUpService.getFactorsV2()),
            )
            .subscribe({
                next: factors => {
                    this.sortStepUpFactorsByType(factors);
                    this.loading = false;
                },
                error: error => {
                    if (error.error === 'locked_out') {
                        this.stepUpLockedOut = true;
                    } else {
                        this.toast.add(
                            ToastType.error,
                            'There was an error getting authentication details, please try again.',
                        );
                        console.error(error);
                    }
                    this.loading = false;
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    private sortStepUpFactorsByType(factors: StepUpFactor[]): void {
        this.totpFactor = factors.find(factor => factor.factorType === 'totp' && factor.status === 'ACTIVE');
        this.smsFactor = factors.find(factor => factor.factorType === 'sms' && factor.status === 'ACTIVE');
        this.emailFactor = factors.find(factor => factor.factorType === 'email' && factor.status === 'ACTIVE');
    }

    disableStepUp(): void {
        this.stepUpModalService
            .openDisableWarningModal()
            .pipe(
                switchMap(() => this.stepUpModalService.stepUp('auth-portal', [{ reset: 'totp' }], true)),
                switchMap(verifyResponse =>
                    this.cachedStepUpService.stepUpResetV2(this.totpFactor.id, verifyResponse.accessToken),
                ),
            )
            .subscribe({
                error: (error: StepUpError) => {
                    if (error.error === 'cancelled') {
                        return;
                    }
                    console.warn(error);
                    this.toast.add(ToastType.error, 'Failed to disable step up authentication.');
                },
            });
    }

    stepUpContactSupportToUnlock(): void {
        this.stepUpSupportService.openStepUpUnlockRequestCase();
    }

    stepUpContactSupportForLostPhone(): void {
        this.stepUpSupportService.openStepUpLostPhoneCase();
    }
}
