import {
    Component,
    ElementRef,
    OnChanges,
    OnDestroy,
    Input,
    SimpleChanges,
    AfterViewInit,
    Inject,
} from '@angular/core';
import { SupportCase } from '../support.interface';
import { WINDOW } from '../../app/injection-tokens';

@Component({
    selector: 'pure-support-nav-cases-list',
    templateUrl: './support-nav-cases-list.component.html',
})
export class SupportNavCasesListComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Input() cases: SupportCase[];
    @Input() selectedCaseId: string;
    @Input() anyCases: boolean;

    private timeout: any;

    constructor(
        private elementRef: ElementRef,
        @Inject(WINDOW) private window: Window,
    ) {}

    ngAfterViewInit(): void {
        if (this.selectedCaseId) {
            const idx = this.findCaseIndexById(this.cases, this.selectedCaseId);
            if (idx !== -1) {
                this.scrollTo(idx);
            }
        }
    }

    ngOnChanges(changesObj: SimpleChanges): void {
        // If the selected case just appeared, scrolled to it (ie: the case got closed, or reopened)
        if (this.selectedCaseId && changesObj.cases) {
            const idxPrevious = this.findCaseIndexById(changesObj.cases.previousValue, this.selectedCaseId);
            const idxCurrent = this.findCaseIndexById(changesObj.cases.currentValue, this.selectedCaseId);
            if (idxPrevious === -1 && idxCurrent !== -1) {
                this.scrollTo(idxCurrent);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    private findCaseIndexById(cases: SupportCase[], findId: string): number {
        if (!cases || !Array.isArray(cases)) {
            return -1;
        }
        return cases.findIndex(supportCase => supportCase.id === findId);
    }

    private scrollTo = (idx: number): void => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = this.window.setTimeout(() => {
            const query = this.elementRef.nativeElement.querySelectorAll('li.support-nav-case');
            query[idx].scrollIntoView({ behavior: 'smooth' });
        }, 0);
    };
}
