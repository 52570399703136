import moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Subscription } from '../models/subscription';

@Injectable({ providedIn: 'root' })
export class SubscriptionService extends GenericService<Subscription> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Subscription,
            endpoint: '/rest/v4/subscriptions',
        });
    }
}
