<app-ngx-d3-liquid-fill-gauge
    [value]="percentage"
    [circleColor]="gaugeColor"
    [circleFillGap]="0"
    [circleThickness]="0.05"
    [textColor]="'#C5C5C5'"
    [valueCountUp]="false"
    [waveAnimate]="false"
    [waveColor]="gaugeColor"
    [waveRise]="false"
    [waveTextColor]="'#FFF'"
    [size]="size"
>
</app-ngx-d3-liquid-fill-gauge>
