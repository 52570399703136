import { StorageEntity } from './storage-entity';
import { VolumePerformanceAggregation } from './volume-performance-aggregation';

export class VolumePerformance extends StorageEntity {
    aggregation: VolumePerformanceAggregation;
    array_id: string;
    core_id: string;

    constructor(json: any) {
        super(json);
        if (json.aggregation) {
            this.aggregation = new VolumePerformanceAggregation(json.aggregation);
        }

        if (json.array_id) {
            this.array_id = json.array_id;
        }
        if (json.core_id) {
            this.core_id = String(json.core_id);
        }
    }
}
