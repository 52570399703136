<div class="drawer-main-content" [ngClass]="{ 'add-padding': selectedArrays.size > 0 && !showUpdateForm }" #mainContent>
    <ng-container *ngIf="!showUpdateForm; else updateAddress">
        <div *ngFor="let arrayList of arrayLists; trackBy: trackByArrayIds">
            <contacts-array-list
                [arrayList]="arrayList"
                [disableEditAll]="selectedArrays.size > 0"
                (arraySelect)="onArraySelect($event)"
                (arrayDeselect)="onArrayDeselect($event)"
                (editAll)="onEditAll($event)"
            >
            </contacts-array-list>
        </div>
    </ng-container>
    <ng-template #updateAddress>
        <update-address
            [selectedArraysList]="selectedArraysList"
            (closeUpdateForm)="onCloseUpdateForm()"
            (confirmAddressChange)="onConfirmAddressChange($event)"
        ></update-address>
    </ng-template>
</div>
<div class="close-drawer-button" [ngClass]="{ shift: drawerOpened }" (click)="closeIconClick()">
    <pureui-svg class="close-drawer-icon" [svg]="'detailed_cancel_cross.svg'" [height]="12"></pureui-svg>
</div>
<div
    class="update-address-button"
    [ngClass]="{ 'show-button': selectedArrays.size > 0 && !showUpdateForm }"
    (click)="updateButtonClicked()"
>
    <div class="update-button-contents">
        <pureui-svg class="update-address-icon" [svg]="'edit.svg'" [height]="15"></pureui-svg>
        UPDATE ADDRESS
    </div>
</div>
