import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UnifiedArray } from '@pure1/data';
import { FBlade, FArray, IArrayHealth } from '../../../model/model';

@Component({
    selector: 'array-expanded-card-health',
    templateUrl: 'array-expanded-card-health.component.html',
})
export class ArrayExpandedCardHealthComponent implements OnChanges {
    @Input() readonly array: UnifiedArray;
    @Input() readonly arrayHealth: IArrayHealth;
    @Input() readonly isExpanded: boolean;

    isSupported: boolean;

    isFBlade(): boolean {
        return this.array instanceof FBlade;
    }

    isFArray(): boolean {
        return this.array instanceof FArray;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array) {
            this.isSupported = this.isVersionSupported(this.array?.version);
        }
    }

    isVersionSupported(version: string): boolean {
        if (!version) {
            return false;
        }

        if (!this.isFArray()) {
            return true;
        }

        try {
            const [major, minor] = version
                .match(/^(\d*)\.(\d*)/i)
                .slice(1)
                .map(Number);
            return major > 4 || (major === 4 && minor > 1);
        } catch (e) {
            return false;
        }
    }
}
