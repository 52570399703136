import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'podPromotionStatus' })
export class PodPromotionStatusPipe implements PipeTransform {
    transform(status: string): string {
        switch (status) {
            case 'demoted':
                return 'Demoted';
            case 'demoting':
                return 'Demoting';
            case 'promoted':
                return 'Promoted';
            case 'promoting':
                return 'Promoting';
            case 'unknown':
                return 'Unknown';
            default:
                console.warn('unknown podPromotionStatus: ' + status);
                return 'Unknown';
        }
    }
}
