import { Resource } from '../interfaces/resource';

export class Contact implements Resource {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;

    constructor(contact: any) {
        this.id = contact.id;
        this.email = contact.email;
        this.firstName = contact.firstName;
        this.lastName = contact.lastName;
        this.name = contact.name;
    }
}
