import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum EmsPhonebookOrgValidationStatus {
    UNVALIDATED = 'unvalidated',
    UNDER_REVIEW = 'underReview',
    VALIDATED = 'validated',
    NO_FA_WITH_SUPPORTED_PURITY_VERSION = 'noFaWithSupportedPurityVersion', // org has some Fa but none with min version of 6.2.10 or 6.3.1
    NO_FA = 'noFa', // org has no FA
}

@Injectable({ providedIn: 'root' })
export class EdgeServiceStatusService {
    private static readonly EMS_ORG_URL = '/rest/v1/ems/org';

    constructor(protected http: HttpClient) {}

    getOrgStatus(orgId: number): Observable<EmsPhonebookOrgValidationStatus> {
        return this.http
            .get(`${EdgeServiceStatusService.EMS_ORG_URL}/validation-status`, {
                responseType: 'text',
                params: {
                    filter: `org_id=${orgId}`,
                },
            })
            .pipe(
                map((response: string) =>
                    this.isPhonebookOrgValidationStatus(response)
                        ? response
                        : EmsPhonebookOrgValidationStatus.UNVALIDATED,
                ),
            );
    }

    private isPhonebookOrgValidationStatus(status: string): status is EmsPhonebookOrgValidationStatus {
        return Object.values(EmsPhonebookOrgValidationStatus).includes(status as EmsPhonebookOrgValidationStatus);
    }
}
