import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { ServiceCatalogGroup } from '../models/service-catalog-group';

@Injectable({ providedIn: 'root' })
export class ServiceCatalogService extends GenericService<ServiceCatalogGroup> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ServiceCatalogGroup,
            endpoint: '/rest/v3/service_catalog',
        });
    }
}
