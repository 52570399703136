import _ from 'lodash';
import moment from 'moment';
import { Subject, Subscription, of, from } from 'rxjs';
import { takeUntil, exhaustMap, catchError, take } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { UnifiedArray, ServiceCatalogQuote } from '@pure1/data';

import { getPageBaseContentElem, infiniteScrollFillContainer } from '../../utils/dom';
import { AssetSortRules, SortRules } from '../services/asset-sort-rules.service';
import { ArraysManager, IGetHealthDataResult } from '../../services/arrays-manager.service';
import { AppliancesViewMode } from '../appliances-view/appliances-view.component';
import { IArrayHealth } from '../../model/model';
import { smartTimer } from '@pstg/smart-timer';

const HEALTH_UPDATE_FREQUENCY = moment.duration(30, 'seconds');
const INITIAL_ITEM_COUNT = 10;
const BACKEND_BATCH_COUNT = 50;

@Component({
    selector: 'arrays-expanded-card-view',
    templateUrl: 'arrays-expanded-card-view.component.html',
})
export class ArraysExpandedCardViewComponent implements OnChanges, OnDestroy {
    @Input() readonly filteredArrays: UnifiedArray[];
    @Input() readonly alertMap: Map<string, IAlert[]>;
    @Input() readonly isLoading: boolean;
    @Input() readonly allOrders: ServiceCatalogQuote[] = [];
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true;
    @Input() readonly mode: AppliancesViewMode;

    sortRules: SortRules<UnifiedArray>;
    displayedItems: UnifiedArray[] = [];
    baseContentElem: HTMLElement;

    private readonly arrayHealthData = new Map<string, IArrayHealth>();
    private readonly arrayHealthSubscriptions = new Map<string, Subscription>();
    private readonly isFlipped = new Map<string, boolean>();
    private readonly isAnimated = new Map<string, boolean>();
    private readonly isExpanded = new Map<string, boolean>();
    private numItemsToShow = INITIAL_ITEM_COUNT;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private assetSortRules: AssetSortRules,
        private arraysManager: ArraysManager,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // Cache the elem. Must be done in ngOnChanges() since its used in here, and this gets called before ngOnInit().
        this.baseContentElem = this.baseContentElem || getPageBaseContentElem();

        if (changes.mode) {
            switch (this.mode) {
                case 'array':
                    this.sortRules = this.assetSortRules.getArraySort(() => this.alertMap);
                    break;
                default:
                    this.sortRules = null;
                    break;
            }
        }

        if (changes.filteredArrays) {
            const filteredArrayDifference =
                changes.filteredArrays.firstChange ||
                !_.isEqual(
                    changes.filteredArrays.previousValue.map(array => array.id),
                    changes.filteredArrays.currentValue.map(array => array.id),
                );
            if (filteredArrayDifference) {
                // anytime we change filteredArrays, cancel all subscriptions,
                // clear the map, and try again
                Array.from(this.arrayHealthSubscriptions.values())
                    .filter(subscription => subscription && !subscription.closed)
                    .forEach(subscription => {
                        subscription.unsubscribe();
                    });
                this.arrayHealthSubscriptions.clear();
            }
            this.updateDisplayedItems();

            infiniteScrollFillContainer(
                () => this.filteredArrays && this.numItemsToShow < this.filteredArrays.length,
                () => this.infiniteScrollAddMore(),
                this.baseContentElem,
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    isCardFlipped(item: UnifiedArray): boolean {
        return this.isFlipped.get(item.id) === true;
    }

    isCardAnimated(item: UnifiedArray): boolean {
        return this.isAnimated.get(item.id) === true;
    }

    flipCard(item: UnifiedArray, event: MouseEvent): void {
        this.isAnimated.set(item.id, true); // Animate the card flip
        this.isFlipped.set(item.id, !this.isCardFlipped(item)); // Reverse the flipped state
        event.stopPropagation();
        event.preventDefault();
    }

    getAlerts(item: UnifiedArray): IAlert[] {
        return this.alertMap?.get(item.id) || [];
    }

    getHealth(item: UnifiedArray): IArrayHealth {
        return this.arrayHealthData.get(item.id);
    }

    isCardExpanded(item: UnifiedArray): boolean {
        return this.isExpanded.get(item.id) === true;
    }

    setExpandedState(item: UnifiedArray, isExpanded: boolean): void {
        this.isExpanded.set(item.id, isExpanded);
    }

    infiniteScrollAddMore(): void {
        this.numItemsToShow += INITIAL_ITEM_COUNT;
        this.updateDisplayedItems();
    }

    private updateDisplayedItems(): void {
        this.displayedItems = this.filteredArrays.slice(0, this.numItemsToShow);
        this.ngZone.runOutsideAngular(() => {
            const chunkedIds = _.chunk(
                this.filteredArrays
                    // we get more ids than are currently displayed so that the data is ready when the card needs to be rendered
                    .slice(0, this.numItemsToShow + INITIAL_ITEM_COUNT)
                    .map(item => item.id),
                BACKEND_BATCH_COUNT,
            );

            chunkedIds.forEach(ids => {
                const concattedIds = ids.join(',');

                if (!this.arrayHealthSubscriptions.has(concattedIds)) {
                    // iterate through existing subscriptions, removing any keys who are prefixes of the new key
                    // (i.e. were started before BACKEND_BATCH_COUNT was reached)
                    this.arrayHealthSubscriptions.forEach((subscription, arrayIds, map) => {
                        if (concattedIds.startsWith(arrayIds)) {
                            subscription.unsubscribe();
                            map.delete(arrayIds);
                        }
                    });
                    // Then setup the next subscription
                    const subscription = smartTimer(0, HEALTH_UPDATE_FREQUENCY.asMilliseconds())
                        .pipe(
                            // don't subscribe to a new observable until the old one has returned (just in case it takes longer than 30s)
                            exhaustMap(() =>
                                from(this.arraysManager.getHealthData(ids)).pipe(
                                    take(1),
                                    catchError(err => {
                                        console.error('arraysManager.getHealthData() failed', err);
                                        return of<IGetHealthDataResult>(null);
                                    }),
                                ),
                            ),
                            takeUntil(this.destroy$),
                        )
                        .subscribe(getHealthDataResult => {
                            if (!getHealthDataResult) {
                                return; // Silently ignore errors when failing to auto-update
                            }

                            Object.keys(getHealthDataResult).forEach(arrayId => {
                                this.arrayHealthData.set(arrayId, getHealthDataResult[arrayId]);
                            });
                            this.cdr.markForCheck();
                        });

                    this.arrayHealthSubscriptions.set(concattedIds, subscription);
                }
            });
        });
    }
}
