import { Theme } from '../ui/styles/theme';

export const SEVERITY_OTHER = 3;
export const SEVERITY_INFO = 2;
export const SEVERITY_WARNING = 1;
export const SEVERITY_CRITICAL = 0;

export const SEVERITY_MOST_SEVERE = SEVERITY_CRITICAL;
export const SEVERITY_LEAST_SEVERE = SEVERITY_OTHER;

interface IColorValue {
    color: string;
    value: number;
}

const severityMap: { [severity: string]: IColorValue } = {
    critical: {
        color: Theme.red,
        value: SEVERITY_CRITICAL,
    },
    warning: {
        color: Theme.yellow,
        value: SEVERITY_WARNING,
    },
    info: {
        color: Theme.blue,
        value: SEVERITY_INFO,
    },
    other: {
        color: Theme.gray,
        value: SEVERITY_OTHER,
    },
};

export function severity(severity: string): IColorValue {
    return severityMap[severity] || severityMap.other;
}
