<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(survey.surveyId)"
>
</feature-banner>

<!-- The try-button class is styled in feature-banner -->
<ng-template #npsButtonTemplate>
    <div class="btn btn-primary try-button" (click)="openFeedbackForm()"> Take the survey </div>
</ng-template>
