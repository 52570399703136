import { DraasApiCloudFormationTemplate } from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';

export class DisasterRecoveryCloudFormationTemplate {
    readonly s3PresignedUrl: string;
    readonly s3PresignedUrlExpiration: Date;
    readonly templateJsonHash: string;
    readonly hashAlgorithm: string;

    constructor(json: DraasApiCloudFormationTemplate) {
        this.s3PresignedUrl = json.s3PresignedUrl;
        this.s3PresignedUrlExpiration = moment.utc(json.s3PresignedUrlExpiration).toDate();
        this.templateJsonHash = json.templateJsonHash;
        this.hashAlgorithm = json.hashAlgorithm;
    }
}
