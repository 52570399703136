import { podStatus } from '@pure1/data';
import { Pipe, PipeTransform } from '@angular/core';

export interface PodStatusUI {
    label: string;
    svg: string;
}

@Pipe({
    name: 'podStatus',
})
export class PodStatusPipe implements PipeTransform {
    transform(status: podStatus | string): PodStatusUI {
        switch (status) {
            case 'critical':
                return { label: 'Not in sync', svg: 'pod-offline.svg' };
            case 'healthy':
                return { label: 'In sync', svg: 'pod-online.svg' };
            case 'warning':
                return { label: 'Resyncing', svg: 'pod-warning.svg' };
            default:
                console.warn(`Unrecognized pod status: ${status}`);
                return { label: 'Unknown', svg: 'pod-online.svg' };
        }
    }
}
