import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from './generic.service';
import { SustainabilityArray } from '../models/sustainability-array';
import { ListParams } from '../interfaces/list-params';
import { DataPage } from '../interfaces/data-page';

const defaultParams = {
    pageStart: 0,
    pageSize: 1000,
    extra: 'applianceTypes=FA&applianceTypes=FB',
};

@Injectable({ providedIn: 'root' })
export class SustainabilityService extends GenericService<SustainabilityArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SustainabilityArray,
            defaultParams: defaultParams,
            endpoint: '/rest/v1/sustainability',
        });
    }

    list(params?: ListParams<SustainabilityArray>): Observable<DataPage<SustainabilityArray>> {
        return super.list({ ...params, sort: undefined });
    }
}
