import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { AllowListedAccountLabel } from '../models/AllowListedAccountLabel';

@Injectable({ providedIn: 'root' })
export class AllowListAccountLabelService extends GenericService<AllowListedAccountLabel> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: AllowListedAccountLabel,
            endpoint: '/rest/v3/cloud_accounts/labels',
            create: true,
            delete: true,
        });
    }
}
