import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { WINDOW } from '../../app/injection-tokens';

const DOWNLOAD_ENDPOINT = '/rest/v5/quotes/download-url';

const UPLOAD_ENDPOINT = '/rest/v5/quotes/upload-url';

export type DownloadUrlParamIdType = 'PO' | 'QUOTE';

interface UrlResponse {
    url: string;
}

@Injectable({ providedIn: 'root' })
export class QuoteUrlService {
    constructor(
        @Inject(WINDOW) private window: Window,
        private http: HttpClient,
    ) {}

    getDownloadUrl(salesFlowId: string, id: string, type: DownloadUrlParamIdType = 'QUOTE'): Observable<string> {
        return this.http
            .get<UrlResponse>(DOWNLOAD_ENDPOINT, {
                params: {
                    salesFlowId: salesFlowId,
                    id: id,
                    type: type,
                },
            })
            .pipe(map(response => response.url));
    }

    openNewWindowOnDownloadUrlWith302Response(
        salesFlowId: string,
        id: string,
        type: DownloadUrlParamIdType = 'QUOTE',
    ): void {
        const urlToUse = this.generateDownloadPdfUrlWith302Response(salesFlowId, id, type);
        this.window.open(urlToUse, '_blank');
    }

    generateDownloadPdfUrlWith302Response(
        salesFlowId: string,
        id: string,
        type: DownloadUrlParamIdType = 'QUOTE',
    ): string {
        if (!salesFlowId || !id) {
            return '';
        }

        let urlToReturn = this.window.location.origin + DOWNLOAD_ENDPOINT;

        urlToReturn += '?salesFlowId=' + salesFlowId;
        urlToReturn += '&id=' + id;
        urlToReturn += '&type=' + type;

        return urlToReturn;
    }

    getUploadUrl(salesFlowId: string, contentLengthInBytes: number, md5Hash: string): Observable<string> {
        return this.http
            .post<UrlResponse>(UPLOAD_ENDPOINT, {
                id: salesFlowId,
                content_length: contentLengthInBytes,
                md5: md5Hash,
            })
            .pipe(map(response => response.url));
    }
}
