import { MetricAnnotation } from './metric-annotation';

export class PodMetricAnnotation extends MetricAnnotation {
    readonly pods: { readonly id: string }[];

    constructor(json: any) {
        super(json);
        this.pods =
            json.pods === null
                ? null
                : json.pods.map(v => {
                      return {
                          id: v.id,
                      };
                  });
    }
}
