export const MAP_STYLE: google.maps.MapTypeStyle[] = [
    {
        // Remove all dotted lines from the map (mainly to remove the world gridlines when it repeats)
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
    },
    {
        // Re-add all the lines pertaining to countries, provinces, etc
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'on' }],
    },
];
