import { Pipe, PipeTransform } from '@angular/core';

/**
 * obfuscates a phone number string with asterisks, showing only the last four digits
 * eg:
 *  input: +1-123-456-7890
 *  output: +*-***-***-7890
 */
@Pipe({ name: 'obfuscatePhoneNumber', pure: true })
export class ObfuscatePhoneNumberPipe implements PipeTransform {
    transform(phoneNumber: string): string {
        if (phoneNumber === null || phoneNumber === undefined || phoneNumber.trim().length === 0) {
            return '';
        } else {
            return `${phoneNumber.substring(0, phoneNumber.length - 4).replace(/[\d]/g, '*')}${phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length)}`;
        }
    }
}
