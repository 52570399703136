import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

const SIZE_OF_K = 1024;

@Pipe({ name: 'formatSize' })
export class FormatSizePipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');
    transform(bytes: number, decimal: number, axisLabel?: boolean, fixUnit?: string): string {
        if (typeof bytes !== 'number' || isNaN(bytes) || bytes < 0) {
            return null;
        }
        decimal = decimal || 0;
        if (axisLabel && bytes <= 0) {
            return '';
        }

        const units = axisLabel ? ['K', 'M', 'G', 'T', 'P'] : ['B', 'K', 'M', 'G', 'T', 'P'];
        if (axisLabel) {
            bytes = bytes / SIZE_OF_K;
        }

        let index = 0;
        const unitIndex = fixUnit ? units.indexOf(fixUnit) : -1;
        if (unitIndex !== -1) {
            while (index < unitIndex) {
                bytes = bytes / SIZE_OF_K;
                index++;
            }
        } else {
            const len = units.length - 1;
            while (bytes / SIZE_OF_K >= 1 && index < len) {
                bytes = bytes / SIZE_OF_K;
                index++;
            }
        }

        const val = FormatSizePipe.decimalPipe.transform(bytes, `1.${decimal}-${decimal}`);
        const unit = unitIndex !== -1 ? '' : bytes > 0 ? units[index] : 'G';

        return `${val} ${unit}`.trim();
    }
}
