<div class="reserve-amount">
    <div class="pure1-common-slider">
        <ngx-slider
            [value]="increaseAmountInUnit"
            (valueChange)="onAmountChange($event)"
            [options]="sliderOptions"
        ></ngx-slider>
    </div>
    <div class="reserve-input">
        <hive-text-field
            numbersOnly
            [maxlength]="maxLength()"
            [ngModel]="increaseAmountInUnit"
            (blur)="resetDefault()"
            (ngModelChange)="onInputAmountChange($event)"
        ></hive-text-field>
    </div>
    <span class="reserve-unit">
        {{ licenseType | licenseReservedUnit }}
    </span>
    <div
        class="arrow-and-percent-increase-container"
        *ngIf="!isNewLicense && increaseAmountInUnit > 0 && currentAmount > 0"
    >
        <span class="arrow-icon">
            <i class="fa fa-arrow-up"></i>
        </span>
        <span class="percent-increase-text">
            {{ increaseAmount / currentAmount | formatPercent }}
        </span>
    </div>
</div>
