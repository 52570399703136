<div
    class="card-container"
    infiniteScroll
    [infiniteScrollContainer]="baseContentElem"
    [immediateCheck]="true"
    (scrolled)="infiniteScrollAddMore()"
>
    <div
        *ngFor="let item of displayedItems; trackBy: 'id' | trackByProperty"
        class="card-div do-not-remove-this-class-nr-card-div"
        [class.flip]="isCardFlipped(item)"
        [class.animated]="isCardAnimated(item)"
    >
        <!-- Array -->
        <array-card-fa-fb
            *ngIf="mode === 'array'"
            [array]="$any(item)"
            [alerts]="getAlerts(item)"
            [allOrders]="allOrders"
            [isCardFlipped]="isCardFlipped(item)"
            [showWarningCardForOutOfSupportAppliance]="showWarningCardForOutOfSupportAppliance"
            (onFlipCard)="flipCard(item, $event.event)"
            attr.data-pw="{{ FLEET_LOCATORS.card(item.name) }}"
        >
        </array-card-fa-fb>
    </div>
</div>
