import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { ProgramType } from './subscription';
import { EolHardware, EverModernUpgrade } from './subscription-asset';
import { GenealogyUpcomingRenewal } from './subscription-genealogy';

export class GenealogySoftwareUpgrade {
    version: string;
    date: moment.Moment;

    constructor(json: any) {
        this.version = json.version;
        this.date = json.date ? moment.utc(json.date) : null;
    }
}

export class GenealogyHardwareUpgrade {
    model: string;
    capacity: string;
    raw: number;
    usable: number;
    estimated_capacity: number;
    date: moment.Moment;

    constructor(json: any) {
        this.model = json.model;
        this.capacity = json.capacity;
        this.date = json.date ? moment.utc(json.date) : null;
        this.raw = json.raw;
        this.usable = json.usable;
        this.estimated_capacity = json.estimated_capacity;
    }
}

export class GenealogyArrayCurrentState {
    date: moment.Moment;
    model: string;
    contractType: ProgramType;
    version: string;
    capacity: number;
    percentFull: number;
    raw: number;
    usable: number;
    estimated_capacity: number;

    constructor(json: any) {
        this.date = moment.utc();
        this.model = json.model;
        this.contractType = json.program_type;
        this.version = json.version;
        this.capacity = json.capacity;
        this.percentFull = json.percent_full;
        this.raw = json.raw;
        this.usable = json.usable;
        this.estimated_capacity = json.estimated_capacity;
    }
}

export class GenealogyDispatchEvent {
    deliveryDate: moment.Moment;
    targetModel: string;
    serviceCategory: string;

    constructor(json: any) {
        this.deliveryDate = json.delivery_date ? moment.utc(json.delivery_date) : null;
        this.targetModel = json.target_model;
        this.serviceCategory = json.service_category;
    }
}

export class ArrayGenealogy implements Resource {
    id: string; // array ID
    name: string; // hostname
    applianceInstallation: GenealogyHardwareUpgrade;
    softwareUpgrades: GenealogySoftwareUpgrade[];
    hardwareUpgrades: GenealogyHardwareUpgrade[];
    eolHardware: EolHardware;
    upcomingRenewalEvent: GenealogyUpcomingRenewal;
    currentState: GenealogyArrayCurrentState;
    dispatchEvents: GenealogyDispatchEvent[];
    everModernUpgrade: EverModernUpgrade;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.applianceInstallation = json.installation ? new GenealogyHardwareUpgrade(json.installation) : null;
        this.softwareUpgrades = (json.software_upgrades || []).map(j => new GenealogySoftwareUpgrade(j));
        this.hardwareUpgrades = (json.hardware_upgrades || []).map(j => new GenealogyHardwareUpgrade(j));
        this.eolHardware = json.eol_hardware ? new EolHardware(json.eol_hardware) : null;
        this.upcomingRenewalEvent = json.upcoming_renewal_event?.date
            ? new GenealogyUpcomingRenewal(json.upcoming_renewal_event)
            : null;
        this.currentState = json.current_state ? new GenealogyArrayCurrentState(json.current_state) : null;
        this.dispatchEvents = (json.dispatch_events || []).map(j => new GenealogyDispatchEvent(j));
        this.everModernUpgrade = json.ever_modern_upgrade ? new EverModernUpgrade(json.ever_modern_upgrade) : null;
    }
}
