<div class="modal-header">
    <h3 class="modal-title"> Export Quotes </h3>
</div>

<div class="modal-body">
    <!-- File name -->
    <div class="form-group row">
        <label class="col-xs-3 col-form-label text-xs-right" for="export-filename"> Name </label>
        <div class="col-xs-9 filename-input">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    id="export-filename"
                    [(ngModel)]="fileNameEntry"
                    [placeholder]="exportRequest.fileName"
                    maxlength="60"
                />
                <span class="input-group-addon">.zip</span>
            </div>
        </div>
    </div>

    <!-- Data to export -->
    <div class="form-group row">
        <label class="col-xs-3 text-xs-right"> Data </label>
        <div class="col-xs-9 item-count">
            {{ entityCountMessage }}
        </div>
    </div>
</div>

<div class="modal-footer btn-toolbar">
    <button type="button" id="cancelButton" class="btn btn-info" (click)="modal.close()"> Cancel </button>

    <button type="button" id="applyButton" class="btn btn-primary" (click)="clickApply()" [disabled]="loading">
        Export
    </button>
</div>
