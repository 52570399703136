import { Resource } from '../interfaces/resource';

export class LicenseReference {
    readonly licenseId: string;
    readonly licenseName: string;

    constructor(json: any) {
        this.licenseId = json.license_id;
        this.licenseName = json.license_name;
    }
}

export class LicenseLevelAggregation implements Resource {
    readonly id: string;
    readonly name: string;

    readonly location: string;
    readonly licenseTypeStr: string;
    readonly licenseReference: LicenseReference;
    readonly percentageUsage: number;
    readonly usedUsage: number;
    readonly latestReservedAmount: number;
    readonly onDemandDays: number;
    readonly onDemandUsage: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = this.id;
        this.location = json.location;
        this.licenseTypeStr = json.license_type;
        this.licenseReference = json.license ? new LicenseReference(json.license) : null;
        this.percentageUsage = json.percentage_usage;
        this.usedUsage = json.used_usage;
        this.latestReservedAmount = json.latest_reserved_amount;
        this.onDemandDays = json.on_demand_days;
        this.onDemandUsage = json.on_demand_usage;
    }
}
