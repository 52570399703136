import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseBanner } from '../base-banner';
import { UserNotification } from '../../model/user-notification';

@Component({
    selector: 'x-to-xl-upgrade-banner',
    templateUrl: 'x-to-xl-upgrade-banner.component.html',
})
export class XToXlUpgradeBannerComponent extends BaseBanner implements AfterViewInit {
    populatedNotification: UserNotification = new UserNotification({});

    @ViewChild('xToXlUpgradeTemplate', { static: true }) readonly xToXlUpgradeTemplate: TemplateRef<any>;
    @ViewChild('xToXlUpgradeButtonTemplate', { static: true }) readonly xToXlUpgradeButtonTemplate: TemplateRef<any>;

    ngAfterViewInit(): void {
        const templates = { linksTemplate: this.xToXlUpgradeTemplate, buttonTemplate: this.xToXlUpgradeButtonTemplate };
        this.populatedNotification = Object.assign({}, this.notification, templates);
    }
}
