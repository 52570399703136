import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PhantomArray } from '../models/phantom-array';

export const PHANTOM_ARRAY_ID_PREFIX = 'phantom:';

export function isPhantomArray(array: { id: string }): boolean {
    return array?.id?.startsWith(PHANTOM_ARRAY_ID_PREFIX);
}
@Injectable({ providedIn: 'root' })
export class PhantomArraysService extends GenericService<PhantomArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PhantomArray,
            endpoint: '/rest/v1/forecast/phantom-arrays',
            create: true,
            update: true,
            delete: true,
        });
    }
}
