import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { SortParams } from '../interfaces/list-params';
import { AlertResourceV2 } from '../models/alert-resource-v2';

@Injectable({ providedIn: 'root' })
export class AlertServiceV2 extends GenericService<AlertResourceV2> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: AlertResourceV2,
            endpoint: '/rest/v4/alerts',
            defaultParams: {
                pageStart: 0,
                pageSize: 1000,
                sort: <SortParams>{
                    key: 'body.updated',
                    order: 'asc',
                },
            },
        });
    }
}
