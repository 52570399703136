<div class="address-street-view street-view-not-found" *ngIf="!panorama || !streetViewFound">
    <img class="placeholder-image" src="/images/StreetViewPlaceholder.png" />
</div>
<div class="street-view-container" #streetView></div>
<div class="street-address-container">
    <div class="street-address">{{ arrayList[0].address.street_address || 'Street address not found' }}</div>
    <div
        class="edit-all-button"
        (click)="editAllClicked()"
        [ngClass]="{ disabled: !canViewArray || !areAllArraysInOrg || disableEditAll }"
        (mouseenter)="openEditAllTooltip(editTooltip)"
        (mouseleave)="editTooltip.close()"
        [ngbTooltip]="tooltipMessage"
        placement="top"
        container="body"
        triggers="manual"
        #editTooltip="ngbTooltip"
    >
        <pureui-svg class="update-address-icon" [svg]="'edit.svg'" [height]="15"></pureui-svg>
    </div>
</div>
<div class="array-list">
    <div class="array-list-header">Arrays ({{ arrayList.length }})</div>
    <div
        *ngFor="let array of arrayList; trackBy: 'id' | trackByProperty"
        class="array-element"
        [ngClass]="{
            selected: selectedArrays.has(array),
            disabled: !canViewArray || array.organization.id !== userOrgId
        }"
        (click)="arrayClicked(array)"
        (mouseenter)="openArrayTooltip(arrayTooltip, array)"
        (mouseleave)="arrayTooltip.close()"
        [ngbTooltip]="tooltipMessage"
        placement="top"
        container="body"
        triggers="manual"
        #arrayTooltip="ngbTooltip"
    >
        <input
            type="checkbox"
            [disabled]="!canViewArray || array.organization.id !== userOrgId"
            [checked]="selectedArrays.has(array)"
            class="array-element-checkbox"
        />{{ array.fqdn || array.name }}
    </div>
</div>
<ng-template #tooltipMessage let-message="message">
    {{ message }}
</ng-template>
