import { Product } from './unified-array';
import { Resource } from '../interfaces/resource';

type ApplianceType = 'FA' | 'FB';

export type SafeModeStatusType = 'UPGRADE_REQUIRED' | 'DISABLED' | 'PARTIAL' | 'ENABLED' | 'GRANULAR_ENABLED';

export class DpaSafeMode implements Resource {
    id: string | null;
    applianceID: string;
    name: string;
    product: Product;
    model: string;
    version: string;
    blockSafeModeStatus: SafeModeStatusType | null;
    blockEradicationTimerInDays: number | null;
    fileSafeModeStatus: SafeModeStatusType | null;
    fileEradicationTimerInDays: number | null;
    objectSafeModeStatus: SafeModeStatusType | null;
    objectEradicationTimerInDays: number | null;
    volumes: number;
    volumesProtected: number;

    private applianceType: ApplianceType;

    constructor(response: DpaSafeMode) {
        this.applianceType = response.applianceType;
        this.applianceID = response.applianceID;
        this.name = response.name;
        this.model = response.model;
        this.version = response.version;
        this.model = response.model;
        this.blockSafeModeStatus = response.blockSafeModeStatus;
        this.blockEradicationTimerInDays = response.blockEradicationTimerInDays;
        this.fileSafeModeStatus = response.fileSafeModeStatus;
        this.fileEradicationTimerInDays = response.fileEradicationTimerInDays;
        this.objectSafeModeStatus = response.objectSafeModeStatus;
        this.objectEradicationTimerInDays = response.objectEradicationTimerInDays;
        this.product = response.applianceType === 'FA' ? Product.FA : Product.FB;
        this.volumes = response.volumes;
        this.volumesProtected = response.volumesProtected;

        //Just for satisfying the Resource interface
        this.id = this.applianceID;
    }
}
