<button
    *ngIf="supportCase"
    class="btn btn-secondary"
    (click)="openModal()"
    [class.escalated]="supportCase.isEscalated"
    [disabled]="supportCase.isEscalated"
>
    <pureui-svg
        class="pstg-secondary-icon escalation-icon"
        *ngIf="supportCase.isEscalated"
        svg="escalation.svg"
        height="16"
    ></pureui-svg>
    {{ supportCase.isEscalated ? 'Escalated' : 'Escalate' }}
</button>
