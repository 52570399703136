import moment from 'moment';

export class SfdcPurchaseOrder {
    salesFlowId: string;
    id: string;
    pure1Id: string;
    createdDate: moment.Moment;
    fileName: string;
    contentLength: number;

    constructor(json: any, salesFlowId?: string) {
        this.salesFlowId = salesFlowId;
        this.id = json.id;
        this.pure1Id = json.pure1_id;
        this.createdDate = json.created_date ? moment(json.created_date).utc() : null;
        this.fileName = json.file_name;
        this.contentLength = json.content_length;
    }
}
