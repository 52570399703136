<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<ng-template #autoOnSMLinksTemplate>
    <a
        href="https://support.purestorage.com/FlashArray/FlashArray_Security/SafeMode/Auto-On_SafeMode"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Auto-on SafeMode - KB article"
        class="bold-link"
    >
        Read More
    </a>
    |
    <a
        href="https://support.purestorage.com/FlashArray/FlashArray_Release/01_Purity_FA_Release_Notes/9925_Purity_FA_6.4.x_Release_Notes"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Auto-on SafeMode - Release notes"
        class="bold-link"
    >
        Release Notes
    </a>
</ng-template>

<ng-template #autoOnSMButtonTemplate>
    <a
        *ngIf="notification.path"
        class="btn btn-primary try-button"
        [routerLink]="notification.path"
        [angularticsLabel]="notification.title"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Auto-on SafeMode - Get Started"
    >
        Get Started
    </a>
</ng-template>
