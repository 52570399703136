export enum PlacementRecWarningCode {
    NO_SHARED_HOST = 'NO_SHARED_HOST',
    DIFFERENT_LOCATIONS = 'DIFFERENT_LOCATIONS',
    TARGET_EXCEEDS_VOLUMES_COUNT_LIMIT = 'TARGET_EXCEEDS_VOLUMES_COUNT_LIMIT',
    SOURCE_EXCEEDS_VOLUMES_COUNT_LIMIT = 'SOURCE_EXCEEDS_VOLUMES_COUNT_LIMIT',
    SOURCE_EXCEEDS_STRETCHED_VOLUMES_COUNT_LIMIT = 'SOURCE_EXCEEDS_STRETCHED_VOLUMES_COUNT_LIMIT',
    TARGET_EXCEEDS_STRETCHED_VOLUMES_COUNT_LIMIT = 'TARGET_EXCEEDS_STRETCHED_VOLUMES_COUNT_LIMIT',
    SOURCE_EXCEEDS_PODS_COUNT_LIMIT = 'SOURCE_EXCEEDS_PODS_COUNT_LIMIT',
    TARGET_EXCEEDS_PODS_COUNT_LIMIT = 'TARGET_EXCEEDS_PODS_COUNT_LIMIT',
    SOURCE_EXCEEDS_STRETCHED_PODS_COUNT_LIMIT = 'SOURCE_EXCEEDS_STRETCHED_PODS_COUNT_LIMIT',
    TARGET_EXCEEDS_STRETCHED_PODS_COUNT_LIMIT = 'TARGET_EXCEEDS_STRETCHED_PODS_COUNT_LIMIT',
}

type PlacementObjectives = {
    avg_perf_usage: number;
    avg_cap_usage: number;
    var_perf_usage: number;
    var_cap_usage: number;
    max_perf_usage: number;
    max_cap_usage: number;
};

type ConfidenceInterval = {
    upper: number;
    lower: number;
};

export type PredictedValue = {
    timestamp_ms: number;
    value: number;
    confidence_interval: ConfidenceInterval;
};

export type LoadValues = {
    avg: PredictedValue[];
    blended_max: PredictedValue[];
};

export type WarningPair = {
    warning_code: PlacementRecWarningCode;
    message: string;
};

export type PlacementItem = {
    array_id: string;
    objectives: Partial<PlacementObjectives>; // A backend error returns an empty object
    load_values: LoadValues;
    capacity_values: PredictedValue[];
    days_to_reach_90_percent_capacity: number;
    days_to_reach_100_percent_capacity: number;
    warnings: WarningPair[];
    error: string;
};
