import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafemodeChangeRequest } from '../../services/safemode-multiparty-authorization.service';

@Component({
    selector: 'safemode-enable-scheduled-confirmation-multiparty-auth',
    templateUrl: 'safemode-enable-scheduled-confirmation-multiparty-auth.component.html',
})
export class SafeModeEnableScheduledConfirmationMultipartyAuthComponent {
    @Input() readonly createCaseDoneFlag: boolean;
    @Input() readonly createCaseErrorFlag: boolean;
    @Input() readonly newRequest: SafemodeChangeRequest;
    @Output() readonly onDismiss = new EventEmitter();
}
