import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaseSeverity } from '../support.interface';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'severity-picker',
    templateUrl: 'severity-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SeverityPickerComponent),
            multi: true,
        },
    ],
})
export class SeverityPickerComponent implements ControlValueAccessor {
    readonly ampli = ampli;

    supportCaseSeverity = CaseSeverity;
    hoveredOption: CaseSeverity;
    value: CaseSeverity;
    isDisabled: boolean;
    forceSev1Warning = false;

    onChange = (_: any) => {};

    onTouched = (_: any) => {};

    changeDescriptionType(type?: CaseSeverity): void {
        if (this.hoveredOption === type) {
            this.hoveredOption = null;
        } else {
            this.hoveredOption = type;
        }
        this.forceSev1Warning = false;
    }

    setValueOnKeyUp(severity: CaseSeverity): void {
        this.value = severity;
        this.hoveredOption = null;
        this.emitChanges();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    emitChanges(): void {
        this.ampli.supportCaseModalSeveritySelected({
            label: this.value,
        });
        if (this.value === CaseSeverity.CRITICAL) {
            this.forceSev1Warning = true;
        } else {
            this.forceSev1Warning = false;
        }
        if (this.onChange) {
            this.onChange(this.value);
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(obj: CaseSeverity): void {
        this.value = obj;
    }

    clickSeverity(severity: CaseSeverity): void {
        this.value = severity;
        this.emitChanges();
    }
}
