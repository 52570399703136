import { Component, Input, OnChanges } from '@angular/core';
import { AdditionalRoleAssignment, KnownRoleDomains, RoleType } from '@pure1/data';

/**
 * This is component for showing the legacy(primary) role (pure1 admin/viewer/...) and all aditional roles
 * Please use it everywhere you are showing whole list of all user roles
 */
@Component({
    selector: 'show-all-roles',
    templateUrl: './show-all-roles.component.html',
})
export class ShowAllRolesComponent implements OnChanges {
    @Input() readonly role: RoleType;
    @Input() readonly additionalRoles: AdditionalRoleAssignment[];

    additionalRolesToShow: AdditionalRoleAssignment[] = [];

    ngOnChanges(): void {
        this.additionalRolesToShow = [];
        const hasPendingRemovalSafeModeRole = this.additionalRoles.some(
            role => role.status === 'REMOVAL_PENDING' && role.domain === KnownRoleDomains.SAFE_MODE,
        );

        if (hasPendingRemovalSafeModeRole) {
            this.additionalRolesToShow = this.additionalRoles.filter(
                role => !(role.status === 'ASSIGNED' && role.domain === KnownRoleDomains.SAFE_MODE),
            );
        } else {
            this.additionalRolesToShow = this.additionalRoles;
        }
    }
}
