import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { FormatDurationPipe } from './format-duration.pipe';

const NOT_AVAILABLE = '-';

/**
 * 2015 chosen since this is FB specific and lag dated before then doesn't make sense,
 * but actual lag values should in practice be much more recent
 */

@Pipe({
    name: 'formatReplicationLag',
})
export class FormatReplicationLagPipe implements PipeTransform {
    private formatDurationPipe: FormatDurationPipe = new FormatDurationPipe();
    transform(value: number): string {
        if (value != null && moment(moment().valueOf() - value).isAfter(moment('2015-01-01'))) {
            return this.formatDurationPipe.transform(value, 4, true, 2);
        }
        return NOT_AVAILABLE;
    }
}
