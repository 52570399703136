import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateEditSupportCaseComponent } from './create-edit-support-case.component';
import { UIModule } from 'core/src/ui/ui.module';
import { ProductSectionModule } from '../product-section/product-section.module';
import { ContactSectionModule } from '../contact-section/contact-section.module';
import { SeverityPickerComponent } from '../severity-picker/severity-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        ContactSectionModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ProductSectionModule,
        ReactiveFormsModule,
        UIModule,
    ],
    declarations: [CreateEditSupportCaseComponent, SeverityPickerComponent],
    exports: [CreateEditSupportCaseComponent],
})
export class CreateEditSupportCaseModule {}
