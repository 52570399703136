import moment from 'moment';
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ExportManagerService } from '../services/export-manager.service';
import { FileDownloaderService } from '../services/file-downloader.service';

/**
 * How long we wait before showing a task as completed in the UI. Because we usually auto-download the completed
 * tasks, we don't want to briefly show it as completed before removing it. So to get by this, we hold it in
 * the "in progress" state for a little extra time to give the download time to start.
 * The only disadvantage is that this can give the perception of the export taking longer only in the case
 * that the auto-download doesn't kick off.
 */
const DELAY_BEFORE_SHOWING_COMPLETED_MS = moment.duration(4, 'seconds').asMilliseconds();

/**
 * Limit the number of items we show in the statusbar.
 * No reason there should be this many items except for in staging on accounts used for automated tests.
 */
const MAX_TASKS_TO_SHOW = 75;

@Component({
    selector: 'export-overlay',
    templateUrl: 'export-overlay.component.html',
    providers: [FileDownloaderService],
})
export class ExportOverlayComponent implements OnInit, OnDestroy {
    @Output() readonly overlayVisibleChange = new EventEmitter<boolean>();

    readonly analyticsPrefix = 'Export overlay - ';
    exportTasks: IExportTask[] = [];
    detailsTask = null;

    private readonly destroy$ = new Subject<void>();
    private peakTaskCount = 0;

    /** Set of export ids that we have already attempted to auto-download */
    private readonly attemptedDownloadIds = new Set<exportTaskId>();

    /** The time we first saw a task being shown as completed */
    private readonly taskTimeCompleted = new Map<exportTaskId, moment.Moment>();

    constructor(
        private angulartics2: Angulartics2,
        private exportManager: ExportManagerService,
        private modalService: NgbModal,
        private fileDownloader: FileDownloaderService,
    ) {}

    ngOnInit(): void {
        this.exportManager.activeExportsChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.onExportTasksChanged();
        });

        this.exportManager.overlayVisibilityChange$.pipe(takeUntil(this.destroy$)).subscribe((visible: boolean) => {
            this.overlayVisibleChange.emit(visible);
        });

        this.onExportTasksChanged();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    isVisible(): boolean {
        return this.exportManager.hasActiveExports();
    }

    showTaskAsCompleted(task: IExportTask): boolean {
        if (!task.isCompleted) {
            return false;
        }

        // The whole idea of this is to delay showing the completed state for tasks that we're going to auto-download.
        // Since we don't auto-download if not created this session, we don't need to delay the display.
        if (!task.createdThisSession) {
            return true;
        }

        // If we failed, we can show the failure immediately
        if (task.isFailed) {
            return true;
        }

        const completedTime = this.taskTimeCompleted.get(task.id);
        const timeSinceCompleted = completedTime && moment.duration(moment().valueOf() - completedTime.valueOf());
        return timeSinceCompleted && timeSinceCompleted.asMilliseconds() >= DELAY_BEFORE_SHOWING_COMPLETED_MS;
    }

    clickDownload(modal: TemplateRef<NgbActiveModal>, task: IExportTask): void {
        if (task.isCompleted) {
            // If successful, download as normal. Otherwise, show the details modal.
            if (!task.isFailed) {
                this.download(task);
            } else {
                this.showDetailsModal(modal, task);
            }
        }
    }

    showDetailsModal(modal: TemplateRef<NgbActiveModal>, task: IExportTask): void {
        this.detailsTask = task;
        this.modalService.open(modal, { backdrop: 'static', size: 'sm' });
    }

    private onExportTasksChanged(): void {
        this.exportTasks = this.exportManager
            .getActiveExports()
            .sort((a, b) => b.createdTime.valueOf() - a.createdTime.valueOf())
            .slice(0, MAX_TASKS_TO_SHOW);

        // Auto-download any completed exports that we have not yet attempted to download already
        this.exportTasks.forEach(task => {
            if (task.isCompleted) {
                // Track the time we first saw the task in the completed status
                if (!this.taskTimeCompleted.has(task.id)) {
                    this.taskTimeCompleted.set(task.id, moment());
                }

                // Auto-download completed tasks that we created if we haven't tried downloading them yet
                if (task.createdThisSession && !this.attemptedDownloadIds.has(task.id) && !task.isFailed) {
                    this.download(task);
                    this.angulartics2.eventTrack.next({
                        action: this.analyticsPrefix + 'Auto-download',
                        properties: { category: 'Action' },
                    });
                }
            }
        });

        // If we have >= 2 exports being shown, log the peak amount to give us a rough idea how many items we are showing people
        if (this.exportTasks.length > 1 && this.exportTasks.length > this.peakTaskCount) {
            this.peakTaskCount = this.exportTasks.length;
            this.angulartics2.eventTrack.next({
                action: this.analyticsPrefix + 'Peak task count',
                properties: { category: 'Action', label: this.peakTaskCount.toString() },
            });
        }
    }

    private download(task: IExportTask): void {
        this.attemptedDownloadIds.add(task.id);
        this.exportManager.downloadTask(task, this.fileDownloader);
    }
}
