import moment from 'moment';
import cryptoJS from 'crypto-js';
import { ArrayNameTooltipService } from '../../ui/directives/array-name-tooltip.service';
import { ToastService, ToastType } from '../../services/toast.service';
import { CaseItemDataService } from '../services/case-item.service';
import {
    SupportCaseItem,
    CaseAttachment,
    SupportCaseWithUrl,
    CaseEscalation,
    CaseEmail,
    CaseComment,
    User,
} from '../support.interface';
import { CaseManager } from '../services/case-data.service';
import {
    Component,
    OnInit,
    OnDestroy,
    HostListener,
    ElementRef,
    ViewChild,
    Inject,
    Input,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadModalComponent, Attachment } from '../upload-modal/upload-modal.component';
import { WINDOW } from '../../app/injection-tokens';
import { FileDownloaderService } from '../../export/services/file-downloader.service';
import { DUMMY_SUPPORT_USER } from '../../ui/components/pure-avatar/pure-avatar.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { FileReaderService } from '../services/file-reader.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, mergeMap, switchMap, tap, take, takeUntil } from 'rxjs/operators';
import { SupportLoaderService } from '../services/support-loader.service';
import { CachedCurrentUserService, CurrentUser } from '@pure1/data';
import { SupportContactService } from '../services/support-contact.service';
import { smartTimer } from '@pstg/smart-timer';
import { ampli } from 'core/src/ampli';

/**
 * Size of the chunks we split the file into when uploading attachments
 */
const CHUNK_SIZE = 50 * 1024 * 1024;

/**
 * Number of simultaneously read and uploaded chunks
 */
const NUMBER_OF_SIMULTANEOUS_CHUNK_READS = 4;

interface IFeedItemViewState {
    itemNeedCollapsing?: boolean;
    isItemCollapsed: boolean;
    itemBottomShowing: boolean;
    itemObj: any;
}

@Component({
    selector: 'pure-support-case',
    templateUrl: 'support-case.component.html',
    providers: [FileDownloaderService],
})
export class SupportCaseComponent implements OnInit, OnDestroy, OnChanges {
    readonly ampli = ampli;

    @Input() size: string;
    @Input() limit: any;
    @Input() caseId: string;
    @Input() standalone = false;

    dragoverActive = false;
    currentUser: CurrentUser;
    commentText = '';
    commentTextUtf8Length = 0;
    hasLimit: boolean;
    collapseControls = true;
    submittingComment = false;
    submittingAttachment = false;
    downloadingAttachment = {};
    maxCommentLength = 4000;
    showBackToTopButtonForItemId = '';
    bottomOfItemWithBackToTopButtonShown = false;
    feedItemViewStates: { [itemId: string]: IFeedItemViewState } = {};
    items: (CaseAttachment | CaseComment | CaseEscalation | CaseEmail | SupportCaseItem)[];
    caseDetails: SupportCaseWithUrl;
    refreshIntervalDuration: number;
    markAsReadDebounceDuration: number;
    lastMarkAsReadBrowserTime: moment.Moment;
    sizeClass: string;
    supportUser = DUMMY_SUPPORT_USER;
    caseManagerSubscription: Subscription;
    dragoverTimeout: number = null;
    hasSNOWContact = false;
    snowContact: User;
    @ViewChild('caseFeedItemsWrapper') caseFeedItemsWrapper: ElementRef;
    @ViewChild('inputFile') inputFile: ElementRef;

    private readonly stopTimer$ = new Subject<void>();

    constructor(
        private arrayNameTooltipService: ArrayNameTooltipService,
        private fileDownloader: FileDownloaderService,
        private caseItemDataService: CaseItemDataService,
        private ngbModal: NgbModal,
        private caseManager: CaseManager,
        private cachedCurrentUserService: CachedCurrentUserService,
        private supportContactService: SupportContactService,
        private toastService: ToastService,
        private supportLoader: SupportLoaderService,
        private fileReaderService: FileReaderService,
        private http: HttpClient,
        private ref: ChangeDetectorRef,
        @Inject(WINDOW) private window: Window,
    ) {}

    getFQDNFromId = (id, name) => this.arrayNameTooltipService.getFQDNFromId(id, name);

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(
                take(1),
                tap(user => (this.currentUser = user)),
                switchMap(user => this.supportContactService.getContactById(user.id)),
            )
            .subscribe({
                next: contact => {
                    this.snowContact = contact;
                    this.hasSNOWContact = true;
                },
                error: () => (this.hasSNOWContact = false),
            });

        this.limit = Boolean(this.limit) ? parseInt(this.limit, 10) : 0;
        this.hasLimit = this.limit > 0;

        this.caseManagerSubscription = this.caseManager.allOpenCases$.subscribe(() => {
            this.updateCaseDetails().subscribe();
        });

        switch (this.size) {
            case 'sm':
                this.sizeClass = 'case-feed-view-sm';
                break;
            case 'lg':
            /* falls through */
            default:
                this.sizeClass = 'case-feed-view-lg';
                this.standalone = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.caseId) {
            this.updateCase();
        }
    }

    ngOnDestroy(): void {
        if (this.caseManagerSubscription) {
            this.caseManagerSubscription.unsubscribe();
        }
        this.cancelRefreshInterval();
    }

    updateCase(): void {
        this.cancelRefreshInterval();
        this.refreshIntervalDuration = moment.duration(5, 'minutes').asMilliseconds();
        this.markAsReadDebounceDuration = moment.duration(30, 'seconds').asMilliseconds();
        this.lastMarkAsReadBrowserTime = moment().subtract(1, 'year'); // It's in the past

        this.caseDetails = null;
        this.items = [];
        const updateCaseDetailsObservable$ = this.updateCaseDetails();
        this.supportLoader
            .add(updateCaseDetailsObservable$)
            .pipe(
                switchMap(() => {
                    this.startRefreshInterval();
                    return this.supportLoader.add(this.getItems());
                }),
            )
            .subscribe(items => {
                this.scrollToLastItem(items);
                if (this.standalone) {
                    this.markAsRead();
                }
            });
    }

    getItems: () => Observable<SupportCaseItem[]> = () => {
        return this.caseItemDataService.getItemsForCase(this.caseId, this.limit).pipe(
            takeUntil(this.stopTimer$),
            tap(items => {
                this.items = items;
                this.buildCollapsedStateOfFeedItems();
            }),
        );
    };

    submitComment: () => Promise<SupportCaseItem> = () => {
        const commentText = this.commentText;
        this.commentText = '';
        this.commentTextUtf8Length = 0;
        this.submittingComment = true;
        this.ampli.supportCaseCommentClicked();
        this.cancelRefreshInterval();
        const placeholder = this.showOptimisticItem({
            caseId: this.caseId,
            body: commentText,
            itemType: 'comment',
        });
        return this.caseItemDataService
            .postComment(this.caseId, {
                body: commentText,
            })
            .toPromise()
            .then(commentWithId => {
                if (this.limit > 0) {
                    this.limit++;
                }
                return this.fillPlaceholder(placeholder, commentWithId.id);
            })
            .catch(() => {
                this.commentText = commentText;
            })
            .finally(() => {
                this.submittingComment = false;
                this.startRefreshInterval();
            }) as Promise<SupportCaseItem>;
    };

    reopen: () => Promise<SupportCaseItem> = () => {
        this.cancelRefreshInterval();
        this.submittingComment = true;
        this.ampli.supportCaseReopenClicked();

        return this.caseItemDataService
            .postComment(this.caseId, { body: this.commentText })
            .toPromise()
            .then(commentWithId => {
                this.caseManager.reopen(this.caseDetails.id);
                const placeholder = this.showOptimisticItem({
                    caseId: this.caseId,
                    body: this.commentText,
                    itemType: 'comment',
                });
                this.commentText = '';
                this.commentTextUtf8Length = 0;
                return this.fillPlaceholder(placeholder, commentWithId.id);
            })
            .finally(() => {
                this.submittingComment = false;
                this.startRefreshInterval();
            });
    };

    getEmail(emailOrContactId: string): string {
        // Service Now only returns snow id then we try to check if it is current user and if yes use his email
        if (this.snowContact?.serviceNowId === emailOrContactId) {
            return this.snowContact.email;
        }

        return emailOrContactId;
    }

    openEditCaseModal(modal: TemplateRef<NgbActiveModal>): void {
        this.ampli.supportCaseEditClicked();
        this.ngbModal.open(modal);
    }

    updateCaseDetails: () => Observable<SupportCaseWithUrl> = () => {
        return this.caseManager.getFullCase(this.caseId).pipe(
            map((caseDetails: SupportCaseWithUrl) => {
                this.caseDetails = {
                    ...caseDetails,
                    subject:
                        typeof caseDetails.subject === 'string' && caseDetails.subject.length > 0
                            ? caseDetails.subject
                            : '-',
                };
                return caseDetails;
            }),
        );
    };

    uploadFile = (fileList: FileList) => {
        const files = [];
        if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
                files.push(fileList.item(i));
            }
            const attachmentPromises = [];
            const modalInstance = this.ngbModal.open(UploadModalComponent);
            // Loop in reverse so that the first file is opened last.
            modalInstance.componentInstance.files = files.reverse();
            modalInstance.componentInstance.onFileSubmitted.subscribe((attachment: Attachment) => {
                attachmentPromises.push(this.checkAttachmentName(attachment).then(this.saveAttachment));
            });
            modalInstance.result.finally(() => {
                Promise.all(attachmentPromises)
                    .then(this.getItems)
                    .finally(() => {
                        this.submittingAttachment = false;
                        this.startRefreshInterval();
                    });

                // Reset input file value
                this.inputFile.nativeElement.value = '';
            });
        }
    };

    saveAttachment: (attachment: Attachment) => Promise<any> = attachment => {
        if (attachment) {
            this.submittingAttachment = true;
            const placeholder: any = this.showOptimisticItem(attachment);
            placeholder.tmpMessage = 'Reading file...';

            const callbackProgress = (progressPercentage: number): void => {
                placeholder.tmpMessage = 'Uploading file... ' + progressPercentage + '%';
            };

            return this.uploadFileInChunks(attachment.file, attachment.name, callbackProgress)
                .then(() => {
                    placeholder.tmpMessage = 'Submitting attachment...';
                    return this.caseItemDataService.postAttachment(this.caseId, attachment).toPromise();
                    // the postAttachment method gives me back the attachment but enhanced with the ID coming from SFDC upload
                })
                .then((attWithId: any) => {
                    this.ampli.supportCaseAttachmentSelected();
                    return this.fillPlaceholder(placeholder, attWithId.id);
                })
                .catch(reason => {
                    this.toastService.add(
                        ToastType.error,
                        `ERROR while uploading the file (${reason.status}; ${reason.error?.message})`,
                    );
                    this.removeOptimisticItem(attachment);
                })
                .finally(() => this.startRefreshInterval());
        }
    };

    uploadFileInChunks = (
        file: File,
        filename: string,
        callbackProgress: (progressPercentage: number) => void,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            // create request to initiate upload
            this.http
                .post<any>('/rest/v1/support/secure-uploads/attachments/init-upload', {
                    originalFilename: filename,
                    caseId: this.caseId,
                    fileSize: file.size,
                })
                .subscribe(({ uploadId, fileId, tesseractCaseId }) => {
                    // Prepare a list of chunk descriptions that will be used in the final request.
                    // For each chunk, after getting the presigned S3 URL and uploading the chunk to that URL, compute the chunk description and
                    // add it to this list.
                    const chunkDescriptions: { partNumber: number; etag: string }[] = [];

                    // keep track of the chunk currently being read and uploaded (for reordering purposes performed in backend)
                    const totalNumberOfChunks = Math.ceil(file.size / CHUNK_SIZE);
                    let chunksRead = 0;
                    let chunksUploaded = 0;

                    const calculateProgressPercent = (): number => {
                        // reading chunks accounts for 50% of progress, uploading for the other 50%
                        const readingFilePercent = (chunksRead * 50) / totalNumberOfChunks;
                        const uploadingFilePercent = (chunksUploaded * 50) / totalNumberOfChunks;
                        return Math.min(Math.floor(readingFilePercent + uploadingFilePercent), 100);
                    };

                    // callback for each chunk read
                    // the observable will emit when the chunk has been fully processed (uploaded chunk to s3)
                    const processChunk = (data: ArrayBuffer): Observable<any> => {
                        chunksRead++;
                        const currentChunkNumber = chunksRead; // chunks are read in order
                        callbackProgress(calculateProgressPercent());

                        // create MD5 for chunk
                        const dataWordArray = cryptoJS.lib.WordArray.create(data as any);
                        const chunkMd5Checksum = cryptoJS.MD5(dataWordArray).toString(cryptoJS.enc.Base64);

                        // get presigned URL for chunk, then upload file to said URL
                        return this.getPresignedUrlForChunk(
                            fileId,
                            tesseractCaseId,
                            uploadId,
                            chunkMd5Checksum,
                            currentChunkNumber,
                        ).pipe(
                            mergeMap((preSignedUrl: string) =>
                                this.uploadToPresignedUrl(preSignedUrl, data, chunkMd5Checksum),
                            ),
                            tap(etag => {
                                // upload finished -- update percentage emitted and chunk descriptions
                                chunksUploaded++;
                                callbackProgress(calculateProgressPercent());
                                chunkDescriptions.push({ etag: etag as any, partNumber: currentChunkNumber });
                            }),
                            catchError(err => {
                                // reject overall result, and rethrow to interrupt the parts read+upload
                                reject();
                                throw err;
                            }),
                        );
                    };

                    const finalizeUpload = () => {
                        // at this point we know that all the chunks have been read and uploaded, and that all chunkDescriptions are ready
                        const reqData = {
                            caseId: this.caseId,
                            fileId,
                            tesseractCaseId,
                            uploadId,
                            partDescriptions: chunkDescriptions,
                        };
                        this.http
                            .post('/rest/v1/support/secure-uploads/attachments/finalize-upload', reqData)
                            .subscribe(() => {
                                callbackProgress(100);
                                resolve(void 0);
                            }, reject);
                    };

                    this.fileReaderService.readFileInChunksConcurrently(
                        file,
                        CHUNK_SIZE,
                        NUMBER_OF_SIMULTANEOUS_CHUNK_READS,
                        processChunk,
                        finalizeUpload,
                    );
                }, reject);
        });
    };

    escalationCreated = escalation => {
        this.items.push(escalation);
    };

    stopPasteTextEvent(event: ClipboardEvent): void {
        const { clipboardData } = event;
        if (clipboardData && Array.isArray(clipboardData.types)) {
            if (clipboardData.types.indexOf('Files') === -1) {
                event.stopPropagation();
            }
        }
    }

    trackById(_: number, item: SupportCaseItem): string {
        return item.id;
    }

    showOptimisticItem: (item: any) => SupportCaseItem = item => {
        const now = moment();
        const placeholder = this.caseItemDataService.getPlaceholder(item);
        this.items = this.items.concat(placeholder);
        this.buildCollapsedStateOfFeedItems();
        this.scrollToLastItem(this.items);
        this.caseManager.updateCase({
            isClosed: false,
            id: this.caseId,
            lastCaseActivity: now,
            status: 'Awaiting on Support',
        });
        return placeholder;
    };

    removeOptimisticItem(toBeRemoved: Attachment): void {
        this.items = this.items.filter(
            item => !(item.itemType === 'attachment' && 'name' in item && item.name === toBeRemoved.name),
        );
        this.buildCollapsedStateOfFeedItems();
    }

    fillPlaceholder: (placeholder: any, itemId: any) => SupportCaseItem = (placeholder, itemId) => {
        return Object.assign(placeholder, {
            tmp: false,
            createdDate: moment(placeholder.createdDate),
            id: itemId,
            caseId: this.caseId,
        });
    };

    downloadFile: (caseId: string, attachment: CaseAttachment) => Promise<void> = (caseId, attachment) => {
        const attachmentId = attachment.id;
        this.downloadingAttachment[attachmentId] = true;
        this.ampli.supportCaseAttachmentClicked();
        return this.fileDownloader
            .downloadCaseAttachment(caseId, attachment)
            .pipe(
                tap(() => {
                    this.downloadingAttachment[attachmentId] = false;
                }),
            )
            .toPromise();
    };

    scrollToLastItem: (items: SupportCaseItem[]) => void = items => {
        let target = '';
        if (
            this.caseDetails.isClosed &&
            typeof this.caseDetails.resolutionSummary === 'string' &&
            this.caseDetails.resolutionSummary.length > 0
        ) {
            target = '#case-feed-resolution-item-' + this.caseId;
        } else {
            const len = items.length;
            if (len > 0) {
                const item = items[len - 1];
                target = '#case-feed-item-' + item.id;
            } else {
                // Case has no items
                return;
            }
        }
        // When navigating from support page to case detail (so case data is already loaded)
        // users could sometimes experience issues with scrolling attempt before items are rendered
        // Calling detectChanges() prevents that
        this.ref.detectChanges();
        this.scrollToItem(target);
    };

    getOuterHeight(el): number {
        let height = el.offsetHeight;
        const style = getComputedStyle(el);

        height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
        return height;
    }

    markAsRead = () => {
        if (!this.caseDetails.isClosed) {
            // It's not a good idea to compare timestamp returned by the server and the one from moment()
            if (
                moment().valueOf() - this.lastMarkAsReadBrowserTime.valueOf() > this.markAsReadDebounceDuration &&
                this.caseDetails
            ) {
                this.caseManager.markAsRead(this.caseDetails).subscribe();
                this.lastMarkAsReadBrowserTime = moment();
            }
        }
    };

    cancelRefreshInterval = () => {
        this.stopTimer$.next();
    };

    startRefreshInterval = () => {
        smartTimer(this.refreshIntervalDuration, this.refreshIntervalDuration)
            .pipe(takeUntil(this.stopTimer$))
            .subscribe(() => this.supportLoader.add(this.getItems()));
    };

    toggleExpandCollapse: (itemId: string) => void = itemId => {
        const userSelected = this.window.getSelection();

        // Do nothing if user has selected text in header
        if (userSelected && userSelected.toString()) {
            return;
        }

        // Toggle the expand collapsed state if user has NOT selected any text
        if (this.feedItemViewStates.hasOwnProperty(itemId)) {
            this.feedItemViewStates[itemId].isItemCollapsed = !this.feedItemViewStates[itemId].isItemCollapsed;

            if (this.feedItemViewStates[itemId].isItemCollapsed) {
                // Update itemNeedCollapsing state if we are collapsing item. This is needed in the following scenario
                // item need collapsing -> user expand item -> user resize browser -> user collapse item
                this.window.setTimeout(() => {
                    this.updateItemNeedCollapsing(this.feedItemViewStates[itemId]);
                }, 0);
            } else {
                // Scroll top of item to top of viewport if we are expanding the item
                const target = '#case-feed-item-' + itemId;
                this.scrollToItem(target);
            }
        }
    };

    getItemCollapsedState: (itemId: string) => boolean = itemId => {
        if (this.feedItemViewStates.hasOwnProperty(itemId)) {
            return this.feedItemViewStates[itemId].isItemCollapsed;
        }

        return false;
    };

    getIfItemNeedCollapsing: (itemId: string) => boolean = itemId => {
        if (this.feedItemViewStates.hasOwnProperty(itemId)) {
            return this.feedItemViewStates[itemId].itemNeedCollapsing;
        }

        return false;
    };

    getItemUserNameForCommentOrAttachment(item: CaseComment | CaseAttachment): string {
        if (item.createdByUser) {
            return item.createdByUser.name || item.createdByUser.email;
        } else if (item.createdById) {
            // this is very error prone, but for now this is the workaround, please dont kill me for this
            if (item.createdById.indexOf('003') === 0 || item.createdById.indexOf('005') === 0) {
                // user has correct ID but we cannot get his info
                return 'Unknown User';
            } else {
                // user has a SNOW user reference, so it is someone from Pure Support
                return 'Pure Storage Support';
            }
        } else {
            return 'Unknown User';
        }
    }

    buildCollapsedStateOfFeedItems = (): void => {
        if (!this.items || this.items.length === 0) {
            return;
        }
        this.items.forEach((item: SupportCaseItem) => {
            if (!this.feedItemViewStates.hasOwnProperty(item.id)) {
                this.feedItemViewStates[item.id] = { isItemCollapsed: true, itemBottomShowing: false, itemObj: null };
            }
        });
        this.window.setTimeout(() => {
            // View might not be initialised yet
            if (this.caseFeedItemsWrapper) {
                const firstItem = this.items[0];
                const firstElement = this.caseFeedItemsWrapper.nativeElement.querySelector(
                    '#case-feed-item-' + firstItem.id,
                );
                if (firstElement) {
                    // Workaround for behavior when $timeout triggers before items are rendered
                    this.window.setTimeout(this.setCollapsetStateOfFeedItems, 0);
                } else {
                    this.setCollapsetStateOfFeedItems();
                }
            }
        }, 0);
    };

    scrollToItem: (target: string) => void = target => {
        this.window.setTimeout(() => {
            const item = this.caseFeedItemsWrapper?.nativeElement?.querySelector(target);

            // Sometimes the element might have been destroyed at this point.
            if (!item) {
                return;
            }

            const duration = 500;
            const itemTop = item.offsetTop;
            const itemHeight = this.getOuterHeight(item);
            const containerHeight = this.getOuterHeight(this.caseFeedItemsWrapper.nativeElement);
            const itemBottom = itemTop + (itemHeight > containerHeight ? containerHeight : itemHeight);

            const containerScrollPosition = this.caseFeedItemsWrapper.nativeElement.scrollTop;

            // Item is already visible so no scrolling is necessary
            if (itemTop >= containerScrollPosition && containerHeight + containerScrollPosition >= itemBottom) {
                return;
            }

            let oldTimestamp = null;
            let currentScroll = containerScrollPosition;

            const offsetTopFinal = itemBottom - containerHeight;

            const step = newTimestamp => {
                if (oldTimestamp !== null) {
                    // Change scroll appropriately to the duration passed
                    if (currentScroll < offsetTopFinal) {
                        currentScroll += (offsetTopFinal * (newTimestamp - oldTimestamp)) / duration;
                        if (currentScroll >= offsetTopFinal) {
                            return (this.caseFeedItemsWrapper.nativeElement.scrollTop = offsetTopFinal);
                        }
                    } else {
                        currentScroll -= (offsetTopFinal * (newTimestamp - oldTimestamp)) / duration;
                        if (currentScroll <= offsetTopFinal) {
                            return (this.caseFeedItemsWrapper.nativeElement.scrollTop = offsetTopFinal);
                        }
                    }

                    this.caseFeedItemsWrapper.nativeElement.scrollTop = currentScroll;
                }
                oldTimestamp = newTimestamp;
                this.window.requestAnimationFrame(step);
            };

            this.window.requestAnimationFrame(step);
        }, 0);
    };

    @HostListener('dragover', ['$event'])
    onDragOver(e: Event): void {
        e.preventDefault();
        e.stopPropagation();

        if (this.dragoverTimeout) {
            this.window.clearTimeout(this.dragoverTimeout);
            this.dragoverTimeout = null;
        }
        this.dragoverActive = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(e: Event): void {
        e.preventDefault();
        e.stopPropagation();

        if (!this.dragoverTimeout) {
            this.dragoverTimeout = this.window.setTimeout(() => {
                this.dragoverActive = false;
            }, 100);
        }
    }

    @HostListener('drop', ['$event'])
    onDrop(e): void {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        this.dragoverActive = false;

        if (files.length > 0) {
            this.uploadFile(files);
        }
    }

    @HostListener('window:resize')
    resizeHandler(): void {
        this.window.setTimeout(() => {
            if (this.items) {
                this.items.forEach((item: SupportCaseItem) => {
                    this.updateItemNeedCollapsing(this.feedItemViewStates[item.id]);
                });
            }
        }, 0);
    }

    /**
     * Updates the itemNeedsCollapsing property of the given FeedItemViewState
     */
    updateItemNeedCollapsing: (itemViewState: IFeedItemViewState) => void = itemViewState => {
        if (itemViewState.itemObj) {
            itemViewState.itemNeedCollapsing = itemViewState.itemObj.scrollHeight > itemViewState.itemObj.clientHeight;
        }
    };

    commentTextChange(value: string): void {
        this.commentTextUtf8Length = new Blob([value]).size;
    }

    scrollHandler = () => {
        // determine currently visible scroll window
        const element = this.caseFeedItemsWrapper.nativeElement;
        const headerOffset = element.offsetTop;
        const currentScrollTop = element.scrollTop + headerOffset;
        const outerHeight = element.offsetHeight;
        const style = getComputedStyle(element);
        const scrollWindowHeight = outerHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
        const currentScrollBottom = currentScrollTop + scrollWindowHeight;
        let newItemIdForShowButton = this.showBackToTopButtonForItemId;
        let newBottomShownVal = this.bottomOfItemWithBackToTopButtonShown;

        // determine which item is in the window: for an item to be visible in the view window, it will be any of these:
        // 1. its top is within the window
        // 2. its bottom is within the window. 2a) AND its top is above the top of the window AND its height > window
        // 3. its top is above top of window AND bottom is below bottom of window
        // we will show the back-to-top button only in case of: (2a or 3) AND if the item is expanded
        for (const itemId in this.feedItemViewStates) {
            // skip this item if item state is not properly initialized or item is not expanded
            if (
                !this.feedItemViewStates.hasOwnProperty(itemId) ||
                !this.feedItemViewStates[itemId].hasOwnProperty('itemObj') ||
                !this.feedItemViewStates[itemId].itemObj ||
                this.feedItemViewStates[itemId].isItemCollapsed
            ) {
                continue;
            }

            const feedItem = this.feedItemViewStates[itemId].itemObj[0];
            if (!feedItem) {
                continue;
            }

            const feedItemTop = feedItem.offsetTop;
            const feedItemBottom = feedItem.offsetHeight + feedItemTop;

            if (
                feedItemTop < currentScrollTop &&
                currentScrollTop < feedItemBottom &&
                feedItem.offsetHeight > scrollWindowHeight
            ) {
                // found the ONLY item to show a backToTopButton for
                newItemIdForShowButton = itemId;

                // check if the item's bottom is visible inside the view window
                newBottomShownVal = feedItemBottom < currentScrollBottom;

                // trigger a digest cycle if any values changed (otherwise backToTop button doesn't always show up/dissapear right away)
                if (
                    newItemIdForShowButton !== this.showBackToTopButtonForItemId ||
                    newBottomShownVal !== this.bottomOfItemWithBackToTopButtonShown
                ) {
                    this.showBackToTopButtonForItemId = newItemIdForShowButton;
                    this.bottomOfItemWithBackToTopButtonShown = newBottomShownVal;
                }
                return;
            }
        }

        // no item to show backToTop Button for, reset the scc vars so backToTop button is not shown.
        newItemIdForShowButton = '';
        newBottomShownVal = false;
        if (
            newItemIdForShowButton !== this.showBackToTopButtonForItemId ||
            newBottomShownVal !== this.bottomOfItemWithBackToTopButtonShown
        ) {
            this.showBackToTopButtonForItemId = newItemIdForShowButton;
            this.bottomOfItemWithBackToTopButtonShown = newBottomShownVal;
        }
    };

    /**
     * Checks whether a potential attachment has a valid name. A name is valid if there is no pre-existing attachment
     * with the same file name.
     * @param attachment The potentially new attachment
     * @returns A promise that resolves with the given attachment if the name is valid, and rejects if it's not.
     */
    private checkAttachmentName = (attachment: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            const existingAttachments = this.items.filter(item => item.itemType === 'attachment');
            const valid = !existingAttachments.some(
                (existingAttachment: any) => existingAttachment.name === attachment.name,
            );
            if (valid) {
                resolve(attachment);
            } else {
                this.toastService.add(ToastType.error, 'There is already an attachment with that filename.');
                reject();
            }
        });
    };

    /**
     * Gets the presigned URL for a chunk
     */
    private getPresignedUrlForChunk(
        fileId: string,
        tesseractCaseId: string,
        uploadId: string,
        chunkMd5Sum: string,
        chunkNumber: number,
    ): Observable<string> {
        const preSignedChunkReqData = {
            caseId: this.caseId,
            fileId,
            tesseractCaseId,
            uploadId,
            chunkMd5Sum,
            chunkNumber,
        };
        return this.http
            .post('/rest/v1/support/secure-uploads/attachments/create-presigned-url', preSignedChunkReqData)
            .pipe(map((response: any) => response.preSignedUrl));
    }

    private setCollapsetStateOfFeedItems = () => {
        this.items.forEach(item => {
            const itemViewState: IFeedItemViewState = this.feedItemViewStates[item.id];
            itemViewState.itemObj = this.caseFeedItemsWrapper.nativeElement.querySelector('#case-feed-item-' + item.id);
            this.updateItemNeedCollapsing(itemViewState);
        });
    };

    /**
     * Makes the request to upload a particular chunk to the presigned S3 URL.
     * @param presignedUrl
     * @param data
     * @param md5checksum
     * @returns The eTag for the upload, parsed from the response headers
     */
    private uploadToPresignedUrl(presignedUrl: string, data: ArrayBuffer, md5checksum: string): Observable<string> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-MD5', md5checksum);

        return this.http
            .put<HttpResponse<any>>(presignedUrl, data, {
                headers,
                observe: 'response',
            })
            .pipe(map(response => response.headers.get('etag').replace(/['"]+/g, '')));
    }
}
