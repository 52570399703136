import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { MarketplaceOrderSummary } from '../models/marketplace-order-summary';

@Injectable({ providedIn: 'root' })
export class MarketplaceOrderSummaryService extends GenericService<MarketplaceOrderSummary> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: MarketplaceOrderSummary,
            endpoint: '/rest/v1/marketplace/order-summaries',
        });
    }
}
