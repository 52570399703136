import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * Generic application-wide service
 */
@Injectable({ providedIn: 'root' })
export class AppService {
    /**
     * When the site's navigation sidebar is toggled open/closed.
     * Event is raised only after the open/close animation finishes.
     */
    readonly navSideBarToggled = new Subject<{ isCollapsed: boolean }>();

    /**
     * Resizable directive's size updated.
     * Event is raised only after the drag event ends (mouseup), not during the dragging.
     */
    readonly resizableSizeUpdated = new Subject<{ dragKey: string }>();

    /**
     * The open/closed state of PageSidePanel
     * Event is raised before the transition fully completes
     */
    readonly pageSidePanelState = new BehaviorSubject<{ isCollapsed: boolean }>({ isCollapsed: true });
}
