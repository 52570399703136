import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { WINDOW } from './injection-tokens';

/**
 * Overrides the global error handler
 */
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(@Inject(WINDOW) private window: Window) {
        super();
    }

    handleError(error: any): void {
        super.handleError(error);

        /*
            Reload page on ChunkLoadError.

            This happens only in prod builds, where outputHashing is enabled.
            When the user loads the page, main.js contains filename refs to other js files to be lazy-loaded, but
            does not load them until needed. If we push an update while the user is on the page, then they try
            to go to a new page that has not been loaded yet, if the hash changed, the old file cached in their main.js
            will not be found, and a ChunkLoadError is thrown.
            To fix this, we simply just reload the page. While that may seem intrusive, because this only happens on
            router lazy-loading, we know it will only happen when a user navigates to a new feature module (not even
            just a new sub-page). So the reload will actually not be intrusive at all, just a minor oddity.
            Only unfortunate part is that we will reload the page the user is on, not the page they tried to go to,
            so they will have to click the link again.
        */
        if (error?.name === 'ChunkLoadError') {
            this.window.location.reload();
        }
    }
}
