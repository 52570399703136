import { PagedDataComponent2 } from '../../gui/paged-data2.component';
import { FilterParams, GFBEntity, Invoice, InvoiceService, SortParams } from '@pure1/data';
import { Component, OnInit } from '@angular/core';
import { HIVE_THEMING, Themes } from '@pure/hive';
import { BehaviorSubject, take } from 'rxjs';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlService } from '../../redux/url.service';
import {
    addFilter,
    DATE,
    DEFAULT_NAMESPACE,
    DUE_DATE,
    INVOICE_ENTITY,
    LOCAL_BAR_INVOICE_MANAGEMENT,
    LOCAL_BAR_INVOICE_MANAGEMENT_HIDDEN,
    NAME_KEY,
    removeFiltersByKey,
    TIME_END_KEY,
    TIME_START_KEY,
} from '../../redux/actions';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { RangeFilters } from './invoice-management-range-filters/invoice-management-range-filters.component';
import moment from 'moment';
import { Action } from 'redux';
import { createFilter } from '../../redux/utils';

const DEFAULT_SORT_KEY = 'id';

@Component({
    selector: 'invoice-management-view',
    templateUrl: './invoice-management-view.component.html',
    providers: [
        {
            provide: HIVE_THEMING,
            useValue: new BehaviorSubject<Themes>(Themes.UINext),
        },
    ],
})
export class InvoiceManagementViewComponent extends PagedDataComponent2<Invoice> implements OnInit {
    readonly DEFAULT_KEY = NAME_KEY;
    readonly DEFAULT_ENTITY = INVOICE_ENTITY;
    readonly GFB_ENTITIES: GFBEntity[] = [INVOICE_ENTITY];

    private readonly hiddenBarId = LOCAL_BAR_INVOICE_MANAGEMENT_HIDDEN;
    private readonly invoiceCreationDateStartKey = `${DATE}.${TIME_START_KEY}`;
    private readonly invoiceCreationDateEndKey = `${DATE}.${TIME_END_KEY}`;
    private readonly invoiceDueDateStartKey = `${DUE_DATE}.${TIME_START_KEY}`;
    private readonly invoiceDueDateEndKey = `${DUE_DATE}.${TIME_END_KEY}`;

    // Total number of assets, filtered and unfiltered
    totalFiltered: number = null;
    totalUnfiltered: number = null;
    selectedRangeFilters: RangeFilters = {
        startRange: null,
        endRange: null,
    };

    // Sorting
    defaultSortKey = DEFAULT_SORT_KEY;
    defaultApiSortKey = DEFAULT_SORT_KEY;
    defaultSort: SortParams = {
        key: DEFAULT_SORT_KEY,
        order: 'asc',
    };

    pageSize = 25;
    unfilteredData: Invoice[];
    loading = false;

    constructor(
        protected invoiceService: InvoiceService,
        protected ngRedux: NgRedux<IState>,
        protected url: UrlService,
    ) {
        super(
            invoiceService,
            ngRedux,
            url,
            new InvoiceManagementUrlReaderWriter(),
            LOCAL_BAR_INVOICE_MANAGEMENT,
            INVOICE_ENTITY,
            DEFAULT_NAMESPACE,
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.invoiceService
            .list()
            .pipe(take(1))
            .subscribe(({ response, total }) => {
                this.unfilteredData = response;
                this.totalUnfiltered = response?.length;
            });
    }

    onDataChange(_: Invoice[]): void {
        this.totalFiltered = this.data?.length;
    }

    onPageSizeChange(pageSize: number): void {
        this.pageSize = pageSize;
    }

    onAfterFetchFailed(_: Error): void {
        this.loading = false;
        this.data = [];
        this.total = 0;
        this.onDataChange(this.data);
    }

    onTimeFilterChanges(timeRangeOption: RangeFilters): void {
        this.selectedRangeFilters = timeRangeOption;
        const actions: Action[] = [
            removeFiltersByKey(this.hiddenBarId, this.invoiceCreationDateStartKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.invoiceCreationDateEndKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.invoiceDueDateStartKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.invoiceDueDateEndKey, DEFAULT_NAMESPACE),
        ];
        if (timeRangeOption.startRange) {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.invoiceCreationDateStartKey,
                        timeRangeOption.startRange?.start.valueOf().toString(),
                    ),
                ),
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.invoiceCreationDateEndKey,
                        timeRangeOption.startRange?.end.valueOf().toString(),
                    ),
                ),
            );
        }
        if (timeRangeOption.endRange) {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.invoiceDueDateStartKey,
                        timeRangeOption.endRange?.start.valueOf().toString(),
                    ),
                ),
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.invoiceDueDateEndKey,
                        timeRangeOption.endRange?.end.valueOf().toString(),
                    ),
                ),
            );
        }
        this.ngRedux.dispatch(actions);
    }

    onBeforeUpdateParams(): void {
        this.handleRedux();
    }

    onBeforeFetchData(): void {
        if (this.listCallParameters) {
            this.listCallParameters.filter = this.appendTimeFilters(this.listCallParameters.filter);
        }
    }

    private appendTimeFilters(existingFilter: FilterParams<Invoice>): FilterParams<Invoice> {
        //Default time limitation is for the past 1 year
        const newFilters: FilterParams<Invoice> = {
            ...existingFilter,
            date: `${DATE}>=${moment.utc().subtract(1, 'year').valueOf()} and ${DATE}<=${moment.utc().valueOf()}`,
        };
        //If there the time range selection is selected
        if (this.selectedRangeFilters.startRange) {
            newFilters[DATE] =
                `${DATE}>${this.selectedRangeFilters.startRange.start.valueOf() - 1} and ${DATE}<${this.selectedRangeFilters.startRange.end.valueOf() + 1}`;
        }
        if (this.selectedRangeFilters.endRange) {
            newFilters[DUE_DATE] =
                `${DUE_DATE}>${this.selectedRangeFilters.endRange.start.valueOf() - 1} and ${DUE_DATE}<${this.selectedRangeFilters.endRange.end.valueOf() + 1}`;
        }
        return newFilters;
    }

    private handleRedux(): void {
        const state = this.ngRedux.getState();
        // Need hidden since the regular barId is managed by PagedData. Would prefer to not introduce a new slice
        const filters = state.filters[this.hiddenBarId];

        if (filters) {
            const startDateStartFilter = filters.find(filter => filter.key === this.invoiceCreationDateStartKey);
            const endDateStartFilter = filters.find(filter => filter.key === this.invoiceDueDateStartKey);
            if (startDateStartFilter) {
                this.selectedRangeFilters.startRange = {
                    start: moment.utc(Number(startDateStartFilter.value)),
                    end: moment.utc(
                        Number(filters.find(filter => filter.key === this.invoiceCreationDateEndKey).value),
                    ),
                };
            }
            if (endDateStartFilter) {
                this.selectedRangeFilters.endRange = {
                    start: moment.utc(Number(endDateStartFilter.value)),
                    end: moment.utc(Number(filters.find(filter => filter.key === this.invoiceDueDateEndKey).value)),
                };
            }
        }
    }
}

class InvoiceManagementUrlReaderWriter extends UrlReaderWriter {
    path = /^\/assets\/invoice-management/;
    localBarId = LOCAL_BAR_INVOICE_MANAGEMENT;

    constructor() {
        super();
    }
}
