<pureui-svg
    class="copy-icon"
    svg="copy.svg"
    height="15"
    (click)="copyFieldToClipboard($event, copyField)"
    (mouseenter)="openCopyTooltip(tooltip)"
    (mouseleave)="closeCopyTooltip(tooltip)"
    [ngbTooltip]="copyTooltipTemplate"
    container="body"
    placement="right"
    triggers="manual"
    #tooltip="ngbTooltip"
>
</pureui-svg>
<ng-template #copyTooltipTemplate let-message="message">
    {{ message }}
</ng-template>
