import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import moment from 'moment';
import {
    CaseComposite,
    CaseRecentCompositeItems,
    CaseRecentItems,
    CasesQuery,
    ItemType,
    SupportCase,
} from '../support.interface';
import { SupportErrorService } from './error.service';

@Injectable()
export class CaseService {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 29000; // 29 seconds
    endpoint = '/rest/v1/support/cases';

    constructor(
        private http: HttpClient,
        private errorService: SupportErrorService,
    ) {}

    @Cacheable({
        maxAge: CaseService.cacheTTL,
        cacheBusterObserver: CaseService.cacheBuster$,
    })
    getCaseById(caseId: string): Observable<SupportCase> {
        return this.http
            .get<CaseComposite>(`${this.endpoint}/${caseId}`)
            .pipe(first(), map(this.caseCompositeHelper), map(this.momentizeHelper), map(this.normalizeHelper));
    }

    @Cacheable({
        maxAge: CaseService.cacheTTL,
        cacheBusterObserver: CaseService.cacheBuster$,
    })
    getOpenCases(query: object): Observable<SupportCase[]> {
        let params = new HttpParams();
        Object.keys(query).forEach(key => {
            if (key !== 'closed') {
                params = params.append(key, query[key]);
            }
        });
        params = params.append('closed', false);
        console.log({ params });
        return this.http.get<CaseComposite[]>(this.endpoint, { params }).pipe(
            first(),
            map((cases: CaseComposite[]) => cases.map(c => this.caseCompositeHelper(c))),
            map((cases: SupportCase[]) => cases.map(c => this.momentizeHelper(c))),
            map((cases: SupportCase[]) => cases.map(c => this.normalizeHelper(c))),
        );
    }

    @Cacheable({
        maxAge: CaseService.cacheTTL,
        cacheBusterObserver: CaseService.cacheBuster$,
    })
    getClosedCases(query: object): Observable<SupportCase[]> {
        let params = new HttpParams();
        Object.keys(query).forEach(key => {
            if (key !== 'closed') {
                params = params.append(key, query[key]);
            }
        });
        params = params.append('closed', true);
        console.log({ params });
        return this.http.get<CaseComposite[]>(this.endpoint, { params }).pipe(
            first(),
            map((cases: CaseComposite[]) => cases.map(c => this.caseCompositeHelper(c))),
            map((cases: SupportCase[]) => cases.map(c => this.momentizeHelper(c))),
            map((cases: SupportCase[]) => cases.map(c => this.normalizeHelper(c))),
        );
    }

    @Cacheable({
        maxAge: CaseService.cacheTTL,
        cacheBusterObserver: CaseService.cacheBuster$,
    })
    getCasesBrief(query: CasesQuery = { closed: false }): Observable<SupportCase[]> {
        const endpoint = '/rest/v1/support/cases-brief';
        let params = new HttpParams();
        Object.keys(query).forEach(key => {
            params = params.append(key, query[key]);
        });
        return this.http.get<CaseComposite[]>(endpoint, { params }).pipe(
            first(),
            map((cases: CaseComposite[]) => cases.map(c => this.caseCompositeHelper(c))),
            map((cases: SupportCase[]) => cases.map(c => this.momentizeHelper(c))),
            map((cases: SupportCase[]) => cases.map(c => this.normalizeHelper(c))),
        );
    }

    @Cacheable({
        maxAge: CaseService.cacheTTL,
        cacheBusterObserver: CaseService.cacheBuster$,
    })
    getCaseRecentItems(caseId: string, limit: number): Observable<CaseRecentItems> {
        const params = new HttpParams();
        params.append('count', limit.toString(10));
        params.append('sort', 'desc');
        return this.http.get<CaseRecentCompositeItems>(`${this.endpoint}/${caseId}/recentItems`, { params }).pipe(
            first(),
            map((data: CaseRecentCompositeItems) => this.recentItemsHelper(data)),
        );
    }

    @CacheBuster({
        cacheBusterNotifier: CaseService.cacheBuster$,
    })
    saveCase(newCase: Partial<SupportCase>): Observable<SupportCase> {
        return this.http
            .post<CaseComposite>(this.endpoint, this.deleteUnwantedProperties(newCase))
            .pipe(
                catchError(this.errorService.errorInterceptor),
                first(),
                map(this.caseCompositeHelper),
                map(this.momentizeHelper),
                map(this.normalizeHelper),
            );
    }

    @CacheBuster({
        cacheBusterNotifier: CaseService.cacheBuster$,
    })
    updateCase(updatedCase: Partial<SupportCase>): Observable<SupportCase> {
        return this.http
            .patch<SupportCase>(`${this.endpoint}/${updatedCase.id}`, this.deleteUnwantedProperties(updatedCase))
            .pipe(
                catchError(this.errorService.errorInterceptor),
                first(),
                map(this.momentizeHelper),
                map(this.normalizeHelper),
            );
    }

    private deleteUnwantedProperties = (data: Partial<SupportCase>) => {
        const cleanedData = {
            ...data,
        };

        // Properties that must not be sent to the REST Api, otherwise we get an HTTP 400.
        const nonRestProperties = [
            'array',
            'caseNumber',
            'combinedSeverity',
            'cadence',
            'closedDate',
            'createdDate',
            'lastModifiedDate',
            'lastCaseActivity',
            'lastUserView',
            'owner',
            'reopenable',
            'status',
            'closed',
        ];
        for (const i in nonRestProperties) {
            delete cleanedData[nonRestProperties[i]];
        }

        // Remove all properties other than `id` in case.contact and case.alternateContact
        if (cleanedData.contact && cleanedData.contact.id) {
            cleanedData.contact = {
                id: cleanedData.contact.id,
                email: cleanedData.contact.email,
            };
        } else {
            cleanedData.contact = null;
        }

        if (cleanedData.alternateContact && cleanedData.alternateContact.id) {
            cleanedData.alternateContact = {
                id: cleanedData.alternateContact.id,
                email: cleanedData.alternateContact.email,
            };
        } else {
            cleanedData.alternateContact = null;
        }

        return cleanedData;
    };

    caseCompositeHelper = (compositeData: CaseComposite): SupportCase => {
        if (!compositeData) {
            return;
        }

        return {
            ...compositeData.case,
            array: compositeData.array,
            status: compositeData.status,
            lastUserView: compositeData.lastUserView,
        };
    };

    private recentItemsHelper = (data: CaseRecentCompositeItems): any => {
        return {
            caseId: data.caseId,
            attachments: data.attachments.map(item => ({
                ...item.attachment,
                createdByUser: item.createdByUser,
                itemType: ItemType.Attachment,
            })),
            comments: data.comments.map(item => ({
                ...item.comment,
                createdByUser: item.createdByUser,
                itemType: ItemType.Comment,
            })),
            emails: data.emails.map(item => ({
                ...item,
                itemType: ItemType.Email,
            })),
            escalations: data.escalations.map(item => ({
                ...item,
                itemType: ItemType.Escalation,
                createdDate: item.createdDate,
            })),
        };
    };

    private momentizeHelper = (caseData: SupportCase): SupportCase => {
        if (!caseData) {
            return;
        }
        const closedDate = moment(caseData.closedDate || caseData.lastModifiedDate);
        const createdDate = moment(caseData.createdDate);
        const lastCaseActivity = moment(caseData.lastModifiedDate || caseData.createdDate);

        return {
            ...caseData,
            closedDate,
            createdDate,
            lastCaseActivity,
            lastModifiedDate: moment(caseData.lastModifiedDate),
            lastUserView: moment(caseData.lastUserView || 0),
        };
    };

    private normalizeHelper = (caseDetails: SupportCase): SupportCase => {
        if (!caseDetails) {
            return;
        }

        if (caseDetails.array && caseDetails.array.purityVersion) {
            caseDetails.array.version = caseDetails.array.purityVersion;
        }
        return caseDetails;
    };
}
