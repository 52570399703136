import { Resource } from '../interfaces/resource';
import { AssessmentLevel } from './assessment-array';
import { ExclusionStatus } from './data-protection-v2-exclusions';

export class DataProtectionV2EntityDetailData implements Resource {
    readonly id: string;
    readonly name: string;

    /**
     * FlashArray properties
     */
    applianceID: string;
    entityName: string;
    bucketName: string;
    size: number;
    snapshotAtLeastOnceADayExistsOrScheduled: boolean;
    snapshotExistsOrScheduledAtLeastOnceADayFor7days: boolean;
    safeModeAtSourceForAtLeast7days: boolean;
    snapshotReplicatedAtLeastOnceADayFor7days: boolean;
    safeModeOnTargetFor7daysOrMore: boolean;
    assessmentLevel: AssessmentLevel;
    /**
     * FlashBlade properties
     */
    safeModeProtected: boolean;
    versioningEnabled: boolean;
    /**
     * TODO: refactor to use the same value as for FA (XAAS-3220)
     */
    safeModeOnTargetFor7DaysOrMore: boolean;
    replicating: boolean;
    excluded: boolean;
    exclusionStatus: ExclusionStatus;

    constructor(response: Partial<DataProtectionV2EntityDetailData>) {
        this.id = this.applianceID = response.applianceID;
        this.bucketName = response.bucketName;
        this.entityName = response.entityName;
        this.name = this.bucketName ?? this.entityName;
        this.size = response.size;
        this.snapshotAtLeastOnceADayExistsOrScheduled = response.snapshotAtLeastOnceADayExistsOrScheduled;
        this.safeModeAtSourceForAtLeast7days = response.safeModeAtSourceForAtLeast7days;
        this.snapshotReplicatedAtLeastOnceADayFor7days = response.snapshotReplicatedAtLeastOnceADayFor7days;
        this.snapshotExistsOrScheduledAtLeastOnceADayFor7days =
            response.snapshotExistsOrScheduledAtLeastOnceADayFor7days;
        this.safeModeOnTargetFor7daysOrMore = response.safeModeOnTargetFor7daysOrMore;
        this.safeModeOnTargetFor7DaysOrMore = response.safeModeOnTargetFor7DaysOrMore;
        this.assessmentLevel = response.assessmentLevel;

        this.safeModeProtected = response.safeModeProtected;
        this.versioningEnabled = response.versioningEnabled;
        this.replicating = response.replicating;
        this.excluded = response.excluded;
        this.exclusionStatus = response.exclusionStatus;
    }
}
