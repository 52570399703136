<div class="message-banner" *ngIf="infoMessage">
    {{ infoMessage }}
</div>
<div class="license-description">
    <section class="description-section">
        <h4 class="section-title"> License Description </h4>
        <span>
            <hive-text-field
                [ngModel]="license.name"
                [maxlength]="maxLength"
                (ngModelChange)="onLicenseNameChange($event)"
            ></hive-text-field>
        </span>
    </section>
    <section class="tier-section">
        <h4 class="section-title">
            Service Level
            <span
                class="section-description"
                ngbTooltip="See comparison among service levels"
                container="body"
                placement="bottom"
            >
                <a
                    href="https://support.purestorage.com/Pure1/Pure1_Manage/Pure1_Manage_-_Subscriptions/Pure_as-a-Service_Catalog"
                    target="_blank"
                >
                    Compare
                </a>
            </span>
        </h4>
        <ng-container *ngIf="isServiceTierSupported(); else noServiceTier">
            <span class="license-type">
                <ng-select
                    class="left-actions"
                    bindLabel="label"
                    bindValue="licenseType"
                    groupBy="group"
                    [clearable]="false"
                    [searchable]="false"
                    [items]="licenseTypes"
                    [ngModel]="license.licenseType"
                    (ngModelChange)="onLicenseTypeChange($event)"
                    [disabled]="!licenseTypes || licenseTypes.length <= 1"
                    angulartics2On="change"
                    angularticsCategory="Action"
                    angularticsAction="Subscription Viewer - New License Service Level Selection"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <pureui-svg
                            class="pstg-secondary-icon license-type-icon"
                            [svg]="item.icon"
                            height="14"
                        ></pureui-svg>
                        {{ item.licenseType }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <pureui-svg
                            class="pstg-secondary-icon license-type-icon"
                            [svg]="item.icon"
                            height="14"
                        ></pureui-svg>
                        {{ item.label }}
                    </ng-template>
                </ng-select>
            </span>
        </ng-container>
        <ng-template #noServiceTier>
            <span class="section-value">{{ migrateToServiceTier(license.licenseType) | defaultString }}</span>
        </ng-template>
    </section>
    <button type="button" class="close modal-x-button pull-right" (click)="deleteThis()">
        <pureui-svg class="pstg-action-icon" svg="trash_erase.svg" height="24"></pureui-svg>
    </button>
</div>
<h4 class="reserved-amount-title">Reserved</h4>
<div class="license-content">
    <section class="reserved-section">
        <license-reserved-amount
            [licenseType]="license.licenseType"
            [increaseAmount]="license.reservedAmount"
            (increaseAmountChange)="onReservedAmountChange($event)"
            [subscription]="subscription"
            [isNewLicense]="true"
        >
        </license-reserved-amount>
    </section>
</div>
<div class="new-license-expansion-datepicker-container" *ngIf="!isQuoteRenewal">
    <span class="new-license-expansion-datepicker-label">Requested Activation Date</span>
    <pureui-svg
        svg="info-circle.svg"
        [height]="13"
        class="pstg-action-icon header-info-icon"
        ngbTooltip="Select the date you would like the capacity available for consumption."
        placement="right"
        container="body"
    >
    </pureui-svg>
    <input
        class="form-control"
        placeholder="Choose a date"
        readonly="readonly"
        name="dp"
        [(ngModel)]="newLicenseChangeDateModel"
        ngbDatepicker
        #newLicensesExpansionsDatePicker="ngbDatepicker"
        (click)="newLicensesExpansionsDatePicker.toggle()"
        (ngModelChange)="onLicenseStartDateChange($event)"
    />
</div>
