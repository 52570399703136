import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { MessagesModalComponent } from './messages-modal.component';
import { IAlertBodyResource } from '../../../utils/legacy-alerts';

@Injectable() // Cannot be provided in root since needs reference to MessagesModalComponent
export class MessagesModalService {
    constructor(private modalService: NgbModal) {}

    open(message: IAlert | IAlertBodyResource, knowledgeBaseLink: string): NgbModalRef {
        const modalRef = this.modalService.open(MessagesModalComponent, {
            windowClass: 'message-modal',
            size: 'lg',
        });
        const comp = modalRef.componentInstance as MessagesModalComponent;
        comp.message = message as any;
        comp.knowledgeBaseLink = knowledgeBaseLink;
        return modalRef;
    }
}
