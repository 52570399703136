import { Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'no-support-modal',
    templateUrl: './no-support-modal.component.html',
})
export class NoSupportModalComponent implements OnDestroy {
    message = 'Access to these features requires a Support account. Contact Pure Storage Support to sign up.';

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
    ) {}

    ngOnDestroy(): void {
        this.activeModal.close();
        this.router.navigate(['/fleet/appliances']);
    }
}
