import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from '@pure1/data';

/** A partial User object that may be passed into this component to force a support avatar */
export const DUMMY_SUPPORT_USER: User = Object.freeze(
    new User({
        email: 'support@purestorage.com',
        name: '',
        firstName: '',
        lastName: '',
    }),
);

@Component({
    selector: 'pure-avatar',
    templateUrl: 'pure-avatar.component.html',
})
export class PureAvatarComponent implements OnChanges {
    @Input() readonly user: User | string;
    @Input() readonly height: number;

    type: 'support' | 'user' | 'unknown';
    firstLetter: string;

    ngOnChanges(changes: SimpleChanges): void {
        this.updateAvatar();
    }

    private updateAvatar(): void {
        if (typeof this.user === 'object' && this.user != null) {
            if (/@purestorage.com/i.test(this.user.email)) {
                this.type = 'support';
            } else {
                this.type = 'user';
                this.firstLetter = this.getFirstCharacter(this.user.name || this.user.email).toUpperCase();
            }
        } else if (typeof this.user === 'string' && this.user.trim().length > 0) {
            if (/@purestorage.com/i.test(this.user)) {
                this.type = 'support';
            } else {
                this.type = 'user';
                this.firstLetter = this.getFirstCharacter(this.user).toUpperCase();
            }
        } else {
            this.type = 'unknown';
        }
    }

    private getFirstCharacter(str: string): string {
        const trimmedStr = str.trim();
        if (trimmedStr === '') {
            return '';
        }
        const codePoint = trimmedStr.charCodeAt(0);
        const charLengthInUtf16CodePoints = codePoint >= 0x10000 ? 2 : 1;
        return trimmedStr.substr(0, charLengthInUtf16CodePoints);
    }
}
