import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    ApplianceGenealogyEventType,
    ArrayGenealogy,
    ArrayGenealogyService,
    FeatureFlagDxpService,
    GFBEntity,
    isApplianceGenealogyEvent,
} from '@pure1/data';
import { ampli } from 'core/src/ampli';
import moment from 'moment';
import { Action } from 'redux';
import { take } from 'rxjs';
import { ExportOptionIds } from '../../export/export-button/export-button.component';
import { PagedDataComponent2 } from '../../gui/paged-data2.component';
import { FeatureNames } from '../../model/FeatureNames';
import {
    addFilter,
    addSort,
    APPLIANCE_GENEALOGY_EVENT_TYPE_KEY,
    ASSET_APPLIANCE_ENTITY,
    ASSET_LICENSE_ENTITY,
    ASSET_SUBSCRIPTION_ENTITY,
    DEFAULT_NAMESPACE,
    GLOBAL_ENTITY,
    LOCAL_BAR_APPLIANCE_GENEALOGY_HIDDEN,
    LOCAL_BAR_ASSET_MANAGEMENT,
    NAME_KEY,
    removeAllSorts,
    removeFiltersByKey,
    SUBSCRIPTION_ASSET_ENTITY,
} from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { UrlService } from '../../redux/url.service';
import { createFilter, getActiveFilters, getCombinedFilterValues } from '../../redux/utils';
import { ToastService, ToastType } from '../../services/toast.service';
import { SortOption } from '../genealogy-sort-dropdown/genealogy-sort-dropdown.component';

enum EventType {
    SOFTWARE_UPGRADE = 'softwareUpgrade',
    HARDWARE_UPGRADE = 'hardwareUpgrade',
    UPCOMING_RENEWAL = 'upcomingApplianceRenewal',
    EOL = 'eol',
    DISPATCH = 'dispatch',
    EVER_MODERN_UPGRADE = 'everModernUpgrade',
}

const SELECTABLE_EVENT_TYPES: ApplianceGenealogyEventType[] = ['hardwareUpgrade', 'softwareUpgrade'];
const APPLIANCE_GENEALOGY_LEGEND_EVENTS: ApplianceGenealogyEventType[] = [
    'applianceInstall',
    'hardwareUpgrade',
    'softwareUpgrade',
    'applianceRenewal',
    'decommission',
];

enum SortType {
    NAME = 'name',
    APPLIANCE_INSTALLATION = 'applianceInstallation',
}

const SORT_OPTIONS: SortOption[] = [
    {
        sortKey: 'applianceInstallation',
        label: 'Chronologically',
    },
    {
        sortKey: 'name',
        label: 'Alphabetically',
    },
];

@Component({
    selector: 'appliance-genealogy-view',
    templateUrl: 'appliance-genealogy-view.component.html',
})
export class ApplianceGenealogyViewComponent extends PagedDataComponent2<ArrayGenealogy> implements OnInit {
    //TODO:Finalize gfb entities
    readonly GFB_ENTITIES: GFBEntity[] = [
        ASSET_SUBSCRIPTION_ENTITY,
        ASSET_LICENSE_ENTITY,
        ASSET_APPLIANCE_ENTITY,
        GLOBAL_ENTITY,
    ];
    readonly DEFAULT_KEY = NAME_KEY;
    readonly DEFAULT_ENTITY = ASSET_APPLIANCE_ENTITY;
    readonly hiddenBarId = LOCAL_BAR_APPLIANCE_GENEALOGY_HIDDEN;
    readonly ampli = ampli;
    private readonly applianceGenealogyEventTypeKey = `${APPLIANCE_GENEALOGY_EVENT_TYPE_KEY}`;
    readonly listViewExportOptions: IExportButtonOption[] = [
        { id: ExportOptionIds.filtered, text: 'Export filtered assets', count: null },
        { id: ExportOptionIds.all, text: 'Export all assets', count: null },
    ];

    selectedExportOption: IExportButtonOption;
    // NOTE: this includes the event_type filter, which is done on the frontend
    applianceFilter: string;
    totalUnfiltered: number = null;
    unfilteredData: ArrayGenealogy[];
    eventTypeFilters: ApplianceGenealogyEventType[] = ['hardwareUpgrade', 'softwareUpgrade'];
    sortState: string = SortType.NAME;
    filteredData: ArrayGenealogy[] = [];
    chartLoading = false;
    events = APPLIANCE_GENEALOGY_LEGEND_EVENTS;
    eventOptions = SELECTABLE_EVENT_TYPES;
    sortOptions = SORT_OPTIONS;
    isFollowupEnabled = false;

    @ViewChild('exportModal', { static: true }) readonly exportModal: TemplateRef<any>;

    constructor(
        protected arrayGenealogyService: ArrayGenealogyService,
        protected featureFlagDxpService: FeatureFlagDxpService,
        protected ngRedux: NgRedux<IState>,
        private ngbModal: NgbModal,
        private toastService: ToastService,
        url: UrlService,
    ) {
        super(
            arrayGenealogyService,
            ngRedux,
            url,
            new ArrayGenealogyReaderWriter(),
            LOCAL_BAR_ASSET_MANAGEMENT,
            SUBSCRIPTION_ASSET_ENTITY,
            DEFAULT_NAMESPACE,
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.arrayGenealogyService
            .list({ pageStart: 0, pageSize: 1 })
            .pipe(take(1))
            .subscribe(({ response, total }) => {
                this.unfilteredData = response;
                this.totalUnfiltered = total;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_FOLLOWUP)
            .pipe(take(1))
            .subscribe((feature: FeatureFlagStatus) => {
                this.isFollowupEnabled = feature?.enabled === true;
                if (this.isFollowupEnabled) {
                    this.eventOptions = [...this.eventOptions, 'upcomingApplianceRenewal'];
                    if (!this.eventTypeFilters.includes('upcomingApplianceRenewal')) {
                        this.eventTypeFilters = [...this.eventTypeFilters, 'upcomingApplianceRenewal'];
                    }
                }
            });

        this.events = this.events.filter(x => x !== 'decommission');
        this.events.push('eol');
        this.eventOptions = [...this.eventOptions, 'eol'];
        if (!this.eventTypeFilters.includes('eol')) {
            this.eventTypeFilters = [...this.eventTypeFilters, 'eol'];
        }

        this.events.push('dispatch');
        this.eventOptions = [...this.eventOptions, 'dispatch'];
        if (!this.eventTypeFilters.includes('dispatch')) {
            this.eventTypeFilters = [...this.eventTypeFilters, 'dispatch'];
        }

        this.events.push('everModernUpgrade');
        this.eventOptions = [...this.eventOptions, 'everModernUpgrade'];
        if (!this.eventTypeFilters.includes('everModernUpgrade')) {
            this.eventTypeFilters = [...this.eventTypeFilters, 'everModernUpgrade'];
        }
    }

    // Gets called when the export menu pops up
    updateExportOptionCounts(optionsMap: Map<number, IExportButtonOption>): void {
        const updateExportOption = (option: IExportButtonOption, count: number) => {
            option.disabled = count === 0 || count == null;
            // Don't want to show 0, just disable it
            option.count = count ? count : null;
        };

        const filteredOpt = optionsMap.get(ExportOptionIds.filtered);
        updateExportOption(filteredOpt, this.data?.length);
        // Handle gfb and contains logic issue by using id filters instead
        this.applianceFilter = this.listCallParameters?.filter
            ? "id=('" + this.data.map(asset => asset.id).join("','") + "')"
            : '';

        if (this.totalUnfiltered !== null) {
            const allOpt = optionsMap.get(ExportOptionIds.all);
            updateExportOption(allOpt, this.totalUnfiltered);
        }
    }

    onClickExport(option: IExportButtonOption): void {
        this.selectedExportOption = option;
        this.ngbModal.open(this.exportModal);
    }

    onApplyDisplayOptions(events: ApplianceGenealogyEventType[]): void {
        const actions: Action[] = [
            removeFiltersByKey(this.hiddenBarId, this.applianceGenealogyEventTypeKey, DEFAULT_NAMESPACE),
        ];
        events.forEach(event => {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(null, DEFAULT_NAMESPACE, this.applianceGenealogyEventTypeKey, event),
                ),
            );
            if (event === 'hardwareUpgrade') {
                ampli.displayOptionsApply({ 'genealogy event types': 'hardware upgrade' });
            } else if (event === 'softwareUpgrade') {
                ampli.displayOptionsApply({ 'genealogy event types': 'software upgrade' });
            } else if (event === 'upcomingApplianceRenewal') {
                ampli.displayOptionsApply({ 'genealogy event types': 'upcoming renewal' });
            } else if (event === 'eol') {
                ampli.displayOptionsApply({ 'genealogy event types': 'all eol' });
            } else if (event === 'dispatch') {
                ampli.displayOptionsApply({ 'genealogy event types': 'all dispatch event' });
            } else if (event === 'everModernUpgrade') {
                ampli.displayOptionsApply({ 'genealogy event types': 'ever modern upgrade' });
            }
        });

        this.ngRedux.dispatch(actions);
    }

    onSort(sortKey: string): void {
        if (sortKey === 'applianceInstallation' || sortKey === 'applianceInstallation-') {
            ampli.sortApplianceGenealogy({ 'Appliance Genealogy Sorting Category': 'Chronologically' });
        } else if (sortKey === 'name' || sortKey === 'name-') {
            ampli.sortApplianceGenealogy({ 'Appliance Genealogy Sorting Category': 'Alphabetically' });
        }

        this.ngRedux.dispatch([removeAllSorts(this.hiddenBarId), addSort(this.hiddenBarId, sortKey)]);
    }

    onDataChange(_: ArrayGenealogy[]): void {
        this.applyEventTypeFilter();
        this.sortData();
    }

    onBeforeUpdateParams(): void {
        // Handle filters
        // Need hidden since the regular barId is managed by PagedData. Would prefer to not introduce a new slice
        const state = this.ngRedux.getState();
        const filters = state.filters[this.hiddenBarId];
        if (filters) {
            const activeFilters = getActiveFilters(null, state.filters[this.hiddenBarId]);
            const filterValues = getCombinedFilterValues(activeFilters, APPLIANCE_GENEALOGY_EVENT_TYPE_KEY);
            if (filterValues === '') {
                this.eventTypeFilters = [];
            } else {
                const filterStrings = filterValues.split(',');
                this.eventTypeFilters = filterStrings.filter(isApplianceGenealogyEvent);
            }
        }
        this.applyEventTypeFilter();

        // Handle sorting
        this.sortState = (state.sorts[this.hiddenBarId] || [])[0] || this.sortState;
        this.sortData();
    }

    onAfterFetchFailed(_: Error): void {
        this.loading = false;
        this.toastService.add(ToastType.error, 'Failed to retrieve appliance genealogy data.');
        this.onDataChange([]);
    }

    private applyEventTypeFilter(): void {
        this.filteredData = (this.data || [])
            .filter(array => {
                if (
                    !array.currentState?.model?.startsWith('FlashBlade//') &&
                    array.currentState?.model?.startsWith('FlashBlade')
                ) {
                    return false; // Skip classic aka legacy aka Norway FBs
                }
                return !!array.applianceInstallation?.date; // Skip if we don't have an installation or date
            })
            .map(array => {
                const filteredArray: ArrayGenealogy = {
                    ...array,
                    softwareUpgrades: null,
                    hardwareUpgrades: null,
                    upcomingRenewalEvent: null,
                    eolHardware: null,
                    dispatchEvents: null,
                    everModernUpgrade: null,
                };
                if (this.eventTypeFilters && this.eventTypeFilters[0]?.length > 0) {
                    this.eventTypeFilters.forEach(filter => {
                        if (filter === EventType.SOFTWARE_UPGRADE) {
                            filteredArray.softwareUpgrades = array.softwareUpgrades;
                        }
                        if (filter === EventType.HARDWARE_UPGRADE) {
                            filteredArray.hardwareUpgrades = array.hardwareUpgrades;
                        }
                        if (filter === EventType.UPCOMING_RENEWAL) {
                            filteredArray.upcomingRenewalEvent = array.upcomingRenewalEvent;
                        }
                        if (filter === EventType.EOL) {
                            filteredArray.eolHardware = array.eolHardware;
                        }
                        if (filter === EventType.DISPATCH) {
                            filteredArray.dispatchEvents = array.dispatchEvents;
                        }
                        if (filter === EventType.EVER_MODERN_UPGRADE) {
                            filteredArray.everModernUpgrade = array.everModernUpgrade;
                        }
                    });
                }
                return filteredArray;
            });
    }

    private sortData(): void {
        // Strip "-" from sortKey to denote desc or asc
        const sortKey = this.sortState.endsWith('-')
            ? this.sortState.slice(0, this.sortState.length - 1)
            : this.sortState;

        const sortDesc = this.sortState.endsWith('-');

        this.filteredData = this.filteredData.slice().sort((arrayA, arrayB) => {
            if (sortKey === SortType.NAME) {
                return arrayA.name?.localeCompare(arrayB.name);
            } else if (sortKey === SortType.APPLIANCE_INSTALLATION) {
                return arrayA.applianceInstallation.date.diff(arrayB.applianceInstallation.date);
            } else {
                return 0;
            }
        });

        if (sortDesc) {
            this.filteredData = this.filteredData.slice().reverse();
        }
    }

    protected getAutoRefreshInterval(): moment.Duration {
        // Arbitrary but it's not necessary to update every 30s. Data will get populated at most every quarter
        return moment.duration(1, 'hour');
    }
}

class ArrayGenealogyReaderWriter extends UrlReaderWriter {
    path = /^\/assets\/appliance-genealogy/;
    localBarId = LOCAL_BAR_ASSET_MANAGEMENT;

    constructor() {
        super();
    }
}
