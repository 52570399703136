import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { VolumeSnapshot } from '../models/volume-snapshot';

@Injectable({ providedIn: 'root' })
export class VolumeSnapshotService extends GenericService<VolumeSnapshot> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: VolumeSnapshot,
            endpoint: '/rest/v4/volume-snapshots',
        });
    }
}
