import { Component, Input } from '@angular/core';
import { UnifiedArray, ServiceCatalogQuote } from '@pure1/data';

import { AppliancesViewMode } from '../appliances-view/appliances-view.component';

@Component({
    selector: 'arrays-list-view',
    templateUrl: 'arrays-list-view.component.html',
})
export class ArraysListViewComponent {
    @Input() readonly filteredArrays: UnifiedArray[];
    @Input() readonly alertMap: Map<string, IAlert[]>;
    @Input() readonly allOrders: ServiceCatalogQuote[] = [];
    @Input() readonly isLoading: boolean;
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Input() readonly mode: AppliancesViewMode;
}
