import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { LicenseType, Subscription } from '@pure1/data';
import { FormatTiBPipe } from '../../ui/pipes/format-tib.pipe';

@Component({
    selector: 'reserved-amount-increased',
    templateUrl: './reserved-amount-increased.component.html',
})
export class ReservedAmountIncreasedComponent implements OnChanges {
    @Input() readonly currentAmount: number;
    @Input() readonly subscription: Subscription;
    @Input() readonly licenseType: LicenseType;
    @Input() readonly increaseAmount: number;

    increaseAmountInUnit: number;
    increaseAmountRatio: number;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.increaseAmount) {
            if (this.licenseType === LicenseType.VMA) {
                this.increaseAmountInUnit = this.increaseAmount;
            } else {
                this.increaseAmountInUnit = this.increaseAmount ? FormatTiBPipe.toTiB(this.increaseAmount) : null;
            }
            if (this.increaseAmountInUnit === null) {
                this.increaseAmountInUnit = 0;
            }
        }
        if (changes.increaseAmount || changes.currentAmount) {
            if (this.currentAmount) {
                this.increaseAmountRatio = this.increaseAmount / this.currentAmount;
            } else {
                this.increaseAmountRatio = null;
            }
        }
    }
}
