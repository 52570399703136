import { inject } from '@angular/core';
import { FeatureNames } from '../../model/FeatureNames';
import { FeatureFlagDxpService, XaaSAuthorizationService } from '@pure1/data';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { CanActivateFn, Router } from '@angular/router';

export const canViewAssetTab = (permissions: string[], featureFlag?: FeatureNames): Observable<boolean> => {
    const authzService = inject(XaaSAuthorizationService);
    const featureFlagDxpService = inject(FeatureFlagDxpService);

    const permissions$ = combineLatest(permissions.map(permission => authzService.isAllowed(permission)));

    const isAuth$ = permissions$.pipe(map(permissions => permissions.every(permission => permission)));

    if (featureFlag == null) {
        return isAuth$;
    }

    return combineLatest([isAuth$, featureFlagDxpService.getFeatureFlag(featureFlag)]).pipe(
        map(([isAuth, featureFlag]) => isAuth && featureFlag?.enabled === true),
    );
};

export const canViewAssetTabGuard = (
    permissions: string[],
    featureFlag?: FeatureNames,
    redirectUnauthorizedToUrl?: string,
): CanActivateFn => {
    return (_route, _state) => {
        const router = inject(Router);
        return canViewAssetTab(permissions, featureFlag).pipe(
            tap(canView => {
                if (!canView && redirectUnauthorizedToUrl) {
                    // Redirect to unauthorized page
                    router.navigate([redirectUnauthorizedToUrl]);
                }
            }),
        );
    };
};
