import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ExportRequestBuilderService } from '../../../export/services/export-request-builder.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportManagerService } from '../../../export/services/export-manager.service';
import { Angulartics2 } from 'angulartics2';
import { ExportOptionIds } from 'core/src/export/export-button/export-button.component';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'appliance-genealogy-export-modal',
    templateUrl: 'appliance-genealogy-export-modal.component.html',
})
export class ApplianceGenealogyExportModalComponent implements OnInit, OnChanges {
    readonly analyticsLabel = 'Appliance Genealogy Export Modal - ';
    readonly ampli = ampli;

    @Input() readonly modal: NgbActiveModal;
    @Input() readonly entityFilter: string;
    @Input() readonly totalFiltered: number;
    @Input() readonly totalUnfiltered: number;
    @Input() readonly selectedOption: IExportButtonOption;

    errorMessage: string;
    exportRequest: IApplianceGenealogyExportRequest;
    fileNameEntry: string;
    loading = false;
    entityCountMessage = '';

    constructor(
        private angulartics2: Angulartics2,
        private exportManager: ExportManagerService,
        private exportRequestBuilder: ExportRequestBuilderService,
    ) {}

    ngOnInit(): void {
        this.generateExportRequest();
        this.fileNameEntry = this.exportRequest.fileName;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalFiltered || changes.selectedOption || changes.totalUnfiltered) {
            this.entityCountMessage = this.getEntityCountMessage();
        }
    }

    clickApply(): void {
        this.generateExportRequest();
        this.exportRequest.fileName = this.fileNameEntry;

        // Set state and send request
        this.errorMessage = null;
        this.loading = true;

        this.exportManager.enqueue(this.exportRequest).subscribe(
            () => {
                this.loading = false;
                this.modal.close();
            },
            err => {
                this.loading = false;
                this.errorMessage = err;
            },
        );

        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel + 'Report Download',
            properties: {
                category: 'Action',
            },
        });
    }

    getEntityCountMessage(): string {
        if (!this.selectedOption) {
            return '';
        }

        let noun = 'appliances';

        switch (this.selectedOption.id) {
            case ExportOptionIds.all: {
                if (this.totalUnfiltered === 1) {
                    noun = 'appliance';
                }
                return `All ${this.totalUnfiltered} ${noun}`;
            }
            case ExportOptionIds.filtered: {
                if (this.totalFiltered === 1) {
                    noun = 'appliance';
                }
                return `${this.totalFiltered} filtered ${noun}`;
            }
            default: {
                return `Unknown option ${this.selectedOption.id}`;
            }
        }
    }

    generateExportRequest(): void {
        const filter =
            this.selectedOption?.id === ExportOptionIds.filtered && this.entityFilter ? this.entityFilter : '';
        this.exportRequest = this.exportRequestBuilder.filteredApplianceGenealogy(filter);
        ampli.exportingOnAssetTab(
            filter
                ? { 'assets tab page': 'Appliance Genealogy', 'export category': 'filtered' }
                : { 'assets tab page': 'Appliance Genealogy', 'export category': 'all' },
        );
    }
}
