<div class="um-title profile-name">
    <div class="um-title-back-icon" (click)="returnToList()">
        <pureui-svg class="pstg-action-icon back-icon" [svg]="'arrow-left.svg'" [height]="15"></pureui-svg>
    </div>
    <pureui-svg class="pstg-action-icon profile-icon" [svg]="'user.svg'" [height]="30"></pureui-svg>
    <div class="profile-text"> {{ currentUser?.email }} </div>
</div>
<stateless-submenu-bar [submenuItems]="submenuItems" (selectedItemChanged)="selectedSubmenuItemChanged($event)">
</stateless-submenu-bar>
<ng-container *ngIf="selectedSubmenuItem.title === 'User Details'">
    <div id="form" class="form" *ngIf="status === 'form'">
        <div class="profile">
            <um-user-form
                *ngIf="!isSsoUser(); else ssoUser"
                [currentUser]="currentUser"
                (userSaved)="onUserSaved($event)"
                (cancelled)="onCancelled()"
                (error)="onError($event)"
            ></um-user-form>

            <ng-template #ssoUser>
                <um-sso-user-form [currentUser]="currentUser" (cancelled)="onCancelled()"></um-sso-user-form>
            </ng-template>

            <div class="profile-options row">
                <div class="col-xs-3"></div>
                <div class="col-xs-9 btn-toolbar">
                    <span
                        class="btn profile-option-button reset-password"
                        *ngIf="!isSsoUser()"
                        (click)="resetPassword()"
                        angulartics2On="click"
                        angularticsAction="UM - Reset password"
                        angularticsCategory="Action"
                    >
                        Reset Password
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div id="error" class="result-message error" *ngIf="status === 'error'">
        <pureui-svg class="pstg-secondary-icon result-icon" [svg]="'escalation.svg'" [height]="74"></pureui-svg><br />
        <h2>Permissions error, please contact support.</h2>
    </div>
    <div
        id="success-reset-password"
        class="result-message success-reset-password"
        *ngIf="status === 'successResetPassword'"
    >
        <div class="result-reset-password-name">{{ currentUser.name }}</div>
        <div class="result-reset-password-question"
            >An email was sent to<br /><span class="result-reset-password-email">{{ currentUser.email }}</span
            ><br />with instructions on how<br />to reset the password.</div
        >
        <button (click)="returnToForm()" class="btn btn-info result-delete-reject">OK</button>
    </div>
</ng-container>
<account-security-section *ngIf="selectedSubmenuItem.title === 'Account Security'" [currentUser]="currentUser">
</account-security-section>
