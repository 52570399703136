<section class="invoice-table-container" #targetElement>
    <hive-table
        dataKey="id"
        [scrollable]="true"
        scrollHeight="calc(100% - 50px)"
        [value]="data"
        [selection]="selectedInvoice ? [selectedInvoice] : null"
        selectionMode="single"
        [sort]="sort"
        (selectionChange)="onSelectionChange($event.data)"
        (sortChange)="onSortChange($event)"
    >
        <div class="table-top-actions">
            <div class="actions-left-section">
                <div class="export-action" ngbTooltip="Export" container="body">
                    <ng-template #invoiceExportModal let-modal>
                        <invoice-export-modal
                            [modal]="modal"
                            [selectedOption]="selectedExportOption"
                            [invoiceFilter]="invoiceFilter"
                            [totalFiltered]="totalItemCount"
                            [totalUnfiltered]="totalUnfiltered"
                            [selectedRangeFilters]="selectedRangeFilters"
                        >
                        </invoice-export-modal>
                    </ng-template>
                    <export-button
                        class="export-btn"
                        [showNewIcon]="true"
                        [showText]="false"
                        (updateCounts)="updateExportOptionCounts($event.optionsMap)"
                        [exportOptions]="listViewExportOptions"
                        (onClickExport)="onClickExport($event.option)"
                    >
                    </export-button>
                </div>
            </div>
            <div class="table-right-actions">
                <section class="time-filter">
                    <invoice-management-range-filters
                        (selectedRangeFiltersChange)="onTimeFilterChanges($event)"
                        [selectedRangeFilters]="selectedRangeFilters"
                    ></invoice-management-range-filters>
                </section>
                <div class="vertical-bar"></div>
                <hive-pagination
                    [itemsPerPage]="itemsPerPage"
                    [itemsPerPageOptions]="pageSizes"
                    [offset]="offset"
                    [totalItemCount]="totalItemCount"
                    [pageSizeDropdownVisible]="true"
                    (pageChange)="onPageChange($event)"
                    (itemsPerPageChange)="onItemsPerPageChange($event)"
                >
                </hive-pagination>
                <div class="vertical-bar"></div>
                <hive-column-toggle></hive-column-toggle>
            </div>
        </div>
        <ng-template hiveTemplate="header">
            <tr>
                <th
                    hive-header
                    [colWeight]="2"
                    colWidth="120px"
                    id="id"
                    sortKey="id"
                    primaryText="Invoice Number"
                    visibilityMode="column-toggle-disabled"
                >
                </th>
                <th hive-header id="status" sortKey="status" primaryText="Invoice Status"> </th>
                <th hive-header id="orderNumber" sortKey="partner_purchase_order" primaryText="Purchase Order Numbers">
                </th>
                <th hive-header id="orgName" sortKey="end_user_name" primaryText="Customer Name"> </th>
                <th hive-header id="creationDate" sortKey="date" primaryText="Invoice Date"> </th
                ><th hive-header id="dueDate" sortKey="due_date" primaryText="Invoice Due Date"> </th>
                <th hive-header id="address" sortKey="ship_to_city" primaryText="Ship to Address"> </th>
                <th hive-header id="contractNumber" sortKey="subscription.name" primaryText="Contract Number"> </th>
                <th hive-header id="paymentFrequency" sortKey="payment_frequency" primaryText="Payment Frequency"> </th
                ><th hive-header id="transactionType" sortKey="transaction_type" primaryText="Transaction Type"> </th>
            </tr>
        </ng-template>
        <ng-template hiveTemplate="body" let-invoice>
            <tr [hiveTableRow]="invoice" selectable>
                <td hive-cell [primaryText]="invoice.id" [primaryTextTooltip]="invoice.id"> </td>
                <td hive-cell [primaryText]="invoice.status" [primaryTextTooltip]="invoice.status"> </td>
                <td hive-cell [primaryText]="invoice.partnerPo" [primaryTextTooltip]="invoice.partnerPo.join(', ')">
                </td>
                <td hive-cell [primaryText]="invoice.customerName" [primaryTextTooltip]="invoice.customerName"> </td>
                <td
                    hive-cell
                    [primaryText]="invoice.date | date: 'yyyy-MM-dd' : 'UTC'"
                    [primaryTextTooltip]="invoice.date | date: 'yyyy-MM-dd' : 'UTC'"
                >
                </td>
                <td
                    hive-cell
                    [ngClass]="{ expired: isOpenedInvoiceExpired(invoice.dueDate, invoice.status) }"
                    [primaryText]="invoice.dueDate | date: 'yyyy-MM-dd' : 'UTC'"
                    [primaryTextTooltip]="invoice.dueDate | date: 'yyyy-MM-dd' : 'UTC'"
                >
                </td>
                <td
                    hive-cell
                    [primaryText]="invoice | invoiceAddress | defaultString"
                    [primaryTextTooltip]="invoice | invoiceAddress | defaultString"
                >
                </td>
                <td
                    hive-cell
                    [primaryText]="invoice.contractNumber | defaultString"
                    [primaryTextTooltip]="invoice.contractNumber | defaultString"
                >
                </td>
                <td
                    hive-cell
                    [primaryText]="invoice.paymentFrequency | defaultString"
                    [primaryTextTooltip]="invoice.paymentFrequency | defaultString"
                >
                </td>
                <td hive-cell [primaryText]="invoice.transactionType" [primaryTextTooltip]="invoice.transactionType">
                </td>
            </tr>
        </ng-template>
    </hive-table>
</section>

<hive-page-draggable-splitter [targetElement]="targetElement" *ngIf="selectedInvoice"></hive-page-draggable-splitter>

<hive-details-panel [closable]="false" *ngIf="selectedInvoice">
    <div class="invoice-wrapper">
        <invoice-details [invoice]="selectedInvoice"></invoice-details>
    </div>
</hive-details-panel>
