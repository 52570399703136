<div class="expanded-card-health-container">
    <!-- Always shown -->
    <div class="expanded-card-health-dashboard-flashblade" [ngClass]="{ 'expand-summary': isExpanded }">
        <fb-hw-efm-front
            *ngFor="let efm of dashboardHardware.efms; trackBy: 'id' | trackByProperty"
            (click)="onClickEfm(efm)"
            class="flashblade-component hardware-summary"
            [ngClass]="{ 'selected-hardware-summary': showSelectedEfm(efm) }"
            [efm]="efm"
        >
        </fb-hw-efm-front>

        <fb-hw-chassis-front
            *ngFor="let chassis of dashboardHardware.chassis; trackBy: 'id' | trackByProperty"
            (click)="onClickChassis(chassis)"
            class="flashblade-component hardware-summary"
            [ngClass]="{ 'selected-hardware-summary': showSelectedBox(chassis) }"
            [chassis]="chassis"
        >
        </fb-hw-chassis-front>
    </div>

    <!-- Shown only when expanded -->
    <div class="expanded-card-health-graph-container">
        <div class="expanded-card-health-graph-label" *ngIf="efmVisible; else chassisLabel"
            >XFM {{ selectedEfm.index }}</div
        >
        <ng-template #chassisLabel>
            <div class="expanded-card-health-graph-label">Chassis {{ selectedChassis.index }}</div>
        </ng-template>

        <div class="flashblade-labels">
            <div class="front">Front</div>
            <div class="back">Rear</div>
        </div>

        <div class="flashblade-graphics" *ngIf="efmVisible; else chassisGraphics">
            <div class="efm-front">
                <fb-hw-efm-front [efm]="selectedEfm"></fb-hw-efm-front>
            </div>
            <div class="efm-back">
                <fb-hw-efm-back [efm]="selectedEfm"></fb-hw-efm-back>
            </div>
        </div>

        <ng-template #chassisGraphics>
            <div class="flashblade-graphics" [ngClass]="{ generation2: isGeneration2(selectedChassis) }">
                <div class="front">
                    <fb-hw-chassis-front class="flashblade" [chassis]="selectedChassis"></fb-hw-chassis-front>
                </div>
                <div class="back">
                    <fb-hw-chassis-back [chassis]="selectedChassis"></fb-hw-chassis-back>
                </div>
            </div>
        </ng-template>
    </div>
</div>
