<ng-container [formGroup]="contactForm">
    <h1 class="section-header">Contacts</h1>
    <div>
        <pureui-spinner [pureuiShow]="loading || parentLoading"></pureui-spinner>
        <div [ngStyle]="{ visibility: loading || parentLoading ? 'hidden' : 'visible' }">
            <div class="row">
                <div class="col-xs-4">
                    <label class="support-create-label required"> Primary Contact Email </label>
                </div>
                <div class="col-xs-8">
                    <search-contact formControlName="primary" [contacts]="contacts"></search-contact>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-4">
                    <label class="support-create-label"> Secondary Contact Email </label>
                </div>
                <div class="col-xs-8">
                    <search-contact formControlName="alternate" [contacts]="contacts"></search-contact>
                </div>
            </div>
        </div>
    </div>
</ng-container>
