import { Directive, Input, HostBinding, OnInit } from '@angular/core';

@Directive({
    selector: 'a[arrayHref]',
    host: {
        target: '_blank', // open link in new tab
    },
})
export class ArrayHrefDirective implements OnInit {
    @Input('arrayHref') readonly arrayId: string;
    @HostBinding('href') href: string;

    ngOnInit(): void {
        this.href = '/api/1.0/array-sso/redirect?id=' + this.arrayId;
    }

    ngOnChanges(): void {
        this.href = '/api/1.0/array-sso/redirect?id=' + this.arrayId;
    }
}
