export type ScheduleType = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'NO_REPEAT';

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export class ReportSchedule {
    day_of_month: number;
    day_of_week: DayOfWeek;
    enabled: boolean;
    generation_time: number;
    schedule_type: ScheduleType;
    time_zone: string;

    constructor(json) {
        this.day_of_month = json.day_of_month;
        this.day_of_week = json.day_of_week;
        this.enabled = json.enabled;
        this.generation_time = json.generation_time;
        this.schedule_type = json.schedule_type;
        this.time_zone = json.time_zone;
    }
}
