import moment from 'moment';
import { Resource } from '../interfaces/resource';

export class Session implements Resource {
    readonly name: string;
    readonly id: string;
    readonly array: Resource;
    readonly start_time: moment.Moment;
    readonly end_time: moment.Moment;
    readonly event: string;
    readonly count?: number;
    readonly location: string;
    readonly method: string;
    readonly source: 'array' | 'pure1';
    readonly user: string;
    readonly user_interface: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.start_time = json.start_time && moment.unix(json.start_time / 1000);
        this.end_time = json.end_time && moment.unix(json.end_time / 1000);
        this.event = json.event;
        this.count = json.count || undefined;
        this.location = json.location;
        this.method = json.method;
        this.source = json.source || 'array';
        this.user = json.user;
        this.user_interface = json.user_interface;
        if (json.arrays && json.arrays[0]) {
            this.array = {
                name: json.arrays[0].name,
                id: json.arrays[0].id,
            };
        } else {
            this.source = 'pure1'; // workaround until backend returns 'source' field
            this.array = {
                name: '',
                id: '',
            };
        }
    }
}
