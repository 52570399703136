<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.close()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <h3 class="modal-title"> Export Status </h3>
</div>

<div class="modal-body">
    <div class="container-fluid" *ngIf="!exportTask.isFailed">
        <!-- Filename -->
        <div class="row">
            <div class="col-xs-3 text-xs-right">
                <strong>Name</strong>
            </div>
            <div class="col-xs-9" style="word-break: break-all">
                {{ exportTask.fileName }}
            </div>
        </div>

        <!-- Time started -->
        <div class="row">
            <div class="col-xs-3 text-xs-right">
                <strong>Started</strong>
            </div>
            <div class="col-xs-9">
                <span
                    [ngbTooltip]="exportTask.createdTime.toDate() | date: 'yyyy-MM-dd HH:mm:ss'"
                    placement="top"
                    tooltipClass="tooltip-top"
                    container="body"
                >
                    {{ exportTask.createdTime.fromNow(false) }}
                </span>
            </div>
        </div>
    </div>

    <!-- Failed to create export -->
    <div class="container-fluid" *ngIf="exportTask.isFailed">
        <div class="row">
            <div class="media">
                <div class="media-left">
                    <pureui-svg
                        class="pstg-secondary-icon pstg-alert-warning-icon"
                        svg="alert_warning.svg"
                        height="30"
                    ></pureui-svg>
                </div>
                <div class="media-body">
                    We could not complete your export request due to a temporary server problem. Please try again.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        (click)="cancelExport()"
        angulartics2On="click"
        angularticsAction="Export overlay - Click cancel export"
        angularticsCategory="Action"
    >
        <span *ngIf="!exportTask.isFailed">Cancel Export</span>
        <span *ngIf="exportTask.isFailed">OK</span>
    </button>
</div>
