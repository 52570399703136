import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class UsersService extends GenericService<User> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: User,
            endpoint: '/rest/v2/users',
            create: true,
            update: true,
            delete: true,
        });
    }
}
