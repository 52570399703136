import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { WINDOW } from '../../app/injection-tokens';
import { PlatformClientService } from '@pure/pure1-ui-platform-angular';

@Component({
    selector: 'tou-modal',
    templateUrl: './tou-modal.component.html',
})
export class TouModalComponent {
    constructor(
        private ngbActiveModal: NgbActiveModal,
        private platformClientService: PlatformClientService,
    ) {}

    logout(): void {
        this.platformClientService.logout();
    }

    acceptTermsOfUse(): void {
        this.ngbActiveModal.close();
    }
}
