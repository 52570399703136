import { Resource } from '../interfaces/resource';

import { StorageEntity } from './storage-entity';

export type ComplianceStatus = 'completed' | 'inProgress' | 'delayed' | 'unknown' | 'pausedDisallowed';

export class Volume extends StorageEntity {
    compliance_status: ComplianceStatus;
    arrays: Resource[];
    source_type: string;

    constructor(json: any) {
        super(json);
        this.compliance_status = json.compliance_status;
        this.arrays = json.arrays;
        this.source_type = json.source_type;
    }
}
