import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'map-modal-component',
    templateUrl: './map-modal.component.html',
})
export class MapModalComponent {
    @Input() readonly message: string;

    constructor(public activeModal: NgbActiveModal) {}
}
