import moment from 'moment';
import {
    DraasApiStepExecutionInfo,
    DraasApiPercentageJobStepProgress,
    DraasApiLocalCopyJobStepProgress,
    DraasApiReplicationJobStepProgress,
} from '@pure/paas-api-gateway-client-ts';

export type DisasterRecoveryJobExecutionStepStatus =
    | 'CANCELED'
    | 'COMPLETE'
    | 'FAILED'
    | 'PAUSED'
    | 'PENDING'
    | 'RUNNING'
    | 'VOLATILE'
    | 'UNKNOWN';

export class DisasterRecoveryJobExecutionStep {
    key: string;
    description: string[];
    startTime: moment.Moment;
    duration: moment.Duration;
    status: DisasterRecoveryJobExecutionStepStatus;
    percentageProgress: number;
    localCopyProgress: DisasterRecoveryJobStepProgressWithBytes | undefined;
    replicationProgress: DisasterRecoveryJobStepProgressWithBytes | undefined;

    constructor(json: DraasApiStepExecutionInfo, jobExecutionId: string, stepIdx: number) {
        this.key = jobExecutionId + ':' + stepIdx;
        this.description = [];
        if (json.description) {
            this.description.push(json.description);
        }
        if (json.progress_message) {
            this.description.push(...json.progress_message.split(/[\r\n]+/));
        }
        this.startTime = json.start ? moment.utc(json.start) : null;
        this.duration = json.start && json.end ? moment.duration(moment.utc(json.end).diff(this.startTime)) : null;
        this.status = json.status as DisasterRecoveryJobExecutionStepStatus;
        this.percentageProgress = json.progress_details?.find(isPercentageJobStepProgress)?.current_progress || 0;
        this.localCopyProgress = json.progress_details
            ?.filter(isLocalCopyJobStepProgress)
            ?.map(progress => new DisasterRecoveryJobStepProgressWithBytes(progress))
            .pop();
        this.replicationProgress = json.progress_details
            ?.filter(isReplicationJobStepProgress)
            ?.map(progress => new DisasterRecoveryJobStepProgressWithBytes(progress))
            .pop();
    }
}

export function isPercentageJobStepProgress(
    progress: DraasApiLocalCopyJobStepProgress | DraasApiReplicationJobStepProgress | DraasApiPercentageJobStepProgress,
): progress is DraasApiPercentageJobStepProgress {
    return progress.type === 'PercentageJobStepProgress';
}

export function isLocalCopyJobStepProgress(
    progress: DraasApiLocalCopyJobStepProgress | DraasApiReplicationJobStepProgress | DraasApiPercentageJobStepProgress,
): progress is DraasApiLocalCopyJobStepProgress {
    return progress.type === 'LocalCopyJobStepProgress';
}

export function isReplicationJobStepProgress(
    progress: DraasApiLocalCopyJobStepProgress | DraasApiReplicationJobStepProgress | DraasApiPercentageJobStepProgress,
): progress is DraasApiReplicationJobStepProgress {
    return progress.type === 'ReplicationJobStepProgress';
}

export class DisasterRecoveryJobStepProgressWithBytes {
    startTime: moment.Moment | null;
    endTime: moment.Moment | null;
    currentBytes: number;
    totalBytes: number;

    constructor(progress: DraasApiLocalCopyJobStepProgress | DraasApiReplicationJobStepProgress) {
        this.startTime = progress.start_time ? moment.utc(progress.start_time) : null;
        this.endTime = progress.end_time ? moment.utc(progress.end_time) : null;
        this.currentBytes = progress.current_bytes;
        this.totalBytes = progress.total_bytes;
    }
}
