import moment from 'moment';
import { Component, Input, TemplateRef, QueryList, OnInit, AfterContentInit } from '@angular/core';
import { NgbCarouselConfig, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserNotification } from '../../../model/user-notification';
import { WarningBannerNotification } from '../../../spotlight-banner/base-banner';

const CAROUSEL_INTERVAL = moment.duration(10, 'seconds');

// Hide Pure navigation arrows for these notification IDs
const HIDE_PURE_NAVIGATION_IDS = [];

@Component({
    selector: 'pure-carousel',
    templateUrl: 'pure-carousel.component.html',
    providers: [NgbCarouselConfig],
})
export class PureCarouselComponent implements OnInit, AfterContentInit {
    protected showPureNavigationArrows = true;

    @Input() readonly slideTemplates: QueryList<TemplateRef<any>>;
    @Input() readonly slideNotifications: (UserNotification | WarningBannerNotification)[];

    constructor(private carouselConfig: NgbCarouselConfig) {}

    ngOnInit(): void {
        this.carouselConfig.showNavigationArrows = false;
        this.carouselConfig.showNavigationIndicators = true;
        this.carouselConfig.interval = CAROUSEL_INTERVAL.asMilliseconds();
    }

    // For handling the first slide in the carousel (index 0)
    // This only works for "standard" notifications, so warning notifications will never hide the arrows on initialization
    ngAfterContentInit(): void {
        if (this.slideNotifications?.[0] instanceof UserNotification) {
            const startingSlideNotificationID = this.slideNotifications[0].id;
            this.showPureNavigationArrows = !HIDE_PURE_NAVIGATION_IDS.includes(startingSlideNotificationID);
        }
    }

    // For getting information on subsequent slide changes after the initialization of the carousel
    onCarouselSlideChange(event: NgbSlideEvent): void {
        const slideIndex = parseInt(event.current.replace('ngb-slide-', ''));
        if (this.slideNotifications && this.slideNotifications.length > slideIndex) {
            const currentSlideNotificationID =
                this.slideNotifications[slideIndex] instanceof UserNotification
                    ? (this.slideNotifications[slideIndex] as UserNotification).id
                    : '';
            this.showPureNavigationArrows = !HIDE_PURE_NAVIGATION_IDS.includes(currentSlideNotificationID);
        }
    }
}
