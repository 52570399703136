import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { DpaSafeMode } from '../models/dpa-safe-mode';

const defaultParams = {
    pageStart: 0,
    pageSize: 1000,
};

@Injectable({ providedIn: 'root' })
export class DpaSafeModeService extends GenericService<DpaSafeMode> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: DpaSafeMode,
            defaultParams: defaultParams,
            endpoint: '/rest/v1/dpa/safe_mode',
        });
    }
}
