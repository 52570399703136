/**
 * Counts the number of unicode symbols in a string.
 * It does not normalize the string, so the count for two visually identical string can be different.
 */
export function getUnicodeLength(str: string): number {
    if (typeof str !== 'string') {
        return 0;
    }
    let len = str.length;
    for (let i = len - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        if (code >= 0xdc00 && code <= 0xdfff) {
            len--;
            i--;
        }
    }
    return len;
}

export function truncate(input: string, limit = 140): string {
    if (typeof input !== 'string') {
        return input;
    }
    if (getUnicodeLength(input) > limit) {
        let count = 0;
        let i = 0;
        while (count !== limit) {
            const code = input.charCodeAt(i);
            if (code >= 0xd800 && code < 0xe000) {
                i++;
            }
            i++;
            count++;
        }
        input = input.slice(0, i) + '…';
    }
    return input;
}

export function htmlEntities(str: string): string {
    if (typeof str !== 'string') {
        return str;
    }

    return str.replace(/(&|>|<|")/g, function (char: string): string {
        return `&#${char.charCodeAt(0)};`;
    });
}
