import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { differenceWith, isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { GOOGLE_MAPS } from '../../../app/injection-tokens';
import { MAP_STYLE } from '../../../arrays/arrays-map-view/arrays-map-view/google-maps-styling';
import { GoogleMapsService } from '../../../services/google-maps.service';

type GoogleMaps = typeof google.maps;

const DEFAULT_MARKER_ICON_PATH = '/images/map-marker-default.svg';
const SELECTED_MARKER_ICON_PATH = '/images/map-marker-selected.svg';

const MARKER_HEIGHT = 52;
const MARKER_WIDTH = 40;
const MARKER_LABEL_OFFSET = 20;
const MAX_ZOOM_LEVEL = 19;
const MIN_ZOOM_LEVEL = 3;

/**
 * Universal map-view directive
 * This map-view directive don't care about data-format which you providing, has no information about layout and well-extendable.
 * Example of usage
 *     <div [mapView]="datacentersWithLocation" [getMarkerData]="getMarkerData"></div>
 */
@Directive({
    selector: '[mapView]',
})
export class MapViewDirective<T> implements OnInit, OnDestroy {
    /**
     * getMarkerData is a function which converts your object into marker for a map view
     * As example: {id: 123, location: [50, 0]} -> {label: '123', position: {lat: 50, lng: 0}}
     */
    @Input() readonly getMarkerData: (item: T) => Pick<google.maps.MarkerOptions, 'label' | 'position'>;
    @Input() readonly tooltipTemplateRef?: TemplateRef<{ $implicit: T }>;
    @Input() readonly controlsTemplateRef?: TemplateRef<unknown>;
    @Output('markerClicked') readonly markerClicked = new EventEmitter<T>();
    readonly maxZoom = MAX_ZOOM_LEVEL;
    readonly minZoom = MIN_ZOOM_LEVEL;
    readonly markers = new Map<T, google.maps.Marker>();
    readonly infoWindows = new Map<T, google.maps.InfoWindow>();
    private map?: google.maps.Map;
    private googleMaps: GoogleMaps;
    private controlsView?: EmbeddedViewRef<unknown>;

    @Input() set mapView(items: T[]) {
        if (items !== null) {
            this.items$.next(items);
        }
    }

    @Input() set offset(value: [x: number, y: number] | undefined) {
        this.offset$.next(value);
    }

    get zoom(): number | undefined {
        return this.map?.getZoom?.();
    }

    set zoom(value: number | undefined) {
        this.map?.setZoom?.(value);
    }

    get zoomInDisabled(): boolean {
        return this.zoom >= MAX_ZOOM_LEVEL;
    }

    get zoomOutDisabled(): boolean {
        return this.zoom <= MIN_ZOOM_LEVEL;
    }

    get mapTypeId(): string | undefined {
        return this.map?.getMapTypeId();
    }

    set mapTypeId(value: string | undefined) {
        this.map?.setMapTypeId(value);
    }

    private readonly isDestroyed$ = new Subject<void>();
    private readonly isInitialized$ = new ReplaySubject<void>();
    private readonly items$ = new ReplaySubject<T[]>();
    private readonly offset$ = new BehaviorSubject<[x: number, y: number] | undefined>(undefined);

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly elementRef: ElementRef,
        @Inject(GOOGLE_MAPS) private readonly googleMapsPromise: Promise<GoogleMaps>,
        private readonly googleMapsService: GoogleMapsService,
    ) {}

    ngOnDestroy(): void {
        this.isDestroyed$.next();
    }

    ngOnInit(): void {
        combineLatest([this.items$, this.offset$, this.isInitialized$])
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe(([items, offset]) => {
                if (items?.length) {
                    this.updateRequiredItems(items);
                    this.centerMap(offset);
                }
            });
        this.isDestroyed$
            .pipe(
                take(1),
                switchMap(() => this.items$),
            )
            .subscribe(items => {
                this.destroyMapView(items);
            });
        this.googleMapsPromise.then(googleMaps => {
            this.googleMaps = googleMaps;
            this.initGoogleMaps();
            this.buildControls();
            this.isInitialized$.next();
        });
    }

    zoomIn(): void {
        this.zoom = this.zoom + 1;
    }

    zoomOut(): void {
        this.zoom = this.zoom - 1;
    }

    toggleMapTypeId(): void {
        if (this.mapTypeId === 'roadmap') {
            this.mapTypeId = 'satellite';
        } else {
            this.mapTypeId = 'roadmap';
        }
    }

    /**
     * We update only changed items, items which were previously in the list aren't affected by update
     */
    updateRequiredItems(items: T[]): void {
        const itemsToBeRemoved = differenceWith(Array.from(this.markers.keys()), items, isEqual);
        const itemsToBeAdded = differenceWith(items, Array.from(this.markers.keys()), isEqual);
        for (const item of itemsToBeRemoved) {
            this.removeItemFromMap(item);
        }
        for (const item of itemsToBeAdded) {
            const infoWindow = this.createInfoWindow(item);
            this.createMarker(item, infoWindow);
        }
    }

    unselectAllMarkers(): void {
        const defaultMarkerIcon = {
            labelOrigin: new this.googleMaps.Point(MARKER_LABEL_OFFSET, MARKER_LABEL_OFFSET),
            scaledSize: new this.googleMaps.Size(MARKER_WIDTH, MARKER_HEIGHT),
            url: DEFAULT_MARKER_ICON_PATH,
        };
        this.markers.forEach(m => m.setIcon(defaultMarkerIcon));
    }

    destroyMapView(items: T[]): void {
        for (const item of items) {
            this.removeItemFromMap(item);
        }
        this.controlsView?.destroy();
        if (this.map) {
            this.map.controls[this.googleMaps.ControlPosition.RIGHT_BOTTOM]?.clear();
            this.googleMapsService.releaseMap(this.map);
        }
    }

    removeItemFromMap(item: T): void {
        if (this.infoWindows.has(item)) {
            this.infoWindows.get(item).close();
            this.infoWindows.delete(item);
        }
        if (this.googleMaps && this.markers.has(item)) {
            const marker = this.markers.get(item);
            this.googleMaps.event.clearInstanceListeners(marker);
            marker.setMap(null);
            this.markers.delete(item);
        }
    }

    createInfoWindow(item: T): google.maps.InfoWindow | undefined {
        this.changeDetectorRef.detach();
        if (!this.tooltipTemplateRef) {
            return undefined;
        }
        const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef, { $implicit: item });
        view.detectChanges();
        const infoWindow = new this.googleMaps.InfoWindow({
            content: view.rootNodes.find(node => node.tagName),
            maxWidth: 250,
        });
        this.infoWindows.set(item, infoWindow);
        view.destroy();
        this.changeDetectorRef.reattach();
        return infoWindow;
    }

    createMarker(item: T, infoWindow?: google.maps.InfoWindow): google.maps.Marker | undefined {
        if (!this.googleMaps) {
            return undefined;
        }
        const defaultMarkerIcon = {
            labelOrigin: new this.googleMaps.Point(MARKER_LABEL_OFFSET, MARKER_LABEL_OFFSET),
            scaledSize: new this.googleMaps.Size(MARKER_WIDTH, MARKER_HEIGHT),
            url: DEFAULT_MARKER_ICON_PATH,
        };
        const selectedMarkerIcon = {
            labelOrigin: new this.googleMaps.Point(MARKER_LABEL_OFFSET, MARKER_LABEL_OFFSET),
            scaledSize: new this.googleMaps.Size(MARKER_WIDTH, MARKER_HEIGHT),
            url: SELECTED_MARKER_ICON_PATH,
        };
        const marker = new this.googleMaps.Marker({
            icon: defaultMarkerIcon,
            map: this.map,
            label: item.toString(),
            ...this.getMarkerData?.(item),
        });
        this.markers.set(item, marker);
        if (infoWindow) {
            marker.addListener('mouseover', () => {
                this.infoWindows.forEach(iW => iW.close());
                infoWindow.open(this.map, marker);
                marker.setIcon(selectedMarkerIcon);
            });
            marker.addListener('mouseout', () => {
                infoWindow.close();
                marker.setIcon(defaultMarkerIcon);
            });
            infoWindow.addListener('closeclick', () => {
                marker.setIcon(defaultMarkerIcon);
            });
        }
        marker.addListener('click', () => {
            this.unselectAllMarkers();
            this.markerClicked.emit(item);
        });
        return marker;
    }

    centerMap(offset?: [x: number, y: number]): void {
        let bounds = new this.googleMaps.LatLngBounds();
        if (this.markers.size === 0) {
            // Coordinates perfectly handcrafted with the finest techniques to fit most of the world
            bounds = new this.googleMaps.LatLngBounds(
                new google.maps.LatLng(75, -155),
                new google.maps.LatLng(-60, 180),
            );
        }
        this.markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });
        this.map.fitBounds(bounds);
        if (offset && offset.length === 2) {
            this.map.panBy(...offset);
        }
    }

    private initGoogleMaps() {
        try {
            this.map = this.googleMapsService.getMap({
                clickableIcons: false,
                fullscreenControl: false,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                scaleControl: false,
                panControl: false,
                rotateControl: false,
                maxZoom: this.maxZoom,
                minZoom: this.minZoom,
                styles: MAP_STYLE,
            });
            const nativeElement: HTMLElement = this.elementRef.nativeElement;
            nativeElement.appendChild(this.map.getDiv());
        } catch (error) {
            console.error('Google Maps cannot be initialized');
            throw error;
        }
    }

    private buildControls(): void {
        this.controlsView?.destroy();
        if (this.controlsTemplateRef) {
            this.controlsView = this.viewContainerRef.createEmbeddedView(this.controlsTemplateRef);
            this.controlsView.detectChanges();
            for (const node of this.controlsView.rootNodes) {
                if (node.nodeType === 1) {
                    this.map.controls[this.googleMaps.ControlPosition.RIGHT_BOTTOM].push(node);
                }
            }
        }
    }
}
