/** Represents a pair {Array, Mitigation} in the ATOM service. In the Pure1 Manage world, especially in the UI design,
 * a mitigation is called "optimization".
 */
import { Theme } from '../../ui/styles/theme';
import { Resource } from '../interfaces/resource';

export class Mitigation implements Resource {
    id: string;
    name: string;

    arrayId: string;
    purityVersion: string;
    optimizationName: string;
    optimizationStatus: MitigationStatus;
    severity: MitigationSeverity;
    approvedBy: string;
    optedIn: boolean;
    optInTime: Date;
    method: MitigationMethod;
    installEndTime: Date;
    detectionTime: Date;
    customerName: string;

    // the fields that are not part of the API response, but are added in the UI
    arrayName: string;
    arrayModel: string;
    optimizationDescription: string;
    optimizationKbLink: string;
    type: MitigationType;

    constructor(json: any) {
        this.id = json.arrayId + json.optimizationName; // made up ID to ensure uniqueness
        this.arrayId = json.arrayId;
        this.name = json.optimizationName;
        this.purityVersion = json.purityVersion;
        this.optimizationName = json.optimizationName;
        this.optimizationStatus = json.optimizationStatus;
        this.severity = json.severity;
        this.approvedBy = json.approvedBy;
        this.optedIn = json.optedIn;
        this.optInTime = new Date(json.optInTime);
        this.method = json.method;
        this.installEndTime = new Date(json.installEndTime);
        this.detectionTime = new Date(json.detectionTime);
        this.customerName = json.customerName;
        this.type = json.type;
    }
}

export enum MitigationSeverity {
    CRITICAL = 'CRITICAL',
    WARNING = 'WARNING',
    INFO = 'INFO',
    UNKNOWN = 'UNKNOWN',
}

enum MitigationMethod {
    ATOM_VATS = 'atom_vats',
    PURESW = 'puresw',
    MANUAL = 'manual',
    UNKNOWN = 'unknown',
}

export enum MitigationStatus {
    AVAILABLE = 'needs_optimization', // array has an optimization available but hasn't been opted in yet
    PENDING = 'pending', // array has been opted in for this optimization, but the patching didn't start yet
    IN_PROGRESS = 'processing', // the optimization is being applied in the array
    COMPLETED = 'succeeded', // the optimization was applied successfully
    FAILED = 'failed', // there was a failure while applying the optimization
    MANUAL = 'manual', // there was a failure while applying the optimization automatically, and a later successful manual application (by support)
    EXPIRED = 'expired', // the optimization was opted in, but the RA session was not opened on time to execute it (24hs)
    UPGRADED = 'upgraded', // the optimization is no longer needed because a Purity upgrade fixed the issue
}

export enum MitigationType {
    SECURITY_PATCH = 'security_patch',
    OPTIMIZATION = 'optimization',
    OTHER = 'other',
}

// Readable labels and colors for each optimization status

export const STATUS_LABELS = {
    [MitigationStatus.AVAILABLE]: 'Needs Optimization',
    [MitigationStatus.PENDING]: 'Opted-In',
    [MitigationStatus.IN_PROGRESS]: 'In Progress',
    [MitigationStatus.COMPLETED]: 'Completed',
    [MitigationStatus.FAILED]: 'Failed',
    [MitigationStatus.MANUAL]: 'Manual',
    [MitigationStatus.EXPIRED]: 'Expired',
    [MitigationStatus.UPGRADED]: 'Upgraded',
};

export const STATUS_COLORS = {
    [MitigationStatus.AVAILABLE]: Theme.purityOptimizations.cards.eligible,
    [MitigationStatus.PENDING]: Theme.purityOptimizations.cards.optedIn,
    [MitigationStatus.IN_PROGRESS]: Theme.purityOptimizations.cards.optedIn,
    [MitigationStatus.COMPLETED]: Theme.purityOptimizations.cards.completed,
    [MitigationStatus.FAILED]: Theme.purityOptimizations.cards.failed,
    [MitigationStatus.MANUAL]: Theme.purityOptimizations.cards.completed,
    [MitigationStatus.EXPIRED]: Theme.purityOptimizations.cards.eligible,
    [MitigationStatus.UPGRADED]: Theme.purityOptimizations.cards.completed,
};

export interface MitigationCardData {
    name: string;
    description: string;
    totalOptedIn: number;
    totalEligible: number;
    totalCompleted: number;
    total: number;
    type: MitigationType;
}

export interface MitigationDetail {
    softwareName: string;
    softwareInternalName: string;
    platform: string;
    entryScript: string;
    description: string;
    softwareMetaId: number;
    alertCode: number;
    haReductionRequired: boolean;
    type: MitigationType;
}

export interface listMitigationsOptions {
    showCompleted: boolean;
    criticalOnly: boolean;
}
