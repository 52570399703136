import { Resource } from '../interfaces/resource';
import { FlexHardwareCapacity } from './flex-hardware-capacity';

export class FlexHardwareModel implements Resource {
    // id & name needed for Resource implementation
    id: string;
    name: string;

    model: string;
    capacity: FlexHardwareCapacity[];

    constructor(json: any) {
        this.id = json.product_id;
        this.name = json.product_name;
        this.model = json.model;
        this.capacity = (json.capacity || []).map(cap => new FlexHardwareCapacity(cap));
    }
}
