import moment from 'moment';
import { flatMap } from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IRestResponse,
    Resource,
    DataPage,
    SimulatedVolume,
    QueryParams,
    CapacityConfig,
    isPhantomArray,
    FlashBladeCapacityConfig,
    getTotalRawTB,
    getTotalBladeCount,
} from '@pure1/data';
import { HttpCacheService } from './http-cache.service';
import { BYTES_PER_TIB } from '../forecast/wizards/simulate-license-reserve-level-modal/simulate-license-reserve-level-modal.component';
import { stripResource } from '../forecast/simulation-summary/simulations.service';
import { isFBE, isFBS } from '../utils/marketing';

export const SIMULATION_STEPS_URL = '/rest/v1/forecast/simulation-steps';

const QUERY_PARAM_IDS = 'ids';

type Html = `${string}<${string}>${string}`;

export type ActionType =
    | 'copy_array'
    | 'copy_volumes'
    | 'migrate_volumes'
    | 'migrate_array'
    | 'scale_array'
    | 'scale_volumes'
    | 'place_workloads'
    | 'training_window_capacity'
    | 'training_window_load'
    | 'upgrade_controller_model'
    | 'simulate_flashblade_model'
    | 'upgrade_hardware_capacity'
    | 'upgrade_flashblade_hardware_capacity'
    | 'apply_safe_mode'
    | 'expand_license_reserve_level';

export const HARDWARE_ACTION_TYPES: ActionType[] = ['upgrade_controller_model', 'upgrade_hardware_capacity'];
export const FLASHBLADE_HARDWARE_ACTION_TYPES: ActionType[] = [
    'simulate_flashblade_model',
    'upgrade_flashblade_hardware_capacity',
];
export const TRAINING_WINDOW_ACTION_TYPES: ActionType[] = ['training_window_capacity', 'training_window_load'];
export const WORKLOAD_ACTION_TYPES: ActionType[] = [
    'copy_array',
    'copy_volumes',
    'migrate_array',
    'migrate_volumes',
    'scale_array',
    'scale_volumes',
    'place_workloads',
];
export const WORKLOAD_ACTION_TYPES_ARRAY: ActionType[] = ['copy_array', 'migrate_array', 'scale_array'];
export const WORKLOAD_ACTION_TYPES_SCALING: ActionType[] = ['scale_array', 'scale_volumes'];
export const WORKLOAD_ACTION_TYPES_WITH_TARGET: ActionType[] = [
    'copy_array',
    'copy_volumes',
    'migrate_array',
    'migrate_volumes',
    'place_workloads',
];

export type ActionDetails =
    | CopyMigrateArrayActionDetails
    | CopyMigrateVolumesActionDetails
    | ScaleArrayActionDetails
    | ScaleVolumesActionDetails
    | TrainingWindowActionDetails
    | UpgradeControllerModelActionDetails
    | SimulateFlashBladeModelActionDetails
    | UpgradeHardwareCapacityActionDetails
    | UpgradeFlashBladeHardwareCapacityActionDetails
    | PlaceWorkloadActionDetails
    | ApplySafeModeActionDetails
    | ExpandLicenseReserveLevelActionDetails;

// We can't extend Resource because here, volume_id is a number (long)
// Eventually transition to guids
// Since id here is not globally unique, never use volumeReference as items of tables.
export interface VolumeReference {
    array_id: string;
    id: number;
    name: string;
}

/** Copy or migrate array */
export interface CopyMigrateArrayActionDetails {
    source: Resource;
    target: Resource;
}

export interface MigrateArrayActionDetails {
    source: Resource;
    target: Resource;
}

/** Copy or migrate volumes */
export interface CopyMigrateVolumesActionDetails {
    volumes: VolumeReference[];
    source: Resource;
    target: Resource;
}

export interface ScaleArrayActionDetails {
    scaling_factor: number;
    source: Resource;
}

export interface ScaleVolumesActionDetails {
    scaling_factor: number;
    volumes: VolumeReference[];
}

export interface TrainingWindowActionDetails {
    start: number;
    end: number;
}

export interface UpgradeControllerModelActionDetails {
    controller_model: string;
    is_recommended: boolean;
}

export interface SimulateFlashBladeModelActionDetails {
    model: string;
}

export interface UpgradeHardwareCapacityActionDetails extends CapacityConfig {
    total_capacity_change_pct: number;
    increased_usable_TiB: number;
    is_recommended: boolean;
    increased_raw_TB?: number;
}

export interface UpgradeFlashBladeHardwareCapacityActionDetails extends FlashBladeCapacityConfig {
    increased_usable_TiB: number;
}

export interface PlaceWorkloadActionDetails {
    /** Source phantom workload */
    workloads: Resource[];
    /** Target array */
    target: Resource;
}

export interface ApplySafeModeActionDetails {
    snapshot_policies: SnapshotPolicy[];
}

export interface ExpandLicenseReserveLevelActionDetails {
    license_reserve_level: number;
}

export interface SnapshotPolicy {
    snapshot_frequency: number;
    retention_all_for: number;
    retention_days: number;
    retention_per_day: number;
}

const decimalPipe = new DecimalPipe('en-US');

/**
 * Gets the summary to display in the UI for a step
 * @param arrayId The id of the array we're displaying this in the context of (eg, was a copy from this array, or to this array)
 */
export function getStepDescription(step: SimulationStep, arrayId?: string): string {
    switch (step.action) {
        case 'training_window_capacity':
        case 'training_window_load': {
            const details = <TrainingWindowActionDetails>step.action_details;
            const duration = moment.duration(details.end - details.start);
            const chartName = step.action === 'training_window_capacity' ? 'Capacity' : 'Load';
            return `Changed ${chartName} chart training window to ${Math.floor(duration.asDays())} days`;
        }

        case 'upgrade_hardware_capacity': {
            const details = <UpgradeHardwareCapacityActionDetails>step.action_details;
            const usableTiBStr = decimalPipe.transform(details.increased_usable_TiB, '1.1-1') + ' TiB';
            if (isPhantomArray(step.arrays[0])) {
                return `Usable capacity: ${usableTiBStr}`; // Slightly different phrasing for phantom arrays
            } else if (details.increased_usable_TiB < 0) {
                return `Decreased usable capacity by ${usableTiBStr.slice(1)}`;
            } else {
                return `Increased usable capacity by ${usableTiBStr}`;
            }
        }

        case 'upgrade_flashblade_hardware_capacity': {
            const details = <UpgradeFlashBladeHardwareCapacityActionDetails>step.action_details;
            return getFlashBladeCapacityUpgradeStepDescription(details, isPhantomArray(step.arrays[0]));
        }

        case 'upgrade_controller_model': {
            const details = <UpgradeControllerModelActionDetails>step.action_details;
            if (isPhantomArray(step.arrays[0])) {
                return `Controller: ${details.controller_model}`; // Slightly different phrasing for phantom arrays
            } else {
                return `Changed controller to ${details.controller_model}`;
            }
        }

        case 'simulate_flashblade_model': {
            const details = <SimulateFlashBladeModelActionDetails>step.action_details;
            if (isPhantomArray(step.arrays[0])) {
                return `Model: ${details.model}`; // Slightly different phrasing for phantom arrays
            } else {
                throw new Error('Only Support Phantom FlashBlade Simulation');
            }
        }

        case 'scale_array': {
            const details = <ScaleArrayActionDetails>step.action_details;
            if (details.source.id === step.arrays[0].id) {
                return `Scaled array by ${details.scaling_factor / 100}x`;
            } else {
                return `Scaled volumes from ${details.source.name} by ${details.scaling_factor / 100}x`;
            }
        }

        case 'scale_volumes': {
            const details = <ScaleVolumesActionDetails>step.action_details;
            const plural = details.volumes.length - 1 > 1 ? 's' : '';
            const moreVolumes =
                details.volumes.length > 1 ? `and ${details.volumes.length - 1} more workload${plural} ` : '';
            return `Scaled ${details.volumes[0].name} ` + moreVolumes + `by ${details.scaling_factor / 100}x`;
        }

        case 'copy_volumes': {
            const details = <CopyMigrateVolumesActionDetails>step.action_details;
            const plural = details.volumes.length - 1 > 1 ? 's' : '';
            const copyDirection =
                details.source.id === arrayId ? `to ${details.target.name}` : `from ${details.source.name}`;
            const moreVolumes =
                details.volumes.length > 1 ? `and ${details.volumes.length - 1} more workload${plural} ` : '';
            return `Cloned ${details.volumes[0].name} ` + moreVolumes + `${copyDirection}`;
        }

        case 'copy_array': {
            const details = <CopyMigrateArrayActionDetails>step.action_details;
            const copyDirection =
                details.source.id === arrayId ? `to ${details.target.name}` : `from ${details.source.name}`;
            return `Cloned array workload ${copyDirection}`;
        }

        case 'migrate_volumes': {
            const details = <CopyMigrateVolumesActionDetails>step.action_details;
            const plural = details.volumes.length - 1 > 1 ? 's' : '';
            const copyDirection =
                details.source.id === arrayId ? `to ${details.target.name}` : `from ${details.source.name}`;
            const moreVolumes =
                details.volumes.length > 1 ? `and ${details.volumes.length - 1} more workload${plural} ` : '';
            return `Migrated ${details.volumes[0].name} ` + moreVolumes + `${copyDirection}`;
        }

        case 'migrate_array': {
            const details = <CopyMigrateArrayActionDetails>step.action_details;
            const copyDirection =
                details.source.id === arrayId ? `to ${details.target.name}` : `from ${details.source.name}`;
            return `Migrated array workload ${copyDirection}`;
        }

        case 'place_workloads': {
            const details = <PlaceWorkloadActionDetails>step.action_details;
            const plural = details.workloads.length - 1 > 1 ? 's' : '';
            const moreWorkloads =
                details.workloads.length > 1 ? ` and ${details.workloads.length - 1} more workload${plural} ` : '';
            return `Place new workload "${details.workloads[0].name}"` + moreWorkloads;
        }

        case 'apply_safe_mode': {
            const policy = (step.action_details as ApplySafeModeActionDetails).snapshot_policies[0];
            const pluralize = (count: number, unit: string) => `${count} ${count === 1 ? unit : unit + 's'}`;
            const createOneSnapshotEvery = pluralize(policy.snapshot_frequency, 'hour');
            const retainOnSourceFor = pluralize(policy.retention_all_for, 'day');
            const thenRetain = pluralize(policy.retention_per_day, 'snapshot');
            const snapshotPerDayFor = pluralize(policy.retention_days, 'day');
            return (
                `Applied Snapshot Policy as: ` +
                `"Create a snapshot every ${createOneSnapshotEvery}, retain on source for ${retainOnSourceFor} ` +
                `then retain ${thenRetain} per day for ${snapshotPerDayFor}"`
            );
        }

        case 'expand_license_reserve_level': {
            const details = <ExpandLicenseReserveLevelActionDetails>step.action_details;
            return (
                'Expand reserve level of license ' +
                step.licenses[0].name +
                ' to ' +
                (details.license_reserve_level / BYTES_PER_TIB).toFixed(0) +
                ' TiB'
            );
        }

        default:
            console.error(`Invalid step action: ${step.action}`);
            return 'Unknown';
    }
}

function getFlashBladeCapacityUpgradeStepDescription(
    details: UpgradeFlashBladeHardwareCapacityActionDetails,
    isPhantom: boolean,
): Html {
    const usableTiBStr = decimalPipe.transform(details.increased_usable_TiB, '1.1-1') + ' TiB';
    if (isPhantom) {
        if (isFBE(details?.chassis[0]?.blades[0]?.controller)) {
            return `Usable capacity: ${usableTiBStr}<br>Chassis: ${details.chassis.length}, Total Raw: ${getTotalRawTB(details).toFixed(1)} TB`;
        } else {
            // FB-S
            return `Usable capacity: ${usableTiBStr}<br>Chassis: ${details.chassis.length}, Blades: ${getTotalBladeCount(details)}, Total Raw: ${getTotalRawTB(details).toFixed(1)} TB`;
        }
    } else {
        // get those added chassis/blades/drives count, increased raw capacity and usable capacity
        let rawTBChange = 0;
        let numberOfBladesAdded = 0;
        let numberOfDrivesAdded = 0;
        let numberOfChassisAdded = 0;
        let countAddedDrive = true; // we only need to count it once
        details.chassis.forEach(chassis => {
            if (chassis.simulated) {
                numberOfChassisAdded++;
            }
            chassis.blades.forEach(blade => {
                if (blade.simulated) {
                    numberOfBladesAdded++;
                }
                blade.drives.forEach(drive => {
                    if (drive.simulated && !blade.simulated && countAddedDrive) {
                        numberOfDrivesAdded++;
                    }
                    rawTBChange += drive?.simulated ? drive?.raw_TB - (drive?.original_raw_TB ?? 0) : 0;
                });
                countAddedDrive = false;
            });
        });
        const rawTBStr = decimalPipe.transform(rawTBChange, '1.1-1') + ' TB';
        let description = ``;
        if (numberOfChassisAdded > 0) {
            description += `Added ${numberOfChassisAdded} chassis, `;
        }
        if (numberOfBladesAdded > 0) {
            if (description.length == 0) {
                description += `Added `;
            }
            description += `${numberOfBladesAdded} blade${numberOfBladesAdded > 1 ? 's' : ''}, `;
        }
        if (numberOfDrivesAdded > 0) {
            if (description.length == 0) {
                description += `Added `;
            }
            description += `${numberOfDrivesAdded} drive${numberOfDrivesAdded > 1 ? 's' : ''} per blade, `;
        }
        if (description.length > 0) {
            description = description.substring(0, description.length - 2); // remove trailing comma and space
            return `${description}<br>Increased raw capacity by ${rawTBStr}<br>Increased usable capacity by ${usableTiBStr}`;
        } else {
            return `Increased raw capacity by ${rawTBStr}<br>Increased usable capacity by ${usableTiBStr}`;
        }
    }
}

export class SimulationStep implements Resource {
    /** Show performance risk message in the UI if more than this many of volumes are selected as part of a simulation at once */
    static readonly VOLUME_PERF_RISK_THRESHOLD = 75;

    /***
     * Gets the array names of all arrays that have a phantom workload placed on it
     */
    static getArrayRefsWithPhantomWorkload(steps: SimulationStep[], workloadId: string): Resource[] {
        return steps
            .filter(step => step.action === 'place_workloads' && step.id) // Only care about non-speculative workload steps
            .map(step => step.action_details as PlaceWorkloadActionDetails)
            .filter(step => step.workloads.some(workload => workload.id === workloadId))
            .map(details => details.target);
    }

    /**
     * Gets the subset of workload steps which apply to the given volumes, and only the given volumes (ie no additional volumes may be included).
     * @param steps All the steps to be checked
     * @param selectedVolumes The volumes that all must be affected (no more, no less) by a step to be included in the results
     * @returns The subset of steps that affect all the given selectedVolumes
     */
    static getWorkloadStepsByAffectedVolumes(
        steps: SimulationStep[],
        selectedVolumes: SimulatedVolume[],
    ): SimulationStep[] {
        if (!steps || !selectedVolumes) {
            return [];
        }

        const volumeActions: ActionType[] = ['scale_volumes', 'copy_volumes', 'migrate_volumes'];

        return steps
            .filter(step => volumeActions.includes(step.action))
            .filter(step => {
                const actionDetails = step.action_details as
                    | ScaleVolumesActionDetails
                    | CopyMigrateVolumesActionDetails;
                const stepVols = actionDetails.volumes || [];
                return (
                    stepVols.length === selectedVolumes.length && selectedVolumes.every(vol => step.affectsVolume(vol))
                );
            });
    }

    static fromJson(json: any): SimulationStep {
        return new SimulationStep(
            json.id,
            json.name,
            json.simulation,
            json.licenses,
            json.arrays,
            json.created,
            json.updated,
            json.action,
            json.is_valid,
            json.action_details,
        );
    }

    /**
     * @param array The array to be scaled
     * @param scaleFactor How much to scale (200 = 2x = 200%)
     */
    static createArrayScalingStep(array: Resource, scaleFactor: number): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<ScaleArrayActionDetails>('scale_array', [array], null, {
            scaling_factor: scaleFactor,
            source: stripResource(array),
        });
    }

    /**
     * @param array The array the volumes are on
     * @param scaleFactor How much to scale (200 = 2x = 200%)
     * @param volumes Which volumes the scaling is to be applied to
     */
    static createVolumeScalingStep(
        array: Resource,
        scaleFactor: number,
        volumes: VolumeReference[],
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<ScaleVolumesActionDetails>('scale_volumes', [array], null, {
            scaling_factor: scaleFactor,
            volumes: volumes,
        });
    }

    /**
     * @param sourceArray The source that the volumes are copied from (and the array the volumes are native to)
     * @param targetArray The destination to copy the volumes to
     * @param volumes Which volumes on sourceArray to be copied
     */
    static createCopyVolumesStep(
        sourceArray: Resource,
        targetArray: Resource,
        volumes: VolumeReference[],
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<CopyMigrateVolumesActionDetails>(
            'copy_volumes',
            [sourceArray, targetArray],
            null,
            {
                source: stripResource(sourceArray),
                target: stripResource(targetArray),
                volumes: volumes,
            },
        );
    }

    /**
     * @param sourceArray The array to copy the volumes from
     * @param targetArray The array to copy the volumes to
     */
    static createCopyArrayStep(sourceArray: Resource, targetArray: Resource): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<CopyMigrateArrayActionDetails>(
            'copy_array',
            [sourceArray, targetArray],
            null,
            {
                source: stripResource(sourceArray),
                target: stripResource(targetArray),
            },
        );
    }

    /**
     * @param sourceArray The source that the volumes are migrated from (and the array the volumes are native to)
     * @param targetArray The destination to migrate the volumes to
     *
     * @param volumes Which volumes on sourceArray to be migrated
     */
    static createMigrateVolumesStep(
        sourceArray: Resource,
        targetArray: Resource,
        volumes: VolumeReference[],
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<CopyMigrateVolumesActionDetails>(
            'migrate_volumes',
            [sourceArray, targetArray],
            null,
            {
                source: stripResource(sourceArray),
                target: stripResource(targetArray),
                volumes: volumes,
            },
        );
    }

    /**
     * @param sourceArray The array to migrate the volumes from
     * @param targetArray The array to migrate the volumes to
     */
    static createMigrateArrayStep(sourceArray: Resource, targetArray: Resource): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<CopyMigrateArrayActionDetails>(
            'migrate_array',
            [sourceArray, targetArray],
            null,
            {
                source: stripResource(sourceArray),
                target: stripResource(targetArray),
            },
        );
    }

    /**
     * @param array The array to change the model for
     * @param controllerModel The name of the new model (eg FA-X70R2)
     * @param isRecommended if the array is recommended
     */
    static createUpgradeControllerModelStep(
        array: Resource,
        controllerModel: string,
        isRecommended: boolean,
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<UpgradeControllerModelActionDetails>(
            'upgrade_controller_model',
            [array],
            null,
            {
                controller_model: controllerModel,
                is_recommended: isRecommended,
            },
        );
    }

    /**
     * @param array The array to change the model for
     * @param model The name of the new FB model (eg FB-S200)
     */
    static createSimulateFlashBladeModelStep(array: Resource, model: string): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<SimulateFlashBladeModelActionDetails>(
            'simulate_flashblade_model',
            [array],
            null,
            {
                model: model,
            },
        );
    }

    /**
     * @param array The array to change the capacity for
     * @param hardwareCapacity The new hardware capacity (containing all datapacks, both existing and simulated)
     */
    static createUpgradeHardwareCapacityStep(
        array: Resource,
        hardwareCapacity: CapacityConfig,
        totalCapacityChangePct: number,
        increasedUsableTiB: number,
        isRecommended: boolean,
        increasedRawTB?: number,
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<UpgradeHardwareCapacityActionDetails>(
            'upgrade_hardware_capacity',
            [array],
            null,
            {
                chassis: hardwareCapacity.chassis,
                shelves: hardwareCapacity.shelves,
                total_capacity_change_pct: totalCapacityChangePct,
                increased_usable_TiB: increasedUsableTiB,
                is_recommended: isRecommended,
                increased_raw_TB: increasedRawTB,
            },
        );
    }

    /**
     * @param array The array to change the capacity for
     * @param flashBladeCapacityConfig The new hardware capacity (containing all datapacks, both existing and simulated)
     * @param increasedUsableTiB The usable TiB increased in this step.
     * @param originalFlashBladeCapacityConfig The original hardware capacity (containing all datapacks, only existing)
     */
    static createUpgradeFlashBladeHardwareCapacityStep(
        array: Resource,
        flashBladeCapacityConfig: FlashBladeCapacityConfig,
        increasedUsableTiB: number,
        originalFlashBladeCapacityConfig?: FlashBladeCapacityConfig,
    ): Partial<SimulationStep> {
        const chassis = SimulationStep.mapCurrentConfigWithOriginalConfig(
            flashBladeCapacityConfig,
            originalFlashBladeCapacityConfig,
        );
        return SimulationStep.createStepRequest<UpgradeFlashBladeHardwareCapacityActionDetails>(
            'upgrade_flashblade_hardware_capacity',
            [array],
            null,
            {
                chassis: chassis,
                increased_usable_TiB: increasedUsableTiB,
            } as UpgradeFlashBladeHardwareCapacityActionDetails,
        );
    }

    /**
     * @param array The array to change the training window for
     * @param trainingWindow The new training window
     */
    static createLoadTrainingWindowStep(array: Resource, trainingWindow: ITrainingTimes): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<TrainingWindowActionDetails>('training_window_load', [array], null, {
            start: trainingWindow.startTime,
            end: trainingWindow.endTime,
        });
    }

    /**
     * @param array The array to change the training window for
     * @param trainingWindow The new training window
     */
    static createCapacityTrainingWindowStep(array: Resource, trainingWindow: ITrainingTimes): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<TrainingWindowActionDetails>(
            'training_window_capacity',
            [array],
            null,
            {
                start: trainingWindow.startTime,
                end: trainingWindow.endTime,
            },
        );
    }

    /**
     * @param workload The workload to be assigned
     * @param targetArray The array to place the workload onto
     */
    static createPlaceWorkloadStep(workloads: Resource[], targetArray: Resource): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<PlaceWorkloadActionDetails>('place_workloads', [targetArray], null, {
            workloads: workloads,
            target: stripResource(targetArray),
        });
    }

    /**
     * @param array The array to simulate the safe_mode
     * @param snapshotConfiguration The configuration required for the simulation
     */
    static createApplySafeModeStep(
        array: Resource,
        applySafeModeActionDetails: ApplySafeModeActionDetails,
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<ApplySafeModeActionDetails>(
            'apply_safe_mode',
            [array],
            null,
            applySafeModeActionDetails,
        );
    }

    /**
     * @param license The license to simulate the reserve level
     * @param expandLicenseReserveLevelActionDetails The configuration required for the simulation
     */
    static createExpandLicenseReserveLevelStep(
        license: Resource,
        expandLicenseReserveLevelActionDetails: ExpandLicenseReserveLevelActionDetails,
    ): Partial<SimulationStep> {
        return SimulationStep.createStepRequest<ExpandLicenseReserveLevelActionDetails>(
            'expand_license_reserve_level',
            null,
            [license],
            expandLicenseReserveLevelActionDetails,
        );
    }

    static mapCurrentConfigWithOriginalConfig(
        currentCapacityConfig: FlashBladeCapacityConfig,
        originalCapacityConfig?: FlashBladeCapacityConfig,
    ) {
        if (originalCapacityConfig) {
            currentCapacityConfig.chassis.forEach((chassis, index) => {
                const originalNumberOfChassis = originalCapacityConfig.chassis.length;
                if (originalNumberOfChassis <= index) {
                    chassis.simulated = true;
                } else {
                    chassis.simulated = false;
                }
                chassis.blades.forEach((blade, bladeIndex) => {
                    if (
                        chassis.simulated ||
                        (originalNumberOfChassis > index &&
                            originalCapacityConfig.chassis[index].blades.length <= bladeIndex)
                    ) {
                        blade.simulated = true;
                    } else {
                        blade.simulated = false;
                    }
                    blade.drives.forEach((drive, driveIndex) => {
                        if (
                            blade.simulated ||
                            originalCapacityConfig.chassis[index].blades[bladeIndex].drives.length <= driveIndex
                        ) {
                            drive.simulated = true;
                        } else {
                            drive.original_raw_TB =
                                originalCapacityConfig.chassis[index].blades[bladeIndex].drives[driveIndex].raw_TB;
                            drive.simulated = drive.raw_TB !== drive.original_raw_TB;
                        }
                    });
                });
            });
        } else {
            // everything is simulated in phantom fb
            currentCapacityConfig.chassis?.forEach(chassis => {
                chassis.simulated = true;
                chassis.blades.forEach(blade => {
                    blade.simulated = true;
                    blade.drives.forEach(drive => {
                        drive.simulated = true;
                    });
                });
            });
        }
        return currentCapacityConfig.chassis;
    }

    /**
     * Helper for creating a SimulationStep to be used in a request (in opposed to one returned from the api)
     */
    private static createStepRequest<T extends ActionDetails>(
        action: ActionType,
        arrays: Resource[],
        licenses: Resource[],
        details: T,
    ): SimulationStep {
        arrays = arrays ? arrays.filter(a => a != null).map(a => stripResource(a)) : null;

        licenses = licenses ? licenses.filter(l => l != null).map(l => stripResource(l)) : null;

        return new SimulationStep(
            undefined,
            'simulation step',
            undefined,
            licenses,
            arrays,
            undefined,
            undefined,
            action,
            undefined,
            details,
        );
    }

    constructor(
        public id: string,
        public name: string,
        public simulation: Resource,
        public licenses: Resource[],
        public arrays: Resource[],
        public created: number,
        public updated: number,
        public action: ActionType,
        public is_valid: boolean,
        public action_details: ActionDetails,
        public viewStepEnabled: boolean = false,
    ) {}

    /**
     * If this step affects the specified volume
     */
    affectsVolume(volume: SimulatedVolume): boolean {
        return this.getAffectedVolumesForArray(volume.array.id)
            .concat(this.getAffectedVolumesForArrayLevelAction(volume))
            .some(vol => vol.id.toString() === volume.coreId);
    }

    isSpeculative(): boolean {
        return this.id === undefined;
    }

    /**
     * If this step is for a volume operation and exceeds VOLUME_PERF_RISK_THRESHOLD.
     */
    isVolumePerformanceRisk(): boolean {
        // Don't need to check the type since we use the same threshold for any step that contains volumes. No volumes? No problems.
        const details = this.action_details as ScaleVolumesActionDetails | CopyMigrateVolumesActionDetails;
        return details?.volumes?.length > SimulationStep.VOLUME_PERF_RISK_THRESHOLD;
    }

    /**
     * Gets all the volumeIds affected by this step for the given array
     */
    private getAffectedVolumesForArray(arrayId: string): VolumeReference[] {
        switch (this.action) {
            case 'scale_volumes':
            case 'copy_volumes':
            case 'migrate_volumes': {
                const actionDetails = this.action_details as
                    | ScaleVolumesActionDetails
                    | CopyMigrateVolumesActionDetails;
                return actionDetails.volumes.filter(vol => vol.array_id === arrayId);
            }

            default:
                return [];
        }
    }

    /**
     * Return the volume if it is affected by any array level operations
     */
    private getAffectedVolumesForArrayLevelAction(volume: SimulatedVolume): VolumeReference[] {
        switch (this.action) {
            case 'scale_array':
            case 'copy_array':
            case 'migrate_array': {
                const actionDetails = this.action_details as ScaleArrayActionDetails | CopyMigrateArrayActionDetails;
                if (actionDetails.source.id === volume.array.id) {
                    return [this.toVolumeReference(volume)];
                }
                break;
            }

            default:
                break;
        }

        return [];
    }

    private toVolumeReference(volume: SimulatedVolume): VolumeReference {
        return {
            name: volume.name,
            array_id: volume.array.id,
            id: Number(volume.coreId),
        };
    }
}

@Injectable({ providedIn: 'root' })
export class SimulationStepService {
    constructor(
        private http: HttpClient,
        private httpCacheService: HttpCacheService,
    ) {}

    list(): Observable<DataPage<SimulationStep>> {
        return this.http.get<IRestResponse<any>>(SIMULATION_STEPS_URL).pipe(
            map(response => {
                return {
                    total: response.total_item_count,
                    response: response.items.map(item => SimulationStep.fromJson(item)),
                };
            }),
        );
    }

    create(simulationStep: Partial<SimulationStep>): Observable<SimulationStep> {
        return this.http.post<IRestResponse<any>>(SIMULATION_STEPS_URL, simulationStep).pipe(
            map(response => {
                if (response.total_item_count === 1) {
                    const step = SimulationStep.fromJson(response.items[0]);
                    this.clearCache(step.licenses ? step.licenses : step.arrays);
                    return step;
                } else {
                    return undefined;
                }
            }),
        );
    }

    update(request: Partial<SimulationStep>, ids: string[] | QueryParams): Observable<DataPage<SimulationStep>> {
        if (!(ids instanceof Array)) {
            throw new Error('Cannot be called with queryParams');
        }

        const options = {
            params: new HttpParams().set(QUERY_PARAM_IDS, ids.join(',')),
        };

        return this.http.patch<IRestResponse<SimulationStep>>(SIMULATION_STEPS_URL, request, options).pipe(
            map(response => {
                const steps = (response.items || []).map(item => SimulationStep.fromJson(item));
                this.clearCache(flatMap(steps, step => (step.licenses ? step.licenses : step.arrays)));
                return {
                    total: response.total_item_count,
                    response: steps,
                };
            }),
        );
    }

    delete(id: string, affectedArraysOrLicenses: Resource[]): Observable<void> {
        const options = {
            params: new HttpParams().set(QUERY_PARAM_IDS, id),
        };

        return this.http.delete<void>(SIMULATION_STEPS_URL, options).pipe(
            tap(() => {
                this.clearCache(affectedArraysOrLicenses);
            }),
        ); // Since we trust the caller to indicate which arrays or license resources are impacted by removing this simulation step, we clear cache entries for those arrays and licenses
    }

    /**
     * Clears the http cache for after a step is modified
     * @param filterArraysOrLicenses
     */
    private clearCache(filterArraysOrLicenses: Resource[]): void {
        const filterIds = filterArraysOrLicenses?.map(resource => resource.id);

        // Note: the filter function passed to clear() will clear the value for a key if returns true, will keep value if returns false
        // Think similar to Array.prototype.filter()
        this.httpCacheService.clear(key => {
            // We could instead list out which keys we want to clear instead,
            // but this way is less error-prone for the future
            if (key.includes('/history/capacity_breakdown') || key.includes('/history/load')) {
                return false;
            }

            // If filterArrayIds specified, and the url contains "array_id" or "license_id", then only delete cache entry if
            // it matches on of our arrayIds or licenseIds
            if (
                filterIds &&
                (key.includes('array_id') || key.includes('license_id')) &&
                (!filterIds.some(arrayId => key.includes(`array_id=${arrayId}`)) ||
                    !filterIds.some(licenseId => key.includes(`license_id=${licenseId}`)))
            ) {
                return false;
            }

            return true;
        });
    }
}
