import moment from 'moment';

export class SfdcQuote {
    createdById: string;
    createdDate: moment.Moment;
    endDate: moment.Moment;
    expirationDate: moment.Moment;
    id: string;
    lastModifiedById: string;
    lastModifiedDate: moment.Moment;
    name: string;
    netPrice: number;
    partnerAcceptance: string;
    partnerComment: string;
    paymentCycle: string;
    paymentTerm: string;
    pure1QuoteStatus: string;
    salesFlowId: string;
    startDate: moment.Moment;
    status: string;
    subscriptionId: string;
    subscriptionTerm: string;

    constructor(json: any, salesFlowId?: string) {
        this.salesFlowId = salesFlowId;
        this.createdById = json.created_by_id;
        this.createdDate = json.created_date ? moment(json.created_date).utc() : null;
        this.endDate = json.end_date ? moment(json.end_date).utc() : null;
        this.expirationDate = json.expiration_date ? moment(json.expiration_date).utc() : null;
        this.id = json.id;
        this.lastModifiedById = json.last_modified_by_id;
        this.lastModifiedDate = json.last_modified_date ? moment(json.last_modified_date).utc() : null;
        this.name = json.name;
        this.netPrice = json.net_price;
        this.partnerAcceptance = json.partner_acceptance;
        this.partnerComment = json.partner_comment;
        this.paymentCycle = json.payment_cycle;
        this.paymentTerm = json.payment_term;
        this.pure1QuoteStatus = json.pure1_quote_status;
        this.startDate = json.start_date ? moment(json.start_date).utc() : null;
        this.status = json.status;
        this.subscriptionId = json.subscription_id;
        this.subscriptionTerm = json.subscription_term;
    }
}
