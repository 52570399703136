import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { ServiceLevelAggregation } from '../models/service-level-aggregation';

@Injectable({ providedIn: 'root' })
export class ServiceLevelAggregationService extends GenericService<ServiceLevelAggregation> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ServiceLevelAggregation,
            endpoint: '/rest/v1/usage-aggregation/detail/service',
        });
    }
}
