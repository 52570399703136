<form id="step-up-form" [formGroup]="passCodeForm">
    <input
        *ngFor="let i of [0, 1, 2, 3, 4, 5]"
        class="form-control"
        [ngClass]="{ error: hasError }"
        type="text"
        formControlName="passCode{{ i }}"
        maxlength="1"
        (keyup)="passcodeKeyUp(i, $event)"
        (paste)="pastePasscode(i, $event)"
    />
</form>
