<div class="container">
    <div class="row">
        <div class="col-xs-12">
            <div id="upgrade-day">
                <button type="button" class="btn previous-day btn-link" (click)="addDays(-1)">
                    <pureui-svg class="pstg-action-icon" [svg]="'chevron-left.svg'" [height]="10"></pureui-svg>
                </button>
                <div id="upgrade-date-display" class="d-inline-block">
                    {{ printDate() }}
                </div>
                <button type="button" class="btn next-day btn-link" (click)="addDays(1)">
                    <pureui-svg class="pstg-action-icon" [svg]="'chevron-right.svg'" [height]="10"></pureui-svg>
                </button>
            </div>
        </div>
    </div>

    <div id="upgrade-timeslots" class="row">
        <ng-container *ngIf="timeSlots$ | async as timeSlots; else loading">
            <div *ngIf="timeSlots.length === 0" class="col"> No timeslots available for the current date </div>
            <div role="radiogroup" class="btn-group btn-group-toggle col-xs-12">
                <div *ngFor="let timeSlot of timeSlots; trackBy: trackByIndex">
                    <div class="row" *ngIf="timeSlotHasRemainingCapacity(timeSlot)">
                        <label
                            class="btn btn-outline-primary btn-lg btn-block"
                            [class.active]="control.value === timeSlot"
                        >
                            <input
                                type="radio"
                                [id]="timeSlot.startTime.valueOf()"
                                [formControl]="control"
                                [value]="timeSlot"
                            />
                            <div class="custom-timeslot">
                                <span>
                                    {{ printPrettyTime(timeSlot.startTime) }}
                                    (1 hrs)
                                </span>
                                <div
                                    *ngIf="isCurrentArrayInTimeslot(timeSlot) && !oneScheduleForMultipleAppliances"
                                    class="dot"
                                    [ngClass]="'dot-' + (getSelectedArrayIndex() + 1)"
                                ></div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #loading>
            <div class="col-xs-12" style="font-size: 1.5em; text-align: center">
                <pureui-spinner [pureuiShow]="true"></pureui-spinner>
            </div>
        </ng-template>
    </div>
</div>
