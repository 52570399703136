import moment from 'moment';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { WINDOW } from '../app/injection-tokens';

/**
 * Storage store that uses local resources and operates synchronously.
 * Because I/O to this store is fast, it works with single values at a time.
 */
export interface IStorageStore {
    get(key: string): any;
    set(key: string, value: any): void;
    remove(key: string): void;
    clear(): void;
}

/**
 * Storage store that is persisted to the cloud.
 * Because I/O to this store can be slow, it works asynchronously and operates on
 * collections instead of individually settings. Each collection should be a local group of
 * settings. Collections must be predefined in the server.
 */
interface ICloudStorageStore {
    /**
     * Gets all the settings in a collection.
     */
    get<T extends Object>(collection: ICloudStorageGroupName): Promise<T>;

    /**
     * Updates a single collection's settings.
     * Partial updates are not supported. Any setting not specified gets removed from the collection.
     */
    set<T extends Object>(collection: ICloudStorageGroupName, values: T): Promise<void>;
}

/**
 * The predefined list of collection names for the cloud storage store.
 */
type ICloudStorageGroupName = 'dashboard';

@Injectable({ providedIn: 'root' })
export class StorageService {
    readonly local: IStorageStore;
    readonly session: IStorageStore;
    readonly cloud: ICloudStorageStore;

    private readonly defaultStore: IStorageStore;

    constructor(
        @Inject(WINDOW) readonly window: Window,
        readonly httpClient: HttpClient,
    ) {
        // Create stores
        this.local = BrowserStorageStore.create(window, window.localStorage);
        this.session = BrowserStorageStore.create(window, window.sessionStorage);
        this.cloud = new CloudStorageStore(httpClient);

        // Default to local storage
        this.defaultStore = this.local;
    }

    get(key: string): any {
        return this.defaultStore.get(key);
    }

    set(key: string, value: any): void {
        this.defaultStore.set(key, value);
    }

    clear(): void {
        this.defaultStore.clear();
    }

    remove(key: string): void {
        this.defaultStore.remove(key);
    }
}

interface ICloudSettingGroup {
    groupName: ICloudStorageGroupName;
    settings: Object;
}

class CloudStorageStore implements ICloudStorageStore {
    private readonly baseUrl = '/rest/v1/user/settings';

    /** How long we leave items in the cache before we refresh from the server. */
    private readonly cacheDurationMs = moment.duration(30, 'seconds').asMilliseconds();

    private getGroupsPromise: Promise<Map<ICloudStorageGroupName, Object>>;
    private getGroupsPromiseExpiration: moment.Moment;

    constructor(private httpClient: HttpClient) {}

    get<T extends Object>(groupName: ICloudStorageGroupName): Promise<T> {
        // Update groups if not cached / cache expired
        if (
            !this.getGroupsPromise ||
            !this.getGroupsPromiseExpiration ||
            this.getGroupsPromiseExpiration.isBefore(moment())
        ) {
            this.getGroupsPromise = this.httpClient
                .get<ICloudSettingGroup[]>(this.baseUrl)
                .toPromise()
                .then(
                    response => {
                        // Convert to a map
                        const map = new Map<ICloudStorageGroupName, Object>();
                        if (response && Array.isArray(response)) {
                            response.forEach(group => {
                                map.set(group.groupName, group.settings);
                            });
                        }

                        this.getGroupsPromiseExpiration = moment().add(this.cacheDurationMs, 'milliseconds');
                        return map;
                    },
                    err => {
                        this.clearCache();
                        return new Map<ICloudStorageGroupName, Object>();
                    },
                );
        }

        // Return the requested group
        return this.getGroupsPromise.then(groups => {
            return <T>groups.get(groupName);
        });
    }

    set<T extends Object>(groupName: ICloudStorageGroupName, values: T): Promise<void> {
        const body: ICloudSettingGroup[] = [
            {
                groupName: groupName,
                settings: values,
            },
        ];

        // Send setting group update
        return this.httpClient
            .post(this.baseUrl, body)
            .pipe(catchError(err => of(void 0)))
            .toPromise()
            .then(() => {
                // Clear the cache so we can serve the values we just wrote
                this.clearCache();
            });
    }

    private clearCache(): void {
        this.getGroupsPromise = null;
        this.getGroupsPromiseExpiration = moment().subtract(1, 'day');
    }
}

class BrowserStorageStore implements IStorageStore {
    private baseKey = '';

    static create(window: Window, storage: Storage): IStorageStore {
        if (storage && BrowserStorageStore.isStorageAvailable(storage)) {
            return new BrowserStorageStore(window, storage);
        } else {
            return new InMemoryStorageStore();
        }
    }

    /**
     * Gets that read/write access to this store is available
     */
    private static isStorageAvailable(storage: Storage): boolean {
        const testKey = 'testKey-fkj02u501u23512'; // Totally random key that should cause no collisions to real code
        const testValue = '1337';

        try {
            storage.setItem(testKey, testValue);
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

    constructor(
        private window: Window,
        private storage: Storage,
    ) {}

    get(key: string): any {
        const item = this.storage.getItem(this.getKey(key));
        return item ? JSON.parse(item) : null;
    }

    set(key: string, value: any): void {
        this.storage.setItem(this.getKey(key), JSON.stringify(value));
    }

    clear(): void {
        this.storage.clear();
    }

    remove(key: string): void {
        this.storage.removeItem(this.getKey(key));
    }

    /**
     * Gets the fully-qualified key to use
     */
    private getKey(key: string): string {
        return this.window.pure1.basekey + ':' + key;
    }
}

export class InMemoryStorageStore implements IStorageStore {
    private readonly data = new Map<string, any>();

    clear(): void {
        this.data.clear();
    }

    set(key: string, val: any): void {
        this.data.set(key, val);
    }

    get(key: string): string {
        return this.data.has(key) ? this.data.get(key) : null;
    }

    remove(key: string): void {
        this.data.delete(key);
    }
}
