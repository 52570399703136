<div
    *ngFor="let item of items; trackBy: 'id' | trackByProperty"
    class="toast-item"
    [ngClass]="[item.cssClass, item.stateCssClass]"
>
    <div class="toast-left">
        <div class="toast-icon">
            <i [ngClass]="item.iconClass"></i>
        </div>
    </div>

    <div class="toast-body">
        <div class="toast-message" *ngIf="isString(item.text)">{{ item.text }}</div>
        <div class="toast-message" *ngIf="isTemplateRef(item.text)">
            <ng-container [ngTemplateOutlet]="$any(item.text)"></ng-container>
        </div>
        <div class="toast-message" *ngIf="isKeepHtml(item.text)" innerHtml="{{ item.text.html }}"></div>
    </div>

    <div class="toast-right">
        <div class="toast-close" (click)="dismissItem(item)">
            <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="10"></pureui-svg>
        </div>
    </div>
</div>
