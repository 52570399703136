import { Component, Inject, Input, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CopyToClipboardBase } from './copy-to-clipboard-base';

enum CopyMessage {
    NOT_COPIED = 'Copy to clipboard',
    COPIED = 'Copied',
}

@Component({
    templateUrl: 'copy-to-clipboard.component.html',
    selector: 'copy-to-clipboard',
})
export class CopyToClipboardComponent extends CopyToClipboardBase {
    @Input() readonly copyField: string;

    copyMessage = CopyMessage.NOT_COPIED;

    @ViewChild('tooltip', { static: true }) readonly tooltip: NgbTooltip;

    constructor(renderer: Renderer2, @Inject(DOCUMENT) document: Document) {
        super(renderer, document);
    }

    copyFieldToClipboard($event: UIEvent, field: string): void {
        super.copyFieldToClipboard($event, field);

        this.copyMessage = CopyMessage.COPIED;
        this.openCopyTooltip(this.tooltip);
        $event.stopPropagation();
    }

    openCopyTooltip(tooltip: NgbTooltip): void {
        if (tooltip.isOpen()) {
            tooltip.close();
        }

        tooltip.open({
            message: this.copyMessage,
        });
    }

    closeCopyTooltip(tooltip: NgbTooltip): void {
        if (tooltip.isOpen()) {
            tooltip.close();
        }

        this.copyMessage = CopyMessage.NOT_COPIED;
    }
}
