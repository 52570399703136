import { MetricAnnotation } from './metric-annotation';

export class VolumeMetricAnnotation extends MetricAnnotation {
    readonly volumes: { readonly arrayId: string; readonly volumeId: string }[];

    constructor(json: any) {
        super(json);
        this.volumes =
            json.volumes === null
                ? null
                : json.volumes.map(v => {
                      return {
                          arrayId: v.array_id,
                          volumeId: String(v.id),
                      };
                  });
    }
}
