import { DraasApiLicense } from '@pure/paas-api-gateway-client-ts';
import { Resource } from '../interfaces/resource';

export class DisasterRecoveryLicense implements Resource {
    id: string;
    name: string;
    key: string;
    startDate: Date;
    endDate: Date;

    constructor(json: DraasApiLicense) {
        this.id = json.id;
        this.name = json.key;
        this.key = json.key;
        this.startDate = new Date(json.start_date_utc);
        this.endDate = new Date(json.end_date_utc);
    }
}
