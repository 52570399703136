<div id="escalate-modal">
    <div class="modal-header">
        <button type="button" class="close" (click)="activeModal.close()">
            <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
        </button>
        <h3 class="modal-title">Escalate a case</h3>
    </div>

    <div class="modal-body modal-case-escalation">
        <!-- Not submitted -->
        <form *ngIf="!isRequestSubmitted" [formGroup]="escalationForm" (paste)="stopPasteTextEvent($event)">
            <div class="container-fluid">
                <div class="row form-group">
                    <label class="col-xs-3 col-form-label text-xs-right required">Name</label>
                    <div class="col-xs-9">
                        <input
                            type="text"
                            name="escalation-contact-name"
                            id="escalation-contact-name"
                            required
                            formControlName="contactName"
                            class="form-control"
                            placeholder="Name"
                        />
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-xs-3 col-form-label text-xs-right required">Phone number</label>
                    <div class="col-xs-9">
                        <input-phone [prefill]="contactPhone" (onPhoneChange)="onPhoneChange($event)"> </input-phone>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-xs-3 col-form-label text-xs-right required">Escalation Reason</label>
                    <div class="col-xs-9">
                        <select
                            formControlName="reason"
                            required
                            name="escalation-reason"
                            id="escalation-reason"
                            class="form-control custom-select"
                            (change)="onSelectChange()"
                            *ngIf="!supportCase.isClosed"
                        >
                            <option value="" disabled selected>Escalation Reason</option>
                            <option [value]="escalationReasons.PROGRESS_BY_CUSTOMER">{{
                                escalationReasons.PROGRESS_BY_CUSTOMER | escalationReason
                            }}</option>
                            <option [value]="escalationReasons.SEVERITY_INCREASED">{{
                                escalationReasons.SEVERITY_INCREASED | escalationReason
                            }}</option>
                            <option [value]="escalationReasons.OTHER">{{
                                escalationReasons.OTHER | escalationReason
                            }}</option>
                        </select>
                        <div *ngIf="supportCase.isClosed" class="form-control disabled"
                            >Case closed but issue not resolved</div
                        >
                    </div>
                </div>
                <div class="row form-group">
                    <label
                        class="col-xs-3 col-form-label text-xs-right"
                        [class.required]="escalationForm.controls.reason.value === escalationReasons.OTHER"
                        >Brief Description</label
                    >
                    <div class="col-xs-9">
                        <textarea
                            formControlName="comment"
                            class="form-control resize-vert"
                            rows="2"
                            name="escalation-comment"
                            id="escalation-comment"
                            #escalationComment
                            placeholder="Brief Description"
                        ></textarea>
                    </div>
                </div>
            </div>
        </form>

        <!-- Submitted confirmation -->
        <div *ngIf="isRequestSubmitted" style="text-align: center">
            <div>
                <pureui-svg class="pstg-action-icon" svg="checkmark-circle.svg" height="40"></pureui-svg>
            </div>
            <br />
            <h2 class="support-escalate-submitted-primary-text">Your escalation request has been submitted.</h2>
            <p class="support-escalate-submitted-secondary-text">
                A manager will contact you shortly to review the escalation of your case.<br />
                Thank you!
            </p>
        </div>
    </div>

    <div class="modal-footer">
        <!-- Not submitted -->
        <div class="btn-toolbar" *ngIf="!isRequestSubmitted">
            <button class="btn btn-info" type="reset" (click)="activeModal.dismiss()">Cancel</button>
            <button
                class="btn btn-primary support-spinner-button"
                type="submit"
                (click)="createEscalation()"
                [disabled]="escalationForm.invalid || isRequestSubmitting"
            >
                <pureui-spinner [pureuiShow]="isRequestSubmitting" class="support-button-spinner"></pureui-spinner>
                Escalate
            </button>
        </div>

        <!-- Submitted confirmation -->
        <div class="btn-toolbar" *ngIf="isRequestSubmitted">
            <button class="btn btn-primary" type="reset" (click)="closeModal()">Done</button>
        </div>
    </div>
</div>
