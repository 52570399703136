import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { MarketplaceOrder } from '../models/marketplace-order';

@Injectable({ providedIn: 'root' })
export class MarketplaceOrderService extends GenericService<MarketplaceOrder> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: MarketplaceOrder,
            endpoint: '/rest/v1/marketplace/orders',
            update: true,
        });
    }
}
