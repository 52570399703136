<svg
    class="capacity-expandable-icon"
    [width]="20"
    [height]="20"
    hive-svg="capacity_down_icon_active.svg"
    hiveTooltip
    hiveTooltipDescription="Additional installed capacity available. Click here to learn more."
    [ngbPopover]="capacityDownLicensingPopover"
    popoverClass="capacity-down-licensing-popover"
    placement="bottom"
    container="body"
></svg>
<ng-template #capacityDownLicensingPopover>
    <div class="capacity-down-licensing-popover-wrapper">
        <div class="image-column">
            <img src="/images/capacity-image.svg" alt="capacity-image" />
        </div>
        <div class="text-column">
            <h3 class="font-mildly-bold">Optimize Your Usable Capacity!</h3>
            <div class="body">
                This appliance has more storage capacity installed, but it is not provisioned for use by your license.
            </div>
        </div>
    </div>
</ng-template>
