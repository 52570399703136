import { formatSize } from '../utils/formatSize';
import { CapacityData } from './CapacityData';

export class FArrayCapacityData extends CapacityData {
    unique: number;
    uniqueStr: IValueUnit;
    volumes: number;
    volumesStr: IValueUnit;
    fileSystemsSpace: number;
    fileSystemsSpaceStr: IValueUnit;
    sharedSpace: number;
    sharedSpaceStr: IValueUnit;
    snapshots: number;
    snapshotsStr: IValueUnit;
    replication: number;
    replicationStr: IValueUnit;

    constructor(capacity: any) {
        super(capacity);
        if (capacity) {
            this.unique = capacity.unique;
            this.volumes = capacity.volumes;
            this.fileSystemsSpace = capacity.file_systems_space;
            this.sharedSpace = capacity.shared_space;
            this.snapshots = capacity.snapshots;
            this.replication = capacity.replication;
        } else {
            this.unique = 0;
            this.volumes = 0;
            this.fileSystemsSpace = 0;
            this.sharedSpace = 0;
            this.snapshots = 0;
            this.replication = 0;
        }

        this.uniqueStr = formatSize(this.unique, 2);
        this.volumesStr = formatSize(this.volumes, 2);
        this.fileSystemsSpaceStr = formatSize(this.fileSystemsSpace, 2);
        this.sharedSpaceStr = formatSize(this.sharedSpace, 2);
        this.snapshotsStr = formatSize(this.snapshots, 2);
        this.replicationStr = formatSize(this.replication, 2);
        this.dialSegments =
            this.capacity > 0
                ? [
                      { value: (100 * this.unique) / this.capacity, cssClass: 'unique', tooltipName: 'Unique' },
                      { value: (100 * this.snapshots) / this.capacity, cssClass: 'snapshots' },
                      { value: (100 * this.sharedSpace) / this.capacity, cssClass: 'shared' },
                      { value: (100 * this.system) / this.capacity, cssClass: 'system' },
                      { value: (100 * this.replication) / this.capacity, cssClass: 'replication' },
                  ]
                : [];
    }
}
