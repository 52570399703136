import { cloneDeep } from 'lodash';
import {
    Component,
    Input,
    ElementRef,
    ViewChild,
    Renderer2,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    OnDestroy,
    NgZone,
    ChangeDetectorRef,
    Inject,
} from '@angular/core';
import { FArray, IArrayHealth } from '../../../../model/model';

import 'raphael';
import { ScaleRaphael } from '../scale.raphael';
import '../../../../../../resources/array_health';
import { WINDOW } from '../../../../app/injection-tokens';

const XL_GRAPHIC_SCALE_TO = 0.7;
const SCALE_TO = 0.9;
const EXPANDED_HARDWARE_SCALE_DOWN = 0.57;

@Component({
    selector: 'array-expanded-card-health-farray',
    templateUrl: 'array-expanded-card-health-farray.component.html',
})
export class ArrayExpandedCardHealthFArrayComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() readonly array: FArray;
    @Input() readonly arrayHealth: IArrayHealth;
    @Input() readonly isExpanded: boolean;

    @ViewChild('frontLabel', { static: true }) readonly frontLabel: ElementRef;
    @ViewChild('rearLabel', { static: true }) readonly rearLabel: ElementRef;
    @ViewChild('boxLabel', { static: true }) readonly boxLabel: ElementRef;
    @ViewChild('dashboardGraphContainer', { static: true }) readonly dashboardGraphContainer: ElementRef;
    @ViewChild('arrayGraphContainer', { static: true }) readonly arrayGraphContainer: ElementRef;

    private viewInitialized = false;
    private isDestroyed = false;
    private clickableRects: RaphaelElement[] = [];
    private removeDashboardListenerFns: (() => void)[] = [];

    private arrayDataElem;
    private boxName = '';
    private selectedIndex = 0;
    private selectedClickableRect: RaphaelElement;
    private _hasBack = false;

    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone,
        @Inject(WINDOW) private window: Window,
    ) {}

    ngAfterViewInit(): void {
        this.arrayDataElem = new pure.web.jsp.array.Data();
        this.viewInitialized = true;

        // This can change hasBack, which is a bound value. Doing this from a ngAfterViewInit
        // will cause an ExpressionChangedAfterItHasBeenCheckedError. So we need to do it in a new cycle.
        if (this.arrayHealth) {
            this.window.setTimeout(() => {
                this.ngZone.runOutsideAngular(() => {
                    this.drawDashboardHealthGraphic();
                });
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.viewInitialized) {
            return; // Don't draw before the view is ready
        }

        // Update if we have change to expanded, or if the health data changes
        const newlyExpanded = changes.isExpanded && this.isExpanded;
        if (newlyExpanded || changes.arrayHealth) {
            this.ngZone.runOutsideAngular(() => {
                this.drawDashboardHealthGraphic();
            });
        } else if (changes.isExpanded) {
            this.toggleOpacity();
        }
    }

    ngOnDestroy(): void {
        this.isDestroyed = true;
        this.removeDashboardListeners();
    }

    getDashboardElemId(): string {
        return 'd-' + this.array.arrayId;
    }

    getGraphElemId(): string {
        return 'g-' + this.array.arrayId;
    }

    isCBS(): boolean {
        return pure.web.jsp.array.DataUtil.prototype.isCBS(this.array);
    }

    isOxygen(): boolean {
        return pure.web.jsp.array.DataUtil.prototype.isOxygen(this.array);
    }

    isTachyon(): boolean {
        return pure.web.jsp.array.DataUtil.prototype.isTachyon(this.array);
    }

    isXlGraphic(): boolean {
        return this.isTachyon() || this.isOxygen();
    }

    get hasBack(): boolean {
        return this._hasBack;
    }

    private toggleOpacity(): void {
        this.clickableRects.forEach((clickableRect, index) => {
            clickableRect.node.setAttribute(
                'class',
                `hardware-summary-overlay ${!this.isExpanded || this.selectedIndex === index ? 'selected-hardware-summary-overlay' : ''}`,
            );
        });
    }

    private drawDashboardHealthGraphic(): void {
        if (this.isDestroyed || !this.arrayHealth) {
            return;
        }

        this.removeDashboardListeners();
        const dashboardGraph = new pure.web.jsp.ArrayGraphDashboard(
            this.dashboardGraphContainer.nativeElement,
            this.arrayHealth,
        );
        const dgraph = dashboardGraph.graph;
        const barSpace = dgraph.barSpace || 2;
        if (!dashboardGraph.paper) {
            dashboardGraph.paper = ScaleRaphael(this.getDashboardElemId(), dgraph.boxWidth, dgraph.totalHeight);
        }

        this.clickableRects = [];
        this.removeDashboardListenerFns = [];
        if (pure.web.jsp.array.DataUtil.prototype.isCBS(this.array)) {
            // cycle through the controllers. Currently, CBS only has two controllers
            const x = 1.5;
            let y = 0.5;
            let cbsControllerCount = 0;
            // this logic is mostly borrowed from arrayGraphDashboard.js > pure.web.jsp.ArrayGraphDashboard.prototype.drawGraph
            dgraph.boxes.forEach(dBox => {
                if (pure.web.jsp.array.DataUtil.prototype.isCBS(dBox.box)) {
                    dBox.setPaper(dashboardGraph.paper);
                    if (cbsControllerCount === 1) {
                        dBox.draw(x + dBox.graph.boxWidth / 2, y);
                    } else {
                        dBox.draw(x, y);
                    }
                    cbsControllerCount++;
                    if (cbsControllerCount === 2) {
                        y += dBox.graph.boxHeight + 3 * barSpace;
                    }
                }
            });

            dgraph.boxes.forEach(dBox => {
                if (!pure.web.jsp.array.DataUtil.prototype.isCBS(dBox.box)) {
                    dBox.setPaper(dashboardGraph.paper);
                    dBox.draw(x, y);
                    y += dBox.getBoxHeight(dgraph) + 3 * barSpace;
                }
            });
            dashboardGraph.paper.scaleAll(0.89);
        } else {
            let y = 0;
            dgraph.boxes.forEach((dBox, boxIndex) => {
                dBox.setPaper(dashboardGraph.paper);
                dBox.draw(0.5, y);

                const clickableRect = dBox.drawComponent(
                    0.5,
                    y,
                    dgraph.boxWidth,
                    dashboardGraph.getBoxHeight(dBox.box, dgraph),
                    '#fff',
                    '#fff',
                ) as RaphaelElement;
                clickableRect.node.setAttribute(
                    'class',
                    `hardware-summary-overlay ${!this.isExpanded || this.selectedIndex === boxIndex ? 'selected-hardware-summary-overlay' : ''}`,
                );
                clickableRect.data('index', boxIndex);
                const clickHandler = () => this.shelfClicked(boxIndex, clickableRect);
                clickableRect.click(clickHandler);
                this.clickableRects.push(clickableRect);
                this.removeDashboardListenerFns.push(() => clickableRect.unclick(clickHandler));
                if (this.selectedIndex === boxIndex) {
                    this.selectedClickableRect = clickableRect;
                }

                y += dBox.getBoxHeight(dgraph);
            });

            dashboardGraph.paper.scaleAll(SCALE_TO);
        }

        this.drawArrayGraphic(this.arrayHealth, this.selectedIndex);
    }

    private shelfClicked(boxIndex: number, clickableRect: RaphaelElement): void {
        if (!this.isExpanded) {
            return;
        }

        this.selectedClickableRect.node.setAttribute('class', 'hardware-summary-overlay');
        clickableRect.node.setAttribute('class', 'hardware-summary-overlay selected-hardware-summary-overlay');
        this.selectedClickableRect = clickableRect;

        this.drawArrayGraphic(this.arrayHealth, boxIndex);
    }

    private drawArrayGraphic(data: IArrayHealth, boxIndex: number): void {
        this.selectedIndex = boxIndex;

        if (!this.isExpanded) {
            return;
        }

        // Deep copy the array health data since some part of the array_health.js can actually modify it in undesired ways (see: CLOUD-135610)
        data = cloneDeep(data);

        (this.arrayGraphContainer.nativeElement as HTMLElement).innerHTML = '';

        const arrayGraph = new pure.web.jsp.ArrayGraph(
            this.arrayGraphContainer.nativeElement,
            data,
            this.arrayDataElem,
        );
        const graph = arrayGraph.graph;

        if (pure.web.jsp.array.DataUtil.prototype.isCBS(this.array)) {
            const barSpace = graph.barSpace || 2;
            if (!arrayGraph.paper) {
                arrayGraph.paper = ScaleRaphael(this.getGraphElemId(), graph.boxWidth, graph.boxHeight * 2);
            }

            // cycle through the controllers. Currently, CBS only has two controllers
            let cbsControllerCount = 0;
            let x = 1;
            let y = 1;

            // scale down cloud related constants when drawing CBS graphics
            graph.cloudControllerSpace *= EXPANDED_HARDWARE_SCALE_DOWN;
            graph.cloudBoxWidth *= EXPANDED_HARDWARE_SCALE_DOWN;
            graph.cloudInstanceWidth *= EXPANDED_HARDWARE_SCALE_DOWN;
            graph.cloudInstanceHeight *= EXPANDED_HARDWARE_SCALE_DOWN;
            graph.cloudInstanceSpace *= EXPANDED_HARDWARE_SCALE_DOWN;

            // this logic is mostly borrowed from arrayGraph.js > pure.web.jsp.ArrayGraph.prototype.drawGraph
            graph.boxes.forEach(dBox => {
                dBox.setPaper(arrayGraph.paper);
                if (!pure.web.jsp.array.DataUtil.prototype.isCBS(dBox.box)) {
                    // draw the nvrams and ssds, increment y by height of the boxes and add margin
                    dBox.draw(y);
                    y += dBox.getBoxHeight(graph) + 6 * barSpace;
                } else {
                    dBox.draw(x, y);
                    x += dBox.graph.cloudBoxWidth + dBox.graph.cloudControllerSpace;
                    cbsControllerCount++;
                    if (cbsControllerCount === 2) {
                        // draw first two controllers on the same line, and then increment
                        y += dBox.getBoxHeight(graph) + 6 * barSpace;
                    }
                }
            });
        } else {
            const box = graph.boxes[boxIndex];
            const boxHeight = arrayGraph.getBoxHeight(box.box, graph);
            const scaleTo = this.isXlGraphic() ? XL_GRAPHIC_SCALE_TO : SCALE_TO;

            this.boxName = arrayGraph.graph.boxes[boxIndex].root;

            const frontLabelTop = (boxHeight / 2 + 10) * scaleTo;
            this.renderer.setStyle(this.frontLabel.nativeElement, 'top', frontLabelTop + 'px');

            const rearLabelTop = (boxHeight * 1.5 + 10) * scaleTo;
            this.renderer.setStyle(this.rearLabel.nativeElement, 'top', rearLabelTop + 'px');

            // Manually update the element since we might be doing this outside of a digest
            (this.boxLabel.nativeElement as HTMLDivElement).textContent = this.boxName;

            if (!arrayGraph.paper) {
                arrayGraph.paper = ScaleRaphael(this.getGraphElemId(), graph.boxWidth, 2 * boxHeight + 10);
            }
            box.setPaper(arrayGraph.paper);
            box.drawFront(0.5, 0);
            if (box.drawBack) {
                if (this._hasBack !== true) {
                    this._hasBack = true;
                    // we are running this code outside of Angular. When we update the bound value this._hasBack,
                    // we need to mark this for change detection
                    this.cdr.markForCheck();
                }
                box.drawBack(0.5, boxHeight + 10);
            }
            arrayGraph.paper.scaleAll(scaleTo);
        }
    }

    private removeDashboardListeners(): void {
        this.removeDashboardListenerFns.forEach(f => f());
    }
}
