import { Resource } from '../interfaces/resource';

import { ArraySubscription } from './ArraySubscription';

export class ArraySubscriptionLicense implements Resource {
    id: string;
    name: string; // license description
    org_id: number;
    license_type: string; // service level
    site_id: string;
    end_time: number;
    start_time: number;
    reserved_amount: number;
    reserved_unit: string;
    subscription: ArraySubscription;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.org_id = json.org_id;
        this.license_type = json.license_type;
        this.site_id = json.site_id;
        this.end_time = json.end_time;
        this.start_time = json.start_time;
        this.reserved_amount = json.reserved_amount;
        this.reserved_unit = json.reserved_unit;
        this.subscription = json.subscription ? new ArraySubscription(json.subscription) : undefined;
    }
}
