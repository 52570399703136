import { Injectable } from '@angular/core';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DraasApiVmLastJobExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryVmLastJobExecutionInfo } from '../models/disaster-recovery-vm-last-job-execution-info';
import { FilterParams } from '@pure1/data';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryVmLastJobExecutionsService extends DisasterRecoveryBaseService<
    DisasterRecoveryVmLastJobExecutionInfo,
    DraasApiVmLastJobExecutionInfo
> {
    protected resourceClass = DisasterRecoveryVmLastJobExecutionInfo;
    protected pathParams = ['clusterId', 'protectionGroupId'];

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryVmLastJobExecutionInfo>): string {
        let url = `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/vm-job-executions/protection`;
        if (filter.protectionGroupId) {
            url += `/${filter.protectionGroupId}`;
        }
        return url;
    }
}
