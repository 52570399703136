import { Pipe, PipeTransform } from '@angular/core';
import { Member } from '@pure1/data';

const NOT_AVAILABLE = '-';

@Pipe({
    name: 'memberLocationDisplay',
})
export class MemberLocationDisplayPipe implements PipeTransform {
    transform(member: Member): string {
        if (!member) {
            return NOT_AVAILABLE;
        }
        const location = member.location;
        if (!location) {
            return NOT_AVAILABLE;
        }

        return location.name || NOT_AVAILABLE;
    }
}
