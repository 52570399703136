<div>
    <div role="radiogroup" class="btn-group btn-group-toggle col-xs-12" ngDefaultControl (change)="emitChanges()">
        <label
            class="btn btn-outline-primary btn-sev-1"
            tabindex="-1"
            [class.active]="value === supportCaseSeverity.CRITICAL"
            (focus)="changeDescriptionType(supportCaseSeverity.CRITICAL)"
            (focusout)="changeDescriptionType()"
            (mouseenter)="changeDescriptionType(supportCaseSeverity.CRITICAL)"
            (mouseleave)="changeDescriptionType()"
        >
            <input disabled type="radio" /> 1 - Critical
        </label>
        <label
            class="btn btn-outline-primary btn-sev-2"
            tabindex="0"
            [class.active]="value === supportCaseSeverity.MAJOR"
            (click)="clickSeverity(supportCaseSeverity.MAJOR)"
            (focus)="changeDescriptionType(supportCaseSeverity.MAJOR)"
            (focusout)="changeDescriptionType()"
            (mouseenter)="changeDescriptionType(supportCaseSeverity.MAJOR)"
            (mouseleave)="changeDescriptionType()"
            (keyup.space)="setValueOnKeyUp(supportCaseSeverity.MAJOR)"
        >
            <input type="radio" /> 2 - Major
        </label>
        <label
            class="btn btn-outline-primary btn-sev-3"
            tabindex="0"
            [class.active]="value === supportCaseSeverity.MINOR"
            (click)="clickSeverity(supportCaseSeverity.MINOR)"
            (focus)="changeDescriptionType(supportCaseSeverity.MINOR)"
            (focusout)="changeDescriptionType()"
            (mouseenter)="changeDescriptionType(supportCaseSeverity.MINOR)"
            (mouseleave)="changeDescriptionType()"
            (keyup.space)="setValueOnKeyUp(supportCaseSeverity.MINOR)"
        >
            <input type="radio" /> 3 - Minor
        </label>
        <label
            class="btn btn-outline-primary btn-sev-4"
            tabindex="0"
            [class.active]="value === supportCaseSeverity.INFORMATIONAL"
            (click)="clickSeverity(supportCaseSeverity.INFORMATIONAL)"
            (focus)="changeDescriptionType(supportCaseSeverity.INFORMATIONAL)"
            (focusout)="changeDescriptionType()"
            (mouseenter)="changeDescriptionType(supportCaseSeverity.INFORMATIONAL)"
            (mouseleave)="changeDescriptionType()"
            (keyup.space)="setValueOnKeyUp(supportCaseSeverity.INFORMATIONAL)"
        >
            <input type="radio" /> 4 - Informational
        </label>
    </div>
    <div class="description-wrapper">
        <p class="description" *ngIf="!hoveredOption && !value"></p>
        <p class="description" *ngIf="hoveredOption === supportCaseSeverity.CRITICAL">
            <strong>Critical:</strong> impact to a critical business system that is preventing business functions from
            operating, resulting in loss of productivity or financial impact including data loss or data corruption.
        </p>
        <!--SEV1 Options is temporarily disabled-->
        <!--        <div class="description description-sev-1-selected" *ngIf="forceSev1Warning || (!hoveredOption && value === supportCaseSeverity.CRITICAL)">-->
        <!--            <p>-->
        <!--                <pureui-svg svg="alert_warning.svg" [height]="16" class="pstg-secondary-icon"></pureui-svg>-->
        <!--            </p>-->
        <!--            <p>-->
        <!--            By selecting this option, please be ready to engage immediately with Pure Support by phone. Pure Support will-->
        <!--            also initiate visibility with sales, escalation and executive teams.-->
        <!--            </p>-->
        <!--        </div>-->
        <p
            class="description"
            *ngIf="
                hoveredOption === supportCaseSeverity.MAJOR || (!hoveredOption && value === supportCaseSeverity.MAJOR)
            "
        >
            <strong>Major:</strong> impact on a critical business system with reduced or degraded functionality
            impacting productivity or financial loss.
        </p>
        <p
            class="description"
            *ngIf="
                hoveredOption === supportCaseSeverity.MINOR || (!hoveredOption && value === supportCaseSeverity.MINOR)
            "
        >
            <strong>Minor:</strong> impact or degradation of service to business system impacting non-essential
            functions with minimum or no impact to the business.
        </p>
        <p
            class="description"
            *ngIf="
                hoveredOption === supportCaseSeverity.INFORMATIONAL ||
                (!hoveredOption && value === supportCaseSeverity.INFORMATIONAL)
            "
        >
            <strong>Informational:</strong> inquiries including a request for documentation, questions on product
            functionality or configuration.
        </p>
        <div class="disclaimer">
            <p>
                <pureui-svg svg="alert_info.svg" [height]="16" class="pstg-secondary-icon"></pureui-svg>
            </p>
            <p>
                For severity 1 issues, please contact Pure Storage Technical Services by phone for assistance -
                <a href="https://support.purestorage.com/Pure1/Support" target="_blank">
                    Pure Storage phone number reference</a
                >.
            </p>
        </div>
    </div>
</div>
