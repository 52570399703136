<div class="countdown-timer">
    <div class="countdown-icon-container">
        <pureui-svg class="countdown-icon" svg="countdown-timer.svg" height="30"></pureui-svg>
    </div>
    <div class="timer-section">
        <div class="minutes">{{ displayValue(minutesDisplay) }}:</div>
        <div class="unit-label">min</div>
    </div>
    <div class="timer-section">
        <div class="seconds">{{ displayValue(secondsDisplay) }}</div>
        <div class="unit-label">sec</div>
    </div>
</div>
