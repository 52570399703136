<div>
    <!-- FlashArray -->
    <ng-container *ngIf="isFArray()">
        <array-expanded-card-health-farray
            *ngIf="isSupported && arrayHealth"
            [array]="$any(array)"
            [arrayHealth]="arrayHealth"
            [isExpanded]="isExpanded"
        >
        </array-expanded-card-health-farray>

        <div *ngIf="!isSupported" class="health-not-supported">
            <div class="not-supported-message">
                Health view is not supported by the Purity version installed on this array.<br /><br />
                Upgrade to a supported release to enable this view.
            </div>
        </div>
    </ng-container>

    <!-- Flashblade -->
    <array-expanded-card-health-fblade
        *ngIf="isFBlade() && arrayHealth"
        [array]="$any(array)"
        [arrayHealth]="arrayHealth"
        [isExpanded]="isExpanded"
    >
    </array-expanded-card-health-fblade>
</div>
