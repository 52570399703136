import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { SubscriptionGenealogyEventType } from './genealogy-event-type';
import { LicenseType } from './license';

export interface GenealogyEvent {
    readonly date: moment.Moment;
    readonly eventType: SubscriptionGenealogyEventType;
}

export class GenealogyLicenseInfo {
    readonly name: string;
    readonly originalCapacity: number;
    readonly newCapacity: number;
    readonly licenseType: LicenseType;

    constructor(json: any) {
        this.name = json.name;
        this.originalCapacity = json.original_capacity;
        this.newCapacity = json.new_capacity;
        this.licenseType = json.license_type;
    }
}

export class GenealogyStart implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly totalCapacity: number;
    readonly subscriptionTerm: number;
    readonly newLicenses: readonly GenealogyLicenseInfo[];
    readonly eventType: SubscriptionGenealogyEventType = 'start';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.name;
        this.totalCapacity = json.total_capacity;
        this.subscriptionTerm = json.subscription_term;
        this.newLicenses = (json.new_licenses || []).map(j => new GenealogyLicenseInfo(j));
    }
}

export class GenealogyPlannedRamp implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly originalCapacity: number;
    readonly newCapacity: number;
    readonly capacityIncrease: readonly GenealogyLicenseInfo[];
    readonly capacityDecrease: readonly GenealogyLicenseInfo[];
    readonly added: readonly GenealogyLicenseInfo[];
    readonly removed: readonly GenealogyLicenseInfo[];
    readonly eventType: SubscriptionGenealogyEventType = 'plannedRamp';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.name;
        this.originalCapacity = json.original_capacity;
        this.newCapacity = json.new_capacity;
        this.capacityIncrease = (json.capacity_increase || []).map(j => new GenealogyLicenseInfo(j));
        this.capacityDecrease = (json.capacity_decrease || []).map(j => new GenealogyLicenseInfo(j));
        this.added = (json.added || []).map(j => new GenealogyLicenseInfo(j));
        this.removed = (json.removed || []).map(j => new GenealogyLicenseInfo(j));
    }
}

export class GenealogyUpdateRequest implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly originalCapacity: number;
    readonly newCapacity: number;
    readonly capacityIncrease: readonly GenealogyLicenseInfo[];
    readonly capacityDecrease: readonly GenealogyLicenseInfo[];
    readonly added: readonly GenealogyLicenseInfo[];
    readonly removed: readonly GenealogyLicenseInfo[];
    readonly eventType: SubscriptionGenealogyEventType = 'updateRequest';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.name;
        this.originalCapacity = json.original_capacity;
        this.newCapacity = json.new_capacity;
        this.capacityIncrease = (json.capacity_increase || []).map(j => new GenealogyLicenseInfo(j));
        this.capacityDecrease = (json.capacity_decrease || []).map(j => new GenealogyLicenseInfo(j));
        this.added = (json.added || []).map(j => new GenealogyLicenseInfo(j));
        this.removed = (json.removed || []).map(j => new GenealogyLicenseInfo(j));
    }
}

export class GenealogyRenewal implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly newName: string;
    readonly originalName: string;
    readonly newCapacity: number;
    readonly originalSubscriptionTerm: number;
    readonly newSubscriptionTerm: number;
    readonly eventType: SubscriptionGenealogyEventType = 'renewal';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.new_name;
        this.newName = json.new_name;
        this.originalName = json.original_name;
        this.newCapacity = json.new_capacity;
        this.originalSubscriptionTerm = json.original_subscription_term;
        this.newSubscriptionTerm = json.new_subscription_term;
    }
}

export class GenealogyExpiration implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly totalCapacity: number;
    readonly gracePeriodEnd: moment.Moment;
    readonly eventType: SubscriptionGenealogyEventType = 'expiration';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.name;
        this.totalCapacity = json.total_capacity;
        this.gracePeriodEnd = json.grace_period_end ? moment.utc(json.grace_period_end) : null;
    }
}

export class GenealogyTermination implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly totalCapacity: number;
    readonly eventType: SubscriptionGenealogyEventType = 'termination';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.name;
        this.totalCapacity = json.total_capacity;
    }
}

export class GenealogyUpcomingRenewal implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly eventType: SubscriptionGenealogyEventType = 'upcomingRenewal';

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.name = json.name;
    }
}

export class GenealogyCurrentLicenseInfo {
    readonly name: string;
    readonly totalCapacity: number;
    readonly licenseType: LicenseType;

    constructor(json: any) {
        this.name = json.name;
        this.totalCapacity = json.total_capacity;
        this.licenseType = json.license_type;
    }
}

export class GenealogySubscriptionCurrentState implements GenealogyEvent {
    readonly date: moment.Moment;
    readonly name: string;
    readonly eventType: SubscriptionGenealogyEventType = 'currentSubscriptionState';
    readonly totalCapacity: number;
    readonly contractType: string;
    readonly licenses: GenealogyCurrentLicenseInfo[];

    constructor(json: any) {
        this.date = moment.utc();
        this.name = json.name;
        this.totalCapacity = json.total_capacity;
        this.contractType = json.program_type;
        this.licenses = (json.licenses || []).map(j => new GenealogyCurrentLicenseInfo(j));
    }
}

export class SubscriptionGenealogy implements Resource {
    readonly id: string; // Subscription ID
    readonly name: string; // Subscription Name
    readonly initialSubscriptionName: string;
    readonly currentSubscriptionName: string;
    readonly startEvent: GenealogyStart;
    readonly plannedRampEvents: readonly GenealogyPlannedRamp[];
    readonly updateRequestEvents: readonly GenealogyUpdateRequest[];
    readonly renewalEvents: readonly GenealogyRenewal[];
    readonly expirationEvent?: GenealogyExpiration;
    readonly terminationEvent?: GenealogyTermination;
    readonly upcomingRenewalEvent?: GenealogyUpcomingRenewal;
    readonly currentState?: GenealogySubscriptionCurrentState;
    readonly isRansomwareProtectionEnabled: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.current_name;
        this.initialSubscriptionName = json.initial_subscription_name;
        this.currentSubscriptionName = json.current_name;
        this.startEvent = json.start?.date ? new GenealogyStart(json.start) : null;
        this.plannedRampEvents = (json.planned_ramp_events || []).map(j => new GenealogyPlannedRamp(j));
        this.updateRequestEvents = (json.update_request_events || []).map(j => new GenealogyUpdateRequest(j));
        this.renewalEvents = (json.renewal_events || []).map(j => new GenealogyRenewal(j));
        this.expirationEvent = json.expiration_event?.date ? new GenealogyExpiration(json.expiration_event) : null;
        this.terminationEvent = json.termination_event?.date ? new GenealogyTermination(json.termination_event) : null;
        this.upcomingRenewalEvent = json.upcoming_renewal_event?.date
            ? new GenealogyUpcomingRenewal(json.upcoming_renewal_event)
            : null;
        this.currentState = json.current_state ? new GenealogySubscriptionCurrentState(json.current_state) : null;
        this.isRansomwareProtectionEnabled = json.ransomware || false;
    }
}
