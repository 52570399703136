<section *ngIf="opportunityQuote?.purchaseOrders?.length > 0" class="attachment-list">
    <div>
        <h3 class="attachment-list-title">Previously Uploaded POs</h3>
    </div>
    <div
        class="existing-attachment attachment"
        *ngFor="let poFile of opportunityQuote.purchaseOrders; trackBy: 'pure1Id' | trackByProperty"
    >
        <div class="left-attachment-details" (click)="openNewWindowForPOFileDownload(poFile)">
            <span class="file-name">{{ poFile.fileName }}</span>
        </div>
        <div class="right-attachment-details">
            <span class="file-modified-date">{{ poFile.createdDate | moment2date | date: 'yyyy-MM-dd HH:mm' }}</span>
        </div>
    </div>
    <hive-separator class="attachment-separator" *ngIf="opportunityQuote?.status === QuoteStatus.PO_REJECTED" />
</section>

<div class="purchase-order-info">
    <div
        class="file-drag-and-drop"
        [ngClass]="{ dragover: isDragover }"
        (drop)="onDrop()"
        (dragover)="onDragover()"
        (dragleave)="onDragleave()"
        *ngIf="
            opportunityQuote?.status === QuoteStatus.ACCEPTED || opportunityQuote?.status === QuoteStatus.PO_REJECTED
        "
    >
        <input #fileInput id="upload-file-input" type="file" accept=".pdf" (change)="onFileChange($event)" />
        <svg class="pstg-action-icon upload-icon" hive-svg="arrow-up-cloud-no-fill.svg" [height]="24"></svg>
        <span>Drag & Drop or <a (click)="fileInput.click()">Choose file</a> to upload</span>
        <span>.pdf</span>
    </div>
</div>

<section class="attachment-list" *ngIf="!!fileToUpload">
    <div class="new-attachment attachment">
        <div class="left-attachment-details">
            <span class="file-name" hiveTooltip [hiveTooltipDescription]="fileToUpload.name">{{
                fileToUpload.name
            }}</span>
            <span class="file-size">({{ fileToUpload.size | formatSize: 0 }})</span>
        </div>
        <div class="right-attachment-details">
            <span *ngIf="!errorMessage; else fileErrorField" class="file-modified-date">{{
                fileToUpload.lastModified | date: 'yyyy-MM-dd HH:mm'
            }}</span>
            <ng-template #fileErrorField>
                <span class="file-error-message">{{ errorMessage }}</span>
            </ng-template>

            <hive-button
                class="delete-attachment-button"
                size="smallest"
                emphasis="tertiary"
                icon="x-action.svg"
                (click)="onRemoveFile()"
            >
            </hive-button>
        </div>
    </div>
</section>
