import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CaseComment, CaseEmail, CaseEscalation, SupportCaseItem } from '../support.interface';

@Component({
    selector: 'pure-support-avatar',
    templateUrl: 'pure-support-avatar.component.html',
})
export class PureSupportAvatarComponent implements OnChanges {
    @Input() readonly item: SupportCaseItem;

    type: 'support' | 'user' | 'unknown';
    firstLetter: string;

    ngOnChanges(changes: SimpleChanges): void {
        this.updateAvatar();
    }

    private updateAvatar(): void {
        if (this.item) {
            switch (this.item.itemType) {
                case 'attachment':
                case 'comment': {
                    const castItem = this.item as CaseComment;
                    if (castItem.createdByUser) {
                        if (/support@purestorage.com/i.test(castItem.createdByUser.email)) {
                            this.type = 'support';
                        } else {
                            this.type = 'user';
                            this.firstLetter = this.getFirstCharacter(
                                castItem.createdByUser.name || castItem.createdByUser.email,
                            ).toUpperCase();
                        }
                    } else if (castItem.createdById) {
                        // this is very error prone, but for now this is the workaround, please dont kill me for this
                        if (castItem.createdById.indexOf('003') === 0 || castItem.createdById.indexOf('005') === 0) {
                            // user has correct ID but we cannot get his info
                            this.type = 'unknown';
                        } else {
                            // user has a SNOW user reference, so it is someone from Pure Support
                            this.type = 'support';
                        }
                    } else {
                        this.type = 'unknown';
                    }
                    break;
                }
                case 'email': {
                    const castItem = this.item as CaseEmail;
                    if (castItem.from) {
                        if (
                            /support@purestorage.com|pure@service-now.com|pureuat@service-now.com|puretest@service-now.com|puredev@service-now.com/i.test(
                                castItem.from,
                            )
                        ) {
                            this.type = 'support';
                        } else {
                            if (castItem.from.indexOf('@') > -1) {
                                // it has email
                                this.type = 'user';
                                if (castItem.from.indexOf('<') === 0) {
                                    this.firstLetter = this.getFirstCharacter(castItem.from.substr(1)).toUpperCase();
                                } else {
                                    this.firstLetter = this.getFirstCharacter(castItem.from).toUpperCase();
                                }
                            } else {
                                this.type = 'unknown';
                            }
                        }
                    } else {
                        this.type = 'unknown';
                    }
                    break;
                }
                case 'escalation': {
                    const castItem = this.item as CaseEscalation;
                    if (castItem.contactName) {
                        this.type = 'user';
                        this.firstLetter = this.getFirstCharacter(castItem.contactName).toUpperCase();
                    } else {
                        this.type = 'unknown';
                    }
                    break;
                }
                default:
                    this.type = 'unknown';
            }
        } else {
            this.type = 'unknown';
        }
    }

    private getFirstCharacter(str: string): string {
        const trimmedStr = str.trim();
        if (trimmedStr === '') {
            return '';
        }
        const codePoint = trimmedStr.charCodeAt(0);
        const charLengthInUtf16CodePoints = codePoint >= 0x10000 ? 2 : 1;
        return trimmedStr.substr(0, charLengthInUtf16CodePoints);
    }
}
