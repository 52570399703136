import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArraySafeModeEligibilityInfo } from '../models/array-safemode-eligibility';
import { GenericService } from './generic.service';

@Injectable({ providedIn: 'root' })
export class ArraySafeModeEligibilityService extends GenericService<ArraySafeModeEligibilityInfo> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArraySafeModeEligibilityInfo,
            endpoint: '/rest/v2/forecast/arrays/safemode/eligibility',
            defaultParams: {},
        });
    }
}
