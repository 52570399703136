import { Resource } from '../interfaces/resource';
import { BucketPerformanceAggregation } from './bucket-performance-aggregation';

export class Bucket implements Resource {
    name: string;
    id: string;
    array_name: string;
    array_fqdn: string;
    appliance_id: string;
    aggregation: BucketPerformanceAggregation;

    constructor(json: any) {
        this.name = json.name;
        this.id = json.id;
        if (json.arrays) {
            this.array_name = json.arrays[0].name;
            this.appliance_id = json.arrays[0].id;
            this.array_fqdn = json.arrays[0].fqdn;
        }

        if (json.performance_aggregation) {
            this.aggregation = new BucketPerformanceAggregation(json.performance_aggregation.aggregation);
        }
    }
}
