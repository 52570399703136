import { Component, Input, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportOptionIds } from '../../../export/export-button/export-button.component';
import { ExportManagerService } from '../../../export/services/export-manager.service';
import { ExportRequestBuilderService } from '../../../export/services/export-request-builder.service';

@Component({
    selector: 'quote-export-modal',
    templateUrl: 'quote-export-modal.component.html',
})
export class QuoteExportModalComponent {
    @Input() readonly modal: NgbActiveModal;
    @Input() readonly quoteFilter: string;
    @Input() readonly totalFiltered: number;
    @Input() readonly totalUnfiltered: number;
    @Input() readonly selectedOption: IExportButtonOption;

    errorMessage: string;
    exportRequest: IQuoteExportRequest;
    fileNameEntry: string;
    loading = false;
    entityCountMessage = '';

    constructor(
        private exportManager: ExportManagerService,
        private exportRequestBuilder: ExportRequestBuilderService,
    ) {}

    ngOnInit(): void {
        this.generateExportRequest();
        this.fileNameEntry = this.exportRequest.fileName;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalFiltered || changes.selectedOption || changes.totalUnfiltered) {
            this.entityCountMessage = this.getEntityCountMessage();
        }
    }

    clickApply(): void {
        this.generateExportRequest();
        this.exportRequest.fileName = this.fileNameEntry;

        // Set state and send request
        this.errorMessage = null;
        this.loading = true;

        this.exportManager.enqueue(this.exportRequest).subscribe({
            next: () => {
                this.loading = false;
                this.modal.close();
            },
            error: err => {
                this.loading = false;
                this.errorMessage = err;
            },
        });
    }

    getEntityCountMessage(): string {
        if (!this.selectedOption) {
            return '';
        }

        let noun = 'quotes';

        switch (this.selectedOption.id) {
            case ExportOptionIds.all: {
                if (this.totalUnfiltered === 1) {
                    noun = 'quote';
                }
                return `All ${this.totalUnfiltered} ${noun}`;
            }
            case ExportOptionIds.filtered: {
                if (this.totalFiltered === 1) {
                    noun = 'quote';
                }
                return `${this.totalFiltered} filtered ${noun}`;
            }
            default: {
                return `Unknown option ${this.selectedOption.id}`;
            }
        }
    }

    generateExportRequest(): void {
        const filter = this.selectedOption?.id === ExportOptionIds.filtered && this.quoteFilter ? this.quoteFilter : '';
        this.exportRequest = this.exportRequestBuilder.filteredQuotes(filter);
    }
}
