import { Resource } from '../interfaces/resource';

export class LicenseBillingInfo implements Resource {
    id: string;
    name: string;
    currency: string; // Ex: 'USD'
    licenseTotalCharge: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.currency = json.currency;
        this.licenseTotalCharge = json.license_total_charge;
    }
}
