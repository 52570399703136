// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_MAJOR_40 = '#FC9188';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_SUCCESS_40 = '#74D39C';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_SUCCESS_80 = '#084F3E';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_INFO_50 = '#3DB7F5';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT1 = '#7D9AD2';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT2 = '#EDCC63';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT3 = '#FF7D8F';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT4 = '#9AD7E4';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT5 = '#B77DD2';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT7 = '#F47E63';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT9 = '#E47D9B';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_DATAVIZ_CAT10 = '#FFB78A';

// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_MAJOR_50 = '#FC7164';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_SUCCESS_50 = '#43C78B';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_WARNING_30 = '#FBD309';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_SEMANTIC_WARNING_50 = '#BDA114';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_NEUTRAL_30 = '#C9C9C9';
// @deprecated This variable have to be mapped into hive v3 semantic colors
export const HIVE_THEME_60 = '#1B72CB';
