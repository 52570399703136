export class ReportEmailRecipients {
    bcc: string[];
    cc: string[];
    to: string[];

    constructor(json: any) {
        this.bcc = json.bcc || [];
        this.cc = json.cc || [];
        this.to = json.to || [];
    }
}

export class ReportEmailDeliveryMethod {
    body: string;
    recipients: ReportEmailRecipients;
    subject: string;

    constructor(json: any) {
        this.body = json.body;
        this.recipients = new ReportEmailRecipients(json.recipients || {});
        this.subject = json.subject;
    }
}
