import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PlacementRecWarningCode, WarningPair } from '@pure1/data';

type DisplayMessage = {
    message: string;
    link: string;
};

// There are some codes not in here because we don't want to display them.
const CODE_TO_MESSAGE = new Map<PlacementRecWarningCode, DisplayMessage>([
    [
        PlacementRecWarningCode.NO_SHARED_HOST,
        {
            message: 'No shared host.',
            link: 'https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/03_Pure1_Manage_-_Planning#Optimal_Placement_Caveats',
        },
    ],
    [
        PlacementRecWarningCode.DIFFERENT_LOCATIONS,
        {
            message: 'Different geographic location.',
            link: 'https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/03_Pure1_Manage_-_Planning#Optimal_Placement_Caveats',
        },
    ],
    [
        PlacementRecWarningCode.TARGET_EXCEEDS_VOLUMES_COUNT_LIMIT,
        {
            message: 'Exceeds count limit for volumes.',
            link: 'https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/03_Pure1_Manage_-_Planning#Optimal_Placement_Caveats',
        },
    ],
    [
        PlacementRecWarningCode.TARGET_EXCEEDS_STRETCHED_VOLUMES_COUNT_LIMIT,
        {
            message: 'Exceeds count limit for stretched volumes.',
            link: 'https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/03_Pure1_Manage_-_Planning#Optimal_Placement_Caveats',
        },
    ],
    [
        PlacementRecWarningCode.TARGET_EXCEEDS_PODS_COUNT_LIMIT,
        {
            message: 'Exceeds count limit for pods.',
            link: 'https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/03_Pure1_Manage_-_Planning#Optimal_Placement_Caveats',
        },
    ],
    [
        PlacementRecWarningCode.TARGET_EXCEEDS_STRETCHED_PODS_COUNT_LIMIT,
        {
            message: 'Exceeds count limit for stretched pods.',
            link: 'https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/03_Pure1_Manage_-_Planning#Optimal_Placement_Caveats',
        },
    ],
]);

@Component({
    selector: 'placement-warnings-popover',
    templateUrl: 'placement-warnings-popover.component.html',
})
export class PlacementWarningsPopoverComponent implements OnChanges {
    @Input() readonly warningPairs: WarningPair[];
    @ViewChild('popoverRef') popover: NgbPopover;

    displayedMessages: DisplayMessage[];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.warningPairs) {
            // Filter out error codes that we don't display and so don't have a message for.
            this.displayedMessages = this.warningPairs
                .filter(pair => CODE_TO_MESSAGE.has(pair.warning_code))
                .map(pair => CODE_TO_MESSAGE.get(pair.warning_code));
        }
    }

    onClickOpenPopover(event: MouseEvent): void {
        this.popover.open();
        event.stopPropagation(); // We don't want to select the row when the user just wants to open the popover.
    }

    closePopover(): void {
        this.popover.close();
    }
}
