import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { createSortQueryString } from '../utils';
import { Collection, IRestResponse } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';
import { ListParams, FilterParams } from '../interfaces/list-params';

import { PGroup } from '../models/pgroup';

const ENDPOINT = '/rest/v3/protection-groups/targets';

@Injectable({ providedIn: 'root' })
export class PGroupService implements Collection<PGroup> {
    constructor(private http: HttpClient) {}

    list(params: ListParams<PGroup>): Observable<DataPage<PGroup>> {
        const defaultParams: ListParams<PGroup> = {
            pageStart: 0,
            pageSize: 1000,
            sort: {
                key: 'name',
                order: 'asc',
            },
        };
        params = { ...defaultParams, ...params };

        const queryParams: string[] = [];
        queryParams.push(`sort=${createSortQueryString(params.sort)}`);
        queryParams.push(`limit=${params.pageSize}`);
        queryParams.push(`start=${params.pageStart}`);
        if (params.extra) {
            queryParams.push(params.extra);
        }

        if (params.filter) {
            queryParams.push(`filter=${this.makeFilterQueryParam(params.filter)}`);
        }

        const url = ENDPOINT + (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');

        return this.http.get<IRestResponse<unknown>>(url).pipe(map(response => this.makeDataPage(response)));
    }

    create(): Observable<PGroup> {
        throw new Error('Not Supported');
    }

    update(): Observable<DataPage<PGroup>> {
        throw new Error('Not Supported');
    }

    delete(): Observable<void> {
        throw new Error('Not Supported');
    }

    private makeDataPage(wrapper: IRestResponse<unknown>): DataPage<PGroup> {
        const pgroups = wrapper.items.map(jsonPGroup => PGroup.fromJson(jsonPGroup));
        return {
            total: (wrapper as any).total, // wrapper contains non-standard "total" property instead of "total_item_count"
            response: pgroups,
        };
    }

    private makeFilterQueryParam(filter: FilterParams<PGroup>): string {
        return encodeURIComponent(
            Object.keys(filter)
                .map(k => `${filter[k]}`)
                .join(' and '),
        );
    }
}
