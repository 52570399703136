import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Volume } from '../models/volume';

@Injectable({ providedIn: 'root' })
export class VolumeService extends GenericService<Volume> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Volume,
            endpoint: '/rest/v4/volumes',
        });
    }
}
