<ul
    class="nav nav-tabs clearfix"
    pureTabGroup
    [selectedTab]="selectedTab"
    (selectedTabChange)="selectTab($event)"
    [dynamic]="true"
>
    <li class="nav-item tab-open" [ngClass]="{ active: selectedTab === 'open' }" pureTabName="open">
        <span class="nav-link"
            >Open Cases
            <span *ngIf="cases !== undefined">
                {{ (searchText.length > 0 ? filteredOpenCases?.length + '/' : '') + cases.length | parenthesize }}
            </span>
        </span>
    </li>
    <li class="nav-item tab-closed" [ngClass]="{ active: selectedTab === 'closed' }" pureTabName="closed">
        <span class="nav-link"
            >Closed Cases
            <span *ngIf="closedCases !== undefined">
                {{
                    (searchText.length > 0 ? filteredClosedCases?.length + '/' : '') + closedCases.length | parenthesize
                }}
            </span>
        </span>
    </li>
</ul>
