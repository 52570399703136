import { Directive, HostBinding, OnChanges, Input } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * Hides the tooltip on the target element when the element is disabled.
 * Fix for ng-bootstrap issue: https://github.com/ng-bootstrap/ng-bootstrap/issues/2547
 */
@Directive({
    selector: '[ngbTooltip][disabled]',
})
export class FixDisabledTooltipDirective implements OnChanges {
    @Input('disabled')
    @HostBinding('disabled')
    readonly disabled: boolean;

    constructor(private readonly ngbTooltip: NgbTooltip) {}

    ngOnChanges(): void {
        if (this.disabled && this.ngbTooltip) {
            this.ngbTooltip.close();
        }
    }
}
