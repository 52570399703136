import moment from 'moment-timezone';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'moment2date',
})
export class Moment2DateNormalizePipe implements PipeTransform {
    transform(value: moment.Moment | Date | string | number | null | undefined): Date | string | number | null {
        if (moment.isMoment(value)) {
            return value.toDate();
        }
        return value;
    }
}
