import moment from 'moment';

export type SlaIndicatorStatus = 'OK' | 'AT_RISK' | 'UNKNOWN' | 'INELIGIBLE';

export type SlaIndicatorIneligibleReason =
    | 'LICENSE_TYPE_UFFO'
    | 'LICENSE_TYPE_UDR'
    | 'SUBSCRIPTION_START_EARLY'
    | 'RESERVE_CAPACITY_LOW_BLOCK_PERF_PREM_ULTRA'
    | 'RESERVE_CAPACITY_LOW_BLOCK_CAPACITY';

export class LicenseSlaIndicatorMisses {
    date: moment.Moment;
    lastUpdated: moment.Moment;
    licenseId: string;

    performanceMisses?: number;
    energyEfficiencyMissed: boolean;

    constructor(json: any) {
        this.date = json.date ? moment.utc(json.date) : null;
        this.lastUpdated = json.last_updated ? moment.utc(json.last_updated) : null;
        this.licenseId = json.license_id;
        this.performanceMisses = json.performance_misses;
        this.energyEfficiencyMissed = json.energy_efficiency_missed;
    }
}

export class LicenseSlaIndicator {
    status: SlaIndicatorStatus;
    /** Ineligibility status code. Null unless status === 'INELIGIBLE'. */
    ineligibleReason?: SlaIndicatorIneligibleReason;
    /** Ineligibility user-friendly message. Null unless status === 'INELIGIBLE'. */
    ineligibleMessage?: string;

    constructor(json: any) {
        this.status = json.status;
        this.ineligibleReason = json.ineligible_reason;
        this.ineligibleMessage = json.ineligible_message;
    }
}

export class LicensePerformanceSlaIndicator extends LicenseSlaIndicator {
    misses: number;

    constructor(json: any) {
        super(json);
        this.misses = json.misses;
    }
}

export class LicenseEnergyEfficiencyIndicator extends LicenseSlaIndicator {
    monthAverageEE: number;
    monthTargetEE: number;
    isEligible: boolean;

    constructor(json: any) {
        super(json);
        this.status = json.status;
        this.monthAverageEE = json.month_average_ee;
        this.monthTargetEE = json.month_target_ee;
        this.isEligible = json.is_eligible;
    }
}

export class LicenseSlaIndicatorSummary {
    ok: number;
    atRisk: number;
    unknown: number;
    status: SlaIndicatorStatus;
    startDate?: moment.Moment;
    endDate?: moment.Moment;

    constructor(json: any) {
        this.ok = json.ok;
        this.atRisk = json.at_risk;
        this.unknown = json.unknown;
        this.status = json.status;
        this.startDate = json.start_date ? moment.utc(json.start_date) : null; // Nullable for backwards compatibility
        this.endDate = json.end_date ? moment.utc(json.end_date) : null;
    }
}

export class LicenseSlaIndicators {
    summary: LicenseSlaIndicatorSummary;
    performance?: LicensePerformanceSlaIndicator;
    energyEfficiency?: LicenseEnergyEfficiencyIndicator;

    constructor(json: any) {
        this.summary = json.summary ? new LicenseSlaIndicatorSummary(json.summary) : null;
        this.performance = json.performance ? new LicensePerformanceSlaIndicator(json.performance) : null;
        this.energyEfficiency = json.energy_efficiency
            ? new LicenseEnergyEfficiencyIndicator(json.energy_efficiency)
            : null;
    }
}
