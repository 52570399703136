import moment from 'moment';
import { Resource } from '../interfaces/resource';

export type AlohaArraySeverity = 'critical' | 'warning' | 'ok';

export class AlohaArray implements Resource {
    name: string;
    id: string;
    appliance_id: string;
    model: string;
    version: string;
    needsPatch: boolean;
    isPatched: boolean;
    isOptedIn: boolean;
    optInDeadline?: moment.Moment | null;
    severity: AlohaArraySeverity;

    constructor(json: any) {
        this.name = json.hostname || json.name || '';
        this.id = json.array_id;
        this.appliance_id = json.appliance_id;
        this.model = json.model || '';
        this.version = json.version || '';
        this.needsPatch = json.needs_patch != null ? json.needs_patch : !json.opt_in;
        this.isPatched = false;
        this.isOptedIn = json.opt_in;
        this.optInDeadline = json.opt_in_deadline_in_sec ? moment.unix(json.opt_in_deadline_in_sec) : null;
        if (json.flag) {
            switch (json.flag) {
                case 'red':
                    this.severity = 'critical';
                    break;
                case 'yellow':
                    this.severity = 'warning';
                    break;
                default:
                    this.severity = 'ok';
                    break;
            }
        } else {
            this.severity = this.needsPatch ? 'critical' : 'ok';
        }
    }
}
