<ng-template #confirmModal let-close="close">
    <div class="modal-body" style="text-align: center; height: 280px">
        <ng-container *ngIf="createCaseDoneFlag; else confirmModalLoading">
            <ng-container *ngIf="!createCaseErrorFlag; else confirmModalError">
                <ng-container *ngIf="!createCaseForAspFlag; else confirmModalAspCase">
                    <div>
                        <pureui-svg class="pstg-action-icon" svg="checkmark-circle.svg" height="64"> </pureui-svg>
                    </div>
                    <br />
                    <h5
                        ><b>Case ID: #{{ caseId }}</b></h5
                    >
                    <br />
                </ng-container>
            </ng-container>
            <button class="btn btn-secondary" (click)="close(caseId)">Done</button>
        </ng-container>
        <ng-template #confirmModalLoading>
            <br />
            <pureui-spinner class="p-loading" [pureuiShow]="true"></pureui-spinner>
            <br />
        </ng-template>
        <ng-template #confirmModalError>
            <div style="height: 80px"></div>
            <h4>Oops... Something went wrong...</h4>
            <h4>Please contact administrator or try again later.</h4>
            <div style="height: 64px"></div>
        </ng-template>
        <ng-template #confirmModalAspCase>
            <div>
                <pureui-svg class="pstg-action-icon" svg="checkmark-circle.svg" height="64"> </pureui-svg>
            </div>
            <br />
            <h5
                ><b>Case ID: #{{ caseId }}</b></h5
            >
            <h3
                >This case was created successfully for an ASP managed asset. Please contact your support provider for
                more information about the case.</h3
            >
            <br />
        </ng-template>
    </div>
</ng-template>

<main id="create-edit-support-case">
    <div class="modal-header">
        <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
            <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
        </button>
        <h3 class="modal-title">
            {{ caseId ? 'Edit support case' : 'Open support case' }}
        </h3>
    </div>
    <pureui-spinner class="p-loading" [pureuiShow]="loading && !error"> </pureui-spinner>
    <div *ngIf="error" class="modal-center">
        <h4>Unfortunately there was a problem.</h4>
        <h5>{{ error }}</h5>
    </div>
    <form
        id="support-create-edit-form"
        [ngStyle]="{ visibility: loading ? 'hidden' : 'visible' }"
        [formGroup]="supportCaseForm"
    >
        <section id="support-create-info" class="support-create-section container-fluid">
            <h1 class="section-header">Information</h1>
            <product-section
                formControlName="product"
                [initialProductLine]="initialCase?.productLine"
                [initialSubCategory]="initialCase?.subCategory"
                [initialProduct]="initialCase?.array || initialCase?.arrayId"
                [isBeingEdited]="!!caseId"
                [checkSupportContract]="checkSupportContract"
                [searchArrayName]="searchArrayName"
                [subCategoryOptions]="subCategoryOptions$ | async"
            >
            </product-section>
            <div class="row">
                <div class="col-xs-4">
                    <label class="support-create-label required"> Subject </label>
                </div>
                <div class="col-xs-8">
                    <input id="subject" class="form-control" type="text" formControlName="subject" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-4">
                    <label class="support-create-label required"> Description </label>
                </div>
                <div class="col-xs-8">
                    <textarea class="form-control support-create-description" type="text" formControlName="description">
                    </textarea>
                </div>
            </div>
            <div class="row" *ngIf="!caseId">
                <div class="col-xs-4">
                    <label class="support-create-label required"> Severity </label>
                </div>
                <div class="col-xs-8">
                    <severity-picker formControlName="severity"></severity-picker>
                </div>
            </div>
        </section>
        <section id="support-contact-info" class="support-create-section container-fluid">
            <contact-section
                formControlName="contacts"
                [parentLoading]="loading"
                [initialPrimaryContact]="initialCase?.contact"
                [initialAlternateContact]="initialCase?.alternateContact"
            ></contact-section>
            <div class="btn-toolbar clearfix">
                <button
                    type="button"
                    class="btn btn-primary float-xs-right margin"
                    (click)="submitCase()"
                    [disabled]="!supportCaseForm.valid"
                >
                    {{ caseId ? 'Update' : 'Create' }}
                </button>
                <button type="button" class="btn btn-info float-xs-right margin" (click)="cancelCase()">
                    Cancel
                </button>
            </div>
        </section>
    </form>
</main>
