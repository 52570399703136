import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PhantomWorkload } from '../models/phantom-workload';

@Injectable({ providedIn: 'root' })
export class PhantomWorkloadsService extends GenericService<PhantomWorkload> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PhantomWorkload,
            endpoint: '/rest/v1/forecast/phantom-workloads',
            create: true,
            update: true,
            delete: true,
        });
    }
}
