import { Component, ElementRef, Inject, Input, NgZone, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import {
    Chart,
    Options,
    PositionObject,
    PointOptionsObject,
    TooltipFormatterContextObject,
} from 'highcharts/highstock';
import { Subject } from 'rxjs';
import { WINDOW } from '../../../app/injection-tokens';
import { AppService } from '../../../app/app.service';

export type RadialTooltipFormatterCallbackFunction = (
    context: TooltipFormatterContextObject,
) => false | string | string[];

@Component({
    selector: 'pure-radial-dial',
    templateUrl: 'pure-radial-dial.component.html',
})
export class PureRadialDialComponent implements OnChanges, OnDestroy {
    @Input() readonly size: number;
    @Input() readonly innerSize: number = 90; // percentage of the dial's inner diameter that is whitespace
    @Input() readonly arcs: IRadialDialSegment[];
    @Input() readonly enableTooltip: boolean = true; // Tooltip for the entire chart. Individual arc tooltips are in IDialSegment
    @Input() readonly tooltipXOffset?: number; // Offset from left side of chart
    @Input() readonly tooltipYOffset?: number; // Offset from top of chart
    @Input() readonly chartTitle: string; // used for the default tooltip title
    @Input() readonly centerLabel: string;
    @Input() readonly centerLabelOffset: number = 0;
    @Input() readonly subtitle: string;
    @Input() readonly subtitleColor: string = '#666666';
    @Input() readonly subtitleOffset: number = 15;
    @Input() readonly overrideTooltipFunc?: RadialTooltipFormatterCallbackFunction;
    @Input() readonly enableAnimation: boolean = true;

    @ViewChild('radialDialChartContainer', { static: true }) readonly chartContainer: ElementRef<HTMLDivElement>;
    chart: Chart;
    chartBoundRect: DOMRect;

    private controllerDestroyed = false;
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private appService: AppService,
        private ngZone: NgZone,
    ) {}

    ngOnChanges(): void {
        this.buildChart();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
        this.chart?.destroy();
    }

    private buildChart(): void {
        const seriesOptions: PointOptionsObject[] = this.arcs.map(arc => {
            return {
                name: arc.name,
                y: arc.value,
                color: arc.color,
                custom: { onClick: arc.onClick },
                className: arc.className,
            };
        });

        // Create/draw the chart in the next digest cycle to give the parent element time to have its width established
        // This does not affect any bindings, so we don't need to invoke apply
        this.ngZone.runOutsideAngular(() => {
            // Build chart outside of NgZone to avoid change detection on every mousemove
            this.window.setImmediate(() => {
                if (!this.controllerDestroyed) {
                    // Make sure we don't try creating the chart after the controller gets destroyed
                    this.chart = new Chart(this.getChartOptions(seriesOptions));
                }
            });
        });
    }

    private getChartOptions(seriesOptions: PointOptionsObject[]): Options {
        const chartElem = this.chartContainer.nativeElement;
        const chartTitle = this.chartTitle;
        const chartSize = this.size;
        const tooltipXOffset = this.tooltipXOffset;
        const tooltipYOffset = this.tooltipYOffset;
        const overrideTooltipFunc = context => this.overrideTooltipFunc(context);
        return {
            chart: {
                backgroundColor: null,
                renderTo: chartElem,
                plotShadow: false,
                height: chartSize,
                width: chartSize,
                margin: 0,
            },
            tooltip: {
                enabled: this.enableTooltip,
                useHTML: true,
                outside: true, // need this so tooltip can render outside of chart area
                hideDelay: 100,
                positioner: function (): PositionObject {
                    // additional offset chosen for tooltip to be under and slightly to the right
                    const chartPosition = this.chart.pointer.getChartPosition();
                    return { x: chartPosition.left + tooltipXOffset, y: chartPosition.top + tooltipYOffset };
                },
                formatter: this.overrideTooltipFunc
                    ? function (): any {
                          return overrideTooltipFunc(this);
                      }
                    : function (): string {
                          const selected = this.point;
                          const points = this.series.points;
                          let rowsHtml = '';
                          points.forEach(point => {
                              const isSelected = point.name === selected.name;
                              const generatedTableRow = `
                            <tr class="${isSelected ? 'selected' : ''}">
                                <td>
                                    <span class="entityColor" style="background: ${point.color}"></span>
                                    <span class="metricName">${point.name}</span>
                                    <span class="metricValue metricValueRightAlign pull-right">${point.y}</span>
                                </td>
                            </tr>`;
                              rowsHtml += generatedTableRow;
                          });

                          return `
                        <div class="perf-chart-tooltip radial-dial-tooltip">
                            <div class="tt-header">
                                <div class="header-left">${chartTitle}</div>
                            </div>
                            <div class="tt-body">
                                <table class="table">
                                    ${rowsHtml}
                                </table>
                            </div>
                        </div>`;
                      },
            },
            title: {
                text: this.centerLabel,
                align: 'center',
                y: this.size / 2 + this.centerLabelOffset,
            },
            subtitle: {
                text: this.subtitle,
                useHTML: true,
                align: 'center',
                y: this.size / 2 + this.centerLabelOffset + this.subtitleOffset,
                style: {
                    color: this.subtitleColor,
                },
            },
            plotOptions: {
                pie: {
                    slicedOffset: 0,
                    size: '100%',
                    dataLabels: {
                        enabled: false,
                    },
                    animation: this.enableAnimation,
                },
            },
            series: [
                {
                    name: chartTitle,
                    type: 'pie',
                    innerSize: this.innerSize,
                    data: seriesOptions,
                    animation: this.enableAnimation,
                    events: {
                        click: e => {
                            e.point.options.custom.onClick?.();
                        },
                    },
                    states: {
                        inactive: {
                            enabled: false,
                        },
                    },
                },
            ],
        };
    }
}
