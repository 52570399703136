import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityQuote } from '@pure1/data';
import { QuoteUrlService } from '../../../service-catalog/services/quote-url.service';

@Component({
    selector: 'quote-management-modal',
    templateUrl: 'quote-management-modal.component.html',
})
export class QuoteManagementModalComponent {
    @Input() readonly opportunityQuote: OpportunityQuote;
    @Input() readonly modal: NgbActiveModal;

    downloadUrl: string;

    constructor(private quoteUrlService: QuoteUrlService) {}

    onClick(): void {
        this.quoteUrlService.openNewWindowOnDownloadUrlWith302Response(
            this.opportunityQuote.salesFlowId,
            this.opportunityQuote.id,
        );
    }
}
