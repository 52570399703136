import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Bucket } from '../models/bucket';

@Injectable({ providedIn: 'root' })
export class BucketService extends GenericService<Bucket> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Bucket,
            endpoint: '/rest/v3/buckets',
        });
    }
}
