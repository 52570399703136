import { isEmpty } from 'lodash';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ArrayContractStatus, buildUrlFilterStringForV3Endpoint, ServiceCatalogQuote, UnifiedArray } from '@pure1/data';

import { FArray, FBlade, HistoricalPerformanceData, IArrayHealth, isFBladeProduct, PureArray } from '../model/model';
import PureUtils from '../utils/pure_utils';
import { Angulartics2 } from 'angulartics2';

// TODO: Eventually, this should be changed to work on UnifiedArray instead of PureArray

/**
 * Frequently requested fields
 */
const FREQUENTLY_REQUESTED_FIELDS = new Set<getArraysFields>(<getArraysFields[]>[
    'org_id',
    'last_updated',
    'is_current',
    'array_id',
    'domain',
    'gui_url',
    'hostname',
    'message_counts',
    'purearray_monitor',
    'purearray_list_space',
    'utility_usage_summary',
    'model',
    'os',
    'purity_version',
    'version',
    'protocol_list',
    'protocol',
    'product',
    'city',
    'country',
    'fqdn',
    'contract_expiration_date',
    'contract_status',
    'contract_renewal_status',
    'contract_last_renew_requested',
    'active_status',
    'safe_mode_global',
    'safe_mode_object_store',
    'safe_mode_file_systems',
    'safe_mode_status_weight',
    'end_of_life_hardware',
    'has_end_of_life_hardware',
    'capacity_expandable',
]);

/**
 * Rest v3 arrays endpoint
 */
const ARRAYS_ENDPOINT = '/rest/v3/arrays';

const PERF_ENDPOINT = '/rest/v3/arrays?fields=array_id,hostname,product,performance_history&filter=array_id%3D';

/**
 * Metric availability depends on the asset type.
 */
export type HealthDataMetric =
    | 'empty'
    | 'unique'
    | 'volumes'
    | 'snapshots'
    | 'shared_space'
    | 'fileSystemSpace'
    | 'objectStoreSpace'
    | 'system'
    | 'dataReduction'
    | 'replication';

export type IHealthData = { [metric in HealthDataMetric]?: ITimeseriesValues };

export interface IGetHealthDataResult {
    [arrayId: string]: IArrayHealth;
}

interface IArrayCapacityRawData {
    provisioned?: ITimeseriesValues;
    s3_bucket_space?: ITimeseriesValues;
    physical_unique?: ITimeseriesValues;
    snapshot_space?: ITimeseriesValues;
    physical_shared?: ITimeseriesValues;
    file_system_space?: ITimeseriesValues;
    system_space?: ITimeseriesValues;
    total_system_size?: ITimeseriesValues;
    replication_space?: ITimeseriesValues;
    volume_space?: ITimeseriesValues;
    unique_space?: ITimeseriesValues;
}

export type IArrayCapacityData = {
    [key in HealthDataMetric]?: ITimeseriesValues;
};

export interface IGetArraysResult {
    totalArrays: number;
    arrays: IPureArray[];
}

interface IGetPerfArrayResult {
    performance_history: { [key: string]: any[] };
}

@Injectable({ providedIn: 'root' })
export class ArraysManager {
    readonly expiredSupportContractArraysCountUpdated$ = new Subject<void>();

    pureArrays: PureArray[];
    currentGetPureArraysRequest: Promise<any>;

    utilityArrayPresent: boolean;
    expiredEvergreenViewShown: boolean;

    private refreshPerfData = new Map<string, Promise<any>>();
    private expiredSupportContractArraysCount: number | null = null;

    constructor(
        private angulartics2: Angulartics2,
        private http: HttpClient,
    ) {
        this.utilityArrayPresent = false;
        this.expiredEvergreenViewShown = false;
    }

    /**
     * Gets the specified performance metrics for an array.
     */
    getPerformanceMetrics(
        arrayId: string,
        metrics: PerformanceMetricsApiRequestMetricNames[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregationMethod: IAggregationMethod,
        maxPoints?: number,
    ): Promise<IGetPerformanceMetricsResult> {
        const request$ = this.http.get<IGetArrayMetricsResponse>('/rest/v1/metrics/arrays', {
            params: {
                array_id: arrayId,
                metrics: metrics.join(','),
                starttime: String(Math.round(startTime.valueOf())),
                endtime: String(Math.round(endTime.valueOf())),
                aggregation: aggregationMethod,
                max_points: maxPoints != null ? String(maxPoints) : undefined,
            },
        });

        const resultPromise = request$
            .pipe(take(1))
            .toPromise()
            .then(response => {
                return <IGetPerformanceMetricsResult>{
                    values: response,
                    granularity: PureUtils.getTimeSeriesGranularity(response),
                };
            });

        return resultPromise;
    }

    /**
     * Gets the performance metrics for multiple arrays at once
     */
    getPerformanceMetricsMultipleArrays(
        arrayIds: string[],
        metrics: PerformanceMetricsApiRequestMetricNames[],
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregationMethod: IAggregationMethod,
        maxPoints?: number,
    ): Promise<Map<string, IGetPerformanceMetricsResult>> {
        // The api supports multiple objects in the request, but this method keeps it simple and uses the same
        // parameters for each requested array since that is usually what we need on the front-end.
        const requestBody = {
            arrays: arrayIds,
            metrics: metrics,
            startTime: Math.round(startTime.valueOf()),
            endTime: Math.round(endTime.valueOf()),
            aggregation: aggregationMethod,
            maxPoints: maxPoints,
        };

        const request$ = this.http.post<IGetArrayMetricsPostResponse>('/rest/v1/metrics/arrays', [requestBody]);

        const resultPromise = request$
            .pipe(take(1))
            .toPromise()
            .then(response => {
                const resultsMap = new Map<string, IGetPerformanceMetricsResult>();
                Object.keys(response.result).forEach(arrayId => {
                    const arrayData = response.result[arrayId];

                    resultsMap.set(arrayId, {
                        values: <any>arrayData,
                        granularity: PureUtils.getTimeSeriesGranularity(arrayData),
                    });
                });

                return resultsMap;
            });

        return resultPromise;
    }

    getArraysV3(
        fields: getArraysFields[],
        start: number,
        limit?: number,
        sortBy?: pureArraySortByField,
        sortReverse?: boolean,
        filter?: IGlobalFilters,
    ): Promise<IGetArraysResult> {
        const filterParam = this.buildArraysFilterParamForPureArrays(filter);
        const sortParam = this.buildArraysSortParam(sortBy, sortReverse);

        const params = PureUtils.toUrlParams({
            fields: fields.join(','),
            start: start,
            limit: limit,
            filter: filterParam ? encodeURIComponent(filterParam) : undefined, // Turn falsey (inc. empty string) into undefined to avoid included it in query
            sort: sortParam,
        });

        const request$ = this.http.get<IPureArray[]>(ARRAYS_ENDPOINT + (params ? '?' + params : ''), {
            observe: 'response',
        });

        const resultPromise = request$
            .pipe(take(1))
            .toPromise()
            .then(response => {
                return {
                    totalArrays: Number(response.headers.get('X-TOTAL-ITEM-COUNT')),
                    arrays: response.body,
                };
            });

        return resultPromise;
    }

    getSupportContractRenewalQuote(array: UnifiedArray, allOrders: ServiceCatalogQuote[]): ServiceCatalogQuote {
        if (array?.contract_renewal_status !== 'Nonrenewable') {
            return allOrders.find(quote => {
                const supportContractArrayInfo = quote?.supportContractArrayInfos?.find(
                    arrayInfo => arrayInfo?.array_id === array?.cloud_array_id,
                );
                return !!supportContractArrayInfo;
            });
        }
        return null;
    }

    fireGAEventForCustomerSeenExpiredEvergreenCardView(analyticsAction: string): void {
        if (!this.expiredEvergreenViewShown) {
            this.angulartics2.eventTrack.next({
                action: analyticsAction + ' appliances_arrays_view-expired',
                properties: { category: 'Action' },
            });
        }
        this.expiredEvergreenViewShown = true;
    }

    isCardShowArrayInfo(array: UnifiedArray): boolean {
        //check whether array is under expired status.
        const isArrayExpired = array?.contract_status === ArrayContractStatus.EXPIRED;
        //can show array info instead of out of support on appliance page
        // 1. not at expired status
        return !isArrayExpired;
    }

    isEndOfContractCardViewEnabled(featureFlag: boolean, supportExpiration: moment.Moment): boolean {
        return featureFlag && supportExpiration?.isBefore(moment.utc());
    }

    getPureArrays(
        fields: string,
        filter?: IGlobalFilters,
        supportStatusFilterOption?: SupportStatusFilterOption,
    ): Promise<PureArray[]> {
        // TODO: Should probably return UnifiedArray[] instead?
        const filterParam = this.buildArraysFilterParamForPureArrays(filter); // TODO: filterParam isn't used by processFields(), thus completely unused? Is that intentional?
        fields = this.processFields(fields, filterParam);

        const arrayRequest$ = this.http.get<IPureArray[]>(ARRAYS_ENDPOINT, {
            params: {
                fields: fields,
                filter: filterParam ? filterParam : undefined,
                support_status_filter_option: supportStatusFilterOption,
            },
        });

        this.currentGetPureArraysRequest = arrayRequest$
            .pipe(take(1))
            .toPromise()
            .then(response => {
                const arrayData = response;
                this.pureArrays = [];

                if (arrayData) {
                    arrayData.forEach(array => {
                        const fObj = isFBladeProduct(array.product) ? new FBlade(array) : new FArray(array);
                        this.pureArrays.push(fObj);
                    });
                }

                // Update connected arrays counts if there's no filters
                if (isEmpty(filter)) {
                    // Note: filter can also be an empty object
                    const newExpiredContractCount = this.pureArrays.filter(
                        array => array.contract_status === ArrayContractStatus.EXPIRED,
                    ).length;
                    if (newExpiredContractCount !== this.expiredSupportContractArraysCount) {
                        this.expiredSupportContractArraysCount = newExpiredContractCount;
                        this.expiredSupportContractArraysCountUpdated$.next();
                    }
                }

                return this.pureArrays;
            });

        return this.currentGetPureArraysRequest;
    }

    getCapacityData(arrayId: string, isFlashBlade: boolean): Promise<IArrayCapacityData | null> {
        return this.ajaxCall('spog', 'array.capacity', { array_id: arrayId })
            .pipe(take(1))
            .toPromise()
            .then(response => {
                const arrayData = (response.arrayMap || {})[arrayId];
                return arrayData ? this.formatArrayCapacity(arrayData, isFlashBlade) : null;
            });
    }

    getHealthData(arrayIds: string[]): Promise<IGetHealthDataResult> {
        return this.ajaxCall('spog', 'array.health', { array_id: arrayIds.join(',') })
            .pipe(take(1))
            .toPromise()
            .then(response => {
                return response.arrayMap || {};
            });
    }

    getPerformanceData(arrayId: string, timestamp: number): Promise<HistoricalPerformanceData> {
        const cacheKey = `${arrayId}_${timestamp}`;
        let request: Promise<HistoricalPerformanceData>;
        if (this.refreshPerfData.has(cacheKey)) {
            request = this.refreshPerfData.get(cacheKey);
        } else {
            request = this.http
                .get<IGetPerfArrayResult[]>(`${PERF_ENDPOINT}'${arrayId}'`)
                .pipe(take(1))
                .toPromise()
                .then(response => {
                    this.refreshPerfData.delete(cacheKey);
                    return new HistoricalPerformanceData(response[0].performance_history);
                })
                .catch<any>(error => {
                    this.refreshPerfData.delete(cacheKey);
                    console.error('An error occurred while fetching the arrays.', error);
                });

            this.refreshPerfData.set(cacheKey, request);
        }
        return request;
    }

    buildArraysFilterParamForPureArrays(filter: IGlobalFilters): string {
        const paramsWrapWildcard: string[] = [
            'array_name',
            'hostname',
            'host_iqn_wwn_list',
            'target_iqn_wwn_list',
            'host_hostname_list',
            'fqdn',
        ];
        const paramsTags: string[] = ['tags'];

        return buildUrlFilterStringForV3Endpoint(filter, {
            paramsWrapWildcard: paramsWrapWildcard,
            paramsTags: paramsTags,
        });
    }

    getAggregatedFleetSummary(filter: IGlobalFilters): Promise<IAggregatedFleetSummary> {
        return this.http
            .get<IAggregatedFleetSummary>('/rest/v3/arrays/aggregation_summary', {
                params: {
                    filter: this.buildArraysFilterParamForPureArrays(filter),
                },
            })
            .pipe(take(1))
            .toPromise();
    }

    performUtilityArrayPresenceCheck(): Promise<void> {
        const request$ = this.http.get<(IPureArray & { utility_usage_summary: any })[]>(ARRAYS_ENDPOINT, {
            params: {
                fields: 'array_id,hostname,utility_usage_summary',
                start: '0',
                limit: '1',
                sort: 'utility_usage_summary.history_30days_average-',
            },
        });

        const resultPromise = request$
            .pipe(take(1))
            .toPromise()
            .then(response => {
                this.utilityArrayPresent =
                    response &&
                    response.length > 0 &&
                    response[0].utility_usage_summary &&
                    response[0].utility_usage_summary.is_utility_array;
            });

        return resultPromise;
    }

    /**
     * Calls the (super old and painfully outdated!) /json endpoint
     */
    private ajaxCall(action: string, command: string, data: any): Observable<any> {
        data.action = action;
        data.command = command;

        const body = $.param({
            json: JSON.stringify(data),
        });

        return this.http.post<any>('/json', body, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }

    /**
     * Gets the query string to use for the 'sort' parameter
     */
    private buildArraysSortParam(sortBy: pureArraySortByField, sortReverse: boolean): string {
        const queryParts: string[] = [];

        // Perform the specified sort first
        if (sortBy) {
            queryParts.push(sortBy + (sortReverse ? '-' : ''));
        }

        // Always end the search by hostname, unless already specified, to ensure
        // a consistent order for the results.
        if (sortBy !== 'hostname') {
            queryParts.push('hostname');
        }

        return queryParts.join(',');
    }

    // union fields with frequently requested fields
    private processFields(fields: string, filterParam: string): string {
        const frequentFields: string[] = Array.from(FREQUENTLY_REQUESTED_FIELDS);
        const fieldSet = new Set<string>();
        const fieldsArray =
            fields && fields.length > 0
                ? fields.split(',').map(field => {
                      return field.trim();
                  })
                : [];
        frequentFields.concat(fieldsArray).forEach((field: string) => {
            fieldSet.add(field);
        });
        return Array.from(fieldSet).join(',');
    }

    private formatArrayCapacity(rawData: IArrayCapacityRawData, isFlashBlade: boolean): IArrayCapacityData {
        let data: IArrayCapacityData = {};
        if (isFlashBlade) {
            data = {
                fileSystemSpace: rawData.provisioned || rawData.file_system_space,
                objectStoreSpace: rawData.s3_bucket_space,
                volumes: rawData.volume_space,
                snapshots: rawData.snapshot_space,
                shared_space: rawData.physical_shared,
                system: rawData.system_space,
                empty: rawData.total_system_size,
                replication: rawData.replication_space,
            };
        } else {
            data = {
                unique: rawData.unique_space,
                snapshots: rawData.snapshot_space,
                shared_space: rawData.physical_shared,
                system: rawData.system_space,
                empty: rawData.total_system_size,
                replication: rawData.replication_space,
            };
        }

        // For FA/FB, we always have empty, but don't always have the other metrics. So use the empty array
        // as the baseline to make them all the same length.
        if (data.empty) {
            const dataKeys: HealthDataMetric[] = [
                'unique',
                'volumes',
                'snapshots',
                'shared_space',
                'fileSystemSpace',
                'objectStoreSpace',
                'system',
                'replication',
            ]; // All metrics except "empty"

            const keysForCapacitySumUniqueOnly: HealthDataMetric[] = [
                'unique',
                'snapshots',
                'shared_space',
                'system',
                'replication',
            ]; // use unique instead of volumes/filesystems/objectstore

            const keysForCapacitySumNoUnique: HealthDataMetric[] = [
                'volumes',
                'snapshots',
                'shared_space',
                'fileSystemSpace',
                'objectStoreSpace',
                'system',
                'replication',
            ]; // All metrics except "empty"

            dataKeys
                .filter(prop => data[prop] != null)
                .forEach(prop => {
                    const diff = data.empty.length - data[prop].length;
                    if (diff !== 0) {
                        const prefix: ITimeseriesValues = [];
                        for (let i = 0; i < diff; i++) {
                            prefix.push([data.empty[i][0], 0]);
                        }
                        data[prop] = prefix.concat(data[prop]);
                    }
                });

            for (let i = 0; i < data.empty.length; i++) {
                const isUniquePresent = data.unique?.[i];
                const keySetToSum = isUniquePresent ? keysForCapacitySumUniqueOnly : keysForCapacitySumNoUnique;
                const sumNonEmpty = keySetToSum
                    .map(prop => (data[prop] ? data[prop][i][1] : 0)) // Get y-axis value of each metric
                    .reduce((a, b) => a + b, 0); // Sum

                data.empty[i][1] = Math.max(data.empty[i][1] - sumNonEmpty, 0);
            }
        }

        return data;
    }
}

interface IGetArrayMetricsResponse {
    [metricName: string]: ITimeseriesValues;
}

interface IGetArrayMetricsPostResponse {
    result: {
        [arrayId: string]: IGetArrayMetricsResponse;
    };
}
