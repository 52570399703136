import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatReplicationStatus',
})
export class FormatReplicationStatusPipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 0:
                return 'Replicating';
            case 1:
                return 'Baselining';
            case 2:
                return 'Paused';
            case 3:
                return 'Unhealthy';
            case 4:
                return 'Quiescing';
            default:
                console.warn('UNKNOWN Pod Replication Status: ' + status);
                return '-';
        }
    }
}
