<div class="d-inline" [formGroup]="arrayForm">
    <ng-template #rt let-result="result" let-term="term">
        <div class="search-array-item" [ngClass]="{ 'text-muted': checkSupportContract && result.isOutOfSupport() }">
            <ngb-highlight
                *ngIf="result.name !== 'Other' && arrayNameTooltipService.getFQDNFromId(result.id, result.name) as fqdn"
                [result]="fqdn"
                [term]="term"
            ></ngb-highlight>
            <ngb-highlight *ngIf="result.name === 'Other'" [result]="result.name" [term]="term"></ngb-highlight>
            <div class="search-array-item-info">
                <ngb-highlight
                    class="secondary-info search-array-item-model"
                    [result]="result.model"
                    [term]="term"
                ></ngb-highlight>
                <span class="search-array-info-separator" *ngIf="result.model && result.version">|</span>
                <ngb-highlight
                    class="secondary-info search-array-item-version"
                    [result]="result.version"
                    [term]="term"
                ></ngb-highlight>

                <span
                    class="secondary-info search-array-item-support"
                    *ngIf="checkSupportContract && !!result.contract_expiration"
                >
                    <ng-container *ngIf="result.isOutOfSupport()">
                        Support expired&nbsp;<span class="text-danger">{{
                            result.contract_expiration | date: 'MM-dd-yyyy'
                        }}</span>
                    </ng-container>

                    <ng-container *ngIf="result.isSupportInGracePeriod()">
                        Support expired&nbsp;<span class="text-danger"
                            >{{ result.contract_expiration | date: 'MM-dd-yyyy' }} (grace period)</span
                        >
                    </ng-container>

                    <ng-container *ngIf="result.isSupportActive()">
                        Support expires {{ result.contract_expiration | date: 'MM-dd-yyyy' }}
                    </ng-container>
                </span>
            </div>
        </div>
    </ng-template>

    <input
        class="form-control full-width"
        type="text"
        [ngbTypeahead]="search"
        [value]="searchString"
        (selectItem)="onItemSelected($event)"
        (click)="click$.next('')"
        (keydown)="typeaheadKeydown($event)"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        #typeInstance="ngbTypeahead"
        #typeaheadInput
        formControlName="array"
    />
</div>
