import { get } from 'lodash';

/**
 * Searches for a particular text in the text fields of an array of objects.
 * @param data The array of objects upon which the search will be performed
 * @param fields The fields to search the text on
 * @param text The text to search
 * @returns The subarray of objects containing the text in at least one of the given fields
 */
export function textSearch<T>(data: T[], fields: string[], text: string): T[] {
    text = text.toLowerCase();
    return data.filter((obj: any): boolean => {
        if (obj.pureTextSearchIgnore) {
            return true;
        }
        // if any of the fields contains the value, return true for this obj
        for (let fieldIdx = 0; fieldIdx < fields.length; fieldIdx++) {
            const value = get(obj, fields[fieldIdx]);
            if (typeof value === 'string') {
                if (value.toLowerCase().indexOf(text) !== -1) {
                    return true;
                }
            }
        }
        return false;
    });
}
