import { Observable, from } from 'rxjs';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
} from '@angular/forms';

import { PureArray } from '../../../model/PureArray';
import { ArraysManager } from '../../../services/arrays-manager.service';
import { forString } from '../../../utils/comparator';
import { SearchArrayComponent } from '../../shared/components/search-array/search-array.component';
import { AbstractProductSectionAppliancesComponent } from '../abstract-product-section-appliances';

@Component({
    selector: 'product-section-flashblades',
    templateUrl: 'product-section-flashblades.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductSectionFlashBladesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProductSectionFlashBladesComponent),
            multi: true,
        },
    ],
})
export class ProductSectionFlashBladesComponent extends AbstractProductSectionAppliancesComponent<
    PureArray,
    SearchArrayComponent
> {
    constructor(
        private arraysManager: ArraysManager,
        protected cdr: ChangeDetectorRef,
        protected fb: UntypedFormBuilder,
    ) {
        super(cdr, fb);
    }

    getAppliances(): Observable<PureArray[]> {
        const arraysPromise = this.arraysManager
            .getPureArrays('array_id,fqdn,hostname,os,purity_version,model', null, 'all_arrays')
            .then(arrays => {
                return arrays.filter(fa => fa.isFlashBlade()).sort(forString<PureArray>(array => array.hostname).asc);
            });
        return from(arraysPromise);
    }

    selectVisibleProduct(initialProduct: PureArray): void {
        if (!initialProduct) {
            return;
        }
        this.appliances$.subscribe(() => {
            if (this.isBeingEdited) {
                const selectedArray = this.appliances.find(arr => arr.arrayId === initialProduct.arrayId);
                if (!selectedArray) {
                    this.appliances = this.appliances
                        .concat(initialProduct)
                        .slice()
                        .sort(forString<PureArray>(array => array.hostname).asc);
                }
                this.writeValue(initialProduct);
                return;
            }
            if (this.appliances.length === 0) {
                this.appliances = [initialProduct];
            }
            this.writeValue(initialProduct);
        });
    }

    validateAppliance(control: UntypedFormControl): ValidationErrors {
        // Let's not bother with checking when editing
        if (this.isBeingEdited) {
            return null;
        }
        const val = control.value;
        if (val && val instanceof PureArray) {
            // valid array has been selected
            if (this.checkSupportContract && val.isOutOfSupport()) {
                return { outOfSupport: `${val.product} does not have active support contract` };
            }
        } else {
            return { array: 'A valid product is required' };
        }
        return null;
    }

    writeValue(val: any): void {
        // need to wait for the arrays, otherwise the draft recovery breaks as we store only arrayId
        this.appliances$.subscribe(() => {
            if (val?.arrayId) {
                const selectedArray = this.appliances.find(arr => arr.arrayId === val.arrayId);
                selectedArray && this.form.setValue({ product: selectedArray });
            }
        });
    }
}
