import _ from 'lodash';
import { Subject, Observable, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    OnDestroy,
    HostListener,
    Inject,
} from '@angular/core';
import { Resource, SortParams } from '@pure1/data';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';

import { FilterEntityKeyToValuesMap } from '../../../gui/paged-data2.component';
import { DynamicTooltipService } from '../../../services/dynamic-tooltip.service';
import { ToastService, ToastType } from '../../../services/toast.service';
import { ampli } from '../../../ampli';
import { WINDOW } from '../../../app/injection-tokens';

const DEFAULT_MAX_SELECTED_ITEMS = 30;

const DEFAULT_MAX_SELECTED_MESSAGE = `You've reached the limit<br>of selected items`;

export interface ITableColumn<T> {
    title: string | TemplateRef<any>;
    subtitle?: string | TemplateRef<any>;
    key: string;
    showWhenUnavailable?: boolean;
    weight?: number;
    isHidden: boolean;
    centerText?: boolean;
    centerVertical?: boolean;
    getSortKey?: () => string;
    getSortParams?: () => SortParams[];
    sortParamsMapping?: (sortParams: SortParams) => string | TemplateRef<any>;
    getText?: (item: T) => string | TemplateRef<any>;
}

export interface IColumnOption<T extends Resource> extends ITableColumn<T> {
    // IMPORTANT: IF hasSearch IS TRUE, YOU MUST ALSO PASS searchItem. DON'T FORGET!
    // searchItem must be the appropriate entity for its column
    // eventually there should maybe be an ISearchableColumnOption or equivalent
    hasSearch: boolean;
    searchItem?: string;
    collapsedWeight?: number;
    collapsedColumnOption?: ITableColumn<T>;
    subColumns?: ITableColumn<T>[];
}

export interface ISearchParameters {
    key: string;
    value: string;
    entity: string;
}

export interface IDisabledResourceConfigOptions<T extends Resource> {
    tooltipMessage?: string;
    isDisabled: (item: T) => boolean;
}

export interface IUnavailableResourceConfigOptions<T extends Resource> {
    isUnavailable: (item: T) => boolean;
    getUnavailableTemplate: () => string | TemplateRef<any>;
}

export interface IMaximumSelectionConfigOptions {
    tooltipMessage?: string;
    /** Maximum numbers of items that can be selected, whether through manually clicking or through SelectAllFiltered */
    maximumSelection?: number;
}

// If provided, this will override selectResource when clicking on any part of the row that's not the checkbox
export interface ICustomRowClickConfigOptions<T extends Resource> {
    onRowClick: (event: MouseEvent | TouchEvent, item: T) => void;
    resetRecent$?: () => Subject<void>;
}

export interface ISelectAllFilteredConfigOptions<T extends Resource> {
    /** What to call the "items" (pluralized). Eg: "volumes" */
    itemName: string;
    /** Gets all of the filtered items */
    getFilteredItems(): Observable<T[]>;
    /** Gets only the count of filtered items (eg, total item count as seen in the pagination) */
    getFilteredItemCount(): number;
    /** Gets the count of the filtered items in current page */
    getFilteredItemCountInCurrentPage?: () => number;
}

export interface ITableConfigOptions<T extends Resource> {
    disabledOptions?: IDisabledResourceConfigOptions<T>[];
    unavailableOptions?: IUnavailableResourceConfigOptions<T>[];
    maxSelectionOption?: IMaximumSelectionConfigOptions;
    customRowClickOptions?: ICustomRowClickConfigOptions<T>;
    selectAllFilteredOptions?: ISelectAllFilteredConfigOptions<T>;

    /** Text to show when there are no results. If null, it will use the default of: "No results found. Please adjust your filters." */
    noResultsMessage?: string | TemplateRef<any>;

    /** If specified, allows additional classes to be added to a table row based on the item. Separate multiple classes with space. */
    getTableRowClasses?: (item: T) => string;
}

/**
 * PerformanaceTable is NOT just for the Performnace pages! Think of it as our general purpose table instead.
 * Prefer using the Hive table (#ask-ui-next). If that doesn't work, use this one. Only make your own table component as a last resort.
 */
@Component({
    selector: 'performance-table',
    templateUrl: 'performance-table.component.html',
    providers: [DynamicTooltipService],
})
export class PerformanceTableComponent<T extends Resource> implements OnChanges, OnDestroy {
    @Input() readonly angularticsPrefix?: string;
    @Input() readonly tableOptions: ITableConfigOptions<T> | null;
    @Input() readonly columnOptions: IColumnOption<T>[];
    /** Should be null only before the data is fetched for the first time */
    @Input() readonly items: T[] | null;
    @Input() readonly sort: SortParams;
    @Input() readonly defaultSort: SortParams;
    @Input() readonly tableHeaderFilters: FilterEntityKeyToValuesMap;
    @Input() readonly isLoading: boolean;
    @Input() readonly limitToOneExpandedColumn: boolean = false;
    @Input() readonly isReadonly: boolean = false;
    @Input() selection: T[] = []; // TODO: [CLOUD-30732] Components should not modify their inputs. Refactor to make this readonly.
    @Input() readonly multiSelect: boolean = true; // this will enable multiple checkbox row selections
    @Input() readonly disableDeselection: boolean = false;
    @Input() readonly visibleSecondaryRowIds = new Set<string>();
    @Input() readonly secondaryRowTemplate: TemplateRef<any>;

    @Output() readonly selectionChange = new EventEmitter<T[]>();
    @Output() readonly sortChange = new EventEmitter<string>();
    @Output() readonly sortParamsChange = new EventEmitter<SortParams>();
    @Output() readonly searchChange = new EventEmitter<ISearchParameters>();

    @ViewChild('selectAllCheckbox') readonly selectAllCheckbox: ElementRef<HTMLInputElement>;
    @ViewChild('sortParamsDropdown') readonly sortParamsDropdown: ElementRef;

    columns: ITableColumn<T>[] = [];

    sortParamsDropdownColumnKey: string;
    showSortParamsDropdown: boolean;

    /** If the Select All Filtered message is being shown at all */
    showSelectAllFilteredMessage = false;
    /** If the Select All Filtered has been clicked since it has been shown */
    selectAllFilteredClicked = false;
    /** If Select All Filtered has been clicked (and the results returned) */
    isSelectingAllFiltered = false;
    /** When defined, contains the active request to fetch the Select All Filtered items */
    getSelectAllFilteredItems$: Subscription;
    /** Preview count of how many items are available to be selected with Select All Filtered */
    selectAllFilteredCount: number;

    private maxSelectedItems = DEFAULT_MAX_SELECTED_ITEMS;
    private maxSelectedMessage = DEFAULT_MAX_SELECTED_MESSAGE;
    private columnUnavailableSpan = 0;
    private mostRecentResourceClicked: T = null; // Only useful with CustomRowClickConfigOptions

    private readonly columnSubColumnsAreExpanded = new Map<string, boolean>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private dynamicTooltipService: DynamicTooltipService,
        private toast: ToastService,
        private angulartics2: Angulartics2,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tableOptions) {
            const maxSelectedOption = this.tableOptions && this.tableOptions.maxSelectionOption;
            this.maxSelectedItems =
                (maxSelectedOption && maxSelectedOption.maximumSelection) || DEFAULT_MAX_SELECTED_ITEMS;
            this.maxSelectedMessage =
                (maxSelectedOption && maxSelectedOption.tooltipMessage) || DEFAULT_MAX_SELECTED_MESSAGE;

            if (this.tableOptions?.customRowClickOptions?.resetRecent$) {
                this.tableOptions.customRowClickOptions
                    .resetRecent$()
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                        this.mostRecentResourceClicked = null;
                    });
            }
        }

        // Disable the filter message if the feature gets disabled
        if (changes.tableOptions && this.showSelectAllFilteredMessage && !this.isSelectAllFilteredEnabled()) {
            this.clearSelection();
        }

        // If the items change (eg due to pagination/filtering/sorting change), hide the Select All message. We need to check
        // the item ids, not just references, since we don't care if the items updated without any ordering change.
        if (changes.items && this.showSelectAllFilteredMessage) {
            const prevIds = (<T[]>changes.items.previousValue || []).map(item => item.id);
            const currIds = (this.items || []).map(item => item.id);
            if (!_.isEqual(prevIds, currIds)) {
                // Important that we use a comparer that cares about order
                this.showSelectAllFilteredMessage = false;
                if (this.isSelectingAllFiltered || this.getSelectAllFilteredItems$) {
                    this.clearSelection(); // If we had all items selected, clear the selection too
                }
            }
        }

        // If the selected items decreases from the previous value, and we already have all items selected, exit the "select all" state
        if (changes.selection && this.isSelectingAllFiltered) {
            const prevLength = (changes.selection.previousValue || []).length;
            const currLength = (changes.selection.currentValue || []).length;
            if (currLength < prevLength) {
                this.showSelectAllFilteredMessage = false;
                this.isSelectingAllFiltered = false;
            }
        }

        if (changes.columnOptions) {
            this.createFlatColumnList();
        }

        if (changes.items || changes.selection) {
            this.updateSelectAllCheckboxStatus();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    isItemDisabled(item: T): boolean {
        // If doing Select All Filtered, all items will be disabled
        if (this.isSelectAllFilteredEnabled() && (this.isSelectingAllFiltered || this.getSelectAllFilteredItems$)) {
            return true;
        }

        // Check for custom-defined disable logic
        const disabledOpts = this.tableOptions && this.tableOptions.disabledOptions;
        if (disabledOpts && disabledOpts.length > 0) {
            return disabledOpts.some(option => option.isDisabled(item));
        }

        return false;
    }

    isItemUnavailable(item: T): boolean {
        const unavailableOpts = this.tableOptions && this.tableOptions.unavailableOptions;
        if (unavailableOpts && unavailableOpts.length > 0) {
            return unavailableOpts.some(option => option.isUnavailable(item));
        }
        return false;
    }

    areAnyItemsDisabled(): boolean {
        return this.items && this.items.some(item => this.isItemDisabled(item));
    }

    getUnavailableTemplate(item: T): string | TemplateRef<any> {
        const unavailableOpts = this.tableOptions && this.tableOptions.unavailableOptions;

        if (unavailableOpts && unavailableOpts.length > 0) {
            const result = unavailableOpts.find(option => option.isUnavailable(item));
            if (result) {
                return result.getUnavailableTemplate();
            }
        }

        return null;
    }

    getDisabledTooltipMessage(): string {
        const disableOptions = this.tableOptions?.disabledOptions;
        const disableMessage = disableOptions?.[0]?.tooltipMessage || '';
        return disableMessage;
    }

    getColumnUnavailableSpan(): number {
        return this.columnUnavailableSpan;
    }

    getTableHeaderFilter(entity: string, key: string): string {
        return (
            (this.tableHeaderFilters && this.tableHeaderFilters[entity] && this.tableHeaderFilters[entity][key]) || ''
        );
    }

    getHeaderColumnColspan(columnOption: IColumnOption<T>): number {
        let count = (columnOption.subColumns || []).filter(col => !col.isHidden).length;
        if (
            columnOption.subColumns &&
            columnOption.collapsedColumnOption &&
            !this.subColumnsAreExpanded(columnOption)
        ) {
            count = 0;
        }
        return count > 1 ? count : undefined;
    }

    getHeaderColumnWeight(columnOption: IColumnOption<T>): number {
        if (
            columnOption.subColumns &&
            this.columnSubColumnsAreExpanded.has(columnOption.key) &&
            !this.columnSubColumnsAreExpanded.get(columnOption.key) &&
            columnOption.collapsedWeight
        ) {
            return columnOption.collapsedWeight;
        } else {
            return columnOption.weight;
        }
    }

    getTableRowClassArray(item: T): string {
        return this.tableOptions?.getTableRowClasses?.(item);
    }

    getDataRowWeightClass(tableColumn: ITableColumn<T>): string {
        return 'weight-' + (tableColumn.weight == null ? '1' : tableColumn.weight);
    }

    hasSubColumns(columnOption: IColumnOption<T>): boolean {
        return columnOption.subColumns && columnOption.subColumns.length > 0;
    }

    areSubColumnsExpanded(columnOption: IColumnOption<T>): boolean {
        return !(this.columnSubColumnsAreExpanded.get(columnOption.key) === false);
    }

    openSortParamsDropdown(event: MouseEvent, columnOption: IColumnOption<T> | ITableColumn<T>): void {
        this.showSortParamsDropdown = true;
        this.sortParamsDropdownColumnKey = columnOption.key;

        // So that documentClickHandler is not called.
        // Otherwise, it can potentially throw an error if showSortParamsDropdown has been set to true, but sortParamsDropdown has not been rendered yet.
        // This is mostly only applicable in unit tests where fixture.detectChanges() is called manually.
        event.stopPropagation();
    }

    @HostListener('document:click', ['$event'])
    documentClickHandler(event: MouseEvent): void {
        if (
            event.target &&
            this.showSortParamsDropdown &&
            !this.sortParamsDropdown.nativeElement.parentElement.contains(event.target)
        ) {
            this.showSortParamsDropdown = false;
        }
    }

    clickSortParam(sortParam: SortParams): void {
        this.showSortParamsDropdown = false;
        if (this.angularticsPrefix) {
            this.angulartics2.eventTrack.next({
                action: `${this.angularticsPrefix} sorting`,
                properties: { label: sortParam.key + '-' + sortParam.order, category: 'Action' },
            });
        }

        this.sortParamsChange.emit(sortParam);
    }

    toggleSort(columnOption: IColumnOption<T> | ITableColumn<T>): void {
        if (this.angularticsPrefix && typeof columnOption.title === 'string') {
            // Currently don't support other kinds of columnOptions
            this.angulartics2.eventTrack.next({
                action: `${this.angularticsPrefix} sorting`,
                properties: { label: columnOption.title, category: 'Action' },
            });
        }
        if (this.hasSort(columnOption)) {
            this.sortChange.emit(columnOption.getSortKey());
        }
    }

    toggleSubColumns(columnOption: IColumnOption<T>): void {
        if (this.columnSubColumnsAreExpanded.has(columnOption.key)) {
            if (this.limitToOneExpandedColumn) {
                if (this.columnSubColumnsAreExpanded.get(columnOption.key) === false) {
                    this.columnOptions.forEach((columnOptionInner: IColumnOption<T>) => {
                        if (columnOptionInner.subColumns && columnOptionInner.subColumns.length > 0) {
                            this.columnSubColumnsAreExpanded.set(columnOptionInner.key, false);
                        }
                    });
                    this.columnSubColumnsAreExpanded.set(columnOption.key, true);
                } else {
                    // if column is currently expanded and we are collapsing it, we need to expand the next expandable columnset
                    // Note: even if there are no other options available, createFlatColumnList() will re-expand this just collapsed column
                    this.columnSubColumnsAreExpanded.set(columnOption.key, false);
                    for (let i = 0; i < this.columnOptions.length; i++) {
                        if (
                            this.columnOptions[i].subColumns &&
                            this.columnOptions[i].subColumns.length > 0 &&
                            this.columnOptions[i].collapsedWeight &&
                            this.columnOptions[i] !== columnOption
                        ) {
                            this.columnSubColumnsAreExpanded.set(this.columnOptions[i].key, true);
                            break;
                        }
                    }
                }
            } else {
                this.columnSubColumnsAreExpanded.set(
                    columnOption.key,
                    !this.columnSubColumnsAreExpanded.get(columnOption.key),
                );
            }
        } else {
            // by default if the key doesn't exist, it means the column is expanded
            this.columnSubColumnsAreExpanded.set(columnOption.key, false);
        }

        this.createFlatColumnList();
    }

    clickSubColumnTitle($event: MouseEvent, subColumnOption: ITableColumn<T>): void {
        if (subColumnOption.getSortParams) {
            this.openSortParamsDropdown($event, subColumnOption);
        } else {
            this.toggleSort(subColumnOption);
        }
    }

    onSearchChange(key: string, value: string, entity: string): void {
        this.searchChange.emit({
            key: key,
            value: value || '',
            entity: entity,
        });

        ampli.performanceTableFilterChange({
            'filter key': key,
            'filter value': value,
            'filter entity': entity,
            'page pathname': this.window.location.pathname,
        });
    }

    clickSelectAllCheckbox(event: MouseEvent | TouchEvent): void {
        if (!this.items || this.items.length === 0 || this.isReadonly || this.areAnyItemsDisabled()) {
            return;
        }

        this.showSelectAllFilteredMessage = false;
        this.getSelectAllFilteredItems$?.unsubscribe();

        const selectedItems = this.getSelectedItems();

        if (selectedItems.length === 0) {
            const selectedRowCount = this.selection.length;
            for (
                let index = 0;
                index < this.maxSelectedItems - selectedRowCount && index < this.items.length;
                index++
            ) {
                this.selection.push(this.items[index]);
            }
            if (this.selection.length === this.maxSelectedItems) {
                this.dynamicTooltipService.open(this.maxSelectedMessage, event);
                event.preventDefault();
            }
        } else {
            this.selection = this.selection.filter(
                resource => selectedItems.findIndex(selectedItem => selectedItem.id === resource.id) < 0,
            );
            this.mostRecentResourceClicked = null;
        }

        // If we selected all items on this page, and there is more than one page, show the Select All Filtered
        if (this.isSelectAllFilteredEnabled() && this.items.every(item => this.isItemSelected(item))) {
            const filteredItemCount = this.tableOptions.selectAllFilteredOptions.getFilteredItemCount();
            if (filteredItemCount > this.items.length && this.selection.length < filteredItemCount) {
                this.showSelectAllFilteredMessage = true;
                this.selectAllFilteredClicked = false;
                this.isSelectingAllFiltered = false;
                this.selectAllFilteredCount = filteredItemCount;
            }
        }

        this.updateSelectAllCheckboxStatus();
        this.selectionChange.emit(this.selection);
    }

    customRowClick(event: MouseEvent | TouchEvent, item: T): void {
        if (this.tableOptions && this.tableOptions.customRowClickOptions) {
            this.tableOptions.customRowClickOptions.onRowClick(event, item);
        }

        this.mostRecentResourceClicked = item;
    }

    selectClass(item: T): string {
        if (this.isItemMostRecentlyClicked(item)) {
            return 'individual-row-focus';
        }
        return this.isItemSelected(item) ? 'table-active' : '';
    }

    hasSort(column: IColumnOption<T> | ITableColumn<T>): boolean {
        return !!column.getSortKey;
    }

    sortClass(column: IColumnOption<T> | ITableColumn<T>): string {
        if (!this.hasSort(column)) {
            return '';
        }

        let sortClass: string;
        if (this.sort === null) {
            sortClass = column.getSortKey() === this.defaultSort.key ? 'st-sort-ascent' : '';
        } else if (
            this.sort.key === column.getSortKey() ||
            column
                .getSortParams?.()
                .map(sortParam => sortParam.key)
                .includes(this.sort.key)
        ) {
            sortClass = 'st-sort-' + (this.sort.order === 'asc' ? 'ascent' : 'descent');
        }
        return 'manual-sort ' + sortClass;
    }

    selectResource(event: MouseEvent | TouchEvent, item: T): void {
        if (this.isReadonly || this.isItemDisabled(item)) {
            return;
        }

        this.showSelectAllFilteredMessage = false;
        this.getSelectAllFilteredItems$?.unsubscribe();

        if (this.isItemSelected(item)) {
            if (!this.disableDeselection) {
                this.selection = this.selection.filter(selectedItem => selectedItem.id !== item.id);
            }
        } else if (this.selection.length === this.maxSelectedItems) {
            this.dynamicTooltipService.open(this.maxSelectedMessage, event);
            event.preventDefault();
        } else {
            if (!this.multiSelect) {
                this.selection = [];
            }
            this.selection.push(item);
        }
        this.mostRecentResourceClicked = null;
        this.updateSelectAllCheckboxStatus();
        this.selectionChange.emit(this.selection);
    }

    isItemSelected(item: T): boolean {
        return this.selection && this.selection.some(selectedItem => selectedItem.id === item.id);
    }

    isItemMostRecentlyClicked(item: T): boolean {
        return this.mostRecentResourceClicked && this.mostRecentResourceClicked.id === item.id;
    }

    isTemplateRef(value: string | TemplateRef<any>): value is TemplateRef<any> {
        return value instanceof TemplateRef;
    }

    subColumnsAreExpanded(column: IColumnOption<T>): boolean {
        if (this.columnSubColumnsAreExpanded.has(column.key)) {
            return this.columnSubColumnsAreExpanded.get(column.key);
        } else {
            return true;
        }
    }

    isSelectAllFilteredEnabled(): boolean {
        return this.tableOptions?.selectAllFilteredOptions != null;
    }

    clickSelectAllFiltered($event: MouseEvent, tooltip: NgbTooltip): void {
        $event.preventDefault();

        if (this.selectAllFilteredClicked) {
            return; // Ensure it is only clicked once
        }

        if (this.exceedsSelectAllFilteredLimit()) {
            tooltip.open();
            return;
        }

        this.selectAllFilteredClicked = true;

        this.getSelectAllFilteredItems$ = this.tableOptions.selectAllFilteredOptions
            .getFilteredItems()
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe(
                items => {
                    this.getSelectAllFilteredItems$ = null;
                    this.isSelectingAllFiltered = true;
                    this.selection = items;
                    this.updateSelectAllCheckboxStatus(); // Shouldn't have an impact since everything should already be selected, but just in case...
                    this.selectionChange.emit(this.selection);
                },
                err => {
                    this.getSelectAllFilteredItems$ = null;
                    this.toast.add(
                        ToastType.error,
                        `Failed to fetch the ${this.tableOptions.selectAllFilteredOptions.itemName}, please try again.`,
                    );
                    console.error('Failed to fetch SelectAllFiltered items', err);
                },
            );
    }

    clickClearSelection($event: MouseEvent): void {
        $event.preventDefault();
        this.clearSelection();
    }

    exceedsSelectAllFilteredLimit(): boolean {
        return this.selectAllFilteredCount > this.tableOptions?.maxSelectionOption?.maximumSelection;
    }

    private clearSelection(): void {
        this.selection = [];
        this.mostRecentResourceClicked = null;
        this.showSelectAllFilteredMessage = false;
        this.isSelectingAllFiltered = false;
        this.selectAllFilteredClicked = false;
        this.getSelectAllFilteredItems$?.unsubscribe();
        this.getSelectAllFilteredItems$ = null;
        this.selectionChange.emit(this.selection);
        this.updateSelectAllCheckboxStatus();
    }

    private createFlatColumnList(): void {
        this.columns = [];
        this.columnUnavailableSpan = 0;

        if (this.limitToOneExpandedColumn) {
            let firstExpandableColumnSet: IColumnOption<T> = null;
            let expandedColumnSetCount = 0;

            // first make a passthrough the columnOptions to see if any expandable columns exist and
            // collapse all but one expandable column
            this.columnOptions.forEach(columnOption => {
                if (columnOption.subColumns && columnOption.subColumns.length > 0 && columnOption.collapsedWeight) {
                    if (!firstExpandableColumnSet) {
                        firstExpandableColumnSet = columnOption;
                    }
                    if (this.subColumnsAreExpanded(columnOption)) {
                        expandedColumnSetCount++;
                        if (expandedColumnSetCount > 1) {
                            this.columnSubColumnsAreExpanded.set(columnOption.key, false);
                            expandedColumnSetCount--;
                        } else {
                            this.columnSubColumnsAreExpanded.set(columnOption.key, true);
                        }
                    }
                }
            });
            if (expandedColumnSetCount === 0 && firstExpandableColumnSet) {
                this.columnSubColumnsAreExpanded.set(firstExpandableColumnSet.key, true);
            }
        }

        this.columnOptions.forEach(columnOption => {
            if (columnOption.subColumns && columnOption.subColumns.length > 0) {
                if (this.subColumnsAreExpanded(columnOption)) {
                    this.columns.push(...columnOption.subColumns);
                    if (this.hideColumnWhenUnavailable(columnOption)) {
                        this.columnUnavailableSpan += columnOption.weight || 1;
                    }
                } else {
                    this.columns.push(columnOption.collapsedColumnOption);
                    if (this.hideColumnWhenUnavailable(columnOption)) {
                        this.columnUnavailableSpan += columnOption.collapsedColumnOption.weight || 1;
                    }
                }
            } else {
                this.columns.push(columnOption);
                if (this.hideColumnWhenUnavailable(columnOption)) {
                    this.columnUnavailableSpan += columnOption.weight || 1;
                }
            }
        });
    }

    private updateSelectAllCheckboxStatus(): void {
        // The "indeterminate" property is not available as an html attribute, which is why it gets set programatically here
        const checkedCount = this.getSelectedItems().length;
        if (this.multiSelect && this.selectAllCheckbox) {
            const checkbox = this.selectAllCheckbox.nativeElement;

            if (checkedCount === 0) {
                checkbox.checked = false;
                checkbox.indeterminate = false;
            } else if (checkedCount === this.items.length) {
                checkbox.checked = true;
                checkbox.indeterminate = false;
            } else {
                checkbox.checked = false;
                checkbox.indeterminate = true;
            }
        }
    }

    private hideColumnWhenUnavailable(column: IColumnOption<T>): boolean {
        return !column.showWhenUnavailable && !column.isHidden && column.weight !== 0;
    }

    private getSelectedItems(): T[] {
        return (this.items || []).filter(item => this.isItemSelected(item));
    }
}
