import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Tag } from '@pure1/data';

import { TagChange } from '../tag-change';
import { MultiResourceTagValue } from '../multi-resource-tag-value';
import { ArrayTagUpdateService } from '../services/array-tag-update.service';

export interface TagActions {
    upsert: MultiResourceTagValue[];
    delete: MultiResourceTagValue[];
}

@Component({
    selector: 'tag-change-summary',
    templateUrl: './tag-change-summary.component.html',
})
export class TagChangeSummaryComponent implements OnChanges {
    @Input() changes: TagChange[];
    @Output() rollbacked = new EventEmitter<TagActions>();
    private updatedTagKeys = new Set<string>();
    private resourceIds = new Set<string>();

    constructor(private arrayTagUpdateService: ArrayTagUpdateService) {}

    ngOnChanges(): void {
        this.updatedTagKeys.clear();
        this.resourceIds.clear();
        this.changes.forEach((change: TagChange) => {
            this.updatedTagKeys.add(this.getTagUniqueKey(change));
            this.resourceIds.add(change.resourceId);
        });
    }

    get summary(): string {
        if (this.updatedTagKeys.size > 0 && this.resourceIds.size > 0) {
            return this.getSummary();
        } else {
            return '';
        }
    }

    undo(): void {
        const tagsToUpsert: Map<string, MultiResourceTagValue> = new Map();
        const tagsToDelete: Map<string, MultiResourceTagValue> = new Map();

        const getTagUniqueId = (tag: Tag): string => {
            // use key twice to elimiate id collision with key or value contains ':'
            return `${tag.namespace}:${tag.tag_organization_id}:${tag.key}:${tag.value}:${tag.key}`;
        };

        this.changes.forEach(change => {
            if (!change.oldValue) {
                const toDeleteTag = change.toNewTag();
                const tagId = getTagUniqueId(toDeleteTag);
                if (!tagsToDelete.has(tagId)) {
                    tagsToDelete.set(tagId, new MultiResourceTagValue(toDeleteTag));
                }
                tagsToDelete.get(tagId).add(change.resourceId);
            } else {
                const toUpsertTag = change.toOldTag();
                if (toUpsertTag) {
                    const tagId = getTagUniqueId(toUpsertTag);
                    if (!tagsToUpsert.has(tagId)) {
                        tagsToUpsert.set(tagId, new MultiResourceTagValue(toUpsertTag));
                    }
                    tagsToUpsert.get(tagId).add(change.resourceId);
                }
            }
        });
        // Block until all backend tag changes complete
        const toUpsert = Array.from(tagsToUpsert.values());
        const toDelete = Array.from(tagsToDelete.values());
        this.arrayTagUpdateService.update(toUpsert, toDelete).then(() => {
            this.rollbacked.emit({ upsert: toUpsert, delete: toDelete });
        });
    }

    private getSummary(): string {
        return `${this.updatedTagKeys.size > 1 ? 'Multiple tags have' : 'A tag has'} been updated on ${this.resourceIds.size} array${this.resourceIds.size > 1 ? 's' : ''}.`;
    }

    private getTagUniqueKey(tag: TagChange): string {
        return tag.namespace + ':' + (tag.oldKey || tag.newKey).toLowerCase();
    }
}
