<div
    class="clickable-badge"
    [ngbPopover]="popContent"
    popoverClass="pure1-informational-popover"
    container="body"
    #popoverRef="ngbPopover"
    [autoClose]="'outside'"
    triggers="manual"
    (click)="onClickOpenPopover($event)"
    placement="right"
>
    <pureui-svg svg="checked-badge.svg" height="20" class="recommendation-icon"></pureui-svg>
    <pureui-svg svg="info-circle.svg" height="10" class="info-icon"></pureui-svg>
</div>

<!-- This entire <ng-template> is under the class '.placement-recommendations-warnings-popover' because it gets applied by the `popoverClass` input above. -->
<ng-template #popContent>
    <div class="placement-warnings-popover-content">
        <div class="warnings-popover-header">
            <button type="button" class="close modal-x-button" (click)="closePopover()">
                <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
            </button>
        </div>
        <div class="warnings-popover-body">
            <div class="checked-badge-icon">
                <pureui-svg svg="checked-badge.svg" height="35"></pureui-svg>
            </div>
            <div class="warnings-text">
                <h4>Optimal Placement</h4>
                <p
                    >Recommended because this array will have spare capacity and load bandwidth after the workload
                    migration.</p
                >
                <div *ngIf="warningPairs && warningPairs.length > 0" class="warnings-list">
                    <pureui-svg svg="alert_info.svg" class="pstg-alert-info-icon" height="16"></pureui-svg>
                    <ul>
                        <li *ngFor="let caveat of displayedMessages"
                            >{{ caveat.message }} <a [href]="caveat.link" target="_blank">Learn More</a></li
                        >
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
