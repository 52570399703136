import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';

import { AttachmentComposite, CaseAttachment, ItemType } from '../support.interface';
import { SupportErrorService } from './error.service';
import { catchError, first, map } from 'rxjs/operators';

@Injectable()
export class CaseAttachmentService {
    private static cacheBuster$ = new Subject<void>();
    private static cacheTTL = 29000; // 29 seconds
    private endpoint = '/rest/v1/support/cases';

    constructor(
        private http: HttpClient,
        private errorService: SupportErrorService,
    ) {}

    @Cacheable({
        maxAge: CaseAttachmentService.cacheTTL,
        cacheBusterObserver: CaseAttachmentService.cacheBuster$,
    })
    getCaseAttachments(caseId: string): Observable<CaseAttachment[]> {
        return this.http.get<AttachmentComposite[]>(`${this.endpoint}/${caseId}/attachments`).pipe(
            first(),
            map((attachments: AttachmentComposite[]) => attachments.map(a => this.compositeHelper(a))),
        );
    }

    @Cacheable({
        maxAge: CaseAttachmentService.cacheTTL,
        cacheBusterObserver: CaseAttachmentService.cacheBuster$,
    })
    getCaseAttachmentById(caseId: string, attachmentId: string): Observable<CaseAttachment> {
        return this.http.get<CaseAttachment>(`${this.endpoint}/${caseId}/attachments/${attachmentId}`);
    }

    @CacheBuster({
        cacheBusterNotifier: CaseAttachmentService.cacheBuster$,
    })
    addCaseAttachment(caseId: string, attachment: Partial<CaseAttachment>): Observable<CaseAttachment> {
        return this.http
            .post<CaseAttachment>(`${this.endpoint}/${caseId}/attachments`, attachment)
            .pipe(catchError(this.errorService.errorInterceptor));
    }

    private compositeHelper = (compositeData: AttachmentComposite): CaseAttachment => {
        if (!compositeData) {
            return;
        }

        return {
            ...compositeData.attachment,
            createdByUser: compositeData.createdByUser,
            itemType: ItemType.Attachment,
        };
    };
}
