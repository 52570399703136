import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { LicenseType, Subscription } from '@pure1/data';
import { WINDOW } from '../../../app/injection-tokens';
import { FormatTiBPipe } from '../../../ui/pipes/format-tib.pipe';
import { Options } from '@angular-slider/ngx-slider';

const VMA_LICENSE_MIN_RESERVE = 100;
const VMA_LICENSE_MAX_RESERVE = 10000;

const CBS_LICENSE_MIN_RESERVE_IN_TIB = 1;
const CBS_LICENSE_MAX_RESERVE_IN_TIB = 10240;

const DEFAULT_MIN_RESERVE_IN_TIB = 50;
const PAAS_BLOCK_CAPACITY_MIN_RESERVE_IN_TIB = 200;
export const DEFAULT_MAX_RESERVE_IN_TIB = 10240;

@Component({
    selector: 'license-reserved-amount',
    templateUrl: './license-reserved-amount.component.html',
})
export class LicenseReservedAmountComponent implements OnChanges {
    @Input() readonly currentAmount: number;
    @Input() readonly licenseType: LicenseType;
    @Input() readonly increaseAmount: number;
    @Input() readonly subscription: Subscription;
    @Input() readonly isNewLicense: boolean = false;
    @Output() readonly increaseAmountChange = new EventEmitter<number>();

    increaseAmountInUnit: number;
    sliderOptions: Options;
    private minReserve: number;
    private maxReserve: number;

    constructor(@Inject(WINDOW) private window: Window) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.licenseType || changes.currentAmount) {
            this.updateMinReserve();
            this.updateMaxReserve();
            this.sliderOptions = {
                floor: this.minReserve,
                ceil: this.maxReserve,
                showSelectionBar: true,
                hidePointerLabels: true,
                hideLimitLabels: true,
            };
        }

        if (changes.increaseAmount) {
            if (this.licenseType === LicenseType.VMA) {
                this.increaseAmountInUnit = this.increaseAmount ? this.increaseAmount : null;
            } else {
                this.increaseAmountInUnit = this.increaseAmount ? FormatTiBPipe.toTiB(this.increaseAmount) : null;
            }
            if (!this.increaseAmountInUnit) {
                this.window.setTimeout(() => this.onAmountChange(this.minReserve), 0);
            }
        }
    }

    maxLength(): number {
        return this.maxReserve.toString().length;
    }

    onInputAmountChange(amountInUnitText: string): void {
        const amountInUnitNumber = Number(amountInUnitText);
        if (amountInUnitText && !Number.isNaN(amountInUnitNumber)) {
            const amount = Math.min(Math.max(amountInUnitNumber, this.minReserve), this.maxReserve);
            this.onAmountChange(amount);
        } else {
            this.onAmountChange(this.minReserve);
        }
    }

    resetDefault(): void {
        if (this.increaseAmountInUnit < this.minReserve) {
            this.increaseAmountInUnit = this.minReserve;
        }
    }

    onAmountChange(increaseAmountInUnit: number): void {
        if (increaseAmountInUnit !== this.increaseAmountInUnit) {
            this.increaseAmountInUnit = increaseAmountInUnit;
            let amount: number;
            if (this.licenseType === LicenseType.VMA) {
                amount = this.increaseAmountInUnit;
            } else {
                amount = this.increaseAmountInUnit ? FormatTiBPipe.fromTiB(this.increaseAmountInUnit) : 0;
            }
            this.increaseAmountChange.emit(amount);
        }
    }

    private updateMinReserve(): void {
        // do not enforce any min reserve if there is a pre-existing subscription
        if (this.subscription) {
            this.minReserve = 0;
            return;
        }
        if (this.licenseType === LicenseType.VMA) {
            this.minReserve = this.currentAmount
                ? Math.max(VMA_LICENSE_MIN_RESERVE - this.currentAmount, 0)
                : VMA_LICENSE_MIN_RESERVE;
        } else if (this.licenseType === LicenseType.BLOCK_CAPACITY) {
            this.minReserve = this.currentAmount
                ? Math.max(PAAS_BLOCK_CAPACITY_MIN_RESERVE_IN_TIB - FormatTiBPipe.toTiB(this.currentAmount), 0)
                : PAAS_BLOCK_CAPACITY_MIN_RESERVE_IN_TIB;
        } else if (this.licenseType === LicenseType.CBS) {
            this.minReserve = this.currentAmount
                ? Math.max(CBS_LICENSE_MIN_RESERVE_IN_TIB - FormatTiBPipe.toTiB(this.currentAmount), 0)
                : CBS_LICENSE_MIN_RESERVE_IN_TIB;
        } else {
            this.minReserve = this.currentAmount
                ? Math.max(DEFAULT_MIN_RESERVE_IN_TIB - FormatTiBPipe.toTiB(this.currentAmount), 0)
                : DEFAULT_MIN_RESERVE_IN_TIB;
        }
    }

    private updateMaxReserve(): void {
        if (this.licenseType === LicenseType.VMA) {
            this.maxReserve = this.currentAmount
                ? Math.max(VMA_LICENSE_MAX_RESERVE - this.currentAmount, 0)
                : VMA_LICENSE_MAX_RESERVE;
        } else {
            this.maxReserve = this.currentAmount
                ? Math.max(DEFAULT_MAX_RESERVE_IN_TIB - FormatTiBPipe.toTiB(this.currentAmount), 0)
                : DEFAULT_MAX_RESERVE_IN_TIB;
        }
    }
}
