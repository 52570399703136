import moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { tap, retryWhen, delay, concatMap, switchMap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { WINDOW } from './injection-tokens';

/** Max times to try a request. After this many attempts total, no matter what type of request it is, it'll stop retrying. */
const MAX_ATTEMPTS = 3;

/** How long to wait after receiving an error response before retrying for the first time. Subsequent retries will have a longer delay, scaling off this value. */
const BASE_RETRY_DELAY_MS = 500;

/** Only retry if the http error contains one of these status codes */
const RETRY_HTTP_STATUS_CODES: number[] = [
    0, // Special case - requires additional checking
    500, // Internal server error
    502, // Bad gateway
    503, // Service unavailable
    504, // Gateway timeout
];

/** Only retry if the http request is for one of these methods */
const RETRY_HTTP_METHODS: string[] = ['GET'];

/** Don't retry if we expect doing so will cause the total time since the initial request to exceed this amount of time */
const MAX_TOTAL_TIME_MS = moment.duration(30, 'seconds').asMilliseconds();

interface IRejectReason {
    reason: string;
    shouldLog: boolean;
}

/**
 * Provides general retry logic to all HTTP requests made through Angular.
 * This is completely hidden from the calling code, so will work fine with any additional (eg endpoint-specific) retry
 * logic (though may make more attempts than you'd otherwise expect, of course).
 *
 * Retries are only attempted for API calls that should be very safe to actually retry (assuming it follows REST guidelines).
 * Eg, only specific HTTP request methods & return codes. See implementation for details.
 */
@Injectable()
export class HttpRetryInterceptor implements HttpInterceptor {
    constructor(@Inject(WINDOW) private window: Window) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let attempt = 1;
        const startTime = moment();

        return next.handle(request).pipe(
            retryWhen(errors$ => {
                return errors$.pipe(
                    tap(() => {
                        attempt++;
                    }),
                    concatMap(error => {
                        const url = request.urlWithParams;
                        let reject: IRejectReason;
                        try {
                            reject = this.shouldRejectRetry(request, error, attempt, startTime);
                        } catch (ex) {
                            reject = { reason: `Unexpected error: ${ex}`, shouldLog: true };
                        }

                        if (reject) {
                            if (reject.shouldLog) {
                                console.error('[HttpRetry] Retry rejected', {
                                    url,
                                    attempt,
                                    error,
                                    reason: reject.reason,
                                });
                            }
                            return throwError(error);
                        } else {
                            console.log('[HttpRetry] Retrying', { url, attempt, error });
                            return of(void 0).pipe(
                                delay(this.getRetryDelay(attempt)),
                                switchMap(() => of(error)),
                            );
                        }
                    }),
                );
            }),
        );
    }

    /**
     * Gets if a request can be retried. Returns null if it can. Otherwise, returns a string containing the rejection reason.
     * @param attempt What request attempt number this will be (so will always start at 2, because the first attempt would have failed already by the time we check if need to retry)
     */
    private shouldRejectRetry(
        request: HttpRequest<any>,
        error: HttpErrorResponse,
        attempt: number,
        startTime: moment.Moment,
    ): IRejectReason | null {
        if (attempt > MAX_ATTEMPTS) {
            return { reason: 'Too many attempts', shouldLog: true };
        }

        if (!(error instanceof HttpErrorResponse)) {
            return { reason: 'Unexpected error type', shouldLog: true };
        }

        // HTTP Method
        if (!RETRY_HTTP_METHODS.includes(request.method.toUpperCase())) {
            return { reason: 'HTTP method not valid for retry', shouldLog: false };
        }

        // HTTP status code
        if (!RETRY_HTTP_STATUS_CODES.includes(error.status)) {
            return { reason: 'HTTP status code not valid for retry', shouldLog: false };
        }

        if (error.status === 0) {
            let canRetry = false;
            canRetry = canRetry || this.window.navigator.onLine === false; // No internet connection
            if (!canRetry) {
                return { reason: 'No valid retry reason for HTTP status code 0', shouldLog: false };
            }
        }

        // Elapsed time
        const elapsedTime = moment().valueOf() - startTime.valueOf();
        const avgReqTime = elapsedTime / (attempt - 1);
        const retryDelay = this.getRetryDelay(attempt);
        if (elapsedTime + avgReqTime + retryDelay > MAX_TOTAL_TIME_MS) {
            return { reason: 'Estimated completion time exceeds max allowed time', shouldLog: true };
        }

        return null; // Allowed to retry
    }

    /**
     * Gets how long to wait before retrying a HTTP request (in milliseconds)
     * @param attempt What request attempt number this will be (so will always start at 2)
     */
    private getRetryDelay(attempt: number): number {
        return BASE_RETRY_DELAY_MS * (attempt - 1);
    }
}
