import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { LicenseType, Metric, IRestResponse } from '@pure1/data';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

export type GenericType = LicenseType | Metric;

type TypeMapping = { [key in GenericType]: Metric };

const BASE_URL = '/rest/v3/subscription-licenses';
const LICENSE_URL = BASE_URL + '/license_metric_type';
const RESOURCE_URL = BASE_URL + '/resource_metric_type';

@Injectable({ providedIn: 'root' })
export class MetricTypeMappingService {
    constructor(private http: HttpClient) {}

    getTypeMapping(): Observable<Map<GenericType, Metric>> {
        return forkJoin(this.http.get(LICENSE_URL), this.http.get(RESOURCE_URL)).pipe(
            map(responses => {
                const mapping = new Map<GenericType, Metric>();
                responses.forEach((response: IRestResponse<TypeMapping>) => {
                    // response.items is an array of objects each containing a single type mapping
                    // Example:
                    // [
                    //    { 'FA Service' : 'license_effective_used_space' },
                    //    { 'VMA' : 'license_vm_instance_count' },
                    // ]
                    response.items.forEach(item => {
                        const key = Object.keys(item)[0] as GenericType;
                        mapping.set(key, item[key]);
                    });
                });
                return mapping;
            }),
            catchError((err: HttpErrorResponse) => {
                console.warn(`Metrics mapping endpoint returned ${err.status}`);
                return of(new Map<GenericType, Metric>());
            }),
        );
    }
}
