import { formatLatency } from '../utils/formatLatency';
import { formatIOPs } from '../utils/formatIOPs';
import { formatNetworkSpeed } from '../utils/formatNetworkSpeed';

export class PerformanceData implements IPerformanceData {
    readLatency: number;
    readLatencyStr: IValueUnit;
    writeLatency: number;
    writeLatencyStr: IValueUnit;
    mirroredWriteLatency: number;
    mirroredWriteLatencyStr: IValueUnit;
    otherLatency: number;
    otherLatencyStr: IValueUnit;
    iops: number;
    iopsStr: IValueUnit;
    readIops: number;
    readIopsStr: IValueUnit;
    writeIops: number;
    writeIopsStr: IValueUnit;
    mirroredWriteIops: number;
    mirroredWriteIopsStr: IValueUnit;
    otherIops: number;
    otherIopsStr: IValueUnit;
    bandwidth: number;
    bandwidthStr: IValueUnit;
    readBandwidth: number;
    readBandwidthStr: IValueUnit;
    writeBandwidth: number;
    writeBandwidthStr: IValueUnit;
    mirroredWriteBandwidth: number;
    mirroredWriteBandwidthStr: IValueUnit;

    constructor(recentPerf: any) {
        if (recentPerf) {
            this.readLatency = recentPerf.usec_per_read_op;
            this.writeLatency = recentPerf.usec_per_write_op;
            this.mirroredWriteLatency = recentPerf.usec_per_mirrored_write_op;
            this.otherLatency = recentPerf.usec_per_other_op;
            this.iops =
                recentPerf.reads_per_sec +
                recentPerf.writes_per_sec +
                (recentPerf.others_per_sec || null) +
                (recentPerf.mirrored_writes_per_sec || null);
            this.readIops = recentPerf.reads_per_sec;
            this.writeIops = recentPerf.writes_per_sec;
            this.mirroredWriteIops = recentPerf.mirrored_writes_per_sec;
            this.otherIops = recentPerf.others_per_sec;
            this.bandwidth =
                recentPerf.input_per_sec + recentPerf.output_per_sec + (recentPerf.mirrored_input_per_sec || null);
            this.readBandwidth = recentPerf.output_per_sec;
            this.writeBandwidth = recentPerf.input_per_sec;
            this.mirroredWriteBandwidth = recentPerf.mirrored_input_per_sec;
        } else {
            this.readLatency = null;
            this.writeLatency = null;
            this.mirroredWriteLatency = null;
            this.otherLatency = null;
            this.iops = null;
            this.readIops = null;
            this.writeIops = null;
            this.mirroredWriteIops = null;
            this.otherIops = null;
            this.bandwidth = null;
            this.readBandwidth = null;
            this.writeBandwidth = null;
            this.mirroredWriteBandwidth = null;
        }

        this.readLatencyStr = formatLatency(this.readLatency, 2);
        this.writeLatencyStr = formatLatency(this.writeLatency, 2);
        this.mirroredWriteLatencyStr = formatLatency(this.mirroredWriteLatency, 2);
        this.otherLatencyStr = formatLatency(this.otherLatency, 2);
        this.iopsStr = formatIOPs(this.iops, 1);
        this.readIopsStr = formatIOPs(this.readIops, 2);
        this.writeIopsStr = formatIOPs(this.writeIops, 2);
        this.mirroredWriteIopsStr = formatIOPs(this.mirroredWriteIops, 2);
        this.otherIopsStr = formatIOPs(this.otherIops, 2);
        this.bandwidthStr = formatNetworkSpeed(this.bandwidth, 1);
        this.readBandwidthStr = formatNetworkSpeed(this.readBandwidth, 2);
        this.writeBandwidthStr = formatNetworkSpeed(this.writeBandwidth, 2);
        this.mirroredWriteBandwidthStr = formatNetworkSpeed(this.mirroredWriteBandwidth, 2);
    }
}
