<feature-banner
    [notification]="populatedNotification"
    (dismissNotification)="dismissNotification.emit(populatedNotification)"
>
</feature-banner>

<!-- The try-button class is styled in feature-banner -->
<ng-template #cduButtonTemplate>
    <a
        *ngIf="populatedNotification.path"
        class="btn btn-primary try-button"
        [routerLink]="populatedNotification.path"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Get started"
        [angularticsLabel]="notification.title"
    >
        Get Started
    </a>
</ng-template>

<ng-template #cduLinksTemplate>
    <ng-container *ngIf="notification.kbArticleLink">
        <a
            [href]="notification.kbArticleLink"
            target="_blank"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Banner - Read more"
            [angularticsLabel]="notification.title"
        >
            Read More
        </a>
        <ng-container> | </ng-container>
    </ng-container>
    <a
        href="https://support.purestorage.com/FlashArray/FlashArray_Release/01_Purity_FA_Release_Notes/9926_Purity%2F%2FFA_6.3.x_Release_Notes"
        target="_blank"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Banner - Release notes"
        [angularticsLabel]="notification.title"
    >
        Purity//FA Release Notes
    </a>
</ng-template>
