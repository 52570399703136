import { DraasApiPhonehomeTimeCard } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryPhonehomeTimeCard {
    clusterId: string;
    datacenters: Date | undefined;
    datastores: Date | undefined;
    hosts: Date | undefined;
    repositories: Date | undefined;
    resourcePools: Date | undefined;
    switchHostAssignments: Date | undefined;
    virtualMachines: Date | undefined;
    vSwitches: Date | undefined;
    workerVirtualMachines: Date | undefined;

    constructor(json: DraasApiPhonehomeTimeCard) {
        this.clusterId = json.cluster_id;
        this.datacenters = json.datacenters ? new Date(json.datacenters) : undefined;
        this.datastores = json.datastores ? new Date(json.datastores) : undefined;
        this.hosts = json.hosts ? new Date(json.hosts) : undefined;
        this.repositories = json.repositories ? new Date(json.repositories) : undefined;
        this.resourcePools = json.resource_pools ? new Date(json.resource_pools) : undefined;
        this.switchHostAssignments = json.switch_host_assignments ? new Date(json.switch_host_assignments) : undefined;
        this.virtualMachines = json.virtual_machines ? new Date(json.virtual_machines) : undefined;
        this.vSwitches = json.v_switches ? new Date(json.v_switches) : undefined;
        this.workerVirtualMachines = json.worker_virtual_machines ? new Date(json.worker_virtual_machines) : undefined;
    }
}
