import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { VolumePerformance } from '../models/volume-performance';

@Injectable({ providedIn: 'root' })
export class VolumePerformanceService extends GenericService<VolumePerformance> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: VolumePerformance,
            endpoint: '/rest/v3/volumes',
        });
    }
}
