import { Tag } from '@pure1/data';

/**
 * MultiResourceTag is represents a row in <manage-tags-modal>, i.e.: the union of all the tags with the same
 * namespace and keys for all the reources that will be edited or deleted.
 *
 * It is strongly suggested that you do not create your own MultiResourceTag, but use the MultiResourceTagBuilder.
 */
export interface MultiResourceTag {
    /**
     * The namespace common to all the tags
     */
    namespace: string;

    /**
     * The key common to all the tags
     */
    key: string;

    /**
     * The value common to all the tags if they share one value, falsy otherwise
     */
    value: string;

    /**
     * The number of resources that are being edited
     */
    resourceCount: number;

    /**
     * The key before editing starts. or the first key in alphabatical order for all keys different in cases (upper or lower).
     */
    originKey: string;

    /**
     * A map value => resourceId[] of all the values before editing starts
     */
    originValues: { [value: string]: string[] };

    /**
     * Set to true if it contains multiple values before editing, or the tags are not set on all resources
     */
    originMultiple: boolean;

    tag_organization_id: number;
}

export class MultiResourceTagBuilder {
    mrt: MultiResourceTag = {
        namespace: '',
        key: '',
        value: '',
        resourceCount: 0,
        originKey: '',
        originValues: {},
        tag_organization_id: -1,
        originMultiple: false,
    };

    private count = 0;

    constructor(tag: Tag, resourceCount: number) {
        this.mrt.namespace = tag.namespace;
        this.mrt.key = tag.key;
        this.mrt.value = tag.value;
        this.mrt.resourceCount = resourceCount;
        this.mrt.originKey = tag.key;
        this.mrt.tag_organization_id = tag.tag_organization_id;
    }

    add(key: string, value: string, resourceId: string): this {
        if (this.mrt.originKey.toLowerCase() !== key.toLowerCase()) {
            console.warn(`${key} doesn't belong here`);
            return this;
        }
        this.count++;
        if (this.mrt.originKey.localeCompare(key) > 0) {
            this.mrt.originKey = key;
            this.mrt.key = key;
        }

        if (this.mrt.value !== value) {
            this.mrt.value = '';
        }
        this.mrt.originValues[value] = this.mrt.originValues[value] || [];
        this.mrt.originValues[value].push(resourceId);
        return this;
    }

    build(): MultiResourceTag {
        if (this.count !== this.mrt.resourceCount) {
            this.mrt.value = '';
        }
        const values = Object.keys(this.mrt.originValues);
        if (values.length > 1 || this.mrt.originValues[values[0]].length !== this.mrt.resourceCount) {
            this.mrt.originMultiple = true;
        }
        return this.mrt;
    }
}
