import { ViewReference } from './view-reference';

// The naming convention is a little strange
// Every Assignment is an assignable, so the common code for handling the assignable is in this base class
// The Assignment models (User, ExternalUser, and Group) are exported from the data layer.
// This Assignment class is not intended to be exported from the data layer. (We have stronger typing farther up)
export class Assignment {
    view: ViewReference;
    selector: string;

    constructor(json: any) {
        if (json.view && json.selector) {
            throw new Error('view and selector cannot both be specified');
        }
        this.selector = json.selector;
        this.view = json.view ? new ViewReference(json.view) : json.view;
    }
}
