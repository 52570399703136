<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<section class="data">
    <invoice-management-summary-totals [invoices]="unfilteredData"></invoice-management-summary-totals>
    <div class="gfb-bar">
        <gfb
            class="btn-group flex-btn-group flex-btn-group-first"
            [barId]="barId"
            [entities]="GFB_ENTITIES"
            [defaultKey]="DEFAULT_KEY"
            [defaultEntity]="DEFAULT_ENTITY"
        >
        </gfb>
    </div>
</section>
<invoice-management-table
    [data]="data"
    [itemsPerPage]="pageSize"
    [offset]="offset"
    [totalItemCount]="total"
    [totalUnfiltered]="totalUnfiltered"
    [selectedRangeFilters]="selectedRangeFilters"
    [filter]="listCallParameters.filter"
    [sort]="defaultSort"
    (pageChange)="onPageChange($event)"
    (itemsPerPageChange)="onPageSizeChange($event)"
    (timeFilterChange)="onTimeFilterChanges($event)"
    (sortChange)="onSortChange($event)"
></invoice-management-table>
