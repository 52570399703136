import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { UnifiedArrayBusymeterAggregation } from './unified-array-busymeter-aggregation';
import { UnifiedArrayPerformanceAggregation } from './unified-array-performance-aggregation';

export class MetricGroupTag {
    namespace: string;
    key: string;
    value: string;
    display_key: string;
    display_value: string;

    constructor(json: any) {
        this.namespace = json.namespace;
        this.key = json.key;
        this.value = json.value;
        this.display_key = json.display_key;
        this.display_value = json.display_value;
    }
}

// TODO: Verify what we're actually getting back. Assuming for now it is just everything in purearray_list_space.  https://jira.purestorage.com/browse/CLOUD-57190
export type MetricGroupCapacityMetricType =
    | 'total'
    | 'capacity'
    | 'system'
    | 'snapshots'
    | 'data_reduction'
    | 'shared_space'
    | 'file_systems_space'
    | 'buckets_space'
    | 'available'
    | 'empty'
    | 'percent_full'
    | 'unique';

export const MetricGroupCapacityMetricTypeList: MetricGroupCapacityMetricType[] = [
    'total',
    'capacity',
    'system',
    'snapshots',
    'data_reduction',
    'shared_space',
    'file_systems_space',
    'buckets_space',
    'available',
    'empty',
    'percent_full',
    'unique',
];

export class CapacityGrowth {
    value_30days: number;
    percent_30days: number;
    /** Data for the sparklines to show the summarized growth */
    growthSummarySparkline: ITimeseriesValues;
    constructor(json: any) {
        if (!json) {
            return;
        }
        this.value_30days = json.value_30days;
        this.percent_30days = json.percent_30days;

        // Backend for metrics groups only populates 30 days of data due to performance cost and POJOs available.
        // Just in case this changes in the future, only get last 30 data points (30 days)
        this.growthSummarySparkline = json.dataset_90days.slice(-30);
    }
}

// Intentionally not extending CapacityData, since I don't want all those preformatted strings as part of this class
export class MetricGroupCapacityData {
    aggregationTime: moment.Moment;

    total: number;
    capacity: number; // this is total capacity ie: total_system_size
    faCapacity: number; // this metric is the same as capacity but only counts system size for FA
    system: number;
    snapshots: number;
    dataReduction: number;
    sharedSpace: number;
    fileSystemsSpace: number;
    bucketsSpace: number;
    available: number;
    empty: number;
    percentFull: number;
    unique: number;
    replication: number;
    provisioning: number;
    thinProvisioning: number;
    volumes: number;
    totalBlades: number;
    usedBlades: number;
    notInstalledBlades: number;
    totalProvisioned: number;
    logicalBytesStored: number;
    totalUsed: number;

    /** Percentage breakdown of the capacity by metric type */
    dialSegments: IDialSegment[];

    constructor(json: any) {
        if (!json) {
            return;
        }

        this.aggregationTime = moment(json.aggregation_time || 0);

        this.capacity = json.data.total_system_size['1h_avg'];
        this.faCapacity = json.data.fa_total_system_size['1h_avg'];
        this.unique = json.data.unique_space['1h_avg'];
        this.volumes = json.data.volume_space['1h_avg'];
        this.bucketsSpace = json.data.s3_bucket_space['1h_avg'];
        this.fileSystemsSpace = json.data.file_system_space['1h_avg'];
        this.snapshots = json.data.snapshot_space['1h_avg'];
        this.replication = json.data.replication_space['1h_avg'];
        this.sharedSpace = json.data.physical_shared['1h_avg'];
        this.system = json.data.system_space['1h_avg'];
        this.totalBlades = Math.round(json.data.total_blades_count['1h_avg']);
        this.usedBlades = Math.round(json.data.used_blades_count['1h_avg']);
        this.totalProvisioned = json.data.total_provisioned['1h_avg'];
        this.logicalBytesStored = json.data.logical_bytes_stored['1h_avg'];
        this.totalUsed = json.data.total_used['1h_avg'];

        this.total =
            (this.unique || 0) +
            (this.snapshots || 0) +
            (this.replication || 0) +
            (this.sharedSpace || 0) +
            (this.system || 0);
        this.empty = this.capacity > 0 ? this.capacity - this.total : 0;
        this.percentFull = this.capacity > 0 ? (100 * this.total) / this.capacity : 0;
        this.available = this.capacity > 0 ? this.capacity - (this.system || 0) : 0;
        this.provisioning =
            this.faCapacity && this.faCapacity !== 0 ? (this.totalProvisioned / this.faCapacity) * 100 : null;
        this.dataReduction = this.totalUsed && this.totalUsed !== 0 ? this.logicalBytesStored / this.totalUsed : null;

        this.dialSegments =
            this.capacity > 0
                ? [
                      { value: (100 * this.unique) / this.capacity, cssClass: 'unique', tooltipName: 'Unique' },
                      { value: (100 * this.snapshots) / this.capacity, cssClass: 'snapshots' },
                      { value: (100 * this.sharedSpace) / this.capacity, cssClass: 'shared' },
                      { value: (100 * this.system) / this.capacity, cssClass: 'system' },
                      { value: (100 * this.replication) / this.capacity, cssClass: 'replication' },
                      { value: (100 * this.fileSystemsSpace) / this.capacity, cssClass: 'file-systems' },
                      { value: (100 * this.bucketsSpace) / this.capacity, cssClass: 'object-stores' },
                  ]
                : [];

        this.dialSegments = this.dialSegments.filter(seg => seg.value === 0); // Remove empty segments
    }
}

/**
 * Format Spog wants for creating metric group
 */
export interface CreateMetricGroupPartial {
    name: string;
    tags: MetricGroupTag[];
    view_id: string | null;
}

/**
 * Format Spog wants for editing a metric group
 */
export interface EditMetricGroupPartial extends CreateMetricGroupPartial {
    org_id: number;
    id: string;
}

export class MetricGroup implements Resource {
    readonly name: string;
    readonly id: string;

    tags: MetricGroupTag[];
    view_id: string | null;
    org_id: number;
    aggregation: UnifiedArrayPerformanceAggregation;
    busymeter_aggregation: UnifiedArrayBusymeterAggregation;
    capacity_aggregation: MetricGroupCapacityData;
    capacity_growth: CapacityGrowth;
    item_count = 99;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.tags = (json.tags || []).map(tag => new MetricGroupTag(tag));
        this.view_id = json.view_id;
        this.org_id = json.org_id;
        // TODO $$ re-add (and remove 99) when backend is ready https://jira.purestorage.com/browse/CLOUD-52945
        // this.item_count = json.view?.item_count;
        this.aggregation = new UnifiedArrayPerformanceAggregation(json.performance_aggregation);
        this.busymeter_aggregation = new UnifiedArrayBusymeterAggregation(json.busymeter_aggregation);
        this.capacity_aggregation = new MetricGroupCapacityData(json.capacity_aggregation);
        this.capacity_growth = new CapacityGrowth(json.capacity_growth);
    }
}
