import { DraasApiAWSElasticIp } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryAWSElasticIp {
    readonly elasticIp?: string;
    readonly status: 'PENDING' | 'SYNCED' | 'ERROR';
    readonly error?: string;
    readonly requestId?: string;

    constructor(json: DraasApiAWSElasticIp) {
        this.elasticIp = json.elastic_ip;
        this.status = json.status;
        this.error = json.error;
        this.requestId = json.request_id;
    }
}
