import {
    AnyAction,
    applyMiddleware,
    compose,
    createStore,
    Dispatch,
    Middleware,
    Reducer,
    Store,
    StoreCreator,
    StoreEnhancer,
    Unsubscribe,
} from 'redux';
import { NgZone, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

const assert = (condition: boolean, message: string): void => {
    if (!condition) {
        throw new Error(message);
    }
};

export type Comparator = (x: any, y: any) => boolean;
export type PropertySelector = string | number | symbol;
export type PathSelector = (string | number)[];
export type FunctionSelector<RootState, S> = (s: RootState) => S;
export type Selector<RootState, S> = PropertySelector | PathSelector | FunctionSelector<RootState, S>;

export const sniffSelectorType = <RootState, S>(selector?: Selector<RootState, S>): string =>
    !selector ? 'nil' : Array.isArray(selector) ? 'path' : 'function' === typeof selector ? 'function' : 'property';

export const getIn = (v: any | undefined, pathElems: (string | number)[]): any | undefined => {
    if (!v) {
        return v;
    }

    // If this is an ImmutableJS structure, use existing getIn function
    if ('function' === typeof v.getIn) {
        return v.getIn(pathElems);
    }

    const [firstElem, ...restElems] = pathElems;

    if (undefined === v[firstElem]) {
        return undefined;
    }

    if (restElems.length === 0) {
        return v[firstElem];
    }

    return getIn(v[firstElem], restElems);
};

const resolver = <RootState, S>(selector?: Selector<RootState, S>) => ({
    property: (state: any) => (state ? state[selector as PropertySelector] : undefined),
    path: (state: RootState) => getIn(state, selector as PathSelector),
    function: selector as FunctionSelector<RootState, S>,
    nil: (state: RootState) => state,
});

const resolveToFunctionSelector = <RootState, S>(selector?: Selector<RootState, S>) =>
    resolver(selector)[sniffSelectorType(selector)];

@Injectable({
    providedIn: 'root',
})
export class NgRedux<RootState> implements Store<RootState> {
    private store: Store<RootState> | undefined = undefined;
    private store$: BehaviorSubject<RootState>;

    constructor(private ngZone: NgZone) {
        this.store$ = new BehaviorSubject<RootState | undefined>(undefined).pipe(
            filter(n => n !== undefined),
            switchMap(observableStore => observableStore as any),
            // TODO: fix this? needing to explicitly cast this is wrong
        ) as BehaviorSubject<RootState>;
    }

    configureStore = (
        rootReducer: Reducer<RootState, AnyAction>,
        initState: RootState,
        middleware: Middleware[] = [],
        enhancers: StoreEnhancer<RootState>[] = [],
    ): void => {
        assert(!this.store, 'Store already configured!');
        // Variable-arity compose in typescript FTW.
        this.setStore(
            compose<StoreCreator>(applyMiddleware(...middleware), ...enhancers)(createStore)(
                rootReducer as Reducer<any, AnyAction>,
                initState,
            ),
        );
    };

    provideStore = (store: Store<RootState>): void => {
        assert(!this.store, 'Store already configured!');
        this.setStore(store);
    };

    getState = (): RootState => this.store!.getState();

    subscribe = (listener: () => void): Unsubscribe => this.store!.subscribe(listener);

    replaceReducer = (nextReducer: Reducer<RootState, AnyAction>): void => {
        this.store!.replaceReducer(nextReducer);
    };

    dispatch: Dispatch<AnyAction> = <A extends AnyAction>(action: A): A => {
        assert(
            !!this.store,
            'Dispatch failed: did you forget to configure your store? ' +
                'https://github.com/angular-redux/platform/blob/master/packages/store/' +
                'README.md#quick-start',
        );

        if (!NgZone.isInAngularZone()) {
            return this.ngZone.run(() => this.store!.dispatch(action));
        } else {
            return this.store!.dispatch(action);
        }
    };

    select = <SelectedType>(
        selector?: Selector<RootState, SelectedType>,
        comparator?: Comparator,
    ): Observable<SelectedType> =>
        this.store$.pipe(
            distinctUntilChanged(),
            map(resolveToFunctionSelector(selector)),
            distinctUntilChanged(comparator),
        );

    private setStore(store: Store<RootState>) {
        this.store = store;
        const storeServable = this.storeToObservable(store);
        this.store$.next(storeServable as any);
    }

    private storeToObservable = (store: Store<RootState>): Observable<RootState> =>
        new Observable<RootState>((observer: Observer<RootState>) => {
            observer.next(store.getState());
            const unsubscribeFromRedux = store.subscribe(() => observer.next(store.getState()));
            return () => {
                unsubscribeFromRedux();
                observer.complete();
            };
        });

    [Symbol.observable] = (): any => null;
}
