import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { datadogLogs, LogsEvent } from '@datadog/browser-logs';

import { WINDOW } from './injection-tokens';
import { AngularticsConfigService } from './angulartics-config.service';
import moment from 'moment';
import { isPure1Production } from '../utils/url';
import { Subject, takeUntil } from 'rxjs';
import { smartTimer } from '@pstg/smart-timer';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class DatadogLoggingConfigService implements OnDestroy {
    private readonly resetIntervalSeconds = 10;
    private readonly throttleAfterCount = 3;
    private readonly logCounter = new Map<String, { count: number; log: LogsEvent }>();
    private readonly ignoredLogs: (string | RegExp)[] = [
        // Use string for exact message match
        // Amplitude: Ignore errors from Amplitude Logger, which is usually caused by network calls being blocked by an adblocker
        /console error: Amplitude Logger \[Error\]/, // Example: console error: Amplitude Logger [Error]: TypeError: Failed to fetch'
        'Fetch error POST https://api2.amplitude.com/2/httpapi',
        'Fetch error POST https://api2.amplitude.com/batch',
    ];
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private angularticsConfig: AngularticsConfigService,
        private ngZone: NgZone,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    run(): void {
        this.ngZone.runOutsideAngular(() =>
            smartTimer(
                moment.duration(this.resetIntervalSeconds, 'seconds').asMilliseconds(),
                moment.duration(this.resetIntervalSeconds, 'seconds').asMilliseconds(),
                moment.duration(this.resetIntervalSeconds, 'seconds').asMilliseconds(),
            )
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.logCounter.clear()),
        );
        datadogLogs.init({
            clientToken: 'pub33808b4d77f7b77dbad23f13e95305e1',
            forwardErrorsToLogs: true,
            service: 'pure1..com',
            sampleRate: 100, // Log 100% of errors
            version: environment.buildVersion,
            env: isPure1Production(this.window.location) ? 'prod' : 'not-prod', // Later, may actually want to split this out by prod/pre-prod/staging/dev/
            beforeSend: (log: LogsEvent) => {
                // discard logs for aborted requests
                if (log.http?.status_code === 0) {
                    return false;
                }
                const count = this.updateLogCount(log);
                return count <= this.throttleAfterCount && !this.isIgnored(log.message);
            },
        });

        // Include the Angulartics custom dimensions (once they're available) to provide additional context
        this.angularticsConfig.userIdentified$.subscribe(userIdentityInfo => {
            datadogLogs.addLoggerGlobalContext('original-email-domain', userIdentityInfo.originalEmailDomain);
            datadogLogs.addLoggerGlobalContext('impersonated-email-domain', userIdentityInfo.impersonatedEmailDomain);
            datadogLogs.addLoggerGlobalContext('original-contact-id', userIdentityInfo.originalContactId);
            datadogLogs.addLoggerGlobalContext('impersonated-contact-id', userIdentityInfo.impersonatedContactId);
        });
    }

    updateLogCount(log: LogsEvent): number {
        const key = this.makeKey(log);
        if (!this.logCounter.has(key)) {
            this.logCounter.set(key, { count: 0, log });
        }
        const old = this.logCounter.get(key);
        old.count++;
        return this.logCounter.get(key).count;
    }

    makeKey(log: LogsEvent): string {
        return log.message + '\n' + log.error?.stack?.slice(0, 500);
    }

    isIgnored(message: string): boolean {
        return this.ignoredLogs.some(rule => {
            if (typeof rule === 'string') {
                return message === rule;
            } else {
                return rule.test(message);
            }
        });
    }
}
