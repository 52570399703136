import { Alert } from './Alert';

export class FBladeAlert extends Alert implements FBladeAlert {
    description: string;
    subject: string;

    constructor(json: any) {
        super(json);

        this.arrayId = json.array_id ? String(json.array_id) : null;
        this.description = json.description ? String(json.description) : '-';
        this.subject = json.subject ? String(json.subject) : '-';
    }
}
