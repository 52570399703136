import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { GenealogyEventType } from '@pure1/data';

@Component({
    selector: 'genealogy-display-options',
    templateUrl: './genealogy-display-options.component.html',
})
export class GenealogyDisplayOptionsComponent implements OnChanges {
    @Input() readonly selectedEvents: GenealogyEventType[];
    @Input() readonly eventOptions: GenealogyEventType[];

    @Output() readonly applyOptions = new EventEmitter<GenealogyEventType[]>();

    events: GenealogyEventType[] = [];
    label: string;
    isAllSelected: boolean;

    ngOnChanges(): void {
        this.events = [...this.selectedEvents];

        if (this.events.length === this.eventOptions.length) {
            this.label = 'All';
            this.isAllSelected = true;
        } else {
            this.label = `${this.events.length} selected`;
            this.isAllSelected = false;
        }
    }

    onChange(events: GenealogyEventType[]): void {
        // The ng-select change listener also captures input (change) event
        if (Array.isArray(events)) {
            this.applyOptions.emit(events);
        }
    }

    onToggleAll(): void {
        if (this.events.length === this.eventOptions.length) {
            this.applyOptions.emit([]);
        } else {
            this.applyOptions.emit(this.eventOptions);
        }
    }

    getAllGroup(): string {
        return 'All';
    }
}
