import { Resource } from '../interfaces/resource';

export class PhantomArray implements Resource {
    readonly id: string;
    readonly name: string;

    constructor(json: any) {
        this.id = String(json.id);
        this.name = json.name;
    }
}
