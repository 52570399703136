<label>Invoice Date:</label>
<calendar-time-range-select
    class="form-control start-range"
    [extremeTimeRange]="invoiceDateTimeRange"
    [selected]="selectedRangeFilters?.startRange"
    [enableCustomChoice]="true"
    customLabel="All time"
    [showReset]="true"
    [isUTCTime]="true"
    (selectionChange)="selectDateRange($event, 'creation')"
>
</calendar-time-range-select>
<label>Invoice Due Date:</label>
<calendar-time-range-select
    class="form-control end-range"
    [extremeTimeRange]="invoiceDueDateRange"
    [selected]="selectedRangeFilters?.endRange"
    [enableCustomChoice]="true"
    customLabel="All time"
    [showReset]="true"
    [isUTCTime]="true"
    (selectionChange)="selectDateRange($event, 'due')"
>
</calendar-time-range-select>
