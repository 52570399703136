<div class="banner-content" [class.alert-warning]="warningNotification.color === 'warning'">
    <div class="banner-graphic">
        <pureui-svg
            class="pstg-secondary-icon"
            [svg]="warningNotification?.icon || 'alert_warning.svg'"
            width="60"
        ></pureui-svg>
    </div>
    <div class="banner-text">
        <div class="banner-title" *ngIf="warningNotification.title">
            {{ warningNotification.title }}
        </div>
        <div class="banner-body" [innerHTML]="warningNotification.body"></div>
    </div>
    <div class="banner-actions" *ngIf="!warningNotification.hideAction">
        <a
            class="btn btn-primary start-button"
            [routerLink]="warningNotification.url"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Warning Banner - Get Started"
            angularticsLabel="aloha"
        >
            {{ warningNotification.buttonLabel || 'Get Started' }}
        </a>
    </div>
</div>
