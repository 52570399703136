<div class="btn-group flex-btn-group flex-btn-group-first flex-btn-group-last">
    <label
        class="btn btn-primary legacy-uib-btn-radio"
        [ngClass]="{ active: router.isActive(singlePath, false) }"
        [routerLink]="singlePath"
        angulartics2On="click"
        angularticsAction="Performance - Aggregation View Nav - Go to state"
        [angularticsLabel]="singlePath"
    >
        <pureui-svg svg="aggregation-nav-icon-single.svg" height="16" class="pstg-action-icon"></pureui-svg>
        <span class="button-text">Array</span>
    </label>

    <label
        class="btn btn-primary legacy-uib-btn-radio"
        [ngClass]="{ active: router.isActive(groupPath, false) }"
        [routerLink]="groupPath"
        angulartics2On="click"
        angularticsAction="Performance - Aggregation View Nav - Go to state"
        [angularticsLabel]="groupPath"
    >
        <pureui-svg svg="aggregation-nav-icon-group.svg" height="16" class="pstg-action-icon"></pureui-svg>
        <span class="button-text">Group</span>
    </label>
</div>
