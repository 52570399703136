import { DraasApiEnrolledVm } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryEnrolledVm {
    id: string;
    protectionGroupId: string;
    state: 'ENROLL_PENDING' | 'ENROLLED' | 'REMOVAL_PENDING' | 'REMOVED' | 'ENROLL_FAILED' | 'REMOVAL_FAILED';

    constructor(json: DraasApiEnrolledVm) {
        this.id = json.id;
        this.protectionGroupId = json.protection_group_id;
        this.state = json.state;
    }
}
