import { filter, Subject, Subscription, takeUntil } from 'rxjs';

import { Component, OnInit, OnDestroy, Inject, OnChanges, SimpleChanges, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Banner, bannerStatus, DataPage } from '@pure1/data';

import { BannerCacheService } from './banner-cache.service';
import { WINDOW } from '../../../app/injection-tokens';
import { StorageService } from '../../../services/storage.service';

const persistKey = 'banners';

interface IBannersViewState {
    dismissed: Set<Banner['id']>;
}

@Component({
    selector: 'banners',
    templateUrl: 'banners.component.html',
})
export class BannersComponent implements OnInit, OnDestroy, OnChanges {
    @Input() readonly additionalBanners: Banner[] = [];

    banners: Banner[] = [];

    viewState: IBannersViewState;

    private bannerCacheSubscription: Subscription;
    private pathname: string;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private bannerCacheService: BannerCacheService,
        private storageService: StorageService,
        @Inject(WINDOW) private window: Window,
        private router: Router,
    ) {}

    ngOnInit(): void {
        const initialState = this.storageService.local.get(persistKey);
        this.viewState = {
            dismissed: new Set<Banner['id']>(initialState?.dismissed ?? []),
        };

        this.updateBanners();

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.updateBanners();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
        this.unsubscribeBannerList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.additionalBanners) {
            this.updateBanners();
        }
    }

    getBannerClass(status: bannerStatus): string {
        if (status === 'info') {
            return 'alert-info';
        } else if (status === 'warning') {
            return 'alert-warning';
        } else {
            return 'alert-danger';
        }
    }

    close(id: Banner['id']): void {
        this.viewState.dismissed.add(id);
        this.storageService.local.set(persistKey, {
            dismissed: Array.from(this.viewState.dismissed),
        });
    }

    private unsubscribeBannerList(): void {
        if (this.bannerCacheSubscription && !this.bannerCacheSubscription.closed) {
            this.bannerCacheSubscription.unsubscribe();
        }
    }

    private updateBanners(): void {
        this.pathname = this.window.location.pathname;
        this.unsubscribeBannerList();
        this.bannerCacheSubscription = this.bannerCacheService.list().subscribe((response: DataPage<Banner>) => {
            const additionalBanners = this.additionalBanners ? this.additionalBanners : [];
            this.banners = response.response
                .filter((banner: Banner) => banner.pagePrefix.test(this.pathname))
                .concat(additionalBanners);
        });
    }
}
