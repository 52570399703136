import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    StepUpChallengeResponse,
    StepUpError,
    StepUpErrorType,
    StepUpFactor,
    StepUpService,
    StepUpVerifyResponse,
} from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { StepUpSupportService } from '../services/step-up-support.service';

const DEFAULT_TTL_SECONDS = 300;

@Component({
    selector: 'totp-step-up-challenge',
    templateUrl: 'totp-step-up-challenge.component.html',
})
export class TotpStepUpChallengeComponent implements OnInit {
    @Input() readonly factor: StepUpFactor;
    @Input() readonly audience: string;
    @Input() readonly authorizationDetails: any[];
    @Input() readonly singleUse: boolean;
    @Input() readonly ttl: number;

    @Output() readonly verifySuccess = new EventEmitter<StepUpVerifyResponse>();
    @Output() readonly verifyCancel = new EventEmitter<void>();

    errorMessage: string;
    challengeResponse: StepUpChallengeResponse = null;
    passcodeInputValue: string;

    constructor(
        private angulartics: Angulartics2,
        private stepUpService: StepUpService,
        private stepUpSupport: StepUpSupportService,
    ) {}

    ngOnInit(): void {
        this.challenge();
    }

    private challenge(): void {
        // issue the challenge
        this.angulartics.eventTrack.next({
            action: 'Step Up - Challenge Modal - passcode challenge issued',
            properties: { category: 'Action', label: this.factor.factorType },
        });

        this.stepUpService
            .stepUpChallenge(
                this.factor.id,
                this.audience,
                this.authorizationDetails,
                this.singleUse,
                this.ttl != null ? this.ttl : DEFAULT_TTL_SECONDS,
            )
            .subscribe({
                next: response => {
                    this.challengeResponse = response;
                },
                error: (error: StepUpError) => {
                    console.warn(`error issuing challenge: ${error.errorDescription}`);
                    this.errorMessage = error.errorDescription;
                },
            });
    }

    submitDisabled(): boolean {
        return this.passcodeInputValue == null; // invalid value, see TotpPasscodeInputComponent
    }

    verify(): void {
        this.stepUpService
            .stepUpVerify(this.passcodeInputValue, this.challengeResponse.stateToken, this.factor.id)
            .subscribe({
                next: (response: StepUpVerifyResponse) => {
                    this.angulartics.eventTrack.next({
                        action: 'Step Up - Challenge Modal - passcode verified successfully',
                        properties: { category: 'Action' },
                    });
                    this.verifySuccess.emit(response);
                },
                error: (error: StepUpError) => this.handleStepUpError(error),
            });
    }

    goToSupport(): void {
        this.stepUpSupport.openStepUpChallengeHelpCase();
        this.verifyCancel.emit();
    }

    close(): void {
        this.verifyCancel.emit();
    }

    inputValueChanged(value: string): void {
        this.passcodeInputValue = value;
    }

    private handleStepUpError(error: StepUpError) {
        this.errorMessage = error.errorDescription;

        if (error.error === 'locked_out') {
            this.angulartics.eventTrack.next({
                action: 'Step Up - Challenge Modal - passcode lockout',
                properties: { category: 'Action' },
            });
        }
        this.trackPassCodeError(error.error);
    }

    private trackPassCodeError(stepUpError: StepUpErrorType): void {
        this.angulartics.eventTrack.next({
            action: 'Step Up - Challenge Modal - challenge error',
            properties: { category: 'Action', label: stepUpError },
        });
    }
}
