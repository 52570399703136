import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { SupportPxCluster } from '../models/support-px-cluster';

// See CLOUD-104579 for the background on this service
@Injectable({ providedIn: 'root' })
export class SupportPxClusterService extends GenericService<SupportPxCluster> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SupportPxCluster,
            endpoint: '/rest/v1/support/portworx/clusters',
        });
    }
}
