import { Subject } from 'rxjs';
import { takeUntil, filter, take, switchMap } from 'rxjs/operators';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { CachedCurrentUserService, CurrentUser } from '@pure1/data';

import { WINDOW } from './injection-tokens';
import { AuthenticationService } from '../services/authentication.service';

/**
 * Checks if the current user's id has changed (eg due to switching users in another tab).
 * When the user changes, reload the page.
 * NOTE: Current user and /introspect are not called frequently. The UserChangedDetectorInterceptor is meant
 *  to catch user change from any (SPOG) backend response.
 */
@Injectable({ providedIn: 'root' })
export class UserChangedDetectorService implements OnDestroy {
    private readonly destroy$ = new Subject<void>();

    private originalUser: CurrentUser;

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        @Inject(WINDOW) private window: Window,
        private authenticationService: AuthenticationService,
    ) {}

    run(): void {
        this.authenticationService
            .isLoggedIn()
            .pipe(
                filter(Boolean),
                take(1),
                switchMap(_ => this.cachedCurrentUserService.get()),
                takeUntil(this.destroy$),
            )
            .subscribe(newUser => {
                if (!this.originalUser) {
                    // Set the user for the first time
                    this.originalUser = newUser;
                    return;
                }

                // Check if user id has changed
                if (newUser && this.originalUser.id !== newUser.id) {
                    this.reloadPage();
                }
            });
    }

    /**
     * Special case to stop this service completely
     */
    stop(): void {
        this.ngOnDestroy();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private reloadPage(): void {
        this.window.location.reload();
    }
}
