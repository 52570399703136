import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TouModalComponent } from './tou-modal/tou-modal.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TouModalComponent],
})
export class TouModule {}
