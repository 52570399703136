export type FactorType = 'totp' | 'sms' | 'email';

export type FactorStatus = 'ACTIVE' | 'PENDING_ACTIVATION';

export type FactorProfile = {
    email?: string;
    phoneNumber?: string;
    secret?: string;
    barcodeUri?: string;
};

export class StepUpFactor {
    id: string;
    factorType: FactorType;
    status: FactorStatus;
    profile: FactorProfile;

    constructor(json: any) {
        this.id = json.id;
        this.factorType = json.factor_type;
        this.status = json.status;
        this.profile = {
            phoneNumber: json.profile?.phone_number,
            email: json.profile?.email,
            secret: json.profile?.secret,
            barcodeUri: json.profile?.barcode_uri,
        };
    }
}
