import moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { License } from '../models/license';

@Injectable({ providedIn: 'root' })
export class LicenseService extends GenericService<License> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: License,
            endpoint: '/rest/v3/subscription-licenses',
        });
    }
}
