import { Angulartics2Module } from 'angulartics2';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ArraysTagsViewComponent } from './arrays-tags-view/arrays-tags-view.component';
import { TagChangeSummaryComponent } from './tag-change-summary/tag-change-summary.component';
import { UIModule } from '../ui/ui.module';
import { UserManagementModule } from '../user-management/user-management.module';
import { TagsSharedModule } from './tags.shared.module';
import { AuthorizerModule } from '@pure/authz-authorizer';

@NgModule({
    imports: [
        AuthorizerModule,
        CommonModule,
        Angulartics2Module,
        NgbModule,
        TagsSharedModule,
        UIModule,
        UserManagementModule,
    ],
    declarations: [ArraysTagsViewComponent, TagChangeSummaryComponent],
    exports: [ArraysTagsViewComponent],
})
export class TagsModule {}
