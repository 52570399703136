import { Component, Input } from '@angular/core';

import { Tag } from '@pure1/data';

@Component({
    selector: 'pure-tag',
    template: `<span class="tag-key">
            <span class="tag-readonly-icon" *ngIf="!editable"
                ><pureui-svg svg="locked.svg" height="10"></pureui-svg
            ></span>
            <span class="tag-key-text">{{ tag.key }}</span>
        </span>
        <span class="tag-value">{{ tag.value }}</span>`,
    preserveWhitespaces: true,
})
export class PureTagComponent {
    @Input() tag: Tag;
    @Input() editable: boolean;
}
