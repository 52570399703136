import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import {
    addIdsToRequest,
    getUniqueKeysFromInputs,
    IGetBatchMetricsHistoryParameters,
} from './base-batch-metrics-history.service';
import {
    MetricsEndpoint,
    ApiCallBatcherInputs,
    IMetricsHistoryOptions,
    MetricsHistoryMetricNames,
} from './metrics-history.service';
import { ApiCallBatcher } from './api-call-batcher';
import { ITimeSeriesCacheKey, TimeSeriesCache } from '../utils/time-series-cache';
import { IRestResponse } from '../interfaces/collection';
import { WINDOW } from '../../app/injection-tokens';

const TIMESERIES_VALUES_CACHE_DURATION = moment.duration(3, 'minutes');

const BATCH_WAIT_TIME_MS = 100;

export type IGetBatchPodMetricsHistoryResult = Map<string, IGetPodPerformanceMetricsResult>;

@Injectable({ providedIn: 'root' })
export class BatchPodMetricsHistoryService {
    private apiEndpoint = MetricsEndpoint.pods;

    private timeseriesValuesCache: TimeSeriesCache<IGetPodPerformanceMetricsResult> =
        new TimeSeriesCache<IGetPodPerformanceMetricsResult>(TIMESERIES_VALUES_CACHE_DURATION);
    private podBatcher = new ApiCallBatcher<ApiCallBatcherInputs, IGetBatchPodMetricsHistoryResult>(
        BATCH_WAIT_TIME_MS,
        request => this.apiBatcherGroupKey(request),
        requests => this.apiBatcherExecute(requests),
        this.window,
    );

    constructor(
        private http: HttpClient,
        @Inject(WINDOW) private window: Window,
    ) {}

    getMetricsHistoryTimeseries(
        podId: string,
        isPod: boolean,
        options: IMetricsHistoryOptions,
    ): Observable<IGetPodPerformanceMetricsResult> {
        const apiBatcherInput: ApiCallBatcherInputs = {
            podId: podId,
            startTime: options.startTime,
            endTime: options.endTime,
            aggregationMethod: options.aggregationMethod,
            maxPoints: options.maxPoints,
            metricName: options.metricName,
        };

        return this.podBatcher.enqueue(apiBatcherInput).pipe(
            map(result => result.get(podId)),
            take(1),
        );
    }

    protected apiBatcherGroupKey(request: ApiCallBatcherInputs): string {
        const keyValues = [request.arrayId, request.startTime.valueOf(), request.endTime.valueOf(), request.maxPoints];
        return keyValues.join('_');
    }

    protected apiBatcherExecute(requests: ApiCallBatcherInputs[]): Observable<IGetBatchPodMetricsHistoryResult> {
        const { podId, startTime, endTime, maxPoints, aggregationMethod } = requests[0];
        const metricSet = new Set<MetricsHistoryMetricNames>(requests.map(r => r.metricName));
        const uniqueMetricList = Array.from(metricSet).sort();
        const uniqueIdList = getUniqueKeysFromInputs<ApiCallBatcherInputs, 'podId'>(requests, 'podId');

        const addIdsToParameters = (idList: string[], params: IGetBatchMetricsHistoryParameters) =>
            addIdsToRequest('ids', idList, params);
        const createCacheKeyFromId = (id: string) =>
            this.createCacheKey(uniqueMetricList, id, startTime, endTime, aggregationMethod);

        const params = {
            metrics: uniqueMetricList.join(','),
            start_time: String(startTime.valueOf()),
            end_time: String(endTime.valueOf()),
            max_points: maxPoints ? String(maxPoints) : undefined,
            aggregation: aggregationMethod || undefined,
        };

        return this.fetchTimeSeriesData(
            uniqueIdList,
            uniqueMetricList,
            params,
            addIdsToParameters,
            createCacheKeyFromId,
        );
    }

    protected createCacheKey(
        metricNames: MetricsHistoryMetricNames[],
        id: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        aggregationMethod: IAggregationMethod,
    ): ITimeSeriesCacheKey {
        return {
            metricNames: metricNames,
            entityId: id,
            startTime: startTime,
            endTime: endTime,
            aggregationMethod: aggregationMethod,
        };
    }

    protected fetchTimeSeriesData(
        uniqueIdList: string[],
        uniqueMetricList: MetricsHistoryMetricNames[],
        params: IGetBatchMetricsHistoryParameters,
        addIdsToParameters: (idList: string[], params: IGetBatchMetricsHistoryParameters) => void,
        createCacheKey: (id: string) => ITimeSeriesCacheKey,
    ): Observable<IGetBatchPodMetricsHistoryResult> {
        const unCachedIdList: string[] = [];
        const cachedResults = new Map<string, IGetPodPerformanceMetricsResult>();
        uniqueIdList.forEach(id => {
            const cacheKey = createCacheKey(id);
            const cacheValue = this.timeseriesValuesCache.getValue(cacheKey);

            if (cacheValue) {
                cachedResults.set(id, cacheValue);
            } else {
                unCachedIdList.push(id);
            }
        });

        if (unCachedIdList.length === 0) {
            // no uncached results, no API call necessary
            return of(cachedResults);
        }
        // add ids we do not have cached timeseries for to the request
        addIdsToParameters(unCachedIdList, params);

        return this.http.get<IRestResponse<IGetPodPerformanceMetricsResult>>(this.apiEndpoint, { params: params }).pipe(
            map(response => {
                const responseMap = new Map<string, IGetPodPerformanceMetricsResult>();

                response.items.forEach(metricsResult => {
                    const entityId = metricsResult.id;
                    responseMap.set(entityId, metricsResult);
                    const cacheKey = createCacheKey(entityId);
                    this.timeseriesValuesCache.put(cacheKey, metricsResult); // Add it to the cache for next time
                });

                return responseMap;
            }),
            map(response => {
                // fill in cached timeseries
                cachedResults.forEach((value: IGetPodPerformanceMetricsResult, key: string) => {
                    response.set(key, value);
                });
                return response;
            }),
        );
    }
}
