import { Pipe, PipeTransform } from '@angular/core';

/**
 * If the input value is a non-empty string, returns the input string wrapped in parentheses.
 * Eg:
 * Input:   foo
 * Output:  (foo)
 */
@Pipe({ name: 'parenthesize', pure: true })
export class ParenthesizePipe implements PipeTransform {
    transform(value: any): string {
        if (value === null || value === undefined || (typeof value === 'string' && value.trim().length === 0)) {
            return '';
        } else {
            return '(' + value + ')';
        }
    }
}
