import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchArrayComponent } from './components/search-array/search-array.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchPxClusterComponent } from './components/search-px-cluster/search-px-cluster.component';
import { SearchDisasterRecoveryClusterComponent } from './components/search-disaster-recovery-cluster/search-disaster-recovery-cluster.component';
import { SearchLicenseComponent } from './components/search-license/search-license.component';
import { UIModule } from '../../ui/ui.module';

@NgModule({
    declarations: [
        SearchArrayComponent,
        SearchLicenseComponent,
        SearchPxClusterComponent,
        SearchDisasterRecoveryClusterComponent,
    ],
    imports: [CommonModule, FormsModule, NgbModule, ReactiveFormsModule, UIModule],
    exports: [
        SearchArrayComponent,
        SearchLicenseComponent,
        SearchPxClusterComponent,
        SearchDisasterRecoveryClusterComponent,
    ],
})
export class SharedModule {}
