import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StepUpError } from '@pure1/data';

import { StepUpSupportService } from '../../services/step-up-support.service';

export type StepUpErrorMessageBody = { title: string; message: string; closeLabel: string };

@Component({
    selector: 'step-up-error-message-modal',
    templateUrl: 'step-up-error-message-modal.component.html',
    host: { class: 'step-up-modal' },
})
export class StepUpErrorMessageModalComponent {
    @Input() readonly errorBody: StepUpErrorMessageBody;
    @Input() readonly showSupportButton = false;
    @Input() readonly error: StepUpError;

    constructor(
        public activeModal: NgbActiveModal,
        private stepUpSupport: StepUpSupportService,
    ) {}

    close(): void {
        this.activeModal.dismiss(this.error);
    }

    goToSupport(): void {
        this.stepUpSupport.openStepUpUnlockRequestCase();
        this.close();
    }
}
