import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Invoice } from '../models/invoice';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class InvoiceService extends GenericService<Invoice> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Invoice,
            endpoint: '/rest/v1/partner/invoice',
            //default time range for the invoice is one year
            defaultParams: {
                filter: {
                    date: `date>=${moment.utc().subtract(1, 'year').valueOf()} and date<=${moment.utc().valueOf()}`,
                },
            },
        });
    }
}
