import { take } from 'rxjs/operators';
import { Directive, HostBinding, SimpleChanges, OnChanges, Input, OnInit } from '@angular/core';
import { CachedCurrentUserService } from '@pure1/data';

@Directive({
    selector: '[pure-read-only]',
})
export class PureReadOnlyDirective implements OnInit, OnChanges {
    disabled: boolean;

    private readonly = false;

    constructor(private cachedCurrentUserService: CachedCurrentUserService) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(cu => {
                this.readonly = cu.readOnly;
                this.updateReadOnly();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.readonly && !changes.disabled.currentValue) {
            this.updateReadOnly();
        }
    }

    private updateReadOnly(): void {
        this.disabled = this.disabled || this.readonly;
    }
}
