import { cloneDeep } from 'lodash';
import { StorageService } from '../../services/storage.service';
import { Case, UpgradeScheduleDraft } from '../support.interface';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class CaseDraftService<T extends Case | UpgradeScheduleDraft> {
    // the key (string) to be used to store the draft in session storage
    protected abstract DRAFT_KEY: string;
    // the entity to be saved as draft
    private draft: Partial<T> = {};

    constructor(protected storageService: StorageService) {}

    getDraft = (): Partial<T> => {
        this.draft = this.storageService.session.get(this.DRAFT_KEY) || {};
        return cloneDeep(this.draft);
    };

    setDraftField = (field: string, value: any): void => {
        if (typeof value === 'undefined') {
            delete this.draft[field];
        } else {
            this.draft[field] = value;
        }
        if (Object.keys(this.draft).length === 0) {
            this.cancelDraft();
        } else {
            this.storageService.session.set(this.DRAFT_KEY, this.draft);
        }
    };

    cancelDraft = (): void => {
        this.storageService.session.remove(this.DRAFT_KEY);
        this.draft = {};
    };
}
