<nav class="row navbar navbar-light navbar-full">
    <ul class="nav navbar-nav" pureTabGroup [selectedTab]="selectedItem.title">
        <li
            *ngFor="let submenuItem of submenuItems"
            class="nav-item"
            [ngClass]="{ active: submenuItem === selectedItem }"
            [pureTabName]="submenuItem.title"
        >
            <a class="nav-link" (click)="changeTabSelection(submenuItem)">
                {{ submenuItem.title }}
                <pureui-svg *ngIf="submenuItem.warning" svg="warning-solid.svg" width="14" height="14"></pureui-svg>
            </a>
        </li>
    </ul>
</nav>
