import { AfterViewInit, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, Directive } from '@angular/core';

import { AppService } from '../../../app/app.service';
import { setResizerSize } from '../../../redux/actions';
import { NgRedux } from '../../../redux/ng-redux.service';
import { IState } from '../../../redux/pure-redux.service';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

const DRAG_MOVE_EVENTS = ['mousemove', 'touchmove'];
const DRAG_END_EVENTS = ['mouseup', 'touchend', 'touchcancel'];

/**
 * Describes the state of the current dragging.
 */
interface IDragState {
    /**
     * Mouse position on the relevant coordinate when the dragging started
     */
    startMousePos: number;

    /**
     * Size of the element in the relevant dimension when the dragging started.
     */
    startElemSize: number;
}

@Directive()
export abstract class BaseResizeComponent implements AfterViewInit, OnDestroy {
    @ViewChild('resize', { static: true }) readonly resizeDiv: ElementRef;

    private rootElem: HTMLElement;
    private dragState: IDragState;
    private currentSize: number;
    private docListeners: (() => void)[] = [];
    private destroy$ = new Subject<boolean>();

    constructor(
        private el: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        private appService: AppService,
        private ngZone: NgZone,
        private ngRedux: NgRedux<IState>,
        private cssStyleAttribute: 'width' | 'height',
    ) {}

    ngOnDestroy(): void {
        this.endDragging();
        this.destroy$.next(false);
    }

    ngAfterViewInit(): void {
        this.rootElem = this.resizeDiv.nativeElement;

        // TODO: Change the callback handlers to run outside the Angular zone (do the same in vertical-resize component as well).
        if (this.el.nativeElement.id) {
            this.setSize(this.ngRedux.getState().resizerSize[this.el.nativeElement.id]);
            this.ngZone.runOutsideAngular(() => {
                this.ngRedux
                    .select(['resizerSize', this.el.nativeElement.id])
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => this.setSize(this.ngRedux.getState().resizerSize[this.el.nativeElement.id]));
            });
        } else {
            console.warn(`This resizer does not have an id, so its position won't be remembered`);
        }
    }

    /**
     * Handles starting the dragging.
     */
    dragStartHandler(event: MouseEvent | TouchEvent): void {
        // Ensure we don't somehow start two drag operations at once
        if (!this.dragState) {
            this.endDragging();
            this.dragState = null;
        }

        this.dragState = {
            startMousePos: this.getPageSize(event),
            startElemSize: this.getStartingOffset(this.rootElem),
        };

        // Hook up drag events to document
        this.ngZone.runOutsideAngular(() => {
            DRAG_END_EVENTS.forEach(listenTo => {
                this.docListeners.push(this.renderer.listen('document', listenTo, () => this.endDragging()));
            });
            DRAG_MOVE_EVENTS.forEach(listenTo => {
                this.docListeners.push(
                    this.renderer.listen('document', listenTo, event => this.dragMoveHandler(event)),
                );
            });
        });

        // Prevent highlighting while dragging
        event.cancelBubble = true;
        event.returnValue = false;

        event.stopPropagation?.();
        event.preventDefault?.();
    }

    setSize(size: number): void {
        if (size != null) {
            this.currentSize = size;
            this.renderer.setStyle(this.rootElem, this.cssStyleAttribute, size + 'px');
        }
    }

    abstract getSizeFromMouseEvent(event: MouseEvent): number;

    abstract getSizeFromTouchEvent(touch: Touch): number;

    abstract getStartingOffset(rootElem: HTMLElement): number;

    /**
     * Handles when the mouse moves while dragging.
     */
    private dragMoveHandler(event: MouseEvent | TouchEvent): void {
        // Update the element size based on the new cursor position
        const sizeChangeSinceStart = this.dragState.startMousePos - this.getPageSize(event);
        const newSize = this.dragState.startElemSize - sizeChangeSinceStart;
        this.setSize(newSize);
    }
    /**
     * Ends the current dragging.
     */
    private endDragging(): void {
        // Notify listeners of change
        if (this.dragState != null && this.el.nativeElement.id) {
            this.appService.resizableSizeUpdated.next({ dragKey: this.el.nativeElement.id });
        }

        // Remove drag events
        this.docListeners.forEach(unregiseterListener => {
            unregiseterListener();
        });

        this.docListeners = [];
        this.dragState = null;

        // Save the new position
        if (this.el.nativeElement.id) {
            this.ngRedux.dispatch(setResizerSize(this.el.nativeElement.id, this.currentSize));
        }
    }

    /**
     * Gets the page size value from an event.
     */
    private getPageSize(event: MouseEvent | TouchEvent): number {
        // If the browser for some reason doesn't supply a value for the size, we will return 0, which will cause the
        // control to simply not move when the user tries to resize.
        if (event instanceof MouseEvent) {
            return this.getSizeFromMouseEvent(event) || 0;
        } else if (event instanceof TouchEvent) {
            const touch = event?.touches?.[0];
            return (touch && this.getSizeFromTouchEvent(touch)) || 0;
        }
    }
}
