import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { License } from '@pure1/data';

const DEFAULT_DECIMAL = 2;

@Pipe({ name: 'formatPreRatio' })
export class FormatPreRatioPipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');

    transform(license: License, decimal = DEFAULT_DECIMAL): string {
        if (!license) {
            return null;
        }

        const preRatio = license.preRatio;
        if (typeof preRatio !== 'number' || isNaN(preRatio) || preRatio < 0) {
            return null;
        }

        return FormatPreRatioPipe.decimalPipe.transform(preRatio, `1.${decimal}-${decimal}`) + ' to 1';
    }
}
