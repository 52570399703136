import { StorageEntity } from './storage-entity';
import { FileSystemPerformanceAggregation } from './file-system-performance-aggregation';

export class FileSystem extends StorageEntity {
    array_fqdn: string;
    array_id: string;
    file_system_local_id: string;
    file_system_guid: string;
    aggregation: FileSystemPerformanceAggregation;
    root_directory_id: string;

    constructor(json: any) {
        super(json);
        this.array_fqdn = json.array_fqdn;
        this.array_id = json.array_id;
        this.file_system_local_id = json.file_system_local_id;
        this.file_system_guid = json.file_system_guid;
        this.root_directory_id = json.root_directory_id || '';
        if (json.performance_aggregation) {
            this.aggregation = new FileSystemPerformanceAggregation(json.performance_aggregation.aggregation);
        }
    }
}
