import { PipeTransform, Pipe } from '@angular/core';
import { Metric } from '@pure1/data';

@Pipe({ name: 'subscriptionMetric' })
export class SubscriptionMetricPipe implements PipeTransform {
    transform(metric: Metric): string {
        switch (metric) {
            case Metric.LICENSE_EFFECTIVE_USED_SPACE:
            case Metric.LICENSE_VM_INSTANCE_COUNT:
                return 'License Usage Summary';
            case Metric.LICENSE_LOGICAL_MANAGED_CAPACITY:
                return 'Logical Managed Capacity License';
            case Metric.LICENSE_EUC_BREAKDOWN:
                return 'License Usage Details';
            case Metric.DRAAS_CLUSTER_LOGICAL_MANAGED_CAPACITY:
                return 'Logical Managed Capacity DRaaS Cluster';
            case Metric.ARRAY_EFFECTIVE_USED_SPACE:
                return 'Appliance Usage Summary';
            case Metric.VM_CLUSTER_VM_INSTANCE_COUNT:
                return 'Cluster Usage Summary';
            default:
                console.warn('Input is not of Metric type: ', metric);
                return metric;
        }
    }
}
