import { Component, Input } from '@angular/core';
import { WarningPair } from '@pure1/data';

/**
 * 'GOOD': This array was one of the top-ranked placements for the workload.
 * 'OK': This workload will fit on the array, but is not optimal.
 * 'BAD': This workload will not fit.
 * 'UNKNOWN': We don't have the results yet.
 */
export type RecommendationDegree = 'GOOD' | 'GOOD_WITH_WARNINGS' | 'OK' | 'BAD' | 'ERROR' | 'NOT READY';

@Component({
    selector: 'placement-recommendation-degree-icon',
    templateUrl: './placement-recommendation-degree-icon.component.html',
})
export class PlacementRecommendationDegreeIconComponent {
    @Input() readonly degree: RecommendationDegree;
    @Input() readonly warnings: WarningPair[];
    @Input() readonly isLoadTrueMax: boolean;
    @Input() readonly areRecommendationsReady: boolean;

    readonly trueMaxRecommendationWarning =
        'True Max is not taken into account for recommendations, for more details, visit the Help section in the user guide.';
}
