import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import LRU from 'lru-cache';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface CacheValue<T> {
    requestTime: moment.Moment;
    response: T;
}

interface CacheObservableWithBody<T> {
    subject: Observable<CacheValue<T>>;
    body: any;
}

@Injectable({ providedIn: 'root' }) // Share the cache globally (don't have separate cache per feature)
export class HttpCacheService {
    private readonly cache = new LRU<string, Observable<CacheValue<any>>>({
        max: 500,
        maxAge: moment.duration(1, 'hour').asMilliseconds(),
    });

    private readonly postAsGetCacheBody = new LRU<string, CacheObservableWithBody<any>>({
        max: 500,
        maxAge: moment.duration(5, 'minutes').asMilliseconds(),
    });

    constructor(private http: HttpClient) {}

    httpGetWithCache<T>(url: string, params: HttpParams, forced = false): Observable<CacheValue<T>> {
        const key = `${url}?${params.toString()}`;
        const requestTime = moment();

        if (this.cache.has(key) && !forced) {
            const cached = this.cache.get(key);
            if (cached) {
                return <Observable<CacheValue<T>>>cached.pipe(map(data => cloneDeep(data)));
            }
        }

        const dataSubject = new ReplaySubject<CacheValue<T>>(1);
        this.cache.set(key, dataSubject);
        this.http
            .get<T>(url, { params: params })
            .pipe(take(1))
            .subscribe({
                next: response => dataSubject.next({ requestTime, response }),
                error: err => {
                    if (!err || err.status !== 404) {
                        // cache the 404 as well
                        this.cache.del(key);
                    }
                    dataSubject.error(err);
                },
            });

        return dataSubject.pipe(map(data => cloneDeep(data)));
    }

    /**
     * This method is for postAsGet requests. For caching we use the params as the key but also store
     *   the body used to compare with the new body coming in. If the params are the same
     *   and the body is the same then return the cached value. If the params are the same but
     *   the body is different then make a new request
     */
    httpPostAsGetWithCache<T>(url: string, body: any, params: HttpParams, forced = false): Observable<CacheValue<T>> {
        const key = `${url}?${params.toString()}`;
        const requestTime = moment();

        // If params are the same check the body stored
        if (this.postAsGetCacheBody.has(key) && !forced) {
            // Check to make sure the body is the same
            const cacheValue = this.postAsGetCacheBody.get(key);
            // Use lodash isEqual to do a deep comparison of the body objects
            if (cacheValue && isEqual(cacheValue.body, body)) {
                return <Observable<CacheValue<T>>>cacheValue.subject.pipe(map(data => cloneDeep(data)));
            }
        }

        // If the params or the body are different from the previous request then
        //  make a new request and cache the result

        const dataSubject = new ReplaySubject<CacheValue<T>>(1);
        this.postAsGetCacheBody.set(key, { body: body, subject: dataSubject });

        this.http
            .post<T>(url, body, { params })
            .pipe(take(1))
            .subscribe({
                next: response => dataSubject.next({ requestTime, response }),
                error: err => {
                    this.postAsGetCacheBody.del(key);
                    dataSubject.error(err);
                },
            });

        return dataSubject.pipe(map(data => cloneDeep(data)));
    }

    /**
     * Clears all items from the cache
     * @param filter If specified, only clear the keys that match the given filter. Otherwise, clears all.
     */
    clear(filter?: (key: string) => boolean): number {
        filter = filter || (() => true);

        let count = 0;
        Array.from(this.cache.keys())
            .filter(key => filter(key as any))
            .forEach(key => {
                count++;
                this.cache.del(key);
            });

        Array.from(this.postAsGetCacheBody.keys())
            .filter(key => filter(key as any))
            .forEach(key => {
                count++;
                this.postAsGetCacheBody.del(key);
            });

        return count;
    }
}
