import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseBanner } from '../base-banner';
import { CreateOrUpdateResult, OpportunityService } from '../../services/opportunity.service';
import moment from 'moment';
import { CachedCurrentUserService } from '@pure1/data';
import { Observable, take } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import { WINDOW } from '../../app/injection-tokens';
import { ampli } from '../../ampli';

export interface OpportunityCreationConfig {
    badgeSvg: string;
    controllerModel: string;
    comment?: string;
}

@Component({
    selector: 'opportunity-creation-banner',
    templateUrl: 'opportunity-creation-banner.component.html',
})
export class OpportunityCreationBanner extends BaseBanner implements OnInit {
    @Input() readonly config: OpportunityCreationConfig;

    readonly ampli = ampli;

    // Use this to track requests made through this banner
    FROM_BANNER_PREFIX = 'From Banner';
    FROM_BANNER_PREFIX_FB_EOL = 'From Banner:';
    DEFAULT_COMMENT = 'User request for Upgrade to';
    isSubmitting = false;

    userEmail: string;

    @ViewChild('requestConfirmedModal') requestConfirmedModal: TemplateRef<any>;

    constructor(
        @Inject(WINDOW) private window: Window,
        private opportunityService: OpportunityService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private modalService: NgbModal,
    ) {
        super();
    }

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(user => (this.userEmail = user.email));
    }

    onButtonClick(): void {
        if (this.isSubmitting) {
            return;
        }

        const now = moment();
        const phantomArrayId = 'phantom:' + now.valueOf();
        const controllerModel = this.config.controllerModel;
        // Changed the prefix format since FB Eol banner creation
        const prefix =
            this.notification.id === 'A000098'
                ? `${this.FROM_BANNER_PREFIX_FB_EOL}`
                : `${this.FROM_BANNER_PREFIX} ${this.notification.id}:`;
        const baseComment = this.config.comment ?? `${this.DEFAULT_COMMENT} ${this.config.controllerModel}`;
        const comment = `${prefix} ${baseComment} by ${this.userEmail} on ${now.utc().format()}`;
        const opportunity$: Observable<HttpResponse<CreateOrUpdateResult>> =
            this.opportunityService.createBannerNewApplianceQuoteRequest(
                phantomArrayId,
                controllerModel,
                comment,
                `New ${controllerModel} Quote Request from Pure1 Banner`,
            );

        this.isSubmitting = true;
        opportunity$.pipe(take(1)).subscribe({
            next: res => {
                this.isSubmitting = false;
                if (res.status === 202) {
                    // Request has already been submitted by someone in the same org.
                    // We will show a different confirmation message in this case
                    this.window.alert(
                        `Thank you for your request. Someone in your organization has already submitted a request on this matter`,
                    );
                } else {
                    this.modalService.open(this.requestConfirmedModal, {
                        backdrop: 'static',
                        windowClass: 'request-confirmed-modal',
                    });

                    ampli.bannerOpportunities({
                        'banner name': this.notification.title,
                        'banner opportunity action': 'Successful Request',
                    });
                }
                this.dismissNotification.emit();
            },
            error: error => {
                console.error('call opportunity errored', error);
                this.isSubmitting = false;

                ampli.bannerOpportunities({
                    'banner name': this.notification.title,
                    'banner opportunity action': 'Failed Request',
                });
            },
        });
    }
}
