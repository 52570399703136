import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Params } from '@angular/router';
import { FeatureFlagDxpService, SafeModeStatusEnum, UnifiedArray } from '@pure1/data';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FArray, FBlade, FeatureNames, PureArray } from '../../../model/model';
import { SafeModeSchedulerModalService } from '../../../safemode/services/safemode-scheduler-modal.service';
import { getSafeModeApplianceFilter, getSafeModeLink } from '../../../safemode/util';
import { HealthDataMetric } from '../../../services/arrays-manager.service';

import { Theme } from '../../../ui/styles/theme';

export interface ICapacityConfig {
    fill: string;
    outline: string;
    label: string;
    key: string;
    rawKey: HealthDataMetric | string;
    secondarySeries?: boolean;
    tooltipText?: string;
}

@Component({
    selector: 'array-expanded-card-capacity',
    templateUrl: 'array-expanded-card-capacity.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayExpandedCardCapacityComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly isExpanded: boolean;
    @Input() readonly array: UnifiedArray & FArray & FBlade;

    capacityMetadata: readonly ICapacityConfig[];
    isFlashBlade: boolean;

    safeModeLink: string;
    safeModeStatusEnum = SafeModeStatusEnum;
    safeModeMultipartyFeatureFlag = false;
    capacityDownLicensingEnabled = false;

    private destroy$ = new Subject<void>();

    constructor(
        private featureFlagDxpService: FeatureFlagDxpService,
        private safeModeSchedulerModal: SafeModeSchedulerModalService,
    ) {}

    ngOnInit(): void {
        const metaData = this.array instanceof FBlade ? this.fBladeCapacityMetadata() : this.fArrayCapacityMetadata();

        this.capacityMetadata = metaData || [];

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.SAFE_MODE_MULTIPARTY_AUTH)
            .pipe(take(1))
            .subscribe(feature => {
                this.safeModeMultipartyFeatureFlag = feature?.enabled === true;
            });
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.CAPACITY_DOWN_LICENSING_APPLIANCE)
            .pipe(take(1))
            .subscribe(feature => {
                this.capacityDownLicensingEnabled = feature?.enabled === true;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array) {
            this.isFlashBlade = this.array instanceof FBlade;
            this.safeModeLink = getSafeModeLink(getSafeModeApplianceFilter(this.array.name));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSafeModeState(): string {
        return '/dashboard/assessment/data-protection-v2';
    }

    getSafeModeUIParams(array: UnifiedArray): Params {
        return { filter: JSON.stringify(getSafeModeApplianceFilter(array.name)) };
    }

    openSafeModeMultipartAuthModal(array: PureArray): void {
        this.safeModeSchedulerModal.openSupportScheduler(array.arrayId);
    }

    private fArrayCapacityMetadata(): ICapacityConfig[] {
        const c = Theme.capacity;

        return [
            {
                label: 'Unique',
                key: 'uniqueStr',
                rawKey: 'unique',
                fill: c.unique.fill,
                outline: c.unique.outline,
                tooltipText:
                    "Physical space occupied by data unique to FlashArray's volumes and file systems after data reduction and deduplication.",
            },
            {
                label: 'Snapshots',
                key: 'snapshotsStr',
                rawKey: 'snapshots',
                fill: c.snapshots.fill,
                outline: c.snapshots.outline,
                tooltipText: 'Physical space occupied by data unique to one or more snapshots after data compression.',
            },
            {
                label: 'Shared',
                key: 'sharedSpaceStr',
                rawKey: 'shared_space',
                fill: c.shared.fill,
                outline: c.shared.outline,
                tooltipText:
                    'Physical space occupied by deduplicated data on FlashArray.  The space is shared with other volumes and snapshots as a result of data deduplication.',
            },
            {
                label: 'System',
                key: 'systemStr',
                rawKey: 'system',
                fill: c.system.fill,
                outline: c.system.outline,
                tooltipText: 'Physical space occupied by internal array metadata on FlashArray.',
            },
            {
                label: 'Replication',
                key: 'replicationStr',
                rawKey: 'replication',
                fill: c.replication.fill,
                outline: c.replication.outline,
                tooltipText:
                    'Physical system space on FlashArray used to accommodate pod-based replication features, including failovers, resync, and DR testing.',
            },
            { label: 'Empty', key: 'emptyStr', rawKey: 'empty', fill: c.empty.fill, outline: c.empty.outline },
        ];
    }

    private fBladeCapacityMetadata(): ICapacityConfig[] {
        const c = Theme.capacity;

        return [
            {
                label: 'File Systems',
                key: 'fileSystemsSpaceStr',
                rawKey: 'fileSystemSpace',
                fill: c.fileSystems.fill,
                outline: c.fileSystems.outline,
                tooltipText:
                    'Physical space occupied on FlashBlade by data unique to file systems after data compression.',
            },
            {
                label: 'Object Store',
                key: 'objectStoreSpaceStr',
                rawKey: 'objectStoreSpace',
                fill: c.objectStore.fill,
                outline: c.objectStore.outline,
                tooltipText: 'Physical space occupied on FlashBlade by data unique to buckets after data compression.',
            },
            {
                label: 'Snapshots',
                key: 'snapshotSpaceStr',
                rawKey: 'snapshots',
                fill: c.snapshots.fill,
                outline: c.snapshots.outline,
                tooltipText: 'Physical space occupied by data unique to one or more snapshots after data compression.',
            },
            {
                label: 'Shared',
                key: 'sharedSpaceStr',
                rawKey: 'shared_space',
                fill: c.shared.fill,
                outline: c.shared.outline,
                tooltipText: 'The space contributed by data that is not unique to a managed directory, or snapshot.',
            },
            { label: 'Empty', key: 'emptyStr', rawKey: 'empty', fill: c.empty.fill, outline: c.empty.outline },
        ];
    }
}
