import { formatSize } from '../utils/formatSize';
import { CapacityData } from './CapacityData';

export class FBladeCapacityData extends CapacityData {
    unique: number;
    uniqueStr: IValueUnit;
    fileSystemsSpace: number;
    fileSystemsSpaceStr: IValueUnit;
    objectStoreSpace: number;
    objectStoreSpaceStr: IValueUnit;
    snapshotSpace: number;
    snapshotSpaceStr: IValueUnit;
    sharedSpace: number;
    sharedSpaceStr: IValueUnit;

    constructor(capacity: any) {
        super(capacity);
        if (capacity) {
            this.unique = capacity.unique;
            this.fileSystemsSpace = capacity.file_systems_space;
            this.objectStoreSpace = capacity.buckets_space;
            this.snapshotSpace = capacity.snapshot_space || capacity.snapshots || 0;
            this.sharedSpace = capacity.shared_space;
            this.provisioned = capacity.file_systems_space;
        } else {
            this.unique = 0;
            this.fileSystemsSpace = 0;
        }

        this.uniqueStr = formatSize(this.unique, 2);
        this.fileSystemsSpaceStr = formatSize(this.fileSystemsSpace, 2);
        this.objectStoreSpaceStr = formatSize(this.objectStoreSpace, 2);
        this.snapshotSpaceStr = formatSize(this.snapshotSpace, 2);
        this.sharedSpaceStr = formatSize(this.sharedSpace, 2);
        this.provisionedStr = formatSize(this.provisioned, 2);
        this.dialSegments =
            this.capacity > 0
                ? [
                      { value: (100 * this.fileSystemsSpace) / this.capacity, cssClass: 'file-systems' },
                      { value: (100 * this.objectStoreSpace) / this.capacity, cssClass: 'object-stores' },
                      { value: (100 * this.snapshotSpace) / this.capacity, cssClass: 'snapshots' },
                      { value: (100 * this.sharedSpace) / this.capacity, cssClass: 'shared' },
                  ]
                : [];
    }
}
