<ng-container [formGroup]="productForm">
    <div class="row">
        <div class="col-xs-4">
            <label class="support-create-label required"> Category </label>
        </div>
        <div class="col-xs-8">
            <input
                *ngIf="isBeingEdited"
                class="form-control full-width subcategory"
                type="text"
                [value]="
                    productForm.controls.subCategory.value?.group +
                    ' - ' +
                    productForm.controls.subCategory.value?.displayName
                "
                disabled="true"
            />
            <ng-select
                class="case-subcategory"
                *ngIf="!isBeingEdited"
                [items]="subCategoryOptions"
                formControlName="subCategory"
                groupBy="group"
                bindLabel="displayName"
                [trackByFn]="makeTrackByFn()"
                (ngModelChange)="onSubCategoryChange($event)"
                [compareWith]="matchSubCategory"
                [searchable]="false"
                [clearable]="false"
                ngDefaultControl
                ngbTooltip="Product Family"
            >
                <ng-template ng-label-tmp let-item="item"> {{ item.group }} - {{ item.displayName }} </ng-template>
            </ng-select>
        </div>
    </div>
    <div
        class="row"
        *ngIf="isBeingEdited && productForm?.controls.selectedProduct.value && !shouldHaveProductSelected()"
    >
        <div class="col-xs-4">
            <label class="support-create-label required"> Product Name </label>
        </div>
        <div class="col-xs-8">
            <input
                class="form-control full-width product-name"
                type="text"
                [value]="
                    productForm?.controls.selectedProduct.value?.fqdn ||
                    productForm?.controls.selectedProduct.value?.name ||
                    productForm?.controls.selectedProduct.value?.hostname ||
                    productForm?.controls.selectedProduct.value
                "
                disabled="true"
            />
        </div>
    </div>
    <div class="row" *ngIf="shouldHaveProductSelected()">
        <div class="col-xs-4">
            <label class="support-create-label required"> Product Name </label>
        </div>
        <div class="col-xs-8 product-name-selector">
            <product-section-flasharrays
                *ngIf="productForm.controls.subCategory.value?.group === subCategoryGroup.FlashArray"
                formControlName="selectedProduct"
                [initialProduct]="initialProduct"
                [searchArrayName]="searchArrayName"
                [isBeingEdited]="isBeingEdited"
                [checkSupportContract]="checkSupportContract"
            ></product-section-flasharrays>
            <product-section-flashblades
                *ngIf="productForm.controls.subCategory.value?.group === subCategoryGroup.FlashBlade"
                formControlName="selectedProduct"
                [initialProduct]="initialProduct"
                [searchArrayName]="searchArrayName"
                [isBeingEdited]="isBeingEdited"
                [checkSupportContract]="checkSupportContract"
            ></product-section-flashblades>
            <product-section-px-clusters
                *ngIf="productForm?.controls.subCategory.value?.group === subCategoryGroup.Portworx"
                formControlName="selectedProduct"
                [initialProduct]="initialProduct"
                [searchArrayName]="searchArrayName"
                [isBeingEdited]="isBeingEdited"
            ></product-section-px-clusters>
            <product-section-disaster-recovery
                *ngIf="productForm?.controls.subCategory.value?.group === subCategoryGroup.DisasterRecovery"
                formControlName="selectedProduct"
                [initialProduct]="initialProduct"
                [searchArrayName]="searchArrayName"
                [isBeingEdited]="isBeingEdited"
                [checkSupportContract]="checkSupportContract"
            ></product-section-disaster-recovery>
            <product-section-licenses
                *ngIf="productForm.controls.subCategory.value?.group === subCategoryGroup.EvergreenOne"
                formControlName="selectedProduct"
                [initialProduct]="initialProduct"
                [searchArrayName]="searchArrayName"
                [isBeingEdited]="isBeingEdited"
                [checkSupportContract]="checkSupportContract"
            ></product-section-licenses>
        </div>
    </div>
</ng-container>
