import { DecimalPipe, formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    APPLIANCE_GENEALOGY_EVENTS,
    ApplianceGenealogyEventType,
    ArrayGenealogy,
    EolComponent,
    EverModernUpgrade,
    FeatureFlagDxpService,
    formatCapacityTB,
    GenealogyArrayCurrentState,
    GenealogyDispatchEvent,
    GenealogyHardwareUpgrade,
    getApplianceGenealogyEventColor,
    getApplianceGenealogyEventDisplayName,
    getApplianceGenealogyEventIconUrl,
    isPlaceholderFlashbladeInstallDate,
} from '@pure1/data';
import { PointLabelObject, PointOptionsObject, SeriesOptionsType } from 'highcharts/highstock';
import { Dictionary, sortBy } from 'lodash';
import moment from 'moment';
import { take } from 'rxjs';
import { FeatureNames } from '../../../model/FeatureNames';
import { UNKNOWN_COMPONENT_DEFAULT_DISPLAY } from '../../asset-management-view/asset-management-table-columns.component';

const EVENT_SIZE = 24;
const UPCOMING_SIZE = 20;
const STATE_SIZE = 12;

@Component({
    selector: 'appliance-genealogy-chart',
    templateUrl: './appliance-genealogy-chart.component.html',
})
export class ApplianceGenealogyChartComponent {
    @Input() readonly genealogies: ArrayGenealogy[];

    @Output() readonly loading = new EventEmitter<boolean>();

    genealogyChartConfig = {
        buildEvents: this.buildEvents.bind(this),
        buildTimeline: this.buildTimeline.bind(this),
        eventLabelFormatter: this.eventLabelFormatter,
        getEarliestDate: this.getEarliestDate,
        getDisplayName: getApplianceGenealogyEventDisplayName,
        getIconUrl: getApplianceGenealogyEventIconUrl,
        getSubscriptionName: this.getSubscriptionNameSelection,
        getTooltipContent: this.getTooltipContent.bind(this),
    };

    isFollowupEnabled = false;

    static decimalPipe: DecimalPipe = new DecimalPipe('en-US');

    readonly today = moment.utc();

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_FOLLOWUP)
            .pipe(take(1))
            .subscribe((feature: FeatureFlagStatus) => {
                this.isFollowupEnabled = feature?.enabled === true;
            });
    }

    onLoading(loading: boolean): void {
        this.loading.emit(loading);
    }

    getEarliestDate(genealogies: ArrayGenealogy[]): moment.Moment {
        let earliestDate = moment.utc();
        genealogies.forEach(genealogy => {
            if (earliestDate.isAfter(genealogy.applianceInstallation.date)) {
                earliestDate = genealogy.applianceInstallation.date.clone();
            }
        });

        return earliestDate;
    }

    buildEvents(genealogy: ArrayGenealogy, index: number): SeriesOptionsType {
        const events: SeriesOptionsType = {
            name: 'events',
            data: [],
            type: 'scatter',
            dataLabels: {
                y: 10,
            },
            showInNavigator: false,
            lineWidth: 0,
        };

        events.data.push({
            name: genealogy.name,
            x: genealogy.applianceInstallation.date.valueOf(),
            y: index,
            marker: {
                symbol: `url(${getApplianceGenealogyEventIconUrl('applianceInstall')})`,
                height: EVENT_SIZE,
                width: EVENT_SIZE,
            },
            custom: {
                eventType: 'applianceInstall',
                ...genealogy.applianceInstallation,
            },
        });

        genealogy.hardwareUpgrades?.forEach((hardwareUpgrade, hwIndex) => {
            const previousUpgrade =
                hwIndex > 0 ? genealogy.hardwareUpgrades[hwIndex - 1] : genealogy.applianceInstallation;

            const newEvent: PointOptionsObject = {
                name: genealogy.name,
                x: hardwareUpgrade.date.valueOf(),
                y: index,
                marker: {
                    symbol: `url(${getApplianceGenealogyEventIconUrl('hardwareUpgrade')})`,
                    height: EVENT_SIZE,
                    width: EVENT_SIZE,
                },
                custom: {
                    eventType: 'hardwareUpgrade',
                    ...hardwareUpgrade,
                    previous: {
                        ...previousUpgrade,
                    },
                },
            };

            events.data.push(newEvent);
        });

        genealogy.softwareUpgrades?.forEach((softwareUpgrade, swIndex) => {
            const newEvent: PointOptionsObject = {
                name: genealogy.name,
                x: softwareUpgrade.date.valueOf(),
                y: index,
                marker: {
                    symbol: `url(${getApplianceGenealogyEventIconUrl('softwareUpgrade')})`,
                    height: EVENT_SIZE,
                    width: EVENT_SIZE,
                },
                custom: {
                    eventType: 'softwareUpgrade',
                    version: softwareUpgrade.version,
                },
            };

            const previousUpgrade = swIndex > 0 ? genealogy.softwareUpgrades[swIndex - 1] : null;

            if (previousUpgrade) {
                newEvent.custom.previous = {
                    version: previousUpgrade.version,
                };
                events.data.push(newEvent);
            }
        });

        if (genealogy.upcomingRenewalEvent && this.isFollowupEnabled) {
            events.data.push({
                name: genealogy.name,
                x: genealogy.upcomingRenewalEvent.date.valueOf(),
                y: index,
                marker: {
                    symbol: `url(${getApplianceGenealogyEventIconUrl('upcomingApplianceRenewal')})`,
                    height: UPCOMING_SIZE,
                    width: UPCOMING_SIZE,
                },
                custom: {
                    eventType: 'upcomingApplianceRenewal',
                },
            });
        }

        if (genealogy.currentState && this.isFollowupEnabled) {
            events.data.push({
                name: genealogy.name,
                x: genealogy.currentState.date.valueOf(),
                y: index,
                marker: {
                    symbol: `url(${getApplianceGenealogyEventIconUrl('currentApplianceState')})`,
                    height: STATE_SIZE,
                    width: STATE_SIZE,
                },
                custom: {
                    eventType: 'currentApplianceState',
                    ...genealogy.currentState,
                },
            });
        }

        // Group by date and component label
        const componentsByDate = (genealogy.eolHardware?.eolComponents || []).reduce((byDate, component) => {
            // Skip null/empty eolDates
            if (!component.eolDate) {
                return byDate;
            }
            const dateKey = component.eolDate.format('YYYY-MM-DD');
            if (!byDate.has(dateKey)) {
                byDate.set(dateKey, []);
            }
            byDate.get(dateKey).push(component);
            return byDate;
        }, new Map<string, EolComponent[]>());
        for (const components of componentsByDate.values()) {
            const date = components[0].eolDate;
            const isUpcoming = date.isAfter(this.today);
            const eventType = isUpcoming ? 'eolUpcoming' : 'eol';
            events.data.push({
                name: genealogy.name,
                x: date.valueOf(),
                y: index,
                marker: {
                    symbol: `url(${getApplianceGenealogyEventIconUrl(eventType)})`,
                    height: EVENT_SIZE,
                    width: EVENT_SIZE,
                },
                custom: {
                    eventType: eventType,
                    components: components,
                },
            });
        }

        // Group by date
        const dispatchEventsByDate = (genealogy.dispatchEvents || []).reduce((byDate, dispatchEvent) => {
            // Skip null/empty deliveryDate
            if (!dispatchEvent.deliveryDate) {
                return byDate;
            }
            const dateKey = dispatchEvent.deliveryDate.format('YYYY-MM-DD');
            if (!byDate.has(dateKey)) {
                byDate.set(dateKey, []);
            }
            byDate.get(dateKey).push(dispatchEvent);
            return byDate;
        }, new Map<string, GenealogyDispatchEvent[]>());
        for (const dispatchEvents of dispatchEventsByDate.values()) {
            const date = dispatchEvents[0].deliveryDate;
            const isUpcoming = date.isAfter(this.today);
            const eventType = isUpcoming ? 'dispatchUpcoming' : 'dispatch';
            events.data.push({
                name: genealogy.name,
                x: date.valueOf(),
                y: index,
                marker: {
                    symbol: `url(${getApplianceGenealogyEventIconUrl(eventType)})`,
                    height: EVENT_SIZE,
                    width: EVENT_SIZE,
                },
                custom: {
                    eventType: eventType,
                    dispatchEvents: dispatchEvents,
                },
            });
        }

        if (genealogy.everModernUpgrade) {
            // assetAgeTargetDate is required as the upgrade date on the timeline
            const date = genealogy.everModernUpgrade.assetAgeTargetDate;
            if (date) {
                const isUpcoming = date.isAfter(this.today);
                const eventType = isUpcoming ? 'everModernUpgradeUpcoming' : 'everModernUpgrade';
                events.data.push({
                    name: genealogy.name,
                    x: date.valueOf(),
                    y: index,
                    marker: {
                        symbol: `url(${getApplianceGenealogyEventIconUrl(eventType)})`,
                        height: EVENT_SIZE,
                        width: EVENT_SIZE,
                    },
                    custom: {
                        eventType: eventType,
                        everModernUpgrade: genealogy.everModernUpgrade,
                    },
                });
            }
        }

        // Highcharts doesn't like it if the data isn't sorted
        events.data = sortBy(events.data, 'x');

        return events;
    }

    buildTimeline(genealogy: ArrayGenealogy, index: number): SeriesOptionsType {
        // Pick an end date for the chart because Highcharts doesn't handle unbounded lines.
        // The end date is the max event date, or today if all events are in the past.
        let endDate = moment.utc();
        if (
            genealogy.upcomingRenewalEvent?.date &&
            genealogy.upcomingRenewalEvent.date.isAfter(endDate) &&
            this.isFollowupEnabled
        ) {
            endDate = genealogy.upcomingRenewalEvent.date;
        }

        endDate = (genealogy.eolHardware?.eolComponents || []).reduce((maxDate, c) => {
            if (!c.eolDate) {
                return maxDate;
            }
            return moment.max(c.eolDate, maxDate);
        }, endDate);

        endDate = (genealogy.dispatchEvents || []).reduce((maxDate, d) => {
            if (!d.deliveryDate) {
                return maxDate;
            }
            return moment.max(d.deliveryDate, maxDate);
        }, endDate);

        if (genealogy.everModernUpgrade?.assetAgeTargetDate) {
            endDate = moment.max(genealogy.everModernUpgrade.assetAgeTargetDate, endDate);
        }

        return {
            data: [
                {
                    name: genealogy.name,
                    x: genealogy.applianceInstallation.date.valueOf(),
                    x2: endDate.valueOf(),
                    y: index,
                    color: getApplianceGenealogyEventColor('applianceInstall'),
                },
            ],
            pointWidth: 1,
            borderWidth: 0,
            grouping: false,
            type: 'xrange',
            dataLabels: {
                enabled: false,
            },
            showInNavigator: false,
        };
    }

    eventLabelFormatter(pointLabel: PointLabelObject, isTooltip: boolean): string {
        const event: Dictionary<any> = pointLabel.point.options.custom;
        let previousEvent = '';
        let currentEvent = '';
        switch (event.eventType as ApplianceGenealogyEventType) {
            case 'applianceInstall':
                currentEvent = `${event.model} - ${event.capacity != null ? event.capacity : event.raw}TB`;
                break;
            case 'hardwareUpgrade': {
                const capacity = event.raw != null ? event.raw : event.capacity;
                const previousCapacity = event.previous?.raw != null ? event.previous.raw : event.previous?.capacity;
                currentEvent = `${event.model} - ${formatNumber(capacity, 'en-US', '1.0-1')}TB`;
                previousEvent =
                    event.previous && isTooltip
                        ? `${event.previous.model} - ${formatNumber(previousCapacity, 'en-US', '1.0-1')}TB`
                        : '';
                break;
            }
            case 'softwareUpgrade':
                currentEvent = `${event.version}`;
                previousEvent = event.previous ? `${event.previous.version}` : '';
                break;
            case 'upcomingApplianceRenewal':
                currentEvent = 'Upcoming Renewal';
                break;
            case 'currentApplianceState':
                return null;
            case 'eol':
                currentEvent = 'EOL';
                break;
            case 'eolUpcoming':
                currentEvent = 'Upcoming EOL';
                break;
            case 'dispatch':
                currentEvent = 'Service Dispatch';
                break;
            case 'dispatchUpcoming':
                currentEvent = 'Upcoming Service Dispatch';
                break;
            case 'everModernUpgrade':
            case 'everModernUpgradeUpcoming':
                currentEvent = 'Ever Modern Upgrade';
                break;
            default:
                return '';
        }

        const eventDescription =
            previousEvent.length > 0
                ? `${previousEvent} <i class="fa fa-arrow-right"></i> ${currentEvent}`
                : `${currentEvent}`;

        return `
            <div class="event-description">${eventDescription}</div>
        `;
    }

    formatNumber(a: number): string {
        return ApplianceGenealogyChartComponent.decimalPipe.transform(a, '1.0-1');
    }

    getSubscriptionNameSelection(genealogies: ArrayGenealogy[]): string[] {
        return genealogies.map(genealogy => genealogy.name);
    }

    getTooltipContent(pointLabel: PointLabelObject): string {
        const pointMoment = moment.utc(pointLabel.x);
        const formattedDate = pointMoment.format('YYYY-MM-DD');
        const arrayName = pointLabel.key;
        const eventType = pointLabel.point.options.custom?.eventType as ApplianceGenealogyEventType;
        if (!eventType) {
            return '';
        }

        let eventDetails: string;

        switch (eventType) {
            case 'currentApplianceState': {
                const event = pointLabel.point.options.custom as GenealogyArrayCurrentState;
                eventDetails = `
                    <div class="current-state-info">
                        <h2 class="event-detail">Contract Type</h2>
                        <div class="event-detail">${event.contractType}</div>
                    </div>
                    <div class="current-state-info">
                        <h2 class="event-detail">Model</h2>
                        <div class="event-detail">${event.model}</div>
                    </div>
                    <div class="current-state-info">
                        <h2 class="event-detail">Purity Version</h2>
                        <div class="event-detail">${event.version}</div>
                    </div>`;

                if (event.usable != null || event.raw != null) {
                    if (event.raw) {
                        // Do not show raw if null/undefined/zero
                        eventDetails += `
                            <div class="current-state-info">
                                <h2 class="event-detail">Raw (TB)</h2>
                                <div class="event-detail">${this.formatNumber(event.raw)} TB</div>
                            </div>`;
                    }
                    if (event.usable) {
                        // Do not show usable if null/undefined/zero
                        eventDetails += `
                            <div class="current-state-info">
                                <h2 class="event-detail">Usable (TiB)</h2>
                                <div class="event-detail">${this.formatNumber(event.usable)} TiB${event.percentFull != null ? ' (' + event.percentFull + '%)' : ''}</div>
                            </div>`;
                    }
                } else if (event.estimated_capacity) {
                    eventDetails += `
                        <div class="current-state-info">
                            <h2 class="event-detail">Estimated (Raw)</h2>
                            <div class="event-detail">${formatCapacityTB(event.estimated_capacity)} TB${event.percentFull != null ? ' (' + event.percentFull + '%)' : ''}</div>
                        </div>`;
                } else if (event.capacity) {
                    eventDetails += `
                        <div class="current-state-info">
                            <h2 class="event-detail">Capacity (Raw)</h2>
                            <div class="event-detail">${formatCapacityTB(event.capacity)} TB${event.percentFull != null ? ' (' + event.percentFull + '%)' : ''}</div>
                        </div>`;
                }
                break;
            }
            case 'upcomingApplianceRenewal':
                eventDetails = `
                    <div class="event-info">
                        <img class="event-icon" src="${getApplianceGenealogyEventIconUrl('applianceRenewal')}" />
                        <div class="option-text">${getApplianceGenealogyEventDisplayName(eventType)}</div>
                    </div>`;
                break;
            case 'softwareUpgrade':
                eventDetails = `
                    <div class="event-info">
                        <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                        <div class="option-text">${getApplianceGenealogyEventDisplayName(eventType)}</div>
                        ${this.eventLabelFormatter(pointLabel, true)}
                    </div>`;
                break;
            case 'applianceInstall': {
                // Check if this is a FB and if we are using a placeholder install date
                //  Some FBs do not have an install date so we use this placeholder date range from
                //  when we started saving FB genealogy
                let eventDisplayName;
                if (
                    eventType === APPLIANCE_GENEALOGY_EVENTS[0] &&
                    pointLabel.point.options.custom?.model.startsWith('FB') &&
                    isPlaceholderFlashbladeInstallDate(pointMoment)
                ) {
                    eventDisplayName = 'Initial tracking date';
                } else {
                    eventDisplayName = getApplianceGenealogyEventDisplayName(eventType);
                }

                eventDetails = this.buildHardwareEvent(eventDisplayName, pointLabel, eventType);
                break;
            }
            case 'hardwareUpgrade': {
                eventDetails = this.buildHardwareEvent(
                    getApplianceGenealogyEventDisplayName(eventType),
                    pointLabel,
                    eventType,
                );
                break;
            }
            case 'eol':
            case 'eolUpcoming': {
                const components: EolComponent[] = pointLabel.point.options.custom.components;
                // Dedupe labels by creating a set and then converting it back into an array
                const labels = [...new Set<string>(components.map(c => c.label))];
                eventDetails = `
                    <div class="event-info event-info-header">
                        <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                        <div class="option-text">${getApplianceGenealogyEventDisplayName(eventType)}</div>
                    </div>
                    <table class="eol-components">
                        ${labels.map(label => '<tr><td class="text-left">' + (label || UNKNOWN_COMPONENT_DEFAULT_DISPLAY) + '</td></tr>').join('')}
                    </table>`;
                break;
            }
            case 'dispatch':
            case 'dispatchUpcoming': {
                const dispatchEvents: GenealogyDispatchEvent[] = pointLabel.point.options.custom.dispatchEvents;
                eventDetails = `
                    <div class="event-info event-info-header">
                        <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                        <div class="option-text">${getApplianceGenealogyEventDisplayName(eventType)}</div>
                    </div>
                    <div></div>
                    <table class="dispatch-events">
                        ${dispatchEvents
                            .map(
                                dispatchEvent => `<tr>
                            <td class="text-left">${dispatchEvent.serviceCategory}</td>
                            <td class="text-right">${dispatchEvent.targetModel ? dispatchEvent.targetModel.toUpperCase() : ''}</td></tr>`,
                            )
                            .join('')}
                    </table>`;
                break;
            }
            case 'everModernUpgrade':
            case 'everModernUpgradeUpcoming': {
                const everModernUpgrade: EverModernUpgrade = pointLabel.point.options.custom.everModernUpgrade;
                eventDetails = `
                    <div class="event-info event-info-header">
                        <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                        <div class="option-text">${getApplianceGenealogyEventDisplayName(eventType)}</div>
                    </div>`;
                if (everModernUpgrade.newHwType) {
                    eventDetails += `
                        <table class="ever-modern-upgrade">
                            <tr><td class="text-left">Controller Upgrade</td><td class="text-right">${everModernUpgrade.newHwType.toUpperCase()}</td></tr>
                        </table>`;
                }
                eventDetails += `<div class="recommended-action">${getEverModernUpgradeRecommendedAction(everModernUpgrade)}</div>`;
                break;
            }
            default:
                eventDetails = `
                    <div class="event-info">
                        <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                        <div class="option-text">${getApplianceGenealogyEventDisplayName(eventType)}</div>
                        ${this.eventLabelFormatter(pointLabel, true)}
                    </div>`;
        }

        return `
            <div class="genealogy-chart-tooltip appliance-genealogy-tooltip">
                <div class="tooltip-header">${arrayName}</div>
                <div class="tooltip-body">
                    <div class="date-info">${formattedDate}</div>
                    ${eventDetails}
                </div>
            </div>`;
    }

    buildHardwareEvent(
        eventDisplayName: string,
        pointLabel: PointLabelObject,
        eventType: ApplianceGenealogyEventType,
    ): string {
        const previousEvent = pointLabel.point.options.custom.previous as GenealogyHardwareUpgrade;
        const event = pointLabel.point.options.custom as GenealogyHardwareUpgrade;

        if (!event.usable && !event.estimated_capacity) {
            return `
                <div class="event-info">
                    <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                    <div class="option-text">${eventDisplayName}</div>
                    ${this.eventLabelFormatter(pointLabel, true)}
                </div>`;
        } else if (event.usable) {
            let modelLabel = event.model;
            let rawLabel = `${this.formatNumber(event.raw)}`;
            let usableLabel = `${this.formatNumber(event.usable)}`;

            if (previousEvent) {
                if (previousEvent.model !== event.model) {
                    modelLabel = `${previousEvent.model} <i class="fa fa-arrow-right"></i> ${modelLabel}`;
                }
                if (previousEvent.raw !== event.raw && previousEvent.raw !== null) {
                    rawLabel = `${this.formatNumber(previousEvent.raw)} <i class="fa fa-arrow-right"></i> ${rawLabel}`;
                } else if (previousEvent.estimated_capacity !== null) {
                    rawLabel = `${this.formatNumber(previousEvent.estimated_capacity)} <i class="fa fa-arrow-right"></i> ${rawLabel}`;
                }
                if (previousEvent.usable !== event.usable && previousEvent.usable !== null) {
                    usableLabel = `${this.formatNumber(previousEvent.usable)} <i class="fa fa-arrow-right"></i> ${usableLabel}`;
                }
            }

            return `
                <div class="event-info event-info-header">
                    <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                    <div class="option-text">${eventDisplayName}</div>
                    <div class="option-text">${modelLabel}</div>
                </div>
                <div class="event-info-line">
                    <h2 class="event-detail">Capacity</h2>
                </div>
                <div class="event-info-line">
                    <div class="event-detail">Raw (TB)</div>
                    <div class="event-detail">
                        ${rawLabel}
                    </div>
                </div>
                <div class="event-info-line">
                    <div class="event-detail">Usable (TiB)</div>
                    <div class="event-detail">
                        ${usableLabel}
                    </div>
                </div>`;
        } else {
            let modelLabel = event.model;
            let estimatedCapacity = `${this.formatNumber(event.estimated_capacity)}`;

            if (previousEvent) {
                if (previousEvent.model !== event.model) {
                    modelLabel = `${previousEvent.model} <i class="fa fa-arrow-right"></i> ${modelLabel}`;
                }
                if (previousEvent.raw !== null) {
                    estimatedCapacity = `${this.formatNumber(previousEvent.raw)} <i class="fa fa-arrow-right"></i> ${estimatedCapacity}`;
                } else if (previousEvent.estimated_capacity !== null) {
                    estimatedCapacity = `${this.formatNumber(previousEvent.estimated_capacity)} <i class="fa fa-arrow-right"></i> ${estimatedCapacity}`;
                }
            }

            return `
                <div class="event-info event-info-header">
                    <img class="event-icon" src="${getApplianceGenealogyEventIconUrl(eventType)}" />
                    <div class="option-text">${eventDisplayName}</div>
                    <div class="option-text">${modelLabel}</div>
                </div>
                <div class="event-info-line">
                    <h2 class="event-detail">Capacity</h2>
                </div>
                <div class="event-info-line">
                    <div class="event-detail">Estimated (TB)</div>
                    <div class="event-detail">
                        ${estimatedCapacity}
                    </div>
                </div>`;
        }
    }
}

export function getEverModernUpgradeRecommendedAction(emu: EverModernUpgrade): string {
    if (!emu) {
        return null;
    }
    if (emu.emuBookingStatus) {
        if (!emu.emuConfirmedShipDate) {
            return `Your Ever Modern Upgrade has been booked.`;
        } else if (!emu.psWithEmu) {
            return `Your Ever Modern Upgrade has been shipped on ${emu.emuConfirmedShipDate.format('YYYY-MM-DD')}.`;
        } else {
            return (
                `Your Ever Modern Upgrade has been shipped on ${emu.emuConfirmedShipDate.format('YYYY-MM-DD')}.` +
                ` Contact your Pure AE to schedule installation.`
            );
        }
    } else if (emu.foreverCommitmentCriterion && emu.assetAgeCriterion) {
        return (
            `Your controllers are eligible for an Ever Modern Upgrade.` +
            ` Please contact renewals@purestorage.com or your Partner to book your Ever Modern Upgrade.`
        );
    } else if (emu.foreverCommitmentCriterion && !emu.assetAgeCriterion) {
        return (
            `Your controllers will be eligible for an Ever Modern Upgrade on ${emu.assetAgeTargetDate.format('YYYY-MM-DD')}.` +
            ` Please contact your Pure AE or Partner if you want to explore alternative upgrade paths.`
        );
    } else if (emu.assetAgeCriterion) {
        return (
            `Your controllers will be eligible for an Ever Modern Upgrade` +
            ` if you renew your Forever subscription to ${emu.nextEvergreenDate.format('YYYY-MM-DD')}.` +
            ` Please contact renewals@purestorage.com or your Partner to renew your subscription and book your upgrade.`
        );
    } else {
        return (
            `Your controllers will be eligible for an Ever Modern upgrade on ${emu.assetAgeTargetDate.format('YYYY-MM-DD')}` +
            ` if you renew your subscription to ${emu.nextEvergreenDate.format('YYYY-MM-DD')}.` +
            ` Contact renewals@purestorage.com or your Partner about renewal,` +
            ` or your Pure AE or Partner about alternative upgrade paths.`
        );
    }
}
