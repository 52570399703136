import { Observable, Subject, BehaviorSubject, zip } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Collection, User, Resource, DataPage, QueryParams } from '@pure1/data';

import { Assignment } from '../user-management.interface';
import { takeUntil, tap, filter, map } from 'rxjs/operators';

const MAX_NESTED_STATES = 3; // likely won't need depth more than one or two

export enum UmStates {
    UM = 'um',
    Admin = 'um.admin',
    AdminEditUser = 'um.edit-user',
    Regular = 'um.regular',
}

@Injectable() // Not provided in root: only gets used as sandboxed instance
export class UserModalStateService implements OnDestroy {
    get state(): UmStates {
        return this.stateStack[0] || UmStates.UM;
    }
    set state(newState: UmStates) {
        this.stateStack.unshift(newState);
        if (this.stateStack.length > MAX_NESTED_STATES) {
            this.stateStack.pop(); // we drop any states that exceed maximum state stack depth
        }
    }
    userToEdit: User;
    assignments: Assignment[] = [];
    assignmentService: Collection<Assignment>;
    private destroy$ = new Subject<void>();
    private serviceCache = new Map<Collection<any>, BehaviorSubject<DataPage<any>>>();
    private closeFn: () => void;

    private stateStack: UmStates[] = [];

    private static applyPatch<T extends Resource>(updated: T[], dataPage: DataPage<T>): DataPage<T> {
        const cachedIds = dataPage.response.map(item => item.id);
        const updatedMap = new Map<string, T>(updated.map<[string, T]>(item => [item.id, item]));

        if (!Array.from(updatedMap.keys()).every(id => cachedIds.includes(id))) {
            // we expect every value to be in the cache, but if not, we'll pick it up on the next list refresh
            console.warn('cannot update value that is not cached');
        }
        dataPage.response = dataPage.response.map(item => (updatedMap.has(item.id) ? updatedMap.get(item.id) : item));

        return dataPage;
    }

    private static applyDelete<T extends Resource>(ids: string[], dataPage: DataPage<T>): DataPage<T> {
        const cachedIds = dataPage.response.map(item => item.id);
        if (!ids.every(id => cachedIds.includes(id))) {
            // we expect every value to be in the cache, but if not, we'll pick it up on the next list refresh
            console.warn('cannot delete value that is not cached');
        }
        dataPage.response = dataPage.response.filter(item => !ids.includes(item.id)); // remove all items with given ids
        dataPage.total = dataPage.total - ids.length; // subtract the number we've just removed from the total
        return dataPage;
    }

    private static applyCreate<T extends Resource>(item: T, dataPage: DataPage<T>): DataPage<T> {
        dataPage.response.push(item); // this works because we assume no sort is applied
        dataPage.total += 1; // increment the total by one, the newly created value
        return dataPage;
    }

    ngOnDestroy(): void {
        this.serviceCache.forEach(subject => {
            subject.complete();
        });
        this.destroy$.next();
        this.destroy$.complete();
    }

    listWithCache<T extends Resource>(service: Collection<T>): Observable<DataPage<T>> {
        if (!this.serviceCache.has(service)) {
            this.serviceCache.set(service, new BehaviorSubject<DataPage<T>>(null));
            service.list().pipe(takeUntil(this.destroy$)).subscribe(this.serviceCache.get(service));
        }
        return this.serviceCache.get(service).pipe(filter(value => !!value)); // supress the initial null value
    }

    updateWithCache<T extends Resource>(
        service: Collection<T>,
        properties: Partial<T>,
        ids: string[] | QueryParams,
    ): Observable<DataPage<T>> {
        const updateCache = (updated: DataPage<T>) => {
            // callback to update the cached version of the data as soon as the backend returns successfully
            if (this.serviceCache.has(service)) {
                const cachedDataPage = this.serviceCache.get(service).getValue();
                if (cachedDataPage && updated && updated.response.length > 0) {
                    this.serviceCache
                        .get(service)
                        .next(UserModalStateService.applyPatch(updated.response, cachedDataPage));
                }
            }
        };
        /* NOTE: if update is applied to multiple ids and the backend experiences partial failure (i.e., some items were
         * changed successfully while other items were not), then we need to invalidate the cache, unsubscribe, and resubscribe.
         * In this case, we only update a single item for each backend call, so there is no need for more advanced error
         * handling, but we will need to fix and test this if we want to generalize further.
         */
        return service.update(properties, ids).pipe(tap(updateCache));
    }

    createWithCache<T extends Resource>(service: Collection<T>, properties: Partial<T>): Observable<T> {
        const updateCache = (item: T) => {
            // callback to update the cached version of the data as soon as the backend returns successfully
            if (this.serviceCache.has(service)) {
                const cachedDataPage = this.serviceCache.get(service).getValue();
                if (cachedDataPage) {
                    this.serviceCache.get(service).next(UserModalStateService.applyCreate(item, cachedDataPage));
                }
            }
        };
        return service.create(properties).pipe(tap(updateCache));
    }

    deleteWithCache<T extends Resource>(service: Collection<T>, ids: string[]): Observable<void> {
        const updateCache = () => {
            // callback to update the cached version of the data as soon as the backend returns successfully
            if (this.serviceCache.has(service)) {
                const cachedDataPage = this.serviceCache.get(service).getValue();
                if (cachedDataPage) {
                    this.serviceCache.get(service).next(UserModalStateService.applyDelete(ids, cachedDataPage));
                }
            }
        };
        return zip(...ids.map(id => service.delete(id))).pipe(
            tap(updateCache),
            map(() => {}),
        );
    }

    registerModalClose(closeFn: () => void): void {
        this.closeFn = closeFn;
    }

    editUser(user: User): void {
        this.userToEdit = user;
        this.state = UmStates.AdminEditUser;
    }

    previousState(): void {
        // return to the most recent state (at the top of the stack)
        this.stateStack.shift();
        if (this.stateStack.length === 0 && this.closeFn) {
            this.closeFn();
        }
    }
}
