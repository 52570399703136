export class ServiceCatalogCTA {
    text: string;
    actionType: string;
    actionName: string;
    class: string;
    dropdownOptions: ServiceCatalogCTA[];

    constructor(json: any) {
        this.text = json.text;
        this.actionType = json.action_type;
        this.actionName = json.action_name;
        this.class = json.class;
        this.dropdownOptions = this.parseAllCtas(json.dropdown_options);
    }

    private parseAllCtas(json: any[]): ServiceCatalogCTA[] {
        if (json?.length > 0) {
            return json.map(ctasJson => new ServiceCatalogCTA(ctasJson));
        }

        return [];
    }
}
