import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TableColumn } from '../../subscription-page-table/subscription-page-table.component';

export interface SelectableColumnGroup<T> {
    [columnGroupName: string]: SelectableColumn<T>[];
}

export interface SelectableColumn<T> {
    name: string;
    column: TableColumn<T>;
    readonly: boolean;
    selected: boolean;
}

type CheckBoxStatus = 'checked' | 'indeterminate' | 'unchecked';

@Component({
    selector: 'subscription-column-selector',
    templateUrl: 'subscription-column-selector.component.html',
})
export class SubscriptionColumnSelectorComponent<T> implements OnChanges {
    @Input() readonly columnOptionGroups: SelectableColumnGroup<T>; // We're currently modifying the underneath objects

    @Output() readonly columnOptionGroupsChange = new EventEmitter<SelectableColumnGroup<T>>();

    selectAllStatuses = new Map<string, CheckBoxStatus>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.columnOptionGroups && this.columnOptionGroups) {
            this.selectAllStatuses.clear();
            this.updateSelectAllCheckboxStatus();
        }
    }

    onOptionClick(column: SelectableColumn<T>, groupName: string): void {
        if (column.readonly) {
            return;
        }

        column.selected = !column.selected;
        this.updateSelectAllCheckboxStatus(groupName);
        this.columnOptionGroupsChange.emit(this.columnOptionGroups);
    }

    onSelectAllClick(group: KeyValue<string, SelectableColumn<T>[]>): void {
        const groupName = group.key;
        const columns = group.value;
        const selectedStatus = this.selectAllStatuses.get(groupName);
        if (selectedStatus === 'checked' || selectedStatus === 'indeterminate') {
            columns.forEach(column => {
                if (!column.readonly) {
                    column.selected = false;
                }
            });
            this.selectAllStatuses.set(groupName, 'unchecked');
        } else {
            columns.forEach(column => {
                if (!column.readonly) {
                    column.selected = true;
                }
            });
            this.selectAllStatuses.set(groupName, 'checked');
        }
        this.columnOptionGroupsChange.emit(this.columnOptionGroups);
    }

    sortOrder(a: KeyValue<string, SelectableColumn<T>[]>, b: KeyValue<string, SelectableColumn<T>[]>): number {
        // Return original order. By default, keyValuePipe sorts alphabetically in ascending order
        return 0;
    }

    private updateSelectAllCheckboxStatus(groupName?: string): void {
        if (groupName) {
            this.updateSelectAllStatusForGroup(groupName);
        } else {
            Object.keys(this.columnOptionGroups).forEach(groupName => {
                this.updateSelectAllStatusForGroup(groupName);
            });
        }
    }

    private updateSelectAllStatusForGroup(groupName: string): void {
        const columns = this.columnOptionGroups[groupName];
        if (columns.every(column => column.selected)) {
            this.selectAllStatuses.set(groupName, 'checked');
        } else if (columns.some(column => !column.readonly && column.selected)) {
            this.selectAllStatuses.set(groupName, 'indeterminate');
        } else {
            this.selectAllStatuses.set(groupName, 'unchecked');
        }
    }
}
