import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { ServiceStatus } from '../models/service-status';

@Injectable({ providedIn: 'root' })
export class ServiceStatusService extends GenericService<ServiceStatus> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ServiceStatus,
            endpoint: '/rest/v1/service-status',
        });
    }
}
