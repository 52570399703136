import { Component, Output, EventEmitter } from '@angular/core';

import { BaseBanner } from '../base-banner';

@Component({
    selector: 'safemode-banner',
    templateUrl: 'safemode-banner.component.html',
})
export class SafeModeBannerComponent extends BaseBanner {
    @Output() readonly dismissNotification = new EventEmitter<void>();
}
