import moment from 'moment';
import { cloneDeep } from 'lodash';

import { IAggregationMethod, MetricsHistoryMetricNames } from '../services/metrics-history.service';
import { Subject, takeUntil } from 'rxjs';
import { smartTimer } from '@pstg/smart-timer';

interface IInternalValue<V> {
    expirationTime: number;
    value: V;
}

interface ICache<K, V> {
    destroy(): void;
    getValue(key: K): V;
    put(key: K, value: V): void;
}

export interface ITimeSeriesCacheKey {
    metricNames: MetricsHistoryMetricNames[];
    entityId: string;
    startTime: moment.Moment;
    endTime: moment.Moment;
    aggregationMethod: IAggregationMethod;
}

export class TimeSeriesCache<V> implements ICache<ITimeSeriesCacheKey, V> {
    private map: { [key: string]: IInternalValue<V> } = {};
    private readonly stopTimer$ = new Subject<void>();
    private timerIsRunning = false;

    constructor(private ttl: moment.Duration) {}

    put(key: ITimeSeriesCacheKey, value: V): void {
        this.map[this.getInternalKey(key)] = this.getInternalValue(value);

        // Start the clean-up so that the cache does not grow too big
        if (!this.timerIsRunning) {
            let intervalMs = 2 * this.ttl.asMilliseconds();
            intervalMs = Math.max(intervalMs, moment.duration(5, 'seconds').asMilliseconds()); // Constrain cleanup frequency to a reasonable range
            intervalMs = Math.min(intervalMs, moment.duration(1, 'minutes').asMilliseconds());
            this.timerIsRunning = true;
            smartTimer(intervalMs, intervalMs, intervalMs)
                .pipe(takeUntil(this.stopTimer$))
                .subscribe({
                    next: () => this.cleanCache(),
                    complete: () => (this.timerIsRunning = false),
                });
        }
    }

    getValue(key: ITimeSeriesCacheKey): V {
        const internalKey = this.getInternalKey(key);
        const internalValue = this.map[internalKey];
        if (internalValue) {
            if (internalValue.expirationTime >= moment().valueOf()) {
                return cloneDeep(internalValue.value);
            } else {
                delete this.map[internalKey];
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Clears all the cached items
     */
    clear(): void {
        this.map = {};
    }

    /**
     * Completely destroys the cache. Any attempt to use the cache after this will result in an exception.
     */
    destroy(): void {
        this.stopTimer$.next();
        this.map = null;
    }

    private getInternalKey(key: ITimeSeriesCacheKey): string {
        return `${key.entityId}_${key.startTime.valueOf()}_${key.endTime.valueOf()}_${key.aggregationMethod}_${key.metricNames.join('_')}`;
    }

    private getInternalValue(value: V): IInternalValue<V> {
        return {
            expirationTime: moment().add(this.ttl).valueOf(),
            value: cloneDeep(value),
        };
    }

    /**
     * Cleans out stale items from the cache to free up memory
     */
    private cleanCache(): void {
        const now = moment().valueOf();
        Object.keys(this.map).forEach(key => {
            if (this.map[key].expirationTime <= now) {
                delete this.map[key];
            }
        });

        // If the cache is empty, stop the clean-up task.
        if (Object.keys(this.map).length === 0) {
            this.stopTimer$.next();
        }
    }
}
