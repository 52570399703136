<div [ngSwitch]="degree || 'UNKNOWN'">
    <div *ngSwitchCase="'GOOD'" class="placement-good">
        <pureui-svg
            svg="checked-badge.svg"
            height="20"
            [ngbTooltip]="isLoadTrueMax ? trueMaxRecommendationWarning : 'Optimal placement'"
            placement="right"
            container="body"
        ></pureui-svg>
    </div>
    <div *ngSwitchCase="'GOOD_WITH_WARNINGS'" class="placement-good-with-warnings">
        <placement-warnings-popover [warningPairs]="warnings"></placement-warnings-popover>
    </div>
    <div *ngSwitchCase="'OK'" class="placement-ok">
        <span
            [ngbTooltip]="isLoadTrueMax ? trueMaxRecommendationWarning : 'Non-optimal placement, but acceptable'"
            placement="right"
            container="body"
            >—</span
        >
    </div>
    <div *ngSwitchCase="'BAD'" class="placement-bad">
        <pureui-svg
            svg="crossed-yellow-badge.svg"
            height="20"
            [ngbTooltip]="isLoadTrueMax ? trueMaxRecommendationWarning : 'Selected workload may not fit on this array'"
            placement="right"
            container="body"
        ></pureui-svg>
    </div>
    <div *ngSwitchCase="'ERROR'" class="placement-error" [ngClass]="{ 'not-ready': !areRecommendationsReady }">
        <pureui-svg
            svg="not-applicable-base.svg"
            height="20"
            [ngbTooltip]="
                isLoadTrueMax
                    ? trueMaxRecommendationWarning
                    : 'There is not enough data to reliably project array metrics'
            "
            placement="right"
            container="body"
        ></pureui-svg>
    </div>
    <div *ngSwitchDefault class="placement-undefined" [ngClass]="{ 'not-ready': !areRecommendationsReady }"> -- </div>
</div>
