import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwitchUserModalComponent } from '../switch-user-modal/switch-user-modal.component';
import { UserModalStateService } from '../user-modal/user-modal-state.service';
import { CurrentUser } from '@pure1/data';

@Component({
    selector: 'title-with-switch',
    templateUrl: 'title-with-switch.component.html',
})
export class TitleWithSwitchComponent {
    @Input() readonly currentUser: CurrentUser;

    constructor(
        private ngbModal: NgbModal,
        private umStateService: UserModalStateService,
    ) {}

    switchUser(): void {
        const modalInstance = this.ngbModal.open(SwitchUserModalComponent);
        modalInstance.componentInstance.currentUser = this.currentUser;
    }

    clickUserName(): void {
        this.umStateService.editUser(this.currentUser);
    }
}
