import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'safemode-pgroup',
    templateUrl: './safemode-pgroup.component.html',
})
export class SafemodePgroupComponent implements OnInit {
    @Input() readonly applianceName: string;
    @Input() readonly pgroupId: string;
    podName: string;
    pgroupName: string;

    ngOnInit(): void {
        const pgroupName = this.pgroupId.substring(this.pgroupId.indexOf(':') + 1);
        const array = pgroupName.split('::');
        this.pgroupName = array.pop();
        if (array.length > 0) {
            this.podName = array.pop();
        }
    }
}
