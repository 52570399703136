import moment from 'moment';
import { Subject, ReplaySubject, Observable, of, forkJoin } from 'rxjs';
import { take, takeUntil, tap, mapTo, switchMap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Resource, updateFlashBladeModel } from '@pure1/data';

import {
    SimulationStep,
    SimulationStepService,
    TrainingWindowActionDetails,
    UpgradeControllerModelActionDetails,
    UpgradeHardwareCapacityActionDetails,
    ActionType,
    CopyMigrateVolumesActionDetails,
    CopyMigrateArrayActionDetails,
    ExpandLicenseReserveLevelActionDetails,
    SimulateFlashBladeModelActionDetails,
    UpgradeFlashBladeHardwareCapacityActionDetails,
    ScaleArrayActionDetails,
    PlaceWorkloadActionDetails,
} from '../../services/simulation-step.service';
import { ForecastSimulation, SimulationService } from '../services/simulation.service';
import { HttpCacheService } from '../../services/http-cache.service';

const DEFAULT_SIMULATION_NAME = 'default_simulation';
const DEFAULT_TRAINING_WINDOW_IN_DAYS = 30;

const DEFAULT_TRAINING_WINDOW_IN_MILLIS = moment.duration(DEFAULT_TRAINING_WINDOW_IN_DAYS, 'days').asMilliseconds();

const UNIQUE_ACTION_TYPES = Object.freeze(<ActionType[]>[
    'training_window_capacity',
    'training_window_load',
    'upgrade_controller_model',
    'upgrade_hardware_capacity',
    'simulate_flashblade_model',
    'upgrade_flashblade_hardware_capacity',
    'apply_safe_mode',
    'expand_license_reserve_level',
]);

const WORKLOAD_ACTIONS_TYPES = Object.freeze(<ActionType[]>[
    'migrate_volumes',
    'migrate_array',
    'scale_array',
    'scale_volumes',
    'place_workloads',
]);

const COPY_ACTION_TYPES = Object.freeze(<ActionType[]>['copy_array', 'copy_volumes']);

/** Union of the ActionDetail types for the COPY_ACTION_TYPES  */
type COPY_ACTION_DETAILS = CopyMigrateArrayActionDetails | CopyMigrateVolumesActionDetails;

export interface ArraySimulations {
    array: Resource;
    simulationSteps: SimulationStep[];
}

export interface LicenseSimulations {
    license: Resource;
    simulationSteps: SimulationStep[];
}

/**
 * How this update was triggered. If saveSpeculative, can assume that the actual simulation steps being used to compute
 * the projections have not changed, so we do not need to update the values.
 */
export type SimulationsEventUpdateType = 'normal' | 'saveSpeculative';

export interface ArraySimulationsEvent {
    updateType: SimulationsEventUpdateType;
    value: Map<string, ArraySimulations>;
}

export interface LicenseSimulationsEvent {
    updateType: SimulationsEventUpdateType;
    value: Map<string, LicenseSimulations>;
}

/**
 * Returns an object containing just the properties needed for a Resource.
 */
export function stripResource(resource: Resource): Resource {
    return {
        id: resource.id,
        name: resource.name,
    };
}

function getUniqueArrayIdActionPairs(steps: Partial<SimulationStep>[]): { action: ActionType; arrayId: string }[] {
    // Build a map of unique actiontypes, keyed by arrayId
    const actionsByArray = new Map<string, Set<ActionType>>();
    steps.forEach(step => {
        step.arrays?.forEach(array => {
            if (!actionsByArray.has(array.id)) {
                actionsByArray.set(array.id, new Set<ActionType>());
            }
            actionsByArray.get(array.id).add(step.action);
        });
    });

    // Flatten into an array
    const ret: { action: ActionType; arrayId: string }[] = [];
    actionsByArray.forEach((actions, arrayId) => {
        actions.forEach(action => {
            ret.push({ arrayId, action });
        });
    });

    return ret;
}

/**
 * BEWARE: A lot of the methods in this service that return observables are "hot"! That is, they will execute whether
 * you call subscribe on the returned observable or not. The returned observable is then only used for controlling logic flow.
 * This is terrible practice, but it's part of a slow migrate process. It will be cleaned up at some point...
 */
@Injectable({ providedIn: 'root' })
export class SimulationsService implements OnDestroy {
    readonly arraysSimulations$ = new ReplaySubject<ArraySimulationsEvent>(1);
    readonly licenseSimulations$ = new ReplaySubject<LicenseSimulationsEvent>(1);
    readonly simulation$ = new ReplaySubject<ForecastSimulation>(1);
    readonly capacitySimulation$ = new Subject<string>(); // specific array's capacity simulation is changed.
    readonly loadSimulation$ = new Subject<string>(); // specific array's load simulation is changed.
    readonly capacityTrainingWindow$ = new Subject<string>(); // specific array's capacity training window is changed.
    readonly loadTrainingWindow$ = new Subject<string>(); // specific array's load training window is changed.

    /** Notifies when a simulation step is deleted (from a user event only; backend can do whatever it wants) */
    readonly simulationStepDeleted$ = new Subject<SimulationStep>();
    readonly simulationStepsUpdated$ = new ReplaySubject<void>(1);

    private _simulationSteps: SimulationStep[] = []; // Cache of simulation steps saved on the server for use with speculative steps
    private _speculativeSimulationSteps: Partial<SimulationStep>[] = []; // Steps that exist on front end only
    private _trainingWindow: number = null;
    private _simulation: ForecastSimulation = null; // TODO: Eventually, remove this and use simulation$ everywhere instead so we don't have to worry about race conditions on loading

    private readonly _arraysSimulations = new Map<string, ArraySimulations>();
    private readonly _licensesSimulations = new Map<string, LicenseSimulations>();
    private readonly destroy$ = new Subject<void>();

    constructor(
        private simulationService: SimulationService,
        private simulationStepService: SimulationStepService,
        private httpCacheService: HttpCacheService,
    ) {
        this.initializeSimulation();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    get trainingWindow(): number {
        return this._trainingWindow;
    }

    get speculativeSimulationSteps(): Partial<SimulationStep>[] {
        return this._speculativeSimulationSteps;
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     *
     * Updates the training window
     * @returns An observable that emits once when the window change is complete
     */
    setTrainingWindow(trainingWindow: number): Observable<void> {
        this._trainingWindow = trainingWindow;
        return this.updateSimulationTrainingWindow();
    }

    hasSimulation(arrayId: string): boolean {
        return this.hasLoadChangingSimulation(arrayId) || this.hasCapacityChangingSimulation(arrayId);
    }

    hasLoadChangingSimulation(arrayId: string): boolean {
        return (
            this.getUpgradeControllerModel(arrayId) != null ||
            this.getSimulateFlashBladeModel(arrayId) != null ||
            this.hasWorkloadChangingSimulation(arrayId)
        );
    }

    hasCapacityChangingSimulation(arrayId: string): boolean {
        return (
            this.getUpgradeHardwareCapacity(arrayId) != null ||
            this.getUpgradeFlashBladeHardwareCapacity(arrayId) != null ||
            this.hasWorkloadChangingSimulation(arrayId) ||
            this.hasSafeModeSimulation(arrayId)
        );
    }

    hasWorkloadChangingSimulation(arrayId: string): boolean {
        if (!this._arraysSimulations.has(arrayId)) {
            return false;
        }

        // It's either a workload changing step OR it is a copy-to target (because there's no change on copy-to source)
        return this._arraysSimulations.get(arrayId).simulationSteps.some(step => {
            const isWorkloadAction = WORKLOAD_ACTIONS_TYPES.includes(step.action);
            const isCopyToTarget =
                COPY_ACTION_TYPES.includes(step.action) &&
                (<COPY_ACTION_DETAILS>step.action_details).target.id === arrayId;
            return isWorkloadAction || isCopyToTarget;
        });
    }

    hasRemovedAllWorkload(arrayId: string): boolean {
        if (!this._arraysSimulations.has(arrayId)) {
            return false;
        }
        const simulationSteps = this._arraysSimulations.get(arrayId).simulationSteps;
        // Check if the array has been removed from all workload and not added any new workload
        return (
            simulationSteps.some(step => {
                const hasMigratedAllWorkload =
                    step.action === 'migrate_array' &&
                    (<CopyMigrateArrayActionDetails>step.action_details).source.id === arrayId;
                const hasScaleDownAllWorkload =
                    step.action === 'scale_array' &&
                    (<ScaleArrayActionDetails>step.action_details).scaling_factor === 0;
                return hasMigratedAllWorkload || hasScaleDownAllWorkload;
            }) &&
            !simulationSteps.some(step => {
                const hasCopyMigrateArrayWorkload =
                    (step.action === 'migrate_array' || step.action === 'copy_array') &&
                    (<CopyMigrateArrayActionDetails>step.action_details).target.id === arrayId;
                const hasCopyMigrateVolumeWorkload =
                    (step.action === 'migrate_volumes' || step.action === 'copy_volumes') &&
                    (<CopyMigrateVolumesActionDetails>step.action_details).target.id === arrayId;
                const hasPhantomWorkloads =
                    step.action === 'place_workloads' &&
                    (<PlaceWorkloadActionDetails>step.action_details).target.id === arrayId;
                return hasCopyMigrateArrayWorkload || hasCopyMigrateVolumeWorkload || hasPhantomWorkloads;
            })
        );
    }

    hasScalingSimulation(arrayId: string): boolean {
        return this.findArraySimulationStep(arrayId, 'scale_volumes') != null;
    }

    hasSafeModeSimulation(arrayId: string): boolean {
        return this.findArraySimulationStep(arrayId, 'apply_safe_mode') != null;
    }

    arrayHasSpeculativeSteps(arrayId: string): boolean {
        return this._speculativeSimulationSteps?.some(step => step.arrays?.some(array => array.id === arrayId));
    }

    licenseHasSpeculativeSteps(licenseId: string): boolean {
        return this._speculativeSimulationSteps?.some(step => step.licenses?.some(license => license.id === licenseId));
    }

    getSpeculativeStepsForArray(arrayId: string): Partial<SimulationStep>[] {
        return this._speculativeSimulationSteps.filter(step => step.arrays?.some(array => array.id === arrayId));
    }

    getSpeculativeStepsForLicense(licenseId: string): Partial<SimulationStep>[] {
        return this._speculativeSimulationSteps.filter(step =>
            step.licenses?.some(license => license.id === licenseId),
        );
    }

    getLoadTrainingWindow(arrayId: string): ITrainingTimes {
        const step = this.findArraySimulationStep(arrayId, 'training_window_load');
        if (!step) {
            return null;
        }
        const details = step.action_details as TrainingWindowActionDetails;
        return { startTime: details.start, endTime: details.end };
    }

    getCapacityTrainingWindow(arrayId: string): ITrainingTimes {
        const step = this.findArraySimulationStep(arrayId, 'training_window_capacity');
        if (!step) {
            return null;
        }
        const details = <TrainingWindowActionDetails>step.action_details;
        return { startTime: details.start, endTime: details.end };
    }

    getUpgradeControllerModel(arrayId: string): string {
        const step = this.findArraySimulationStep(arrayId, 'upgrade_controller_model');
        if (!step) {
            return null;
        }
        const details = <UpgradeControllerModelActionDetails>step.action_details;
        return details.controller_model;
    }

    getSimulateFlashBladeModel(arrayId: string): string {
        const step = this.findArraySimulationStep(arrayId, 'simulate_flashblade_model');
        if (!step) {
            return null;
        }
        const details = <SimulateFlashBladeModelActionDetails>step.action_details;
        return updateFlashBladeModel(details.model);
    }

    getUpgradeHardwareCapacity(arrayId: string): UpgradeHardwareCapacityActionDetails {
        const step = this.findArraySimulationStep(arrayId, 'upgrade_hardware_capacity');
        if (!step) {
            return null;
        }
        return <UpgradeHardwareCapacityActionDetails>step.action_details;
    }

    getUpgradeFlashBladeHardwareCapacity(arrayId: string): UpgradeFlashBladeHardwareCapacityActionDetails {
        const step = this.findArraySimulationStep(arrayId, 'upgrade_flashblade_hardware_capacity');
        if (!step) {
            return null;
        }
        return <UpgradeFlashBladeHardwareCapacityActionDetails>step.action_details;
    }

    getSimulationStepsForArray(arrayId: string): SimulationStep[] {
        if (this._arraysSimulations.has(arrayId)) {
            return this._arraysSimulations.get(arrayId).simulationSteps;
        } else {
            return [];
        }
    }

    getSimulationStepsForLicense(licenseId: string): SimulationStep[] {
        if (this._licensesSimulations.has(licenseId)) {
            return this._licensesSimulations.get(licenseId).simulationSteps;
        } else {
            return [];
        }
    }

    getExpandLicenseReserveLevel(licenseId: string): ExpandLicenseReserveLevelActionDetails {
        const step = this.findLicenseSimulationStep(licenseId, 'expand_license_reserve_level');
        if (!step) {
            return null;
        }
        return <ExpandLicenseReserveLevelActionDetails>step.action_details;
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     */
    deleteLoadTrainingWindow(arrayId: string): Observable<void> {
        return this.modifySimulation(() => this.deleteArraySimulationStepByType(arrayId, 'training_window_load'));
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     */
    deleteCapacityTrainingWindow(arrayId: string): Observable<void> {
        return this.modifySimulation(() => this.deleteArraySimulationStepByType(arrayId, 'training_window_capacity'));
    }

    deleteUpgradeControllerModel(arrayId: string): void {
        this.modifySimulation(() => this.deleteAllArraySimulationStepsByType(arrayId, 'upgrade_controller_model'));
    }

    deleteUpgradeHardwareCapacity(arrayId: string): void {
        this.modifySimulation(() => this.deleteAllArraySimulationStepsByType(arrayId, 'upgrade_hardware_capacity'));
    }

    deleteSimulationStep(step: SimulationStep): void {
        this.modifySimulation(() => this._deleteSimulationStep(step));
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     */
    deleteAllSteps(): Observable<void> {
        return this.modifySimulation(() => this.clearSimulation());
    }

    deleteUpgradeFlashBladeHardwareCapacity(arrayId: string): void {
        this.modifySimulation(() =>
            this.deleteAllArraySimulationStepsByType(arrayId, 'upgrade_flashblade_hardware_capacity'),
        );
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     */
    refreshSimulationSteps(updateType: SimulationsEventUpdateType = 'normal'): Observable<void> {
        const done$ = new Subject<void>();
        this.simulationStepService
            .list()
            .pipe(
                tap(data => {
                    this.updateSimulationSteps(data.response);
                    this.arraysSimulations$.next({ updateType: updateType, value: this._arraysSimulations });
                    this.licenseSimulations$.next({ updateType: updateType, value: this._licensesSimulations });
                }),
                mapTo(null),
                take(1),
                takeUntil(this.destroy$),
            )
            .subscribe(done$);
        return done$;
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     *
     * Sends a request to the back end to add each of the given steps
     */
    applySteps(...steps: Partial<SimulationStep>[]): Observable<void> {
        return this.saveSimulationSteps(
            steps.filter(step => step),
            true,
        );
    }

    /**
     * Speculative simulations are used in the wizard. They are steps that are not saved on the server. To accomplish this
     *  we take the existing steps from the back end and concat the new steps on to those steps. Then tell the UI
     *  to refresh as if it got new steps from the back end. The UI then fetches new data from the back end
     *  if needed, the load/capacity projection is also changed to use the new speculative steps if present.
     *
     * @param steps Speculative simulation steps to use to get new forecast values. Set to null/empty to remove speculative steps.
     */
    setSpeculativeSteps(steps: Partial<SimulationStep>[]): void {
        if (this._speculativeSimulationSteps.length === 0 && (steps || []).length === 0) {
            return; // Don't go from empty to empty
        }

        this._speculativeSimulationSteps = (steps || []).slice();
        this.updateSimulationSteps(); // Combine the existing steps with the new speculative steps
        this.arraysSimulations$.next({ updateType: 'normal', value: this._arraysSimulations }); // tell the UI to refresh using the new steps
    }

    setLicenseSpeculativeSteps(steps: Partial<SimulationStep>[]): void {
        if (this._speculativeSimulationSteps.length === 0 && (steps || []).length === 0) {
            return; // Don't go from empty to empty
        }

        this._speculativeSimulationSteps = (steps || []).slice();
        this.updateSimulationSteps(); // Combine the existing steps with the new speculative steps
        this.licenseSimulations$.next({ updateType: 'normal', value: this._licensesSimulations }); // tell the UI to refresh using the new steps
    }

    /**
     * For speculative controller and capacity steps
     * @param arrayId ArrayId for the speculative step, used to find an existing step
     * @param step The new step to use
     */
    replaceSpeculativeStep(arrayId: string, step: Partial<SimulationStep>): void {
        if (step == null) {
            return;
        }

        const index = this._speculativeSimulationSteps.indexOf(this.findArraySimulationStep(arrayId, step?.action));
        if (index >= 0) {
            this._speculativeSimulationSteps[index] = step;
        } else {
            this._speculativeSimulationSteps.push(step);
        }

        this.updateSimulationSteps(); // Combine the existing steps with the new speculative steps
        this.arraysSimulations$.next({ updateType: 'normal', value: this._arraysSimulations }); // tell the UI to refresh using the new steps
    }

    /**
     * For speculative controller and capacity steps
     * @param action The step action type to remove
     */
    removeSpeculativeStepByAction(
        action:
            | 'upgrade_controller_model'
            | 'upgrade_hardware_capacity'
            | 'simulate_flashblade_model'
            | 'upgrade_flashblade_hardware_capacity',
    ): void {
        // Filter out the speculative step for the given ActionType
        this._speculativeSimulationSteps = this._speculativeSimulationSteps.filter(step => step.action !== action);

        this.updateSimulationSteps(); // Combine the existing steps with the newly trimmed speculative steps
        this.arraysSimulations$.next({ updateType: 'normal', value: this._arraysSimulations }); // tell the UI to refresh using the new steps
    }

    /**
     * For speculative license expand reserve level steps
     */
    removeLicenseSpeculativeSteps(): void {
        this._speculativeSimulationSteps = this._speculativeSimulationSteps.filter(
            step => step.action !== 'expand_license_reserve_level',
        );
        this.updateSimulationSteps(); // Combine the existing steps with the newly trimmed speculative steps
        this.licenseSimulations$.next({ updateType: 'normal', value: this._licensesSimulations }); // tell the UI to refresh using the new steps
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     *
     * Persists the speculative steps set by setSpeculativeSteps().
     */
    applySpeculativeSteps(): Observable<void> {
        if (this._speculativeSimulationSteps.length === 0) {
            return of(void 0);
        }

        // Clear our the speculative steps
        const newSteps = this._speculativeSimulationSteps;
        this._speculativeSimulationSteps = [];

        // Rebuild the steps without the speculative ones. That way, when the update returns, we aren't double-counting the speculative ones
        // and just have the persisted ones returned from the server.
        // We'll notify all consumers after making this update.
        // The notification will come at the end of saveSimulationSteps().
        this.updateSimulationSteps();

        // Apply the speculative steps
        return this.saveSimulationSteps(newSteps, true);
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     *
     * Persists the speculative steps for flasharray controller or capacity upgrades set by setSpeculativeSteps().
     */
    applyHardwareSpeculativeSteps(arrayId: string): Observable<void> {
        if (this._speculativeSimulationSteps.length === 0) {
            return of(void 0);
        }

        // Save the speculative controller and capacity steps
        const controllerStep = this.findSpeculativeArraySimulationStep(arrayId, 'upgrade_controller_model');
        const capacityStep = this.findSpeculativeArraySimulationStep(arrayId, 'upgrade_hardware_capacity');

        // Filter out the hardware speculative steps
        this._speculativeSimulationSteps = this._speculativeSimulationSteps.filter(
            step => step.action !== 'upgrade_controller_model' && step.action !== 'upgrade_hardware_capacity',
        );

        // Rebuild the steps without the speculative ones. That way, when the update returns, we aren't double-counting the speculative ones
        // and just have the persisted ones returned from the server.
        // Note that this change likely won't get reflected outside this service since we don't notify any consumers after making this update.
        // The notification will come at the end of saveSimulationSteps().
        this.updateSimulationSteps();

        // Apply the speculative steps
        return this.applySteps(controllerStep, capacityStep);
    }

    /**
     * NOTE: The returned observable is hot! (See service comments for details)
     *
     * Persists the speculative steps for flashblade model or capacity upgrades set by setSpeculativeSteps().
     */
    applyFlashBladeHardwareSpeculativeSteps(arrayId: string): Observable<void> {
        if (this._speculativeSimulationSteps.length === 0) {
            return of(void 0);
        }

        // Save the speculative controller and capacity steps
        const modelStep = this.findSpeculativeArraySimulationStep(arrayId, 'simulate_flashblade_model');
        const capacityStep = this.findSpeculativeArraySimulationStep(arrayId, 'upgrade_flashblade_hardware_capacity');

        // Filter out the hardware speculative steps
        this._speculativeSimulationSteps = this._speculativeSimulationSteps.filter(
            step =>
                step.action !== 'simulate_flashblade_model' && step.action !== 'upgrade_flashblade_hardware_capacity',
        );

        // Rebuild the steps without the speculative ones. That way, when the update returns, we aren't double-counting the speculative ones
        // and just have the persisted ones returned from the server.
        // Note that this change likely won't get reflected outside this service since we don't notify any consumers after making this update.
        // The notification will come at the end of saveSimulationSteps().
        this.updateSimulationSteps();

        // Apply the speculative steps
        return this.applySteps(modelStep, capacityStep);
    }

    notifyActionlessSimulationChange(arrayIds: string[]): void {
        // this is similar to the private notifyArraySimulationChange(), but is intended to be called when there is no
        // simulation step or action change (i.e. phantom workload definition change)
        arrayIds.forEach(arrayId => {
            this.loadSimulation$.next(arrayId);
            this.capacitySimulation$.next(arrayId);
        });
        // the below comment is used elsewhere and is left verbatim for better "find-and-replace" in the future:
        // Even though we call notifyArraySimulationChange(), we still need to call arraysSimulations$ since some stuff does not key off the notifyArraySimulationChange() events (like forecasted-array-value). May want to fix this later.
        this.arraysSimulations$.next({ updateType: 'normal', value: this._arraysSimulations });
    }

    getAllSavedSimulationStepsByActionType(action: ActionType): SimulationStep[] {
        return this._simulationSteps.filter(simulationStep => simulationStep?.action === action);
    }

    /**
     * Finds the single SimulationStep of the given type on an array, or null if none.
     */
    findArraySimulationStep(arrayId: string, action: ActionType): SimulationStep | null {
        const arraySims = this._arraysSimulations.get(arrayId);
        if (!arraySims) {
            return null;
        }
        // If we have speculative controller or hardware steps they will override the current steps
        //  Return the last one in the list which will be the speculative one
        if (
            this.arrayHasSpeculativeSteps(arrayId) &&
            (action === 'upgrade_controller_model' ||
                action === 'upgrade_hardware_capacity' ||
                action === 'simulate_flashblade_model' ||
                action === 'upgrade_flashblade_hardware_capacity')
        ) {
            const steps = arraySims && arraySims.simulationSteps.filter(step => step.action === action);
            if (steps && steps.length > 0) {
                return steps[steps.length - 1]; // speculative steps are always at the end
            }
        }
        return arraySims && arraySims.simulationSteps.find(step => step.action === action);
    }

    findLicenseSimulationStep(licenseId: string, action: ActionType): SimulationStep | null {
        const licenseSims = this._licensesSimulations.get(licenseId);
        if (!licenseSims) {
            return null;
        }
        // If we have speculative license expansion steps they will override the current step
        //  Return the last one in the list which will be the speculative one
        if (this.licenseHasSpeculativeSteps(licenseId) && action === 'expand_license_reserve_level') {
            const steps = licenseSims && licenseSims.simulationSteps.filter(step => step.action === action);
            if (steps && steps.length > 0) {
                return steps[steps.length - 1]; // speculative steps are always at the end
            }
        }
        return licenseSims && licenseSims.simulationSteps.find(step => step.action === action);
    }

    /**
     * Finds the speculative SimulationStep of the given type on an array, or null if none.
     */
    findSpeculativeArraySimulationStep(arrayId: string, action: ActionType): Partial<SimulationStep> | null {
        return this._speculativeSimulationSteps.find(step => step.action === action);
    }

    getAllWorkloadMovementSimulationSourceArrayIds(arrayId: string): string[] {
        const steps = this.getSimulationStepsForArray(arrayId);

        const sourceIds: string[] = [];
        steps.forEach(step => {
            if (step.action_details?.hasOwnProperty('source') && step.action_details['source'] !== arrayId) {
                sourceIds.push(step.action_details['source'].id);
            }
        });

        return sourceIds;
    }

    /**
     * Finds all SimulationSteps of the given type on an array.
     */
    private findAllArraySimulationSteps(arrayId: string, action: ActionType): SimulationStep[] {
        const arraySims = this._arraysSimulations.get(arrayId);

        if (!arraySims) {
            return [];
        }
        return arraySims.simulationSteps.filter(step => step.action === action);
    }

    private updateSimulationTrainingWindow(): Observable<void> {
        if (
            !this._simulation ||
            !this._trainingWindow ||
            this._simulation.training_time_period === this._trainingWindow
        ) {
            return of(void 0);
        }

        const params = {
            name: DEFAULT_SIMULATION_NAME,
            training_time_period: this._trainingWindow,
        };

        const done$ = new Subject<void>();
        this.simulationService
            .update(params)
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this._simulation.training_time_period = this._trainingWindow;
                    this.httpCacheService.clear(
                        key => key.includes('/forecast/arrays/load') || key.includes('/forecast/arrays/capacity'),
                    );
                    done$.next();
                    this.simulation$.next(this._simulation);
                },
                err => {
                    done$.error(err);
                },
                () => {
                    done$.complete();
                },
            );

        return done$;
    }

    /**
     * Performs the specified action, and returns a separate observable to emits when the action completes.
     * @param action The action to perform
     */
    private modifySimulation(action: () => Observable<any>): Observable<void> {
        const done$ = new Subject<void>();
        this.simulation$
            .pipe(
                take(1),
                switchMap(() => action()),
            )
            .subscribe(done$);
        return done$;
    }

    private _deleteSimulationStep(stepToDelete: SimulationStep): Observable<any> {
        // if we call deletion of steps without an ID, we will automatically remove all persisted simulation steps. Ooops!
        // so let's avoid that
        // A step without an ID is a speculative step, so instead of deleting on the backend, we will remove that
        // speculative step from memory in the frontend only.
        if (!stepToDelete.id) {
            // remove the speculative simulation step
            this._speculativeSimulationSteps = this._speculativeSimulationSteps.filter(step => step !== stepToDelete);
            // and update all array Simulations so it won't get pulled later
            this.updateSimulationSteps();
            return of(void 0);
        }
        // otherwise, we have a real step with a real ID, and everything else makes sense
        // We delete the step and also clear load and capacity projections for any arrays that were affected by the step
        return this.simulationStepService.delete(stepToDelete.id, stepToDelete.arrays || stepToDelete.licenses).pipe(
            take(1),
            takeUntil(this.destroy$),
            tap(() => {
                // Note: some steps can affect multiple arrays. Go through every array to make sure we get each instance.
                for (const arrayId of Array.from(this._arraysSimulations.keys())) {
                    // Use Array.from() to clone the collection since we'll potentially mutate (delete from) _arraySimulations
                    const steps = this._arraysSimulations.get(arrayId).simulationSteps;
                    const index = steps.findIndex(step => step.id === stepToDelete.id);
                    if (index !== -1) {
                        const deleted = steps.splice(index, 1)[0];
                        if (steps.length === 0) {
                            // If we deleted the last step, remove the array completely
                            this._arraysSimulations.delete(arrayId);
                        }
                        this.notifyArraySimulationChange(deleted.action, arrayId);
                    }
                }

                for (const licenseId of Array.from(this._licensesSimulations.keys())) {
                    // Use Array.from() to clone the collection since we'll potentially mutate (delete from) _licensesSimulations
                    const steps = this._licensesSimulations.get(licenseId).simulationSteps;
                    const index = steps.findIndex(step => step.id === stepToDelete.id);
                    if (index !== -1) {
                        if (steps.length === 0) {
                            // If we deleted the last step, remove the license completely
                            this._licensesSimulations.delete(licenseId);
                        }
                    }
                }

                this.simulationStepDeleted$.next(stepToDelete);
                // Even though we call notifyArraySimulationChange(), we still need to call refreshSimulationSteps() to trigger arraysSimulations$ and licensesSimulations$ since some stuff does not key off the notifyArraySimulationChange() events (like forecasted-array-value). May want to fix this later.
                this.refreshSimulationSteps();
            }),
        );
    }

    private deleteAllArraySimulationStepsByType(arrayId: string, actionType: ActionType): Observable<any> {
        const steps = this.findAllArraySimulationSteps(arrayId, actionType);

        return forkJoin(steps.map(this._deleteSimulationStep.bind(this)));
    }

    private deleteArraySimulationStepByType(arrayId: string, actionType: ActionType): Observable<any> {
        const step = this.findArraySimulationStep(arrayId, actionType);
        if (step) {
            return this._deleteSimulationStep(step);
        } else {
            return of(void 0);
        }
    }

    /**
     * Saves the given steps to the backend
     * @param stepsToUpdate The steps to be updated (either as a new step, or as an update to an existing step if appropriate)
     * @param notifyChange If true (which it usually should be), notify other streams about the changes that occurred.
     */
    private saveSimulationSteps(stepsToUpdate: Partial<SimulationStep>[], notifyChange: boolean): Observable<void> {
        if (!stepsToUpdate || stepsToUpdate.length === 0) {
            return of(void 0);
        }

        // TODO: Support being able to just post multiple steps at once, instead of a separate call for each. Backend change may be required.

        const updateStep = (simulation: ForecastSimulation, stepToUpdate: Partial<SimulationStep>): Observable<any> => {
            const request: Partial<SimulationStep> = {
                name: 'simulation step',
                simulation: simulation,
                arrays: stepToUpdate.arrays?.map(arr => stripResource(arr)),
                licenses: stepToUpdate.licenses?.map(license => stripResource(license)),
                action: stepToUpdate.action,
                action_details: stepToUpdate.action_details,
            };

            const sourceArray = request.arrays ? request.arrays[0] : null;
            const license = request.licenses ? request.licenses[0] : null;

            // If there can only be one type of this step per array/license, and this step already exists, update
            // the existing step instead of creating a new one
            if (this._licensesSimulations.has(license?.id) && this.actionTypeIsUnique(request.action)) {
                const existingStep = this.findLicenseSimulationStep(license.id, request.action);
                if (existingStep) {
                    existingStep.action_details = request.action_details;
                    return this.simulationStepService.update(request, [existingStep.id]);
                }
            } else if (this._arraysSimulations.has(sourceArray?.id) && this.actionTypeIsUnique(request.action)) {
                const existingStep = this.findArraySimulationStep(sourceArray.id, request.action);
                if (existingStep) {
                    existingStep.action_details = request.action_details;
                    return this.simulationStepService.update(request, [existingStep.id]);
                }
            }

            // Otherwise, create a new step
            return this.simulationStepService.create(request).pipe(
                tap((step: SimulationStep) => {
                    if (step.licenses) {
                        // create license simulation step
                        step.licenses.forEach(affectedLicense => {
                            if (!this._licensesSimulations.has(affectedLicense.id)) {
                                this._licensesSimulations.set(affectedLicense.id, {
                                    license: affectedLicense,
                                    simulationSteps: [],
                                });
                            }
                            this._licensesSimulations.get(affectedLicense.id).simulationSteps.push(step);
                        });
                    } else {
                        // create array simulation step
                        step.arrays.forEach(affectedArray => {
                            if (!this._arraysSimulations.has(affectedArray.id)) {
                                this._arraysSimulations.set(affectedArray.id, {
                                    array: affectedArray,
                                    simulationSteps: [],
                                });
                            }
                            this._arraysSimulations.get(affectedArray.id).simulationSteps.push(step);
                        });
                    }
                }),
            );
        };

        const updateType: SimulationsEventUpdateType = notifyChange ? 'normal' : 'saveSpeculative';
        const done$ = new Subject<void>();
        this.simulation$
            .pipe(
                take(1), // Only want one ForecastSimulation
                switchMap(simulation => {
                    // Update each step (in parallel), and wait for them all to return
                    return forkJoin(stepsToUpdate.map(step => updateStep(simulation, step)));
                }),
                switchMap(() => {
                    // Refresh the steps from the backend. While there isn't actually a need to wait for this today,
                    // it makes a simpler mental model if we assume for the updates we're about to do that we have the latest steps already.
                    // This will also notify consumers that the steps have changed.
                    return this.refreshSimulationSteps(updateType);
                }),
                tap(() => {
                    // If desired, send notifications about what has changed. This will allow consumers to update accordingly
                    // (eg to fetch new load projection values).
                    if (notifyChange) {
                        getUniqueArrayIdActionPairs(stepsToUpdate).forEach(change => {
                            this.notifyArraySimulationChange(change.action, change.arrayId);
                        });
                        // Even though we call notifyArraySimulationChange(), we still need to call arraysSimulations$ since some stuff does not key off the notifyArraySimulationChange() events (like forecasted-array-value). May want to fix this later.
                        this.arraysSimulations$.next({ updateType: updateType, value: this._arraysSimulations });
                    }
                }),
            )
            .subscribe(done$);
        return done$.asObservable();
    }

    private notifyArraySimulationChange(actionType: ActionType, arrayId: string): void {
        switch (actionType) {
            case 'upgrade_hardware_capacity':
            case 'upgrade_flashblade_hardware_capacity':
            case 'apply_safe_mode':
                this.capacitySimulation$.next(arrayId);
                break;
            case 'upgrade_controller_model':
            case 'simulate_flashblade_model':
                this.loadSimulation$.next(arrayId);
                break;
            case 'training_window_capacity':
                this.capacityTrainingWindow$.next(arrayId);
                break;
            case 'training_window_load':
                this.loadTrainingWindow$.next(arrayId);
                break;
            case 'scale_volumes':
            case 'scale_array':
            case 'copy_volumes':
            case 'copy_array':
            case 'migrate_volumes':
            case 'migrate_array':
            case 'place_workloads':
                this.loadSimulation$.next(arrayId);
                this.capacitySimulation$.next(arrayId);
                break;
            default:
                console.error(`unhandled actionType: ${actionType} in notifyArraySimulationChange`);
        }
    }

    private createSimulation(): Observable<ForecastSimulation> {
        const params = {
            name: DEFAULT_SIMULATION_NAME,
            training_time_period: this._trainingWindow || DEFAULT_TRAINING_WINDOW_IN_MILLIS,
        };

        return this.simulationService.create(params).pipe(take(1), takeUntil(this.destroy$));
    }

    private clearSimulation(): Observable<void> {
        // we delete all simulation steps by passing in an empty ID (i.e. don't filter the DELETE operation by ID)
        // and we also clear all load and capacity projections from the cache
        // (this could be improved in the future to only clear the projections that are affected by the deleted steps)
        return this.simulationStepService.delete('', null).pipe(
            take(1),
            takeUntil(this.destroy$),
            tap(() => {
                // We won't create a new simulation since we aren't deleting the simulation, just clearing
                // all simulation steps and phantom arrays
                this.refreshSimulationSteps(); // Refresh the list
            }),
        );
    }

    /**
     * Replaces the _arraysSimulations with the given steps. This method will cache the steps
     *  for use with speculative simulations
     */
    private updateSimulationSteps(simulationSteps?: SimulationStep[]): void {
        if (simulationSteps) {
            this._simulationSteps = simulationSteps;
        }

        this._arraysSimulations.clear();
        this._licensesSimulations.clear();

        []
            .concat(this._simulationSteps)
            .concat(this._speculativeSimulationSteps)
            .sort((s1, s2) => s1.created - s2.created)
            .forEach(simulationStep => {
                if (simulationStep.licenses) {
                    simulationStep.licenses.forEach(license => {
                        if (!this._licensesSimulations.has(license.id)) {
                            this._licensesSimulations.set(license.id, { license: license, simulationSteps: [] });
                        }
                        const steps = this._licensesSimulations.get(license.id).simulationSteps;
                        steps.push(simulationStep);
                    });
                } else {
                    simulationStep.arrays.forEach(array => {
                        if (!this._arraysSimulations.has(array.id)) {
                            this._arraysSimulations.set(array.id, { array, simulationSteps: [] });
                        }
                        const steps = this._arraysSimulations.get(array.id).simulationSteps;
                        steps.push(simulationStep);
                    });
                }
            });
        this.simulationStepsUpdated$.next();
    }

    /**
     * If this is an actionType of which there can only be one of per array/license.
     * Eg, you can only have one controller upgrade or one license expansion.
     */
    private actionTypeIsUnique(actionType: ActionType): boolean {
        return UNIQUE_ACTION_TYPES.includes(actionType);
    }

    private initializeSimulation(): void {
        this.simulationService
            .list()
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe(data => {
                // for now there should be only one or none simulation
                if (data.total === 1) {
                    this._simulation = data.response[0];
                    this.simulation$.next(this._simulation);
                    this.updateSimulationTrainingWindow();
                    this.refreshSimulationSteps();
                } else {
                    this.createSimulation().subscribe(simulation => {
                        this._simulation = simulation;
                        this.simulation$.next(this._simulation);
                        this.updateSimulationTrainingWindow();
                        this.simulationStepsUpdated$.next();
                    });
                }
            });
    }
}
