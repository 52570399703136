import { Component, Input } from '@angular/core';

import { LicenseType } from '@pure1/data';

@Component({
    selector: 'license-usage',
    templateUrl: './license-usage.component.html',
})
export class LicenseUsageComponent {
    @Input() readonly isSimulation: boolean = false;
    @Input() readonly useFormatTiBPipe: boolean = true;
    @Input() readonly fixUnit: string;
    @Input() readonly reservedAmount: number;
    @Input() readonly currentAmount: number;
    @Input() readonly licenseType: LicenseType;
}
