import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    OnChanges,
} from '@angular/core';
import {
    AxisLabelsFormatterContextObject,
    Chart,
    DataLabelsOptions,
    GanttChart,
    Options,
    PointLabelObject,
    PointOptionsObject,
    Series,
    SeriesOptionsType,
    SeriesScatterOptions,
    SVGElement,
} from 'highcharts/highstock';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

import { AppService } from '../../app/app.service';
import { getSvgUrl } from 'core/src/ui/components/pure-svg.component';
import { GenealogyEventType, Resource } from '@pure1/data';

const ROW_HEIGHT = 80;
const NAVIGATOR_HEIGHT = 40;
const NAVIGATOR_SPACING = 20;
const NAVIGATOR_MASK_HEIGHT = 16;
const X_AXIS_LABEL_HEIGHT = 46;
const BACKGROUND_Z_INDEX = 0;
const TODAY_X_OFFSET = 4;
const TODAY_Y_OFFSET = 10;
const TODAY_Z_INDEX = 2;
const PURITY_SWITCH_Z_INDEX = 1;
const PURITY_SWITCH_DATE = '2023-10-26';
export const TODAY_COLOR = '#fe5000'; // Orange
export const HARDWARE_SWITCH_COLOR = '#1E71CB'; // Blue

export interface GenealogyChartConfig<T extends Resource> {
    getEarliestDate: (genealogies: T[]) => moment.Moment;
    buildTimeline: (genealogy: T, index: number, maxDate: moment.Moment) => SeriesOptionsType;
    buildEvents: (genealogy: T, index: number) => SeriesOptionsType;
    eventLabelFormatter: (pointLabel: PointLabelObject, isTooltip: boolean) => string;
    getDisplayName: (genealogyEvent: GenealogyEventType) => string;
    getIconUrl: (genealogyEvent: GenealogyEventType) => string;
    getSubscriptionName?: (genealogies: T[], selectedSubscriptionName?: string) => string[];
    getTooltipContent: (pointLabel: PointLabelObject) => string;
    yAxisLabelsFormatter?: (ctx: AxisLabelsFormatterContextObject) => string;
}

@Component({
    selector: 'genealogy-chart',
    templateUrl: 'genealogy-chart.component.html',
})
export class GenealogyChartComponent<T extends Resource & { currentSubscriptionName?: string }>
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() readonly genealogies: T[];
    @Input() readonly genealogyChartConfig: GenealogyChartConfig<T>;
    @Input() readonly selectedSubscriptionName: string;

    @Output() readonly loading = new EventEmitter<boolean>();

    @ViewChild('chartContainer') chartContainer: ElementRef;

    chart: Chart;
    maxDate: moment.Moment;

    todayRef: SVGElement;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private appService: AppService,
        protected ngZone: NgZone,
    ) {}

    ngAfterViewInit(): void {
        this.appService.navSideBarToggled.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.chart) {
                this.chart.reflow();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.genealogies || changes.selectedSubscriptionName) && this.genealogies) {
            // Clear chart
            this.chart?.destroy();
            this.chart = this.createChart(this.getChartOptions());

            if (this.genealogies.length > 0) {
                const timelines = this.buildGenealogyTimelines();
                timelines.forEach(genealogy => this.chart.addSeries(genealogy, false));
                const earliestDate = this.genealogyChartConfig.getEarliestDate(this.genealogies);

                // If earliest event is too close to the year boundary, add some padding
                if (earliestDate.diff(earliestDate.clone().startOf('year'), 'months') < 2) {
                    earliestDate.subtract(1, 'year');
                }
                earliestDate.startOf('year');

                const chartHeight = ROW_HEIGHT * this.genealogies.length;
                const xAxis = this.chart.xAxis[0];
                const zoomMax = moment.utc().endOf('year');
                if (this.maxDate.isAfter(zoomMax)) {
                    xAxis.setExtremes(earliestDate.valueOf(), zoomMax.valueOf(), false);
                }

                this.chart.update(
                    {
                        chart: {
                            scrollablePlotArea: {
                                minHeight: chartHeight + X_AXIS_LABEL_HEIGHT + NAVIGATOR_HEIGHT + NAVIGATOR_SPACING,
                                opacity: 1,
                            },
                        },
                        xAxis: {
                            min: earliestDate.valueOf(),
                            max: this.maxDate.valueOf(),
                            plotBands: [
                                {
                                    color: '#f8f8f8',
                                    from: 0,
                                    to: moment.utc().valueOf(),
                                    zIndex: BACKGROUND_Z_INDEX,
                                },
                                {
                                    color: 'rgba(255, 255, 255, 0.5)',
                                    from: moment.utc().valueOf(),
                                    to: this.maxDate.valueOf(),
                                    zIndex: BACKGROUND_Z_INDEX,
                                },
                            ],
                            plotLines: [
                                {
                                    color: TODAY_COLOR,
                                    width: 1,
                                    value: moment.utc().valueOf(),
                                    zIndex: TODAY_Z_INDEX,
                                },
                                {
                                    color: HARDWARE_SWITCH_COLOR,
                                    width: 1,
                                    value: moment(PURITY_SWITCH_DATE).utc().valueOf(),
                                    zIndex: PURITY_SWITCH_Z_INDEX,
                                },
                            ],
                        },
                        navigator: {
                            xAxis: {
                                min: earliestDate.valueOf(),
                                max: this.maxDate.valueOf(),
                            },
                        },
                        yAxis: {
                            categories: this.genealogyChartConfig.getSubscriptionName(
                                this.genealogies,
                                this.selectedSubscriptionName,
                            ),
                        },
                    },
                    false,
                );
                this.chart.redraw();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
        }
        this.destroy$.next();
        this.destroy$.unsubscribe();
        this.todayRef?.destroy();
    }

    private buildGenealogyTimelines(): SeriesOptionsType[] {
        this.maxDate = null;
        const seriesEvents = this.genealogies.map((genealogy, index) => {
            const events = this.genealogyChartConfig.buildEvents(genealogy, index) as SeriesScatterOptions;
            const eventData = events.data;
            const lastEvent = eventData[eventData.length - 1] as PointOptionsObject;
            const lastEventDate = moment.utc(lastEvent.x);
            if (!this.maxDate || lastEventDate.isAfter(this.maxDate)) {
                this.maxDate = lastEventDate;
            }
            return events;
        });

        // If last event is too close to the year boundary, add some padding
        if (this.maxDate.clone().endOf('year').diff(this.maxDate, 'months') < 2) {
            this.maxDate.add(1, 'year');
        }
        const fourYearsFromNow = moment.utc().add(4, 'year');
        if (fourYearsFromNow.isAfter(this.maxDate)) {
            this.maxDate = fourYearsFromNow;
        }
        this.maxDate.endOf('year');

        const seriesTimelines = this.genealogies.map((genealogy, index) => {
            return this.genealogyChartConfig.buildTimeline(genealogy, index, this.maxDate);
        });

        return [...seriesTimelines, ...seriesEvents];
    }

    private getChartOptions(): Options {
        const handleSvg = `url(${getSvgUrl('zoom-handle.svg')})`;
        const _this = this;

        return {
            chart: {
                type: 'gantt',
                renderTo: this.chartContainer.nativeElement,
                backgroundColor: '#f8f8f8',
                plotBorderWidth: 1,
                borderWidth: 1,
                borderColor: '#e6e6e6',
                spacing: [0, 0, NAVIGATOR_SPACING, 0],
                animation: false,
                events: {
                    render: function () {
                        const chart = this;
                        const xPosition = chart.xAxis[0].toPixels(moment.utc().valueOf(), false);
                        if (_this.todayRef) {
                            _this.todayRef.destroy();
                        }
                        if (!_this.genealogies || _this.genealogies.length === 0) {
                            return;
                        }
                        _this.todayRef = chart.renderer
                            .text('Today', xPosition + TODAY_X_OFFSET, chart.plotTop + TODAY_Y_OFFSET)
                            .attr({
                                zIndex: TODAY_Z_INDEX,
                            })
                            .css({
                                color: TODAY_COLOR,
                                fontSize: '12px',
                                fontWeight: 'bold',
                            })
                            .add();
                    },
                },
            },
            time: {
                useUTC: true,
            },
            navigator: {
                enabled: true,
                handles: {
                    height: NAVIGATOR_HEIGHT,
                    width: 20,
                    symbols: [handleSvg, handleSvg],
                },
                height: NAVIGATOR_MASK_HEIGHT,
                margin: NAVIGATOR_HEIGHT - NAVIGATOR_MASK_HEIGHT,
                maskFill: 'rgba(207, 232, 252, 0.4)',
                maskInside: true,
                outlineWidth: 0,
                xAxis: {
                    gridLineWidth: 0,
                    labels: {
                        enabled: false,
                    },
                    width: '98%', // Width and left add spacing around the navigator
                    left: '1%', // Center navigator after reducing width
                },
            },
            scrollbar: {
                enabled: true,
                height: 0,
            },
            legend: {
                enabled: false,
            },
            xAxis: [
                {
                    crosshair: false,
                    labels: {
                        enabled: true,
                    },
                    type: 'datetime',
                    units: [['year', [1]]],
                    gridLineWidth: 1,
                    endOnTick: false,
                    minRange: moment.duration(1, 'year').asMilliseconds(),
                },
            ],
            yAxis: {
                crosshair: false,
                gridLineWidth: 0,
                uniqueNames: true,
                type: 'category',
                staticScale: ROW_HEIGHT,
                labels: {
                    align: 'left',
                    style: {
                        color: '#292929',
                        padding: '0 0 0 25px',
                    },
                    useHTML: true,
                    format: '{text}',
                    reserveSpace: true,
                    formatter: this.genealogyChartConfig?.yAxisLabelsFormatter,
                },
            },
            tooltip: {
                enabled: true,
                borderWidth: 1,
                hideDelay: 25,
                useHTML: true,
                outside: true,
                formatter: function (this: PointLabelObject): string {
                    return _this.genealogyChartConfig.getTooltipContent(this);
                },
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    stickyTracking: false,
                    marker: {
                        enabled: true,
                    },
                    dataLabels: {
                        enabled: true,
                        inside: false,
                        useHTML: true,
                        allowOverlap: false,
                        verticalAlign: 'top',
                        backgroundColor: 'white',
                        shadow: {
                            offsetX: 0,
                            offsetY: 2,
                            width: 4,
                            color: 'rgba(0, 0, 0, 0.08)',
                        },
                        formatter: function (this: PointLabelObject, options: DataLabelsOptions): string {
                            return _this.genealogyChartConfig.eventLabelFormatter(this, false);
                        },
                    },
                },
            },
        };
    }

    private createChart(options: Options): Chart {
        let chart: Chart;
        this.ngZone.runOutsideAngular(() => {
            chart = new GanttChart(options);
        });
        return chart;
    }
}
