import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'safemode-intro',
    templateUrl: './safemode-intro.component.html',
})
export class SafemodeIntroComponent {
    @Input() readonly isOrgCompliant: boolean = false;
    @Input() readonly loading: boolean = true;
    @Input() readonly hasSNOWContact: boolean = true;
    @Output() readonly proceed = new EventEmitter();

    constructor() {}
}
