import { Pipe, PipeTransform } from '@angular/core';
import { Params } from '@angular/router';
import { ProgramType, SubscriptionAsset } from '@pure1/data';
import { SUBSCRIPTION_ENTITY, NAME_KEY, ASSET_APPLIANCE_ENTITY, ARRAY_NAME_KEY } from '../../redux/actions';

@Pipe({
    name: 'assetManagementUrlParams',
})
export class AssetManagementUrlParamsPipe implements PipeTransform {
    transform(asset: SubscriptionAsset): Params {
        switch (asset.subscription.programType) {
            case ProgramType.EVERGREEN_ONE: {
                const subscriptionFilter = {
                    entity: SUBSCRIPTION_ENTITY,
                    key: NAME_KEY,
                    value: asset.subscription.name,
                };
                return { filter: JSON.stringify([subscriptionFilter]) };
            }
            case ProgramType.EVERGREEN_FOREVER:
            case ProgramType.EVERGREEN_FOUNDATION: {
                const applianceFilter = {
                    entity: ASSET_APPLIANCE_ENTITY,
                    key: ARRAY_NAME_KEY,
                    value: asset.name,
                };
                return { filter: JSON.stringify([applianceFilter]) };
            }
            default:
                return undefined;
        }
    }
}
