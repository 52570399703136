import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Contact } from '../support.interface';
import { ampli } from 'core/src/ampli';

const KEYSTROKE_DEBOUNCE_TIME = 200;

@Component({
    selector: 'search-contact',
    templateUrl: 'search-contact.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchContactComponent),
            multi: true,
        },
    ],
})
export class SearchContactComponent implements ControlValueAccessor {
    @ViewChild('typeContactSearchInstance') readonly typeContactSearchInstance: NgbTypeahead;

    readonly ampli = ampli;

    @Input() readonly contacts: Contact[];

    clickContact$ = new Subject<string>();
    value: any;
    searchString = '';
    contactForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {
        // We need to use the form to correctly propagate value changes to the parent component. The ngbTypeahead we
        // use is not propagating the non-matching strings correctly, so the external validation is not triggered
        // correctly.
        this.contactForm = this.fb.group({
            contact: [null, this.validateContact.bind(this)],
        });
    }

    onChange = (_: any) => {};

    onTouched = (_: any) => {};

    formatter = (x: { name: string }) => x.name;

    filterFunction: (contact: Contact, term: string) => boolean = (contact, term) => {
        if (!contact.name) {
            return false;
        }
        if (!contact.email) {
            return contact.name.toLowerCase().includes(term.toLowerCase());
        }
        return (
            contact.name.toLowerCase().includes(term.toLowerCase()) ||
            contact.email.toLowerCase().includes(term.toLowerCase())
        );
    };

    search: (text$: Observable<string>) => Observable<Contact[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(KEYSTROKE_DEBOUNCE_TIME), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.clickContact$.pipe(
            filter(() => !this.typeContactSearchInstance.isPopupOpen()),
        );

        return merge(debouncedText$, clicksWithClosedPopup$).pipe(
            map(term => this.contacts.filter(contact => this.filterFunction(contact, term))),
        );
    };

    validateContact(control: UntypedFormControl): ValidationErrors {
        const val = control.value;
        const isValid = val && val.name;
        if (!control.pristine && !isValid) {
            this.value = undefined;
            this.emitChanges();
        }
        return isValid ? null : { contact: 'A valid contact is required' };
    }

    onItemSelected = $event => {
        this.value = $event.item;
        this.emitChanges();
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    emitChanges(): void {
        if (this.onChange) {
            this.onChange(this.value);
        }
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.contactForm.get('contact').disable();
        } else {
            this.contactForm.get('contact').enable();
        }
    }

    writeValue(obj: any): void {
        this.value = obj;
        if (this.value && this.value.name) {
            this.searchString = this.value.name;
        }
    }
}
