import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';

// This pipe is similar to the angular built-in PercentPipe, but instead
// of rounding to the nearest value, this pipe truncates.
// Eg: {{ 0.99999999 | truncatePercent:'1.2-2' }} renders '99.99%'
// Eg: truncatePercent.transform(0.9999, '1.0-0) == '99%')
@Pipe({ name: 'truncatePercent' })
export class TruncatePercentPipe implements PipeTransform {
    // TODO: PercentPipe is not injectable as of angular 6
    // If you're looking at this code and we are using angular 8 or later,
    // PercentPipe should be injected in the constructor instead of created with 'new'
    constructor() {}

    transform(value: number, digitsInfo = '1.0-0', locale = 'en-US'): string {
        if (isNaN(value)) {
            return '-';
        }
        if (!digitsInfo.match(/^[0-9].[0-9]-[0-9]$/)) {
            digitsInfo = '1.0-0';
        }
        const decimal = Number(digitsInfo.split('-')[1]);
        // numerator is the value shifted and truncated
        // denominator shifts the value back to the correct magnitude while preserving truncation
        value = Math.trunc(Math.pow(10.0, 2 + decimal) * value) / Math.pow(10.0, 2 + decimal);

        return new PercentPipe(locale).transform(value, digitsInfo, locale);
    }
}
