import { FeatureFlagDxpService } from '@pure1/data';
import moment from 'moment';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, switchMap, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WINDOW } from '../../app/injection-tokens';
import { FeatureNames } from '../../model/FeatureNames';
import { FileDownloaderService } from './file-downloader.service';

/** How frequently the status of export tasks are updated in the background */
const BACKGROUND_UPDATE_FREQUENCY_MS = moment.duration(2, 'seconds').asMilliseconds();

const REPORTS_ENDPOINT = '/rest/v1/reports';
export const LIST_OPTIONS_ENDPOINT = `${REPORTS_ENDPOINT}/listoptions`;
export const GLOBAL_LIST_OPTIONS_ENDPOINT = `${REPORTS_ENDPOINT}/global/listoptions`;

type exportTaskStatus =
    | 'OPEN'
    | 'IN_PROGRESS'
    | 'COMPLETED'
    | 'FAILED'
    | 'CLOSED'
    | 'DOWNLOAD_IN_PROGRESS'
    | 'DOWNLOAD_COMPLETED'
    | 'DOWNLOAD_FAILED';

/**
 * The raw structure returned from the server for the export task status.
 */
export interface IRawExportTaskStatus extends IExportRequestPayload {
    id: exportTaskId;
    owner: string;
    created_time: number;
    end_time: number;
    status: exportTaskStatus;
}

type IRawExportOptions = {
    granularity: number;
    size: string;
}[];

/**
 * Contains the status of an individual export task.
 */
export interface IExportTask {
    readonly id: exportTaskId;
    readonly fileName: string;
    readonly createdTime: moment.Moment;
    readonly isInQueue: boolean;

    /** If this export has finished processing (successfully or not) */
    readonly isCompleted: boolean;

    /** If this export has failed to generate */
    readonly isFailed: boolean;

    /** If this report was created during this current session of our application (same browser tab, same user, has not navigated away) */
    readonly createdThisSession: boolean;
}

@Injectable({ providedIn: 'root' })
export class ExportManagerService {
    readonly activeExportsChange$ = new Subject<void>();
    readonly overlayVisibilityChange$ = new BehaviorSubject<boolean>(false);

    /** Set if we are currently updating the active exports */
    private updateInProgress = false;

    /** If true, run the export again immediately after the existing one completes */
    private runUpdateAfterComplete = false;

    /** Also continue background updates up until this time, and if there are any tasks in COMPLETED status */
    private checkForDownloadStartedUntil = moment();

    /** Collection of all the existing active exists (not FAILED or CLOSED) */
    private readonly activeExports = new Map<exportTaskId, IExportTask>();

    /** Collection of exportTaskIds that have been started by this instance (this browser window during this session) */
    private readonly ourEnqueuedTaskIds = new Set<exportTaskId>();

    constructor(
        private http: HttpClient,
        @Inject(WINDOW) private window: Window,
        protected featureFlagDxpService: FeatureFlagDxpService,
    ) {
        this.updateActiveExports();
    }

    enqueue(request: IExportRequest): Observable<void> {
        return this.http.post<IPostReportResponse>(REPORTS_ENDPOINT, request.getRequestPayload()).pipe(
            map((response: IPostReportResponse) => {
                this.ourEnqueuedTaskIds.add(response.report_id);
                this.updateActiveExports();
            }),
            catchError(err => {
                if (err.status === 429) {
                    // HTTP 429 Too Many Requests
                    return throwError('You have too many exports in progress. Please try again later.');
                } else if (err.status === 400 && err.error && err.error.message.indexOf('report') > 0) {
                    // CLOUD-56099 report error message needs to be explicit
                    return throwError(err.error.message);
                } else if (err.data && err.data.message) {
                    return throwError(err.data.message);
                } else {
                    return throwError('An unexpected error has occurred. Please try again');
                }
            }),
        );
    }

    hasActiveExports(): boolean {
        return this.activeExports.size > 0;
    }

    getActiveExports(): IExportTask[] {
        return Array.from(this.activeExports.values());
    }

    remove(task: IExportTask): void {
        this.http.delete(`${REPORTS_ENDPOINT}/${task.id}`).subscribe(
            () => {
                this.activeExports.delete(task.id);

                if (!this.hasActiveExports()) {
                    // if the list is now empty, hide the overlay
                    this.overlayVisibilityChange$.next(false);
                }

                this.updateActiveExports();
            },
            err => {
                this.errorHandler(err, 'Failed to delete task: ' + JSON.stringify(task));
            },
        );
    }

    /**
     * @param isGlobalReport If true, use the /global endpoint variation. Use true when the page is not limited to just the effective org (eg reports page).
     */
    getOptions(request: IExportRequest, isGlobalReport: boolean): Observable<IExportRequestOptions> {
        const requestPayload = <IPerformanceExportRequestPayload>request.getRequestPayload();

        // Set defaults for options that are required but not very important
        const defaultLoadGranularity = moment.duration(3, 'minutes').asSeconds();
        const defaultPerformanceGranularity = moment.duration(5, 'minutes').asSeconds();
        requestPayload.granularities = {
            LOAD: requestPayload.granularities?.LOAD || defaultLoadGranularity,
            PERFORMANCE: requestPayload.granularities?.PERFORMANCE || defaultPerformanceGranularity,
        };

        // If useGlobal is set, we still only want to use it if the FF is enabled
        const useGlobal$ = isGlobalReport
            ? this.featureFlagDxpService
                  .getFeatureFlag(FeatureNames.ORG_SWITCHING)
                  .pipe(map(feature => feature?.enabled))
            : of(false);

        return useGlobal$.pipe(
            map(global => (global ? GLOBAL_LIST_OPTIONS_ENDPOINT : LIST_OPTIONS_ENDPOINT)),
            switchMap(endpoint => this.http.post<IRawExportOptions>(endpoint, requestPayload)),
            map(rawOptions => {
                return {
                    granularities: rawOptions.map(opt => {
                        const duration = moment.duration(opt.granularity, 'milliseconds');
                        return {
                            granularity: duration,
                            size: opt.size,
                        };
                    }),
                };
            }),
        );
    }

    downloadTask(task: IExportTask, fileDownloader: FileDownloaderService): void {
        // We handle the downloads inside of the exportManager so we can internally keep the background updates
        // going for a little bit to try to pick up changes to the task's status changing to DOWNLOAD_IN_PROGRESS
        // and remove it from the list of active exports.
        const downloadUrl = `${REPORTS_ENDPOINT}/${task.id}/download`;

        fileDownloader.downloadUrl(downloadUrl, task.fileName);

        // Wait briefly before kicking off the checking to give the download time to start
        this.window.setTimeout(() => {
            this.checkForDownloadStartedUntil = moment().add(10, 'seconds');
            this.updateActiveExports(); // Ensure we're updating
        }, 600);
    }

    /**
     * Updates the active exports (or does nothing if already updating).
     */
    private updateActiveExports(): void {
        // Don't run if already running
        if (this.updateInProgress) {
            this.runUpdateAfterComplete = true; // But do update again when completed
            return;
        }

        // Get the status for outstanding tasks
        const requestStatuses: exportTaskStatus[] = ['OPEN', 'IN_PROGRESS', 'COMPLETED', 'FAILED'];
        this.updateInProgress = true;

        this.http
            .get<IRawExportTaskStatus[]>(REPORTS_ENDPOINT, {
                params: {
                    filter: 'status=' + requestStatuses.join(','),
                },
            })
            .subscribe(
                (rawTasks: IRawExportTaskStatus[]) => {
                    const wasNoActiveExports = this.activeExports.size === 0;

                    const newTasks = rawTasks.map(task => new ExportTask(task, this.ourEnqueuedTaskIds.has(task.id)));

                    // Update the active tasks
                    this.activeExports.clear();
                    newTasks.forEach(task => {
                        this.activeExports.set(task.id, task);
                    });

                    // Notify subscribers of changes
                    // We can skip this step if we go had no active exports, and still have none
                    if (!wasNoActiveExports || this.activeExports.size > 0) {
                        this.activeExportsChange$.next();
                    }

                    if (
                        (wasNoActiveExports && this.hasActiveExports()) ||
                        (!wasNoActiveExports && !this.hasActiveExports())
                    ) {
                        this.overlayVisibilityChange$.next(this.hasActiveExports());
                    }

                    // Continue to update if there are any incomplete active tasks, or we have another update enqueued
                    const hasInProgressTasks = newTasks.some(task => !task.isCompleted);
                    const hasCompletedTasks = newTasks.some(task => task.isCompleted);

                    if (
                        this.runUpdateAfterComplete ||
                        hasInProgressTasks ||
                        (hasCompletedTasks && moment().isBefore(this.checkForDownloadStartedUntil))
                    ) {
                        const updateDelayMs = this.runUpdateAfterComplete ? 100 : BACKGROUND_UPDATE_FREQUENCY_MS;

                        this.window.setTimeout(() => {
                            this.updateActiveExports();
                        }, updateDelayMs);
                    }

                    // Stop the auto-updates from COMPLETED tasks if no tasks are completed
                    if (!hasCompletedTasks) {
                        this.checkForDownloadStartedUntil = moment();
                    }
                },
                err => {
                    this.errorHandler(err, 'Background update failed');

                    // Retry again automatically (with slight delay) instead of leaving existing tasks in limbo
                    this.window.setTimeout(() => {
                        this.updateActiveExports();
                    }, BACKGROUND_UPDATE_FREQUENCY_MS * 2);
                },
                () => {
                    this.updateInProgress = false;
                    this.runUpdateAfterComplete = false;
                },
            );
    }

    private errorHandler(error: any, details: string): void {
        console.error('ExportManager: ' + details, error);
    }
}

/**
 * Class that wraps the status returned from the server.
 */
export class ExportTask implements IExportTask {
    get id(): exportTaskId {
        return this.raw.id;
    }

    get fileName(): string {
        return this.raw.zip_name;
    }

    get createdTime(): moment.Moment {
        return moment(this.raw.created_time);
    }

    get isFailed(): boolean {
        return this.raw.status === 'FAILED';
    }

    get isCompleted(): boolean {
        return this.raw.status === 'COMPLETED' || this.raw.status === 'FAILED';
    }

    get isInQueue(): boolean {
        return this.raw.status === 'OPEN';
    }

    constructor(
        private readonly raw: IRawExportTaskStatus,
        public readonly createdThisSession: boolean,
    ) {}
}

/**
 * Describes the response of a successful POST to create a new report
 */
interface IPostReportResponse {
    report_id: exportTaskId;
}
