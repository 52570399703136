<div class="expanded-card-performance" [ngClass]="{ 'is-expanded': isExpanded }">
    <div class="performance-summary">
        <div *ngFor="let mc of metricConfigs" class="performance-summary-outer">
            <div class="summary-title">{{ mc.label }}</div>

            <div class="performance-summary-inner">
                <ng-container *ngFor="let series of mc.series">
                    <div *ngIf="!series.hidden" class="performance-summary-detail">
                        <div class="series-label" [ngStyle]="{ color: series.color }">{{ series.shortLabel }}</div>
                        <div class="series-value">{{ $any(array).performanceData[series.key]?.value }}</div>
                        <div class="series-unit">{{ $any(array).performanceData[series.key]?.unit }}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div
        *ngIf="isExpanded"
        class="performance-charts"
        synchronizedChartTooltips
        (mouseleave)="onChartContainerMouseLeave($event)"
    >
        <array-expanded-card-performance-chart
            *ngFor="let mc of metricConfigs"
            [metricConfig]="mc"
            [performanceData]="performanceData"
        ></array-expanded-card-performance-chart>
    </div>
</div>
