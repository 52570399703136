import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class CaseAsyncJobService {
    private static cacheTTL = 29000; // 29 seconds
    private endpoint = '/rest/v1/support/checkasyncjobs';

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: CaseAsyncJobService.cacheTTL,
    })
    checkCasesAsyncJobs(): Observable<string> {
        return this.http.get<string>(this.endpoint);
    }
}
