<div class="edit-summary-container">
    <div class="edit-summary">
        <span>{{ summary }}</span>
        <span
            class="undo-button"
            (click)="undo()"
            angulartics2On="click"
            angularticsAction="Tagging - Undo edit tag"
            angularticsCategory="Action"
            >Undo
        </span>
    </div>
</div>
