import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUser } from '@pure1/data';
import { CaseEscalation, CaseEscalationReason, ItemType, SupportCase } from '../support.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EscalateModalComponent } from './escalate-modal.component';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'escalate-button',
    templateUrl: './escalate-button.component.html',
})
export class EscalateButtonComponent {
    readonly ampli = ampli;

    @Input() readonly supportCase: SupportCase;
    @Input() readonly user: CurrentUser;
    @Output() onEscalationCreated = new EventEmitter<Partial<CaseEscalation>>();

    private readonly REASON_CLOSED = 'Case closed but issue not resolved';

    constructor(private ngbModal: NgbModal) {}

    openModal(): void {
        if (!this.supportCase || this.supportCase.isEscalated) {
            return;
        }
        this.ampli.supportCaseEscalateClicked();
        const modalInstance = this.ngbModal.open(EscalateModalComponent);
        modalInstance.componentInstance.supportCase = this.supportCase;
        modalInstance.componentInstance.user = this.user;
        modalInstance.componentInstance.contactName = this.user.name;
        modalInstance.componentInstance.contactPhone = this.user.phone;

        if (this.supportCase.isClosed) {
            modalInstance.componentInstance.reason = CaseEscalationReason.CLOSED_BUT_NOT_RESOLVED;
        }
        modalInstance.result.then(this.escalationCreated).catch(() => {});
    }

    escalationCreated = (escalation: Partial<CaseEscalation>) => {
        this.onEscalationCreated.emit({ ...escalation, itemType: ItemType.Escalation });
    };
}
