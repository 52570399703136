import moment from 'moment';
import { Resource } from '../interfaces/resource';
import { AlertSeverity } from './alert-severity';
import { Member } from './member';

export class ReplicaLink implements Resource {
    id: string;
    name: string;
    _as_of: moment.Moment;

    alertId: string;
    alertSeverity: AlertSeverity;
    alertSeverityString: string;
    alertSummaryString: string;
    lag: number | null; // in milliseconds
    lastUpdated: moment.Moment | null;
    paused: boolean;
    recoveryPoint: moment.Moment | null;
    status: string; // TODO: ideally get all possible statuses and create a type for it (currently statuses in flux)
    statusDetails: string;

    members: Member[] | null;
    source: Member | null;
    target: Member | null;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;

        this._as_of = moment(json._as_of);

        this.alertId = json.alertId || null;
        const tempAlertSeverity = new AlertSeverity(json.alertSeverityForSort || null); // will be UNKNOWN if null
        this.alertSeverity = tempAlertSeverity.toString() === 'UNKNOWN' && !this.alertId ? null : tempAlertSeverity;
        this.alertSeverityString = json.alertSeverityString || null;
        this.alertSummaryString = json.alertSummaryString || null;
        this.lag = json.lag || null;
        this.lastUpdated = moment(json.lastUpdated || null);
        this.paused = json.paused || false;
        this.recoveryPoint = moment(json.recoveryPoint || null);
        this.status = json.status || 'unknown';
        this.statusDetails = json.statusDetails || null;
        this.members = this.parseAllMemberTypes(json.members);
        this.source = json.source ? new Member(json.source) : null;
        this.target = json.target ? new Member(json.target) : null;
    }

    private parseAllMemberTypes(json: any[]): Member[] {
        if (!json) {
            return null;
        }
        return json.map(memberJson => new Member(memberJson));
    }
}
