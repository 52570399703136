import { Injectable } from '@angular/core';
import { concatMap, defer, EMPTY, Observable, of, Subject, switchMap, zipWith } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { smartTimer } from '@pstg/smart-timer';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryThrottlingHttpClient {
    readonly REQUESTS_PER_SECOND = 10;
    private readonly queue$ = new Subject<Subject<void>>();
    private timestamps: number[] = [];

    constructor(private http: HttpClient) {
        this.queue$
            .pipe(
                concatMap(trigger$ => {
                    if (trigger$.observed) {
                        // trigger the request and wait before moving to the next one
                        trigger$.next();
                        this.updateTimestamps();
                        const waitTime = this.getWaitTimeBeforeNextRequest();
                        return waitTime > 0 ? smartTimer(waitTime) : of(null);
                    } else {
                        // the request was unsubscribed from, skip it
                        return EMPTY;
                    }
                }),
            )
            .subscribe();
    }

    queueRequest<T>(request: Observable<T>): Observable<T> {
        return defer(() => {
            const trigger$ = new Subject<void>();
            return trigger$.pipe(
                zipWith(
                    // queue the request after trigger$ is subscribed to
                    defer(() => {
                        this.queue$.next(trigger$);
                        // emit null immediately, so that the zipWith will only wait for the trigger
                        return of(null);
                    }),
                ),
                finalize(() => trigger$.complete()),
                // trigger was emitted, now switch to the original request
                switchMap(() => request),
            );
        });
    }

    private updateTimestamps(): void {
        this.timestamps.push(Date.now());
        // keep track of the last N timestamps
        this.timestamps = this.timestamps.slice(-this.REQUESTS_PER_SECOND);
    }

    private getWaitTimeBeforeNextRequest(): number {
        if (this.timestamps.length < this.REQUESTS_PER_SECOND) {
            // no need to wait
            return 0;
        }
        const timeSinceOldest = Date.now() - this.timestamps[0];
        // wait until the oldest request is at least 1 second old
        return Math.max(0, 1000 - timeSinceOldest);
    }

    get<T>(url: string): Observable<T>;
    get<T>(url: string, options?: { observe: 'response' }): Observable<HttpResponse<T>>;
    get<T>(url: string, options?: { observe: 'response' }): Observable<T> | Observable<HttpResponse<T>> {
        return this.queueRequest(this.http.get<T>(url, options));
    }

    post<T>(url: string, body: any): Observable<T>;
    post<T>(url: string, body: any, options?: { observe: 'response' }): Observable<HttpResponse<T>>;
    post<T>(url: string, body: any, options?: { observe: 'response' }): Observable<T> | Observable<HttpResponse<T>> {
        return this.queueRequest(this.http.post<T>(url, body, options));
    }

    put<T>(url: string, body: any): Observable<T> {
        return this.queueRequest(this.http.put<T>(url, body));
    }

    patch<T>(url: string, body: any): Observable<T> {
        return this.queueRequest(this.http.patch<T>(url, body));
    }

    delete<T>(url: string, options?: { body: any }): Observable<T> {
        return this.queueRequest(this.http.delete<T>(url, options));
    }
}
