import { DraasApiNode } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryNode {
    id: string;
    clusterId: string;
    status: 'WAITING_REGISTRATION' | 'PENDING' | 'HEALTHY' | 'UNHEALTHY' | 'READY';

    constructor(json: DraasApiNode) {
        this.id = json.id.node_id;
        this.clusterId = json.id.cluster_id;
        this.status = json.state.status;
    }
}
