import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { MetricsHistory } from '../models/metrics-history';

@Injectable({ providedIn: 'root' })
export class MetricsHistoryServiceV2 extends GenericService<MetricsHistory> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: MetricsHistory,
            endpoint: '/rest/v3/metrics/subscriptions/history',
        });
    }
}
