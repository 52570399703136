import moment from 'moment';
import { Resource } from '../interfaces/resource';

export type pgroupStatus = 'completed' | 'inProgress' | 'delayed' | 'unknown' | 'pausedDisallowed';

export interface IPGroupSchedule {
    replicate_enabled: boolean;
    replicate_frequency: number;
    replicate_blackout: { start: number; end: number };
    target_all_for: number;
    target_days: number;
    target_per_day: number;
}

export class PGroup implements Resource {
    name: string;
    id: string;
    schedule: IPGroupSchedule;
    source: Resource;
    target: Resource;
    status: pgroupStatus;
    last_rp: moment.Moment | null;
    target_allowed: boolean;
    target_type: 'Pure' | 'S3' | 'NFS';
    arrays: Resource[];

    static fromJson(json: any): PGroup {
        const pgroup = new PGroup();
        pgroup.name = json.name;
        pgroup.id = json.pgroup_cloud_id;
        pgroup.source = {
            name: json.source,
            id: json.appliance_id,
        };
        pgroup.target = {
            name: json.target,
            id: json.target_appliance_id,
        };
        pgroup.status = json.status;
        pgroup.schedule = json.schedule;
        pgroup.last_rp = json.last_rp ? moment(json.last_rp) : null;
        pgroup.target_allowed = json.allowed;
        pgroup.target_type = json.target_type;
        pgroup.arrays = json.arrays
            ? json.arrays.map(array => ({
                  id: array.id,
                  name: array.name,
              }))
            : [
                  {
                      id: json.appliance_id,
                      name: json.source,
                  },
              ];
        return pgroup;
    }
}
