import moment from 'moment';

import { BaseSnapshot } from './base-snapshot';

export class PGroupSnapshot extends BaseSnapshot {
    name: string;
    id: string;
    pgroup_id: string;
    created: moment.Moment;
    started: moment.Moment | null;
    completed: moment.Moment | null;
    data_transferred: number;
    completion_percent: number;
    status: 'completed' | 'pausedDisallowed' | 'inProgress';

    static fromJson(json: any): PGroupSnapshot {
        const snap = new PGroupSnapshot({});
        snap.name = json.name;
        snap.id = json.name + json.created;
        snap.pgroup_id = json.pgroup_cloud_id;
        snap.created = moment(json.created);
        snap.started = json.started ? moment(json.started) : null;
        snap.completed = json.completed ? moment(json.completed) : null;
        snap.data_transferred = json.data_transferred;
        snap.completion_percent = json.progress;
        snap.status = json.status;
        return snap;
    }
}
