import { DraasApiUpgradeExecution } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryUpgradeExecutionStep } from './disaster-recovery-upgrade-execution-step';

export class DisasterRecoveryUpgradeExecution {
    id: string;
    clusterId: string;
    severity: 'AVAILABLE' | 'RECOMMENDED';
    status: 'AVAILABLE' | 'SCHEDULED' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED';
    currentVersion: string;
    upgradeVersion: string;
    releasedAtUtc: string;
    scheduledDatetimeUtc: string;
    scheduleType: 'RECOMMENDED' | 'CUSTOM';
    finishedDateTimeUtc?: string;
    version: number;
    steps: DisasterRecoveryUpgradeExecutionStep[];

    constructor(json: DraasApiUpgradeExecution) {
        this.id = json.id;
        this.clusterId = json.cluster_id;
        this.severity = json.severity;
        this.status = json.status;
        this.currentVersion = json.current_version;
        this.upgradeVersion = (json as any).upgrade_version ?? json.release_version;
        this.releasedAtUtc = json.released_at_utc;
        this.scheduledDatetimeUtc = (json as any).scheduled_datetime_utc ?? json.scheduled_at_utc;
        this.scheduleType = json.schedule_type;
        this.finishedDateTimeUtc = (json as any).finished_date_time_utc ?? json.finished_at_utc;
        this.version = json.version;
        // The steps are not nullable in the API, but they were added later, so this is a backwards compatibility workaround.
        this.steps = json.steps?.map(step => new DisasterRecoveryUpgradeExecutionStep(step)) ?? [];
    }
}
