<pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
<div class="eol-hw-popover-card">
    <div class="eol-hw-wrapper">
        <pure-alert-indicator
            class="alert-indicator card-alert-indicator card-flip-b"
            [iconHeight]="14"
            [array]="array"
        >
        </pure-alert-indicator>

        <div class="eol-schedule">
            <div class="title"> End of Life Schedule </div>
            <div class="eol-hw-grouped-component" *ngFor="let label of labels">
                <div>{{ label }}</div>
            </div>
        </div>
        <button
            type="button"
            class="btn btn-primary"
            *ngIf="
                opportunityStatus === 'unopened' && (!incidentOpened || !hasPermissionToViewRecommendation());
                else viewRecommendation
            "
            [disabled]="('PURE1:write:request_quote_for_existing' | isNotAllowed) || loading || !hwEolInfo"
            (click)="clickContactAccountTeam()"
        >
            Contact Account Team
        </button>
        <ng-template #viewRecommendation>
            <button
                type="button"
                class="btn btn-primary"
                *ngIf="opportunityStatus === 'unopened' && incidentOpened"
                [disabled]="loading || !hwEolInfo || !hasPermissionToViewRecommendation()"
                (click)="clickViewRecommendation()"
            >
                View Recommendation
            </button>
        </ng-template>
        <div *ngIf="opportunityStatus === 'opened'">
            <div class="team-contacted-message">
                We have received your message and our account team will get in touch with you.
            </div>
        </div>
    </div>
</div>
