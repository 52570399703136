import { Observable, map } from 'rxjs';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
} from '@angular/forms';

import { SearchArrayComponent } from '../../shared/components/search-array/search-array.component';
import { AbstractProductSectionAppliancesComponent } from '../abstract-product-section-appliances';
import { License, LicenseService } from '@pure1/data';
import { forString } from '../../../utils/comparator';

@Component({
    selector: 'product-section-licenses',
    templateUrl: 'product-section-licenses.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductSectionLicensesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProductSectionLicensesComponent),
            multi: true,
        },
    ],
})
export class ProductSectionLicensesComponent extends AbstractProductSectionAppliancesComponent<
    License,
    SearchArrayComponent
> {
    constructor(
        private licenseService: LicenseService,
        protected cdr: ChangeDetectorRef,
        protected fb: UntypedFormBuilder,
    ) {
        super(cdr, fb);
    }

    getAppliances(): Observable<License[]> {
        return this.licenseService.list().pipe(
            map(licenses => {
                return licenses.response.sort(forString<License>(license => license.subscription.name).asc);
            }),
        );
    }

    selectVisibleProduct(initialProduct: License): void {
        if (!initialProduct) {
            return;
        }
        this.appliances$.subscribe(() => {
            if (this.isBeingEdited) {
                const selectedLicense = this.appliances.find(license => license.id === initialProduct.id);
                if (!selectedLicense) {
                    this.appliances = this.appliances
                        .concat(initialProduct)
                        .slice()
                        .sort(forString<License>(license => license.subscription.name).asc);
                }
                this.writeValue(initialProduct);
                return;
            }
            if (this.appliances.length === 0) {
                this.appliances = [initialProduct];
            }
            this.writeValue(initialProduct);
        });
    }

    validateAppliance(control: UntypedFormControl): ValidationErrors {
        // Let's not bother with checking when editing
        if (this.isBeingEdited) {
            return null;
        }
        return control.value && control.value instanceof License ? null : { product: 'A valid product is required' };
    }

    writeValue(val: any): void {
        this.appliances$.subscribe(() => {
            if (val?.id) {
                const selectedLicense = this.appliances.find(license => license.id === val.id);
                selectedLicense && this.form.setValue({ product: selectedLicense });
            }
        });
    }
}
