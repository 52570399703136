import { Pipe, PipeTransform } from '@angular/core';
import {
    GenealogyEventType,
    getApplianceGenealogyEventDisplayName,
    getSubscriptionGenealogyEventDisplayName,
    isApplianceGenealogyEvent,
    isSubscriptionGenealogyEvent,
} from '@pure1/data';

@Pipe({
    name: 'genealogyEventDisplayName',
})
export class GenealogyEventDisplayNamePipe implements PipeTransform {
    transform(value: GenealogyEventType): string {
        if (isApplianceGenealogyEvent(value)) {
            return getApplianceGenealogyEventDisplayName(value);
        }

        if (isSubscriptionGenealogyEvent(value)) {
            return getSubscriptionGenealogyEventDisplayName(value);
        }

        console.warn(`Unexpected genealogy event type: ${value}`);
        return '';
    }
}
