<drawer
    class="view-drawer"
    [width]="calculatedWidth"
    [visibleSection]="buttonsWidth"
    [ngClass]="{ open: drawerOpen }"
    [alwaysVisible]="true"
    [drawerClosed]="!drawerOpen"
    [contentTemplate]="drawerTemplate"
    (closeDrawerClick)="onToggleDrawer()"
>
</drawer>
<div *ngIf="drawerOpen" class="drawer-backdrop" (click)="onToggleDrawer()"></div>
<ng-template #drawerTemplate>
    <div class="drawer-container">
        <div class="drawer-buttons">
            <div *ngFor="let item of icons" class="drawer-btn" (click)="_onIconClick(item.name)">
                <div class="button-icon">
                    <pureui-svg
                        [svg]="selectedIcon === item.name ? item.selectedIcon || item.icon : item.icon"
                        height="20"
                        class="button-icon collapsed-icon"
                    ></pureui-svg>
                    <div *ngIf="item.pillText" class="button-icon-pill">
                        {{ item.pillText }}
                    </div>
                </div>
            </div>
        </div>
        <div class="content-container">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
