import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SupportCasesRedirectDirective } from '../../support/support-cases-redirect.directive';
import { ButtonComponent, LinkComponent } from '@pure/hive';

import { UIModule } from '../../ui/ui.module';

import { MessagesModalService } from './messages-modal/messages-modal.service';
import { MessagesModalComponent } from './messages-modal/messages-modal.component';
import { MessagesDisplayDatetimePipe } from '../pipes/messages-display-datetime.pipe';
import { AuthorizerModule } from '@pure/authz-authorizer';

/**
 * Components within the Alerts feature that needs to be shared with other feature modules
 */
@NgModule({
    imports: [
        AuthorizerModule,
        CommonModule,
        NgbModule,
        UIModule,
        RouterModule,
        SupportCasesRedirectDirective,
        LinkComponent,
        ButtonComponent,
    ],
    declarations: [MessagesDisplayDatetimePipe, MessagesModalComponent],
    exports: [MessagesModalComponent],
    providers: [MessagesModalService],
})
export class AlertsSharedModule {}
