<div class="content">
    <div class="section subscription-summary-chart">
        <div class="section-header"
            >All Subscriptions Overview
            <pureui-svg
                svg="info-circle.svg"
                height="13"
                class="info-icon"
                ngbTooltip="Subscriptions without assets are excluded from this summary."
                container="body"
                placement="bottom"
            >
            </pureui-svg>
        </div>
        <pureui-spinner class="asset-management-summary-spinner" [pureuiShow]="loading"></pureui-spinner>
        <div class="subscription-summary-pie">
            <pure-radial-dial
                *ngIf="!loading"
                [size]="size"
                [innerSize]="innerSize"
                [arcs]="dialSegments"
                [enableTooltip]="false"
                [centerLabel]="centerLabel"
                [centerLabelOffset]="-10"
                subtitle="Subscriptions"
                [enableAnimation]="false"
            ></pure-radial-dial>
            <div *ngIf="!loading" class="health-chart-legend">
                <!-- show emptyMsg if there is only hidden segment in the dialSegments -->
                <div *ngIf="dialSegments && dialSegments.length > 0; else emptyMsg">
                    <div *ngFor="let segment of dialSegments">
                        <div class="health-chart-legend-item">
                            <div class="health-chart-legend-item-circle">
                                <ng-container
                                    *ngTemplateOutlet="circle; context: { color: segment.color }"
                                ></ng-container>
                            </div>
                            <div class="health-chart-legend-item-text">
                                {{ segment.name }}
                                <span class="health-chart-legend-item-count">{{ segment.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #emptyMsg>No subscriptions available</ng-template>
            </div>
        </div>
    </div>
    <ng-template #circle let-color="color">
        <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="5" [attr.fill]="color" />
        </svg>
    </ng-template>
    <div class="section">
        <div class="section-header"> Renewals </div>
        <asset-management-summary-card [summaryInfo]="renewalInfo"></asset-management-summary-card>
    </div>
    <div class="section">
        <div class="section-header"> On-Demand Licenses </div>
        <asset-management-summary-card [summaryInfo]="licensesInfo"></asset-management-summary-card>
    </div>
</div>
