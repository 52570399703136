import { Resource } from '../interfaces/resource';
import {
    DraasApiAggregatedProtectionGroup,
    DraasApiBlackoutWindow,
    DraasApiTagFilter,
} from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryTag } from './disaster-recovery-tag';
import { Moment } from 'moment';
import moment from 'moment/moment';

export enum GroupProtectionState {
    ENABLED = 'ENABLED',
    ENABLED_PENDING = 'ENABLED_PENDING',
    DISABLED = 'DISABLED',
    DISABLED_PENDING = 'DISABLED_PENDING',
}

export class DisasterRecoveryProtectionGroup implements Resource {
    id: string;
    clusterId: string;
    name: string;
    description: string;
    backupStartTimeUtc: Moment;
    blackoutWindow?: DisasterRecoveryBlackoutWindow;
    isInfrastructureGroup: boolean;
    isConsistencyGroup: boolean;
    startReplication: 'AFTER_PROTECTION_SUCCESS' | 'IMMEDIATELY';
    hasCloudPreConversion: boolean;
    hasParallelBoot: boolean;
    serviceLevelPolicyId: string;
    serviceLevelPolicyName: string;
    testRecoveryPlanNames: string[];
    version: number;
    numberOfVms: number;
    state: 'PENDING' | 'SYNCED' | 'DELETE_PENDING' | 'DELETED' | 'FAILED' | 'DELETE_FAILED';
    protectionState: GroupProtectionState;
    isInFailover: boolean;
    tagFilter: DisasterRecoveryTagFilter | null;
    isEnrollmentPending: boolean;
    sourceProviderId: string;
    targetProviderIds: Map<number, string>;

    constructor(json: DraasApiAggregatedProtectionGroup) {
        this.id = json.id;
        this.clusterId = json.cluster_id;
        this.name = json.name;
        this.description = json.description;
        this.backupStartTimeUtc = moment.utc(json.backup_start_time_utc, 'HH:mm:ss');
        this.blackoutWindow = mapDisasterRecoveryBlackoutWindow(json.blackout_window);
        this.isInfrastructureGroup = json.is_infrastructure_group;
        this.isConsistencyGroup = json.is_consistency_group;
        this.startReplication = json.start_replication;
        this.hasCloudPreConversion = json.has_cloud_pre_conversion;
        this.hasParallelBoot = json.has_parallel_boot;
        this.serviceLevelPolicyId = json.service_level_policy_id;
        this.serviceLevelPolicyName = json.service_level_policy_name;
        this.testRecoveryPlanNames = json.test_recovery_plan_names;
        this.version = json.version;
        this.numberOfVms = json.number_of_vms;
        this.state = json.state;
        this.protectionState = GroupProtectionState[json.protection_state] || null;
        this.isInFailover = json.is_in_failover;
        this.tagFilter = json.tag_filter ? new DisasterRecoveryTagFilter(json.tag_filter) : null;
        this.isEnrollmentPending = json.is_enrollment_pending;
        this.sourceProviderId = json.source_provider_id;
        if (json.target_provider_ids) {
            this.targetProviderIds = new Map(
                Object.entries(json.target_provider_ids).map(([ordinal, providerId]) => [
                    parseInt(ordinal),
                    providerId,
                ]),
            );
        }
    }
}

export interface DisasterRecoveryBlackoutWindow {
    startTimeUtc: string;
    endTimeUtc: string;
}

function mapDisasterRecoveryBlackoutWindow(json?: DraasApiBlackoutWindow): DisasterRecoveryBlackoutWindow {
    if (!json) {
        return undefined;
    }

    return {
        startTimeUtc: json.start_time_utc,
        endTimeUtc: json.end_time_utc,
    };
}

export class DisasterRecoveryTagFilter {
    readonly id: string;
    readonly tagIds: string[];
    readonly tagIdsMode: 'ALL' | 'ANY';
    readonly autoProtect: boolean;
    readonly tags: DisasterRecoveryTag[];

    constructor(json: DraasApiTagFilter) {
        this.id = json.id;
        this.tagIds = json.tag_ids;
        this.tagIdsMode = json.tag_ids_mode;
        this.autoProtect = json.auto_protect;
        this.tags = json.tags.map(tag => new DisasterRecoveryTag(tag));
    }
}
