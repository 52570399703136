import { Injectable, Inject } from '@angular/core';
import { Observable, throwError, defer, BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { WINDOW } from '../../app/injection-tokens';

@Injectable({
    providedIn: 'root',
})
export class SupportLoaderService {
    readonly isLoading$ = new BehaviorSubject<boolean>(false);

    private count = 0;
    private generation = 0;

    constructor(@Inject(WINDOW) private window: Window) {}

    add<T>(request: Observable<T>): Observable<T> {
        // defer to assign a correct generation at the time of subscription
        return defer(() => {
            const gen = this.generation;
            this.count++;
            this.updateIsLoading();
            return request.pipe(
                finalize(() => {
                    if (gen === this.generation) {
                        this.count--;
                        this.updateIsLoading();
                    }
                }),
            );
        });
    }

    clear(): void {
        this.count = 0;
        this.generation++;
        this.updateIsLoading();
    }

    private updateIsLoading(): void {
        // Emit new value only if the value changes to avoid needless updates
        const newIsLoading = this.count > 0;
        if (this.isLoading$.value !== newIsLoading) {
            // Use setImmediate() to avoid issues around the update occuring after the view is checked by forcing it on a new change detection.
            // While not an ideal approach, this happens infrequently enough to care.
            this.window.setImmediate(() => {
                this.isLoading$.next(newIsLoading);
            });
        }
    }
}
