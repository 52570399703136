import { merge, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUser } from '@pure1/data';
import { emailRegex } from '../../utils/strings';
import { WINDOW } from '../../app/injection-tokens';
import { createAuthPortalLoginPath } from '../../utils/url';
import { ImpersonationService } from '@pure/pure1-ui-platform-angular';

@Component({
    selector: 'switch-user-modal',
    templateUrl: 'switch-user-modal.component.html',
})
export class SwitchUserModalComponent implements OnInit {
    @ViewChild('typeInstance', { static: true }) readonly instance: NgbTypeahead;

    currentUser: CurrentUser;
    isSwitching = false;
    status: 'successMsg' | 'errorMsg' = 'successMsg';
    email = '';
    emailRegex = emailRegex;
    readonly focus$ = new Subject<string>();
    readonly click$ = new Subject<string>();

    private impersonateHistory: string[] = [];

    constructor(
        public activeModal: NgbActiveModal,
        @Inject(WINDOW) private window: Window,
        private impersonationService: ImpersonationService,
    ) {}

    ngOnInit(): void {
        this.retrieveImpersonateHistory()
            .then(history => {
                this.impersonateHistory = history;
            })
            .catch(() => console.error('Error retrieving impersonation history'));
    }

    async retrieveImpersonateHistory() {
        const history = await this.impersonationService.getImpersonationHistory();
        return history.filter(impersonate => impersonate !== this.currentUser.email);
    }

    search = (text$: Observable<string>): Observable<string[]> => {
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        return merge(text$, this.focus$, clicksWithClosedPopup$).pipe(
            map(text => {
                const lc = text.toLowerCase();
                return this.impersonateHistory.filter(email => email.toLowerCase().includes(lc));
            }),
        );
    };

    switchUser(): void {
        this.isSwitching = true;
        this.status = 'successMsg';
        let path = '';

        if (
            this.currentUser.impersonating &&
            this.email.toLowerCase() === this.currentUser.originalUserEmail.toLowerCase()
        ) {
            // we are impersonating and switching back to original user, get new normal access token
            path = createAuthPortalLoginPath(this.email, null, this.window.location.pathname);
        }

        if (this.email.toLowerCase() !== this.currentUser.originalUserEmail.toLowerCase()) {
            // we are impersonating(or not impersonating) and switching to another user impersonation, get new impersonation token
            path = createAuthPortalLoginPath(
                this.currentUser.originalUserEmail,
                this.email,
                this.window.location.pathname,
            );
        }

        this.window.location.href = this.window.location.origin + path;
    }
}
