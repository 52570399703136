import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { ExportManagerService } from '../../../export/services/export-manager.service';
import {
    ExportRequestBuilderService,
    InvoiceReportType,
    ReportTimeRange,
} from '../../../export/services/export-request-builder.service';
import { ExportOptionIds } from '../../../export/export-button/export-button.component';
import { RangeFilters } from '../invoice-management-range-filters/invoice-management-range-filters.component';
import moment from 'moment';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'invoice-export-modal',
    templateUrl: 'invoice-export-modal.component.html',
})
export class InvoiceExportModalComponent implements OnInit, OnChanges {
    @Input() readonly modal: NgbActiveModal;
    @Input() readonly invoiceFilter: string;
    @Input() readonly totalFiltered: number;
    @Input() readonly totalUnfiltered: number;
    @Input() readonly selectedOption: IExportButtonOption;
    @Input() readonly selectedRangeFilters: RangeFilters = { startRange: null, endRange: null };

    readonly ampli = ampli;

    errorMessage: string;
    exportRequest: IInvoiceExportRequest;
    fileNameEntry: string;
    loading = false;
    entityCountMessage = '';

    constructor(
        private angulartics2: Angulartics2,
        private exportManager: ExportManagerService,
        private exportRequestBuilder: ExportRequestBuilderService,
    ) {}

    ngOnInit(): void {
        this.generateExportRequest();
        this.fileNameEntry = this.exportRequest?.fileName;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalFiltered || changes.selectedOption || changes.totalUnfiltered) {
            this.entityCountMessage = this.getEntityCountMessage();
        }
    }

    clickApply(): void {
        this.generateExportRequest();
        this.exportRequest.fileName = this.fileNameEntry;

        // Set state and send request
        this.errorMessage = null;
        this.loading = true;

        this.exportManager.enqueue(this.exportRequest).subscribe({
            next: () => {
                this.loading = false;
                this.modal.close();
            },
            error: err => {
                this.loading = false;
                this.errorMessage = err;
            },
        });
    }

    getEntityCountMessage(): string {
        if (!this.selectedOption) {
            return '';
        }

        let noun = 'invoices';

        switch (this.selectedOption.id) {
            case ExportOptionIds.all: {
                if (this.totalUnfiltered === 1) {
                    noun = 'invoice';
                }
                return `All ${this.totalUnfiltered} ${noun}`;
            }
            case ExportOptionIds.filtered: {
                if (this.totalFiltered === 1) {
                    noun = 'invoice';
                }
                return `${this.totalFiltered} filtered ${noun}`;
            }
            default: {
                return `Unknown option ${this.selectedOption.id}`;
            }
        }
    }

    generateExportRequest(): void {
        const selectedReportRange: ReportTimeRange = {
            start_time: this.selectedRangeFilters.startRange?.start || moment().subtract(1, 'year'),
            end_time: this.selectedRangeFilters.startRange?.end || moment(),
        };
        this.exportRequest = this.exportRequestBuilder.filteredInvoice(
            this.invoiceFilter,
            InvoiceReportType.INVOICE_SUMMARY_XLSX,
            selectedReportRange,
        );
        this.ampli.exportingOnAssetTab({ 'assets tab page': 'Invoice Management', 'export category': 'filtered' });
    }
}
