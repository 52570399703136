import { Resource } from '../interfaces/resource';

export enum AlertState {
    OPEN = 'open',
    CLOSING = 'closing',
    CLOSED = 'closed',
}

export enum AlertProduct {
    IR = 'ir',
    OS = 'os',
}

export enum AlertOrigin {
    ARRAY = 'array',
    PURE1 = 'pure1',
    DRAAS = 'draas',
}

export interface AlertBody {
    array_id: string;
    closed: number;
    code: number;
    component: string;
    component_id: string;
    current_severity: AlertSeverityName;
    description: string;
    hostname: string;
    id: string;
    origin: AlertOrigin;
    product: AlertProduct;
    servicenow_case_id: string;
    servicenow_case_number: string;
    source: string;
    state: AlertState;
    summary: string;
    updated: number;
    opened: number;
    sub_component: string;
    sub_component_id: string;
}

export class AlertResourceV2 implements Resource {
    id: string;
    name: string;
    body: AlertBody;
    tags: { [key: string]: string[] };

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.body = json.body;
        this.tags = json.tags;
    }
}
