<div class="card-flip" #scrollToElem>
    <div
        class="card-front"
        [ngClass]="{ 'eol-hw': hasEolComponents() }"
        (mouseenter)="hoverState = true"
        (mouseleave)="hoverState = false"
    >
        <div class="card">
            <eol-overlay *ngIf="hasEolComponents()" [array]="array" [hovering]="hoverState"></eol-overlay>

            <!-- Card top -->
            <div class="container-fluid">
                <a>
                    <pure-alert-indicator
                        class="alert-indicator card-alert-indicator"
                        [iconHeight]="20"
                        [alerts]="
                            showWarningCardForOutOfSupportAppliance &&
                            array.contract_status === ArrayContractStatusExpired
                                ? null
                                : alerts
                        "
                        [array]="array"
                        (click)="flipCard($event)"
                        angulartics2On="click"
                        angularticsCategory="Action"
                        angularticsAction="Arrays - Flip card"
                    >
                    </pure-alert-indicator>
                </a>

                <div class="card-array-title">
                    <span class="paws-overlay" *ngIf="array.isCBS()">
                        <span class="paws-background"></span>
                        <pureui-svg class="paws-background-icon" svg="cloud.svg" width="13"></pureui-svg>
                    </span>

                    <span class="card-array-expanded-title-cell card-array-hostname">
                        <a
                            [arrayHref]="array.arrayId"
                            [ngbTooltip]="array.fqdn || array.hostname"
                            placement="top"
                            container="body"
                        >
                            <span class="array-name" [ngClass]="{ 'eol-hw': hasEolComponents() }">{{
                                array.hostname
                            }}</span
                            ><span class="domain" [ngClass]="{ 'eol-hw': hasEolComponents() }" *ngIf="array.fqdn"
                                >.{{ array.fqdn | domain }}</span
                            >
                        </a>
                    </span>

                    <span class="card-array-expanded-title-cell">
                        <span class="card-array-info-model">{{ array.model }}</span>
                        <span class="card-array-info-os">{{ array.os }} {{ array.version }}</span>
                    </span>
                </div>
            </div>

            <!-- Show regular card if array is not under Expired Status AND user is not allowed to view Appliance Card -->
            <ng-container
                *ngIf="!showWarningCardForOutOfSupportAppliance || isCardShowArrayInfo; else showOutOfSupportContract"
            >
                <ng-container *ngIf="array.active_status !== ResourceStatus.SUSPENDED; else showSuspended">
                    <!-- Array is current -->
                    <div *ngIf="array.isCurrent">
                        <!-- Capacity -->
                        <div
                            class="right-border expanded-card-placeholder expanded-card-pane-capacity"
                            [ngClass]="{ 'pane-expanded': isPaneExpanded[CAPACITY_PANE_ID] }"
                        >
                            <div
                                class="expanded-card-title"
                                (click)="togglePane(CAPACITY_PANE_ID)"
                                angulartics2On="click"
                                angularticsCategory="Action"
                                angularticsAction="Arrays - Section toggled"
                                angularticsLabel="Capacity"
                            >
                                <span>Capacity</span>
                                <div class="expanded-card-toggle-icon">
                                    <span class="toggle-icon-expanded">
                                        <span class="fa fa-caret-left"></span>
                                    </span>
                                    <span class="toggle-icon-collapsed">
                                        <span class="fa fa-caret-right"></span>
                                    </span>
                                </div>
                            </div>

                            <array-expanded-card-capacity
                                [array]="$any(array)"
                                [isExpanded]="isPaneExpanded[CAPACITY_PANE_ID]"
                            >
                            </array-expanded-card-capacity>
                        </div>

                        <!-- Health -->
                        <div
                            class="right-border expanded-card-placeholder expanded-card-pane-health"
                            [ngClass]="{ 'pane-expanded': isPaneExpanded[HEALTH_PANE_ID] }"
                        >
                            <div
                                class="expanded-card-title"
                                (click)="togglePane(HEALTH_PANE_ID)"
                                angulartics2On="click"
                                angularticsCategory="Action"
                                angularticsAction="Arrays - Section toggled"
                                angularticsLabel="Health"
                            >
                                <span>Health</span>
                                <div class="expanded-card-toggle-icon">
                                    <span class="toggle-icon-expanded">
                                        <span class="fa fa-caret-left"></span>
                                    </span>
                                    <span class="toggle-icon-collapsed">
                                        <span class="fa fa-caret-right"></span>
                                    </span>
                                </div>
                            </div>

                            <array-expanded-card-health
                                [array]="array"
                                [arrayHealth]="arrayHealth"
                                [isExpanded]="isPaneExpanded[HEALTH_PANE_ID]"
                            >
                            </array-expanded-card-health>
                        </div>

                        <!-- Performance -->
                        <div
                            class="expanded-card-placeholder expanded-card-pane-performance"
                            [ngClass]="{ 'pane-expanded': isPaneExpanded[PERFORMANCE_PANE_ID] }"
                        >
                            <div
                                class="expanded-card-title"
                                (click)="togglePane(PERFORMANCE_PANE_ID)"
                                angulartics2On="click"
                                angularticsCategory="Action"
                                angularticsAction="Arrays - Section toggled"
                                angularticsLabel="Performance"
                            >
                                <span>Performance</span>
                                <div class="expanded-card-toggle-icon">
                                    <span class="toggle-icon-expanded">
                                        <span class="fa fa-caret-left"></span>
                                    </span>
                                    <span class="toggle-icon-collapsed">
                                        <span class="fa fa-caret-right"></span>
                                    </span>
                                </div>
                            </div>

                            <array-expanded-card-performance
                                [array]="array"
                                [isExpanded]="isPaneExpanded[PERFORMANCE_PANE_ID]"
                            >
                            </array-expanded-card-performance>
                        </div>
                    </div>

                    <!-- Array is outdated -->
                    <div *ngIf="!array.isCurrent" class="appliance-not-current">
                        <pureui-reporting-icon></pureui-reporting-icon>
                        <p>Last updated {{ array.lastUpdated }}</p>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #showOutOfSupportContract>
                <ng-container
                    *ngIf="
                        arraysManager.isEndOfContractCardViewEnabled(eocEnabled, array.support_expiration_date);
                        else expiredView
                    "
                >
                    <appliance-out-of-contract-template [selectedArray]="array"></appliance-out-of-contract-template>
                </ng-container>
                <ng-template #expiredView>
                    <appliance-expired-template
                        [selectedArray]="array"
                        [analyticsHeader]="'Appliances - Expanded Card'"
                        [supportIconSize]="35"
                        [renewingIconSize]="16"
                        [selectedArrayExistingQuote]="selectedArrayExistingQuote"
                    >
                    </appliance-expired-template>
                </ng-template>
            </ng-template>

            <ng-template #showSuspended>
                <div class="appliance-not-current">
                    <pureui-svg svg="suspended.svg" height="30"></pureui-svg>
                    <p>Suspended</p>
                </div>
            </ng-template>
        </div>
    </div>

    <!-- Card back -->
    <array-card-back
        [array]="array"
        [alerts]="alerts"
        [removeInternalsFromDom]="!hasCardEverFlipped"
        (onFlipCard)="flipCard($event.event)"
    >
    </array-card-back>
</div>
