import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Directive, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[debounceChange][ngModel]',
})
export class DebounceChangeDirective implements OnInit, OnDestroy {
    @Input() readonly debounceWait = 300;
    @Output() readonly debounceChange = new EventEmitter();

    private subscription: Subscription;

    constructor(private control: NgModel) {}

    ngOnInit(): void {
        this.subscription = this.control.update
            .pipe(debounceTime(this.debounceWait))
            .subscribe(event => this.debounceChange.emit(event));
    }

    ngOnDestroy(): void {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }
}
