import {
    Component,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ChangeDetectionStrategy,
    Inject,
} from '@angular/core';
import { WINDOW } from '../../app/injection-tokens';

@Component({
    selector: 'pureui-pagination',
    templateUrl: 'pagination.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
    @Input() readonly totalItemCount: number;
    @Input() readonly offset: number;
    @Input() readonly itemsPerPage: number;
    @Output() readonly pageChange = new EventEmitter<number>();

    currentCount: string;

    constructor(@Inject(WINDOW) private window: Window) {}

    ngOnChanges(_changes: SimpleChanges): void {
        // Update on any change to the inputs
        const rangeStart = this.totalItemCount === 0 ? 0 : this.offset + 1;
        const rangeEnd =
            this.offset + this.itemsPerPage > this.totalItemCount
                ? this.totalItemCount
                : this.offset + this.itemsPerPage;

        this.currentCount = `${rangeStart}-${rangeEnd}`;

        // If we're provided with partial page, automatically step back to the immediately prior full page
        const remainder = this.offset % this.itemsPerPage;
        if (remainder !== 0 && !isNaN(remainder)) {
            this.window.setImmediate(() => {
                // Need this in a timeout to avoid circular updates (ExpressionChangedAfterItHasBeenCheckedError)
                this.selectPage(this.offset); // selectPage() will take care of the validation for us so we don't have to repeat it here
            });
        }
    }

    selectPage(newOffset: number): void {
        if (!this.itemsPerPage || !this.totalItemCount) {
            newOffset = 0;
        } else {
            newOffset = Math.floor(newOffset / this.itemsPerPage) * this.itemsPerPage; // Make sure the new offset is divisible by the itemsPerPage (no partial pages)
            newOffset = Math.max(newOffset, 0); // Don't allow negative pages
        }
        this.pageChange.emit(newOffset);
    }
}
