<ng-container *ngIf="!featureFlagEnabled">
    <label class="partner-info-title">Designated Partner</label>
    <section class="partner-info">
        <div class="partner-details">
            <label class="partner-details-title">Organization</label>
            <div class="partner-details-content">{{ selectedOrg | defaultString }}</div>
        </div>
        <div class="partner-details">
            <label class="partner-details-title">Recent Partner</label>
            <div class="partner-details-content">{{ selectedPartner?.contactName | defaultString }}</div>
        </div>
        <div class="modify-partner" (click)="modifyPartner()" *ngIf="isModifyButtonValidated()">
            <pureui-svg class="edit-icon" svg="edit.svg" height="12"></pureui-svg>
            <label class="modify-partner-title">Edit Designated Partner</label>
        </div>
    </section>
</ng-container>
<ng-container *ngIf="featureFlagEnabled">
    <label class="partner-info-title">Preferred Partner</label>
    <section class="partner-info-new" [ngClass]="{ editing: isEditing }">
        <ng-container *ngIf="!partnerOnQuote && !savedPartner && !isEditing">
            <div class="add-partner-panel" (click)="isEditing = true">
                <span class="add-sign">+</span>
                <label class="add-sign-label">Add Partner</label>
            </div>
        </ng-container>
        <ng-container *ngIf="isEditing">
            <button type="button" class="close modal-x-button" (click)="cancelEdit()">
                <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
            </button>
            <div class="partner-edit-actions">
                <div class="action-group partner-org-selection">
                    <label>Organization</label>
                    <div class="partner-org-dropdown">
                        <ng-select
                            [readonly]="isRenewalOrder"
                            [clearable]="false"
                            [placeholder]="selectedOrg ? '' : 'Organization'"
                            [searchable]="false"
                            [items]="partnerOrganizationOptions"
                            [ngModel]="selectedOrg"
                            (change)="partnerOrgSelectionChanged($event)"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="action-group partner-user-selection">
                    <label>Partner</label>
                    <ng-select
                        class="partner-user-dropdown"
                        [disabled]="!selectedOrg && !isRenewalOrder"
                        [clearable]="false"
                        bindLabel="contactName"
                        [placeholder]="selectedPartner ? '' : 'Partner'"
                        [searchable]="false"
                        [items]="generatePartnerNameList()"
                        [(ngModel)]="selectedPartner"
                    >
                    </ng-select>
                </div>
                <div class="action-group finalize-edit">
                    <label>&nbsp;</label>
                    <!-- Fill padding to match others -->
                    <div class="modify-partner-buttons">
                        <button class="btn btn-secondary cancel-button" (click)="cancelEdit()">Cancel</button>
                        <button class="btn btn-primary save-button" (click)="savePartner()">Save</button>
                    </div>
                </div>
            </div>
            <div class="add-new-prompt">
                Didn't find your partner?
                <button class="add-new-partner-button" (click)="modifyPartner()">ADD NEW</button>
            </div>
        </ng-container>
        <div class="partner-details-section" *ngIf="partnerOnQuote || savedPartner || isEditing">
            <pure-avatar
                [user]="selectedPartner?.contactName || selectedPartner?.accountName | defaultString"
                [height]="34"
            ></pure-avatar>
            <div class="partner-details">
                <div class="partner-name">{{ selectedPartner?.contactName | defaultString }}</div>
                <div class="partner-summary">
                    <section class="partner-detail">
                        <pureui-svg svg="building.svg" width="9"></pureui-svg>
                        <div class="partner-details-content">{{ selectedPartner?.accountName | defaultString }}</div>
                    </section>
                    <section class="partner-detail" *ngIf="selectedPartner?.contactPhoneNumber">
                        <pureui-svg svg="phone.svg" width="12"></pureui-svg>
                        <div class="partner-details-content">{{ selectedPartner.contactPhoneNumber }}</div>
                    </section>
                    <section class="partner-detail" *ngIf="selectedPartner?.contactEmail">
                        <pureui-svg svg="email-trimmed.svg" width="12"></pureui-svg>
                        <div class="partner-details-content"> {{ selectedPartner.contactEmail }}</div>
                    </section>
                    <div
                        class="modify-partner"
                        (click)="modifyPartner(selectedPartner)"
                        *ngIf="isModifyButtonValidated() && isEditing && selectedPartner"
                    >
                        <pureui-svg class="edit-icon" svg="edit.svg" width="8"></pureui-svg>
                        <label class="modify-partner-title">Edit</label>
                    </div>
                </div>
            </div>
            <div
                class="modify-partner form-closed"
                (click)="editPartner()"
                *ngIf="isModifyButtonValidated() && !isEditing"
            >
                <pureui-svg class="edit-icon" svg="edit.svg" height="12"></pureui-svg>
                <label class="modify-partner-title">Edit</label>
            </div>
        </div>
    </section>
</ng-container>
<ng-template #modifyPartnerModal let-modal>
    <modify-partner-modal
        [activeModal]="modal"
        [partnersMapping]="oldPartnersMapping"
        [activeOrder]="activeOrder"
        [partnerToUpdate]="partnerToUpdate"
        (partnerRequestChanged)="onPartnerRequestChanged($event)"
    >
    </modify-partner-modal>
</ng-template>
