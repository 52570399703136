import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionEvent } from '@pure/hive';

import { PGroupSafemode, Resource, UnifiedArray } from '@pure1/data';
import { map, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GetPgroupNamePipe } from '../../pipes/get-pgroup-name.pipe';
import { MAX_SELECTED_APPLIANCES } from '../../services/safemode-multiparty-authorization.service';
import { SafemodePgroupsService } from '../../services/safemode-pgroups.service';
import { TableDynamicDataSource } from '../../table-utils/table-dynamic-data-source';
import { getUniqueArrayIdsOfArraysAndPGroups } from '../../util';

@Component({
    selector: 'safemode-pgroups-selector-multiparty-auth',
    templateUrl: 'safemode-pgroups-selector-multiparty-auth.component.html',
})
export class SafeModePGroupsSelectorMultipartyAuthComponent implements OnInit, OnDestroy {
    @Input() initialSelection: PGroupSafemode[];
    @Input() selectedArrays: UnifiedArray[];
    @Output() readonly onCancel = new EventEmitter();
    @Output() readonly onForward = new EventEmitter<PGroupSafemode[]>();

    dataSource: TableDynamicDataSource<PGroupSafemode, SafemodePgroupsService> = new TableDynamicDataSource<
        PGroupSafemode,
        SafemodePgroupsService
    >();

    // PGroup of selected PGroups
    // do not manipulate this directly, use selectPGroup and unselectPGroup instead
    selectedPGroups: PGroupSafemode[] = [];

    selectedResources: Resource[] = [];

    nameFilter: FormControl<string> = new FormControl<string>('');
    arrayNameFilter: FormControl<string> = new FormControl<string>('');

    private readonly destroy$ = new Subject<void>();

    readonly maxSelectedAppliances = MAX_SELECTED_APPLIANCES;

    constructor(
        private getPgroupName: GetPgroupNamePipe,
        private safemodePgroupService: SafemodePgroupsService,
    ) {}

    ngOnInit(): void {
        this.dataSource.initialize(
            this.safemodePgroupService,
            'safemode-pgroups-list',
            ['name', 'volumeCount', 'retentionLock'],
            null,
            false,
        );
        this.nameFilter.valueChanges
            .pipe(debounceTime(400), takeUntil(this.destroy$))
            .pipe(
                map(value => {
                    return value.replace('/', '::');
                }),
            )
            .subscribe((value: string) => {
                this.dataSource.filterChanged({ entity: 'safemode-pgroups-list', key: 'name', value: value });
            });
        this.arrayNameFilter.valueChanges
            .pipe(debounceTime(400), takeUntil(this.destroy$))
            .subscribe((value: string) => {
                this.dataSource.filterChanged({ entity: 'safemode-pgroups-list', key: 'arrays.name', value: value });
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initialize(): void {
        this.selectedPGroups = this.initialSelection;
        this.updateSelectedResources();
    }

    // builds output data structure and emits onForward event
    onNext(): void {
        this.onForward.emit(this.selectedPGroups);
    }

    updateSelectedPGroups(updatedResources: Resource[]): void {
        this.selectedResources = updatedResources;
        this.selectedPGroups = this.selectedPGroups.filter(pgroup =>
            updatedResources.find(updatedResource => updatedResource.id === pgroup.id),
        );
    }

    togglePGroupSelection(pGroupSelection: SelectionEvent<PGroupSafemode>): void {
        this.selectedPGroups = pGroupSelection.data;
        this.updateSelectedResources();
    }

    updateSelectedResources(): void {
        this.selectedResources = [];
        this.selectedPGroups.forEach(pgroup => {
            const pgroupName = this.getPgroupName.transform(pgroup);
            this.selectedResources.push({ id: pgroup.id, name: pgroupName });
        });
    }

    canSchedule({
        selectedPGroups,
        selectedArrays,
    }: {
        selectedPGroups: PGroupSafemode[];
        selectedArrays: UnifiedArray[];
    }): boolean {
        if (!selectedPGroups) {
            return false;
        }
        return getUniqueArrayIdsOfArraysAndPGroups(selectedArrays, selectedPGroups).size <= MAX_SELECTED_APPLIANCES;
    }

    reachedPGroupLimit({
        selectedPGroups,
        selectedArrays,
    }: {
        selectedPGroups: PGroupSafemode[];
        selectedArrays: UnifiedArray[];
    }): boolean {
        if (!selectedPGroups) {
            return false;
        }
        return getUniqueArrayIdsOfArraysAndPGroups(selectedArrays, selectedPGroups).size > MAX_SELECTED_APPLIANCES;
    }
}
