import {
    DraasApiAWSProvider,
    DraasApiDatastore,
    DraasApiExtendedAWSNetwork,
    DraasApiVSphereDatastore,
    DraasApiVSphereNetwork,
    DraasApiVSphereProvider,
} from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';
import { DisasterRecoveryNode } from '@pure1/data';

export class DisasterRecoveryProvider {
    id: string;
    name: string;
    type: 'VSPHERE' | 'AWS';
    state: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
    lastUpdatedAt: moment.Moment;
    providerVersion: number;

    constructor(json: DraasApiVSphereProvider | DraasApiAWSProvider) {
        this.id = json.provider_id;
        this.type = json.provider_type;
        this.name = json.name ?? `${json.provider_type}:${json.provider_id}`;
        this.state = json.status;
        this.lastUpdatedAt = json.provider_last_updated_at_utc ? moment.utc(json.provider_last_updated_at_utc) : null;
        this.providerVersion = json.provider_version;
    }
}

export class DisasterRecoveryVSphereProvider extends DisasterRecoveryProvider {
    hostname: string;
    network: VSphereProviderNetwork;
    storage: VSphereProviderStorage;

    constructor(json: DraasApiVSphereProvider) {
        super(json);
        this.hostname = json.hostname;
        this.network = json.network ? new VSphereProviderNetwork(json.network) : null;
        this.storage = json.data_storage ? new VSphereProviderStorage(json.data_storage) : null;
    }

    isComplete(nodes: DisasterRecoveryNode[]): boolean {
        return (
            this.state === 'VALID' &&
            this.network != null &&
            this.storage != null &&
            nodes.length > 0 &&
            nodes.every(node => node.status === 'HEALTHY' || node.status === 'READY')
            // Why the fuck doesn't API return this
        );
    }
}

export class VSphereProviderNetwork {
    gatewayIp: string | null;
    managementCidr: string | null;
    dnsIps: string[] | null;
    domainNames: string[] | null;
    externalCidr: string | null;
    internalCidr: string | null;

    constructor(json: DraasApiVSphereNetwork) {
        this.gatewayIp = json.gateway_ip ?? null;
        this.managementCidr = json.management_cidr ?? null;
        this.dnsIps = json.dns_ips ?? null;
        this.domainNames = json.domain_names ?? null;
        this.externalCidr = json.external_cidr ?? null;
        this.internalCidr = json.internal_cidr ?? null;
    }
}

export class VSphereProviderStorage {
    datastores: VSphereProviderDatastore[];

    constructor(json: any) {
        this.datastores = json.datastores.map(datastore => new VSphereProviderDatastore(datastore));
    }
}

export class VSphereProviderDatastore {
    dataStoreId: string;
    currentBytes: number | null;
    quotaBytes: number;
    used: boolean;

    constructor(json: DraasApiVSphereDatastore) {
        this.dataStoreId = json.datastore;
        this.currentBytes = json.current_bytes ?? null;
        this.quotaBytes = json.quota_bytes;
        this.used = json.used;
    }
}

export class DisasterRecoveryAwsProvider extends DisasterRecoveryProvider {
    network: AwsProviderNetwork | null;

    constructor(json: DraasApiAWSProvider) {
        super(json);
        this.network = json.network ? new AwsProviderNetwork(json.network) : null;
    }

    isComplete(nodes: DisasterRecoveryNode[]): boolean {
        return (
            this.network != null &&
            nodes.length > 0 &&
            nodes.every(node => node.status === 'HEALTHY' || node.status === 'READY')
            // Why the fuck doesn't API return this
        );
    }
}

export class AwsProviderNetwork {
    existingVpcId: string | null;
    vpcCidr: string | null;

    constructor(json: DraasApiExtendedAWSNetwork) {
        this.existingVpcId = json.existing_vpc_id;
        this.vpcCidr = json.vpc_cidr;
    }
}
