import { Resource } from '../interfaces/resource';

export class AllowListedAccountLabel implements Resource {
    readonly id: string;
    label: string;
    name: string;

    constructor(json: any) {
        this.id = json.id;
        this.label = json.label;
        this.name = json.label;
    }

    toRequestBody(): string {
        return '["' + this.label + '"]';
    }
}
