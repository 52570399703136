import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { FileSystem } from '../models/file-system';

@Injectable({ providedIn: 'root' })
export class FileSystemService extends GenericService<FileSystem> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: FileSystem,
            endpoint: '/rest/v4/file-systems',
        });
    }
}
