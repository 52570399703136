import { FlashBladeConfigState } from '../../services/flash-blade-allowable-user-action-service';

export interface FlashBladeCapacityConfigCompParams {
    chassis: FlashBladeChassis[];
    numberOfDrivesPerBlade: number;
    driveRawTB: number;
    increasedUsableTiB: number;

    // these fields are added later in SPoG API, keeping these optional for backward compatibility
    nominalHeat?: number;
    nominalPower?: number;

    // peak heat and power is not consume anywhere yet.
    peakHeat?: number;
    peakPower?: number;

    model?: string;
}

interface FlashBladeChassis {
    numberOfBlades: number;
}

/**
    keys: actions under question
    values:
        null: if the action is not allowable i.e. will take to a configuration that is not found in perfCalc's response.
        FlashBladeConfigState: i.e. not null which tells the action is allowable, also contains configuration's state info according to perfCalc.
    exception:
        minNumOfBlades - the minimum number of blades for this configuration
        currentConfigState - needed to show this info (raw, usable, heat, power) in Capacity-table
 */
export interface FlashBladeCapacityConfigAllowableActions {
    removeBlade: FlashBladeConfigState | null;
    addBlade: FlashBladeConfigState | null;
    addChassis: FlashBladeConfigState | null;
    removeChassis: FlashBladeConfigState | null;
    setDriveCount1: FlashBladeConfigState | null;
    setDriveCount2: FlashBladeConfigState | null;
    setDriveCount3: FlashBladeConfigState | null;
    setDriveCount4: FlashBladeConfigState | null;
    setDriveSize24: FlashBladeConfigState | null;
    setDriveSize48: FlashBladeConfigState | null;
    setDriveSize75: FlashBladeConfigState | null;
    setFBEDriveSize48: FlashBladeConfigState | null;
    setFBEDriveSize75: FlashBladeConfigState | null;
    addHalfChassis: FlashBladeConfigState | null;
    removeHalfChassis: FlashBladeConfigState | null;

    // not actions, config info needed from allowableOptions to show in parent components
    minNumOfBlades: number;
    currentConfigState: FlashBladeConfigState;
}

export const FLASH_BLADE_S_DRIVE_SIZES = [24, 48.2, 75];
export const FLASH_BLADE_S_DEFAULT_USABLE_TIB = 81.8;

export const FLASH_BLADE_E_DRIVE_SIZES = [48.2];
export const FLASH_BLADE_E_DRIVE_SIZES_NEW = [48.2, 75];
export const FLASH_BLADE_E_DEFAULT_USABLE_TIB = 2694.12;
export const FLASH_BLADE_E_MAX_X_PER_C = 3;

export const FLASH_BLADE_SLOTS_PER_CHASSIS = 10;
