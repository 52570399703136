import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    ValidationErrors,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    UntypedFormControl,
} from '@angular/forms';
import { PxCluster, License } from '@pure1/data';
import { isNil } from 'lodash';

import { PureArray } from '../../model/PureArray';
import { CaseSubCategory, ProductLine, SubCategoryOption } from '../support.interface';
import { getSubCategoryOption, SUB_CATEGORY_GROUP } from '../support.utils';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'product-section',
    templateUrl: 'product-section.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProductSectionComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ProductSectionComponent),
            multi: true,
        },
    ],
})
export class ProductSectionComponent implements OnInit, ControlValueAccessor {
    readonly ampli = ampli;

    @Input() searchArrayName: string;
    @Input() initialProductLine: ProductLine;
    @Input() initialSubCategory: CaseSubCategory;
    @Input() initialProduct: PureArray | PxCluster | License | string | any;
    @Input() isBeingEdited: boolean;
    @Input() checkSupportContract: boolean;
    @Input() subCategoryOptions: SubCategoryOption[];

    productForm: UntypedFormGroup;
    oldProductGroup: string;
    readonly subCategoryGroup = SUB_CATEGORY_GROUP;

    constructor(private fb: UntypedFormBuilder) {}
    onTouched = (_: any) => {};

    ngOnInit() {
        this.productForm = this.fb.group({
            subCategory: [null, Validators.required], // initial value is set in determineInitialSubCategoryOption
            selectedProduct: new UntypedFormControl(this.initialProduct, {
                validators: [this.validateSelectedProduct],
            }),
        });
        this.determineInitialSubCategoryOption();
    }

    determineInitialSubCategoryOption() {
        // category cannot be changed
        if (this.isBeingEdited) {
            let subCategoryOption = getSubCategoryOption(this.initialProductLine, this.initialSubCategory);
            // this is a total backup, we don't know what to show
            if (!subCategoryOption) {
                subCategoryOption = {
                    group: this.initialProductLine,
                    displayName: 'Other',
                    subCategory: this.initialSubCategory,
                    productLine: this.initialProductLine,
                };
            }
            this.productForm?.controls.subCategory.setValue(subCategoryOption);
            return;
        }

        let productLine = this.initialProductLine;
        let subCategory = this.initialSubCategory;

        if (isNil(this.initialProductLine) || isNil(this.initialSubCategory)) {
            productLine = ProductLine.FlashArray;
            subCategory = CaseSubCategory.HARDWARE_HARDWARE;
        }

        const subCategoryOption = getSubCategoryOption(productLine, subCategory);
        this.productForm?.controls.subCategory.setValue(subCategoryOption);
        this.oldProductGroup = subCategoryOption.group;
    }

    // Even that === is the default for ng-select, for some reason it's not working
    matchSubCategory(a: SubCategoryOption, b: SubCategoryOption): boolean {
        return a === b;
    }

    onSubCategoryChange(newValue: SubCategoryOption): void {
        if (!newValue) {
            return;
        }
        this.ampli.supportCaseModalCategorySelected({
            label: newValue.group + ':' + newValue.displayName,
        });
        if (this.oldProductGroup !== newValue.group) {
            this.productForm.controls.selectedProduct.setValue(null);
        }
        this.oldProductGroup = newValue.group;
    }

    writeValue(val: any): void {
        val && this.productForm.setValue(val, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.productForm.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.productForm.disable() : this.productForm.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (this.shouldHaveProductSelected() && this.productForm.controls.selectedProduct.invalid) {
            return { invalidForm: { valid: false, message: 'missing product when required' } };
        } else {
            return this.productForm.controls.subCategory.valid
                ? null
                : { invalidForm: { valid: false, message: 'product subcategory is invalid' } };
        }
    }

    shouldHaveProductSelected(): boolean {
        return [
            this.subCategoryGroup.FlashArray,
            this.subCategoryGroup.FlashBlade,
            this.subCategoryGroup.Portworx,
            this.subCategoryGroup.EvergreenOne,
            this.subCategoryGroup.DisasterRecovery,
        ].includes(this.productForm?.controls.subCategory?.value?.group);
    }

    validateSelectedProduct = (control: UntypedFormControl): ValidationErrors => {
        const value = control.value;
        let isInvalid = false;
        if (this.shouldHaveProductSelected()) {
            // this is required from https://github.com/angular/angular/blob/main/packages/forms/src/validators.ts#L209
            isInvalid = value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
        }
        return isInvalid ? { array: 'A valid product name is required' } : null;
    };

    /**
     * Generates a trackBy function suitable for ng-select, as its signature is
     * (item: any) => any, and not (index, item) => any like it is in angular.
     */
    makeTrackByFn(): (item: any) => any {
        return function (item: any): any {
            return `${item.group}:${item.displayName}`;
        };
    }
}
