import { ReplicaLink } from './replica-link';
import { Policy } from './policy';

export class FileSystemReplicaLink extends ReplicaLink {
    policies: Policy[];

    constructor(json: any) {
        super(json);

        this.policies = this.parsePolicies(json.policies);
    }

    private parsePolicies(json: any[]): Policy[] {
        if (!json) {
            return null;
        }

        return json.map(policyJson => new Policy(policyJson));
    }
}
