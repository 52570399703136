<main id="switch-user-modal">
    <form name="switchUserForm" #f="ngForm" (ngSubmit)="switchUser()">
        <!-- Title -->
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <h3 class="modal-title">Switch User</h3>
        </div>
        <div class="modal-body">
            <!-- Loaded -->
            <div id="switch-user-form">
                <!-- Overlay type -->
                <div class="form-group row">
                    <label class="col-xs-3 col-form-label text-xs-right">Switch To</label>

                    <div class="col-xs-9">
                        <div class="uib-typeahead-wrapper">
                            <input
                                required
                                type="email"
                                [(ngModel)]="email"
                                id="email-input"
                                name="email-input"
                                #emailInput="ngModel"
                                [pattern]="emailRegex"
                                placeholder="User's email"
                                [ngbTypeahead]="search"
                                (focus)="focus$.next($any($event.target).value)"
                                (click)="click$.next($any($event.target).value)"
                                #typeInstance="ngbTypeahead"
                                class="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="emailInput.invalid"> Switch </button>
        </div>
    </form>
</main>
