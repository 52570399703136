import moment from 'moment';
import { MetricGroup } from '@pure1/data';

import { isFArrayProduct, isFBladeProduct } from '../model/model';
import { formatApproxDuration } from './formatApproxDuration';
import { Chart } from 'highcharts/highstock';

// TODO: RGBA should just be a separate class... and move the related PureUtils funcs into there (eg colorStringToRgba())
class RGBA {
    constructor(
        public readonly r: number,
        public readonly g: number,
        public readonly b: number,
        public readonly a: number,
    ) {}

    toRGBAString(): string {
        return `rgba(${this.r},${this.g},${this.b},${this.a})`;
    }

    darken(percent: number): RGBA {
        return this.multiplyRGB(1 - percent);
    }

    lighten(percent: number): RGBA {
        return this.multiplyRGB(1 + percent);
    }

    private multiplyRGB(p: number): RGBA {
        return new RGBA(this.clampColor(this.r * p), this.clampColor(this.g * p), this.clampColor(this.b * p), this.a);
    }

    private clampColor(c: number): number {
        return Math.max(0, Math.min(255, Math.round(c)));
    }
}

// Available granularities. Taken from AVAILABLE_GRANULARITIES on the backend.
const TIMESERIES_GRANULARITIES_MS: number[] = [
    moment.duration(30, 'seconds').asMilliseconds(),
    moment.duration(2, 'minutes').asMilliseconds(),
    moment.duration(3, 'minutes').asMilliseconds(),
    moment.duration(5, 'minutes').asMilliseconds(),
    moment.duration(10, 'minutes').asMilliseconds(),
    moment.duration(15, 'minutes').asMilliseconds(),
    moment.duration(20, 'minutes').asMilliseconds(),
    moment.duration(30, 'minutes').asMilliseconds(),
    moment.duration(1, 'hours').asMilliseconds(),
    moment.duration(2, 'hours').asMilliseconds(),
    moment.duration(3, 'hours').asMilliseconds(),
    moment.duration(6, 'hours').asMilliseconds(),
    moment.duration(8, 'hours').asMilliseconds(),
    moment.duration(12, 'hours').asMilliseconds(),
    moment.duration(1, 'days').asMilliseconds(),
];

/**
 * Collection of various general utility methods not worthy of their own service
 */
export default class PureUtils {
    /**
     * Splits a color string into RGBA format, where the RGB are 0-255, and alpha is 0-1.
     * Returns null if the string is not in a valid or supported color format.
     * @param str The color string. Can be in the format of #RRGGBB, rgb(), or rgba().
     */
    static colorStringToRgba(str: string): RGBA {
        // Validate input
        if (!str || str.length < 1) {
            return null;
        }

        // Trim the string so we don't have to do it in each regex since we want to support strings that start/end
        // with whitespaces since the input may not always be nice and clean
        str = str.trim();

        // Match the hex form of: #rrggbb
        const hexRegex = /^#([0-9a-f]{6})$/i;
        const hexMatch = str.match(hexRegex);
        if (hexMatch) {
            const rrggbb = hexMatch[1];
            return new RGBA(
                parseInt(rrggbb.substr(0, 2), 16),
                parseInt(rrggbb.substr(2, 2), 16),
                parseInt(rrggbb.substr(4, 2), 16),
                1,
            );
        }

        // Match the form of: rgb()
        const rgbRegex = /^rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i;
        const rgbMatch = str.match(rgbRegex);
        if (rgbMatch) {
            return new RGBA(Number(rgbMatch[1]), Number(rgbMatch[2]), Number(rgbMatch[3]), 1);
        }

        // Match the form of: rgba()
        const rgbaRegex = /^rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d\.]+)\s*\)$/i;
        const rgbaMatch = str.match(rgbaRegex);
        if (rgbaMatch) {
            return new RGBA(Number(rgbaMatch[1]), Number(rgbaMatch[2]), Number(rgbaMatch[3]), Number(rgbaMatch[4]));
        }

        // Unknown or invalid format
        return null;
    }

    /**
     * returns a string in terms of days/weeks/months/>1year as duration instead of # of days
     */
    static formatProjectionDuration(days: number): string {
        const duration = moment.duration(days, 'days');
        if (duration.asYears() >= 1) {
            return '>1 year';
        } else {
            const time = formatApproxDuration(0, duration.asMilliseconds());
            return time.value + ' ' + time.unit;
        }
    }

    /**
     * Returns the equivilent of the media query of the format: (min-width: ###px)
     */
    static matchesCSSMinWidth($window: Window, minWidthPx: number): boolean {
        // See: http://stackoverflow.com/questions/19291873/window-width-not-the-same-as-media-query

        // Use window.matchMedia if it is available since that will give us the most accurate result.
        if ($window.matchMedia) {
            const mediaQuery = `(min-width: ${minWidthPx}px)`;
            return $window.matchMedia(mediaQuery).matches;
        }

        // If window.matchMedia is not available, fall back to window.innerWidth(), which won't be as reliable.
        const windowWidth = $($window).innerWidth();
        return windowWidth >= minWidthPx;
    }

    /**
     * Trims out the excess data received from the server. Returns only the data within the chart window, plus the first point
     * before and after the window to allow for trendlines leading into/out of the chart.
     */
    static trimTimeseriesSeriesData(
        values: ITimeseriesValues,
        startTime: moment.Moment,
        endTime: moment.Moment,
    ): ITimeseriesValues {
        const startPoint = Math.max(this.findFirstPointIndexWhereXGreaterEqThan(values, startTime.valueOf()) - 1, 0);
        const endPoint = Math.min(
            this.findLastPointIndexWhereXLessEqThan(values, endTime.valueOf()) + 2,
            values.length,
        );

        return values.slice(startPoint, endPoint);
    }

    static setChartYAxisForPercentageNumbers(chart: Chart, redrawAtEnd = true): void {
        // Set max to be 1 for percentage numbers
        const yMax = 1;

        chart.yAxis[0].update(
            {
                max: yMax,
                min: 0,
            },
            false,
        );

        chart.series.forEach(series => {
            if (series.visible) {
                series.yAxis.setExtremes(0, yMax, false);
            }
        });

        if (redrawAtEnd) {
            chart.redraw();
        }
    }

    static setChartYAxisForBinaryNumbers(chart: Chart, redrawAtEnd = true): void {
        // Find the y-axis max of all series in this chart
        let yMax = 4096;
        let tickIntervalDist = 1024;
        chart.series.forEach(series => {
            if (series.visible) {
                yMax = Math.max(yMax, series.yAxis.getExtremes().dataMax);
            }
        });

        // Increase the yMax to form a 'nice, round' number when passed through a filter that formats binary numbers.
        if (yMax !== 4096) {
            let oneFifthOfyMax = yMax / 5;

            // First, get the single pre-decimal value
            let powOf10 = 0;
            let tempFifth = oneFifthOfyMax;
            while (tempFifth >= 10) {
                powOf10++;
                tempFifth = tempFifth / 10;
            }
            const tempFifthRoundedUp = Math.ceil(tempFifth);

            oneFifthOfyMax = tempFifthRoundedUp * Math.pow(10, powOf10);

            let powOf1024 = 0;
            tempFifth = oneFifthOfyMax;
            while (tempFifth >= 1024) {
                powOf1024++;
                tempFifth = tempFifth / 1024;
            }

            tickIntervalDist = oneFifthOfyMax * Math.pow(1.024, powOf1024);
            yMax = oneFifthOfyMax * 5;
        }

        chart.yAxis[0].update(
            {
                max: yMax,
                min: 0,
                tickInterval: tickIntervalDist,
            },
            false,
        );

        chart.series.forEach(series => {
            if (series.visible) {
                series.yAxis.setExtremes(0, yMax, false);
            }
        });

        if (redrawAtEnd) {
            chart.redraw();
        }
    }

    static createMapGroupByProperty<T extends object>(items: T[], property: string): Map<string, T[]> {
        const map = new Map<string, T[]>();

        items.forEach((item: T) => {
            if (property in item) {
                PureUtils.appendInMap(map, <string>item[property], item);
            }
        });
        return map;
    }

    /**
     * Checks if two sets have the same keys
     * @returns True if both sets are same null type, or both are not and contains the same keys
     */
    static areSetsEqual<T>(a: Set<T>, b: Set<T>): boolean {
        if (a === b) {
            return true;
        }

        if ((a == null && b != null) || (a != null && b == null)) {
            return false; // One is null, the other is not
        }

        if (a.size !== b.size) {
            return false;
        }

        // Ideally we'd break early, but we don't seem to have for/of support yet for sets, and this is cheaper than Array.from()
        let isEqual = true;
        a.forEach(key => {
            isEqual = isEqual && b.has(key);
        });
        return isEqual;
    }

    /**
     * Given an entity (IPureArray, IPureVolume, etc.) is mirrored write applicable?
     * Currently only IPureArray with product = 'flash-array' and version >= 5.0.0
     */
    static isMirroredWriteMetricApplicable(entity: IPureAnalyticsSourceEntity): boolean {
        if (entity instanceof MetricGroup) {
            return true; // Always show MW for groups
        }

        if (entity && isFArrayProduct(entity.product)) {
            const array = <IPureArray>entity;
            const matches = array.version?.match(/^(\d+)\./);
            return matches && Number(matches[1]) >= 5;
        }
        return false;
    }

    /**
     * Given an entity (IPureArray, IPureVolume, etc.) is other applicable?
     * Currently only IPureArray with product = 'flash-blade'
     */
    static isOtherMetricApplicable(entity: IPureAnalyticsSourceEntity): boolean {
        if (entity instanceof MetricGroup) {
            return true; // Always show Other for groups
        }

        return entity && isFBladeProduct(entity.product);
    }

    /**
     * Performs a binary search for an element in a sorted array.
     * @param array The sorted array.
     * @param findElem The element to find in the sorted array.
     * @param comparer Comparator function with two arguments: a and b. Returns < 0 if a < b, 0 if a == b, and > 0 if a > b.
     * @returns {number} The index of an element that equals findElem, or -1 if the element is not in the array.
     */
    static binarySearchIndex<T, U>(array: T[], findElem: U, comparer: (a: U, b: T) => number): number {
        let minIdx = 0;
        let maxIdx = array.length - 1;

        while (minIdx <= maxIdx) {
            // eslint-disable-next-line no-bitwise
            const currIdx = ((maxIdx + minIdx) / 2) | 0;
            const cmp = comparer(findElem, array[currIdx]);

            if (cmp > 0) {
                minIdx = currIdx + 1;
            } else if (cmp < 0) {
                maxIdx = currIdx - 1;
            } else {
                return currIdx;
            }
        }

        return -1;
    }

    /**
     * Performs a binary search for an element in a sorted array.
     * @param array The sorted array.
     * @param findElem The element to find in the sorted array.
     * @param comparer Comparator function with two arguments: a and b. Returns < 0 if a < b, 0 if a == b, and > 0 if a > b.
     * @returns {T} The element that equals findElem, or null if the element is not in the array.
     */
    static binarySearch<T, U>(array: T[], findElem: U, comparer: (a: U, b: T) => number): T {
        const index = PureUtils.binarySearchIndex(array, findElem, comparer);
        return index >= 0 ? array[index] : null;
    }

    /**
     * Temporary method for getting the granularity from the timeseries values.
     * This is only here until CLOUD-9343 is implemented, after which we can remove this method.
     */
    static getTimeSeriesGranularity(valuesMap: { [metricName: string]: ITimeseriesValues }): moment.Duration {
        // Use the metric with the most values
        const values = Object.values(valuesMap).sort(
            (a: ITimeseriesValues, b: ITimeseriesValues) => (b || []).length - (a || []).length,
        )[0];

        let minXDist: number;
        if (values && values.length > 1) {
            // Get the min time distance between two points
            minXDist = TIMESERIES_GRANULARITIES_MS[TIMESERIES_GRANULARITIES_MS.length - 1]; // Start with largest value
            for (let i = 1; i < values.length; i++) {
                const xDist = values[i][0] - values[i - 1][0];
                if (xDist < minXDist && xDist > 0) {
                    minXDist = xDist;
                }
            }
        } else {
            // We have < 2 points, so just assume highest granularity
            minXDist = TIMESERIES_GRANULARITIES_MS[0];
        }

        // Get the closest valid granularity to the minXDist
        const granularity = TIMESERIES_GRANULARITIES_MS.sort(
            (a, b) => Math.abs(a - minXDist) - Math.abs(b - minXDist),
        )[0];

        return moment.duration(granularity, 'milliseconds');
    }

    /**
     * convert object to url params
     */
    static toUrlParams(data: { [key: string]: any }): string {
        return Object.keys(data)
            .sort((a, b) => a.localeCompare(b)) // Sort to keep the order deterministic, even though it should be irrelevant
            .filter(key => data[key] !== null && data[key] !== undefined)
            .map(key => key + '=' + data[key])
            .join('&');
    }

    /**
     * Gets the css class to use for the capacity dial arc, based on how full the array is.
     */
    static getCapacityDialCssClass(normalizedPercFull: number): string {
        if (normalizedPercFull >= 100) {
            return 'critical';
        } else if (normalizedPercFull >= 90) {
            return 'warning';
        } else {
            return 'ok';
        }
    }

    /**
     * Gets a random 8-character string suitable for use as an id
     */
    static getRandomId(): string {
        return Math.random().toString(36).substring(2, 10);
    }

    /**
     * Gets the index of the first point >= the given value, or the index after the last point (values.length) if no points are >= findValue.
     */
    private static findFirstPointIndexWhereXGreaterEqThan(values: ITimeseriesValues, findValue: number): number {
        // Search from start because the point we're looking for is going to be closer to the head
        for (let i = 0; i < values.length; i++) {
            if (values[i][0] >= findValue) {
                return i;
            }
        }
        return values.length;
    }

    /**
     * Gets the index of the last point <= the given value, or the index before the first point (-1) if no points are <= findValue
     */
    private static findLastPointIndexWhereXLessEqThan(values: ITimeseriesValues, findValue: number): number {
        // Search in reverse because the point we're looking for is going to be closer to the tail
        for (let i = values.length - 1; i >= 0; i--) {
            if (values[i][0] <= findValue) {
                return i;
            }
        }
        return -1;
    }

    private static appendInMap<K, V>(map: Map<K, V[]>, key: K, value: V): void {
        if (key) {
            if (map.has(key)) {
                map.get(key).push(value);
            } else {
                map.set(key, [value]);
            }
        }
    }
}
