import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { BYTES_IN_TiB } from '@pure1/data';

/**
 * convert bytes to TiB and format value
 */

@Pipe({ name: 'formatTiB' })
export class FormatTiBPipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');

    static toTiB(bytes: number): number {
        return bytes / BYTES_IN_TiB;
    }

    static fromTiB(tibs: number): number {
        return tibs * BYTES_IN_TiB;
    }

    /**
     * @param bytes number of bytes
     * @param decimal number of decimal for the output value in TiB format
     * @param withUnit append TiB unit
     * @param forceDecimals display zero decimals
     */
    transform(bytes: number, decimal: number, withUnit = true, forceDecimals = true): string {
        if (typeof bytes !== 'number' || isNaN(bytes)) {
            return null;
        }
        decimal = decimal || 0;
        const minDecimal = forceDecimals ? decimal : 0;
        const unit = 'TiB';
        const value = FormatTiBPipe.toTiB(bytes);
        const val = FormatTiBPipe.decimalPipe.transform(value, `1.${minDecimal}-${decimal}`);

        return withUnit ? `${val} ${unit}`.trim() : `${val}`.trim();
    }
}
