import moment from 'moment';

class Comparator<T> implements IComparator<T> {
    asc: IComparisonFunction<T>;
    desc: IComparisonFunction<T>;

    constructor(fn: IComparisonFunction<T>) {
        this.asc = fn;
        this.desc = this.reverse(fn);
    }

    private reverse<T>(comp: IComparisonFunction<T>): IComparisonFunction<T> {
        return function (a: T, b: T): number {
            return comp(b, a);
        };
    }
}

export function forMoment<T>(getProp: (obj: T) => moment.Moment | number, nullLast = true): IForComparator<T> {
    const compareFn = function (a: any, b: any): number {
        const valA = getProp(a);
        const valB = getProp(b);
        if (valA == null) {
            return nullLast ? 1 : -1;
        }
        if (valB == null) {
            return nullLast ? -1 : 1;
        }
        return moment(valA).valueOf() - moment(valB).valueOf();
    };
    return new Comparator(compareFn);
}

export function forNumber<T>(getProp: (obj: T) => number, nullLast = true): IForComparator<T> {
    const compareFn = function (a: any, b: any): number {
        const valA = getProp(a);
        const valB = getProp(b);
        if (valA == null) {
            return nullLast ? 1 : -1;
        }
        if (valB == null) {
            return nullLast ? -1 : 1;
        }
        return valA - valB;
    };
    return new Comparator(compareFn);
}

export function forString<T>(getProp: (obj: T) => string, nullLast = true): IForComparator<T> {
    const compareFn = function (a: T, b: T): number {
        const valA = getProp(a);
        const valB = getProp(b);
        if (valA == null) {
            return nullLast ? 1 : -1;
        }
        if (valB == null) {
            return nullLast ? -1 : 1;
        }
        return valA.localeCompare(valB);
    };
    return new Comparator(compareFn);
}

export function joinComparators<T>(...comparators: IForComparator<T>[]): IForComparator<T> {
    const compareFn = (a: T, b: T): number => {
        let comparisonValue = 0;
        let i = 0;
        while (i < comparators.length && comparisonValue === 0) {
            comparisonValue = comparators[i].asc(a, b);
            i++;
        }
        return comparisonValue;
    };
    return new Comparator(compareFn);
}
