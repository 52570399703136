import { Renderer2 } from '@angular/core';
import { copyElemTextToClipboard } from '../../../utils/dom';

export class CopyToClipboardBase {
    constructor(
        protected renderer: Renderer2,
        protected document: Document,
    ) {}

    copyFieldToClipboard($event: UIEvent, field: string): void {
        const textArea: HTMLTextAreaElement = this.renderer.createElement('textArea');
        this.renderer.setStyle(textArea, 'position', 'absolute');
        this.renderer.setStyle(textArea, 'opacity', '0');
        this.renderer.setProperty(textArea, 'value', field);
        this.renderer.appendChild(this.document.body, textArea);

        copyElemTextToClipboard({ nativeElement: textArea }, $event, this.document);
        this.renderer.removeChild(this.document.body, textArea);
    }
}
