<pureui-spinner class="p-loading" [pureuiShow]="loading || summaryLoading"></pureui-spinner>
<banners></banners>
<asset-management-summary
    *featurePolicy="topSummaryFlag"
    [assets]="assets"
    [loading]="summaryLoading"
></asset-management-summary>
<section class="action-bar">
    <gfb
        class="btn-group flex-btn-group flex-btn-group-first"
        [barId]="barId"
        [entities]="GFB_ENTITIES"
        [defaultKey]="DEFAULT_KEY"
        [defaultEntity]="DEFAULT_ENTITY"
    >
    </gfb>
    <div class="last-updated-row">
        <span class="last-updated"
            >Last Update: {{ lastMetricRefresh | moment2date | date: 'yyyy-MM-dd HH:mm:ss' : 'UTC' | defaultString }}
            <pureui-svg
                svg="info-circle.svg"
                height="13"
                ngbTooltip="Metrics will be updated at least once daily."
                container="body"
                placement="top"
            >
            </pureui-svg>
        </span>
        <div class="datepicker-filter-section">
            <span class="datepicker-filter-label">Subscription Start Date: </span>
            <div
                class="datepicker-section"
                [ngbTooltip]="selectedStartTimeRangeOption ? 'Subscription Start Date' : null"
                placement="top"
            >
                <calendar-time-range-select
                    class="form-control"
                    [extremeTimeRange]="extremeTimeRange"
                    [selected]="selectedStartTimeRangeOption"
                    [enableCustomChoice]="true"
                    customLabel="All Time"
                    [showReset]="true"
                    [isUTCTime]="true"
                    (selectionChange)="selectStartTimeRange($event)"
                >
                </calendar-time-range-select>
            </div>
            <hive-separator orientation="vertical"></hive-separator>
            <span class="datepicker-filter-label">Subscription Renewal Date: </span>
            <div
                class="datepicker-section"
                [ngbTooltip]="selectedRenewalTimeRangeOption ? 'Subscription Renewal Date' : null"
                placement="top"
            >
                <calendar-time-range-select
                    class="form-control"
                    [extremeTimeRange]="extremeTimeRange"
                    [selected]="selectedRenewalTimeRangeOption"
                    [enableCustomChoice]="true"
                    customLabel="All Time"
                    [showReset]="true"
                    [isUTCTime]="true"
                    (selectionChange)="selectRenewalTimeRange($event)"
                >
                </calendar-time-range-select>
            </div>
        </div>
    </div>
    <div class="table-top-actions">
        <div class="actions-left-section">
            <ng-container *ngIf="taggingEnabled$ | async">
                <pure-entity-selection-dropdown
                    entityName="Selections"
                    [entities]="selectedAssets"
                    [isEditable]="true"
                    [isSelectable]="true"
                    (entitiesChange)="onSelectionsChange($event)"
                >
                </pure-entity-selection-dropdown>
                <button
                    class="action-button"
                    [ngClass]="{ disabled: selectedAssets.length === 0 }"
                    (click)="
                        selectedAssets.length > 0 && assetManagementService.openTagsModal(null);
                        ampli.assetManagementTagging({ 'tagging behavior': 'multi arrays tagging' })
                    "
                    ngbTooltip="Add/Manage Tags"
                    container="body"
                >
                    <pureui-svg svg="tags.svg" height="18"></pureui-svg>
                </button>
                <hive-separator orientation="vertical"></hive-separator>
            </ng-container>
            <div class="export-action" ngbTooltip="Export" container="body">
                <ng-template #assetExportModal let-modal>
                    <asset-export-modal
                        [modal]="modal"
                        [selectedOption]="selectedExportOption"
                        [assetFilter]="assetFilter"
                        [totalFiltered]="totalFiltered"
                        [totalUnfiltered]="totalUnfiltered"
                    >
                    </asset-export-modal>
                </ng-template>
                <export-button
                    class="export-btn"
                    [showNewIcon]="true"
                    [showText]="false"
                    (updateCounts)="updateExportOptionCounts($event.optionsMap)"
                    [exportOptions]="listViewExportOptions"
                    (onClickExport)="onClickExport($event.option); ampli.assetManagementExport()"
                >
                </export-button>
            </div>
        </div>
        <button
            class="action-button column-selection-button"
            [ngClass]="{ open: popover.isOpen() }"
            (click)="popover.toggle()"
            ngbTooltip="Table Settings"
            container="body"
        >
            <pureui-svg
                svg="sliders-up.svg"
                height="18"
                [ngbPopover]="columnSelector"
                autoClose="outside"
                container="body"
                placement="bottom-right"
                triggers="manual"
                popoverClass="column-selector-popover"
                #popover="ngbPopover"
            >
            </pureui-svg>
        </button>
    </div>
</section>
<asset-management-table-columns
    [isSafeModeFeatureFlagEnabled]="safeModeFeatureFlag$ | async"
    (columnsInit)="onColumnsInit($event)"
></asset-management-table-columns>
<asset-management-table
    *ngIf="columns"
    [ngClass]="{ 'with-top-summary': topSummaryFlag$ | async }"
    [columns]="columns"
    [data]="data"
    [defaultSort]="defaultSort"
    [loading]="loading"
    [offset]="offset"
    [selections]="selectedAssets"
    [selectedStartTimeRangeOption]="selectedStartTimeRangeOption"
    [selectedRenewalTimeRangeOption]="selectedRenewalTimeRangeOption"
    [incidentMap]="incidentMap"
    [sort]="sort"
    [total]="total"
    [taggingEnabled]="taggingEnabled$ | async"
    (pageChange)="onPageChange($event)"
    (selectionsChange)="onSelectionsChange($event)"
    (sortChange)="toggleSort($event)"
>
</asset-management-table>

<ng-template #columnSelector>
    <subscription-column-selector
        [columnOptionGroups]="columnOptionGroups"
        (columnOptionGroupsChange)="onColumnOptionsGroupChange($event)"
    >
    </subscription-column-selector>
</ng-template>
