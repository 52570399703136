<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
</div>
<div class="modal-body">
    <div class="icon">
        <pureui-svg class="check-mark-icon" svg="check_mark.svg" height="31"></pureui-svg>
        <pureui-svg class="confirmation-icon" svg="confirmation-icon.svg" height="80"></pureui-svg>
    </div>
    <label class="confirmed-label">{{ title }}</label>
    <label class="confirmed-message"
        >{{ description }}
        <span *ngIf="canTrackOrder"
            >You can track this request via the
            <a routerLink="/services/servicecatalog/orders" (click)="clickOrdersPage()">Orders Page</a>
        </span>
    </label>
    <button class="btn btn-primary" (click)="confirmedRequest()">OK</button>
</div>
