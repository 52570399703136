import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataPage } from '../interfaces/data-page';
import { ListParams } from '../interfaces/list-params';
import { MetricGroup } from '../models/metric-group';

import { GenericService } from './generic.service';

@Injectable({ providedIn: 'root' })
export class MetricGroupService extends GenericService<MetricGroup> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: MetricGroup,
            endpoint: '/rest/v1/analysis/aggregatedmetrics/groups',
            create: true,
            update: true,
            delete: true,
        });
    }

    list(params?: ListParams<MetricGroup>): Observable<DataPage<MetricGroup>> {
        return super.list(params).pipe(
            catchError(error => {
                if (error.status === 403) {
                    return of(this.makeDataPage(null, null));
                }
                return throwError(() => error);
            }),
        );
    }
}
