import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'disable-step-up-warning-modal',
    templateUrl: 'disable-step-up-warning-modal.component.html',
})
export class DisableStepUpWarningModalComponent {
    constructor(private activeModal: NgbActiveModal) {}

    cancel(): void {
        this.activeModal.dismiss({ error: 'cancelled', errorDescription: 'cancelled' });
    }

    disable(): void {
        this.activeModal.close();
    }
}
