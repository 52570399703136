<span *ngIf="array.isCBS()" class="paws-overlay">
    <span class="paws-background"></span>
    <pureui-svg class="paws-background-icon" [svg]="'cloud.svg'" [height]="13"></pureui-svg>
</span>

<a>
    <pure-alert-indicator
        class="alert-indicator card-alert-indicator card-flip-b"
        [ngClass]="{
            'do-not-show-pointer':
                showWarningCardForOutOfSupportAppliance && array.contract_status === ArrayContractStatusExpired
        }"
        [iconHeight]="20"
        [alerts]="
            showWarningCardForOutOfSupportAppliance && array.contract_status === ArrayContractStatusExpired
                ? null
                : alerts
        "
        [array]="array"
        (click)="flipCard($event)"
        angulartics2On="click"
        angularticsCategory="Action"
        angularticsAction="Arrays - Flip card"
    >
    </pure-alert-indicator>
</a>

<div *ngIf="array.fqdn; else noFqdn">
    <div class="card-array-title fqdn">
        <a
            [arrayHref]="array.arrayId"
            [ngbTooltip]="array.fqdn"
            placement="top"
            container="body"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Arrays - Go to array"
        >
            <div class="array-fqdn">
                <div
                    class="array-name-multiline array-name"
                    [ngClass]="{ 'eol-hw': hasEolComponents(), 'reduce-font-size': hasLongHostname() }"
                >
                    {{ array.hostname }}
                </div>
                <div class="array-name-multiline domain"> {{ array.fqdn | domain }}</div>
            </div>
        </a>
    </div>
</div>
<ng-template #noFqdn>
    <div class="card-array-title">
        <a
            [arrayHref]="array.arrayId"
            [ngbTooltip]="array.hostname"
            placement="top"
            container="body"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Arrays - Go to array"
        >
            <div class="array-name-multiline array-name" [ngClass]="{ 'eol-hw': hasEolComponents() }">{{
                array.hostname
            }}</div>
        </a>
    </div>
</ng-template>
