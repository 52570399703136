import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { NpsSurveyService } from '../services/nps-survey.service';
import { NpsSurvey } from '../models/NpsSurvey';
import { WINDOW } from '../../app/injection-tokens';

@Component({
    selector: 'feedback-button',
    templateUrl: './feedback-button.component.html',
})
export class FeedbackButtonComponent implements OnInit {
    survey: NpsSurvey;

    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private npsSurveyService: NpsSurveyService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // When the page is first loaded there might be cases where redirection (such as from / to /appliances/arrays/card)
        // will cause an incorrect page target to be used. As a workaround, we wait a little bit and then fetch the survey.
        // Note: This timeout was from the UIRouter days, so may not be applicable to Angular Router, but doesn't really do harm to keep it.
        this.window.setTimeout(() => {
            this.refreshSurvey();
        }, 100);

        this.npsSurveyService.refreshSurvey$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.refreshSurvey();
        });

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.refreshSurvey();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    refreshSurvey(): void {
        this.npsSurveyService.getSurveys(this.window.location.pathname).subscribe(surveys => {
            if (surveys.length > 0) {
                this.survey = surveys[0];
            } else {
                this.survey = null;
            }
        });
    }

    openFeedbackForm(): void {
        this.npsSurveyService.announceSurvey(this.survey);
    }
}
