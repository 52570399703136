import moment from 'moment';
import { PxStatus } from './px-volume';
import { PxResource } from './px-resource';
import { PxPoolAggregation } from './px-pool-aggregation';

export class PxNode extends PxResource {
    clusterName: string;
    city: string;
    country: string;
    zip: string;
    status: PxStatus;
    os: string;
    cpu: string;
    lastUpdated: moment.Moment;
    memUsed: number;
    memFree: number;
    memTotal: number;
    pxVersion: string;

    // because node is an aggregation of pool stats, it uses the pool MetricNames
    aggregation: PxPoolAggregation;

    constructor(json: any) {
        super(json);
        this.name = json.node_name;
        this.id = json.node_id;
        this.clusterName = json.name;
        this.city = json.city;
        this.country = json.country;
        this.zip = json.zip;
        this.status = json.status;
        this.os = json.os;
        this.cpu = json.cpu;
        this.memUsed = json.mem_used;
        this.memFree = json.mem_free;
        this.memTotal = json.mem_total;
        this.pxVersion = json.px_version;
        this.lastUpdated = moment(json.last_updated);
        if (json.aggregation) {
            this.aggregation = new PxPoolAggregation(json.aggregation);
        }
    }
}
