<div class="radios-container text-nowrap" [ngbTooltip]="titleText" placement="bottom" container="body">
    <span *ngIf="prefixText !== ''" class="aggregation-options-prefix-text" style="margin-right: 10px"
        >{{ prefixText }}</span
    >
    <label
        class="form-check-inline"
        *ngFor="let aggregation of aggregationOptions"
        (click)="clickAggregationMethod(aggregation)"
    >
        <input
            type="radio"
            class="form-check-input"
            name="aggregationRadio"
            value="{{ aggregation.method }}"
            [ngModel]="aggregationOption.method"
        />
        {{ aggregation.text }}
    </label>
</div>
