import { Injectable, Inject, NgZone, RendererFactory2, Renderer2, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WINDOW } from '../app/injection-tokens';
import { Subject, takeUntil } from 'rxjs';
import { smartTimer } from '@pstg/smart-timer';

/**
 * Add this class to the ngb-modal-window to always ignore it. This is important for modals that are custom sized
 * to be based on viewport (eg using vh instead of px or auto).
 *
 * The modal height fixer works by adding a 1px padding to the bottom of the modal, and if the modal
 * is styled in a way that this padding has no change on the size, it ends up constantly trying to add/remove this
 * style in attempt to change the size.
 */
export const IGNORE_FIX_MODAL_HEIGHT_CSS_CLASS = 'modal-height-fixer-ignore';

const HEIGHT_FIXER_CLASS = 'modal-dialog-height-fix';
const STATE_ATTRIBUTE = 'data-modal-dialog-height-fix';

type STATE_VALUE = 'ok' | 'deny-listed';

function hasClass(elem: Element, cssClass: string): boolean {
    return elem.classList && elem.classList.contains(cssClass);
}

/**
 * Periodically checks for open modals with an odd height. Due to the transition on the modals, Chrome renders modals
 * with odd heights poorly. See CLOUD-9341 for details.
 */
@Injectable({ providedIn: 'root' })
export class ModalHeightFixerService implements OnDestroy {
    private bodyElem: HTMLBodyElement;
    private renderer: Renderer2;
    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(WINDOW) private window: Window,
        private ngZone: NgZone,
        private rendererFactory: RendererFactory2,
        private ngbModal: NgbModal,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    run(): void {
        this.ngZone.runOutsideAngular(() => {
            this.bodyElem = this.window.document.querySelector('body');
            this.renderer = this.rendererFactory.createRenderer(null, null);

            // Unfortunately, there is no decent events we can hook to, so we'll just have to check periodically.
            // These checks are cheap, especially if there are no open modals.
            smartTimer(500, 500)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => this.update());
        });
    }

    private update(): void {
        if (!this.hasOpenModals()) {
            return;
        }

        Array.from(this.bodyElem.children || [])
            .filter(child => child && hasClass(child, 'modal') && !hasClass(child, IGNORE_FIX_MODAL_HEIGHT_CSS_CLASS))
            .map(child => child.querySelector('.modal-dialog'))
            .filter(modalElem => modalElem != null && this.getState(modalElem) !== 'deny-listed')
            .forEach(modalElem => {
                // If we haven't yet, validate that the modal has no padding-bottom defined, otherwise our change will do nothing
                // or will do something undesirable
                if (!this.getState(modalElem)) {
                    const computedStyle = getComputedStyle(modalElem);
                    const paddingBottom = computedStyle && computedStyle['padding-bottom'];
                    if (paddingBottom && !String(paddingBottom).startsWith('0')) {
                        // 0px, 0em, 0rem, they're all acceptable
                        console.warn(
                            'modal-height-fixer cannot run on element since it has a defined padding-bottom',
                            modalElem,
                            paddingBottom,
                        );
                        this.setState(modalElem, 'deny-listed');
                        return;
                    }

                    this.setState(modalElem, 'ok');
                }

                // If the height is odd, toggle the height fixer class
                const isOddHeight = modalElem.clientHeight % 2 === 1;
                if (isOddHeight) {
                    if (hasClass(modalElem, HEIGHT_FIXER_CLASS)) {
                        this.renderer.removeClass(modalElem, HEIGHT_FIXER_CLASS);
                    } else {
                        this.renderer.addClass(modalElem, HEIGHT_FIXER_CLASS);
                    }
                }
            });
    }

    private getState(elem: Element): STATE_VALUE | null {
        const attrib = elem.attributes && elem.attributes.getNamedItem(STATE_ATTRIBUTE);
        return attrib && <any>attrib.value;
    }

    private setState(elem: Element, value: STATE_VALUE): void {
        this.renderer.setAttribute(elem, STATE_ATTRIBUTE, value);
    }

    private hasOpenModals(): boolean {
        return this.ngbModal.hasOpenModals();
    }
}
