import { Component, ElementRef, ViewChild, HostListener, Renderer2, Inject, AfterViewChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const STICKY_CSS_CLASS = 'is-sticky-to-top';
const TOP_OFFSET_PX = 56; // Treat the "top" as this value instead of 0 to provide room for the header

/*
How it works:
The two important elements are the anchor and content divs. The content element just holds the transcluded content.
The anchor sits on the page always positioned where the content would be if we didn't ever stick the content to the top.
The anchor serves two purposes: 1) it lets us know the Y-offset of where the content would be if we didn't make it sticky,
and 2) reserves space on the document when we do make the content sticky to prevent the page content from shifting. We
keep the anchor's height updated to match the content height the best we can.

When we're not sticky, the anchor is absolute positioned to prevent taking up space on the page, but still have a correct
page Y offset. The content element itself will reserve the page space. Just standard stuff here. It is specifically
done this way, though, because due to how much the page can change around on page load, it can be hard to reliably keep
the anchor's height synchronized with the content height. This approach ensures we will not be "wrong" in the non-sticky state.

When we are sticky, the content gets changed to fixed position and the top set accordingly. Because this "removes" the
content element from determining positioning, we change the anchor from absolute to relative positioning to take place
of where the content element was. This way, nothing moves around.
*/

/**
 * Makes the child elements in this component stick to the top of the page when the page scrolls down far enough.
 */
@Component({
    selector: 'scroll-sticky-top',
    templateUrl: 'scroll-sticky-top.component.html',
    host: {
        class: 'scroll-sticky-full-width',
    },
})
export class ScrollStickyTopComponent implements AfterViewChecked {
    @ViewChild('containerElem') readonly containerElem: ElementRef<HTMLElement>;
    @ViewChild('anchorElem') readonly anchorElem: ElementRef<HTMLElement>;
    @ViewChild('contentElem') readonly contentElem: ElementRef<HTMLElement>;

    private isSticky = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
    ) {}

    ngAfterViewChecked(): void {
        this.updateState();
    }

    @HostListener('window:scroll')
    @HostListener('window:resize')
    private updateState(): void {
        // Update the anchor with the height of the content
        this.renderer.setStyle(
            this.anchorElem.nativeElement,
            'height',
            this.contentElem.nativeElement.offsetHeight + 'px',
        );

        // Check if we need to be sticky (top of the anchor scrolled past the bottom of the header)
        const scrollTop = this.document.body.scrollTop;
        const anchorTop = this.anchorElem.nativeElement.getBoundingClientRect().top;
        const distanceFromHeaderBottom = anchorTop - (scrollTop + TOP_OFFSET_PX);
        const newIsSticky = distanceFromHeaderBottom < 0;

        // Update style for sticky
        if (this.isSticky !== newIsSticky) {
            this.isSticky = newIsSticky;
            this.renderer.setStyle(this.contentElem.nativeElement, 'top', this.isSticky ? TOP_OFFSET_PX + 'px' : '');
            if (this.isSticky) {
                this.renderer.addClass(this.containerElem.nativeElement, STICKY_CSS_CLASS);
            } else {
                this.renderer.removeClass(this.containerElem.nativeElement, STICKY_CSS_CLASS);
            }
        }
    }
}
