<div>
    <div class="modal-header">
        <h3 class="modal-title">Pure1 Terms of Use</h3>
    </div>
    <div class="modal-body tou-content">
        <iframe src="/docs/tou-latest.html"></iframe>
    </div>
    <div class="modal-footer btn-toolbar">
        <button id="p-tou-reject" class="btn btn-danger float-xs-right" (click)="logout()">Reject</button>
        <button id="p-tou-accept" class="btn btn-primary float-xs-right" (click)="acceptTermsOfUse()">Accept</button>
    </div>
</div>
;
