import { Directive, HostListener, Input } from '@angular/core';
import { SupportCenterRedirectService } from './services/support-center-redirect.service';

@Directive({
    selector: '[supportCasesRedirect]',
    standalone: true,
})
export class SupportCasesRedirectDirective {
    @Input('supportCasesRedirect') readonly caseId: string;

    constructor(private supportCenterRedirectService: SupportCenterRedirectService) {}

    @HostListener('click', ['$event'])
    onSupportLinkClick(): void {
        this.supportCenterRedirectService.openSupportCenter(this.caseId);
    }
}
