<form id="um-user-form" [formGroup]="userForm">
    <pureui-spinner class="p-loading" [pureuiShow]="loading"></pureui-spinner>
    <section id="user-access-control" class="container-fluid">
        <h1 class="section-header">Access Control</h1>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label class="no-padding" for="user-role">Role</label>
            </div>
            <div class="col-xs-9">
                <show-all-roles [role]="currentUser?.role" [additionalRoles]="currentUser?.additionalRoles" />
            </div>
        </div>
    </section>
    <section id="user-details" class="container-fluid">
        <h1 class="section-header">User Details</h1>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label class="required" for="user-first-name">First name</label>
            </div>
            <div class="col-xs-9">
                <input
                    class="form-control"
                    id="user-first-name"
                    name="user-first-name"
                    type="text"
                    placeholder="First Name *"
                    formControlName="firstName"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label class="required" for="user-last-name">Last name</label>
            </div>
            <div class="col-xs-9">
                <input
                    class="form-control"
                    id="user-last-name"
                    name="user-last-name"
                    type="text"
                    placeholder="Last Name *"
                    formControlName="lastName"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label class="required" for="user-email">Email</label>
            </div>
            <div class="col-xs-9">
                <input
                    class="form-control"
                    id="user-email"
                    name="user-email"
                    type="email"
                    placeholder="Email Address *"
                    formControlName="email"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-phone">Phone</label>
            </div>
            <div class="col-xs-9">
                <input-phone
                    class="phone-control"
                    id="user-phone"
                    [prefill]="currentUser.phone"
                    [prefillCountry]="currentUser?.phone"
                    (onPhoneChange)="onPhoneChange($event)"
                >
                </input-phone>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3 form-label">
                <label for="user-timezone">Time zone</label>
            </div>
            <div class="col-xs-9">
                <select id="user-timezone" class="custom-select form-control" formControlName="timezone">
                    <option
                        *ngFor="let timezone of timezones; trackBy: 'value' | trackByProperty"
                        [value]="timezone.value"
                    >
                        ({{ timezone.timeOffset }}) {{ timezone.value }}</option
                    >
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-3"></div>
            <div class="col-xs-9">
                <div class="form-buttons btn-toolbar">
                    <button
                        class="btn btn-primary float-xs-left save-button"
                        pure-read-only
                        (click)="edit()"
                        [disabled]="!isValidForm()"
                        angulartics2On="click"
                        angularticsAction="UM - Create user"
                        angularticsCategory="Action"
                    >
                        Save
                    </button>
                    <button
                        class="btn btn-info float-xs-left cancel-button"
                        (click)="cancel()"
                        angulartics2On="click"
                        angularticsAction="UM - Cancel create user"
                        angularticsCategory="Action"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </section>
</form>
