import { Resource } from '@pure1/data';

export class FeatureFlagResource implements Resource {
    id: string;
    name: string;
    enabled: boolean;

    constructor(json: any) {
        this.id = null;
        this.name = json.name;
        this.enabled = json.enabled;
    }
}
