import { Observable, Subscription, BehaviorSubject } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import {
    Directive,
    Renderer2,
    OnInit,
    OnChanges,
    ComponentFactoryResolver,
    ElementRef,
    Inject,
    Input,
    ComponentRef,
    Injector,
    ApplicationRef,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';

import { AutocompleteMenuComponent, MoveSelectionDirection } from './autocomplete-menu.component';
import { KEY } from '../../gfb/key.enum';
import { Angulartics2 } from 'angulartics2';
import { WINDOW } from '../../app/injection-tokens';

export type NamedValue<T> = {
    name: string;
    value: T;
    category?: Category;
};

export type Category = {
    name: string; // this could be an entity property, or tag, with a tag namespace?
    displayName: string;
    icon: string;
};

export interface IPureAutocompleteOptions<T> {
    /**
     * Function called to get the autocomplete suggestions.
     *
     * @memberof IPureAutocompleteOptions
     */
    list: (match$: Observable<string>) => Observable<NamedValue<T>[]>;
    /**
     * Element in which the autocomplete menu will be appended. This element must not be statically
     * positioned. Default to document.body.
     *
     * @type {Element}
     * @memberof IPureAutocompleteOptions
     */
    appendTo?: Element;

    scrollEl?: Element;

    /**
     * Boolean flag to indicate whether or not this autocomplete directive will allow for selecting multiple values
     * Not required, default is false, which indicates single-selection only.
     */
    multiSelect?: boolean;

    /**
     * Boolean flag to indicate whether or not we should keep the current input when the menu gives us an empty list of values,
     * this can make the input look dirty unnecessarily, which is bad if we're using this in reactive forms
     */
    cleanInputOnEmpty: boolean;
}

interface ScrollState {
    deregisterListener: () => void;
    fixedScrollTop: number;
}

@Directive({
    selector: 'input[pureAutocomplete]',
})
export class AutocompleteDirective<T> implements OnInit, OnChanges {
    @Input('pureAutocomplete') options: IPureAutocompleteOptions<T>;
    // This input primarily makes sense for the multi-select version
    @Input() selectedValues: NamedValue<T>[];
    @Output() readonly autocompleteSelect = new EventEmitter<NamedValue<T>[]>();

    input: HTMLInputElement;
    match$: BehaviorSubject<string>;

    private deregisterFns: (() => void)[] = [];
    private deregisterOnFocus: () => void;
    private listSubscription: Subscription;
    private valueSelectSubscription: Subscription;
    private autocompleteMenuRef: ComponentRef<AutocompleteMenuComponent<T>>;
    private scrollState: Partial<ScrollState> = {};
    private isMenuVisible = false;
    private currentMatch: string;
    private namedValues: NamedValue<T>[];
    private commitAfterSelect = false;

    constructor(
        private element: ElementRef<HTMLInputElement>,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private applicationRef: ApplicationRef,
        private renderer: Renderer2,
        private angulartics2: Angulartics2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
    ) {
        this.input = this.element.nativeElement; // TODO: This should NOT be in the constructor. Always prefer lifecycle hooks. Probably want ngOnInit() instead. Also, should probably just remove "this.input" and use "this.element.nativeElement" directly instead.
    }

    ngOnInit(): void {
        this.deregisterOnFocus = this.renderer.listen(this.element.nativeElement, 'focus', $event =>
            this.onFocus($event),
        );
        this.selectedValues = this.selectedValues || [];
        this.namedValues = this.namedValues || [];
    }

    ngOnDestroy(): void {
        this.deregisterOnFocus();
        this.destroyMenu();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedValues) {
            this.input.value = this.selectedValues.map(val => val.value).join(', ');
        }
    }

    onClick($event: MouseEvent): void {
        if ($event.target instanceof HTMLElement) {
            // what other kinds of target might there be for MouseEvent?
            // detect whether the click is on the autocomplete-menu (or input itself) vs. anywhere else in the document
            if (
                (this.options.appendTo && this.options.appendTo.contains($event.target)) ||
                this.input.isEqualNode($event.target)
            ) {
                // For the single-select case, by the time the click handler is called,
                // we expect that the AutocompleteMenu component will have emitted an event on its output.
                // This directive will also have emitted an event on its output, and the menu will be hidden.
                // We only need to focus again on the input if we are in multi-select mode.
                if (this.options.multiSelect) {
                    this.input.focus();
                }
            } else {
                // make a shallow copy so that we don't update this.selectedValues if we add the current match
                // if we want the current match in this.selectedValues, that input should be updated by the host component
                const values = this.autocompleteMenuRef.instance.selectedValues.slice();
                // assume we have clicked outside the menu

                this.angulartics2.eventTrack.next({
                    action: 'Autocomplete dismiss click',
                    properties: { category: 'Action' },
                });

                const match = this.readMatch();
                if (match && match.length > 0) {
                    values.push({ name: match, value: undefined });
                }
                this.commit(values);
            }
        }
    }

    destroyMenu(): void {
        this.deregisterFns.forEach(f => f());
        this.deregisterFns = [];
        this.stopListeningScrollEvents();
        if (this.match$) {
            this.match$.complete();
            this.match$ = null;
        }
        if (this.listSubscription && !this.listSubscription.closed) {
            this.listSubscription.unsubscribe();
        }
        if (this.valueSelectSubscription && !this.valueSelectSubscription.closed) {
            this.valueSelectSubscription.unsubscribe();
        }
        if (this.autocompleteMenuRef) {
            this.applicationRef.detachView(this.autocompleteMenuRef.hostView);
            this.autocompleteMenuRef.destroy();
            this.autocompleteMenuRef = null;
        }
    }

    hideMenu(): void {
        this.isMenuVisible = false;
        this.renderer.setStyle(this.autocompleteMenuRef.location.nativeElement, 'display', 'none');
        this.stopListeningScrollEvents();
    }

    onFocus($event: any): void {
        if ($event.refocusing) {
            // make transient, intentional loss of focus trivial
            return;
        }
        this.formatValuesForEditing();
        this.createMenu();
        this.updateAutocompleteList();
    }

    formatValuesForEditing(): void {
        // rewrite the textbox based on this.selectedValues
        const names = this.selectedValues.map(nv => nv.name).join(', ');

        // don't write to the input field unless we need to
        // so we don't make the input dirty if it is used in reactive forms (i.e. manage-tags modal)
        if (names.length > 0 || this.options.cleanInputOnEmpty) {
            this.input.value = names;
            // add a comma so that the last selected name is treated as such (not as text to match against)
            if (names.length > 0) {
                this.input.value += ', ';
            }

            // Trigger input event so that ngModel and other listening to the input event are notified
            // But we don't want is to be captured by the listener set by this directive, so we add a private $pure1 field
            // to know that we must ignore it.
            try {
                const inputEvent = new Event('input');
                (inputEvent as any).$pure1 = true;
                this.input.dispatchEvent(inputEvent);
            } catch (err) {
                if (err && (err.number === -2146827843 || err.name === 'TypeError')) {
                    console.log('Ignoring IE11-specific error', err); // Ignore IE11 "Object doesn't support this action"
                } else {
                    throw err;
                }
            }
        }
    }

    private createMenu(): void {
        this.commitAfterSelect = false;
        if (!this.autocompleteMenuRef) {
            this.autocompleteMenuRef = this.componentFactoryResolver
                .resolveComponentFactory(AutocompleteMenuComponent)
                .create(this.injector) as ComponentRef<AutocompleteMenuComponent<T>>;
            this.autocompleteMenuRef.instance.multiSelect = this.options.multiSelect;
            this.autocompleteMenuRef.instance.selectedValues = this.selectedValues;
            this.applicationRef.attachView(this.autocompleteMenuRef.hostView);

            (this.options.appendTo || this.document.body).appendChild(this.autocompleteMenuRef.location.nativeElement);
            this.hideMenu();

            this.deregisterFns.push(
                this.renderer.listen(this.element.nativeElement, 'input', $event => this.onInput($event)),
                this.renderer.listen(this.element.nativeElement, 'keypress', $event => this.onKeypress($event)),
                this.renderer.listen(this.element.nativeElement, 'keydown', $event => this.onKeydown($event)),
                this.renderer.listen(this.element.nativeElement, 'keyup', $event => this.onKeyup($event)),
                this.renderer.listen(this.document, 'click', $event => this.onClick($event)),
            );

            // Capture the event emitted by the <autocomplete-menu> on selection.
            this.valueSelectSubscription = this.autocompleteMenuRef.instance.valueselect.subscribe(x =>
                this.onSelect(x),
            );
        }
    }

    private onInput($event: any): void {
        if ($event.$pure1) {
            return;
        }
        this.updateAutocompleteList();
        this.refreshSelectedValues();
    }

    private onKeypress($event: any): void {
        if ($event.key === KEY.enter) {
            // Enter key will both select and commit selection (i.e. we are done autocompleting at that point)
            // we need to wait in the multiselect case until the setImmediate of onSelect() before we commit
            this.commitAfterSelect = true;
            // if there is no highlighted entry, AutocompleteMenu will not emit a select event
            // we also only want to select values via enter, not deselect, so the menu performs a "nondestructive toggle"
            const selectedValue = this.autocompleteMenuRef.instance.select(true);
            if (!selectedValue) {
                // if AutocompleteMenu doesn't make changes to the selected values,
                // then we commit the currently selected values
                if (this.selectedValues.length > 0 || this.input.value.trim().length > 0) {
                    // we should make sure there are values to select
                    this.angulartics2.eventTrack.next({
                        action: 'Autocomplete enter autocomplete',
                        properties: { category: 'Action' },
                    });
                    this.commit(this.selectedValues);
                } else {
                    // otherwise, we don't need to commit
                    this.commitAfterSelect = false;
                }
            }
        }
    }

    private onKeyup($event: KeyboardEvent): void {
        if ($event.key === KEY.escape) {
            // don't prevent default when the menu is hidden to preserve native behavior
            if (this.isMenuVisible) {
                this.angulartics2.eventTrack.next({
                    action: 'Autocomplete escape to hide menu',
                    properties: { category: 'Action' },
                });
                $event.stopPropagation();
                $event.preventDefault();
                this.hideMenu();
            }
        }
    }

    private onKeydown($event: KeyboardEvent): void {
        switch ($event.key) {
            case KEY.up:
                $event.stopImmediatePropagation();
                $event.preventDefault();

                this.angulartics2.eventTrack.next({
                    action: 'Autocomplete keyboard navigate',
                    properties: { category: 'Action', label: $event.key },
                });

                this.autocompleteMenuRef.instance.moveSelection(MoveSelectionDirection.Up);
                break;
            case KEY.down:
                $event.stopImmediatePropagation();
                $event.preventDefault();

                this.angulartics2.eventTrack.next({
                    action: 'Autocomplete keyboard navigate',
                    properties: { category: 'Action', label: $event.key },
                });

                this.autocompleteMenuRef.instance.moveSelection(MoveSelectionDirection.Down);
                break;
            case KEY.tab:
                if (!$event.shiftKey) {
                    // Shift tab should not autocomplete
                    $event.stopImmediatePropagation();
                    $event.preventDefault();

                    this.angulartics2.eventTrack.next({
                        action: 'Autocomplete tab autocomplete',
                        properties: { category: 'Action' },
                    });

                    this.autocompleteMenuRef.instance.select();
                }
                break;
            case KEY.space: // space should only select if there is a highlighted selection
                //preventDefault() will be called in gfb.component.ts. So we will call stopImmediatePropagation() here.
                $event.stopImmediatePropagation();

                if (this.autocompleteMenuRef.instance.select()) {
                    $event.preventDefault();

                    this.angulartics2.eventTrack.next({
                        action: 'Autocomplete spacebar select',
                        properties: { category: 'Action' },
                    });
                }
                break;
            default:
        }
    }

    private onSelect(namedValues: NamedValue<T>[]): void {
        if (!this.options.multiSelect) {
            this.hideMenu();

            // Trigger input event so that ngModel and other listening to the input event are notified
            // But we don't want is to be captured by the listener set by this directive, so we add a private $pure1 field
            // to know that we must ignore it.
            try {
                const inputEvent = new Event('input');
                (inputEvent as any).$pure1 = true;
                this.input.dispatchEvent(inputEvent);
            } catch (err) {
                if (err && (err.number === -2146827843 || err.name === 'TypeError')) {
                    console.log('Ignoring IE11-specific error', err); // Ignore IE11 "Object doesn't support this action"
                } else {
                    throw err;
                }
            }

            // Trigger custom (autocompleteSelect) event
            this.formatValuesForDisplay(namedValues);
            this.commit(namedValues);
        } else {
            // we can't run this synchronously because we need to process the click/blur event first, then update the list
            this.window.setImmediate(() => {
                if (Array.isArray(namedValues)) {
                    this.selectedValues = namedValues;
                }
                // if the input does not have focus at this point (i.e. the select event was triggered by a click),
                // then we will refocus onto the input. Either way, we need to update the input value and the menu list
                if (this.input !== this.document.activeElement) {
                    this.input.focus();
                } else {
                    this.formatValuesForEditing();
                    this.updateAutocompleteList();
                }

                if (this.commitAfterSelect) {
                    this.commitAfterSelect = false;
                    this.commit(namedValues);
                }
            });
        }
    }

    private commit(namedValues: NamedValue<T>[]): void {
        this.autocompleteSelect.emit(namedValues);
        this.destroyMenu();
    }

    private updateAutocompleteList(): void {
        this.currentMatch = this.readMatch();
        this.autocompleteMenuRef.instance.match = this.currentMatch;

        if (this.match$) {
            this.match$.next(this.currentMatch);
        } else {
            this.match$ = new BehaviorSubject<string>(this.currentMatch);
            this.listSubscription = this.options.list(this.match$).subscribe(values => {
                this.autocompleteMenuRef.instance.namedValues = values;
                this.namedValues = values;

                if (!(values.length === 0 || (values.length === 1 && values[0].name === this.currentMatch))) {
                    this.showMenu();
                } else {
                    this.hideMenu();
                }
            });
        }
    }

    private formatValuesForDisplay(namedValues: NamedValue<T>[]): void {
        // we call this right before emitting an output event with the namedValues, just in case the host component doesn't want to rewrite (or replace the input)
        this.input.value = namedValues.map(nv => nv.name).join(', ');
    }

    private readMatch(): string {
        // this method has no side effects, it only returns the string for matching
        if (this.options.multiSelect) {
            // in the multiSelect case, we match against the trimmed string after the final comma
            const trimmedInput = this.input.value.trim();
            const lastCommaIndex = trimmedInput.lastIndexOf(','); // is -1 if no comma
            return trimmedInput.substring(lastCommaIndex + 1).trim();
        } else {
            // in the single select case, we match against the full textbox value
            return this.input.value;
        }
    }

    private refreshSelectedValues(): void {
        // This method should only be called for multiSelect autocomplete in response to input events
        // We do not support both values that contain commas and custom values (i.e. not-autocompleted values) at the same time!
        // This method is destructive to values that contain commas (in favor of supporting custom values)
        // selectedValues will be updated, both in the directive and the autocomplete menu
        if (!this.options.multiSelect) {
            return;
        }
        const trimmedInput = this.input.value.trim();
        const lastCommaIndex = trimmedInput.lastIndexOf(','); // is -1 if no comma
        // get a list of names before the last comma (will be an array of empty string if no comma)
        const selectedNames = Array.from(
            new Set(
                trimmedInput
                    .substring(0, lastCommaIndex)
                    .split(',')
                    .map(name => name.trim()),
            ),
        );
        // convert the array of names to an array of NamedValue<T>
        this.selectedValues = selectedNames
            .filter((name: string) => name.length > 0)
            .map((name: string) => {
                // lookup the NamedValue<T> based on the name
                const namedValue = this.namedValues.find((nv: NamedValue<T>) => nv.name === name);
                if (namedValue) {
                    return namedValue;
                } else {
                    return {
                        name,
                        value: undefined, // For those names that have no value T, we make up an undefined value
                    };
                }
            });
        // we have the source of truth regarding currently selected values, now we update the menu component
        this.autocompleteMenuRef.instance.selectedValues = this.selectedValues;
    }

    private showMenu(): void {
        this.positionAutocompleteMenu();
        this.isMenuVisible = true;
        this.renderer.removeStyle(this.autocompleteMenuRef.location.nativeElement, 'display');
        this.listenToScrollEvents();
    }

    private positionAutocompleteMenu(): void {
        const inputRect = this.element.nativeElement.getBoundingClientRect();
        const appendToRect = (this.options.appendTo || this.document.body).getBoundingClientRect();
        const top = Math.round(inputRect.bottom - appendToRect.top + 5);
        const left = Math.round(inputRect.left - appendToRect.left);
        this.renderer.setStyle(this.autocompleteMenuRef.location.nativeElement, 'top', top + 'px');
        this.renderer.setStyle(this.autocompleteMenuRef.location.nativeElement, 'left', left + 'px');
    }

    private listenToScrollEvents(): void {
        this.scrollState.fixedScrollTop = this.options.scrollEl.scrollTop;
        this.scrollState.deregisterListener = this.renderer.listen(this.options.scrollEl, 'scroll', $event =>
            this.onScroll($event),
        );
    }

    private stopListeningScrollEvents(): void {
        if (this.scrollState.deregisterListener) {
            this.scrollState.deregisterListener();
            this.scrollState.deregisterListener = null;
            this.scrollState.fixedScrollTop = null;
        }
    }

    private onScroll(event: Event): void {
        if (this.scrollState.fixedScrollTop != null) {
            this.options.scrollEl.scrollTop = this.scrollState.fixedScrollTop;
        }
    }
}
