import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HoneycombChartComponent } from './honeycomb-chart.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [CommonModule, NgbTooltipModule],
    declarations: [HoneycombChartComponent],
    exports: [HoneycombChartComponent],
})
export class HoneycombChartModule {}
