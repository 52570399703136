import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { GenericService } from './generic.service';
import { HardwareCapacityConfiguration } from '../models/hardware-capacity-configuration';
import { HardwareModels } from '../models/hardware-models';
import { IRestResponse } from '../interfaces/collection';
import { DataPage } from '../interfaces/data-page';

@Injectable({ providedIn: 'root' })
export class HardwareModelsService extends GenericService<HardwareModels> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: HardwareModels,
            endpoint: '/rest/v1/hardware-configurations/models',
        });
    }

    makeDataPage(
        _wrapper: IRestResponse<HardwareModels>,
        response: HttpResponse<IRestResponse<HardwareModels>>,
    ): DataPage<HardwareModels> {
        return {
            total: 1,
            response: [new HardwareModels(response.body)],
            originalResponse: response,
        };
    }
}

@Injectable({ providedIn: 'root' })
export class HardwareCapacitiesService extends GenericService<HardwareCapacityConfiguration> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: HardwareCapacityConfiguration,
            endpoint: '/rest/v1/hardware-configurations/capacities',
        });
    }
}
