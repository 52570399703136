import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    BulkSchedule,
    BulkScheduleItem,
    Case,
    CaseCategory,
    CaseComposite,
    CaseSubCategory,
    Contact,
    FreeTime,
    FreeTimeType,
    ProductLine,
} from '../../support/support.interface';
import { Schedule } from '../safemode-scheduler-common/types';

@Injectable({ providedIn: 'root' })
export class SafeModeSchedulerService {
    readonly ORIGIN = 'Pure1 SafeMode Request';
    readonly CATEGORY = CaseCategory.DEPLOYMENT;
    readonly SUB_CATEGORY = CaseSubCategory.DEPLOYMENT_SAFEMODE;
    readonly PATH_PREFIX = '/rest/v1/support';
    readonly PAGE_SIZE = 1000;

    constructor(private http: HttpClient) {}

    /**
     * Returns time windows between startTime and endTime where there are enough
     * TSEs to do the changes
     * startTime and endTime *must* have a timezone attached.
     * can only consult one calendar at a time (either FA or FB)
     */
    getFreeTimes(
        startTime: moment.Moment,
        endTime: moment.Moment,
        productLine: ProductLine = ProductLine.FlashArray,
    ): Observable<FreeTime[]> {
        return (
            this.http
                .get<{ records: { startTime: number; endTime: number; capacity: number }[] }>(
                    `${this.PATH_PREFIX}/schedulertimeslots`,
                    {
                        params: {
                            startTime: startTime.valueOf(),
                            endTime: endTime.valueOf(),
                            timeSlotType: FreeTimeType.SAFEMODE,
                            productLine,
                        },
                    },
                )
                // Response -> FreeTime[]
                .pipe(
                    map(response =>
                        response.records.map(item => ({
                            start: moment.tz(item.startTime, 'UTC'),
                            end: moment.tz(item.endTime, 'UTC'),
                            capacity: item.capacity || 1,
                        })),
                    ),
                )
        );
    }

    createBulkUpgradeSchedule(
        schedule: Schedule,
        userId: string,
        description: string,
        productLine: ProductLine,
    ): Observable<Partial<Case>> {
        const contact: Contact = {
            id: userId,
        };

        const scheduleItems: BulkScheduleItem[] = schedule.arrays.map(array => {
            return {
                arrayId: array.array.cloud_array_id,
                nextActionDueDate: array.timeslot ? array.timeslot.startTime.valueOf() : null,
                upgradeEndDateTime: array.timeslot
                    ? array.timeslot.startTime.clone().add(array.timeslot.duration).valueOf()
                    : null,
                maintenanceExecutionTimezoneLocal: schedule.timezone,
                numOfHops: 1,
            };
        });

        const newEnableSchedule: BulkSchedule = {
            origin: this.ORIGIN,
            category: this.CATEGORY,
            subCategory: this.SUB_CATEGORY,
            timeFrameType: FreeTimeType.SAFEMODE,
            description: description,
            contact: contact,
            schedule: scheduleItems,
            nextActionDueDate: schedule.fromDate ? schedule.fromDate.valueOf() : null,
            upgradeEndDateTime: schedule.toDate ? schedule.toDate.valueOf() : null,
            maintenanceExecutionTimezoneLocal: schedule.timezone,
        };
        return this.http
            .post<CaseComposite>(`${this.PATH_PREFIX}/bulkschedule`, newEnableSchedule)
            .pipe(map(caseComposite => caseComposite.case));
    }
}
