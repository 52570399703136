import { Injectable } from '@angular/core';

import { DisasterRecoveryPlan, DisasterRecoveryPlanState } from '../models/disaster-recovery-plan';
import { FilterParams } from '../interfaces/list-params';
import { map, Observable, Subject, tap } from 'rxjs';
import {
    DraasApiCreateRecoveryPlanV2,
    DraasApiRecoveryPlan,
    DraasApiRecoveryPlanSnapshots,
    DraasApiRunFailover,
    DraasApiUpdateRecoveryPlan,
} from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryPlanGroupWithSnapshotSets } from '../models/disaster-recovery-plan-group-with-snapshot-sets';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { defaultIfEmpty, filter } from 'rxjs/operators';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryPlansService extends DisasterRecoveryBaseService<
    DisasterRecoveryPlan,
    DraasApiRecoveryPlan
> {
    protected resourceClass = DisasterRecoveryPlan;
    protected pathParams = ['clusterId', 'planId'];

    readonly planCreated$: Subject<DisasterRecoveryPlan> = new Subject();
    readonly planUpdated$: Subject<DisasterRecoveryPlan> = new Subject();
    readonly planRemoved$: Subject<string> = new Subject();

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryPlan>, extra?: string): string {
        const endpoint = `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/recovery-plans`;
        if (extra === 'with-last-execution') {
            return `${endpoint}/with-last-executions`;
        } else {
            return filter.planId ? `${endpoint}/${filter.planId}` : endpoint;
        }
    }

    create(clusterId: string, body: DraasApiCreateRecoveryPlanV2): Observable<DisasterRecoveryPlan> {
        return this.doCreate(this.getEndpoint({ clusterId }), body, this.planCreated$);
    }

    update(clusterId: string, planId: string, body: DraasApiUpdateRecoveryPlan): Observable<DisasterRecoveryPlan> {
        return this.doUpdate(this.getEndpoint({ clusterId, planId }), body, this.planUpdated$);
    }

    delete(clusterId: string, planId: string): Observable<void> {
        return this.doDelete(this.getEndpoint({ clusterId, planId }), planId, this.planRemoved$);
    }

    changeOrderOfGroups(plan: DisasterRecoveryPlan, newOrderOfGroups: string[]): Observable<DisasterRecoveryPlan> {
        const clusterId = plan.clusterId;
        const planId = plan.id;
        const url = this.getEndpoint({ clusterId, planId });
        const body: DraasApiUpdateRecoveryPlan = {
            description: plan.description,
            name: plan.name,
            scale_number: plan.scaleNumber,
            version: plan.version,
            group_ids: newOrderOfGroups,
        };
        return this.http.patch<DraasApiRecoveryPlan>(url, body).pipe(
            map(response => new DisasterRecoveryPlan(response)),
            tap(res => this.planUpdated$.next(res)),
        );
    }

    getSnapshotSets(clusterId: string, planId: string): Observable<DisasterRecoveryPlanGroupWithSnapshotSets[]> {
        const url = `${this.getEndpoint({ clusterId, planId })}/snapshot-sets`;
        return this.http
            .get<DraasApiRecoveryPlanSnapshots>(url)
            .pipe(map(response => response.groups.map(group => new DisasterRecoveryPlanGroupWithSnapshotSets(group))));
    }

    runFailover(clusterId: string, planId: string, snapshotSetByGroups: Record<string, string>): Observable<void> {
        const url = `${this.getEndpoint({ clusterId, planId })}/run-failover`;
        const body: DraasApiRunFailover = {
            snapshot_set_by_groups: snapshotSetByGroups,
        };
        return this.http.post<void>(url, body);
    }

    runFailoverWithLatestSnapshots(clusterId: string, planId: string): Observable<void> {
        const url = `${this.getEndpoint({ clusterId, planId })}/run-failover/latest-snapshots`;
        return this.http.post<void>(url, null);
    }

    runTeardown(clusterId: string, planId: string): Observable<void> {
        const url = `${this.getEndpoint({ clusterId, planId })}/run-teardown`;
        return this.http.post<void>(url, null);
    }

    runFailback(clusterId: string, planId: string): Observable<void> {
        const url = `${this.getEndpoint({ clusterId, planId })}/run-failback`;
        return this.http.post<void>(url, null);
    }

    getProdRecoveryPlanState(clusterId: string): Observable<DisasterRecoveryPlanState> {
        return this.list({
            pageStart: 0,
            pageSize: 1,
            filter: { clusterId },
            sort: { key: 'plan_type', order: 'asc' }, // prod plan first
        }).pipe(
            filter(page => page.response[0]?.planType === 'PRODUCTION'),
            map(page => page.response[0].planState),
            defaultIfEmpty('NOT_IN_FAILOVER_MODE'),
        );
    }
}
