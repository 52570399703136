import { formatSize } from '../utils/formatSize';
import { formatRatio } from '../utils/formatRatio';

export class CapacityData implements ICapacityData {
    capacity: number;
    capacityExpandable: boolean;
    capacityInstalled: number;
    capacityInstalledStr: IValueUnit;
    instantCapacity: number;
    instantCapacityStr: IValueUnit;
    capacityStr: IValueUnit;
    capacityUsedStr: IValueUnit;
    provisioned: number;
    provisionedStr: IValueUnit;
    dataReduction: number;
    dataReductionStr: string;
    total: number;
    percFull: number;
    actualPercFull: string;
    normalizedPercFull: string;
    system: number;
    systemStr: IValueUnit;
    empty: number;
    emptyStr: IValueUnit;
    dialSegments: IDialSegment[];

    constructor(capacity: any) {
        if (capacity) {
            this.capacity = capacity.capacity;
            this.capacityExpandable = capacity.capacity_expandable;
            this.capacityInstalled = capacity.capacity_installed;
            this.instantCapacity = this.capacityExpandable ? capacity.instant_capacity : null;
            this.provisioned = !!capacity.size ? capacity.size : capacity.total * capacity.total_reduction;
            this.dataReduction = capacity.data_reduction;
            this.total = capacity.total;
            this.percFull = this.total / this.capacity;
            this.system = capacity.system;
            this.empty = Math.max(this.capacity - this.total, 0);
        } else {
            this.capacity = 0;
            this.capacityExpandable = false;
            this.capacityInstalled = 0;
            this.instantCapacity = null;
            this.provisioned = 0;
            this.dataReduction = 1;
            this.total = 0;
            this.percFull = 0;
            this.system = 0;
            this.empty = 0;
        }

        this.capacityInstalledStr = formatSize(this.capacityInstalled, 2);
        this.instantCapacityStr = formatSize(this.instantCapacity, 2);
        this.capacityUsedStr = formatSize(this.total, 2);
        this.capacityStr = formatSize(this.capacity, 2);
        this.provisionedStr = formatSize(this.provisioned, 2);
        const actualPerc = Math.floor(this.percFull * 1000) / 10;
        this.actualPercFull = actualPerc.toFixed(0);
        this.normalizedPercFull = actualPerc > 100 ? '100' : this.actualPercFull;
        this.dataReductionStr = formatRatio(this.dataReduction) + ' to 1';
        this.systemStr = formatSize(this.system, 2);
        this.emptyStr = formatSize(this.empty, 2);
    }
}
