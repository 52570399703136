import { Component, Output, EventEmitter, Input } from '@angular/core';

export interface DrawerIcon {
    icon: string;
    name: string;
    selectedIcon?: string;
    /** If specified, show a small pill above the icon with the specified text */
    pillText?: string;
}

@Component({
    selector: 'drawer-with-menu',
    templateUrl: './drawer-with-menu.component.html',
})
export class DrawerWithMenuComponent {
    @Input() readonly width: number = 440;
    @Input() readonly drawerOpen: boolean = false;
    @Input() readonly icons: DrawerIcon[] = [{ icon: 'icon-report.svg', name: 'report' }];
    @Input() readonly selectedIcon: string;
    @Output() readonly toggleDrawer = new EventEmitter<boolean>();
    @Output() readonly onIconClick = new EventEmitter<string>();

    get calculatedWidth(): number {
        // this is the content width + 40 for the icons container
        return this.width + 40;
    }
    buttonsWidth = 50;

    onToggleDrawer(): void {
        this.toggleDrawer.emit(true);
    }

    _onIconClick(iconName: string): void {
        this.toggleDrawer.emit(true);
        this.onIconClick.emit(iconName);
    }
}
