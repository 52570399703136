// A resource for PX clusters specifically for support
// See CLOUD-104579 for the background

import { Resource } from '../interfaces/resource';

export class SupportPxCluster implements Resource {
    name: string;
    id: string;

    constructor(json: any) {
        this.name = json.name;
        this.id = json.id;
    }
}
