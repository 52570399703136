import { Pipe, PipeTransform } from '@angular/core';

type trackByPropertyFunc = (index: number, item: any) => any;

/** Generated function for each unique property name (the item itself does not matter at all). */
const functionCache = new Map<string, trackByPropertyFunc>();

/**
 * Pipe that turns a string into a trackBy function so we don't have to create trackBy functions in each component.
 * Usage:
 *      <div *ngFor="let item of items; trackBy: ('id' | trackByProperty)>
 * Idea taken from: https://www.bennadel.com/blog/3579-using-pure-pipes-to-generate-ngfor-trackby-identity-functions-in-angular-7-2-7.htm
 */
@Pipe({
    name: 'trackByProperty',
    pure: true,
})
export class TrackByPropertyPipe implements PipeTransform {
    transform(property: string): trackByPropertyFunc {
        if (property == null || property.length === 0) {
            throw new Error('trackBy property must be a valid string, was: ' + property);
        }

        if (!functionCache.has(property)) {
            functionCache.set(property, function (index, item): any {
                // No fat arrow: We explicitly do NOT want to capture "this"
                return item[property];
            });
        }

        return functionCache.get(property);
    }
}
