import moment from 'moment';
import { PxLicense } from './px-license';
import { PxResource } from './px-resource';

export class PxCluster extends PxResource {
    lastUpdated: moment.Moment | null;
    pxVersion: string;
    orchestratorVersion: string;
    status: string; // TBD

    // entity counts
    nodesOnlineCount: number;
    nodesOfflineCount: number;
    nodesWarningCount: number;
    volumesOnlineCount: number;
    volumesOfflineCount: number;
    volumesWarningCount: number;

    license: PxLicense | null;

    constructor(json: any) {
        super(json);
        this.id = json.cluster_id;
        this.name = json.name;
        this.lastUpdated = json.last_updated ? moment(json.last_updated) : null;
        this.pxVersion = json.px_version;
        this.orchestratorVersion = json.orchestrator_version;
        this.status = json.status;
        this.nodesOnlineCount = json.nodes_online_count;
        this.nodesOfflineCount = json.nodes_offline_count;
        this.nodesWarningCount = json.nodes_warning_count;
        this.volumesOnlineCount = json.volumes_online_count;
        this.volumesOfflineCount = json.volumes_offline_count;
        this.volumesWarningCount = json.volumes_warning_count;

        this.license = json.license_type ? new PxLicense({ type: json.license_type, exp: json.license_exp }) : null;
    }
}
