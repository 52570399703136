import { Directive, Renderer2, AfterViewInit, ElementRef, Input, OnDestroy } from '@angular/core';

/** Class to add when the dropdown is open */
const OPEN_CSS_CLASS = 'open';

@Directive({
    selector: '[pure-dropdown-hover-ng5]',
})
export class DropdownHoverDirective implements AfterViewInit, OnDestroy {
    @Input() readonly closeDelay: number;

    private closeTimeout: number = null;
    private nativeElement: HTMLElement;
    private deregisterOpenListener: () => void;
    private deregisterCancelListener: () => void;
    private deregisterCloseListener: () => void;

    constructor(
        private element: ElementRef<HTMLElement>,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        this.nativeElement = this.element.nativeElement;
        const list = this.nativeElement.querySelector('ul');
        if (list) {
            // Show on mouse enter
            this.renderer.listen(this.nativeElement, 'mouseenter', () => this.openDropDown());

            // Cancel the timeout when entering back into the element anywhere (not just the link)
            // We need a separate event from the one above to handle hovering over the popup as well
            if (this.closeDelay > 0) {
                this.renderer.listen(this.nativeElement, 'mouseenter', () => this.cancelCloseTimeout());
            }

            // Hide on mouse leave
            this.renderer.listen(this.nativeElement, 'mouseleave', () => this.closeDropDown());
        }
    }

    ngOnDestroy(): void {
        if (this.deregisterOpenListener) {
            this.deregisterOpenListener();
        }
        if (this.deregisterCancelListener) {
            this.deregisterCancelListener();
        }
        if (this.deregisterCloseListener) {
            this.deregisterCloseListener();
        }
    }

    private openDropDown(): void {
        this.renderer.addClass(this.nativeElement, OPEN_CSS_CLASS);
    }

    private closeDropDown(): void {
        if (this.closeDelay > 0) {
            // Remove class after delay
            this.cancelCloseTimeout();
            this.closeTimeout = window.setTimeout(() => {
                this.renderer.removeClass(this.nativeElement, OPEN_CSS_CLASS);
            }, this.closeDelay);
        } else {
            this.renderer.removeClass(this.nativeElement, OPEN_CSS_CLASS);
        }
    }

    private cancelCloseTimeout(): void {
        if (this.closeTimeout !== null) {
            window.clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
    }
}
