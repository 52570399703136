import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { ProductSectionComponent } from './product-section.component';
import { ProductSectionArrayContractExpirationComponent } from './product-section-array-contract-expiration/product-section-array-contract-expiration.component';
import { ProductSectionFlashArraysComponent } from './product-section-flasharrays/product-section-flasharrays.component';
import { ProductSectionFlashBladesComponent } from './product-section-flashblades/product-section-flashblades.component';
import { ProductSectionPxClustersComponent } from './product-section-px-clusters/product-section-px-clusters.component';
import { ProductSectionDisasterRecoveryClustersComponent } from './product-section-disaster-recovery/product-section-disaster-recovery.component';
import { CommonModule } from '@angular/common';
import { UIModule } from '../../ui/ui.module';
import { SharedModule } from '../shared/shared.module';
import { ProductSectionLicensesComponent } from './product-section-licenses/product-section-licenses.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule, NgSelectModule, SharedModule, UIModule],
    declarations: [
        ProductSectionComponent,
        ProductSectionArrayContractExpirationComponent,
        ProductSectionFlashArraysComponent,
        ProductSectionFlashBladesComponent,
        ProductSectionDisasterRecoveryClustersComponent,
        ProductSectionLicensesComponent,
        ProductSectionPxClustersComponent,
    ],
    exports: [ProductSectionComponent],
})
export class ProductSectionModule {}
