<div class="loading-spinner">
    <pureui-spinner class="p-loading" [pureuiShow]="isLoading"></pureui-spinner>
</div>
<ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="State.DEFAULT">
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <h3 class="modal-title"> Request Change for {{ opportunityQuote?.name | defaultString }} </h3>
            <p class="modal-description">
                Tell us the changes you need in the quote. The limit is <b>{{ CHAR_LIMIT }} characters</b>
            </p>
            <p class="modal-description">
                Please note: Only one modification request is allowed per quote. Upon submission, you'll receive a
                confirmation. You can access the updated quote on the Quote Management page once it is revised and
                shared. It may take 2 to 5 business days for processing.
            </p>
        </div>

        <main class="modal-body">
            <textarea
                class="form-control"
                [(ngModel)]="requestChanges"
                [readOnly]="isReadonly"
                [maxlength]="CHAR_LIMIT"
                rows="2"
                placeholder="Enter your changes here"
            ></textarea>
            <p class="changes-limitation"> {{ requestChanges.length }}/{{ CHAR_LIMIT }} characters </p>
        </main>

        <div class="modal-footer">
            <hive-button
                class="cancel-button"
                emphasis="secondary"
                label="Cancel"
                (click)="modal.dismiss()"
            ></hive-button>
            <hive-button
                class="submit-button"
                [disabled]="isSubmitDisabled()"
                label="Request Change"
                (click)="onSubmit()"
            ></hive-button>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="State.COMPLETED">
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <h3 class="modal-title"> Your quote modification request has been submitted </h3>
        </div>

        <main class="modal-body">
            <p>
                Your request to modify the quote has been received. We will review it and provide updates soon. Thank
                you.
            </p>
        </main>

        <footer class="modal-footer">
            <hive-button emphasis="secondary" label="Close" (click)="modal.dismiss()"></hive-button>
        </footer>
    </ng-container>

    <ng-container *ngSwitchCase="State.ERROR">
        <div class="modal-header">
            <button type="button" class="close modal-x-button pull-right" (click)="modal.dismiss()">
                <pureui-svg class="pstg-action-icon" [svg]="'x-action.svg'" [height]="12"></pureui-svg>
            </button>
            <pureui-svg class="pstg-action-icon" svg="warning-icon.svg" height="20"> </pureui-svg>
            <h3 class="modal-title">Oops! Something went wrong.</h3>
        </div>
        <main class="modal-body">
            <div>Please try again. If the issue persists, contact Pure1 Technical Services.</div>
        </main>
        <footer class="modal-footer">
            <hive-button emphasis="secondary" label="Close" (click)="modal.dismiss()"></hive-button>
        </footer>
    </ng-container>
</ng-container>
