import {
    DraasApiFailbackOvasWithPresignedLinks,
    DraasApiFailbackOvaWithPresignedLinks,
    DraasApiFailbackOvaPartWithPresignedLink,
} from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';

export class DisasterRecoveryOvasWithPresignedLinks {
    ovas: DisasterRecoveryOvaWithPresignedLinks[];
    expireAt: moment.Moment;
    secretKey: string;
    bashDownloadScript: string;
    powershellDownloadScript: string;

    constructor(json: DraasApiFailbackOvasWithPresignedLinks) {
        this.ovas = json.ovas.map(ova => new DisasterRecoveryOvaWithPresignedLinks(ova));
        this.expireAt = json.expire_at ? moment.utc(json.expire_at) : null;
        this.secretKey = json.secret_key;
        this.bashDownloadScript = json.bash_download_script;
        this.powershellDownloadScript = json.powershell_download_script;
    }
}

export class DisasterRecoveryOvaWithPresignedLinks {
    id: string;
    fileName: string;
    sizeInBytes: number;
    hash: string;
    parts: DisasterRecoveryOvaPartWithPresignedLink[];

    constructor(json: DraasApiFailbackOvaWithPresignedLinks) {
        this.id = json.id;
        this.fileName = json.file_name;
        this.sizeInBytes = json.size_in_bytes;
        this.hash = json.hash;
        this.parts = json.parts.map(part => new DisasterRecoveryOvaPartWithPresignedLink(part));
    }
}

export class DisasterRecoveryOvaPartWithPresignedLink {
    fileName: string;
    sizeInBytes: number;
    hash: string;
    presignedLink: string;

    constructor(json: DraasApiFailbackOvaPartWithPresignedLink) {
        this.fileName = json.file_name;
        this.sizeInBytes = json.size_in_bytes;
        this.hash = json.hash;
        this.presignedLink = json.presigned_link;
    }
}
