<div class="modal-body">
    <div class="title"> SafeMode Change Request </div>

    <ng-container *ngIf="createCaseDoneFlag; else confirmModalLoading">
        <ng-container *ngIf="!createCaseErrorFlag; else confirmModalError">
            <div class="container">
                <div class="new-request">
                    <pureui-svg class="pstg-action-icon" svg="checkmark-circle.svg" height="64"> </pureui-svg>
                    <div>
                        <div class="sub-title">SafeMode change request created successfully!</div>
                        <div class="description"
                            >We've received your request to perform the following actions. A case has been created.</div
                        >
                        <a
                            class="support-case"
                            hive-link
                            [supportCasesRedirect]="newRequest.caseId"
                            (click)="onDismiss.emit()"
                        >
                            TRACK CASE: {{ newRequest.caseNumber }}
                        </a>
                    </div>
                </div>
                <safemode-change-request-details
                    [flexDirection]="'column'"
                    [resources]="newRequest.resources"
                    [changeInParameters]="newRequest.changeParameters"
                    [executionTime]="newRequest.executionTime"
                >
                </safemode-change-request-details>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #confirmModalLoading>
        <br />
        <pureui-spinner class="p-loading" [pureuiShow]="true"></pureui-spinner>
        <br />
    </ng-template>
    <ng-template #confirmModalError>
        <div class="error-content">
            <h4>Oops... Something went wrong...</h4>
            <h4>Please contact administrator or try again later.</h4>
        </div>
    </ng-template>
</div>
