import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'formatEnergyUsage',
})
export class FormatEnergyUsagePipe implements PipeTransform {
    static readonly decimalPipe = new DecimalPipe('en-US');

    transform(data: number): string {
        if (typeof data !== 'number' || isNaN(data) || data < 0) {
            return null;
        }

        const formattedNumber = FormatEnergyUsagePipe.decimalPipe.transform(data, '1.2-2');
        return `${formattedNumber} W`;
    }
}
