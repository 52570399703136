import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { SimulatedVolume } from '../models/simulated-volume';

@Injectable({ providedIn: 'root' })
export class SimulatedVolumeService extends GenericService<SimulatedVolume> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: SimulatedVolume,
            endpoint: '/rest/v1/forecast/volumes',
            secondarySort: { key: 'name', order: 'asc' },
        });
    }
}
