import { AssessmentAnomalyChartValue, AssessmentAnomalyTimeSeries, AssessmentAnomalyVolume } from './assessment-array';

export class DataProtectionV2DrrVolume extends AssessmentAnomalyVolume {
    readonly id: string;
    readonly name: string;

    orgID: string;
    drrDrop: number;

    constructor(response: any) {
        super(response);
        this.orgID = response.orgID;
        this.valueChangePercentage = this.drrDrop = response.drrDrop;
    }
}

export interface FeedbackData {
    isHelpful: boolean;
    comment: string;
    selectedVolumes: SelectedVolume[];
    viaSubmitButton: boolean;
}

export interface SelectedVolume {
    volumeID: string;
    anomalyID: string;
}

export class DataProtectionV2ChartDrrData extends AssessmentAnomalyTimeSeries {
    constructor(response: any) {
        super(response);
        this.points = response.points.map(point => new DataProtectionV2DrrChartValue(point));
    }
}

export class DataProtectionV2DrrChartValue extends AssessmentAnomalyChartValue {
    constructor(response: any) {
        super(response);
        // UTC timestamp
        this.timestamp = response.timestamp * 1000;
        this.value = response.drrValue;
    }
}
