import moment from 'moment';
import { DraasApiJobExecutionInfo } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryJobExecutionStatus } from './disaster-recovery-job-execution';
import { DisasterRecoveryJobExecutionStep } from './disaster-recovery-job-execution-step';

export type DisasterRecoveryJobType =
    | 'UNKNOWN'
    | 'INVENTORY'
    | 'PROTECTION'
    | 'FAILOVER'
    | 'TEARDOWN'
    | 'FAILBACK'
    | 'VM_START'
    | 'VM_STOP'
    | 'REPLICATION'
    | 'INGEST'
    | 'FAILOVER_INFRASTRUCTURE'
    | 'IMPORT_IMAGE'
    | 'RETENTION'
    | 'CLEAN_VM_DATA'
    | 'FAILBACK_OVA_PRESIGNED_LINKS_JOB'
    | 'FAILBACK_CLEANUP'
    | 'PROTECTION_CLEANUP'
    | 'INGEST_CLEANUP'
    | 'LOCAL_CACHE_CLEANUP';

export class DisasterRecoveryJobExecutionWithSteps {
    id: string;
    executedOn: moment.Moment;
    status: DisasterRecoveryJobExecutionStatus;
    jobType: DisasterRecoveryJobType;
    steps: DisasterRecoveryJobExecutionStep[];
    description?: string;

    constructor(json: DraasApiJobExecutionInfo) {
        this.id = json.execution_info.id;
        this.executedOn = json.execution_info.executed_on ? moment.utc(json.execution_info.executed_on) : null;
        this.status =
            DisasterRecoveryJobExecutionStatus[json.execution_info.status] ||
            DisasterRecoveryJobExecutionStatus.UNKNOWN;
        this.jobType = json.execution_info.job_type || 'UNKNOWN';
        this.steps = json.steps.map((stepJson, idx) => new DisasterRecoveryJobExecutionStep(stepJson, this.id, idx));
        this.description = json.description;
    }
}
