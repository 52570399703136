import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UnifiedArray, ServiceCatalogQuote } from '@pure1/data';

import { getPageBaseContentElem, infiniteScrollFillContainer } from '../../utils/dom';
import { AssetSortRules, SortRules } from '../services/asset-sort-rules.service';
import { AppliancesViewMode } from '../appliances-view/appliances-view.component';
import { FLEET_LOCATORS } from '../../testing/locators/fleet/fleet.locators';

const INITIAL_ITEM_COUNT = 30;

@Component({
    selector: 'arrays-card-view',
    templateUrl: 'arrays-card-view.component.html',
})
export class ArraysCardViewComponent implements OnChanges {
    @Input() readonly filteredArrays: UnifiedArray[];
    @Input() readonly alertMap: Map<string, IAlert[]>;
    @Input() readonly allOrders: ServiceCatalogQuote[] = [];
    @Input() readonly isLoading: boolean;
    @Input() readonly showWarningCardForOutOfSupportAppliance: boolean = true; // defaults to true
    @Input() readonly mode: AppliancesViewMode;

    sortRules: SortRules<UnifiedArray>;
    displayedItems: UnifiedArray[];
    baseContentElem: HTMLElement;

    private readonly isFlipped = new Map<string, boolean>();
    private readonly isAnimated = new Map<string, boolean>();
    private numItemsToShow = INITIAL_ITEM_COUNT;

    protected readonly FLEET_LOCATORS = FLEET_LOCATORS;

    constructor(private assetSortRules: AssetSortRules) {}

    ngOnChanges(changes: SimpleChanges): void {
        // Cache the elem. Must be done in ngOnChanges() since its used in here, and this gets called before ngOnInit().
        this.baseContentElem = this.baseContentElem || getPageBaseContentElem();

        if (changes.mode) {
            switch (this.mode) {
                case 'array':
                    this.sortRules = this.assetSortRules.getArraySort(() => this.alertMap);
                    break;
                default:
                    this.sortRules = null;
                    break;
            }
        }

        if (changes.filteredArrays) {
            this.updateDisplayedItems();

            infiniteScrollFillContainer(
                () => this.filteredArrays && this.numItemsToShow < this.filteredArrays.length,
                () => this.infiniteScrollAddMore(),
                this.baseContentElem,
            );
        }
    }

    isCardFlipped(item: UnifiedArray): boolean {
        return this.isFlipped.get(item.id) === true;
    }

    isCardAnimated(item: UnifiedArray): boolean {
        return this.isAnimated.get(item.id) === true;
    }

    flipCard(item: UnifiedArray, event: MouseEvent): void {
        this.isAnimated.set(item.id, true); // Animate the card flip
        this.isFlipped.set(item.id, !this.isCardFlipped(item)); // Reverse the flipped state
        event.stopPropagation();
        event.preventDefault();
    }

    getAlerts(item: UnifiedArray): IAlert[] {
        return this.alertMap?.get(item.id) || [];
    }

    infiniteScrollAddMore(): void {
        this.numItemsToShow += INITIAL_ITEM_COUNT;
        this.updateDisplayedItems();
    }

    private updateDisplayedItems(): void {
        this.displayedItems = this.filteredArrays.slice(0, this.numItemsToShow);
    }
}
