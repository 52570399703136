import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CachedCurrentUserService } from '@pure1/data';
import { take } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';

@Component({
    selector: 'request-confirmed-modal',
    templateUrl: './request-confirmed-modal.component.html',
})
export class RequestConfirmedModalComponent implements OnInit {
    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly canTrackOrder: boolean = false;
    @Input() readonly angularticsAction: string;
    @Input() readonly title: string = 'Request Submitted';
    @Input() readonly description: string = 'We have received your request and will review it shortly.';

    userName: string;

    constructor(
        private cachedCurrentUserService: CachedCurrentUserService,
        private modalService: NgbModal,
        private angulartics2: Angulartics2,
    ) {}

    ngOnInit(): void {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(cu => {
                this.userName = cu.name;
            });
    }

    clickOrdersPage(): void {
        if (!this.canTrackOrder) {
            return;
        }

        this.angulartics2.eventTrack.next({
            action: this.angularticsAction,
        });

        this.modalService.dismissAll();
    }

    confirmedRequest(): void {
        this.activeModal.close();
    }
}
