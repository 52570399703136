<div class="calendar-time-range-container nav-pagination btn-group text-nowrap">
    <div
        class="calendar-time-range-dropdown"
        ngbDropdown
        #dropDown="ngbDropdown"
        autoClose="outside"
        container="body"
        placement="bottom-right"
        (openChange)="dropdownOpenChange($event)"
    >
        <button
            class="dropdown-button btn btn-secondary with-caret"
            ngbDropdownToggle
            [disabled]="timeRangeSelectDisabled"
        >
            <span class="selected-option-text"> {{ getSelectionName() }} </span>
        </button>
        <div ngbDropdownMenu class="calendar-time-range-dropdown-menu" [ngClass]="{ expanded: !isCustom }">
            <div class="dropdown-row">
                <div class="dropdown-column" *ngIf="isCustom">
                    <div class="datepicker-navbar">
                        <div class="date-range">
                            <p *ngIf="ngbDateRange.start">
                                {{
                                    ngbDateToMoment(ngbDateRange.start).toDate()
                                        | date: 'longDate' : (isUTCTime ? 'UTC' : undefined)
                                }}
                                &ndash;
                                {{
                                    ngbDateRange.end
                                        ? (ngbDateToMoment(ngbDateRange.end).toDate()
                                          | date: 'longDate' : (isUTCTime ? 'UTC' : undefined))
                                        : ''
                                }}
                            </p>
                        </div>
                        <div class="btn-group">
                            <div
                                ngbDropdown
                                class="btn-group jump-year-dropdown"
                                *ngIf="jumpYears && jumpYears.length > 0"
                            >
                                <button class="dropdown-button btn btn-secondary" ngbDropdownToggle>
                                    Jump to year...
                                </button>
                                <div ngbDropdownMenu>
                                    <button
                                        class="jump-year-dropdown-item"
                                        *ngFor="let year of jumpYears; trackBy: trackByValue"
                                        ngbDropdownItem
                                        (click)="jumpToYear(year)"
                                    >
                                        {{ year }}
                                    </button>
                                </div>
                            </div>
                            <div class="btn-group">
                                <button type="button" class="btn btn-secondary" (click)="shiftToToday()">
                                    Today
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secondary with-action-icon time-move-left"
                                    (click)="shiftMonths(-1)"
                                    [disabled]="shiftMonthLeftDisabled()"
                                >
                                    <pureui-svg
                                        class="pstg-action-icon"
                                        svg="chevron-left.svg"
                                        height="10"
                                    ></pureui-svg>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secondary with-action-icon time-move-right"
                                    (click)="shiftMonths(1)"
                                    [disabled]="shiftMonthRightDisabled()"
                                >
                                    <pureui-svg
                                        class="pstg-action-icon"
                                        svg="chevron-right.svg"
                                        height="10"
                                    ></pureui-svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ngb-datepicker
                        class="calendar-time-range-datepicker"
                        [displayMonths]="2"
                        navigation="none"
                        [dayTemplate]="dt"
                        outsideDays="collapsed"
                        [maxDate]="getMaxNgbDate()"
                        [minDate]="getMinNgbDate()"
                        (dateSelect)="selectCustomDate($event)"
                        [startDate]="customDisplayedMonth"
                    >
                    </ngb-datepicker>
                    <ng-template #dt let-date let-focused="today" let-disabled="disabled">
                        <span
                            class="datepicker-day"
                            [class.focused]="focused"
                            [class.in-range]="isInsideRange(date, ngbDateRange)"
                            [class.selected-start]="date.equals(ngbDateRange.start)"
                            [class.selected-end]="date.equals(ngbDateRange.end)"
                            [class.disabled]="disabled || isOutOfRange(date)"
                            (mouseenter)="ngbDateRange.hover = date"
                            (mouseleave)="ngbDateRange.hover = null"
                        >
                            {{ date.day }}
                        </span>
                    </ng-template>
                </div>
                <div class="dropdown-column dropdown-choices">
                    <div *ngFor="let timeRangeOption of options; trackBy: 'text' | trackByProperty">
                        <button
                            ngbDropdownItem
                            class="btn dropdown-item"
                            [ngClass]="{ selected: isSelected(timeRangeOption) }"
                            (click)="clickSelection(timeRangeOption)"
                        >
                            {{ timeRangeOption.text }}
                        </button>
                    </div>
                    <div class="custom-option" *ngIf="enableCustomChoice && !customLabel">
                        <button
                            ngbDropdownItem
                            class="btn dropdown-item custom-dropdown-item"
                            [ngClass]="{ selected: isCustom }"
                            (click)="showCustomPicker()"
                        >
                            Custom
                        </button>
                    </div>
                    <div *ngIf="isCustom" class="action-buttons">
                        <button
                            class="btn btn-primary datepicker-action-button"
                            *ngIf="showReset"
                            (click)="resetCustomPicker()"
                        >
                            Reset
                        </button>
                        <button
                            class="btn btn-primary datepicker-action-button"
                            (click)="doneClicked()"
                            [disabled]="doneDisabled()"
                        >
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
