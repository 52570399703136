<div class="export-button">
    <button
        *ngIf="!showText && !showNewIcon"
        class="btn btn-secondary icon-only"
        (click)="clickButton($event)"
        #exportButton
    >
        <pureui-svg class="pstg-action-icon" svg="export.svg" height="14"></pureui-svg>
    </button>
    <button *ngIf="!showText && showNewIcon" class="arrow-icon-only" (click)="clickButton($event)" #exportButton>
        <pureui-svg class="arrow-icon" svg="arrow-up-from-square.svg" height="18"></pureui-svg>
        <pureui-svg class="chevron-down-icon" svg="chevron-down.svg" height="10"></pureui-svg>
    </button>

    <button
        *ngIf="showText"
        class="btn btn-secondary"
        [ngClass]="{ 'with-caret': useDropdown(), up: useDropdown() && isDropdownOpen }"
        (click)="clickButton($event)"
        #exportButton
    >
        <pureui-svg class="pstg-action-icon" svg="export.svg" height="10"></pureui-svg>
        <span class="export-text">Export</span>
    </button>

    <ul
        class="list-group no-disabled-background"
        *ngIf="useDropdown() && isDropdownOpen"
        [ngClass]="{ 'right-position': useRightPosition }"
    >
        <li
            *ngFor="let option of exportOptions; trackBy: 'id' | trackByProperty"
            (click)="clickOption(option, $event)"
            [ngClass]="{ disabled: option.disabled }"
            class="list-group-item list-group-item-action"
        >
            <span>{{ option.text }}</span>
            <pureui-spinner [pureuiShow]="option.count == null && useSpinner"></pureui-spinner>
            <span [hidden]="option.count == null"> ({{ option.count }})</span>
        </li>
    </ul>
</div>
