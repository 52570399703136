import { Injectable } from '@angular/core';

import { DirectorySnapshot } from '../models/directory-snapshot';
import { Collection } from '../interfaces/collection';
import { Observable, of } from 'rxjs';
import { ListParams, QueryParams } from '../interfaces/list-params';
import { DataPage } from '../interfaces/data-page';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DirectorySnapshotService extends GenericService<DirectorySnapshot> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: DirectorySnapshot,
            endpoint: '/rest/v4/directory-snapshots',
        });
    }
}
