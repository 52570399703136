<ng-container>
    <div *ngIf="loading">
        <pureui-spinner [pureuiShow]="loading"></pureui-spinner>
    </div>
    <ng-container *ngIf="!loading">
        <div *ngIf="isBeingEdited && form.controls.product">
            <input
                class="form-control full-width"
                type="text"
                [value]="
                    form.controls.product.value?.fqdn ||
                    form.controls.product.value?.name ||
                    form.controls.product.value?.hostname
                "
                disabled="true"
            />
        </div>
        <div *ngIf="!isBeingEdited" [formGroup]="form">
            <search-array
                #searchApplianceInput
                formControlName="product"
                [checkSupportContract]="checkSupportContract"
                [arrays]="appliances"
            ></search-array>
            <product-section-array-contract-expiration
                *ngIf="form.controls.product?.value"
                [checkSupportContract]="checkSupportContract"
                [product]="form.controls.product.value"
            ></product-section-array-contract-expiration>
        </div>
    </ng-container>
</ng-container>
