import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StepUpError, StepUpFactor, StepUpVerifyResponse } from '@pure1/data';

@Component({
    selector: 'verify-challenge-modal',
    templateUrl: 'verify-challenge-modal.component.html',
    host: { class: 'step-up-modal' },
})
export class VerifyChallengeModalComponent {
    @Input() readonly factors: StepUpFactor[];
    @Input() readonly audience: string;
    @Input() readonly authorizationDetails: any[];
    @Input() readonly singleUse: boolean;
    // Is this verify-challenge for activation of a factor in PENDING_ACTIVATION status?
    @Input() readonly isActivate: boolean;
    // Required if isActivate is true, for resending activation code in case user didn't successfully receive one.
    @Input() readonly authToResendEnroll: string;
    // If present, shows a red text in the modal subtitle.
    @Input() readonly additionalWarning: string;
    // False if there is any next steps after this verify-challenge step,
    // e.g. two steps needed in registration, one for email/current phone, another for new phone.
    @Input() readonly closeModalOnSuccess = true;
    @Input() readonly ttl: number;

    /**
     * If closeModalOnSuccess is true, we listen to the modal close/dismiss event.
     *                       cancel()              verifyFail()              verifySuccess()              continue()
     * verifyOutput           emit (don't care)      emit (don't care)         no emit (don't care)         N/A
     * activeModal            dismiss                dismiss                   close                        N/A
     *
     * If closeModalOnSuccess is false, modal is left open after StepUpChallengeComponent emits an output, therefore we listen to this verifyOutput.
     *                       cancel()              verifyFail()              verifySuccess()              continue()
     * verifyOutput           emit                   emit                      no emit                      emit
     * activeModal            dismiss                dismiss                   no-op                        no-op
     */
    @Output() readonly verifyOutput = new EventEmitter<StepUpVerifyResponse>();

    stepUpFactor: StepUpFactor;
    stepUpVerifyResponse: StepUpVerifyResponse;
    continueButtonDisabled: boolean;

    constructor(public activeModal: NgbActiveModal) {}

    init(): void {
        if (this.factors && this.factors.length > 0) {
            if (this.isActivate) {
                this.stepUpFactor = this.factors.find(
                    factor => factor.factorType === 'sms' && factor.status === 'PENDING_ACTIVATION',
                );
                if (!this.stepUpFactor) {
                    // If we can't find a pending sms factor
                    this.stepUpFactor = this.factors.find(
                        factor => factor.factorType === 'sms' && factor.status === 'ACTIVE',
                    );
                    if (this.stepUpFactor) {
                        // But there is an already active sms factor
                        // Phone already active, nothing to do here. Mostly like because phone was previously registered at some point.
                        // Activation doesn't need anything from any response afterwards, so closing with a null response.
                        this.verifySuccess(null);
                        return;
                    }
                }
            } else {
                this.stepUpFactor = this.factors.find(
                    factor => factor.factorType === 'totp' && factor.status === 'ACTIVE',
                );
                if (!this.stepUpFactor) {
                    this.stepUpFactor = this.factors.find(
                        factor => factor.factorType === 'sms' && factor.status === 'ACTIVE',
                    );
                }
                if (!this.stepUpFactor) {
                    // if we can't find sms factor, we are in the first step of registration flow, and should use email factor.
                    this.stepUpFactor = this.factors.find(factor => factor.factorType === 'email');
                }
            }
            this.stepUpVerifyResponse = null; // Clear any previous response to start anew.
        }

        if (!this.stepUpFactor) {
            console.warn('no step up factor found for the user');
        }
    }

    verifySuccess(response: StepUpVerifyResponse): void {
        if (this.closeModalOnSuccess) {
            this.activeModal.close(response);
        } else {
            this.stepUpVerifyResponse = response;
            this.continueButtonDisabled = false;
        }
    }

    continue(): void {
        this.continueButtonDisabled = true;
        this.verifyOutput.emit(this.stepUpVerifyResponse);
    }

    verifyFail(error: StepUpError): void {
        this.verifyOutput.emit(null);
        this.activeModal.dismiss(error);
    }

    cancel(): void {
        this.verifyOutput.emit(null);
        this.activeModal.dismiss({ error: 'cancelled', errorDescription: 'cancelled' });
    }
}
