import {
    HIVE_DATAVIZ_CAT1,
    HIVE_DATAVIZ_CAT10,
    HIVE_DATAVIZ_CAT3,
    HIVE_DATAVIZ_CAT4,
    HIVE_DATAVIZ_CAT5,
    HIVE_DATAVIZ_CAT7,
    HIVE_DATAVIZ_CAT9,
    HIVE_SEMANTIC_INFO_50,
    HIVE_SEMANTIC_MAJOR_40,
    HIVE_SEMANTIC_SUCCESS_40,
    HIVE_SEMANTIC_SUCCESS_80,
} from './old-hive-tokens';

export const Theme = {
    analysis: {
        capacityPercentFull: '#82a6c3',
        now: 'rgba(0, 128, 0, 0.8)',
        sliderFill: 'rgb(255, 255, 255)',
        sliderStroke: 'rgb(178, 177, 182)',
        shadow: 'rgba(211, 211, 211, 0.4)',
    },
    blue: '#5ab0ee',
    red: '#f33b2e',
    yellow: '#fac13a',
    green: '#71a74f',
    gray: '#e6e6e6',
    ok: '#5ab0ee',
    zeroColor: '#F4F2F3',
    white: '#fff',
    replication: {
        grid: {
            stroke: '#888',
        },
    },
    tooltip: {
        backgroundColor: 'rgba(240, 240, 240, 0.9)',
        borderColor: '#CCCCCC',
    },
    capacity: {
        // TODO: figure out how (if possible) to retreive these from ui-common
        unique: { fill: '#2EC6C8', outline: '1px solid #2EC6C8' },
        volumes: { fill: '#7d9ad2', outline: '1px solid #7d9ad2' }, // $hive-dataviz-cat1
        snapshots: { fill: '#B5A1DD', outline: '1px solid #B5A1DD' },
        replication: { fill: '#DDD000', outline: '1px solid #DDD000' },
        shared: { fill: '#55C707', outline: '1px solid #55C707' },
        system: { fill: '#B8BEBE', outline: '1px solid #B8BEBE' },
        empty: { fill: '#F4F2F3', outline: '1px solid #cccccc' },
        fileSystems: { fill: '#52C8FD', outline: '1px solid #52C8FD' },
        objectStore: { fill: '#4384B1', outline: '1px solid #4384B1' },
    },
    volumeCapacity: {
        volumePhysical: { fill: '#1A9EA7', dashStyle: 'Solid' },
        snapshotPhysical: { fill: '#3D265A', dashStyle: 'Solid' },
        totalPhysical: { fill: '#FE5000', dashStyle: 'Solid' },
        volumeVirtual: { fill: '#00B89E', dashStyle: 'Dot' },
        snapshotVirtual: { fill: '#5B5BB6', dashStyle: 'Dot' },
        totalVirtual: { fill: '#FE7800', dashStyle: 'Dot' },
        volumeEffectiveUsed: { fill: '#71A74F', dashStyle: 'ShortDashDot' },
        snapshotEffectiveUsed: { fill: '#BB28D6', dashStyle: 'ShortDashDot' },
        totalEffectiveUsed: { fill: '#FEB804', dashStyle: 'ShortDashDot' },
    },
    directoryCapacity: {
        uniquePhysical: { fill: '#1A9EA7', dashStyle: 'Solid' },
        snapshotPhysical: { fill: '#3D265A', dashStyle: 'Solid' },
        totalPhysical: { fill: '#FE5000', dashStyle: 'Solid' },
        uniqueEffectiveUsed: { fill: '#71A74F', dashStyle: 'ShortDashDot' },
        snapshotEffectiveUsed: { fill: '#BB28D6', dashStyle: 'ShortDashDot' },
        totalEffectiveUsed: { fill: '#FEB804', dashStyle: 'ShortDashDot' },
    },
    ecology: {
        neutral: '#E6E6E6',
        best: '#3AAE03',
        worse: '#FF7E35',
    },
    performance: {
        chart_default_height_px: 200,
        read: '#0D98E3',
        write: '#F37430',
        mirrored_write: '#8F6BB2',
        other: '#8F6BB2',
        aggregate: '#8F6BB2',
        series: {
            read: {
                colors: [
                    '#1f5e23',
                    '#e44f12',
                    '#005a99',
                    '#87124f',
                    '#5c3f37',
                    '#065063',
                    '#827620',
                    '#2f2590',
                    '#b51a1f',
                    '#008989',
                ],
            },
            write: {
                colors: [
                    '#50ae54',
                    '#fa8a1c',
                    '#1d8ce3',
                    '#d61d60',
                    '#8d6e64',
                    '#297d92',
                    '#b0b236',
                    '#6541b5',
                    '#ed5252',
                    '#33c7c7',
                ],
            },
            mirrored_write: {
                colors: [
                    '#83c686',
                    '#ffb554',
                    '#64b7f4',
                    '#ee6392',
                    '#a18880',
                    '#5fabbe',
                    '#ced945',
                    '#9378cb',
                    '#ee9a9b',
                    '#55dede',
                ],
            },
            other: {
                colors: [
                    '#83c686',
                    '#ffb554',
                    '#64b7f4',
                    '#ee6392',
                    '#5fabbe',
                    '#ced945',
                    '#a18880',
                    '#9378cb',
                    '#ee9a9b',
                    '#55dede',
                ],
            },
            // For use cases with more than 3 series that need different colors at the same time, we can map them to the following.
            generic_series_1: {
                colors: [
                    '#004D40',
                    '#E65100',
                    '#01579B',
                    '#4A148C',
                    '#3E2723',
                    '#33691E',
                    '#F57F17',
                    '#1A237E',
                    '#B71C1C',
                    '#263238',
                ],
            },
            generic_series_2: {
                colors: [
                    '#00796B',
                    '#F57C00',
                    '#0288D1',
                    '#7B1FA2',
                    '#5D4037',
                    '#689F38',
                    '#FBC02D',
                    '#303F9F',
                    '#D32F2F',
                    '#455A64',
                ],
            },
            generic_series_3: {
                colors: [
                    '#009688',
                    '#FF9800',
                    '#03A9F4',
                    '#9C27B0',
                    '#795548',
                    '#8BC34A',
                    '#FFEB3B',
                    '#3F51B5',
                    '#F44336',
                    '#607D8B',
                ],
            },
            generic_series_4: {
                colors: [
                    '#4DB6AC',
                    '#FFB74D',
                    '#4FC3F7',
                    '#BA68C8',
                    '#A1887F',
                    '#AED581',
                    '#FFF176',
                    '#7986CB',
                    '#E57373',
                    '#90A4AE',
                ],
            },
            generic_series_5: {
                colors: [
                    '#B2DFDB',
                    '#FFE0B2',
                    '#B3E5FC',
                    '#E1BEE7',
                    '#D7CCC8',
                    '#DCEDC8',
                    '#FFF9C4',
                    '#C5CAE9',
                    '#FFCDD2',
                    '#CFD8DC',
                ],
            },
            // Special series colors to use for a chart overlay (eg "compare to same time yesterday")
            overlay: {
                read: '#777777',
                write: '#aaaaaa',
                mirrored_write: '#c1c1c1',
                other: '#dddddd',
            },
            // Special series colors to use for a MetricGroup, for when using the "group_selection" view
            comparisonMetricGroup: {
                read: '#008989',
                write: '#33c7c7',
                mirrored_write: '#55dede',
                other: '#55dede',
            },
        },
    },
    safemode: {
        enabled: { fill: '#71A74F' },
        partial: { fill: '#61C3C6' },
        disabled: { fill: '#8D8D8D' },
        upgrade_req: { fill: '#BBBBBB' },
        excluded: { fill: '#6C6C6C' }, // pure-gray 80%
    },
    subscription: {
        colors: ['#297d92', '#6541b5', '#ed5252', '#33c7c7', '#50ae54', '#fa8a1c', '#1d8ce3', '#d61d60', '#8d6e64'],
    },
    purityOptimizations: {
        cards: {
            optedIn: '#3B99FC',
            eligible: '#F2C94C',
            completed: '#1D4C7E',
            failed: '#FF7070',
        },
    },
    edgeService: {
        arraysHealth: {
            healthy: '#7BC753',
            unhealthy: '#FF7070',
            na: '#FFC121',
            offline: { fill: '#EDEDED', borderColor: '#979797' },
            edgeServiceNotEnabled: '#C4C4C4',
            purityUpgradeRequired: { fill: '#FDE9D6', borderColor: '#F33B2E' },
            _base_circle_: '#F5F5F5',
        },
        arraysAgentAvailability: {
            hasAgent: '#66B2EE',
            hasNoAgent: { fill: '#E5F9FF', borderColor: '#1186DC' },
        },
        agentIcons: {
            mock3: 'logo_small.png',
            'mock-agent-1': 'edge-service-agent-install.svg',
            'e-one-perf-mgmt-agent': 'edge-agents-e-one-perf-mgmnt.svg',
        },
        gatewayUpdateStatus: {
            inactive: '#E9E9E9',
            eligibleForUpdate: '#2EC6C8',
            eligibleButUpdateDisallowed: '#B0E593',
            eligibleButPurityUpdateRequired: '#FECFA2',
            upToDate: '#4384B1',
            installing: '#9057C4',
            updating: '#56B0F6',
            uninstalling: '#D1BEF5',
            updateFailed: '#AE1E19',
            uninstallFailed: '#F03D37',
            installFailed: '#FFA37C',
        },
    },
    draasOverview: {
        green: '#71a74f',
        lightGreen: '#9BC183',
        red: '#ED7974',
        yellow: '#F2C94C',
        lightGray: '#EDEDED',
        gray: '#C5C5C5',
    },
    dpa: {
        green: '#45B08C',
        red: '#FE5000',
        lightGray: '#F4FBFF',
        gray: '#888888',
        bgGray: '#B0B0B0',
        darkGray: '#222222',
        textGray: '#454545',
        blue: '#178EE4',
        orange: '#FFB78A',
        lightBlue: '#9AD7E4',
        purple: '#B77DD2',
    },
    security: {
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        orange: HIVE_SEMANTIC_MAJOR_40,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        lightGreen: HIVE_SEMANTIC_SUCCESS_40,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        green: HIVE_SEMANTIC_SUCCESS_80,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        blue: HIVE_SEMANTIC_INFO_50,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        violet: HIVE_DATAVIZ_CAT1,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        darkViolet: HIVE_DATAVIZ_CAT5,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        darkRose: HIVE_DATAVIZ_CAT9,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        aquamarine: HIVE_DATAVIZ_CAT4,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        darkOrange: HIVE_DATAVIZ_CAT7,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        lightOrange: HIVE_DATAVIZ_CAT10,
        // @deprecated This variable have to be mapped into hive v3 semantic colors
        rose: HIVE_DATAVIZ_CAT3,
        lightGray: '#e6e6e6',
        gray: '#888888',
        darkGray: '#363636',
        tooltip: {
            backgroundColor: 'rgba(255,255,255,0.95)',
        },
    },
};
