<div class="modal-body">
    <button type="button" class="close modal-x-button safemode-enable-close-button" (click)="onCancel.emit()">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" [height]="12"></pureui-svg>
    </button>

    <div class="title">Select Protection Groups</div>

    <div class="pgroups-info">
        <pureui-svg class="pstg-action-icon" svg="info-bubble-icon.svg" height="40"></pureui-svg>
        <span>Changes on Eradication Timer will not apply to Protection Groups.</span>
    </div>

    <div class="pagination">
        <hive-pagination
            [totalItemCount]="dataSource.totalItemCount"
            [itemsPerPage]="dataSource.pageSize"
            [offset]="dataSource.offset"
            [pageSizeDropdownVisible]="false"
            (pageChange)="dataSource.pageChange($event)"
        ></hive-pagination>
    </div>

    <hive-table
        dataKey="id"
        [selection]="selectedPGroups"
        selectionMode="multiple"
        [filters]="{ safemodePgroupSelector: dataSource.filtering }"
        [value]="dataSource.data"
        [isLoading]="dataSource.loading"
        (filterChange)="dataSource.filterChanged($event)"
        (selectionChange)="togglePGroupSelection($event)"
    >
        <ng-template hiveTemplate="header">
            <tr>
                <th hive-square-header id="rowCheckbox"> </th>
                <th hive-template-header id="name" colWeight="5">
                    <div class="filter-field">
                        <div class="small-input">
                            <hive-text-field
                                label="Array Name"
                                size="small"
                                [formControl]="arrayNameFilter"
                                showClear="true"
                            ></hive-text-field>
                        </div>
                        /
                        <div class="bigger-input">
                            <hive-text-field
                                class="bigger-input"
                                label="Pod Name / Protection Group Name"
                                size="small"
                                [formControl]="nameFilter"
                                showClear="true"
                            ></hive-text-field>
                        </div>
                    </div>
                </th>
                <th id="numberOfVolumes" primaryText="Number of Volumes" colWeight="1" hive-header></th>
                <th id="retentionLock" primaryText="SafeMode" colWeight="1" hive-header></th>
            </tr>
        </ng-template>

        <ng-template hiveTemplate="body" let-pgroup>
            <tr [hiveTableRow]="pgroup" selectable>
                <td hive-square-cell>
                    <hive-table-checkbox></hive-table-checkbox>
                </td>
                <td hive-template-cell>
                    <div class="template-hive-cell">
                        <safemode-pgroup
                            [pgroupId]="pgroup.id"
                            [applianceName]="pgroup.arrays[0].name"
                        ></safemode-pgroup>
                    </div>
                </td>
                <td hive-cell textOverflow="tooltip" [primaryText]="pgroup.volumeCount"></td>
                <td
                    hive-cell
                    textOverflow="tooltip"
                    [primaryText]="pgroup.retentionLock === 'ratcheted' ? 'Enabled' : 'Disabled'"
                ></td>
            </tr>
        </ng-template>
    </hive-table>

    <div class="btn-toolbar clearfix mt-1">
        <pure-entity-selection-dropdown
            entityName="Selections"
            [isSelectable]="false"
            [entities]="selectedResources"
            (entitiesChange)="updateSelectedPGroups($event)"
        >
        </pure-entity-selection-dropdown>
        <span
            *ngIf="{ selectedPGroups, selectedArrays } | memoizeFnc: reachedPGroupLimit; else infoMessage"
            class="errorMessage"
        >
            You reached the limit ({{ maxSelectedAppliances }} appliances max)
        </span>
        <ng-template #infoMessage>
            <span class="infoMessage">Select any number of protection groups</span>
        </ng-template>
        <button
            type="button"
            class="btn btn-primary float-xs-right margin"
            angulartics2On="click"
            angularticsCategory="Action"
            angularticsAction="Assessment - SafeMode - pgroup Selector - Continue schedule request"
            [angularticsLabel]="selectedPGroups.length + ''"
            (click)="onNext()"
            [disabled]="!({ selectedPGroups, selectedArrays } | memoizeFnc: canSchedule)"
        >
            Select
        </button>
        <button type="button" class="btn btn-secondary float-xs-right margin" (click)="onCancel.emit()">
            Cancel
        </button>
    </div>
</div>
