import { Resource } from '../interfaces/resource';

export class UsageAggregation {
    name: string;
    aggregation: number;

    constructor(json: any) {
        this.name = json.entity_id;
        this.aggregation = json.usage_data;
    }
}

export class SubscriptionUsageAggregations implements Resource {
    readonly id: string;
    readonly name: string;

    readonly subscriptionUsages: UsageAggregation[];
    readonly serviceLevelUsages: UsageAggregation[];
    readonly licenseLevelUsages: UsageAggregation[];

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.subscriptionUsages = (json.aggregation_by_subscription || []).map(
            aggregation => new UsageAggregation(aggregation),
        );
        this.licenseLevelUsages = (json.aggregation_by_license || []).map(
            aggregation => new UsageAggregation(aggregation),
        );
        this.serviceLevelUsages = (json.aggregation_by_service_tier || []).map(
            aggregation => new UsageAggregation(aggregation),
        );
    }
}
