import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Incident, IncidentDTO } from '../models/incident';
import { map, Observable, take } from 'rxjs';
import { IRestResponse } from '../interfaces/collection';

@Injectable({ providedIn: 'root' })
export class IncidentService extends GenericService<Incident> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Incident,
            endpoint: '/rest/v1/incidents',
            update: true,
        });
    }

    createSERec(incidentDTO: IncidentDTO): Observable<Object> {
        return this.http.post('/rest/v1/incidents', incidentDTO);
    }

    /**
     * Fetch emails which will receive a message when an SE Rec is created.
     * On writting these are the admins of the organization, but could change
     */
    getRecipientEmails(orgId: number, arrayId: string): Observable<string[]> {
        return this.http
            .get<
                IRestResponse<string>
            >('/rest/v1/contacts/admin-emails', { params: { org_id: orgId, array_id: arrayId } })
            .pipe(
                take(1),
                map(res => res?.items || []),
            );
    }
}
