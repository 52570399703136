import { Component, Input } from '@angular/core';

export interface AssetManagementSummaryInfo {
    title: string;
    svg: string;
    value: number;
    tooltip?: string;
}

@Component({
    selector: 'asset-management-summary-card',
    templateUrl: './asset-management-summary-card.component.html',
})
export class AssetManagementSummaryCardComponent {
    @Input() readonly summaryInfo: AssetManagementSummaryInfo;
}
