import { Resource } from '../interfaces/resource';

export type WorkloadSizes = 'Small' | 'Typical' | 'Large' | 'Custom';

export interface PhantomWorkloadLiteral {
    id: string;
    name: string;
    data_reduction: number;
    read_io_size: number;
    write_io_size: number;
    read_iops: number;
    write_iops: number;
    read_bandwidth: number;
    write_bandwidth: number;
    system_capacity: number;
    application_type: string;
    metadata_percentage: number;
    tshirt_size: WorkloadSizes;
}

export class PhantomWorkload implements Resource {
    readonly id: string;
    readonly name: string;
    readonly dataReduction: number;
    readonly readIoSize: number;
    readonly writeIoSize: number;
    readonly readIops: number;
    readonly writeIops: number;
    readonly readBandwidth: number;
    readonly writeBandwidth: number;
    readonly systemCapacity: number;
    readonly applicationType: string;
    readonly metadataPercentage: number;
    readonly tshirtSize: WorkloadSizes;

    constructor(json: any) {
        this.id = String(json.id);
        this.name = json.name;
        this.dataReduction = json.data_reduction;
        this.readIoSize = json.read_io_size;
        this.writeIoSize = json.write_io_size;
        this.readIops = json.read_iops;
        this.writeIops = json.write_iops;
        this.readIops = json.read_iops;
        this.writeIops = json.write_iops;
        this.readBandwidth = json.read_bandwidth;
        this.writeBandwidth = json.write_bandwidth;
        this.systemCapacity = json.system_capacity;
        this.applicationType = json.application_type;
        this.metadataPercentage = json.metadata_percentage;
        this.tshirtSize = json.tshirt_size;
    }
}
