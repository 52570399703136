export interface IReportChoice<T> {
    value: T;
    displayName: string;
}

export const REPORT_VIEW_CHOICES: IReportChoice<dpaReportView>[] = [
    { value: 'BOTH', displayName: 'Resiliency Score & Assessment Report' },
    { value: 'RESILIENCY_SCORE', displayName: 'Resiliency Score Report' },
];

export const REPORT_DETAIL_CHOICES: IReportChoice<dpaReportDetails>[] = [
    { value: 'ALL', displayName: 'All' },
    { value: 'BEST', displayName: 'Meets Guidance' },
    { value: 'REVIEW_REQUIRED', displayName: 'Review Required' },
];

export const REPORT_METRIC_CHOICES: IReportChoice<dpaReportMetric>[] = [
    { value: 'BOTH', displayName: 'Object Count & Capacity' },
    { value: 'CAPACITY', displayName: 'Capacity' },
    { value: 'ENTITY_COUNT', displayName: 'Object Count' },
];

export const REPORT_VALUE_TYPES_CHOICES: IReportChoice<dpaReportValueType>[] = [
    { value: 'BOTH', displayName: '% and numbers' },
    { value: 'PERCENTS', displayName: '% value only' },
    { value: 'ABSOLUTE', displayName: 'number only' },
];
