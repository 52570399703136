import { HttpParams } from '@angular/common/http';

/**
 * Creates a HttpParams instance from an object. Unlike the HttpParams constructor option,
 * this will remove undefined values instead of replacing them with the string "undefined".
 */
export function createHttpParams(obj: { [key: string]: string | number }): HttpParams {
    let p = new HttpParams();
    Object.keys(obj || {}).forEach(key => {
        const value = obj[key];
        if (value !== undefined) {
            p = p.set(key, String(value));
        }
    });
    return p;
}

/**
 * Checks if the page is on the production or pre-production urls.
 */
export function isPure1Production(location?: Location): boolean {
    location = location || window.location;
    return /^(preprod\.)?pure1\.purestorage\.com$/.test(location.hostname);
}

/**
 * Processes PATH part of the URL in raw form for proper redirect_uri param generation
 * @param rawPage - PATH part of the URL as simple string
 */
export function processRawPage(rawPage: string): string {
    // rawPage will only have /welcome if the request initially hit a backend controller and redirected to the GUI
    // The controllers will only redirect to the GUI if the SessionValidation annotation is set with the redirectToGUI boolean
    // If so, rawPage looks something like:
    // /welcome?page=%2Frest%2Fv1%2Forders-redirect%2Fsubscription?id=someId&filter=<super_long_encoded_filter>
    // Otherwise, it will look like
    // /services/servicecatalog/main
    if (!rawPage.includes('/welcome')) {
        return encodeURIComponent(rawPage);
    }

    // When page is supplied, it'll take the shape in the above example with the '/' already supplied (and encoded)
    // So there's no need to keep the initial '/'
    let page = rawPage.startsWith('/welcome?page=')
        ? rawPage.replace('/welcome?page=', '')
        : rawPage.replace('/welcome', '/');

    // Need to encode the query params again because they're nested inside of page
    if (page.includes('?')) {
        /**
         * There should only be two sections:
         * 1. path (ex: /fleet/appliances/arrays/card)
         * 2. query params (ex: id=blah&filter=...)
         */
        const pageSections = page.split('?');
        // Note: this will double encode the params since they're already encoded at this point
        // In the backend controller, the entire uri will be decoded once, so the params will still be encoded
        pageSections[1] = encodeURIComponent(pageSections[1]);
        page = pageSections.join('?');
    }

    return page;
}

/**
 * Function to construct an Auth Portal Login PATH portion of URL.
 * Work for normal and impersonation login flow.
 *
 * @param loginEmail Email of the user that should be logged in. In case of impersonation this email identifies the actor.
 * @param impersonateEmail (OPTIONAL) Email of the person you are trying to impersonate.
 * @param redirectUri PATH portion of URL to where the login flow will eventually redirect to. ORIGIN will be supplied.
 *
 * @return PATH portion of URL for initiating login flow with Auth Portal. (EMPTY String if mandatory params are not present)
 */
export function createAuthPortalLoginPath(
    loginEmail: string,
    impersonateEmail: string = '',
    redirectUri: string,
): string {
    let path = '';

    if (loginEmail && redirectUri) {
        path =
            '/auth/login' +
            '?client_id=pure1' +
            '&login_hint=' +
            encodeURIComponent(loginEmail.toLowerCase()) +
            '&redirect_uri=' +
            encodeURIComponent(window.location.origin) +
            processRawPage(redirectUri) +
            (impersonateEmail ? '&impersonate=' + encodeURIComponent(impersonateEmail.toLowerCase()) : '');
    }

    return path;
}
