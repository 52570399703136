import { Injectable } from '@angular/core';
import {
    DraasApiCluster,
    DraasApiClusterConfiguration,
    DraasApiClusterHealth,
    DraasApiKeyValuePair,
    DraasApiSaveDRaaSClusterSourceNetworkStorageConfiguration,
    DraasApiSaveClusterVSphereCredentials,
    DraasApiSaveDRaaSClusterTargetAWSConfiguration,
    DraasApiSaveDRaaSClusterTargetNetworkConfiguration,
    DraasApiDatastore,
    DraasApiUpdateVSphereProviderDatastores,
    DraasApiUpdateAWSCredentials,
    DraasApiCredentialsChangeRequest,
    DraasApiUpdateVSphereCredentials,
    DraasApiAWSElasticIp,
    DraasApiAWSElasticIpGenerationRequest,
    DraasApiPhonehomeTimeCard,
    DraasApiDatastoresChangeRequest,
    DraasApiVSphereProvider,
    DraasApiAWSProvider,
    DraasApiVmWareDatastore,
    DraasApiNode,
} from '@pure/paas-api-gateway-client-ts';
import { map, Observable, of, tap } from 'rxjs';
import { DisasterRecoveryAWSElasticIp } from '../models/disaster-recovery-aws-elastic-ip';
import { DisasterRecoveryAWSElasticIpGenerationRequest } from '../models/disaster-recovery-aws-elastic-ip-generation-request';
import { DisasterRecoveryCluster } from '../models/disaster-recovery-cluster';
import { DisasterRecoveryClusterConfiguration } from '../models/disaster-recovery-cluster-configuration';
import { DisasterRecoveryClusterConfigurationOption } from '../models/disaster-recovery-cluster-configuration-option';
import { DisasterRecoveryCredentialsChangeRequest } from '../models/disaster-recovery-credentials-change-request';
import { DisasterRecoveryDatastore } from '../models/disaster-recovery-datastore';
import { DraasApiConfig } from './disaster-recovery-constants';
import { IRestResponse } from '../interfaces/collection';
import { DisasterRecoveryPhonehomeTimeCard } from '../models/disaster-recovery-phonehome-time-card';
import { DisasterRecoveryDatastoresChangeRequest } from '../models/disaster-recovery-datastores-change-request';
import {
    DisasterRecoveryAwsProvider,
    DisasterRecoveryProvider,
    DisasterRecoveryVSphereProvider,
} from '../models/disaster-recovery-provider';
import { DisasterRecoveryVmWareDatastore } from '../models/disaster-recovery-vmware-datastore';
import { DisasterRecoveryNode } from '../models/disaster-recovery-node';
import { DisasterRecoveryClusterHealth } from '../models/disaster-recovery-cluster-health';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryClusterConfigurationService {
    protected cachedCluster: DisasterRecoveryCluster | null = null;
    protected cachedClusterConfiguration: DisasterRecoveryClusterConfiguration | null = null;

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {}

    private getEndpoint(): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/1.0/clusters`;
    }

    getCluster(force = false): Observable<DisasterRecoveryCluster | null> {
        if (!force && this.cachedCluster) {
            return of(this.cachedCluster);
        }

        const url = this.getEndpoint();

        return this.http.get<DraasApiCluster[]>(url).pipe(
            map(response => response.map(cluster => new DisasterRecoveryCluster(cluster))),
            map(clusters => (clusters.length > 0 ? clusters[0] : null)),
            tap(cluster => (this.cachedCluster = cluster)),
        );
    }

    getClusters(): Observable<DisasterRecoveryCluster[]> {
        const url = this.getEndpoint();

        return this.http
            .get<DraasApiCluster[]>(url)
            .pipe(map(response => response.map(cluster => new DisasterRecoveryCluster(cluster))));
    }

    getClusterConfiguration(clusterId: string, force = false): Observable<DisasterRecoveryClusterConfiguration> {
        if (!force && this.cachedClusterConfiguration?.cluster?.id === clusterId) {
            return of(this.cachedClusterConfiguration);
        }
        const url = `${this.getEndpoint()}/${clusterId}/configurations`;

        return this.http.get<DraasApiClusterConfiguration>(url).pipe(
            map(response => new DisasterRecoveryClusterConfiguration(response)),
            tap(configuration => (this.cachedClusterConfiguration = configuration)),
        );
    }

    getClusterConfigurations(): Observable<DisasterRecoveryClusterConfiguration[]> {
        const url = `${this.getEndpoint()}/configurations`;

        return this.http
            .get<DraasApiClusterConfiguration[]>(url)
            .pipe(map(response => response.map(cluster => new DisasterRecoveryClusterConfiguration(cluster))));
    }

    getClusterConfigurationOptions(
        clusterId: string,
        assetType: 'datacenters' | 'vswitches' | 'datastores' | 'resource-pools' | 'regions',
    ): Observable<DisasterRecoveryClusterConfigurationOption[]> {
        const url = `${this.getEndpoint()}/${clusterId}/configuration-options/${assetType}`;

        return this.http
            .get<DraasApiKeyValuePair[]>(url)
            .pipe(map(response => response.map(pair => new DisasterRecoveryClusterConfigurationOption(pair))));
    }

    getDatastores(clusterId: string, datacenterId?: string): Observable<DisasterRecoveryDatastore[]> {
        let url = `${this.getEndpoint()}/${clusterId}/datastores`;

        if (datacenterId) {
            url += `?datacenter=${datacenterId}`;
        }

        return this.http
            .get<IRestResponse<DraasApiDatastore>>(url)
            .pipe(map(response => response.items.map(it => new DisasterRecoveryDatastore(it))));
    }

    getVmWareDatastores(clusterId: string, providerId: string): Observable<DisasterRecoveryVmWareDatastore[]> {
        const queryParams = ['page_number=0', 'page_size=1000'];

        const url = `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/providers/vmware/${providerId}/datastores?${queryParams.join('&')}`;

        return this.http
            .get<IRestResponse<DraasApiVmWareDatastore>>(url)
            .pipe(map(response => response.items.map(it => new DisasterRecoveryVmWareDatastore(it))));
    }

    getPhonehomeTimeCard(clusterId: string): Observable<DisasterRecoveryPhonehomeTimeCard> {
        const url = `${this.getEndpoint()}/${clusterId}/phonehome-time-card`;

        return this.http
            .get<DraasApiPhonehomeTimeCard>(url)
            .pipe(map(response => new DisasterRecoveryPhonehomeTimeCard(response)));
    }

    saveSourceVSphere(
        clusterId: string,
        hostname: string,
        username: string,
        password: string,
        providerVersion?: number,
    ): Observable<DisasterRecoveryClusterConfiguration> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/vsphere`;
        const body: DraasApiSaveClusterVSphereCredentials = {
            vsphere: {
                hostname,
                username,
                password,
            },
            provider_version: providerVersion ?? 0,
        };

        return this.http
            .post<DraasApiClusterConfiguration>(url, body)
            .pipe(map(response => new DisasterRecoveryClusterConfiguration(response)));
    }

    saveSourceNetworkStorage(
        clusterId: string,
        switchValue: string,
        vlanId: string,
        datacenter: string,
        resourcePool: string,
        datastore: string,
        quota: number,
        networkAddress: string,
        networkAddressMask: number,
        providerVersion?: number,
    ): Observable<DisasterRecoveryClusterConfiguration> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/vsphere/network-storage`;
        const body: DraasApiSaveDRaaSClusterSourceNetworkStorageConfiguration = {
            vsphere_network: {
                switch: switchValue,
                vlan_id: vlanId,
                cidr: networkAddress + '/' + networkAddressMask.toFixed(0),
            },
            vsphere_storage: {
                datacenter,
                resource_pool: resourcePool,
                datastore,
                quota_bytes: quota,
            },
            provider_version: providerVersion ?? 0,
        };

        return this.http
            .post<DraasApiClusterConfiguration>(url, body)
            .pipe(map(response => new DisasterRecoveryClusterConfiguration(response)));
    }

    saveTargetAwsCredentials(
        clusterId: string,
        region: string,
        accessKey: string,
        secretKey: string,
        providerVersion?: number,
    ): Observable<DisasterRecoveryClusterConfiguration> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/aws`;
        const body: DraasApiSaveDRaaSClusterTargetAWSConfiguration = {
            aws_credentials: {
                region,
                access_key: accessKey,
                secret_key: secretKey,
            },
            provider_version: providerVersion ?? 0,
        };

        return this.http
            .post<DraasApiClusterConfiguration>(url, body)
            .pipe(map(response => new DisasterRecoveryClusterConfiguration(response)));
    }

    saveTargetNetwork(
        clusterId: string,
        controllerExternalIp: string,
        providerVersion?: number,
    ): Observable<DisasterRecoveryClusterConfiguration> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/aws/network`;
        const body: DraasApiSaveDRaaSClusterTargetNetworkConfiguration = {
            aws_network: {
                controller_external_ip: controllerExternalIp,
            },
            provider_version: providerVersion ?? 0,
        };

        return this.http
            .post<DraasApiClusterConfiguration>(url, body)
            .pipe(map(response => new DisasterRecoveryClusterConfiguration(response)));
    }

    bootstrapVpn(clusterId: string): Observable<DisasterRecoveryClusterConfiguration> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/vpn`;
        return this.http
            .post<DraasApiClusterConfiguration>(url, null)
            .pipe(map(response => new DisasterRecoveryClusterConfiguration(response)));
    }

    updateVSphereDatastores(
        clusterId: string,
        providerId: string,
        body: DraasApiUpdateVSphereProviderDatastores,
    ): Observable<DisasterRecoveryDatastoresChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/vsphere/${providerId}/datastores`;
        return this.http
            .put<DraasApiDatastoresChangeRequest>(url, body)
            .pipe(map(response => new DisasterRecoveryDatastoresChangeRequest(response)));
    }

    updateAWSCredentials(
        clusterId: string,
        providerId: string,
        body: DraasApiUpdateAWSCredentials,
    ): Observable<DisasterRecoveryCredentialsChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/aws/${providerId}/credentials`;
        return this.http
            .patch<DraasApiCredentialsChangeRequest>(url, body)
            .pipe(map(response => new DisasterRecoveryCredentialsChangeRequest(response)));
    }

    updateVSphereCredentials(
        clusterId: string,
        providerId: string,
        body: DraasApiUpdateVSphereCredentials,
    ): Observable<DisasterRecoveryCredentialsChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/vsphere/${providerId}/credentials`;
        return this.http
            .patch<DraasApiCredentialsChangeRequest>(url, body)
            .pipe(map(response => new DisasterRecoveryCredentialsChangeRequest(response)));
    }

    getCredentialsChangeRequest(
        clusterId: string,
        providerId: string,
        requestId: string,
    ): Observable<DisasterRecoveryCredentialsChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/providers/${providerId}/credentials-change-requests/${requestId}`;
        return this.http
            .get<DraasApiCredentialsChangeRequest>(url)
            .pipe(map(response => new DisasterRecoveryCredentialsChangeRequest(response)));
    }

    getLatestCredentialsChangeRequest(
        clusterId: string,
        providerId: string,
    ): Observable<DisasterRecoveryCredentialsChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/providers/${providerId}/credentials-change-requests/latest`;
        return this.http
            .get<DraasApiCredentialsChangeRequest>(url)
            .pipe(map(response => new DisasterRecoveryCredentialsChangeRequest(response)));
    }

    getDatastoresChangeRequest(
        clusterId: string,
        providerId: string,
        requestId: string,
    ): Observable<DisasterRecoveryDatastoresChangeRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/providers/${providerId}/datastores-change-requests/${requestId}`;
        return this.http
            .get<DraasApiDatastoresChangeRequest>(url)
            .pipe(map(response => new DisasterRecoveryDatastoresChangeRequest(response)));
    }

    generateElasticIp(clusterId: string): Observable<DisasterRecoveryAWSElasticIpGenerationRequest> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/aws/elastic-ip`;
        return this.http
            .post<DraasApiAWSElasticIpGenerationRequest>(url, null, { observe: 'response' })
            .pipe(map(response => new DisasterRecoveryAWSElasticIpGenerationRequest(response.body)));
    }

    getElasticIp(clusterId: string): Observable<DisasterRecoveryAWSElasticIp> {
        const url = `${this.getEndpoint()}/${clusterId}/configurations/aws/elastic-ip`;
        return this.http
            .get<DraasApiAWSElasticIp>(url)
            .pipe(map(response => new DisasterRecoveryAWSElasticIp(response)));
    }

    getProviders(clusterId: string): Observable<DisasterRecoveryProvider[]> {
        const url = `${this.getEndpoint()}/${clusterId}/providers`;
        return this.http.get<(DraasApiVSphereProvider | DraasApiAWSProvider)[]>(url).pipe(
            map(response =>
                response.map(providerJson => {
                    if (providerJson.provider_type === 'VSPHERE') {
                        return new DisasterRecoveryVSphereProvider(providerJson as DraasApiVSphereProvider);
                    } else {
                        return new DisasterRecoveryAwsProvider(providerJson as DraasApiAWSProvider);
                    }
                }),
            ),
        );
    }

    getProvider(clusterId: string, providerId: string): Observable<DisasterRecoveryProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/providers/${providerId}`;
        return this.http.get<DraasApiVSphereProvider | DraasApiAWSProvider>(url).pipe(
            map(providerJson => {
                if (providerJson.provider_type === 'VSPHERE') {
                    return new DisasterRecoveryVSphereProvider(providerJson as DraasApiVSphereProvider);
                } else {
                    return new DisasterRecoveryAwsProvider(providerJson as DraasApiAWSProvider);
                }
            }),
        );
    }

    getNodes(clusterId: string, providerId: string): Observable<DisasterRecoveryNode[]> {
        const url = `${this.getEndpoint()}/${clusterId}/providers/${providerId}/nodes`;
        return this.http
            .get<DraasApiNode[]>(url)
            .pipe(map(response => response.map(node => new DisasterRecoveryNode(node))));
    }

    renameProvider(
        clusterId: string,
        providerId: string,
        newName: string,
    ): Observable<DisasterRecoveryVSphereProvider | DisasterRecoveryAwsProvider> {
        const url = `${this.getEndpoint()}/${clusterId}/providers/${providerId}/name`;
        return this.http.put<DraasApiVSphereProvider | DraasApiAWSProvider>(url, { name: newName }).pipe(
            map(providerJson => {
                if (providerJson.provider_type === 'VSPHERE') {
                    return new DisasterRecoveryVSphereProvider(providerJson as DraasApiVSphereProvider);
                } else {
                    return new DisasterRecoveryAwsProvider(providerJson as DraasApiAWSProvider);
                }
            }),
        );
    }

    getClusterHealth(clusterId: string): Observable<DisasterRecoveryClusterHealth> {
        const url = `${this.getEndpoint()}/${clusterId}/health`;
        return this.http.get<DraasApiClusterHealth>(url).pipe(map(health => new DisasterRecoveryClusterHealth(health)));
    }
}
