import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';

const routes: Routes = [
    {
        // Root route redirect (eg when navigating to "pure1.purestorage.com")
        // The hasPermissionGuard will redirect again if the user doesn't have access to the /fleet page
        path: '',
        pathMatch: 'full',
        redirectTo: 'fleet',
    },
    {
        // Marketplace (flow initiated in AWS / Azure marketplaces)
        path: 'marketplace',
        loadChildren: () => import('../marketplace/marketplace.module').then(mod => mod.MarketplaceModule),
    },
    {
        // Unsubscribe (flow initiated in emails)
        path: 'unsubscribe-notifications',
        loadChildren: () => import('../unsubscribe/unsubscribe.module').then(mod => mod.UnsubscribeModule),
    },
    {
        path: 'errors',
        loadChildren: () => import('../error/error.module').then(mod => mod.ErrorModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            preloadingStrategy: environment.preloadStrategy,
        }),
    ],
    exports: [RouterModule],
})
export class RootAppRoutesModule {}
