import { Directive, Input, OnInit, OnChanges, Optional } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ArrayNameTooltipService } from './array-name-tooltip.service';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

export interface ArrayReference {
    id: string;
    name?: string;
}

@Directive({
    selector: '[arrayNameTooltip]',
})
export class ArrayNameTooltipDirective implements OnInit, OnChanges {
    @Input('arrayNameTooltip') readonly arrayReference: ArrayReference;
    @Input() readonly placement: PlacementArray;

    constructor(
        @Optional() private tooltipObject: NgbTooltip,
        private arrayNameTooltipService: ArrayNameTooltipService,
    ) {}

    ngOnInit(): void {
        if (!this.tooltipObject) {
            console.warn('[ngbTooltip] is required in addition to [arrayNameTooltip]');
            return;
        }
        this.tooltipObject.container = 'body';
        this.tooltipObject.placement = this.placement || 'top';

        this.ngOnChanges();
    }

    ngOnChanges(): void {
        this.tooltipObject.ngbTooltip = this.arrayReference.name;
        this.arrayNameTooltipService
            .get(this.arrayReference.id)
            .then(name => (this.tooltipObject.ngbTooltip = name || this.arrayReference.name));
    }
}
