import { Injectable } from '@angular/core';

import { DisasterRecoveryPolicy } from '../models/disaster-recovery-policy';
import { FilterParams } from '../interfaces/list-params';
import { Observable, Subject } from 'rxjs';
import {
    DraasApiCreateServiceLevelPolicyV2,
    DraasApiServiceLevelPolicy,
    DraasApiUpdateServiceLevelPolicy,
} from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryPlan } from '../models/disaster-recovery-plan';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryPoliciesService extends DisasterRecoveryBaseService<
    DisasterRecoveryPolicy,
    DraasApiServiceLevelPolicy
> {
    protected resourceClass = DisasterRecoveryPolicy;
    protected pathParams = ['clusterId', 'policyId'];

    readonly policyCreated$: Subject<DisasterRecoveryPolicy> = new Subject();
    readonly policyUpdated$: Subject<DisasterRecoveryPolicy> = new Subject();
    readonly policyRemoved$: Subject<string> = new Subject();

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryPlan>): string {
        return filter.policyId
            ? `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/service-level-policies/${filter.policyId}`
            : `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/service-level-policies`;
    }

    create(clusterId: string, body: DraasApiCreateServiceLevelPolicyV2): Observable<DisasterRecoveryPolicy> {
        return this.doCreate(this.getEndpoint({ clusterId }), body, this.policyCreated$);
    }

    update(
        clusterId: string,
        policyId: string,
        body: DraasApiUpdateServiceLevelPolicy,
    ): Observable<DisasterRecoveryPolicy> {
        return this.doUpdate(this.getEndpoint({ clusterId, policyId }), body, this.policyUpdated$);
    }

    delete(clusterId: string, policyId: string): Observable<void> {
        return this.doDelete(this.getEndpoint({ clusterId, policyId }), policyId, this.policyRemoved$);
    }
}
