import { Resource } from '../interfaces/resource';
import { MemberLocation } from './member-location';

// This class is used as type for both the sources and targets
// in replica links
export class Member implements Resource {
    id: string;
    name: string;

    location: MemberLocation;
    resourceType: string;

    // includes 'podPromotionStatus' for the pod-replica-link
    podPromotionStatus: string | null;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.resourceType = json.resourceType;

        // pod-replica-links have locations for future-proofing. for now locations will still always have one element
        this.location = json.location
            ? new MemberLocation(json.location)
            : json.locations
              ? new MemberLocation(json.locations[0])
              : null;

        this.podPromotionStatus = json.pod_promotion_status || null;
    }
}
