import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

const singleDigitRegex = /^\d$/;
const singleDigitValidatorFn = Validators.compose([Validators.required, Validators.pattern(singleDigitRegex)]);

@Component({
    selector: 'totp-passcode-input',
    templateUrl: 'totp-passcode-input.component.html',
})
export class TotpPasscodeInputComponent {
    @Input() readonly hasError: boolean;
    // Emits the value if the form becomes valid, null if the form becomes invalid.
    @Output() readonly valueChanged = new EventEmitter<string>();

    passCodeForm: UntypedFormGroup;
    passCode0: string;
    passCode1: string;
    passCode2: string;
    passCode3: string;
    passCode4: string;
    passCode5: string;

    constructor(
        @Inject(DOCUMENT) private document: HTMLDocument,
        private fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        const formConfig = {
            passCode0: [this.passCode0, singleDigitValidatorFn],
            passCode1: [this.passCode1, singleDigitValidatorFn],
            passCode2: [this.passCode2, singleDigitValidatorFn],
            passCode3: [this.passCode3, singleDigitValidatorFn],
            passCode4: [this.passCode4, singleDigitValidatorFn],
            passCode5: [this.passCode5, singleDigitValidatorFn],
        };
        this.passCodeForm = this.fb.group(formConfig);
        this.passCodeForm.valueChanges.subscribe(values => {
            if (this.passCodeForm.valid) {
                this.valueChanged.emit(
                    values.passCode0 +
                        values.passCode1 +
                        values.passCode2 +
                        values.passCode3 +
                        values.passCode4 +
                        values.passCode5,
                );
            } else {
                this.valueChanged.emit(null);
            }
        });
    }

    pastePasscode(passcodeFieldIndex: number, event: ClipboardEvent): void {
        const clip = event.clipboardData.getData('text');
        const pasteLength = Math.min(6 - passcodeFieldIndex, clip.length);
        for (let i = 0; i < pasteLength; ++i) {
            this.passCodeForm.controls['passCode' + (passcodeFieldIndex + i)].setValue(clip[i]);
        }
        event.preventDefault(); // so that value doesn't get pasted into newly focused input box below
        const indexToFocus = Math.min(passcodeFieldIndex + clip.length, 5);
        this.document
            .querySelector<HTMLInputElement>(`totp-passcode-input input:nth-of-type(${indexToFocus + 1})`)
            .focus();
    }

    passcodeKeyUp(passcodeFieldIndex: number, event: KeyboardEvent): void {
        if (event.key === 'ArrowRight' && passcodeFieldIndex < 5) {
            this.moveToPasscodeField(passcodeFieldIndex + 1);
        } else if (event.key === 'ArrowLeft' && passcodeFieldIndex > 0) {
            this.moveToPasscodeField(passcodeFieldIndex - 1);
        } else if (event.key === 'Backspace' && passcodeFieldIndex > 0) {
            this.moveToPasscodeField(passcodeFieldIndex - 1);
        } else if (singleDigitRegex.test(event.key) && passcodeFieldIndex < 5) {
            const currentField = this.document.querySelector<HTMLInputElement>(
                `totp-passcode-input input:nth-of-type(${passcodeFieldIndex + 1})`,
            );
            if (currentField.value.length === 1) {
                this.moveToPasscodeField(passcodeFieldIndex + 1);
            }
        }
    }

    private moveToPasscodeField(passcodeFieldIndex: number) {
        const elementToFocus = this.document.querySelector<HTMLInputElement>(
            `totp-passcode-input input:nth-of-type(${passcodeFieldIndex + 1})`,
        );
        elementToFocus.focus();
        // Select what's already in the input box, so that there is no need to move cursor within the input box.
        elementToFocus.setSelectionRange(0, elementToFocus.value.length);
    }
}
