import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportManagerService } from '../services/export-manager.service';

@Component({
    selector: 'export-details-modal',
    templateUrl: 'export-details-modal.component.html',
})
export class ExportDetailsModalComponent {
    @Input() readonly exportTask: IExportTask;
    @Input() readonly activeModal: NgbActiveModal;

    constructor(private exportManager: ExportManagerService) {}

    cancelExport(): void {
        this.exportManager.remove(this.exportTask);
        this.activeModal.close();
    }
}
