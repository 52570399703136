import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
    transform(value: string, args: string): string {
        if (!args || !value) {
            return value;
        }

        const expression = args.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(expression, 'gi');
        const match = value.match(regex);

        if (!match) {
            return value;
        }

        return value.replace(regex, `<span class="highlight">${match[0]}</span>`);
    }
}
