<div *ngIf="!notification.isUpdate; else updateFlag">
    <div *ngIf="!notification.isComingSoon" class="banner-icon">
        <pureui-svg svg="new-big-badge.svg" [height]="83.25"></pureui-svg>
    </div>
    <div *ngIf="notification.isComingSoon" class="banner-icon">
        <pureui-svg svg="coming-soon-badge.svg" [height]="83.25"></pureui-svg>
    </div>
</div>

<ng-template #updateFlag>
    <div class="banner-icon">
        <pureui-svg svg="update-badge.svg" [height]="83.25"></pureui-svg>
    </div>
</ng-template>

<button
    class="dismiss-button svg-hover-dropshadow"
    (click)="dismissNotification.emit()"
    pure-read-only
    angulartics2On="click"
    angularticsCategory="Action"
    [angularticsAction]="'Banner - Close banner'"
    [angularticsLabel]="notification.title"
>
    <pureui-svg svg="close-button.svg" [height]="12.39"></pureui-svg>
</button>
<div class="banner-content">
    <div class="banner-graphic">
        <pureui-svg [svg]="notification.image" width="60"></pureui-svg>
    </div>
    <div class="banner-text">
        <div class="banner-title">
            {{ notification.title }}
        </div>
        <div class="banner-body">
            {{ notification.body }}
            <span class="kb-links">
                <ng-container *ngIf="notification.linksTemplate; else defaultLinksTemplate">
                    <ng-container *ngTemplateOutlet="notification.linksTemplate"></ng-container>
                </ng-container>
                <ng-template #defaultLinksTemplate>
                    <ng-container *ngIf="notification.kbArticleLink">
                        <a
                            [href]="notification.kbArticleLink"
                            target="_blank"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Banner - Read more"
                            [angularticsLabel]="notification.title"
                        >
                            Read More
                        </a>
                    </ng-container>
                    <ng-container *ngFor="let link of notification.customLinks; let i = index">
                        <ng-container *ngIf="notification.kbArticleLink || i !== 0">|</ng-container>
                        <a
                            [href]="link.url"
                            [target]="link.openInNewTab ? '_blank' : '_self'"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Banner - Custom link - {{ link.displayName }}"
                            [angularticsLabel]="notification.title"
                        >
                            {{ link.displayName }}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="!notification.hideReleaseNotes && !notification.isComingSoon">
                        |
                        <a
                            href="https://support.purestorage.com/Pure1/Pure1_Manage/001_Overview/000:_Pure1_Manage_Release_Notes"
                            target="_blank"
                            angulartics2On="click"
                            angularticsCategory="Action"
                            angularticsAction="Banner - Release notes"
                            [angularticsLabel]="notification.title"
                        >
                            Release Notes
                        </a>
                    </ng-container>
                </ng-template>
            </span>
        </div>
    </div>
    <div class="banner-actions">
        <ng-container *ngIf="notification.buttonTemplate; else pageRedirectButton">
            <ng-container *ngTemplateOutlet="notification.buttonTemplate"></ng-container>
        </ng-container>
        <ng-template #pageRedirectButton>
            <ng-container
                *ngIf="
                    notification.id !== 'A000089' &&
                        notification.id !== 'A000094' &&
                        notification.id !== 'A000097' &&
                        notification.id !== 'A000106' &&
                        notification.id !== 'A000109';
                    else idSpecificBannerButton
                "
            >
                <a
                    *ngIf="notification.path"
                    class="btn btn-primary try-button"
                    [routerLink]="notification.path"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                >
                    {{ notification.isComingSoon ? 'Learn more' : 'Try it out' }}
                </a>
            </ng-container>
            <ng-template #idSpecificBannerButton>
                <a
                    *ngIf="notification.id === 'A000089'"
                    class="btn btn-primary try-button"
                    (click)="openStepUpSetup()"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                >
                    Try it out
                </a>
                <a
                    *ngIf="notification.id === 'A000094' || notification.id === 'A000097'"
                    class="btn btn-primary try-button"
                    href="https://support.purestorage.com/Pure1/Pure1_Manage/001_Overview/08_Pure1_Manage_Mobile_App"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                >
                    Learn more
                </a>
                <a
                    *ngIf="notification.id === 'A000106'"
                    class="btn btn-primary try-button"
                    [href]="'identity-center' | pure1AppLink: '/overview?source=p1m'"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                >
                    Launch
                </a>
                <a
                    *ngIf="notification.id === 'A000109'"
                    class="btn btn-primary try-button"
                    target="_blank"
                    angulartics2On="click"
                    angularticsCategory="Action"
                    angularticsAction="Banner - Try it out"
                    [angularticsLabel]="notification.title"
                    prefillGoogleForm="https://docs.google.com/forms/d/e/1FAIpQLSf6Zv_-Q7XxuUGT81kzJ178FpYAPt2njJ3oNnl9u10BDKG3_Q/viewform"
                    contactNameField="entry.467633826"
                    contactEmailField="entry.144640397"
                >
                    Register for Beta
                </a>
            </ng-template>
        </ng-template>
    </div>
</div>
