import moment from 'moment';
import { HourlyAggregation } from './hourly-aggregation';

export class FileSystemPerformanceAggregation {
    data: any;

    all_read_latency: HourlyAggregation;
    all_write_latency: HourlyAggregation;
    all_others_latency_per_op: HourlyAggregation;
    all_read_iops: HourlyAggregation;
    all_write_iops: HourlyAggregation;
    all_others_ops_per_sec: HourlyAggregation;
    all_read_bandwidth: HourlyAggregation;
    all_write_bandwidth: HourlyAggregation;

    nfs_read_latency: HourlyAggregation;
    nfs_write_latency: HourlyAggregation;
    nfs_other_latency: HourlyAggregation;
    nfs_read_iops: HourlyAggregation;
    nfs_write_iops: HourlyAggregation;
    nfs_other_iops: HourlyAggregation;
    nfs_read_bandwidth: HourlyAggregation;
    nfs_write_bandwidth: HourlyAggregation;

    aggregation_time: moment.Moment;
    latest_metric_time: moment.Moment;

    constructor(json: any) {
        this.aggregation_time = moment(json.aggregation_time * 1000);
        this.latest_metric_time = moment(json.latest_metric_time * 1000);

        this.nfs_read_latency = new HourlyAggregation(json.data.nfs_read_latency);
        this.nfs_write_latency = new HourlyAggregation(json.data.nfs_write_latency);
        this.nfs_other_latency = new HourlyAggregation(json.data.nfs_other_latency);
        this.nfs_read_iops = new HourlyAggregation(json.data.nfs_read_iops);
        this.nfs_write_iops = new HourlyAggregation(json.data.nfs_write_iops);
        this.nfs_other_iops = new HourlyAggregation(json.data.nfs_other_iops);
        this.nfs_read_bandwidth = new HourlyAggregation(json.data.nfs_read_bandwidth);
        this.nfs_write_bandwidth = new HourlyAggregation(json.data.nfs_write_bandwidth);

        this.all_read_latency = new HourlyAggregation(json.data.all_read_latency);
        this.all_write_latency = new HourlyAggregation(json.data.all_write_latency);
        this.all_others_latency_per_op = new HourlyAggregation(json.data.all_others_latency_per_op);
        this.all_read_iops = new HourlyAggregation(json.data.all_read_iops);
        this.all_write_iops = new HourlyAggregation(json.data.all_write_iops);
        this.all_others_ops_per_sec = new HourlyAggregation(json.data.all_others_ops_per_sec);
        this.all_read_bandwidth = new HourlyAggregation(json.data.all_read_bandwidth);
        this.all_write_bandwidth = new HourlyAggregation(json.data.all_write_bandwidth);

        this.data = json.data;
    }
}
