import moment from 'moment';
import { Chart, Point, charts as highchartsInstances, SeriesFlagsOptions, Axis } from 'highcharts/highstock';

import PureUtils from '../utils/pure_utils';

/**
 * Reflows all Highcharts instances
 */
export function reflowAllCharts(): void {
    (highchartsInstances || []).forEach(chart => {
        if (chart) {
            try {
                chart.reflow();
            } catch (ex) {
                // We don't really care about errors, so swallow them
                console.warn('Error while reflowing Highchart instance', chart, ex);
            }
        }
    });
}

/**
 * Clears the chart hover state (tooltip and crosshairs)
 */
export function clearHoverState(chart: Chart): void {
    if (!chart) {
        return;
    }

    if (chart.tooltip) {
        chart.tooltip.hide();
    }

    if (chart.xAxis && chart.xAxis[0]) {
        chart.xAxis[0].hideCrosshair();
    }
}

/**
 * Clears the currently selected points on the chart
 */
export function clearSelectedPoints(chart: Chart): void {
    if (!chart) {
        return;
    }

    chart.getSelectedPoints().forEach(p => {
        p.select(false, true);
    });
}

/**
 * Sets the selected state for the given points
 */
export function selectPoints(points: Point[]): void {
    points.forEach(p => {
        p.select(true, true);
    });
}

/**
 * Gets the point on each series that has the given x-axis value.
 */
export function getPointsByXValue(chart: Chart, x: number): Point[] {
    if (!chart) {
        return [];
    }

    return chart.series
        .filter(serie => serie != null)
        .map(serie => PureUtils.binarySearch(serie.data, x, (a, b) => a - b.x))
        .filter(point => point != null);
}

/**
 * Gets the Highcharts instances that are under the given html element
 */
export function getChartsUnderElement(rootElem: Element): Chart[] {
    return (highchartsInstances || []).filter(chart => {
        if (!chart || !chart.series || !chart.series[0] || !chart.container) {
            return false; // Make sure we have a valid chart instance
        }

        return rootElem.contains(chart.container);
    });
}

/**
 * Simple helper for building the tooltip html in the performance chart style
 */
export function buildPerfChartTooltipHtml(headerLeft: string, headerRight: string, rowsHtml: string): string {
    return `
    <div class="perf-chart-tooltip">
        <div class="tt-header">
            <div class="header-left">${headerLeft}</div>
            <div class="header-right">${headerRight}</div>
        </div>
        <div class="tt-body">
            <table class="table">
                <tbody>
                    ${rowsHtml}
                </tbody>
            </table>
        </div>
    </div>`;
}

/**
 * Creates a flag series to annotate busymeter changes
 * @param onSeriesId The series for the flags to point at
 */
export function createBusymeterAnnotationSeries(onSeriesId: string): SeriesFlagsOptions {
    const kbUrl =
        'https://support.purestorage.com/Pure1/Pure1_Manage/003_Analytics/Array_Load_Statistics_Quick_Reference_Guide';

    let flagDates = [
        // The timestamp below will show up at 3:00pm in Pacific Daylight time. If we did this during PST when daylight
        //  savings was not in effect it would be -08:00 at the end.
        moment('2019-06-13T15:00:00-07:00'),
    ];

    // Filter out dates in the future so it doesn't show up prematurely on pages like Forecast
    flagDates = flagDates.filter(date => date.isBefore(moment()));

    /*
    The link's text, by default, is a smaller area than the point itself. But we want the html title attribute (tooltip)
    to show when you hover over any part of the flag, not just the middle. So expand the container's area a bit to have it
    cover a wider area that will cause the tooltip to show.
    Note that Highcharts uses the size of the content to determine the flag size. So we need to also have
    a "visibility: hidden" element in place for allocating the space.
    */
    const linkStyle =
        'color: white; display: block; position: absolute; left: -8px; top: -3px; height: 1.8em; width: 2em; text-align: center; line-height: 1.8em;';

    const series: SeriesFlagsOptions = {
        type: 'flags',
        color: '#5ab0ee', // $pstg-blue
        fillColor: '#5ab0ee', // $pstg-blue
        lineWidth: 1.5,
        onSeries: onSeriesId,
        shape: 'squarepin',
        width: 13,
        zIndex: 1000,
        useHTML: true,
        cursor: 'pointer',
        stickyTracking: false,
        states: {
            hover: {
                enabled: false,
            },
        },
        events: {
            click: (): void => {
                window.open(kbUrl, '_blank');
            },
        },
        data: flagDates.map(date => {
            return {
                x: date.valueOf(),
                title: `
                    <span style="visibility: hidden">?</span>
                    <a title="Load algorithm updated. Click for more info." href="${kbUrl}" target="_blank" style="${linkStyle}">?</a>
                `,
            };
        }),
        enableMouseTracking: false,
    };

    return series;
}

export function bytesTickPositioner(this: Axis): number[] {
    const ONE_K_BASE_10 = 1000;
    const ONE_K_BASE_2 = 1024;

    // The tick positions provided by this.tickPositions are the default ones generated by highcharts
    return this.tickPositions.map(oldPosition => {
        let divisions = 0;

        // We divide by 1000 (as the default ticks are in base 10)
        while (oldPosition > ONE_K_BASE_10) {
            oldPosition /= ONE_K_BASE_10;
            divisions++;
        }

        // We reapply the amount of times we've divided, except we do it in base 2
        return Math.pow(ONE_K_BASE_2, divisions) * oldPosition;
    });
}

/**
 * Helper method for grouping events by a given time granularity between start and end times
 * endTime is optional and defaults to the current time if not given
 * eventsWithTimeProperty:  a Map of events where each event is both key and the corresponding value
 *                          and where each event has a 'time' property
 * Returns an array of tuples where each tuple is: (timestamp, [events])
 * Only returns time buckets for which at least one event falls within it
 *
 */
export function groupEventsForFlagDataPoints(
    startTime: moment.Moment,
    granularity: moment.Duration,
    eventsWithTimeProperty: Map<any, any>,
    endTime: moment.Moment = moment(),
): [number, any[]][] {
    if (!startTime || !granularity || !eventsWithTimeProperty) {
        return null;
    }

    const startTimeInMs = startTime.valueOf();
    const granularityInMs = granularity.asMilliseconds();
    const endTimeInMs = endTime.valueOf();

    if (eventsWithTimeProperty.size < 1 || endTimeInMs <= startTimeInMs || granularityInMs < 1) {
        return [];
    }

    // because the # of events over 30 days (expected max time range) is not expected be high and we also don't expect more than
    // 600 timestamps (max_points) on a history graph, we will simply loop through all events for each granularity bucket
    // to see if it belongs in that bucket until no more events are left (or we exceed the end time).
    let bucketStart = startTimeInMs;
    let bucketEnd = startTimeInMs + granularityInMs; // Note: we will use inclusive start and exclusive end
    bucketEnd = bucketEnd > endTimeInMs ? endTimeInMs : bucketEnd;

    const bucketizedEvents: [number, any[]][] = [];

    while (eventsWithTimeProperty.size > 0 && bucketStart < endTimeInMs) {
        const eventsInThisBucket = [];

        // find and log all events belonging to this time bucket
        eventsWithTimeProperty.forEach((value, key) => {
            if (bucketStart <= key.time && key.time < bucketEnd) {
                eventsInThisBucket.push(key);
            }
        });

        // add this data point to the flags series data if it has any events
        if (eventsInThisBucket.length > 0) {
            const avgTime =
                eventsInThisBucket.map(event => event.time).reduce((accum, current) => accum + current, 0) /
                eventsInThisBucket.length;
            bucketizedEvents.push([avgTime, eventsInThisBucket]);

            // remove the found events from the overall events list
            eventsInThisBucket.forEach(value => {
                eventsWithTimeProperty.delete(value);
            });
        }

        // increase bucket limits to next time bucket
        bucketStart = bucketEnd;
        bucketEnd = bucketStart + granularityInMs;
        bucketEnd = bucketEnd > endTimeInMs ? endTimeInMs : bucketEnd;
    }

    return bucketizedEvents;
}
