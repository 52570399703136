import { DraasApiOvaInfo } from '@pure/paas-api-gateway-client-ts';
import { Resource } from '../interfaces/resource';

export class DisasterRecoveryOvaInfo implements Resource {
    id: string;
    name: string;
    buildNumber: string;
    createdDate: Date;

    constructor(json: DraasApiOvaInfo) {
        this.id = json.id;
        this.name = json.readable_name;
        this.buildNumber = json.build_number;
        this.createdDate = new Date(json.created_date);
    }
}
