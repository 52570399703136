import moment from 'moment';
import { HourlyAggregation } from './hourly-aggregation';

export class UnifiedArrayBusymeterAggregation {
    aggregation_time: moment.Moment;
    data: any;
    total_busyness: HourlyAggregation;

    constructor(json: any) {
        if (json) {
            this.aggregation_time = moment(json.aggregation_time);
            this.data = json.data;
            this.total_busyness = new HourlyAggregation(json.data.total_busyness || json.data.bm_total_busyness); // TODO: Why is the api returning "bm_total_busyness" instead of "total_busyness"? Should this be made consistent at the API level?
        }
    }
}
