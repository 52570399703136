import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { Organization } from '../models/organization';

@Injectable({ providedIn: 'root' })
export class OrganizationService extends GenericService<Organization> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: Organization,
            endpoint: '/rest/v2/orgs',
        });
    }
}
