import { DraasApiKeyValuePair } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryClusterConfigurationOption {
    key: string;
    value?: string;

    constructor(json: DraasApiKeyValuePair) {
        this.key = json.key;
        this.value = json.value;
    }
}
