import { DraasApiRecoverySummary } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryRecoverySummary {
    success: number;
    failed: number;
    neverRun: number;

    constructor(json: DraasApiRecoverySummary) {
        this.success = json.success;
        this.failed = json.failed;
        this.neverRun = json.never_run;
    }
}
