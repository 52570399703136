import moment from 'moment';
import { HourlyAggregation } from './hourly-aggregation';

export class BucketPerformanceAggregation {
    data: any;

    // bandwidth
    s3_read_bandwidth: HourlyAggregation;
    s3_write_bandwidth: HourlyAggregation;

    // iops
    s3_read_iops: HourlyAggregation;
    s3_write_iops: HourlyAggregation;
    s3_other_iops: HourlyAggregation;

    // latency
    s3_read_latency: HourlyAggregation;
    s3_write_latency: HourlyAggregation;
    s3_other_latency: HourlyAggregation;

    aggregation_time: moment.Moment;
    latest_metric_time: moment.Moment;

    constructor(json: any) {
        this.aggregation_time = moment(json.aggregation_time * 1000);
        this.latest_metric_time = moment(json.latest_metric_time * 1000);

        this.s3_read_bandwidth = new HourlyAggregation(json.data.s3_read_bandwidth);
        this.s3_write_bandwidth = new HourlyAggregation(json.data.s3_write_bandwidth);
        this.s3_read_iops = new HourlyAggregation(json.data.s3_read_iops);
        this.s3_write_iops = new HourlyAggregation(json.data.s3_write_iops);
        this.s3_other_iops = new HourlyAggregation(json.data.s3_other_iops);
        this.s3_read_latency = new HourlyAggregation(json.data.s3_read_latency);
        this.s3_write_latency = new HourlyAggregation(json.data.s3_write_latency);
        this.s3_other_latency = new HourlyAggregation(json.data.s3_other_latency);

        this.data = json.data;
    }
}
