import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { COMMON_LOCATORS } from '../../testing/locators/common/common.locators';

@Component({
    selector: 'pureui-spinner',
    template: `<i
        class="fa fa-spin fa-gear"
        [style.display]="pureuiShow ? 'inline-block' : 'none'"
        attr.data-pw="{{ COMMON_LOCATORS.pure1Spinner }}"
    ></i>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
    @Input() readonly pureuiShow: boolean = false;
    protected readonly COMMON_LOCATORS = COMMON_LOCATORS;
}
