export function capitalize(input: string, options?: { all: boolean }): string {
    if (typeof input !== 'string') {
        return input;
    }
    options = { all: false, ...options };
    const regex = options.all === true ? /([\w_]+[\S-]*)+/g : /^([\w_]+[\S-]*)/g;
    return input.replace(regex, text => {
        return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    });
}
