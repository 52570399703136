import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ExportRequestBuilderService } from '../../export/services/export-request-builder.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportManagerService } from '../../export/services/export-manager.service';
import { Angulartics2 } from 'angulartics2';
import { ExportOptionIds } from 'core/src/export/export-button/export-button.component';
import { ampli } from 'core/src/ampli';

@Component({
    selector: 'asset-export-modal',
    templateUrl: 'asset-export-modal.component.html',
})
export class AssetExportModalComponent implements OnInit, OnChanges {
    readonly ampli = ampli;

    @Input() readonly modal: NgbActiveModal;
    @Input() readonly assetFilter: string;
    @Input() readonly totalFiltered: number;
    @Input() readonly totalUnfiltered: number;
    @Input() readonly selectedOption: IExportButtonOption;

    errorMessage: string;
    exportRequest: IAssetExportRequest;
    fileNameEntry: string;
    loading = false;
    entityCountMessage = '';

    constructor(
        private angulartics2: Angulartics2,
        private exportManager: ExportManagerService,
        private exportRequestBuilder: ExportRequestBuilderService,
    ) {}

    ngOnInit(): void {
        this.generateExportRequest();
        this.fileNameEntry = this.exportRequest.fileName;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.totalFiltered || changes.selectedOption || changes.totalUnfiltered) {
            this.entityCountMessage = this.getEntityCountMessage();
        }
    }

    clickApply(): void {
        this.generateExportRequest();
        this.exportRequest.fileName = this.fileNameEntry;

        // Set state and send request
        this.errorMessage = null;
        this.loading = true;

        this.exportManager.enqueue(this.exportRequest).subscribe(
            () => {
                this.loading = false;
                this.modal.close();
            },
            err => {
                this.loading = false;
                this.errorMessage = err;
            },
        );
    }

    getEntityCountMessage(): string {
        if (!this.selectedOption) {
            return '';
        }

        let noun = 'assets';

        switch (this.selectedOption.id) {
            case ExportOptionIds.all: {
                if (this.totalUnfiltered === 1) {
                    noun = 'asset';
                }
                return `All ${this.totalUnfiltered} ${noun}`;
            }
            case ExportOptionIds.filtered: {
                if (this.totalFiltered === 1) {
                    noun = 'asset';
                }
                return `${this.totalFiltered} filtered ${noun}`;
            }
            default: {
                return `Unknown option ${this.selectedOption.id}`;
            }
        }
    }

    generateExportRequest(): void {
        const filter = this.selectedOption?.id === ExportOptionIds.filtered && this.assetFilter ? this.assetFilter : '';
        this.exportRequest = this.exportRequestBuilder.filteredAssets(filter);
        ampli.exportingOnAssetTab(
            filter
                ? { 'assets tab page': 'Appliance Genealogy', 'export category': 'filtered' }
                : { 'assets tab page': 'Appliance Genealogy', 'export category': 'all' },
        );
    }
}
