<admin-user-view
    *ngIf="userModalStateService.state.startsWith('um.admin')"
    [currentUser]="currentUser"
></admin-user-view>
<regular-user-view
    *ngIf="userModalStateService.state.startsWith('um.regular')"
    [currentUser]="currentUser"
></regular-user-view>

<um-edit-user *ngIf="userModalStateService.state === 'um.edit-user'" [currentUser]="currentUser"></um-edit-user>
