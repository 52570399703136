import { PureArray } from './PureArray';
import { FArrayCapacityData } from './FArrayCapacityData';
import { isCBS } from '../utils/marketing';

const NA = '–';

export class FArray extends PureArray {
    capacityData: FArrayCapacityData;
    hasMirroredWrites: boolean;

    constructor(json: any) {
        super(json);

        if (this.isCurrent) {
            this.version = !json.purity_version ? NA : json.purity_version;

            const matches = this.version.match(/^(\d*)\./);
            this.hasMirroredWrites = (matches && Number(matches[1]) > 4) || false;
            this.capacityData = new FArrayCapacityData(json.purearray_list_space);
        }
    }

    isCBS(): boolean {
        return isCBS(this.model);
    }
}
