import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseMetricsHistoryService, MetricsRequestParams } from './base-metrics-history.service';
import { MetricsEndpoint } from './metrics-history.service';
import { WINDOW } from '../../app/injection-tokens';

@Injectable({ providedIn: 'root' })
export class PxNodeMetricsHistoryService extends BaseMetricsHistoryService {
    protected apiEndpoint = MetricsEndpoint.pxNodes;

    constructor(httpClient: HttpClient, @Inject(WINDOW) window: Window) {
        super(httpClient, window);
    }

    protected addIdToParams(id: string, requestParams: MetricsRequestParams): void {
        requestParams.node_id = id;
    }
}
