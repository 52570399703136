import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { PxNode } from '../models/px-node';

@Injectable({ providedIn: 'root' })
export class PxNodeService extends GenericService<PxNode> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PxNode,
            endpoint: '/rest/v1/portworx/nodes',
            defaultParams: {
                sort: {
                    key: 'node_name',
                    order: 'asc',
                },
            },
        });
    }
}
