import { Pipe, PipeTransform } from '@angular/core';

/**
 * Email's 'FROM' field comes from SNOW in various cases (see options below).
 * This pipe formats it correctly for support page use-cases (correct forms are 1,2)
 *
 * #1 - Justin Case <justin@purestorage.com>
 * #2 - <justing@purestorage.com>
 * #3 - justing@purestorage.com
 * #4 - <SREVICE-NOW-SYS-ID>
 * #5 - SREVICE-NOW-SYS-ID
 */
@Pipe({ name: 'emailFrom' })
export class EmailFromPipe implements PipeTransform {
    transform(from: string): string {
        if (from) {
            const splitFrom = from.split(' ');
            if (splitFrom.length === 1) {
                // just id or just email
                if (from.indexOf('@') > -1) {
                    // it is an email
                    if (from.indexOf('<') > -1 && from.indexOf('>') > -1) {
                        return from;
                    } else {
                        return `<${from}>`;
                    }
                } else {
                    // it is sys id
                    return '<Unknown User Email>';
                }
            } else if (splitFrom.length > 1) {
                // should be returned as comes but adjust for pure storage support
                if (
                    /support@purestorage.com|pure@service-now.com|pureuat@service-now.com|puretest@service-now.com|puredev@service-now.com/i.test(
                        from,
                    )
                ) {
                    return 'Pure Storage Support <support@purestorage.com>';
                } else {
                    return from;
                }
            } else {
                return '<Unknown User Email>';
            }
        } else {
            return '<Unknown User Email>';
        }
    }
}
