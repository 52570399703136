import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ArrayContractStatus, UnifiedArray } from '@pure1/data';

interface ExpirationState {
    svg?: string;
    text?: string;
    class?: string;
}

const EXPIRATION_STATES: Readonly<Map<ArrayContractStatus, ExpirationState>> = Object.freeze(
    new Map([
        [ArrayContractStatus.ACTIVE, { svg: 'under_contract.svg', text: 'Under Contract', class: 'pstg-active-icon' }],
        [
            ArrayContractStatus.ACTIVE_WITH_GRACE_PERIOD,
            {
                svg: 'expired_grace_period.svg',
                text: 'Under Contract with Grace Period',
                class: 'pstg-alert-warning-icon',
            },
        ],
        [
            ArrayContractStatus.ACTIVE_EXTENDED_MAINTENANCE,
            {
                svg: 'expired_grace_period.svg',
                text: 'Under Contract - Extended Maintenance',
                class: 'pstg-warning-icon',
            },
        ],
        [ArrayContractStatus.EXPIRED, { svg: 'expired.svg', text: 'Expired', class: 'pstg-alert-critical-icon' }],
    ]),
);

@Component({
    selector: 'contract-status-icon',
    templateUrl: 'contract-status-icon.component.html',
})
export class ContractStatusIconComponent implements OnChanges {
    @Input() readonly array: UnifiedArray;

    expirationStatus: ExpirationState;

    constructor() {}

    ngOnChanges(changesObj: SimpleChanges): void {
        if (changesObj.array) {
            this.setArrayExpirationStatus();
        }
    }

    private setArrayExpirationStatus(): void {
        this.expirationStatus = EXPIRATION_STATES.get(this.array.contract_status);
    }
}
