export const emailRegex = /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z0-9]+)$/i;
export const ARRAY_NAME_REG_EX = /^([A-Za-z0-9\-_]|[A-Za-z0-9\-_][A-Za-z0-9\-_ ]*[A-Za-z0-9\-_])$/;
export const VIEW_NAME_REG_EX = /^([A-Za-z0-9\-_]|[A-Za-z0-9\-_][A-Za-z0-9\-_ ]*[A-Za-z0-9\-_])$/;
export const GROUP_NAME_REG_EX = /^([A-Za-z0-9\-_]|[A-Za-z0-9\-_][A-Za-z0-9\-_ ]*[A-Za-z0-9\-_])$/;
export const IP_ADDR_REG_EX = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

/**
 * Gets the domain name and TLD from an email. Eg: abc@test.com => test.com
 * Input does not HAVE to be a completely-valid email address, but if the input is invalid, returns null.
 * Do not use this for any form of email validation.
 */
export function getEmailDomain(email: string): string | null {
    if (!email || !(typeof email === 'string') || email.length === 0) {
        return null;
    }

    const atIndex = email.lastIndexOf('@');
    if (atIndex <= 0) {
        return null;
    }

    const domain = email.substr(atIndex + 1);
    if (domain.length === 0) {
        return null;
    }

    return domain.trimRight();
}
