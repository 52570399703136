<div class="expanded-card-health-container" [attr.array-id]="array.arrayId">
    <div
        class="expanded-card-health-dashboard"
        [ngClass]="{ 'cbs-dashboard': isCBS() }"
        [id]="getDashboardElemId()"
        #dashboardGraphContainer
    ></div>

    <div [hidden]="!isExpanded">
        <div class="expanded-card-health-graph-container">
            <div class="expanded-card-health-graph-label" #boxLabel></div>
            <div [hidden]="!hasBack" class="expanded-card-health-graph-type front" #frontLabel>Front</div>
            <div [hidden]="!hasBack" class="expanded-card-health-graph-type rear" #rearLabel>Rear</div>
            <div
                class="expanded-card-health-graph"
                [ngClass]="{ 'oxygen-health-graph': isXlGraphic() }"
                [id]="getGraphElemId()"
                #arrayGraphContainer
            ></div>
        </div>
    </div>
</div>
