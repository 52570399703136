import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    DisplayedPartner,
    FeatureFlagDxpService,
    Partner,
    PartnerRequest,
    QuoteType,
    ServiceCatalogQuote,
} from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import { FeatureNames } from '../../../model/FeatureNames';
import { PartnerInfoService } from './partner-info.service';

@Component({
    selector: 'partner-info',
    templateUrl: './partner-info.component.html',
})
export class PartnerInfoComponent implements OnInit, OnChanges {
    @Input() readonly isArrayRenewable: boolean = false; //This is passing from Support Contract Only
    @Input() readonly partnersInfos: Partner[];
    @Input() readonly activeOrder: ServiceCatalogQuote;
    @Input() readonly isEditOrder: boolean = false; //Default value is false, except from Order Page
    @Output() readonly partnerRequestChanged = new EventEmitter<PartnerRequest>();

    @ViewChild('modifyPartnerModal') readonly modifyPartnerModal: TemplateRef<any>;

    readonly analyticsLabel = 'Quote Requesting Modal - Partner Info Section';

    partnersMapping: Map<string, DisplayedPartner[]> = new Map<string, DisplayedPartner[]>(); //key: partnerOrg; value: list of partner names under that org
    oldPartnersMapping: Map<string, string[]> = new Map<string, string[]>(); // To support non-feature-flag functionality

    featureFlagEnabled = false;
    isEditing = false;
    partnerOrganizationOptions: string[];
    isRenewalOrder = false;

    selectedOrg: string;
    selectedPartner: DisplayedPartner;

    // For editing partners
    savedPartner: DisplayedPartner; // The partner that will be saved when submitted
    partnerToUpdate: DisplayedPartner; // The partner that is selected to be updated in the form
    partnerOnQuote: DisplayedPartner; // The partner that is already listed on the salesflow or subscription

    constructor(
        private angulartics2: Angulartics2,
        private featureFlagDxpService: FeatureFlagDxpService,
        private modalService: NgbModal,
        private partnerInfoService: PartnerInfoService,
    ) {}

    ngOnInit(): void {
        this.featureFlagDxpService.getFeatureFlag(FeatureNames.REQUEST_PARTNER).subscribe(feature => {
            this.featureFlagEnabled = feature?.enabled === true;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeOrder && this.activeOrder) {
            this.isRenewalOrder =
                this.activeOrder?.quoteType === QuoteType.RENEWAL ||
                this.activeOrder?.quoteType === QuoteType.SUPPORT_CONTRACT_RENEWAL;

            this.partnerOnQuote = this.partnerInfoService.getPartnerInfoFromQuote(this.activeOrder);
        }

        if ((changes.partnersInfos || changes.activeOrder) && this.partnersInfos?.length > 0) {
            this.partnersMapping.clear();

            this.partnersInfos.forEach(partnerInfo => {
                const accountPartners = this.partnersMapping.get(partnerInfo.accountName) || [];

                const newPartner = DisplayedPartner.fromPartner(partnerInfo);
                accountPartners.push(newPartner);

                this.partnersMapping.set(partnerInfo.accountName, accountPartners);

                // Old functionality. Will be removed with feature flag
                const legacyAccountPartners = this.oldPartnersMapping.get(partnerInfo.accountName) || [];
                legacyAccountPartners.push(partnerInfo.contactName);
                this.oldPartnersMapping.set(partnerInfo.accountName, legacyAccountPartners);
            });
            this.partnerOrganizationOptions = Array.from(this.partnersMapping?.keys() || []);

            this.generatePartnerInfo();
        }
    }

    generatePartnerInfo(): void {
        const displayedPartner = this.partnerInfoService.generateDisplayedPartnerInfo(this.activeOrder, null);
        this.selectedOrg = displayedPartner.accountName;
        this.selectedPartner = displayedPartner;
    }

    onPartnerRequestChanged(newPartnerInfo: PartnerRequest): void {
        if (this.featureFlagEnabled) {
            const newPartner = DisplayedPartner.fromPartnerRequest(newPartnerInfo);
            const existingOrgPartners = this.partnersMapping.get(newPartner.accountName) || [];
            const existingPartnerIndex = existingOrgPartners.findIndex(
                partner => partner.contactEmail === newPartner.contactEmail,
            );

            if (existingPartnerIndex >= 0) {
                existingOrgPartners[existingPartnerIndex] = newPartner;
            } else {
                existingOrgPartners.push(newPartner);
            }

            this.partnersMapping.set(newPartnerInfo.account_name, existingOrgPartners);
            this.partnerOrganizationOptions = Array.from(this.partnersMapping?.keys() || []);
        }

        this.selectedOrg = newPartnerInfo.account_name;
        this.selectedPartner = DisplayedPartner.fromPartnerRequest(newPartnerInfo);

        if (!this.featureFlagEnabled) {
            this.partnerRequestChanged.emit(this.selectedPartner.toPartnerRequest());
        }
    }

    modifyPartner(partnerToUpdate?: DisplayedPartner): void {
        this.partnerToUpdate = partnerToUpdate; // Fine to be null. ModifyPartnerModal will handle this separately

        if (!partnerToUpdate && this.isRenewalOrder) {
            // In a renewal order, org cannot be modified
            this.partnerToUpdate = new DisplayedPartner({ account_name: this.selectedOrg });
        }

        this.modalService.open(this.modifyPartnerModal, { backdrop: 'static', windowClass: 'modify-partner-modal' });
        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel + ' - Open Modify Partner Modal',
            properties: { category: 'Action', label: partnerToUpdate ? `Edit existing partner` : `Add new partner` },
        });
    }

    editPartner(): void {
        this.isEditing = true;
    }

    cancelEdit(): void {
        this.isEditing = false;
        if (this.savedPartner) {
            this.selectedOrg = this.savedPartner?.accountName;
            this.selectedPartner = this.savedPartner;
        }
    }

    savePartner(): void {
        this.isEditing = false;
        this.savedPartner = this.selectedPartner;
        this.partnerRequestChanged.emit(this.selectedPartner.toPartnerRequest());
    }

    isModifyButtonValidated(): boolean {
        //If it is from edit order action, customer are not allowed to make the changes.
        if (this.isEditOrder) {
            return false;
        }
        //If it is for support contract and the renewal is requested, customer are not allowed to make the changes.
        return (
            this.isArrayRenewable ||
            (this.activeOrder?.quoteType !== QuoteType.SUPPORT_CONTRACT_RENEWAL && !this.activeOrder?.pure1Acknowledged)
        );
    }

    partnerOrgSelectionChanged(newSelection: string): void {
        if (this.selectedOrg !== newSelection) {
            this.selectedPartner = new DisplayedPartner({ account_name: newSelection });
        }
        this.selectedOrg = newSelection;
    }

    generatePartnerNameList(): DisplayedPartner[] {
        return this.partnersMapping.get(this.selectedOrg) || [];
    }
}
