import {
    DraasApiVSphereDatastore,
    DraasApiClusterConfiguration,
    DraasApiExtendedVSphereNetwork,
    DraasApiExtendedVSphereStorage,
    DraasApiVSphereProvider,
    DraasApiAWSProvider,
    DraasApiExtendedAWSNetwork,
    DraasApi_VPN,
    DraasApiBootstrapStatusDetail,
    DraasApiGeolocation,
} from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryCluster } from './disaster-recovery-cluster';
import { DisasterRecoveryUpgradeExecution } from './disaster-recovery-upgrade-execution';

export class DisasterRecoveryClusterConfiguration {
    cluster: DisasterRecoveryCluster;
    sourceVSphere?: SourceVSphere;
    sourceNetwork?: ExtendedSourceNetwork;
    sourceStorage?: SourceStorage;
    targetAwsCredentials?: TargetAwsCredentials;
    targetNetwork?: TargetNetwork;
    vpn?: Vpn;
    lastUpdatedTime?: Date;
    upgradeExecution?: DisasterRecoveryUpgradeExecution;

    constructor(json: DraasApiClusterConfiguration) {
        this.cluster = new DisasterRecoveryCluster(json.cluster);
        this.sourceVSphere = mapSourceVSphere(json.vsphere);
        this.sourceNetwork = mapExtendedSourceNetwork(json.vsphere_network);
        this.sourceStorage = mapSourceStorage(json.vsphere_storage);
        this.targetAwsCredentials = mapTargetAwsCredentials(json.aws_credentials);
        this.targetNetwork = mapTargetNetwork(json.aws_network);
        this.vpn = mapVpn(json.vpn);
        this.lastUpdatedTime = this.calculateLastUpdatedTime();
        this.upgradeExecution = json.upgrade_execution
            ? new DisasterRecoveryUpgradeExecution(json.upgrade_execution)
            : undefined;
    }

    calculateLastUpdatedTime(): Date | null {
        const lastUpdatedTimes = [];
        if (this.sourceVSphere?.providerLastUpdatedAt) {
            lastUpdatedTimes.push(this.sourceVSphere.providerLastUpdatedAt);
        }
        if (this.targetAwsCredentials?.providerLastUpdatedAt) {
            lastUpdatedTimes.push(this.targetAwsCredentials.providerLastUpdatedAt);
        }
        if (this.vpn) {
            lastUpdatedTimes.push(this.vpn.lastUpdatedAtUtc);
        }

        return lastUpdatedTimes.sort().pop();
    }
}

export interface SourceVSphere {
    providerName: string | undefined;
    hostname: string;
    clusterId: string;
    status: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
    providerId: string;
    providerVersion: number;
    providerLastUpdatedAt: Date | null;
    statusDetail: DisasterRecoveryBootstrapStatusDetail;
    location: DisasterRecoveryGeolocation;
}

function mapSourceVSphere(vSphere: DraasApiVSphereProvider | undefined): SourceVSphere | undefined {
    if (!vSphere) {
        return undefined;
    }

    return {
        providerName: vSphere.name,
        hostname: vSphere.hostname,
        clusterId: vSphere.cluster_id,
        status: vSphere.status,
        providerId: vSphere.provider_id,
        providerVersion: vSphere.provider_version,
        providerLastUpdatedAt: vSphere.provider_last_updated_at_utc
            ? new Date(vSphere.provider_last_updated_at_utc)
            : null,
        statusDetail: mapBootstrapStatusDetail(vSphere.bootstrap_status_detail),
        location: mapGeolocation(vSphere.location),
    };
}

export interface ExtendedSourceNetwork {
    switch: string;
    vlanId: string;
    cidr: string;
    clusterId: string;
    status: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
}

function mapExtendedSourceNetwork(
    sourceNetwork: DraasApiExtendedVSphereNetwork | undefined,
): ExtendedSourceNetwork | undefined {
    if (!sourceNetwork) {
        return undefined;
    }

    return {
        switch: sourceNetwork.switch,
        vlanId: sourceNetwork.vlan_id,
        cidr: sourceNetwork.cidr,
        clusterId: sourceNetwork.cluster_id,
        status: sourceNetwork.status,
    };
}

export interface SourceStorage {
    datacenter: string;
    resourcePool: string;
    clusterId: string;
    status: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
    datastores: VSphereDatastore[];
}

export interface VSphereDatastore {
    datastore: string;
    quota: number;
    used: boolean;
    currentBytes: number | null;
}

function mapSourceStorage(sourceStorage: DraasApiExtendedVSphereStorage | undefined): SourceStorage | undefined {
    if (!sourceStorage) {
        return undefined;
    }

    return {
        datacenter: sourceStorage.datacenter,
        resourcePool: sourceStorage.resource_pool,
        clusterId: sourceStorage.cluster_id,
        status: sourceStorage.status,
        datastores: sourceStorage.datastores?.map(datastore => mapVSphereDatastore(datastore)) || [
            {
                datastore: sourceStorage.datastore,
                quota: sourceStorage.quota_bytes,
                used: false,
                currentBytes: null,
            },
        ],
    };
}

function mapVSphereDatastore(datastore: DraasApiVSphereDatastore | undefined): VSphereDatastore | undefined {
    if (!datastore) {
        return undefined;
    }

    return {
        datastore: datastore.datastore,
        quota: datastore.quota_bytes,
        used: datastore.used,
        currentBytes: datastore.current_bytes,
    };
}

export interface TargetAwsCredentials {
    providerName: string | undefined;
    region: string;
    clusterId: string;
    status: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
    providerVersion?: number;
    providerId: string;
    providerLastUpdatedAt: Date | null;
    statusDetail: DisasterRecoveryBootstrapStatusDetail;
    location: DisasterRecoveryGeolocation;
}

function mapTargetAwsCredentials(awsCredentials: DraasApiAWSProvider | undefined): TargetAwsCredentials | undefined {
    if (!awsCredentials) {
        return undefined;
    }

    return {
        providerName: awsCredentials.name,
        region: awsCredentials.region,
        clusterId: awsCredentials.cluster_id,
        status: awsCredentials.status,
        providerVersion: awsCredentials.provider_version,
        providerId: awsCredentials.provider_id,
        providerLastUpdatedAt: awsCredentials.provider_last_updated_at_utc
            ? new Date(awsCredentials.provider_last_updated_at_utc)
            : null,
        statusDetail: mapBootstrapStatusDetail(awsCredentials.bootstrap_status_detail),
        location: mapGeolocation(awsCredentials.location),
    };
}

export interface TargetNetwork {
    controllerExternalIp: string;
    clusterId: string;
    status: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
}

function mapTargetNetwork(targetNetwork: DraasApiExtendedAWSNetwork | undefined): TargetNetwork | undefined {
    if (!targetNetwork) {
        return undefined;
    }

    return {
        controllerExternalIp: targetNetwork.controller_external_ip,
        clusterId: targetNetwork.cluster_id,
        status: targetNetwork.status,
    };
}

export interface Vpn {
    clusterId: string;
    status: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
    lastUpdatedAtUtc: Date;
    version: number;
    statusDetail: DisasterRecoveryBootstrapStatusDetail;
}

function mapVpn(vpn: DraasApi_VPN | undefined): Vpn | undefined {
    if (!vpn) {
        return undefined;
    }

    return {
        clusterId: vpn.cluster_id,
        status: vpn.status,
        lastUpdatedAtUtc: new Date(vpn.last_updated_at_utc),
        version: vpn.version,
        statusDetail: mapBootstrapStatusDetail(vpn.bootstrap_status_detail),
    };
}

export interface DisasterRecoveryBootstrapStatusDetail {
    status: string;
}

function mapBootstrapStatusDetail(
    detail: DraasApiBootstrapStatusDetail | undefined,
): DisasterRecoveryBootstrapStatusDetail | undefined {
    if (!detail) {
        return undefined;
    }

    return {
        status: detail.status,
    };
}

export interface DisasterRecoveryGeolocation {
    latitude: number;
    longitude: number;
}

function mapGeolocation(geolocation: DraasApiGeolocation | undefined): DisasterRecoveryGeolocation | undefined {
    if (!geolocation) {
        return undefined;
    }

    return {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
    };
}
