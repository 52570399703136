import moment from 'moment';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable, Inject, Renderer2, NgZone, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { WINDOW } from '../app/injection-tokens';

/** How long the fade out animation lasts. Should be roughly the same as the transition time on the CSS in #pure-dynamic-tooltip. */
const CLOSE_ANIMATION_DURATION_MS = 200;

/** Automatically close the tooltip after it has been open for this long */
const DEFAULT_TOOLTIP_DURATION_MS = moment.duration(3, 'seconds').asMilliseconds();

/** Close the tooltip when we move >= this distance from the source of the tooltip */
const DEFAULT_CLOSE_DISTANCE_PX = 100;

/** Always keep the tooltip this distance (px) from the edge of the document */
const DISTANCE_FROM_PAGE_SIDES = 5;

const DISTANCE_FROM_CURSOR_X = 25;
const DISTANCE_FROM_CURSOR_Y = 0;

const TOOLTIP_OPEN_CLASS = 'tooltip-open';
const TOOLTIP_CLOSED_CLASS = 'tooltip-closed';

const HTML_TEMPLATE = `<div id="pure-dynamic-tooltip" class="tooltip-closed">
    <div class="dynamic-tooltip-message"></div>
</div>`;

interface ITooltipState {
    autoCloseTimeout: number;
    startCoords: IPageCoords;
}

/**
 * @deprecated If we're going to make use of this again, it should probably look better... there may also be a better alternative available from NgBootstrap
 *
 * Allows for a tooltip-like popup to be created programatically at a specific location.
 * Used for providing feedback to the user for an action they just performed (like why they were not
 * allowed to click/select something).
 * Only one of these tooltips can be open at a time.
 */
@Injectable() // Only works as a sandboxed service due to injecting Renderer2
export class DynamicTooltipService implements OnDestroy {
    private unregisterDOMMoveEvents: Function[] = [];
    private tooltipRootElem: HTMLElement;
    private tooltipMessageElem: HTMLElement;

    private tooltipState: ITooltipState;
    private setClosedClassTimeout: number;

    private readonly destroy$ = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private ngZone: NgZone,
        private renderer: Renderer2,
        private router: Router,
    ) {
        this.ngZone.runOutsideAngular(() => {
            // Create tooltip template
            const dummyElem: HTMLElement = this.renderer.createElement('div');
            dummyElem.innerHTML = HTML_TEMPLATE;
            this.tooltipRootElem = dummyElem.firstElementChild as HTMLElement;
            this.tooltipMessageElem = this.tooltipRootElem.querySelector('.dynamic-tooltip-message');

            this.document.querySelector('body').appendChild(this.tooltipRootElem);

            // Close the tooltip when it gets touched
            ['mouseover', 'touchstart', 'click'].forEach(eventName => {
                this.renderer.listen(this.tooltipRootElem, eventName, () => this.close());
            });

            // Close the tooltip on state change (but not location change since we have dynamically-updating locations)
            this.router.events
                .pipe(
                    filter(e => e instanceof NavigationEnd),
                    takeUntil(this.destroy$),
                )
                .subscribe(() => {
                    this.close();
                });
        });
    }

    ngOnDestroy(): void {
        this.close();
        this.tooltipRootElem.remove();
        this.destroy$.next();
        this.destroy$.complete();
        this.window.clearTimeout(this.setClosedClassTimeout);
    }

    /**
     * Opens the tooltip
     * @param messageHtml Tooltip message. Use line breaks for a newline.
     * @param sourceEvent Event that occurred to create this tooltip
     */
    open(messageHtml: string, sourceEvent: MouseEvent | TouchEvent): void {
        this.ngZone.runOutsideAngular(() => {
            this.close();

            // Remove closed class timeout
            if (this.setClosedClassTimeout != null) {
                this.window.clearTimeout(this.setClosedClassTimeout);
                this.setClosedClassTimeout = null;
            }

            // Update tooltip content
            this.tooltipMessageElem.innerHTML = messageHtml;
            this.renderer.removeClass(this.tooltipRootElem, TOOLTIP_CLOSED_CLASS);
            this.renderer.addClass(this.tooltipRootElem, TOOLTIP_OPEN_CLASS);

            // Place tooltip to right of cursor, vertically centered
            const pageCoords = this.getPageCoordsFromEvent(sourceEvent);
            const tooltipWidth = this.tooltipRootElem.offsetWidth;
            const tooltipHeight = this.tooltipRootElem.offsetHeight;

            const newLeft = this.getTooltipCoord(
                pageCoords.pageX,
                DISTANCE_FROM_CURSOR_X,
                tooltipWidth,
                this.document.body.clientWidth,
            );
            const newTop = this.getTooltipCoord(
                pageCoords.pageY - tooltipHeight / 2,
                DISTANCE_FROM_CURSOR_Y,
                tooltipHeight,
                this.document.body.clientHeight,
            );
            this.renderer.setStyle(this.tooltipRootElem, 'left', newLeft + 'px');
            this.renderer.setStyle(this.tooltipRootElem, 'top', newTop + 'px');

            // Hook up non-persisting close handlers
            const closeTimeout = this.window.setTimeout(() => {
                this.close();
            }, DEFAULT_TOOLTIP_DURATION_MS);

            ['mousemove', 'touchmove'].forEach(eventName => {
                const unregisterFn = this.renderer.listen(this.document, eventName, event =>
                    this.documentMoveHandler(event),
                );
                this.unregisterDOMMoveEvents.push(unregisterFn);
            });

            // Update state
            this.tooltipState = {
                startCoords: pageCoords,
                autoCloseTimeout: closeTimeout,
            };
        });
    }

    /**
     * Gets if there is a tooltip currently opened.
     */
    isOpen(): boolean {
        return this.tooltipState != null;
    }

    /**
     * Gets the position to use for the tooltip for a single axis
     * @param pageCoord Position to place the tooltip
     * @param distanceFromCursor Distance the tooltip should be from the cursor on this axis
     * @param tooltipSize The size of the tooltip
     * @param documentSize The size of the document
     */
    private getTooltipCoord(
        pageCoord: number,
        distanceFromCursor: number,
        tooltipSize: number,
        documentSize: number,
    ): number {
        // Naive position that doesn't consider the document size
        let newPos = Math.max(pageCoord + distanceFromCursor, DISTANCE_FROM_PAGE_SIDES);

        // If we exceed the max size, shift the tooltip to the other side of the desired position
        const maxPos = documentSize - tooltipSize - distanceFromCursor - DISTANCE_FROM_PAGE_SIDES;
        if (newPos > maxPos) {
            newPos = pageCoord - tooltipSize - distanceFromCursor;
        }

        return Math.round(newPos);
    }

    /**
     * Closes the currently-open tooltip.
     */
    private close(): void {
        if (!this.tooltipState) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.unregisterDOMMoveEvents.forEach(unregisterFn => {
                unregisterFn();
            });
            this.unregisterDOMMoveEvents = [];

            this.renderer.removeClass(this.tooltipRootElem, TOOLTIP_OPEN_CLASS);
            this.window.clearTimeout(this.tooltipState.autoCloseTimeout);

            if (!this.setClosedClassTimeout) {
                this.setClosedClassTimeout = this.window.setTimeout(() => {
                    this.renderer.addClass(this.tooltipRootElem, TOOLTIP_CLOSED_CLASS);
                }, CLOSE_ANIMATION_DURATION_MS);
            }

            this.tooltipState = null;
        });
    }

    /**
     * Handles document move events to see how much the cursor has moved since the tooltip has been opened.
     */
    private documentMoveHandler(event: MouseEvent | TouchEvent): void {
        const pageCoords = this.getPageCoordsFromEvent(event);
        const distance = this.getDistanceBetweenPoints(pageCoords, this.tooltipState.startCoords);

        if (distance > DEFAULT_CLOSE_DISTANCE_PX) {
            this.close();
        }
    }

    /**
     * Gets the pageX/Y value from a jquery event in a way that supports multiple platforms.
     * @param event The jquery event to get the page coordinate from
     */
    private getPageCoordsFromEvent(event: MouseEvent | TouchEvent): IPageCoords {
        return {
            pageX: this.getPageCoordFromEvent('X', event),
            pageY: this.getPageCoordFromEvent('Y', event),
        };
    }

    private getPageCoordFromEvent(coord: 'X' | 'Y', event: MouseEvent | TouchEvent): number {
        if (event instanceof MouseEvent) {
            return coord === 'X' ? event.pageX : event.pageY;
        } else if (event instanceof TouchEvent && event.touches?.length > 0) {
            const touch = event.touches?.[0];
            return coord === 'X' ? touch.pageX : touch.pageY;
        } else {
            return 0;
        }
    }

    private getDistanceBetweenPoints(a: IPageCoords, b: IPageCoords): number {
        const x = a.pageX - b.pageX;
        const y = a.pageY - b.pageY;
        return Math.sqrt(x * x + y * y);
    }
}
